import { Svg, Path, Defs, LinearGradient } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const ConfluenceIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg fill="none" viewBox="0 0 512 512" style={style}>
            <Path
                fill="#2684FF"
                fill-rule="evenodd"
                d="M24.132 387.324c-5.172 8.777-10.98 18.961-15.912 27.075-4.415 7.764-2.045 17.778 5.33 22.52l103.425 66.237c3.63 2.333 8.009 3.051 12.152 1.992 4.144-1.059 7.706-3.805 9.885-7.622 4.137-7.203 9.468-16.559 15.275-26.577 40.972-70.377 82.183-61.767 156.489-24.839l102.55 50.754a15.34 15.34 0 0 0 12.382.569c4.032-1.56 7.285-4.752 9.019-8.849l49.245-115.915c3.48-8.279-.06-17.932-7.955-21.692-21.64-10.598-64.68-31.711-103.425-51.169-139.384-70.459-257.845-65.905-348.46 87.516Z"
                clip-rule="evenodd"
            />
            <Path
                fill="url(#a)"
                fill-rule="evenodd"
                d="M24.132 387.324c-5.172 8.777-10.98 18.961-15.912 27.075-4.415 7.764-2.045 17.778 5.33 22.52l103.425 66.237c3.63 2.333 8.009 3.051 12.152 1.992 4.144-1.059 7.706-3.805 9.885-7.622 4.137-7.203 9.468-16.559 15.275-26.577 40.972-70.377 82.183-61.767 156.489-24.839l102.55 50.754a15.34 15.34 0 0 0 12.382.569c4.032-1.56 7.285-4.752 9.019-8.849l49.245-115.915c3.48-8.279-.06-17.932-7.955-21.692-21.64-10.598-64.68-31.711-103.425-51.169-139.384-70.459-257.845-65.905-348.46 87.516Z"
                clip-rule="evenodd"
            />
            <Path
                fill="#2684FF"
                fill-rule="evenodd"
                d="M487.868 124.657c5.172-8.776 10.979-18.96 15.912-27.075 4.415-7.764 2.045-17.778-5.33-22.52L395.025 8.825c-3.66-2.566-8.179-3.434-12.478-2.398-4.299 1.036-7.992 3.884-10.196 7.862-4.137 7.204-9.467 16.56-15.275 26.578-40.972 70.377-82.182 61.766-156.489 24.839L98.357 15.2a15.343 15.343 0 0 0-12.383-.57c-4.032 1.56-7.285 4.753-9.019 8.85L27.71 139.395c-3.479 8.278.061 17.932 7.956 21.692 21.64 10.598 64.68 31.711 103.424 51.168 139.703 70.377 258.164 65.658 348.779-87.598Z"
                clip-rule="evenodd"
            />
            <Path
                fill="url(#b)"
                fill-rule="evenodd"
                d="M487.868 124.657c5.172-8.776 10.979-18.96 15.912-27.075 4.415-7.764 2.045-17.778-5.33-22.52L395.025 8.825c-3.66-2.566-8.179-3.434-12.478-2.398-4.299 1.036-7.992 3.884-10.196 7.862-4.137 7.204-9.467 16.56-15.275 26.578-40.972 70.377-82.182 61.766-156.489 24.839L98.357 15.2a15.343 15.343 0 0 0-12.383-.57c-4.032 1.56-7.285 4.753-9.019 8.85L27.71 139.395c-3.479 8.278.061 17.932 7.956 21.692 21.64 10.598 64.68 31.711 103.424 51.168 139.703 70.377 258.164 65.658 348.779-87.598Z"
                clip-rule="evenodd"
            />
            <Defs>
                <LinearGradient id="a" x1="481.188" x2="357.388" y1="537.351" y2="264.449">
                    <stop offset=".18" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </LinearGradient>
                <LinearGradient id="b" x1="30.812" x2="154.806" y1="-25.453" y2="247.576">
                    <stop offset=".18" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </LinearGradient>
            </Defs>
        </Svg>
    );
};
