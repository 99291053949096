export const enum CacheTag {
    Admin = 'Admin',
    EMAILS = 'Emails',
    USER = 'User',
    AUTH = 'Auth',
    SETTINGS = 'settings',
    AUTHORIZED_EMAILS = 'AuthorizedEmails',
    TEAM = 'Team',
    CUSTOM_FIELDS = 'CustomFields',
    ONBOARDING = 'Onboarding',
    WORKSPACE = 'Workspace',
    CHECKLIST = 'Checklist',
    TEMPLATE = 'Template',
    ORGANIZATION = 'Organisation',
    NETWORK = 'Network',
    TASK = 'Task',
    Meeting = 'Meeting',
    MeetingTopic = 'MeetingTopic',
    Label = 'Label',
}
