import React, { useCallback, useMemo, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { ModalType, UnexpectedErrorNotification, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { numericCompare } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { InlineSection } from 'Pages/meeting/components/MeetingView/InlineSection';
import { MeetingViewSearchParams, useSelectedTopicId } from 'Pages/meeting/components/MeetingView/MeetingView';
import { SubmissionsSectionDropdown } from 'Pages/meeting/components/TableOfContents/SubmissionsSectionDropdown';
import { Deferred } from 'Shared/components/Deferred/Deferred';
import { MeetingCardContainerContext } from 'Shared/components/meeting/MeetingCardContainerContext';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { InlineTopic } from 'Shared/components/meeting/topicView/InlineTopic';
import { TopicContentSkeleton } from 'Shared/components/meeting/topicView/TopicContentSkeleton';
import { TopicSubmission } from 'Shared/components/meeting/topicView/TopicSubmission';
import { useAddMeetingSectionsMutation, useDeleteMeetingSectionsMutation } from 'Shared/services/meetingSection';
import { useAddTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc } from 'Shared/trpc';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { EditSectionRoleModal } from '../EditMeetingAccessModal/EditSectionRoleModal';
import { MeetingEditAttendees } from '../MeetingEditAttendees/MeetingEditAttendees';
import {
    getAfterSectionObj,
    getDeleteSectionIds,
    getInsideSectionObj,
    hasTopicsInside,
    scrollIntoView,
} from '../TableOfContents/utils';

export const documentHeadingFontSize = (level: number) => {
    return clsx(
        level === 0 && '!text-lg',
        level === 1 && '!text-base',
        level === 2 && '!text-base',
        level >= 3 && '!text-sm'
    );
};

export const documentHeadingPaddingCompensation = (level: number) => {
    return level === 1 || level === 2 ? 'pt-[3px]' : level >= 3 && 'pt-[5px]';
};

export const documentHeadingClasses = (level: number) => {
    return [
        'flex items-center justify-between pl-4 pr-2 mx-2 font-bold rounded-md min-h-[2.5rem] gap-2',
        level === 0 && 'text-gray-900 bg-gray-300 !text-lg',
        level === 1 && 'text-gray-800 bg-gray-200 !text-base',
        level === 2 && 'text-gray-700 bg-gray-100 !text-base',
        level >= 3 && 'text-gray-700 bg-gray-100 !text-sm',
    ];
};

export const documentHeadingTextColorClasses = (level: number) => {
    switch (level) {
        case 0:
            return 'text-gray-900';
        case 1:
            return 'text-gray-800';
        default:
            return 'text-gray-700';
    }
};

export const documentHeadingColorClasses = (level: number) => {
    switch (level) {
        case 0:
            return 'text-gray-900 bg-gray-300';
        case 1:
            return 'text-gray-800 bg-gray-200';
        default:
            return 'text-gray-700 bg-gray-100';
    }
};

const isMeetingSection = (item: MeetingSection | MeetingTopic): item is MeetingSection =>
    (item as MeetingSection).section_series_id != null;

export const MeetingDocumentView = (): JSX.Element => {
    const isFirstRender = useRef(true);

    const { meetingId, meeting } = useMeetingContext();
    const ref = useRef();
    const { open: openModal } = useModal();
    const { confirm } = useConfirm();
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);
    const selectedTopicId = useSelectedTopicId();

    const { data: topics, isLoading: isLoadingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const { data: sections, isLoading: isLoadingSections } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const { data: topicSubmissions = [] } = trpc.meeting.listTopicSubmissions.useQuery({ meetingId }, { staleTime: 0 });

    const { data: blocks, isLoading: isLoadingBlocks } = trpc.meeting.listBlocks.useQuery(
        { meetingId, related: ['subtasks'] },
        { staleTime: 0, refetchOnWindowFocus: false, trpc: { context: { skipBatch: true } } }
    );

    const isLoading = isLoadingTopics || isLoadingSections || isLoadingBlocks;

    const [addSections] = useAddMeetingSectionsMutation();
    const [deleteSections] = useDeleteMeetingSectionsMutation();
    const [addTopics] = useAddTopicsMutation();
    const { show: showNotification } = useNotification();

    const topicsAndSections = useMemo(() => {
        return [...(topics || []), ...(sections || [])].sort((a, b) => numericCompare(a.display_id, b.display_id));
    }, [topics, sections]);

    const handleConfirmDelete = useCallback(
        async (applyOn: APPLY_ON, selectedSection: MeetingSection) => {
            if (applyOn) {
                const sectionIds = getDeleteSectionIds(selectedSection, topicsAndSections, []);
                try {
                    await deleteSections({ meetingId: meetingId, applyOn: applyOn, sections: sectionIds }).unwrap();
                } catch (e) {
                    showNotification(UnexpectedErrorNotification);
                }
            }
        },
        [meetingId]
    );

    const handleAddTopicInside = async (section: MeetingSection) => {
        const newId = uuidv4();
        const { order, display_id, parent_section_id } = getInsideSectionObj(section, topicsAndSections);
        const topicObject = {
            id: newId,
            topic_series_id: newId,
            title: '',
            meeting_id: section.meeting_id,
            order: order,
            display_id: display_id,
            meeting_section_id: parent_section_id,
        };
        try {
            const result = await addTopics({
                meetingId: section.meeting_id,
                topics: [topicObject],
            }).unwrap();
            const topicId = result.topics?.[0]?.id;
            if (topicId) {
                setSearchParams({ ...searchParams, topicId: topicId.toString() });
            }
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const addSection = async (section: MeetingSection, sectionObj: Partial<MeetingSection>) => {
        try {
            await addSections({ meetingId: section.meeting_id, sections: [sectionObj] }).unwrap();
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const handleAddSectionInside = (section: MeetingSection) => {
        const newId = uuidv4();
        const sectionObject: Partial<MeetingSection> = {
            id: newId,
            title: '',
            meeting_id: section.meeting_id,
            ...getInsideSectionObj(section, topicsAndSections),
        };
        return addSection(section, sectionObject);
    };

    const handleAddSectionAfter = (section: MeetingSection) => {
        const newId = uuidv4();
        const sectionObject = {
            id: newId,
            title: '',
            meeting_id: section.meeting_id,
            ...getAfterSectionObj(section),
        };
        return addSection(section, sectionObject);
    };

    const handleEditSectionRights = (section: MeetingSection) => {
        openModal(EditSectionRoleModal, { section });
    };

    const handleSectionMenuEvents = async (section: MeetingSection, key: string) => {
        switch (key) {
            case 'delete': {
                if (hasTopicsInside(section, topicsAndSections)) {
                    await confirm({
                        type: 'primary',
                        isCancelButtonVisible: false,
                        title: t`You can't delete this section because there is at least one topic inside`,
                    });
                    return;
                }
                const applyOn = await confirm(
                    {
                        showAll: false,
                        title: (
                            <div>
                                <Trans>
                                    Do you really want to delete <strong>{section?.title}</strong>?
                                </Trans>
                            </div>
                        ),
                        defaultOption: APPLY_ON.FUTURE_MEETINGS,
                        type: ModalType.Danger,
                    },
                    ConfirmSaveMeetingModal
                );
                await handleConfirmDelete(applyOn, section);
                break;
            }
            case 'addTopic':
                void handleAddTopicInside(section);
                break;
            case 'addSectionInside':
                void handleAddSectionInside(section);
                break;
            case 'addSectionAfter':
                void handleAddSectionAfter(section);
                break;
            case 'editSectionRights':
                void handleEditSectionRights(section);
                break;
            default:
        }
    };

    const handleTopicRef = (topicId: Id) => (element: HTMLDivElement) => {
        if (isFirstRender.current && topicId === selectedTopicId && element != null) {
            scrollIntoView(element);
            isFirstRender.current = false;
        }
    };

    const topicAndSectionElements = useMemo(() => {
        return topicsAndSections?.map((item) =>
            isMeetingSection(item) ? (
                <InlineSection
                    key={`section-${item.id}`}
                    section={item}
                    canAddAfter={item.can_manage_parent}
                    onSectionMenuEvent={handleSectionMenuEvents}
                />
            ) : (
                <InlineTopic
                    ref={handleTopicRef(item.id)}
                    key={`topic-${item.id}`}
                    topic={{ ...item, meeting }}
                    initialBlocks={(blocks as MeetingBlock[])?.filter(
                        ({ meeting_topic_id }) => meeting_topic_id === item.id
                    )}
                />
            )
        );
    }, [topicsAndSections, blocks, meeting]);

    return (
        <div
            ref={ref}
            className="rounded-lg mx-auto max-w-[1058px] min-h-[576px] border border-gray-200 bg-white shadow-md"
        >
            <MeetingCardContainerContext.Provider value={ref}>
                <div className="pb-2 flex flex-col gap-2">
                    <MeetingEditAttendees />
                    {topicSubmissions.length > 0 && (
                        <>
                            <div className={clsx(documentHeadingClasses(0))}>
                                <Trans>Submitted topics</Trans>
                                <SubmissionsSectionDropdown variant={'filled'} size={'md'} />
                            </div>
                            {topicSubmissions.map(({ meetingTopic }) => (
                                <TopicSubmission
                                    ref={handleTopicRef(meetingTopic.id)}
                                    topicId={meetingTopic.id}
                                    key={meetingTopic.id}
                                    isInline={true}
                                />
                            ))}
                        </>
                    )}
                    {isLoading ? <TopicContentSkeleton /> : <Deferred>{topicAndSectionElements}</Deferred>}
                </div>
            </MeetingCardContainerContext.Provider>
        </div>
    );
};
