import { Text as PdfText, View } from '@react-pdf/renderer';
import { colors } from '../colors';
import { useMeetingPdfContext } from './MeetingPdf';

export const Signatures = () => {
    const { meeting, spacing, fontSize, useBasicSignatureLayout } = useMeetingPdfContext();

    const groupSize = useBasicSignatureLayout ? 3 : 2;
    let signatories = meeting.meetingUsers.filter((user) => user.signature);
    const groupedSignatories = [];

    while (signatories.length > 0) {
        const max = signatories.length > groupSize ? groupSize : signatories.length;
        groupedSignatories.push(signatories.slice(0, max));
        if (signatories.length > groupSize) {
            signatories = signatories.slice(groupSize, signatories.length + 1);
        } else {
            signatories = [];
        }
    }

    return (
        <View>
            {groupedSignatories.map((group, index) => (
                <View
                    key={index}
                    wrap={false}
                    style={{
                        flexDirection: 'row',
                        gap: useBasicSignatureLayout ? 16 : 26,
                    }}
                >
                    {group.map((meetingUser, index, arr) => (
                        <View
                            key={meetingUser.user_id}
                            style={{
                                width: useBasicSignatureLayout ? '30%' : '100%',
                                maxWidth: '50%',
                                paddingTop: useBasicSignatureLayout ? spacing.signature.small : spacing.signature.large,
                            }}
                            wrap={false}
                        >
                            {!useBasicSignatureLayout && (
                                <PdfText
                                    style={{ fontSize: 6, color: 'white' }}
                                >{`signature_placeholder_${meetingUser.user_id}`}</PdfText>
                            )}
                            <PdfText
                                style={{
                                    fontSize: fontSize.medium,
                                    color: colors.black,
                                    borderTop: `1px solid ${colors.gray['300']}`,
                                    fontWeight: 'light',
                                    paddingTop: spacing.small,
                                    marginTop: spacing.normal,
                                    marginRight: arr.length === 1 ? 6 : 0,
                                }}
                            >
                                {meetingUser.user.full_name}
                            </PdfText>
                            <PdfText style={{ fontSize: fontSize.small, color: colors.gray['500'] }}>
                                {meetingUser.user_data.title}
                            </PdfText>
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
};
