import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmailToTaskAlert } from 'Shared/components/settings/EmailToTaskAlert';
import { WorkspaceEmailToTask } from 'Shared/components/workspace/WorkspaceEmailToTask';

export const EmailToTaskPanel: FC<{ workspaceId: Id }> = ({ workspaceId }) => {
    return (
        <Tabs.Panel>
            <p>
                <Trans>You can create a task in this workspace by sending an email to:</Trans>
            </p>

            <WorkspaceEmailToTask workspaceId={workspaceId} className="mt-4" />

            <EmailToTaskAlert />
        </Tabs.Panel>
    );
};
