import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { faVectorCircle } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { creator, HierarchyCircularNode, interpolateZoom, select } from 'd3';
import { Button, colors, EmptyState, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { AddCircleModal } from 'Pages/governance/AddCircleModal';
import {
    CircleSizes,
    colorScale,
    colorScaleDraft,
    describeArc,
    fontSizes,
    getMainCircle,
    getMargin,
    getRoleHasCircleSiblings,
    getStratifiedNodes,
    HalfSize,
    IdPrefix,
    StrokeWidth,
    ViewportSize,
    ZoomTransitionDuration,
} from 'Pages/governance/utils';
import { Can } from 'Shared/components/Can';
import { Circle, Root } from 'Shared/types/governance';
import { Permission } from 'Shared/utils/rbac';

type GraphProps = {
    nodes?: (Circle | Root)[];
    onNodeSelected: (node: Circle) => void;
};

export const Graph = React.forwardRef(({ nodes, onNodeSelected }: GraphProps, ref) => {
    const graph = useRef();
    const tooltip = useRef();
    const view = useRef(null);
    const circlesContainer = useRef(null);
    const arcsContainer = useRef(null);

    const currentZoomLevel = useRef(1);
    const [SVGSize, setSVGSize] = useState([0, 0]);

    // Displayed on top of the circle along the circle's arc
    const archedCircleLabelsContainer = useRef(null);
    const archedCircleLabels = useRef(null);

    const labelsContainer = useRef(null);
    const labels = useRef(null);
    const circles = useRef(null);
    const arcs = useRef(null);
    const selectedNode = useRef(null);
    const previouslySelectedNode = useRef(null);
    const { open: openModal } = useModal();
    const [stratifiedNodes, setStratifiedNodes] = useState([]);

    // Size in pixels of the svg container
    const selectNode = useCallback((node: HierarchyCircularNode<Circle | Root>) => {
        if (selectedNode.current != null) {
            select(`#${IdPrefix}${selectedNode.current.data.id}`).attr('stroke', null).attr('stroke-dasharray', null);
        }
        if (node.data.id !== 'root') {
            const circle = (node as HierarchyCircularNode<Circle>).data;
            select(`#${IdPrefix}${node.data.id}`)
                .attr('stroke', !circle.draft ? colors.blue[600] : colors.orange[600])
                .attr('stroke-dasharray', !circle.draft ? '0' : '5,5');
        }
        previouslySelectedNode.current = selectedNode.current;
        selectedNode.current = node;
        onNodeSelected(selectedNode.current);
    }, []);

    const shouldCircleLabelBeVisible = (node: HierarchyCircularNode<Circle>) => {
        const currentSelectedNode = selectedNode.current;
        if (node.id === 'root') {
            return false;
        }
        if (currentSelectedNode.id === 'root' && node.depth === 1) {
            return true;
        }
        // - node is NOT parent of selected circle
        if (node.descendants().find((d) => d.id === currentSelectedNode?.id)) {
            return false;
        }

        // - node is direct child of selected circle
        if (
            currentSelectedNode?.descendants().find((d: HierarchyCircularNode<Circle>) => d.id === node.id) &&
            currentSelectedNode?.depth + 1 === node.depth
        ) {
            return true;
        }

        // - node is direct sibling of selected circle
        const selectedNodeParent = currentSelectedNode?.parent;
        let isChildOfSelectedParent;
        if (selectedNodeParent == null) {
            isChildOfSelectedParent = true;
        } else {
            isChildOfSelectedParent = selectedNodeParent
                .descendants()
                .find((d: HierarchyCircularNode<Circle>) => d.id === node.id);
        }
        return isChildOfSelectedParent && currentSelectedNode?.depth === node.depth;
    };

    const getCircleOpacity = (node: HierarchyCircularNode<Circle>) => {
        const currentSelectedNode = selectedNode.current;
        let selectedNodeDepth = currentSelectedNode.depth;
        if (currentSelectedNode.data.type === 'role' && !getRoleHasCircleSiblings(currentSelectedNode, nodes)) {
            selectedNodeDepth -= 1;
        }
        const depth = node.depth;
        const depthDifference = Math.abs(selectedNodeDepth - depth);
        if (depthDifference < 2) {
            return 1;
        }
        if (depthDifference === 2) {
            return 0.5;
        }
        return 0;
    };

    const shouldCircleArchedLabelBeVisible = (node: HierarchyCircularNode<Circle>) => {
        const currentSelectedNode = selectedNode.current;

        // - node is parent of selected circle
        return node.descendants().some((d) => d.id === currentSelectedNode?.id);
    };

    const shouldRoleLabelBeVisible = (node: HierarchyCircularNode<Circle>): boolean => {
        let currentSelectedNode = selectedNode.current;
        if (currentSelectedNode.data.type === 'role') {
            currentSelectedNode = currentSelectedNode?.parent;
        }

        // - node is direct child of selected circle
        if (currentSelectedNode?.children?.some((d: HierarchyCircularNode<Circle>) => d.id === node.id)) {
            return true;
        }

        // - node is direct sibling of selected circle
        const selectedNodeParent = currentSelectedNode?.parent;
        let isChildOfSelectedParent;
        if (selectedNodeParent == null) {
            isChildOfSelectedParent = true;
        } else {
            isChildOfSelectedParent = selectedNodeParent
                .descendants()
                .find((d: HierarchyCircularNode<Circle>) => d.id === node.id);
        }
        return isChildOfSelectedParent && currentSelectedNode?.depth === node.depth;
    };

    const getCircleSize = (scaledRadius: number): number => {
        const diameter = 2 * scaledRadius;

        const smallestSide = Math.min(SVGSize[0], SVGSize[1]);
        const diameterToContainerRatio = diameter / smallestSide;

        if (diameterToContainerRatio >= 0.5) {
            return CircleSizes.Big;
        }
        if (diameterToContainerRatio >= 0.25) {
            return CircleSizes.Medium;
        }
        if (diameterToContainerRatio >= 0.1) {
            return CircleSizes.Small;
        }
        return CircleSizes.Tiny;
    };

    /* actual radius is k * node.r during zoom, not node.r */
    const getCircleFontSize = (scaledRadius: number): number => {
        const circleSize = getCircleSize(scaledRadius);
        return fontSizes[circleSize];
    };

    const recomputeLabelSizes = () => {
        if (!labels.current) {
            return;
        }
        labels.current
            .select(function () {
                return this;
            })
            // Only animate labels for which the circle is visible
            .filter((d: HierarchyCircularNode<Circle>) => {
                if (d.data.type === 'circle' || d.id === 'root') {
                    return shouldCircleLabelBeVisible(d);
                }
                return shouldRoleLabelBeVisible(d);
            })
            .style('font-size', (d: HierarchyCircularNode<Circle>) => {
                const scaledRadius = d.r * currentZoomLevel.current;
                return getCircleFontSize(scaledRadius) + 'px';
            })
            .style('line-height', (d: HierarchyCircularNode<Circle>) => {
                const scaledRadius = d.r * currentZoomLevel.current;
                return getCircleFontSize(scaledRadius) * 1.2 + 'px';
            });
        /** Debug Stuff START - Comment out this entire block when not debugging **/
        /*            .attr('class', (d) => {
                let color = '';

                switch (getCircleSize(d.r * currentZoomLevel.current)) {
                    case CircleSizes.Tiny:
                        color = 'bg-green-100';
                        break;
                    case CircleSizes.Small:
                        color = 'bg-yellow-300';
                        break;
                    case CircleSizes.Medium:
                        color = 'bg-orange-500';
                        break;
                    case CircleSizes.Big:
                        color = 'bg-red-700';
                        break;
                }

                return clsx('flex items-center h-full w-full text-center justify-center hyphens-auto', color);
            });*/
        /** Debug Stuff END **/
    };

    const recomputeArchedLabelSizes = () => {
        if (!archedCircleLabels.current) {
            return;
        }
        archedCircleLabels.current
            .select(function () {
                return this;
            })
            // Only animate labels for which the circle is visible
            .filter((d: HierarchyCircularNode<Circle>) => {
                return shouldCircleArchedLabelBeVisible(d);
            })
            .style('font-size', (d: HierarchyCircularNode<Circle>) => {
                const scaledRadius = d.r * currentZoomLevel.current;
                return getCircleFontSize(scaledRadius) + 'px';
            })
            .style('line-height', (d: HierarchyCircularNode<Circle>) => {
                const scaledRadius = d.r * currentZoomLevel.current;
                return getCircleFontSize(scaledRadius) * 1.2 + 'px';
            });
    };

    const recomputeContainerSize = () => {
        const container = select(graph.current).node();
        setSVGSize([container.clientWidth, container.clientHeight]);
    };

    const zoomOnNode = useCallback(
        (node: HierarchyCircularNode<Circle>) => {
            select(graph.current)
                .transition()
                .duration(ZoomTransitionDuration)
                .tween('zoom', () => {
                    const size =
                        node.data.type === 'role'
                            ? node.r * 6 + 6 * getMargin(node.r)
                            : node.r * 2 + 2 * getMargin(node.r);

                    const i = interpolateZoom(view.current, [node.x, node.y, size]);
                    return (t: number) => {
                        const [x, y, r] = i(t);
                        const k = ViewportSize / r;
                        currentZoomLevel.current = k;
                        view.current = [x, y, r];

                        let transitionedMiddle = false;
                        let transitionedEnd = false;

                        arcs.current.attr('d', (d: HierarchyCircularNode<Circle>) =>
                            describeArc((d.x - x) * k, (d.y - y) * k, d.r * k, -90, 90)
                        );

                        labels.current
                            .select(function () {
                                return this.parentNode.parentNode;
                            })
                            .attr('transform', (d: HierarchyCircularNode<Circle>) => {
                                const a = Math.sqrt((d.r * 2) ** 2 / 2) / 2;
                                return `translate(${(d.x - x - a) * k},${(d.y - y - a) * k})`;
                            })
                            .attr('width', ({ r }) => Math.sqrt((r * 2) ** 2 / 2) * k)
                            .attr('height', ({ r }) => Math.sqrt((r * 2) ** 2 / 2) * k)
                            .style('opacity', (d: HierarchyCircularNode<Circle>) => {
                                if (d.data.type === 'circle' || d.id === 'root') {
                                    return shouldCircleLabelBeVisible(d) ? 1 : 0;
                                }
                                return shouldRoleLabelBeVisible(d) ? 1 : 0;
                            })
                            .attr('font-weight', ({ id }) =>
                                id !== 'root' && id === selectedNode.current.id ? 'bolder' : 'bold'
                            );

                        archedCircleLabels.current
                            .style('opacity', (d: HierarchyCircularNode<Circle>) =>
                                shouldCircleArchedLabelBeVisible(d) ? 1 : 0
                            )
                            .style('visibility', (d: HierarchyCircularNode<Circle>) =>
                                shouldCircleArchedLabelBeVisible(d) ? 'visible' : 'hidden'
                            )
                            .attr('font-weight', ({ id }: { id: string }) =>
                                id !== 'root' && id === selectedNode.current.id ? 'bolder' : 'bold'
                            );

                        circles.current
                            .attr(
                                'transform',
                                (d: HierarchyCircularNode<Circle>) => `translate(${(d.x - x) * k},${(d.y - y) * k})`
                            )
                            .attr('r', (d: HierarchyCircularNode<Circle>) => d.r * k)
                            .style('fill', (d: HierarchyCircularNode<Circle>) => {
                                if (d.data.id === 'root') {
                                    return 'transparent';
                                }

                                if (d.data.type === 'circle') {
                                    return (
                                        d.data.color ?? (!d.data.draft ? colorScale(d.depth) : colorScaleDraft(d.depth))
                                    );
                                }
                                const linkedCircle = nodes?.find(
                                    (node) => node.id === d.data.linked_circle_id
                                ) as Circle;

                                if (linkedCircle && linkedCircle.color != null) {
                                    return linkedCircle.color;
                                }

                                if (d.data.color != null) {
                                    return d.data.color;
                                }
                                return d.data.draft ? colors.orange[50] : 'white';
                            })
                            .style('transition', 'opacity 0.3s ease')
                            .style('opacity', (d: HierarchyCircularNode<Circle>) => {
                                return getCircleOpacity(d);
                            })
                            .style('visibility', (d: HierarchyCircularNode<Circle>) => {
                                if (getCircleOpacity(d) === 0) {
                                    return 'hidden';
                                }
                                return 'visible';
                            });

                        // We recompute the label sizes only for the middle and end of animation
                        if ((t > 0.5 && !transitionedMiddle) || (t > 0.99 && !transitionedEnd)) {
                            if (t > 0.5) {
                                transitionedMiddle = true;
                            }
                            if (t > 0.99) {
                                transitionedEnd = true;
                            }
                            recomputeLabelSizes();
                            recomputeArchedLabelSizes();
                        }
                    };
                });
        },
        [nodes]
    );

    // Makes zoom / select available to the parent of Graph
    useImperativeHandle(ref, () => ({
        selectNode,
        zoomOnNode,
        stratifiedNodes,
    }));

    useEffect(() => {
        recomputeArchedLabelSizes();
        recomputeLabelSizes();
    }, [SVGSize]);

    const handleResize = useCallback((): void => {
        recomputeContainerSize();
    }, []);

    const initializeGraph = () => {
        const container = select(graph.current)
            .attr('viewBox', `-${HalfSize} -${HalfSize} ${ViewportSize} ${ViewportSize}`)
            .style('display', 'block')
            .style('cursor', 'pointer')
            .style('width', '100%')
            .style('height', '100%');

        circlesContainer.current = container.append('g');

        arcsContainer.current = container.append('g');

        archedCircleLabelsContainer.current = container
            .append('g')
            .style('font-family', 'Poppins, sans-serif, Helvetica, Arial')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle');

        labelsContainer.current = container
            .append('g')
            .style('font-family', 'Poppins, sans-serif, Helvetica, Arial')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle');

        recomputeContainerSize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    };

    const handleDataChanges = (nodes: (Circle | Root)[]) => {
        let previousFrameWheelDelta: number = null;
        let wheelTimeout: number = null;

        if (nodes) {
            const root: HierarchyCircularNode<Root> = getStratifiedNodes(nodes);
            setStratifiedNodes(root);
            const descendants = root.descendants();

            // If there's no selected node we select the topmost circle with the biggest size
            if (selectedNode.current == null) {
                const mainCircle = getMainCircle(nodes, root);
                selectNode(mainCircle);
            } else {
                // ...otherwise, we select the node with the same id...
                // (event if it's the same node, we need to select it again as its position may have changed in the pack layout)
                const newSelectedNode = descendants.find((node) => node.data.id === selectedNode.current.data.id);
                if (newSelectedNode) {
                    selectNode(newSelectedNode);
                } else {
                    const parentNode = selectedNode.current.parent || root;
                    const newParentNode = descendants.find((node) => node.data.id === parentNode.data.id);
                    selectNode(newParentNode);
                }
            }
            const nodeToZoomOn =
                selectedNode.current?.data.type === 'role' ? selectedNode.current.parent || root : selectedNode.current;

            if (view.current == null) {
                view.current = [nodeToZoomOn.x, nodeToZoomOn.y, nodeToZoomOn.r * 2 + getMargin(nodeToZoomOn.r) * 2];
            }

            circles.current = circlesContainer.current
                .selectAll('circle')
                .data(descendants, (d: HierarchyCircularNode<Circle>) => d.data.id)
                .join('circle')
                .attr('id', (d: HierarchyCircularNode<Circle>) => `${IdPrefix}${d.data.id}`)
                .attr('stroke', ({ id }: { id: Id }) =>
                    id !== 'root' && id === selectedNode.current.id
                        ? !selectedNode.current.data.draft
                            ? colors.blue[600]
                            : colors.orange[600]
                        : null
                )
                .style('stroke-width', StrokeWidth)
                .style('vector-effect', 'non-scaling-stroke')
                .on('mouseover', function (_: PointerEvent, d: HierarchyCircularNode<Circle>) {
                    if (d.data.id !== 'root') {
                        if (selectedNode.current.data.id !== d.data.id) {
                            select(this)
                                .attr('stroke', !d.data.draft ? colors.blue[400] : colors.orange[400])
                                .attr('stroke-dasharray', !d.data.draft ? '0' : '5,5');
                        }
                        select(tooltip.current).style('opacity', 1).text(d.data.name);
                    }
                })
                .on('mousemove', (event: PointerEvent) => {
                    const tooltipSelection = select(tooltip.current);
                    tooltipSelection
                        .style('left', `${event.offsetX}px`)
                        .style('top', `${event.offsetY - tooltipSelection.node().clientHeight}px`);
                })
                .on('mouseout', function (_: PointerEvent, d: HierarchyCircularNode<Circle>) {
                    if (selectedNode.current.data.id !== d.data.id) {
                        select(this).attr('stroke', null);
                    }
                    select(tooltip.current).style('opacity', 0);
                })
                .on('click', (event: PointerEvent, node: HierarchyCircularNode<Circle>) => {
                    if (selectedNode.current.data.id !== node.data.id) {
                        event.stopPropagation();
                        selectNode(node);
                        if (
                            node.data.type === 'circle' ||
                            (node.data.type === 'role' && getRoleHasCircleSiblings(node.data, nodes))
                        ) {
                            zoomOnNode(node);
                        } else {
                            zoomOnNode(node.parent || root);
                        }
                    }
                })
                .on('wheel', (event: WheelEvent, d: HierarchyCircularNode<Circle>) => {
                    clearTimeout(wheelTimeout);
                    wheelTimeout = setTimeout(() => {
                        previousFrameWheelDelta = null;
                    }, 200);
                    if (event.deltaY > 0) {
                        if (previousFrameWheelDelta == null || previousFrameWheelDelta < 0) {
                            if (selectedNode.current.parent != null) {
                                zoomOnNode(selectedNode.current.parent);
                                selectNode(selectedNode.current.parent);
                            } else {
                                zoomOnNode(root);
                                selectNode(root);
                            }
                        }
                    } else if (previousFrameWheelDelta == null || previousFrameWheelDelta > 0) {
                        if (d.data.type === 'role' && !getRoleHasCircleSiblings(d.data, nodes)) {
                            zoomOnNode(d.parent);
                        } else {
                            zoomOnNode(d);
                        }
                        selectNode(d);
                    }
                    previousFrameWheelDelta = event.deltaY;
                });

            arcs.current = arcsContainer.current
                .selectAll('path')
                .data(descendants, (d: HierarchyCircularNode<Circle>) => d.data.id)
                .join('path')
                .filter((d: HierarchyCircularNode<Circle>) => d.data.type === 'circle')
                .attr('fill', 'none')
                .attr('id', (_: HierarchyCircularNode<Circle>, i: number) => `s${i}`);

            archedCircleLabels.current = archedCircleLabelsContainer.current
                .selectAll('text')
                .data(descendants, (d: HierarchyCircularNode<Circle>) => d.data.id)
                .join('text')
                .filter((d: HierarchyCircularNode<Circle>) => d.data.type === 'circle')
                .style('text-shadow', '1px 1px 1px white')
                .style('stroke-linejoin', 'bevel')
                .style('paint-order', 'stroke')
                .style('dominant-baseline', 'middle')
                .select(function () {
                    return this.firstElementChild || this.appendChild(creator('textPath').apply(this));
                })
                .style('opacity', 0)
                .style('transition', 'opacity 0.3s ease-in, font-size 0.2s ease-in-out')
                .attr('xlink:href', (_: HierarchyCircularNode<Circle>, i: number) => `#s${i}`)
                .attr('startOffset', '50%')
                .text((d: HierarchyCircularNode<Circle>) => d.data.name);

            labels.current = labelsContainer.current
                .selectAll('foreignObject')
                .data(descendants, (d: HierarchyCircularNode<Circle>) => d.data.id)
                .html(null)
                .style('opacity', 0)
                .style('transition', 'opacity 0.3s ease-in, font-size 0.2s ease-in-out')
                .join('foreignObject')
                .append('xhtml:div')
                .attr('class', 'flex items-center justify-center w-full h-full')
                .append('xhtml:p')
                .style('word-break', 'break-word')
                .style('word-wrap', 'break-word')
                .style('overflow-wrap', 'break-word')
                .style('hyphens', 'auto')
                .style('flex-wrap', 'wrap')
                .style('color', colors.gray[900])
                .style('text-shadow', '1px 1px 1px white')
                .style('font-weight', 'bold')
                .attr('class', 'flex items-center h-full w-full text-center justify-center hyphens-auto')
                .text((d: HierarchyCircularNode<Circle>) => d.data.name);

            recomputeLabelSizes();
            recomputeArchedLabelSizes();
            zoomOnNode(nodeToZoomOn);
        }
    };

    useEffect(() => {
        return initializeGraph();
    }, []);

    useEffect(() => {
        handleDataChanges(nodes);
    }, [nodes]);

    return (
        <div className="flex h-full items-center justify-center">
            <svg ref={graph} className={(nodes || []).length === 1 ? '!hidden' : undefined} />
            <div
                className={clsx(
                    'pointer-events-none absolute rounded-md bg-black bg-opacity-70 p-2 text-white opacity-0',
                    (nodes || []).length === 1 && '!hidden'
                )}
                ref={tooltip}
            />
            {(nodes || []).length === 1 && (
                <EmptyState icon={faVectorCircle} size="lg">
                    <EmptyState.Text>
                        <Trans>No circles or roles</Trans>
                    </EmptyState.Text>
                    <Can permission={Permission.ManageGovernance}>
                        <Button
                            color="primary"
                            onClick={() =>
                                openModal(AddCircleModal, {
                                    title: t`Add circle`,
                                    parentCircleId: 'root',
                                    circles: [stratifiedNodes],
                                    type: 'circle',
                                })
                            }
                        >
                            <Trans>Add circle</Trans>
                        </Button>
                    </Can>
                </EmptyState>
            )}
        </div>
    );
});
