import { FC, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { Icon } from '~/components/Icon/Icon';
import { IconName } from '~/components/Icon/type';

export type EmptyStateSizes = 'md' | 'lg';

const classes = {
    base: 'relative flex flex-col justify-center items-center gap-3 text-center w-full min-h-full',
    size: {
        md: 'text-lg',
        lg: 'text-2xl',
    },
    icon: {
        wrapper: 'flex justify-center items-center rounded-full',
        wrapperLight: 'bg-gray-200 bg-opacity-80',
        wrapperDark: 'bg-gray-100 bg-opacity-10',
        baseLight: 'text-gray-400',
        baseDark: 'text-gray-300',
        padding: {
            md: 'p-10',
            lg: 'p-18',
            full: 'p-24',
        },
        size: {
            md: 'h-20 w-20 lg:h-24 lg:w-24',
            lg: 'h-24 w-24 lg:h-36 lg:w-36',
        },
    },
};

const EmptyStateText = ({ children, className }: { children: ReactNode; className?: string }): JSX.Element => (
    <h3 className={clsx('font-medium text-gray-500', className)}>{children}</h3>
);

export type EmptyStateProps = {
    icon?: IconDefinition | IconName;
    size?: EmptyStateSizes;
    className?: string;
    onClick?: () => void;
    children?: ReactNode;
    isDark?: boolean;
};
const EmptyStateComponent: FC<EmptyStateProps> = ({ icon, size = 'md', className, children, isDark = false }) => {
    return (
        <div className={clsx(classes.base, classes.size[size], className)}>
            {icon && (
                <div
                    className={clsx(
                        classes.icon.wrapper,
                        classes.icon.padding[size],
                        isDark ? classes.icon.wrapperDark : classes.icon.wrapperLight
                    )}
                >
                    <Icon
                        name={icon}
                        className={clsx(
                            isDark ? classes.icon.baseDark : classes.icon.baseLight,
                            classes.icon.size[size]
                        )}
                    />
                </div>
            )}
            {children}
        </div>
    );
};

EmptyStateComponent.displayName = 'EmptyState';
EmptyStateText.displayName = 'EmptyState.Text';

export const EmptyState = Object.assign(EmptyStateComponent, {
    Text: EmptyStateText,
});
