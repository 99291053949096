import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { EmptyFunction } from './function';
import { EmptyString } from './string';

type AppendScriptParams = {
    source: string;
    onLoad?: (event: Event) => void;
    onError?: (event: Event | string) => void;
    async?: boolean;
};

const initialAppendScriptParams: AppendScriptParams = {
    onError: EmptyFunction,
    onLoad: EmptyFunction,
    source: EmptyString,
    async: true,
};

export const appendScript = (params: AppendScriptParams = initialAppendScriptParams): void => {
    const script: HTMLScriptElement = document.createElement('script');
    script.src = params.source;
    script.async = params.async;
    script.onload = (event) => {
        params.onLoad?.(event);
    };
    script.onerror = (event) => {
        params.onError?.(event);
    };
    document.body.appendChild(script);
};

export const removeHtml = (text: string) => String(text).replace(/<[^>]+>/gm, '');

export const reactElementToHtml = (element: JSX.Element): string => {
    const div = document.createElement('div');
    const root = createRoot(div);

    flushSync(() => {
        root.render(element);
    });

    return div.innerHTML;
};

export const htmlEncode = (s) => {
    return s
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&#39;')
        .replace(/"/g, '&#34;');
};
