import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { i18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import validator from 'validator';
import { z } from 'zod';
import { isValidEmail } from '@wedo/utils';
import { TOTAL_ONBOARDING_STEPS } from 'Pages/onboarding/utils/onboardingStore';
import { CompanySizeOptions, IndustryOptions } from 'Pages/onboarding/utils/selectOptions';
import { getErrorMessage, NAME_REGEX } from 'Shared/utils/user';

const industryEnum = [...IndustryOptions.keys()] as [string, ...string[]];

const ERROR_MESSAGE = Object.freeze({
    organizationSubdomainSize: msg`Should be between 3 and 20 characters`,
    organizationNameSize: msg`Should be between 3 and 50 characters`,
    fieldRequired: msg`Required field`,
    emailInvalid: msg`Invalid email address`,
    initials: msg`Initials must be between 2 and 3 characters`,
});

type NewOrganizationSchemaConfig = { isRestrictedDomain?: boolean };

const DefaultNewOrganizationSchemaConfig = { isRestrictedDomain: false };

export const computeNewOrganizationSchema = ({
    isRestrictedDomain,
}: NewOrganizationSchemaConfig = DefaultNewOrganizationSchemaConfig) =>
    z.object({
        step: z
            .number()
            .min(0)
            .max(TOTAL_ONBOARDING_STEPS - 1),
        userId: z.string().or(z.number()),
        email: z.string(),
        authLink: z.string(),
        networkId: z.string().or(z.number()),
        token: z.string(),
        authToken: z.string(),
        organizationName: z
            .string()
            .min(3, { message: i18n._(ERROR_MESSAGE.organizationNameSize) })
            .max(50, { message: i18n._(ERROR_MESSAGE.organizationNameSize) }),
        organizationIndustry: z.enum(industryEnum, {
            errorMap: () => ({ message: i18n._(ERROR_MESSAGE.fieldRequired) }),
        }),
        organizationSize: z.enum(CompanySizeOptions, {
            errorMap: () => ({ message: i18n._(ERROR_MESSAGE.fieldRequired) }),
        }),
        organizationWebsite: z
            .literal('')
            .or(z.string().refine((str) => validator.isURL(str), { message: t`This URL is invalid` })),
        subdomain: z
            .string()
            .min(3, { message: i18n._(ERROR_MESSAGE.organizationSubdomainSize) })
            .max(20, { message: i18n._(ERROR_MESSAGE.organizationSubdomainSize) })
            .regex(/^[a-z0-9_-]+$/i, {
                message: t`The domain must contain only letters, numbers, underscores (_) and dashes (-)`,
            }),
        teamName: z.string().min(1, { message: i18n._(ERROR_MESSAGE.fieldRequired) }),
        teamId: z.string().or(z.number()),
        invites: z
            .literal('')
            .or(
                z
                    .string()
                    .refine(
                        (value) => {
                            return isRestrictedDomain || !value.includes('@');
                        },
                        { message: t`You only need to write the part before the "@"` }
                    )
                    .refine(
                        (value) => {
                            let email = value;
                            if (!isRestrictedDomain) {
                                email += '@email.com';
                            }

                            return isValidEmail(email);
                        },
                        {
                            message: getErrorMessage('emailInvalid'),
                        }
                    )
            )
            .array()
            .superRefine((list, ctx) => {
                // create a new error for duplicates
                list.forEach((val, index) => {
                    const duplicate = list.reduce((acc, v, i) => {
                        if (v === val && i < index && v !== '') {
                            return acc + 1;
                        }
                        return acc;
                    }, 0);

                    if (duplicate > 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            path: [index],
                            message: t`Emails must be unique.`,
                        });
                    }
                });
            }),
        workspaceNames: z.string().array(),
        firstName: z
            .string()
            .min(1, { message: i18n._(ERROR_MESSAGE.fieldRequired) })
            .regex(NAME_REGEX, { message: getErrorMessage('noSpecialCharacters') }),
        lastName: z
            .string()
            .regex(NAME_REGEX, { message: getErrorMessage('noSpecialCharacters') })
            .or(z.literal('')),
        jobTitle: z.string(),
        language: z.string(),
        password: z.string(),
        passwordConfirmation: z.string(),

        /** the domain inferred from the email address */
        domainFromEmail: z.string(),

        networks: z.array(
            z.object({
                networkId: z.string().or(z.number()),
                status: z.string(),
            })
        ),
    });

export type NewOrganizationSchema = ReturnType<typeof computeNewOrganizationSchema>;

export const useNewOrganizationSchema = (
    newOrganizationSchemaConfig: NewOrganizationSchemaConfig = DefaultNewOrganizationSchemaConfig
) => {
    const linguiContext = useLingui();
    return useMemo(
        () => computeNewOrganizationSchema(newOrganizationSchemaConfig),
        [linguiContext, newOrganizationSchemaConfig]
    );
};
