import { useMemo } from 'react';
import { cloneDeep } from 'lodash-es';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { WorkspaceTabs } from 'Shared/components/workspace/utils';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { UserFeature } from 'Shared/types/user';
import { WorkspaceTab } from 'Shared/types/workspace';
import { isValidWorkspaceId } from 'Shared/utils/workspace';

export const useWorkspace = (workspaceId: Id) => {
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !isValidWorkspaceId(workspaceId) });

    const { currentUser } = useCurrentUserContext();
    const { network: currentNetwork } = useCurrentNetwork();
    const hasGanttFeature = useHasFeature(currentUser!, currentNetwork!, UserFeature.Gantt);

    const tabs = useMemo(() => {
        const result = cloneDeep(WorkspaceTabs);

        return result
            .filter(({ type }) => type !== 'gantt' || hasGanttFeature)
            .map((item) => {
                const tab = (workspace?.settings?.tabs as WorkspaceTab[])?.find(({ type }) => type === item.type);
                return tab ? { ...item, active: tab.active } : item;
            });
    }, [workspace]);

    return { workspace, tabs };
};
