import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { HTMLTagElement, Tag, TagProps } from '~/components/Tag/Tag';
import { TagSelectValue } from '~/components/TagSelect/TagSelect';
import { Tooltip } from '~/components/Tooltip/Tooltip';
import { getColorId, isDarkColor } from '~/utils/color';
import { EmptyFunction, onArrowLeft, onArrowRight, onBackSpace } from '@wedo/utils';
import { useEvent, useIsOverflowing } from '@wedo/utils/hooks';
import { colors } from '../../../colors.js';

const BG_COLOR = colors.gray[500];

type ValueTagProps = {
    value: TagSelectValue;
    onRemove?: () => void;
    size: TagProps['size'];
    isFocused?: boolean;
    onArrowLeftPress?: () => void;
    onArrowRightPress?: () => void;
    showBorder?: boolean;
};

export const ValueTag = React.forwardRef<{ element: HTMLTagElement; isOverflowing: boolean }, ValueTagProps>(
    (
        {
            value,
            onRemove,
            size,
            isFocused = false,
            showBorder = false,
            onArrowRightPress = EmptyFunction,
            onArrowLeftPress = EmptyFunction,
        },
        ref
    ) => {
        const tagRef = useRef<HTMLTagElement>();
        const [setRef, isOverflowing] = useIsOverflowing();
        const colorId = getColorId(value?.color) as string;

        useEvent('keydown', onArrowLeft(onArrowLeftPress));

        useEvent('keydown', onArrowRight(onArrowRightPress));

        useEvent(
            'keydown',
            onBackSpace(() => {
                if (isFocused) {
                    onRemove();
                }
            })
        );

        useImperativeHandle(ref, () => ({
            element: tagRef?.current,
            isOverflowing,
        }));

        return (
            <Tag
                ref={tagRef}
                hideRemoveTooltip
                size={size}
                shape="square"
                onRemove={onRemove}
                color="none"
                className={clsx(
                    showBorder && 'border-2',
                    isFocused ? 'border-blue-500' : 'border-white',
                    `bg-${colorId}-200`,
                    `text-${colorId}-800`
                )}
                removeClassName={clsx(
                    isDarkColor(value?.color ?? BG_COLOR)
                        ? 'focus:!text-black hover:!text-black focus:!bg-white hover:!bg-white'
                        : 'focus:!text-white hover:!text-white focus:!bg-black hover:!bg-black',
                    'hover:opacity-30 focus:opacity-30'
                )}
            >
                <Tooltip content={isOverflowing && value?.name}>
                    <div className="truncate">
                        <div className="truncate" ref={setRef}>
                            {value?.name}
                        </div>
                    </div>
                </Tooltip>
            </Tag>
        );
    }
);
