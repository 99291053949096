import React, { FC, useEffect, useRef, useState } from 'react';
import { faBriefcase, faEye, faEyeSlash, faPlus, faTimes, faUser } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { create } from 'zustand';
import { Button, ButtonProps, Textarea, Tooltip, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString, onEnter, onEsc, preventDefault, sleep, stopPropagation } from '@wedo/utils';
import { useInputState, useSearchParams } from '@wedo/utils/hooks';
import { useClickAway } from '@wedo/utils/hooks/useClickAway';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { BulkTasksAdditionConfirmModal } from 'Pages/TasksPage/components/AddTaskInput/BulkTasksAdditionConfirmModal';
import { useAddTaskInput } from 'Pages/TasksPage/components/AddTaskInput/useAddTaskInput';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { WorkspacePicker } from 'Shared/components/workspace/WorkspacePicker';
import { useGetChecklistQuery } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetUserQuery } from 'Shared/services/user';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { TaskFilter, TaskStatus } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { TEXT_WITHOUT_BULLETS_REGEX } from 'Shared/utils/parseClipboard';
import { SectionBlock } from './types';

type AddTaskInputStore = {
    isAddingTask: boolean;
};

export const useAddTaskInputStore = create<AddTaskInputStore>()(() => ({
    isAddingTask: false,
}));

type ClearButtonProps = {
    title: string;
    onClick: ButtonProps['onClick'];
    onKeyDown?: ButtonProps['onKeyDown'];
};

const ClearButton: FC<ClearButtonProps> = ({ onClick, onKeyDown, title }) => (
    <Button
        title={title}
        icon={faTimes}
        onClick={onClick}
        size="xs"
        shape="circle"
        variant="text"
        onKeyDown={onKeyDown}
    />
);

type AddTaskInputProps = {
    view: TaskFilter;
    statuses: TaskStatus[];
    workspaceId?: Id;
    checklistId?: Id;
    templateId?: Id;
    userId?: Id;
};

export const AddTaskInput = ({ view, statuses, workspaceId, checklistId, templateId, userId }: AddTaskInputProps) => {
    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);

    const { currentUser } = useCurrentUserContext();
    const { open } = useModal();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { setSelectedTasks, setRecentlyCreatedTaskId } = useTasksContext();

    const { data: initialWorkspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
    const { data: checklist } = useGetChecklistQuery(checklistId, { skip: !checklistId });
    const { data: template } = useGetChecklistTemplateQuery(checklist?.checklist_template_id, {
        skip: !checklist?.checklist_template_id,
    });
    const { data: initialUser } = useGetUserQuery(userId, { skip: !userId });

    const [shouldWatch, setShouldWatch] = useState<boolean>(true);
    const [task, setTask, handleTaskChange] = useInputState(EmptyString);
    const [assignee, setAssignee] = useState<User>();
    const [workspace, setWorkspace] = useState<Workspace>(initialWorkspace);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const { addTasks, addSection, isAdding } = useAddTaskInput({
        templateId,
        checklistId,
        assigneeId: assignee?.id,
        isWatcher: shouldWatch,
        workspaceId: workspaceId || workspace?.id,
    });

    const isAddingTasks = useAddTaskInputStore((state) => state.isAddingTask);

    const textAreaRef = useRef<HTMLTextAreaElement>();
    const largeInputContainer = useRef<HTMLDivElement>();
    const userPickerPopover = useRef<HTMLDivElement>();
    const workspacePickerPopover = useRef<HTMLDivElement>();

    const isDisabled = checklistId == null && !statuses.includes('todo');
    const canCreateTask = !isEmpty(task.trim());
    const isAssigneePickerDisabled = view === 'me' || !!userId;

    const hideLargeTaskInput = () => {
        setIsFocused(false);
        useAddTaskInputStore.setState({ isAddingTask: false });
        setTask(EmptyString);
        textAreaRef?.current?.blur();
    };

    useEffect(() => {
        setAssignee(view === 'me' ? initialUser ?? currentUser : initialUser);
    }, [currentUser, view, workspaceId]);

    useEffect(() => setWorkspace(initialWorkspace), [initialWorkspace]);

    useEffect(() => {
        if (initialUser) {
            setAssignee(initialUser);
        }
    }, [initialUser]);

    useClickAway(largeInputContainer, (event) => {
        if (
            isEmpty(task.trim()) &&
            (!userPickerPopover?.current || !userPickerPopover?.current.contains(event.target as Node)) &&
            (!workspacePickerPopover?.current || !workspacePickerPopover?.current.contains(event.target as Node))
        ) {
            hideLargeTaskInput();
        }
    });

    const isAddSectionCommand = (task: string): boolean => task.trim().startsWith(':');

    const getSectionNameFromCommand = (sectionName: string): string => {
        const sectionKeyIndex = sectionName.indexOf(':');
        return sectionName.substring(sectionKeyIndex + 1).trim();
    };

    const getSectionBlocksFromClipboardData = (lineData: string[]): Array<SectionBlock> => {
        const sections: SectionBlock[] = [];

        for (const line of lineData) {
            if (isAddSectionCommand(line) && !isMyTasksPage) {
                sections.push({ name: getSectionNameFromCommand(line), tasks: [] });
            } else {
                if (sections.length > 0) {
                    const section = sections[sections.length - 1];
                    section.tasks.push({ name: line, status: null });
                } else {
                    sections.push({ tasks: [{ name: line, status: null }], name: EmptyString });
                }
            }
        }

        return sections;
    };

    const handlePasteTasks: React.ClipboardEventHandler<HTMLTextAreaElement> = async (event) => {
        const { clipboardData } = event;

        const lineData = clipboardData
            .getData('text')
            .split('\n')
            .map((row) => row.trim().replace(TEXT_WITHOUT_BULLETS_REGEX, ''))
            .filter(Boolean);
        const sections = getSectionBlocksFromClipboardData(lineData);
        if (sections.filter((s) => !isEmpty(s.name)).length === 0 && lineData.length === 1) {
            return;
        }

        open(BulkTasksAdditionConfirmModal, {
            templateId,
            checklistId,
            userId,
            sections,
            assignee,
            workspace,
            isWatcher: shouldWatch,
        });
        if (isEmpty(task.trim())) {
            hideLargeTaskInput();
        }
        event.preventDefault();
    };

    const handleAddSingleTask = async () => {
        if (isAddSectionCommand(task) && !isMyTasksPage) {
            const res = await addSection(task);
            if ('data' in res) {
                setSearchParams({ ...searchParams, order: 'section' });
            }
        } else {
            const response = await addTasks([{ name: task.trim() }], true);
            if ('data' in response && response.data.length === 1) {
                setSelectedTasks([{ id: response.data[0].id, groupedId: null }]);
                setRecentlyCreatedTaskId(response.data[0].id);
            }
        }

        setTask(EmptyString);
        textAreaRef.current.focus();
    };

    const handleShouldWatchToggle = () => setShouldWatch((current) => !current);

    const showLargeTaskInput = async () => {
        setIsFocused(true);
        useAddTaskInputStore.setState({ isAddingTask: true });
        await sleep(100);
        textAreaRef?.current?.focus();
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        onEsc(hideLargeTaskInput)(event);
        if (event.key === 'Enter') {
            event.preventDefault();
            if (canCreateTask) {
                void handleAddSingleTask();
            }
        }
    };

    return (
        <div
            className={clsx(
                '@container rounded-md border border-gray-300 bg-white p-1',
                isFocused && 'ring-2 ring-blue-500 ring-offset-2',
                searchParams.layout === 'kanban' && 'overflow-x-hidden'
            )}
            ref={largeInputContainer}
        >
            <Textarea
                borderless
                ref={textAreaRef}
                value={task}
                onChange={handleTaskChange}
                onKeyDownCapture={handleKeyDown}
                onPasteCapture={handlePasteTasks}
                placeholder={t`Type or paste your tasks here...`}
                rows={1}
                wrapperClassName="bg-white p-2"
                onFocus={showLargeTaskInput}
                onBlur={() => setIsFocused(false)}
            />

            <div
                className={clsx(
                    'flex max-w-full items-center justify-between overflow-hidden transition-all',
                    isAddingTasks ? 'mt-2 h-10 opacity-100' : 'h-0 opacity-0'
                )}
            >
                <div className={clsx('flex shrink gap-0 overflow-hidden', !isAddingTasks && 'invisible')}>
                    <Tooltip
                        wrapperClassName="max-w-full overflow-hidden shrink"
                        content={view === 'me' && t`You can't change assignee when the filter is set to My tasks`}
                        placement="bottom"
                        anchorRef={userPickerPopover}
                    >
                        <div className="overflow-hidden p-1">
                            <UserPicker
                                showNobody
                                ref={userPickerPopover}
                                contextTitle={checklistId ? t`Checklist members` : t`Workspace members`}
                                contextUsers={
                                    checklistId
                                        ? (template?.userGroup?.members || []).map((m) => m.user)
                                        : (workspace?.userGroup?.members || []).map((m) => m.user)
                                }
                                onUserSelected={setAssignee}
                                className={clsx(
                                    'max-w-full overflow-hidden',
                                    !assignee ? '@lg:!w-auto !w-[1.875rem] !px-0' : 'px-1'
                                )}
                                disabled={isAssigneePickerDisabled}
                                variant="filled"
                                color="default"
                                size="sm"
                                icon={assignee ? undefined : faUser}
                                iconClassName="mx-0 @lg:ml-1.5"
                            >
                                <>
                                    {!assignee && (
                                        <div className="@lg:block hidden truncate pr-1">
                                            <Trans>Select assignee</Trans>
                                        </div>
                                    )}

                                    {assignee && (
                                        <div className="flex max-w-full items-center gap-1">
                                            <UserAvatar
                                                user={assignee}
                                                size="xs"
                                                tooltipContent={!isAssigneePickerDisabled && t`Assign task to`}
                                            />
                                            <span className="@lg:block hidden truncate px-1">
                                                {assignee?.full_name}
                                            </span>
                                            {!isAssigneePickerDisabled && (
                                                <ClearButton
                                                    title={t`Remove assignee`}
                                                    onClick={() => setAssignee(undefined)}
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            </UserPicker>
                        </div>
                    </Tooltip>

                    <Tooltip
                        wrapperClassName="max-w-full overflow-hidden shrink"
                        content={!!initialWorkspace && t`You can't change workspace when you're inside a workspace`}
                        placement="bottom"
                    >
                        <div className="overflow-hidden p-1">
                            <WorkspacePicker
                                ref={workspacePickerPopover}
                                onWorkspaceSelect={setWorkspace}
                                disabled={!!initialWorkspace}
                                size="sm"
                                icon={workspace ? undefined : faBriefcase}
                                iconClassName="ml-0 @lg:ml-1.5"
                                className={clsx(
                                    'max-w-full focus:ring-blue-400',
                                    !workspace && '@lg:!px-2.5x` @lg:!w-auto !w-[1.875rem] !p-0'
                                )}
                            >
                                <>
                                    {!workspace && (
                                        <div className="@lg:block hidden truncate pr-1">
                                            <Trans>Select workspace</Trans>
                                        </div>
                                    )}

                                    {workspace && (
                                        <div className="flex max-w-full items-center justify-center gap-1">
                                            <WorkspaceIcon workspace={workspace} />
                                            <span className="@lg:block hidden truncate overflow-hidden pr-1">
                                                {workspace?.name}
                                            </span>
                                            {!initialWorkspace && (
                                                <ClearButton
                                                    title={t`Remove workspace`}
                                                    onClick={stopPropagation(
                                                        preventDefault(() => setWorkspace(undefined))
                                                    )}
                                                    onKeyDown={onEnter(
                                                        stopPropagation(preventDefault(() => setWorkspace(undefined)))
                                                    )}
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            </WorkspacePicker>
                        </div>
                    </Tooltip>

                    <Button
                        icon={shouldWatch ? faEye : faEyeSlash}
                        className={clsx('shrink-0 m-1', shouldWatch ? 'text-blue-400' : 'text-gray-500')}
                        onClick={handleShouldWatchToggle}
                        disabled={isDisabled}
                        size="sm"
                        title={shouldWatch ? t`Unwatch this task` : t`Watch this task`}
                    />
                </div>

                <div className="flex shrink-0 gap-1 pr-1">
                    <Button onClick={hideLargeTaskInput} size="sm">
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        icon={faPlus}
                        color="primary"
                        disabled={!canCreateTask}
                        onClick={handleAddSingleTask}
                        size="sm"
                        loading={isAdding}
                    >
                        <Trans>Create</Trans>
                    </Button>
                </div>
            </div>
        </div>
    );
};
