import React from 'react';
import { faEmptySet, faStickyNote } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';

export const networkNotificationHandler: NotificationHandlerFn = (notification) => {
    const createdBy = notification.created_by.full_name;

    switch (notification.event_key) {
        case 'commented_network':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> mentioned you in a note
                    </Trans>
                ),
            };
        case 'updated_comment_network':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> edited a note you were mentioned in
                    </Trans>
                ),
            };
        case 'deleted_comment_network':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> deleted a note you were mentioned in
                    </Trans>
                ),
            };
        default:
            return { icon: faEmptySet, text: <></> };
    }
};
