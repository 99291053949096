import React, { FC, useState } from 'react';
import { t } from '@lingui/macro';
import { Id } from '@wedo/types';
import { useTasksContext } from 'App/contexts/TasksContext';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { WorkspaceTagSelect } from 'Shared/components/workspace/WorkspaceTagSelect';

export const WorkspaceRow: FC<BulkEditTaskRowProps<Id[]>> = ({ value, onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonWorkspaceIds } = useBulkTasksEditPane(selectedTasks);

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(commonWorkspaceIds);
        } else if (change === 'edit') {
            onChange(commonWorkspaceIds);
        } else if (change === 'clear') {
            onChange([]);
        }
    };

    return (
        <BulkEditRow label={t`Workspaces`} changeOption={changeValue} onChangeOptionChange={handleChange} isResetHidden>
            <WorkspaceTagSelect
                canEdit
                value={value}
                onChange={onChange}
                inputClassName="mt-2"
                placeholder={t`Select workspaces`}
            />
        </BulkEditRow>
    );
};
