import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { t } from '@lingui/macro';
import { Checkbox, ManagedTable, Tooltip } from '@wedo/design-system';
import {
    DEPENDENCIES,
    LOCKED_RESTRICTIONS,
    PERMISSIONS,
    RESTRICTIONS,
} from 'Pages/meeting/components/EditMeetingAccessModal/Permissions';
import { MEETING_STATUSES as MS } from 'Pages/meeting/components/RBAC/MeetingStatuses';
import { MeetingRole } from 'Shared/types/meetingRole';
import { DEFAULT_MEETING_ROLES as DEFAULT_ROLES } from '../RBAC/DefaultMeetingRoles';

const isPermissionEnabled = (meetingRole, permission, status) => {
    return meetingRole.permissions[status]?.includes(permission.code) ?? false;
};

type RolePermissionItem = {
    code: string;
    name: ReactNode;
    description?: ReactNode;
    meetingRole: MeetingRole;
    preparation: boolean;
    agenda_ready: boolean;
    ongoing: boolean;
    finished: boolean;
    locked: boolean;
};

type Props = {
    meetingRole: MeetingRole;
    onUpdate: (MeetingRole, { attribute, value }: { attribute: string; value: any }) => void;
    handleAddMeetingRoleDone: (MeetingRole) => void;
};
export const EditRolePanel = ({ meetingRole, onUpdate }: Props): JSX.Element => {
    const handleUpdateRole = (role, status, remove) => {
        const permissionsByStatus = role.meetingRole.permissions[status];

        let newPermissionsByStatus;
        if (remove) {
            newPermissionsByStatus = permissionsByStatus.filter((p) => {
                return p !== role.code && !DEPENDENCIES[role.code].find((link) => link === p);
            });
        } else {
            newPermissionsByStatus = [...permissionsByStatus, role.code];
            Object.keys(DEPENDENCIES).map((key) => {
                const isLinked = DEPENDENCIES[key].find((i) => i === role.code);
                if (isLinked) {
                    newPermissionsByStatus = [...newPermissionsByStatus, key];
                }
            });
        }
        // Convert array to set to remove duplicates
        const newPermissionsSet = new Set(newPermissionsByStatus);

        onUpdate(role.meetingRole, {
            attribute: 'permissions',
            value: { ...role.meetingRole.permissions, [status]: [...newPermissionsSet] },
        });
    };

    const isPermissionDisabled = (role) => {
        if (Object.values(DEFAULT_ROLES).includes(role.meetingRole.code)) {
            return RESTRICTIONS[role.meetingRole.code].includes(role.code);
        } else {
            return RESTRICTIONS.customRole.includes(role.code);
        }
    };
    const isLockedPermissionDisabled = (role) => {
        if (Object.values(DEFAULT_ROLES).includes(role.meetingRole.code)) {
            return LOCKED_RESTRICTIONS[role.meetingRole.code].includes(role.code);
        } else {
            return LOCKED_RESTRICTIONS.customRole.includes(role.code);
        }
    };
    const columns = [
        {
            dataIndex: 'name',
            render: (role) => (
                <>
                    <span>{role.name}</span>
                    {role.description && (
                        <Tooltip content={role.description} placement={'top'}>
                            <FontAwesomeIcon icon={faInfoCircle} className={'ml-[5px] text-blue-600'} />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: t`Preparation`,
            dataIndex: 'preparation',
            align: 'center',
            render: (role) => (
                <Checkbox
                    checked={role.preparation}
                    disabled={isPermissionDisabled(role)}
                    onChange={(e) => handleUpdateRole(role, 'preparation', !e.target.checked)}
                />
            ),
        },
        {
            title: t`Validated`,
            dataIndex: 'agenda_ready',
            align: 'center',
            render: (role) => (
                <Checkbox
                    checked={role.agenda_ready}
                    disabled={isPermissionDisabled(role)}
                    onChange={(e) => handleUpdateRole(role, 'agenda_ready', !e.target.checked)}
                />
            ),
        },
        {
            title: t`Ongoing`,
            dataIndex: 'ongoing',
            align: 'center',
            render: (role) => (
                <Checkbox
                    checked={role.ongoing}
                    disabled={isPermissionDisabled(role)}
                    onChange={(e) => handleUpdateRole(role, 'ongoing', !e.target.checked)}
                />
            ),
        },
        {
            title: t`Review`,
            dataIndex: 'finished',
            align: 'center',
            render: (role) => (
                <Checkbox
                    checked={role.finished}
                    disabled={isPermissionDisabled(role)}
                    onChange={(e) => handleUpdateRole(role, 'finished', !e.target.checked)}
                />
            ),
        },
        {
            title: t`Locked`,
            dataIndex: 'locked',
            align: 'center',
            render: (role) => (
                <Checkbox
                    checked={role.locked}
                    disabled={isLockedPermissionDisabled(role)}
                    onChange={(e) => handleUpdateRole(role, 'locked', !e.target.checked)}
                />
            ),
        },
    ];

    const getPermissions = (): RolePermissionItem[] =>
        PERMISSIONS.map((permission) => {
            return {
                ...permission,
                meetingRole: meetingRole,
                [MS.PREPARATION]: isPermissionEnabled(meetingRole, permission, MS.PREPARATION),
                [MS.AGENDA_READY]: isPermissionEnabled(meetingRole, permission, MS.AGENDA_READY),
                [MS.ONGOING]: isPermissionEnabled(meetingRole, permission, MS.ONGOING),
                [MS.FINISHED]: isPermissionEnabled(meetingRole, permission, MS.FINISHED),
                [MS.LOCKED]: isPermissionEnabled(meetingRole, permission, MS.LOCKED),
            };
        });

    return (
        <ManagedTable
            tableProps={{ className: '!rounded-none' }}
            tableHeadProps={{ className: '!bg-transparent' }}
            data-testid={`panel-permissions-${meetingRole.code}`}
            data={getPermissions()}
            columns={columns}
            rowKey={(i) => i.code}
        />
    );
};
