import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { selectCurrentWorkspaceId } from 'Pages/meeting/MeetingViewSlice';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { useMoveMeetingMutation } from 'Shared/services/meeting';

export const MoveMeetingModal = ({ meetingId, ...modalProps }: { meetingId: Id } & ContextModalProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);

    const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);

    const [moveToWorkspaceId, setMoveToWorkspaceId] = useState<string>();

    const [moveMeeting, { isLoading }] = useMoveMeetingMutation();

    const handleConfirm = async () => {
        try {
            await moveMeeting({
                meetingId: meeting.id,
                workspaceId: moveToWorkspaceId,
            }).unwrap();
            await modalProps.close();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal size={'md'} {...modalProps}>
            <Modal.Header title={t`In which workspace do you want to move this meeting?`} />
            <Modal.Body>
                <WorkspaceSelect
                    value={moveToWorkspaceId}
                    onChange={(value: string) => setMoveToWorkspaceId(value === '-1' ? null : value)}
                    withoutCurrent={true}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button
                    disabled={moveToWorkspaceId == null && currentWorkspaceId == null}
                    key="save"
                    color={'primary'}
                    loading={isLoading}
                    onClick={handleConfirm}
                >
                    <Trans>Move</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
