import { colorPickerColors } from '~/components/ColorPicker/ColorPicker';
import { getRandomItem } from '@wedo/utils';

const hexToRGBArray = (colorHex: string): number[] => {
    let color = colorHex;

    if (color.length === 3) {
        color =
            color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    } else if (color.length !== 6) {
        throw new Error('Invalid hex color: ' + color);
    }
    const rgb = [];
    for (let i = 0; i <= 2; i++) {
        rgb[i] = parseInt(color.substring(i * 2, i * 2 + 2), 16);
    }
    return rgb;
};

const luma = (colorHex: string): number => {
    const color = colorHex.substring(1);
    const rgb = hexToRGBArray(color);
    return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
};

export const isDarkColor = (color: string): boolean => luma(color) < 165;

export const contrastingColor = (color: string): string => (!isDarkColor(color) ? '#000000' : '#ffffff');

export const getRandomWedoColor = () => getRandomItem(colorPickerColors)[1].value;

const colorHexMap = {
    '#64748b': 'gray',
    '#78716c': 'stone',
    '#ef4444': 'red',
    '#f97316': 'orange',
    '#f59e0b': 'amber',
    '#eab308': 'yellow',
    '#84cc16': 'lime',
    '#22c55e': 'green',
    '#10b981': 'emerald',
    '#14b8a6': 'teal',
    '#06b6d4': 'cyan',
    '#0ea5e9': 'sky',
    '#3b82f6': 'blue',
    '#6366f1': 'indigo',
    '#8b5cf6': 'violet',
    '#a855f7': 'purple',
    '#d946ef': 'fuchsia',
    '#ec4899': 'pink',
    // Angular colors
    '#e1e7ec': 'gray',
    '#76c3ff': 'blue',
    '#ed8b86': 'red',
    '#ffd8a3': 'orange',
    '#fde355': 'yellow',
    '#5eda95': 'green',
    '#c99fda': 'purple',
    '#fab6cd': 'pink',
    // Vote colors
    '#0072ce': 'blue',
    '#8895a7': 'gray',
    '#e75e58': 'red',
    '#4cd689': 'green',
    '#ffba5a': 'orange',
    '#f7d620': 'yellow',
    '#8c5508': 'orange',
    '#b57acc': 'purple',
    '#67bcff': 'blue',
    '#fbb6ce': 'pink',
    // This other blue
    '#2f94e7': 'blue',
} as const;

export type colorHex = keyof typeof colorHexMap;

export type colorId = (typeof colorHexMap)[colorHex];

export const getColorId = (colorHex: string, defaultValue = 'gray'): colorId => {
    return colorHexMap[colorHex?.toLocaleLowerCase()] ?? defaultValue;
};
