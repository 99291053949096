import {
    ClipboardEvent,
    DragEvent,
    FocusEvent as ReactFocusEvent,
    KeyboardEvent,
    MouseEvent,
    MutableRefObject,
    ReactNode,
    JSX,
    useCallback,
    useEffect,
    useId,
    useLayoutEffect,
    useMemo,
    useRef,
} from 'react';
import {
    Editable,
    ReactEditor,
    RenderElementProps,
    RenderLeafProps,
    Slate,
    useSlateStatic,
    withReact,
} from 'slate-react';
import { RenderPlaceholderProps } from 'slate-react/dist/components/editable';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    createEditor,
    Editor as SlateEditor,
    EditorFragmentDeletionOptions,
    Element,
    NodeEntry,
    Operation,
    Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { TextDeleteOptions } from 'slate/dist/interfaces/transforms/text';
import { TextUnit } from 'slate/dist/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray, preventDefault } from '@wedo/utils';
import { RumErrorBoundary } from 'Shared/components/RumErrorBoundary';
import { ErrorCardTags } from 'Shared/components/error/ErrorCard';
import { DefaultElement } from './components/DefaultElement';
import { DefaultLeaf } from './components/DefaultLeaf';
import { NoOpOperation } from './utils/operation';

type EditorStore = {
    focusedEditors: Record<string, boolean>;
};

const useEditorStore = create<EditorStore>()(
    immer(() => ({
        focusedEditors: {},
    }))
);

export const isEditorFocused = (editor: SlateEditor) => {
    return useEditorStore.getState().focusedEditors[editor.id] === true;
};

export const useEditorIsFocused = () => {
    const editor = useSlateStatic();
    const focusedEditors = useEditorStore((state) => state.focusedEditors);
    return focusedEditors[editor.id] === true;
};

export const focusEditor = (editor: SlateEditor) => {
    document.querySelector<HTMLInputElement>(`[data-slate-editor="true"][data-id="${editor.id}"]`)?.focus();
};

const inverseOperation = Operation.inverse;

Operation.inverse = (operation: Operation) => {
    if (operation.type === NoOpOperation) {
        return { type: NoOpOperation };
    }
    if (operation.type === 'remove_node' && ['attachment', 'image'].includes(operation.node?.type)) {
        return { type: NoOpOperation };
    }
    return inverseOperation(operation);
};

// FIXME This is a terrible hack while https://github.com/ianstormtaylor/slate/issues/5435 is not fixed
const createRange = window.document?.createRange;
if (window.document) {
    window.document.createRange = () => {
        const range = createRange.apply(window.document);
        const setEnd = range.setEnd;
        range.setEnd = (node: Node, offset: number) => {
            try {
                setEnd.apply(range, [node, offset]);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn('Slate would have crashed!');
            }
        };
        return range;
    };
}

export type Plugin = {
    apply?: (editor: SlateEditor, operation: Operation) => boolean;
    initialize?: (editor: SlateEditor) => (() => void) | void;
    isVoid?: (editor: SlateEditor, element: Element) => boolean;
    isInline?: (editor: SlateEditor, element: Element) => boolean;
    insertText?: (editor: SlateEditor, text: string) => boolean;
    insertData?: (editor: SlateEditor, data: DataTransfer) => boolean;
    insertBreak?: (editor: SlateEditor) => boolean;
    insertSoftBreak?: (editor: SlateEditor) => boolean;
    deleteText?: (editor: SlateEditor, options: TextDeleteOptions) => boolean;
    deleteForward?: (editor: SlateEditor, unit: TextUnit) => boolean;
    deleteBackward?: (editor: SlateEditor, unit: TextUnit) => boolean;
    deleteFragment?: (editor: SlateEditor, options: EditorFragmentDeletionOptions) => Promise<boolean>;
    normalizeNode?: (editor: SlateEditor, entry: NodeEntry) => boolean;
    isSelectable?: (editor: SlateEditor, element: Element) => boolean;
    setFragmentData?: (editor: SlateEditor, data: DataTransfer, originEvent?: 'drag' | 'copy' | 'cut') => boolean;
    onKeyDown?: (editor: SlateEditor, event: KeyboardEvent<HTMLDivElement>) => boolean;
    onBlur?: (editor: SlateEditor, event: ReactFocusEvent<HTMLDivElement>) => boolean;
    onBeforeInput?: (editor: SlateEditor, event: InputEvent) => boolean;
    onPaste?: (editor: SlateEditor, event: ClipboardEvent<HTMLDivElement>) => boolean;
    onDrop?: (editor: SlateEditor, event: DragEvent<HTMLDivElement>) => boolean;
    onOutsideClick?: (editor: SlateEditor, event: MouseEvent<HTMLDivElement>) => boolean;
    renderElement?: (editor: SlateEditor, props: RenderElementProps, previousElement: JSX.Element) => JSX.Element;
    renderLeaf?: (editor: SlateEditor, props: RenderLeafProps) => JSX.Element;
    render?: (editor: SlateEditor) => ReactNode;
    renderAfter?: (editor: SlateEditor) => ReactNode;
    renderWrapper?: (editor: SlateEditor, content: ReactNode) => ReactNode;
    onChange?: (editor: SlateEditor, children: Element[]) => boolean | Promise<boolean>;
};

type EditorProps = {
    placeholder?: string;
    className?: string;
    plugins?: Plugin[];
    hasAutoFocus?: boolean;
    editorRef?: MutableRefObject<SlateEditor>;
    dataProps?: Record<string, string | Id | boolean | number>;
    isReadOnly?: boolean;
};

type PluggedEditorProps = {
    editor: SlateEditor;
    wrapperRef: MutableRefObject<HTMLDivElement>;
} & EditorProps;

const PluggedEditor = ({
    editor,
    placeholder,
    className,
    plugins,
    hasAutoFocus,
    dataProps = {},
    isReadOnly,
    wrapperRef,
}: PluggedEditorProps): JSX.Element => {
    const id = useId();

    editor.id = id;
    editor.isReadOnly = isReadOnly;

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        for (const plugin of plugins) {
            if (plugin.onKeyDown?.(editor, event) === true) {
                return;
            }
        }
    };

    const handleBlur = (event: ReactFocusEvent<HTMLDivElement>) => {
        for (const plugin of plugins) {
            if (plugin.onBlur?.(editor, event) === true) {
                return;
            }
        }
        // TODO discuss with Victor to find a solution with the color picker in the toolbar
        // ReactEditor.deselect(editor);
    };

    const handleBeforeInput = (event: InputEvent) => {
        for (const plugin of plugins) {
            if (plugin.onBeforeInput?.(editor, event) === true) {
                return;
            }
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
        for (const plugin of plugins) {
            if (plugin.onPaste?.(editor, event)) {
                return;
            }
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        for (const plugin of plugins) {
            if (plugin.onDrop?.(editor, event)) {
                return;
            }
        }
    };

    const renderElement = (props: RenderElementProps) => {
        let previousElement = null;
        for (const plugin of plugins) {
            const element = plugin.renderElement?.(editor, props, previousElement);
            if (element) {
                previousElement = element;
            }
        }
        return previousElement != null ? previousElement : <DefaultElement {...props} />;
    };

    const renderLeaf = (props: RenderLeafProps) => {
        for (const plugin of plugins) {
            const leaf = plugin.renderLeaf?.(editor, props);
            if (leaf) {
                return leaf;
            }
        }
        return <DefaultLeaf {...props} />;
    };

    const renderPlaceholder = ({ children, attributes: { style, ...attributes } }: RenderPlaceholderProps) => {
        return (
            <div className="pointer-events-none absolute select-none text-gray-500" {...attributes}>
                {children}
            </div>
        );
    };

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        const lastBlock = editor.children[editor.children.length - 1];
        if (lastBlock != null && event.target.hasAttribute('data-slate-editor')) {
            const boundingRect = ReactEditor.toDOMNode(editor, lastBlock)?.getBoundingClientRect();
            if (event.pageY > (boundingRect?.bottom ?? 0)) {
                for (const plugin of plugins) {
                    if (plugin.onOutsideClick?.(editor, event) === true) {
                        return;
                    }
                }
            }
        }
    };

    useLayoutEffect(() => {
        const handleFocus = ({ target }: FocusEvent) => {
            if (target === wrapperRef.current?.querySelector('[data-slate-editor="true"]')) {
                useEditorStore.setState((state) => {
                    state.focusedEditors[id] = true;
                });
            }
        };
        const handleBlur = ({ target }: FocusEvent) => {
            if (target === wrapperRef.current?.querySelector('[data-slate-editor="true"]')) {
                useEditorStore.setState((state) => {
                    state.focusedEditors[id] = false;
                });
            }
        };
        document.addEventListener('focusin', handleFocus);
        document.addEventListener('focusout', handleBlur);
        return () => {
            useEditorStore.setState((state) => {
                delete state.focusedEditors[id];
            });
            document.removeEventListener('focusin', handleFocus);
            document.removeEventListener('focusout', handleBlur);
        };
    }, []);

    useLayoutEffect(() => {
        if (hasAutoFocus) {
            requestAnimationFrame(() => {
                Transforms.select(editor, SlateEditor.end(editor, []));
                focusEditor(editor);
            });
        }
    }, [hasAutoFocus]);

    return plugins.reduce(
        (content, plugin) => {
            const wrapper = plugin.renderWrapper?.(editor, content);
            return wrapper != null ? wrapper : content;
        },
        <Editable
            data-id={id}
            placeholder={placeholder}
            className={clsx('group/editor flex h-full flex-1 flex-col gap-2 py-3 text-sm outline-none', className)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onDOMBeforeInput={handleBeforeInput}
            onPaste={handlePaste}
            onDrop={handleDrop}
            onDragStart={preventDefault()}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            renderPlaceholder={renderPlaceholder}
            onClick={handleClick}
            {...dataProps}
            readOnly={isReadOnly}
        />
    );
};

// The order the plugins are declared is important! The more priority a plugin has, the sooner it must be declared
// Plugins which handle key events are very sensible to this priority for example
export const Editor = ({
    placeholder,
    className,
    plugins = EmptyArray as Plugin[],
    hasAutoFocus = false,
    editorRef,
    dataProps,
    isReadOnly,
}: EditorProps) => {
    const wrapperRef = useRef<HTMLDivElement>();

    const { editor, editorElement, pluginElements, pluginAfterElements } = useMemo(() => {
        const editor = withReact(withHistory(createEditor()));

        if (editorRef != null) {
            editorRef.current = editor;
        }

        const {
            apply,
            isVoid,
            isInline,
            insertText,
            insertData,
            insertBreak,
            insertSoftBreak,
            delete: deleteText,
            deleteForward,
            deleteBackward,
            deleteFragment,
            normalizeNode,
            isSelectable,
        } = editor;

        editor.apply = (operation) => {
            for (const plugin of plugins) {
                if (plugin.apply?.(editor, operation) === true) {
                    return;
                }
            }
            apply(operation);
        };

        editor.isVoid = (element) => {
            for (const plugin of plugins) {
                if (plugin.isVoid?.(editor, element) === true) {
                    return true;
                }
            }
            return isVoid(element);
        };

        editor.isInline = (element) => {
            for (const plugin of plugins) {
                if (plugin.isInline?.(editor, element) === true) {
                    return true;
                }
            }
            return isInline(element);
        };

        editor.insertText = (text) => {
            for (const plugin of plugins) {
                if (plugin.insertText?.(editor, text) === true) {
                    return;
                }
            }
            insertText(text);
        };

        editor.insertData = (data) => {
            for (const plugin of plugins) {
                if (plugin.insertData?.(editor, data) === true) {
                    return;
                }
            }
            insertData(data);
        };

        editor.insertBreak = () => {
            for (const plugin of plugins) {
                if (plugin.insertBreak?.(editor) === true) {
                    return;
                }
            }
            insertBreak();
        };

        editor.insertSoftBreak = () => {
            for (const plugin of plugins) {
                if (plugin.insertSoftBreak?.(editor) === true) {
                    return;
                }
            }
            insertSoftBreak();
        };

        editor.delete = (options) => {
            for (const plugin of plugins) {
                if (plugin.deleteText?.(editor, options) === true) {
                    return;
                }
            }
            deleteText(options);
        };

        editor.deleteForward = (unit) => {
            for (const plugin of plugins) {
                if (plugin.deleteForward?.(editor, unit) === true) {
                    return;
                }
            }
            deleteForward(unit);
        };

        editor.deleteBackward = (unit) => {
            for (const plugin of plugins) {
                if (plugin.deleteBackward?.(editor, unit) === true) {
                    return;
                }
            }
            deleteBackward(unit);
        };

        editor.deleteFragment = async (options) => {
            for (const plugin of plugins) {
                if ((await plugin.deleteFragment?.(editor, options)) === true) {
                    return;
                }
            }
            deleteFragment(options);
        };

        editor.normalizeNode = (entry) => {
            for (const plugin of plugins) {
                if (plugin.normalizeNode?.(editor, entry) === true) {
                    return;
                }
            }
            normalizeNode(entry);
        };

        editor.isSelectable = (element) => {
            for (const plugin of plugins) {
                if (plugin.isSelectable?.(editor, element) === false) {
                    return false;
                }
            }
            return isSelectable(element);
        };

        editor.setFragmentData = (data: DataTransfer, originEvent?: 'drag' | 'copy' | 'cut') => {
            for (const plugin of plugins) {
                if (plugin.setFragmentData?.(editor, data, originEvent) === true) {
                    return;
                }
            }
        };

        const editorElement = (
            <PluggedEditor
                editor={editor}
                plugins={plugins}
                hasAutoFocus={hasAutoFocus}
                placeholder={placeholder}
                className={className}
                dataProps={dataProps}
                isReadOnly={isReadOnly}
                wrapperRef={wrapperRef}
            />
        );

        const pluginElements = plugins.map((plugin) => plugin.render?.(editor));

        const pluginAfterElements = plugins.map((plugin) => plugin.renderAfter?.(editor));

        return { editor, editorElement, pluginElements, pluginAfterElements };
    }, [plugins, hasAutoFocus, placeholder, className, dataProps, isReadOnly]);

    const handleChange = useCallback(
        async (children: Element[]) => {
            for (const plugin of plugins) {
                const onChange = plugin.onChange?.(editor, children);
                if (
                    onChange != null &&
                    (typeof onChange === 'boolean' ? onChange === true : (await onChange) === true)
                ) {
                    return;
                }
            }
        },
        [editor, plugins]
    );

    const handleWrapperRef = (element: HTMLDivElement) => {
        wrapperRef.current = element;
        if (element != null) {
            // The CSS selector below allow us to select all the sibling elements before the editor
            const pluginElementsHeight = Array.from(
                element.querySelectorAll(
                    ':scope > *:not([data-slate-editor="true"] ~ *):not([data-slate-editor="true"])'
                )
            ).reduce((height, child) => height + child.getBoundingClientRect().height, 0);
            element.style.setProperty('--top', `${pluginElementsHeight}px`);
        }
    };

    // Plugins are initialized in a useEffect to make sure the editor had a chance to render first
    useEffect(() => {
        const destroyFunctions = plugins.map((plugin) => plugin.initialize?.(editor));
        return () => {
            for (const destroyFunction of destroyFunctions) {
                if (typeof destroyFunction === 'function') {
                    destroyFunction();
                }
            }
        };
    }, [editor, plugins]);

    return (
        <Slate editor={editor} initialValue={EmptyArray} onChange={handleChange}>
            <RumErrorBoundary
                fallback={({ error, resetError }) => {
                    return (
                        <div className="flex max-w-5xl flex-col gap-4 rounded-md bg-white p-3 text-center">
                            <div className="flex justify-center">
                                <img src="/assets/error.svg" alt="" className="h-40" />
                            </div>
                            <p className="text-2xl font-semibold text-blue-600 md:text-2xl">
                                <Trans>The editor has crashed</Trans>
                            </p>
                            <p className="text-lg font-medium text-gray-500 md:text-xl">
                                <Trans>We automatically track errors, but don't hesitate to contact support.</Trans>
                            </p>
                            <pre className="mt-4 overflow-auto max-h-64 whitespace-pre rounded-md bg-gray-100 p-4 text-left font-mono text-sm">
                                {error.toString()}
                            </pre>

                            <ErrorCardTags />
                            <div className="flex gap-4">
                                <Button
                                    onClick={() => {
                                        Transforms.deselect(editor);
                                        resetError();
                                    }}
                                    color={'gray'}
                                    className="w-full"
                                >
                                    <Trans>Reload</Trans>
                                </Button>
                            </div>
                        </div>
                    );
                }}
            >
                <div ref={handleWrapperRef} className="relative w-full flex flex-col">
                    {pluginElements}
                    {editorElement}
                    {pluginAfterElements}
                </div>
            </RumErrorBoundary>
        </Slate>
    );
};
