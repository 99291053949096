import { FC, MouseEvent } from 'react';
import { faEllipsisVertical, faGear, faShare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { type ButtonPosition, Dropdown, type Size, useConfirm, useModal } from '@wedo/design-system';
import { DeleteTeamModal } from 'Pages/settings/teams/components/DeleteTeamModal';
import { SettingsModal } from 'Shared/components/team/SettingsModal/SettingsModal';
import { TeamFavoriteToggle } from 'Shared/components/team/TeamFavoriteToggle';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useRestoreDeletedTeamMutation } from 'Shared/services/team';
import { Team } from 'Shared/types/team';

type TeamDropdownProps = {
    team: Team;
    position?: ButtonPosition;
    size?: Size;
};

export const TeamDropdown: FC<TeamDropdownProps> = ({ team, position = 'none', size = 'md' }) => {
    const { open } = useModal();
    const { confirm } = useConfirm();
    const { isCurrentUserModerator } = useManageMembers(team);

    const [restoreTeam] = useRestoreDeletedTeamMutation();

    const handleRestoreTeam = () => {
        void confirm({
            type: 'primary',
            title: t`Restore ${team?.name}`,
            content: t`Do you want to restore the team ${team?.name}?`,
            onConfirm: () => restoreTeam(team),
        });
    };

    if (!team) {
        return <Dropdown icon={faEllipsisVertical} size={size} position={position} />;
    }

    if (team?.deleted) {
        return (
            <Dropdown
                icon={faEllipsisVertical}
                size={size}
                position={position}
                onClick={(event: MouseEvent) => event.stopPropagation()}
                aria-label={t`Team menu`}
            >
                <Dropdown.Item
                    icon={faShare}
                    onClick={(event: MouseEvent) => {
                        handleRestoreTeam();
                        event.stopPropagation();
                    }}
                >
                    <Trans>Restore team</Trans>
                </Dropdown.Item>
            </Dropdown>
        );
    }

    return (
        <Dropdown
            icon={faEllipsisVertical}
            size={size}
            position={position}
            onClick={(event: MouseEvent) => event.stopPropagation()}
            aria-label={t`Team menu`}
        >
            <Dropdown.Item
                icon={faGear}
                onClick={(evt: MouseEvent) => {
                    open(SettingsModal, { teamId: team.id });
                    evt.stopPropagation();
                }}
            >
                <Trans>Settings</Trans>
            </Dropdown.Item>

            <TeamFavoriteToggle.DropdownItem team={team} />

            {isCurrentUserModerator && <Dropdown.DividerItem />}

            {isCurrentUserModerator && (
                <Dropdown.Item
                    danger
                    icon={faTrash}
                    onClick={(event: MouseEvent) => {
                        open(DeleteTeamModal, { team });
                        event.stopPropagation();
                    }}
                >
                    <Trans>Delete team</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
