import { Descendant, Editor, Element, NodeEntry } from 'slate';
import { is } from './node';

export const isIn = (editor: Editor, type: Element['type'], f: (above: NodeEntry<Element>) => boolean) => {
    const above = Editor.above(editor, { match: is(type) });
    return above != null ? f(above as NodeEntry<Element>) !== false : false;
};

export const areBlocksEmpty = (blocks: Descendant[]) =>
    blocks == null || blocks.length === 0 || blocks.every((child) => child.type === 'hidden');

export const isEditorEmpty = (editor: Editor) => areBlocksEmpty(editor.children);
