import React from 'react';
import { Trans } from '@lingui/macro';
import { Spinner } from '@wedo/design-system';

export const LoadingRedirectPage = () => {
    return (
        <div className="flex min-h-screen flex-col items-center justify-center gap-10">
            <Spinner className="h-40 w-40" color="blue" />
            <div className="font-medium text-gray-600">
                <Trans>Redirecting you in to WEDO...</Trans>
            </div>
        </div>
    );
};
