import {
    ClipboardEventHandler,
    forwardRef,
    KeyboardEventHandler,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { CodeInputCell } from '~/components/CodeInput/CodeInputCell';
import { EmptyFunction } from '@wedo/utils';

type CodeInputProps = {
    /** id to give to the first input */
    id?: string;
    /** Label that describes the code (aria-label added on each input) */
    label?: string;
    /** Whether to focus on the first case by default */
    autofocus?: boolean;
    /** Number of characters in the code. This property is overridden by "codeRegex" if it is not null.  */
    length?: number;
    /** Placeholder to show; one letter per case. */
    placeholder?: string;
    /** Initial value of the code. */
    initialValue?: string;
    /** Function to modify the user's input as they type.
     * @param value The character being typed
     * @param index The index of the character in the code (starting at 0) */
    characterModifier?: (value: string, index: number) => string;
    /** Regex to check user's inputs, character per character. This property is overridden by "codeRegex" if it is not null. */
    characterRegex?: RegExp;
    /** Array of regexes to check the user's inputs; one regex per character. The length of the array indicates the length of the code. This property overrides "length" and "characterRegex". */
    codeRegex?: Array<RegExp>;
    /** Disable the inputs, but prefill can still be used. */
    disabled?: boolean;
    /** Event emitted when any character changes */
    onChange?: (code: string) => void;
    /** Event emitted once the code is complete. */
    onComplete?: (code: string) => void;
    /** Event emitted when the code was complete and becomes incomplete. */
    onIncomplete?: () => void;
    /** Event emitted when the user validates the codes (clicks on ENTER). */
    onValidate?: (code: string) => void;
    status?: 'default' | 'error' | 'success';
};

export const CodeInput = forwardRef<HTMLInputElement, CodeInputProps>(
    (
        {
            id = null,
            label = null,
            autofocus = false,
            length = 6,
            characterRegex = /^[0-9]?$/,
            codeRegex = null,
            characterModifier = (value) => value,
            disabled = false,
            placeholder = '',
            initialValue = '',
            onComplete = EmptyFunction,
            onIncomplete = EmptyFunction,
            onValidate = EmptyFunction,
            onChange = EmptyFunction,
            status = 'default',
        },
        ref
    ) => {
        const [code, setCode] = useState([]);
        const [isCodeComplete, setIsCodeComplete] = useState(false);
        const referenceList = useRef<HTMLInputElement[]>([]);
        const [oldFormatLength, setOldFormatLength] = useState(0);
        const [oldPrefill, setOldPrefill] = useState('');

        const codeFormat = codeRegex ?? new Array(length).fill(characterRegex);

        useImperativeHandle(ref, () => ({
            ...referenceList?.current?.[0],
            focus: () => referenceList?.current?.[0]?.focus(),
        }));

        const transformCharacter = (character, index) => {
            if (character === '' || index >= codeFormat.length || !codeFormat[index].test(character)) {
                return null;
            }

            return characterModifier(character, index);
        };

        const transformCode = (code) => {
            return code.split('').slice(0, codeFormat.length).map(transformCharacter);
        };

        if (oldFormatLength !== codeFormat.length) {
            setOldFormatLength(codeFormat.length);
            setCode(new Array(codeFormat.length).fill(null));
        }

        const handleCodeChange = (newCode: string) => {
            setCode(transformCode(newCode));

            const indexAfterPaste = newCode.length === codeFormat.length ? newCode.length - 1 : newCode.length;
            setTimeout(() => referenceList.current[indexAfterPaste].focus());
        };

        const handleMove = (index) => {
            if (index < 0 || index >= codeFormat.length) {
                return;
            }

            referenceList.current[index].focus();
            requestAnimationFrame(() => referenceList.current[index].select());
        };

        const handleCharacterChange = (text: string, index: number) => {
            if (text.length > 1) {
                if (index > 0) {
                    return;
                }

                handleCodeChange(text);
                return;
            }

            const newCode = structuredClone(code);
            const character = transformCharacter(text, index);
            newCode[index] = character;

            setCode(newCode);

            if (character !== null) {
                handleMove(index + 1);
            }
        };

        const handlePaste: ClipboardEventHandler<HTMLDivElement> = (e) => {
            const pastedCode = e.clipboardData.getData('text');
            handleCodeChange(pastedCode);
        };

        const handleEnterKey: KeyboardEventHandler<HTMLDivElement> = (e) => {
            if (e.key === 'Enter') {
                onValidate(code.join(''));
            }
        };

        if (initialValue !== oldPrefill) {
            setOldPrefill(initialValue);
            const newCode = transformCode(initialValue);

            setCode(newCode);
        }

        // Here "useEffect" is necessary to avoid that the parent component re-renders before this one is rendered.
        useEffect(() => {
            onChange(code.join(''));

            if (code.length === codeFormat.length && code.every((value) => value !== null)) {
                setIsCodeComplete(true);
                onComplete(code.join(''));
            } else if (isCodeComplete) {
                setIsCodeComplete(false);
                onIncomplete();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [code]);

        return (
            <div onKeyDown={handleEnterKey} onPasteCapture={handlePaste} className="flex gap-2">
                {codeFormat.map((regex, index) => (
                    <CodeInputCell
                        status={status}
                        ref={(e) => (referenceList.current[index] = e)}
                        id={index === 0 ? id : null}
                        index={index}
                        label={label}
                        autofocus={index === 0 ? autofocus : false}
                        key={index}
                        placeholder={placeholder[index] ?? ''}
                        disabled={disabled}
                        regex={regex}
                        value={code[index]}
                        onChange={(value) => handleCharacterChange(value, index)}
                        onPrevious={() => handleMove(index - 1)}
                        onNext={() => handleMove(index + 1)}
                    />
                ))}
            </div>
        );
    }
);
