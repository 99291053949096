import React, { FC } from 'react';
import { FeedItemCommentedTask } from 'Pages/activityFeed/FeedItem/FeedItemCommentedTask';
import { FeedItemCompletedTask } from 'Pages/activityFeed/FeedItem/FeedItemCompletedTask';
import { FeedItemCreatedWorkspace } from 'Pages/activityFeed/FeedItem/FeedItemCreatedWorkspace';
import { FeedItemDecision } from 'Pages/activityFeed/FeedItem/FeedItemDecision';
import { FeedItemNote } from 'Pages/activityFeed/FeedItem/FeedItemNote';
import { FeedItemWorkspaceStatus } from 'Pages/activityFeed/FeedItem/FeedItemWorkspaceStatus';
import { ActivityLog } from 'Shared/types/activityLog';
import { FeedItemCreatedTemplate } from 'Pages/activityFeed/FeedItem/FeedItemCreatedTemplate';
import { FeedItemCreatedChecklist } from 'Pages/activityFeed/FeedItem/FeedItemCreatedChecklist';

export const ActivityFeedItem: FC<{ activityFeed: ActivityLog }> = ({ activityFeed }) => {
    switch (activityFeed?.event_key) {
        case 'decision':
            return <FeedItemDecision activityFeed={activityFeed} />;
        case 'note':
            return <FeedItemNote activityFeed={activityFeed} />;
        case 'completed_task':
            return <FeedItemCompletedTask activityFeed={activityFeed} />;
        case 'commented_task':
            return <FeedItemCommentedTask activityFeed={activityFeed} />;
        case 'added_tag_status':
            return <FeedItemWorkspaceStatus activityFeed={activityFeed} />;
        case 'created_workspace':
            return <FeedItemCreatedWorkspace activityFeed={activityFeed} />;
        case 'created_template':
            return <FeedItemCreatedTemplate activityFeed={activityFeed} />;
        case 'created_checklist':
            return <FeedItemCreatedChecklist activityFeed={activityFeed} />;
        default:
            return <span />;
    }
};
