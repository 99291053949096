import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Label, RadioGroup, Select, Skeleton } from '@wedo/design-system';
import { PlanType } from '@wedo/types';
import { canSwitchCurrency, CountriesWithSwissFranc } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { useSubscriptionAddressStore } from 'Pages/subscription/hooks/useSubscriptionAddressStore';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import {
    getValidLicenseOptions,
    SUBSCRIPTION_ADDON_STEP_URL,
    SUBSCRIPTION_ADDRESS_STEP_URL,
    SUBSCRIPTION_CONFIRMATION_STEP_URL,
} from 'Pages/subscription/utils';
import 'Pages/subscription/utils';
import { FrequencySelectorRadioGroup } from 'Shared/components/billing/FrequencySelectorRadioGroup';
import { getFrequencyFromSubscription, getPlanType } from 'Shared/utils/chargebee';
import { getNumberOfUsers } from 'Shared/utils/organization';

export const NumberOfUsersSelector: FC<{
    value: number;
    onChange: (value: number) => void;
    label: string;
    placeholder?: string;
    licenses: number[];
}> = ({ value, onChange, label, licenses }) => {
    return (
        <div className="mt-8 flex items-center justify-between">
            <Label>{label}</Label>
            <div className="w-32">
                <Select value={String(value)} onChange={(v: string) => onChange(Number(v))}>
                    {licenses.map((license) => (
                        <Select.Option key={license} value={String(license)}>
                            {license}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

type PlanButtonProps = {
    planType: PlanType;
    isDisabled?: boolean;
};

const PlanButton: FC<PlanButtonProps> = ({ planType, isDisabled = false }) => {
    const { plans, isLoadingPlans } = useSubscriptionPage();
    const {
        planType: selectedPlanType,
        currency,
        actions: { setPlanType },
    } = useSubscriptionStore();
    const amountInCents =
        planType === 'pro'
            ? plans
                  ?.find((plan) => plan.id === 'pro-subscription')
                  ?.prices.find((price) => price.currencyCode === currency && price.periodUnit === 'month')?.price
            : planType === 'enterprise'
              ? plans
                    ?.find((plan) => plan.id === 'enterprise-subscription')
                    ?.prices.find((price) => price.currencyCode === currency && price.periodUnit === 'month')?.price
              : 0;

    const amount = Math.trunc(amountInCents / 100);
    const cents = Math.round(amountInCents % 100);

    const features = planType === 'pro' ? [t`All features`, t`Live chat support`] : [t`SAML`, t`SCIM`];
    const icon = planType === 'pro' ? faCheck : faPlus;

    const isSelected = planType === selectedPlanType;

    return (
        <Button
            disabled={isDisabled}
            variant="ghost"
            className={clsx(
                'overflow-hidden border border-gray-200 bg-white shadow-md',
                isSelected ? 'opacity-100' : 'opacity-30'
            )}
            onClick={() => setPlanType(planType)}
        >
            <div className="flex flex-col flex-wrap">
                <div
                    className={clsx(
                        'p-4 text-2xl text-white',
                        planType === 'pro' ? 'bg-gradient-blue' : 'bg-gradient-neon'
                    )}
                >
                    {isDisabled
                        ? t`Not available`
                        : planType.substring(0, 1).toUpperCase() + planType.substring(1).toLowerCase()}
                </div>

                <div className="my-4 flex items-center justify-center gap-2">
                    <span className="text-xl text-gray-700">{currency}</span>
                    {isLoadingPlans ? (
                        <Skeleton />
                    ) : (
                        <div className="flex gap-1 text-gray-700">
                            <span className="text-5xl">{amount}</span>
                            <span className="text-2xl">{cents}</span>
                        </div>
                    )}
                    <div className="text-md flex flex-col items-start text-gray-600">
                        <span>
                            <Trans>per user</Trans>
                        </span>
                        <span>
                            <Trans>per month</Trans>
                        </span>
                    </div>
                </div>

                <div className="mb-4 mt-6 flex flex-col items-start gap-2 px-4">
                    {features.map((feature) => (
                        <div key={feature} className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon={icon}
                                className="text-md h-3 w-3 rounded-full bg-green-400 p-1 text-white"
                            />
                            <span>{feature}</span>
                        </div>
                    ))}
                </div>
            </div>
        </Button>
    );
};

export const ChoosePlanForm = () => {
    const navigate = useNavigate();

    const { hasExistingSubscription, noChangeInPlan, userId, subscription, organization, isLoadingEstimate } =
        useSubscriptionPage();
    const { actions, ...address } = useSubscriptionAddressStore();
    const {
        userLicenses,
        frequency,
        currency,
        actions: { setFrequency, setUserLicenses, setCurrency },
    } = useSubscriptionStore();

    const nbUsers = getNumberOfUsers(organization);
    const validLicenseNumbers = useMemo(() => getValidLicenseOptions(nbUsers), [nbUsers]);

    const handlePrevious = () => {
        if (hasExistingSubscription) {
            navigate('/settings/billing');
        } else {
            navigate({ pathname: SUBSCRIPTION_ADDRESS_STEP_URL, searchParams: { 'user-id': userId } });
        }
    };

    const handleNext = () => {
        if (hasExistingSubscription) {
            navigate({ pathname: SUBSCRIPTION_CONFIRMATION_STEP_URL, searchParams: { 'user-id': userId } });
        } else {
            navigate({ pathname: SUBSCRIPTION_ADDON_STEP_URL, searchParams: { 'user-id': userId } });
        }
    };

    return (
        <div className={'@container'}>
            <div className="mb-8 flex items-center justify-between">
                <h1 className="text-3xl">
                    <Trans>Choose your plan</Trans>
                </h1>
                <div className="flex items-center gap-2">
                    {address.country != null && canSwitchCurrency(address.country) && (
                        <RadioGroup
                            value={currency}
                            name={'currency'}
                            onChange={setCurrency}
                            radioType="buttonGroup"
                            className="mt-1"
                        >
                            <RadioGroup.Radio
                                disabled={hasExistingSubscription && subscription.currency_code !== 'EUR'}
                                value={'EUR'}
                            >
                                EUR
                            </RadioGroup.Radio>
                            <RadioGroup.Radio
                                disabled={hasExistingSubscription && subscription.currency_code !== 'USD'}
                                value={'USD'}
                            >
                                USD
                            </RadioGroup.Radio>
                        </RadioGroup>
                    )}
                    {address.country != null && !CountriesWithSwissFranc.has(address.country) && (
                        <>
                            <FrequencySelectorRadioGroup
                                value={frequency}
                                onChange={setFrequency}
                                isOptionDisabled={(value) => {
                                    return (
                                        hasExistingSubscription &&
                                        getFrequencyFromSubscription(subscription) === 'yearly' &&
                                        value === 'monthly'
                                    );
                                }}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="@lg:grid-cols-2 grid gap-6">
                <PlanButton
                    planType="pro"
                    isDisabled={hasExistingSubscription && getPlanType(subscription) === 'enterprise'}
                />
                <PlanButton planType="enterprise" />
            </div>

            <NumberOfUsersSelector
                label={t`Please select the number of licenses`}
                value={userLicenses}
                onChange={setUserLicenses}
                licenses={validLicenseNumbers}
            />

            <div className="mt-6 flex flex-wrap justify-between gap-2">
                <Button className={'@md:w-auto w-full'} icon={faChevronLeft} onClick={handlePrevious}>
                    <Trans>Previous</Trans>
                </Button>
                <Button
                    icon={faChevronRight}
                    iconPosition="end"
                    color="primary"
                    onClick={handleNext}
                    className={'@md:w-auto w-full'}
                    disabled={noChangeInPlan || isLoadingEstimate}
                >
                    <Trans>Next</Trans>
                </Button>
            </div>
        </div>
    );
};
