import React, { FC } from 'react';
import { faAlignLeft, faPen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Card, EmptyState, useModal } from '@wedo/design-system';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { WorkspaceSettingsModal } from 'Shared/components/workspace/WorkspaceSettingsModal/WorkspaceSettingsModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { Workspace } from 'Shared/types/workspace';

export const WorkspaceDescriptionCard: FC<{ workspace: Workspace }> = ({ workspace }) => {
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(workspace);

    return (
        <Card>
            <Card.Header
                title={<Trans>Description</Trans>}
                actions={
                    isCurrentUserModerator && (
                        <Button
                            icon={faPen}
                            size="sm"
                            onClick={() => open(WorkspaceSettingsModal, { workspaceId: workspace?.id })}
                        >
                            <Trans>Edit</Trans>
                        </Button>
                    )
                }
            />
            <Card.Body>
                {isEmpty(workspace?.description) ? (
                    <div className="flex justify-center">
                        <EmptyState icon={faAlignLeft}>
                            <EmptyState.Text>
                                <Trans>No description for {workspace?.name} workspace</Trans>
                            </EmptyState.Text>
                            {isCurrentUserModerator && (
                                <Button onClick={() => open(WorkspaceSettingsModal, { workspaceId: workspace?.id })}>
                                    <Trans>Add description</Trans>
                                </Button>
                            )}
                        </EmptyState>
                    </div>
                ) : (
                    <DescriptionEditor html={workspace?.description} isReadOnly={true} />
                )}
            </Card.Body>
        </Card>
    );
};
