import React, { FC, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    EditImageModal,
    EditImageModalProps,
    getCroppedImgBlob,
    PixelCrop,
    UnexpectedErrorNotification,
    UploadButton,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { fileToImage, fileTypeToImageExtension } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useDeleteUserPictureMutation, useGetUserQuery, useUpdateUserPictureMutation } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';
import { User } from 'Shared/types/user';

const isCurrentUserSet = (currentUser: User | undefined | null) => {
    return currentUser !== undefined && currentUser !== null && Object.keys(currentUser).length > 1;
};

export const ManageUserPicture: FC<{ userId?: Id }> = ({ userId = null }) => {
    const { currentUser } = useCurrentUserContext();
    const { data: otherUser } = useGetUserQuery(userId, { skip: !userId });
    const [updatePicture, { isLoading: isUploadLoading }] = useUpdateUserPictureMutation();
    const [deletePicture, { isLoading: isDeleteLoading }] = useDeleteUserPictureMutation();
    const { open } = useModal();
    const { confirm: showConfirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const user = userId !== null ? otherUser : currentUser;

    const { initials, color, photo_url } = useMemo(() => {
        if (isCurrentUserSet(user)) {
            const {
                initials,
                color: { background: color },
                photo_url,
            } = user;
            return { initials, color, photo_url };
        }

        return { initials: null, color: null, photo_url: null };
    }, [user]);

    const isLoading = isUploadLoading || isDeleteLoading;

    const uploadSVG = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await updatePicture({ file: formData, userId: userId !== currentUser.id ? userId : null });
        if ('error' in response) {
            showNotification(UnexpectedErrorNotification);
        }
    };
    const uploadImage = async (image, file) => {
        const formData = new FormData();
        formData.append(
            'file',
            new File([image], `user_photo.${fileTypeToImageExtension(file.type)}`, { type: file.type })
        );

        const response = await updatePicture({ file: formData, userId: userId !== currentUser.id ? userId : null });
        if ('error' in response) {
            showNotification(UnexpectedErrorNotification);
        } else {
            void trpcUtils().user.list.invalidate();
        }
    };

    const editImageModalProps = (file: File): EditImageModalProps => {
        return {
            image: file,
            aspect: 1,
            hasCircularCrop: true,
            onEdit: async (crop: PixelCrop) => {
                const image = await getCroppedImgBlob(file, crop);
                await uploadImage(image, file);
            },
        };
    };

    const handleDeletePicture = async () => {
        const res = await deletePicture(currentUser.id !== userId ? userId : null);
        if ('error' in res) {
            showNotification(UnexpectedErrorNotification);
        } else {
            void trpcUtils().user.list.invalidate();
        }
    };

    return (
        <div className="grid grid-cols-[auto_auto_1fr] grid-rows-[auto_auto_1fr] items-center gap-4">
            <div className="row-span-3 h-32 w-32 rounded-full border border-gray-300 p-2">
                {photo_url === null || photo_url === '' ? (
                    <div
                        style={{ backgroundColor: color }}
                        className={`flex h-full w-full items-center rounded-full text-center text-4xl font-bold`}
                    >
                        <span className="grow">{initials}</span>
                    </div>
                ) : (
                    <img className="max-h-full max-w-full rounded-full" alt={t`Profile`} src={photo_url} />
                )}
            </div>

            <UploadButton
                accept="image/*"
                onFileSelect={async (file) => {
                    try {
                        await fileToImage(file);
                    } catch {
                        void showConfirm({
                            type: 'danger',
                            title: t`Invalid image format`,
                            content: t`Please check the image extension and format, and try once again`,
                            isCancelButtonVisible: false,
                            confirmText: t`Close`,
                        });
                        return;
                    }
                    if (file.type === 'image/svg+xml') {
                        await uploadSVG(file);
                        return;
                    }
                    open(EditImageModal, editImageModalProps(file));
                }}
                color={'primary'}
                loading={isUploadLoading}
                className="md:min-w-[200px]"
            >
                {photo_url === '' || photo_url === null || photo_url === undefined ? (
                    <Trans>Choose a photo</Trans>
                ) : (
                    <Trans>Replace photo</Trans>
                )}
            </UploadButton>
            <div />
            <Button
                className="md:min-w-[200px]"
                loading={isDeleteLoading}
                onClick={handleDeletePicture}
                disabled={isLoading || photo_url === '' || photo_url === null}
                color="danger"
                variant="outlined"
            >
                <Trans>Delete photo</Trans>
            </Button>

            <div className="col-span-2 self-end text-xs text-gray-700">
                <Trans>Maximum file size 5MB, minimum resolution 200x200.</Trans>
            </div>
        </div>
    );
};
