import { create } from 'zustand';

type SidebarState = {
    isCollapsed: boolean;
    isMobileCollapsed: boolean;
    isFloatingDrawerOpen: boolean;
    setIsCollapsed: (value: boolean) => void;
    setIsMobileCollapsed: (value: boolean) => void;
    setIsFloatingDrawerOpen: (value: boolean) => void;
};

export const useSidebarStore = create<SidebarState>((set, get) => ({
    isCollapsed: false,
    isMobileCollapsed: true,
    isFloatingDrawerOpen: false,
    setIsCollapsed: (isCollapsed: boolean) => isCollapsed !== get().isCollapsed && set({ isCollapsed }),
    setIsMobileCollapsed: (isMobileCollapsed: boolean) =>
        isMobileCollapsed !== get().isMobileCollapsed && set({ isMobileCollapsed }),
    setIsFloatingDrawerOpen: (isFloatingDrawerOpen: boolean) =>
        isFloatingDrawerOpen !== get().isFloatingDrawerOpen && set({ isFloatingDrawerOpen }),
}));
