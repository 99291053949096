import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faArrowRight, faPen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { TimeLineItem } from 'Shared/components/history/TimeLineItem';
import { UserFullName } from 'Shared/components/history/UserFullName';

type EntityNameProps = { name: string };

const EntityName: FC<EntityNameProps> = ({ name }) => <span className="italic">{name}</span>;

export const ChangedNameTimeLineItem: FC<TimeLineItem> = ({ activity, entityType }) => {
    if (entityType === 'workspace') {
        return (
            <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faPen}>
                <p>
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> changed the workspace name from{' '}
                        <EntityName name={activity.old_value.name} /> <FontAwesomeIcon icon={faArrowRight} />{' '}
                        <EntityName name={activity.new_value.name} />
                    </Trans>
                </p>
            </Timeline.Item>
        );
    }

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faPen}>
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> changed the template name from{' '}
                    <EntityName name={activity.old_value.name} /> <FontAwesomeIcon icon={faArrowRight} />{' '}
                    <EntityName name={activity.new_value.name} />
                </Trans>
            </p>
        </Timeline.Item>
    );
};
