import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@wedo/design-system';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TaskDetail } from 'Shared/components/task/TaskDetail/TaskDetail';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetTaskQuery } from 'Shared/services/task';

export const TaskPage = () => {
    const { taskId } = useParams();
    const { data: task } = useGetTaskQuery({ id: taskId });
    const { setSelectedTasks } = useTasksContext();

    usePageTitle(task?.name);

    useEffect(() => {
        setSelectedTasks([{ id: taskId, groupedId: null }]);
    }, []);

    return (
        <div className="flex h-full w-full justify-center overflow-auto sm:mt-5 sm:h-auto sm:px-10">
            <Card className="mx-auto my-1 block h-full !w-full max-w-full overflow-auto shadow-md sm:h-auto sm:w-auto sm:max-w-[900px]">
                <TaskDetail.Header hideTasksToExport taskId={taskId} />
                <Card.Body className="!px-0">
                    <TaskDetail taskId={taskId} />
                </Card.Body>
            </Card>
        </div>
    );
};
