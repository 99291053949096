import { useContext, useState } from 'react';
import { storage } from '@wedo/utils';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useGlobalLogin, useLogin } from 'Pages/SignInPage/hooks/useLogin';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useGlobalLoginTotpMutation, useLoginTotpMutation } from 'Shared/services/auth';
import { LocalStorage } from 'Shared/types/localStorage';

type TotpAuthentication = {
    error: string;
    isLoading: boolean;
    isSuccess: boolean;
    authenticate: () => Promise<void>;
};

export const useTotpAuthentication = () => {
    const {
        loginState: { email, password, rememberMe, trustedDevice, totp },
    } = useContext(LoginContext);
    const [localLogin, { isLoading: isNetworkLoading }] = useLoginTotpMutation();
    const [globalLogin, { isLoading: isGlobalLoading }] = useGlobalLoginTotpMutation();
    const [error, setError] = useState('');
    const { isGlobal } = useCurrentNetwork();
    const loginSuccess = useLogin();
    const handleGlobalLogin = useGlobalLogin();

    const isLoading = isNetworkLoading || isGlobalLoading;

    const authenticate: TotpAuthentication['authenticate'] = async () => {
        const login = isGlobal ? globalLogin : localLogin;

        const result = await login({
            email,
            password,
            rememberMe,
            trustedDevice,
            code: totp,
        });
        if ('error' in result) {
            setError(result.error.message);
            return;
        }

        if (trustedDevice) {
            storage.setItem(LocalStorage.DeviceToken, result.data.authToken);
        } else {
            storage.removeItem(LocalStorage.DeviceToken);
        }

        if (isGlobal) {
            await handleGlobalLogin(result.data);
        } else {
            await loginSuccess();
        }
    };

    return { error, authenticate, isLoading };
};
