import { Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const YoutubeIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Path
                d="M10.3662 147.377C12.6614 112.042 41.0705 84.3494 76.4369 82.5973C127.65 80.06 200.848 77 256 77C311.152 77 384.35 80.06 435.563 82.5973C470.93 84.3494 499.339 112.042 501.634 147.377C503.833 181.231 506 222.887 506 255.571C506 288.256 503.833 329.912 501.634 363.766C499.339 399.101 470.93 426.793 435.563 428.546C384.35 431.083 311.152 434.143 256 434.143C200.848 434.143 127.65 431.083 76.4369 428.546C41.0705 426.793 12.6614 399.101 10.3662 363.766C8.16721 329.912 6 288.256 6 255.571C6 222.887 8.16721 181.231 10.3662 147.377Z"
                fill="#FC0D1B"
            />
            <Path d="M202.429 184.143V327L345.286 255.571L202.429 184.143Z" fill="white" />
        </Svg>
    );
};
