import { MeetingBlock, VoteOptionType } from '~/meeting/types';

export const numberToPercentage = (n: number) => {
    const percent = n * 100;
    if (percent % 1 === 0) {
        return `${percent}%`;
    }

    return `${percent.toFixed(2)}%`;
};

const getTotalVotedShares = (
    vote: Extract<MeetingBlock, { type: 'vote' }>,
    excludeDiscussions = false,
    excludeAbstained = false
): number => {
    let answers = vote.vote?.voteAnswers;
    if (excludeDiscussions) {
        answers = answers.filter((a) => a.status !== 'requested_discussion');
    }
    if (excludeAbstained) {
        answers = answers.filter((a) => a.status !== 'abstained');
    }

    if (vote.vote.voteAnswers.some((answers) => answers.shares > 0)) {
        return answers.reduce((prev, cur) => prev + cur.shares, 0);
    }

    return (
        vote.vote?.voteOptions.reduce((prev, cur) => prev + cur.vote_count, 0) +
        (!excludeAbstained ? vote.vote?.abstained_count || 0 : 0)
    );
};

const getOptionCount = ({
    voteOption,
    vote,
}: {
    voteOption?: VoteOptionType;
    vote: Extract<MeetingBlock, { type: 'vote' }>;
}): number => {
    const totalShares = vote.vote.voteAnswers.reduce((prev, cur) => prev + cur.shares, 0);
    let count = 0;
    if (totalShares > 0) {
        if (voteOption.id === 'abstained') {
            vote.vote.voteAnswers?.forEach((answer) => {
                if (answer.status === 'abstained') {
                    count += answer.shares;
                }
            });
        }
        if (voteOption.id === 'requested_discussion') {
            vote.vote.voteAnswers?.forEach((answer) => {
                if (answer.status === 'requested_discussion') {
                    count += answer.shares;
                }
            });
        }
        vote.vote.voteAnswers?.forEach((answer) => {
            if (answer.vote_option_id === voteOption.id) {
                count += answer.shares;
            }
        });
    } else {
        if (vote.vote.can_abstain && voteOption.id === 'abstained') {
            return vote.vote?.abstained_count;
        }
        count = voteOption.vote_count;
    }
    return count;
};

export const getVoteOptionRelativeShareFraction = (
    option: VoteOptionType,
    vote: Extract<MeetingBlock, { type: 'vote' }>
): number => {
    const totalVotedShares = getTotalVotedShares(vote);
    const currentOptionShares = getOptionCount({ voteOption: option, vote });
    if (currentOptionShares > 0 && totalVotedShares > 0) {
        return currentOptionShares / totalVotedShares;
    }
    return 0;
};
