import { Placement } from '@floating-ui/react';
import clsx from 'clsx';
import { Tooltip } from '~/components/Tooltip/Tooltip';

const classes = {
    base: 'overflow-hidden rounded-full',
    bar: 'rounded-full text-xs text-white font-bold relative',
    percentText: {
        base: 'absolute -translate-y-1/2 top-1/2 ',
        regularValue: 'left-1/2 -translate-x-1/2',
        smallValue: 'text-gray-700 translate-x-full -right-1',
    },
    size: {
        default: 'h-5',
        thin: 'h-2',
    },
    colors: {
        blue: 'bg-gradient-blue',
        green: 'bg-gradient-green',
        orange: 'bg-gradient-orange',
        indigo: 'bg-gradient-indigo',
        red: 'bg-gradient-red',
        gray: 'bg-gradient-gray',
        royal: 'bg-gradient-royal',
        pink: 'bg-gradient-pink',
        purple: 'bg-gradient-purple',
        neon: 'bg-gradient-neon',
        summer: 'bg-gradient-summer',
        lollipop: 'bg-gradient-lollipop',
        custom: '',
    } as const,
};

export interface ProgressBarProps {
    percent: number;
    className?: string;
    placement?: Placement;
    color?: keyof typeof classes.colors | string;
    size?: 'default' | 'thin';
    showPercent?: boolean;
    borderless?: boolean;
}

export const ProgressBar = ({
    percent,
    color = 'blue',
    size = 'default',
    placement = 'bottom',
    showPercent = true,
    className = '',
    borderless,
}: ProgressBarProps): JSX.Element => {
    const formattedPercent = Math.round(percent);

    return (
        <Tooltip content={showPercent && size === 'thin' ? `${formattedPercent}%` : null} placement={placement}>
            <div
                className={clsx(
                    classes.base,
                    color !== 'custom' && size === 'thin' && borderless ? 'bg-gray-200' : 'bg-gray-100',
                    !borderless && 'border border-gray-200',
                    className
                )}
            >
                <div
                    className={clsx(
                        classes.bar,
                        classes.size[size],
                        classes.colors[color] ? classes.colors[color] : color,
                        'transition-all duration-300'
                    )}
                    style={{
                        width: `${formattedPercent}%`,
                    }}
                >
                    {showPercent && size === 'default' && (
                        <span
                            className={clsx(
                                classes.percentText.base,
                                percent <= 15 ? classes.percentText.smallValue : classes.percentText.regularValue
                            )}
                        >{`${formattedPercent}%`}</span>
                    )}
                </div>
            </div>
        </Tooltip>
    );
};

ProgressBar.displayName = 'ProgressBars.ProgressBar';
ProgressBar.ProgressBar = ProgressBar;
