import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { selectCurrentWorkspaceId } from 'Pages/meeting/MeetingViewSlice';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { AttachmentItem } from 'Shared/components/file/AttachmentItem/AttachmentItem';
import { DroppableFile } from 'Shared/components/file/DroppableFile';
import { useTask } from 'Shared/hooks/useTask';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Attachment } from 'Shared/types/attachment';
import { CustomFieldValue } from 'Shared/types/customField';

type TaskDetailCustomFieldDroppableAttachmentListProps = {
    taskId: Id;
    onAddAttachments: (attachments: Attachment[]) => Promise<void>;
    onRemoveAttachment: (attachment: Attachment, index?: number) => Promise<void>;
    attachments: Attachment[];
    customFieldValues: CustomFieldValue[];
    addLabel?: string;
};

export const TaskDetailCustomFieldDroppableAttachmentList = ({
    taskId,
    onAddAttachments,
    onRemoveAttachment,
    attachments,
    addLabel,
    customFieldValues,
}: TaskDetailCustomFieldDroppableAttachmentListProps) => {
    const meetingWorkspaceId = useSelector(selectCurrentWorkspaceId);

    const { workspaceId: taskWorkspaceId } = useParams<TasksPageParams>();
    const { isTaskReadonly } = useTask(taskId);
    const { open: openModal } = useModal();
    const { data: currentWorkspace } = useGetWorkspaceQuery(taskWorkspaceId ?? meetingWorkspaceId);

    const handleRemoveClick = async (attachment) => {
        await onRemoveAttachment(attachment);
    };

    const handleDropFile = async (files: FileList) => {
        openModal(AddAttachmentModal, {
            files: [...files],
            taskId,
            workspaceId: currentWorkspace?.id,
            onDone: onAddAttachments,
            allowedSources: ['upload', 'url'],
        });
    };

    return (
        <DroppableFile onDrop={handleDropFile} iconOnly className={'!justify-start'}>
            <div className={'grid-wrap grid w-full grid-cols-2 gap-2'}>
                {customFieldValues.map((value) =>
                    value.attachments.map((attachment) => (
                        <AttachmentItem
                            key={attachment.id}
                            attachment={attachment}
                            relation={{ custom_field_value_id: value.id }}
                            workspaceId={currentWorkspace?.id}
                            attachmentList={attachments.filter((a) => a?.open_url || a?.preview_url)}
                            onDelete={() => handleRemoveClick(attachment)}
                            isReadonly={isTaskReadonly}
                        />
                    ))
                )}

                {(!isTaskReadonly || attachments.length === 0) && (
                    <div>
                        <Button
                            className={'!max-w-full'}
                            disabled={isTaskReadonly}
                            variant={attachments.length > 0 ? 'filled' : 'text'}
                            icon={attachments.length > 0 && faPlus}
                            size={attachments.length > 0 ? 'sm' : 'md'}
                            title={attachments.length > 0 && (addLabel || t`Join attachment`)}
                            onClick={() =>
                                openModal(AddAttachmentModal, {
                                    taskId,
                                    workspaceId: currentWorkspace?.id,
                                    onDone: onAddAttachments,
                                })
                            }
                        >
                            {attachments.length === 0 ? (
                                <div className={'truncate'}>{addLabel || t`Join attachment`}</div>
                            ) : undefined}
                        </Button>
                    </div>
                )}
            </div>
        </DroppableFile>
    );
};
