import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { Dropdown } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { MatchedTabs } from 'Shared/components/layout/NavBar/MatchedTabs';
import { isNavBarButtonType, isNavBarDividerType, isNavBarLinkType } from 'Shared/components/layout/NavBar/utils';
import { useTranslateTabs } from 'Shared/components/layout/useTranslateTabs';
import { useResponsiveItems } from 'Shared/hooks/useResponsiveItems';
import { NavBarButton } from './NavBarButton';
import { NavBarDivider } from './NavBarDivider';
import { NavBarLink } from './NavBarLink';
import { NavBarDivider as NavBarDividerType, NavBarTab, TranslatedNavBarLink, TranslatedNavBarTab } from './types';

type NavBarProps = {
    tabs?: Array<NavBarTab>;
    basePath?: string;
    children?: ReactNode;
};

export const NavBar: FC<NavBarProps> = ({ tabs: initialTabs = [], basePath, children }) => {
    const tabs = useTranslateTabs(initialTabs) as Array<TranslatedNavBarTab>;
    const dropdownRef = useRef<HTMLDivElement>();
    const linkTabs = tabs?.filter((tab) => isNavBarLinkType(tab)) as TranslatedNavBarLink[];

    const rightSideRef = useRef<HTMLDivElement>();
    const { width: rightSideWidth } = useElementSize(rightSideRef);

    const [selectedTab, setSelectedTab] = useState<TranslatedNavBarTab>();
    const { width: dropdownWidth } = useElementSize(dropdownRef);

    const { visibleItems, hiddenItems, outerRef, innerRef } = useResponsiveItems({
        items: tabs.filter((tab) => !tab.hidden),
        dropdownWidth,
        staticWidth: rightSideWidth,
    });

    const [visibleTabs, hiddenTabs] = useMemo(() => {
        if (tabs?.length === 1) {
            const firstTab = tabs[0];
            return firstTab.hidden ? [[], tabs] : [tabs, []];
        }
        return [
            visibleItems.length <= 1 ? [] : visibleItems,
            (visibleItems.length === 1 ? visibleItems.concat(hiddenItems) : hiddenItems).concat(
                tabs.filter((tab) => tab.hidden)
            ),
        ];
    }, [visibleItems, hiddenItems, tabs]);

    const selectedTabLabel = useMemo(() => {
        if (selectedTab?.title === undefined) {
            return EmptyString;
        }
        return selectedTab.title;
    }, [selectedTab]);

    const selectedTabInDropdown = hiddenTabs?.some((hiddenTab) => isEqual(hiddenTab?.title, selectedTab?.title));

    const handleSetInnerRef = (element: HTMLDivElement) => {
        innerRef.current = element;
        if (innerRef.current != null && outerRef.current != null && outerRef.current.style.height === '') {
            outerRef.current.style.height = `${innerRef.current.offsetHeight}px`;
        }
    };

    return (
        <>
            <MatchedTabs tabs={linkTabs} prefix={basePath} onMatchedTabChange={setSelectedTab} />
            <div ref={outerRef} className="relative flex w-full border-b border-gray-300 pr-1">
                <div ref={handleSetInnerRef} className="absolute flex w-full items-end py-1.5">
                    {visibleTabs.map((tab, index) => {
                        if (isNavBarLinkType(tab)) {
                            return <NavBarLink {...tab} key={index} prefix={basePath} />;
                        }
                        if (isNavBarButtonType(tab)) {
                            return (
                                <NavBarButton
                                    title={tab.title}
                                    icon={tab.icon}
                                    onClick={tab.onClick}
                                    key={`nav-button-${index}`}
                                />
                            );
                        }
                        if (isNavBarDividerType(tab)) {
                            return (
                                <NavBarDivider content={(tab as NavBarDividerType).content} key={`divider-${index}`} />
                            );
                        }
                        return <></>;
                    })}

                    <div className="flex flex-col items-center" ref={dropdownRef}>
                        <Dropdown
                            variant="text"
                            icon={faChevronDown}
                            className={clsx(
                                'truncate !text-gray-600',
                                hiddenTabs.length === 0 && 'invisible',
                                hiddenTabs.includes(selectedTab) && 'w-fit'
                            )}
                            style={{
                                '::after:width': `${dropdownWidth}px`,
                            }}
                            data-testid="navbar-dropdown-button"
                            iconPosition={'end'}
                            label={
                                selectedTabInDropdown ? (
                                    <span className={'truncate'}>{selectedTabLabel}</span>
                                ) : undefined
                            }
                        >
                            <>
                                {hiddenTabs.map((tab, index) => {
                                    if (isNavBarLinkType(tab)) {
                                        return (
                                            <Dropdown.LinkItem
                                                {...tab}
                                                selected={isEqual(selectedTab, tab)}
                                                key={`dropdown-link-${index}`}
                                                prefix={basePath}
                                                hidden={false}
                                            >
                                                {tab.title}
                                            </Dropdown.LinkItem>
                                        );
                                    }
                                    if (isNavBarButtonType(tab)) {
                                        return (
                                            <Dropdown.Item
                                                onClick={tab.onClick}
                                                icon={tab.icon}
                                                key={`dropdown-button-${index}`}
                                            >
                                                {tab.title}
                                            </Dropdown.Item>
                                        );
                                    }
                                    if (isNavBarDividerType(tab)) {
                                        return (
                                            <Dropdown.DividerItem key={`divider-${index}`}>
                                                {tab.content}
                                            </Dropdown.DividerItem>
                                        );
                                    }
                                    return null;
                                })}
                            </>
                        </Dropdown>
                        {selectedTabInDropdown && (
                            <div
                                className="absolute bottom-0 h-0.5 bg-gray-500"
                                style={{
                                    width: `${dropdownWidth}px`,
                                }}
                            />
                        )}
                    </div>
                    {children && (
                        <>
                            <div className="flex-1" />
                            <div ref={rightSideRef} className="flex shrink-0 items-end gap-1">
                                {children}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
