import { useState } from 'react';
import { t } from '@lingui/macro';
import { useChangePasswordMutation } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

type PasswordChangeReturn = {
    error: string;
    change: (password: string, passwordNew: string) => Promise<void>;
    isSuccess: boolean;
    isLoading: boolean;
};

export const usePasswordChange = (): PasswordChangeReturn => {
    const [error, setError] = useState('');
    const [changePassword, { isSuccess, isLoading }] = useChangePasswordMutation();

    const change = async (passwordOld: string, passwordNew: string): Promise<void> => {
        setError('');
        const result = await changePassword({ passwordOld, passwordNew });

        if (!('error' in result)) {
            return;
        }

        const originalError = errorMessage(result.error);
        let errorTranslated = t`Unexpected error`;

        if (originalError === 'Invalid credentials.') {
            errorTranslated = t`Invalid password`;
        } else if (originalError === 'TypeError: Failed to fetch') {
            errorTranslated = t`Network error`;
        }

        setError(errorTranslated);
    };

    return {
        error,
        change,
        isSuccess,
        isLoading,
    };
};
