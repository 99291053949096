import React from 'react';
import { BasicCustomFieldModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/BasicCustomFieldModalForm';
import { CustomFieldModalSectionProps } from 'Pages/settings/customFields/types/customFieldModalSectionProps';
import { AttachmentCustomField, CustomFieldType } from 'Shared/types/customField';

export const AttachmentModalForm: React.FC<CustomFieldModalSectionProps<AttachmentCustomField>> = ({
    onSuccess,
    onCancel,
    field,
    groupId,
}) => {
    return (
        <BasicCustomFieldModalForm
            onSuccess={onSuccess}
            onCancel={onCancel}
            fieldType={CustomFieldType.Attachment}
            field={field}
            groupId={groupId}
        />
    );
};
