import { SortDirection } from '@wedo/design-system';
import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import store from 'App/store';
import { SortColumn } from 'Pages/WorkspacesPage/types';
import { CacheTag } from 'Shared/services/cacheTag';
import { currentNetworkCustomFieldGroups } from 'Shared/services/customFields';
import { currentNetworkTeamsTag, teamTag } from 'Shared/services/team';
import { trpcUtils } from 'Shared/trpc';
import { UserMeetingPermission } from 'Shared/types/meeting';
import { Section } from 'Shared/types/section';
import { Workspace, WorkspaceHistory, WorkspaceWatcher } from 'Shared/types/workspace';
import { baseApi, configureTag } from './base';

export const { tagType: workspaceTagType, tag: workspaceTag, tags: workspaceTags } = configureTag(CacheTag.WORKSPACE);

export const currentNetworkWorkspacesTag = workspaceTag('all');
export const workspacesOutsideTeamsTag = workspaceTag('no-team-workspaces');

export const workspaceApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [workspaceTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getWorkspaces: build.query<
                Workspace[],
                {
                    limit?: number;
                    offset?: number;
                    filter?: string;
                    status?: 'deleted' | 'archived' | 'open';
                    sortColumn?: SortColumn;
                    sortDirection?: SortDirection;
                    teamId?: Id;
                    expand?: boolean;
                    name?: string;
                }
            >({
                query: ({
                    limit,
                    offset,
                    filter = 'all',
                    status = 'open',
                    sortColumn = 'tag.name',
                    sortDirection = 'ascending',
                    teamId,
                    expand = true,
                    name = '',
                } = {}) => ({
                    url: 'tags',
                    params: {
                        ...(sortColumn === 'progression' && { expand: true }),
                        related: [],
                        deleted: status === 'deleted' ? true : status === 'open' ? false : undefined,
                        archived: status === 'archived' ? true : status === 'open' ? false : undefined,
                        limit,
                        offset,
                        orderBy: sortColumn,
                        orderDirection: sortDirection === 'descending' ? 'DESC' : 'ASC',
                        filter: filter,
                        team_id: teamId,
                        expand,
                        name,
                    },
                }),
                transformResponse: ({
                    data,
                }: {
                    data: Array<{ meta: Workspace['meta']; tag: Omit<Workspace, 'meta'> }>;
                }) => {
                    return data?.map(({ tag, meta }) => ({ ...tag, meta }));
                },
                transformErrorResponse: (error) => {
                    throw error;
                },
                providesTags: (result) => [
                    ...workspaceTags(result),
                    currentNetworkWorkspacesTag,
                    ...result.map(({ team_id }) => teamTag(team_id)),
                ],
            }),

            getWorkspace: build.query<Workspace, Id>({
                query: (id) => ({ url: `tags/${id}` }),
                transformResponse: (response: { tag: Workspace; meta: Workspace['meta'] }) => {
                    return { ...response?.tag, meta: response?.meta };
                },
                providesTags: (result) => [
                    workspaceTag(result?.id),
                    teamTag(result?.team_id),
                    currentNetworkWorkspacesTag,
                ],
            }),

            updateWorkspace: build.mutation<Workspace, Partial<Workspace> & { id: Id }>({
                query: (workspace) => ({
                    url: `tags/${workspace.id}`,
                    body: workspace,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result) => {
                    void trpcUtils().workspace.list.invalidate();
                    return [workspaceTag(result?.id), teamTag(result?.team_id), currentNetworkWorkspacesTag];
                },
            }),

            addWorkspace: build.mutation<Workspace, { teamId: Id; workspace?: Partial<Workspace> }>({
                query: ({ teamId, workspace }) => ({
                    url: 'tags',
                    method: HttpMethod.Post,
                    body: { ...workspace, team_id: teamId },
                }),
                invalidatesTags: () => {
                    void trpcUtils().workspace.list.invalidate();
                    return [currentNetworkWorkspacesTag, currentNetworkTeamsTag];
                },
            }),

            updateWorkspaceLogo: build.mutation<string, { form: FormData; workspaceId: Id }>({
                query: ({ form, workspaceId }) => ({
                    url: `tags/${workspaceId}/logo`,
                    body: form,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { workspaceId }) => [
                    workspaceTag(workspaceId),
                    currentNetworkWorkspacesTag,
                ],
            }),

            deleteWorkspaceLogo: build.mutation<Workspace, { workspaceId: Id }>({
                query: ({ workspaceId }) => ({
                    url: `tags/${workspaceId}/logo`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result) => [workspaceTag(result?.id), currentNetworkWorkspacesTag],
            }),

            activateWorkspaceCustomFieldGroup: build.mutation<Workspace, { workspaceId: Id; groupId: Id }>({
                query: ({ workspaceId, groupId }) => ({
                    url: `tags/${workspaceId}/custom-field-groups`,
                    method: HttpMethod.Post,
                    body: { custom_field_group_id: groupId },
                }),
                invalidatesTags: (result) => [
                    workspaceTag(result?.id),
                    currentNetworkWorkspacesTag,
                    currentNetworkCustomFieldGroups,
                ],
            }),

            deActivateWorkspaceCustomFieldGroup: build.mutation<Workspace, { workspaceId: Id; groupId: Id }>({
                query: ({ workspaceId, groupId }) => ({
                    url: `tags/${workspaceId}/custom-field-groups/${groupId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result) => [
                    workspaceTag(result?.id),
                    currentNetworkWorkspacesTag,
                    currentNetworkCustomFieldGroups,
                ],
            }),

            getWorkspaceSections: build.query<Array<Section>, Id>({
                query: (workspaceId) => `tags/${workspaceId}/sections`,
                providesTags: (result, meta, workspaceId) => [currentNetworkWorkspacesTag, workspaceTag(workspaceId)],
            }),

            addSection: build.mutation<Section, { workspaceId: Id; name: string; order?: number; color?: string }>({
                query: ({ workspaceId, name, order, color }) => ({
                    url: `tags/${workspaceId}/sections`,
                    method: HttpMethod.Post,
                    body: { name, order, color },
                }),
                invalidatesTags: (result, error, { workspaceId }) => {
                    invalidateCachedTasks();
                    return [workspaceTag(workspaceId), currentNetworkWorkspacesTag];
                },
            }),

            deleteSection: build.mutation<Section, { workspaceId: Id; sectionId: Id }>({
                query: ({ workspaceId, sectionId }) => ({
                    url: `tags/${workspaceId}/sections/${sectionId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { workspaceId }) => {
                    invalidateCachedTasks();
                    return [workspaceTag(workspaceId), currentNetworkWorkspacesTag];
                },
            }),

            updateSection: build.mutation<Section, { workspaceId: Id; sectionId: Id; section?: Partial<Section> }>({
                query: ({ workspaceId, sectionId, section }) => ({
                    url: `tags/${workspaceId}/sections/${sectionId}`,
                    method: HttpMethod.Put,
                    body: section,
                }),
                invalidatesTags: (result, error, { workspaceId }) => [
                    workspaceTag(workspaceId),
                    currentNetworkWorkspacesTag,
                ],
            }),

            addUserToWatchers: build.mutation<Array<WorkspaceWatcher>, { workspaceId: Id; userId: Id }>({
                query: ({ workspaceId, userId }) => ({
                    url: `tags/${workspaceId}/watchers`,
                    method: HttpMethod.Post,
                    body: { id: userId },
                }),
                invalidatesTags: (result, error, { workspaceId }) => [
                    workspaceTag(workspaceId),
                    currentNetworkWorkspacesTag,
                ],
            }),

            getDuplicateWorkspaceInfo: build.query<{ startDate: string; endDate: string }, Id>({
                query: (id) => ({ url: `tags/${id}/duplicate` }),
                providesTags: (result, meta, id) => [workspaceTag(id)],
            }),

            duplicateWorkspace: build.mutation<
                Workspace,
                {
                    workspaceId: Id;
                    config: {
                        assignee: boolean;
                        watchers: boolean;
                        attachments: boolean;
                        members: boolean;
                        tasks: boolean;
                        files: boolean;
                        customFields: boolean;
                        scaleDate: boolean;
                        referenceDateValue: string;
                    };
                    workspace: Pick<Workspace, 'name' | 'color' | 'team_id' | 'description' | 'settings'>;
                }
            >({
                query: ({ config, workspace, workspaceId }) => ({
                    url: `tags/${workspaceId}/duplicate`,
                    method: HttpMethod.Post,
                    body: { config, tag: workspace },
                }),
                invalidatesTags: (result, meta, { workspaceId }) => {
                    void trpcUtils().workspace.list.invalidate();
                    return [
                        workspaceTag(result?.id),
                        workspaceTag(workspaceId),
                        currentNetworkWorkspacesTag,
                        currentNetworkTeamsTag,
                    ];
                },
            }),

            removeUserFromWatchers: build.mutation<Array<WorkspaceWatcher>, { workspaceId: Id; userId: Id }>({
                query: ({ workspaceId, userId }) => ({
                    url: `tags/${workspaceId}/watchers/${userId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { workspaceId }) => [
                    workspaceTag(workspaceId),
                    currentNetworkWorkspacesTag,
                ],
            }),

            getWorkspaceActivities: build.query<Array<WorkspaceHistory>, Id>({
                query: (id) => ({ url: `tags/${id}/activities` }),
                providesTags: (result, meta, id) => [currentNetworkWorkspacesTag, workspaceTag(id)],
            }),

            archiveWorkspace: build.mutation<Workspace, Id>({
                query: (workspaceId) => ({
                    url: `tags/${workspaceId}`,
                    method: HttpMethod.Put,
                    body: { archived: true },
                }),
                invalidatesTags: (workspace, meta, workspaceId) => {
                    void trpcUtils().workspace.list.invalidate();
                    return [workspaceTag(workspaceId), currentNetworkWorkspacesTag, currentNetworkTeamsTag];
                },
            }),

            unarchiveWorkspace: build.mutation<Workspace, Id>({
                query: (workspaceId) => ({
                    url: `tags/${workspaceId}`,
                    method: HttpMethod.Put,
                    body: { archived: false },
                }),
                invalidatesTags: (workspace, meta, workspaceId) => {
                    void trpcUtils().workspace.list.invalidate();
                    return [workspaceTag(workspaceId), currentNetworkWorkspacesTag, currentNetworkTeamsTag];
                },
            }),

            deleteWorkspace: build.mutation<Workspace, Id>({
                query: (workspaceId) => ({
                    url: `tags/${workspaceId}`,
                    method: HttpMethod.Put,
                    body: { deleted: true },
                }),
                invalidatesTags: (result, meta, workspaceId) => {
                    void trpcUtils().workspace.list.invalidate();
                    return [workspaceTag(workspaceId), currentNetworkWorkspacesTag, currentNetworkTeamsTag];
                },
            }),

            restoreWorkspace: build.mutation<Workspace, Id>({
                query: (workspaceId) => ({
                    url: `tags/${workspaceId}`,
                    method: HttpMethod.Put,
                    body: { deleted: false },
                }),
                invalidatesTags: (result, meta, workspaceId) => {
                    void trpcUtils().workspace.list.invalidate();
                    return [workspaceTag(workspaceId), currentNetworkWorkspacesTag, currentNetworkTeamsTag];
                },
            }),

            updateWorkspaceMeetingAccess: build.mutation<
                Workspace,
                {
                    workspaceId: Id;
                    payload: Array<{ userId: Id; meetingRole: UserMeetingPermission }>;
                }
            >({
                query: ({ workspaceId, payload }) => ({
                    url: `tags/${workspaceId}/members-meeting`,
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: (result, error, { workspaceId }) => [workspaceTag(workspaceId)],
            }),
        }),
    });

export const {
    useGetWorkspacesQuery,
    useGetWorkspaceQuery,
    useUpdateWorkspaceMutation,
    useAddWorkspaceMutation,
    useUpdateWorkspaceLogoMutation,
    useDeleteWorkspaceLogoMutation,
    useGetWorkspaceSectionsQuery,
    useAddSectionMutation,
    useDeleteSectionMutation,
    useUpdateSectionMutation,
    useAddUserToWatchersMutation,
    useRemoveUserFromWatchersMutation,
    useArchiveWorkspaceMutation,
    useUnarchiveWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useRestoreWorkspaceMutation,
    useGetDuplicateWorkspaceInfoQuery,
    useDuplicateWorkspaceMutation,
    useGetWorkspaceActivitiesQuery,
    useUpdateWorkspaceMeetingAccessMutation,
} = workspaceApi;

export const invalidateWorkspaceById = (id: Id) => workspaceApi.util.invalidateTags([workspaceTag(id)]);
export const invalidateWorkspaces = () => workspaceApi.util.invalidateTags([currentNetworkWorkspacesTag]);

export const getWorkspace = (workspaceId: Id) =>
    store.dispatch(workspaceApi.endpoints.getWorkspace.initiate(workspaceId));
