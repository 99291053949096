import { FC, PropsWithChildren, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Label, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useLoader } from '@wedo/utils/hooks';
import { TeamsSelect } from 'Shared/components/team/TeamsSelect/TeamsSelect';
import { Team } from 'Shared/types/team';

type MoveEntityToAnotherTeamProps = {
    entity: { team: Team };
    entityName: 'workspace' | 'template';
    onMoveEntityToTeam: (teamId: Id) => Promise<void>;
    modalTitle: string;
} & ContextModalProps &
    PropsWithChildren;

export const MoveEntityToAnotherTeamModal: FC<MoveEntityToAnotherTeamProps> = ({
    children,
    onMoveEntityToTeam,
    entity,
    entityName,
    modalTitle,
    ...modalProps
}) => {
    const teamSelect = useRef<HTMLInputElement>();
    const { wrap: startMove, isLoading } = useLoader();
    const [team, setTeam] = useState(null);

    const handleMoveTeam = async () => {
        await startMove(onMoveEntityToTeam(team?.id ?? null));
        await modalProps.close();
    };

    return (
        <Modal {...modalProps} size="sm" initialFocus={teamSelect}>
            <Modal.Header title={modalTitle} />

            <Modal.Body>
                <Label>
                    <Trans>Select team</Trans>
                </Label>

                <TeamsSelect
                    ref={teamSelect}
                    teamId={team?.id ?? null}
                    onChange={setTeam}
                    disabledTeams={new Set([entity?.team_id ?? null])}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button className={'max-w-full'} color="primary" onClick={handleMoveTeam} loading={isLoading}>
                    <div className={'truncate'}>
                        {team === null
                            ? t`Remove from ${entity?.team?.name}`
                            : entityName === 'workspace'
                              ? t`Move workspace to ${team?.name}`
                              : t`Move template to ${team?.name}`}
                    </div>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
