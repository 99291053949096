import React, { useMemo } from 'react';
import { faInputText } from '@fortawesome/pro-duotone-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { EmptyState, Skeleton } from '@wedo/design-system';
import { CustomFieldDraggableItem } from 'Pages/settings/customFields/components/CustomFieldItem/CustomFieldDraggableItem';
import { CustomGroupDraggableItem } from 'Pages/settings/customFields/components/CustomGroupItem/CustomGroupDraggableItem';
import { useCombinedFields } from 'Pages/settings/customFields/hooks/useCombinedFields';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { isGroup } from 'Shared/utils/customFields';

export type CustomFieldFilterType = 'all' | 'active' | 'archived';

type CustomFieldsTableProps = {
    className?: string;
    status?: CustomFieldFilterType;
};

export const CustomFieldsTable: React.FC<CustomFieldsTableProps> = ({ className, status }) => {
    const { combinedList, isLoading, fetchErrorOccurred, refetch } = useCombinedFields();

    const visibleItems = useMemo<Array<CustomField | CustomFieldGroup>>(
        () =>
            combinedList.filter((item) => {
                if (status === 'all') {
                    return true;
                }
                if (status === 'active') {
                    return !item.archived;
                }
                if (status === 'archived') {
                    return item.archived;
                }
                return true;
            }),
        [combinedList, status]
    );

    const showArchived = status === 'archived' || status === 'all';

    if (fetchErrorOccurred) {
        return (
            <div className="mt-10 flex justify-center">
                <RetryComponent retryFunction={refetch} />
            </div>
        );
    }

    if (isLoading) {
        return <Skeleton count={4} className="mt-4 h-20" />;
    }

    if (visibleItems.length === 0) {
        return (
            <EmptyState className="mt-5" icon={faInputText}>
                <EmptyState.Text>
                    <Trans>No custom fields</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <div className={clsx(className, 'grid grid-cols-1 gap-2')}>
            {visibleItems.map((item) => {
                return isGroup(item) ? (
                    <CustomGroupDraggableItem
                        key={`group-${item.id}`}
                        group={item as CustomFieldGroup}
                        areArchivedItemsVisible={showArchived}
                    />
                ) : (
                    <CustomFieldDraggableItem key={`field-${item.id}`} customField={item as CustomField} />
                );
            })}
        </div>
    );
};
