import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps, FC, forwardRef, PropsWithChildren } from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { TableContext, TableContextType, useTableContext } from './TableContext';

const classes = {
    table: {
        base: 'min-w-full divide-y divide-gray-200 text-left text-sm text-gray-500',
    },
    thead: {
        base: 'bg-gray-50 text-left text-xs font-medium uppercase tracking-wide text-gray-500',
    },
    th: {
        size: {
            condensed: 'px-1 py-2 sm:px-2',
            default: 'p-3 sm:px-4',
        },
    },
    td: {
        base: 'text-sm text-gray-900',
        size: {
            condensed: 'p-1 sm:px-2',
            default: 'p-3 sm:px-4',
        },
    },
};

export type TableBodyProps = PropsWithChildren<ComponentProps<'tbody'>>;
const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(({ children, ...props }, ref) => {
    return (
        <tbody ref={ref} className="divide-y divide-gray-200 bg-white" {...props}>
            {children}
        </tbody>
    );
});

export type TableCellProps = PropsWithChildren<ComponentProps<'td'>>;
const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(({ children, className, ...props }, ref) => {
    const { size } = useTableContext();
    return (
        <td ref={ref} className={clsx(classes.td.base, classes.td.size[size], className)} {...props}>
            {children}
        </td>
    );
});

export type TableHeadProps = PropsWithChildren<ComponentProps<'thead'>> & { trClassName?: string };
export const TableHead: FC<TableHeadProps> = ({ children, className, trClassName, ...props }) => {
    return (
        <thead className={clsx(classes.thead.base, className)} {...props}>
            <tr className={trClassName}>{children}</tr>
        </thead>
    );
};

export type SortDirection = 'ascending' | 'descending' | null;

export type HeadCellProps = {
    onSort?: () => void;
    sortDirection?: SortDirection;
};
export type TableHeadCellProps = PropsWithChildren<ComponentProps<'th'> & HeadCellProps>;
export const TableHeadCell: FC<TableHeadCellProps> = ({
    onSort,
    sortDirection = null,
    children,
    className,
    ...props
}) => {
    const { size } = useTableContext();
    return (
        <th
            scope="col"
            className={clsx(classes.th.size[size], onSort && 'cursor-pointer hover:bg-gray-100', className)}
            {...props}
            onClick={onSort}
        >
            {onSort ? (
                <div className={'flex justify-between gap-2'}>
                    {children}
                    {sortDirection !== null && (
                        <FontAwesomeIcon
                            icon={sortDirection === 'ascending' ? faCaretUp : faCaretDown}
                            size="lg"
                            className="text-blue-600"
                            aria-hidden="true"
                        />
                    )}
                </div>
            ) : (
                children
            )}
        </th>
    );
};

export type TableRowProps = PropsWithChildren<ComponentProps<'tr'>>;
export const TableRow: FC<TableRowProps> = ({ children, className, ...props }) => {
    const { striped, hoverable } = useTableContext();
    return (
        <tr
            className={clsx(
                {
                    'odd:bg-white even:bg-gray-50': striped,
                    'hover:bg-gray-100': hoverable,
                },
                className
            )}
            {...props}
        >
            {children}
        </tr>
    );
};

export type TableProps = PropsWithChildren<ComponentProps<'table'> & TableContextType>;
const TableComponent: FC<TableProps> = ({
    children,
    striped,
    hoverable,
    size = 'default',
    wrapperClassName,
    className,
    ...props
}) => {
    return (
        <TableContext.Provider value={{ striped, hoverable, size }}>
            <div className={clsx('h-fit w-full overflow-x-auto rounded-lg border border-gray-300', wrapperClassName)}>
                <table className={clsx(classes.table.base, className)} {...props}>
                    {children}
                </table>
            </div>
        </TableContext.Provider>
    );
};

TableComponent.displayName = 'Table';
TableHead.displayName = 'Table.Head';
TableBody.displayName = 'Table.Body';
TableRow.displayName = 'Table.Row';
TableCell.displayName = 'Table.Cell';
TableHeadCell.displayName = 'Table.HeadCell';

export const Table = Object.assign(TableComponent, {
    Head: TableHead,
    Body: TableBody,
    Row: TableRow,
    Cell: TableCell,
    HeadCell: TableHeadCell,
});
