import React from 'react';
import { t } from '@lingui/macro';
import { Label, Skeleton, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailIcon } from 'Shared/components/task/TaskDetail/shared/TaskDetailIcon';
import { useTask } from 'Shared/hooks/useTask';
import { TaskDetailRelativeDateContent } from '../shared/TaskDetailRelativeDate';
import { TaskDetailRow } from '../shared/TaskDetailRow';

type TaskDetailRelativePlannedDateProps = {
    taskId: Id;
};

export const TaskDetailRelativeStartDate = ({ taskId }: TaskDetailRelativePlannedDateProps) => {
    const { task, isLoadingTask } = useTask(taskId);

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={t`Start date`} delay={300}>
                    <TaskDetailIcon type="planned_date" isActive={task?.relative_planned_date != null} />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    <>
                        <Label>{t`Start date`}</Label>
                        <TaskDetailRelativeDateContent taskId={taskId} fieldName={'relative_planned_date'} />
                    </>
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon />
        </TaskDetailRow>
    );
};
