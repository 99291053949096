export const InfiniteScrollEvent = 'infiniteScroll';

export const ScrollToDayEvent = 'scrollToDay';

export const toggleSubTasksEvent = (taskId: string) => {
    return `toggleSubTasks-${taskId}`;
};

export const RenderDependencies = 'renderDependencies';

export class EventBus extends EventTarget {
    dispatchInfiniteScrollEvent() {
        this.dispatchEvent(new CustomEvent(InfiniteScrollEvent));
    }

    dispatchRenderDependenciesEvent() {
        this.dispatchEvent(new CustomEvent(RenderDependencies));
    }

    dispatchScrollToDayEvent(day: number) {
        this.dispatchEvent(new CustomEvent(ScrollToDayEvent, { detail: day }));
    }

    dispatchToggleSubTasksEvent(taskId: string) {
        this.dispatchEvent(new CustomEvent(toggleSubTasksEvent(taskId)));
    }
}
