import React from 'react';
import Autolinker from 'autolinker';
import PropTypes from 'prop-types';
import { htmlEncode } from '@wedo/utils';

const defaultOptions = {
    className: 'hover:underline text-blue-500',
};

export class AutolinkerWrapper extends React.Component {
    static propTypes = {
        options: PropTypes.object.isRequired,
        tagName: PropTypes.string.isRequired,
        text: PropTypes.string,
    };

    static defaultProps = {
        options: defaultOptions,
        tagName: 'div',
    };

    componentDidMount() {
        this.invokeLink();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text !== this.props.text || prevProps.options !== this.props.options) {
            this.invokeLink();
        }
    }

    invokeLink = () => {
        this.element.innerHTML = Autolinker.link(htmlEncode(this.props.text), this.props.options || defaultOptions);
    };

    saveRef = (element) => {
        this.element = element;
    };

    render() {
        const { options, tagName, text, ...rest } = this.props; // eslint-disable-line no-unused-vars

        return (
            <this.props.tagName {...rest} ref={this.saveRef}>
                {this.props.text}
            </this.props.tagName>
        );
    }
}
