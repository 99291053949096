import { Children, cloneElement, forwardRef, ReactElement, useMemo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { HelperText } from '~/components/HelperText/HelperText';
import { Size } from '~/types';
import { ButtonProps, ButtonVariant } from '../Button/Button';

type ItemGroupProps = {
    children: JSX.Element | JSX.Element[];
    type?: 'submit' | 'button';
    className?: string;
    icon?: IconProp;
    href?: string;
    disabled?: boolean;
    variant?: ButtonVariant;
    size?: Size;
    helperText?: string;
    statusText?: string;
    statusTextClassName?: string;
    status?: 'default' | 'error' | 'success' | 'loading';
};

export const ItemGroup = forwardRef<HTMLDivElement, ItemGroupProps>(
    (
        {
            children,
            className,
            helperText,
            status,
            statusText,
            variant = 'outlined',
            size = 'md',
            disabled = false,
            statusTextClassName,
            ...props
        }: ItemGroupProps,
        ref
    ) => {
        const items = useMemo(() => {
            const filteredChildren = Children.toArray(children).filter(Boolean) as ReactElement<ButtonProps>[];

            return filteredChildren.map((child, index) =>
                cloneElement(child, {
                    position:
                        filteredChildren.length === 1
                            ? 'none'
                            : index === 0
                              ? 'start'
                              : index === filteredChildren.length - 1
                                ? 'end'
                                : 'middle',
                    ...(status != null ? { status } : {}),
                    ...child.props,
                })
            );
        }, [children]);

        return (
            <>
                <div className={clsx('flex h-fit max-w-full rounded-md', className)} ref={ref}>
                    {items}
                </div>
                {helperText && <HelperText className="mt-2">{helperText}</HelperText>}
                {statusText && (
                    <HelperText className={clsx('mt-2', statusTextClassName)} status={status}>
                        {statusText}
                    </HelperText>
                )}
            </>
        );
    }
);
