import { FC, ReactNode } from 'react';
import clsx from 'clsx';

export type PageHeaderProps = {
    title?: ReactNode;
    actions?: ReactNode | ReactNode[];
    tabs?: ReactNode | ReactNode[];
    wrapperClass?: string;
    titleWrapperClass?: string;
    titleClass?: string;
    actionsClass?: string;
};

export const PageHeader: FC<PageHeaderProps> = ({
    title = null,
    actions = null,
    tabs = null,
    wrapperClass = '',
    titleClass = '',
    actionsClass = '',
}) => {
    return (
        <div
            className={clsx(
                '@container grid min-h-[3rem] shrink-0 grid-cols-[auto,1fr] items-center gap-4 border-b border-gray-200 bg-white px-3 py-1 min-w-0',
                wrapperClass
            )}
        >
            <div
                className={clsx(
                    'w-full  overflow-hidden text-lg font-bold',
                    tabs !== null && '@7xl:max-w-[24rem] @xl:max-w-[18rem] max-w-[16rem]',
                    titleClass
                )}
            >
                {title}
            </div>
            <div className="flex w-full justify-between gap-2 min-w-0">
                {tabs}
                {actions && (
                    <div className={clsx('flex grow items-center justify-end gap-2', actionsClass)}>{actions}</div>
                )}
            </div>
        </div>
    );
};
