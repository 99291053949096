import { Placement } from '@floating-ui/react';
import React, { FC, ReactNode } from 'react';
import { ButtonProps, Popover } from '@wedo/design-system';
import { TaskPriorityPicker } from 'Shared/components/task/TaskPriority/TaskPriorityPicker';
import { Task } from 'Shared/types/task';
import { taskPriority } from 'Shared/utils/task';

type TaskPriorityPickerPopoverProps = {
    isDisabled?: boolean;
    priority: Task['priority'];
    onChange: (priority: Task['priority'], close: () => void) => void;
    label?: ReactNode;
    variant?: ButtonProps['variant'];
    size?: ButtonProps['size'];
    position?: ButtonProps['position'];
    placement?: Placement;
    className?: string;
    hideTooltip?: boolean;
};

export const TaskPriorityPickerPopover: FC<TaskPriorityPickerPopoverProps> = ({
    isDisabled = false,
    onChange,
    priority,
    label,
    variant = 'text',
    size = 'md',
    position = 'none',
    placement = 'bottom',
    className,
    hideTooltip = false,
}) => {
    const computedLabel = label ?? taskPriority[priority ?? 0].label;

    return (
        <Popover
            placement={placement}
            text={computedLabel}
            disabled={isDisabled}
            variant={variant}
            size={size}
            position={position}
            className={className}
        >
            {({ close }) => (
                <div
                    tabIndex={-1}
                    className={'relative flex flex-wrap items-center justify-center gap-2 bg-white p-1 outline-0'}
                >
                    <TaskPriorityPicker onChange={(priority) => onChange(priority, close)} hideTooltip={hideTooltip} />
                </div>
            )}
        </Popover>
    );
};
