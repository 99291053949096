import { type AnimationEvent } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Badge, BadgeType } from '~/components/Badge/Badge';

const BackgroundGradient = {
    meeting: 'bg-gradient-uv',
    file: 'bg-gradient-amber',
    task: 'bg-gradient-neon',
    checklist: 'bg-gradient-lime',
    user: 'bg-gradient-blue',
    workspace: 'bg-gradient-red',
    setting: 'bg-gradient-pink',
    governance: 'bg-gradient-cyan',
};

export const BadgePopup = ({
    type,
    points,
    quantity,
    isRepeating,
    icon,
    name,
    onAnimationEnd,
    onClick,
}: {
    type: BadgeType;
    points: number;
    quantity?: number;
    isRepeating: boolean;
    icon: IconDefinition;
    name: string;
    onAnimationEnd?: (event: AnimationEvent) => void;
    onClick?: () => void;
}) => {
    const bgGradient = BackgroundGradient[type] ?? 'bg-gradient-gray';

    return (
        <div
            className={clsx(
                'pointer-events-auto w-[300px] h-[75px] relative m-auto flex items-center justify-center flex-wrap',
                onClick && 'cursor-pointer'
            )}
            onClick={onClick}
        >
            <div
                onAnimationEnd={onAnimationEnd}
                className="z-10 animate-badge-grow-move opacity-0 origin-center h-[75px] w-[75px] top-0 my-0 mx-auto relative"
            >
                <Badge
                    className="h-[75px] w-[75px]"
                    isStatic
                    type={type}
                    points={points}
                    quantity={quantity}
                    isRepeating={isRepeating}
                    icon={icon}
                />
            </div>
            <div className={clsx('flex items-center content-center w-full relative text-white -top-[63.5px] left-0')}>
                <div
                    className={clsx(
                        'w-[300px] h-[52px] relative left-0 opacity-0 flex flex-col items-center justify-center right-0 my-0 mx-auto overflow-hidden animate-badge-banner-animate',
                        bgGradient
                    )}
                >
                    <div className="animate-badge-text-slide flex flex-col w-[calc(100%-60px)] absolute justify-center ml-[60px] left-0 h-full top-0">
                        <div className="text-xs">
                            <Trans>Badge unlocked</Trans> - <Trans>{points} pts</Trans>
                        </div>
                        <div className="truncate font-medium">{name}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
