import { totpContextInit } from 'Pages/settings/security/utils/TotpContext';
import {
    QrCodeType,
    ResetError,
    ResetState,
    StageDisable,
    StageEnable,
    TotpReducerType,
} from 'Pages/settings/security/utils/type';

export const totpReducer: TotpReducerType = (state, action) => {
    switch (action.type) {
        case ResetState:
            return totpContextInit;
        case ResetError:
            return { ...state, error: '' };
        case 'stage':
            return { ...state, stage: action.value as StageEnable | StageDisable };
        case 'securityCode':
        case 'authenticators':
            return { ...state, [action.type]: action.value as string[] };
        case 'qrCode':
            return { ...state, qrCode: action.value as QrCodeType };
        default:
            return { ...state, [action.type]: action.value as string };
    }
};
