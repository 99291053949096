import { UserRole } from 'Shared/types/user';

export enum Permission {
    AddWorkspace = 'AddWorkspace',
    AddTemplate = 'AddTemplate',
    AddTeam = 'AddTeam',
    AddMeeting = 'AddMeeting',
    ModerateGroups = 'ModerateGroups',
    ManageMeeting = 'ManageMeeting', // can be an editor
    HaveNonEditorRoles = 'HaveNonEditorRoles', // can be all roles except editor,
    AccessAllSettings = 'AccessAllSettings',
    ManageNetwork = 'ManageNetwork',
    ViewUsers = 'ViewUsers',
    ViewTeams = 'ViewTeams',
    ViewTemplates = 'ViewTemplates',
    ViewWorkspaces = 'ViewWorkspaces',
    ManageTasks = 'ManageTasks',
    ViewTaskHistory = 'ViewTaskHistory',
    ManageGovernance = 'ManageGovernance',
    ManageFiles = 'ManageFiles',
    StartChecklist = 'StartChecklist',
}

const LightAuthorizedPermissions: Permission[] = [];
const ExternalAuthorizedPermissions: Permission[] = [
    ...LightAuthorizedPermissions,
    Permission.HaveNonEditorRoles,
    Permission.ManageTasks,
    Permission.ManageFiles,
    Permission.ViewTaskHistory,
    Permission.StartChecklist,
];
const UserAuthorizedPermissions: Permission[] = [
    ...ExternalAuthorizedPermissions,
    Permission.AddWorkspace,
    Permission.AddTemplate,
    Permission.AddTeam,
    Permission.AddMeeting,
    Permission.ModerateGroups,
    Permission.ManageMeeting,
    Permission.AccessAllSettings,
    Permission.ViewUsers,
    Permission.ViewTemplates,
    Permission.ViewTeams,
    Permission.ViewWorkspaces,
];
const AdminAuthorizedPermissions: Permission[] = [
    ...UserAuthorizedPermissions,
    Permission.ManageNetwork,
    Permission.ManageGovernance,
];

const getAuthorizedPermissionsByRole = (role: UserRole) => {
    switch (role) {
        case UserRole.LIGHT:
            return LightAuthorizedPermissions;
        case UserRole.EXTERNAL:
            return ExternalAuthorizedPermissions;
        case UserRole.USER:
            return UserAuthorizedPermissions;
        case UserRole.ADMIN:
            return AdminAuthorizedPermissions;
        default:
            return [];
    }
};

export const can = (role: UserRole, permission: Permission): boolean => {
    return getAuthorizedPermissionsByRole(role).includes(permission);
};
