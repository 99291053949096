import { useLingui } from '@lingui/react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { t } from '@lingui/macro';
import { colors } from '@wedo/design-system';
import { formatDate } from '@wedo/utils';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { SignatureSettings, SignatureType } from 'Shared/types/signature';

type UserSignatureProps = {
    settings: SignatureSettings;
    type?: SignatureType;
};

const BORDER_THICKNESS = 8;
const INNER_PADDING = 8;

const SIGNATURE_TYPE_WIDTH = 52;
const INNER_SIGNATURE_TYPE_WIDTH = 46.8;
const SIGNATURE_TYPE_HEIGHT = 36;
const INNER_SIGNATURE_TYPE_HEIGHT = 31;
const SES_RECTANGLE_COLOR_START = '#f97316';
const SES_RECTANGLE_COLOR_END = '#ec4899';
const AES_RECTANGLE_COLOR_START = colors.blue[500];
const AES_RECTANGLE_COLOR_END = colors.blue[600];
const QES_RECTANGLE_COLOR_START = '#22ca5e';
const QES_RECTANGLE_COLOR_END = '#14b8a6';
const SIGNATURE_TYPE_PADDING_X = 26;
const SIGNATURE_TYPE_PADDING_Y = 17;

const SIGNATURE_TYPE_TITLE_OFFSET_Y = 24;
const SIGNATURE_TYPE_TEXT_OFFSET_Y = 16;

const SIGNATURE_TYPE_TEXT_OFFSET_X = 8;

const NAME_OFFSET_X = 8;
const NAME_OFFSET_Y = 54;
const NAME_WHITE_SQUARE_HEIGHT = 96;

const OPTIONAL_LINE_SQUARE_OFFSET_Y = 36;
const OPTIONAL_LINE_OFFSET_Y = 46;

const QR_CODE_SIZE = 72;

const DATE_HEIGHT = 16;
const DATE_OFFSET_Y = 22;
const DATE_OFFSET_BACKGROUND_X = 72;
const DATE_OFFSET_BACKGROUND_Y = 30;

const OPTIONAL_LINE_HEIGHT = 18;

const TEXT_COLOR = 'rgb(15, 23, 42)';

export const UserSignatureCanvas = forwardRef<HTMLCanvasElement, UserSignatureProps>(
    (
        {
            settings: { name, optionalLine, showDate = true, showQRCode = true, handwrittenSignatureUrl, font },
            type = 'SES',
        },
        ref
    ) => {
        const { i18n } = useLingui();

        const canvasRef = useRef<HTMLCanvasElement>(null);
        const context = canvasRef.current?.getContext('2d');

        const signatureTypeLabel =
            type === 'SES'
                ? t`Simple electronic signature`
                : type === 'AES'
                  ? t`Advanced electronic signature`
                  : t`Qualified electronic signature`;
        const translatedType = type === 'SES' ? t`SES` : type === 'AES' ? t`AES` : t`QES`;

        const previousContext = usePrevious(context);
        const previousSignatureType = usePrevious(type);
        const previousName = usePrevious(name);
        const previousFontFace = usePrevious(font);
        const previousShowDate = usePrevious(showDate);
        const previousOptionalLine = usePrevious(optionalLine);
        const previousShowQRCode = usePrevious(showQRCode);
        const previousHandwrittenSignatureURL = usePrevious(handwrittenSignatureUrl);

        const drawSignatureType = (context: CanvasRenderingContext2D) => {
            const sesGradient = context.createLinearGradient(0, 0, 52, 0);
            sesGradient.addColorStop(0, SES_RECTANGLE_COLOR_START);
            sesGradient.addColorStop(1, SES_RECTANGLE_COLOR_END);

            const aesGradient = context.createLinearGradient(0, 0, 52, 0);
            aesGradient.addColorStop(0, AES_RECTANGLE_COLOR_START);
            aesGradient.addColorStop(1, AES_RECTANGLE_COLOR_END);

            const qesGradient = context.createLinearGradient(0, 0, 52, 0);
            qesGradient.addColorStop(0, QES_RECTANGLE_COLOR_START);
            qesGradient.addColorStop(1, QES_RECTANGLE_COLOR_END);
            // Blue square in the bottom left corner
            context.fillStyle = type === 'QES' ? qesGradient : type === 'AES' ? aesGradient : sesGradient;
            context.beginPath();
            context.roundRect(
                BORDER_THICKNESS + INNER_PADDING,
                context.canvas.height - BORDER_THICKNESS - INNER_PADDING - SIGNATURE_TYPE_HEIGHT,
                SIGNATURE_TYPE_WIDTH,
                SIGNATURE_TYPE_HEIGHT,
                [7.2]
            );
            context.fill();

            // Draw the inner white rectangle
            context.fillStyle = '#FFFFFF';
            context.beginPath();
            context.roundRect(
                BORDER_THICKNESS + INNER_PADDING + (SIGNATURE_TYPE_WIDTH - INNER_SIGNATURE_TYPE_WIDTH) / 2,
                context.canvas.height -
                    BORDER_THICKNESS -
                    INNER_PADDING -
                    SIGNATURE_TYPE_HEIGHT +
                    (SIGNATURE_TYPE_HEIGHT - INNER_SIGNATURE_TYPE_HEIGHT) / 2,
                INNER_SIGNATURE_TYPE_WIDTH,
                INNER_SIGNATURE_TYPE_HEIGHT,
                [5.8]
            );
            context.fill();

            const textLength = type.length;
            if (textLength > 0) {
                // Draw blue square with text inside
                let fontSize = 16;
                context.font = `bold ${fontSize}px sans-serif`;
                context.fillStyle = type === 'QES' ? qesGradient : type === 'AES' ? aesGradient : sesGradient;
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText(
                    translatedType,
                    BORDER_THICKNESS + INNER_PADDING + SIGNATURE_TYPE_PADDING_X,
                    context.canvas.height - BORDER_THICKNESS - INNER_PADDING - SIGNATURE_TYPE_PADDING_Y
                );

                // Draw text next to blue square
                // First draw the white background around the text
                context.fillStyle = '#FFFFFF';
                context.fillRect(
                    BORDER_THICKNESS + INNER_PADDING + SIGNATURE_TYPE_WIDTH + SIGNATURE_TYPE_TEXT_OFFSET_X,
                    context.canvas.height - BORDER_THICKNESS - INNER_PADDING - SIGNATURE_TYPE_WIDTH,
                    context.canvas.width -
                        (BORDER_THICKNESS + INNER_PADDING) * 2 -
                        SIGNATURE_TYPE_WIDTH -
                        SIGNATURE_TYPE_TEXT_OFFSET_X,
                    SIGNATURE_TYPE_HEIGHT
                );

                // Then the title
                fontSize = 14;
                context.font = `bold ${fontSize}px sans-serif`;
                context.fillStyle = TEXT_COLOR;
                context.textAlign = 'left';
                context.textBaseline = 'middle';
                context.fillText(
                    signatureTypeLabel,
                    BORDER_THICKNESS + INNER_PADDING + SIGNATURE_TYPE_WIDTH + SIGNATURE_TYPE_TEXT_OFFSET_X,
                    context.canvas.height - BORDER_THICKNESS - INNER_PADDING - SIGNATURE_TYPE_TITLE_OFFSET_Y
                );

                // Then the text below
                fontSize = 12;
                context.font = `${fontSize}px sans-serif`;
                context.fillStyle = TEXT_COLOR;
                context.textAlign = 'left';
                context.textBaseline = 'middle';
                context.fillText(
                    t`Signed with WEDO`,
                    BORDER_THICKNESS + INNER_PADDING + SIGNATURE_TYPE_WIDTH + SIGNATURE_TYPE_TEXT_OFFSET_X,
                    context.canvas.height -
                        BORDER_THICKNESS -
                        INNER_PADDING -
                        SIGNATURE_TYPE_TITLE_OFFSET_Y +
                        SIGNATURE_TYPE_TEXT_OFFSET_Y
                );
            }
        };

        const drawName = async (context: CanvasRenderingContext2D) => {
            // White background around name
            context.fillStyle = '#FFF';
            context.fillRect(
                BORDER_THICKNESS,
                BORDER_THICKNESS,
                context.canvas.width - BORDER_THICKNESS - INNER_PADDING - QR_CODE_SIZE,
                NAME_WHITE_SQUARE_HEIGHT
            );

            if (handwrittenSignatureUrl != null) {
                const image = new Image();
                image.src = handwrittenSignatureUrl;

                await new Promise<void>((resolve) => {
                    image.onload = () => {
                        const imageWidth =
                            context.canvas.width -
                            NAME_OFFSET_X -
                            QR_CODE_SIZE -
                            (BORDER_THICKNESS + INNER_PADDING) * 2;
                        const imageHeight = imageWidth * (image.height / image.width);
                        context.drawImage(
                            image,
                            BORDER_THICKNESS + INNER_PADDING,
                            BORDER_THICKNESS + INNER_PADDING,
                            imageWidth,
                            imageHeight
                        );

                        resolve();
                    };
                });
            } else {
                // User name
                const textLength = name.length;
                if (textLength > 0) {
                    const fontSizeRatio = (font === 'Loftygoals' ? 420 : 640) / textLength;
                    const fontSizeMultiplicator = 1.2;
                    const maxFontSize = font === 'Loftygoals' ? 38 : 52;
                    const fontSize = Math.min(
                        fontSizeRatio * fontSizeMultiplicator,
                        maxFontSize * fontSizeMultiplicator
                    );
                    context.font = `${fontSize}px ${font}`;
                    context.fillStyle = TEXT_COLOR;
                    context.textAlign = 'left';
                    context.textBaseline = 'middle';
                    context.fillText(
                        name,
                        BORDER_THICKNESS + INNER_PADDING + NAME_OFFSET_X,
                        BORDER_THICKNESS + INNER_PADDING + NAME_OFFSET_Y
                    );
                }
            }
        };

        const drawOptionalLine = (context: CanvasRenderingContext2D) => {
            // White background around optional line
            context.fillStyle = '#FFF';
            context.fillRect(
                BORDER_THICKNESS + INNER_PADDING,
                BORDER_THICKNESS + INNER_PADDING + NAME_OFFSET_Y + OPTIONAL_LINE_SQUARE_OFFSET_Y,
                context.canvas.width - (BORDER_THICKNESS + INNER_PADDING) * 2 - QR_CODE_SIZE,
                OPTIONAL_LINE_HEIGHT
            );

            // Draw text
            const fontSize = 14;
            context.font = `bold ${fontSize}px sans-serif`;
            context.fillStyle = TEXT_COLOR;
            context.textAlign = 'left';
            context.textBaseline = 'middle';
            context.fillText(
                optionalLine,
                BORDER_THICKNESS + INNER_PADDING + NAME_OFFSET_X,
                BORDER_THICKNESS + INNER_PADDING + NAME_OFFSET_Y + OPTIONAL_LINE_OFFSET_Y
            );
        };

        const drawDate = (context: CanvasRenderingContext2D) => {
            const formattedDate = formatDate(new Date(), 'PP', i18n);
            const formattedTime = formatDate(new Date(), 'p', i18n);
            // White background around date
            context.fillStyle = '#FFF';
            context.fillRect(
                context.canvas.width - BORDER_THICKNESS - INNER_PADDING - DATE_OFFSET_BACKGROUND_X,
                DATE_OFFSET_BACKGROUND_Y,
                QR_CODE_SIZE,
                DATE_HEIGHT
            );

            // Draw text
            if (showDate) {
                const fontSize = 12;
                context.font = `${fontSize}px sans-serif`;
                context.textAlign = 'right';
                context.textBaseline = 'middle';
                context.strokeStyle = 'white';
                context.lineWidth = 4;
                context.strokeText(
                    formattedDate + ' · ' + formattedTime,
                    context.canvas.width - BORDER_THICKNESS - INNER_PADDING,
                    DATE_OFFSET_Y
                );
                context.fillStyle = TEXT_COLOR;
                context.fillText(
                    formattedDate + ' · ' + formattedTime,
                    context.canvas.width - BORDER_THICKNESS - INNER_PADDING,
                    DATE_OFFSET_Y
                );
            }
        };

        const drawQRCode = (context: CanvasRenderingContext2D) => {
            if (showQRCode) {
                // Draw QR Code
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.onload = () => {
                    context.drawImage(
                        img,
                        context.canvas.width - BORDER_THICKNESS - INNER_PADDING - QR_CODE_SIZE,
                        context.canvas.height - BORDER_THICKNESS - INNER_PADDING - QR_CODE_SIZE,
                        QR_CODE_SIZE,
                        QR_CODE_SIZE
                    );
                };
                img.src = '/assets/signature-qr-code.svg';
            } else {
                // We replace the QR Code with a white rectangle
                context.fillStyle = '#FFF';
                context.fillRect(
                    context.canvas.width - BORDER_THICKNESS - INNER_PADDING - QR_CODE_SIZE,
                    context.canvas.height - BORDER_THICKNESS - INNER_PADDING - QR_CODE_SIZE,
                    QR_CODE_SIZE,
                    QR_CODE_SIZE
                );
            }
        };

        const drawBackground = (context: CanvasRenderingContext2D) => {
            // Gray background
            context.fillStyle = 'rgb(226 232 240)';
            context.fillRect(0, 0, context.canvas.width, context.canvas.height);

            // White foreground
            context.fillStyle = '#FFFFFF';
            context.fillRect(
                BORDER_THICKNESS,
                BORDER_THICKNESS,
                context.canvas.width - BORDER_THICKNESS - INNER_PADDING,
                context.canvas.height - BORDER_THICKNESS - INNER_PADDING
            );
        };

        const loadFontsAndDrawName = async (context: CanvasRenderingContext2D) => {
            const loadedFonts = await Promise.all([
                new FontFace('Anjhay', 'url(/assets/signatureFonts/Anjhay.ttf)').load(),
                new FontFace('Astagina Signature', 'url(/assets/signatureFonts/AstaginaSignature.ttf)').load(),
                new FontFace('Loftygoals', 'url(/assets/signatureFonts/Loftygoals.otf)').load(),
            ]);
            loadedFonts.forEach((loadedFont) => {
                document.fonts.add(loadedFont);
            });

            await drawName(context);
        };

        const redraw = async () => {
            drawBackground(context);
            drawQRCode(context);
            drawSignatureType(context);
            await loadFontsAndDrawName(context);
            drawOptionalLine(context);
            drawDate(context);
        };

        if (
            previousContext !== context ||
            previousName !== name ||
            previousFontFace !== font ||
            previousOptionalLine !== optionalLine ||
            previousHandwrittenSignatureURL !== handwrittenSignatureUrl ||
            previousSignatureType !== type ||
            previousShowQRCode !== showQRCode ||
            previousOptionalLine !== optionalLine ||
            previousShowDate !== showDate
        ) {
            if (context != null) {
                void redraw();
            }
        }

        useImperativeHandle(ref, () => canvasRef.current);

        return (
            <canvas id="signatureCanvas" width="400" height="200" ref={canvasRef}>
                {t`${name}'s signature`}
            </canvas>
        );
    }
);
