import { Transition } from '@headlessui/react';
import { FC, Fragment, PropsWithChildren, useId } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { Checkbox } from '~/components/Checkbox/Checkbox';
import { TreeProps } from '~/components/Tree/components/TreeFull';
import { CheckboxTree, findRoot, setCheckedValue } from '~/components/Tree/utils/tree';

export const TreeItem: FC<PropsWithChildren & TreeProps> = ({
    children,
    isRoot = false,
    onCheckboxClick,
    onOpenClick,
    onChange,
    value,
    isDisabled,
}) => {
    const htmlId = useId();

    const { open: isOpen, checked: isChecked, label } = value;

    const handleChange = (type: 'check' | 'open') => {
        if (!onChange) {
            return;
        }

        const valueCopy = structuredClone(value) as CheckboxTree;
        switch (type) {
            case 'check':
                setCheckedValue(valueCopy, !value.checked);
                break;
            default:
                valueCopy.open = !valueCopy.open;
        }

        onChange(findRoot(valueCopy));
    };

    const handleCheck = () => {
        onCheckboxClick?.(value);
        handleChange('check');
    };

    const handleOpen = () => {
        onOpenClick?.(value);
        handleChange('open');
    };

    const Wrapper = isRoot ? 'ul' : Fragment;

    return (
        <Wrapper>
            <li
                className={clsx(
                    `relative list-none border-l border-gray-200 last:border-transparent`,
                    !isRoot &&
                        `ml-[0.55rem] pl-8 before:absolute before:-left-px before:h-[1.0rem] before:w-7 before:border-b before:border-l before:border-gray-200`
                )}
            >
                <div className="flex items-start gap-2 py-1">
                    {(children || isRoot) && (
                        <Button
                            className={clsx('mt-1 shrink-0', !children && 'invisible')}
                            aria-label={isOpen ? t`Collapse ${label}` : t`Expand ${label}`}
                            variant="ghost"
                            size="xs"
                            color="gray"
                            onClick={handleOpen}
                            icon={isOpen ? faChevronDown : faChevronRight}
                        />
                    )}

                    <Checkbox
                        id={htmlId + 'checkbox'}
                        className="mt-1 shrink-0"
                        isIndeterminate={isChecked === null}
                        checked={isChecked ?? false}
                        onChange={handleCheck}
                        disabled={isDisabled}
                    />
                    <label htmlFor={htmlId + 'checkbox'} className="cursor-pointer text-sm">
                        {label}
                    </label>
                </div>

                {children && (
                    <Transition
                        show={isOpen}
                        as={Fragment}
                        enter="transition-opacity ease-out duration-100"
                        enterFrom="transform opacity-0"
                        enterTo="transform opacity-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100"
                        leaveTo="transform opacity-0 "
                    >
                        <ul>{children}</ul>
                    </Transition>
                )}
            </li>
        </Wrapper>
    );
};
