import React, { FC } from 'react';
import { EmptyFunction } from '@wedo/utils';
import { FeedItem, FeedItemColor } from 'Pages/activityFeed/FeedItem/FeedItem';
import {
    WorkspaceStatusHeader,
    WorkspaceStatusHeaderActions,
    WorkspaceStatusText,
} from 'Pages/workspace/info/WorkspaceStatusItem';
import { getWorkspaceStatus } from 'Shared/components/workspace/WorkspaceStatusUtils';
import { useGetUserQuery } from 'Shared/services/user';
import { ActivityLog } from 'Shared/types/activityLog';
import { WorkspaceStatus } from 'Shared/types/workspaceStatus';

export const FeedItemWorkspaceStatus: FC<{ activityFeed: ActivityLog & { previousTagStatus: WorkspaceStatus } }> = ({
    activityFeed,
}) => {
    const { data: user } = useGetUserQuery(activityFeed.created_by.id, { skip: !activityFeed.created_by.id });

    if (!('object' in activityFeed)) {
        return <></>;
    }

    const object = activityFeed.object as Object;
    const tagStatus = { ...object, updated_by: user } as WorkspaceStatus;
    const workspaceStatus = getWorkspaceStatus(tagStatus.status);

    return (
        <FeedItem icon={workspaceStatus.icon} color={workspaceStatus.color as FeedItemColor}>
            <FeedItem.Wrapper color={workspaceStatus.color as FeedItemColor}>
                <div className="mx-4 mb-2 flex justify-between border-b pb-2 pt-3">
                    <WorkspaceStatusHeader status={tagStatus} />
                    <WorkspaceStatusHeaderActions
                        status={tagStatus}
                        previousStatus={activityFeed?.previousTagStatus}
                        onReload={EmptyFunction}
                    />
                </div>
                <div className="mx-4 pb-2">
                    <WorkspaceStatusText status={tagStatus} />
                </div>
            </FeedItem.Wrapper>
            <FeedItem.Footer createdAt={new Date(activityFeed.created_at)} createdBy={activityFeed.created_by} />
        </FeedItem>
    );
};
