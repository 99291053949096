import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { z } from 'zod';
import { meetingTopicOutputSchema } from '@wedo/backend/src/modules/meetingTopic/schema';
import { MeetingTopicSubmission, TopicSubmissionStatus } from 'Shared/types/meetingTopic';

type MeetingTopic = z.infer<typeof meetingTopicOutputSchema>;

export const TopicSubmissionStatusToString: Record<TopicSubmissionStatus, MessageDescriptor> = {
    [TopicSubmissionStatus.DRAFT]: msg`Draft`,
    [TopicSubmissionStatus.ACCEPTED]: msg`Accepted`,
    [TopicSubmissionStatus.REJECTED]: msg`Rejected`,
    [TopicSubmissionStatus.SUBMITTED]: msg`Submitted`,
    [TopicSubmissionStatus.COMPLETED]: msg`Completed`,
} as const;

export const getTopicSubmissionStatus = (
    topic: MeetingTopic,
    topicSubmission: MeetingTopicSubmission,
    completedTopic?: MeetingTopic
): TopicSubmissionStatus => {
    if (topic == null) {
        return null;
    }
    if (topicSubmission == null) {
        return TopicSubmissionStatus.DRAFT;
    }
    if (topicSubmission.status == null) {
        return TopicSubmissionStatus.SUBMITTED;
    }
    if (topicSubmission.status === 'accepted') {
        if (completedTopic != null) {
            return TopicSubmissionStatus.COMPLETED;
        }
        return TopicSubmissionStatus.ACCEPTED;
    }
    if (topicSubmission.status === 'rejected') {
        return TopicSubmissionStatus.REJECTED;
    }
    return null;
};
