import { useCallback, useLayoutEffect, useRef } from 'react';

export const useEventCallback = <T extends unknown[], O>(f: (...args: T) => O): ((...args: T) => O) => {
    const ref = useRef<typeof f | undefined>(undefined);

    useLayoutEffect(() => {
        ref.current = f;
    });

    return useCallback((...args: T) => ref.current.apply(0, args), []);
};
