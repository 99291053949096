import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faSpinner, faXmark } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { getColorId } from '@wedo/design-system';
import { stopPropagationOnMouseEvents } from '@wedo/utils';
import { Label } from 'Shared/types/label';

type LabelTagProps = {
    label: Partial<Label>;
    onRemove?: () => void;
};

export const LabelTag = ({ label, onRemove }: LabelTagProps) => {
    const colorId = getColorId(label?.color);
    return (
        <div
            className={clsx(
                'mx-0.5 block inline max-w-[10rem] overflow-hidden overflow-ellipsis whitespace-nowrap rounded-full px-2',
                'bg-' + colorId.toString() + '-200',
                'text-' + colorId.toString() + '-800'
            )}
        >
            {label ? label.name : <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />}
            {onRemove && (
                <span
                    className="ml-1 cursor-pointer"
                    onClick={onRemove}
                    role="button"
                    tabIndex={0}
                    onKeyDown={onRemove}
                    {...stopPropagationOnMouseEvents}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </span>
            )}
        </div>
    );
};
