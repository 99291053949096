import { useLingui } from '@lingui/react';
import { pdf } from '@react-pdf/renderer';
import React, { ChangeEvent, createElement, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { i18n, MessageDescriptor } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import { Form, Input, ItemGroup, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { type TaskPdfProps, TasksPdf } from '@wedo/pdf/tasks/TasksPdf';
import { Id } from '@wedo/types';
import { isMessageDescriptor } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { getUsers } from 'App/store/usersStore';
import { PdfColumns, useExportTaskPdfStore } from 'Pages/TasksPage/hooks/useExportTaskPdfStore';
import { Group } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useTask } from 'Shared/hooks/useTask';
import { ExportTasksDensity, ExportTasksOrientation } from 'Shared/services/task';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { User } from 'Shared/types/user';
import { convertSvgToPng } from 'Shared/utils/svg';
import { CheckboxItem } from './CheckboxItem';
import { ExportTabHandle } from './ExportTasksModal';
import { RadioItem } from './RadioItem';

type Column = {
    id: PdfColumns;
    text: MessageDescriptor | '';
    width: string | number;
    hidden?: boolean;
};
export const Columns: Array<Column> = [
    { id: 'name', text: msg`Name`, width: '*', hidden: true },
    { id: 'startDate', text: msg`Start date`, width: 45 },
    { id: 'tags', text: msg`Checklist / Workspaces`, width: 90 },
    { id: 'dueDate', text: msg`Due date`, width: 60 },
    { id: 'priority', text: '', width: 10, hidden: true },
    { id: 'assignee', text: msg`Assignee`, width: 60, hidden: true },
    { id: 'customFields', text: msg`Custom fields`, width: 'full' },
    { id: 'subTasks', text: msg`Subtasks`, width: 'full' },
    { id: 'description', text: msg`Description`, width: 'full' },
    { id: 'comments', text: msg`Comments`, width: 'full' },
    { id: 'attachments', text: msg`Attachments`, width: 'full' },
];

const columnTextToString = (column: Pick<Column, 'text'>): string => {
    if (isMessageDescriptor(column.text)) {
        return i18n._(column.text);
    }

    return column.text;
};

type PdfExportTabProps = {
    workspaceId: Id;
    title: string;
    footer: string;
    selectedTasks: Id[];
    isExportingAllTasks: boolean;
    isGroupTitleHidden: boolean;
    hideTasksToExport?: boolean;
    groupedTasks: Group[];
    isTemplate: boolean;
    handleToggleSelectedTasks: (isSelectedTasksSelected: boolean) => void;
};

export const PdfExportTab = forwardRef<ExportTabHandle, PdfExportTabProps>(
    (
        {
            workspaceId,
            title: initialTitle,
            footer: initialFooter,
            selectedTasks,
            isExportingAllTasks,
            hideTasksToExport = false,
            isGroupTitleHidden = false,
            groupedTasks,
            isTemplate,
            handleToggleSelectedTasks,
        }: PdfExportTabProps,
        ref
    ) => {
        const { i18n } = useLingui();
        const { task: firstSelectedTask } = useTask(selectedTasks?.[0]);
        const { show: showNotification } = useNotification();
        const { isLoading: isExporting, wrap: startExporting } = useLoader();

        const [title, setTitle] = useState(initialTitle);

        if (title == null && initialTitle != null) {
            setTitle(initialTitle);
        }

        const [footer, setFooter] = useState(initialFooter);
        const [tasks, setTasks] = useState<Id[]>(
            isExportingAllTasks || selectedTasks.length <= 0 ? null : selectedTasks
        );

        const {
            selectedColumns: columns,
            hasSignature,
            setHasSignature,
            setSelectedColumns: setColumns,
            orientation,
            setOrientation,
            density,
            setDensity,
        } = useExportTaskPdfStore();

        const { network } = useCurrentNetwork();
        const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });

        useEffect(() => {
            if (tasks != null && selectedTasks.length === 1) {
                setTitle(firstSelectedTask?.name);
            } else if (tasks == null) {
                setTitle(initialTitle);
            }
        }, [tasks, selectedTasks]);

        const handleChangeTitle = ({ target }: ChangeEvent<HTMLInputElement>) => setTitle(target.value);

        const handleChangeFooter = ({ target }: ChangeEvent<HTMLInputElement>) => setFooter(target.value);

        const handleToggleTasks = () => {
            setTasks(tasks === null ? selectedTasks : null);
            handleToggleSelectedTasks(tasks === null ? true : false);
        };

        const handleToggleColumn = (column: PdfColumns) => () => {
            if (columns.has(column)) {
                columns.delete(column);
                setColumns(new Set(columns));
            } else {
                setColumns(new Set(columns.add(column)));
            }
        };

        const handleToggleSignature = () => setHasSignature(!hasSignature);

        const handleChangeOrientation = (orientation: ExportTasksOrientation) => () => setOrientation(orientation);

        const handleChangeDensity = (density: ExportTasksDensity) => () => setDensity(density);

        useEffect(() => {
            setTasks(isExportingAllTasks || selectedTasks.length <= 0 ? null : selectedTasks);
        }, []);

        useImperativeHandle(ref, () => ({
            exportTasks: async () => {
                return startExporting(async () => {
                    const logoUrl = workspace?.logo_url ?? workspace?.team?.logo_url ?? network.logo_url;
                    const pdfProps: TaskPdfProps = {
                        settings: {
                            title,
                            footer,
                            isTemplate,
                            isGroupTitleHidden,
                            columns: Array.from(columns)
                                .filter((columnId) => Columns.find((col) => col.id === columnId))
                                .map((columnId) => Columns.find(({ id }) => id === columnId)?.id),
                            signature: hasSignature && {
                                show: true,
                                locationAndDateLabel: t`Location and date:`,
                                signatureLabel: t`Signature:`,
                            },
                            orientation,
                            density,
                            locale: { code: i18n.locale, messages: i18n.messages },
                            logo: await convertSvgToPng(logoUrl, 300, 70),
                        },
                        users: getUsers() as User[],
                        groupedTasks,
                        network,
                    };

                    try {
                        const blob = await pdf(createElement(TasksPdf, pdfProps)).toBlob();
                        return {
                            url: URL.createObjectURL(blob),
                            filename: `${title}.pdf`,
                        };
                    } catch {
                        showNotification(UnexpectedErrorNotification);
                        return null;
                    }
                });
            },
        }));

        return (
            <Form layout="horizontal">
                <fieldset disabled={isExporting}>
                    <Form.Item label={t`Title`} htmlFor="title">
                        <ItemGroup>
                            <Input
                                id="title"
                                name="title"
                                value={title ?? ''}
                                onChange={handleChangeTitle}
                                className="w-full"
                                disabled={title == null}
                            />
                            <Input.Addon text={'.pdf'} />
                        </ItemGroup>
                    </Form.Item>
                    <Form.Item label={t`Footer`} htmlFor="footer">
                        <Input id="footer" name="footer" value={footer} onChange={handleChangeFooter} />
                    </Form.Item>
                    {!hideTasksToExport && (
                        <Form.Item label={t`Tasks to export`}>
                            <RadioItem
                                id="all"
                                name="export"
                                label={t`All tasks`}
                                isChecked={tasks === null}
                                onChange={handleToggleTasks}
                            />
                            <RadioItem
                                id="selected"
                                name="export"
                                label={t`Selected tasks (${selectedTasks.length})`}
                                isChecked={tasks !== null}
                                isDisabled={selectedTasks.length <= 0}
                                onChange={handleToggleTasks}
                            />
                        </Form.Item>
                    )}
                    <Form.Item label={t`Items to show`}>
                        {Columns.filter(({ hidden }) => !hidden).map(({ id, text }) => {
                            return (
                                <CheckboxItem
                                    key={id}
                                    name={id}
                                    label={columnTextToString({ text })}
                                    isChecked={columns.has(id)}
                                    onChange={handleToggleColumn(id)}
                                />
                            );
                        })}
                        <CheckboxItem
                            name="signature"
                            label={t`Date and signature`}
                            isChecked={hasSignature}
                            onChange={handleToggleSignature}
                        />
                    </Form.Item>
                    <Form.Item label={t`Orientation`}>
                        <RadioItem
                            id="portrait"
                            name="orientation"
                            label={t`Portrait`}
                            isChecked={orientation === 'portrait'}
                            onChange={handleChangeOrientation('portrait')}
                        />
                        <RadioItem
                            id="landscape"
                            name="orientation"
                            label={t`Landscape`}
                            isChecked={orientation === 'landscape'}
                            onChange={handleChangeOrientation('landscape')}
                        />
                    </Form.Item>
                    <Form.Item label={t`Font size`}>
                        <RadioItem
                            id="compact"
                            name="font_size"
                            label={t`Small`}
                            isChecked={density === 'small'}
                            onChange={handleChangeDensity('small')}
                        />
                        <RadioItem
                            id="normal"
                            name="font_size"
                            label={t`Medium`}
                            isChecked={density === 'medium'}
                            onChange={handleChangeDensity('medium')}
                        />
                        <RadioItem
                            id="large"
                            name="font_size"
                            label={t`Large`}
                            isChecked={density === 'large'}
                            onChange={handleChangeDensity('large')}
                        />
                    </Form.Item>
                </fieldset>
            </Form>
        );
    }
);
