import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { faHourglassEnd } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { differenceInDays } from 'date-fns';
import { Button, useConfirm } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { SUBSCRIPTION_ADDRESS_STEP_URL } from 'Pages/subscription/utils';
import { useClearOnboardingTrialMutation } from 'Shared/services/onboarding';
import { useGetOrganizationQuery } from 'Shared/services/organization';

const TrialPageCard: FC = () => {
    return (
        <div className="flex max-w-[30rem] flex-col items-center gap-2 rounded-md bg-white px-12 py-12">
            <div className=" mb-10 flex h-48 w-48 items-center justify-center rounded-full bg-gray-200">
                <FontAwesomeIcon icon={faHourglassEnd} className="h-24 text-gray-600" />
            </div>
            <span className="text-center text-3xl text-gray-800">
                <Trans>Your trial has ended</Trans>
            </span>
            <span className="text-center text-gray-500">
                <Trans>Upgrade to continue using WEDO</Trans>
            </span>
        </div>
    );
};

export const TrialEndedPage = () => {
    const navigate = useNavigate();
    const { data: organisation } = useGetOrganizationQuery();
    const { confirm } = useConfirm();

    const [clearTrial] = useClearOnboardingTrialMutation();

    useEffect(() => {
        const isOrganisationInTrial = organisation?.free_trial_end_date && organisation?.status === 'in_trial';
        const trialEndDate = new Date(organisation?.free_trial_end_date ?? new Date());
        const daysRemaining = differenceInDays(trialEndDate, new Date()) + 1;

        if (!isOrganisationInTrial || daysRemaining > 0) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    const startNewTrial = async () => {
        const response = await clearTrial(organisation);
        if ('data' in response) {
            navigate('/');
        }
    };

    const handleStartNewTrial = () => {
        void confirm({
            type: 'danger',
            title: t`Just double checking`,
            content: t`By starting a new trial, all tasks, meetings and workspaces will be deleted from your current trial.
                    All users will maintain their access.`,
            confirmText: t`I understand, delete everything`,
            onConfirm: startNewTrial,
        });
    };

    return (
        <OnboardingLayout>
            <OnboardingLayout.Page>
                <OnboardingLayout.Form hideSteps>
                    <div className="flex h-full flex-col items-center justify-center gap-4">
                        <span className="text-center text-3xl text-gray-800">
                            <Trans>Your trial is over</Trans>
                        </span>

                        <span className="text-center text-gray-600">
                            <Trans>Choose a subscription to continue where you left</Trans>
                        </span>

                        <div className="flex flex-wrap justify-center gap-2">
                            <Button color="primary" onClick={() => navigate(SUBSCRIPTION_ADDRESS_STEP_URL)}>
                                <Trans>Choose a subscription</Trans>
                            </Button>

                            <Button color="success" onClick={handleStartNewTrial}>
                                <Trans>Start new trial</Trans>
                            </Button>
                        </div>

                        <Button
                            variant="ghost"
                            href="mailto:info@wedo.com"
                            className="mt-2 text-sm text-blue-500 hover:text-blue-700 hover:underline"
                        >
                            info@wedo.com
                        </Button>
                    </div>
                </OnboardingLayout.Form>

                <OnboardingLayout.Preview>
                    <TrialPageCard />
                </OnboardingLayout.Preview>
            </OnboardingLayout.Page>
        </OnboardingLayout>
    );
};
