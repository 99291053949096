import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

export const useInputState = (
    initialValue = ''
): [
    string,
    Dispatch<SetStateAction<string>>,
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void
] => {
    const [field, setField] = useState(initialValue);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string): void => {
        let fieldValue = e;

        if (typeof e !== typeof '') {
            fieldValue = (e as ChangeEvent<HTMLInputElement>).target.value;
        }

        setField(fieldValue as string);
    };

    return [field, setField, handleChange];
};
