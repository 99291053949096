import { flip, offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import React, { FC, ReactNode, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MessageDescriptor } from '@lingui/core';
import { Editor } from 'slate';
import { Button } from '@wedo/design-system';
import { preventDefault, preventOverflowMiddleware, restrictSizeMiddleware } from '@wedo/utils';
import { trans } from 'Shared/components/editor/plugins/toolbarPlugin/toolbarPlugin';
import { isInVoid } from 'Shared/components/editor/utils/block';
import { isEditorEmpty } from 'Shared/components/editor/utils/slate';

type ToolbarCustomItemProps = {
    editor: Editor;
    icon: IconDefinition;
    title?: MessageDescriptor;
    label?: MessageDescriptor;
    children: ReactNode;
    isActive?: boolean;
    isDropDownItem?: boolean;
};
export const ToolbarCustomItem: FC<ToolbarCustomItemProps> = ({
    editor,
    icon,
    title,
    label,
    isActive = false,
    children,
    isDropDownItem = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'bottom-start',
        middleware: [offset(6), flip(), shift(), preventOverflowMiddleware, restrictSizeMiddleware],
    });

    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Button
                ref={refs.setReference}
                variant="text"
                disabled={isEditorEmpty(editor) || isInVoid(editor)}
                active={isActive}
                icon={icon}
                size="sm"
                title={title && trans(title)}
                onClick={handleToggleMenu}
                onMouseDown={preventDefault()}
                className="!justify-start !gap-3 !text-gray-800 disabled:opacity-40 w-full min-h-[1.875rem]"
                iconClassName="text-gray-600"
                {...getReferenceProps()}
            >
                {label && trans(label)}
            </Button>
            {isOpen && (
                <>
                    {isDropDownItem ? (
                        <div className="flex flex-col gap-1 p-1 text-sm w-full pl-7" {...getFloatingProps()}>
                            {children}
                        </div>
                    ) : (
                        <div
                            ref={refs.setFloating}
                            style={floatingStyles}
                            className="flex flex-col gap-1 rounded-md border border-gray-300 bg-white p-1 text-sm shadow-md z-10 overflow-y-auto"
                            {...getFloatingProps()}
                        >
                            {children}
                        </div>
                    )}
                </>
            )}
        </>
    );
};
