import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { faEnvelopeCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, EmptyState, Table, Tooltip, useConfirm, Skeleton, FormatDateDistance } from '@wedo/design-system';
import { getFormattedDate } from '@wedo/utils';
import { AuthorizedEmail } from 'Pages/settings/authorizedEmails/types/authorizedEmail';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useDeleteAuthorizedEmailMutation, useGetAuthorizedEmailsQuery } from 'Shared/services/authorizedEmails';

type ModalContentProps = {
    emailAddress: string;
    name: string;
};

const ModalContent: React.FC<ModalContentProps> = ({ emailAddress, name }) => (
    <Trans>
        Are you sure you want to delete the authorized email <span className="bold font-mono">{emailAddress}</span>{' '}
        under the name <span className="bold">{name}</span>?
    </Trans>
);

export const AuthorizedEmailsTable: React.FC = () => {
    const { data: authorizedEmails, isLoading } = useGetAuthorizedEmailsQuery();
    const { confirm } = useConfirm();
    const {
        i18n: { locale },
    } = useLingui();

    const [deleteEmail] = useDeleteAuthorizedEmailMutation();

    const handleDeleteEmail = useCallback(
        async (email: AuthorizedEmail) => {
            await confirm({
                type: 'danger',
                title: t`Delete authorized email`,
                content: <ModalContent emailAddress={email.email} name={email.name} />,
                confirmText: t`Delete email`,
                onConfirm: () => deleteEmail(email).unwrap(),
            });
        },
        [confirm, deleteEmail]
    );

    if (isLoading) {
        return <Skeleton className="h-48" />;
    }

    if (isEmpty(authorizedEmails)) {
        return (
            <EmptyState icon={faEnvelopeCircleCheck}>
                <EmptyState.Text>
                    <Trans>No authorized emails</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <Table striped hoverable>
            <Table.Head>
                <Table.HeadCell>
                    <Trans>Name</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Email</Trans>
                </Table.HeadCell>
                <Table.HeadCell className={'@lg:block hidden'}>
                    <Trans>Created by</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="w-20" />
            </Table.Head>

            <Table.Body>
                {authorizedEmails &&
                    authorizedEmails.map((email) => (
                        <Table.Row key={email.email}>
                            <Table.Cell>{email.name}</Table.Cell>
                            <Table.Cell className="font-mono">{email.email}</Table.Cell>
                            <Table.Cell className={'@lg:block hidden'}>
                                <div className="flex gap-4">
                                    <span className="flex shrink-0 flex-row flex-nowrap items-center justify-between gap-2 p-0.5">
                                        <UserAvatar user={email.user} size="sm" />
                                    </span>
                                    <Tooltip
                                        content={
                                            <Trans>
                                                Created by {email.user.full_name} on{' '}
                                                {getFormattedDate(new Date(email.created_at), locale)}
                                            </Trans>
                                        }
                                    >
                                        <div>
                                            <div>{email.user.full_name}</div>
                                            <FormatDateDistance date={email.created_at} />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <Button
                                    icon={faTrash}
                                    onClick={() => handleDeleteEmail(email)}
                                    title={t`Delete`}
                                    data-testid={`delete-button-${email.email}`}
                                    color="danger"
                                    variant="outlined"
                                >
                                    <span className="@lg:block hidden">
                                        <Trans>Delete</Trans>
                                    </span>
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
            </Table.Body>
        </Table>
    );
};
