import React from 'react';
import clsx from 'clsx';

export const Title = ({
    level = 1,
    className = '',
    children,
}: {
    level?: number;
    className?: string;
    children: JSX.Element[] | JSX.Element;
}): JSX.Element => {
    switch (level) {
        case 1:
            return <h3 className={clsx('text-lg font-medium leading-6 text-gray-900 mb-6', className)}>{children}</h3>;
        case 2:
            return <h4 className={clsx('text-base font-medium text-gray-700 mb-2', className)}>{children}</h4>;
        default:
            return <div>{children}</div>;
    }
};
