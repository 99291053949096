import { Children, ReactNode, startTransition, useEffect, useMemo, useState } from 'react';

const DefaultChunkSize = 5;

type DeferredProps = {
    chunkSize?: number;
    children: ReactNode;
};

export const Deferred = ({ chunkSize = DefaultChunkSize, children }: DeferredProps) => {
    const [renderedElementCount, setRenderedElementCount] = useState(chunkSize);

    const childrenArray = useMemo(() => Children.toArray(children), [children]);

    useEffect(() => {
        if (renderedElementCount < childrenArray.length) {
            startTransition(() => {
                setRenderedElementCount(Math.min(renderedElementCount + chunkSize, childrenArray.length));
            });
        }
    }, [renderedElementCount, childrenArray, chunkSize]);

    return childrenArray.slice(0, renderedElementCount);
};
