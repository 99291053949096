import { t, Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Modal, useModal } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';

export const SignatureQuotaAttainedModal = ({ ...modalProps }: ContextModalProps) => {
    const currentUser = useSessionUser();
    const navigate = useNavigate();
    const { closeAnyOpenModals } = useModal();

    const handleIncreaseQuota = () => {
        closeAnyOpenModals();
        navigate('/settings/billing?manage=signatureQuota');
    };
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Signature quota reached`} />
            <Modal.Body>
                <Alert type={'warning'} title={t`No remaining signatures`}>
                    {currentUser.role === 'ADMIN' ? (
                        <Trans>
                            The signature quota has been reached. Please increase the quota in order to allow more
                            documents to be signed.
                        </Trans>
                    ) : (
                        <Trans>
                            The signature quota has been reached. Please contact an administrator to increase the quota.
                        </Trans>
                    )}
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                {currentUser.role === 'ADMIN' && (
                    <Button color={'primary'} onClick={handleIncreaseQuota}>
                        <Trans>Increase the quota</Trans>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
