import { Id } from '@wedo/types';

export const getIdMapping = <T extends { id: Id }>(items: Array<T>): Map<Id, T> =>
    new Map<Id, T>(items?.map((item) => [item.id, item]));

export const getKeyMapping = <T, K extends keyof T>(items: Array<T>, key: K = 'id' as K): Map<T[K], T> =>
    new Map(items.map((item) => [item[key], item]));

export const getIdToIndexMapping = <T, K extends keyof T>(items: Array<T>, key: K = 'id' as K): Map<T[K], number> =>
    new Map(items.map((item, index) => [item[key], index]));
