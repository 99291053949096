import { UserRole } from 'Shared/types/user';
import { type Member } from 'Shared/types/userGroup';

export const getAllModerators = (members: Array<Member>) => {
    return members?.filter((member) => member.is_moderator || member.user?.role === UserRole.ADMIN);
};

export const getMemberDifference = (members1: Array<Member> = [], members2: Array<Member> = []) => {
    return members1?.filter(
        ({ user_id }) => !members2?.some(({ user_id: member2UserId }) => user_id === member2UserId)
    );
};

export const isLastModerator = (member: Member, members: Array<Member>) => {
    const numberOfModerators = getAllModerators(members)?.length ?? 0;
    return member.is_moderator && numberOfModerators === 1 && member?.user?.role !== UserRole.LIGHT;
};
