import React, { ChangeEvent } from 'react';
import { Label, Radio } from '@wedo/design-system';

type RadioItemProps = {
    id: string;
    name: string;
    label: string;
    isChecked: boolean;
    isDisabled?: boolean;
    onChange: (checked: boolean) => void;
};

export const RadioItem = ({ id, name, label, isChecked, isDisabled = false, onChange }: RadioItemProps) => {
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.checked);

    return (
        <div className="flex items-center">
            <Radio id={id} name={name} checked={isChecked} disabled={isDisabled} onChange={handleChange} />
            <Label disabled={isDisabled} htmlFor={id} color="text-black" className="ml-3" inputType="inline">
                {label}
            </Label>
        </div>
    );
};
