import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isAfter, isBefore, startOfDay, isEqual } from 'date-fns';
import { useStore } from 'zustand';
import appStore from 'App/store';
import { meetingApi } from 'Shared/services/meeting';
import { useGanttContextStore } from './GanttContext';
import { type GanttPageParams } from './GanttPage';
import { GlobalDateWindow } from './utils';

export const useMeetings = () => {
    const { workspaceId } = useParams<GanttPageParams>();

    const store = useGanttContextStore()!;

    const minDateString = useStore(store, ({ data }) => data.dateWindows.get(GlobalDateWindow)!.minDate);

    const maxDateString = useStore(store, ({ data }) => data.dateWindows.get(GlobalDateWindow)!.maxDate);

    const { data } = useQuery({
        queryKey: [['meetings', workspaceId]],
        staleTime: 0,
        queryFn: () =>
            appStore
                .dispatch(
                    meetingApi.endpoints.getMeetings.initiate({
                        related: ['meetingUsers', 'meetingUsers.user', 'extendedStatus'],
                        tagId: workspaceId!,
                        orderBy: 'start_at_asc',
                    })
                )
                .then((result) => result?.data),
    });

    return useMemo(() => {
        const minDate = startOfDay(new Date(minDateString));
        const maxDate = startOfDay(new Date(maxDateString));
        return data?.filter((meeting) => {
            const meetingStartAt = startOfDay(new Date(meeting.start_at));
            const meetingEndAt = startOfDay(new Date(meeting.end_at));
            return (
                (isAfter(meetingStartAt, minDate) || isEqual(meetingStartAt, minDate)) &&
                (isBefore(meetingEndAt, maxDate) || isEqual(meetingEndAt, maxDate))
            );
        });
    }, [data, minDateString, maxDateString]);
};
