import React, { FC, PropsWithChildren } from 'react';
import { plural, t } from '@lingui/macro';
import { ContextModalProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskSelectModal } from 'Shared/components/task/TaskSelectModal';
import { trpc } from 'Shared/trpc';

type ImportTasksModalProps = {
    meetingId: Id;
    workspaceId?: Id;
    onDone: (taskIds: Id[]) => void;
} & ContextModalProps &
    PropsWithChildren;

export const ImportTasksModal: FC<ImportTasksModalProps> = ({
    meetingId,
    workspaceId,
    onDone,
    children,
    ...modalProps
}) => {
    const { mutateAsync: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    return (
        <TaskSelectModal
            onDone={(taskIds) => {
                void registerBadgeActivity('IMPORT_TOPIC_TASK_1');
                onDone(taskIds);
            }}
            confirmText={(selectedTasks: number) =>
                plural(selectedTasks, {
                    one: `Import 1 task`,
                    other: `Import ${selectedTasks} tasks`,
                })
            }
            modalTitle={t`Click on tasks you want to import`}
            meetingId={meetingId}
            workspaceId={workspaceId}
            {...modalProps}
        >
            {children}
        </TaskSelectModal>
    );
};
