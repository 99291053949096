import { create } from 'zustand';
import { CommentsWrapper } from 'Shared/components/editor/plugins/commentPlugin/CommentsWrapper';
import { type Plugin } from '../../Editor';
import { selectedBlockIndex } from '../../utils/block';
import { Comments } from './Comments';
import { createStore } from './store';

export const useFocusedCommentIdStore = create<string>()(() => null);

type CommentPluginProps = {
    topicId: string;
    isStatic: boolean;
};

export const commentPlugin = ({ topicId, isStatic }: CommentPluginProps): Plugin => {
    const store = createStore();
    return {
        onKeyDown: (editor, event) => {
            // Comment plugin adds DIVs under each block, thus, even if DIVs are non editable, Slate thinks it can move
            // the cursor to it, we prevent this behavior
            if (event.key === 'ArrowDown' && selectedBlockIndex(editor) === editor.children.length - 1) {
                event.preventDefault();
                return true;
            }
            return false;
        },
        render: () => {
            return <Comments key="commentPlugin" store={store} topicId={topicId} isStatic={isStatic} />;
        },
        renderWrapper: (editor, children) => {
            return <CommentsWrapper store={store}>{children}</CommentsWrapper>;
        },
        renderElement: (editor, { element }, previousElement) => {
            const handleRef = (id: string) => (element: HTMLDivElement) => {
                store.setState(({ blockPlaceholders }) => {
                    if (element != null) {
                        blockPlaceholders.set(id, element);
                    } else {
                        blockPlaceholders.delete(id);
                    }
                    return { blockPlaceholders: new Map(blockPlaceholders) };
                });
            };

            return (
                <>
                    {previousElement}
                    {element.id != null && (
                        <div ref={handleRef(element.id)} contentEditable={false} className="relative -mt-2" />
                    )}
                </>
            );
        },
    };
};
