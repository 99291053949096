import React, { useState } from 'react';
import { faBriefcase, faChevronLeft, faChevronRight, faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import validator from 'validator';
import { Button, Input, ItemGroup, Label } from '@wedo/design-system';
import { CountryCode } from '@wedo/types';
import { CountriesThatRequireVatNumber, EmptyString, getPreferredCurrency, isEmpty, isValidEmail } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useOnboardingStore } from 'Pages/onboarding/utils/onboardingStore';
import { Address, useSubscriptionAddressStore } from 'Pages/subscription/hooks/useSubscriptionAddressStore';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import { SUBSCRIPTION_CONFIRMATION_STEP_URL, SUBSCRIPTION_PLAN_STEP_URL } from 'Pages/subscription/utils';
import { CountrySelector } from 'Shared/components/address/CountrySelector';
import { SwitzerlandCantonSelector } from 'Shared/components/address/SwitzerlandCantonSelector';
import { useUpdateOrganizationMutation } from 'Shared/services/organization';
import { ApiError } from 'Shared/types/apiError';
import { Organization } from 'Shared/types/organization';

const Fields = ['company', 'line1', 'zip', 'city', 'country', 'email', 'canton', 'vat', 'phone'] as const;

type AddressFields = (typeof Fields)[number];

const MandatoryFields: Array<keyof Address> = [
    'company',
    'addressLine1',
    'zipCode',
    'country',
    'email',
    'city',
    'phone',
];

export const BillingAddressForm = () => {
    const navigate = useNavigate();

    const { userId, hasExistingSubscription } = useSubscriptionPage();
    const {
        actions: { setAddress },
        ...address
    } = useSubscriptionAddressStore();

    const { setPaymentMethod, setCurrency, setFrequency } = useSubscriptionStore((state) => ({
        setPaymentMethod: state.actions.setPaymentMethod,
        setCurrency: state.actions.setCurrency,
        setFrequency: state.actions.setFrequency,
    }));
    const { token } = useOnboardingStore((store) => ({ token: store.token }));

    const [updateOrganisation, { isLoading: isLoadingUpdateOrganisation }] = useUpdateOrganizationMutation();

    const [isZipCodeInvalid, setIsZipCodeInvalid] = useState<boolean>(false);
    const [isVatNumberInvalid, setIsVatNumberInvalid] = useState<boolean>(false);
    const [isDirty, { add: markDirty, remove: markClean }] = useSet<AddressFields>(new Set());

    const isCantonEmpty = address.country === CountryCode.Switzerland && isEmpty(address.canton);
    const requiresVatNumber = CountriesThatRequireVatNumber.has(address.country);

    const areMandatoryFormFieldsEmpty =
        MandatoryFields.some((field) => isEmpty(address[field])) ||
        isCantonEmpty ||
        (requiresVatNumber && isEmpty(address.vatNumber));

    const isEmailInvalid = !isValidEmail(address.email);
    const isPhoneInvalid = !validator.isMobilePhone(address.phone?.replace(/\s/g, '') ?? EmptyString);
    const isNextDisabled = areMandatoryFormFieldsEmpty || isEmailInvalid || isPhoneInvalid;

    const emailErrorString = isEmpty(address.email)
        ? t`Email can't be empty`
        : isEmailInvalid
          ? t`Email format is invalid`
          : EmptyString;

    const zipCodeErrorString = isEmpty(address.zipCode)
        ? t`Zip code can't be empty`
        : isZipCodeInvalid
          ? t`Invalid zip code`
          : EmptyString;

    const vatNumberErrorString = isEmpty(address.vatNumber)
        ? t`VAT number can't be empty`
        : isVatNumberInvalid
          ? t`Invalid VAT number`
          : EmptyString;

    const phoneNumberErrorString = isEmpty(address.phone)
        ? t`Phone number can't be empty`
        : isPhoneInvalid
          ? t`Invalid phone number`
          : EmptyString;

    const markAllFieldsAsDirty = () => {
        for (const field of Fields) {
            markDirty(field);
        }
    };

    const clearAllValidationErrors = () => {
        setIsZipCodeInvalid(false);
        setIsVatNumberInvalid(false);
    };

    const handleCountry = (country: CountryCode) => {
        setIsVatNumberInvalid(false);
        markClean('vat');
        setAddress({
            ...address,
            country,
            ...(!CountriesThatRequireVatNumber.has(country) ? { vatNumber: undefined } : {}),
            ...(country !== CountryCode.Switzerland ? { canton: undefined } : {}),
        });
        if (!hasExistingSubscription) {
            setCurrency(getPreferredCurrency(country));
        }
        if (country === CountryCode.Switzerland && !hasExistingSubscription) {
            setPaymentMethod('invoice');
            setFrequency('yearly');
        } else if (country !== CountryCode.Switzerland) {
            if (!hasExistingSubscription) {
                setPaymentMethod('card');
            }
        }
    };

    const handleUpdateOrganisation = async () => {
        return updateOrganisation({
            billing_address: {
                city: address.city,
                company: address.company,
                country: address.country,
                email: address.email,
                first_name: address.firstName,
                last_name: address.lastName,
                line1: address.addressLine1,
                line2: address.addressLine2,
                line3: address.addressLine3,
                zip: address.zipCode,
                phone: address.phone,
            },
            cf_canton: address.canton,
            vat_number: address.vatNumber ? address.vatNumber : undefined,
        } as unknown as Organization);
    };

    const handleNext = async () => {
        clearAllValidationErrors();
        markAllFieldsAsDirty();

        if (isNextDisabled) {
            return;
        }

        const response = await handleUpdateOrganisation();
        if ('error' in response) {
            const error = response.error as ApiError;
            if (error.matches({ path: 'billing_address[zip]' })) {
                setIsZipCodeInvalid(true);
            } else if (error.matches({ path: 'vat_number' })) {
                setIsVatNumberInvalid(true);
            }
        } else {
            if (hasExistingSubscription) {
                navigate({ pathname: SUBSCRIPTION_CONFIRMATION_STEP_URL, searchParams: { 'user-id': userId } });
            } else {
                setCurrency(getPreferredCurrency(response.data?.billing_address?.country));
                if (response.data?.billing_address?.country === CountryCode.Switzerland) {
                    setFrequency('yearly');
                }
                navigate({ pathname: SUBSCRIPTION_PLAN_STEP_URL, searchParams: { 'user-id': userId } });
            }
        }
    };

    const handlePrevious = () => {
        if (userId) {
            navigate(`/onboarding_user/${userId}/${token}`);
        } else if (hasExistingSubscription) {
            navigate(SUBSCRIPTION_PLAN_STEP_URL);
        } else {
            navigate('/settings/billing');
        }
    };

    return (
        <>
            <h1 className="mb-10 text-3xl">
                <Trans>Your billing address</Trans>
            </h1>

            <div className="flex flex-col gap-6">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                    <div>
                        <Label htmlFor="first-name">
                            <Trans>First name</Trans>
                        </Label>
                        <Input
                            value={address.firstName}
                            onChange={(e) => setAddress({ firstName: e.target.value })}
                            id="first-name"
                        />
                    </div>

                    <div>
                        <Label htmlFor="last-name">
                            <Trans>Last name</Trans>
                        </Label>
                        <Input
                            value={address.lastName}
                            onChange={(e) => setAddress({ lastName: e.target.value })}
                            id="last-name"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                    <div>
                        <Label isMandatory htmlFor="company">
                            <Trans>Company</Trans>
                        </Label>
                        <Input
                            value={address.company}
                            onChange={(e) => setAddress({ company: e.target.value })}
                            id="company"
                            leadingIcon={faBriefcase}
                            onBlur={() => markDirty('company')}
                            status={isEmpty(address.company) && isDirty.has('company') ? 'error' : 'default'}
                            statusText={
                                isEmpty(address.company) && isDirty.has('company') && t`Company name can't be empty`
                            }
                        />
                    </div>

                    <div>
                        <Label isMandatory htmlFor="phone">
                            <Trans>Phone</Trans>
                        </Label>
                        <Input
                            value={address.phone}
                            onChange={(e) => setAddress({ phone: e.target.value })}
                            id="phone"
                            placeholder="+41 12 234 56 78"
                            leadingIcon={faPhone}
                            onBlur={() => markDirty('phone')}
                            status={
                                isDirty.has('phone') && (isPhoneInvalid || isEmpty(address.phone)) ? 'error' : 'default'
                            }
                            statusText={isDirty.has('phone') && phoneNumberErrorString}
                        />
                    </div>
                </div>

                <div>
                    <Label isMandatory>
                        <Trans>Address</Trans>
                    </Label>
                    <div className="flex flex-col gap-2">
                        <Input
                            value={address.addressLine1}
                            onChange={(e) => setAddress({ addressLine1: e.target.value })}
                            placeholder={t`Address line 1`}
                            onBlur={() => markDirty('line1')}
                            status={isEmpty(address.addressLine1) && isDirty.has('line1') ? 'error' : 'default'}
                            statusText={
                                isEmpty(address.addressLine1) && isDirty.has('line1') && t`Address can't be empty`
                            }
                        />
                        {!isEmpty(address.addressLine1) && (
                            <Input
                                value={address.addressLine2}
                                onChange={(e) => setAddress({ addressLine2: e.target.value })}
                                placeholder={t`Address line 2`}
                            />
                        )}
                        {!isEmpty(address.addressLine1) && !isEmpty(address.addressLine2) && (
                            <Input
                                value={address.addressLine3}
                                onChange={(e) => setAddress({ addressLine3: e.target.value })}
                                placeholder={t`Address line 3`}
                            />
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                    <div>
                        <Label isMandatory htmlFor="zip-code">
                            <Trans>Zip code</Trans>
                        </Label>
                        <Input
                            value={address.zipCode}
                            onChange={(e) => setAddress({ zipCode: e.target.value })}
                            id="zip-code"
                            placeholder={t`Zip/postal code`}
                            onBlur={() => markDirty('zip')}
                            status={
                                (isEmpty(address.zipCode) || isZipCodeInvalid) && isDirty.has('zip')
                                    ? 'error'
                                    : 'default'
                            }
                            statusText={zipCodeErrorString}
                        />
                    </div>

                    <div>
                        <Label isMandatory htmlFor="city">
                            <Trans>City</Trans>
                        </Label>
                        <Input
                            value={address.city}
                            onChange={(e) => setAddress({ city: e.target.value })}
                            id="city"
                            status={isEmpty(address.city) && isDirty.has('city') ? 'error' : 'default'}
                            statusText={isEmpty(address.city) && isDirty.has('city') && t`City can't be empty`}
                            onBlur={() => markDirty('city')}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                    <div>
                        <Label isMandatory>
                            <Trans>Country</Trans>
                        </Label>
                        <CountrySelector
                            isDisabled={hasExistingSubscription}
                            value={address.country}
                            onChange={handleCountry}
                            onBlur={() => markDirty('country')}
                            status={isEmpty(address.country) && isDirty.has('country') ? 'error' : 'default'}
                            statusText={
                                isEmpty(address.country) && isDirty.has('country') && t`Country name can't be empty`
                            }
                        />
                    </div>

                    {address.country === 'CH' && (
                        <div>
                            <Label isMandatory>
                                <Trans>Canton</Trans>
                            </Label>
                            <SwitzerlandCantonSelector
                                canton={address.canton}
                                onChange={(canton) => setAddress({ canton })}
                                onBlur={() => markDirty('canton')}
                                status={isCantonEmpty && isDirty.has('canton') ? 'error' : 'default'}
                                statusText={isCantonEmpty && isDirty.has('canton') && t`Canton can't be empty`}
                            />
                        </div>
                    )}

                    {requiresVatNumber && (
                        <div>
                            <Label isMandatory htmlFor="vat-number">
                                <Trans>VAT Number</Trans>
                            </Label>
                            <ItemGroup
                                status={
                                    (isVatNumberInvalid || isEmpty(address.vatNumber)) && isDirty.has('vat')
                                        ? 'error'
                                        : 'default'
                                }
                                statusText={vatNumberErrorString}
                            >
                                <Input.Addon text={address.country} />
                                <Input
                                    id="vat-number"
                                    className="w-full"
                                    value={address.vatNumber}
                                    onChange={(e) => setAddress({ vatNumber: e.target.value })}
                                    onBlur={() => markDirty('vat')}
                                />
                            </ItemGroup>
                        </div>
                    )}
                </div>

                <div>
                    <Label isMandatory htmlFor="email">
                        <Trans>Email address (invoices will be sent to this email)</Trans>
                    </Label>
                    <Input
                        value={address.email}
                        onChange={(e) => setAddress({ email: e.target.value })}
                        id="email"
                        placeholder={`jane.doe@org.com`}
                        leadingIcon={faEnvelope}
                        onBlur={() => markDirty('email')}
                        status={
                            isDirty.has('email') && (isEmpty(address.email) || isEmailInvalid) ? 'error' : 'default'
                        }
                        statusText={emailErrorString}
                    />
                </div>
            </div>

            <div className="mt-10 flex justify-between">
                <Button icon={faChevronLeft} onClick={handlePrevious}>
                    <Trans>Previous</Trans>
                </Button>
                <Button
                    icon={faChevronRight}
                    iconPosition="end"
                    color="primary"
                    onClick={handleNext}
                    loading={isLoadingUpdateOrganisation}
                >
                    <Trans>Next</Trans>
                </Button>
            </div>
        </>
    );
};
