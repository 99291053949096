import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import {
    faClone,
    faEllipsisV,
    faEnvelope,
    faFileExport,
    faHourglass,
    faLink,
    faSquareCheck,
    faTrash,
    faUndoAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    ButtonVariant,
    Dropdown,
    Size,
    Tooltip,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { useMatch, useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useGanttContextStore } from 'Pages/GanttPage/GanttContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { usePendingTasks } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { DuplicateTaskModal } from 'Shared/components/task/TaskDetail/modals/DuplicateTaskModal';
import { TaskEmailToCommentModal } from 'Shared/components/task/TaskDetail/modals/TaskEmailToCommentModal';
import { SelectTaskDependencyModal } from 'Shared/components/task/TaskDetail/rows/taskDependency/SelectTaskDependencyModal';
import { useConfirmDeleteTask } from 'Shared/hooks/useConfirmDeleteTask';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useTask } from 'Shared/hooks/useTask';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Task, TaskType } from 'Shared/types/task';
import { UserFeature } from 'Shared/types/user';
import { Permission } from 'Shared/utils/rbac';

type TaskMenuProps = {
    task: Task;
    meetingId?: Id;
    workspaceId?: Id;
    checklistId?: Id;
    userId?: Id;
    hideTasksToExport?: boolean;
    size?: Size | 'xs';
    variant?: ButtonVariant;
    allowSubTasksDependencies?: boolean;
};
export const TaskDropdown = ({
    task,
    meetingId,
    workspaceId,
    checklistId,
    userId,
    hideTasksToExport = false,
    size = 'sm',
    variant,
    allowSubTasksDependencies,
}: TaskMenuProps) => {
    const ganttContextStore = useGanttContextStore();
    const queryClient = useQueryClient();

    const { network } = useCurrentNetwork();
    const { can, currentUser } = useCurrentUserContext();
    const { open: openModal } = useModal();
    const { show: showNotification } = useNotification();
    const { isMyTasksPage } = useIsMyTasksPage();
    const isOnSingleTaskPage = useMatch(`/tasks/:taskId`);
    const isGanttEnabled = useHasFeature(currentUser, network, UserFeature.Gantt);
    const { taskRecurrence } = useTask(task?.id);
    const { isCompleted: isTaskCompleted } = useTaskStatus(task);
    const { templateId } = useParams();
    const { addToDeletedTasks } = usePendingTasks();
    const { confirmDeleteTask } = useConfirmDeleteTask(task);

    const [{ view, order, grouping, search, status, scope }] = useSearchParams(TasksPageSearchParams);

    const { mutateAsync: convert } = trpc.task.updateType.useMutation({ onSuccess: invalidateCachedTasks });
    const { mutateAsync: updateStatus } = trpc.task.updateStatus.useMutation({
        onSuccess: () => setTimeout(() => invalidateCachedTasks(), 500),
    });
    const { mutate: addTaskDependencies } = trpc.task.addDependencies.useMutation({
        onSuccess: async () => {
            if (ganttContextStore == null) {
                setTimeout(invalidateCachedTasks, 100);
            } else {
                void trpcUtils().task.listDependencies.invalidate();
            }
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });

    const canExportTask =
        (workspaceId != null || checklistId != null || userId != null || task?.id != null) && meetingId == null;

    const isMilestone = task?.type === TaskType.Milestone;

    const handleCopyPermalink = () => {
        const l = window.location;
        let permalink = l.protocol + '//' + l.hostname + (l.port ? ':' + l.port : '');
        permalink += '/tasks/' + task.id;
        void navigator.clipboard.writeText(permalink);
        showNotification({ type: 'success', title: t`Link copied to clipboard` });
    };

    const handleAddDependency = () => {
        openModal(SelectTaskDependencyModal, {
            taskId: task.id,
            onDone: (taskIds) =>
                addTaskDependencies(
                    taskIds?.map((id) => ({ blockedTaskId: Number(task.id), blockingTaskId: Number(id) }))
                ),
            workspaceId: isMyTasksPage ? '-1' : workspaceId,
            templateId,
            checklistId,
            userId,
            allowSubTasksDependencies,
        });
    };

    const handleExport = () => {
        openModal(ExportTasksModal, {
            checklistId,
            grouping: grouping ?? 'default',
            hideTasksToExport: isOnSingleTaskPage || hideTasksToExport,
            initialSelectedTasksToExport: 'selected-tasks',
            meetingId,
            order: order ?? 'default',
            scope,
            search,
            selectedTasks: [task?.id],
            statuses: status,
            templateId,
            userId,
            view,
            workspaceId,
        });
    };

    const handleConvert = async (to: TaskType) => {
        const response = await convert({ taskId: Number(task?.id), to });
        if (response && 'error' in response) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const handleDeleteTask = async () => {
        const { confirm: shouldDelete, applyOn } = await confirmDeleteTask();
        if (!shouldDelete) {
            return;
        }

        await updateStatus({ taskIds: [task.id], deleted: true, applyOn });
        if (ganttContextStore != null) {
            const queryTag =
                task.parent_task_id != null
                    ? taskQueryTag.updated(task.parent_task_id, 'subTasks')
                    : taskQueryTag.removed();
            await invalidateQueries(queryClient, [queryTag]);
        }
        addToDeletedTasks(task.id);
    };

    const handleRestore = async () => {
        await updateStatus({ taskIds: [task.id], deleted: false, applyOn: ApplyOn.OnlyCurrentTask });
    };

    return (
        <Dropdown icon={faEllipsisV} title={t`Task options`} size={size} disabled={!task} variant={variant}>
            {!meetingId && can(Permission.ManageTasks) && (
                <Tooltip
                    content={
                        taskRecurrence != null && isTaskCompleted
                            ? t`It is not possible to duplicate a recurring task that is completed`
                            : null
                    }
                >
                    <Dropdown.Item
                        disabled={taskRecurrence != null && isTaskCompleted}
                        icon={faClone}
                        onClick={() =>
                            openModal(DuplicateTaskModal, {
                                taskId: task.id,
                                meetingId,
                                checklistId,
                                templateId,
                                workspaceId,
                            })
                        }
                    >
                        {isMilestone ? t`Duplicate milestone` : t`Duplicate task`}
                    </Dropdown.Item>
                </Tooltip>
            )}
            <Dropdown.Item icon={faHourglass} onClick={handleAddDependency}>
                <Trans>Add dependency</Trans>
            </Dropdown.Item>
            <Dropdown.Item
                icon={faEnvelope}
                onClick={() => openModal(TaskEmailToCommentModal, { task })}
            >{t`Email to comment`}</Dropdown.Item>
            <Dropdown.Item icon={faLink} onClick={handleCopyPermalink}>
                {isMilestone ? t`Copy milestone permalink` : t`Copy task permalink`}
            </Dropdown.Item>

            {can(Permission.ManageTasks) && isGanttEnabled && !isMyTasksPage && task?.parent_task_id == null && (
                <>
                    {isMilestone ? (
                        <Dropdown.Item icon={faSquareCheck} onClick={() => handleConvert(TaskType.Task)}>
                            <Trans>Convert to task</Trans>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item icon="openMilestone" onClick={() => handleConvert(TaskType.Milestone)}>
                            <Trans>Convert to milestone</Trans>
                        </Dropdown.Item>
                    )}
                </>
            )}

            {canExportTask && (
                <Dropdown.Item icon={faFileExport} onClick={handleExport}>
                    {isMilestone ? <Trans>Export milestone</Trans> : <Trans>Export task</Trans>}
                </Dropdown.Item>
            )}

            {can(Permission.ManageTasks) && (
                <>
                    <Dropdown.DividerItem />

                    {task?.deleted ? (
                        <Dropdown.Item onClick={handleRestore} icon={faUndoAlt}>
                            {isMilestone ? <Trans>Restore milestone</Trans> : <Trans>Restore task</Trans>}
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item danger onClick={handleDeleteTask} icon={faTrash}>
                            {isMilestone ? t`Delete milestone` : t`Delete task`}
                        </Dropdown.Item>
                    )}
                </>
            )}
        </Dropdown>
    );
};
