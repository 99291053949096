import React from 'react';
import { t } from '@lingui/macro';
import { Modal } from '@wedo/design-system';
import { CustomFieldButton } from 'Pages/settings/customFields/components/AddCustomFieldModal/CustomFieldButton';
import { CustomFieldType } from 'Shared/types/customField';
import { customFieldTypeToIcon } from 'Shared/utils/customFields';

interface CustomFieldSelectionSectionProps {
    onClick: (fieldType: CustomFieldType) => void;
}

export const CustomFieldSelectionSection: React.FC<CustomFieldSelectionSectionProps> = ({ onClick }) => {
    return (
        <>
            <Modal.Header title={t`Add custom field`} />

            <Modal.Body>
                <div className="grid gap-4 sm:grid-cols-2">
                    <CustomFieldButton
                        icon={customFieldTypeToIcon[CustomFieldType.ShortText]}
                        label={t`Short text`}
                        onClick={() => onClick(CustomFieldType.ShortText)}
                    />
                    <CustomFieldButton
                        icon={customFieldTypeToIcon[CustomFieldType.Number]}
                        label={t`Number`}
                        onClick={() => onClick(CustomFieldType.Number)}
                    />
                    <CustomFieldButton
                        icon={customFieldTypeToIcon[CustomFieldType.Date]}
                        label={t`Date`}
                        onClick={() => onClick(CustomFieldType.Date)}
                    />
                    <CustomFieldButton
                        icon={customFieldTypeToIcon[CustomFieldType.MultipleChoice]}
                        label={t`Multiple choice`}
                        onClick={() => onClick(CustomFieldType.MultipleChoice)}
                    />
                    <CustomFieldButton
                        icon={customFieldTypeToIcon[CustomFieldType.Dropdown]}
                        label={t`Dropdown list`}
                        onClick={() => onClick(CustomFieldType.Dropdown)}
                    />
                    <CustomFieldButton
                        icon={customFieldTypeToIcon[CustomFieldType.Attachment]}
                        label={t`Attachment`}
                        onClick={() => onClick(CustomFieldType.Attachment)}
                    />
                </div>
            </Modal.Body>
        </>
    );
};
