import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Form, Input, Modal, Textarea } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useGetUserQuery } from 'Shared/services/user';
import { MeetingUser } from 'Shared/types/meetingUser';

type AttendeeEditModelProps = {
    attendee: MeetingUser;
    onDone: (data?: { id: Id; changes: Partial<MeetingUser> }) => void;
} & ContextModalProps;
export const AttendeeEditModal = ({ attendee, onDone, ...modalProps }: AttendeeEditModelProps): JSX.Element => {
    const { data: user } = useGetUserQuery(attendee?.user_id ?? null);

    const [externalFullName, setExternalFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [title, setTitle] = useState('');
    const [department, setDepartment] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [location, setLocation] = useState('');

    useEffect(() => {
        if (attendee) {
            setEmail(
                attendee.user?.email_address ||
                    attendee.user?.userEmail?.email_address ||
                    attendee.user_data?.email_address
            );
            setPhone(attendee.user_data.phone_number || '');
            setExternalFullName(attendee.user_data.external_full_name || '');
            setTitle(attendee.user_data.title || '');
            setDepartment(attendee.user_data.department || '');
            setOrganizationName(attendee.user_data.organisation_name || '');
            setLocation(attendee.user_data.location || '');
        }
    }, [attendee]);

    const handleSave = () => {
        onDone({
            id: attendee.id,
            changes: {
                user_data: {
                    ...attendee.user_data,
                    ...(attendee.user_id == null ? { email_address: email } : {}),
                    phone_number: phone,
                    external_full_name: externalFullName,
                    title: title,
                    department: department,
                    organisation_name: organizationName,
                    location: location,
                },
            },
        });
        modalProps.close();
    };

    if (!attendee) {
        return <div />;
    }

    return (
        <Modal size="md" {...modalProps}>
            <Modal.Header title={t`Edit attendee`} />
            <Modal.Body>
                <Form onSubmit={handleSave}>
                    {user && attendee.user_id != null ? (
                        <>
                            <Form.Item label={t`First name`} htmlFor="first-name" cols={3}>
                                <Input id={'first-name'} value={user?.first_name} disabled />
                            </Form.Item>
                            <Form.Item label={t`Last name`} htmlFor="last-name" cols={3}>
                                <Input id={'last-name'} value={user?.last_name} disabled />
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item label={t`Full name`} htmlFor="full-name" cols={6}>
                            <Input
                                id={'full-name'}
                                value={externalFullName}
                                onChange={(e) => setExternalFullName(e.target.value)}
                                type="text"
                            />
                        </Form.Item>
                    )}

                    <Form.Item label={t`Email address`} htmlFor="email" cols={3}>
                        <Input
                            id={'email'}
                            maxLength={300}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={attendee.user_id != null}
                        />
                    </Form.Item>
                    <Form.Item label={t`Phone number`} htmlFor="phone" cols={3}>
                        <Textarea
                            id={'phone'}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            maxLength={300}
                        />
                    </Form.Item>
                    <Form.Item label={t`Job title`} htmlFor="job-title" cols={3}>
                        <Input id={'job-title'} onChange={(e) => setTitle(e.target.value)} value={title} type="text" />
                    </Form.Item>
                    <Form.Item label={t`Department`} htmlFor="department" cols={3}>
                        <Input
                            id={'department'}
                            onChange={(e) => setDepartment(e.target.value)}
                            value={department}
                            type="text"
                        />
                    </Form.Item>
                    <Form.Item label={t`Organization`} htmlFor="organization" cols={3}>
                        <Input
                            id={'organization'}
                            onChange={(e) => setOrganizationName(e.target.value)}
                            value={organizationName}
                            type="text"
                        />
                    </Form.Item>
                    <Form.Item label={t`City, Country`} htmlFor="location" cols={3}>
                        <Textarea
                            id={'location'}
                            onChange={(e) => setLocation(e.target.value)}
                            value={location}
                            maxLength={300}
                        />
                    </Form.Item>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={handleSave}>
                    <Trans>Save attendee</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
