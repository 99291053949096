import { FC, PropsWithChildren } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { t } from '@lingui/macro';
import { Id } from '@wedo/types';
import { getAuthToken, tryOrNull } from '@wedo/utils';
import { trpc, trpcUtils } from 'Shared/trpc';
import { User } from 'Shared/types/user';

type UsersState = {
    users: Array<User>;
    usersById: Record<Id, User>;
};

const selectUsersQuery = (users: Array<User>) => {
    return {
        users,
        usersById: Object.fromEntries(users.map((user: User) => [user.id.toString(), user])),
    };
};

const useUsersQuery = () => {
    return trpc.user.list.useQuery(null, {
        suspense: true,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        notifyOnChangeProps: ['data'],
        select: selectUsersQuery,
        trpc: { context: { skipBatch: true } },
    });
};

export const UsersStore: FC<PropsWithChildren> = ({ children }) => {
    const { isSuccess, data } = useUsersQuery();
    // FIXME: this conditional check has been added as a hack and should eventually be removed once the app authentication state is correctly managed
    if (!isSuccess || data.usersById == null) {
        return null;
    }
    return <>{children}</>;
};

const sessionUserId = () => {
    return tryOrNull(() => {
        const [, tokenData] = getAuthToken().split('.', 3);
        return JSON.parse(atob(tokenData)).id;
    });
};

const selectUser = (state: UsersState, userId: Id) => {
    const UnknownUser: Partial<User> = {
        id: 'unknown',
        full_name: t`Unknown user`,
        title: '',
    };
    if (state?.usersById == null || userId == null || userId === 0 || isNaN(Number(userId))) {
        return null;
    }
    const user = state.usersById[userId.toString()];
    if (user == null) {
        const currentUserId = sessionUserId();
        if (state.usersById[currentUserId]?.role !== 'EXTERNAL') {
            datadogRum.addError(new Error(`User ${userId} not found`), { fingerprint: ['user.notFound'] });
        }
        return UnknownUser as User;
    }
    return user;
};

export const selectActiveUsers = (state: UsersState) => {
    return state.users?.filter(
        (user) => user.status === 'ACTIVE' || user.status === 'INVITED' || user.status === 'PENDING'
    );
};

export const getUser = (userId: Id) => {
    return selectUser(selectUsersQuery(trpcUtils().user.list.getData(null)), userId);
};

export const getUsers = () => {
    return trpcUtils().user.list.getData(null);
};

export const getSessionUser = () => {
    return getUser(sessionUserId());
};

export const useUser = (userId: Id) => {
    return selectUser(useUsersQuery().data, userId);
};

export const useUsers = () => {
    return useUsersQuery().data.users;
};

export const useActiveUsers = () => {
    return selectActiveUsers(useUsersQuery().data);
};

export const useSessionUser = () => {
    return useUser(sessionUserId());
};
