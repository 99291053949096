import { Svg, Path, G } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const NetflixIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" style={style} fill="none">
            <G transform="translate(12.494543 6.7556334)" fill="none">
                <G transform="translate(81.631476 113.77111) scale(.45)" fill="none">
                    <Path
                        fill="#b1060f"
                        fillRule="evenodd"
                        stroke="#000"
                        d="m451.82617-188.60352-.36914 210.000004-.37109 209.999996-17.27735-48.75c-.006-.0168-.0273-.0789-.0332-.0957l-22.32617 467.51563c21.95088 61.98892 33.71165 95.08081 33.84375 95.21289.17284.1729 12.68225.92753 27.79883 1.67773 45.79984 2.2727 102.55492 7.14269 145.60937 12.49219 9.96875 1.2385 18.54796 1.87275 19.06445 1.41016.5165-.4626.79775-214.28135.625-475.15235l-.31445-474.31055h-186.25zm-336.875-.625V285.5625c0 261.1354.25552 475.04843.56836 475.36133.31282.3128 16.4847-1.21398 35.9375-3.39258 19.45278-2.1787 46.33789-4.88783 59.74414-6.01953 20.56097-1.7358 81.94995-5.68286 89.0586-5.72656 2.06786-.013 2.20049-10.68157 2.5-201.10157l.3164-201.08789 14.85352 42.02539c2.29551 6.49543 3.01555 8.52378 5.25195 14.85157l22.32031-467.400394c-4.72927-13.371182-2.25012-6.352022-7.66992-21.675782-18.23693-51.562504-33.71225-95.296904-34.39062-97.187504l-1.23438-3.4375H114.95117z"
                    />
                    <Path
                        fill="url(#b)"
                        fillRule="evenodd"
                        d="M114.95117-189.22852V77.285156l187.7461 498.023434c.0196-11.41266.042-17.57934.0625-30.625l.3164-201.08789 14.85352 42.02539c82.68109 233.9563 127.10203 359.39701 127.36328 359.65821.17284.1729 12.68225.92753 27.79883 1.67773 45.79984 2.2727 102.55492 7.14269 145.60937 12.49219 9.96875 1.2385 18.54796 1.87275 19.06445 1.41016.355-.31796.58674-105.15812.64844-253.0586L451.54688-29.443359l-.0899 50.839843-.37109 209.999996-17.27735-48.75c-16.88185-47.6341-28.15376-79.49-95.97656-271.249996-18.23693-51.562504-33.71225-95.296904-34.39062-97.187504l-1.23438-3.4375H114.95112z"
                    />
                    <Path
                        fill="#e50914"
                        fillRule="evenodd"
                        d="m114.95508-189.22852 188.12109 533.06641v-.24219l14.85352 42.02539c82.68109 233.9563 127.10203 359.39701 127.36328 359.65821.17284.1729 12.68225.92753 27.79883 1.67773 45.79984 2.2727 102.55492 7.14269 145.60937 12.49219 9.91766 1.23215 18.45293 1.86506 19.04883 1.41601l-186.66406-529.5664v.0976l-17.27735-48.75c-16.88185-47.6341-28.15376-79.49-95.97656-271.249996-18.23693-51.562504-33.71225-95.296904-34.39062-97.187504l-1.23438-3.4375H114.95508z"
                    />
                    <Path
                        fill="#b1060f"
                        fillRule="evenodd"
                        stroke="#000"
                        d="m451.82617-188.60352-.36914 210.000004-.37109 209.999996-17.27735-48.75c-.006-.0168-.0273-.0789-.0332-.0957l-22.32617 467.51563c21.95088 61.98892 33.71165 95.08081 33.84375 95.21289.17284.1729 12.68225.92753 27.79883 1.67773 45.79984 2.2727 102.55492 7.14269 145.60937 12.49219 9.96875 1.2385 18.54796 1.87275 19.06445 1.41016.5165-.4626.79775-214.28135.625-475.15235l-.31445-474.31055h-186.25zm-336.875-.625V285.5625c0 261.1354.25552 475.04843.56836 475.36133.31282.3128 16.4847-1.21398 35.9375-3.39258 19.45278-2.1787 46.33789-4.88783 59.74414-6.01953 20.56097-1.7358 81.94995-5.68286 89.0586-5.72656 2.06786-.013 2.20049-10.68157 2.5-201.10157l.3164-201.08789 14.85352 42.02539c2.29551 6.49543 3.01555 8.52378 5.25195 14.85157l22.32031-467.400394c-4.72927-13.371182-2.25012-6.352022-7.66992-21.675782-18.23693-51.562504-33.71225-95.296904-34.39062-97.187504l-1.23438-3.4375H114.95117z"
                    />
                    <Path
                        fill="url(#c)"
                        fillRule="evenodd"
                        d="M114.95117-189.22852V77.285156l187.7461 498.023434c.0196-11.41266.042-17.57934.0625-30.625l.3164-201.08789 14.85352 42.02539c82.68109 233.9563 127.10203 359.39701 127.36328 359.65821.17284.1729 12.68225.92753 27.79883 1.67773 45.79984 2.2727 102.55492 7.14269 145.60937 12.49219 9.96875 1.2385 18.54796 1.87275 19.06445 1.41016.355-.31796.58674-105.15812.64844-253.0586L451.54688-29.443359l-.0899 50.839843-.37109 209.999996-17.27735-48.75c-16.88185-47.6341-28.15376-79.49-95.97656-271.249996-18.23693-51.562504-33.71225-95.296904-34.39062-97.187504l-1.23438-3.4375H114.95112z"
                    />
                    <Path
                        fill="#e50914"
                        fillRule="evenodd"
                        d="m114.95508-189.22852 188.12109 533.06641v-.24219l14.85352 42.02539c82.68109 233.9563 127.10203 359.39701 127.36328 359.65821.17284.1729 12.68225.92753 27.79883 1.67773 45.79984 2.2727 102.55492 7.14269 145.60937 12.49219 9.91766 1.23215 18.45293 1.86506 19.04883 1.41601l-186.66406-529.5664v.0976l-17.27735-48.75c-16.88185-47.6341-28.15376-79.49-95.97656-271.249996-18.23693-51.562504-33.71225-95.296904-34.39062-97.187504l-1.23438-3.4375H114.95508z"
                    />
                </G>
            </G>
        </Svg>
    );
};
