import React, { FC, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { getRandomWedoColor, TagSelect, type TagSelectProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray, EmptyString, isEmpty } from '@wedo/utils';
import {
    useAddLabelMutation,
    useDeleteLabelMutation,
    useGetLabelsQuery,
    useUpdateLabelMutation,
} from 'Shared/services/label';
import { Label, LabelRelation } from 'Shared/types/label';

type LabelSelectProps = {
    value: Id[];
    onChange: (values: Id[]) => void;
    relation?: Partial<LabelRelation>;
    canAdd?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    loadingLabels?: Set<Id>;
} & Pick<TagSelectProps, 'size' | 'forceSingleLine' | 'inputClassName' | 'placeholder'>;

export const LabelSelect: FC<LabelSelectProps> = ({
    value,
    onChange,
    placeholder = t`Select label(s)`,
    relation,
    canAdd = false,
    canEdit = false,
    canDelete = false,
    forceSingleLine = false,
    size = 'md',
    inputClassName,
    loadingLabels = new Set(),
}) => {
    const { data: labels = EmptyArray as Label[], isLoading: isLoadingLabels } = useGetLabelsQuery();

    const [deleteLabel] = useDeleteLabelMutation();
    const [addLabel, { isLoading: isLoadingAddLabel }] = useAddLabelMutation();
    const [updateLabel] = useUpdateLabelMutation();

    const [search, setSearch] = useState<string>(EmptyString);

    const filteredLabels = useMemo<Label[]>(() => {
        if (isEmpty(search)) {
            return labels;
        }
        return labels.filter((label) => label.name.toLowerCase().trim().includes(search.toLowerCase().trim()));
    }, [search, labels]);

    const handleDeleteLabel = (label: Label) => deleteLabel({ labelId: label.id });

    const handleEditLabel = (label: Label, name: string) => updateLabel({ labelId: label.id, label: { name } });

    const handleColorChange = (label: Label, color: string) => updateLabel({ labelId: label.id, label: { color } });

    const handleCreateLabel = async (name: string) => {
        const response = await addLabel({
            label: { name: name, color: getRandomWedoColor() },
            relation: relation,
        });
        if ('data' in response) {
            onChange([...value, response.data.id]);
            setSearch(EmptyString);
        }
    };

    return (
        <TagSelect
            multiple
            forceSingleLine={forceSingleLine}
            contextValues={labels}
            value={value}
            onChange={onChange}
            search={search}
            onSearch={setSearch}
            placeholder={placeholder}
            size={size}
            inputClassName={inputClassName}
            onCreate={canAdd && handleCreateLabel}
            isLoadingOnCreate={isLoadingAddLabel}
        >
            {filteredLabels?.length === 0 && !isEmpty(search) && labels?.length > 0 && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No labels found</Trans>
                </div>
            )}
            {labels?.length === 0 && !isLoadingLabels && isEmpty(search) && !canAdd && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No labels in network.</Trans>
                </div>
            )}
            {labels?.length === 0 && !isLoadingLabels && isEmpty(search) && canAdd && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No labels in network, start typing to create new labels.</Trans>
                </div>
            )}
            {filteredLabels?.map((label) => (
                <TagSelect.Option
                    isLoading={loadingLabels?.has(label.id)}
                    key={label.id}
                    id={label.id}
                    name={label.name}
                    color={label.color}
                    onRemove={canDelete ? () => handleDeleteLabel(label) : undefined}
                    onEdit={canEdit ? (name: string) => handleEditLabel(label, name) : undefined}
                    onColorChange={canEdit ? (color: string) => handleColorChange(label, color) : undefined}
                />
            ))}
        </TagSelect>
    );
};
