import { useEffect, useState } from 'react';

export const useNotificationServiceWorker = () => {
    const { origin } = window.location;

    const [serviceWorker, setServiceWorker] = useState<ServiceWorkerRegistration>();

    useEffect(() => {
        if (serviceWorker != null) {
            return;
        }
        navigator.serviceWorker
            ?.register(`${origin}/notification-service-worker.js`, { scope: '/' })
            .then(setServiceWorker);
    }, []);

    return serviceWorker;
};
