import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';
import { CircleSearchSelect } from 'Pages/governance/CircleSearchSelect';
import { getCircleBreadcrumb } from 'Pages/governance/utils';
import { useUpdateCircleMutation } from 'Shared/services/governance';
import { Circle, Root } from 'Shared/types/governance';

type MoveCircleModalProps = {
    circles: HierarchyCircularNode<Circle | Root>[];
    circle: HierarchyCircularNode<Circle>;
} & ContextModalProps;

export const MoveCircleModal = ({ circles, circle, ...modalProps }: MoveCircleModalProps) => {
    const [updateCircle] = useUpdateCircleMutation();

    const { title, breadcrumb } = useMemo(
        () => ({
            title: circle.data.type === 'circle' ? t`Move circle` : t`Move role`,
            breadcrumb: getCircleBreadcrumb(circle.data, circles),
        }),
        [circle]
    );

    const [selectedCircle, setSelectedCircle] = useState(null);

    return (
        <Modal {...modalProps}>
            <Modal.Header
                title={
                    <>
                        <div>{title}</div>
                        <div className="text-xs text-gray-700">{breadcrumb}</div>
                    </>
                }
            />
            <Modal.Body>
                <CircleSearchSelect
                    label={t`Destination`}
                    circles={circles.filter((c) => c.id !== circle.id)}
                    value={selectedCircle}
                    setValue={setSelectedCircle}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button
                    disabled={selectedCircle == null}
                    onClick={async () => {
                        await updateCircle({
                            id: circle.data.id,
                            parent_circle_id: selectedCircle !== 'root' ? selectedCircle : null,
                        });
                        modalProps.close();
                    }}
                    color="primary"
                >{t`Move`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
