import { useLingui } from '@lingui/react';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import { Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { Path, Range, Transforms } from 'slate';
import { Icon, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import { onInvalidation } from 'App/store/invalidationStore';
import { useUsers } from 'App/store/usersStore';
import { GroupedTask } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { addComment } from 'Shared/components/editor/plugins/commentPlugin/utils';
import { createParagraphBlock } from 'Shared/components/editor/plugins/paragraphPlugin';
import { createBlock, isBlockEmpty, reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { Task as TaskItem, TaskHandle } from 'Shared/components/task/Task';
import { getIcon, getText } from 'Shared/components/task/TaskActivityLogsModal/TaskActivityLogsValues';
import { buildGetTaskDetailParameters, tag as taskTag, useGetTaskQuery } from 'Shared/services/task';
import { ActivityLog } from 'Shared/types/activityLog';
import { Task as TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { focusEditor, Plugin, useEditorIsFocused } from '../Editor';

export const Task = 'task';

const SelectedClasses = 'ring-2 ring-blue-500 ring-offset-2 group-[.is-dragging]:ring-0';

export const createTaskBlock = (taskId?: Id, topicId?: Id, task?: TaskType) => {
    return createBlock({
        type: Task,
        task_id: taskId ?? null,
        meeting_topic_id: topicId,
        task,
        children: [{ text: '' }],
    });
};

type TaskElementProps = {
    isInert: boolean;
    meetingId: Id;
    topicId: Id;
} & RenderElementProps;

const TaskElement = ({ isInert, meetingId, element, children, attributes, topicId }: TaskElementProps) => {
    const { i18n } = useLingui();
    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();
    const users = useUsers();
    const { meeting } = useMeeting(meetingId);

    const [canUseQuery, setCanUseQuery] = useState(false);

    const { data: task = element.task, isLoading } = useGetTaskQuery(
        buildGetTaskDetailParameters(element.task_id, meetingId),
        {
            skip: element.task_id == null || (element.task != null && !canUseQuery),
            selectFromResult: ({ data, isLoading }) => ({ isLoading, data: camelToSnake(data) }),
        }
    );

    const dispatch = useAppDispatch();

    const selected = useSelected();
    const focused = useEditorIsFocused();
    const isSelected = selected && focused && editor.selection != null && Range.isCollapsed(editor.selection);
    const isDraftTopic = topicId != null && meetingId == null;

    // We should focus the task element if the element doesn't have a task id (because it means the task element is
    // being created)
    const shouldFocusTask = useRef(element.task_id == null);
    const taskRef = useRef<TaskHandle>();

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLDivElement>) => {
        const path = ReactEditor.findPath(editor, element);
        if (['Enter', 'NumpadEnter'].includes(event.code)) {
            event.preventDefault();
            const nextBlock = editor.children[path[0] + 1];
            if (nextBlock != null && isBlockEmpty(nextBlock)) {
                Transforms.select(editor, Path.next(path));
                focusEditor(editor);
            } else {
                if (isReadOnly) {
                    void addComment({
                        meetingTopicId: element.meeting_topic_id,
                        meetingBlockId: element.id,
                        focus: true,
                    });
                } else {
                    Transforms.insertNodes(editor, createParagraphBlock(), { at: Path.next(path), select: true });
                    reorderBlocks(editor);
                    forceSave(editor);
                    focusEditor(editor);
                }
            }
        }
    };

    useEffect(() => {
        // Once we have a task, check if we should focus
        if (task != null && shouldFocusTask.current) {
            shouldFocusTask.current = false;
            taskRef.current?.focus();
        }
    }, [task]);

    useEffect(() => {
        const invalidationTag = taskTag(element.task_id);
        return onInvalidation(invalidationTag, () => setCanUseQuery(true));
    }, []);

    return (
        <div data-block-id={element.id} {...attributes} className="relative">
            {(isLoading && task == null) || element.task_id == null ? (
                <Skeleton className="h-8" />
            ) : task?.name == null ? (
                <div
                    className={clsx('bg-gray-100 px-2 py-1 text-gray-400', isSelected && SelectedClasses)}
                    contentEditable={false}
                >
                    <Trans>You can&apos;t view this task</Trans>
                </div>
            ) : (
                <div contentEditable={false}>
                    <TaskItem
                        ref={taskRef}
                        onSelectTask={() =>
                            !isInert && !isDraftTopic && dispatch(taskSelected({ taskId: element.task_id }))
                        }
                        task={{ ...task, workspaces: task?.workspaces ?? task?.tags } as GroupedTask}
                        meetingId={meetingId}
                        topicId={topicId}
                        isInert={isInert}
                        isEditable={!isReadOnly}
                        onKeyDown={handleKeyDown}
                        className={clsx('flex-1', isSelected && SelectedClasses)}
                        workspaceId={meeting?.tag_id}
                        editor={editor}
                    />
                    {task.meeting_activities?.length > 0 && (
                        <div className="px-1 pt-0.5">
                            {task.meeting_activities.map((activity: ActivityLog) => (
                                <div key={activity.id} className="block text-xs text-gray-500">
                                    <Icon name={getIcon(activity)} className="w-5" />
                                    {getText({
                                        activity: {
                                            ...activity,
                                            created_by:
                                                typeof activity.created_by === 'object'
                                                    ? activity.created_by
                                                    : users.find(
                                                          (user: User) => user.id === activity.created_by?.toString()
                                                      ),
                                        },
                                        i18n,
                                    })}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};

type TaskPluginProps = {
    isInert?: boolean;
    meetingId: Id;
    topicId?: Id;
};

export const taskPlugin = ({ isInert = false, meetingId, topicId }: TaskPluginProps): Plugin => ({
    isVoid: (_, element) => element.type === Task,
    renderElement: (_, { attributes, element, children }) => {
        return (
            element.type === Task && (
                <TaskElement
                    element={element}
                    attributes={attributes}
                    isInert={isInert}
                    meetingId={meetingId}
                    topicId={topicId}
                >
                    {children}
                </TaskElement>
            )
        );
    },
});
