import React, { FC, PropsWithChildren } from 'react';
import { Table } from '@wedo/design-system';
import { ClearTag } from 'Shared/components/bulkEdit/ClearTag';
import { EditTag } from 'Shared/components/bulkEdit/EditTag';

type BulkEditChangeTableRowProps = {
    fieldName: string;
    changeType: 'edit' | 'clear';
};

export const BulkEditChangeTableRow: FC<PropsWithChildren<BulkEditChangeTableRowProps>> = ({
    fieldName,
    changeType,
    children,
}) => {
    return (
        <Table.Row>
            <Table.Cell>
                <span className="flex items-center gap-2">{fieldName}</span>
            </Table.Cell>
            <Table.Cell>{changeType === 'clear' ? <ClearTag /> : <EditTag />}</Table.Cell>
            <Table.Cell>{children}</Table.Cell>
        </Table.Row>
    );
};
