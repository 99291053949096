import { ReactNode } from 'react';
import clsx from 'clsx';

export const TASK_DETAIL_ICON_CLASS = '!h-4 !w-4';

export const TaskDetailRowComponent = ({
    children,
    className = '',
}: {
    children: ReactNode;
    className?: string;
}): JSX.Element => {
    return <div className={clsx('flex items-center gap-1 px-3 py-2', className)}>{children}</div>;
};
export const TaskDetailRowIconWrapper = ({
    children,
    className = '',
}: {
    children: ReactNode;
    className?: string;
}): JSX.Element => {
    return <div className={clsx('flex h-7 w-7 flex-shrink-0 items-center justify-center', className)}>{children}</div>;
};
export const TaskDetailRowContent = ({
    children,
    className = '',
}: {
    children: ReactNode;
    className?: string;
}): JSX.Element => {
    return <div className={clsx('min-w-0 grow', className)}>{children}</div>;
};
export const TaskDetailRowAddon = ({
    children,
    className = '',
}: {
    children?: ReactNode;
    className?: string;
}): JSX.Element => {
    return (
        <div className={clsx('flex h-8 w-8 shrink-0 items-center justify-center self-start', className)}>
            {children}
        </div>
    );
};

export const TaskDetailRow = Object.assign(TaskDetailRowComponent, {
    IconWrapper: TaskDetailRowIconWrapper,
    Content: TaskDetailRowContent,
    Addon: TaskDetailRowAddon,
});
