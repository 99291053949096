import React from 'react';
import { useParams } from 'react-router-dom';
import { faChevronDown, faDiamond, faPause, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, Dropdown, ItemGroup, useModal } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { useAddTaskInput } from 'Pages/TasksPage/components/AddTaskInput/useAddTaskInput';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { AddChecklistSectionModal } from 'Shared/components/checklist/AddChecklistSectionModal';
import { AddWorkspaceSectionModal } from 'Shared/components/workspace/AddWorkspaceSectionModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { TaskType } from 'Shared/types/task';
import { UserFeature } from 'Shared/types/user';

export const AddTaskButton = () => {
    const { open } = useModal();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { currentUser } = useCurrentUserContext();
    const { network } = useCurrentNetwork();
    const { workspaceId, userId, checklistId, templateId } = useParams<TasksPageParams>();
    const { setSelectedTasks } = useTasksContext();

    const { addTasks, isAdding: isAddingTask } = useAddTaskInput({
        templateId,
        checklistId,
        workspaceId,
        isWatcher: true,
        assigneeId: userId,
    });

    const isGanttEnabled = useHasFeature(currentUser, network, UserFeature.Gantt);

    const handleAddTask = async (type: TaskType = TaskType.Task) => {
        const response = await addTasks([{ name: '', type }]);
        if ('data' in response) {
            const tasks = response.data;
            setSelectedTasks([{ id: tasks?.[0]?.id, groupedId: null }]);
        }
    };

    const handleAddSection = () => {
        if (workspaceId) {
            open(AddWorkspaceSectionModal, { workspaceId });
        } else if (checklistId) {
            open(AddChecklistSectionModal, { checklistId });
        } else if (templateId) {
            open(AddChecklistSectionModal, { templateId });
        }
    };

    return (
        <ItemGroup>
            <Button
                title={t`Add task`}
                tooltipClassName="flex lg:hidden"
                icon={faPlus}
                color="primary"
                loading={isAddingTask}
                onClick={() => handleAddTask()}
                data-testid="header-add-task"
            >
                <span className="hidden lg:flex">
                    <Trans>Add task</Trans>
                </span>
            </Button>

            {!isMyTasksPage && !userId && (
                <Dropdown icon={faChevronDown} position="end" color="primary">
                    <Dropdown.Item onClick={handleAddSection} icon={faPause} iconClassName="fa-rotate-90">
                        <Trans>Add section</Trans>
                    </Dropdown.Item>
                    {isGanttEnabled && (
                        <Dropdown.Item onClick={() => handleAddTask(TaskType.Milestone)} icon={faDiamond}>
                            <Trans>Add milestone</Trans>
                        </Dropdown.Item>
                    )}
                </Dropdown>
            )}
        </ItemGroup>
    );
};
