import { colors } from '@wedo/design-system';
import { type State } from '@wedo/pdf/meeting/store';
import { mmToPx } from '@wedo/pdf/utils';
import { Stamp } from 'Shared/components/pdfViewer/SharedPdfViewer';

const FontSize = 8;
const FontColor = parseInt(colors.gray['400'].substring(1), 16);

export const stampFooter = (state: State): Stamp => {
    return async (instance, document, [pageRangeStart, pageRangeEnd]) => {
        const { PDFNet } = instance.Core;

        const stamper = await PDFNet.Stamper.create(PDFNet.Stamper.SizeType.e_font_size, FontSize, 0);
        const [, , marginBottom, marginLeft] = state.settings.margins;
        await stamper.setPosition(mmToPx(marginLeft), mmToPx(marginBottom));
        await stamper.setFont(await PDFNet.Font.create(document, PDFNet.Font.StandardType1Font.e_helvetica));
        await stamper.setFontColor(
            await PDFNet.ColorPt.init(
                ((FontColor >> 16) & 255) / 255.0,
                ((FontColor >> 8) & 255) / 255.0,
                (FontColor & 255) / 255.0,
                0
            )
        );
        await stamper.setAsBackground(false);

        if (state.settings.elementsToDisplay.includes('footer')) {
            await stamper.setAlignment(
                PDFNet.Stamper.HorizontalAlignment.e_horizontal_left,
                PDFNet.Stamper.VerticalAlignment.e_vertical_bottom
            );
            await stamper.setTextAlignment(PDFNet.Stamper.TextAlignment.e_align_left);
            await stamper.stampText(
                document,
                state.settings.footer,
                await PDFNet.PageSet.createRange(pageRangeStart, pageRangeEnd)
            );
        }

        if (state.settings.elementsToDisplay.includes('pageNumbering')) {
            await stamper.setAlignment(
                PDFNet.Stamper.HorizontalAlignment.e_horizontal_right,
                PDFNet.Stamper.VerticalAlignment.e_vertical_bottom
            );
            await stamper.setTextAlignment(PDFNet.Stamper.TextAlignment.e_align_right);
            for (let page = pageRangeStart; page <= pageRangeEnd; page++) {
                await stamper.stampText(
                    document,
                    state.settings.pageNumbering === 'default'
                        ? `${page}/${pageRangeEnd}`
                        : (state.settings.pageNumbering - 1 + page).toString(),
                    await PDFNet.PageSet.createSinglePage(page)
                );
            }
        }
    };
};
