import {
    faCircleArrowRight,
    faCircleCheck,
    faCircleExclamation,
    faCirclePause,
    faCircleXmark,
    IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Status } from 'Shared/types/workspaceStatus';

type WorkspaceStatusDefinition = {
    title: string;
    color: string;
    colorText: string;
    borderColor: string;
    icon: IconDefinition;
};

export const workspaceStatus = () => ({
    [Status.OnTrack]: {
        title: t`On track`,
        color: 'blue',
        colorText: 'text-blue-600',
        borderColor: 'border-t-blue-600',
        icon: faCircleArrowRight,
    },
    [Status.AtRisk]: {
        title: t`At risk`,
        color: 'orange',
        colorText: 'text-orange-500',
        borderColor: 'border-t-orange-500',
        icon: faCircleExclamation,
    },
    [Status.OffTrack]: {
        title: t`Off track`,
        color: 'red',
        colorText: 'text-red-500',
        borderColor: 'border-t-red-500',
        icon: faCircleXmark,
    },
    [Status.OnHold]: {
        title: t`On hold`,
        color: 'gray',
        colorText: 'text-gray-500',
        borderColor: 'border-t-gray-500',
        icon: faCirclePause,
    },
    [Status.Completed]: {
        title: t`Completed`,
        color: 'green',
        colorText: 'text-green-500',
        borderColor: 'border-t-green-500',
        icon: faCircleCheck,
    },
});

export const getWorkspaceStatus = (status: Status): WorkspaceStatusDefinition => workspaceStatus()[status];
