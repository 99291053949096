import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Input } from '~/components/Input/Input';

type CodeInputCellProps = {
    id: string;
    label: string;
    index: number;
    autofocus: boolean;
    regex: RegExp;
    value: string;
    disabled: boolean;
    placeholder: string;
    onChange: (e: string) => void;
    onPrevious: () => void;
    onNext: () => void;
    status?: 'default' | 'error' | 'success';
};

export const CodeInputCell = forwardRef<HTMLInputElement, CodeInputCellProps>(
    ({ id, label, index, value, autofocus, onChange, onPrevious, onNext, disabled, status, placeholder }, ref) => {
        const internalRef = useRef<HTMLInputElement>(null);

        useImperativeHandle(ref, () => internalRef.current);

        const handleChange = (e) => {
            const newValue = e.target.value;
            onChange(newValue);
        };

        const handleKeydown = (e) => {
            if (e.code === 'ArrowLeft' || (e.code === 'Backspace' && e.target.value === '' && e.target.focus)) {
                onPrevious();
            } else if (e.code === 'ArrowRight') {
                onNext();
            }
        };

        useEffect(() => {
            if (autofocus && internalRef !== null) {
                internalRef.current.focus();
            }
        }, [internalRef, autofocus]);

        return (
            <Input
                id={id}
                aria-label={label ? t`${label} character ${index + 1}` : null}
                ref={internalRef}
                autoComplete="one-time-code"
                onPaste={(e) => e.preventDefault()}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={(e) => e.target.select()}
                value={value ?? ''}
                onKeyDown={handleKeydown}
                onChange={handleChange}
                inputClassName={clsx(
                    status === 'error' &&
                        'border-red-300 text-red-800 placeholder-red-300 focus-visible:border-red-300 focus-visible:ring-red-500',
                    '!h-10 !w-10 !text-center font-mono !text-lg'
                )}
            />
        );
    }
);
