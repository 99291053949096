import { HierarchyCircularNode } from 'd3';
import { Root } from 'postcss';
import { create } from 'zustand';
import { Circle } from 'Shared/types/governance';

type GovernanceState = {
    root: HierarchyCircularNode<Root>;
    selectAndZoomOnNode: (node: HierarchyCircularNode<Circle>) => void;
    setRoot: (value: HierarchyCircularNode<Root>) => void;
    setSelectAndZoomOnNode: (value: (node: HierarchyCircularNode<Circle>) => void) => void;
};
export const useGovernanceStore = create<GovernanceState>((set) => ({
    root: undefined,
    selectAndZoomOnNode: () => null,
    setRoot: (value: HierarchyCircularNode<Root>) => set(() => ({ root: value })),
    setSelectAndZoomOnNode: (value: (node: HierarchyCircularNode<Circle>) => void) =>
        set(() => ({ selectAndZoomOnNode: value })),
}));
