import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Button, CloseSource, ContextModalProps, Input, Label, Modal, Textarea } from '@wedo/design-system';
import { useLocalStorage } from '@wedo/utils/hooks/useLocalStorage';
import { LocalStorage } from 'Shared/types/localStorage';
import { Meeting } from 'Shared/types/meeting';

type CustomizeRequestMessageModalProps = {
    meeting: Meeting;
    onSendRequest: (subject: string, message: string) => Promise<void>;
    defaultSubject: string;
    defaultMessage: string;
} & ContextModalProps &
    PropsWithChildren;

export const CustomizeRequestMessageModal: FC<CustomizeRequestMessageModalProps> = ({
    meeting,
    onSendRequest,
    children,
    defaultSubject,
    defaultMessage,
    ...modalProps
}) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [seriesSignMessage, setSeriesSignMessage] = useLocalStorage<string>(
        LocalStorage.SignatureMessage + meeting?.series_master_id
    );

    const [isRequestPending, setIsRequestPending] = useState(false);

    const handleBeforeClose = async (source: CloseSource) => {
        if (source === 'api') {
            return true;
        }

        return !isRequestPending;
    };

    useEffect(() => {
        if (seriesSignMessage != null && seriesSignMessage !== '') {
            setMessage(seriesSignMessage);
        } else {
            setMessage(defaultMessage);
        }

        setSubject(defaultSubject);
    }, [seriesSignMessage]);

    return (
        <Modal onBeforeClose={handleBeforeClose} size={'md'} {...modalProps}>
            <Modal.Header title={t`Customize email before sending`} />
            <Modal.Body>
                <Label className={'mb-2'}>
                    {t`Subject`}
                    <Input value={subject} onChange={(e) => setSubject(e.target.value)} />
                </Label>
                <Label>
                    {t`Message`}
                    <Textarea rows={6} value={message} onChange={(e) => setMessage(e.target.value)} />
                </Label>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isRequestPending} onClick={() => modalProps.close()}>{t`Cancel`}</Button>
                <Button
                    loading={isRequestPending}
                    color="primary"
                    onClick={async () => {
                        setSeriesSignMessage(message);
                        setIsRequestPending(true);
                        await onSendRequest(subject, message, modalProps.close);
                        setIsRequestPending(false);
                    }}
                >{t`Send`}</Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
