import { FC } from 'react';
import { faEllipsisV, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, Dropdown, ItemGroup, useModal } from '@wedo/design-system';
import { AddCustomFieldModal } from 'Pages/settings/customFields/components/AddCustomFieldModal/AddCustomFieldModal';
import { EditGroupModal } from 'Pages/settings/customFields/components/GroupModal/EditGroupModal';
import { useArchiveGroupModal } from 'Pages/settings/customFields/hooks/useArchiveGroupModal';
import { useDeleteGroupModal } from 'Pages/settings/customFields/hooks/useDeleteGroupModal';
import { archiveIcon, deleteIcon, editIcon, unArchiveIcon } from 'Pages/settings/customFields/utils/action';
import { useUnArchiveCustomFieldGroupMutation } from 'Shared/services/customFields';
import { CustomFieldGroup } from 'Shared/types/customField';

interface FieldsGroupItemActionsProps {
    group: CustomFieldGroup;
}

export const CustomGroupItemActions: FC<FieldsGroupItemActionsProps> = ({ group }) => {
    const { open } = useModal();
    const { open: openDeleteGroupModal } = useDeleteGroupModal();
    const { open: openArchiveGroupModal } = useArchiveGroupModal();
    const [unarchiveGroup] = useUnArchiveCustomFieldGroupMutation();

    return (
        <ItemGroup>
            <Button
                size="sm"
                icon={faPlus}
                onClick={() =>
                    open(AddCustomFieldModal, {
                        groupId: group?.id,
                        onBeforeClose: async (source: string) => source === 'cross' || source === 'api',
                    })
                }
            >
                <Trans>Add field</Trans>
            </Button>

            <Dropdown icon={faEllipsisV} size="sm" data-testid="group-dropdown">
                <Dropdown.Item icon={editIcon} onClick={() => open(EditGroupModal, { group })}>
                    <Trans>Edit</Trans>
                </Dropdown.Item>

                {!group?.archived && (
                    <Dropdown.Item icon={archiveIcon} onClick={() => openArchiveGroupModal(group)}>
                        <Trans>Archive</Trans>
                    </Dropdown.Item>
                )}

                {group?.archived && (
                    <Dropdown.Item icon={unArchiveIcon} onClick={() => unarchiveGroup(group.id)}>
                        <Trans>Unarchive</Trans>
                    </Dropdown.Item>
                )}

                <Dropdown.DividerItem />

                <Dropdown.Item onClick={() => openDeleteGroupModal(group)} icon={deleteIcon} danger={true}>
                    <Trans>Delete</Trans>
                </Dropdown.Item>
            </Dropdown>
        </ItemGroup>
    );
};
