import React from 'react';
import { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { useArchiveCustomFieldGroupMutation } from 'Shared/services/customFields';
import { CustomFieldGroup } from 'Shared/types/customField';

export const useArchiveGroupModal = () => {
    const { confirm } = useConfirm();
    const [archiveGroup] = useArchiveCustomFieldGroupMutation();

    const open = useCallback(
        async (group: CustomFieldGroup) => {
            await confirm({
                type: 'primary',
                title: t`Archive group`,
                content: (
                    <Trans>
                        Are you sure you want to archive the group <span className="bold font-mono">{group.label}</span>
                        ? The group will be hidden, but the values will be retained.
                    </Trans>
                ),
                confirmText: t`Archive`,
                onConfirm: () => archiveGroup(group.id).unwrap(),
            });
        },
        [confirm, archiveGroup]
    );

    return { open };
};
