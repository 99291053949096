export const LanguageList = ['en-gb', 'en-us', 'fr-ch', 'fr-fr', 'de-ch', 'de-de', 'it-ch', 'it-it'] as const;

export type LanguageCode = (typeof LanguageList)[number];

export const LanguageMap = new Map<string, string>([
    ['en-gb', 'English (United Kingdom)'],
    ['en-us', 'English (United States)'],
    ['fr-ch', 'Français (Suisse)'],
    ['fr-fr', 'Français (France)'],
    ['de-ch', 'Deutsch (Schweiz)'],
    ['de-de', 'Deutsch (Deutschland)'],
    ['it-ch', 'Italiano (Svizzera)'],
    ['it-it', 'Italiano (Italia)'],
]);
