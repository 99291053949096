import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { AutoTooltipText, Tooltip } from '@wedo/design-system';
import { useElementSize } from '@wedo/utils/hooks';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';

type GridCardTitleProps = {
    color: string;
    icon: ReactNode | ReturnType<typeof WorkspaceIcon> | ReturnType<typeof TeamIcon>; // TODO: replace by Victor's icon
    title: string;
};

export const GridCardTitle: FC<GridCardTitleProps> = ({ icon, title }) => {
    const [titleRef, setTitleRef] = useState<HTMLDivElement>();
    const wrapperRef = useRef(null);

    const wrapperSize = useElementSize(wrapperRef);

    const shouldDisplayTooltip = useMemo(() => titleRef?.offsetWidth !== titleRef?.scrollWidth, [wrapperSize]);

    return (
        <div ref={setTitleRef} className="flex items-center gap-2 z-10">
            {icon}
            <AutoTooltipText tooltipText={title}>
                <Tooltip anchorRef={setTitleRef} content={shouldDisplayTooltip && title}>
                    <span>{title}</span>
                </Tooltip>
            </AutoTooltipText>
        </div>
    );
};
