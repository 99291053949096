import { useLingui } from '@lingui/react';
import { isValid as isValidDate } from 'date-fns';
import { formatDate } from '@wedo/utils';

export type DateLike = Date | number | string;

export const useFormatDate = (date: DateLike, dateFormat: string): string => {
    const { i18n } = useLingui();

    try {
        return formatDate(new Date(date), dateFormat, i18n);
    } catch (e) {
        return '';
    }
};

export type FormatDateProps = {
    format: string;
    date: DateLike;
    className?: string;
};

export const FormatDate = ({ date, format, className }: FormatDateProps): JSX.Element => {
    const text = useFormatDate(date, format);

    if (!isValidDate(new Date(date))) {
        return null;
    }

    return (
        <time className={className} dateTime={new Date(date).toISOString()}>
            {text}
        </time>
    );
};
