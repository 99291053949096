import React, { ClipboardEvent, KeyboardEvent, useCallback, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import { Button, contrastingColor, Input, ItemGroup, Label, Modal } from '@wedo/design-system';
import { FaIconName } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { DraggableOptionItem } from 'Pages/settings/customFields/components/CustomFieldModalContent/OptionsModalContent/DraggableOptionItem';
import { CustomFieldNameLabel } from 'Pages/settings/customFields/components/shared/CustomFieldNameLabel';
import { FieldNameInput } from 'Pages/settings/customFields/components/shared/FieldNameInput';
import {
    AvailabilityOption,
    IsCustomFieldGlobalRadio,
} from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';
import { useCombinedFields } from 'Pages/settings/customFields/hooks/useCombinedFields';
import { useCustomFieldGroup } from 'Pages/settings/customFields/hooks/useCustomFieldGroup';
import { useCustomFieldGroups } from 'Pages/settings/customFields/hooks/useCustomFieldGroups';
import { useFieldOptions } from 'Pages/settings/customFields/hooks/useFieldOptions';
import {
    getFieldAvailability,
    getRandomIconName,
    MIN_FIELD_LABEL_LENGTH,
    MIN_OPTION_LENGTH,
} from 'Pages/settings/customFields/utils/field';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { useAddCustomFieldMutation, useUpdateCustomFieldMutation } from 'Shared/services/customFields';
import { CustomField, CustomFieldOption, CustomFieldType } from 'Shared/types/customField';
import { closestY } from 'Shared/utils/dnd';
import { TEXT_WITHOUT_BULLETS_REGEX } from 'Shared/utils/parseClipboard';
import { isSuccess } from 'Shared/utils/rtkQuery';

type OptionsBasicSectionProps = {
    onCancel: () => void;
    onSuccess: () => void;
    fieldType: CustomFieldType;
    groupId?: string;
    field?: CustomField;
};

export const OptionsModalContent: React.FC<OptionsBasicSectionProps> = ({
    onCancel,
    onSuccess,
    groupId,
    field,
    fieldType,
}) => {
    const [addField, { isLoading: addFieldIsLoading }] = useAddCustomFieldMutation();
    const [updateField, { isLoading: updateFieldIsLoading }] = useUpdateCustomFieldMutation();

    const isInGroup = (!!field?.custom_field_group_id || groupId) as boolean;

    const { maxOrder } = useCombinedFields();
    const { getGroup } = useCustomFieldGroups();
    const { maxOrder: maxOrderGroup } = useCustomFieldGroup(getGroup(groupId));
    const { measuring, sensors } = useDndSortableVerticalStrategy();
    const { options, setOptions, sortedOptions, uniqueOptions, optionLabels, optionsPayload, reOrderOptions } =
        useFieldOptions(field);

    const [availability, setAvailability] = useState<AvailabilityOption>(getFieldAvailability(field));
    const [text, setText] = useState<string>(field?.label ?? EmptyString);
    const [option, setOption] = useState<string>(EmptyString);
    const [icon, setIcon] = useState<FaIconName>(
        isInGroup ? (EmptyString as FaIconName) : field?.icon ?? getRandomIconName()
    );

    const isLoading = addFieldIsLoading || updateFieldIsLoading;

    const isDuplicateOption = (option: string): boolean => uniqueOptions.has(option.trim());

    const optionIsInvalid = (label: string): boolean => isDuplicateOption(label) || isEmpty(label.trim());

    const optionIsDuplicate = isDuplicateOption(option);

    const hasIcon = !isEmpty(icon);

    const addOptionButtonDisabled = optionIsInvalid(option) || option.length < MIN_OPTION_LENGTH;

    const sortButtonIsDisabled = options.length < 2 || isEqual(options, sortedOptions);

    const saveButtonDisabled = isEmpty(text) || text.length < MIN_FIELD_LABEL_LENGTH || (!hasIcon && !isInGroup);

    const updateButtonIsDisabled =
        isEqual(icon, field?.icon) &&
        isEqual(text, field?.label) &&
        isEqual(availability.isGlobal, field?.is_global) &&
        isEqual(options, field?.options);

    const handleIconChange = (icon: IconDefinition): void => {
        setIcon(icon === null ? (EmptyString as FaIconName) : `fa-${icon.iconName}`);
    };

    const handleAddOptionsPaste = async ({ clipboardData }: ClipboardEvent<HTMLInputElement>) => {
        let values = clipboardData
            .getData('text')
            .split('\n')
            .map((row) => row.trim().replace(TEXT_WITHOUT_BULLETS_REGEX, ''))
            .filter(Boolean);
        values = values.filter(
            (value, index) => values.indexOf(value) === index && !options.find((option) => option.label === value)
        );

        setOptions((options) => [
            ...options,
            ...values.map((value, index) => {
                return {
                    label: value.trim(),
                    order: options.length + index,
                    color: null,
                } as CustomFieldOption;
            }),
        ]);
        // Timeout is used to trigger the setOption to an empty string after the onChange event on the input
        setTimeout(() => {
            setOption(EmptyString);
        }, 0);
    };

    const handleAddOption = useCallback(() => {
        setOptions((options) => [
            ...options,
            {
                label: option.trim(),
                order: options.length,
                color: null,
            } as CustomFieldOption,
        ]);
        setOption(EmptyString);
    }, [option, setOptions]);

    const handleOptionAddUsingEnterKey = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter' && !addOptionButtonDisabled) {
            handleAddOption();
        }
    };

    const handleSortOptions = () => setOptions(sortedOptions);

    const handleSetOptionColor = (index: number, newColor: string): void => {
        setOptions((options) => {
            const updatedOptions = [...options];
            if (isEmpty(newColor)) {
                updatedOptions[index].color = null;
            } else {
                updatedOptions[index].color = {
                    background: newColor,
                    text: contrastingColor(newColor),
                };
            }
            return updatedOptions;
        });
    };

    const handleOptionDelete = (index: number): void => {
        setOptions((options) => [...options.slice(0, index), ...options.slice(index + 1)]);
    };

    const handleOptionLabelUpdate = (index: number, label: string): void => {
        setOptions((options) => {
            const updated = [...options];
            options[index].label = label;
            return updated;
        });
    };

    const handleAddField = async () => {
        if (
            await isSuccess(
                addField({
                    label: text,
                    object_type: 'task',
                    order: (isInGroup ? maxOrderGroup : maxOrder) + 1,
                    is_global: availability.isGlobal,
                    icon,
                    custom_field_group_id: groupId ? groupId : null,
                    options,
                    type: fieldType,
                })
            )
        ) {
            onSuccess();
        }
    };

    const handleUpdateField = async () => {
        if (
            await isSuccess(
                updateField({
                    id: field.id,
                    icon,
                    is_global: availability.isGlobal,
                    label: text,
                    options: optionsPayload,
                } as CustomField)
            )
        ) {
            onSuccess();
        }
    };

    const handleEnterKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (field) {
                if (!updateButtonIsDisabled) {
                    await handleUpdateField();
                }
            } else if (!saveButtonDisabled) {
                await handleAddField();
            }
        }
    };

    const handleDragEnd = (e: DragEndEvent) => {
        const activeId = e.active.id;
        const overId = e.over.id;
        reOrderOptions(activeId as string, overId as string);
    };

    return (
        <>
            <Modal.Header title={field ? t`Edit custom field` : t`Add custom field`} />

            <Modal.Body>
                <CustomFieldNameLabel />

                <FieldNameInput
                    value={text}
                    onChange={setText}
                    onKeyDown={handleEnterKeyPress}
                    icon={icon}
                    onIconChange={handleIconChange}
                    isInGroup={isInGroup}
                    shortName={field?.short_name}
                />

                {!isInGroup && (
                    <IsCustomFieldGlobalRadio
                        availability={availability}
                        onClick={setAvailability}
                        title={t`Is this custom field global?`}
                    />
                )}

                <div>
                    <Label htmlFor="option-name" className="mt-8 flex items-center justify-between">
                        <Trans>Options</Trans>
                        <Button
                            variant="link"
                            color="primary"
                            disabled={sortButtonIsDisabled}
                            onClick={handleSortOptions}
                        >
                            <Trans>Sort</Trans>
                        </Button>
                    </Label>
                </div>
                <ItemGroup
                    statusText={optionIsDuplicate && t`This option has already been added.`}
                    status={isEmpty(option.trim()) ? 'default' : optionIsDuplicate ? 'error' : 'default'}
                >
                    <Input
                        className={'flex-grow'}
                        id="option-name"
                        placeholder={t`Option name`}
                        value={option}
                        onChange={(e) => setOption(e.target.value)}
                        onKeyDown={(e) => handleOptionAddUsingEnterKey(e)}
                        onPaste={handleAddOptionsPaste}
                    />
                    <Button
                        icon={faPlus}
                        position="end"
                        title={t`Add option`}
                        color="primary"
                        className="text-white"
                        disabled={addOptionButtonDisabled}
                        onClick={handleAddOption}
                    />
                </ItemGroup>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestY}
                    measuring={measuring}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={optionLabels} strategy={verticalListSortingStrategy}>
                        <div className="mt-4 space-y-1">
                            {options.map((option, index) => (
                                <DraggableOptionItem
                                    key={option.label}
                                    option={option}
                                    onColorChange={(color) => handleSetOptionColor(index, color)}
                                    onDelete={() => handleOptionDelete(index)}
                                    onCheckLabelIsValid={(label) => !optionIsInvalid(label)}
                                    onTextChange={(label) => handleOptionLabelUpdate(index, label)}
                                />
                            ))}
                        </div>
                    </SortableContext>
                </DndContext>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onCancel} disabled={isLoading}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button
                    color="primary"
                    disabled={field ? updateButtonIsDisabled : saveButtonDisabled}
                    loading={isLoading}
                    onClick={field ? handleUpdateField : handleAddField}
                >
                    {field ? t`Update` : t`Save`}
                </Button>
            </Modal.Footer>
        </>
    );
};
