import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';

export const EditTag = () => {
    return (
        <Tag color="green" icon={faPen} size="sm">
            <Trans>Edit</Trans>
        </Tag>
    );
};
