import React, { FC, PropsWithChildren } from 'react';
import { faArchive, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Card, Tag } from '@wedo/design-system';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { User } from 'Shared/types/user';

type CardStatus = 'deleted' | 'archived';
type GridCardBodyProps = PropsWithChildren & {
    members?: User[];
    isHoverable?: boolean;
    className?: string;
    status?: CardStatus;
};

export const GridCardBody: FC<GridCardBodyProps> = ({
    children,
    members = [],
    isHoverable = false,
    className = '',
    status,
}) => {
    return (
        <Card.Body
            className={clsx(
                'flex flex-col items-center justify-center gap-6 rounded-b-md text-black',
                isHoverable &&
                    'group-hover/grid-card:bg-gray-100 group-hover/grid-card:text-black group-hover/grid-card:shadow-md',
                className
            )}
        >
            <div className="absolute top-[85px] right-2">
                {status === 'deleted' ? (
                    <Tag size="sm" icon={faTrash} color="red">{t`Deleted`}</Tag>
                ) : (
                    status === 'archived' && <Tag size="sm" icon={faArchive} color="orange">{t`Archived`}</Tag>
                )}
            </div>
            <div className="flex items-center gap-2">
                <UsersAvatarGroup showTooltip={false} users={members} maxDisplayed={5} />
            </div>
            {children}
        </Card.Body>
    );
};
