export const fileToImage = async (file: File): Promise<HTMLImageElement> => {
    const img = new Image();

    const fileUrl = URL.createObjectURL(file);
    img.src = fileUrl;

    return new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = () => {
            URL.revokeObjectURL(fileUrl);
            resolve(img);
        };

        img.onerror = reject;
    });
};

export const fileTypeToImageExtension = (fileType: string): string => fileType.split('/')[1];

export const imageExtensions = new Set(['png', 'jpeg', 'jpg']);

export const isImageExtension = (extension: string): boolean => imageExtensions.has(extension.toLowerCase());

export const getImageDimension = (url: string) =>
    new Promise<{ width: number; height: number; naturalWidth: number; naturalHeight: number }>((resolve, reject) => {
        const image = new Image();
        image.onload = () =>
            resolve({
                width: image.width,
                height: image.height,
                naturalWidth: image.naturalWidth,
                naturalHeight: image.naturalHeight,
            });
        image.onerror = (error) => reject(error);
        image.src = url;
    });
