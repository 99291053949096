import React, { FC } from 'react';
import { FormatDate, ProgressBar, Table, useModal } from '@wedo/design-system';
import { getLocalStorageSearchParams } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { WorkspaceDropdown } from 'Shared/components/workspace/WorkspaceDropdown';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { WorkspaceSettingsModal } from 'Shared/components/workspace/WorkspaceSettingsModal/WorkspaceSettingsModal';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { getWorkspaceStatus } from 'Shared/components/workspace/WorkspaceStatusUtils';
import { Workspace } from 'Shared/types/workspace';
import { getAllModerators } from 'Shared/utils/userGroup';
import { getWorkspaceTaskParams, WorkspaceSettingsToTab } from 'Shared/utils/workspace';

export const WorkspaceTableRow: FC<{ workspace: Workspace }> = ({ workspace }) => {
    const { open } = useModal();
    const navigate = useNavigate();
    const { grouping, layout } = getWorkspaceTaskParams(workspace);

    const handleClick = () => {
        const pathname = `/workspaces/${workspace.id}/${WorkspaceSettingsToTab[workspace.settings?.default_tab] ?? 'tasks'}`;
        const localStorageSearchParams = getLocalStorageSearchParams(pathname);
        navigate({
            pathname,
            searchParams: { grouping, layout, ...localStorageSearchParams },
        });
    };

    const handleManageMembers = () => {
        if (workspace.deleted) {
            return;
        }
        open(WorkspaceSettingsModal, { workspaceId: workspace.id, defaultIndex: 1 });
    };

    const moderatorList = getAllModerators(workspace.userGroup?.members ?? []).map((member) => member.user);

    const progressColor =
        workspace.tagStatus?.status != null ? getWorkspaceStatus(workspace.tagStatus?.status).color : 'gray';

    return (
        <Table.Row className="cursor-pointer flex">
            <Table.Cell className={'@5xl:w-[40%] @lg:w-[50%] w-[80%] @md:w-[60%]'} onClick={handleClick}>
                <div className="flex items-center gap-2 font-medium">
                    <WorkspaceIcon workspace={workspace} />
                    {workspace.name}
                </div>
            </Table.Cell>
            <Table.Cell className="@5xl:table-cell hidden w-[20%]" onClick={handleClick}>
                <ProgressBar percent={workspace.meta.percentage ?? 0} color={progressColor} />
            </Table.Cell>
            <Table.Cell className="@5xl:w-[10%] @lg:w-[20%] hidden @md:table-cell w-[15%]" onClick={handleClick}>
                {workspace?.tagStatus?.status && <WorkspaceStatusTag status={workspace.tagStatus.status} size="sm" />}
            </Table.Cell>
            <Table.Cell className="@lg:table-cell hidden @lg:w-[20%] @5xl:w-[10%] w-[15%]">
                <FormatDate format="PPP" date={new Date(workspace?.tagStatus?.due_date)} />
            </Table.Cell>
            <Table.Cell className="@5xl:table-cell hidden w-[10%]" onClick={handleManageMembers}>
                <div className={'flex items-center justify-start gap-2'}>
                    <UsersAvatarGroup showTooltip={false} maxDisplayed={3} users={moderatorList} />
                </div>
            </Table.Cell>
            <Table.Cell className="w-[20%] @md:w-[10%]">
                <div className={'flex items-center justify-end gap-2'}>
                    <WorkspaceDropdown workspace={workspace} />
                </div>
            </Table.Cell>
        </Table.Row>
    );
};
