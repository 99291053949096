import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { CountryCode } from '@wedo/types';
import { getPreferredCurrency } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { useSubscriptionAddressStore } from 'Pages/subscription/hooks/useSubscriptionAddressStore';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import { useGetOrganizationQuery, useGetSubscriptionEstimateQuery } from 'Shared/services/organization';
import { trpc } from 'Shared/trpc';
import { Organization } from 'Shared/types/organization';
import {
    getFrequencyFromSubscription,
    getHasSignatureFeature,
    getNumberOfGovernanceLicenses,
    getNumberOfLightUsers,
    getNumberOfStandardUsers,
    getPlanType,
    getSubscriptionData,
} from 'Shared/utils/chargebee';
import { getNumberOfUsers } from 'Shared/utils/organization';
import { getValidLicenseOptions } from '../utils';

export const useSubscriptionPage = () => {
    const [searchParams] = useSearchParams();
    const { data: plans, isLoading: isLoadingPlans } = trpc.subscription.listPlans.useQuery();

    const { actions, ...state } = useSubscriptionStore();
    const {
        actions: { setAddress, reset: resetAddress, setAddressIfEmpty },
        ...pageAddress
    } = useSubscriptionAddressStore();
    const { data: organization } = useGetOrganizationQuery();
    const { currentUser } = useCurrentUserContext();

    const estimateParam = getSubscriptionData({
        plan: state.planType,
        frequency: state.frequency,
        currency: state.currency,
        totalUsers: state.userLicenses,
        lightUsers: state.lightUserLicenses,
        couponCode: state.couponCodes?.[0],
        signatureAddon: state.signatureAddon,
        governanceLicenses: state.governanceLicenses,
    });

    const hasExistingSubscription = organization?.subscription != null;
    const isOnSubscriptionPage = window.location.pathname.startsWith('/onboarding/subscribe');

    const { data: estimate, isFetching: isLoadingEstimate } = useGetSubscriptionEstimateQuery(
        { ...estimateParam, replace_coupon_list: true },
        { skip: state.userLicenses === 0 || !isOnSubscriptionPage }
    );

    const userId = searchParams.get('user-id');

    const noChangeInPlan =
        hasExistingSubscription &&
        state.planType === getPlanType(organization?.subscription) &&
        state.userLicenses === getNumberOfStandardUsers(organization?.subscription) &&
        state.lightUserLicenses === getNumberOfLightUsers(organization?.subscription) &&
        state.frequency === getFrequencyFromSubscription(organization?.subscription) &&
        state.signatureAddon === getHasSignatureFeature(organization?.subscription) &&
        state.governanceLicenses === getNumberOfGovernanceLicenses(organization?.subscription);

    const reset = () => {
        actions.reset();
        resetAddress();
    };

    const addCouponCode = (couponCode: string) => {
        actions.setCouponCodes(
            state.couponCodes.filter((coupon_code) => coupon_code !== couponCode).concat(couponCode)
        );
    };

    const removeCouponCode = () => {
        actions.setCouponCodes([]);
    };

    const fillAddressDetails = (organization: Organization) => {
        const address = organization?.billing_address;
        const swissCanton = organization?.cf_canton;

        setAddressIfEmpty({
            firstName: address?.first_name ?? currentUser?.first_name,
            lastName: address?.last_name ?? currentUser?.last_name,
            company: address?.company ?? organization?.name,
            phone: address?.phone,
            addressLine1: address?.line1,
            addressLine2: address?.line2,
            addressLine3: address?.line3,
            city: address?.city,
            zipCode: address?.zip,
            country: address?.country ?? CountryCode.Switzerland,
            email: address?.email ?? currentUser?.email_address,
        });

        setAddress({ canton: isEmpty(pageAddress.canton) ? swissCanton : pageAddress.canton });
    };

    const fillPlanTypeDetails = (organization: Organization) => {
        if (organization?.subscription != null) {
            actions.setPaymentMethod(organization?.card ? 'card' : 'invoice');
            actions.setFrequency(getFrequencyFromSubscription(organization?.subscription));
            if (getPlanType(organization?.subscription) === 'enterprise') {
                actions.setPlanType('enterprise');
            }
            if (state.signatureAddon === undefined) {
                actions.setSignatureAddon(getHasSignatureFeature(organization?.subscription));
            }
            if (state.governanceLicenses === 0) {
                actions.setGovernanceLicenses(getNumberOfGovernanceLicenses(organization?.subscription));
            }
        }
    };
    const fillCouponCodeDetails = (organization: Organization) => {
        if (organization?.subscription?.coupons?.length > 0) {
            actions.setCouponCodes(organization.subscription.coupons.map(({ coupon_code }) => coupon_code));
        } else {
            actions.setCouponCodes([]);
        }
    };

    const fillPaymentMethodDetails = (organization: Organization) => {
        if (organization?.subscription != null) {
            actions.setPaymentMethod(organization?.card ? 'card' : 'invoice');
        }
    };

    const fillUserLicenseDetails = (organization: Organization) => {
        const nbUsers = getNumberOfUsers(organization);
        const nbLightUsers = getNumberOfLightUsers(organization?.subscription);
        const validLicenseNumbers = getValidLicenseOptions(nbUsers);
        const validLightUserLicenseNumbers = getValidLicenseOptions(
            nbLightUsers > 0 ? nbLightUsers : Number(organization?.light_users_count),
            { allowZeroLicenses: true }
        );
        actions.setUserLicenses(validLicenseNumbers[0]);
        actions.setLightUserLicenses(validLightUserLicenseNumbers[0]);
    };

    const fillPlanDetails = (organization: Organization) => {
        fillPlanTypeDetails(organization);
        fillCouponCodeDetails(organization);
        fillPaymentMethodDetails(organization);
        fillUserLicenseDetails(organization);
    };

    const initializeSubscriptionData = (organization: Organization) => {
        if (organization?.subscription != null) {
            actions.setCurrency(organization.subscription.currency_code);
        } else {
            actions.setCurrency(getPreferredCurrency(organization?.billing_address?.country));
        }
        fillAddressDetails(organization);
        fillPlanDetails(organization);
    };

    return {
        userId,
        hasExistingSubscription,
        noChangeInPlan,
        estimateParam,
        organization,
        estimate,
        reset,
        isLoadingEstimate,
        subscription: organization?.subscription,
        isLoadingPlans,
        plans,
        addCouponCode,
        removeCouponCode,
        initializeSubscriptionData,
    };
};
