import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { faChevronDoubleDown, faChevronDoubleUp, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { PanelRow } from 'Shared/components/Panel/PanelRow';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { Task } from 'Shared/types/task';

export const ShowMore = ({
    isMore,
    count,
    onClick,
}: {
    isMore: boolean;
    count: number;
    onClick: () => void;
}): JSX.Element => (
    <Button variant="link" className="mb-2 flex justify-start" onClick={onClick}>
        <FontAwesomeIcon icon={isMore ? faChevronDoubleUp : faChevronDoubleDown} className="mr-1" />
        {isMore ? <Trans>Show less</Trans> : <Trans>Show {count} more</Trans>}
    </Button>
);

const TaskItem = ({ task, onClick }: { task: Task; onClick: (taskId: Id) => void }): JSX.Element => (
    <div
        role="button"
        tabIndex={0}
        onKeyDown={() => onClick(task.id)}
        className="mb-2 flex"
        onClick={() => onClick(task.id)}
    >
        <span className="whitespace-wrap text-blue-600 hover:underline">{task.name}</span>
    </div>
);

type FileDetailTasksProps = {
    tasks: Task[];
};
export const FileDetailTasks = ({ tasks }: FileDetailTasksProps) => {
    const { open } = useModal();

    const [showMore, setShowMore] = useState<boolean>(false);

    const handleClick = (task: Task): void => {
        open(TaskModal, { taskId: task.id });
    };

    return (
        <PanelRow isActive toolTipContent={t`Tasks`} icon={faSquareCheck}>
            <>
                <TaskItem task={tasks[0]} onClick={() => handleClick(tasks[0])} />
                {showMore &&
                    tasks
                        .slice(1, tasks.length)
                        .map((task) => <TaskItem key={task.id} task={task} onClick={() => handleClick(task)} />)}
                {tasks.length > 1 && (
                    <ShowMore isMore={showMore} count={tasks.length} onClick={() => setShowMore(!showMore)} />
                )}
                {/* TODO revert it after task details refactoring*/}
                {/*{isTaskModalVisible && (*/}
                {/*    <TaskModal*/}
                {/*        visible={isTaskModalVisible}*/}
                {/*        onDone={() => setIsTaskModalVisible(false)}*/}
                {/*        taskId={selectedTaskId}*/}
                {/*    />*/}
                {/*)}*/}
            </>
        </PanelRow>
    );
};
