import React, { forwardRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Input, Label, Modal } from '@wedo/design-system';
import { EmptyFunction, EmptyString } from '@wedo/utils';
import { useVerifyUserPasswordMutation } from 'Shared/services/auth';
import { RtkQueryTransformedError } from 'Shared/types/rtkQuery';
import { isSuccess } from 'Shared/utils/rtkQuery';

interface PasswordVerificationModalBodyProps {
    onSuccess?: (password: string) => void;
    onCancel?: () => void;
}

export const PasswordVerificationModalBody = forwardRef<HTMLInputElement, PasswordVerificationModalBodyProps>(
    ({ onSuccess = EmptyFunction, onCancel = EmptyFunction }, ref) => {
        const [verifyUserPassword, { isLoading, error }] = useVerifyUserPasswordMutation();
        const [password, setPassword] = useState<string>(EmptyString);

        const onCancelPasswordVerification = () => {
            setPassword(EmptyString);
            onCancel();
        };

        const handleVerifyPassword = async () => {
            if (await isSuccess(verifyUserPassword(password))) {
                onSuccess(password);
            }
        };

        return (
            <>
                <Modal.Body>
                    <Label>
                        <Trans>Enter your password</Trans>
                        <Input
                            ref={ref}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onPressEnter={!isEmpty(password) && handleVerifyPassword}
                            status={error ? 'error' : 'default'}
                            statusText={error && (error as RtkQueryTransformedError).message}
                        />
                    </Label>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="ml-auto" onClick={onCancelPasswordVerification}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleVerifyPassword}
                        disabled={isEmpty(password)}
                        loading={isLoading}
                    >
                        <Trans>Next</Trans>
                    </Button>
                </Modal.Footer>
            </>
        );
    }
);
