import React, { ReactNode, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import clsx from 'clsx';

const LineWidth = 60;

export type Item = {
    key: string;
    title: ReactNode;
    color: string;
    value: number;
};
type DonutChartProps = {
    data: Item[];
};
export const DonutChart = ({ data }: DonutChartProps): JSX.Element => {
    const [selected, setSelected] = useState<number>();

    return (
        <div className="flex h-full w-full flex-row items-center gap-2">
            <div className="block h-full w-1/2">
                <PieChart
                    data={data}
                    animate
                    lineWidth={LineWidth}
                    viewBoxSize={[120, 120]}
                    segmentsStyle={(index) => ({
                        transition: 'stroke .3s',
                        opacity: index === selected ? 0.9 : 1,
                    })}
                    segmentsShift={(index) => (index === selected ? 3 : 1)}
                    label={({ dataEntry }) => dataEntry.value > 0 && dataEntry.percentage + '%'}
                    labelPosition={100 - LineWidth / 2}
                    center={[60, 60]}
                    reveal={100}
                    startAngle={270}
                    labelStyle={{
                        fill: '#000',
                        opacity: 0.75,
                        pointerEvents: 'none',
                        fontSize: '7px',
                    }}
                    onMouseOver={(_, index) => {
                        setSelected(index);
                    }}
                    onMouseOut={() => {
                        setSelected(undefined);
                    }}
                />
            </div>
            <div className="flex h-full w-1/2 flex-col justify-center gap-1">
                {data.map((item, index) => (
                    <div
                        key={item.key}
                        className={clsx('flex items-center gap-2 truncate', selected === index ? 'font-bold' : '')}
                        onMouseOver={() => setSelected(index)}
                        onFocus={() => setSelected(index)}
                        onMouseLeave={() => setSelected(undefined)}
                    >
                        <div
                            className="h-5 w-5 shrink-0 rounded-md border border-gray-300"
                            style={{ backgroundColor: item.color }}
                        />
                        <span className={'truncate'}>{item.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
