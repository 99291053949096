import { FC, useRef } from 'react';
import { faPlus, faRectangleHistory } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, EmptyState, Skeleton, useModal } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { TopicSubmissionItem } from 'Pages/MyTopicsPage/components/TopicSubmissionItem';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { PageHeader } from 'Shared/components/PageHeader';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { trpc } from 'Shared/trpc';
import { MeetingTopic, MeetingTopicSubmission, TopicSubmissionStatus } from 'Shared/types/meetingTopic';
import { AddTopicModal } from './components/AddTopicModal';

type MyTopicsPageView = 'open' | 'rejected' | 'completed';

const MyTopicsPageSearchParams = {
    view: enumeration('open', 'rejected', 'completed').default('open'),
    search: string(),
};

const viewToTopicSubmissionStatuses: Record<MyTopicsPageView, Array<TopicSubmissionStatus>> = {
    open: [TopicSubmissionStatus.DRAFT, TopicSubmissionStatus.SUBMITTED, TopicSubmissionStatus.ACCEPTED],
    rejected: [TopicSubmissionStatus.REJECTED],
    completed: [TopicSubmissionStatus.COMPLETED],
} as const;

type TopicSubmissionData = {
    meetingTopic: MeetingTopic;
    meetingTopicSubmission: MeetingTopicSubmission;
    completedTopic?: MeetingTopic;
};

const MyTopicsInfiniteScrollPage: FC<InfiniteScrollPageProps> = ({ limit, offset, updateInfiniteScroll }) => {
    const [{ view, search }] = useSearchParams(MyTopicsPageSearchParams);

    const { data: topicSubmissions = [], isLoading } = trpc.meetingTopic.listSubmissions.useQuery(
        { limit, offset, search, statuses: viewToTopicSubmissionStatuses[view] },
        { staleTime: 0 }
    ) as { data: TopicSubmissionData[]; isLoading: boolean };

    if (isLoading) {
        return (
            <div className="mb-2 flex flex-col gap-2">
                <Skeleton count={5} className="h-10" />
            </div>
        );
    }

    updateInfiniteScroll(topicSubmissions);

    return (
        <>
            {topicSubmissions.map(({ meetingTopic, meetingTopicSubmission, completedTopic }) => {
                return (
                    <TopicSubmissionItem
                        key={meetingTopic.id}
                        meetingTopic={meetingTopic}
                        meetingTopicSubmission={meetingTopicSubmission}
                        completedTopic={completedTopic}
                    />
                );
            })}
        </>
    );
};

export const MyTopicsPage = () => {
    const toolbarRef = useRef<HTMLDivElement>();

    const [{ view, search }, setSearchParams] = useSearchParams(MyTopicsPageSearchParams);

    const { open } = useModal();
    const { toggleButton, searchInput } = useResponsiveSearchInput({
        handleSearch: (search) => setSearchParams((current) => ({ ...current, search })),
        search,
        containerRef: toolbarRef,
        size: 'md',
    });

    const listSubmissionsParams = (view: MyTopicsPageView) => [
        {
            search,
            onlyCount: true,
            statuses: viewToTopicSubmissionStatuses[view],
        },
    ];

    const { data: openSubmissions } = trpc.meetingTopic.listSubmissions.useQuery(...listSubmissionsParams('open'));
    const { data: rejectedSubmissions } = trpc.meetingTopic.listSubmissions.useQuery(
        ...listSubmissionsParams('rejected')
    );
    const { data: completedSubmissions } = trpc.meetingTopic.listSubmissions.useQuery(
        ...listSubmissionsParams('completed')
    );

    const Tabs: Array<NavBarTab> = [
        {
            isDefault: true,
            to: { searchParams: { view: 'open' } },
            matchSearchParams: ['view'],
            title: t({ message: 'Open', id: `Open topics` }),
            count: openSubmissions,
        },
        {
            to: { searchParams: { view: 'rejected' } },
            matchSearchParams: ['view'],
            title: t({ message: 'Rejected', id: `Rejected topics` }),
            count: rejectedSubmissions,
        },
        {
            to: { searchParams: { view: 'completed' } },
            matchSearchParams: ['view'],
            title: t({ message: 'Completed', id: `Completed topics` }),
            count: completedSubmissions,
        },
    ];

    usePageTitle(t`My topics`);

    return (
        <>
            <PageHeader title={t`My topics`} />

            <div className="flex h-full max-h-full flex-col gap-6 overflow-hidden">
                <div className="px-6" ref={toolbarRef}>
                    <NavBar basePath={'/topics'} tabs={Tabs}>
                        {toggleButton}

                        <Button
                            aria-label={t`Add topic`}
                            color="primary"
                            icon={faPlus}
                            onClick={() => open(AddTopicModal)}
                        >
                            <span className="hidden lg:flex">
                                <Trans>Add topic</Trans>
                            </span>
                        </Button>
                    </NavBar>

                    <div className="mt-2">{searchInput}</div>
                </div>

                <InfiniteScroll
                    page={MyTopicsInfiniteScrollPage}
                    className="flex flex-col gap-4 py-4 px-4 scrollbar-light"
                    emptyPage={
                        <EmptyState icon={faRectangleHistory}>
                            <EmptyState.Text>
                                <Trans>No topics found</Trans>
                            </EmptyState.Text>
                            {view === 'open' && (
                                <div>
                                    <Button color="primary" icon={faPlus} onClick={() => open(AddTopicModal)}>
                                        <Trans>Add topic</Trans>
                                    </Button>
                                </div>
                            )}
                        </EmptyState>
                    }
                />
            </div>
        </>
    );
};
