import { useCallback, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmModal, useConfirm } from '@wedo/design-system';
import { useSessionUser } from 'App/store/usersStore';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { User, UserRole } from 'Shared/types/user';
import { Member } from 'Shared/types/userGroup';
import { Workspace } from 'Shared/types/workspace';
import { getMemberDifference } from 'Shared/utils/userGroup';

export const useManageMembers = (entity: Workspace | Template | Team) => {
    const sessionUser = useSessionUser();
    const { confirm } = useConfirm();

    const entityType = useMemo<'workspace' | 'template' | 'team'>(() => {
        if (!entity || typeof entity !== 'object') {
            return undefined;
        }
        if ('checklist_id' in entity) {
            return 'template';
        }
        if ('team_id' in entity) {
            return 'workspace';
        }
        return 'team';
    }, [entity]);

    const currentUserMember = entity?.userGroup?.members?.find(({ user_id }) => user_id === sessionUser.id);

    const isCurrentUserModerator =
        currentUserMember?.is_moderator ||
        (entityType === 'team'
            ? sessionUser.role === UserRole.ADMIN
            : sessionUser.role === UserRole.ADMIN && currentUserMember != null);

    const confirmRemoveCurrentUser = useCallback(() => {
        return confirm(
            {
                type: 'warning',
                title:
                    entityType === 'template'
                        ? t`Are you sure you want to remove yourself from the template?`
                        : t`Are you sure you want to remove yourself from the workspace?`,
                content:
                    entityType === 'template'
                        ? t`You will not have access to the template anymore.`
                        : t`You will not have access to the workspace anymore.`,
            },
            ConfirmModal
        );
    }, [entityType]);

    const confirmMoveToPrivate = async (user: User) => {
        const teamMembersNotInEntity = getMemberDifference(
            entity?.team?.userGroup?.members,
            entity?.userGroup?.members
        );
        if (!teamMembersNotInEntity.some(({ user_id }) => user_id === user.id)) {
            teamMembersNotInEntity.push({ user, user_id: user.id } as Member);
        }
        return confirm(
            {
                type: 'warning',
                title:
                    user.id === sessionUser.id
                        ? t`Are you sure you want to remove yourself?`
                        : t`Are you sure you want to remove ${user.full_name}?`,
                content: (
                    <div className="flex flex-col gap-2">
                        {user.id === sessionUser.id && (
                            <p>
                                {entityType === 'workspace' && <Trans>You will lose access to this workspace!</Trans>}
                                {entityType === 'template' && <Trans>You will lose access to this template!</Trans>}
                            </p>
                        )}

                        <p>
                            {entityType === 'workspace' && (
                                <Trans>
                                    Access to this workspace will be restricted and will no longer inherit access rights
                                    from the team.
                                </Trans>
                            )}
                            {entityType === 'template' && (
                                <Trans>
                                    Access to this template will be restricted and will no longer inherit access rights
                                    from the team.
                                </Trans>
                            )}
                        </p>
                    </div>
                ),
                confirmText: t`Restrict access`,
            },
            ConfirmModal
        );
    };

    return {
        isCurrentUserModerator,
        confirmRemoveCurrentUser,
        entityType,
        confirmMoveToPrivate,
    };
};
