import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faPartyHorn } from '@fortawesome/pro-duotone-svg-icons';
import { Plural, t, Trans } from '@lingui/macro';
import { Alert } from '@wedo/design-system';
import { ConfirmUsersReturn } from 'Shared/services/admin';

type ResultTabProps = {
    result: ConfirmUsersReturn;
};

export const ResultTab: FC<ResultTabProps> = ({ result }) => {
    if (!result) return null;

    return (
        <div className="flex h-full flex-col items-center justify-center gap-2 ">
            <FontAwesomeIcon
                className="mt-4 rounded-full bg-green-100 p-14 text-9xl text-green-500"
                icon={faPartyHorn}
            />
            <div className="mt-2 text-2xl font-bold text-green-500">
                <Trans>Users imported successfully</Trans>
            </div>
            <div className="mt-2 text-xl font-bold text-gray-500">
                <Trans>Invite them from the user list</Trans>
            </div>
            <div>
                {result.ignored > 0 && (
                    <Alert type="warning" title={t`Some users are already in the network`} className="mb-4">
                        <Plural
                            value={result.ignored}
                            one="# user was not imported"
                            other="# users were not imported"
                        />
                    </Alert>
                )}
            </div>
        </div>
    );
};
