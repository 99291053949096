import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Editor } from 'slate';
import {
    Button,
    CloseSource,
    ContextModalProps,
    Modal,
    Spinner,
    Tag,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { generateUUID } from '@wedo/utils';
import { getUser, useSessionUser } from 'App/store/usersStore';
import { TopicSubmissionHistory } from 'Pages/MyTopicsPage/components/TopicSubmissionHistory';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { ToolbarAddBlock } from 'Shared/components/editor/components/ToolbarAddBlock';
import { SubmitTopicModal } from 'Shared/components/meeting/SubmitTopicModal';
import { TopicPresenters } from 'Shared/components/meeting/TopicPresenters';
import { TopicDurationPopover } from 'Shared/components/meeting/topicView/TopicDuration';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { User } from 'Shared/types/user';

export const AddTopicModal: FC<{ topicId?: string } & ContextModalProps> = ({ topicId, children, ...modalProps }) => {
    const editorRef = useRef<Editor>();
    const topicTitleInput = useRef<HTMLInputElement>();

    const navigate = useNavigate();
    const currentUser = useSessionUser();

    const invalidateMyTopics = () => trpcUtils().meetingTopic.listSubmissions.invalidate();

    const { open } = useModal();
    const { show } = useNotification();
    const { confirm } = useConfirm();

    const { mutateAsync: addTopic } = trpc.meetingTopic.add.useMutation();
    const { mutateAsync: updateTopic } = trpc.meetingTopic.update.useMutation({ onSuccess: invalidateMyTopics });
    const { mutateAsync: removeTopic } = trpc.meetingTopic.remove.useMutation({ onSuccess: invalidateMyTopics });
    const { mutateAsync: submitTopics } = trpc.meetingTopic.submit.useMutation({
        onSuccess: invalidateMyTopics,
        onError: () => show(UnexpectedErrorNotification),
    });

    const [toolbarIdentifier] = useState<string>(generateUUID);
    const [topicTitle, setTopicTitle] = useState<string>('');
    const [topicPresenters, setTopicPresenters] = useState<Array<User>>([currentUser]);
    const [topicDuration, setTopicDuration] = useState<number>(0);
    const [newTopic, setNewTopic] = useState<MeetingTopic>();

    const deleteTopicAndCloseModal = async () => {
        await removeTopic({ topicId: newTopic?.id });
        return true;
    };

    const saveTopicData = async () => {
        await updateTopic({
            topicId: newTopic.id,
            title: topicTitle,
            duration: topicDuration,
            presenters: topicPresenters.map(({ id }) => id),
        });
    };

    const confirmSaveDraft = async () => {
        return confirm({
            type: 'danger',
            title: t`Leave draft topic`,
            content: t`Are you sure you don't want to save your draft topic? All changes made will be lost.`,
            confirmText: t`Delete draft`,
            cancelText: t`Keep editing`,
        });
    };

    const confirmEnterTopicTitle = async () => {
        return confirm({
            type: 'primary',
            title: t`Missing topic title`,
            content: t`Please enter a topic title to save topic submission draft`,
            isCancelButtonVisible: false,
            confirmText: t`Close`,
        });
    };

    const handleBeforeClose = async (source: CloseSource) => {
        if (topicId != null) {
            if (topicTitle.trim().length === 0) {
                await confirmEnterTopicTitle();
                return false;
            }
            await saveTopicData();
            return true;
        }

        if (source === 'backdrop-or-esc') {
            const leave = await confirmSaveDraft();
            if (leave) {
                if (newTopic?.id != null) {
                    await removeTopic({ topicId: newTopic?.id });
                }
                return true;
            }
            return false;
        }
        if (source === 'api') {
            return true;
        }
        return deleteTopicAndCloseModal();
    };

    const handleSave = async () => {
        if (topicTitle.trim().length === 0) {
            return;
        }

        await saveTopicData();
        void modalProps.close();
        void navigate(`/topics`);
    };

    const handleUserSelected = (user: User) => {
        if (topicPresenters.some(({ id }) => id === user.id)) {
            setTopicPresenters((current) => current.filter(({ id }) => id !== user.id));
        } else {
            topicPresenters.push(user);
            setTopicPresenters([...topicPresenters]);
        }
    };

    const handleSubmit = () => {
        if (topicTitle.trim().length === 0) {
            return;
        }

        open(SubmitTopicModal, {
            topicTitle,
            onDone: async (meetingId: Id) => {
                if (meetingId == null) {
                    return;
                }
                await saveTopicData();
                await submitTopics({ meetingId, topicIds: [newTopic.id] });
                void modalProps.close();
            },
        });
    };

    useEffect(() => {
        if (topicId == null) {
            addTopic({ title: '' }).then((topic: MeetingTopic) => {
                trpcUtils().meetingTopic.get.fetch(topic.id, { staleTime: 0 }).then(setNewTopic);
            });
        } else {
            trpcUtils()
                .meetingTopic.get.fetch(topicId, { staleTime: 0 })
                .then((topic: MeetingTopic) => {
                    setTopicTitle(topic.title);
                    setTopicDuration(topic.duration);
                    setTopicPresenters(topic.presenters.map(({ userId }) => getUser(userId)));
                    setNewTopic(topic);
                });
        }
    }, []);

    useEffect(() => {
        if (topicTitleInput.current != null) {
            topicTitleInput.current.focus();
        }
    }, [newTopic?.id]);

    if (newTopic?.id == null) {
        return (
            <Modal {...modalProps} size="lg" onBeforeClose={handleBeforeClose}>
                <Modal.Header title={t`Add topic`} />

                <Modal.Body>
                    <div className="flex flex-col items-center gap-4">
                        <Spinner color="blue" size="lg" />
                        {topicId == null ? t`Generating your topic` : t`Loading your topic`}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Cancel</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal
            {...modalProps}
            size="lg"
            initialFocus={topicTitleInput}
            onBeforeClose={handleBeforeClose}
            panelClassName="max-h-[calc(100vh-4rem)] flex flex-col"
        >
            <div className="p-4 flex justify-between gap-2 border-b">
                <input
                    ref={topicTitleInput}
                    value={topicTitle}
                    onChange={(e) => setTopicTitle(e.target.value)}
                    className="w-full outline-none text-xl font-semibold"
                    placeholder={t`Topic title`}
                />
                <div className="flex gap-2 items-center">
                    <Tag color="yellow" size="sm">
                        <Trans>Draft</Trans>
                    </Tag>
                    <Button
                        color="success"
                        icon={faPaperPlane}
                        onClick={handleSubmit}
                        title={topicTitle.trim().length === 0 && t`Topic title can't be empty`}
                        disabled={topicTitle.trim().length === 0}
                    >
                        <Trans>Submit</Trans>
                    </Button>
                </div>
            </div>

            <div className="overflow-y-auto scrollbar-light py-6">
                <div className="flex gap-2 items-center justify-between mx-[45px] rounded-t-md border">
                    <div className="flex-1 z-50 bg-red-50 w-full" id={toolbarIdentifier} />
                    <div className="flex gap-2 items-center pr-2 max-h-[40px]">
                        <TopicPresenters
                            topicPresenters={topicPresenters}
                            onUserSelected={handleUserSelected}
                            wrapperClassName="flex flex-col items-center py-2"
                            maxDisplayed={3}
                        />

                        <TopicDurationPopover
                            duration={topicDuration}
                            startAt={new Date()}
                            onUpdate={async (duration) => {
                                setTopicDuration(duration);
                                return {};
                            }}
                        />

                        <ToolbarAddBlock editorRef={editorRef} topicId={newTopic.id} />
                    </div>
                </div>

                <div className="max-w-[calc(100%-90px)] ml-[45px] border-l border-r border-b rounded-b-md">
                    <TopicEditor
                        editorRef={editorRef}
                        topic={newTopic}
                        viewMode={MeetingViewMode.TopicView}
                        className="min-h-[300px] pb-16 !max-w-full"
                        toolbarPluginClassName="!justify-start pl-2"
                        toolbarPluginIdentifier={toolbarIdentifier}
                    />
                </div>
            </div>

            <TopicSubmissionHistory topicId={topicId} />

            {topicId != null ? (
                <Modal.Footer>
                    <Button
                        onClick={modalProps.close}
                        disabled={topicTitle.trim().length === 0}
                        title={topicTitle.trim().length === 0 && t`Topic title can't be empty`}
                    >
                        <Trans>Close</Trans>
                    </Button>
                </Modal.Footer>
            ) : (
                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleSave}
                        disabled={topicTitle.trim().length === 0}
                        title={topicTitle.trim().length === 0 && t`Topic title can't be empty`}
                    >
                        <Trans>Save</Trans>
                    </Button>
                </Modal.Footer>
            )}
            {children}
        </Modal>
    );
};
