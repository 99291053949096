import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { tokenize } from 'ss-search';
import { Button } from '@wedo/design-system';
import { WorkspaceLabel } from 'Shared/components/workspace/WorkspaceSelect';
import { Team } from 'Shared/types/team';
import { Workspace } from 'Shared/types/workspace';

export const WorkspaceList: FC<{
    handleClick: (workspace: Workspace) => void;
    keyDown: React.KeyboardEvent<HTMLInputElement>;
    search: string;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
    allWorkspaces: Workspace[];
    teamsAndWorkspaces: Team[];
}> = ({ handleClick, keyDown, search, selectedIndex, setSelectedIndex, allWorkspaces, teamsAndWorkspaces }) => {
    const searchWords = tokenize(search);
    const [liElements, setLiElements] = useState(new Map<number, HTMLLIElement>());

    const addLiElement = (index: number, element: HTMLLIElement) => {
        setLiElements(liElements.set(index, element));
    };

    useEffect(() => {
        if (liElements.size > 0) {
            setLiElements(new Map());
        }
    }, [teamsAndWorkspaces]);

    useEffect(() => {
        if (keyDown === null || !liElements.has(selectedIndex)) {
            return;
        }

        if (keyDown.code === 'ArrowDown' && liElements.has(selectedIndex + 1)) {
            setSelectedIndex(selectedIndex + 1);
            liElements.get(selectedIndex + 1).scrollIntoView({ block: 'center' });
        } else if (keyDown.code === 'ArrowUp' && liElements.has(selectedIndex - 1)) {
            setSelectedIndex(selectedIndex - 1);
            liElements.get(selectedIndex - 1).scrollIntoView({ block: 'center' });
        } else if (keyDown.code === 'Enter') {
            handleClick(allWorkspaces[selectedIndex]);
        }
    }, [keyDown]);

    const WorkspaceItem: FC<{ workspace: Workspace; index: number }> = ({ workspace, index }) => (
        <li
            ref={(element) => {
                if (element !== null && !liElements.has(index)) {
                    addLiElement(index, element);
                }
            }}
            key={workspace.id}
            id={`workspace-${workspace.id}`}
            className={clsx(
                'm-px cursor-pointer snap-start items-center py-1 hover:bg-gray-50',
                index === selectedIndex && 'bg-gray-100'
            )}
        >
            <Button
                tabIndex={-1}
                className="flex w-full"
                variant="ghost"
                onClick={() => handleClick(workspace)}
                onMouseEnter={() => setSelectedIndex(index)}
            >
                <WorkspaceLabel workspace={workspace} searchWords={searchWords} />
            </Button>
        </li>
    );

    return (
        <>
            {teamsAndWorkspaces
                .filter((team) => team.workspaces.length > 0)
                .map((team) => (
                    <React.Fragment key={team.id}>
                        <li className="snap-start text-xs font-bold uppercase">{team.name}</li>
                        {team.workspaces.map((workspace, wIndex) => (
                            <WorkspaceItem
                                key={wIndex}
                                workspace={workspace}
                                index={allWorkspaces.findIndex(({ id }) => id === workspace.id)}
                            />
                        ))}
                    </React.Fragment>
                ))}
        </>
    );
};
