import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import {
    CustomFieldItem,
    CustomFieldItemProps,
} from 'Pages/settings/customFields/components/CustomFieldItem/CustomFieldItem';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';

type CustomFieldDraggableItemProps = CustomFieldItemProps;

export const CustomFieldDraggableItem: React.FC<CustomFieldDraggableItemProps> = ({ customField, className }) => {
    const { draggableStyle } = useDndSortableVerticalStrategy();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: `field-${customField.id}`,
        data: {
            cardType: 'field',
            customField,
        },
    });

    const style = draggableStyle(transform, transition, isDragging);

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={clsx(
                '@container group/drag flex w-full items-center gap-2 overflow-hidden',
                !isDragging && 'cursor-grab',
                isDragging && 'cursor-grabbing'
            )}
        >
            <CustomFieldItem customField={customField} className={clsx(className, 'flex-1')} />
        </div>
    );
};
