import { useEffect } from 'react';
import { isString } from 'lodash-es';

export const usePageTitle = (pageTitle: string | (() => string)) => {
    const title = isString(pageTitle) ? pageTitle : pageTitle?.();

    useEffect(() => {
        if (title) {
            document.title = `${title} | WEDO`;
        }

        return () => {
            document.title = 'WEDO';
        };
    }, [title]);
};
