import { useEffect } from 'react';
import { type State, type Store } from '@wedo/pdf/meeting/store';

export const useData = <T extends keyof State>(store: Store, name: T, data: State[T]) => {
    useEffect(() => {
        if (data != null) {
            store.setState((state) => {
                state[name] = data;
            });
        }
    }, [data]);
};
