import { ReactNode, useMemo, useState } from 'react';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import {
    Active,
    DndContext,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    pointerWithin,
    TouchSensor,
    useDroppable,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Plural, plural } from '@lingui/macro';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { Editor, NodeEntry, Range, Transforms } from 'slate';
import { createStore, StoreApi, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Button, ConfirmModal, useModal, useModalStore } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { refreshEditor } from 'Shared/components/editor/plugins/serverBlocksPlugin/serverBlocksPlugin';
import { is } from 'Shared/components/editor/utils/node';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { AttachmentItem } from 'Shared/components/file/AttachmentItem/AttachmentItem';
import { DroppableFile } from 'Shared/components/file/DroppableFile';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { type Attachment as AttachmentType } from 'Shared/types/attachment';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { Workspace } from 'Shared/types/workspace';
import { isEditorFocused, Plugin, useEditorIsFocused } from '../Editor';
import { createBlock, reorderBlocks } from '../utils/block';

export const Attachment = 'attachment';

type Store = {
    attachments: Array<{ blockId: string; attachments: Array<AttachmentType> }>;
};

export const getAllSelectedAttachments = (editor: Editor) => {
    return Array.from<NodeEntry<MeetingBlock>>(Editor.nodes(editor, { match: is(Attachment) })).flatMap(
        ([{ attachments }]) => attachments
    );
};

export const deleteAttachmentBlockConfirmationProps = (attachments: Partial<AttachmentType>[]) => ({
    type: 'danger',
    title: plural(attachments.length, {
        one: 'Delete attachment',
        other: `Delete ${attachments.length} attachments`,
    }),
    content: (
        <div className="max-w-[20rem] truncate">
            <p>
                <Plural
                    value={attachments.length}
                    other="Do you want to delete the following attachments?"
                    one="Do you want to delete the following attachment?"
                />
            </p>
            <ul className="list-disc pl-4 mt-2">
                {attachments.map((attachment: AttachmentType) => (
                    <li key={attachment.id}>{attachment?.currentVersion?.filename}</li>
                ))}
            </ul>
        </div>
    ),
});

export const createAttachmentBlock = (attachments: AttachmentType[]) => {
    return createBlock({
        type: Attachment,
        attachments,
        children: [{ text: '' }],
    });
};

type DraggableAttachmentsWrapperProps = {
    store: StoreApi<Store>;
    children: ReactNode;
};

const DraggableAttachmentsWrapper = ({ store, children }: DraggableAttachmentsWrapperProps) => {
    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();
    const [active, setActive] = useState<Active>();

    const items = useStore(store, ({ attachments }) => attachments.map(({ blockId }) => blockId));

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 15,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    const handleDragStart = ({ active }: DragStartEvent) => {
        setActive(active);
    };

    const handleDragOver = ({ active, over }: DragOverEvent) => {
        if (active != null && over != null) {
            store.setState(({ attachments }) => {
                const activeIndex = attachments.findIndex(({ blockId }) => blockId === active.data.current.blockId);
                const overIndex = attachments.findIndex(({ blockId }) => blockId === over.data.current.blockId);
                const fromIndex = attachments[activeIndex].attachments.findIndex(({ id }) => id === active.id);
                const toIndex = attachments[overIndex].attachments.findIndex(({ id }) => id === over.id);
                const attachment = attachments[activeIndex].attachments[fromIndex];
                attachments[activeIndex].attachments.splice(fromIndex, 1);
                attachments[overIndex].attachments.splice(
                    toIndex === -1 ? attachments[overIndex].attachments.length : toIndex,
                    0,
                    attachment
                );
            });
        }
    };

    const handleDragEnd = () => {
        let hasChanges = false;
        const allAttachments = store.getState().attachments;
        editor.children.forEach(({ id, attachments }, index) => {
            const currentAttachments = allAttachments.find(({ blockId }) => blockId === id)?.attachments;
            if (
                currentAttachments != null &&
                (currentAttachments.length !== attachments?.length ||
                    currentAttachments.some(({ id }, index) => id !== attachments[index].id))
            ) {
                hasChanges = true;
                if (currentAttachments.length === 0) {
                    Transforms.removeNodes(editor, { at: [index] });
                } else {
                    Transforms.setNodes(
                        editor,
                        { attachments: currentAttachments.map((attachment, order) => ({ ...attachment, order })) },
                        { at: [index] }
                    );
                }
            }
        });
        if (hasChanges) {
            forceSave(editor);
        }
        setActive(null);
    };

    const handleDragCancel = () => {
        setActive(null);
    };

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            sensors={sensors}
            collisionDetection={pointerWithin}
        >
            <SortableContext items={items} strategy={verticalListSortingStrategy} disabled={isReadOnly}>
                {children}
            </SortableContext>
            <DragOverlay dropAnimation={null}>{active?.data.current.dragOverlay?.()}</DragOverlay>
        </DndContext>
    );
};

type AttachmentDragOverlayProps = {
    attachment: AttachmentType;
    workspace: Workspace;
};

const AttachmentDragOverlay = ({ attachment, workspace }: AttachmentDragOverlayProps) => {
    return (
        <AttachmentItem
            attachment={attachment}
            workspaces={workspace ? [workspace] : null}
            isReadonly={true}
            showMenu={false}
            showTooltip={false}
            attachmentList={[]}
            relation={{}}
        />
    );
};

type DraggableAttachmentElementProps = {
    attachment: AttachmentType;
    attachmentsList: AttachmentType[];
    meetingBlockId: Id;
    workspace: Workspace;
    onDelete: () => void;
    isReadOnly: boolean;
    showMenu: boolean;
    meetingId: Id;
    topicId: Id;
};

const DraggableAttachmentElement = ({
    attachment: initialAttachment,
    attachmentsList,
    onDelete,
    isReadOnly,
    showMenu,
    meetingBlockId,
    workspace,
    meetingId,
    topicId,
}: DraggableAttachmentElementProps) => {
    const editor = useSlateStatic();

    const [attachment, setAttachment] = useState<AttachmentType>(initialAttachment);

    const { active, attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: attachment.id,
        transition: { easing: 'ease', duration: 125 },
        data: {
            blockId: meetingBlockId,
            dragOverlay: () => <AttachmentDragOverlay attachment={attachment} workspace={workspace} />,
        },
    });

    const isDraftTopic = topicId != null && meetingId == null;

    const [previousInitialAttachment, setPreviousInitialAttachment] = useState<AttachmentType>();
    if (initialAttachment && !isEqual(previousInitialAttachment, initialAttachment)) {
        setPreviousInitialAttachment(initialAttachment);
        setAttachment(initialAttachment);
    }

    const handleReload = (reloadedAttachment: AttachmentType, refreshTopicEditor = false) => {
        setAttachment({ ...attachment, ...reloadedAttachment });
        if (refreshTopicEditor && !isDraftTopic) {
            refreshEditor(editor, meetingId, topicId);
        }
    };

    return (
        <div
            ref={setNodeRef}
            style={{ transform: CSS.Transform.toString(transform), transition }}
            className={clsx(isDragging && 'opacity-50', isReadOnly && 'cursor-default')}
            {...listeners}
            {...attributes}
        >
            <AttachmentItem
                attachment={attachment}
                attachmentList={attachmentsList}
                relation={{ meeting_block_id: meetingBlockId }}
                workspaceId={workspace ? workspace.id : null}
                workspaces={workspace ? [workspace] : null}
                onDelete={onDelete}
                isReadonly={isReadOnly}
                showMenu={showMenu}
                showTooltip={active == null}
                onReload={handleReload}
            />
        </div>
    );
};

type AttachmentElementProps = {
    store: StoreApi<Store>;
    meetingId: Id;
    topicId: Id;
    workspaceId: Id;
} & RenderElementProps;

const AttachmentElement = ({
    store,
    meetingId,
    topicId,
    workspaceId,
    element,
    children,
    attributes: { ref, ...attributes },
}: AttachmentElementProps) => {
    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();
    const selected = useSelected();
    const focused = useEditorIsFocused();
    const isSelected = !isReadOnly && selected && focused && Range.isCollapsed(editor.selection);

    const { open: openModal } = useModal();
    const { setNodeRef } = useDroppable({ id: element.id, data: { blockId: element.id } });
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: workspaceId == null });

    const { mutateAsync: removeAttachmentRelations } = trpc.attachment.removeRelations.useMutation();

    const attachments = useStore(store, ({ attachments }) =>
        (attachments.find(({ blockId }) => blockId === element.id)?.attachments ?? []).concat(element.filenames ?? [])
    );

    const attachmentItems = useMemo(
        () => attachments.map((attachment) => (typeof attachment === 'string' ? null : attachment.id)).filter(Boolean),
        [attachments]
    );

    const handleAddAttachmentsDone = (addedAttachments: AttachmentType[]) => {
        if (addedAttachments?.length > 0) {
            Transforms.setNodes(
                editor,
                {
                    attachments: attachments.concat(addedAttachments),
                },
                { at: [editor.children.findIndex(({ id }) => id === element.id)] }
            );
            forceSave(editor);
        }
    };

    const handleAdd = () => {
        openModal(AddAttachmentModal, {
            workspaceId,
            onDone: handleAddAttachmentsDone,
        });
    };

    const handleDelete = async (attachmentId: Id) => {
        const path = ReactEditor.findPath(editor, element);
        if (attachments.length > 1) {
            await removeAttachmentRelations([{ attachmentId, meetingBlockId: element.id }]);
            Transforms.setNodes(
                editor,
                { attachments: attachments.filter(({ id }) => id !== attachmentId) },
                { match: ({ id }) => id === element.id, at: path }
            );
            forceSave(editor);
        } else {
            Transforms.removeNodes(editor, { match: ({ id }) => id === element.id, at: path });
            reorderBlocks(editor);
            forceSave(editor);
        }
    };

    const handleDropFile = async (files: FileList) => {
        openModal(AddAttachmentModal, {
            files,
            workspaceId,
            allowedSources: ['upload', 'url'],
            onDone: handleAddAttachmentsDone,
        });
    };

    const handleRef = (element: HTMLDivElement) => {
        ref(element);
        setNodeRef(element);
    };

    return (
        <div ref={handleRef} data-block-id={element.id} {...attributes} className="@container">
            <DroppableFile
                readonly={isReadOnly}
                className={'!justify-start'}
                onDrop={handleDropFile}
                iconOnly
                contentEditable={false}
            >
                <div
                    className={clsx(
                        '@2xl:grid-cols-4 @xl:grid-cols-3 @sm:grid-cols-2 grid w-full grid-cols-1 gap-1',
                        isSelected && 'ring-2 ring-blue-500 ring-offset-2 group-[.is-dragging]:ring-0'
                    )}
                >
                    <SortableContext disabled={isReadOnly} items={attachmentItems} strategy={rectSortingStrategy}>
                        {attachments.map((attachment: AttachmentType | string) => (
                            <DraggableAttachmentElement
                                key={typeof attachment === 'string' ? attachment : attachment.id}
                                attachment={
                                    typeof attachment === 'string'
                                        ? ({ currentVersion: { filename: attachment } } as AttachmentType)
                                        : attachment
                                }
                                attachmentsList={attachments}
                                meetingBlockId={element.id}
                                workspace={workspace}
                                onDelete={typeof attachment === 'string' ? null : () => handleDelete(attachment.id)}
                                isReadOnly={typeof attachment === 'string' || isReadOnly}
                                showMenu={typeof attachment !== 'string'}
                                meetingId={meetingId}
                                topicId={topicId}
                            />
                        ))}
                    </SortableContext>
                    {!isReadOnly && <Button size="sm" icon={faPlus} onClick={handleAdd} />}
                </div>
            </DroppableFile>
            {children}
        </div>
    );
};

export const attachmentPlugin = (options: { workspaceId: Id; meetingId: Id; topicId: Id }): Plugin => {
    const store = createStore<Store>()(immer(() => ({ attachments: [] })));
    return {
        onChange: (_, children) => {
            const previousAttachments = store.getState().attachments;
            const nextAttachments =
                children
                    ?.filter(({ type }) => type === 'attachment')
                    .map(({ id, attachments }) => ({ blockId: id, attachments })) ?? [];

            if (
                previousAttachments.length !== nextAttachments.length ||
                previousAttachments.some(({ blockId, attachments }, index) => {
                    const nextAttachment = nextAttachments[index];
                    return (
                        blockId !== nextAttachment.blockId ||
                        attachments?.length !== nextAttachment.attachments?.length ||
                        attachments?.some(({ id, currentVersion }, index) => {
                            const attachment = nextAttachment.attachments[index];
                            return (
                                id !== attachment.id || currentVersion.filename !== attachment.currentVersion.filename
                            );
                        })
                    );
                })
            ) {
                store.setState({ attachments: nextAttachments });
            }
            return false;
        },
        isVoid: (editor, element) => element.type === Attachment,
        renderWrapper: (editor, children) => (
            <DraggableAttachmentsWrapper store={store}>{children}</DraggableAttachmentsWrapper>
        ),
        renderElement: (editor, { children, element, attributes }) =>
            element.type === Attachment && (
                <AttachmentElement
                    store={store}
                    workspaceId={options?.workspaceId}
                    element={element}
                    attributes={attributes}
                    topicId={options.topicId}
                    meetingId={options.meetingId}
                >
                    {children}
                </AttachmentElement>
            ),
        deleteBackward: (editor, unit) => {
            const selectedAttachments = getAllSelectedAttachments(editor);
            if (selectedAttachments.length === 0 || !isEditorFocused(editor)) {
                return false;
            }

            useModalStore.getState().actions.open(ConfirmModal, {
                ...deleteAttachmentBlockConfirmationProps(selectedAttachments),
                onConfirm: async () => Editor.deleteBackward(editor, { unit }),
                resolve: () => false,
            });

            return true;
        },
        deleteFragment: async (editor) => {
            const selectedAttachments = getAllSelectedAttachments(editor);
            if (selectedAttachments.length === 0) {
                return false;
            }

            return new Promise<boolean>((resolve) => {
                useModalStore.getState().actions.open(ConfirmModal, {
                    ...deleteAttachmentBlockConfirmationProps(selectedAttachments),
                    onConfirm: async () => resolve(false),
                    resolve: () => resolve(true),
                });
            });
        },
        insertText: (editor) => getAllSelectedAttachments(editor).length > 0,
        insertBreak: (editor) => getAllSelectedAttachments(editor).length > 0,
        insertSoftBreak: (editor) => getAllSelectedAttachments(editor).length > 0,
        onDrop: (editor) => getAllSelectedAttachments(editor).length > 0,
        onPaste: (editor, event) => {
            const selectedAttachments = getAllSelectedAttachments(editor);
            if (selectedAttachments.length > 0) {
                event.stopPropagation();
                event.preventDefault();
            }
            return selectedAttachments.length !== 0;
        },
    };
};
