import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { ActivityLog } from '@wedo/db';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getUser } from 'App/store/usersStore';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { ActivityLogObject } from 'Shared/types/activityLog';
import { Template } from 'Shared/types/template';

const TemplateTitle: FC<{ template: Template }> = ({ template }) => {
    return (
        <Button size="xs" variant="link" href={`/templates/${template?.id}`} className="w-full">
            <div className="text-xs group/meeting flex items-center gap-1 w-full font-semibold">
                <span className="truncate">{template?.name}</span>
            </div>
        </Button>
    );
};

const TemplateUserTitle: FC<{ userId: Id; templateId: Id }> = ({ userId, templateId }) => {
    const user = getUser(userId);
    const { data: template } = useGetChecklistTemplateQuery(templateId);

    return (
        <div className="flex gap-1 items-center text-xs max-w-full truncate font-semibold">
            {user?.full_name} <Trans>in</Trans> <TemplateTitle template={template} />
        </div>
    );
};

const TemplateTitleOldValueEventKeys = new Set([
    'deleted_checklist_template',
    'restored_checklist_template',
    'archived_checklist_template',
    'unarchived_checklist_template',
]);

const TemplateUserTitleOldValueEventKeys = new Set([
    'revoked_checklist_template_member',
    'promoted_checklist_template_member',
    'removed_member_from_checklist_template',
]);

export const TemplateModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    if (auditLog.objectType !== ActivityLogObject.TEMPLATE) {
        return null;
    }

    if (TemplateTitleOldValueEventKeys.has(auditLog.eventKey)) {
        return <TemplateTitle template={auditLog.oldValue as Template} />;
    }

    if (TemplateUserTitleOldValueEventKeys.has(auditLog.eventKey)) {
        return (
            <TemplateUserTitle templateId={auditLog.objectId} userId={(auditLog.oldValue as { user_id: Id }).user_id} />
        );
    }

    return (
        <>
            {auditLog.eventKey === 'created_checklist_template' && (
                <TemplateTitle template={auditLog.newValue as Template} />
            )}

            {auditLog.eventKey === 'changed_checklist_template_privacy' && (
                <div className="text-xs flex gap-1 items-center">
                    {(auditLog.newValue as Template).private ? (
                        <Trans>
                            <TemplateTitle template={auditLog.oldValue as Template} /> is now private
                        </Trans>
                    ) : (
                        <Trans>
                            <TemplateTitle template={auditLog.oldValue as Template} /> is now public
                        </Trans>
                    )}
                </div>
            )}

            {auditLog.eventKey === 'added_member_to_checklist_template' && (
                <TemplateUserTitle
                    templateId={auditLog.objectId}
                    userId={(auditLog.newValue as { user_id: Id }).user_id}
                />
            )}
        </>
    );
};
