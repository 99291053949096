import React, { useContext, useEffect, useId, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    CodeInput,
    Form,
    HelperText,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { useInputState } from '@wedo/utils/hooks';
import { TotpModalFooter } from 'Pages/settings/security/components/TotpModalContent/TotpModalFooter';
import { useChangeStage } from 'Pages/settings/security/hooks/useChangeStage';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { PhoneNumberPicker } from 'Shared/components/PhoneNumberPicker/PhoneNumberPicker';
import { trpc } from 'Shared/trpc';
import { Title } from './Title';

export const PhoneNumber = (): JSX.Element => {
    const id = useId();
    const phoneNumberInputRef = useRef<HTMLInputElement>();
    const { state, dispatch } = useContext(TotpContext);
    const [phone, , handlePhoneChange] = useInputState();
    const [code, , handleCodeChange] = useInputState('');
    const [phoneError, setPhoneError] = useState<string>('');
    const { show: showNotification } = useNotification();

    const { next } = useChangeStage();
    const { mutate: verify, isLoading: isVerifying } = trpc.auth.verifyPhoneVerificationCode.useMutation({
        onSuccess: next,
        onError: () => {
            dispatch({ type: 'error', value: t`Wrong code` });
        },
    });

    const {
        mutate: send,
        isLoading: isLoadingSend,
        isSuccess: isCodeSent,
    } = trpc.auth.sendPhoneVerificationCode.useMutation({
        onSuccess: (data: any) => {
            dispatch({ type: 'phoneNumber', value: phone });
            dispatch({ type: 'authenticators', value: state.authenticators.concat(data?.id) });
        },
        onError: (e) => {
            if (e.message === 'landline') {
                setPhoneError(t`A landline number can't receive SMS`);
                return;
            }

            showNotification(UnexpectedErrorNotification);
        },
    });

    useEffect(() => {
        if ('current' in phoneNumberInputRef) {
            setTimeout(() => phoneNumberInputRef.current?.focus(), 100);
        }
    }, [phoneNumberInputRef]);

    const handleSendCodeSubmit = async () => {
        if (!phoneNumberInputRef.current.isValid) {
            setPhoneError(t`Invalid phone number`);
        } else {
            await send(phone);
        }
    };
    const handleCodeSubmit = () => {
        void verify({ verificationCode: code, phoneNumber: state.phoneNumber });
    };

    return (
        <>
            <Modal.Body>
                <Title>
                    <Trans>Enter your phone number</Trans>
                </Title>
                <Form layout="vertical" onSubmit={handleSendCodeSubmit}>
                    <Form.Item htmlFor={`${id}phone-number`} label={t`Mobile phone number`}>
                        <PhoneNumberPicker
                            ref={phoneNumberInputRef}
                            placeholder="+41 12 345 67 89"
                            initialValue={''}
                            onChange={(value) => {
                                setPhoneError('');
                                handlePhoneChange(value);
                            }}
                            status={phoneError === '' ? 'default' : 'error'}
                        >
                            <Button color={'primary'} type="submit" loading={isLoadingSend}>
                                <Trans>Send code</Trans>
                            </Button>
                        </PhoneNumberPicker>
                        {phoneError !== '' && (
                            <HelperText className="mt-1" status={'error'}>
                                {phoneError}
                            </HelperText>
                        )}
                        <HelperText className="mt-1">
                            <Trans>
                                If you lose access to your authenticator app we can send you a recovery code by SMS.
                            </Trans>
                        </HelperText>
                    </Form.Item>
                </Form>
                <Title className="mt-8">
                    <Trans>Enter the confirmation code</Trans>
                </Title>
                <Form layout="vertical" onSubmit={handleCodeSubmit}>
                    <Form.Item htmlFor={`${id}phone-code`} label={t`4-digit code`}>
                        <CodeInput
                            id={id + 'phone-code'}
                            autofocus={isCodeSent}
                            length={4}
                            initialValue={code}
                            onChange={handleCodeChange}
                            onComplete={handleCodeSubmit}
                            disabled={!isCodeSent}
                        />
                        {state.error !== '' && (
                            <HelperText className="mt-1" status={'error'}>
                                {state.error}
                            </HelperText>
                        )}
                        <HelperText className="mt-1">
                            <Trans>An SMS code has been sent to your mobile phone.</Trans>
                        </HelperText>
                    </Form.Item>
                </Form>
            </Modal.Body>
            <TotpModalFooter onNext={handleCodeSubmit} isLoading={isVerifying} />
        </>
    );
};
