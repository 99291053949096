import React from 'react';
import { Trans } from '@lingui/macro';
import { Checkbox } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { Meeting, MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';

const blockTypes = [
    { label: <Trans>Open tasks</Trans>, value: 'open_task' },
    { label: <Trans>Completed tasks</Trans>, value: 'completed_task' },
    { label: <Trans>Paragraphs</Trans>, value: 'paragraph' },
    { label: <Trans>Decisions</Trans>, value: 'decision' },
    { label: <Trans>Attachments</Trans>, value: 'attachment' },
    { label: <Trans>Images</Trans>, value: 'image' },
    { label: <Trans>Votes</Trans>, value: 'vote' },
];

type BlockTypesPickerProps = {
    meeting: Meeting;
    selectedBlocks: string[];
    onChange: (values: string[]) => void;
};

export const BlockTypesPicker = ({ meeting, selectedBlocks, onChange }: BlockTypesPickerProps) => {
    const { currentUser } = useCurrentUserContext();
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const handleChange = (value: string) =>
        onChange(
            selectedBlocks?.includes(value)
                ? selectedBlocks.filter((item) => item !== value)
                : [...selectedBlocks, value]
        );

    return (
        <>
            <div className={'mb-1 mt-3 font-bold'}>
                <Trans>Block types to keep</Trans>
            </div>
            <div className="flex flex-row flex-wrap gap-x-6 gap-y-2 text-sm">
                {blockTypes.map((blockType) => {
                    return (
                        (blockType.value !== 'vote' || isEditor) && (
                            <div className="" key={blockType.value}>
                                <Checkbox
                                    id={blockType.value}
                                    checked={selectedBlocks?.includes(blockType.value)}
                                    name={blockType.value}
                                    onChange={() => handleChange(blockType.value)}
                                    className="mr-2"
                                />
                                <label htmlFor={blockType.value}>{blockType.label}</label>
                            </div>
                        )
                    );
                })}
            </div>
        </>
    );
};
