import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Tabs } from '@wedo/design-system';
import { UrlSourceForm } from 'Shared/components/file/AddAttachmentModal/UrlSourceForm';

type UrlTabPanelProps = {
    linkInput: React.MutableRefObject<HTMLInputElement>;
    url: string;
    urlError: string;
    fileName: string;
    fileNameError?: string;
    onUrlChange: React.Dispatch<React.SetStateAction<string>>;
    onFileNameChange: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: () => void;
    closeModal: () => void;
};

export const UrlSourceTabPanel: FC<UrlTabPanelProps> = ({
    linkInput,
    url,
    urlError,
    onUrlChange,
    fileName,
    fileNameError = '',
    onFileNameChange,
    onSubmit,
    closeModal,
}) => {
    return (
        <Tabs.Panel>
            <UrlSourceForm
                linkInput={linkInput}
                url={url}
                urlError={urlError}
                fileName={fileName}
                fileNameError={fileNameError}
                onUrlChange={onUrlChange}
                onFileNameChange={onFileNameChange}
                onSubmit={onSubmit}
            />

            <div className="flex gap-2 justify-end mt-8">
                <Button onClick={closeModal}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={onSubmit}>
                    <Trans>Save</Trans>
                </Button>
            </div>
        </Tabs.Panel>
    );
};
