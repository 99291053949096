import { z } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Id } from '@wedo/types';
import { NewOrganizationSchema } from 'Pages/onboarding/hooks/useNewOrganizationSchema';
import { Team } from 'Shared/types/team';
import { User } from 'Shared/types/user';

export const UPDATE_USER = Symbol('update user');

export const TOTAL_ONBOARDING_STEPS = 9;

export type NewOrganizationType = z.infer<NewOrganizationSchema> & {
    originalUser: User & { reset_token?: string; network_id?: Id };
};

export type NewOrganizationAction =
    | {
          key: keyof Omit<NewOrganizationType, 'originalUser' | 'invites' | 'workspaces' | 'networks'>;
          value: string;
      }
    | { key: keyof Pick<NewOrganizationType, 'invites' | 'workspaceNames' | 'networks'>; value: string[] }
    | { key: typeof UPDATE_USER; value: Partial<User> & { team?: Partial<Team>; network_id?: Id } }
    | { key: 'originalUser'; value: User & { reset_token?: string } };

type Actions = {
    setStep: (step: number) => void;
    updateState: (changes: Partial<NewOrganizationType>) => void;
    clearOnboarding: () => void;
};

const defaultOnboardingState: NewOrganizationType = {
    step: 0,
    token: '',
    authToken: '',
    email: '',
    userId: '',
    networkId: '',
    authLink: '',
    originalUser: null,
    domainFromEmail: '',
    teamName: '',
    teamId: '',
    invites: ['', '', ''],
    workspaceNames: [],
    firstName: '',
    lastName: '',
    jobTitle: '',
    language: '',
    password: '',
    passwordConfirmation: '',
    organizationName: '',
    organizationIndustry: null,
    organizationSize: null,
    organizationWebsite: '',
    networks: [],
};

export const useOnboardingStore = create(
    persist<NewOrganizationType & { actions: Actions }>(
        (set) => ({
            ...defaultOnboardingState,
            actions: {
                setStep: (step) => {
                    set(() => ({ step: step }));
                },
                updateState: (changes) => {
                    set(() => ({ ...changes }));
                },
                clearOnboarding: () => {
                    set(() => ({
                        ...defaultOnboardingState,
                    }));
                },
            },
        }),
        {
            name: 'onboardingStore',
            partialize: (state: NewOrganizationType) =>
                Object.fromEntries(
                    Object.entries(state).filter(
                        ([key]) => !['actions', 'password', 'passwordConfirmation'].includes(key)
                    ) // don't save actions and password in localStorage
                ),
        }
    )
);
