import { useFocusedCommentIdStore } from 'Shared/components/editor/plugins/commentPlugin/commentPlugin';
import { trpcUtils } from 'Shared/trpc';

type Props = {
    meetingTopicId: string;
    meetingBlockId?: string;
    value?: unknown;
    focus?: boolean;
};

export const addComment = ({ meetingTopicId, meetingBlockId, value, focus }: Props) => {
    const utils = trpcUtils();
    utils.client.meetingComment.add
        .mutate({
            meetingTopicId,
            meetingBlockId,
            value: value ?? [{ type: 'paragraph', children: [{ type: 'text', children: [{ text: '' }] }] }],
        })
        .then(({ id }) => {
            if (focus) {
                useFocusedCommentIdStore.setState(id);
            }
            utils.meetingComment.listByTopicId.invalidate(meetingTopicId);
        });
};
