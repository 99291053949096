import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Id } from '@wedo/types';
import { Task } from 'Shared/types/task';

export type PanelProps = {
    task: Task;
    onNext: () => void;
    workspaceId?: Id;
};

export const Panel = ({ className, children }: HTMLAttributes<HTMLDivElement>) => {
    return <div className={clsx('h-full gap-2', className)}>{children}</div>;
};
