import React, { FC } from 'react';
import { faHome, faLink, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tabs } from '@wedo/design-system';
import { AddAttachmentModalProps, AllowedSource } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';

type AddAttachmentModalTabsHeaderProps = {
    isUploading: boolean;
    allowedSources: AddAttachmentModalProps['allowedSources'];
    onTabChange: React.Dispatch<React.SetStateAction<AllowedSource>>;
    externalLinkTabButton: React.MutableRefObject<HTMLButtonElement>;
};

export const AddAttachmentModalTabsHeader: FC<AddAttachmentModalTabsHeaderProps> = ({
    allowedSources,
    onTabChange,
    isUploading,
    externalLinkTabButton,
}) => {
    return (
        <Tabs.Header>
            {allowedSources.includes('upload') && (
                <Tabs.Tab isDisabled={isUploading} icon={faUpload} onClick={() => onTabChange('upload')}>
                    <Trans>From computer</Trans>
                </Tabs.Tab>
            )}

            {allowedSources.includes('internal') && (
                <Tabs.Tab isDisabled={isUploading} icon={faHome} onClick={() => onTabChange('internal')}>
                    <Trans>From WEDO</Trans>
                </Tabs.Tab>
            )}

            {allowedSources.includes('url') && (
                <Tabs.Tab
                    isDisabled={isUploading}
                    icon={faLink}
                    ref={externalLinkTabButton}
                    onClick={() => onTabChange('url')}
                >
                    <Trans>From link</Trans>
                </Tabs.Tab>
            )}
        </Tabs.Header>
    );
};
