import { Circle, Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const EvernoteIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg viewBox="0 0 512 512" width="512" height="512" fill="none" style={style}>
            <Circle cx="256" cy="256" r="250" fill="#00A82D" />
            <Path
                d="M197.072 167.507C197.072 170.705 196.812 176.035 193.694 179.499C190.316 182.697 185.119 182.964 182.001 182.964C164.46 182.964 140.127 178.556 123.537 185.362C148.857 139.928 197.909 109.738 197.591 112.342C195.254 130.32 197.072 149.424 197.072 167.507ZM249.56 373.776C249.56 351.657 267.229 333.535 289.056 333.535C295.552 333.535 300.748 338.865 300.748 345.528C300.748 350.058 298.41 353.789 294.772 355.921C290.81 358.359 285.323 357.222 281.78 360.451C279.182 362.583 277.103 366.048 277.103 369.779C277.103 373.776 278.662 377.507 281.26 380.172C285.937 384.969 292.174 387.634 298.93 387.634C316.599 387.634 330.89 372.977 330.89 354.855C330.89 338.599 320.237 324.208 306.205 317.812C284.91 306.892 252.33 313.106 249.82 278.903C245.914 296.994 237.298 329.271 213.961 329.271C143.465 333.566 118.055 276.463 113.143 216.009C113.143 202.951 120.938 194.157 130.812 192.824C148.481 192.824 166.151 192.824 183.82 192.824C192.914 192.824 198.111 190.426 201.489 187.228C205.906 182.964 206.946 176.834 206.946 169.639C206.946 150.895 206.946 122.071 206.946 113.408C206.946 102.093 216.82 95.2856 229.552 95.2856C237.052 95.2856 244.092 95.5492 251.379 97.4176C267.229 101.415 270.607 117.938 270.607 117.938C270.607 117.938 300.489 123.268 315.559 125.933C329.851 128.598 365.189 130.996 371.945 167.507C387.795 254.385 378.181 338.599 377.402 338.599C366.229 420.68 299.709 416.683 299.709 416.683C275.529 416.683 249.56 400.181 249.56 373.776ZM327.512 232.266C318.937 231.466 311.662 234.931 309.063 241.593C305.381 251.035 343.362 258.886 343.362 245.857C342.323 238.395 336.087 233.332 327.512 232.266Z"
                fill="white"
            />
        </Svg>
    );
};
