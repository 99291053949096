import { ReactNode } from 'react';
import { Vote } from 'Shared/types/vote';

interface VoteRatingStarsProps {
    vote: Vote;
    value: number;
    size: 'sm' | 'md' | null;
    onChange?: (value) => void;
    disabled: boolean;
    allowHalf?: boolean;
    title?: ReactNode;
}

export const VoteRatingStars = ({}: VoteRatingStarsProps) => {
    return <div />;
};
