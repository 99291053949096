import { useEffect, useState } from 'react';
import { addMilliseconds, addWeeks, differenceInMilliseconds, isAfter, startOfDay } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { Id } from '@wedo/types';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { Meeting } from 'Shared/types/meeting';

export const createMeetingDataFromTemplate = (
    template: Partial<Meeting>
): Partial<Meeting> & { timeZone: string; current_meeting_id: Id } => {
    const startOfToday = startOfDay(new Date());
    let startAt = new Date(template.start_at);
    if (template.seriesMaster?.recurrence_pattern === 'once') {
        do {
            startAt = addWeeks(startAt, 1);
        } while (!isAfter(startOfDay(startAt), startOfToday));
    }

    return {
        current_meeting_id: template.id,
        type: template.type,
        title: template.title,
        tag_id: template.tag_id,
        start_at: startAt.toISOString(),
        end_at: addMilliseconds(
            startAt,
            differenceInMilliseconds(new Date(template.end_at), new Date(template.start_at))
        ).toISOString(),
        recurrence_pattern: template.recurrence_pattern,
        location: template.location,
        series_master_id: template.series_master_id,
        timeZone: template.start_at_time_zone,
        disabledDateBefore: template.end_at,
    };
};

export const useGenerateNextMeetingInSeries = (meetingId: Id = '', skip: boolean) => {
    const [meetingData, setMeetingData] = useState<Partial<Meeting>>(null);

    const updateMeetingData = (newData: Partial<Meeting>) =>
        setMeetingData((oldData) => ({ ...(oldData ?? {}), ...newData }));

    const { data: template } = useGetMeetingQuery(buildGetMeetingParameters(meetingId), {
        skip: isEmpty(meetingId) || skip,
    });

    useEffect(() => {
        if (template) {
            const data = createMeetingDataFromTemplate(template);
            data.start_at = null;
            data.end_at = null;
            updateMeetingData(data);
        }
    }, [meetingId, template]);
    return meetingData;
};
