import React, { FC, ReactElement } from 'react';
import { Trans } from '@lingui/macro';
import { DisplayPopover } from 'Shared/components/displayPopover/DisplayPopover';
import { StatusRow } from 'Shared/components/displayPopover/StatusRow';
import { GridLayout, ListLayout } from 'Shared/components/displayPopover/utils';
import { PageLayout } from 'Shared/types/layout';
import { EntityStatus } from 'Shared/types/workspace';

type WorkspacesDisplayPopoverProps = {
    status: EntityStatus;
    layout: PageLayout;
    onStatusChange: (status: EntityStatus) => void;
    onLayoutChange: (layout: PageLayout) => void;
    onReset: () => void;
};

const statusToText: Record<EntityStatus, ReactElement> = {
    open: <Trans id="Open workspaces">Open</Trans>,
    deleted: <Trans id="Deleted workspaces">Deleted</Trans>,
    archived: <Trans id="Archived workspaces">Archived</Trans>,
};

export const WorkspacesDisplayPopover: FC<WorkspacesDisplayPopoverProps> = ({
    status,
    layout,
    onStatusChange,
    onLayoutChange,
    onReset,
}) => {
    return (
        <>
            <StatusRow status={status} onStatusChange={onStatusChange} statusToText={statusToText} />
            <DisplayPopover onReset={onReset}>
                <div className="p-4">
                    <h3 className="text-sm text-gray-600 mb-2">
                        <Trans>Layout</Trans>
                    </h3>

                    <DisplayPopover.LayoutGrid
                        layout={layout}
                        layouts={[ListLayout, GridLayout]}
                        onLayoutChange={onLayoutChange}
                    />
                </div>
            </DisplayPopover>
        </>
    );
};
