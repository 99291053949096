import React, { FC, useMemo } from 'react';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { EmptyState, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useIsOverflowing } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { User } from 'Shared/types/user';
import { Member } from 'Shared/types/userGroup';
import { Workspace } from 'Shared/types/workspace';
import { isLastModerator } from 'Shared/utils/userGroup';
import { ManageMembersTableRow } from './ManageMembersTableRow';

type ManageMembersTableProps = {
    search: string;
    entity: Workspace | Template | Team;
    className?: string;
    isReadOnly?: boolean;
    onModeratorToggle: (userId: Id, value: boolean) => void;
    onUserDelete: (user: User) => Promise<boolean>;
    canRemoveMyself?: boolean;
    tableBodyClassName?: string;
};

export const ManageMembersTable: FC<ManageMembersTableProps> = ({
    entity,
    search,
    className,
    tableBodyClassName = 'max-h-[30rem] overflow-auto',
    isReadOnly = false,
    canRemoveMyself = true,
    onModeratorToggle,
    onUserDelete,
}) => {
    const sessionUser = useSessionUser();

    const { entityType } = useManageMembers(entity);

    const [tableBodyRef, isTableBodyOverflowing] = useIsOverflowing();

    const formattedSearch = search?.toLowerCase().trim();

    const filteredMembers = useMemo<Array<Member>>(() => {
        if (isEmpty(search.trim())) {
            return entity?.userGroup?.members ?? [];
        }

        return (
            entity?.userGroup?.members.filter(
                (member) =>
                    member?.user?.full_name?.toLowerCase()?.includes(formattedSearch) ||
                    member?.user?.initials?.toLowerCase()?.includes(formattedSearch)
            ) ?? []
        );
    }, [search, entity?.userGroup?.members]);

    const canBeDeleted = (member: Member): boolean => {
        return (
            entity?.userGroup?.members?.length >= 2 &&
            !isLastModerator(member, entity.userGroup.members) &&
            (member.user_id !== sessionUser.id || canRemoveMyself)
        );
    };

    const handleToggleModerator = async (userId: Id, value: boolean) => onModeratorToggle(userId, value);

    if (!isEmpty(search) && isEmpty(filteredMembers)) {
        return (
            <EmptyState icon={faUsers} size="md">
                <EmptyState.Text>
                    <Trans>No members found</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <div className="scrollbar-light">
            <Table hoverable size="condensed" className={clsx(className, '')}>
                <Table.Head trClassName="w-full flex justify-between">
                    <Table.HeadCell className="block w-full">
                        <Trans>Name</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className=" w-[12rem] text-center whitespace-nowrap">{t`Access rights`}</Table.HeadCell>
                    {!isReadOnly && <Table.HeadCell className={clsx(isTableBodyOverflowing ? 'w-20' : 'w-16')} />}
                </Table.Head>

                <Table.Body className={clsx('scrollbar-light block !w-full', tableBodyClassName)} ref={tableBodyRef}>
                    {filteredMembers?.map((member: Member) => {
                        return (
                            <ManageMembersTableRow
                                key={member.user_id}
                                isReadOnly={isReadOnly}
                                members={entity?.userGroup?.members}
                                teamMembers={entity?.team?.userGroup?.members}
                                member={member}
                                entityType={entityType}
                                canBeDeleted={canBeDeleted}
                                onDeleteUser={onUserDelete}
                                onToggleUser={handleToggleModerator}
                            />
                        );
                    })}
                </Table.Body>
            </Table>
        </div>
    );
};
