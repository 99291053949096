import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { getIconFromName } from '@wedo/design-system';
import { FaIconName } from '@wedo/types';

interface IconTagProps {
    icon: FaIconName;
    className?: string;
}

export const IconTag: React.FC<IconTagProps> = ({ icon, className }) => {
    const iconName = getIconFromName(icon) as IconDefinition;
    return iconName ? <FontAwesomeIcon className={className} icon={iconName} /> : null;
};
