import React from 'react';
import { faEmptySet, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';

export const checklistNotificationHandler: NotificationHandlerFn = (notification) => {
    const createdBy = notification.created_by.full_name;
    const checklist = notification.checklist;
    const checklistTemplate = notification.checklistTemplate;

    switch (notification.event_key) {
        case 'created_checklist':
            return {
                icon: faPlay,
                text: (
                    <Trans>
                        <strong>{createdBy}</strong> started the checklist{' '}
                        <strong>
                            {checklist.name} ({checklistTemplate.name})
                        </strong>
                    </Trans>
                ),
            };
        default:
            return { icon: faEmptySet, text: <></> };
    }
};
