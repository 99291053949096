import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import { useNavigate } from '@wedo/utils/hooks';
import { CreatePendingCustomerCard } from 'Pages/DevTest/CreatePendingCustomerCard';
import { CreateTrialCard } from 'Pages/DevTest/CreateTrialCard';
import { PageHeader } from 'Shared/components/PageHeader';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';

export const DevTools = () => {
    const navigate = useNavigate();
    const { network } = useCurrentNetwork();

    useEffect(() => {
        if (network?.organisation?.type !== 'demo') {
            navigate('/tasks');
        }
    }, [network?.organisation?.type]);

    return (
        <div className="flex flex-col overflow-hidden">
            <PageHeader title={t`Dev tools`} />
            <div className="flex flex-col overflow-y-auto">
                <div className="p-6">
                    <CreateTrialCard />
                </div>

                <div className="p-6">
                    <CreatePendingCustomerCard />
                </div>
            </div>
        </div>
    );
};
