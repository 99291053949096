import { createStore } from 'zustand';

export type VersionStore = {
    hash: string;
    clientVersion: string;
    newVersionAvailable: boolean;
};

export const getAssetsHash = (document: Document) => {
    return Array.from(document.querySelectorAll('script,link'))
        .map(({ src, href }) => new URL(src || href || 'https://wedo.com'))
        .filter(({ host }) => host === location.host)
        .sort()
        .join(';');
};

export const versionStore = createStore<VersionStore>(() => ({
    hash: getAssetsHash(document),
    clientVersion: __VERSION__,
    newVersionAvailable: false,
}));

export const clientVersion = () => {
    return versionStore.getState().clientVersion;
};
