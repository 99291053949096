import { FC, ReactNode, useMemo } from 'react';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { endOfMonth, setMonth, startOfMonth } from 'date-fns';
import { Button } from '~/components/Button/Button';
import { CaretNav, DatePickerBox } from '~/components/DatePicker/components/index';
import { Tooltip } from '~/components/Tooltip/Tooltip';
import { dateInTimezone, Day, useCalendar } from '~/hooks/useCalendar';
import { useDateTime } from '@wedo/utils/hooks';

type MonthCalendarProps = {
    calendar: ReturnType<typeof useCalendar>;
    dateSelected: ReturnType<typeof useCalendar>;
    onDayClick: (d: Day) => void;
    onMonthClick: (month: number, year: number) => void;
    onYearClick: (year: number) => void;
    className: string;
    disabledText?: ReactNode;
};

const createDateTz = (date: Date, calendar: ReturnType<typeof useCalendar>) =>
    dateInTimezone({
        date,
        timezone: calendar.timezone,
        weekStartsOn: calendar.weekStartsOn,
        firstWeekContainsDate: calendar.firstWeekContainsDate,
    })();

const dayToString = (day: Day) =>
    `${String(day.year).padStart(4, '0')}-${String(day.month).padStart(2, '0')}-${String(day.dayOfMonth).padStart(
        2,
        '0'
    )}`;

export const MonthCalendar: FC<MonthCalendarProps> = ({
    calendar,
    dateSelected,
    onDayClick,
    onMonthClick,
    onYearClick,
    className,
    disabledText,
}) => {
    const { abbreviatedMonths, abbreviatedDays } = useDateTime();

    const dateTz = calendar.dateTz();
    const dateSelectedTz = dateSelected.dateTz();
    const dayList = calendar.dayOfMonthList({ padded: true });

    const min = createDateTz(new Date(dateSelected.minDate), dateSelected);
    const minStr = dayToString(min);

    const max = createDateTz(new Date(dateSelected.maxDate), dateSelected);
    const maxStr = dayToString(max);

    const today = createDateTz(new Date(), dateSelected);

    const { previousMonth, nextMonth } = useMemo(
        () => ({
            previousMonth: endOfMonth(setMonth(calendar.date, dateTz.month - 1)).getTime(),
            nextMonth: startOfMonth(setMonth(calendar.date, dateTz.month + 1)).getTime(),
        }),
        [calendar.date.getTime()]
    );

    const canPrevious = previousMonth >= min.timestamp;
    const canNext = nextMonth <= max.timestamp;

    const isToday = (day: Day): boolean => {
        return day.year === today.year && day.month === today.month && day.dayOfMonth === today.dayOfMonth;
    };

    const isSelected = (day: Day): boolean => {
        return (
            day.year === dateSelectedTz.year &&
            day.month === dateSelectedTz.month &&
            day.dayOfMonth === dateSelectedTz.dayOfMonth
        );
    };

    const isDisabled = (day: Day): boolean => {
        const dayStr = dayToString(day);

        return dayStr < minStr || dayStr > maxStr;
    };

    return (
        <div className={clsx('flex flex-col gap-2', className)}>
            <div className="flex justify-between">
                <div className="flex items-center gap-1">
                    <CaretNav
                        icon={faAngleDoubleLeft}
                        text={t`Previous year`}
                        disabled={!canPrevious}
                        onClick={() => calendar.setYear(dateTz.year - 1)}
                    />
                    <CaretNav
                        icon={faAngleLeft}
                        text={t`Previous month`}
                        disabled={!canPrevious}
                        onClick={() => calendar.setMonth(dateTz.month - 1)}
                    />
                </div>

                <div className="flex items-center gap-2">
                    <Button variant="link" onClick={() => onMonthClick(dateTz.month, dateTz.year)}>
                        {abbreviatedMonths[dateTz.month]}
                    </Button>
                    <Button variant="link" onClick={() => onYearClick(dateTz.year)}>
                        {dateTz.year}
                    </Button>
                </div>

                <div className="flex items-center gap-1">
                    <CaretNav
                        icon={faAngleRight}
                        text={t`Next month`}
                        disabled={!canNext}
                        onClick={() => calendar.setMonth(dateTz.month + 1)}
                    />
                    <CaretNav
                        icon={faAngleDoubleRight}
                        text={t`Next year`}
                        disabled={!canNext}
                        onClick={() => calendar.setYear(dateTz.year + 1)}
                    />
                </div>
            </div>
            <div className="grid grid-cols-7 text-xs leading-6 text-gray-500">
                {abbreviatedDays.map((day) => (
                    <div key={day} className="text-center">
                        {day}
                    </div>
                ))}
            </div>
            <div className={'grid grid-cols-7 gap-y-2 overflow-hidden text-sm'}>
                {dayList.map((day: Day) => (
                    <Tooltip
                        key={`${day.dayOfMonth}-${day.month}`}
                        content={disabledText != null && isDisabled(day) ? disabledText : undefined}
                        delay={500}
                    >
                        <DatePickerBox
                            onClick={() => onDayClick(day)}
                            isDisabled={isDisabled(day)}
                            isSelected={isSelected(day)}
                            isActive={isToday(day)}
                            isSecondary={day.month !== dateTz.month}
                            text={day.dayOfMonth.toString()}
                            datetime={`${String(day.year).padStart(4, '0')}-${String(day.month + 1).padStart(
                                2,
                                '0'
                            )}-${String(day.dayOfMonth).padStart(2, '0')}`}
                        />
                    </Tooltip>
                ))}
            </div>
        </div>
    );
};
