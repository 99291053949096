import { customFetch, withAuth, withJsonBody, withMethod, withUrl } from '@wedo/utils';
import { Badge } from 'Shared/types/badge';

export const registerBadgeActivity = async (activity: string) => {
    return customFetch(
        withAuth,
        withMethod('POST'),
        withJsonBody({ 0: activity }),
        withUrl('/rpc/badge.registerActivity?batch=1')
    );
};

export const getScoreFromBadges = (badges: Badge[]) => {
    return (badges || []).reduce((acc, badge) => acc + (badge.userBadges?.length || 0) * badge.value, 0);
};
