import { LocaleDefinition } from '@wedo/types';

export const itIT: LocaleDefinition = {
    DATETIME_FORMATS: {
        AMPMS: ['AM', 'PM'],
        DAY: ['domenica', 'luned\u00ec', 'marted\u00ec', 'mercoled\u00ec', 'gioved\u00ec', 'venerd\u00ec', 'sabato'],
        FIRSTDAYOFWEEK: 0,
        MONTH: [
            'gennaio',
            'febbraio',
            'marzo',
            'aprile',
            'maggio',
            'giugno',
            'luglio',
            'agosto',
            'settembre',
            'ottobre',
            'novembre',
            'dicembre',
        ],
        SHORTDAY: ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'],
        SHORTMONTH: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
        WEEKENDRANGE: [5, 6],
        fullDate: 'EEEE d MMMM y',
        longDate: 'd MMMM y',
        medium: 'dd MMM y HH:mm:ss',
        mediumDate: 'dd MMM y',
        mediumTime: 'HH:mm:ss',
        short: 'dd/MM/yy HH:mm',
        shortDate: 'dd/MM/yy',
        shortTime: 'HH:mm',
    },
    NUMBER_FORMATS: {
        DECIMAL_SEP: ',',
        GROUP_SEP: '.',
    },
};
