import { t } from '@lingui/macro';
import { Card, Spinner, Switch, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useGetAdminNetworkQuery, useUpdateNetworkMutation } from 'Shared/services/network';

export const EnforceDkimCard = () => {
    const { data: network } = useGetAdminNetworkQuery();
    const { show: showNotification } = useNotification();
    const [updateNetwork, { isLoading: isLoading }] = useUpdateNetworkMutation();

    const handleToggle = async (value: boolean) => {
        const response = await updateNetwork({ config: { enforce_dkim: value } });
        if ('error' in response) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    return (
        <Card className={'mt-6'}>
            <Card.Header
                title={t`Enable DKIM verification on inbound messages`}
                description={t`Allow only valid DKIM-signed inbound email messages for email conversion features like email to task.`}
                actions={
                    <Switch.Group>
                        <div className="flex items-center">
                            {isLoading && (
                                <Spinner className="mr-2 h-5 w-5 animate-spin fill-blue-800 text-blue-800 text-opacity-20" />
                            )}
                            <Switch
                                checked={network?.config?.enforce_dkim || false}
                                onChange={(value) => handleToggle(value)}
                            />
                        </div>
                    </Switch.Group>
                }
            />
        </Card>
    );
};
