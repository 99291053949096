import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Input, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/TaskDetailCustomFields';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldShortTextInput = ({
    taskId,
    value,
    customField,
    onUpdate,
    id,
}: {
    taskId: Id;
    customField: CustomField;
    value: CustomFieldValue;
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    id?: string;
}) => {
    const { isTaskReadonly } = useTask(taskId);

    return (
        <Input
            className="grow"
            id={id}
            value={value?.short_text_value}
            placeholder={t`Add ${customField.label}`}
            aria-label={customField.label}
            onChange={(e) => onUpdate(customField.id, value?.id, { short_text_value: e.target.value })}
            debounce
            disabled={isTaskReadonly}
        />
    );
};

export const TaskDetailCustomFieldShortText = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    const singleValue = useMemo(() => values?.[0], [values]);
    const hasValue = !isEmpty(singleValue?.short_text_value);

    return (
        <TaskDetailCustomFieldWrapper
            taskId={taskId}
            customField={customField}
            hasValue={hasValue}
            onDelete={
                !isEmpty(singleValue?.short_text_value) ? () => onDelete(customField.id, [singleValue.id]) : undefined
            }
        >
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldShortTextInput
                    taskId={taskId}
                    value={singleValue}
                    customField={customField}
                    onUpdate={onUpdate}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
