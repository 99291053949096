import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Children, cloneElement, ReactNode } from 'react';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import clsx from 'clsx';

const classes = {
    list: 'divide-y divide-gray-200 rounded-md border border-gray-200 md:flex md:divide-y-0',
    item: {
        base: 'relative md:flex md:flex-1',
        button: {
            base: 'overflow-hidden flex-1 group flex pr-6 py-2 text-sm font-medium items-center',
            current: 'overflow-hidden flex-1 flex items-center pr-6 py-2 text-sm font-medium',
        },
        container: 'flex items-center pl-4 py-2 w-full text-sm font-medium overflow-hidden',
        step: {
            wrapper: {
                base: 'flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full',
                todo: 'border-2 border-gray-200',
                current: 'border-2 bg-blue-600 border-blue-600',
                done: 'bg-green-500',
            },
            title: {
                base: 'ml-4 text-sm font-medium truncate grow text-start',
                todo: 'text-gray-500',
                current: 'text-blue-600',
                done: 'text-green-800',
            },
            icon: 'h-4 w-4 text-white',
            index: {
                todo: 'text-gray-500',
                current: 'text-white',
            },
        },
    },
};

const classesHover = {
    item: {
        button: {
            base: 'hover:cursor-pointer',
            current: 'hover:cursor-pointer',
        },
        step: {
            wrapper: {
                todo: 'group-hover:border-gray-400',
                done: 'group-hover:bg-green-800',
            },
            title: {
                todo: 'group-hover:text-gray-900',
            },
            index: {
                todo: 'group-hover:text-gray-900',
            },
        },
    },
};

const StepCircle = ({ status, index, isClickable }: { status: string; index: number; isClickable: boolean }) => {
    if (status === 'current') {
        return (
            <span className={clsx(classes.item.step.wrapper.base, classes.item.step.wrapper.current)}>
                <span className={classes.item.step.index.current}>{index}</span>
            </span>
        );
    }
    return (
        <span
            className={clsx(
                classes.item.step.wrapper.base,
                classes.item.step.wrapper[status],
                isClickable && classesHover.item.step.wrapper[status]
            )}
        >
            {status === 'todo' ? (
                <span
                    className={clsx(
                        classes.item.step.index[status],
                        isClickable && classesHover.item.step.index[status]
                    )}
                >
                    {index}
                </span>
            ) : (
                <FontAwesomeIcon icon={faCheck} className={classes.item.step.icon} aria-hidden="true" />
            )}
        </span>
    );
};

export interface StepProps {
    children: ReactNode;
    last?: boolean;
    index?: number;
    currentIndex?: number;
    onClick?: () => void;
    className?: string;
}

const Step = ({ children, currentIndex, index, onClick = null, last, className }: StepProps): JSX.Element => {
    const isClickable = onClick !== null;

    const status = index === currentIndex ? 'current' : index < currentIndex ? 'done' : 'todo';

    const HTMLElement = onClick ? 'button' : 'div';

    return (
        <li key={`step-${index}`} className={clsx(classes.item.base, className)}>
            <HTMLElement
                onClick={onClick}
                className={clsx(
                    !isClickable && 'cursor-default',
                    status !== 'current' && classes.item.button.base,
                    status !== 'current' && isClickable && classesHover.item.button.base,
                    status === 'current' && classes.item.button.current,
                    status === 'current' && isClickable && classesHover.item.button.current
                )}
            >
                <span className={classes.item.container}>
                    <StepCircle status={status} index={index} isClickable={isClickable} />
                    <span
                        className={clsx(
                            classes.item.step.title.base,
                            classes.item.step.title[status],
                            isClickable && classesHover.item.step.title[status]
                        )}
                    >
                        {children}
                    </span>
                </span>
            </HTMLElement>

            {!last ? (
                <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                        <svg
                            className="h-full w-full text-gray-200"
                            viewBox="0 0 22 80"
                            fill="white"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </>
            ) : null}
        </li>
    );
};

export interface StepsProps {
    children?: ReactNode;
    className?: string;
    wrapperClassName?: string;
    currentIndex?: number;
}

export const StepsComponent = ({ children, currentIndex, className, wrapperClassName }: StepsProps): JSX.Element => {
    const filteredChildren = Children.toArray(children).filter(Boolean);

    return (
        <nav aria-label="Progress" className={wrapperClassName}>
            <ol className={clsx(classes.list, className)}>
                {filteredChildren.map((child, index) =>
                    cloneElement(child, {
                        currentIndex,
                        index: index + 1,
                        ...(index === filteredChildren.length - 1 ? { last: true } : {}),
                        ...child.props,
                    })
                )}
            </ol>
        </nav>
    );
};

Step.displayName = 'Steps.Step';

export const Steps = Object.assign(StepsComponent, { Step });
