import useResizeObserver from '@react-hook/resize-observer';
import { RefObject, useCallback, useLayoutEffect, useState } from 'react';
import { useEvent } from './useEvent';

export type Size = {
    width: number;
    height: number;
};

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(target: RefObject<T> | null): Size => {
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    });

    const updateSize = useCallback(() => {
        if (target?.current != null) {
            const boundingClientRect = target.current.getBoundingClientRect();
            const { paddingTop, paddingRight, paddingBottom, paddingLeft } = window.getComputedStyle(target.current);
            // We want the content rectangle size, not the bounding rectangle size
            if (boundingClientRect != null) {
                setSize({
                    width: boundingClientRect.width - parseFloat(paddingLeft) - parseFloat(paddingRight),
                    height: boundingClientRect.height - parseFloat(paddingTop) - parseFloat(paddingBottom),
                });
            }
        }
    }, [target]);

    useLayoutEffect(updateSize, [updateSize]);

    useResizeObserver(target, ({ contentRect: { width, height } }) => setSize({ width, height }));

    useEvent('resize', updateSize);

    return size;
};
