import { formatDistance } from 'date-fns';
import { FormatDate } from '~/components/FormatDate/FormatDate';
import { useDateFunction } from '~/components/FormatDate/useDateFunction';
import { Tooltip } from '~/components/Tooltip/Tooltip';

export type DateLike = Date | number | string;

export const useFormatDateDistance = (
    date: DateLike,
    options?: {
        includeSeconds?: boolean;
        addSuffix?: boolean;
        locale?: Locale;
    }
): string =>
    useDateFunction(date, (date: Date, baseDate: Date, locale?: Locale) =>
        formatDistance(date, baseDate, { ...options, locale })
    );

export type FormatDateDistanceProps = {
    date: DateLike;
    showSeconds?: boolean;
    showSuffix?: boolean;
    hideTooltip?: boolean;
    locale?: Locale;
    className?: string;
};

export const FormatDateDistance = ({
    date,
    locale,
    showSeconds = false,
    showSuffix = true,
    hideTooltip = false,
    className,
}: FormatDateDistanceProps): JSX.Element => {
    const text = useFormatDateDistance(date, { locale, addSuffix: showSuffix, includeSeconds: showSeconds });
    return (
        <Tooltip content={!hideTooltip && <FormatDate date={new Date(date)} format="Pp" locale={locale} />}>
            <time className={className} dateTime={new Date(date).toISOString()}>
                {text}
            </time>
        </Tooltip>
    );
};
