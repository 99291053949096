import { FC } from 'react';
import clsx from 'clsx';
import { Button, FormatDate, Table } from '@wedo/design-system';
import { getFormattedBytes } from '@wedo/utils';
import { useUser } from 'App/store/usersStore';
import { AttachmentIcon } from 'Shared/components/file/AttachmentIcon';
import { AttachmentLabels } from 'Shared/components/file/AttachmentLabels';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useDownloadAttachment } from 'Shared/hooks/useDownloadAttachment';
import { Attachment } from 'Shared/types/attachment';

export type FileTableRowProps = {
    file: Attachment;
    isSelected: boolean;
    onFileSelect: (file: Attachment) => void;
    onFileNameClick?: (file: Attachment) => void;
};

export const FileTableRow: FC<FileTableRowProps> = ({ file, isSelected, onFileSelect, onFileNameClick }) => {
    const { isAttachmentInfected } = useDownloadAttachment(file);

    const updatedBy = useUser(file.updated_by);

    return (
        <Table.Row onClick={() => onFileSelect(file)} className={clsx(isSelected && 'bg-blue-100')}>
            <Table.Cell>
                <AttachmentIcon attachment={file} className="mx-auto" />
            </Table.Cell>

            <Table.Cell className={clsx('max-w-[400px] truncate', file?.labels?.length > 0 && '!py-0')}>
                <Button
                    variant="link"
                    onClick={() => onFileNameClick(file)}
                    className={clsx(
                        'truncate hover:text-blue-600 whitespace-pre !gap-0',
                        isAttachmentInfected && 'text-red-500'
                    )}
                >
                    {file?.filename}
                </Button>
                {file?.labels?.length > 0 && <AttachmentLabels attachment={file} />}
            </Table.Cell>

            <Table.Cell className="hidden md:table-cell">{getFormattedBytes(Number(file?.file_size))}</Table.Cell>

            <Table.Cell>
                <FormatDate format="d MMM yyyy, HH:mm" date={new Date(file?.updated_at)} />
            </Table.Cell>

            <Table.Cell>
                <div className="flex justify-start">
                    <UserAvatar user={updatedBy} size="xs" />
                </div>
            </Table.Cell>
        </Table.Row>
    );
};
