import { FaIconName, Id } from '@wedo/types';
import { Attachment } from 'Shared/types/attachment';
import { Checklist } from 'Shared/types/checklist';
import { ColorTuple } from 'Shared/types/colorTuple';
import { Workspace } from 'Shared/types/workspace';

export type CustomFieldValue = {
    id: Id;
    task_id: Id;
    custom_field_id?: Id;
    custom_field_option_id?: Id;
    short_text_value?: string;
    numeric_value?: number;
    date_value?: string;
    attachment_value?: { attachment_id: string; attachment_version_id: string; filename: string };
    // Related
    customField?: CustomField;
    attachments?: Partial<Attachment>[];
    option?: CustomFieldOption;
    customFieldOption?: CustomFieldOption;
    custom_field_group_id?: string;
    custom_group_value_id?: string;
    options?: {
        id: string;
        label: string;
    }[];
};

export type CustomFieldOption = {
    id: Id;
    custom_field_id: string;

    label: string;
    order: number;
    color: ColorTuple | null;

    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;
};

export enum CustomFieldType {
    ShortText = 'short_text',
    Number = 'numeric',
    Date = 'date',
    MultipleChoice = 'set',
    Dropdown = 'enum',
    Attachment = 'attachment',
}

export type CustomField = {
    id: string;
    network_id: string;
    type: CustomFieldType;
    object_type: 'task';
    label: string;
    icon?: FaIconName;
    order: number;
    is_global: boolean;
    custom_field_group_id: null | string;
    archived: boolean;
    archived_at: string;
    archived_by: string;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    options?: Array<CustomFieldOption>;
    tags?: Array<Workspace>;
    checklists?: Array<Checklist>;

    custom_group_value_id?: string;
    custom_field_option_id?: string;
    date_value?: string;
    numeric_value?: number;
    short_text_value?: string;
    attachment_value?: { attachment_id: string; attachment_version_id: string; filename: string };
    // Computed
    values?: CustomFieldValue[];
    short_name: string;
};

export type ShortTextCustomField = {
    options: [];
} & CustomField;

export type NumberCustomField = {
    options: [];
} & CustomField;

export type DateCustomField = {
    options: [];
    tags: Array<Workspace>;
} & CustomField;

export type MultipleChoiceCustomField = {
    options: [];
    tags: Array<Workspace>;
} & CustomField;

export type DropdownCustomField = {
    options: Array<CustomFieldOption>;
    tags: Array<Workspace>;
} & CustomField;

export type AttachmentCustomField = {
    options: Array<CustomFieldOption>;
    tags: Array<Workspace>;
} & CustomField;

export type CustomFieldGroup = {
    id: string;
    network_id: string;
    label: string;
    value_label: string;
    icon: FaIconName;
    short_name: string;
    object_type: 'task';
    multiple_values: boolean;
    order: number;
    archived: boolean;
    archived_at: null | string;
    archived_by: null | string;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    is_global: boolean;
    customFields: Array<CustomField>;
    tags: Array<Workspace>;
    checklists: Array<Checklist>;
};

export type CustomFieldGroupValue = {
    id: string;
    custom_field_group_id: string;
    task_id: string;
    values: CustomFieldValue[];

    order: number;

    created_at: Date;
    created_by: number;

    updated_at: Date;
    updated_by: number;
};
