import { FC } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { Trans } from '@lingui/macro';
import { Label, Tabs } from '@wedo/design-system';
import { SectionsPanel } from 'Shared/components/sections/SectionsPanel/SectionsPanel';
import { Section } from 'Shared/types/section';

type SectionsPanelProps = {
    sections: Array<Section>;
    onDragEnd: (e: DragEndEvent) => void;
    onAddSection: (name: string) => Promise<boolean>;
    onDeleteSection: (section: Section) => Promise<boolean>;
    onUpdateSection: (section: Partial<Section>) => Promise<boolean>;
    canChangeColor?: boolean;
};

export const SectionsTabsPanel: FC<SectionsPanelProps> = ({
    sections,
    onDragEnd,
    onAddSection,
    onDeleteSection,
    onUpdateSection,
    canChangeColor = false,
}) => {
    return (
        <Tabs.Panel>
            <Label className="text-lg">
                <Trans>Sections</Trans>
            </Label>

            <SectionsPanel
                canChangeColor={canChangeColor}
                sections={sections}
                onDragEnd={onDragEnd}
                onAddSection={onAddSection}
                onDelete={onDeleteSection}
                onUpdate={onUpdateSection}
            />
        </Tabs.Panel>
    );
};
