import { useCallback, useMemo } from 'react';
import { useGetOrganizationQuery, useLazyGetSubscriptionQuery } from 'Shared/services/organization';
import { getPlanType } from 'Shared/utils/chargebee';

export const useCurrentOrganisation = () => {
    const [trigger, { data, isLoading }] = useLazyGetSubscriptionQuery();
    const { data: organization } = useGetOrganizationQuery();

    const isEnterprise = async () => {
        let result = data;
        if (!result) {
            result = (await trigger()).data;
        }
        const planType = getPlanType(result);
        return planType === 'enterprise';
    };

    const domains = useMemo(() => {
        return (
            organization?.networks
                ?.flatMap(({ domains }) => domains.map(({ name }) => name.trim()))
                .filter((domain) => domain !== '') ?? []
        );
    }, [organization]);

    /** Verify if the email's domain is different from the organisation's domains
     * @return
     * true when the email's domain is different, false when the email's domain is among the organisation's domains
     */
    const isEmailOutsideOrganization = useCallback(
        (email: String) => {
            const trimmedEmail = email.trim();
            return domains.length === 0 || domains.every((domain) => !trimmedEmail.endsWith(`@${domain}`));
        },
        [domains]
    );

    return {
        isEnterprise,
        isLoading,
        organization,
        domains,
        isEmailOutsideOrganization,
    };
};
