import { BaseSyntheticEvent } from 'react';

export const stopPropagation =
    <T, E extends BaseSyntheticEvent<T>>(handler?: (event?: E) => void) =>
    (event: E) => {
        event.stopPropagation();
        handler?.(event);
    };

export const preventDefault =
    <T, E extends BaseSyntheticEvent<T>>(handler?: (event?: E) => void) =>
    (event: E) => {
        event.preventDefault();
        handler?.(event);
    };
