import { type RefObject, useCallback, useEffect, useRef } from 'react';
import { addDays, format, isSameDay } from 'date-fns';
import { useGanttContextStore } from './GanttContext';
import { useDateFnsLocale } from './useDateFnsLocale';
import { durationInDays, GlobalDateWindow, daysSinceEpoch } from './utils';

export type TimelineMouseMarkerProps = {
    timelineRef: RefObject<HTMLElement>;
};

export const TimelineMouseMarker = ({ timelineRef }: TimelineMouseMarkerProps) => {
    const store = useGanttContextStore()!;

    const timelineElementRef = useRef<HTMLElement>(null);
    const markerRef = useRef<HTMLDivElement>(null);
    const startLabelRef = useRef<HTMLDivElement>(null);
    const endLabelRef = useRef<HTMLDivElement>(null);
    const verticalLineRef = useRef<HTMLDivElement>(null);

    const locale = useDateFnsLocale();

    const handleMouseMove = useCallback(({ clientX }: MouseEvent) => {
        const state = store.getState();
        const minDateString = state.data.dateWindows.get(GlobalDateWindow)!.minDate;
        if (minDateString != null) {
            const columnWidth = state.view.zoom.columnWidth;
            const minDate = new Date(minDateString);
            const relativeX = clientX - timelineElementRef.current.getBoundingClientRect().x;
            if (!markerRef.current!.classList.contains('active')) {
                const day = Math.floor(relativeX / columnWidth);
                const dayX = day * columnWidth;
                markerRef.current!.style.transform = `translateX(${dayX}px)`;
                startLabelRef.current!.textContent = format(
                    addDays(minDate, day),
                    state.view.zoom.view === 'year' ? 'EEEEEE d MMM' : 'EEEEEE d',
                    { locale }
                );
            }
            verticalLineRef.current!.style.transform = `translateX(${relativeX}px)`;
        }
    }, []);

    useEffect(() => {
        timelineElementRef.current = timelineRef.current!;
        timelineElementRef.current.addEventListener('mousemove', handleMouseMove);
        const unsubscribe = store.subscribe(
            ({ view }) => view.hoveredTask,
            (hoveredTask) => {
                if (hoveredTask != null) {
                    const day = daysSinceEpoch(hoveredTask.plannedDate ?? hoveredTask.dueDate);
                    if (day != null) {
                        const duration = durationInDays(hoveredTask.plannedDate, hoveredTask.dueDate);
                        markerRef.current!.style.transform = `translateX(calc((${day} - var(--start-day)) * var(--column-width)))`;
                        markerRef.current!.firstElementChild!.style.justifyContent = 'space-between';
                        markerRef.current!.firstElementChild!.style.gap = '0.25rem';
                        markerRef.current!.style.minWidth = `calc(${duration} * var(--column-width))`;
                        markerRef.current!.classList.add('active');
                        if (
                            hoveredTask.plannedDate != null &&
                            hoveredTask.dueDate != null &&
                            !isSameDay(new Date(hoveredTask.plannedDate), new Date(hoveredTask.dueDate))
                        ) {
                            startLabelRef.current!.textContent = format(new Date(hoveredTask.plannedDate), 'd MMM');
                            endLabelRef.current!.textContent = format(new Date(hoveredTask.dueDate), 'd MMM');
                        } else {
                            startLabelRef.current!.textContent = format(
                                new Date(hoveredTask.plannedDate ?? hoveredTask.dueDate),
                                'd MMM'
                            );
                            endLabelRef.current!.textContent = '';
                        }
                    }
                } else {
                    markerRef.current!.style.minWidth = 'var(--column-width)';
                    markerRef.current!.firstElementChild!.style.justifyContent = 'center';
                    markerRef.current!.firstElementChild!.style.gap = '0';
                    markerRef.current!.classList.remove('active');
                    endLabelRef.current!.textContent = '';
                }
            }
        );
        return () => {
            timelineElementRef.current.removeEventListener('mousemove', handleMouseMove);
            unsubscribe();
        };
    }, []);

    return (
        <>
            <div
                ref={markerRef}
                className="absolute z-40 pointer-events-none bottom-0 top-[calc(var(--row-height)*2+1px)] hidden group-hover/timeline:flex min-w-[var(--column-width)] [&.active]:transition-[transform,min-width] group/marker"
            >
                <div className="sticky -translate-y-full border-x py-1 px-2 min-w-[calc(100%+1px)] bg-gray-100 text-gray-700 group-[.active]/marker:bg-blue-500 group-[.active]/marker:text-white whitespace-nowrap top-[calc(var(--row-height)*2+1px)] h-[calc(var(--row-height)-1px)] border-gray-200 flex items-center gap-1">
                    <span ref={startLabelRef} />
                    <span ref={endLabelRef} />
                </div>
            </div>
            <div
                ref={verticalLineRef}
                className="absolute pointer-events-none border-l border-gray-300 top-0 bottom-0 z-30"
            />
        </>
    );
};
