import React from 'react';
import { faAlignLeft, faPen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Card, EmptyState, useModal } from '@wedo/design-system';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { Template } from 'Shared/types/template';

export const TemplateDescriptionCard = ({ template }: { template: Template }) => {
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(template);

    return (
        <Card>
            <Card.Header
                title={<Trans>Description</Trans>}
                actions={
                    isCurrentUserModerator && (
                        <Button
                            icon={faPen}
                            size="sm"
                            onClick={() => open(TemplateSettingsModal, { templateId: template?.id })}
                        >
                            <Trans>Edit</Trans>
                        </Button>
                    )
                }
            />
            <Card.Body>
                {isEmpty(template?.description) ? (
                    <div className="flex justify-center">
                        <EmptyState icon={faAlignLeft}>
                            <EmptyState.Text>
                                <Trans>No description for {template?.name} template</Trans>
                            </EmptyState.Text>
                            <Button onClick={() => open(TemplateSettingsModal, { templateId: template?.id })}>
                                <Trans>Add description</Trans>
                            </Button>
                        </EmptyState>
                    </div>
                ) : (
                    <DescriptionEditor isReadOnly html={template?.description} />
                )}
            </Card.Body>
        </Card>
    );
};
