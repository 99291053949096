import { Trans } from '@lingui/macro';

export { useNotification } from './useNotification';
export { NotificationContextProvider } from './NotificationContextProvider';
export { NotificationsContainer } from './NotificationsContainer';

export const UnexpectedErrorNotification = {
    type: 'danger',
    title: <Trans>Unexpected error</Trans>,
    message: <Trans>Your changes might not have been saved. Please try again or contact support.</Trans>,
};
export const NoInternetErrorNotification = {
    type: 'danger',
    title: <Trans>No internet connection</Trans>,
    message: <Trans>Cannot connect to the server. Please verify your internet connection and try again.</Trans>,
};

export const SavedSuccessNotification = {
    type: 'success',
    title: <Trans>Saved successfully</Trans>,
};
