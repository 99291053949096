import { HttpMethod, Id } from '@wedo/types';
import { MeetingRole } from '../types/meetingRole';
import { baseApi, configureTag } from './base';
import { tag as meetingTag } from './meeting';

export const { tagType, tag } = configureTag('MeetingRole');

export const meetingRoleApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addMeetingRoles: build.mutation<
                unknown,
                { meetingId: Id; meetingRoles: Pick<MeetingRole, 'id' | 'name' | 'color' | 'permissions'>[] }
            >({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/roles`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    return [meetingTag(meetingId)];
                },
            }),
            updateMeetingRoles: build.mutation<
                unknown,
                {
                    meetingId: Id;
                    meetingRoles: { id: Id; changes: Pick<MeetingRole, 'name' | 'color' | 'permissions'> }[];
                }
            >({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/roles`,
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    return [meetingTag(meetingId)];
                },
            }),
            deleteMeetingRoles: build.mutation<unknown, { meetingId: Id; meetingRoles: Id[] }>({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/roles/delete`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    return [meetingTag(meetingId)];
                },
            }),
        }),
    });

export const { useAddMeetingRolesMutation, useUpdateMeetingRolesMutation, useDeleteMeetingRolesMutation } =
    meetingRoleApi;
