import React, { MouseEvent as ReactMouseEvent, ReactNode, useEffect, useRef } from 'react';
import { useEvent } from '@wedo/utils/hooks';
import { SelectionRectangle } from './SelectionRectangle';
import { ScrollingThreshold, SelectableContainers, SelectableElements, IntersectedElements } from './constants';
import { ContainerElement, Point, Rectangle } from './types';
import { isIntersecting, scrollSpeed, shouldScroll } from './utils';

const UnselectableNodes = ['INPUT', 'BUTTON', 'TEXTAREA'];

type SelectableAreaProps = {
    children: ReactNode;
    className?: string;
    onSelectionEnds?: (elements: HTMLElement[], clearSelection: () => void) => void;
};

export const SelectableArea = ({ children, className, onSelectionEnds }: SelectableAreaProps) => {
    const selectableArea = useRef<HTMLDivElement>();
    const selectionRectangle = useRef<HTMLDivElement>();

    const startPoint = useRef<Point>(null);

    const selectedElements = useRef<HTMLElement[]>([]);

    const clearSelection = () =>
        selectableArea.current
            .querySelectorAll<HTMLElement>(IntersectedElements)
            .forEach((element) => delete element.dataset.intersected);

    const selectElements = (rectangle: Rectangle) => {
        selectedElements.current = Array.from<HTMLElement>(
            selectableArea.current.querySelectorAll(SelectableElements)
        ).filter((element) => {
            const intersecting = isIntersecting(element.getBoundingClientRect(), rectangle);
            element.dataset.intersected = intersecting.toString();
            return intersecting;
        });
    };

    const resizeSelectionRectangle = (x: number, y: number) => {
        Object.assign(selectionRectangle.current.style, {
            top: `${y > startPoint.current.y ? startPoint.current.y : y}px`,
            left: `${x > startPoint.current.x ? startPoint.current.x : x}px`,
            width: `${Math.abs(x - startPoint.current.x)}px`,
            height: `${Math.abs(y - startPoint.current.y)}px`,
        });
        return selectionRectangle.current.getBoundingClientRect();
    };

    const scroll = (container: ContainerElement) => () => {
        if (startPoint.current == null) {
            return;
        }

        const { x, y } = container;
        const { xSpeed, ySpeed } = scrollSpeed(container);

        startPoint.current = { x: startPoint.current.x - xSpeed, y: startPoint.current.y - ySpeed };

        container.scrollLeft += xSpeed;
        container.scrollTop += ySpeed;

        selectElements(resizeSelectionRectangle(x, y));

        if (container.autoScrolling) {
            if (shouldScroll(container)) {
                requestAnimationFrame(scroll(container));
            } else {
                container.autoScrolling = false;
            }
        }
    };

    const handleWheel = (event: WheelEvent) => {
        // If there is no start point, then we ignore the scroll event
        if (startPoint.current != null) {
            // As we manually handle the scrollLeft and scrollTop of the container, we can prevent the default behaviour
            // of the event
            event.preventDefault();
            const { deltaX, deltaY } = event;
            const container = (event.target as HTMLElement).closest(
                SelectableContainers
            ) as unknown as ContainerElement;
            const { scrollLeft, scrollTop } = container;
            container.scrollLeft += deltaX;
            container.scrollTop += deltaY;
            startPoint.current = {
                x: startPoint.current.x - (container.scrollLeft - scrollLeft),
                y: startPoint.current.y - (container.scrollTop - scrollTop),
            };
            selectElements(resizeSelectionRectangle(container.x, container.y));
        }
    };

    const handleMouseDown = ({ pageX: x, pageY: y, button, target }: ReactMouseEvent<HTMLDivElement>) => {
        // We don't want to start a selection rectangle if the user didn't left-click, or if it clicks outside the
        // selectable area
        const element = target as unknown as HTMLElement;
        if (
            button === 0 &&
            !UnselectableNodes.includes(element.nodeName) &&
            element.closest('[data-unselectable="true"]') == null
        ) {
            // We reset the selection whenever the mouse is clicked
            selectableArea.current.querySelectorAll<HTMLElement>(IntersectedElements).forEach((element) => {
                element.dataset.intersected = 'false';
            });

            startPoint.current = { x, y };

            // We don't want text to be selected when the user is dragging
            selectableArea.current.style.userSelect = 'none';

            Object.assign(selectionRectangle.current.style, {
                display: 'block',
                top: `${y}px`,
                left: `${x}px`,
                width: 0,
                height: 0,
            });
        }
    };

    const handleMouseMove = ({ pageX: x, pageY: y }: MouseEvent) => {
        // If there is no start point, then the mouse is moving without being clicked
        if (startPoint.current != null) {
            const rectangle = resizeSelectionRectangle(x, y);

            selectElements(rectangle);

            Array.from<ContainerElement>(selectableArea.current.querySelectorAll(SelectableContainers)).forEach(
                (container) => {
                    const { top, right, bottom, left } = container.getBoundingClientRect();
                    // We may scroll a container only if it's intersecting with the selection rectangle, if the mouse is
                    // in its vertical axis, or if it's a horizontal container and the mouse is in its horizontal axis
                    if (
                        isIntersecting({ top, right, bottom, left }, rectangle) ||
                        (x > left && x < right) ||
                        (container.dataset.selectableContainerHorizontal === 'true' && y > top && y < bottom)
                    ) {
                        // We update the mouse position of all selected containers as it may be useful to compute the
                        // auto-scrolling speed
                        Object.assign(container, {
                            x,
                            y,
                            top: top + ScrollingThreshold,
                            right: right - ScrollingThreshold,
                            bottom: bottom - ScrollingThreshold,
                            left: left + ScrollingThreshold,
                        });
                        if (!container.autoScrolling && shouldScroll(container)) {
                            container.autoScrolling = true;
                            requestAnimationFrame(scroll(container));
                        }
                    } else {
                        // If the container isn't intersecting the selection anymore, we should stop it from
                        // auto-scrolling
                        container.autoScrolling = false;
                    }
                }
            );
        }
    };

    const handleMouseUp = () => {
        selectableArea.current.querySelectorAll<ContainerElement>(SelectableContainers).forEach((container) => {
            container.autoScrolling = false;
        });
        selectableArea.current.style.userSelect = 'initial';
        selectionRectangle.current.style.display = 'none';
        if (startPoint.current != null) {
            onSelectionEnds?.(
                Array.from(selectableArea.current.querySelectorAll<HTMLElement>(IntersectedElements)),
                clearSelection
            );
        }
        startPoint.current = null;
    };

    useEvent('mousemove', handleMouseMove);
    useEvent('mouseup', handleMouseUp);

    useEffect(
        () => {
            // FIXME containers may not yet be rendered as data has not yet be fetched
            const containers = Array.from(selectableArea.current.querySelectorAll(SelectableContainers));
            containers.forEach((container) => container.addEventListener('wheel', handleWheel));
            return () => {
                containers.forEach((container) => container.removeEventListener('wheel', handleWheel));
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div ref={selectableArea} className={className} onMouseDown={handleMouseDown}>
            {children}
            <SelectionRectangle ref={selectionRectangle} />
        </div>
    );
};
