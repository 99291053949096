import {
    faBoxArchive,
    faFolderOpen,
    faPen,
    faTrash,
    faCheck,
    faArrowRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';

export const editIcon = faPen;

export const archiveIcon = faBoxArchive;

export const unArchiveIcon = faFolderOpen;

export const deleteIcon = faTrash;

export const confirmIcon = faCheck;

export const undoIcon = faArrowRotateLeft;
