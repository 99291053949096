import React, { ReactElement, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TopicDuration } from 'Shared/components/meeting/topicView/TopicDuration';
import { useUpdateTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc } from 'Shared/trpc';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type ResetTopicsDurationProps = {
    meetingId: Id;
} & ContextModalProps;
export const ResetTopicsDurationModal = ({ meetingId, ...modalProps }: ResetTopicsDurationProps): ReactElement => {
    const [duration, setDuration] = useState(0);

    const { data: topics = [] } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });

    const [updateTopics, { isLoading }] = useUpdateTopicsMutation();

    const handleSave = async () => {
        const changeObjects = topics.map((t: MeetingTopic) => {
            return {
                id: t.id,
                changes: { duration: duration },
            };
        });
        await updateTopics({
            meetingId: meetingId,
            topics: changeObjects,
        }).unwrap();
        void modalProps.close();
    };

    return (
        <Modal size="md" {...modalProps}>
            <Modal.Header title={t`Set topics duration`} />
            <Modal.Body>
                <div className="mb-5 w-full text-center text-sm">
                    <Trans>Choose a duration for all topics in this meeting</Trans>
                </div>
                <TopicDuration duration={duration} onUpdate={setDuration} onSave={handleSave} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" onClick={handleSave} loading={isLoading}>
                    <Trans>Set duration</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
