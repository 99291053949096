import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import {
    IconDefinition,
    faCalendar,
    faCalendarExclamation,
    faUser,
    faSync,
    faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { TASK_DETAIL_ICON_CLASS } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';

const typeToIcon: Record<string, IconDefinition> = {
    planned_date: faCalendar,
    due_date: faCalendarExclamation,
    assignee: faUser,
    recurrence: faSync,
    description: faPencil,
} as const;

type TaskDetailIconProps = {
    isActive?: boolean;
    type: keyof typeof typeToIcon;
};

export const TaskDetailIcon: FC<TaskDetailIconProps> = ({ isActive = true, type }) => {
    return (
        <FontAwesomeIcon
            className={clsx(TASK_DETAIL_ICON_CLASS, isActive ? 'text-gray-500' : 'text-gray-400')}
            icon={typeToIcon[type]}
        />
    );
};
