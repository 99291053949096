import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Group } from '../../hooks/useGroupedTasks';

type NoTasksDroppableProps = {
    group: Group;
    isReadOnly: boolean;
};

export const NoTasksSortable = ({ group, isReadOnly }: NoTasksDroppableProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tasks, ...groupProperties } = group;

    const { setNodeRef } = useSortable({ id: `${group.key}-no-tasks`, data: { group: groupProperties } });

    return (
        <div
            ref={setNodeRef}
            className={clsx(
                '!border border-t-0 border-gray-200 px-2 py-1 leading-6 text-gray-500',
                !isReadOnly && 'ml-5'
            )}
        >
            <Trans>No tasks</Trans>
        </div>
    );
};
