import { useCallback, useEffect, useState } from 'react';

const SmallScreenThreshold = 768;

export const useIsSmallScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleResize = useCallback(() => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return width <= SmallScreenThreshold;
};
