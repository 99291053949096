import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';

export const ClearTag = () => {
    return (
        <Tag size="sm" color="red" icon={faTrash}>
            <Trans>Clear</Trans>
        </Tag>
    );
};
