import { useNavigate, useParams } from 'react-router-dom';
import { faAddressCard, faCircleCheck, faMedal, faRss, faVectorCircle } from '@fortawesome/pro-regular-svg-icons';
import { msg } from '@lingui/macro';
import { useUser } from 'App/store/usersStore';
import { PageHeaderTab, TabsPageHeader } from 'Shared/components/layout/TabsPageHeader';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserOptionsMenu } from 'Shared/components/user/UserOptionsMenu';
import { usePageTitle } from 'Shared/hooks/usePageTitle';

const Tabs: PageHeaderTab[] = [
    {
        to: '/profile',
        icon: faAddressCard,
        title: msg`Profile`,
    },
    {
        to: '/tasks',
        icon: faCircleCheck,
        title: msg`Tasks`,
    },
    {
        to: '/feed',
        icon: faRss,
        title: msg`Feed`,
        matchPathname: '/feed/*',
    },
    {
        to: '/governance',
        icon: faVectorCircle,
        title: msg`Governance`,
        matchPathname: '/governance/*',
    },
    {
        to: '/badges',
        icon: faMedal,
        title: msg`Badges`,
    },
];

export const UserPage = () => {
    const { userId } = useParams();

    const user = useUser(userId);

    usePageTitle(user?.full_name);

    const title = (
        <div className="flex items-center gap-2">
            <UserAvatar user={user} showTooltip={false} /> {user?.full_name}
        </div>
    );

    const navigate = useNavigate();

    return (
        <TabsPageHeader
            title={title}
            baseLink={`/users/${userId}`}
            actions={
                <UserOptionsMenu
                    user={user}
                    onDelete={() => {
                        navigate('/users');
                    }}
                />
            }
            tabs={Tabs}
        />
    );
};
