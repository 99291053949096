import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { convertPointsToSvg, createPolygon, Vector2D } from '@wedo/utils';
import { colors } from '../../../colors';

const SVG_SIZE = 100;

const getPointsBannerCoordinates = (sideLength: number, thickness: number): Vector2D[] => {
    return [
        [SVG_SIZE / 2, SVG_SIZE / 2 + sideLength],
        [SVG_SIZE / 2 + (Math.sqrt(3) / 2) * sideLength, SVG_SIZE / 2 + sideLength / 2],
        [SVG_SIZE / 2 + (Math.sqrt(3) / 2) * sideLength, SVG_SIZE / 2 + sideLength / 2 - thickness],
        [SVG_SIZE / 2 - (Math.sqrt(3) / 2) * thickness, SVG_SIZE / 2 + sideLength - thickness / 2],
    ];
};

const getQuantityBannerCoordinates = (sideLength: number, thickness: number): Vector2D[] => {
    return [
        [SVG_SIZE / 2, SVG_SIZE / 2 + sideLength],
        [SVG_SIZE / 2 + (Math.sqrt(3) / 2) * thickness, SVG_SIZE / 2 + sideLength - thickness / 2],
        [SVG_SIZE / 2 - (Math.sqrt(3) * sideLength) / 2, SVG_SIZE / 2 + sideLength / 2 - thickness],
        [SVG_SIZE / 2 - (Math.sqrt(3) * sideLength) / 2, SVG_SIZE / 2 + sideLength / 2],
    ];
};

export type BadgeType =
    | 'meeting'
    | 'file'
    | 'task'
    | 'checklist'
    | 'user'
    | 'workspace'
    | 'setting'
    | 'other'
    | 'governance';

type BadgeProps = {
    type?: BadgeType;
    points: number;
    quantity?: number;
    timesAchieved?: number;
    isRepeating?: boolean;
    isStatic?: boolean;
    isLocked?: boolean;
    icon: IconProp;
    className?: string;
    onClick?: () => void;
    id?: string;
} & PropsWithChildren;

const TypeGradient = ({ type, id }: { type: BadgeType; id: string }) => {
    switch (type) {
        case 'meeting':
            return (
                <linearGradient id="typeGradient-meeting">
                    <stop offset="0%" stopColor="#6366f1" />
                    <stop offset="100%" stopColor="#a855f7" />
                </linearGradient>
            );
        case 'file':
            return (
                <linearGradient id="typeGradient-file">
                    <stop offset="0%" stopColor="#fbbf24" />
                    <stop offset="100%" stopColor="#f59e0b" />
                </linearGradient>
            );
        case 'task':
            return (
                <linearGradient id="typeGradient-task">
                    <stop offset="0%" stopColor="#22c55e" />
                    <stop offset="100%" stopColor="#14b8a6" />
                </linearGradient>
            );
        case 'checklist':
            return (
                <linearGradient id="typeGradient-checklist">
                    <stop offset="0%" stopColor="#a3e635" />
                    <stop offset="100%" stopColor="#84cc16" />
                </linearGradient>
            );
        case 'user':
            return (
                <linearGradient id="typeGradient-user">
                    <stop offset="0%" stopColor="#0072ce" />
                    <stop offset="100%" stopColor="#2f94e7" />
                </linearGradient>
            );
        case 'workspace':
            return (
                <linearGradient id="typeGradient-workspace">
                    <stop offset="0%" stopColor="#f87171" />
                    <stop offset="100%" stopColor="#ef4444" />
                </linearGradient>
            );
        case 'setting':
            return (
                <linearGradient id="typeGradient-setting">
                    <stop offset="0%" stopColor="#f472b6" />
                    <stop offset="100%" stopColor="#ec4899" />
                </linearGradient>
            );
        case 'governance':
            return (
                <linearGradient id="typeGradient-governance">
                    <stop offset="0%" stopColor="#22d3ee" />
                    <stop offset="100%" stopColor="#06b6d4" />
                </linearGradient>
            );
        default:
            if (id !== 'LEGACY_RANK_HERO') {
                return (
                    <linearGradient id="typeGradient-other">
                        <stop offset="0%" stopColor="#94a3b8" />
                        <stop offset="100%" stopColor="#64748b" />
                    </linearGradient>
                );
            }
            return (
                <linearGradient id="typeGradient-hero" gradientTransform="rotate(45)">
                    <stop offset="0%" stopColor="rgba(255, 0, 0, 1)" />
                    <stop offset="10%" stopColor="rgba(255, 154, 0, 1)" />
                    <stop offset="20%" stopColor="rgba(208, 222, 33, 1)" />
                    <stop offset="30%" stopColor="rgba(79, 220, 74, 1)" />
                    <stop offset="40%" stopColor="rgba(63, 218, 216, 1)" />
                    <stop offset="50%" stopColor="rgba(47, 201, 226, 1)" />
                    <stop offset="60%" stopColor="rgba(28, 127, 238, 1)" />
                    <stop offset="70%" stopColor="rgba(95, 21, 242, 1)" />
                    <stop offset="80%" stopColor="rgba(186, 12, 248, 1)" />
                    <stop offset="90%" stopColor="rgba(251, 7, 217, 1)" />
                    <stop offset="100%" stopColor="rgba(255, 0, 0, 1)" />
                </linearGradient>
            );
    }
};

const getTier = (points: number) => {
    if (points >= 100) {
        return 'diamond';
    }
    if (points >= 50) {
        return 'gold';
    }
    if (points >= 20) {
        return 'silver';
    }
    return 'bronze';
};
const TierGradient = ({ points }: { points: number }) => {
    const tier = getTier(points);
    switch (tier) {
        case 'diamond':
            return (
                <linearGradient id="tierGradient-diamond">
                    <stop offset="0%" stopColor="#7cc5fd" />
                    <stop offset="100%" stopColor="#0072ce" />
                </linearGradient>
            );
        case 'gold':
            return (
                <linearGradient id="tierGradient-gold">
                    <stop offset="0%" stopColor="#785F02" />
                    <stop offset="100%" stopColor="#EDBD0F" />
                </linearGradient>
            );
        case 'silver':
            return (
                <linearGradient id="tierGradient-silver">
                    <stop offset="0%" stopColor="#64748b" />
                    <stop offset="100%" stopColor="#94a3b8" />
                </linearGradient>
            );
        case 'bronze':
        default:
            return (
                <linearGradient id="tierGradient-bronze">
                    <stop offset="0%" stopColor="#753909" />
                    <stop offset="100%" stopColor="#dd7538" />
                </linearGradient>
            );
    }
};

export const Badge = ({
    points,
    quantity,
    timesAchieved,
    isRepeating,
    icon,
    type = 'other',
    isLocked = false,
    isStatic = false,
    className = 'w-[120px] h-[120px]',
    id,
    onClick,
}: BadgeProps) => {
    const typeGradientId = id !== 'LEGACY_RANK_HERO' ? `typeGradient-${type}` : 'typeGradient-hero';
    const tierGradientId = `tierGradient-${getTier(points) ?? 'default'}`;

    return (
        <svg
            id={id}
            onClick={onClick}
            className={clsx(
                'group',
                !isStatic && 'hover:scale-110 hover:-rotate-2 transition-all hover:filter-none ',
                isLocked && 'filter brightness-50 grayscale opacity-50',
                onClick != null && 'cursor-pointer',
                className
            )}
            viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <TypeGradient type={type} id={id} />
                <TierGradient points={points} />
            </defs>

            <polygon
                points={convertPointsToSvg(createPolygon([SVG_SIZE / 2, SVG_SIZE / 2], 46, 6))}
                stroke={`url(#${typeGradientId})`}
                fill="white"
                strokeWidth={6}
            />

            <polygon
                points={convertPointsToSvg(createPolygon([SVG_SIZE / 2, SVG_SIZE / 2], 38, 6))}
                fill={`url(#${typeGradientId})`}
                opacity={0.3}
            />
            {quantity != null && (
                <>
                    <polygon
                        points={convertPointsToSvg(getQuantityBannerCoordinates(50, 15))}
                        fill={colors.gray[600]}
                    />
                    <text
                        transform="rotate(30)"
                        x="61"
                        y="58.5"
                        fill="white"
                        fontSize="9"
                        fontWeight="bold"
                        textAnchor="middle"
                    >
                        {quantity}
                    </text>
                </>
            )}
            <polygon points={convertPointsToSvg(getPointsBannerCoordinates(50, 18))} fill={`url(#${tierGradientId})`} />

            <circle cx="50" cy="50" r="20" fill={`url(#${typeGradientId})`} />

            <FontAwesomeIcon x="39" y="39" width="22" height="22" color="#FFFFFF" icon={icon} />

            <text
                transform="rotate(-30)"
                x="18"
                y="107"
                fill="white"
                fontSize="9"
                fontWeight="bold"
                textAnchor="middle"
            >
                +{points} pts
            </text>

            {isRepeating && (
                <g>
                    <circle cx="80" cy="12" r="10" fill={colors.gray[600]} />
                    <FontAwesomeIcon x="74" y="6" width="12" height="12" color={colors.white} icon={faSync} />
                </g>
            )}

            {timesAchieved != null && (
                <text
                    x="93"
                    y="95"
                    fill="#445566"
                    fontWeight="bold"
                    fontSize="10"
                    textAnchor="end"
                >{`x${timesAchieved}`}</text>
            )}

            <defs>
                <clipPath id="shineClip">
                    <polygon
                        points={convertPointsToSvg(createPolygon([SVG_SIZE / 2, SVG_SIZE / 2], 50, 6))}
                        fill="white"
                    />
                </clipPath>
                <linearGradient id="shineGradient">
                    <stop offset="0%" stopColor="#ffffff" stopOpacity="0" />
                    <stop offset="15%" stopColor="#ffffff" stopOpacity="0" />
                    <stop offset="20%" stopColor="#ffffff" stopOpacity="1" />
                    <stop offset="80%" stopColor="#ffffff" stopOpacity="1" />
                    <stop offset="85%" stopColor="#ffffff" stopOpacity="0" />
                    <stop offset="100%" stopColor="#ffffff" stopOpacity="0" />
                </linearGradient>
            </defs>

            <g clipPath="url(#shineClip)">
                <rect
                    width={SVG_SIZE}
                    height={SVG_SIZE}
                    fill="url(#shineGradient)"
                    className={clsx(
                        '-translate-x-36 -translate-y-40 rotate-45 opacity-60',
                        !isLocked &&
                            'group-hover:translate-x-36 group-hover:translate-y-12 duration-700 animate-badge-shine'
                    )}
                />
            </g>
        </svg>
    );
};
