import { Svg, Path, Rect, G, Defs, ClipPath } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const MFilesIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <G clip-path="url(#clip0_1800_26419)">
                <Path d="M505.167 6.83334H6.83319V505.167H505.167V6.83334Z" fill="#007DC6" />
                <Path
                    d="M70.0002 420.758H140.126L182.201 241.937L208.248 378.515H278.207L357.682 242.772L335.476 421.092H405.101L442 91H371.541L255.499 301.878L217.264 92.3357H146.304L70.0002 420.758Z"
                    fill="white"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1800_26419">
                    <Rect width="500" height="500" fill="white" transform="translate(5.99988 6)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};
