export enum PasswordRule {
    Size,
    Lowercase,
    Number,
    Special,
}

export const checkPasswordCompliance = (password: string): Set<PasswordRule> => {
    const rulesRespected = new Set<PasswordRule>();

    if (/[a-z]/.test(password)) {
        rulesRespected.add(PasswordRule.Lowercase);
    }
    if (/[0-9]/.test(password)) {
        rulesRespected.add(PasswordRule.Number);
    }
    if (password.length >= 8) {
        rulesRespected.add(PasswordRule.Size);
    }
    if (/[^a-zA-Z0-9]/.test(password)) {
        rulesRespected.add(PasswordRule.Special);
    }

    return rulesRespected;
};
