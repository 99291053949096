import { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ColorPicker,
    ContextModalProps,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { useUpdateSectionMutation } from 'Shared/services/workspace';
import { Section } from 'Shared/types/section';
import { Workspace } from 'Shared/types/workspace';
import { Template } from 'Shared/types/template';
import { Checklist } from 'Shared/types/checklist';
import { useUpdateChecklistSectionMutation } from 'Shared/services/checklist';

type WorkspaceSectionColorModalProps = {
    section: Section;
    workspace?: Workspace;
    template?: Template;
    checklist?: Checklist;
} & ContextModalProps;

export const ChooseSectionColorModal: FC<WorkspaceSectionColorModalProps> = ({
    section,
    workspace,
    checklist,
    template,
    ...modalProps
}) => {
    const { show } = useNotification();

    const [updateSection, { isLoading: isUpdateSectionLoading }] = useUpdateSectionMutation();
    const [updateChecklistSection, { isLoading: isUpdateChecklistSectionLoading }] =
        useUpdateChecklistSectionMutation();

    const [color, setColor] = useState<string>(section?.color);

    const hasUserChangedColor = color !== section?.color;
    const isLoading = isUpdateSectionLoading || isUpdateChecklistSectionLoading;

    const updateWorkspaceSectionColor = async () =>
        updateSection({
            workspaceId: section.tag_id,
            sectionId: section.id,
            section: { color },
        });

    const updateTemplateSectionColor = async () =>
        updateChecklistSection({
            checklistId: template?.checklist_id,
            sectionId: section.id,
            section: { color },
        });

    const updateChecklistSectionColor = async () =>
        updateChecklistSection({
            checklistId: checklist.id,
            sectionId: section.id,
            section: { color },
        });

    const handleUpdate = async () => {
        let response;
        if (workspace) {
            response = await updateWorkspaceSectionColor();
        } else if (checklist) {
            response = updateChecklistSectionColor();
        } else if (template) {
            response = updateTemplateSectionColor();
        }
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} size="sm">
            <Modal.Header title={t`Choose a color for the ${section?.name} section`} />

            <Modal.Body>
                <div className="flex justify-center">
                    <ColorPicker color={color} onChange={setColor} />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" disabled={!hasUserChangedColor} onClick={handleUpdate} loading={isLoading}>
                    <Trans>Update</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
