import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, forwardRef, ReactNode, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { MessageDescriptor } from '@lingui/core';
import clsx from 'clsx';
import { Dropdown, NavLink, NavLinkOptions, Tag, Tooltip } from '@wedo/design-system';
import { EmptyArray, EmptyString } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { PageHeader, PageHeaderProps } from 'Shared/components/PageHeader';
import { MatchedTabs } from 'Shared/components/layout/NavBar/MatchedTabs';
import { useTranslateTabs } from 'Shared/components/layout/useTranslateTabs';
import { useResponsiveItems } from 'Shared/hooks/useResponsiveItems';

export type PageHeaderTab = NavLinkOptions & {
    icon: IconProp;
    title: MessageDescriptor | ReactNode;
    count?: number;
    onClick?: () => void;
    isNew?: boolean;
};

export type TranslatedPageHeaderTab = Omit<PageHeaderTab, 'title'> & { title: string };

const Tab = forwardRef<HTMLAnchorElement, PageHeaderTab>(
    ({ icon, title, count, onClick, isNew, ...navLinkOptions }, ref) => {
        return (
            <NavLink
                ref={ref}
                onClick={onClick}
                className={(isActive) =>
                    clsx(
                        'flex shrink-0 items-center gap-1.5 rounded-t-md border-l border-r border-t px-2.5 pb-[0.75rem] pt-[0.4rem] text-sm',
                        isActive
                            ? 'border-gray-200 bg-gray-50 text-gray-800'
                            : 'border-white text-gray-500 hover:border-gray-200 hover:bg-gray-50'
                    )
                }
                {...navLinkOptions}
            >
                <FontAwesomeIcon icon={icon} className={clsx('h-4 w-4')} aria-hidden="true" />
                <span>{title as string}</span>
                {count > 0 && (
                    <span
                        className={
                            'rounded-full bg-gray-100 px-2 text-center text-xs text-gray-700 group-hover:bg-gray-200'
                        }
                    >
                        {count}
                    </span>
                )}
            </NavLink>
        );
    }
);

type TabsProps = {
    baseLink: string;
    tabs: PageHeaderTab[];
    extraTab?: ReactNode;
    forceJustifyTabs?: 'end' | 'center';
};

const Tabs: FC<TabsProps> = ({ baseLink = EmptyString, tabs: initialTabs, forceJustifyTabs, extraTab }) => {
    const tabs = useTranslateTabs(initialTabs) as TranslatedPageHeaderTab[];

    const dropdownRef = useRef<HTMLDivElement>();
    const { width } = useElementSize(dropdownRef);

    const { visibleItems, hiddenItems, outerRef, innerRef } = useResponsiveItems({
        items: tabs,
        dropdownWidth: width,
        staticWidth: 0,
    });

    const [selectedTab, setSelectedTab] = useState<TranslatedPageHeaderTab>();

    const [visibleTabs, hiddenTabs] = useMemo(() => {
        return [
            visibleItems.length <= 1 ? EmptyArray : visibleItems,
            visibleItems.length === 1 ? visibleItems.concat(hiddenItems) : hiddenItems,
        ];
    }, [visibleItems, hiddenItems]);

    const showDropdown = visibleTabs.length === 0;

    const extraTabWithLabel =
        extraTab != null
            ? React.cloneElement(extraTab, {
                  isInDropdown: true,
              })
            : undefined;

    const isSelectedTabHidden = useMemo<boolean>(() => {
        for (const tab of hiddenTabs) {
            if (tab === selectedTab) {
                return !showDropdown;
            }
        }
        return false;
    }, [selectedTab, hiddenTabs, showDropdown]);

    return (
        <>
            <MatchedTabs
                tabs={tabs}
                prefix={baseLink}
                onMatchedTabChange={(tab: TranslatedPageHeaderTab) => setSelectedTab(tab)}
            />
            <div ref={outerRef} className="w-full min-w-0">
                <div
                    ref={innerRef}
                    className={clsx(
                        'flex flex-1 items-center gap-2 font-medium text-gray-700',
                        !showDropdown && 'translate-y-[0.31rem]',
                        forceJustifyTabs === 'end' && 'justify-end',
                        forceJustifyTabs === 'center' && 'justify-center',
                        showDropdown && !forceJustifyTabs && 'justify-end',
                        !showDropdown && !forceJustifyTabs && 'justify-center'
                    )}
                >
                    {visibleTabs.map((tab, index) => (
                        <Tab key={index} prefix={baseLink} {...tab} />
                    ))}
                    {hiddenTabs.length === 0 && extraTab != null && extraTab}
                    {hiddenTabs.length > 0 && (
                        <Tooltip content={isSelectedTabHidden && selectedTab.title} placement="right" delay={300}>
                            <div
                                className={clsx(
                                    'border-l border-r border-t border-white',
                                    isSelectedTabHidden &&
                                        'group/dropdown translate-y-[0.12rem] border-l border-r border-t !border-gray-200 bg-gray-50 pb-[0.36rem] hover:bg-gray-200'
                                )}
                            >
                                <Dropdown
                                    variant={showDropdown ? 'outlined' : 'text'}
                                    icon={faChevronDown}
                                    className={clsx(
                                        '!text-gray-600',
                                        !showDropdown && '-translate-y-1',
                                        isSelectedTabHidden &&
                                            'border-t border-gray-200 bg-gray-50 group-hover/dropdown:bg-gray-200'
                                    )}
                                    iconClassName={clsx(isSelectedTabHidden && '!text-gray-800')}
                                    label={showDropdown && selectedTab?.title}
                                    size="sm"
                                    ref={dropdownRef}
                                >
                                    {hiddenTabs.map((tab, index) => (
                                        <Dropdown.LinkItem
                                            key={index}
                                            prefix={baseLink}
                                            to={tab.to}
                                            icon={tab.icon}
                                            count={tab.count}
                                            selected={selectedTab === tab}
                                        >
                                            <div className="flex w-full justify-between">
                                                <span>{tab.title}</span>
                                                {tab?.count > 0 && <Tag size="xs">{tab.count}</Tag>}
                                            </div>
                                        </Dropdown.LinkItem>
                                    ))}
                                    {extraTabWithLabel != null && extraTabWithLabel}
                                </Dropdown>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </>
    );
};

const Infos = ({ children }: { children: ReactNode }) => {
    return <div className="flex items-center gap-2 text-gray-700">{children}</div>;
};

const TabsPageHeaderComponent: FC<Omit<PageHeaderProps, 'tabs'> & TabsProps & { className?: string }> = ({
    baseLink,
    tabs,
    extraTab,
    className = '',
    ...pageHeaderProps
}) => {
    return (
        <div className={clsx('flex flex-col h-full max-h-full overflow-hidden', className)}>
            <PageHeader
                {...pageHeaderProps}
                tabs={
                    <Tabs
                        baseLink={baseLink}
                        tabs={tabs}
                        forceJustifyTabs={pageHeaderProps?.actions ? undefined : 'end'}
                        extraTab={extraTab}
                    />
                }
            />
            <Outlet />
        </div>
    );
};

Tabs.displayName = 'TabsPageHeader.Tabs';
Infos.displayName = 'TabsPageHeader.Infos';

export const TabsPageHeader = Object.assign(TabsPageHeaderComponent, { Tabs, Infos });
