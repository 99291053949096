import { useMemo } from 'react';
import { useAppDispatch } from 'App/store';
import { useSectionsPanel } from 'Shared/components/sections/SectionsPanel/useSectionsPanel';
import { useUpdateSectionMutation, workspaceApi } from 'Shared/services/workspace';
import { Section } from 'Shared/types/section';
import { Workspace } from 'Shared/types/workspace';

export const useWorkspaceSections = (workspace: Workspace) => {
    const [updateSection] = useUpdateSectionMutation();
    const dispatch = useAppDispatch();

    const sections = useMemo<Array<Section>>(() => workspace?.sections, [workspace]);

    const sectionNames = useMemo(() => new Set(sections?.map((section) => section.name)), [sections]);

    const { maxOrder, handleDragEnd } = useSectionsPanel({
        sections,
        updateItemOrders: (payload, sortedSections) => {
            dispatch(
                workspaceApi.util.updateQueryData('getWorkspace', workspace.id, (workspace) => {
                    workspace.sections = sortedSections;
                })
            );

            for (const item of payload) {
                void updateSection({ workspaceId: workspace.id, sectionId: item.id, section: { order: item.order } });
            }
        },
    });

    return { sections, maxOrder, handleDragEnd, sectionNames };
};
