import { forwardRef, HTMLAttributes, MouseEvent, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { Icon } from '~/components/Icon/Icon';
import { IconName } from '~/components/Icon/type';
import { NavLink, NavLinkProps } from '~/components/NavLink/NavLink';
import { Tooltip, TooltipProps } from '~/components/Tooltip/Tooltip';
import { Size } from '~/types';
import { preventDefault, stopPropagation } from '@wedo/utils';

const classes = {
    base: 'inline-flex items-center justify-center truncate font-medium text-center focus-visible:ring-2 gap-1 focus:ring-offset-2 focus:outline-none',
    shape: {
        circle: {
            size: {
                xs: 'rounded-xl',
                sm: 'rounded-xl',
                md: 'rounded-full',
                lg: 'rounded-full',
                xl: 'rounded-full',
            },
        },
        square: {
            size: {
                xs: 'rounded-md',
                sm: 'rounded-md',
                md: 'rounded-md',
                lg: 'rounded-md',
                xl: 'rounded-md',
            },
        },
    },
    size: {
        xs: 'px-1.5 py-0.5 !text-xs',
        sm: 'px-2 py-0.5 !text-sm',
        md: 'px-2 py-1 !text-sm',
        lg: 'px-2 py-1 !text-base',
        xl: 'px-3 py-2 !text-lg',
    },
    color: {
        blue: 'bg-blue-200 text-blue-900 focus:ring-blue-300',
        red: 'bg-red-100 text-red-800 focus:ring-red-200',
        green: 'bg-green-100 text-green-800 focus:ring-green-200',
        yellow: 'bg-yellow-100 text-yellow-800 focus:ring-yellow-200',
        orange: 'bg-orange-100 text-orange-800 focus:ring-orange-200',
        purple: 'bg-purple-100 text-purple-800 hover:bg-purple-200 focus:ring-purple-500',
        gray: 'bg-gray-100 text-gray-700 focus:ring-gray-300',
        black: 'bg-black text-white focus:ring-black',
        none: 'focus:ring-blue-600',
        white: 'bg-white text-blue-950 hover:bg-gray-50 focus:ring-black-500',
    },
    hover: {
        blue: 'hover:bg-blue-100',
        red: 'hover:bg-red-200',
        green: 'hover:bg-green-200',
        yellow: 'hover:bg-yellow-200',
        orange: 'hover:bg-orange-200',
        gray: 'hover:bg-gray-200',
        black: 'hover:bg-gray-800',
        white: 'hover:bg-gray-50',
        dark: {
            blue: 'hover:bg-blue-700',
            red: 'hover:bg-red-600',
            green: 'hover:bg-green-600',
            yellow: 'hover:bg-yellow-700',
            orange: 'hover:bg-orange-700',
            gray: 'hover:bg-gray-700',
            black: 'hover:bg-gray-800',
        },
    },
    darkColor: {
        blue: 'bg-blue-600 text-white focus:ring-blue-600',
        red: 'bg-red-500 text-white focus:ring-red-500',
        green: 'bg-green-500 text-white focus:ring-green-500',
        yellow: 'bg-yellow-600 text-white focus:ring-yellow-500',
        orange: 'bg-orange-500 text-white focus:ring-orange-500',
        purple: 'bg-purple-500 text-white hover:bg-purple-700 focus:ring-purple-500',
        gray: 'bg-gray-600 text-white focus:ring-gray-600',
        black: 'bg-black text-white focus:ring-black',
        none: 'focus:ring-blue-600',
        white: 'bg-white text-blue-950 focus:ring-black-500',
    },
    dismiss: {
        blue: 'text-blue-700 hover:bg-blue-300 focus:bg-blue-600 focus:text-white focus:outline-none ring-offset-blue-300 focus:!ring-blue-600',
        red: 'text-red-600 hover:bg-red-300 hover:text-red-700 focus:bg-red-500 focus:text-white focus:outline-none ring-offset-red-200 focus:!ring-red-500',
        green: 'text-green-600 hover:bg-green-300 focus:bg-green-500 focus:text-white focus:outline-none ring-offset-green-200 focus:!ring-green-500',
        yellow: 'text-yellow-900 hover:bg-yellow-300 focus:bg-yellow-500 focus:text-white focus:outline-none ring-offset-yellow-200 focus:!ring-yellow-500',
        orange: 'text-orange-600 hover:bg-orange-300 hover:text-orange-800 focus:bg-orange-500 focus:text-white focus:outline-none ring-offset-orange-200 focus:!ring-orange-500',
        purple: 'text-purple-600 hover:bg-purple-100 focus:bg-purple-500 focus:text-white focus:outline-none',
        gray: 'text-gray-600 hover:bg-gray-500 hover:text-gray-100 focus:bg-gray-600 focus:text-white focus:outline-none ring-offset-gray-400 focus:!ring-gray-600',
        black: 'text-white hover:bg-gray-700 focus:bg-gray-800 focus:outline-none ring-offset-black focus:!ring-gray-800',
        white: 'text-gray-400 hover:bg-gray-50 focus:bg-gray-500 focus:text-blue-950 focus:outline-none',
        none: 'bg-opacity-0 hover:bg-opacity-20 bg-black focus:ring-blue-600',
        dark: {
            blue: 'text-white hover:bg-blue-800 focus:bg-blue-400 focus:ring-blue-400 ring-offset-blue-500',
            red: 'text-white hover:bg-red-400 bg-white bg-opacity-0 hover:bg-opacity-100 focus:bg-red-400 focus:ring-red-400 ring-offset-red-500',
            green: 'text-white hover:bg-green-400 focus:bg-green-400 focus:ring-green-400 ring-offset-green-500',
            yellow: 'text-white hover:bg-yellow-500 focus:bg-yellow-500 focus:ring-yellow-500 ring-offset-yellow-600',
            orange: 'text-white hover:bg-orange-400 focus:text-orange-500 focus:bg-orange-400 focus:ring-orange-400 ring-offset-orange-500',
            gray: 'text-white hover:bg-gray-500 bg-white bg-opacity-0 hover:bg-opacity-100 focus:bg-gray-500 focus:ring-gray-500  ring-offset-gray-600',
            black: 'text-white hover:bg-gray-700 bg-white bg-opacity-0 hover:bg-opacity-100 focus:bg-gray-600 focus:ring-gray-800 ring-offset-black',
            none: 'text-white hover:bg-opacity-20 bg-white bg-opacity-0 focus:bg-opacity-20',
        },
    },
};

export type TagColor = 'blue' | 'red' | 'green' | 'yellow' | 'gray' | 'orange' | 'black' | 'purple' | 'white' | 'none';

export type HTMLTagElement = Extract<Extract<HTMLSpanElement, HTMLButtonElement>, HTMLAnchorElement>;

export type TagProps = {
    children?: ReactNode;
    onClick?: (event: MouseEvent<HTMLTagElement, MouseEvent>) => void;
    href?: NavLinkProps['to'];
    className?: string;
    iconClassName?: string;
    color?: TagColor;
    icon?: IconDefinition | IconName;
    size?: Size | 'xs' | 'xl';
    dark?: boolean;
    onRemove?: () => void;
    isRemoveLoading?: boolean;
    hideRemoveTooltip?: boolean;
    shape?: 'square' | 'circle';
    removeClassName?: string;
    title?: ReactNode;
    tooltipProps?: TooltipProps;
} & Omit<HTMLAttributes<HTMLSpanElement>, 'title'>;

export const Tag = forwardRef<HTMLTagElement, TagProps>(
    (
        {
            children,
            className,
            iconClassName,
            color = 'blue',
            icon,
            href,
            size = 'md',
            onClick,
            dark = false,
            onRemove,
            isRemoveLoading = false,
            hideRemoveTooltip,
            shape = 'circle',
            removeClassName,
            title,
            tooltipProps,
            ...props
        },
        ref
    ) => {
        const tagClass = clsx(
            href != null || onClick != null ? (dark ? classes.hover.dark[color] : classes.hover[color]) : '',
            classes.base,
            classes.size[size],
            classes.shape[shape].size[size],
            dark ? classes.darkColor[color] : classes.color[color],
            className
        );

        const content = (
            <>
                {onClick == null && icon && (
                    <Icon name={icon} className={clsx(children != null && '-ml-0.5', 'h-3.5 w-3.5', iconClassName)} />
                )}
                {children}
                {onRemove && (
                    <Button
                        aria-label={t`Remove` + ': ' + children}
                        className={clsx(
                            color && dark ? classes.dismiss.dark[color] : classes.dismiss[color],
                            removeClassName
                        )}
                        size="xs"
                        variant="text"
                        shape="circle"
                        onClick={preventDefault(stopPropagation(onRemove))}
                        title={hideRemoveTooltip ? undefined : t`Remove`}
                        icon={faXmark}
                        loading={isRemoveLoading}
                    />
                )}
            </>
        );

        return href ? (
            <Tooltip content={title} {...tooltipProps}>
                <NavLink ref={ref} className={tagClass} to={href} {...props}>
                    {content}
                </NavLink>
            </Tooltip>
        ) : onClick ? (
            <Button
                ref={ref}
                className={tagClass}
                onClick={onClick}
                iconClassName={clsx('!ml-0', iconClassName)}
                variant="ghost"
                icon={icon}
                {...props}
            >
                {content}
            </Button>
        ) : (
            <span ref={ref} className={tagClass} {...props}>
                {content}
            </span>
        );
    }
);

Tag.displayName = 'Tag';
