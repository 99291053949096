import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { DefaultListViewMinWidth } from './ListView';
import { EventBus } from './events';
import { type Task, type Section, type SectionWithTasks, type DateWindow } from './types';
import { computeDateWindows, getZoomFromView, groupTasks } from './utils';

export const ZoomViews = ['year', 'quarter', 'month', 'week'] as const;

export const ZoomColumnWidths = [6, 14, 34, 60] as const;

export type ZoomView = (typeof ZoomViews)[number];

export type Dependency = { fromId: string; toId: string };

type DataState = {
    dateWindows: Map<string, DateWindow>;
    dependencies: Map<string, Array<Dependency>>;
    sections: Array<Section> | null;
    sectionsById: Map<string, Section> | null;
    sectionsWithTasks: Array<SectionWithTasks> | null;
    tasks: Array<Task> | null;
};

type ViewState = {
    closedSections: Set<string>;
    floatingDependency: {
        canConnect: boolean;
        id: string;
        element: HTMLElement | undefined;
        direction: 'to' | 'from';
        hoverId?: string | null;
        hoverElement?: HTMLElement;
        pointerPosition?: DOMPoint;
    } | null;
    focusedTaskId: string | null;
    hoveredTask: Task | null;
    listViewWidth: number;
    taskElements: Map<string, HTMLElement>;
    zoom: {
        level: number;
        view: ZoomView;
        columnWidth: number;
    };
};

export type GanttStore = {
    eventBus: EventBus;
    data: DataState;
    view: ViewState;
};

export const createStore = (sections: Array<Section> | null, tasks: Array<Task> | null | undefined) => {
    return create<GanttStore>()(
        subscribeWithSelector(
            immer(() => {
                const sectionsById =
                    sections != null ? new Map(sections.map((section) => [section.id, section])) : null;
                return {
                    eventBus: new EventBus(),
                    data: {
                        dateWindows:
                            sectionsById != null && tasks != null ? computeDateWindows(tasks, sectionsById) : null,
                        dependencies: new Map(),
                        sections: sections,
                        sectionsById,
                        sectionsWithTasks:
                            sectionsById != null && tasks != null ? groupTasks(tasks, sectionsById) : null,
                        tasks: tasks,
                    },
                    view: {
                        closedSections: new Set(),
                        floatingDependency: null,
                        focusedTaskId: null,
                        hoveredTask: null,
                        listViewWidth: DefaultListViewMinWidth,
                        taskElements: new Map(),
                        zoom: getZoomFromView('month'),
                    },
                };
            })
        )
    );
};
