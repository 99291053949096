import { msg } from '@lingui/macro';
import { HttpMethod, Id } from '@wedo/types';
import { tag as attachmentTag, tagList } from 'Shared/services/attachmentTags';
import { CacheTag } from 'Shared/services/cacheTag';
import { TransformParameter } from 'Shared/types/apiError';
import { Label, LabelRelation } from 'Shared/types/label';
import { baseApi, configureTag, listId } from './base';

export const { tagType: labelTagType, tag: labelTag, tags: labelTags } = configureTag(CacheTag.Label);

const labelError = {
    DuplicateError: {
        code: 'DuplicateError',
        path: 'Label already exists',
        message: msg`Label name is already used`,
    },
} satisfies TransformParameter;

export const labelApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [labelTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getLabels: build.query<Label[], void>({
                query: () => ({ url: 'labels' }),
                transformResponse: (res: Label[]) => res,
                providesTags: (result) => [...labelTags(result), labelTag(listId)],
            }),

            addLabel: build.mutation<Label, { label: Partial<Label>; relation: Partial<LabelRelation> }>({
                query: ({ label, relation }) => ({
                    url: 'labels',
                    body: { label, relation },
                    method: HttpMethod.Post,
                }),
                transformErrorResponse: (error) => error.transform(labelError),
                invalidatesTags: () => [labelTag(listId)],
            }),

            updateLabel: build.mutation<Label, { labelId: Id; label: Partial<Label> }>({
                query: ({ labelId, label }) => ({
                    url: `labels/${labelId}`,
                    body: label,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { labelId }) => [labelTag(labelId)],
                transformErrorResponse: (error) => error.transform(labelError),
            }),

            deleteLabel: build.mutation<any, { labelId: Id }>({
                query: ({ labelId }) => ({
                    url: `labels/${labelId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { labelId }) => [labelTag(labelId)],
            }),

            addLabelRelation: build.mutation<LabelRelation, { labelId: Id; relation: Partial<LabelRelation> }>({
                query: ({ labelId, relation }) => ({
                    url: `labels/${labelId}/relations`,
                    body: { relation },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { relation }) => [
                    attachmentTag(tagList),
                    attachmentTag(relation?.attachment_id),
                ],
            }),

            removeLabelRelation: build.mutation<any, { labelId: Id; relation: Partial<LabelRelation> }>({
                query: ({ labelId, relation }) => ({
                    url: `labels/${labelId}/relations`,
                    method: HttpMethod.Delete,
                    params: {
                        task_id: relation.task_id,
                        meeting_id: relation.meeting_id,
                        attachment_id: relation.attachment_id,
                    },
                }),
                invalidatesTags: [attachmentTag(tagList)],
            }),
        }),
    });

export const {
    useGetLabelsQuery,
    useAddLabelMutation,
    useUpdateLabelMutation,
    useDeleteLabelMutation,
    useAddLabelRelationMutation,
    useRemoveLabelRelationMutation,
} = labelApi;
