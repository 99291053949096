export const cleanObjectNullAttribute = (object: any): void => {
    Object.keys(object).forEach((key) => (object[key] === undefined ? delete object[key] : {}));
};

export const clone = (obj: object) => {
    return JSON.parse(JSON.stringify(obj));
};

export const EmptyObject = {};

export const getObjectWithoutKeys = <T extends Object>(object: T, keys: (keyof T)[]) => {
    const newObject = { ...object };
    keys.forEach((key) => delete newObject[key]);
    return newObject;
};
