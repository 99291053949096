import { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { Badge, Card, colors, getBadgeInfo, Skeleton, Tooltip, useModal } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { BadgeModal } from 'Pages/BadgesPage/BadgeModal';
import { BadgesPageSearchParams } from 'Pages/BadgesPage/BadgesPage';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { trpc } from 'Shared/trpc';
import { Badge as BadgeType } from 'Shared/types/badge';
import { DevFeature, UserFeature, UserRole } from 'Shared/types/user';
import { isGovernanceAddonId } from 'Shared/utils/chargebee';

const typeLabel = {
    meeting: msg`Meeting`,
    task: msg`Task`,
    file: msg`File`,
    checklist: msg`Checklist`,
    user: msg`User`,
    workspace: msg`Workspace`,
    setting: msg`Setting`,
    governance: msg`Governance`,
    other: msg`Other`,
};

export const BadgesList = ({ badges, isLoading }: { badges: BadgeType[]; isLoading?: boolean }) => {
    const currentUser = useSessionUser();
    const isAdmin = useMemo(() => currentUser?.role === UserRole.ADMIN, [currentUser]);
    const { data: organization } = useGetOrganizationQuery();
    const { data: subscription } = trpc.subscription.get.useQuery();
    const { network } = useCurrentNetwork();
    const hasSignatureFeature = useHasFeature(currentUser, network, DevFeature.Signature);

    const isInTrial = organization?.free_trial_end_date != null && organization?.status === 'in_trial';
    const hasGovernanceAddon = subscription?.subscription_items.some((item) => isGovernanceAddonId(item.item_price_id));
    const hasGovernanceOrGovernanceBadge =
        hasGovernanceAddon ||
        badges?.some(({ type, userBadges }) => type === 'governance' && userBadges?.length > 0) ||
        isInTrial;

    const hasTopicSubmissionFeature = useHasFeature(currentUser, network, DevFeature.TopicSubmission);
    const hasGanttFeature = useHasFeature(currentUser, network, UserFeature.Gantt);
    const [{ status }] = useSearchParams(BadgesPageSearchParams);
    const { open: openModal } = useModal();
    const filteredBadges = badges?.filter((badge) => {
        return badge.userBadges.length > 0 || !getBadgeInfo(badge.id).isAdminOnly || isAdmin;
    });

    const badgesSplitByType = useMemo(() => {
        return filteredBadges?.reduce((badges, badge) => {
            if (!badges[badge.type]) {
                badges[badge.type] = [];
            }
            badges[badge.type].push(badge);
            return badges;
        }, {});
    }, [badges]);

    return (Object.keys(badgesSplitByType ?? {}) || []).map((badgeType) => {
        return (
            (badgeType !== 'governance' || hasGovernanceOrGovernanceBadge) && (
                <Card key={badgeType} className={'m-6'}>
                    <Card.Header
                        title={i18n._(typeLabel[badgeType])}
                        actions={
                            (status == null || status === 'all') && (
                                <Tooltip
                                    content={`${badgesSplitByType[badgeType].filter((badge) => badge.userBadges.length > 0).length}/${badgesSplitByType[badgeType].length}`}
                                >
                                    <div className="w-6 h-6">
                                        <PieChart
                                            lineWidth={60}
                                            startAngle={270}
                                            data={[
                                                {
                                                    value: badgesSplitByType[badgeType].filter(
                                                        (badge) => badge.userBadges.length > 0
                                                    ).length,
                                                    color: colors.green[500],
                                                },
                                                {
                                                    value:
                                                        badgesSplitByType[badgeType].length -
                                                        badgesSplitByType[badgeType].filter(
                                                            (badge) => badge.userBadges.length > 0
                                                        ).length,
                                                    color: colors.gray[300],
                                                },
                                            ]}
                                        />
                                    </div>
                                </Tooltip>
                            )
                        }
                    />
                    <Card.Body>
                        <div className="grid grid-cols-[repeat(auto-fill,_120px)] justify-between gap-4">
                            {isLoading ? (
                                <Skeleton count={48} className="h-[120px] !w-[120px]" />
                            ) : (
                                badgesSplitByType[badgeType]?.map((badge) => {
                                    const badgeInfo = getBadgeInfo(badge.id);
                                    if (
                                        ((badgeInfo.addon === 'topicSubmission' && !hasTopicSubmissionFeature) ||
                                            (badgeInfo.addon === 'signature' && !hasSignatureFeature) ||
                                            (badgeInfo.addon === 'gantt' && !hasGanttFeature)) &&
                                        badge.userBadges.length === 0
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <Tooltip content={badgeInfo?.name} key={badge.id}>
                                            <Badge
                                                onClick={() => openModal(BadgeModal, { badge })}
                                                id={badge.id}
                                                key={badge.id}
                                                type={badge.type}
                                                points={badge.value}
                                                quantity={badgeInfo?.quantity}
                                                isLocked={badge.userBadges.length === 0}
                                                timesAchieved={
                                                    badge.userBadges.length > 1 ? badge.userBadges.length : undefined
                                                }
                                                isRepeating={badge.id.endsWith('_R')}
                                                icon={badgeInfo?.icon ?? faQuestion}
                                            />
                                        </Tooltip>
                                    );
                                })
                            )}
                        </div>
                    </Card.Body>
                </Card>
            )
        );
    });
};
