import { useNavigate } from 'react-router-dom';
import { type Notification as NotificationType } from 'Shared/types/notification';

export const useNetworkAction = () => {
    const navigate = useNavigate();

    return (notification: NotificationType) => {
        switch (notification.event_key) {
            case 'commented_network':
            case 'updated_comment_network':
            case 'deleted_comment_network':
                navigate('/feed');
                break;
            default:
        }
    };
};
