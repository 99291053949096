import React from 'react';
import {
    faEmptySet,
    faMinus,
    faPlus,
    faStickyNote,
    faUserPlus,
    faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';

export const teamNotificationHandler: NotificationHandlerFn = (notification) => {
    const createdBy = <strong>{notification.created_by.full_name}</strong>;
    const teamName = <strong>{notification.team.name}</strong>;

    switch (notification.event_key) {
        case 'added_team_member':
            return {
                icon: faPlus,
                text: (
                    <Trans>
                        {createdBy} added you to the {teamName} team
                    </Trans>
                ),
            };
        case 'removed_team_member':
            return {
                icon: faMinus,
                text: (
                    <Trans>
                        {createdBy} removed you from the {teamName} team
                    </Trans>
                ),
            };
        case 'promoted_team_member':
            return {
                icon: faUserSecret,
                text: (
                    <Trans>
                        {createdBy} promoted you as moderator of the {teamName} team
                    </Trans>
                ),
            };
        case 'revoked_team_member':
            return {
                icon: faUserSecret,
                text: (
                    <Trans>
                        {createdBy} revoked you as moderator of the {teamName} team
                    </Trans>
                ),
            };
        case 'added_team_member_request':
            return {
                icon: faUserPlus,
                text: (
                    <Trans>
                        {createdBy} requested membership for the team {teamName} team
                    </Trans>
                ),
            };
        case 'commented_team':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> mentioned you in a note
                    </Trans>
                ),
            };
        case 'updated_comment_team':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> edited a note you were mentioned in
                    </Trans>
                ),
            };
        case 'deleted_comment_team':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> deleted a note you were mentioned in
                    </Trans>
                ),
            };
        default:
            return { icon: faEmptySet, text: <></> };
    }
};
