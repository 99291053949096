import { useEffect, useRef } from 'react';
import { Signature, SignatureRequest } from '@wedo/db';
import { useUser } from 'App/store/usersStore';
import { trpc } from 'Shared/trpc';

export const useLock = ({
    signature,
    signatureRequest,
}: {
    signature: Signature;
    signatureRequest: SignatureRequest;
}) => {
    const { mutate: getSignatureLock, data: signatureLockData } = trpc.signature.tryLock.useMutation({
        signatureId: signature?.id,
    });
    const { mutateAsync: releaseSignatureLock } = trpc.signature.releaseLock.useMutation();

    // "signatureLockData" gets undefined everytime we resend the lock request.
    // The reference is used to cache the value until "signatureLockData" isn't undefined anymore
    const signatureLockRef = useRef(null);
    if (signatureLockData != null && signatureLockRef.current !== signatureLockData) {
        signatureLockRef.current = signatureLockData;
    }
    const signatureLock = signatureLockRef.current;

    const lockedByUser = useUser(signatureLock?.userId);

    useEffect(() => {
        let interval;
        if (
            signature != null &&
            signatureRequest != null &&
            signature.status !== 'SIGNED' &&
            !signatureRequest.isCanceled
        ) {
            getSignatureLock(signatureRequest.id);

            interval = setInterval(() => {
                getSignatureLock(signatureRequest.id);
            }, 5_000);
        }
        return () => {
            clearInterval(interval);
            if (signatureRequest?.attachmentId != null) {
                void releaseSignatureLock(signatureRequest.attachmentId);
            }
        };
    }, [signature, signatureRequest]);

    if (signature == null) {
        return null;
    }

    return { lockedByUser, signatureLock };
};
