import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Button, Label } from '@wedo/design-system';
import { getWeekDayList } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/ReccurrenceUtils';
import { FormRecurrence } from 'Shared/types/formRecurrence';

type RecurrenceRepeatOnWeekDaysProps = {
    state: FormRecurrence;
    toggleDay: (Day) => void;
};

export const RecurrenceRepeatOnWeekDaysInput = ({ state, toggleDay }: RecurrenceRepeatOnWeekDaysProps) => {
    const { i18n } = useLingui();

    const weekDays = useMemo(() => getWeekDayList(i18n), [i18n]);
    const isWeekDaySelected = (weekDay) => (state.days || []).some((d) => d === weekDay.index);

    const handleClick = (day) => {
        toggleDay(day.index);
    };
    return (
        <>
            <Label className={'col-span-2 text-end'} inputType={'inline'}>{t`Repeat on`}</Label>
            <div className={'col-span-10 flex flex-wrap gap-1'}>
                {weekDays.map((day, index) => {
                    return (
                        <Button
                            key={index}
                            role="checkbox"
                            active={isWeekDaySelected(day)}
                            aria-checked={isWeekDaySelected(day)}
                            className="w-[2.2rem] justify-center"
                            onClick={() => handleClick(day)}
                        >
                            {day.label}
                        </Button>
                    );
                })}
            </div>
        </>
    );
};
