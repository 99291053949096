import { useEffect } from 'react';
import { Element } from 'slate';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Id } from '@wedo/types';

const CleaningTimeout = 60000;

export type Status = 'idle' | 'pending' | 'saving' | 'error';

type ServerPluginStoreTopic = {
    blocks: Element[];
    startTimestamp: number;
    pendingBlocks: Element[];
    pendingTimeout: number;
    savingPromise: Promise<any[]>;
    savingAbortController: AbortController;
    replaceBlocks: boolean;
    status: Status;
};

type ServerPluginStore = {
    meetings: Record<
        Id,
        {
            cleaningTimeout: number;
            topics: Record<Id, ServerPluginStoreTopic>;
        }
    >;
};

export const useServerBlocksPluginStore = create<ServerPluginStore>()(immer(() => ({ meetings: {} })));

export const getState = <T>(meetingId: Id, topicId: Id, get: (topic: ServerPluginStoreTopic) => T) =>
    get(useServerBlocksPluginStore.getState().meetings[meetingId]?.topics[topicId]);

export const setState = (meetingId: Id, topicId: Id, set: (topic: ServerPluginStoreTopic) => void) =>
    useServerBlocksPluginStore.setState((state) => {
        if (state.meetings[meetingId] == null) {
            state.meetings[meetingId] = {
                cleaningTimeout: null,
                topics: {},
            };
        }
        if (state.meetings[meetingId].topics[topicId] == null) {
            state.meetings[meetingId].topics[topicId] = {
                blocks: null,
                startTimestamp: null,
                pendingBlocks: null,
                pendingTimeout: null,
                savingPromise: null,
                savingAbortController: null,
                replaceBlocks: false,
                status: 'idle',
            };
        }
        set(state.meetings[meetingId].topics[topicId]);
    });

export const useServerBlocksPlugin = (meetingId: Id) => {
    useEffect(() => {
        // Whenever this effect is mounted, we check if there is a cache cleaning timeout running, and if so, we stop it
        const cleaningTimeout = useServerBlocksPluginStore.getState().meetings[meetingId]?.cleaningTimeout;
        if (cleaningTimeout != null) {
            clearTimeout(cleaningTimeout);
        }

        return () => {
            useServerBlocksPluginStore.setState((state) => {
                if (state.meetings[meetingId] == null) {
                    state.meetings[meetingId] = {
                        cleaningTimeout: null,
                        topics: {},
                    };
                }
                // Whenever the effect is unmounted, we start a timeout to clean the cached data
                state.meetings[meetingId].cleaningTimeout = setTimeout(() => {
                    useServerBlocksPluginStore.setState((state) => {
                        delete state.meetings[meetingId];
                    });
                }, CleaningTimeout) as unknown as number;
            });
        };
    });
};
