import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { FormatDate, Table, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getFormattedBytes, stopPropagation } from '@wedo/utils';
import { useIsOverflowing } from '@wedo/utils/hooks';
import { useAppDispatch } from 'App/store';
import { AttachmentMenu } from 'Shared/components/file/AttachmentItem/AttachmentMenu';
import { AttachmentLabels } from 'Shared/components/file/AttachmentLabels';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useDownloadAttachment } from 'Shared/hooks/useDownloadAttachment';
import {
    invalidateAttachment,
    invalidateGetAttachments,
    invalidateGetAttachmentVersions,
    useDeleteAttachmentsMutation,
    useLazyGetAttachmentQuery,
} from 'Shared/services/attachment';
import { Attachment } from 'Shared/types/attachment';
import { isAttachmentPreviewableOrOffice, isUrlFile } from 'Shared/utils/attachment';

type TableRowAttachmentProps = {
    attachment: Attachment;
    workspaceId: Id;
    isPreviewable: boolean;
    isActionable: boolean;
    onFilenameClick: (attachment: Attachment) => void;
};

export const TableRowAttachment = ({
    attachment,
    workspaceId,
    isPreviewable,
    isActionable,
    onFilenameClick,
}: TableRowAttachmentProps) => {
    const dispatch = useAppDispatch();

    const [getAttachment, { data: lazyAttachment, isUninitialized, isLoading }] = useLazyGetAttachmentQuery();
    const [deleteAttachments] = useDeleteAttachmentsMutation();
    const { isAttachmentInfected } = useDownloadAttachment(attachment);

    const [titleRef, isOverflowing] = useIsOverflowing();

    const isAttachmentPreviewable = isPreviewable && isAttachmentPreviewableOrOffice(attachment);

    const handleDelete = async () => {
        await deleteAttachments({ attachments: [{ id: attachment.id }] });
        dispatch(invalidateGetAttachments());
    };

    const handleReload = async () => {
        if (lazyAttachment == null) {
            await getAttachment({ id: attachment.id, related: ['attachments.currentVersion', 'attachments.tags'] });
        }
        dispatch(invalidateGetAttachmentVersions());
        dispatch(invalidateGetAttachments());
        dispatch(invalidateAttachment(attachment.id));
    };

    return (
        <>
            <Tooltip
                content={
                    isAttachmentInfected
                        ? t`This file may be a virus!`
                        : isOverflowing && attachment.currentVersion.filename
                }
            >
                <Table.Cell className="max-w-[10rem]">
                    <div className={clsx('truncate', isAttachmentInfected && 'text-red-500')}>
                        <span
                            ref={titleRef}
                            {...(isAttachmentPreviewable && {
                                className: 'hover:underline',
                                role: 'button',
                                tabIndex: 0,
                                onKeyDown: () => onFilenameClick(attachment),
                                onClick: () => onFilenameClick(attachment),
                            })}
                        >
                            {attachment.currentVersion.filename}
                        </span>
                    </div>

                    {attachment?.labels?.length > 0 && <AttachmentLabels attachment={attachment} />}
                </Table.Cell>
            </Tooltip>

            <Table.Cell className="hidden lg:table-cell w-28">
                {isUrlFile(attachment) ? '--' : getFormattedBytes(Number(attachment.currentVersion.file_size))}
            </Table.Cell>

            <Table.Cell className="w-48">
                <FormatDate date={attachment.currentVersion.updated_at} format={'short'} />
            </Table.Cell>

            <Table.Cell className="w-14">
                <UserAvatar user={attachment.updated_by} size="xs" />
            </Table.Cell>

            {isActionable && (
                <Table.Cell onClick={stopPropagation()} className="text-end">
                    <AttachmentMenu
                        isAll
                        attachment={lazyAttachment}
                        relation={{}}
                        onReload={handleReload}
                        onDelete={handleDelete}
                        isReadonly={!attachment?.canEdit}
                        size="sm"
                        workspaceId={workspaceId}
                        isDisabled={isUninitialized || isLoading}
                    />
                </Table.Cell>
            )}
        </>
    );
};
