import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    AutoTooltipText,
    Button,
    colors,
    ContextModalProps,
    FormatDate,
    Input,
    ManagedTable,
    Modal,
    ModalType,
    Select,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { useMeetingVoters } from 'Pages/meeting/components/Vote/VoteHooks';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    buildGetVoteBlockParameters,
    useAddVoteAnswerMutation,
    useDeleteVoteAnswersMutation,
    useGetVoteQuery,
    useResetMeetingVotesResultsMutation,
    useResetVoteResultsMutation,
    useUpdateVoteMutation,
    useUpdateVoteOptionsMutation,
} from 'Shared/services/meetingVote';
import { MeetingUser } from 'Shared/types/meetingUser';
import { Vote, VoteAnswer, VoteOption } from 'Shared/types/vote';

type EditVoteResultsModalProps = {
    voteId: Id;
    meetingId: Id;
} & ContextModalProps &
    PropsWithChildren;

export const EditVoteResultsModal = ({ voteId, meetingId, children, ...modalProps }: EditVoteResultsModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const { data: vote } = useGetVoteQuery(buildGetVoteBlockParameters(voteId, meetingId), {
        skip: voteId == null,
        refetchOnMountOrArgChange: true,
    });

    // Component state
    const [hasChanged, setHasChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Form state
    const [formVoteOptions, setFormVoteOptions] = useState<VoteOption[]>([]);
    const [formAbstainedOption, setFormAbstainedOption] = useState<VoteOption>(null);
    const [formVoteAnswers, setFormVoteAnswers] = useState<Partial<VoteAnswer>[]>([]);

    // Updates
    const [voteOptionsToUpdate, setVoteOptionsToUpdate] = useState([]);
    const [voteAnswersToAdd, setVoteAnswersToAdd] = useState([]);
    const [voteAnswersToDelete, setVoteAnswersToDelete] = useState([]);
    const [voteUpdates, setVoteUpdates] = useState<Partial<Vote>>({});

    // Vote hooks and memos
    const voters = useMeetingVoters(meetingId);
    const shouldResetResultsButtonBeDisabled = useMemo(() => {
        return (
            (vote?.voteAnswers?.length === 0 &&
                vote?.voteOptions?.find((o) => o.vote_count > 0) == null &&
                vote?.abstained_count === 0) ||
            vote?.status === 'closed' ||
            meeting?.status === 'locked' ||
            meeting?.deleted
        );
    }, [vote?.voteAnswers, vote?.voteOptions, vote?.abstained_count, meeting?.status, meeting?.deleted, vote?.status]);

    const isLinearScaleVote = useMemo(() => ['rating', 'linear_scale'].includes(vote?.type), [vote?.type]);

    // RTK Queries
    const [addVoteAnswer] = useAddVoteAnswerMutation();
    const [deleteVoteAnswers] = useDeleteVoteAnswersMutation();
    const [updateVoteOptions] = useUpdateVoteOptionsMutation();
    const [updateVote] = useUpdateVoteMutation();
    const [resetVoteResults] = useResetVoteResultsMutation();
    const [resetMeetingVotesResults] = useResetMeetingVotesResultsMutation();

    useEffect(() => {
        setFormVoteOptions(vote?.voteOptions || []);
        setFormVoteAnswers(vote?.voteAnswers || []);
        if (vote?.can_abstain) {
            setFormAbstainedOption({
                id: 'abstained',
                value: t`Abstained`,
                vote_id: vote?.id,
                color: colors.gray[600],
                updated_at: vote?.updated_at,
                vote_count: vote?.abstained_count,
            });
        } else {
            setFormAbstainedOption(null);
        }
    }, [vote?.voteOptions, vote?.voteAnswers, vote?.can_abstain]);

    const handleConfirmDiscardChanges = () => {
        setFormVoteOptions(vote?.voteOptions);
        setFormVoteAnswers(vote?.voteAnswers);

        setVoteOptionsToUpdate([]);
        setVoteAnswersToAdd([]);
        setVoteAnswersToDelete([]);
        setHasChanged(false);

        void modalProps.close();
    };

    const handleSaveConfirm = async () => {
        setIsLoading(true);
        try {
            if (voteUpdates != null && Object.keys(voteUpdates).length > 0) {
                await updateVote({ meetingId: meeting?.id, voteId: vote.id, changes: voteUpdates })
                    .unwrap()
                    .catch(() => showNotification(UnexpectedErrorNotification));
            }
            if (voteOptionsToUpdate.length > 0) {
                await updateVoteOptions({
                    meetingId: meeting.id,
                    voteId: vote.id,
                    data: {
                        updatedVoteOptions: voteOptionsToUpdate,
                    },
                });
                setVoteOptionsToUpdate([]);
            }
            if (voteAnswersToAdd.length > 0) {
                await addVoteAnswer({
                    meetingId: meeting.id,
                    voteId: vote.id,
                    data: {
                        voteAnswers: voteAnswersToAdd,
                    },
                });
                setVoteAnswersToAdd([]);
            }
            if (voteAnswersToDelete.length > 0) {
                await deleteVoteAnswers({
                    meetingId: meeting.id,
                    voteId: vote.id,
                    data: {
                        userIds: voteAnswersToDelete,
                    },
                });
                setVoteAnswersToDelete([]);
            }
            setHasChanged(false);
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        } finally {
            setIsLoading(false);
        }
        await modalProps.close();
    };

    const handleUpdateVoteOptionVoteCount = ({ id, count }: { id: string; count: number }) => {
        setHasChanged(true);

        if (id === 'abstained') {
            setFormAbstainedOption({ ...formAbstainedOption, vote_count: count });
            setVoteUpdates({ ...voteUpdates, abstained_count: count });
        } else {
            setFormVoteOptions(
                [
                    ...formVoteOptions.filter((o) => o.id !== id),
                    { ...formVoteOptions.find((o) => o.id === id), vote_count: count },
                ].sort((a, b) => a.order - b.order)
            );
            setVoteOptionsToUpdate([
                ...voteOptionsToUpdate.filter((o) => o.id !== id),
                { id, changes: { vote_count: count } },
            ]);
        }
    };

    const handleUpdateVoteAnswer = ({ userId, selectedValue }: { userId: Id; selectedValue: string }) => {
        setHasChanged(true);

        let status;
        let voteOptionId = null;

        if (selectedValue === 'abstained') {
            status = 'abstained';
        } else {
            if (selectedValue != null) {
                status = 'voted';
            }
            voteOptionId = selectedValue;
        }
        setFormVoteAnswers([
            ...formVoteAnswers.filter((o) => o.user_id !== userId),
            {
                ...formVoteAnswers.find((o) => o.user_id === userId),
                user_id: userId,
                status,
                vote_option_id: voteOptionId,
            },
        ]);
        if (selectedValue != null) {
            setVoteAnswersToAdd([
                ...voteAnswersToAdd.filter((a) => a.user_id !== userId),
                {
                    user_id: userId,
                    vote_type: vote?.type,
                    status,
                    selectedOptionIds: voteOptionId != null ? [voteOptionId] : undefined,
                },
            ]);
            setVoteAnswersToDelete([...voteAnswersToDelete.filter((a) => a !== userId)]);
        } else {
            setVoteAnswersToAdd([...voteAnswersToAdd.filter((a) => a.user_id !== userId)]);
            setVoteAnswersToDelete([...voteAnswersToDelete, userId]);
        }
    };

    const getUserVoteAnswer = (userId: Id) => {
        return formVoteAnswers?.find((a) => a.user_id === userId);
    };

    const compareAnswerStatus = (meetingUserA: MeetingUser, meetingUserB: MeetingUser) => {
        const userIdA = meetingUserA.user_id;
        const userIdB = meetingUserB.user_id;
        const orders = [0, 0];
        const userVotes = [getUserVoteAnswer(userIdA), getUserVoteAnswer(userIdB)];
        for (let i = 0; i < orders.length; i++) {
            if (userVotes[i]?.user_id === userVotes[i]?.updated_by) {
                orders[i] = 3;
            } else {
                if (userVotes[i]?.status === 'voted') {
                    orders[i] = 4;
                }
                if (userVotes[i]?.status === 'requested_discussion') {
                    orders[i] = 2;
                }
                if (userVotes[i]?.status === 'abstained') {
                    orders[i] = 1;
                }
            }
        }

        return orders[0] < orders[1] ? 1 : -1;
    };

    const getVoteStatusText = (userId: Id) => {
        const userVote = vote?.voteAnswers?.find((a) => a.user_id === userId);
        if (userVote != null) {
            if (userVote.user_id === userVote.updated_by) {
                if (userVote?.status === 'voted') {
                    return t`Voted`;
                }
                if (userVote?.status === 'requested_discussion') {
                    if (userVote?.discussion != null && userVote?.discussion !== '') {
                        return (
                            <Tooltip delay={300} content={userVote?.discussion}>
                                <div className={'flex items-center gap-1'}>
                                    <FontAwesomeIcon icon={faComment} />
                                    <div className={'cursor-help underline decoration-dotted'}>
                                        <Trans>Requested discussion</Trans>
                                    </div>
                                </div>
                            </Tooltip>
                        );
                    }
                    return t`Requested discussion`;
                }
                if (userVote?.status === 'abstained') {
                    return t`Abstained`;
                }
            } else {
                const userVotedFor = meeting?.meetingUsers?.find((mu) => mu.user_id === userVote.user_id);
                const userVotedBy = meeting?.meetingUsers?.find((mu) => mu.user_id === userVote.updated_by);
                return t`${userVotedBy?.user?.full_name} voted on behalf of ${userVotedFor?.user?.full_name}`;
            }
        }
        return t`Hasn't voted yet`;
    };

    const editResultsColumns = [
        {
            title: '',
            dataIndex: 'avatar',
            align: 'center',
            render: (meetingUser: MeetingUser) => <MeetingUserAvatar user={meetingUser} size="sm" />,
        },
        {
            title: t`Voter`,
            dataIndex: 'voter',
            className: 'max-w-[200px]',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const fullNameA = a.user ? a.user.full_name : a.user_data?.external_full_name;
                const fullNameB = b.user ? b.user.full_name : b.user_data?.external_full_name;
                return a.created_at && fullNameA < fullNameB ? 1 : -1;
            },
            render: (meetingUser: MeetingUser) => (
                <div className="whitespace-nowrap">
                    <AutoTooltipText>
                        {meetingUser.user ? meetingUser.user?.full_name : meetingUser.user_data?.external_full_name}
                    </AutoTooltipText>
                </div>
            ),
        },
        {
            title: t`Status`,
            dataIndex: 'status',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                return compareAnswerStatus(a, b);
            },
            render: (meetingUser: MeetingUser) => <>{getVoteStatusText(meetingUser.user_id)}</>,
        },
        {
            title: t`Date`,
            dataIndex: 'date',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                return new Date(getUserVoteAnswer(a.user_id)?.updated_at) >
                    new Date(getUserVoteAnswer(b.user_id)?.updated_at)
                    ? 1
                    : -1;
            },
            render: (meetingUser: MeetingUser) =>
                getUserVoteAnswer(meetingUser.user_id)?.updated_at != null ? (
                    <FormatDate
                        date={new Date(getUserVoteAnswer(meetingUser.user_id)?.updated_at)}
                        format={'shortDate'}
                    />
                ) : (
                    '-'
                ),
        },
        {
            title: t`Answer`,
            dataIndex: 'answer',
            className: 'max-w-sm truncate',
            sorter: (a: MeetingUser, b: MeetingUser) =>
                getUserVoteAnswer(a.user_id)?.voteOption?.value?.localeCompare(
                    getUserVoteAnswer(b.user_id)?.voteOption?.value
                ),
            render: (meetingUser: MeetingUser) => {
                const userVoteAnswer = getUserVoteAnswer(meetingUser.user_id);
                const disabled = vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted;
                return (
                    <Select
                        disabled={disabled}
                        onChange={(value: string) =>
                            handleUpdateVoteAnswer({ userId: meetingUser.user_id, selectedValue: value })
                        }
                        placeholder={t`Choose answer`}
                        isClearable={!disabled}
                        value={
                            userVoteAnswer?.status === 'voted'
                                ? (userVoteAnswer.vote_option_id as string)
                                : userVoteAnswer?.status === 'abstained'
                                ? 'abstained'
                                : null
                        }
                    >
                        {vote?.voteOptions?.map((o) => (
                            <Select.Option key={`vote-answer-${o.id}`} value={o.id}>
                                {o.value + (o.label ? ` (${o.label})` : '')}
                            </Select.Option>
                        ))}
                        {vote?.can_abstain && (
                            <Select.Option
                                key={'vote-answer-abstained'}
                                value={'abstained'}
                            >{t`Abstained`}</Select.Option>
                        )}
                    </Select>
                );
            },
        },
    ];

    const editOptionVoteCountColumns = [
        {
            title: t`Option`,
            dataIndex: 'option',
            sorter: (a, b) => (isLinearScaleVote ? ((a.order - b.order) as number) : a.value?.localeCompare(b.value)),
            render: (option) => (
                <span>
                    {option.value}
                    {option.label && ` (${option.label})`}
                </span>
            ),
        },
        {
            title: t`Last update date`,
            dataIndex: 'last-update',
            sorter: (a, b) => (a.updated_at > b.updated_at ? 1 : -1),
            render: (option) => <FormatDate date={new Date(option.updated_at)} format={'short'} />,
        },
        {
            title: t`Votes`,
            dataIndex: 'votes',
            sorter: (a, b) => Number(a.vote_count) - Number(b.vote_count),
            render: (option) => {
                return (
                    <Input
                        type={'number'}
                        min={0}
                        disabled={vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted}
                        onChange={(e) => {
                            handleUpdateVoteOptionVoteCount({ id: option.id, count: e.target.value });
                        }}
                        value={option.vote_count || 0}
                    />
                );
            },
        },
    ];

    const confirmModalSaveProps = {
        title: t`Do you want to save your changes?`,
        type: 'primary',
    };

    const handleOk = async () => {
        if (!hasChanged) {
            await modalProps.close();
            return;
        }
        const save = await confirm(confirmModalSaveProps);
        if (save) {
            await handleSaveConfirm();
        }
    };

    const handleCancel = async () => {
        if (!hasChanged) {
            await modalProps.close();
            return;
        }
        const discard = await confirm({}, ConfirmDiscardChangesModal);
        if (discard) {
            await handleConfirmDiscardChanges();
        }
    };

    return (
        <>
            <Modal size={'lg'} {...modalProps}>
                <Modal.Header
                    title={
                        meeting?.status === 'locked' || vote?.status === 'closed' ? t`View results` : t`Edit results`
                    }
                />
                <Modal.Body>
                    <div className={'mb-4 overflow-x-auto'}>
                        {voters != null && voters.length > 0 ? (
                            <ManagedTable data={voters} columns={editResultsColumns} rowKey={(i) => i.id} />
                        ) : (
                            <ManagedTable
                                data={[
                                    ...formVoteOptions,
                                    ...(formAbstainedOption != null ? [formAbstainedOption] : []),
                                ]}
                                columns={editOptionVoteCountColumns}
                                rowKey={(i) => i.id}
                            />
                        )}
                    </div>
                    <div>
                        <Button
                            color={'danger'}
                            disabled={shouldResetResultsButtonBeDisabled}
                            onClick={async () => {
                                const applyOn = await confirm(
                                    {
                                        title: t`Which vote results do you want to reset?`,
                                        okText: t`Confirm`,
                                        cancelText: t`Cancel`,
                                        type: ModalType.Danger,
                                        customApplyOn: [
                                            { message: t`Only this vote`, value: 'this' },
                                            {
                                                message: t`This vote and other open votes in this meeting`,
                                                value: 'all',
                                            },
                                        ],
                                        showAll: false,
                                        showFuture: false,
                                        showThis: false,
                                        defaultOption: 'this',
                                    },
                                    ConfirmSaveMeetingModal
                                );
                                if (applyOn == null) {
                                    return;
                                }
                                if (applyOn === 'all') {
                                    resetMeetingVotesResults({
                                        meetingId: meeting?.id,
                                    })
                                        .unwrap()
                                        .catch((e) => {
                                            if (e?.data?.errors[0]?.message !== 'No rows deleted') {
                                                showNotification(UnexpectedErrorNotification);
                                            }
                                        });
                                    return;
                                }
                                await resetVoteResults({
                                    meetingId: meeting.id,
                                    voteId: vote.id,
                                })
                                    .unwrap()
                                    .catch(() => showNotification(UnexpectedErrorNotification));
                            }}
                        >{t`Reset results`}</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button loading={isLoading} onClick={handleCancel}>{t`Cancel`}</Button>
                    <Button color={'primary'} loading={isLoading} onClick={handleOk}>
                        {hasChanged ? t`Save` : t`Close`}
                    </Button>
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
