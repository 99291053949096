import { Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const NotionIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M84.6 476.296L27.2773 404.886C13.4855 387.704 6 366.577 6 344.831V86.2498C6 53.6774 32.0598 26.6495 65.6785 24.3542L331.536 6.20305C350.85 4.88437 370.047 10.0182 385.913 20.7451L479.321 83.8986C496.041 95.2031 506 113.683 506 133.404V427.386C506 459.263 480.376 485.655 447.46 487.681L152.389 505.844C126.122 507.461 100.733 496.395 84.6 476.296Z"
                fill="white"
            />
            <Path
                d="M176.801 215.645V212.26C176.801 203.678 183.674 196.561 192.533 195.97L257.055 191.662L346.284 323.059V207.736L323.317 204.674V203.066C323.317 194.384 330.347 187.22 339.318 186.76L398.026 183.753V192.201C398.026 196.167 395.077 199.56 391.035 200.243L376.907 202.633V389.395L359.176 395.492C344.364 400.586 327.873 395.134 319.393 382.338L232.768 251.62V376.383L259.431 381.485L259.059 383.959C257.896 391.705 251.207 397.564 243.116 397.925L176.801 400.876C175.925 392.546 182.168 385.093 190.782 384.188L199.505 383.271V216.921L176.801 215.645Z"
                fill="black"
            />
            <Path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M333.915 38.7797L68.0578 56.9308C52.1332 58.0181 39.7891 70.8207 39.7891 86.2498V344.831C39.7891 359.328 44.7794 373.413 53.9739 384.868L111.297 456.278C120.565 467.824 135.15 474.181 150.239 473.253L445.311 455.089C460.434 454.158 472.207 442.032 472.207 427.386V133.404C472.207 124.343 467.631 115.852 459.949 110.658L366.541 47.5049C357.022 41.0687 345.503 37.9885 333.915 38.7797ZM81.2411 90.2832C77.5435 87.5457 79.3033 81.8424 83.9484 81.5092L335.703 63.4518C343.724 62.8764 351.695 65.0853 358.182 69.6813L408.695 105.469C410.612 106.827 409.713 109.761 407.339 109.89L140.731 124.389C132.663 124.828 124.699 122.458 118.284 117.708L81.2411 90.2832ZM128.226 169.845C128.226 161.184 135.224 154.029 144.171 153.542L426.051 138.191C434.771 137.716 442.112 144.422 442.112 152.864V407.421C442.112 416.066 435.14 423.214 426.21 423.722L146.115 439.659C136.41 440.211 128.226 432.754 128.226 423.358V169.845Z"
                fill="black"
            />
        </Svg>
    );
};
