import React, { useEffect, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Card,
    Spinner,
    Switch,
    Tooltip,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { UpgradeToEnterpriseModal } from 'Shared/components/billing/UpgradeToEnterpriseModal';
import { DisplayToken } from 'Shared/components/settings/DisplayToken';
import { useCurrentOrganisation } from 'Shared/hooks/useCurrentOrganisation';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useGetAdminNetworkQuery, useUpdateNetworkConfigMutation } from 'Shared/services/network';
import { DevFeature } from 'Shared/types/user';

export const ScimAuthCard = () => {
    const { open } = useModal();
    const { show } = useNotification();

    const { data: network } = useGetAdminNetworkQuery();
    const [updateConfig, { isError }] = useUpdateNetworkConfigMutation();
    const [scimToggle, setScimToggle] = useState<boolean>(false);
    const [scimStrictMode, setScimStrictMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<string>(null);
    const { isEnterprise, isLoading: isSubscriptionLoading } = useCurrentOrganisation();
    const hasAllowSamlFlag = useHasFeature(null, network, DevFeature.AllowSaml);

    const samlNotEnabled = useMemo(() => !network?.config?.saml?.enable, [network]);

    useEffect(() => {
        setScimToggle(network?.config?.saml?.scim || false);
        setScimStrictMode(network?.config?.saml?.scim_strict_mode || false);
    }, [network]);

    const handleToggle = async (attribute: string, toggle: boolean) => {
        if (!hasAllowSamlFlag && !(await isEnterprise())) {
            open(UpgradeToEnterpriseModal, {
                content: (
                    <Trans>
                        To enable <b>System for Cross-domain Identity provisioning</b> in your organization you need to
                        upgrade to the enterprise subscription model
                    </Trans>
                ),
            });
        }
        setLoading(attribute);
        const res = await updateConfig({ ...network?.config?.saml, [attribute]: toggle });
        if ('error' in res) {
            show(UnexpectedErrorNotification);
        }
        setLoading(null);
    };

    return (
        <Card className="mt-6">
            <Card.Header
                className={clsx(!scimToggle && 'rounded-b-lg')}
                title={t`SCIM`}
                description={t`System for Cross-domain Identity provisioning`}
                actions={
                    <Switch.Group>
                        <div className="flex items-center">
                            {loading === 'scim' ||
                                (isSubscriptionLoading && (
                                    <Spinner className="mr-2 h-5 w-5 animate-spin fill-blue-800 text-blue-800 text-opacity-20" />
                                ))}
                            <Tooltip content={samlNotEnabled && t`Enable Single Sign-On before using SCIM`}>
                                <Switch
                                    checked={scimToggle}
                                    onChange={(value) => handleToggle('scim', value)}
                                    disabled={(loading === 'scim' && !isError) || samlNotEnabled}
                                />
                            </Tooltip>
                        </div>
                    </Switch.Group>
                }
            />

            {scimToggle && (
                <Card.Body>
                    <h4 className="mb-2 text-base font-semibold">
                        <Trans>Scim token</Trans>
                    </h4>
                    <p className="mb-1 block text-sm font-medium text-gray-700">
                        <Trans>Copy the token below and use it in your service provider</Trans>
                    </p>

                    <DisplayToken token={network?.scim_api_token} />

                    <h4 className="mb-2 mt-6 text-base font-semibold">
                        <Trans>Strict mode</Trans>
                    </h4>
                    <div className="mt-4 flex items-center gap-2">
                        <Switch.Group>
                            <Switch
                                checked={scimStrictMode}
                                onChange={(value) => handleToggle('scim_strict_mode', value)}
                                loading={loading === 'scim_strict_mode'}
                                disabled={loading === 'scim_strict_mode' && !isError}
                            />
                            <Switch.Label>
                                <Trans>
                                    Only SCIM provisioning is allowed. Adding, updating and deleting users in WEDO is
                                    not allowed.
                                </Trans>
                            </Switch.Label>
                        </Switch.Group>
                    </div>
                </Card.Body>
            )}
        </Card>
    );
};
