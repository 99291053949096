import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import React, { MouseEvent, useRef, useState } from 'react';
import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react';
import { Editor, Range } from 'slate';
import { tryOrNull, tryOrValue } from '@wedo/utils';
import { useEvent, useIsSmallScreen } from '@wedo/utils/hooks';
import { useKey } from '@wedo/utils/hooks/useKey';
import { Link } from 'Shared/components/editor/plugins/linkPlugin';
import { isInVoid, selectedBlock } from 'Shared/components/editor/utils/block';
import { is } from 'Shared/components/editor/utils/node';
import { Plugin, useEditorIsFocused } from '../Editor';
import { DividerItem, ToolItem } from './toolbarPlugin/toolbarPlugin';

/*
 * (opacity)
 * 1 |_______
 *   |       \
 *   |        \
 *   |         \
 * 0 |__________\
 *   0      50  100 (distance between the toolbar and the mouse)
 *   |---S---|-D-|
 *
 * S: FadeStaticThreshold
 * D: FadeDynamicThreshold
 */

const FadeStaticThreshold = 50;
const FadeDynamicThreshold = 50;

const Tools = [
    'bold',
    'italic',
    'underlined',
    'strikethrough',
    DividerItem,
    'aligns',
    DividerItem,
    'list',
    DividerItem,
    'text-color',
    'background-color',
    DividerItem,
    'link',
    DividerItem,
    'reset',
] as const;

const areRectsEquals = (firstRect: DOMRect, secondRect: DOMRect) => {
    return (
        firstRect != null &&
        secondRect != null &&
        firstRect.x === secondRect.x &&
        firstRect.y === secondRect.y &&
        firstRect.width === secondRect.width &&
        firstRect.height === secondRect.height
    );
};

const FloatingToolbarElement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const canFade = useRef(true);
    const floatingBoundingClientRect = useRef<DOMRect>();
    const [forceRender, setForceRender] = useState(false);

    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();

    const isSmallScreen = useIsSmallScreen();

    const { refs, floatingStyles } = useFloating({
        placement: 'top-start',
        whileElementsMounted: autoUpdate,
        // 26 seems a bit arbitrary (and it is!) it was computed to make the first floating toolbar button aligned with
        // the cursor
        middleware: [offset({ crossAxis: -26, mainAxis: 10 }), flip(), shift()],
    });

    const handleClose = () => {
        setIsOpen(false);
        canFade.current = true;
        refs.setReference(null);
    };

    const handleMouseDown = ({ target }: MouseEvent) => {
        if (refs.reference.current != null && (target as Element).closest('.floating-toolbar') == null) {
            handleClose();
        }
    };

    const handleMouseUp = ({ pageX, pageY, target }: MouseEvent) => {
        const element = target as Element;
        element.closest('[data-slate-editor="true"]')?.classList.remove('is-selecting');
        requestAnimationFrame(() => {
            const slateSelectionRect = tryOrNull(() =>
                ReactEditor.toDOMRange(editor, editor.selection).getBoundingClientRect()
            );
            const domSelectionRect = tryOrNull(() => document.getSelection().getRangeAt(0).getBoundingClientRect());
            const slateRange = tryOrNull(() =>
                ReactEditor.toSlateRange(editor, document.getSelection().getRangeAt(0), {})
            );
            const [block] = tryOrValue(() => selectedBlock(editor, slateRange) ?? [], []);
            if (
                (!isReadOnly || block?.type === 'note') &&
                editor.selection != null &&
                (Range.isExpanded(editor.selection) || document.getSelection()?.isCollapsed === false) &&
                element.closest('.floating-toolbar') == null &&
                element.closest('[data-slate-editor="true"]') != null &&
                !isInVoid(editor) &&
                !Editor.above(editor, { match: is(Link) }) &&
                areRectsEquals(slateSelectionRect, domSelectionRect)
            ) {
                const scrollableContainer = element.closest('.scrollbar-light');
                const initialScrollTop = scrollableContainer.scrollTop;
                refs.setReference({
                    getBoundingClientRect: () => {
                        const x = pageX;
                        const y = pageY - scrollableContainer.scrollTop + initialScrollTop;
                        return { x, y, height: 0, width: 0, left: x, top: y, right: x, bottom: y };
                    },
                });
                setIsOpen(true);
                requestAnimationFrame(() => {
                    if (refs.floating.current) {
                        floatingBoundingClientRect.current = refs.floating.current.getBoundingClientRect();
                    }
                });
            } else if (element.closest('.floating-toolbar') == null) {
                handleClose();
            }
        });
    };

    const handleMouseMove = ({ pageX, pageY, target, buttons }: MouseEvent) => {
        const element = target as Element;
        const editorElement = element.closest('[data-slate-editor="true"]');
        if (editorElement != null && buttons !== 0 && editor.selection != null && Range.isExpanded(editor.selection)) {
            editorElement.classList.add('is-selecting');
        }
        if (editorElement == null && element.closest('.floating-toolbar') == null) {
            handleClose();
        } else if (isOpen && canFade.current && floatingBoundingClientRect.current != null) {
            const { top, right, bottom, left } = floatingBoundingClientRect.current;
            const x = pageX < left ? left : pageX > right ? right : pageX;
            const y = pageY < top ? top : pageY > bottom ? bottom : pageY;
            const distance =
                Math.max(FadeStaticThreshold, Math.sqrt((pageX - x) ** 2 + (pageY - y) ** 2)) - FadeStaticThreshold;
            const opacity = 1 - Math.min(1, distance / (FadeStaticThreshold + FadeDynamicThreshold));
            refs.floating.current.style.opacity = opacity.toString();
            if (opacity === 0) {
                handleClose();
            }
        }
    };

    const handleEnter = () => {
        canFade.current = false;
        refs.floating.current.style.opacity = '1';
    };

    useKey('Escape', handleClose);

    useEvent('mousedown', handleMouseDown);
    useEvent('mouseup', handleMouseUp);
    useEvent('mousemove', handleMouseMove);

    return (
        !isSmallScreen &&
        isOpen && (
            <div
                ref={refs.setFloating}
                className="floating-toolbar absolute top-0 z-50 flex flex-1 items-center gap-1 rounded-md border border-gray-400 bg-white p-1 font-medium text-gray-700 shadow-md"
                style={floatingStyles}
                onMouseEnter={handleEnter}
            >
                {Tools.map((item, index) => (
                    <ToolItem
                        key={index}
                        item={item}
                        mustRender={forceRender}
                        onTrigger={() => setForceRender(!forceRender)}
                    />
                ))}
            </div>
        )
    );
};

const FocusedFloatingToolbarElement = ({ isStatic }: { isStatic: boolean }) => {
    const focused = useEditorIsFocused();
    return focused && !isStatic && <FloatingToolbarElement />;
};

export const floatingToolbarPlugin = (isStatic: boolean): Plugin => ({
    render: () => <FocusedFloatingToolbarElement key="FocusedFloatingToolbarElement" isStatic={isStatic} />,
});
