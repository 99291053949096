import { FC, PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { Card, getColorId, NavLink, type NavLinkProps } from '@wedo/design-system';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';

type GridCardHeaderProps = {
    classNameHeader?: string;
    color?: string;
    href?: NavLinkProps['to'];
    icon?: ReactNode | typeof WorkspaceIcon | typeof TeamIcon; // TODO: replace by Victor's icon
    iconColor?: string;
    title: string;
    isHoverable?: boolean;
};

export const GridCardHeader: FC<PropsWithChildren & GridCardHeaderProps> = ({
    classNameHeader = '',
    children,
    color,
    href,
    icon,
    iconColor,
    title,
    isHoverable = false,
}) => {
    const titleTag = <GridCard.Title color={iconColor ?? color ?? '#000000'} icon={icon} title={title} />;

    return (
        <>
            {color && <div className={`h-1 rounded-t-[0.45rem] bg-gradient-${getColorId(color).toString()}`} />}

            <Card.Header
                className={clsx(
                    classNameHeader,
                    isHoverable && 'group-hover/grid-card:bg-gray-100 group-hover/grid-card:shadow-md'
                )}
                title={href ? <NavLink to={href}>{titleTag}</NavLink> : titleTag}
                actions={children}
            />
        </>
    );
};
