import { Id } from '@wedo/types';
import { configureTag } from 'Shared/services/base';

// Created this file to avoid circular dependency

export const tagList: Id = 'list';

export const { tagType, tag, tags } = configureTag('Attachment');
export const tagDetails: Id = 'details-';
export const folderList: Id = 'folder';
export const tagVersions: Id = 'versions';
