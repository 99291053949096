import React, { useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { faArrowUpRightFromSquare, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    Alert,
    Button,
    Checkbox,
    Input,
    ItemGroup,
    Label,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { EmptyString, onEnter } from '@wedo/utils';
import { useNavigate, useInputState } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { PlanPreview } from 'Pages/subscription/components/ChoosePlan/PlanPreview';
import { useSubscriptionAddressStore } from 'Pages/subscription/hooks/useSubscriptionAddressStore';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import { SUBSCRIPTION_ADDON_STEP_URL, SUBSCRIPTION_PLAN_STEP_URL } from 'Pages/subscription/utils';
import { PaymentOptionRadioGroup } from 'Shared/components/billing/PaymentOptionRadioGroup';
import { useChargebee } from 'Shared/hooks/useChargebee';
import { useFormattedCurrency } from 'Shared/hooks/useFormattedCurrency';
import { invalidateCurrentNetwork } from 'Shared/services/network';
import {
    useApplyCouponCodeMutation,
    useCreateSubscriptionMutation,
    useComputeSubscriptionEstimateMutation,
    useUpdateOrganizationMutation,
    useUpdateSubscriptionMutation,
    useGetExistingSubscriptionHostedPageQuery,
    useGetNewSubscriptionHostedPageQuery,
    useRefreshOrganizationStatusMutation,
} from 'Shared/services/organization';
import { trpc, trpcUtils } from 'Shared/trpc';
import { getSubscriptionData } from 'Shared/utils/chargebee';

export const ConfirmSubscriptionForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { getFormattedCurrency } = useFormattedCurrency();
    const { userLanguage } = useCurrentUserContext();
    const { chargebee } = useChargebee();
    const { show } = useNotification();
    const {
        hasExistingSubscription,
        estimateParam,
        estimate,
        noChangeInPlan,
        userId,
        subscription,
        addCouponCode: applyCoupon,
        reset,
    } = useSubscriptionPage();
    const { country } = useSubscriptionAddressStore();
    const { mutateAsync: refreshAddons } = trpc.subscription.refreshAddons.useMutation({
        onSuccess: () => {
            dispatch(invalidateCurrentNetwork());
            void trpcUtils().userLicense.invalidate();
            void trpcUtils().subscription.get.invalidate();
        },
    });
    const {
        agree,
        currency,
        userLicenses,
        lightUserLicenses,
        planType,
        couponCodes,
        frequency,
        onboardingCompleted,
        paymentMethod,
        signatureAddon,
        governanceLicenses,
        source,
        actions: { setOnboardingCompleted, setPaymentMethod, setAgree },
    } = useSubscriptionStore();

    const subscriptionData = getSubscriptionData({
        plan: planType,
        frequency,
        currency,
        totalUsers: userLicenses,
        lightUsers: lightUserLicenses,
        signatureAddon,
        governanceLicenses,
        couponCode:
            subscription == null || subscription?.coupons?.[0].coupon_code !== couponCodes?.[0]
                ? couponCodes?.[0]
                : undefined,
    });

    const { data: existingCheckoutConfig, isLoading: isLoadingExistingSubscriptionHostedPage } =
        useGetExistingSubscriptionHostedPageQuery(
            { subscription: { id: subscription?.id }, ...subscriptionData },
            { skip: !hasExistingSubscription }
        );

    const { data: newCheckoutConfig, isLoading: isLoadingNewSubscriptionHostedPage } =
        useGetNewSubscriptionHostedPageQuery(subscriptionData, { skip: hasExistingSubscription });

    const [applyCouponCode, { isLoading: isLoadingApplyCouponCode }] = useApplyCouponCodeMutation();
    const [computeSubscriptionEstimate, { isLoading: isLoadingGetSubscriptionEstimate }] =
        useComputeSubscriptionEstimateMutation();
    const [updateOrganization, { isLoading: isUpdatingOrganization }] = useUpdateOrganizationMutation();
    const [createSubscription, { isLoading: isCreatingSubscription }] = useCreateSubscriptionMutation();
    const [updateSubscription, { isLoading: isUpdatingSubscription }] = useUpdateSubscriptionMutation();
    const [refreshOrganizationStatus, { isLoading: isRefreshingOrganizationStatus }] =
        useRefreshOrganizationStatusMutation();

    const isLoadingSubscribe =
        isUpdatingOrganization ||
        isCreatingSubscription ||
        isUpdatingSubscription ||
        isLoadingExistingSubscriptionHostedPage ||
        isLoadingNewSubscriptionHostedPage ||
        isRefreshingOrganizationStatus;

    const couponCodeInput = useRef<HTMLInputElement>();

    const [coupon, setCoupon, handleCoupon] = useInputState(EmptyString);
    const [isAddingCouponCode, setIsAddingCouponCode] = useState<boolean>(false);
    const [isInvalidCouponCode, setIsInvalidCouponCode] = useState<boolean>(false);

    const handlePrevious = () => {
        if (source === 'manage_subscription') {
            navigate({ pathname: SUBSCRIPTION_PLAN_STEP_URL, searchParams: { 'user-id': userId } });
        } else {
            navigate({ pathname: SUBSCRIPTION_ADDON_STEP_URL, searchParams: { 'user-id': userId } });
        }
    };

    const handleApplyCoupon = async () => {
        setIsInvalidCouponCode(false);
        const response = await applyCouponCode(coupon);
        if ('data' in response && response.data.status === 'invalid') {
            setIsInvalidCouponCode(true);
        } else if ('data' in response && response.data?.status === 'valid') {
            const estimate = await computeSubscriptionEstimate({
                ...estimateParam,
                coupon_ids: [coupon],
            });
            if ('error' in estimate) {
                setIsInvalidCouponCode(true);
            } else if ('data' in estimate) {
                applyCoupon(coupon);
                setIsAddingCouponCode(false);
            }
        }
    };

    const handleUpdateOrganization = async (paymentMethod: 'invoice' | 'card') => {
        await updateOrganization({
            cf_is_payment_slip_ch: paymentMethod === 'invoice' ? 'yes' : 'no',
            net_term_days: paymentMethod === 'invoice' ? 30 : 0,
        });
    };

    const handleInvoicePayment = async () => {
        if (!hasExistingSubscription) {
            await handleUpdateOrganization('invoice');
        }

        const data = {
            ...subscriptionData,
            coupon_ids: couponCodes,
            auto_collection: 'off',
            replace_coupon_list: true,
        };

        if (hasExistingSubscription) {
            const response = await updateSubscription(data);
            if ('error' in response) {
                show(UnexpectedErrorNotification);
            } else {
                setOnboardingCompleted(true);
                void refreshAddons();
                return;
            }
        }

        const response = await createSubscription(data);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            setOnboardingCompleted(true);
            void refreshAddons();
        }
    };

    const handleCardPayment = async () => {
        if (!hasExistingSubscription) {
            await handleUpdateOrganization('card');
            void refreshAddons();
        }

        chargebee.openCheckout({
            hostedPage: () => {
                if (hasExistingSubscription) {
                    return Promise.resolve(existingCheckoutConfig);
                }
                return Promise.resolve(newCheckoutConfig);
            },
            error: () => show(UnexpectedErrorNotification),
            success: async () => {
                const response = await refreshOrganizationStatus();
                setOnboardingCompleted(true);
                if ('data' in response) {
                    chargebee.closeAll();
                }
                void refreshAddons();
            },
        });
    };

    const handleFinishPayment = () => {
        if (hasExistingSubscription) {
            navigate('/settings/billing');
        } else {
            navigate('/');
        }
        reset();
    };

    const handleSubscribe = () => {
        if (paymentMethod === 'invoice') {
            void handleInvoicePayment();
        } else {
            void handleCardPayment();
        }
    };

    if (onboardingCompleted) {
        return (
            <div className={'flex flex-col items-center justify-center gap-4'}>
                <div className={'flex flex-col items-center justify-center'}>
                    <ConfettiExplosion />
                    <img src="/assets/welcome.svg" alt="" />
                </div>
                <div className="mt-2 text-2xl font-bold text-green-500">
                    <Trans>Welcome to WEDO</Trans>
                </div>
                <Button
                    iconPosition={'end'}
                    color={'success'}
                    onClick={handleFinishPayment}
                    icon={faArrowUpRightFromSquare}
                >{t`Go to WEDO`}</Button>
            </div>
        );
    }

    return (
        <div className={'@container'}>
            <h1 className="mb-10 text-3xl">
                <Trans>Confirm your subscription</Trans>
            </h1>

            <PlanPreview totalTitle={t`Total due today`} />

            <div className="mt-1">
                {!isAddingCouponCode && couponCodes?.length === 0 && (
                    <Button
                        size="md"
                        variant="text"
                        color="success"
                        onClick={() => {
                            setCoupon(EmptyString);
                            setIsAddingCouponCode(true);
                            setTimeout(() => couponCodeInput?.current?.focus(), 50);
                        }}
                    >
                        <Trans>I have a coupon</Trans>
                    </Button>
                )}

                {isAddingCouponCode && (
                    <div className="mt-4 w-full rounded-md border bg-white p-4 shadow-sm">
                        <div className="flex items-center justify-between">
                            <Label>
                                <Trans>Add coupon code</Trans>
                            </Label>
                            <Button
                                icon={faTimes}
                                color="danger"
                                variant="text"
                                shape="circle"
                                onClick={() => {
                                    setIsAddingCouponCode(false);
                                    setCoupon(EmptyString);
                                    setIsInvalidCouponCode(false);
                                }}
                            />
                        </div>
                        <ItemGroup
                            status={isInvalidCouponCode ? 'error' : 'default'}
                            statusText={isInvalidCouponCode && t`Invalid coupon code`}
                        >
                            <Input
                                ref={couponCodeInput}
                                value={coupon}
                                onChange={handleCoupon}
                                placeholder={t`Code`}
                                onKeyDown={onEnter(handleApplyCoupon)}
                            />
                            <Button
                                color="primary"
                                onClick={handleApplyCoupon}
                                loading={isLoadingApplyCouponCode || isLoadingGetSubscriptionEstimate}
                            >
                                <Trans>Apply</Trans>
                            </Button>
                        </ItemGroup>
                    </div>
                )}
            </div>

            {!hasExistingSubscription && country === 'CH' && (
                <>
                    <h2 className="mt-8 text-2xl">
                        <Trans>Payment method</Trans>
                    </h2>

                    <PaymentOptionRadioGroup value={paymentMethod} onChange={setPaymentMethod} />
                </>
            )}

            <div className="mt-10 flex flex-col gap-4">
                {estimate?.credit_note_estimates?.length > 0 && (
                    <Alert type={'warning'} title={t`Some unpaid invoices have been modified.`}>
                        <div className="text-xs text-gray-700">
                            {estimate?.credit_note_estimates?.map((creditNote) => (
                                <span key={creditNote.reference_invoice_id}>
                                    <Trans>
                                        Adjustment credits worth{' '}
                                        <span className="font-semibold">
                                            {getFormattedCurrency(creditNote.total, currency)}
                                        </span>{' '}
                                        will be created and adjusted against invoice{' '}
                                        <span className="font-semibold">{creditNote.reference_invoice_id}</span>.
                                    </Trans>
                                </span>
                            ))}
                        </div>
                    </Alert>
                )}

                {hasExistingSubscription && (
                    <span className="text-xs text-gray-500">
                        <Trans>
                            You will be charged{' '}
                            {getFormattedCurrency(estimate?.invoice_estimate?.amount_due ?? 0, currency)} for the
                            changes to your plan, prorated for your current billing period. At your next renewal date,
                            your new subscription total will be{' '}
                            {getFormattedCurrency(estimate?.next_renewal_estimate?.amount_due, currency)}.
                        </Trans>
                    </span>
                )}

                <div className="flex items-center gap-2">
                    <Checkbox checked={agree} onChange={(e) => setAgree(e.target.checked)} id="i-agree" />
                    <Label htmlFor="i-agree" className="translate-y-0.5 cursor-pointer select-none text-base">
                        <Trans>
                            I have read and I agree to the{' '}
                            <Button
                                variant="link"
                                color="primary"
                                href={`https://wedo.com/${userLanguage}/terms`}
                                target="_blank"
                                className="text-base"
                            >
                                subscriber agreement
                            </Button>
                        </Trans>
                    </Label>
                </div>
            </div>

            <div className="mt-10 flex flex-wrap justify-between gap-2">
                <Button className={'@md:w-auto w-full'} icon={faChevronLeft} onClick={handlePrevious}>
                    <Trans>Previous</Trans>
                </Button>
                <Button
                    className={'@md:w-auto w-full'}
                    icon={faChevronRight}
                    iconPosition="end"
                    color="primary"
                    disabled={!agree || noChangeInPlan}
                    onClick={handleSubscribe}
                    loading={isLoadingSubscribe}
                >
                    {hasExistingSubscription ? (
                        <Trans>Upgrade</Trans>
                    ) : (
                        <Trans>
                            Subscribe for {getFormattedCurrency(estimate?.invoice_estimate.amount_due, currency)}
                        </Trans>
                    )}
                </Button>
            </div>
        </div>
    );
};
