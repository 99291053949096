import { useNavigate } from 'react-router-dom';
import { useModal } from '@wedo/design-system';
import { WorkspaceSettingsModal } from 'Shared/components/workspace/WorkspaceSettingsModal/WorkspaceSettingsModal';
import { Notification } from 'Shared/types/notification';

export const useWorkspaceAction = () => {
    const { open } = useModal();
    const navigate = useNavigate();

    return (notification: Notification) => {
        switch (notification.event_key) {
            case 'commented_tag':
            case 'deleted_comment_tag':
            case 'updated_comment_tag':
                navigate(`/workspaces/${notification.object_id}/feed`);
                break;
            default:
                open(WorkspaceSettingsModal, { workspaceId: notification.object_id, defaultIndex: 1 });
        }
    };
};
