import { useContext } from 'react';
import { t } from '@lingui/macro';
import { useChangeStage } from 'Pages/settings/security/hooks/useChangeStage';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { ResetError } from 'Pages/settings/security/utils/type';
import { useCheckPasswordMutation } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

type PasswordVerifyReturn = {
    passwordInit: string;
    check: (password: string) => Promise<void>;
    isLoading: boolean;
};

export const usePasswordVerify = (): PasswordVerifyReturn => {
    const { state, dispatch } = useContext(TotpContext);
    const { next } = useChangeStage();
    const [checkPassword, { isLoading }] = useCheckPasswordMutation();

    const check = async (password: string): Promise<void> => {
        dispatch({ type: ResetError });
        dispatch({ type: 'password', value: password });

        const result = await checkPassword(password);

        if ('data' in result && result.data !== undefined) {
            next();
        } else if ('error' in result) {
            let error: string;

            switch (errorMessage(result.error)) {
                case 'Password wrong':
                    error = t`Invalid password`;
                    break;
                case 'TypeError: Failed to fetch':
                    error = t`Network error`;
                    break;
                default:
                    error = t`Unexpected error`;
            }

            dispatch({ type: 'error', value: error });
        }
    };

    return { passwordInit: state.password, check, isLoading };
};
