import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Modal } from '@wedo/design-system';

export const MaxUsersReachedModal: FC<ContextModalProps> = ({ ...modalProps }) => {
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Max users reached`} />
            <Modal.Body>
                <Alert type="warning" title={t`You reached the maximum of users of your subscription.`} />
                <div className="flex justify-center"></div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" href="/settings/billing" onClick={modalProps.close}>
                    <Trans>Upgrade your plan</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
