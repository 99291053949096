import React, { FC, useMemo } from 'react';
import { Id } from '@wedo/types';
import { EmailToTaskButton } from 'Shared/components/settings/EmailToTaskButton';
import { useGetSurrogateKeyQuery } from 'Shared/services/surrogateKey';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';

type WorkspaceEmailToTaskProps = {
    workspaceId: Id;
    className?: string;
};

export const WorkspaceEmailToTask: FC<WorkspaceEmailToTaskProps> = ({ workspaceId, className }) => {
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);
    const {
        data: workspaceSurrogateKey,
        isLoading,
        isError,
    } = useGetSurrogateKeyQuery({ id: workspace.id, property: 'workspace_id' });

    const email = useMemo<string>(
        () => `"${workspace.name}" <${workspaceSurrogateKey?.key}@mail.wedo.app>`,
        [workspace?.network_id, workspaceId, workspaceSurrogateKey?.key]
    );

    return <EmailToTaskButton disabled={isLoading || isError} email={email} className={className} />;
};
