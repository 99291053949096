import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Tag, Tooltip } from '@wedo/design-system';

export const AddonTag = () => {
    return (
        <Tooltip
            delay={300}
            content={t`Once your trial is finished, you will have to buy the corresponding add-on to continue using this feature`}
        >
            <Tag size="xs" color="purple">
                <Trans>Add-on</Trans>
            </Tag>
        </Tooltip>
    );
};
