import React, { FC, ReactNode } from 'react';
import { faQuestion } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Avatar, type AvatarSizes, colorPickerColorsMap, Tooltip, TooltipProps } from '@wedo/design-system';
import { User } from 'Shared/types/user';

type UserAvatarProps = {
    user: Pick<User, 'photo' | 'initials' | 'full_name' | 'color' | 'id'>;
    size?: AvatarSizes;
    showBorder?: boolean;
    showTooltip?: boolean;
    onRemove?: () => void;
    removeButtonLabel?: string;
    className?: string;
    wrapperClassName?: string;
    isRounded?: boolean;
    tooltipPlacement?: TooltipProps['placement'];
    tooltipContent?: ReactNode;
    isStacked?: boolean;
};

export const UserAvatar: FC<UserAvatarProps> = ({
    user,
    size = 'md',
    showTooltip = true,
    showBorder = false,
    onRemove,
    removeButtonLabel,
    className,
    wrapperClassName,
    isRounded = true,
    tooltipPlacement = 'top',
    tooltipContent,
    isStacked,
}) => {
    const computedTooltipContent = tooltipContent ?? user?.full_name;

    return (
        <Tooltip
            content={showTooltip && computedTooltipContent}
            wrapperClassName="flex-shrink-0"
            placement={tooltipPlacement}
        >
            <Avatar
                className={clsx(showBorder && 'border border-gray-300 p-1', className)}
                wrapperClassName={wrapperClassName}
                img={user?.photo != null ? `/files/${user?.photo}` : undefined}
                clearButtonLabel={removeButtonLabel}
                initials={user?.initials}
                icon={user?.id === 'unknown' ? faQuestion : undefined}
                size={size}
                style={
                    user?.photo
                        ? {}
                        : user?.id !== 'unknown'
                        ? { background: user?.color?.background, color: user?.color?.text }
                        : { background: colorPickerColorsMap.gray.value, color: '#FFF' }
                }
                onClear={onRemove}
                isRounded={isRounded}
                isStacked={isStacked}
            />
        </Tooltip>
    );
};
