import clsx from 'clsx';

export const getTagOptionClasses = ({
    selected = false,
    active = false,
}: {
    selected: boolean;
    active: boolean;
}): string =>
    clsx(
        'mx-1 mt-1 cursor-pointer select-none rounded-md px-2 py-1 text-sm',
        active && !selected && 'bg-hover cursor-pointer !bg-gray-50',
        selected && active && 'bg-blue-200 text-gray-800',
        selected && !active && 'bg-blue-100 text-blue-700'
    );
