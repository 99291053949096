import { KeyboardEvent } from 'react';

export const onKeys =
    <T>(codes: string[]) =>
    (handler: (event?: KeyboardEvent<T>) => void) =>
    (event: KeyboardEvent<T>) => {
        if (codes.includes(event.code)) {
            handler(event);
        }
    };

export const onSpace = onKeys(['Space']);

export const onEnter = onKeys(['Enter', 'NumpadEnter']);

export const onEsc = onKeys(['Escape']);

export const onBackSpace = onKeys(['Backspace']);

export const onArrowLeft = onKeys(['ArrowLeft']);

export const onArrowRight = onKeys(['ArrowRight']);

export const onArrowY = onKeys(['ArrowUp', 'ArrowDown']);
