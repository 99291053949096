import React from 'react';
import { Trans } from '@lingui/macro';

export const IncludeSomeItemsFilterLabel = () => (
    <span className="mt-1 shrink-0 rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">
        <Trans>
            Include items that match{' '}
            <span className="text-gray-900">
                <Trans>some filters</Trans>
            </span>
        </Trans>
    </span>
);
