export const formatCurrency = ({
    value,
    currency = 'CHF',
    locale = 'en-US',
}: {
    value: number;
    currency?: string;
    locale?: string;
}): string => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value / 100);
};
