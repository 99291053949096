import React, { FC, ReactNode } from 'react';
import { t } from '@lingui/macro';
import { Dropdown, Popover } from '@wedo/design-system';
import { EntityStatus, EntityStatuses } from 'Shared/types/workspace';

type StatusRowProps = {
    status: EntityStatus;
    onStatusChange: (status: EntityStatus) => void;
    statusToText: Record<EntityStatus, ReactNode>;
    allowedStatus?: EntityStatus[];
};

export const StatusRow: FC<StatusRowProps> = ({ status, onStatusChange, statusToText, allowedStatus }) => {
    return (
        <Popover text={t`Status`} placement="bottom" active={status !== 'open'} variant="text" icon={'status'}>
            <div className="flex flex-col bg-white p-1 gap-1">
                {EntityStatuses.filter((item) => !allowedStatus || allowedStatus.includes(item)).map((statusCode) => (
                    <Dropdown.Item
                        key={statusCode}
                        selected={status === statusCode}
                        onClick={() => onStatusChange(statusCode)}
                    >
                        {statusToText[statusCode]}
                    </Dropdown.Item>
                ))}
            </div>
        </Popover>
    );
};
