import React from 'react';
import { t } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { Button, ContextModalProps, Modal, Skeleton } from '@wedo/design-system';
import {
    getCircleActivityIcon,
    getCircleActivityIconColor,
    getCircleActivityText,
    isCircleActivityImportant,
} from 'Pages/governance/CircleActivityLogsValues';
import { ActivitiesTable } from 'Shared/components/activity/ActivitiesTable/ActivitiesTable';
import { useFetchCircleActivitiesQuery } from 'Shared/services/governance';
import { Circle } from 'Shared/types/governance';

type CircleHistoryModalProps = {
    circle: HierarchyCircularNode<Circle>;
} & ContextModalProps;

export const CircleHistoryModal = ({ circle, ...modalProps }: CircleHistoryModalProps) => {
    const { data: activities, isLoading: isLoadingActivities } = useFetchCircleActivitiesQuery({ id: circle.id });

    return (
        <Modal size={'lg'} {...modalProps}>
            <Modal.Header title={t`${circle.data.name} history`} />
            <Modal.Body>
                {isLoadingActivities ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-2" />
                    </div>
                ) : (
                    <ActivitiesTable
                        activities={activities}
                        getIcon={getCircleActivityIcon}
                        getText={(param) => getCircleActivityText(param.activity)}
                        isImportant={isCircleActivityImportant}
                        getIconColor={getCircleActivityIconColor}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
