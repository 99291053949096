import { HttpMethod, Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { tags as attachmentTags } from 'Shared/services/attachmentTags';
import { tagType as MeetingTopicTagType } from 'Shared/services/meetingTopic';
import { tag as taskTag, taskTagType } from 'Shared/services/task';
import { MeetingBlock } from '../types/meetingBlock';
import { baseApi, configureTag, resourceId } from './base';

export const {
    tagType: meetingBlockTagType,
    tag: meetingBlockTag,
    tags: meetingBlockTags,
} = configureTag('MeetingBlock');

export enum ApplyOn {
    ThisTopic = 'topic',
    AllTopics = 'all',
}

export type GetMeetingBlocksParams = {
    search?: string;
    searchType?: string;
    meetingId?: Id;
    topicId?: Id;
    tagIds?: Id[];
    filter?: string;
    orderBy?: string;
    related?: string[];
    types?: string[];
    offset?: number;
    limit?: number;
};

type UpdateMeetingTopicBlocksParams = {
    meetingId: Id;
    topicId: Id;
    addedBlocks?: Partial<MeetingBlock>[];
    updatedBlocks?: { id: Id; changes: Partial<MeetingBlock> }[];
    deletedBlocks?: Id[];
};

type ImportMeetingTopicBlocksParams = {
    meetingId: Id;
    topicId: Id;
    blockTypes: string[];
    applyOn: ApplyOn.AllTopics | ApplyOn.ThisTopic;
};

export const meetingBlockApi = baseApi
    .enhanceEndpoints({
        //TODO revert this and fix the uncaught error
        // addTagTypes: [tagType, TaskTagType, AttachmentTagType],
        addTagTypes: [meetingBlockTagType, taskTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMeetingBlocks: build.query<MeetingBlock[], GetMeetingBlocksParams>({
                query: (params) => ({ url: 'blocks', params }),
                providesTags: (result, error, { topicId, related }) =>
                    [
                        ...meetingBlockTags(result),
                        ...attachmentTags(
                            result
                                ?.filter((item) => item.type === 'attachment' && item.attachments)
                                .map((item) => item.attachments)
                                .flat()
                        ),
                        meetingBlockTag(resourceId(MeetingTopicTagType, topicId)),
                        (related || []).includes('task') ? taskTag(resourceId(meetingBlockTagType, topicId)) : null,
                    ].filter(Boolean),
            }),

            getMeetingBlock: build.query<MeetingBlock, Id>({
                query: (id) => ({ url: `blocks/${id}` }),
                providesTags: (result) => [meetingBlockTag(result?.id)],
            }),

            updateMeetingTopicBlocks: build.mutation<MeetingBlock[], UpdateMeetingTopicBlocksParams>({
                query: ({
                    topicId,
                    addedBlocks = EmptyArray,
                    updatedBlocks = EmptyArray,
                    deletedBlocks = EmptyArray,
                }) => ({
                    url: `topics/${topicId}/blocks`,
                    body: { addedBlocks, updatedBlocks, deletedBlocks },
                    method: HttpMethod.Put,
                }),
            }),

            importMeetingTopicBlocks: build.mutation<MeetingBlock[], ImportMeetingTopicBlocksParams>({
                query: ({ meetingId, topicId, blockTypes, applyOn }) => ({
                    url: `meetings/${meetingId}/topics/${topicId}/import-blocks`,
                    body: { blockTypes, applyOn, related: ['meetingBlocks'] },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { topicId }) => [
                    meetingBlockTag(resourceId(MeetingTopicTagType, topicId)),
                ],
            }),
        }),
    });

export const { useGetMeetingBlocksQuery, useUpdateMeetingTopicBlocksMutation, useImportMeetingTopicBlocksMutation } =
    meetingBlockApi;

export const UpdateMeetingTopicBlocksFixedCacheKey = 'update-meeting-topic-blocks-fixed-cache-key';

export const invalidateMeetingTopicBlocks = (topicId: Id) =>
    meetingBlockApi.util.invalidateTags([meetingBlockTag(resourceId(MeetingTopicTagType, topicId))]);
