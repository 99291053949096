import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Id } from '@wedo/types';
import { ShortLanguageCode } from '@wedo/types';
import { storage } from '@wedo/utils';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';
import { useGetCurrentUserQuery } from 'Shared/services/user';
import { LocalStorage } from 'Shared/types/localStorage';
import { User, UserRole } from 'Shared/types/user';
import { can, Permission } from 'Shared/utils/rbac';

type CurrentUserContextType = {
    currentUser: User | undefined;
    currentUserId: Id | undefined;
    isLoading: boolean;
    userLanguage: ShortLanguageCode;
    isAdmin: boolean;
    isUserCurrentUser: ({ user_id }: { user_id: Id }) => boolean;
    unreadIntercomMessageCount: number;
    setUnreadIntercomMessageCount: (unreadCount: number) => void;
    can: (permission: Permission) => boolean;
};

const CurrentUserContext = createContext<CurrentUserContextType>({
    currentUser: null,
    currentUserId: null,
    isLoading: null,
    userLanguage: null,
    isAdmin: null,
    isUserCurrentUser: () => false,
    unreadIntercomMessageCount: 0,
    setUnreadIntercomMessageCount: () => undefined,
    can: () => undefined,
});

export const useCurrentUserContext = (): CurrentUserContextType => useContext(CurrentUserContext);

type CurrentUserContextProviderProps = {
    initialCurrentUser?: User;
    children: ReactNode;
};

export const CurrentUserContextProvider: React.FC<CurrentUserContextProviderProps> = ({
    initialCurrentUser,
    children,
}) => {
    const { setUserLanguage } = useUserLanguage();

    const { data: currentUser = initialCurrentUser, isLoading } = useGetCurrentUserQuery(null, {
        skip: initialCurrentUser !== undefined,
    });
    const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

    useEffect(() => {
        if (currentUser?.language_code && currentUser.language_code !== storage.getItem(LocalStorage.Language)) {
            setUserLanguage(currentUser?.language_code);
        }
    }, [currentUser?.language_code]);

    const currentUserId = useMemo(() => currentUser?.id, [currentUser]);

    const userLanguage = useMemo<ShortLanguageCode>(
        () => currentUser?.language_code?.substring(0, 2) as ShortLanguageCode,
        [currentUser]
    );

    const isAdmin = useMemo(() => currentUser?.role === UserRole.ADMIN, [currentUser]);

    const isUserCurrentUser = ({ user_id }: { user_id: Id }): boolean => user_id === currentUserId;

    const canUser = useCallback(
        (permission: Permission) => {
            return can(currentUser?.role, permission);
        },
        [currentUser]
    );

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser,
                currentUserId,
                isLoading,
                userLanguage,
                isUserCurrentUser,
                isAdmin,
                unreadIntercomMessageCount: unreadMessagesCount,
                setUnreadIntercomMessageCount: setUnreadMessagesCount,
                can: canUser,
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
};
