import { FC } from 'react';
import { plural } from '@lingui/macro';
import { ActivityLog } from '@wedo/db';
import { ChargebeeSubscription, ChargebeeSubscriptionItem } from '@wedo/types';
import { ActivityLogObject } from 'Shared/types/activityLog';
import { getPlanTypeFromPlanId, isLightUserAddonId } from 'Shared/utils/chargebee';

const subscriptionItemToString = (subscriptionItem: ChargebeeSubscriptionItem) => {
    if (isLightUserAddonId(subscriptionItem.item_price_id)) {
        return `${subscriptionItem.quantity} ${plural(subscriptionItem.quantity, { one: 'light user', other: 'light users' })}`;
    }
    return `${subscriptionItem.quantity} ${getPlanTypeFromPlanId(subscriptionItem.item_price_id)} ${plural(subscriptionItem.quantity, { one: 'user', other: 'users' })}`;
};

const subscriptionItemsToString = (subscriptionItems: Array<ChargebeeSubscriptionItem>) => {
    return subscriptionItems.map((subscriptionItem) => subscriptionItemToString(subscriptionItem)).join(', ');
};

export const OrganizationModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    if (auditLog.objectType !== ActivityLogObject.ORGANIZATION) {
        return null;
    }

    if (auditLog.eventKey === 'updated_subscription') {
        return (
            <div className="text-xs w-full truncate font-semibold">
                {subscriptionItemsToString((auditLog.oldValue as ChargebeeSubscription).subscription_items)} {' --> '}
                {subscriptionItemsToString((auditLog.newValue as ChargebeeSubscription).subscription_items)}
            </div>
        );
    }

    if (auditLog.eventKey === 'created_subscription') {
        return (
            <div className="text-xs font-semibold">
                {subscriptionItemsToString((auditLog.newValue as ChargebeeSubscription).subscription_items)}
            </div>
        );
    }

    return null;
};
