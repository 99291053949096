import { msg } from '@lingui/macro';
import { formatDuration, intlFormat, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { de, enGB, enUS, fr, frCH, it, itCH } from 'date-fns/locale';
import { type Meeting } from './types';

const multiDayFormat = 'd MMMM yyyy';
const timeFormat = 'HH:mm';

export const numericCompare = new Intl.Collator(undefined, { numeric: true }).compare;

export const getDateFnsLocale = (locale: string): Locale => {
    switch (locale.toLowerCase()) {
        case 'fr':
        case 'fr-fr':
            return fr;
        case 'fr-ch':
            return frCH;
        case 'en':
        case 'en-gb':
            return enGB;
        case 'de':
        case 'de-de':
        case 'de-ch':
            return de;
        case 'it-ch':
            return itCH;
        case 'it':
        case 'it-it':
            return it;
        default:
            return enUS;
    }
};

export const formatMeetingDateTime = (meeting: Partial<Meeting>, locale: string, userTimeZone?: string) => {
    const startAt = new Date(meeting.start_at);
    const endAt = new Date(meeting.end_at);
    const startAtTimeZone = userTimeZone ?? meeting.start_at_time_zone;
    const endAtTimeZone = userTimeZone ?? meeting.end_at_time_zone;
    if (isSameDay(startAt, endAt)) {
        return `${intlFormat(startAt, { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', timeZone: startAtTimeZone }, { locale })} • ${formatInTimeZone(startAt, startAtTimeZone, timeFormat)} - ${formatInTimeZone(endAt, endAtTimeZone, timeFormat)}`;
    }
    return `${formatInTimeZone(startAt, startAtTimeZone, multiDayFormat)} • ${formatInTimeZone(startAt, startAtTimeZone, timeFormat)} - ${formatInTimeZone(endAt, endAtTimeZone, multiDayFormat)} • ${formatInTimeZone(endAt, endAtTimeZone, timeFormat)}`;
};

export const formatMeetingTitle = (meeting: Partial<Meeting>, locale: string, userTimeZone?: string) => {
    const startAt = new Date(meeting.start_at);
    const startAtTimeZone = userTimeZone ?? meeting.start_at_time_zone;
    return msg`${meeting.title} on ${intlFormat(startAt, { day: 'numeric', month: 'short', year: 'numeric', timeZone: startAtTimeZone }, { locale })}`;
};

export const formatDurationWithLocale = (duration: Duration, locale: string) => {
    return formatDuration(duration, {
        locale: getDateFnsLocale(locale),
        format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
    });
};
