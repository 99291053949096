import { useState } from 'react';
import { startsWith } from 'lodash-es';
import { NotificationContext, NotificationData } from './NotificationContext';

let currentId = 1;

export const NotificationContextProvider = ({ children }) => {
    const [notifications, setNotifications] = useState<NotificationData[]>([]);
    const value = {
        notifications,
        add: (notification) => {
            setNotifications([...notifications, { id: currentId++, delay: 5000, ...notification }]);
        },
        remove: (id: string) => {
            setNotifications(notifications.filter((notification) => notification.id !== id));
        },
        replace: (idPrefix: string, notification) => {
            setNotifications([
                ...(notifications || []).filter((notification) => !startsWith(notification.id, idPrefix)),
                { delay: 5000, ...notification, id: notification.id + '-' + currentId++ },
            ]);
        },
    };

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
