import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { InputStatus } from '~/components/Input/Input';

const classes = {
    base: 'text-sm text-gray-600',
    error: 'text-red-600',
    success: 'text-green-600',
};

export interface HelperTextProps extends HTMLAttributes<HTMLParagraphElement> {
    children?: React.ReactNode;
    status?: InputStatus;
}

export const HelperText = ({ children, className, status = 'default', ...props }: HelperTextProps): JSX.Element => {
    return (
        <p
            className={clsx(
                classes.base,
                status === 'error' && classes.error,
                status === 'success' && classes.success,
                className
            )}
            {...props}
        >
            {children ?? ''}
        </p>
    );
};
