import React, { useEffect, useRef, useState } from 'react';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, Input } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { onEnter } from '@wedo/utils';
import { useIsOverflowing } from '@wedo/utils/hooks';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { MeetingUser } from 'Shared/types/meetingUser';

type AttendeeRemarkProps = {
    attendeeId: Id;
    remark: string;
    onUpdate: (
        applyOn: APPLY_ON,
        changes: {
            id: Id;
            changes: Partial<MeetingUser>;
        }[]
    ) => void;
};
export const AttendeeRemark = ({ attendeeId, remark, onUpdate }: AttendeeRemarkProps): JSX.Element => {
    const ref = useRef<HTMLInputElement>();
    const [remarkVisible, setRemarkVisible] = useState(remark && remark.length > 0);
    const [isEdit, setIsEdit] = useState(false);
    const [inputValue, setInputValue] = useState(remark);
    const [remarkTextRef, isRemarkTextEllipsisVisible] = useIsOverflowing();

    useEffect(() => {
        setInputValue(remark);
    }, [remark]);

    const handleRemarkChange = (value: string) => {
        if (value !== remark) {
            onUpdate(APPLY_ON.THIS_MEETING, [
                {
                    id: attendeeId,
                    changes: {
                        remark: value,
                    },
                },
            ]);
        }
        setIsEdit(false);
        setRemarkVisible(value?.length > 0);
    };

    const handleVisible = () => {
        setIsEdit(true);
        setRemarkVisible(true);
        setTimeout(() => {
            ref.current.focus();
        });
    };

    return remarkVisible ? (
        isEdit ? (
            <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={onEnter(() => handleRemarkChange(inputValue))}
                ref={ref}
                className={'ml-px mr-1 w-full min-w-0 !max-w-[220px]'}
                placeholder={t`Remark`}
                trailingIcon={faXmark}
                trailingIconOnClick={() => handleRemarkChange('')}
                maxLength={50}
                onBlur={() => handleRemarkChange(inputValue)}
            />
        ) : (
            <Button
                aria-label={t`Edit remark`}
                variant={'text'}
                title={isRemarkTextEllipsisVisible ? remark : undefined}
                className="w-full min-w-0 !max-w-[220px] !justify-start font-normal"
                onClick={handleVisible}
            >
                <span ref={remarkTextRef} className={'truncate'}>
                    {remark || inputValue}
                </span>
            </Button>
        )
    ) : (
        <Button
            variant={'text'}
            aria-label={t`Add remark`}
            className={'min-w-0 !max-w-[220px] text-center sm:opacity-0 sm:group-hover:opacity-100'}
            onClick={handleVisible}
        >
            <Trans>Add</Trans>
        </Button>
    );
};
