import React, { FC } from 'react';
import clsx from 'clsx';
import { isSameDay, isToday } from 'date-fns';
import { AutoTooltipText, Card, FormatDate, NavLink, Tooltip } from '@wedo/design-system';
import { MeetingUsersAvatarGroup } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUsersAvatarGroup';
import { FormatMeetingDateTime } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingStatusTag } from 'Shared/components/meeting/MeetingStatusTag';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { Meeting } from 'Shared/types/meeting';
import { meetingStatusToMeetingView } from 'Shared/utils/meeting';

const MeetingListItemDay = ({ date }: { date: Date }) => (
    <div
        className={clsx(
            `h-9 w-9 rounded-full text-center text-xl font-bold leading-9`,
            isToday(date) ? 'bg-blue-600 text-white' : 'text-gray-500'
        )}
    >
        <FormatDate format={'dd'} date={date} />
    </div>
);

const MeetingListItemMonthWeekDay = ({ date }: { date: Date }) => (
    <div className="font-bold uppercase text-gray-400">
        <FormatDate format={'MMM'} date={date} />, <FormatDate format={'EEEEEE'} date={date} />
    </div>
);

const MeetingListItemTime = ({ startAt, endAt }: { startAt: string; endAt: string }) => (
    <div className="text-xs font-bold text-gray-500">
        <FormatDate format={'HH:mm'} date={new Date(startAt)} /> -{' '}
        <FormatDate format={'HH:mm'} date={new Date(endAt)} />
    </div>
);

export const MeetingListItemDate = ({ startAt, endAt }: { startAt: string; endAt: string }) => (
    <div className="flex items-center">
        <div className="w-12">
            <MeetingListItemDay date={new Date(startAt)} />
        </div>
        <div className="w-24">
            <MeetingListItemMonthWeekDay date={new Date(startAt)} />
        </div>
        <div className="w-24">
            <MeetingListItemTime startAt={startAt} endAt={endAt} />
        </div>
    </div>
);

const MeetingListItemDateWithTooltip = ({ startAt, endAt }: { startAt: string; endAt: string }) => (
    <Tooltip content={<FormatMeetingDateTime startAt={startAt} endAt={endAt} short={false} />}>
        <MeetingListItemDate startAt={startAt} endAt={endAt} />
    </Tooltip>
);

type MeetingListItemProps = { meeting: Meeting };

export const MeetingListItem: FC<MeetingListItemProps> = ({ meeting }) => {
    const getDefaultPreferences = usePreferences();

    const isMultiDays = !isSameDay(new Date(meeting.start_at), new Date(meeting.end_at));
    const meetingStartingDate = new Date(meeting.start_at);
    const viewMode =
        getDefaultPreferences('defaultMeetingView', null)?.[meeting?.status] ??
        meetingStatusToMeetingView[meeting?.status];

    return (
        <>
            {/* medium/big screen */}
            <NavLink
                className="hidden text-gray-800 md:block"
                to={{
                    pathname: `/meetings/${meeting.id}`,
                    searchParams: {
                        viewMode: viewMode,
                    },
                }}
            >
                <Card className="flex justify-between !flex-row p-2 items-center hover:bg-gray-100">
                    {isMultiDays ? (
                        <MeetingListItemDateWithTooltip startAt={meeting.start_at} endAt={meeting.end_at} />
                    ) : (
                        <MeetingListItemDate startAt={meeting.start_at} endAt={meeting.end_at} />
                    )}
                    <div className="align-center flex justify-center text-xs">
                        {meeting.tag_id && <WorkspaceIcon workspace={meeting.tag} showTooltip />}
                    </div>
                    <div className="flex-1 flex-shrink overflow-hidden px-2 text-sm font-bold">
                        <AutoTooltipText>{meeting.title}</AutoTooltipText>
                    </div>
                    <div className="mr-2 hidden text-right md:!block">
                        <MeetingStatusTag meetingStatus={meeting.extendedStatus} size="xs" />
                    </div>
                    <MeetingUsersAvatarGroup
                        meetingUsers={meeting?.meetingUsers?.filter((mu) => mu.is_attendee)}
                        maxDisplayed={4}
                        size="sm"
                    />
                </Card>
            </NavLink>

            {/* small screen */}
            <NavLink
                className="md:hidden block mb-3 w-full"
                to={{
                    pathname: `/meetings/${meeting.id}`,
                    searchParams: { viewMode: viewMode },
                }}
            >
                <Card className="p-2 text-blue-950 hover:bg-gray-100 hover:text-gray-800">
                    <div className="flex flex-col h-40 justify-between relative p-2">
                        <div className="flex flex-col items-start justify-between w-full">
                            <div className="font-bold truncate ... w-full">
                                <div className="mb-2 flex gap-2">
                                    {meeting.tag_id && (
                                        <div>
                                            <WorkspaceIcon workspace={meeting.tag} />
                                        </div>
                                    )}
                                    <AutoTooltipText>{meeting.title}</AutoTooltipText>
                                </div>
                                <MeetingStatusTag meetingStatus={meeting.extendedStatus} size="xs" mode="light" />
                            </div>
                        </div>
                        <div className="flex justify-between items-end">
                            <div className="flex flex-col">
                                <div
                                    className={clsx(
                                        'flex gap-1 items-center relative -left-1.5',
                                        isToday(meetingStartingDate) && 'mb-1'
                                    )}
                                >
                                    <MeetingListItemDay date={meetingStartingDate} />
                                    <MeetingListItemMonthWeekDay date={meetingStartingDate} />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <MeetingListItemTime startAt={meeting.start_at} endAt={meeting.end_at} />
                                </div>
                            </div>
                            <MeetingUsersAvatarGroup
                                meetingUsers={meeting.meetingUsers?.filter((mu) => mu.is_attendee)}
                                maxDisplayed={4}
                                size="md"
                            />
                        </div>
                    </div>
                </Card>
            </NavLink>
        </>
    );
};
