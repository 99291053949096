import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faChevronDown, faPen, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Button, Divider, Popover, useModal } from '@wedo/design-system';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { TemplateIcon } from 'Shared/components/template/TemplateIcon';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { Template } from 'Shared/types/template';

export const TemplateDescriptionPopover: FC<{ template: Template }> = ({ template }) => {
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(template);

    return (
        <Popover
            wrapperClassName="max-w-full"
            className="max-w-full"
            placement="bottom-start"
            variant="text"
            text={
                <div className="flex max-w-full items-center gap-1 truncate">
                    <TemplateIcon template={template} />
                    <div className={'truncate pl-1'}>{template?.name}</div>
                    <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3 text-gray-400" />
                </div>
            }
        >
            {({ close }) => (
                <div className="flex flex-col max-h-[20rem] w-80 grid-rows-[auto,auto,1fr] overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg md:w-[30rem]">
                    <div className="flex items-start justify-between px-2 pt-2">
                        <div className="flex items-start pt-0.5 gap-2">
                            <TemplateIcon template={template} />
                            <div className="line-clamp-2">{template?.name}</div>
                        </div>

                        <div className="flex gap-1">
                            {isCurrentUserModerator && (
                                <Button
                                    icon={faPen}
                                    size="sm"
                                    disabled={template?.deleted}
                                    onClick={() => open(TemplateSettingsModal, { templateId: template?.id })}
                                />
                            )}
                            <Button icon={faTimes} size="sm" onClick={() => close()} />
                        </div>
                    </div>

                    <Divider className="" />

                    {template?.team?.id && (
                        <Breadcrumb className="ml-3">
                            <Breadcrumb.Item to="/teams">
                                <Trans>Teams</Trans>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item to={`/teams/${template.team.id}/templates`}>
                                {template.team.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{template?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    )}

                    <div className="scrollbar-light overflow-y-auto px-3">
                        <DescriptionEditor isReadOnly html={template?.description} placeholder={t`No description`} />
                    </div>
                </div>
            )}
        </Popover>
    );
};
