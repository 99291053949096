import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import {
    EmptyState,
    ExtendableSearchInput,
    Pagination as PaginationComponent,
    Skeleton,
    Table,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { FileBreadcrumb, FileBreadcrumbHandle } from 'Shared/components/file/fileList/FileBreadcrumb';
import { LabelSelect } from 'Shared/components/file/fileList/LabelSelect';
import { FilesTable } from 'Shared/components/file/fileList/table/FilesTable';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { useGetFoldersAttachmentsQuery } from 'Shared/services/attachment';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { Attachment, FileItem } from 'Shared/types/attachment';
import { Team } from 'Shared/types/team';
import { Workspace } from 'Shared/types/workspace';

type WorkspaceRowProps = {
    workspace: Workspace;
} & ComponentProps<'tr'>;

const WorkspaceRow = ({ workspace, ...props }: WorkspaceRowProps) => {
    return (
        <Table.Row className="cursor-pointer" {...props}>
            <Table.Cell>
                <WorkspaceIcon workspace={workspace} className="-mr-1" />
            </Table.Cell>
            <Table.Cell className="h-10">{workspace.name}</Table.Cell>
        </Table.Row>
    );
};

const BlankSlateAttachment = () => (
    <EmptyState icon={faFileAlt} size="md" className={'relative'}>
        <EmptyState.Text>
            <Trans>No attachments</Trans>
        </EmptyState.Text>
    </EmptyState>
);

type InternalAttachmentSelectorProps = {
    workspaceId: Id;
    onAttachmentSelected: (attachments: Attachment[]) => void;
};

export const InternalAttachmentSelector = ({
    workspaceId: initialWorkspaceId,
    onAttachmentSelected,
}: InternalAttachmentSelectorProps): JSX.Element => {
    const fileBreadcrumbRef = useRef<FileBreadcrumbHandle>();
    const { data: allWorkspaces } = trpc.workspace.list.useQuery({});
    const { data: teams, isLoading: isLoadingTeams } = trpc.team.list.useQuery({});

    const [workspaceId, setWorkspaceId] = useState(initialWorkspaceId);
    const [workspaces, setWorkspaces] = useState([]);
    const [teamId, setTeamId] = useState(null);
    const [folderId, setFolderId] = useState(null);
    const [page, setPage] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [search, setSearch] = useState<string>(null);
    const [sortBy, setSortBy] = useState({ column: 'updated_at', direction: 'DESC' });
    const [labels, setLabels] = useState<Id[]>([]);

    const workspacesOutsideTeams = allWorkspaces?.filter(({ teamId }) => teamId == null);

    const { data: files, isLoading: isLoadingFiles } = useGetFoldersAttachmentsQuery(
        {
            tagId: workspaceId,
            folderId,
            filter: { filter: 'workspace', tagId: workspaceId },
            search,
            labels,
            page,
            pageSize: 15,
            orderBy: sortBy.column,
            orderDirection: sortBy.direction,
        },
        { skip: workspaceId == null }
    );

    const { data: workspace } = useGetWorkspaceQuery(initialWorkspaceId, { skip: !initialWorkspaceId });

    useEffect(() => {
        if (workspace) {
            const items = [];
            if (workspace.team?.id) {
                items.push({ id: `team-${workspace.team.id}`, name: workspace.team.name });
                setTeamId(workspace.team.id);
                setWorkspaces(allWorkspaces?.filter(({ teamId }) => teamId === workspace.team.id));
            }
            items.push({ id: `workspace-${workspace.id}`, name: workspace.name });
            fileBreadcrumbRef.current.setItems(items);
        }
    }, [workspace]);

    const updateSelectedFiles = (selectedFiles: FileItem[]) => {
        setSelectedFiles(selectedFiles);
        onAttachmentSelected(
            files?.data
                .filter(({ key }) => selectedFiles.some((file) => file.id === key))
                .map(({ object }) => object) ?? []
        );
    };

    useEffect(() => {
        setPage(1);
        updateSelectedFiles([]);
    }, [workspaceId, folderId, search, labels]);

    const handleSelect = (file: FileItem, selectedFiles: FileItem[]) => {
        if (file?.type === 'folder') {
            setFolderId(file.id);
        } else {
            updateSelectedFiles(selectedFiles);
        }
    };

    const handleSelectTeam = (team: Team) => () => {
        fileBreadcrumbRef.current.addItem(`team-${team.id}`, team.name);
        setTeamId(team.id);
        setWorkspaces(allWorkspaces?.filter(({ teamId }) => teamId === team.id));
    };

    const handleSelectWorkspace = (workspace: Workspace) => () => {
        fileBreadcrumbRef.current.addItem(`workspace-${workspace.id}`, workspace.name);
        setWorkspaceId(workspace.id);
    };

    const handleNavigate = (itemId: Id) => {
        updateSelectedFiles([]);
        if (itemId == null) {
            setTeamId(null);
            setWorkspaceId(null);
            setFolderId(null);
            fileBreadcrumbRef.current.clearItems();
        } else if (itemId.toString().startsWith('workspace-')) {
            setFolderId(null);
        } else if (itemId.toString().startsWith('team-')) {
            setWorkspaceId(null);
            setFolderId(null);
            fileBreadcrumbRef.current.popItem();
        } else {
            setFolderId(itemId);
        }
    };

    return (
        <>
            <div className="flex items-center justify-between">
                <FileBreadcrumb ref={fileBreadcrumbRef} folderId={folderId} onNavigate={handleNavigate} />
                {workspaceId != null && (
                    <div className="flex gap-2">
                        <ExtendableSearchInput onSearch={setSearch} />
                        <LabelSelect
                            forceSingleLine
                            canAdd
                            canEdit
                            canDelete
                            value={labels}
                            onChange={setLabels}
                            inputClassName="w-64"
                        />
                    </div>
                )}
            </div>
            <div className="mt-2">
                {isLoadingFiles || isLoadingTeams ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className={'h-10'} />
                    </div>
                ) : workspaceId != null && files?.data.length > 0 ? (
                    <>
                        <FilesTable
                            canSelectMultiple={true}
                            isForcedMultipleSelect={true}
                            isPreviewable={false}
                            isDraggable={false}
                            isActionable={false}
                            files={files.data}
                            selectedFiles={selectedFiles}
                            onSort={setSortBy}
                            sortBy={sortBy}
                            onSelect={handleSelect}
                        />
                        {files?.pagination.rowCount > files?.pagination.pageSize && (
                            <div className="mt-2 flex justify-center">
                                <PaginationComponent
                                    currentPage={page}
                                    onPageChange={setPage}
                                    totalCount={files.pagination.rowCount}
                                    pageSize={files.pagination.pageSize}
                                />
                            </div>
                        )}
                    </>
                ) : workspaceId == null && teams?.length === 0 && workspacesOutsideTeams?.length === 0 ? (
                    <EmptyState icon={faUsersClass} size="md" className={'relative'}>
                        <EmptyState.Text>
                            <Trans>No teams</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                ) : workspaceId == null ? (
                    <Table size="condensed">
                        <Table.Head>
                            <Table.HeadCell className="w-8"></Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Name</Trans>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {teamId == null && workspaceId == null && (
                                <>
                                    {workspacesOutsideTeams?.map((workspace) => (
                                        <WorkspaceRow
                                            key={workspace.id}
                                            onClick={handleSelectWorkspace(workspace)}
                                            workspace={workspace}
                                        />
                                    ))}
                                    {teams?.map((team) => (
                                        <Table.Row
                                            key={team.id}
                                            className="cursor-pointer"
                                            onClick={handleSelectTeam(team)}
                                        >
                                            <Table.Cell>
                                                <TeamIcon className="-mr-1" />
                                            </Table.Cell>
                                            <Table.Cell className="h-10">{team.name}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </>
                            )}
                            {teamId != null &&
                                workspaceId == null &&
                                workspaces?.map((workspace) => (
                                    <WorkspaceRow
                                        key={workspace.id}
                                        onClick={handleSelectWorkspace(workspace)}
                                        workspace={workspace}
                                    />
                                ))}
                        </Table.Body>
                    </Table>
                ) : (
                    <BlankSlateAttachment />
                )}
            </div>
        </>
    );
};
