import { useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { RequireAuthSearchParams } from 'Shared/services/auth';

const RestrictedPaths = ['/signin', '/magic-link', '/lost-totp', 'reset-password'];
export const useTokenExpired = () => {
    const navigate = useNavigate();
    const [{ token, user_id, userId, login_saml }] = useSearchParams(RequireAuthSearchParams);

    const loginRequired = () => {
        let redirect: string = null;
        if (!token && window.location.hostname.split('.')[0] !== 'login' && location.pathname !== '/') {
            redirect = location.pathname + location.search;
        }

        if (!RestrictedPaths.includes(location?.pathname)) {
            void navigate(
                { pathname: '/signin', searchParams: { redirect, token, user_id, userId, login_saml } },
                { replace: true }
            );
        }
    };

    return { loginRequired };
};
