import React, { FC, MutableRefObject } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Input, Label, Modal } from '@wedo/design-system';

type TeamNameModalBodyProps = {
    teamNameInput: MutableRefObject<HTMLInputElement>;
    name: string;
    onNameChange: (name: string) => void;
    onAddTeam: () => void;
    isDuplicateError: boolean;
};

export const TeamNameModalBody: FC<TeamNameModalBodyProps> = ({
    teamNameInput,
    name,
    onNameChange,
    isDuplicateError,
    onAddTeam,
}) => {
    return (
        <Modal.Body>
            <Label>
                <Trans>Team name</Trans>
            </Label>

            <Input
                ref={teamNameInput}
                value={name}
                onChange={(e) => onNameChange(e.target.value)}
                placeholder={t`HR, Marketing, Finance ...`}
                status={isDuplicateError ? 'error' : 'default'}
                statusText={isDuplicateError && t`Team name already exists, use a different team name`}
                onPressEnter={onAddTeam}
            />

            <Alert type={'info'} title={t`Tip`} className="mt-4">
                <Trans>Commonly we advise creating a team per department.</Trans>
            </Alert>
        </Modal.Body>
    );
};
