import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import { useStore } from 'zustand';
import { colors } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { useGanttContextStore } from './GanttContext';
import { type GanttPageParams } from './GanttPage';
import { GanttViewControls } from './GanttViewControls';
import { GanttViewDivider } from './GanttViewDivider';
import { ListView, DefaultListViewMinWidth } from './ListView';
import { TimelineView } from './TimelineView';
import { ScrollToDayEvent } from './events';
import { computeDateWindows, GlobalDateWindow, daysSinceEpoch } from './utils';

const DefaultRowHeight = 34;

export const RowsBackgroundImage = `repeating-linear-gradient(to bottom, transparent, transparent var(--row-height), ${colors.gray['200']} var(--row-height), ${colors.gray['200']} calc(var(--row-height) + 1px))`;

export const ganttViewElement = () => {
    return document.getElementById('gantt-view')!;
};

export const GanttView = () => {
    const { workspaceId } = useParams<GanttPageParams>();
    const store = useGanttContextStore()!;
    const isLoading = useStore(store, ({ data }) => data.sectionsWithTasks == null);
    const utils = trpc.useUtils();

    const containerRef = useRef<HTMLDivElement>(null);

    const handleOverflow = useCallback(() => {
        const listViewWidth = store.getState().view.listViewWidth;
        const containerRect = containerRef.current!.getBoundingClientRect();
        const timelineHeader = document.getElementById('gantt-timeline-header')!;
        const timelineHeaderRect = timelineHeader.getBoundingClientRect();

        for (
            let row = document.elementFromPoint(containerRect.left + listViewWidth + 3, timelineHeaderRect.bottom + 1);
            (row?.getBoundingClientRect().top ?? Infinity) < containerRect.bottom;
            row = row?.nextElementSibling
        ) {
            const taskElement = row?.querySelector('[data-task-id]');
            if (taskElement != null) {
                const taskElementRect = taskElement.getBoundingClientRect();
                if (taskElementRect.right < containerRect.left + listViewWidth + 4) {
                    taskElement.parentElement!.classList.add('is-overflowing-left');
                } else if (taskElementRect.left > containerRect.right) {
                    taskElement.parentElement!.classList.add('is-overflowing-right');
                } else {
                    taskElement.parentElement!.classList.remove('is-overflowing-left', 'is-overflowing-right');
                }
            }
        }
    }, []);

    const handleScrollToDay = useCallback(({ detail: day }: CustomEvent) => {
        containerRef.current!.scrollTo({
            left: day * store.getState().view.zoom.columnWidth,
            behavior: 'smooth',
        });
    }, []);

    const handleScroll = () => {
        handleOverflow();
    };

    const handleResize = useCallback(() => {
        handleOverflow();
    }, []);

    const handleRef = (element: HTMLDivElement | null) => {
        if (element != null) {
            handleOverflow();
        }
    };

    useInvalidationEvent(
        ([tag]) => {
            const [, taskId] = tag.match(/\.(\d+)\./);
            const task = utils.task.gantt.get.getData({ taskId, workspaceId: workspaceId! });
            if (task != null) {
                store.setState((state) => {
                    state.data.tasks = state.data.tasks!.map((oldTask) => (oldTask.id === task.id ? task : oldTask));
                    state.data.dateWindows = computeDateWindows(state.data.tasks!, state.data.sectionsById!);
                });
                store.getState().eventBus.dispatchRenderDependenciesEvent();
            }
        },
        taskQueryTag.updated('*', 'dueDate'),
        taskQueryTag.updated('*', 'plannedDate'),
        taskQueryTag.updated('*', 'order')
    );

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        const eventBus = store.getState().eventBus;
        eventBus.addEventListener(ScrollToDayEvent, handleScrollToDay);
        return () => {
            window.removeEventListener('resize', handleResize);
            eventBus.removeEventListener(ScrollToDayEvent, handleScrollToDay);
        };
    }, []);

    return isLoading ? (
        <div
            className="border border-gray-200 rounded-md h-full bg-white flex animate-pulse flex-1"
            style={{ '--row-height': `${DefaultRowHeight}px`, backgroundImage: RowsBackgroundImage }}
        />
    ) : (
        <div
            id="gantt-view"
            ref={handleRef}
            className="border border-gray-200 rounded-md bg-white text-sm h-full flex-1 grid grid-rows-[min-content_1fr] isolate"
            style={{
                '--row-height': `${DefaultRowHeight}px`,
                '--list-view-width': `${DefaultListViewMinWidth}px`,
                '--start-day': daysSinceEpoch(store.getState().data.dateWindows.get(GlobalDateWindow)!.minDate),
            }}
        >
            <GanttViewControls />
            <div ref={containerRef} className="flex overflow-auto relative" onScroll={handleScroll}>
                <ListView />
                <GanttViewDivider />
                <TimelineView />
            </div>
        </div>
    );
};
