import React from 'react';
import clsx from 'clsx';
import { CustomFieldDropdown } from 'Pages/settings/customFields/components/CustomFieldItem/CustomFieldDropdown';
import { ItemContainer } from 'Pages/settings/customFields/components/Item/ItemContainer';
import { ItemLabel } from 'Pages/settings/customFields/components/Item/ItemLabel';
import { LastUpdatedByAvatar } from 'Pages/settings/customFields/components/LastUpdatedByAvatar';
import { ArchivedTag } from 'Pages/settings/customFields/components/tags/ArchivedTag';
import { CustomFieldTypeTag } from 'Shared/components/customField/CustomFieldTypeTag';
import { useGetUserQuery } from 'Shared/services/user';
import { CustomField } from 'Shared/types/customField';

export type CustomFieldItemProps = {
    customField: CustomField;
    className?: string;
};

export const CustomFieldItem: React.FC<CustomFieldItemProps> = ({ customField, className }) => {
    const { data: createdBy } = useGetUserQuery(customField?.created_by);

    return (
        <ItemContainer
            className={clsx('flex max-w-full justify-between gap-4 overflow-hidden bg-white px-4 py-2', className)}
        >
            <div className="flex items-center gap-6 truncate ">
                <ItemLabel label={customField?.label} icon={customField?.icon} />
            </div>

            <div className="flex items-center gap-2">
                {customField?.archived && <ArchivedTag />}
                <CustomFieldTypeTag fieldType={customField?.type} />
                <LastUpdatedByAvatar createdBy={createdBy} createdAt={customField?.created_at} />
                <CustomFieldDropdown customField={customField} />
            </div>
        </ItemContainer>
    );
};
