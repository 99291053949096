import { useEffect, useId, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { SerializedError } from '@reduxjs/toolkit';
import clsx from 'clsx';
import {
    BorderedContainer,
    ColorPickerPopover,
    Form,
    Input,
    ItemGroup,
    Label,
    RadioGroup,
    Select,
    Switch,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { LanguageMap, onEnter } from '@wedo/utils';
import { useSessionUser, useUsers } from 'App/store/usersStore';
import { PartialUser } from 'Pages/settings/users/utils/user';
import { UserRoleDescription } from 'Shared/components/user/UserRoleDescription';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useCurrentOrganisation } from 'Shared/hooks/useCurrentOrganisation';
import { AddUserArg, adminErrorTransform } from 'Shared/services/admin';
import { trpc } from 'Shared/trpc';
import { ApiError, UnknownError } from 'Shared/types/apiError';
import { UserRole } from 'Shared/types/user';
import { getNumberOfLightUsers, getPlanType, useRemainingGovernanceLicenses } from 'Shared/utils/chargebee';
import { emailError, initialsError, requiredAndNoSpecialError } from 'Shared/utils/user';

type EditUserFormProps = {
    user: PartialUser;
    userApiError: ApiError | SerializedError;
    onChange: (type: keyof AddUserArg, value: any) => void;
    isReadonly?: boolean;
    onEnterPress: () => void;
};

type ErrorType = keyof AddUserArg | typeof UnknownError;

export const EditUserForm = ({
    user: formUser,
    userApiError,
    onChange,
    isReadonly,
    onEnterPress,
}: EditUserFormProps) => {
    const id = useId();
    const currentUser = useSessionUser();
    const { show } = useNotification();
    const { isEmailOutsideOrganization } = useCurrentOrganisation();
    const { isSamlEnabled } = useCurrentNetwork();
    const { data: subscription } = trpc.subscription.get.useQuery();
    const { data: userLicenses } = trpc.userLicense.list.useQuery();
    const remainingGovernanceLicenses = useRemainingGovernanceLicenses();
    const userHasGovernanceLicense = userLicenses?.some(
        (userLicense) => userLicense.license === 'governance' && userLicense.userId === Number(formUser.id)
    );

    const users = useUsers();
    const user = users.find((user) => user.id === formUser.id);
    const totalLightUserLicenses = getNumberOfLightUsers(subscription);
    const lightUsers = users.filter((user) => user.role === UserRole.LIGHT);

    const [editedFields, setEditedFields] = useState<Set<keyof AddUserArg>>(new Set());
    const [errors, setErrors] = useState<Map<ErrorType, ApiError>>(new Map());
    const [previousUserApiError, setPreviousUserApiError] = useState(userApiError);

    const firstNameInputRef = useRef<HTMLInputElement>();

    const addError = (key: ErrorType, value: ApiError) => {
        setErrors(errors.set(key, value));
    };

    const isNewError = (key: keyof AddUserArg) => !errors.get(key) && editedFields.has(key);

    const handleChange = (type: keyof AddUserArg, value: string) => {
        setEditedFields(editedFields.add(type));
        addError(type, null);
        onChange(type, value);
    };

    const isExternalDisabled = !isEmailOutsideOrganization(formUser.userEmail?.email_address ?? '');

    const addErrorFromApi = (error: ApiError) => {
        if (!(error instanceof ApiError)) {
            Function.prototype();
        } else if (error.matches(adminErrorTransform.firstName)) {
            addError('firstName', error);
        } else if (error.matches(adminErrorTransform.lastName)) {
            addError('lastName', error);
        } else if (error.matches(adminErrorTransform.initials)) {
            addError('initials', error);
        } else if (error.matches(adminErrorTransform.email)) {
            addError('email', error);
        } else if (error.matches(adminErrorTransform.duplicate)) {
            addError('email', error);
        } else if (error.matches(adminErrorTransform.maxLights)) {
            show({
                type: 'danger',
                message: t`You have reached the maximum number of light users. Please upgrade your plan.`,
            });
        } else if (error.matches(adminErrorTransform.maxStandard)) {
            show({
                type: 'danger',
                message: t`You have reached the maximum number of users. Please upgrade your plan.`,
            });
        } else {
            show(UnexpectedErrorNotification);
        }
    };

    if (previousUserApiError !== userApiError) {
        setPreviousUserApiError(userApiError);
        addErrorFromApi(userApiError as ApiError);
        setEditedFields(new Set(['firstName', 'lastName', 'email', 'initials']));
    }

    let error = requiredAndNoSpecialError(formUser.first_name);
    if (isNewError('firstName') && error !== '') {
        addError('firstName', new ApiError(null).setMessage(error));
    }

    error = initialsError(formUser.initials);
    if (isNewError('initials') && error !== '') {
        addError('initials', new ApiError(null).setMessage(error));
    }
    if (error === '' && errors.get('initials')) {
        addError('initials', null);
    }

    error = emailError(formUser.userEmail?.email_address);
    if (isNewError('email') && error !== '') {
        addError('email', new ApiError(null).setMessage(error));
    }

    useEffect(() => {
        if (!user?.id && isExternalDisabled && user?.role === UserRole.EXTERNAL) {
            handleChange('role', UserRole.USER);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExternalDisabled, user?.role]);

    useEffect(() => {
        if ('current' in firstNameInputRef) {
            setTimeout(() => firstNameInputRef.current?.focus(), 100);
        }
    }, [firstNameInputRef]);

    useEffect(() => {
        if (isExternalDisabled && formUser.role === UserRole.EXTERNAL) {
            handleChange('role', UserRole.USER);
        }
    }, [isExternalDisabled, formUser.role]);

    return (
        <Form layout="vertical">
            {formUser.id != null && (
                <Form.Item
                    cols={6}
                    labelClassName={'underline decoration-dotted !cursor-help'}
                    label={
                        <Tooltip content={t`Optional setting that will override first and last name`}>
                            <Trans>Display name</Trans>
                        </Tooltip>
                    }
                    htmlFor={`${id}display-name`}
                >
                    <Input
                        id={`${id}display-name`}
                        value={formUser.display_name}
                        onChange={(e) => handleChange('displayName', e.target.value)}
                        status={errors.get('displayName') ? 'error' : 'default'}
                        statusText={errors.get('displayName')?.message ?? ''}
                        disabled={isReadonly}
                        onKeyDown={onEnter(onEnterPress)}
                    />
                </Form.Item>
            )}
            <Form.Item cols={2} label={t`Initials`} htmlFor={`${id}initials`}>
                <ItemGroup
                    status={errors.get('initials') ? 'error' : 'default'}
                    statusText={errors.get('initials')?.message ?? ''}
                >
                    <Input
                        className={'flex-grow'}
                        id={`${id}initials`}
                        value={formUser.initials}
                        onChange={(e) => handleChange('initials', e.target.value)}
                        disabled={isReadonly}
                    />
                    <ColorPickerPopover
                        showSelectedColor={true}
                        classNameButton="rounded-l-none"
                        color={formUser.color.background}
                        onChange={(color) => handleChange('color', color)}
                        disabled={isReadonly}
                    />
                </ItemGroup>
            </Form.Item>

            <Form.Item cols={2} label={t`First name`} htmlFor={`${id}first-name`}>
                <Input
                    ref={firstNameInputRef}
                    id={`${id}first-name`}
                    value={formUser.first_name}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    status={errors.get('firstName') ? 'error' : 'default'}
                    statusText={errors.get('firstName')?.message ?? ''}
                    disabled={isReadonly}
                    onKeyDown={onEnter(onEnterPress)}
                />
            </Form.Item>

            <Form.Item cols={2} label={t`Last name`} htmlFor={`${id}last-name`}>
                <Input
                    id={`${id}last-name`}
                    value={formUser.last_name}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                    disabled={isReadonly}
                    onKeyDown={onEnter(onEnterPress)}
                />
            </Form.Item>

            <Form.Item cols={2} label={t`Email`} htmlFor={`${id}email`}>
                <Input
                    id={`${id}email`}
                    value={formUser?.userEmail?.email_address}
                    onChange={(e) => handleChange('email', e.target.value)}
                    status={errors.get('email') ? 'error' : 'default'}
                    statusText={errors.get('email')?.message ?? ''}
                    disabled={(formUser?.id != null && formUser?.userNetwork?.status !== 'PENDING') || isReadonly}
                />
            </Form.Item>

            <Form.Item cols={2} label={t`Job title`} htmlFor={`${id}title`}>
                <Input
                    id={`${id}title`}
                    value={formUser?.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                    disabled={isReadonly}
                />
            </Form.Item>

            <Form.Item cols={2} label={t`Language`}>
                <Select
                    value={formUser.language_code}
                    onChange={(lang: string) => handleChange('language', lang)}
                    disabled={isReadonly}
                    customRenderSelected={(value: string) => LanguageMap.get(value)}
                >
                    {[...LanguageMap.keys()].map((key) => (
                        <Select.Option key={key} value={key}>
                            {LanguageMap.get(key)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {currentUser.id !== formUser.id && currentUser.role === UserRole.ADMIN && (
                <Form.Item cols={6} label={t`Base license`}>
                    <RadioGroup
                        name="base-license"
                        size="md"
                        radioType="panel"
                        value={
                            [UserRole.USER, UserRole.ADMIN].includes(formUser.role)
                                ? 'standard'
                                : formUser.role?.toLowerCase()
                        }
                        onChange={(license) => handleChange('baseLicense', license)}
                    >
                        <RadioGroup.Radio value={'standard'}>
                            <div className="flex gap-2 justify-between items-center">
                                <div>
                                    {getPlanType(subscription) === 'enterprise' ? (
                                        <Trans>Enterprise</Trans>
                                    ) : (
                                        <Trans>Pro</Trans>
                                    )}
                                    <div className="text-xs">
                                        <UserRoleDescription
                                            role={formUser.role === UserRole.ADMIN ? UserRole.ADMIN : UserRole.USER}
                                        />
                                    </div>
                                </div>
                                <RadioGroup
                                    radioType={'buttonGroup'}
                                    value={
                                        [UserRole.USER, UserRole.ADMIN].includes(formUser.role) ? formUser.role : null
                                    }
                                    name="role"
                                    onChange={(role) => handleChange('role', role)}
                                >
                                    <RadioGroup.Radio
                                        value="USER"
                                        disabled={![UserRole.USER, UserRole.ADMIN].includes(formUser.role)}
                                    >
                                        <Trans>User</Trans>
                                    </RadioGroup.Radio>
                                    <RadioGroup.Radio
                                        value="ADMIN"
                                        disabled={![UserRole.USER, UserRole.ADMIN].includes(formUser.role)}
                                    >
                                        <Trans>Administrator</Trans>
                                    </RadioGroup.Radio>
                                </RadioGroup>
                            </div>
                        </RadioGroup.Radio>
                        {(totalLightUserLicenses > 0 || lightUsers.length > 0) && (
                            <RadioGroup.Radio value={'light'}>
                                <div>
                                    <Trans>Light</Trans>
                                    <div className="text-xs">
                                        <UserRoleDescription role={UserRole.LIGHT} />
                                    </div>
                                </div>
                            </RadioGroup.Radio>
                        )}
                        <RadioGroup.Radio disabled={isExternalDisabled} value={'external'}>
                            <Trans>External</Trans>
                            <div className="text-xs">
                                <UserRoleDescription role={UserRole.EXTERNAL} />
                            </div>
                        </RadioGroup.Radio>
                    </RadioGroup>
                </Form.Item>
            )}
            {currentUser.role === UserRole.ADMIN && (
                <Form.Item cols={6} label={t`Addons`}>
                    <BorderedContainer>
                        <Label className="flex gap-2 justify-between items-center pl-2" inputType={'inline'}>
                            <div className="flex flex-col gap-1">
                                <Trans>Governance</Trans>
                                <div className="text-xs text-gray-500">
                                    <Trans>Gives the user access to the Governance module</Trans>
                                </div>
                            </div>
                            <Tooltip
                                content={
                                    remainingGovernanceLicenses === 0 &&
                                    !userHasGovernanceLicense &&
                                    t`No more remaining governance licenses`
                                }
                            >
                                <Switch
                                    disabled={remainingGovernanceLicenses === 0 && !userHasGovernanceLicense}
                                    checked={formUser?.userLicenses?.includes('governance')}
                                    onChange={(checked) => handleChange('governanceAddon', checked ? 'true' : 'false')}
                                />
                            </Tooltip>
                        </Label>
                    </BorderedContainer>
                </Form.Item>
            )}
            {currentUser.id !== formUser.id && (isSamlEnabled || formUser.role === UserRole.USER) && (
                <Form.Item cols={6} label={t`Options`}>
                    <BorderedContainer
                        className={clsx('flex items-center gap-2 mb-2', formUser.role !== UserRole.USER && 'invisible')}
                    >
                        <Label
                            disabled={isReadonly}
                            className="flex gap-2 justify-between items-center pl-2 w-full"
                            inputType={'inline'}
                        >
                            <Trans>Can create external users</Trans>
                            <Switch
                                checked={formUser.userNetwork?.can_add_external}
                                onChange={(value) => handleChange('can_add_external', value ? 'true' : 'false')}
                                disabled={isReadonly}
                            />
                        </Label>
                    </BorderedContainer>
                    <Form.Item cols={6} className={clsx('mb-2', !isSamlEnabled && 'hidden')}>
                        <BorderedContainer className="flex items-center gap-2">
                            <Label
                                inputType={'inline'}
                                disabled={isReadonly}
                                className="flex gap-2 justify-between items-center pl-2 w-full"
                            >
                                <Trans>Enable SAML authentication</Trans>
                                <Switch
                                    checked={formUser?.userAccount?.saml}
                                    onChange={(value) => handleChange('saml', value ? 'true' : 'false')}
                                    disabled={isReadonly}
                                />
                            </Label>
                        </BorderedContainer>
                    </Form.Item>
                </Form.Item>
            )}
            <input type="submit" hidden />
        </Form>
    );
};
