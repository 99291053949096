import { t } from '@lingui/macro';
import { intlFormat } from 'date-fns';
import { de, enGB, enUS, fr, frCH, it, itCH } from 'date-fns/locale';
import { DayOfWeekEn } from '@wedo/types';

export const isLeapYear = (year: number): boolean => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export const getDaysInMonth = (year: number, month: number): number =>
    [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];

export const isValidDate = (d: unknown) => d instanceof Date && !isNaN(d);

/**
 * Returns a new date that is {value} months from {date}
 * It checks for the amount of days a month has (even if it's a leap year)
 * @param date - date of reference
 * @param value - How many months to add to it
 */
export const addMonths = (date: Date, value: number): Date => {
    const previousDate = date.getDate();
    const newDate = new Date(date);
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + value);
    newDate.setDate(Math.min(previousDate, getDaysInMonth(newDate.getFullYear(), newDate.getMonth())));
    return newDate;
};

export const isLastWeekOfMonth = (date: Date): boolean => {
    const currentMonth = date.getMonth();
    const inSevenDays = new Date(date);
    inSevenDays.setDate(inSevenDays.getDate() + 7);
    return currentMonth !== inSevenDays.getMonth();
};

export const getWeekOfMonth = (date: Date): number => Math.ceil(date.getDate() / 7);

export const isLastDayOfMonth = (date: Date): boolean =>
    date.getDate() === getDaysInMonth(date.getFullYear(), date.getMonth());

type FormatLocalOption = {
    precision?: 'day' | 'hour' | 'minute' | 'second';
    defaultLocale?: string;
};

export const formatLocale = ({ precision = 'second', defaultLocale = 'fr-ch' }: FormatLocalOption = {}) => {
    let options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    switch (precision) {
        case 'second':
            options = {
                ...options,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            break;

        case 'minute':
            options = {
                ...options,
                hour: '2-digit',
                minute: '2-digit',
            };
            break;

        case 'hour':
            options = {
                ...options,
                hour: '2-digit',
            };
            break;
        default:
            options = { ...options };
    }

    return (dateStr: string, locale = defaultLocale ?? 'fr-ch'): string => {
        const date = new Date(dateStr);

        return new Intl.DateTimeFormat(locale, options).format(date);
    };
};

type WeekDayData = {
    id: DayOfWeekEn;
    label: string;
    shortHand: string;
};

export const numberToDayOfWeek = () =>
    new Map<number, WeekDayData>([
        [0, { id: 'Sunday', label: t`Sunday`, shortHand: t`Su` }],
        [1, { id: 'Monday', label: t`Monday`, shortHand: t`Mo` }],
        [2, { id: 'Tuesday', label: t`Tuesday`, shortHand: t`Tu` }],
        [3, { id: 'Wednesday', label: t`Wednesday`, shortHand: t`We` }],
        [4, { id: 'Thursday', label: t`Thursday`, shortHand: t`Th` }],
        [5, { id: 'Friday', label: t`Friday`, shortHand: t`Fr` }],
        [6, { id: 'Saturday', label: t`Saturday`, shortHand: t`Sa` }],
    ]);

export const DAY_OF_WEEK_TO_NUMBER = new Map<DayOfWeekEn, number>([
    ['Sunday', 0],
    [`Monday`, 1],
    [`Tuesday`, 2],
    [`Wednesday`, 3],
    [`Thursday`, 4],
    [`Friday`, 5],
    [`Saturday`, 6],
]);

/**
 * @param locale language and region info such as en-GB, fr-FR, fr-CH etc.
 * @return the first day of the week based on locale, e.g. for en-US it is sunday (7) but for en-Gb it is monday (1)
 */
const getFirstDayOfWeek = (locale: string): 1 | 2 | 3 | 4 | 5 | 6 | 7 => {
    const localeData = new Intl.Locale(locale) as unknown as IntlLocale;
    if ('weekInfo' in localeData) {
        return localeData.weekInfo.firstDay;
    }
    return 1;
};

// 7 corresponds to Sunday and 1 to monday, whereas our server responds to 0 as sunday, so we need to use % 7
type IntlLocale = typeof Intl.Locale & {
    weekInfo: {
        firstDay: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    };
};

/**
 * @param locale language and region info such as en-GB, fr-FR, fr-CH etc.
 * @return returns an array of week days in order of current region., e.g. in fr-FR days wil start from lundi (monday)
 *         whereas in en-US days will start from monday
 */
export const getWeekdays = (locale: Intl.Locale | string): Array<WeekDayData> => {
    const firstDay = getFirstDayOfWeek(locale.toString()) % 7;
    const result = [];
    for (let i = firstDay, repeat = 0; ; i = (i + 1) % 7) {
        if (i === firstDay) {
            repeat++;
            if (repeat === 2) {
                break;
            }
        }
        result.push(numberToDayOfWeek().get(i));
    }
    return result;
};

// 0 corresponds to sunday and 1 to monday, these are the numbers being used by WEDO server api
type WeekDayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/**
 * @param locale language and region info such as en-GB, fr-FR, fr-CH etc.
 * @returns a map mapping from the day number to actual order of that day in the week, so if the week starts
 *          on sunday (0), then the mapping will be { 0 --> 0, 1 --> 1 .... 6 --> 6 }, but if the week is starting from
 *          monday (1) then the mapping will be { 1 --> 0, 2 --> 1 ... 0 --> 6 }
 */
export const getWeekDayNumberToLocalOrder = (locale: Intl.Locale | string): Map<WeekDayNumber, WeekDayNumber> => {
    const firstDay = locale ? getFirstDayOfWeek(locale.toString()) % 7 : 0;
    const result = new Map<WeekDayNumber, WeekDayNumber>();
    for (let i = firstDay, k = 0, repeat = 0; repeat < 2; i = (i + 1) % 7, k++) {
        result.set(i as WeekDayNumber, (k % 7) as WeekDayNumber);
        if (i === firstDay) {
            repeat++;
        }
    }
    return result;
};

/**
 * returns formatted date string like 1st Jan 2005, 24th Aug 2022 etc.
 * @param date date to be formatted according to locale
 * @param locale the locale currently in place like en-US, fr-CH etc.
 */
export const getFormattedDate = (date: Date, locale: string) =>
    intlFormat(date, { month: 'long', day: 'numeric', year: 'numeric' }, { locale });

export const getDateFnsLocale = (locale: string): Locale => {
    switch (locale.toLowerCase()) {
        case 'fr':
        case 'fr-fr':
            return fr;
        case 'fr-ch':
            return frCH;
        case 'en':
        case 'en-gb':
            return enGB;
        case 'en-us':
            return enUS;
        case 'de':
        case 'de-de':
        case 'de-ch':
            return de;
        case 'it-ch':
            return itCH;
        case 'it':
        case 'it-it':
            return it;
        default:
            return enUS;
    }
};

export const MILLISECONDS_PER_DAY = 86_400_000;

export const MILLISECONDS_PER_HOUR = 3_600_000;

export const MILLISECONDS_PER_MINUTE = 60_000;

/*
@ return formatted date range as
  13 - 21 October when the month and year is same
  13 Oct - 21 Nov when month is different, but year is same
  13 Oct 2022 - 21 Nov 2023 when month and year are different
 */
export const formatRange = (d1: Date, d2: Date, locale: string) => {
    if (Intl.DateTimeFormat.prototype.formatRange === undefined) {
        return `${new Intl.DateTimeFormat(locale, {
            month: 'short',
            day: 'numeric',
        }).format(d1)} - ${new Intl.DateTimeFormat(locale, {
            month: 'short',
            day: 'numeric',
        }).format(d2)}`;
    }
    return new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: 'numeric',
    }).formatRange(d1, d2);
};
