import { useState } from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { DatePickerBox, CaretNav } from '~/components/DatePicker/components/index';
import { useDateTime } from '@wedo/utils/hooks';

type YearCalendarProps = {
    min: { year: number; month: number };
    max: { year: number; month: number };
    month: number;
    year: number;
    onMonthClick?: (year: number, month: number) => void;
    onYearClick?: (year: number) => void;
    className: string;
};

export const YearCalendar = ({
    min,
    max,
    year,
    month,
    onYearClick = () => null,
    onMonthClick = () => null,
    className,
}: YearCalendarProps) => {
    const { abbreviatedMonths } = useDateTime();
    const [yearSelected, setYearSelected] = useState(year);

    const isBoxDisabled = (month: number): boolean => {
        if (yearSelected < min.year || (yearSelected === min.year && month < min.month)) {
            return true;
        }

        return yearSelected > max.year || (yearSelected === max.year && month > max.month);
    };

    const isPreviousDisabled = yearSelected <= min.year;

    const isNextDisabled = yearSelected >= max.year;

    return (
        <div className={clsx('flex flex-col gap-2', className)}>
            <div className="flex place-content-between items-center">
                <CaretNav
                    disabled={isPreviousDisabled}
                    icon={faAngleLeft}
                    text={t`Previous year`}
                    onClick={() => yearSelected - 1 >= min.year && setYearSelected(yearSelected - 1)}
                />

                <Button variant="link" onClick={() => onYearClick(yearSelected)}>
                    {yearSelected}
                </Button>

                <CaretNav
                    disabled={isNextDisabled}
                    icon={faAngleRight}
                    text={t`Next year`}
                    onClick={() => yearSelected + 1 <= max.year && setYearSelected(yearSelected + 1)}
                />
            </div>
            <div className={'grid grid-cols-3 gap-y-4 overflow-hidden text-sm'}>
                {abbreviatedMonths.map((abbreviatedMonth, index) => (
                    <DatePickerBox
                        key={index}
                        isDisabled={isBoxDisabled(index)}
                        isActive={index === month && yearSelected === year}
                        isSecondary={false}
                        datetime={`${yearSelected}-${String(month + 1).padStart(2, '0')}`}
                        text={abbreviatedMonth}
                        onClick={() => onMonthClick(yearSelected, index)}
                    />
                ))}
            </div>
        </div>
    );
};
