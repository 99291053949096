import { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { Input, InputProps, InputSize } from '~/components/Input/Input';

type ExtendableSearchInputProps = {
    position?: InputProps['position'];
    search?: string;
    onSearch: (value: string) => void;
    size?: InputSize;
    delay?: number;
} & Pick<ComponentProps<'input'>, 'onFocus' | 'onBlur'>;

export const ExtendableSearchInput: FC<ExtendableSearchInputProps> = ({
    position,
    search,
    onSearch,
    size = 'md',
    delay = 250,
    ...props
}) => {
    const searchRef = useRef<HTMLInputElement>(null);
    const [extended, setExtended] = useState<boolean>(search != null && search !== '');

    useEffect(() => {
        if (extended) {
            searchRef.current?.focus();
        }
    }, [extended]);

    const handleClearSearch = (): void => {
        onSearch('');
        searchRef.current?.blur();
        setExtended(false);
    };

    return (
        <>
            {!extended && (
                <Button
                    variant={'text'}
                    icon={faMagnifyingGlass}
                    onClick={() => setExtended(true)}
                    data-testid="searchInput-button"
                    size={size}
                />
            )}
            {extended && (
                <Input
                    {...props}
                    ref={searchRef}
                    debounce={delay}
                    position={position}
                    placeholder={extended ? t`Search...` : null}
                    value={search || ''}
                    className="bg-white !text-gray-800"
                    inputClassName={clsx('transition-[width] duration-150', extended ? 'w-56' : 'w-0 !border-0 !p-0')}
                    size={size}
                    trailingIcon={extended && faXmark}
                    trailingIconOnClick={handleClearSearch}
                    onFocus={() => setExtended(true)}
                    onChange={(e) => onSearch(e.target.value)}
                    onBlur={() => (search == null || search.length === 0) && setExtended(false)}
                    data-testid="searchInput"
                    leadingIcon={faMagnifyingGlass}
                />
            )}
        </>
    );
};
