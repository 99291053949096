import React from 'react';
import { Table } from '@wedo/design-system';
import { TeamDropdown } from 'Shared/components/team/TeamDropdown';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { Team } from 'Shared/types/team';

type TeamRowProps = {
    team: Team;
};

export const TeamRow: React.FC<TeamRowProps> = ({ team }) => {
    const users = team?.userGroup?.members?.map((member) => member.user);

    return (
        <Table.Row key={team.name}>
            <Table.Cell className="font-medium">{team.name}</Table.Cell>

            <Table.Cell className="hidden xl:table-cell">
                <UsersAvatarGroup users={users} size="sm" />
            </Table.Cell>

            <Table.Cell className="hidden text-center lg:table-cell xl:hidden">
                <UsersAvatarGroup users={users} maxDisplayed={2} size="sm" />
            </Table.Cell>

            <Table.Cell className="hidden text-center md:table-cell">{team.tags.length}</Table.Cell>

            <Table.Cell className="hidden text-center md:table-cell">{team.checklist_templates.length}</Table.Cell>

            <Table.Cell className="text-end">
                <TeamDropdown team={team} />
            </Table.Cell>
        </Table.Row>
    );
};
