import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { SectionCard, SectionCardProps } from './SectionCard';

type DraggableSectionCardProps = SectionCardProps;

export const DraggableSectionCard: FC<DraggableSectionCardProps> = ({
    section,
    onDelete,
    onUpdate,
    sections,
    canChangeColor = false,
}) => {
    const { draggableStyle } = useDndSortableVerticalStrategy();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: section?.id,
    });

    const style = draggableStyle(transform, transition, isDragging);

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={clsx('', !isDragging && 'cursor-grab', isDragging && 'cursor-grabbing')}
        >
            <SectionCard
                section={section}
                onDelete={onDelete}
                onUpdate={onUpdate}
                sections={sections}
                canChangeColor={canChangeColor}
            />
        </div>
    );
};
