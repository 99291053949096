import clsx from 'clsx';

type BoxProps = {
    isActive: boolean;
    datetime: string;
    text: string;
    isSecondary?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
    className?: string;
};

export const DatePickerBox = ({
    isActive,
    datetime,
    text,
    isSelected = false,
    isSecondary = false,
    isDisabled = false,
    onClick = () => null,
    className = '',
}: BoxProps) => {
    const classButton = (): string => {
        if (isDisabled) {
            return 'text-gray-200 hover:bg-gray-100 cursor-not-allowed';
        }

        if (isSecondary) {
            return 'text-gray-400 hover:bg-gray-100';
        }

        if (isSelected) {
            return 'bg-blue-600 font-semibold text-white hover:bg-blue-700';
        }
        return 'text-gray-900 hover:bg-gray-200';
    };

    return (
        <div className={'flex items-center justify-center'}>
            <button
                disabled={isDisabled}
                type="button"
                className={clsx(
                    'flex min-w-[2rem] items-center justify-center whitespace-nowrap rounded-md border px-2 py-1 focus:z-10',
                    classButton(),
                    !isSelected && isActive ? 'border-gray-300' : 'border-transparent',
                    className
                )}
                onClick={onClick}
            >
                <time dateTime={datetime}>{text}</time>
            </button>
        </div>
    );
};
