import { useMemo } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import {
    faArrows,
    faComment,
    faCopy,
    faGavel,
    faParagraph,
    faShareFromSquare,
    faSquareCheck,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Editor, Element, Path, Transforms } from 'slate';
import { Dropdown, FormatDate, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useMeetingContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { Attachment, deleteAttachmentBlockConfirmationProps } from 'Shared/components/editor/plugins/attachmentPlugin';
import { addComment } from 'Shared/components/editor/plugins/commentPlugin/utils';
import { Decision } from 'Shared/components/editor/plugins/decisionPlugin';
import { Paragraph } from 'Shared/components/editor/plugins/paragraphPlugin';
import { createTaskBlock, Task } from 'Shared/components/editor/plugins/taskPlugin';
import { createBlock, hasVoid, reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { MoveBlockModal } from 'Shared/components/meeting/MeetingBlocks/MoveBlockModal';
import { DuplicateTaskModal } from 'Shared/components/task/TaskDetail/modals/DuplicateTaskModal';
import { invalidateMeetingTopicBlocks } from 'Shared/services/meetingBlock';
import { trpc } from 'Shared/trpc';
import {
    MeetingPermission,
    useUserHasMeetingPermission,
    useUserHasMeetingSectionPermission,
} from 'Shared/types/meeting';
import { Task as TaskType } from 'Shared/types/task';

const ConvertibleBlocks = [Paragraph, Decision];
const ConvertibleToParagraphBlocks = [Decision];
const ConvertibleToNoteBlocks = [Paragraph, Decision];
const ConvertibleToDecisionBlocks = [Paragraph];

type OptionsItemProps = {
    node: Element;
    topicId: Id;
    sectionId: Id;
    onOpen: (isOpen: boolean) => void;
};

export const OptionsItem = ({ node, topicId, sectionId, onOpen }: OptionsItemProps) => {
    const editor = useSlateStatic();
    const currentUser = useSessionUser();
    const dispatch = useAppDispatch();
    const { show } = useNotification();

    const { meetingId, meeting } = useMeetingContext();
    const { open: openModal } = useModal();
    const { confirm } = useConfirm();

    const { hasPermission: canEditVote } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const { hasPermission: canEditTopicContent } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        sectionId,
        MeetingPermission.EDIT_TOPIC_CONTENT
    );

    const { data: topic } = trpc.meetingTopic.get.useQuery(topicId, { select: camelToSnake });
    const { mutateAsync: moveBlock } = trpc.meetingBlock.move.useMutation({
        onSuccess: () => {
            reorderBlocks(editor);
            forceSave(editor);
            dispatch(invalidateMeetingTopicBlocks(topicId));
        },
        onError: () => {
            show({
                type: 'danger',
                title: <Trans>Access denied</Trans>,
                message: <Trans>You do not have enough permissions to postpone this block to this meeting</Trans>,
            });
        },
    });

    const nextOccurrences = topic?.next_occurrences?.filter(({ id }) => id != null) ?? [];
    const canEdit = canEditTopicContent && (node?.vote_id == null || canEditVote);
    const hasVoidElement = useMemo(() => editor != null && hasVoid(editor, node), [editor, node]);
    const isDraftTopic = topicId != null && meetingId == null;

    const handleConvertTo = (type: string) => () => {
        Transforms.setNodes(editor, { type }, { at: ReactEditor.findPath(editor, node) });
        forceSave(editor);
    };

    const handleConvertToNote = async () => {
        const comment = [{ type: 'paragraph', children: node.children }];
        const [previousBlock] = Editor.previous(editor, { at: ReactEditor.findPath(editor, node) }) ?? [null];
        void addComment({
            meetingTopicId: topicId,
            meetingBlockId: previousBlock != null ? previousBlock.id : undefined,
            value: comment,
        });
        Transforms.removeNodes(editor, { at: ReactEditor.findPath(editor, node) });
        reorderBlocks(editor);
        forceSave(editor);
    };

    const handlePostpone = (topicId: string) => {
        void moveBlock({ meetingBlockId: node.id, destinationTopicId: topicId });
    };

    const handleDuplicate = () => {
        if (node.type === Task) {
            openModal(DuplicateTaskModal, {
                meetingId,
                taskId: node.task_id,
                onDone: (tasks: TaskType[]) => {
                    Transforms.insertNodes(
                        editor,
                        tasks.map((task) => createTaskBlock(task.id, node.meeting_topic_id ?? topicId, task)),
                        { at: Path.next([node.order]) }
                    );
                    reorderBlocks(editor);
                    forceSave(editor);
                },
            });
        } else {
            Transforms.insertNodes(
                editor,
                [
                    createBlock({
                        type: node.type,
                        reference_vote_id: node.vote_id,
                        children: structuredClone(node.children),
                        decoration: node.decoration,
                        attachments: node.attachments,
                    }),
                ],
                { at: Path.next([node.order]) }
            );
            reorderBlocks(editor);
            forceSave(editor);
        }
    };

    const handleDelete = async () => {
        if (node.type === Attachment) {
            const deleteAllAttachments = await confirm(deleteAttachmentBlockConfirmationProps(node.attachments));
            if (!deleteAllAttachments) {
                return;
            }
        }
        Transforms.removeNodes(editor, {
            at: ReactEditor.findPath(editor, node),
            match: ({ id }) => id === node.id,
            voids: true,
        });
        forceSave(editor);
    };

    const handleOpen = () => {
        onOpen(true);
    };

    const handleClose = () => {
        onOpen(false);
    };

    return (
        <Dropdown
            variant="ghost"
            icon={faEllipsisVertical}
            className="focus-visible:bg-blue-50 flex !h-auto !w-auto !rounded-none !bg-white text-gray-500 outline-0 !ring-0 !ring-offset-0"
            iconClassName="h-3.5 w-3.5 p-1"
            dropdownClassName="editor-component"
            onOpen={handleOpen}
            onClose={handleClose}
            aria-label={t`Block options`}
        >
            {(canEditTopicContent || isDraftTopic) && ConvertibleBlocks.includes(node?.type) && (
                <Dropdown.GroupItem>
                    <Trans>Convert to</Trans>
                </Dropdown.GroupItem>
            )}
            {(canEditTopicContent || isDraftTopic) && ConvertibleToParagraphBlocks.includes(node?.type) && (
                <Dropdown.Item icon={faParagraph} iconClassName="!text-purple-500" onClick={handleConvertTo(Paragraph)}>
                    <Trans>Paragraph</Trans>
                </Dropdown.Item>
            )}
            {(canEditTopicContent || isDraftTopic) && ConvertibleToDecisionBlocks.includes(node?.type) && (
                <Dropdown.Item icon={faGavel} iconClassName="!text-green-500" onClick={handleConvertTo(Decision)}>
                    <Trans>Decision</Trans>
                </Dropdown.Item>
            )}
            {(canEditTopicContent || isDraftTopic) && ConvertibleBlocks.includes(node?.type) && !hasVoidElement && (
                <Dropdown.Item icon={faSquareCheck} iconClassName="!text-blue-600" onClick={handleConvertTo(Task)}>
                    <Trans>Task</Trans>
                </Dropdown.Item>
            )}
            {canEditTopicContent && ConvertibleToNoteBlocks.includes(node?.type) && (
                <Dropdown.Item icon={faComment} iconClassName="!text-yellow-500" onClick={handleConvertToNote}>
                    <Trans>Comment (private)</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.GroupItem>
                <Trans>Action</Trans>
            </Dropdown.GroupItem>
            {canEdit && (node?.type !== Task || !isDraftTopic) && (
                <Dropdown.Item icon={faCopy} onClick={handleDuplicate}>
                    <Trans>Duplicate</Trans>
                </Dropdown.Item>
            )}
            {canEditTopicContent && (
                <Dropdown.Item
                    icon={faArrows}
                    onClick={() =>
                        openModal(MoveBlockModal, {
                            editor,
                            blockId: node.id,
                            sourceTopicId: node.meeting_topic_id ?? topicId,
                            meetingId,
                        })
                    }
                >
                    <Trans>Move</Trans>
                </Dropdown.Item>
            )}
            {canEdit && nextOccurrences.length > 0 && (
                <Dropdown icon={faShareFromSquare} label={t`Postpone to...`}>
                    {nextOccurrences.map((occurrence) => (
                        <Dropdown.Item key={occurrence.id} onClick={() => handlePostpone(occurrence.id)}>
                            <FormatDate format="PPP" date={new Date(occurrence.meeting.start_at)} />
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            )}
            {(canEdit || isDraftTopic) && (
                <Dropdown.Item danger icon={faTrash} onClick={handleDelete}>
                    {node?.type === Task ? <Trans>Remove</Trans> : <Trans>Delete</Trans>}
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
