import { ReactNode, Component, createElement } from 'react';
import { datadogRum } from '@datadog/browser-rum';

type ErrorBoundaryState = {
    error: Error | null;
};

type ErrorBoundaryProps = {
    fallback: (props: { error: Error; resetError: () => void }) => ReactNode;
    children: ReactNode;
};

export class RumErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidCatch(error: Error) {
        datadogRum.addError(error);
    }

    resetErrorBoundary() {
        this.setState({ error: null });
    }

    render() {
        return this.state.error == null
            ? this.props.children
            : createElement(this.props.fallback, {
                  error: this.state.error,
                  resetError: this.resetErrorBoundary,
              });
    }
}
