import React from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { v4 as uuidv4 } from 'uuid';
import { ModalType, useConfirm, useModal } from '@wedo/design-system';
import { AttendeeEditModal } from 'Pages/meeting/components/AttendeeEditModal/AttendeeEditModal';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { MeetingUser } from 'Shared/types/meetingUser';
import { User } from 'Shared/types/user';
import { AttendeesTable } from './AttendeesTable';

export const getMeetingAttendeeUserName = (meetingAttendee: MeetingUser): string => {
    return meetingAttendee.user_id != null
        ? meetingAttendee.user.full_name
        : meetingAttendee.user_data.external_full_name;
};

export type applyOnAction = (applyOn: APPLY_ON, data: unknown) => void;

type Props = {
    attendees: MeetingUser[];
    onAdd: applyOnAction;
    onUpdate: applyOnAction;
    onDelete: applyOnAction;
};

export const AttendeesEditable = ({ attendees, onAdd, onUpdate, onDelete }: Props) => {
    const { confirm } = useConfirm();
    const { open: openModal } = useModal();

    const handleConfirmDone = (
        applyOn: APPLY_ON,
        action: (applyOn: APPLY_ON, data: unknown) => void,
        data: unknown
    ) => {
        if (applyOn) {
            action(applyOn, data);
        }
    };
    const confirmSaveMeetingModalProps = {
        showAll: false,
        defaultOption: APPLY_ON.FUTURE_MEETINGS,
    };
    const handleRemove = async (attendee: MeetingUser) => {
        const attendeeName = getMeetingAttendeeUserName(attendee);

        const applyOn = await confirm(
            {
                ...confirmSaveMeetingModalProps,
                title: (
                    <div>
                        <Trans>
                            Remove <strong>{attendeeName}</strong> from...
                        </Trans>
                    </div>
                ),
                type: ModalType.Danger,
            },
            ConfirmSaveMeetingModal
        );
        handleConfirmDone(applyOn, onDelete, [attendee.id]);
    };

    const handleEdit = (attendee: MeetingUser) => {
        openModal(AttendeeEditModal, {
            attendee,
            onDone: (data: { changes: Partial<MeetingUser> }) => {
                if (data) {
                    onUpdate(APPLY_ON.FUTURE_MEETINGS, [{ id: attendee.id, changes: data.changes }]);
                }
            },
        });
    };

    const handleUserSelected = async (user: User) => {
        const applyOn = await confirm(
            {
                ...confirmSaveMeetingModalProps,
                title: (
                    <div>
                        <Trans>
                            Add <strong>{user.full_name}</strong> to...
                        </Trans>
                    </div>
                ),
                type: ModalType.Info,
            },
            ConfirmSaveMeetingModal
        );
        handleConfirmDone(applyOn, onAdd, {
            id: uuidv4(),
            user_id: user.id,
            user: user,
            user_data: {
                title: user.title,
            },
            order: attendees.length,
            attendance: 'present',
        });
    };

    const handleAddRow = async (fullName: string) => {
        const applyOn = await confirm(
            {
                confirmSaveMeetingModalProps,
                title: (
                    <div>
                        <Trans>
                            Add <strong>{fullName}</strong> to...
                        </Trans>
                    </div>
                ),
                type: ModalType.Info,
            },
            ConfirmSaveMeetingModal
        );
        handleConfirmDone(applyOn, onAdd, {
            id: uuidv4(),
            user_id: null,
            user_data: {
                external_full_name: fullName,
            },
            order: attendees.length,
        });
    };

    return (
        <>
            <AttendeesTable
                attendees={attendees}
                onUpdate={onUpdate}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
            />

            <div className="px-4 py-2">
                <UserPicker
                    onUserSelected={handleUserSelected}
                    usersToHide={attendees}
                    onAddRow={handleAddRow}
                    color="primary"
                    icon={faPlus}
                >
                    <Trans>Add attendee</Trans>
                </UserPicker>
            </div>
        </>
    );
};
