import React, { useEffect, useRef } from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Editor } from 'slate';
import { Card, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import {
    getSearchParamsWithoutTopicId,
    MeetingViewSearchParams,
} from 'Pages/meeting/components/MeetingView/MeetingView';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { ConfirmDeleteFutureTopicsModal } from 'Shared/components/meeting/topicView/ConfirmDeleteFutureTopicsModal';
import { TopicCardFooter } from 'Shared/components/meeting/topicView/TopicCardFooter';
import { TopicHeader } from 'Shared/components/meeting/topicView/TopicHeader';
import { TopicSubmission } from 'Shared/components/meeting/topicView/TopicSubmission';
import { usePreviousAndNextTopic, useUpdateTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { MeetingPermission, useUserHasMeetingSectionPermission } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const TopicCard = ({ topicId }: { topicId: string }) => {
    const editorRef = useRef<Editor>();

    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { currentUser } = useCurrentUserContext();
    const { meetingId, meeting } = useMeetingContext();
    const {
        data: topic,
        isLoading,
        isFetching,
        isInitialLoading,
    } = trpc.meetingTopic.get.useQuery(topicId, { select: camelToSnake });
    const { previousTopic, nextTopic } = usePreviousAndNextTopic(meetingId, topicId);
    // We keep this in case the topic is deleted / postponed
    const previousPreviousTopic = usePrevious(previousTopic);

    const [updateTopics] = useUpdateTopicsMutation();

    const { hasPermission: canEditTopicContent } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.EDIT_TOPIC_CONTENT
    );

    const { hasPermission: canManageTopic } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.MANAGE_TOPIC
    );

    const isTopicSubmission = topic != null && topic?.meeting_id !== meetingId;

    useEffect(() => {
        if (topic == null && !isLoading && !isFetching && !isInitialLoading) {
            setSearchParams(
                previousPreviousTopic?.id
                    ? { ...searchParams, topicId: previousPreviousTopic?.id.toString() }
                    : getSearchParamsWithoutTopicId(searchParams),
                { replace: true }
            );
        }
    }, [topic, isLoading, isFetching, previousPreviousTopic, isInitialLoading]);

    const handlePreviousTopic = () => {
        if (previousTopic) {
            setSearchParams({ topicId: previousTopic.id.toString() }, { replace: true });
        } else {
            setSearchParams(getSearchParamsWithoutTopicId(searchParams), { replace: true });
        }
    };

    const handleNextTopic = () => {
        if (nextTopic) {
            setSearchParams({ topicId: nextTopic.id.toString() }, { replace: true });
        }
    };

    const handleTopicChange = async (changes: Partial<MeetingTopic>, safe): Promise<void> => {
        const result = await updateTopics({ meetingId, topics: [{ id: topicId, changes: changes }], safe });
        if ('error' in result) {
            const error = result.error as ApiError;
            if (error.matches({ path: 'ConflictError' })) {
                const res = await confirm<boolean>({}, ConfirmDeleteFutureTopicsModal);
                if (res === true) {
                    void handleTopicChange(changes, false);
                }
            } else {
                show(UnexpectedErrorNotification);
            }
            return;
        }
        void trpcUtils().meetingTopic.get.invalidate(topicId);
        if (changes.addressed || changes.revisited) {
            setTimeout(() => {
                handleNextTopic();
            }, 600);
        } else if (changes.meeting_id) {
            handleNextTopic();
        }
    };

    if (isTopicSubmission) {
        return <TopicSubmission topicId={topicId} />;
    }

    return (
        <Card id={`topic-${topicId}`} className="!bg-transparent" data-section-id={topic?.meeting_section_id}>
            <TopicHeader editorRef={editorRef} topic={topic} />
            <div className="flex min-h-[500px]">
                {topic != null && meeting != null && (
                    <TopicEditor
                        editorRef={editorRef}
                        topic={{ ...topic, meeting }}
                        isReadOnly={!canEditTopicContent}
                        isVoteOnly={!canEditTopicContent}
                        viewMode={MeetingViewMode.TopicView}
                    />
                )}
            </div>
            <TopicCardFooter
                topicId={topicId}
                topic={topic}
                onPreviousTopic={handlePreviousTopic}
                previousTooltipText={
                    previousTopic ? previousTopic.display_id + ' ' + previousTopic.title : t`Attendees list`
                }
                onNextTopic={handleNextTopic}
                nextTooltipText={nextTopic ? nextTopic?.display_id + ' ' + nextTopic?.title : null}
                onTopicChange={handleTopicChange}
                hasNext={!!nextTopic}
                canEdit={canManageTopic}
            />
        </Card>
    );
};
