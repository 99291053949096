import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { BoxComIcon } from './BoxComIcon';
import { CmiCloudIcon } from './CmiCloudIcon';
import { ConfluenceIcon } from './ConfluenceIcon';
import { DropboxIcon } from './DropboxIcon';
import { EvernoteIcon } from './EvernoteIcon';
import { ExternalLinkIcon } from './ExternalLinkIcon';
import { FigmaIcon } from './FigmaIcon';
import { GoogleDriveIcon } from './GoogleDriveIcon';
import { JiraIcon } from './JiraIcon';
import { KDriveIcon } from './KDriveIcon';
import { LinkedInIcon } from './LinkedInIcon';
import { MFilesIcon } from './MFilesIcon';
import { NetflixIcon } from './NetflixIcon';
import { NotionIcon } from './NotionIcon';
import { OnedriveIcon } from './OnedriveIcon';
import { SharePointIcon } from './SharePointIcon';
import { SlackIcon } from './SlackIcon';
import { TrelloIcon } from './TrelloIcon';
import { YoutubeIcon } from './YoutubeIcon';

export type IconName =
    | 'boxCom'
    | 'cmiCloud'
    | 'confluence'
    | 'dropbox'
    | 'evernote'
    | 'externalLink'
    | 'figma'
    | 'googleDrive'
    | 'jira'
    | 'kDrive'
    | 'linkedIn'
    | 'mFiles'
    | 'netflix'
    | 'notion'
    | 'onedrive'
    | 'sharePoint'
    | 'slack'
    | 'trello'
    | 'youtube';

type ImageIconProps = {
    ariaLabel?: string;
    className?: string;
    icon: IconDefinition | IconName;
    color?: string;
    size?: SizeProp;
    style?: Style;
};

export const Icon: FC<ImageIconProps> = ({ icon, style }) => {
    if (typeof icon === 'string') {
        switch (icon) {
            case 'boxCom':
                return <BoxComIcon style={style} />;
            case 'cmiCloud':
                return <CmiCloudIcon style={style} />;
            case 'confluence':
                return <ConfluenceIcon style={style} />;
            case 'dropbox':
                return <DropboxIcon style={style} />;
            case 'evernote':
                return <EvernoteIcon style={style} />;
            case 'figma':
                return <FigmaIcon style={style} />;
            case 'googleDrive':
                return <GoogleDriveIcon style={style} />;
            case 'jira':
                return <JiraIcon style={style} />;
            case 'kDrive':
                return <KDriveIcon style={style} />;
            case 'linkedIn':
                return <LinkedInIcon style={style} />;
            case 'mFiles':
                return <MFilesIcon style={style} />;
            case 'netflix':
                return <NetflixIcon style={style} />;
            case 'notion':
                return <NotionIcon style={style} />;
            case 'onedrive':
                return <OnedriveIcon style={style} />;
            case 'sharePoint':
                return <SharePointIcon style={style} />;
            case 'slack':
                return <SlackIcon style={style} />;
            case 'trello':
                return <TrelloIcon style={style} />;
            case 'youtube':
                return <YoutubeIcon style={style} />;
            default:
                return <ExternalLinkIcon style={style} />;
        }
    }

    const { icon: iconData } = icon;
    const duotone = Array.isArray(iconData[4]);
    const paths = Array.isArray(iconData[4]) ? iconData[4] : [iconData[4]];
    const color = style.color || 'black';

    return (
        <Svg viewBox={`0 0 ${iconData[0]} ${iconData[1]}`} style={style}>
            {paths != null &&
                paths.map((d, index) => (
                    <Path
                        d={d}
                        key={index}
                        strokeWidth={4}
                        fill={color}
                        fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
                    />
                ))}
        </Svg>
    );
};
