import { useLingui } from '@lingui/react';
import React, { useRef, useState } from 'react';
import { faDownload, faFolderOpen } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { intlFormat } from 'date-fns';
import { Alert, Button, ContextModalProps, Modal, Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useLoader } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { useGroupedTasks } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { useTask } from 'Shared/hooks/useTask';
import { useGetChecklistQuery } from 'Shared/services/checklist';
import { useGetCustomFieldGroupsQuery, useGetCustomFieldsQuery } from 'Shared/services/customFields';
import { useGetMeetingQuery } from 'Shared/services/meeting';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetUserQuery } from 'Shared/services/user';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { CustomField } from 'Shared/types/customField';
import { SearchType } from 'Shared/types/search';
import { Task, TaskFilter, TaskOrder, TaskStatus, TaskType } from 'Shared/types/task';
import { UserRole } from 'Shared/types/user';
import { PdfExportTab } from './PdfExportTab';
import { TabularExportTab } from './TabularExportTab';

const UpgradeModal = ({ ...modalProps }: ContextModalProps) => (
    <Modal {...modalProps}>
        <Modal.Header title={t`You have restricted access`}></Modal.Header>
        <Modal.Body>
            <Alert type="danger" title={t`What you can't do in this network?`}>
                <ul className="list-disc">
                    <li>
                        <Trans>Manage a workspace or a template</Trans>
                    </li>
                    <li>
                        <Trans>Assign tasks to colleagues from your network</Trans>
                    </li>
                    <li>
                        <Trans>View task history</Trans>
                    </li>
                    <li>
                        <Trans>Export task lists</Trans>
                    </li>
                    <li>
                        <Trans>View the colleague page</Trans>
                    </li>
                </ul>
            </Alert>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={modalProps.close}>
                <Trans>Close</Trans>
            </Button>
        </Modal.Footer>
    </Modal>
);

export type ExportTabHandle = {
    exportTasks: () => Promise<{ url: string; filename: string }>;
};

type ExportModalProps = {
    checklistId?: Id;
    grouping: TaskOrder;
    hideTasksToExport?: boolean;
    isExportingAllTasks: boolean;
    meetingId?: Id;
    onExport: () => void;
    order: TaskOrder;
    scope: SearchType;
    search: string;
    selectedTasks: Id[];
    statuses: TaskStatus[];
    templateId?: Id;
    userId?: Id;
    view: TaskFilter;
    workspaceId?: Id;
} & ContextModalProps;

export const ExportTasksModal = ({
    checklistId,
    grouping,
    hideTasksToExport = false,
    isExportingAllTasks = false,
    meetingId,
    order,
    scope,
    search,
    selectedTasks = [],
    statuses,
    templateId,
    userId,
    view,
    workspaceId,
    ...modalProps
}: ExportModalProps) => {
    const exportTab = useRef<ExportTabHandle>();
    const [isExportingSelectedTasks, setIsExportingSelectedTasks] = useState(
        isExportingAllTasks || selectedTasks.length <= 0 ? false : true
    );
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const currentUser = useSessionUser();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { i18n } = useLingui();
    const { task } = useTask(selectedTasks?.[0]);
    const { data: checklist } = useGetChecklistQuery(checklistId, { skip: checklistId == null });
    const { data: meeting } = useGetMeetingQuery({ id: meetingId }, { skip: meetingId == null });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: templateId == null });
    const { data: user } = useGetUserQuery(userId, { skip: userId == null });
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: workspaceId == null });
    const [exportedData, setExportedData] = useState<{ url: string; filename: string }>(null);
    const [isReady, setIsReady] = useState(false);
    const { wrap, isLoading } = useLoader();
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const footer = `${intlFormat(new Date(), {}, { locale: i18n.locale })} - ${currentUser.full_name}`;

    const title =
        workspace != null
            ? workspace.name
            : search != null
              ? t`Search results for ${search}`
              : user != null
                ? t`${user.full_name}'s tasks`
                : checklist != null
                  ? `${checklist.checklistTemplate?.name} (${checklist.name})`
                  : template != null
                    ? template.name
                    : view === 'me'
                      ? t`${currentUser.full_name}'s tasks`
                      : view === 'assigned'
                        ? t`Assigned by ${currentUser.full_name}`
                        : isMyTasksPage
                          ? t`${currentUser.full_name}'s tasks`
                          : task?.name;

    const commonParams = {
        footer,
        hideTasksToExport: hideTasksToExport || selectedTasks.length === 0,
        isExportingAllTasks,
        selectedTasks,
        title,
        workspaceId: workspace?.id,
    };

    const tabularParams = {
        ...commonParams,
        checklistId: checklist?.id,
        grouping,
        meetingId: meeting?.id,
        order,
        scope: isExportingAllTasks ? scope : undefined,
        search: isExportingAllTasks ? search : undefined,
        statuses,
        templateId: checklist?.checklistTemplate?.id ?? template?.id,
        userId: user?.id,
        view,
    };

    const { data: list = [] } = trpc.task.list.useQuery(
        {
            checklistId,
            grouping,
            meetingId,
            order,
            pageSize: 10_000,
            related: ['attachment', 'comment'],
            search,
            searchType: scope,
            statuses,
            templateId: checklistId == null ? templateId : undefined,
            userId,
            view,
            workspaceIds: workspaceId != null ? [workspaceId] : undefined,
        },
        { select: camelToSnake, enabled: !isExportingSelectedTasks, refetchOnMount: 'always' }
    );

    const { data: listByIds = [] } = trpc.task.listByIds.useQuery(
        {
            grouping,
            order,
            related: ['attachment', 'comment'],
            taskIds: isExportingSelectedTasks ? selectedTasks : undefined,
        },
        { select: camelToSnake, enabled: isExportingSelectedTasks, refetchOnMount: 'always' }
    );
    const taskList = isExportingSelectedTasks ? listByIds : list;

    const { data: customFieldsList = [] } = useGetCustomFieldsQuery();
    const { data: allCustomFieldGroups = [] } = useGetCustomFieldGroupsQuery();

    const allCustomFields: Array<CustomField & { group_label?: string; group_icon?: string }> = [
        ...customFieldsList,
        ...allCustomFieldGroups.flatMap((group) =>
            group.customFields.map((customField) => {
                return {
                    ...customField,
                    group_label: group.label,
                    group_icon: group.icon,
                    group_order: group.order,
                };
            })
        ),
    ];

    const createCustomFieldForExport = (task: Task) => {
        return task.custom_fields?.map((customField) => {
            const customFieldFound = allCustomFields.find(({ id }) => id === customField.custom_field_id);
            return {
                ...customField,
                icon: customFieldFound?.icon || '',
                group_icon: customFieldFound?.group_icon,
                group_label: customFieldFound?.group_label,
                group_order: customFieldFound?.group_order,
                label: customFieldFound?.label,
                type: customFieldFound?.type,
                option_label: customFieldFound?.options.find(
                    (option) => option.id === customField.custom_field_option_id
                )?.label,
                attachment_value: customField.attachment_value?.filename,
                group_id: customField.custom_group_value_id,
                order: customFieldFound?.order,
            };
        });
    };

    const groupedTasks = useGroupedTasks(
        taskList.map((task: Task) => ({
            ...task,
            custom_fields: createCustomFieldForExport(task),
        })),
        workspaceId,
        checklistId,
        templateId,
        grouping
    );

    const handleTasksToExportChange = (isSelectedTasks: boolean) => {
        setIsExportingSelectedTasks(isSelectedTasks);
    };

    const handleExport = async () => {
        const exportedData = await wrap(exportTab.current.exportTasks());
        setExportedData(exportedData);
        void registerBadgeActivity('EXPORT_TASK_LIST_1');
    };

    if (currentUser.role === UserRole.LIGHT || currentUser.role === UserRole.EXTERNAL) {
        return <UpgradeModal {...modalProps} />;
    }

    return (
        <Modal size="md" {...modalProps}>
            <Modal.Header
                title={
                    selectedTasks?.length === 1 && task?.type === TaskType.Milestone
                        ? t`Export milestone`
                        : t`Export tasks`
                }
            />
            {exportedData != null ? (
                <div className="flex justify-center gap-2 min-h-[310px] items-center">
                    <Button
                        icon={faDownload}
                        href={exportedData.url}
                        download={exportedData.filename}
                        target="_blank"
                        size="lg"
                    >
                        <Trans>Download file</Trans>
                    </Button>
                    {(selectedIndex === 0 || selectedIndex == null) && (
                        <Button icon={faFolderOpen} href={exportedData.url} target="_blank" size="lg">
                            <Trans>Open file</Trans>
                        </Button>
                    )}
                </div>
            ) : (
                <Tabs selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                    <Tabs.Header>
                        <Tabs.Tab isDisabled={isLoading}>PDF</Tabs.Tab>
                        <Tabs.Tab isDisabled={isLoading}>Excel</Tabs.Tab>
                        <Tabs.Tab isDisabled={isLoading}>CSV</Tabs.Tab>
                    </Tabs.Header>
                    <Modal.Body>
                        <Tabs.Panels>
                            <Tabs.Panel>
                                <PdfExportTab
                                    ref={exportTab}
                                    {...commonParams}
                                    groupedTasks={groupedTasks}
                                    isTemplate={templateId != null}
                                    handleToggleSelectedTasks={handleTasksToExportChange}
                                    isGroupTitleHidden={hideTasksToExport && taskList.length === 1}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <TabularExportTab
                                    ref={exportTab}
                                    format="excel"
                                    taskList={taskList}
                                    {...tabularParams}
                                    onReady={setIsReady}
                                    handleToggleSelectedTasks={handleTasksToExportChange}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <TabularExportTab
                                    ref={exportTab}
                                    format="csv"
                                    taskList={taskList}
                                    {...tabularParams}
                                    onReady={setIsReady}
                                    handleToggleSelectedTasks={handleTasksToExportChange}
                                />
                            </Tabs.Panel>
                        </Tabs.Panels>
                    </Modal.Body>
                </Tabs>
            )}
            <Modal.Footer>
                {exportedData != null && (
                    <Button
                        onClick={() => {
                            setIsExportingSelectedTasks(
                                isExportingAllTasks || selectedTasks.length <= 0 ? false : true
                            );
                            setExportedData(null);
                        }}
                    >
                        <Trans>Back</Trans>
                    </Button>
                )}
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                {exportedData == null && (
                    <Button
                        color="primary"
                        onClick={handleExport}
                        disabled={[1, 2].includes(selectedIndex) && !isReady}
                        loading={isLoading}
                    >
                        <Trans>Generate file</Trans>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
