import { FC, useEffect, useRef } from 'react';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Alert, EmptyState, Table } from '@wedo/design-system';
import { VerifyUploadRow } from 'Pages/settings/users/components/ImportModal/VerifyUploadRow';
import { UploadUsersReturn } from 'Shared/services/admin';

export type UserField = keyof UploadUsersReturn | keyof UploadUsersReturn['user'];

type VerifyUploadTabProps = {
    users: UploadUsersReturn[];
    hasError: number;
    onChange: (users: UploadUsersReturn[]) => void;
};

export const VerifyUploadTab: FC<VerifyUploadTabProps> = ({ users, hasError, onChange }) => {
    const errorRef = useRef<HTMLDivElement>();

    const handleDelete = (index: number): void => {
        onChange(users.filter((users, i) => i !== index));
    };

    const handleChange = (index: number, field: UserField, value: string) => {
        const newList = users.map((user, i) => {
            if (i !== index) return user;

            switch (field) {
                case 'first_name':
                    return { ...user, user: { ...user.user, first_name: value } };
                case 'last_name':
                    return { ...user, user: { ...user.user, last_name: value } };
                case 'title':
                    return { ...user, title: value };
                case 'email':
                    return { ...user, email: value };
                case 'role':
                    return { ...user, role: value };
                case 'language_code':
                    return { ...user, user: { ...user.user, language_code: value } };
                case 'organisation_name':
                    return { ...user, organisation_name: value };
                case 'department':
                    return { ...user, department: value };
                case 'phone_number':
                    return { ...user, phone_number: value };
                default:
                    return user;
            }
        });

        onChange(newList);
    };

    useEffect(() => {
        if (hasError > 0 && errorRef?.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [hasError]);

    if (users.length === 0) {
        return (
            <div className="flex h-full items-center">
                <EmptyState icon={faUsers} className="mx-auto my-4" size="lg">
                    <EmptyState.Text>
                        <Trans>No users were found in the uploaded file</Trans>
                    </EmptyState.Text>
                </EmptyState>
            </div>
        );
    }

    return (
        <div ref={errorRef} className="mt-4">
            {hasError > 0 && (
                <Alert
                    className="mb-4"
                    type="danger"
                    title={t`Users import failed. Please verify the status column and update the users. Then retry to import.`}
                />
            )}
            <Table size="condensed">
                <Table.Head>
                    <Table.HeadCell>
                        <Trans>First name</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Trans>Last name</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Trans>Email</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Trans>Phone</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Trans>Organisation</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Trans>Department</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Trans>Job title</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className="w-28">
                        <Trans>Type</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className="w-18">
                        <Trans>Language</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className="w-22 text-center">
                        <Trans>Status</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className="w-16" />
                </Table.Head>
                <Table.Body>
                    {users.map((user, index) => (
                        <VerifyUploadRow
                            key={index}
                            user={user}
                            index={index}
                            onDelete={handleDelete}
                            onChange={handleChange}
                        />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};
