import { Fragment } from 'react';
import { useStore } from 'zustand';
import { useGanttContextStore } from './GanttContext';
import { RowsBackgroundImage } from './GanttView';
import { ListAddTask } from './ListAddTask';
import { ListHeader } from './ListHeader';
import { ListMeetings } from './ListMeetings';
import { ListSection } from './ListSection';
import { ListTask } from './ListTask';

export const DefaultListViewMinWidth = 400;

export const ListView = () => {
    const store = useGanttContextStore()!;

    const sectionsWithTasks = useStore(store, ({ data }) => data.sectionsWithTasks!);
    const closedSections = useStore(store, ({ view }) => view.closedSections);

    return (
        <div
            className="hidden md:grid z-10 grid-cols-[min-content_1fr_min-content_min-content] auto-rows-[var(--row-height)] whitespace-nowrap sticky left-0 bg-white min-h-full h-fit w-[var(--list-view-width)] flex-shrink-0 min-w-min gap-y-px isolate"
            style={{ backgroundImage: RowsBackgroundImage }}
        >
            <ListHeader />
            <ListMeetings />
            {sectionsWithTasks.map(
                (section, sectionIndex) =>
                    (section.id !== '' || section.tasks.length > 0) && (
                        <Fragment key={section.id}>
                            <ListSection section={section} wbs={section.id === '' ? '' : sectionIndex.toString()} />
                            {!closedSections.has(section.id) && (
                                <>
                                    {section.tasks.map((task, taskIndex) => (
                                        <ListTask
                                            key={task.id}
                                            task={task}
                                            section={section}
                                            wbs={section.id === '' ? '' : `${sectionIndex}.${taskIndex + 1}`}
                                        />
                                    ))}
                                    <ListAddTask section={section} />
                                </>
                            )}
                        </Fragment>
                    )
            )}
        </div>
    );
};
