import { detectOverflow } from '@floating-ui/react';
import { size } from '@floating-ui/dom';

export const preventOverflowMiddleware = {
    name: 'preventOverflow',
    fn: async (state) => {
        const overflow = await detectOverflow(state);
        const stateChanges: {
            x?: number;
            y?: number;
        } = {};
        if (overflow.left > 0) {
            stateChanges.x = 0;
        }
        if (overflow.right > 0) {
            stateChanges.x = Math.max(0, state.x - overflow.right);
        }
        if (overflow.top > 0) {
            stateChanges.y = 0;
        }
        if (overflow.bottom > 0) {
            stateChanges.y = Math.max(0, state.y - overflow.bottom);
        }
        return stateChanges;
    },
};

export const restrictSizeMiddleware = size({
    apply({ availableWidth, availableHeight, elements }) {
        Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
        });
    },
});
