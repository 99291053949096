import React, { useState } from 'react';
import { faPencil, faPlus, faTrash, faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisV as fasEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { isEmpty } from 'lodash-es';
import { Button, ContextModalProps, Label, Modal, Tag, Textarea, useModal, Dropdown } from '@wedo/design-system';
import { CircleMemberProfileModal } from 'Pages/governance/CircleMember/CircleMemberProfileModal';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { Circle, CircleMember, Root } from 'Shared/types/governance';

const EditScopeModal = ({
    title,
    initialScope,
    onUpdate,
    ...modalProps
}: {
    title: string;
    initialScope?: string;
    onUpdate: (changes: Partial<CircleMember>) => Promise<void>;
} & ContextModalProps) => {
    const [scope, setScope] = useState(initialScope || '');

    const handleSave = async () => {
        await onUpdate({ scope });
        await modalProps.close();
    };
    return (
        <Modal size={'sm'} position="" {...modalProps}>
            <Modal.Header title={title} />
            <Modal.Body>
                <Label>
                    {t`Scope`}
                    <Textarea value={scope} onChange={(e) => setScope(e.target.value)} />
                </Label>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button onClick={handleSave} color={'primary'}>
                    {isEmpty(initialScope) ? t`Add` : t`Save`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

type CircleMemberCardProps = {
    member: CircleMember;
    circle: Circle;
    isEditing: boolean;
    root: HierarchyCircularNode<Circle | Root>;
    onDelete: () => Promise<void>;
    onUpdate: (changes: Partial<CircleMember>) => Promise<void>;
};

export const CircleMemberCard = ({ member, circle, isEditing, root, onDelete, onUpdate }: CircleMemberCardProps) => {
    const { open: openModal } = useModal();
    const handleClick = () => {
        openModal(CircleMemberProfileModal, {
            user: member.user,
            root,
        });
    };

    const handleClickEditScope = (e) => {
        e.stopPropagation();
        openModal(EditScopeModal, {
            title: !isEmpty(member.scope) ? t`Edit scope` : t`Add scope`,
            initialScope: member.scope,
            onUpdate,
        });
    };
    const handleClickEditCoreAdmin = (e) => {
        e.stopPropagation();
        if (circle.type === 'role') {
            void onUpdate({ is_core: !member.is_core });
        } else {
            void onUpdate({ is_admin: !member.is_admin });
        }
    };

    const handleClickDelete = async (e) => {
        e.stopPropagation();
        await onDelete();
    };

    return (
        <Button
            className={'!h-max !justify-between !p-2 !text-start !items-start'}
            aria-label={t`View member's circle and roles`}
            onClick={handleClick}
        >
            <div className={'flex flex-grow flex-row overflow-hidden gap-2'}>
                <div className={'shrink-0'}>
                    <UserAvatar user={member.user} />
                </div>
                <div className="flex-1 overflow-hidden flex flex-col justify-center">
                    <div className={'truncate'}>{member.user.full_name}</div>
                    {member.scope && (
                        <div className={'truncate text-xs text-gray-600 mt-0.5 whitespace-pre-line'}>
                            {member.scope}
                        </div>
                    )}
                </div>
                {circle?.type === 'circle' && (member.is_core || member.is_admin) && (
                    <div className={'flex-shrink flex items-center'}>
                        <Tag size="xs" color="blue">
                            {member.is_admin && t`Administrator`}
                        </Tag>
                    </div>
                )}
            </div>
            {isEditing && (
                <Dropdown className={'mt-[3px]'} icon={fasEllipsisV} size="sm" onClick={(e) => e.stopPropagation()}>
                    {circle?.type === 'role' && (
                        <Dropdown.Item onClick={handleClickEditScope} icon={!isEmpty(member.scope) ? faPencil : faPlus}>
                            {!isEmpty(member.scope) ? t`Edit scope` : t`Add scope`}
                        </Dropdown.Item>
                    )}
                    {circle?.type === 'circle' && (
                        <Dropdown.Item
                            disabled={member.is_admin && circle.members.filter((m) => m.is_admin).length === 1}
                            icon={faUserShield}
                            onClick={handleClickEditCoreAdmin}
                        >
                            {member.is_admin ? t`Revoke admin` : t`Promote as admin`}
                        </Dropdown.Item>
                    )}

                    <Dropdown.Item
                        disabled={
                            circle.type === 'circle' &&
                            member.is_admin &&
                            circle.members.filter((m) => m.is_admin).length === 1
                        }
                        danger={true}
                        icon={faTrash}
                        onClick={handleClickDelete}
                    >
                        {t`Delete`}
                    </Dropdown.Item>
                </Dropdown>
            )}
        </Button>
    );
};
