import React, { FC, MutableRefObject, useState } from 'react';
import { Link } from 'react-router-dom';
import { faCheck, faStopwatch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Editor } from 'slate';
import { Button, Card, Tag } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { getUser, useUser } from 'App/store/usersStore';
import { useAcceptTopicSubmission } from 'Pages/MyTopicsPage/hooks/useAcceptTopicSubmission';
import { useRejectTopicSubmission } from 'Pages/MyTopicsPage/hooks/useRejectTopicSubmission';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { ErrorCard } from 'Shared/components/error/ErrorCard';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingSectionsSelect } from 'Shared/components/meeting/MeetingSectionsSelect';
import { SubmittedTopicDropdown } from 'Shared/components/meeting/topicView/SubmittedTopicDropdown';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { Meeting } from 'Shared/types/meeting';
import { MeetingTopic, MeetingTopicSubmission } from 'Shared/types/meetingTopic';

export const TopicSubmissionCard: FC<{
    meeting: Meeting;
    topic: MeetingTopic;
    topicSubmission: MeetingTopicSubmission;
    canEdit: boolean;
    editorRef: MutableRefObject<Editor>;
}> = ({ meeting, topic, topicSubmission, canEdit, editorRef }) => {
    const submittedBy = useUser(topicSubmission?.createdBy);
    const [, setSearchParams] = useSearchParams(MeetingViewSearchParams);
    const [sectionId, setSectionId] = useState<string>('no-section');

    const { acceptSubmission } = useAcceptTopicSubmission(topic.id, topicSubmission?.id);
    const { rejectSubmission } = useRejectTopicSubmission(topic.id, topicSubmission?.id);

    const handleReject = async () => {
        const shouldRejectSubmission = await rejectSubmission();
        if (shouldRejectSubmission) {
            setSearchParams((current) => ({ ...current, topicId: undefined }), { replace: true });
        }
    };

    const handleAcceptSubmission = async () => {
        const shouldAccept = await acceptSubmission(sectionId);
        if (shouldAccept) {
            setSearchParams((current) => ({ ...current, topicId: undefined }), { replace: true });
        }
    };
    const isAcceptDisabled = sectionId == null || sectionId === '';

    if (topicSubmission.status != null) {
        return (
            <Card>
                <ErrorCard
                    errorSymbol={<img src="/assets/denied.svg" alt="" />}
                    title={t`This resource is either missing or you don't have access to it`}
                    subTitle={t`If you think you should have access, please contact your administrator.`}
                />
            </Card>
        );
    }

    return (
        <Card
            id={`topic-${topic.id}`}
            className="!bg-transparent @container"
            data-section-id={topic.meeting_section_id}
        >
            <div className="flex items-center justify-between gap-2 p-2 bg-gray-200 border-b">
                <div>
                    <div className="pl-2 font-semibold max-w-full truncate">{topic.title}</div>
                    <div className="text-xs pl-2">
                        {topicSubmission.sourceMeetingId != null ? (
                            <Trans>
                                Submitted by {submittedBy?.full_name} from{' '}
                                <Link
                                    className="text-blue-600 hover:text-blue-700"
                                    to={`/meetings/${topicSubmission.sourceMeeting.id}`}
                                >
                                    <FormatMeetingTitle
                                        title={topicSubmission.sourceMeeting.title}
                                        startAt={topicSubmission.sourceMeeting.startAt}
                                    />
                                </Link>
                            </Trans>
                        ) : (
                            <Trans>Submitted by {submittedBy?.full_name}</Trans>
                        )}
                    </div>
                </div>
                <div className="flex gap-2 items-center">
                    <UsersAvatarGroup
                        users={topic.presenters.map(({ user_id }) => getUser(user_id))}
                        size="sm"
                        maxDisplayed={4}
                    />
                    {topic.duration > 0 && (
                        <Tag icon={faStopwatch} color="gray">
                            {topic.duration}
                        </Tag>
                    )}
                    {canEdit && (
                        <SubmittedTopicDropdown
                            topicId={topic.id}
                            sectionId={sectionId}
                            submissionId={topicSubmission?.id}
                            variant={'filled'}
                        />
                    )}
                </div>
            </div>
            <div className="flex min-h-[500px]">
                <TopicEditor
                    isReadOnly
                    isStatic
                    editorRef={editorRef}
                    topic={{ ...topic, meeting }}
                    viewMode={MeetingViewMode.TopicView}
                />
            </div>
            {canEdit && (
                <div className="flex gap-2 items-center justify-between p-4 border-t">
                    <div>
                        <Button color="danger" icon={faXmark} onClick={handleReject}>
                            <Trans>Reject</Trans>
                        </Button>
                    </div>
                    <div className="flex gap-2 grow justify-end">
                        <MeetingSectionsSelect
                            meeting={meeting}
                            value={sectionId}
                            onChange={setSectionId}
                            wrapperClassName="max-w-64 grow"
                        />
                        <Button
                            icon={faCheck}
                            color="success"
                            onClick={handleAcceptSubmission}
                            disabled={isAcceptDisabled}
                            title={isAcceptDisabled && t`You must choose a section for this topic`}
                        >
                            <Trans>Accept</Trans>
                        </Button>
                    </div>
                </div>
            )}
        </Card>
    );
};
