import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { FormatDateDistance } from '@wedo/design-system';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useGetUserQuery } from 'Shared/services/user';
import { MeetingBlock } from 'Shared/types/meetingBlock';

type LastUpdateInfoProps = {
    meetingBlock: MeetingBlock;
};

export const LastUpdateInfo: FC<LastUpdateInfoProps> = ({ meetingBlock }) => {
    const { data: createdBy } = useGetUserQuery(meetingBlock?.created_by);
    const { data: updatedBy } = useGetUserQuery(meetingBlock?.updated_by);

    const content = () => {
        return (
            <div className="text-xs">
                {meetingBlock.updated_by && (
                    <>
                        <Trans>
                            Last update by <b>{updatedBy?.full_name}</b>
                        </Trans>
                        &nbsp;
                        <FormatDateDistance date={meetingBlock.updated_at} />
                    </>
                )}
                {meetingBlock.created_by &&
                    meetingBlock.updated_by &&
                    meetingBlock.created_by !== meetingBlock.updated_by && (
                        <>
                            <Trans>
                                Created by <b>{createdBy?.full_name}</b>
                            </Trans>
                            &nbsp;
                            <FormatDateDistance date={meetingBlock.created_at} />
                        </>
                    )}
            </div>
        );
    };
    //TODO
    if (!meetingBlock) {
        return <div />;
    }

    return (
        <div contentEditable={false}>
            {meetingBlock.updated_by && <UserAvatar user={updatedBy} size="xs" tooltipContent={content()} />}
        </div>
    );
};
