import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ComponentProps } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { NavLink, NavLinkOptions } from '../NavLink/NavLink';

const classes = {
    list: 'flex flex-wrap items-center',
    item: {
        chevron: 'mx-1 h-3 w-3 text-gray-400 group-first:hidden md:mx-2',
        li: 'group flex items-center',
        base: 'inline-flex items-center text-sm font-medium',
        href: {
            off: 'text-gray-500',
            on: 'text-gray-700 hover:text-gray-900 cursor-pointer',
        },
    },
    icon: 'mr-2 h-4 w-4',
};

export type BreadcrumbItemProps = {
    to?: NavLinkOptions['to'];
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    icon?: IconProp;
} & ComponentProps<'li'>;

const BreadcrumbItem = ({ onClick, icon, children, className, to, ...props }: BreadcrumbItemProps): JSX.Element => {
    const isLink = to != null;
    const Component = isLink ? NavLink : 'span';

    return (
        <li className={clsx(classes.item.li, className)} {...props}>
            <FontAwesomeIcon icon={faChevronRight} aria-hidden className={classes.item.chevron} />
            <Component
                className={clsx(classes.item.base, classes.item.href[isLink || onClick != null ? 'on' : 'off'])}
                to={to}
                onClick={onClick}
            >
                {icon && <FontAwesomeIcon icon={icon} aria-hidden className={classes.icon} />}
                <div className="max-w-[12rem] truncate whitespace-nowrap">{children}</div>
            </Component>
        </li>
    );
};

export const Breadcrumb = ({ children, ...props }: ComponentProps<'nav'>) => {
    return (
        <nav aria-label="Breadcrumb" {...props}>
            <ol className={classes.list}>{children}</ol>
        </nav>
    );
};

BreadcrumbItem.displayName = 'Breadcrumb.Item';

Breadcrumb.Item = BreadcrumbItem;
