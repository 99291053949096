import type { ChangeEvent } from 'react';
import { Form, Input } from '@wedo/design-system';
import { AutoId } from '@wedo/design-system/src/lib/components/Form/Form';
import { type Store } from '@wedo/pdf/meeting/store';
import { tryParseInt } from '@wedo/utils';
import { setSetting } from './store';

export enum Margin {
    Top,
    Right,
    Bottom,
    Left,
}

type MarginInputProps = {
    id?: string;
    label: string;
    store: Store;
    margin: Margin;
    margins: [number, number, number, number];
    isDisabled: boolean;
};

export const MarginInput = ({ id, label, store, margin, margins, isDisabled }: MarginInputProps) => {
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(Math.min(tryParseInt(target.value, 10), 50), 0);
        setSetting(store, 'margins', Object.assign([], margins, { [margin]: value }));
    };

    return (
        <Form.Item htmlFor={AutoId} cols={3} label={label}>
            <Input
                id={id}
                disabled={isDisabled}
                placeholder={label}
                inputClassName="min-w-0"
                value={margins[margin]}
                type="number"
                max={50}
                min={0}
                onChange={handleChange}
                debounce={300}
            />
        </Form.Item>
    );
};
