import { useLingui } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import {
    Alert,
    Bubble,
    Button,
    Checkbox,
    ContextModalProps,
    Input,
    Label,
    Modal,
    Steps,
    Textarea,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useLocalStorage } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useShareMeetingMutation } from 'Shared/services/meeting';
import { useUpdateMeetingUsersMutation } from 'Shared/services/meetingUser';
import { MeetingStatus } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { User } from 'Shared/types/user';

type SendByEmailModalProps = { meetingId: Id } & ContextModalProps;

const defaultMessagesTranslations = () => ({
    [MeetingStatus.PREPARATION]: t`Hi,
                    The meeting agenda is in preparation.
                    Please have a look and prepare your topics.
                    Kind regards`,
    [MeetingStatus.AGENDA_READY]: t`Hi, 
                    The meeting agenda is validated.
                    Please read it and prepare your meeting.
                    Kind regards`,
    [MeetingStatus.ONGOING]: t`Hi, 
                    The meeting minutes are currently written.
                    You can read them live during the meeting.
                    Kind regards`,
    [MeetingStatus.FINISHED]: t`Hi, 
                    The meeting minutes are in review.
                    Please read them and make your remarks.
                    Kind regards`,
    [MeetingStatus.LOCKED]: t`Hi, 
                    The meeting minutes are locked.
                    Please take some time to read them.
                    Kind regards`,
});

export const SendMeetingByEmailModal = ({ meetingId, ...modalProps }: SendByEmailModalProps) => {
    const { currentUser } = useCurrentUserContext();
    const { meeting } = useMeeting(meetingId);
    const { i18n } = useLingui();
    const { show: showNotification } = useNotification();

    const defaultMessages = defaultMessagesTranslations();

    const [step, setStep] = useState(1);

    const [oldDefaultSubject, setOldDefaultSubject] = useState('');
    const [subject, setSubject] = useState(oldDefaultSubject);

    const [oldDefaultMessage, setOldDefaultMessage] = useState('');
    const [message, setMessage] = useState(oldDefaultMessage);

    const [oldDefaultRecipients, setOldDefaultRecipients] = useState<Partial<MeetingUser>[]>([]);
    const [recipients, setRecipients] = useState<Partial<MeetingUser>[]>(oldDefaultRecipients);

    const [usersToAdd, setUsersToAdd] = useState<User[]>([]);

    const [shareMeeting, { isLoading: isSharing }] = useShareMeetingMutation();
    const [updateMeetingUsers] = useUpdateMeetingUsersMutation();

    const [seriesShareMessage, setSeriesShareMessage] = useLocalStorage<string>(
        'series-share-message-' + meeting?.series_master_id + '-' + meeting?.status
    );

    const defaultRecipients = useMemo(() => {
        return meeting.meetingUsers.filter((mu) => !!mu.user);
    }, [meeting?.meetingUsers]);

    const defaultSubject = useMemo(() => {
        return formatMeetingTitle(meeting, i18n);
    }, [i18n, meeting]);

    const showAddRecipientsError = () => {
        showNotification(UnexpectedErrorNotification);
    };

    const addNewRecipientsAsMeetingUsers = async () => {
        await updateMeetingUsers({
            changes: {
                addedMeetingUsers: [
                    ...usersToAdd.map((u) => ({ user_id: u.id, meeting_role_code: 'reader', is_attendee: false })),
                ],
            },
            meetingId: meeting.id,
        })
            .unwrap()
            .catch(() => {
                showAddRecipientsError();
            });
    };

    const handleConfirm = async () => {
        if (!isEmpty(usersToAdd)) {
            await addNewRecipientsAsMeetingUsers();
        }
        const data = {
            meeting_id: meeting.id,
            parameters: {
                subject: subject,
                body: message,
                meetingTitle: formatMeetingTitle(meeting, i18n),
                intro: t`${currentUser.full_name} has shared a meeting with you.`,
            },
            recipients: recipients,
        };
        const result = await shareMeeting(data);
        if (!('error' in result)) {
            showNotification({ type: 'success', title: t`Email sent successfully` });
            if (message !== defaultMessages[meeting?.status]) {
                setSeriesShareMessage(message);
            }
            void modalProps.close();
        } else {
            showAddRecipientsError();
        }
    };

    if (oldDefaultSubject !== defaultSubject) {
        setSubject(defaultSubject);
        setOldDefaultSubject(defaultSubject);
    }

    if (!seriesShareMessage && oldDefaultMessage !== defaultMessages[meeting?.status]) {
        setMessage(defaultMessages[meeting?.status]);
        setOldDefaultMessage(defaultMessages[meeting?.status]);
    } else if (seriesShareMessage && oldDefaultMessage !== seriesShareMessage) {
        setMessage(seriesShareMessage);
        setOldDefaultMessage(seriesShareMessage);
    }

    if (oldDefaultRecipients !== defaultRecipients) {
        setRecipients(defaultRecipients);
        setOldDefaultRecipients(defaultRecipients);
    }

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={t`Send meeting by email`} />
            <Steps className={'!rounded-none'}>
                <Steps.Step index={1} currentIndex={step} onClick={() => setStep(1)}>
                    {t`Choose recipients`}
                </Steps.Step>
                <Steps.Step index={2} currentIndex={step} onClick={() => setStep(2)}>
                    {t`Customize message`}
                </Steps.Step>
            </Steps>
            <Modal.Body>
                <div className={'min-h-[300px]'}>
                    {step === 1 && (
                        <div className={'flex h-full min-h-[300px] flex-col justify-between gap-4'}>
                            <div className={'mt-3 flex flex-wrap items-center gap-3'}>
                                {defaultRecipients.map((mu) => (
                                    <div
                                        key={mu.id}
                                        className={clsx(
                                            'relative flex items-center gap-2 rounded-full pr-2',
                                            recipients.some((r) => r.id === mu.id)
                                                ? 'bg-blue-300 hover:bg-blue-400'
                                                : 'bg-gray-200 hover:bg-gray-300'
                                        )}
                                    >
                                        <Label
                                            className="flex max-w-[250px] cursor-pointer select-none"
                                            inputType="inline"
                                            htmlFor={String(mu.id)}
                                        >
                                            <MeetingUserAvatar size="sm" user={mu} />
                                            <div
                                                className={'flex min-w-0 max-w-full items-center gap-1.5 px-2 text-sm'}
                                            >
                                                <span className={'shrink truncate'}>{mu.user.full_name}</span>
                                            </div>
                                        </Label>
                                        <Checkbox
                                            checked={recipients.some((r) => r.id === mu.id)}
                                            onChange={(e) => {
                                                if (e.currentTarget.checked) {
                                                    setRecipients((recipients) => [...recipients, mu]);
                                                } else {
                                                    setRecipients((recipients) =>
                                                        recipients.filter((r) => r.id !== mu.id)
                                                    );
                                                }
                                            }}
                                            id={String(mu.id)}
                                            name={'recipients'}
                                        />
                                    </div>
                                ))}
                                {usersToAdd.map((user) => (
                                    <div
                                        key={user.id}
                                        className={
                                            'relative flex items-center gap-2 rounded-full bg-blue-300 pr-2 hover:bg-blue-400'
                                        }
                                    >
                                        <Label
                                            className="flex max-w-[250px] cursor-pointer select-none"
                                            inputType="inline"
                                            htmlFor={String(user.id)}
                                        >
                                            <UserAvatar size="sm" user={user} />
                                            <div
                                                className={'flex min-w-0 max-w-full items-center gap-1.5 px-2 text-sm'}
                                            >
                                                <span className={'shrink truncate'}>{user.full_name}</span>
                                            </div>
                                        </Label>
                                        <Checkbox
                                            checked={true}
                                            onChange={() => {
                                                setUsersToAdd((usersToAdd) =>
                                                    usersToAdd.filter((u) => u.id !== user.id)
                                                );
                                            }}
                                            id={'user-checkbox-' + user.id}
                                            name={'recipients'}
                                        />
                                        <Tooltip content={t`Will be added to the meeting as a "reader"`}>
                                            <Bubble size={'xs'} className={'absolute -left-0.5 -top-0.5'} />
                                        </Tooltip>
                                    </div>
                                ))}
                                <UserPicker
                                    onUserSelected={(user) => setUsersToAdd((usersToAdd) => [...usersToAdd, user])}
                                    variant={'outlined'}
                                    size={'sm'}
                                    title={t`Add new recipient`}
                                    icon={faPlus}
                                    usersToHide={meeting.meetingUsers.map((mu) => mu.user).concat(usersToAdd)}
                                />
                            </div>
                            {usersToAdd.length > 0 && (
                                <Alert title={EmptyString} type={'info'}>
                                    {t`The additional user(s) that you selected will be added to the meeting with the "reader" role once you finish`}
                                </Alert>
                            )}
                        </div>
                    )}
                    {step === 2 && (
                        <>
                            <div>
                                <Label htmlFor={'subject'}>{t`Subject`}</Label>
                                <Input id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                            </div>

                            <div className={'mt-2'}>
                                <Label htmlFor={'message'}>{t`Message`}</Label>
                                <Textarea
                                    rows={5}
                                    id="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>{t`Close`}</Button>
                {step === 2 && <Button onClick={() => setStep(1)} disabled={isSharing}>{t`Previous`}</Button>}
                <Button
                    loading={isSharing}
                    color={'primary'}
                    disabled={
                        (step === 1 && recipients.length === 0 && usersToAdd.length === 0) ||
                        (step === 2 && (isEmpty(message) || isEmpty(subject)))
                    }
                    onClick={() => (step === 2 ? handleConfirm() : setStep(2))}
                >
                    {step === 2 ? t`Send` : t`Next`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
