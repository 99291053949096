import React, { useContext, useEffect, useRef } from 'react';
import { t } from '@lingui/macro';
import { Alert, Form, Input, Modal } from '@wedo/design-system';
import { useInputState } from '@wedo/utils/hooks';
import { TotpModalFooter } from 'Pages/settings/security/components/TotpModalContent/TotpModalFooter';
import { usePasswordVerify } from 'Pages/settings/security/hooks/usePasswordVerify';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';

export const VerifyPassword = (): JSX.Element => {
    const { passwordInit, check, isLoading } = usePasswordVerify();
    const [value, , handleValue] = useInputState(passwordInit);
    const {
        state: { error, force2fa },
    } = useContext(TotpContext);

    const passwordInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if ('current' in passwordInputRef) {
            setTimeout(() => passwordInputRef.current?.focus(), 100);
        }
    }, [passwordInputRef]);

    const handleSubmit = (): void => {
        void check(value);
    };

    return (
        <>
            <Modal.Body>
                {force2fa && (
                    <Alert
                        type="info"
                        className="mb-2"
                        title={t`Before accessing WEDO, your network administrator has requested that you enable two-factor authentication. Two-factor authentication greatly increases the security of your account. The activation process only takes a few steps`}
                    />
                )}
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <Form.Item htmlFor={'totp-password'} label={t`Enter your password`}>
                        <Input
                            ref={passwordInputRef}
                            id={'totp-password'}
                            data-testid={'totp-password'}
                            type="password"
                            value={value}
                            onChange={handleValue}
                            status={error === '' ? 'default' : 'error'}
                            statusText={error}
                        />
                    </Form.Item>
                </Form>
            </Modal.Body>
            <TotpModalFooter onNext={handleSubmit} isLoading={isLoading} />
        </>
    );
};
