import React, { useMemo } from 'react';
import { faCreditCard, faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isValid as isValidDate } from 'date-fns';
import { isDate } from 'lodash-es';
import {
    Button,
    Card,
    FormatDate,
    Skeleton,
    Tag,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { ChargebeeCard, ChargebeePaymentsDataConfig, ChargebeeSubscription, CurrencyCode } from '@wedo/types';
import { EditBillingAddressModal } from 'Pages/settings/billing/components/EditBillingAddressModal';
import { cardFooterPosition } from 'Pages/settings/billing/utils/style';
import { useChargebee } from 'Shared/hooks/useChargebee';
import { useGetOrganizationQuery, useGetUpdatedPaymentsMethodDataMutation } from 'Shared/services/organization';
import { getFrequencyFromSubscription, getPlanType, getSubscriptionData } from 'Shared/utils/chargebee';

type PaymentDetailsCardProps = {
    card?: ChargebeeCard;
    nextBillingAt?: Date;
    billingEmail?: string;
    subscription: ChargebeeSubscription;
    currency: CurrencyCode;
    lightUsers: number;
    totalUsers: number;
};

export const PaymentDetailsCard: React.FC<PaymentDetailsCardProps> = ({
    card,
    nextBillingAt,
    billingEmail,
    subscription,
    currency,
    totalUsers,
    lightUsers,
}) => {
    const { open } = useModal();
    const { show } = useNotification();
    const [getUpdatedPaymentsData] = useGetUpdatedPaymentsMethodDataMutation();
    const { refetch: refetchOrganisation } = useGetOrganizationQuery();
    const { chargebee } = useChargebee();

    const isLoading = !isValidDate(nextBillingAt) || !subscription;
    const isCardPayment = !!card;

    const subscriptionData = useMemo(() => {
        return getSubscriptionData({
            plan: getPlanType(subscription),
            frequency: getFrequencyFromSubscription(subscription),
            currency,
            totalUsers,
            lightUsers,
            couponCode: Array.isArray(subscription?.coupons) && subscription.coupons[0]?.coupon_code,
        });
    }, [currency, lightUsers, subscription, totalUsers]);

    const chargebeeData = useMemo<Promise<ChargebeePaymentsDataConfig>>(async () => {
        const response = await getUpdatedPaymentsData(subscriptionData);
        if ('error' in response) {
            return null;
        }
        return response?.data;
    }, [subscriptionData]);

    if (isLoading) {
        return <Skeleton className="h-64" />;
    }

    const handlePaymentChange = () => {
        chargebee.openCheckout({
            hostedPage: () => chargebeeData,
            error: () => show(UnexpectedErrorNotification),
            close: () => refetchOrganisation(),
        });
    };

    return (
        <Card>
            <Card.Header
                title={t`Payment details`}
                actions={
                    <Tag icon={isCardPayment ? faCreditCard : faQrcode} size="sm">
                        {isCardPayment ? t`Credit card` : t`QR-Bill`}
                    </Tag>
                }
            />

            <Card.Body className="text-sm">
                {!isCardPayment && (
                    <>
                        <p>
                            <Trans>
                                You will receive your QR-Bill at <span className="font-medium">{billingEmail}</span>
                            </Trans>
                            {' ('}
                            <Button variant="link" color="primary" onClick={() => open(EditBillingAddressModal)}>
                                <Trans>edit</Trans>
                            </Button>
                            )
                        </p>

                        <p className="mt-2">
                            <Trans>
                                Next billing on:{' '}
                                {isDate(nextBillingAt) && isValidDate(nextBillingAt) && (
                                    <FormatDate format="PPPP" date={nextBillingAt} className="bold" />
                                )}
                            </Trans>
                        </p>
                    </>
                )}

                {isCardPayment && (
                    <>
                        <div className="flex gap-4">
                            <div>
                                {card.card_type === 'mastercard' && (
                                    <img src="/assets/master-card.svg" alt="Mastercard" className="h-10" />
                                )}
                                {card.card_type === 'visa' && (
                                    <img src="/assets/visa.svg" alt="Visa" className="h-10" />
                                )}
                            </div>
                            <div>
                                <p>{card.masked_number}</p>
                                <p>
                                    <Trans>
                                        Expires on: {card.expiry_month} / {card.expiry_year}
                                    </Trans>
                                </p>
                            </div>
                        </div>

                        <p className="mt-6">
                            <Trans>
                                Next billing on:{' '}
                                {isDate(nextBillingAt) && isValidDate(nextBillingAt) && (
                                    <FormatDate format="PPPP" date={nextBillingAt} className="font-semibold" />
                                )}
                            </Trans>
                        </p>
                    </>
                )}
            </Card.Body>

            <Card.Footer className={cardFooterPosition}>
                <Button size="sm" onClick={handlePaymentChange}>
                    <Trans>Change payment method</Trans>
                </Button>
            </Card.Footer>
        </Card>
    );
};
