import React from 'react';
import { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { useArchiveCustomFieldMutation } from 'Shared/services/customFields';
import { CustomField } from 'Shared/types/customField';

export const useArchiveFieldModal = () => {
    const { confirm } = useConfirm();
    const [archiveField] = useArchiveCustomFieldMutation();

    const open = useCallback(
        async (field: CustomField) => {
            await confirm({
                type: 'primary',
                title: t`Archive field`,
                content: (
                    <>
                        <Trans>
                            Are you sure you want to archive the <span className="bold">{field.label}</span> field? The
                            field will be hidden, but the values will be retained.
                        </Trans>
                    </>
                ),
                confirmText: t`Archive`,
                onConfirm: () => archiveField(field.id).unwrap(),
            });
        },
        [confirm, archiveField]
    );

    return { open };
};
