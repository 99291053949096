import { useEffect, useMemo, useState } from 'react';
import { PeriodicSummary, useGetSettingsQuery } from 'Shared/services/settings';

export const usePeriodicSummary = () => {
    const { data } = useGetSettingsQuery();

    const periodicSummarySaved = useMemo<PeriodicSummary>(() => data?.periodic_summary?.value?.frequency, [data]);

    const [periodicSummary, setPeriodicSummary] = useState<PeriodicSummary>();

    useEffect(() => {
        setPeriodicSummary(periodicSummarySaved);
    }, [periodicSummarySaved]);

    return { periodicSummarySaved, periodicSummary, setPeriodicSummary };
};
