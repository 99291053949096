import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { useConfirm } from '@wedo/design-system';
import { useMeetingContext } from 'App/contexts';
import { trpc, trpcUtils } from 'Shared/trpc';

export const confirmAcceptTopicSubmissionProps = (topicTitle: string) => ({
    type: 'primary',
    title: t`Accept topic`,
    content: (
        <Trans>
            Are you sure you wish to accept topic <b>{topicTitle}</b> in this meeting? You can make changes to topic
            content after accepting it.
        </Trans>
    ),
    confirmText: t`Accept`,
});

export const useAcceptTopicSubmission = (topicId: string, meetingTopicSubmissionId: string) => {
    const { confirm } = useConfirm();
    const { meetingId } = useMeetingContext();
    const { data: topic } = trpc.meetingTopic.get.useQuery(topicId, { select: camelToSnake });

    const { mutateAsync: acceptTopicSubmission } = trpc.meetingTopic.acceptSubmission.useMutation({
        onSuccess: () => {
            void trpcUtils().meetingTopic.getSubmissionForTopic.invalidate({ topicId });
            void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
            void trpcUtils().meeting.listTopicSubmissions.invalidate({ meetingId });
        },
    });

    const acceptSubmission = async (sectionId?: string) => {
        const shouldAccept = await confirm(confirmAcceptTopicSubmissionProps(topic?.title));

        if (shouldAccept) {
            await acceptTopicSubmission({
                topicSubmissionId: meetingTopicSubmissionId,
                sectionId: sectionId === 'no-section' || sectionId === '' || sectionId == null ? undefined : sectionId,
            });
        }

        return shouldAccept;
    };

    return { acceptSubmission };
};
