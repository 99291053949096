import { FC } from 'react';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isValid as isValidDate } from 'date-fns';
import { isDate } from 'lodash-es';
import { Button, FormatDate } from '@wedo/design-system';
import { ChargebeeDiscount, ChargebeeInvoiceEstimate, ChargebeeLineItem, CurrencyCode } from '@wedo/types';
import { getPositive } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { SUBSCRIPTION_ADDON_STEP_URL, SUBSCRIPTION_PLAN_STEP_URL } from 'Pages/subscription/utils';
import { useFormattedCurrency } from 'Shared/hooks/useFormattedCurrency';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import {
    getFrequencyFromPlanId,
    getTranslatedDescriptionFromId,
    isSubscriptionPlanId,
    isAddonId,
} from 'Shared/utils/chargebee';

const SubscriptionItem = ({ item, currency }: { item: ChargebeeLineItem; currency: CurrencyCode }) => {
    const { getFormattedCurrency } = useFormattedCurrency();

    if (item?.entity_id == null) {
        return (
            <div key={`line-item-${item.id}`} className="flex justify-between gap-4">
                <div>{item.description}</div>
                <span className="text-end">{getFormattedCurrency(item.amount, currency)}</span>
            </div>
        );
    }
    const frequency = getFrequencyFromPlanId(item?.entity_id);

    return (
        <div key={`line-item-${item.id}`} className="flex justify-between gap-4">
            <div>
                <span className="text-gray-700">
                    {isSubscriptionPlanId(item.entity_id)
                        ? getTranslatedDescriptionFromId(item.entity_id)
                        : item.description}
                </span>
                {item.quantity > 1 && item.description.indexOf('Prorated') < 0 && (
                    <p className="text-gray-400">
                        <span>{item.quantity}</span> &times;{' '}
                        <span>
                            {getFormattedCurrency(item.unit_amount / (frequency === 'yearly' ? 12 : 1), currency)}
                        </span>{' '}
                        &times;{' '}
                        <span>{frequency === 'yearly' ? <Trans>12 months</Trans> : <Trans>1 month</Trans>}</span>
                    </p>
                )}
            </div>
            <span className="text-end">{getFormattedCurrency(item.amount, currency)}</span>
        </div>
    );
};

type ActiveSubscriptionDetailsProps = {
    invoiceEstimate: ChargebeeInvoiceEstimate;
    onRemoveDiscount?: (discount: ChargebeeDiscount) => void;
    hideNextRenewalInfo?: boolean;
    hideUpgradeActions?: boolean;
};

export const ActiveSubscriptionDetails: FC<ActiveSubscriptionDetailsProps> = ({
    invoiceEstimate,
    onRemoveDiscount,
    hideNextRenewalInfo = false,
    hideUpgradeActions = false,
}) => {
    const { data: organisation } = useGetOrganizationQuery();
    const { getFormattedCurrency } = useFormattedCurrency();
    const navigate = useNavigate();

    if (!invoiceEstimate) {
        return null;
    }

    const currency = invoiceEstimate.currency_code;
    const nextBillingAt = new Date(organisation?.renewal_estimate?.subscription_estimate?.next_billing_at);

    const planItems = invoiceEstimate.line_items.filter((item) => isSubscriptionPlanId(item.entity_id));
    const addonItems = invoiceEstimate.line_items.filter((item) => isAddonId(item.entity_id));

    return (
        <div className="flex flex-col gap-4">
            <div className="text-md font-semibold text-gray-700 flex items-center justify-between">
                {(planItems.length > 0 || !hideUpgradeActions) && <Trans id="plan (noun)">Plan</Trans>}
                {!hideUpgradeActions && (
                    <Button
                        size="sm"
                        onClick={() =>
                            navigate(SUBSCRIPTION_PLAN_STEP_URL, { state: { action: 'manage_subscription' } })
                        }
                    >
                        <Trans>Manage plan</Trans>
                    </Button>
                )}
            </div>
            {planItems.map((lineItem, index) => (
                <SubscriptionItem key={index} currency={currency} item={lineItem} />
            ))}

            <div className="text-md font-semibold text-gray-700 flex items-center justify-between">
                {(addonItems.length > 0 || !hideUpgradeActions) && <Trans>Add-ons</Trans>}
                {!hideUpgradeActions && (
                    <Button
                        onClick={() => navigate(SUBSCRIPTION_ADDON_STEP_URL, { state: { source: 'manage_addons' } })}
                        size="sm"
                    >
                        <Trans>Manage add-ons</Trans>
                    </Button>
                )}
            </div>
            {(addonItems.length > 0 || !hideUpgradeActions) &&
                ((addonItems ?? []).length > 0
                    ? addonItems.map((lineItem, index) => (
                          <SubscriptionItem key={index} currency={currency} item={lineItem} />
                      ))
                    : '-')}

            {invoiceEstimate?.round_off_amount !== 0 && (
                <div className="text-md flex justify-between gap-4 font-semibold text-gray-700">
                    <Trans>Subtotal</Trans>
                    <span>{getFormattedCurrency(invoiceEstimate.sub_total, currency)}</span>
                </div>
            )}

            {invoiceEstimate?.discounts?.map((discountLineItem) => (
                <div
                    className="flex flex-col"
                    key={`discount-${discountLineItem.entity_id}-${discountLineItem.coupon_set_code}`}
                >
                    <div
                        key={`discount-item-${discountLineItem.entity_id}`}
                        className="flex justify-between gap-4 text-green-500"
                    >
                        {discountLineItem.description}{' '}
                        <span className="text-end">-{getFormattedCurrency(discountLineItem.amount, currency)}</span>
                    </div>
                    {onRemoveDiscount && (
                        <div>
                            <Button
                                variant="text"
                                color="danger"
                                size="xs"
                                icon={faTrash}
                                className="-translate-x-1"
                                onClick={() => onRemoveDiscount(discountLineItem)}
                            >
                                <Trans>Remove</Trans>
                            </Button>
                        </div>
                    )}
                </div>
            ))}

            {invoiceEstimate.credits_applied > 0 && (
                <div className="flex justify-between gap-4 font-medium text-green-500">
                    <Trans>Credits applied</Trans>
                    <span>-{getFormattedCurrency(invoiceEstimate.credits_applied, currency)}</span>
                </div>
            )}

            <div className="flex flex-col gap-1">
                {invoiceEstimate?.taxes?.map((tax, index) => (
                    <div key={`tax-item-${index}`} className="flex justify-between gap-4 text-gray-500">
                        <div>
                            {tax.description?.split('<br>').map((line, index) => (
                                <p key={index} className={clsx(index > 0 && 'text-xs text-gray-500')}>
                                    {line}
                                </p>
                            ))}
                        </div>
                        <span>{getFormattedCurrency(tax.amount, currency)}</span>
                    </div>
                ))}

                {invoiceEstimate?.round_off_amount !== 0 && (
                    <div className="flex justify-between gap-4 text-gray-500">
                        <Trans>Round off</Trans>
                        <span>
                            {invoiceEstimate.round_off_amount < 0 && '-'}
                            {getFormattedCurrency(getPositive(invoiceEstimate.round_off_amount), currency)}
                        </span>
                    </div>
                )}
            </div>

            <div className="text-md flex justify-between gap-4 font-semibold text-gray-700">
                <Trans>Total</Trans>
                <span>{getFormattedCurrency(invoiceEstimate.total, currency)}</span>
            </div>

            {invoiceEstimate.amount_paid > 0 && (
                <div className="flex justify-between gap-4 font-medium text-blue-500">
                    <Trans>Excess payments</Trans>
                    <span>{getFormattedCurrency(invoiceEstimate.amount_paid, currency)}</span>
                </div>
            )}

            {isDate(nextBillingAt) && isValidDate(nextBillingAt) && !hideNextRenewalInfo && (
                <>
                    <hr className="mt-2" />

                    <div className="mt-2 flex justify-between gap-4 text-lg font-bold text-gray-700">
                        <span>
                            <Trans>Total at next renewal</Trans> (
                            <FormatDate format="PPP" date={nextBillingAt} />)
                        </span>
                        <span>{getFormattedCurrency(invoiceEstimate.amount_due, currency)}</span>
                    </div>
                </>
            )}
        </div>
    );
};
