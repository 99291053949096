import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { Filter } from 'Shared/types/filter';
import { baseApi, configureTag, listId } from './base';
import { tag as taskTag } from './task';

const { tagType, tag, tags } = configureTag('Filter');

export const filterApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getFilters: build.query<Filter[], { object_type: 'task'; tag_id?: Id }>({
                query: (params) => ({ url: 'filters', params }),
                providesTags: (result) => [...tags(result), tag(listId)],
            }),
            addFilter: build.mutation<Filter, Partial<Filter>>({
                query: (body) => ({ url: `filters`, body, method: HttpMethod.Post }),
                invalidatesTags: () => {
                    invalidateCachedTasks();
                    return [tag(listId)];
                },
            }),
            updateFilter: build.mutation<Filter, Partial<Filter>>({
                query: (body) => ({ url: `filters/${body.id}`, body, method: HttpMethod.Put }),
                invalidatesTags: (result, error, { id }) => {
                    invalidateCachedTasks();
                    return [tag(id), taskTag(listId)];
                },
            }),
            deleteFilter: build.mutation<Filter, Id>({
                query: (id) => ({ url: `filters/${id}`, method: HttpMethod.Delete }),
                invalidatesTags: (result, error, id) => [tag(id)],
            }),
        }),
    });

export const { useGetFiltersQuery, useAddFilterMutation, useUpdateFilterMutation, useDeleteFilterMutation } = filterApi;
