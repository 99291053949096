import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useMarker } from 'react-mark.js';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { useSortable } from '@dnd-kit/sortable';
import { faExternalLink, faGripVertical } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Icon, Spinner, Textarea } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { onBackSpace, onEnter } from '@wedo/utils';
import { useMatch, useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useTasksContext } from 'App/contexts/TasksContext';
import { useAppDispatch } from 'App/store';
import { useGanttContextStore } from 'Pages/GanttPage/GanttContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { TaskAssignee } from 'Shared/components/task/TaskDetail/TaskAssignee';
import { TaskDropdown } from 'Shared/components/task/TaskDetail/TaskDropdown';
import { TaskDueDate } from 'Shared/components/task/TaskDetail/TaskDueDate';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useConfirmCompleteTask } from 'Shared/hooks/useConfirmCompleteTask';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { invalidateTasks, useUpdateTaskMutation } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { mark } from 'Shared/utils/marker';
import { Permission } from 'Shared/utils/rbac';

type Props = {
    taskId: Id;
    subTask: Task;
    shouldAutoFocus: boolean;
    isTemplate: boolean;
    addSubtaskButtonRef: MutableRefObject<HTMLButtonElement>;
};
export const SubTask = ({ taskId, subTask, isTemplate, shouldAutoFocus, addSubtaskButtonRef }: Props) => {
    const ganttContextStore = useGanttContextStore();
    const queryClient = useQueryClient();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const inputRef = useRef<HTMLTextAreaElement>();

    const { setSelectedTasks } = useTasksContext();
    const { can } = useCurrentUserContext();
    const { isTaskReadonly: isSubTaskReadonly } = useTaskStatus(subTask);
    const { confirmCompleteTask } = useConfirmCompleteTask(subTask);
    const isOnSingleTasksPage = useMatch('/tasks/:taskId');

    const [name, setName] = useState(subTask?.name);
    const [updateTask, { isLoading: isUpdating }] = useUpdateTaskMutation();

    const { mutateAsync: updateStatus, isFetching: isUpdateStatusLoading } = trpc.task.updateStatus.useMutation({
        onSuccess: () => {
            dispatch(invalidateTasks([{ id: taskId }]));
        },
    });

    const isLoading = isUpdating || isUpdateStatusLoading;
    const isCompleted = subTask.completed;
    const canEditTask = can(Permission.ManageTasks);

    const { markerRef: inputMarkerRef, marker: inputMarker } = useMarker<HTMLDivElement>();
    const [{ search }] = useSearchParams(TasksPageSearchParams);
    const [isTaskFocus, setIsTaskFocus] = useState(false);

    mark(search, inputMarker);

    const handleFocus = () => {
        setIsTaskFocus(true);
        inputMarker?.unmark();
    };

    const handleBlur = () => {
        setIsTaskFocus(false);
        if (search != null) {
            inputMarker.mark(search, {
                className: 'bg-blue-200 text-blue-800',
                exclude: ['.ignore-marker'],
            });
        }
    };

    // D&D stuff
    const { draggableStyle } = useDndSortableVerticalStrategy();
    const { listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: subTask?.id,
    });
    const style = draggableStyle(transform, transition, isDragging);

    useEffect(() => setName(subTask?.name), [subTask?.name]);

    useEffect(() => {
        if (shouldAutoFocus) {
            inputRef.current.focus();
        }
    }, [shouldAutoFocus]);

    const handleOpenSubTask = async (subTaskId: Id) => {
        if (isOnSingleTasksPage) {
            void navigate(`/tasks/${subTaskId}`);
        } else {
            setSelectedTasks([{ id: subTaskId, groupedId: subTaskId.toString() }]);
            dispatch(taskSelected({ taskId: subTaskId }));
        }
    };

    const handleUpdateSubtask = async (changes: Partial<Task>, applyOn?: ApplyOn) => {
        await updateTask({ ...changes, id: subTask.id, applyOn });
        dispatch(invalidateTasks([{ id: taskId }]));
    };

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement> & { nativeEvent: { inputType: string } }) => {
        if (['insertFromPaste', 'insertFromDrop'].includes((event.nativeEvent as InputEvent).inputType)) {
            const name = event.target.value.replace(/[\r\n]+/g, ' ');
            setName(name);
            void handleUpdateSubtask({ name });
        } else if ((event.nativeEvent as InputEvent).inputType !== 'insertLineBreak') {
            setName(event.target.value);
            void handleUpdateSubtask({ name: event.target.value });
        }
    };

    const handleAssigneeChange = async (user: User) => {
        void handleUpdateSubtask({ assignee_id: user ? user.id : null });
    };

    const handleCompleteToggle = async () => {
        if (!subTask.completed) {
            const { confirm: shouldComplete, applyOn } = await confirmCompleteTask();
            if (!shouldComplete) {
                return;
            }
            void updateStatus({ taskIds: [subTask.id], completed: true, applyOn: applyOn ?? ApplyOn.OnlyCurrentTask });
            return;
        }

        void updateStatus({ taskIds: [subTask.id], completed: false, applyOn: ApplyOn.OnlyCurrentTask });
    };

    return (
        <TaskDetailRow className={'!py-0 group'}>
            <TaskDetailRow.Content className="pl-1.5">
                <div
                    ref={setNodeRef}
                    data-selectable={true}
                    data-intersected={false}
                    className="relative -mt-px flex items-start"
                >
                    <div
                        className={clsx(
                            'flex items-center pt-4',
                            isDragging && 'opacity-0',
                            canEditTask ? '-ml-5 h-4 w-5' : 'hidden'
                        )}
                    >
                        {canEditTask && (
                            <FontAwesomeIcon
                                {...listeners}
                                icon={faGripVertical}
                                data-unselectable={true}
                                className="h-4 w-5 pt-1 text-gray-400 opacity-0 cursor-grab group-hover:opacity-100"
                                aria-hidden="true"
                            />
                        )}
                    </div>

                    <div
                        className={clsx(
                            'grow -mb-px flex items-start gap-2 border border-gray-300 bg-white pl-2 text-sm focus-within:bg-blue-200',
                            isCompleted && 'focus-within:bg-green-150 !bg-green-100'
                        )}
                        style={style}
                    >
                        {isLoading ? (
                            <div className={'flex mt-1.5 h-[1.5rem] w-5 items-center justify-center'}>
                                <Spinner className={'!h-5 !w-5'} color={'blue'} />
                            </div>
                        ) : (
                            <Button
                                variant="ghost"
                                title={
                                    isTemplate
                                        ? null
                                        : subTask.completed
                                          ? t`Restore the subtask`
                                          : t`Mark subtask as complete`
                                }
                                className="flex mt-1.5"
                                disabled={isTemplate}
                                onClick={handleCompleteToggle}
                            >
                                <Icon
                                    name={
                                        subTask.is_blocked
                                            ? 'blockedTask'
                                            : subTask.completed
                                              ? 'completedTask'
                                              : 'openSubtask'
                                    }
                                    className={clsx(
                                        'h-5 w-5 cursor-pointer',
                                        subTask.completed
                                            ? 'text-green-500 hover:text-green-700'
                                            : 'text-gray-400 hover:text-gray-500'
                                    )}
                                />
                            </Button>
                        )}
                        <div className="flex flex-1 relative" ref={inputMarkerRef}>
                            <Textarea
                                className={clsx(
                                    'text-sm ignore-marker my-1.5 break-words',
                                    !isTaskFocus && 'line-clamp-2'
                                )}
                                borderless
                                debounce
                                onKeyDown={(e) => {
                                    onEnter(() => {
                                        if (!e.shiftKey) {
                                            e.preventDefault();
                                            addSubtaskButtonRef.current.focus();
                                        }
                                    })(e);
                                    onBackSpace(async (event) => {
                                        if (event?.target?.value?.length === 0) {
                                            e.preventDefault();
                                            inputRef.current.blur();
                                            void updateStatus({
                                                taskIds: [subTask.id],
                                                deleted: true,
                                                applyOn: ApplyOn.OnlyCurrentTask,
                                            });
                                            if (ganttContextStore != null) {
                                                await invalidateQueries(queryClient, [
                                                    taskQueryTag.updated(subTask.parent_task_id, 'subTasks'),
                                                ]);
                                            }
                                            dispatch(invalidateTasks([{ id: subTask.parent_task_id }]));
                                        }
                                    })(e);
                                }}
                                placeholder={t`Describe this subtask` + '...'}
                                aria-label={t`Describe this subtask`}
                                ref={inputRef}
                                value={name}
                                rows={1}
                                readOnly={isSubTaskReadonly}
                                onChange={handleNameChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                            <div className="flex row">
                                <Button
                                    size={'md'}
                                    color="default"
                                    variant="ghost"
                                    onClick={() => handleOpenSubTask(subTask.id)}
                                    aria-hidden="true"
                                    title={t`Open`}
                                    className="opacity-0 group-hover:opacity-100 p-1 flex items-start"
                                >
                                    <div className="flex w-full h-6 px-2 text-gray-800 hover:bg-gray-200 rounded items-center">
                                        <FontAwesomeIcon icon={faExternalLink} />
                                    </div>
                                </Button>
                                <div className="mt-1.5 mr-1">
                                    <TaskDueDate
                                        task={subTask}
                                        isTemplate={
                                            subTask?.checklist != null &&
                                            subTask.checklist.checklist_template_id == null
                                        }
                                        isCompleted={isCompleted}
                                        isDeleted={subTask.deleted}
                                        layout={'kanban'}
                                    />
                                </div>
                                <TaskAssignee
                                    task={subTask}
                                    onAssigneeChange={handleAssigneeChange}
                                    isReadonly={isSubTaskReadonly}
                                    isCompleted={isCompleted}
                                    isDeleted={subTask.deleted}
                                />
                            </div>
                            {!isTaskFocus && search != null && (
                                <div className="mt-1.5 text-sm font-medium cursor-default absolute top-0 pointer-events-none whitespace-pre-wrap break-words text-transparent">
                                    {name}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="-mr-3 h-[34px] px-1 flex items-center justify-center opacity-100 group-hover:opacity-100">
                        <TaskDropdown task={subTask} hideTasksToExport size="xs" variant="text" />
                    </div>
                </div>
            </TaskDetailRow.Content>
        </TaskDetailRow>
    );
};
