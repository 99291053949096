import React from 'react';
import { Trans } from '@lingui/macro';

export const NewFeaturePulse = ({ showText = false }: { showText?: boolean }) => (
    <div className="flex justify-center items-center gap-1">
        <span className="relative flex">
            <span className="w-2 h-2 bg-purple-500 rounded-full" />
            <span className="w-2 h-2 bg-purple-500 rounded-full absolute top-0 left-0 animate-ping" />
        </span>
        {showText && (
            <span className="text-purple-700 text-xs">
                <Trans>New</Trans>
            </span>
        )}
    </div>
);
