import { HttpMethod, Id } from '@wedo/types';
import { baseApi, configureTag } from './base';

const { tagType, tag } = configureTag('Dashboard');
export const tagHistory: Id = 'tag-history';
export const tagUserStats: Id = 'tag-stats';

export type TaskPriorities = {
    priority_0: number;
    priority_1: number;
    priority_2: number;
    priority_3: number;
    priority_4: number;
};

type TaskDistribution = {
    name: string;
    tagColor: {
        text: string;
        background: string;
    };
    count: string;
};

type TaskHistory = {
    completed: [];
    created: [];
    deleted: [];
};

type TaskTimestamps = { [key: number]: number };

export const dashboardApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getWorkspaceHistory: build.query<
                { created: Array<[string, number]>; completed: Array<[string, number]> },
                Id
            >({
                query: (id) => ({
                    url: `dashboard/tags/${id}/history`,
                    method: HttpMethod.Get,
                }),
                providesTags: () => [tag(tagHistory)],
            }),
            getUserStatsPriorities: build.query<TaskPriorities, Id>({
                query: (id) => ({
                    url: `dashboard/users/${id}/priorities`,
                    method: HttpMethod.Get,
                }),
                providesTags: () => [tag(tagUserStats)],
            }),
            getUserStatsDistribution: build.query<TaskDistribution[], Id>({
                query: (id) => ({
                    url: `dashboard/users/${id}/tags`,
                    method: HttpMethod.Get,
                }),
                providesTags: () => [tag(tagUserStats)],
            }),
            getUserStatsHistory: build.query<TaskHistory, Id>({
                query: (id) => ({
                    url: `dashboard/users/${id}/history`,
                    method: HttpMethod.Get,
                }),
                providesTags: () => [tag(tagUserStats)],
            }),
            getUserStatsTimestamps: build.query<TaskTimestamps, Id>({
                query: (id) => ({
                    url: `dashboard/users/${id}/timestamps`,
                    method: HttpMethod.Get,
                }),
                providesTags: () => [tag(tagUserStats)],
            }),
        }),
    });

export const {
    useGetWorkspaceHistoryQuery,
    useGetUserStatsPrioritiesQuery,
    useGetUserStatsDistributionQuery,
    useGetUserStatsHistoryQuery,
    useGetUserStatsTimestampsQuery,
} = dashboardApi;
