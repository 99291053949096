import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { intersection, setDiff } from '@wedo/utils/set';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { useGetChecklistQuery } from 'Shared/services/checklist';
import { useUpdateTasksMutation } from 'Shared/services/task';
import { useAddTaskWatcherMutation, useRemoveTaskWatcherMutation } from 'Shared/services/taskWatcher';
import { trpc } from 'Shared/trpc';
import { Task, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { getTaskSectionIdForTemplate, getTaskSectionIdForWorkspace } from 'Shared/utils/task';

export const useBulkTasksEditPane = (selectedTasks?: Task[]) => {
    const { show } = useNotification();
    const selectedTaskIds = selectedTasks?.map(({ id }) => id);
    const { workspaceId, checklistId, templateId } = useParams();
    const { data: checklist } = useGetChecklistQuery(checklistId, { skip: !checklistId });
    const { sectionIdToMaxOrder, sectionGroups } = useTaskSections({ workspaceId, templateId, checklistId });
    const { data: fullSelectedTasks = [] } = trpc.task.listByIds.useQuery({ taskIds: selectedTaskIds });

    const getTaskSectionId = (task: Task) =>
        workspaceId ? getTaskSectionIdForWorkspace(task, workspaceId) : getTaskSectionIdForTemplate(task);
    const [updateTasks] = useUpdateTasksMutation();
    const [addTaskWatcher] = useAddTaskWatcherMutation();
    const [removeTaskWatcher] = useRemoveTaskWatcherMutation();
    const selectedTaskIdToSectionId = useMemo(() => {
        return new Map(fullSelectedTasks.map((task) => [task.id, getTaskSectionId(task)]));
    }, [fullSelectedTasks]);

    const getCommonParameterFromSelectedTasks = <T extends keyof Task>(
        param: T | ((task: Task) => Task[T])
    ): Task[T] => {
        const getter = (task: Task) => (typeof param === 'function' ? param(task) : task?.[param]);
        const firstTaskParam = getter(fullSelectedTasks?.[0]);
        if (fullSelectedTasks?.every((selectedTask) => getter(selectedTask) === firstTaskParam)) {
            return firstTaskParam;
        }
        return undefined;
    };

    const getCommonParamIds = (getParamIds: (task: Task) => Id[]): Id[] => {
        let result = new Set<Id>(getParamIds(fullSelectedTasks?.[0]));
        for (const task of fullSelectedTasks) {
            const current = new Set(getParamIds(task));
            result = intersection(result, current);
        }
        return [...result.keys()];
    };

    const commonAssigneeId = useMemo(() => getCommonParameterFromSelectedTasks('assignee_id'), [fullSelectedTasks]);

    const commonAssignee = useMemo<User>(() => {
        if (commonAssigneeId == null) {
            return undefined;
        }
        return fullSelectedTasks?.[0]?.assignee as User;
    }, [commonAssigneeId, fullSelectedTasks]);

    const commonPriority = useMemo<Task['priority']>(
        () => getCommonParameterFromSelectedTasks('priority'),
        [fullSelectedTasks]
    );

    const commonPlannedDate = useMemo<Task['planned_date']>(
        () => getCommonParameterFromSelectedTasks('planned_date'),
        [fullSelectedTasks]
    );

    const commonRelativePlannedDate = useMemo<Task['relative_planned_date']>(
        () => getCommonParameterFromSelectedTasks('relative_planned_date'),
        [fullSelectedTasks]
    );

    const commonRelativeDueDate = useMemo<Task['relative_due_date']>(
        () => getCommonParameterFromSelectedTasks('relative_due_date'),
        [fullSelectedTasks]
    );

    const commonDueDate = useMemo<Task['due_date']>(
        () => getCommonParameterFromSelectedTasks('due_date'),
        [fullSelectedTasks]
    );

    const commonSectionId = useMemo<Id>(
        () => getCommonParameterFromSelectedTasks<'id'>((task) => getTaskSectionId(task)),
        [fullSelectedTasks]
    );

    const commonWorkspaceIds = useMemo(
        () => getCommonParamIds((task) => task?.workspaces?.map(({ id }) => id)),
        [fullSelectedTasks]
    );

    const commonWatcherIds = useMemo<Array<Id>>(() => getCommonParamIds((task) => task?.watchers), [fullSelectedTasks]);

    const areAllTasksCompleted = fullSelectedTasks.every((task) => task.completed);
    const areAllTasksDeleted = fullSelectedTasks.every((task) => task.deleted);
    const areAllTasksOpen = fullSelectedTasks.every((task) => !task.completed && !task.deleted);
    const areSomeTasksDeleted = fullSelectedTasks.some((task) => task.deleted);
    const areSomeTasksCompleted = fullSelectedTasks.some((task) => task.completed);
    const areSomeTasksOpen = fullSelectedTasks.some((task) => !task.deleted && !task.completed);
    const areSomeTasksMilestones = fullSelectedTasks.some((task) => task.type === TaskType.Milestone);

    const handleChooseCommonAssignee = async (user?: User) => {
        const payload = fullSelectedTasks
            .filter(({ assignee_id }) => assignee_id !== user?.id ?? null)
            .map(({ id }) => ({ id, assignee_id: user?.id ?? null }));
        if (!isEmpty(payload)) {
            const response = await updateTasks({ tasks: payload });
            if ('error' in response) {
                show(UnexpectedErrorNotification);
            }
        }
    };

    const handleChooseCommonPriority = async (priority?: Task['priority']) => {
        const response = await updateTasks({
            tasks: fullSelectedTasks.map(({ id }) => ({ id, priority: priority ?? null })),
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonStartDate = async (date?: Date) => {
        const response = await updateTasks({
            tasks: fullSelectedTasks.map(({ id }) => ({ id, planned_date: date?.toISOString() ?? null })),
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonDueDate = async (date?: Date) => {
        const response = await updateTasks({
            tasks: fullSelectedTasks.map(({ id }) => ({ id, due_date: date?.toISOString() ?? null })),
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonRelativeDueDate = async (relativeDueDate: number) => {
        const response = await updateTasks({
            tasks: fullSelectedTasks.map(({ id }) => ({ id, relative_due_date: relativeDueDate ?? null })),
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonRelativePlannedDate = async (relativePlannedDate: number) => {
        const response = await updateTasks({
            tasks: fullSelectedTasks.map(({ id }) => ({ id, relative_planned_date: relativePlannedDate ?? null })),
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const updateSectionInWorkspace = async (sectionId: Id) => {
        const maxOrder = sectionIdToMaxOrder.get(sectionId ?? EmptyString);

        // add all tasks being moved to the new section in the new section, expect for the tasks that are already there
        const response = await updateTasks({
            tasks: fullSelectedTasks
                .filter(({ id }) => selectedTaskIdToSectionId.get(id) !== sectionId)
                .map(({ id }, index) => ({ id, workspace_section_id: sectionId, tag_order: maxOrder + 1 + index })),
            workspaceId,
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const updateSectionInTemplateOrChecklist = async (sectionId: Id) => {
        const payload = [];
        let order = 1;

        for (const section of sectionGroups) {
            for (const task of section.tasks) {
                if (selectedTaskIds.includes(task.id)) {
                    continue;
                }
                payload.push({
                    id: task.id,
                    checklist_section_id: section.key === EmptyString ? null : section.key,
                    order: order++,
                });
            }

            if (section.key === (sectionId ?? EmptyString)) {
                for (const task of fullSelectedTasks) {
                    payload.push({ id: task.id, checklist_section_id: sectionId, order: order++ });
                }
            }
        }

        const response = await updateTasks({
            tasks: payload,
            templateId: templateId ?? checklist?.checklist_template_id,
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonSection = async (sectionId: Id) => {
        if (workspaceId) {
            void updateSectionInWorkspace(sectionId);
        } else {
            void updateSectionInTemplateOrChecklist(sectionId);
        }
    };

    const handleChooseCommonWorkspaceIds = async (workspaceIds: Array<Id>) => {
        const workspacesToBeAdded = setDiff(new Set<Id>(workspaceIds), new Set(commonWorkspaceIds));
        const workspacesToBeRemoved = setDiff(new Set(commonWorkspaceIds), new Set(workspaceIds));

        const response = await updateTasks({
            tasks: fullSelectedTasks.map(({ id, workspaces }) => ({
                id,
                workspaces: [
                    ...workspacesToBeAdded,
                    ...workspaces
                        .filter(({ id }) => !workspacesToBeRemoved.has(id) && !workspacesToBeAdded.has(id))
                        .map(({ id }) => id),
                ],
            })),
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonWatchers = async (watcherIds: Array<Id>) => {
        const commonWatcherIdsCopy = new Set(commonWatcherIds);

        await Promise.all(
            fullSelectedTasks.map(async ({ id: taskId, watchers: preExistingWatcherIds }) => {
                const watchersToBeAdded = setDiff(new Set(watcherIds), new Set(preExistingWatcherIds));
                const watchersToBeRemoved = setDiff(new Set(commonWatcherIdsCopy), new Set(watcherIds));

                await Promise.all([
                    ...[...watchersToBeAdded].map((watcherId) =>
                        addTaskWatcher({ taskId, userId: watcherId, keepCache: true })
                    ),
                    ...[...watchersToBeRemoved].map((watcherId) =>
                        removeTaskWatcher({ taskId, userId: watcherId, keepCache: true })
                    ),
                ]);
            })
        );

        invalidateCachedTasks();
    };

    return {
        fullSelectedTasks,
        commonAssignee,
        commonPriority,
        commonPlannedDate,
        commonRelativePlannedDate,
        commonDueDate,
        commonRelativeDueDate,
        commonSectionId,
        commonWorkspaceIds,
        commonWatcherIds,
        areAllTasksCompleted,
        areAllTasksDeleted,
        areAllTasksOpen,
        areSomeTasksCompleted,
        areSomeTasksOpen,
        areSomeTasksDeleted,
        areSomeTasksMilestones,
        handleChooseCommonAssignee,
        handleChooseCommonPriority,
        handleChooseCommonStartDate,
        handleChooseCommonDueDate,
        handleChooseCommonRelativePlannedDate,
        handleChooseCommonRelativeDueDate,
        handleChooseCommonSection,
        handleChooseCommonWorkspaceIds,
        handleChooseCommonWatchers,
    };
};
