import { FC } from 'react';
import { faFilter } from '@fortawesome/pro-duotone-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { EmptyState, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { ConfigureTaskFiltersTableRow } from 'Pages/TasksPage/components/ConfigureTaskFilterModal/ConfigureTaskFiltersTableRow';
import { useGetFiltersQuery } from 'Shared/services/filter';
import { Filter } from 'Shared/types/filter';

type ConfigureTaskFiltersTableProps = { workspaceId?: Id };

export const ConfigureTaskFiltersTable: FC<ConfigureTaskFiltersTableProps> = ({ workspaceId }) => {
    const { data: customFilters = EmptyArray as Filter[] } = useGetFiltersQuery({
        tag_id: workspaceId,
        object_type: 'task',
    });

    if (isEmpty(customFilters)) {
        return (
            <EmptyState size="md" icon={faFilter}>
                <EmptyState.Text>
                    <Trans>No custom filters</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <Table>
            <Table.Head>
                <Table.HeadCell>
                    <Trans>Name</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="flex justify-center">
                    <Trans>Last updated by</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>On</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Actions</Trans>
                </Table.HeadCell>
            </Table.Head>

            <Table.Body>
                {customFilters?.map((filter: Filter) => (
                    <ConfigureTaskFiltersTableRow key={filter.id} filter={filter} />
                ))}
            </Table.Body>
        </Table>
    );
};
