import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { faArrowDown, faClock, faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { addMinutes } from 'date-fns';
import {
    Button,
    ContextModalProps,
    DatePickerPopover,
    FormatDate,
    Modal,
    TimePickerInput as TimePicker,
    useModal,
} from '@wedo/design-system';
import { getBreakpointValue, getTimezoneLabel, getUserTimeZone } from '@wedo/utils';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { formRowClasses, formRowIconClasses } from './FormStyles';
import { TimeZonePicker } from './TimeZonePicker';

type MeetingFormDateTimeProps = {
    startAt: Date;
    onStartAtChange: (value: Date) => void;
    localeCode?: string;
    disabled: boolean;
    endAt: Date;
    onEndAtChange: (value: Date) => void;
    timeZone: string;
    handleTimeZoneChange: (value: string) => void;
    disabledDateBefore?: Date;
};

export const MeetingFormDateTime = ({
    startAt,
    endAt,
    onStartAtChange,
    disabled,
    onEndAtChange,
    timeZone,
    handleTimeZoneChange,
    disabledDateBefore,
}: MeetingFormDateTimeProps): JSX.Element => {
    const { width: windowWidth } = useWindowSize();
    const { open: openModal } = useModal();

    const handleTimeZoneModalOK = (timeZone: string) => {
        handleTimeZoneChange(timeZone);
    };

    return (
        <>
            <div className={formRowClasses}>
                <div className={formRowIconClasses}>
                    <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="-ml-1 flex-1 overflow-hidden p-1">
                    <div className={'flex flex-wrap gap-x-2 md:flex-nowrap'}>
                        <div className={'flex w-full sm:w-fit'}>
                            <div className={'flex-1'}>
                                <DatePickerPopover
                                    date={startAt}
                                    aria-label={t`Start date`}
                                    data-testid={'start-date'}
                                    onChange={onStartAtChange}
                                    className="sm:autowidth w-full text-center"
                                    placeholder={t`Start date`}
                                    disabled={disabled}
                                    text={startAt ? <FormatDate date={startAt} format="shortDate" /> : t`Start date`}
                                    minDate={disabledDateBefore ? addMinutes(disabledDateBefore, 15) : undefined}
                                />
                            </div>
                            <div className={'pl-[7px] sm:px-[7px]'}>
                                <TimePicker
                                    data-testid={'time-picker-start-time'}
                                    panelClassName={'!w-28'}
                                    className={'!w-28'}
                                    date={startAt}
                                    onChange={onStartAtChange}
                                    disabled={disabled}
                                    placeholder={t`Start time`}
                                    minDate={disabledDateBefore ? addMinutes(disabledDateBefore, 15) : undefined}
                                />
                            </div>
                        </div>

                        <div className={'my-2 flex w-full items-center justify-center text-gray-600 sm:hidden'}>
                            <FontAwesomeIcon icon={faArrowDown} />
                        </div>

                        <div className={'flex w-full flex-row-reverse sm:w-fit sm:flex-row'}>
                            <div className={'hidden items-center sm:flex'}>
                                <Trans>To</Trans>
                            </div>
                            <div className={'pl-[7px] sm:px-[7px]'}>
                                <TimePicker
                                    panelClassName={'!w-28'}
                                    className={'!w-28'}
                                    date={endAt}
                                    onChange={onEndAtChange}
                                    disabled={disabled}
                                    placeholder={t`End time`}
                                    minDate={disabledDateBefore ? addMinutes(disabledDateBefore, 15) : undefined}
                                />
                            </div>
                            <div className={'flex-1'}>
                                <DatePickerPopover
                                    date={endAt}
                                    text={endAt ? <FormatDate date={endAt} format="shortDate" /> : t`End date`}
                                    onChange={onEndAtChange}
                                    className="sm:autowidth w-full text-center"
                                    placeholder={t`End date`}
                                    aria-label={t`End date`}
                                    disabled={disabled}
                                    minDate={disabledDateBefore ? addMinutes(disabledDateBefore, 15) : undefined}
                                />
                            </div>
                        </div>

                        {timeZone === getUserTimeZone() && windowWidth >= getBreakpointValue('sm') && (
                            <div style={{ padding: '0 7px' }}>
                                <Button
                                    variant={'text'}
                                    onClick={() =>
                                        openModal(TimezoneModal, {
                                            timeZone,
                                            disabled,
                                            onConfirm: handleTimeZoneModalOK,
                                        })
                                    }
                                >
                                    <Trans>Time zone</Trans>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {(timeZone !== getUserTimeZone() || windowWidth < getBreakpointValue('sm')) && (
                <div className={formRowClasses}>
                    <div className={formRowIconClasses}>
                        <FontAwesomeIcon icon={faGlobe} />
                    </div>
                    <div className={'-ml-1 flex flex-1 items-center p-1'}>
                        <Button
                            data-testid={'timezone-button'}
                            aria-label={t`Time zone`}
                            variant={'text'}
                            onClick={() =>
                                openModal(TimezoneModal, {
                                    timeZone,
                                    disabled,
                                    onConfirm: handleTimeZoneModalOK,
                                })
                            }
                        >
                            {timeZone !== getUserTimeZone() ? getTimezoneLabel(timeZone) : t`Time zone`}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export const TimezoneModal = ({
    timeZone,
    onConfirm,
    disabled,
    ...modalProps
}: {
    timeZone: string;
    onConfirm: (timezone: string) => void;
    disabled?: boolean;
} & ContextModalProps) => {
    const [localTimeZone, setLocalTimeZone] = useState(timeZone);
    const onReset = async () => {
        onConfirm(getUserTimeZone());
        await modalProps.close();
    };
    const handleConfirm = async () => {
        onConfirm(localTimeZone);
        await modalProps.close();
    };
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Meeting time zone`} />
            <Modal.Body>
                <TimeZonePicker
                    disabled={disabled}
                    value={localTimeZone}
                    onChange={(timeZone) => setLocalTimeZone(timeZone)}
                />
            </Modal.Body>
            <Modal.Footer>
                {!disabled && (
                    <>
                        <Button onClick={modalProps.close}>
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button onClick={onReset} disabled={timeZone === getUserTimeZone()}>
                            <Trans>Reset time zone</Trans>
                        </Button>
                    </>
                )}
                <Button color={'primary'} onClick={disabled ? modalProps.close : handleConfirm}>
                    {disabled ? <Trans>Close</Trans> : <Trans>Confirm</Trans>}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
