import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { ZodError } from 'zod';
import { Alert, Form, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useSessionUser } from 'App/store/usersStore';
import { PhoneNumberPicker } from 'Shared/components/PhoneNumberPicker/PhoneNumberPicker';
import { trpc } from 'Shared/trpc';
import { SignatureCertificate } from 'Shared/types/signature';
import { defaultSignatureCertificate } from 'Shared/utils/signature';

export type SignatoryInformationFormHandle = {
    save: () => boolean;
    getHasChanged: () => boolean;
};

export const SignatoryInformationForm = forwardRef<
    SignatoryInformationFormHandle,
    {
        disabled?: boolean;
        onChange?: (param: boolean) => void;
        onDone?: (signatoryInformation?: SignatureCertificate) => void;
        setHasChanged?: (param: boolean) => void;
        hideAlert?: boolean;
        phoneError?: String;
    }
>(({ onChange = () => {}, onDone, setHasChanged, disabled = false, hideAlert = false, phoneError }, ref) => {
    const phoneNumberPickerRef = useRef(null);
    const [libPhoneNumberError, setLibPhoneNumberError] = useState<string | null>(null);

    const [signatureCertificate, setSignatureCertificate] = useState(defaultSignatureCertificate);
    const [errors, setErrors] = useState<ZodError>(null);

    const sessionUser = useSessionUser();
    const utils = trpc.useContext();
    const { data: signatureSettings } = trpc.user.getSettingsByKey.useQuery('signature');
    const { replace: replaceNotification, show: showNotification } = useNotification();
    const oldPhoneError = useRef(phoneError);

    if (oldPhoneError.current !== phoneError && phoneError != null) {
        oldPhoneError.current = phoneError;
        setLibPhoneNumberError(phoneError.toString());
    }

    const { mutate: updateSettingsByKey, isLoading } = trpc.user.updateSettingsByKey.useMutation({
        onSuccess: () => {
            void utils.user.getSettingsByKey.invalidate('signature');
            replaceNotification('success-signature', {
                title: t`Your changes have been saved`,
                type: 'success',
                id: 'success-signature',
            });
        },

        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });

    const updateSignatory = (key: keyof SignatureCertificate) => {
        return (evt: ChangeEvent<HTMLInputElement> | string) => {
            let value: string;

            if (typeof evt === 'string') {
                value = evt;
            } else {
                value = evt.target.value;
            }
            setSignatureCertificate((certificate) => {
                return { ...certificate, [key]: value };
            });
            if (key === 'phone') {
                setLibPhoneNumberError(null);
            }

            const newErrors = structuredClone(errors);
            if (newErrors != null) {
                newErrors.issues = errors?.issues?.filter((issue) => !issue.path.includes(key));
                setErrors(newErrors);
            }
        };
    };

    const save = async () => {
        if (
            !isLoading &&
            phoneNumberPickerRef.current.isValid &&
            signatureCertificate.phone !== signatureSettings?.phone
        ) {
            if (phoneNumberPickerRef.current.internationalInput !== signatureSettings?.phone) {
                onDone?.(signatureCertificate);
            } else {
                updateSettingsByKey({ key: 'signature', value: signatureCertificate });
            }
        } else {
            if (!phoneNumberPickerRef.current.isValid) {
                setLibPhoneNumberError(t`Invalid phone number`);
            }
        }
        onDone?.(null);
    };

    const errorToStatus = (key: keyof typeof signatureCertificate) => {
        const error = errors?.issues?.find((issue) => issue.path.includes(key));

        if (error == null) {
            return null;
        }

        return {
            status: 'error' as const,
            statusText: error.message,
        };
    };

    useEffect(() => {
        setHasChanged?.(signatureCertificate.phone !== signatureSettings?.phone);
    }, [signatureCertificate, signatureSettings]);

    useImperativeHandle(
        ref,
        () => ({
            save,
        }),
        [save]
    );

    useEffect(() => {
        setSignatureCertificate({
            country: signatureSettings?.country ?? '',
            phone: signatureSettings?.phone ?? sessionUser.userNetwork.phone_number ?? '',
        });
    }, [signatureSettings]);

    useEffect(() => {
        onChange(
            Object.keys(signatureCertificate).some(
                (key: keyof SignatureCertificate) => signatureCertificate[key] !== (signatureSettings?.[key] ?? '')
            )
        );
    }, [signatureSettings, signatureCertificate]);
    return (
        <div className={'flex flex-col gap-4'}>
            {(isEmpty(signatureSettings?.country) || isEmpty(signatureSettings?.phone)) && !hideAlert && (
                <Alert
                    type={'info'}
                >{t`This information is required and will be present in the digital signature of the document`}</Alert>
            )}
            <Form>
                <Form.Item label={t`Phone`} cols={3}>
                    <PhoneNumberPicker
                        disabled={disabled}
                        status={libPhoneNumberError != null ? 'error' : undefined}
                        statusText={libPhoneNumberError}
                        ref={phoneNumberPickerRef}
                        initialValue={signatureSettings?.phone}
                        onChange={updateSignatory('phone')}
                    />
                </Form.Item>
            </Form>
        </div>
    );
});
