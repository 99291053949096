import {
    Children,
    cloneElement,
    ComponentProps,
    isValidElement,
    PropsWithChildren,
    ReactNode,
    useId,
    useMemo,
} from 'react';
import React from 'react';
import clsx from 'clsx';
import { EmptyFunction } from '@wedo/utils';
import { Label } from '../Label/Label';
import { FormContext, useFormContext } from './FormContext';

export type FormLayout = 'horizontal' | 'vertical';
export type FormCols = 1 | 2 | 3 | 4 | 5 | 6;

const classes = {
    form: {
        body: {
            vertical: 'grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6',
            horizontal: 'space-y-6 sm:space-y-5 divide-y divide-gray-200',
            withTitle: 'mt-6',
        },
    },
    item: {
        vertical: '',
        horizontal: 'sm:grid sm:grid-cols-8 sm:items-start sm:gap-4 sm:pt-5',
    },
    label: {
        vertical: '',
        horizontal: 'sm:col-span-2 sm:mt-px sm:pt-2.5',
    },
    cols: {
        1: 'sm:col-span-1',
        2: 'sm:col-span-2',
        3: 'sm:col-span-3',
        4: 'sm:col-span-4',
        5: 'sm:col-span-5',
        6: 'sm:col-span-6',
    },
};

export const AutoId = Symbol('autoId');

type FormItemProps = {
    htmlFor?: string | typeof AutoId;
    label?: ReactNode;
    cols?: FormCols;
    className?: string;
    labelClassName?: string;
    children?: ReactNode;
    isMandatory?: boolean;
} & Pick<ComponentProps<'div'>, 'style'>;

const FormItem = ({
    htmlFor,
    label,
    cols = 6,
    className,
    labelClassName,
    children,
    isMandatory,
    style = {},
}: FormItemProps) => {
    const id = useId();
    const { layout } = useFormContext();

    const childrenWithIds = useMemo(() => {
        return htmlFor === AutoId
            ? Children.map(children, (child) =>
                  isValidElement(child) ? cloneElement(child, { ...child.props, id }) : child
              )
            : children;
    }, [children]);

    return (
        <div
            className={clsx(layout === 'vertical' ? classes.cols[cols] : classes.item.horizontal, className)}
            style={style}
        >
            {label && (
                <Label
                    htmlFor={htmlFor === AutoId ? id : htmlFor}
                    className={clsx(layout === 'horizontal' && classes.label.horizontal, labelClassName)}
                    isMandatory={isMandatory}
                >
                    {label}
                </Label>
            )}
            <div className={clsx(label && layout === 'vertical' && 'mt-1', classes.cols[cols])}>{childrenWithIds}</div>
        </div>
    );
};

export interface FormProps extends PropsWithChildren<ComponentProps<'form'>> {
    layout?: FormLayout;
    title?: string;
    description?: string;
}

const FormComponent = ({
    children,
    layout = 'vertical',
    title,
    description,
    className,
    onSubmit = EmptyFunction,
    ...props
}: FormProps) => {
    const handleSubmit: React.FormEventHandler = (e) => {
        e?.preventDefault();
        onSubmit(e);
    };

    return (
        <FormContext.Provider value={{ layout }}>
            <form className={clsx('space-y-8 divide-y divide-gray-200', className)} onSubmit={handleSubmit} {...props}>
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        {(title || description) && (
                            <div>
                                {title && <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>}
                                {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
                            </div>
                        )}

                        <div className={clsx(classes.form.body[layout], title && classes.form.body.withTitle)}>
                            {children}
                        </div>
                    </div>
                </div>
            </form>
        </FormContext.Provider>
    );
};

FormComponent.displayName = 'Form';
FormItem.displayName = 'Form.Item';

export const Form = Object.assign(FormComponent, { Item: FormItem });
