import React, { FC } from 'react';
import { RadioGroup } from '@wedo/design-system';
import { ApplyOn } from 'Pages/TasksPage/constants';

type ApplyOnRadioGroupProps = {
    value: ApplyOn;
    onChange: (value: ApplyOn) => void;
    allOptionText: string;
    currentTaskOptionText: string;
};

export const ApplyOnRadioGroup: FC<ApplyOnRadioGroupProps> = ({
    value,
    onChange,
    allOptionText,
    currentTaskOptionText,
}) => {
    return (
        <RadioGroup name="apply_on" onChange={onChange} value={value}>
            <RadioGroup.Radio value={ApplyOn.AllTasks}>{allOptionText}</RadioGroup.Radio>
            <RadioGroup.Radio value={ApplyOn.OnlyCurrentTask}>{currentTaskOptionText}</RadioGroup.Radio>
        </RadioGroup>
    );
};
