import React, { FC, ReactElement } from 'react';
import { Trans } from '@lingui/macro';
import { RadioGroup, Skeleton } from '@wedo/design-system';
import { PeriodicSummary } from 'Shared/services/settings';

interface PeriodicReportRadioItem {
    id: PeriodicSummary;
    title: ReactElement;
}

const periodicReportRadioItems: Array<PeriodicReportRadioItem> = [
    { id: 'weekly', title: <Trans>Weekly: every monday morning</Trans> },
    { id: 'daily', title: <Trans> Daily: same days as email notifications</Trans> },
    { id: 'none', title: <Trans>Never</Trans> },
];

interface PeriodicReportsSectionProps {
    value: PeriodicSummary;
    onClick: (value: PeriodicSummary) => void;
}

export const PeriodicReportsSection: FC<PeriodicReportsSectionProps> = ({ value, onClick }) => {
    const isLoading = !value;

    return (
        <>
            <h4 className="mt-8 text-base font-semibold">
                <Trans>Periodic report</Trans>
            </h4>

            <p className="mt-2 text-gray-700 text-sm">
                <Trans>Get an email containing your planned tasks and meetings.</Trans>
            </p>

            {isLoading ? (
                <Skeleton className="mt-2 h-28" />
            ) : (
                <RadioGroup value={value} name="periodic-summary" onChange={onClick} className="mt-4">
                    {periodicReportRadioItems.map((item) => {
                        return (
                            <RadioGroup.Radio value={item.id} key={item.id} id={item.id}>
                                {item.title}
                            </RadioGroup.Radio>
                        );
                    })}
                </RadioGroup>
            )}
        </>
    );
};
