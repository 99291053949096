import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, type MouseEvent } from 'react';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import sprite from './sprite.svg';
import { IconName } from './type';

type IconProps = {
    ariaLabel?: string;
    className?: string;
    name: IconDefinition | IconName;
    color?: string;
    size?: SizeProp;
    onClick?: (event: MouseEvent<SVGElement>) => void;
};
export const Icon: FC<IconProps> = ({ ariaLabel, className, name, color, size, onClick }) => {
    if (typeof name === 'string') {
        return (
            <svg className={clsx('svg-inline--fa', className)} viewBox="0 0 512 512" onClick={onClick}>
                {ariaLabel != null && <title>{ariaLabel}</title>}
                <use href={`${sprite}#${name}`} />
            </svg>
        );
    }
    return <FontAwesomeIcon icon={name} color={color} size={size} className={className} onClick={onClick} />;
};
