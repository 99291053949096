import { ReactNode } from 'react';
import { fromNavigator } from '@lingui/detect-locale';
import { t, Trans } from '@lingui/macro';
import { Accordion, Button } from '@wedo/design-system';
import { getPlatform, parseUserAgent } from '@wedo/utils';
import { clientVersion } from 'App/store/versionStore';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';

const ErrorTag = ({ label, data }: { label: string; data: string }) => (
    <div className="flex rounded-md border border-gray-200">
        <span className="bg-gray-200 px-2 py-0.5 text-gray-700">{label}</span>
        <span className="px-2 py-0.5 text-gray-500">{data}</span>
    </div>
);

export const ErrorCardTags = () => {
    const { browser } = parseUserAgent();
    const { network } = useCurrentNetwork();
    const { userLanguage: userLocale } = useUserLanguage();
    const browserLanguage = fromNavigator();

    return (
        <pre className="mt-4 mb-2 flex flex-wrap gap-3 text-sm">
            <ErrorTag label={t`URL`} data={document.location.href} />
            <ErrorTag label={t`App version`} data={clientVersion()} />
            <ErrorTag label={t`Short name`} data={network?.short_name} />
            <ErrorTag label={t`User language`} data={userLocale.code} />
            <ErrorTag label={t`Platform`} data={getPlatform()} />
            <ErrorTag label={t`Browser`} data={browser.name} />
            <ErrorTag label={t`Browser version`} data={browser.version} />
            <ErrorTag label={t`Browser language`} data={browserLanguage} />
            <ErrorTag label={t`Timestamp`} data={new Date().toISOString()} />
        </pre>
    );
};

type ErrorCardProps = {
    title?: string;
    subTitle?: string;
    message?: string;
    stack?: string;
    actions?: ReactNode;
    errorSymbol?: ReactNode;
    hideAccordion?: boolean;
};

export const ErrorCard = ({
    title = t`Something went wrong`,
    subTitle = t`We automatically track errors, but don't hesitate to contact support.`,
    message,
    stack,
    actions = (
        <div className="flex gap-4 w-full">
            <Button onClick={() => window.location.reload()} color="gray" className="w-full">
                <Trans>Reload</Trans>
            </Button>
            <Button onClick={() => window.history.back()} color="gray" className="w-full">
                <Trans>Back</Trans>
            </Button>
        </div>
    ),
    errorSymbol = <img src="/assets/error.svg" alt="" />,
    hideAccordion = false,
}: ErrorCardProps) => {
    return (
        <div className="w-full h-full overflow-y-auto grid justify-items-center">
            <div className="gap-6 justify-items-center content-start px-12 grid grid-cols-1 py-24 max-w-5xl">
                {errorSymbol}

                <div className="flex flex-col gap-2 items-center">
                    <p className="text-2xl font-semibold text-gray-800 md:text-2xl">{title}</p>
                    <p className="text-lg font-medium text-gray-500 md:text-xl">{subTitle}</p>
                </div>

                {message != null && (
                    <p className="text-left text-base font-medium text-gray-800 self-start">{message}</p>
                )}

                {!hideAccordion && (
                    <Accordion className="!w-full">
                        {stack != null && (
                            <Accordion.Item title={t`Error detail`}>
                                <pre className="overflow-auto whitespace-pre rounded-md bg-gray-100 p-4 text-left font-mono text-sm">
                                    {stack}
                                </pre>
                            </Accordion.Item>
                        )}
                        <Accordion.Item title={t`Browser and system details`}>
                            <ErrorCardTags />
                        </Accordion.Item>
                    </Accordion>
                )}

                {actions}
            </div>
        </div>
    );
};
