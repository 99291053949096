export enum MEETING_PERMISSIONS {
    VIEW_MEETING_AND_AGENDA = 'viewMeeting',
    VIEW_TOPIC_CONTENT = 'viewTopicContent',
    VIEW_FILES = 'viewFiles',
    CAN_VOTE = 'canVote',
    EDIT_TOPIC_CONTENT = 'editTopicContent',
    MANAGE_TOPIC = 'manageTopic',
    MANAGE_SECTIONS = 'manageSections',
    MANAGE_MEETING = 'manageMeeting',
}
