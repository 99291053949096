import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isAfter } from 'date-fns';
import { Button, Checkbox, Input, ManagedTable, Tooltip, useConfirm, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { EditMeetingUserItemsModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingUserItemsModal';
import {
    getAttendeeReadonlyColumn,
    getRolePosition,
    getRoleReadonlyColumn,
    renderColumnAvatar,
    renderColumnFullName,
    renderColumnStatus,
} from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessReadonlyModal';
import { useMeetingAccessStore } from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessStore';
import { DEFAULT_MEETING_ROLES } from 'Pages/meeting/components/RBAC/DefaultMeetingRoles';
import { hasUserVotedInClosedVote } from 'Pages/meeting/components/Vote/VoteUtils';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { allowedVoteRelated, useGetMeetingVotesQuery } from 'Shared/services/meetingVote';
import { trpc } from 'Shared/trpc';
import { MeetingUser } from 'Shared/types/meetingUser';
import { getRoleName, RoleSelector } from './RoleSelector';

const NotAllowRemoveIcon = ({ title }: { title: string }) => {
    return (
        <Tooltip content={title} delay={300}>
            <div className={'flex cursor-not-allowed justify-center text-gray-500'}>
                <FontAwesomeIcon icon={faTrash} size={'sm'} />
            </div>
        </Tooltip>
    );
};

type Props = {
    meetingId: Id;
    mode: 'default' | 'voter' | 'signatory';
};

export const ManageMeetingUsers = ({ meetingId, mode = 'default' }: Props): JSX.Element => {
    const { currentUser } = useCurrentUserContext();
    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();
    const { open } = useModal();

    const {
        meetingUsers,
        meetingUserItems,
        meetingRoles,
        setMeetingUserItems,
        updateMeetingUser,
        removeMeetingUser,
        setHasChanged,
    } = useMeetingAccessStore();
    const { data: signatureRequest } = trpc.signature.getRequestByMeetingId.useQuery(meetingId);

    const { data: votes = [] } = useGetMeetingVotesQuery({
        meetingId,
        related: allowedVoteRelated,
    });

    const handleUpdateShares = (meetingUser: MeetingUser, shares: number): void => {
        updateMeetingUser(meetingUser, {
            attribute: 'shares',
            value: shares < 0 ? 0 : shares,
        });
    };

    const handleUpdateSignature = (meetingUser: MeetingUser) => {
        updateMeetingUser(meetingUser, {
            attribute: 'signature',
            value: !meetingUser.signature,
        });
    };

    const handleUpdateAttendee = (meetingUser: MeetingUser) => {
        updateMeetingUser(meetingUser, {
            attribute: 'is_attendee',
            value: !meetingUser.is_attendee,
        });
    };

    const handleUpdateVote = (meetingUser: MeetingUser) => {
        if (!meetingUser.can_vote && meetingUser.shares === 0) {
            updateMeetingUser(meetingUser, {
                attribute: 'shares',
                value: 1,
            });
        }
        if (
            meetingUser.can_vote &&
            (votes.length === 0 ||
                votes?.find((v) => v.voteAnswers.find((a) => a.user_id === meetingUser.user_id) != null) == null)
        ) {
            updateMeetingUser(meetingUser, {
                attribute: 'shares',
                value: 0,
            });
        }
        updateMeetingUser(meetingUser, {
            attribute: 'can_vote',
            value: !meetingUser.can_vote,
        });
    };

    const handleCustomClick = (userId: Id) => {
        open(EditMeetingUserItemsModal, {
            userId,
        });
    };

    const handleUpdateMeetingUser = (meetingUser: MeetingUser, roleId: Id) => {
        if (roleId === DEFAULT_MEETING_ROLES.CUSTOM) {
            handleCustomClick(meetingUser.user_id);
            return;
        }
        if (meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id === meetingUser.id).length > 0) {
            const role = meetingRoles.find(({ id }) => id === roleId);
            void confirm({
                type: 'warning',
                title: t`Change the role of ${meetingUser.user.full_name}?`,
                content: (
                    <Trans>
                        All custom roles will be replaced by{' '}
                        <span style={{ color: role.color }} className="font-bold">
                            {role.name}
                        </span>
                    </Trans>
                ),
                onConfirm: () => {
                    updateMeetingUser(meetingUser, {
                        attribute: 'meeting_role_id',
                        value: roleId,
                    });
                    setHasChanged(true);
                    setMeetingUserItems(
                        meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id !== meetingUser.id)
                    );
                    return Promise.resolve();
                },
            });
        }
        if (meetingUser.meeting_role_id && meetingUser.meeting_role_id !== roleId) {
            updateMeetingUser(meetingUser, {
                attribute: 'meeting_role_id',
                value: roleId,
            });
        }
    };

    const hasUserVoted = (meetingUser: MeetingUser) =>
        votes?.some((vote) => vote.voteAnswers.find((answer) => answer.user_id === meetingUser.user_id));

    const columns = [
        {
            title: '',
            dataIndex: 'avatar',
            align: 'left',
            render: renderColumnAvatar,
        },
        {
            title: t`User`,
            dataIndex: 'user',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const full_name_a = a.user ? a.user.full_name : a.user_data.external_full_name;
                const full_name_b = b.user ? b.user.full_name : b.user_data.external_full_name;
                return a.created_at && full_name_a < full_name_b ? 1 : -1;
            },
            className: 'max-w-[300px]',
            render: renderColumnFullName,
        },
        ...(mode === 'default'
            ? [
                  {
                      title: t`Status`,
                      dataIndex: 'status',
                      sorter: (a: MeetingUser, b: MeetingUser) =>
                          isAfter(new Date(a.last_access_at), new Date(b.last_access_at)) ? 1 : -1,
                      render: renderColumnStatus,
                  },
              ]
            : []),
        ...(mode === 'default'
            ? [
                  {
                      title: t`Role`,
                      dataIndex: 'role',

                      sorter: (a: MeetingUser, b: MeetingUser) =>
                          getRolePosition(meetingRoles, a.meeting_role_id) <
                          getRolePosition(meetingRoles, b.meeting_role_id)
                              ? 1
                              : -1,
                      render: (meetingUser: MeetingUser) => {
                          const hasCustomRoles =
                              meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id === meetingUser.id)
                                  .length > 0;
                          const userRole = meetingRoles.find((r) => r.id === meetingUser.meeting_role_id);

                          if (meetingUser.user_id == null || meetingUser.user_id === currentUser?.id) {
                              const roleColor = userRole?.color;

                              return (
                                  <div className={'pl-3 font-bold'} style={{ color: roleColor }}>
                                      {getRoleName(userRole)}
                                  </div>
                              );
                          }
                          return (
                              <div className="flex gap-1">
                                  <RoleSelector
                                      roles={meetingRoles}
                                      meetingRoleSelected={meetingUser.meeting_role_id?.toString()}
                                      hasCustomRoles={hasCustomRoles}
                                      userId={meetingUser.user_id}
                                      onUpdateRole={(roleId: Id) => handleUpdateMeetingUser(meetingUser, roleId)}
                                  />
                                  {hasCustomRoles && (
                                      <Button
                                          size="md"
                                          icon={faPen}
                                          onClick={() => handleCustomClick(meetingUser.user_id)}
                                          title={t`Edit`}
                                      ></Button>
                                  )}
                              </div>
                          );
                      },
                  },
              ]
            : [getRoleReadonlyColumn(meetingRoles)]),
        ...(mode === 'default'
            ? [
                  {
                      title: t`Attendee`,
                      dataIndex: 'attendee',
                      align: 'center',
                      sorter: (a: MeetingUser, b: MeetingUser) => (a.is_attendee && !b.is_attendee ? 1 : -1),
                      render: (meetingUser: MeetingUser) => (
                          <Checkbox
                              disabled={meetingUser.user_id == null || meeting?.status === 'locked' || meeting?.deleted}
                              checked={meetingUser.is_attendee}
                              onChange={() => handleUpdateAttendee(meetingUser)}
                              data-testid="is_attendee"
                          />
                      ),
                  },
              ]
            : [getAttendeeReadonlyColumn()]),
        ...(['default', 'signatory'].includes(mode)
            ? [
                  {
                      title: t`Signatory`,
                      dataIndex: 'signature',
                      sortDirections: ['descend', 'ascend', 'descend'],
                      sorter: (a: MeetingUser, b: MeetingUser) => (a.signature && !b.signature ? 1 : -1),
                      align: 'center',
                      render: (meetingUser: MeetingUser) => (
                          <Checkbox
                              disabled={meetingUser.user_id == null || signatureRequest != null}
                              checked={meetingUser.signature}
                              onChange={() => handleUpdateSignature(meetingUser)}
                              data-testid="signature"
                          />
                      ),
                  },
              ]
            : []),
        ...(['default', 'voter'].includes(mode)
            ? [
                  {
                      title: (
                          <Trans id={'Ids.voteHeader'} comment={'Voter in the meeting user table'}>
                              Voter
                          </Trans>
                      ),
                      dataIndex: 'vote',
                      align: 'center',
                      sorter: (a: MeetingUser, b: MeetingUser) => (a.can_vote && !b.can_vote ? 1 : -1),
                      render: (meetingUser: MeetingUser) => (
                          <Tooltip
                              className={'w-80'}
                              delay={300}
                              content={
                                  hasUserVoted(meetingUser)
                                      ? t`Cannot be unchecked because this user has already voted in this meeting`
                                      : null
                              }
                          >
                              <div>
                                  <Checkbox
                                      disabled={
                                          meetingUser.user_id == null ||
                                          meeting?.status === 'locked' ||
                                          meeting?.deleted ||
                                          hasUserVoted(meetingUser)
                                      }
                                      checked={meetingUser.can_vote}
                                      onChange={() => handleUpdateVote(meetingUser)}
                                      data-testid="vote"
                                  />
                              </div>
                          </Tooltip>
                      ),
                  },
              ]
            : []),
        ...(['default', 'voter'].includes(mode)
            ? [
                  {
                      title: t`Shares`,
                      dataIndex: 'shares',
                      align: 'center',
                      sorter: (a: MeetingUser, b: MeetingUser) => (a.shares > b.shares ? 1 : -1),
                      render: (meetingUser: MeetingUser) => (
                          <Tooltip
                              content={
                                  hasUserVotedInClosedVote(meetingUser.user_id, votes)
                                      ? t`You can't edit shares because this user has answered a vote that is now closed`
                                      : null
                              }
                          >
                              <div>
                                  <Input
                                      type={'number'}
                                      inputClassName={'w-full'}
                                      className={'w-16'}
                                      min={0}
                                      size={'sm'}
                                      value={meetingUser.shares}
                                      disabled={
                                          hasUserVotedInClosedVote(meetingUser.user_id, votes) ||
                                          meetingUser.user_id == null ||
                                          !meetingUser.can_vote ||
                                          meeting?.status === 'locked' ||
                                          meeting?.deleted
                                      }
                                      onChange={(e) => handleUpdateShares(meetingUser, Number(e.target.value))}
                                  />
                              </div>
                          </Tooltip>
                      ),
                  },
              ]
            : []),
        ...(mode === 'default'
            ? [
                  {
                      title: '',
                      dataIndex: 'actions',
                      align: 'center',
                      render: (meetingUser: MeetingUser) => {
                          if (meetingUser.user_id === currentUser?.id) {
                              return <span>&nbsp;</span>;
                          }
                          if (hasUserVoted(meetingUser)) {
                              return (
                                  <NotAllowRemoveIcon
                                      title={t`User cannot be deleted because they already have voted in this meeting`}
                                  />
                              );
                          }
                          if (meeting?.status === 'locked' && meetingUser.is_attendee) {
                              return (
                                  <NotAllowRemoveIcon
                                      title={t`User cannot be deleted because they attended the meeting`}
                                  />
                              );
                          }
                          if (meeting?.status === 'locked' && meetingUser.can_vote) {
                              return (
                                  <NotAllowRemoveIcon
                                      title={t`User cannot be deleted because they were part of the meeting voters`}
                                  />
                              );
                          }
                          return (
                              <Button
                                  color={'danger'}
                                  size={'sm'}
                                  variant={'text'}
                                  icon={faTrash}
                                  onClick={() => removeMeetingUser(meetingUser)}
                                  title={t`Remove`}
                              />
                          );
                      },
                  },
              ]
            : []),
    ];

    return (
        <>
            <ManagedTable
                data={meetingUsers}
                columns={columns}
                rowKey={(meetingUser) => meetingUser.user_id || meetingUser.id}
            />
        </>
    );
};
