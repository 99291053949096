import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { enableMapSet } from 'immer';
import { invalidationReducer } from 'App/store/invalidationStore';
import meetingViewReducer from 'Pages/meeting/MeetingViewSlice';
import { apiErrorLogger, baseApi } from 'Shared/services/base';
import { statusPageApi } from 'Shared/services/statusPage';
import { crashReporter } from 'Shared/store/middlewares';

enableMapSet();

export type Action = {
    type: string;
    payload: unknown;
};

const store = configureStore({
    reducer: {
        meetingViewState: meetingViewReducer,
        [baseApi.reducerPath]: baseApi.reducer,
        [statusPageApi.reducerPath]: statusPageApi.reducer,
        invalidationState: invalidationReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
            crashReporter,
            statusPageApi.middleware,
            baseApi.middleware,
            apiErrorLogger
        );
    },
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
// export type RootState = ReturnType<typeof store.getState>;

// eslint-disable-next-line import/no-default-export
export default store;
