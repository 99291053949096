import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { useGetChecklistQuery } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { User } from 'Shared/types/user';
import { isValidWorkspaceId } from 'Shared/utils/workspace';

export const useEntityMembers = ({
    workspaceId,
    checklistId,
    templateId,
}: {
    workspaceId?: Id;
    checklistId: Id;
    templateId: Id;
}): User[] => {
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !isValidWorkspaceId(workspaceId) });
    const { data: checklist } = useGetChecklistQuery(checklistId, { skip: !checklistId });
    const { data: template } = useGetChecklistTemplateQuery(templateId ?? checklist?.checklist_template_id, {
        skip: !templateId && !checklist,
    });

    const members = useMemo(() => workspace?.userGroup?.members ?? template?.userGroup?.members, [workspace, template]);

    return members?.map((member) => member.user) ?? [];
};
