import { ReactNode, useMemo, useState } from 'react';
import { faFile } from '@fortawesome/pro-duotone-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Table } from '~/components/Table/Table';
import { EmptyState } from '../EmptyState/EmptyState';

type Column = {
    dataIndex: string; // identifier of the column
    title: ReactNode; // displayed in the column header
    align?: string;
    render: (any) => ReactNode;
    sorter?: (a: any, b: any) => number;
    className?: string;
};

type ManagedTableProps = {
    columns: Array<Column>;
    tableProps?: any;
    tableHeadProps?: any;
    tableBodyProps?: any;
    rowKey: (dataItem) => string;
    data: any[];
};

export const ManagedTable = ({
    columns,
    data,
    rowKey,
    tableProps = {},
    tableHeadProps = {},
    tableBodyProps = {},
}: ManagedTableProps) => {
    const [sorter, setSorter] = useState<{
        dataIndex: string;
        direction: 'ascending' | 'descending';
    }>({
        dataIndex: '',
        direction: 'ascending',
    });
    const onSort = (column: Column) => {
        let newDirection: 'ascending' | 'descending' = 'ascending';

        if (sorter.dataIndex === column?.dataIndex) {
            switch (sorter.direction) {
                case 'ascending':
                    newDirection = 'descending';
                    break;
                case 'descending':
                    newDirection = null;
                    break;
                default:
                    newDirection = 'ascending';
            }
        }

        setSorter({ dataIndex: newDirection !== null ? column.dataIndex : '', direction: newDirection });
    };

    const sortedData = useMemo(() => {
        const column = columns.find((column) => column.dataIndex === sorter.dataIndex) ?? null;

        if (column === null) {
            return data;
        }

        const draft = structuredClone(data);

        draft?.sort(column.sorter);
        if (sorter.direction === 'descending') {
            draft.reverse();
        }
        return draft;
    }, [data, sorter.direction, sorter.dataIndex]);

    const AlignmentClasses = { center: 'text-center', right: 'text-end', left: 'text-start' };

    return (
        <Table {...tableProps}>
            <Table.Head {...tableHeadProps}>
                {columns.map((column, index) => (
                    <Table.HeadCell
                        key={index}
                        sortDirection={column.sorter && sorter.dataIndex === column.dataIndex ? sorter.direction : null}
                        onSort={column.sorter && (() => onSort(column))}
                    >
                        {column.title}
                    </Table.HeadCell>
                ))}
            </Table.Head>
            <Table.Body {...tableBodyProps}>
                {sortedData?.length > 0 ? (
                    sortedData.map((v, index) => (
                        <Table.Row key={rowKey ? rowKey(v) : index}>
                            {columns.map((c) => (
                                <Table.Cell
                                    className={clsx(AlignmentClasses[c.align] ?? AlignmentClasses.left, c.className)}
                                    key={(rowKey ? rowKey(v) : index) + '-' + c.dataIndex}
                                >
                                    {c.render(v)}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))
                ) : (
                    <Table.Row>
                        <Table.Cell key="empty" colSpan={columns.length}>
                            <EmptyState icon={faFile} size="lg">
                                <EmptyState.Text>{t`No data`}</EmptyState.Text>
                            </EmptyState>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};
