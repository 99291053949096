import React, { FC } from 'react';
import { faHistory } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, EmptyState, FormatDateDistance, Modal, Skeleton, Table } from '@wedo/design-system';
import { getUser } from 'App/store/usersStore';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { trpc } from 'Shared/trpc';

type DownloadHistoryModalProps = ContextModalProps & { attachmentId: string };

export const DownloadHistoryModal: FC<DownloadHistoryModalProps> = ({ attachmentId, ...modalProps }) => {
    const { data: downloads = [], isLoading: isLoading } = trpc.activity.listAttachmentDownloads.useQuery(
        attachmentId,
        {
            select: (items: { id: string; createdAt: string; createdBy: string }[]) =>
                items.map((item) => ({
                    id: item.id,
                    created_at: item.createdAt,
                    user: getUser(item.createdBy),
                })),
        }
    );

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Download history`} />
            <Modal.Body>
                {isLoading ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-4" />
                    </div>
                ) : downloads.length === 0 ? (
                    <EmptyState icon={faHistory} size="md">
                        <EmptyState.Text>
                            <Trans>No downloads found</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                ) : (
                    <Table>
                        <Table.Row>
                            <Table.HeadCell>
                                <Trans>Date</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>User</Trans>
                            </Table.HeadCell>
                        </Table.Row>
                        {downloads.map((download) => (
                            <Table.Row key={download.id}>
                                <Table.Cell>
                                    <FormatDateDistance date={download.created_at} />
                                </Table.Cell>
                                <Table.Cell className="flex gap-2 items-center">
                                    <UserAvatar user={download.user} />
                                    {download.user.full_name}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
