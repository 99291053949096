import type { ReactElement } from 'react';
import { Button, useModal } from '@wedo/design-system';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { Task } from 'Shared/types/task';

type TaskSubtaskHistoryProps = {
    title: ReactElement;
    subtask?: Task;
};
export const TaskSubtaskHistory = ({ title, subtask }: TaskSubtaskHistoryProps) => {
    const { open: openModal } = useModal();
    return (
        <>
            {title}
            &nbsp;
            {subtask?.id && (
                <Button variant={'link'} onClick={() => openModal(TaskModal, { taskId: subtask.id })}>
                    <span className="max-w-96 truncate">{subtask.name}</span>
                </Button>
            )}
        </>
    );
};
