import { FC } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { preventDefault } from '@wedo/utils';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ToolButton: FC<{
    icon: IconDefinition;
    isActive: boolean;
    onClick: () => void;
    children: React.ReactNode;
}> = ({ icon, isActive, onClick, children }) => (
    <div
        className={clsx(
            'flex cursor-pointer snap-start items-center gap-3 rounded-md px-2 py-1 hover:z-10 hover:bg-gray-200',
            isActive && 'bg-blue-200',
        )}
        onClick={onClick}
        onMouseDown={preventDefault()}
        onKeyDown={preventDefault()}
        role="button"
        tabIndex={0}
    >
        <div className={clsx('flex h-4 w-4 items-center justify-center')}>
            <FontAwesomeIcon icon={icon} />
        </div>
        <span className="font-normal">{children}</span>
    </div>
);
