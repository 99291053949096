import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faExclamationTriangle, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { isAfter } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { Button, DatePicker, DatePickerShortcuts, FormatDate, Popover, Skeleton, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getUserTimeZone } from '@wedo/utils';
import { TaskDetailIcon } from 'Shared/components/task/TaskDetail/shared/TaskDetailIcon';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';

type TaskDetailPlannedDateProps = {
    taskId: Id;
};

export const TaskDetailStartDate = ({ taskId }: TaskDetailPlannedDateProps) => {
    const { task, isTaskReadonly, handleTaskUpdate, isLoadingTask } = useTask(taskId);

    const timeZone = getUserTimeZone();

    const today = new Date();

    const onSelectDate = async (date: Date, closePopoverFn: () => void) => {
        await handleTaskUpdate({ planned_date: date.toISOString() });
        closePopoverFn();
    };

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={t`Start date`} delay={300}>
                    <TaskDetailIcon type="planned_date" isActive={!!task?.planned_date} />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    <Popover
                        text={
                            task?.planned_date ? (
                                <>
                                    <FormatDate date={toDate(task.planned_date, { timeZone })} format={'shortDate'} /> (
                                    {t`start date`}){' '}
                                    {task?.planned_date != null &&
                                        task?.due_date != null &&
                                        isAfter(new Date(task?.planned_date), new Date(task?.due_date)) && (
                                            <Tooltip content={t`Start date is after due date`}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                            </Tooltip>
                                        )}
                                </>
                            ) : (
                                t`Select start date`
                            )
                        }
                        disabled={isTaskReadonly}
                        variant="text"
                    >
                        {({ close }) => (
                            <div tabIndex={-1} className={'flex flex-col gap-2 bg-white p-3 outline-0'}>
                                <DatePickerShortcuts onChange={(date: Date) => onSelectDate(date, close)} />
                                <DatePicker
                                    timezone="UTC"
                                    date={task?.planned_date ? new Date(task?.planned_date) : today}
                                    onChange={(date) => onSelectDate(date, close)}
                                />
                            </div>
                        )}
                    </Popover>
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {task?.planned_date != null && !isTaskReadonly && (
                    <Button
                        variant={'text'}
                        icon={faXmark}
                        size={'sm'}
                        className={'enabled:hover:bg-red-100 enabled:hover:text-red-500'}
                        title={t`Remove`}
                        disabled={isTaskReadonly}
                        onClick={() => handleTaskUpdate({ planned_date: null })}
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
