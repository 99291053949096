import { useRef } from 'react';
import { Editor } from 'slate';
import { SavingIndicator as DSSavingIndicator } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { Second } from '@wedo/utils';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { useServerBlocksPluginStore } from './useServerBlocksPluginStore';

type SavingIndicatorProps = {
    editor: Editor;
    meetingId: Id;
    topicId: Id;
};

export const SavingIndicator = ({ editor, meetingId, topicId }: SavingIndicatorProps) => {
    const status = useServerBlocksPluginStore((state) => state.meetings[meetingId]?.topics[topicId]?.status ?? 'idle');
    const oldStatus = useRef(status);

    const handleRetry = () => {
        forceSave(editor);
    };

    if (oldStatus.current === status && status === 'idle') {
        return null;
    }

    oldStatus.current = status;

    return (
        <div className="sticky bottom-0.5">
            <div className="absolute bottom-2 right-2 z-10">
                <DSSavingIndicator
                    onClick={handleRetry}
                    status={status !== 'idle' ? status : 'success'}
                    isIdle={status === 'idle'}
                    delay={3 * Second}
                />
            </div>
        </div>
    );
};
