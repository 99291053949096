import { FC, useId, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { ZodIssue } from 'zod';
import { Button, Form, Input } from '@wedo/design-system';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';

export const TeamStep: FC = () => {
    const { updateKey, actions, zodErrors, getError, ...store } = useOnboarding();

    const [canShowError, setCanShowError] = useState(false);

    const handleNext = async () => {
        setCanShowError(true);
        let errors = structuredClone(zodErrors) as ZodIssue[];
        errors = errors?.filter((error) => ['teamName'].some((key) => error.path.includes(key)));
        if (errors?.length > 0 || store.teamName.trim().length === 0) {
            return;
        }
        actions.setStep(store.step + 1);
    };

    return (
        <>
            <Form
                onKeyDown={(e) => e.key === 'Enter' && handleNext()}
                layout="vertical"
                title={t`Create your first team`}
            >
                <Form.Item>
                    <Trans>
                        We commonly advise creating a team per department. A team is a group of people working on
                        several different topics.
                    </Trans>
                </Form.Item>
                <Form.Item>
                    <Input
                        id={useId()}
                        value={store.teamName}
                        onChange={(e) => updateKey({ key: 'teamName', value: e.target.value })}
                        status={canShowError && !isEmpty(getError('teamName')) ? 'error' : 'default'}
                        statusText={canShowError ? getError('teamName')?.message : ''}
                    />
                </Form.Item>
            </Form>

            <OnboardingLayout.Buttons>
                <Button icon={faChevronLeft} onClick={() => actions.setStep(store.step - 1)}>{t`Previous`}</Button>
                <Button
                    color="primary"
                    disabled={(canShowError && !isEmpty(getError('teamName'))) || isEmpty(store.teamName)}
                    onClick={handleNext}
                    icon={faChevronRight}
                    iconPosition="end"
                >
                    <Trans>Next</Trans>
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
