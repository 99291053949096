import React from 'react';
import { Tag } from '@wedo/design-system';
import { CustomFieldType } from 'Shared/types/customField';
import { customFieldTypeToIcon, customFieldTypeToString } from 'Shared/utils/customFields';

interface CustomFieldTypeTagProps {
    fieldType: CustomFieldType;
}

export const CustomFieldTypeTag: React.FC<CustomFieldTypeTagProps> = ({ fieldType }) => {
    return (
        <Tag size="sm" color="gray" icon={customFieldTypeToIcon[fieldType]}>
            <span className={'@2xl:block hidden'}>{customFieldTypeToString[fieldType]}</span>
        </Tag>
    );
};
