import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Card, ProgressBar, Skeleton, useModal } from '@wedo/design-system';
import { formatCurrency, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { ManageQuotaModal } from 'Pages/settings/billing/components/ManageQuotaModal';
import { useGetAdminNetworkQuery } from 'Shared/services/network';
import { trpc } from 'Shared/trpc';
import { isMeteredSignatureAddonId } from 'Shared/utils/chargebee';

export const UsagesCard = () => {
    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const [{ manage }, setSearchParams] = useSearchParams({ manage: string() });
    const { data: subscription, isLoading: isLoadingSubscription } = trpc.subscription.get.useQuery();
    const meteredSignatureAddon = subscription?.subscription_items.find((item) =>
        isMeteredSignatureAddonId(item.item_price_id)
    );
    const usedSignatures = meteredSignatureAddon?.metered_quantity ?? 0;
    const { data: network } = useGetAdminNetworkQuery();
    const quota = network?.config?.signatureQuota ?? 'no-quota';

    useEffect(() => {
        if (manage === 'signatureQuota') {
            // timeout because "closeAllOpenModals" is called when mounting the DOM
            setTimeout(() => openModal(ManageQuotaModal, { currentQuota: quota }), 0);
            setSearchParams({ manage: '' });
        }
    }, [manage]);

    const percentage = usedSignatures >= quota ? 100 : (usedSignatures / quota) * 100;

    return (
        <Card>
            <Card.Header title={t`Usages`} />
            <Card.Body>
                {isLoadingSubscription ? (
                    <Skeleton className="w-full h-12  mb-4" />
                ) : (
                    <div className="gap-4 flex items-start justify-between mb-4">
                        <div>
                            <div>
                                <Trans>AES and QES signatures</Trans>
                            </div>
                            <div className="text-gray-400">
                                <Trans>
                                    {formatCurrency({
                                        value: meteredSignatureAddon?.unit_price ?? 0,
                                        currency: subscription?.currency_code,
                                        locale: i18n.locale,
                                    })}{' '}
                                    per signature
                                </Trans>
                            </div>
                        </div>
                        <div>
                            {isLoadingSubscription ? (
                                <Skeleton className="!w-32 h-5" />
                            ) : (
                                formatCurrency({
                                    value:
                                        meteredSignatureAddon?.unit_price *
                                        (meteredSignatureAddon?.metered_quantity ?? 0),
                                    currency: subscription?.currency_code,
                                    locale: i18n.locale,
                                })
                            )}
                        </div>
                    </div>
                )}
                {isLoadingSubscription ? (
                    <Skeleton className="h-12" />
                ) : (
                    <>
                        {quota !== 'no-quota' && (
                            <ProgressBar
                                className="mb-2"
                                size="thin"
                                color={percentage === 100 ? 'red' : percentage >= 90 ? 'orange' : 'green'}
                                percent={percentage}
                            />
                        )}
                        <div className="flex justify-between gap-2">
                            <div className="text-sm">
                                {quota !== 'no-quota' ? (
                                    <Trans>
                                        <div
                                            className={clsx(
                                                percentage === 100
                                                    ? 'text-red-500'
                                                    : percentage >= 90
                                                      ? 'text-orange-500'
                                                      : 'text-green-500',
                                                'font-bold inline'
                                            )}
                                        >
                                            {usedSignatures}
                                        </div>{' '}
                                        of {quota} used
                                    </Trans>
                                ) : (
                                    <Trans>{usedSignatures} signatures used</Trans>
                                )}
                            </div>
                            <Button onClick={() => openModal(ManageQuotaModal, { currentQuota: quota })} size="sm">
                                <Trans>Manage quota</Trans>
                            </Button>
                        </div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};
