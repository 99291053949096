import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { sortBy } from 'lodash-es';
import {
    Button,
    CloseSource,
    ContextModalProps,
    Modal,
    ModalType,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { SorterByUserFullName } from 'Pages/meeting/components/EditMeetingAccessModal/EditSectionRoleModal';
import { ManageMeetingUserItems } from 'Pages/meeting/components/EditMeetingAccessModal/ManageMeetingUserItems';
import {
    getCustomRole,
    useMeetingAccessStore,
} from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessStore';
import { useSaveMeetingAccess } from 'Pages/meeting/components/EditMeetingAccessModal/useSaveMeetingAccess';
import { sortRoleByImportance } from 'Pages/meeting/components/EditMeetingAccessModal/utils';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';

type Props = {
    meetingId: Id;
    userId: Id;
} & ContextModalProps &
    PropsWithChildren;
export const EditMeetingUserAccessModal = ({ meetingId, userId, children, ...modalProps }: Props): JSX.Element => {
    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const { handleSaveMeetingUsers, handleSaveMeetingUserItems } = useSaveMeetingAccess({ meetingId });
    const { meeting } = useMeeting(meetingId);

    const {
        hasChanged,
        meetingUsers,
        meetingUserItems,
        updateMeetingUser,
        setMeetingUsers,
        setMeetingUserItems,
        setMeetingRoles,
        setHasChanged,
    } = useMeetingAccessStore();

    const meetingUser = useMemo(() => meetingUsers.find(({ user_id }) => user_id === userId), [userId, meetingUsers]);

    const [isLoading, setIsLoading] = useState(false);
    const savedMeetingUser = useMemo(() => meeting?.meetingUsers?.find(({ id }) => id === meetingUser?.id), [meeting]);

    useEffect(() => {
        if (meeting) {
            setMeetingUsers([...meeting.meetingUsers].sort(SorterByUserFullName));
            setMeetingUserItems(meeting?.meetingUsers?.map((user) => user.items).flat());
            setMeetingRoles([...sortBy(meeting?.roles, sortRoleByImportance), getCustomRole()]);
            setHasChanged(false);
        }
    }, [meeting]);

    const handleUpdateMeetingUserRole = (meetingRoleId: Id) =>
        updateMeetingUser(meetingUser, {
            attribute: 'meeting_role_id',
            value: meetingRoleId,
        });

    const handleRevert = async (): Promise<boolean> => {
        const discard = await confirm({}, ConfirmDiscardChangesModal);
        if (discard) {
            handleUpdateMeetingUserRole(savedMeetingUser.meeting_role_id);
            setHasChanged(true);
            setMeetingUserItems([
                ...meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id !== meetingUser.id),
                ...(meeting.meetingUsers.find(({ id }) => id === meetingUser.id)?.items ?? []),
            ]);
        }
        return Promise.resolve(Boolean(discard));
    };

    const handleBeforeClose = (source?: CloseSource): Promise<boolean> => {
        if (hasChanged && ['cancel', 'backdrop-or-esc', 'cross'].includes(source)) {
            return handleRevert();
        }
        return Promise.resolve(true);
    };

    const handleSave = async () => {
        if (!hasChanged) {
            modalProps.onClose();
            return;
        }
        const applyOn = await confirm(
            {
                title: t`Which meetings should be updated?`,
                type: ModalType.Question,
                showAll: true,
            },
            ConfirmSaveMeetingModal
        );
        try {
            setIsLoading(true);
            await handleSaveMeetingUsers(applyOn);
            await handleSaveMeetingUserItems(applyOn);
            setIsLoading(false);
            await modalProps.onClose();
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    return (
        <Modal size={'lg'} onBeforeClose={handleBeforeClose} {...modalProps}>
            <Modal.Header title={<Trans>{meetingUser?.user.full_name} access rights</Trans>} />
            <Modal.Body>
                <ManageMeetingUserItems userId={userId} />
            </Modal.Body>
            <Modal.Footer>
                <div className="flex gap-2">
                    <Button key="close" onClick={() => modalProps.close()}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button key="save" color="primary" loading={isLoading} onClick={handleSave}>
                        <Trans>Save</Trans>
                    </Button>
                </div>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
