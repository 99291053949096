import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button } from '@wedo/design-system';
import { useDesktopNotifications } from 'Shared/hooks/useDesktopNotifications';

export const DesktopNotificationsSupportedSection = () => {
    const { notificationsEnabled, askPermission, hasDeniedPermission, show } = useDesktopNotifications();

    const handleTestNotification = () => {
        void show(t`WEDO`, {
            body: t`This is an awesome test notification 🥳`,
        });
    };

    return (
        <>
            {notificationsEnabled ? (
                <Alert
                    type={'info'}
                    title={
                        <Trans>
                            Desktop notifications are enabled (
                            <Button variant={'link'} color={'primary'} onClick={handleTestNotification}>
                                test notification
                            </Button>
                            )
                        </Trans>
                    }
                />
            ) : (
                <Alert type={'warning'} title={t`Desktop notifications are disabled`}>
                    <Trans>
                        We highly recommend that you enable desktop permissions to receive notifications directly via
                        your browser. To enable desktop notifications go to your browser settings.
                    </Trans>
                </Alert>
            )}

            {!notificationsEnabled && !hasDeniedPermission && (
                <Button color="primary" className="mt-4" onClick={askPermission}>
                    <Trans>Enable desktop notifications</Trans>
                </Button>
            )}
        </>
    );
};
