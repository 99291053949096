import { useState } from 'react';
import { faCheckCircle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, useConfirm } from '@wedo/design-system';
import { getBreakpointValue } from '@wedo/utils';
import { RevisitTopicLabel } from 'Shared/components/meeting/topicView/RevisitTopicLabel';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type AddressButtonProps = {
    topic: MeetingTopic;
    onTopicChange: (topic: Partial<MeetingTopic>, safe: boolean) => Promise<void>;
    containerSize: { width: number; height: number };
};

export const AddressTopicComponent = ({ topic, onTopicChange, containerSize }: AddressButtonProps): JSX.Element => {
    const { confirm } = useConfirm();

    const [isLoading, setIsLoading] = useState(false);

    const isRepeating = topic?.repeat_every > 0 || topic?.next_occurrences?.some((occurrence) => occurrence.id != null);

    const handleChange = async () =>
        onTopicChange({ addressed: !topic.addressed, repeat_every: isRepeating ? 0 : undefined }, true);

    const handleClick = async () => {
        if (isRepeating) {
            setIsLoading(true);
            await confirm({
                type: 'success',
                title: t`Complete this repeating topic?`,
                content: t`By completing this topic, it will no longer be present in future meetings`,
                confirmText: t`Complete`,
                onConfirm: async () => {
                    void handleChange();
                },
            });
            setIsLoading(false);
        } else {
            void handleChange();
        }
    };

    const UndoButton = () => (
        <Button
            variant={'link'}
            color={'primary'}
            className={'px-0'}
            onClick={() => onTopicChange({ addressed: false }, false)}
        >
            (<Trans>undo</Trans>)
        </Button>
    );

    if (topic.revisited) {
        return null;
    }

    if (topic.addressed && isRepeating) {
        return (
            <div className={'text-sm'}>
                <span>
                    <RevisitTopicLabel topic={topic} />
                </span>{' '}
                <UndoButton />
            </div>
        );
    }

    return (
        <>
            {topic.addressed ? (
                <div className={'text-sm'}>
                    <Trans>This topic is completed</Trans> <UndoButton />
                </div>
            ) : (
                <Button
                    className={clsx('hover:text-green-600', containerSize?.width < 450 ? 'w-20' : 'w-40')}
                    title={containerSize?.width < getBreakpointValue('xs') && t`Complete topic`}
                    icon={isLoading ? faSpinner : faCheckCircle}
                    iconClassName={clsx(isLoading && 'fa-pulse')}
                    onClick={handleClick}
                >
                    {containerSize?.width >= 450 ? <Trans id="Complete topic">Complete</Trans> : undefined}
                </Button>
            )}
        </>
    );
};
