import React, { useEffect, useState } from 'react';
import { faAt } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyFunction } from '@wedo/utils';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useAppDispatch } from 'App/store';
import { FeedType } from 'Pages/activityFeed/ActivityFeedConstants';
import { FeedEditorAttachment } from 'Pages/activityFeed/FeedEditor/FeedEditorAttachment';
import { FeedEditor } from 'Shared/components/editor/FeedEditor';
import { HtmlContentType } from 'Shared/components/editor/plugins/copyPastePlugin/copyPastePlugin';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useMentions } from 'Shared/hooks/useMentions';
import { invalidateActivityFeeds } from 'Shared/services/activityFeed';
import { useAddCommentMutation, useUpdateCommentMutation } from 'Shared/services/comment';
import { Comment, COMMENT_OBJECT_TYPE } from 'Shared/types/comment';
import { User } from 'Shared/types/user';

const domParser = new DOMParser();

const isHtmlEmpty = (html: string) => {
    if (html == null) {
        return true;
    }

    return domParser.parseFromString(html, HtmlContentType).body.textContent.trim() === '';
};

const getObjectType = (type: FeedType): COMMENT_OBJECT_TYPE => {
    switch (type) {
        case 'workspace':
            return COMMENT_OBJECT_TYPE.TAG;
        case 'template':
            return COMMENT_OBJECT_TYPE.CHECKLIST_TEMPLATE;
        case 'me':
            return COMMENT_OBJECT_TYPE.NETWORK;
        case 'team':
            return COMMENT_OBJECT_TYPE.TEAM;
        default:
            return null;
    }
};

const getMentionsByType = (
    type: FeedType,
    objectId: Id
): { tag_id?: Id; checklist_template_id?: Id; team_id?: Id }[] => {
    if (type === 'workspace') {
        return [{ tag_id: objectId }];
    }
    if (type === 'template') {
        return [{ checklist_template_id: objectId }];
    }
    if (type === 'team') {
        return [{ team_id: objectId }];
    }
    return [];
};

type ActivityFeedEditorViewProps = {
    type?: FeedType;
    objectId?: Id;
    currentItem?: Comment;
    onClose?: () => void;
    hasAutoFocus?: boolean;
    onDelete?: () => void;
};
export const ActivityFeedEditorView = ({
    type = null,
    objectId = null,
    currentItem = null,
    onClose = null,
    hasAutoFocus = false,
    onDelete = EmptyFunction,
}: ActivityFeedEditorViewProps): JSX.Element => {
    const dispatch = useAppDispatch();
    // eslint-disable-next-line no-new-wrappers
    const [initialValue, setInitialValue] = useState(currentItem ? currentItem.comment : new String(''));
    const [recipients, setRecipients] = useState<User[]>([]);
    const [addComment, { isLoading: isAdding }] = useAddCommentMutation();
    const [updateComment, { isLoading: isUpdating }] = useUpdateCommentMutation();
    const isLoading = isAdding || isUpdating;
    const { addUserToMentions } = useMentions();

    // newAttachments = attachments that have just been added and are not yet on the server
    const [newAttachments, setNewAttachments] = useState(new Map<string, File>());

    // oldAttachments = attachments that have already been uploaded on the server
    const [oldAttachments, { remove: removeOldAttachment, add: addOldAttachment, reset: resetOldAttachments }] = useSet(
        new Set<Id>()
    );

    const [value, setValue] = useState<string>(initialValue.toString());

    const { show: showNotification } = useNotification();

    useEffect(() => {
        if (currentItem) {
            const mentionsWithUsers = addUserToMentions(currentItem.mentions);
            setRecipients(
                mentionsWithUsers.filter((mention) => mention.user != null).map((mention) => mention.user as User)
            );

            resetOldAttachments();
            currentItem.attachments.forEach((attachment) => {
                addOldAttachment(attachment.attachment_version_id);
            });
        }
    }, [addUserToMentions, currentItem]);

    useEffect(() => {
        setValue(initialValue.toString());
    }, [initialValue]);

    const handleAdd = async (): Promise<void> => {
        if (isHtmlEmpty(value)) {
            return;
        }

        const mentions = [...getMentionsByType(type, objectId), ...recipients.map((u: User) => ({ user_id: u.id }))];

        const form = new FormData();

        form.append('comment', value);
        form.append('mentions', JSON.stringify(mentions));
        newAttachments.forEach((newAttachment) => {
            form.append('attachments', newAttachment);
        });

        const res = await addComment({ objectType: getObjectType(type), objectId: String(objectId), body: form });

        if ('error' in res) {
            showNotification(UnexpectedErrorNotification);
        } else if ('data' in res) {
            dispatch(invalidateActivityFeeds());
            // We use `new String` so even if the previous value was also empty, the equality comparison will fails
            // eslint-disable-next-line no-new-wrappers
            setInitialValue(new String(''));
            setRecipients([]);
            setNewAttachments(new Map());
        }
    };

    const handleEdit = async (): Promise<void> => {
        if (isHtmlEmpty(value)) {
            onDelete();
            return;
        }

        const mentions = [
            ...currentItem.mentions.filter(
                (m) => m.tag_id != null || m.checklist_template_id != null || m.team_id != null
            ),
            ...recipients.map((u: User) => {
                const current = currentItem.mentions.find((m) => m.user_id === u.id);
                return current || { user_id: u.id };
            }),
        ];

        const body = new FormData();
        body.append('comment', value);
        body.append('mentions', JSON.stringify(mentions));
        body.append(
            'attachments',
            JSON.stringify([...oldAttachments.values()].map((attachmentId) => ({ id: String(attachmentId) })))
        );
        newAttachments.forEach((attachment) => {
            body.append('newAttachments', attachment);
        });

        const res = await updateComment({
            commentId: currentItem.id,
            body,
        });
        if ('error' in res) {
            showNotification(UnexpectedErrorNotification);
        } else if ('data' in res) {
            dispatch(invalidateActivityFeeds());
            setRecipients([]);
            onClose();
        }
    };

    const handleConfirm = async (): Promise<void> => {
        if (currentItem) {
            await handleEdit();
        } else {
            await handleAdd();
        }
    };

    const handleAddAttachment = (files: File[]) => {
        setNewAttachments((newAttachments) => {
            files.forEach((file) => {
                newAttachments.set(file.name, file);
            });

            return structuredClone(newAttachments);
        });
    };

    const handleRemoveAttachment = (name: string) => {
        setNewAttachments((newAttachments) => {
            newAttachments.delete(name);
            return structuredClone(newAttachments);
        });
    };

    const handleAddRecipient = (user: User): void => {
        setRecipients((recipients) => {
            if (!recipients.some((recipient) => recipient.id === user.id)) {
                return recipients.concat(user);
            }

            return recipients;
        });
    };

    const handleRemoveRecipient = (userId: Id): void => {
        setRecipients((recipients) => recipients.filter((item) => item.id !== userId));
    };

    return (
        <div className="flex flex-col gap-1">
            <div className="border border-gray-300">
                <FeedEditor
                    html={initialValue as string}
                    onMention={handleAddRecipient}
                    onChange={setValue}
                    hasAutoFocus={hasAutoFocus}
                />
                <FeedEditorAttachment
                    oldAttachments={
                        currentItem?.attachments.filter((attachment) =>
                            oldAttachments.has(attachment.attachment_version_id)
                        ) ?? []
                    }
                    newAttachments={[...newAttachments.values()]}
                    onAddAttachment={handleAddAttachment}
                    onDeleteOldAttachment={removeOldAttachment}
                    onDeleteNewAttachment={handleRemoveAttachment}
                />
            </div>
            <div className="flex justify-between">
                <div className="flex flex-row">
                    {recipients?.length > 0 && (
                        <div className="mr-3 flex flex-row items-center gap-1">
                            {recipients.map((user: User) => (
                                <UserAvatar
                                    key={user.id}
                                    user={user}
                                    size="sm"
                                    onRemove={() => handleRemoveRecipient(user.id)}
                                />
                            ))}
                        </div>
                    )}
                    <UserPicker icon={faAt} onUserSelected={handleAddRecipient} usersToHide={recipients}>
                        <Trans>Add recipients</Trans>
                    </UserPicker>
                </div>
                <div className="flex flex-row gap-1">
                    {!!currentItem && <Button onClick={onClose}>{t`Cancel`}</Button>}
                    <Button
                        color="primary"
                        loading={isLoading}
                        disabled={currentItem == null && isHtmlEmpty(value)}
                        onClick={handleConfirm}
                    >
                        {currentItem ? t`Save` : t`Publish`}
                    </Button>
                </div>
            </div>
        </div>
    );
};
