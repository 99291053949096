import { I18nProvider } from '@lingui/react';
import { Document, Page, View, Text as PdfText, Text } from '@react-pdf/renderer';
import React, { createContext, useContext } from 'react';
import { type Messages, setupI18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { addDays } from 'date-fns';
import { Logo } from '../Logo';
import { colors } from '../colors';
import { Task } from '../tasks/Task';
import { Orientation } from '../types';
import { Network, Task as TaskType, type User } from '../types';
import { DesignPdfContext } from '../types';
import { formatShortDate } from '../utils';
import { mmToPx, fontSizes, registerFont } from '../utils';

type Density = 'small' | 'medium' | 'large';

const taskItemsToDisplay = [
    'startDate',
    'tags',
    'dueDate',
    'priority',
    'assignee',
    'customFields',
    'subTasks',
    'description',
    'comments',
    'attachments',
];

export type TaskItemsToDisplay = (typeof taskItemsToDisplay)[number];

type TaskPdfContextProps = DesignPdfContext & {
    isTaskItemVisible?: Record<TaskItemsToDisplay, boolean>;
    tabSizes: {
        priority: number;
        assignee: number;
        workspace: number;
        startDate: number;
        dueDate: number;
    };
    density: Density;
    locale: {
        code: string;
        messages: Messages;
    };
    users?: Array<User>;
    isTemplate: boolean;
};

type TaskGoup = {
    tasks: Array<TaskType>;
    label: string | JSX.Element;
    key: string;
};

export const TaskPdfContext = createContext<TaskPdfContextProps>(null);

export const useTaskPdfContext = () => useContext(TaskPdfContext);

export type TaskPdfProps = {
    settings: {
        locale: {
            code: string;
            messages: Messages;
        };
        density: Density;
        title: string;
        footer: string;
        isTemplate: boolean;
        isGroupTitleHidden: boolean;
        columns: string[];
        signature: {
            locationAndDateLabel: string;
            show: boolean;
            signatureLabel: string;
        };
        orientation: Orientation;
        grouping: string;
        order: string;
        logo: string;
    };
    groupedTasks: Array<TaskGoup>;
    users?: Array<User>;
    network: Network;
};

const TaskListHeader = () => {
    const { tabSizes, spacing, color, fontSize } = useTaskPdfContext();

    const headerCells = [
        { tag: 'priority' },
        {
            tag: 'assignee',
            content: (
                <PdfText>
                    <Trans>Assignee</Trans>
                </PdfText>
            ),
        },
        {
            tag: 'workspace',
            content: (
                <PdfText>
                    <Trans>Checklist & Workspaces</Trans>
                </PdfText>
            ),
        },
        {
            tag: 'startDate',
            content: (
                <PdfText>
                    <Trans>Start date</Trans>
                </PdfText>
            ),
        },
        {
            tag: 'dueDate',
            content: (
                <PdfText>
                    <Trans>Due date</Trans>
                </PdfText>
            ),
        },
    ];

    return (
        <View
            wrap={false}
            style={{
                fontSize: fontSize.small,
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: spacing.normal,
                backgroundColor: color.background.light,
                paddingHorizontal: spacing.normal,
                border: `1px solid ${color.borderColor}`,
                borderBottom: 'none',
            }}
        >
            <View
                style={{
                    flex: 1,
                    height: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: spacing.normal,
                    paddingVertical: spacing.small,
                }}
            >
                <PdfText>
                    <Trans>Name</Trans>
                </PdfText>
            </View>

            {headerCells
                .filter((cell) => tabSizes[cell.tag] !== 0)
                .map((cell) => {
                    return (
                        <View
                            key={cell.tag}
                            style={{
                                width: tabSizes[cell.tag],
                                height: '100%',
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderLeft: `1px solid ${color.borderColor}`,
                            }}
                        >
                            <View style={{ paddingHorizontal: spacing.normal, paddingVertical: spacing.small }}>
                                {cell.content}
                            </View>
                        </View>
                    );
                })}
        </View>
    );
};

export const TasksPdf = (props: TaskPdfProps) => {
    const minPresenceAhead = 50;

    const isTaskItemVisible: Record<TaskItemsToDisplay, boolean> = Object.fromEntries(
        taskItemsToDisplay.map((id) => [id, props.settings.columns.includes(id)])
    );

    const spacing = {
        page: {
            paddingTop: mmToPx(8),
            paddingRight: mmToPx(14),
            paddingBottom: mmToPx(20),
            paddingLeft: mmToPx(14),
        },
        px: 1,
        small: 2,
        normal: 4,
        medium: 6,
        large: 8,
        extraLarge: 12,
        signature: {
            small: 68,
            large: 175,
        },
    } as const;

    const borderRadius = {
        small: 2,
        normal: 3,
        full: '100%',
    } as const;

    const color = {
        primaryText: colors.black,
        secondaryText: colors.gray['500'],
        secondaryDarkText: colors.gray['700'],
        decisionText: colors.green['700'],
        decisionBackground: colors.green['50'],
        noteText: colors.yellow['700'],
        noteBackground: colors.yellow['50'],
        linkText: colors.blue['500'],
        borderColor: colors.gray['200'],
        background: {
            light: colors.gray['100'],
            normal: colors.gray['200'],
            dark: colors.gray['300'],
        },
    };

    const tabSizes = {
        priority: 16,
        assignee: props.settings.density === 'small' ? 60 : props.settings.density === 'medium' ? 65 : 70,
        workspace: !isTaskItemVisible.tags
            ? 0
            : props.settings.density === 'small'
              ? 75
              : props.settings.density === 'medium'
                ? 85
                : 95,
        startDate: !isTaskItemVisible.startDate
            ? 0
            : props.settings.density === 'small'
              ? 50
              : props.settings.density === 'medium'
                ? 58
                : 68,
        dueDate: !isTaskItemVisible.dueDate
            ? 0
            : props.settings.density === 'small'
              ? 50
              : props.settings.density === 'medium'
                ? 58
                : 68,
    };

    const fontSize: Record<keyof typeof fontSizes, number> = Object.fromEntries(
        Object.entries(fontSizes).map(([key, sizes]) => [key, sizes[props.settings.density]])
    );

    const i18n = setupI18n({
        locale: props.settings.locale.code,
        messages: {
            [props.settings.locale.code]: props.settings.locale.messages,
        },
    });

    return (
        <I18nProvider i18n={i18n}>
            <TaskPdfContext.Provider
                value={{
                    spacing,
                    borderRadius,
                    color,
                    minPresenceAhead,
                    fontSize,
                    tabSizes,
                    density: props.settings.density,
                    isTaskItemVisible,
                    locale: props.settings.locale,
                    users: props.users,
                    isTemplate: props.settings.isTemplate,
                }}
            >
                <Document title={props.settings.title}>
                    <Page
                        size="A4"
                        orientation={props.settings.orientation}
                        style={{
                            flexDirection: 'column',
                            fontFamily: registerFont('roboto'),
                            fontSize: fontSize.normal,
                            paddingTop: spacing.page.paddingTop,
                            paddingRight: spacing.page.paddingRight,
                            paddingBottom: spacing.page.paddingBottom + fontSize.small + spacing.large,
                            paddingLeft: spacing.page.paddingLeft,
                        }}
                    >
                        <Logo url={props.settings.logo} />
                        <View
                            style={{
                                fontSize: fontSize.extraLarge,
                                textAlign: 'center',
                                marginBottom: spacing.extraLarge,
                                marginTop: spacing.extraLarge,
                            }}
                        >
                            <Text>{props.settings.title}</Text>
                        </View>

                        {props.groupedTasks
                            .filter((group) => group.tasks.length > 0)
                            .map((group) => {
                                const date =
                                    group.key === 'today' || group.key === 'tomorrow'
                                        ? formatShortDate(
                                              addDays(new Date(), group.key === 'today' ? 0 : 1).toString(),
                                              i18n.locale
                                          )
                                        : '';

                                return (
                                    <View key={group.key}>
                                        <View wrap={false} minPresenceAhead={minPresenceAhead}>
                                            {!props.settings.isGroupTitleHidden && (
                                                <PdfText
                                                    style={{
                                                        fontSize: fontSize.medium,
                                                        fontWeight: 'bold',
                                                        marginBottom: spacing.normal,
                                                    }}
                                                >
                                                    {group.label}
                                                    {date.length > 0 && ` - ${date}`}
                                                </PdfText>
                                            )}
                                            <TaskListHeader />
                                        </View>
                                        <View style={{ marginBottom: spacing.extraLarge }}>
                                            {group.tasks.map((task, index, array) => (
                                                <Task key={task.id} task={task} isLast={index === array.length - 1} />
                                            ))}
                                        </View>
                                    </View>
                                );
                            })}

                        {props.settings.signature != null && props.settings.signature.show && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    paddingTop: spacing.extraLarge * 3,
                                }}
                                wrap={false}
                            >
                                <PdfText style={{ width: '50%' }}>
                                    {props.settings.signature.locationAndDateLabel}
                                </PdfText>
                                <PdfText style={{ width: '50%' }}>{props.settings.signature.signatureLabel}</PdfText>
                            </View>
                        )}

                        <View
                            style={{
                                bottom: spacing.extraLarge,
                                position: 'absolute',
                                left: spacing.page.paddingLeft,
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                            fixed
                        >
                            <PdfText>{props.settings.footer}</PdfText>
                            <PdfText render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                        </View>
                    </Page>
                </Document>
            </TaskPdfContext.Provider>
        </I18nProvider>
    );
};
