import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { tag as taskTag, taskActivitiesTag, taskTagType } from 'Shared/services/task';
import { tag as customFieldGroupValueTag } from 'Shared/services/taskCustomFieldGroupValue';
import { CustomFieldValue } from 'Shared/types/customField';
import { Task } from 'Shared/types/task';
import { baseApi, configureTag, resourceId } from './base';

const { tagType, tag } = configureTag('CustomFieldValue');

export const taskCustomFieldValue = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getTaskCustomFieldValues: build.query<CustomFieldValue[], Id>({
                query: (taskId) => ({ url: `tasks/${taskId}/custom-fields` }),
                transformResponse: (res: Task) => res.customFields,
                providesTags: (result, error, taskId) => [taskTag(taskId)],
            }),
            addTaskCustomFieldValue: build.mutation<
                Partial<CustomFieldValue>,
                { taskId: Id; customFieldId: Id; value: Partial<CustomFieldValue> }
            >({
                query: ({ taskId, customFieldId, value }) => ({
                    url: `tasks/${taskId}/custom-fields/${customFieldId}`,
                    body: value,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [tag(resourceId(taskTagType, taskId)), taskActivitiesTag(taskId)];
                },
            }),
            removeTaskCustomFieldValue: build.mutation<
                Partial<CustomFieldValue>,
                { taskId: Id; customFieldId: Id; valueId: Id; isCustomFieldGroup?: boolean }
            >({
                query: ({ taskId, customFieldId, valueId }) => ({
                    url: `tasks/${taskId}/custom-fields/${customFieldId}/values/${valueId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { taskId, isCustomFieldGroup }) => {
                    invalidateCachedTasks();
                    return [
                        tag(resourceId(taskTagType, taskId)),
                        taskActivitiesTag(taskId),
                        ...(isCustomFieldGroup ? [customFieldGroupValueTag(resourceId(taskTagType, taskId))] : []),
                    ];
                },
            }),
            updateTaskCustomFieldValue: build.mutation<
                Partial<CustomFieldValue>,
                {
                    taskId: Id;
                    customFieldId: Id;
                    valueId: Id;
                    changes: Partial<CustomFieldValue>;
                }
            >({
                query: ({ taskId, customFieldId, valueId, changes }) => ({
                    url: `tasks/${taskId}/custom-fields/${customFieldId}/values/${valueId}`,
                    body: changes,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [tag(resourceId(taskTagType, taskId)), taskActivitiesTag(taskId)];
                },
            }),
        }),
    });

export const {
    useGetTaskCustomFieldValuesQuery,
    useAddTaskCustomFieldValueMutation,
    useRemoveTaskCustomFieldValueMutation,
    useUpdateTaskCustomFieldValueMutation,
} = taskCustomFieldValue;
