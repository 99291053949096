import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Card, Skeleton } from '@wedo/design-system';
import { User } from 'Shared/types/user';

const classes = {
    color: {
        gray: 'bg-gray-300',
        green: 'bg-green-300',
        blue: 'bg-blue-300',
        red: 'bg-red-300',
    },
};

const Stat = ({
    title,
    color,
    value,
}: {
    title: string;
    color: 'red' | 'blue' | 'green' | 'gray';
    value: number;
}): JSX.Element => (
    <div className="flex w-full flex-row justify-between py-1 items-center">
        <div>{title}</div>
        <div
            className={clsx(
                'w-10 rounded-md py-1 text-center font-medium',
                value > 0 ? classes.color[color] : 'bg-gray-200'
            )}
        >
            {value}
        </div>
    </div>
);

export const ColleagueStats = ({ user }: { user: User }): JSX.Element => (
    <Card>
        <Card.Header title={t`All-time stats`} />
        <Card.Body>
            {user ? (
                <div>
                    <Stat title={t`Created tasks`} color="gray" value={user?.stats?.total?.created_tasks ?? 0} />
                    <Stat title={t`Open tasks`} color="blue" value={user?.stats?.total?.open_tasks ?? 0} />
                    <Stat title={t`Completed tasks`} color="green" value={user?.stats?.total?.completed_tasks ?? 0} />
                    <Stat title={t`Deleted tasks`} color="red" value={user?.stats?.total?.deleted_tasks ?? 0} />
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    <Skeleton count={6} className="h-4" />
                </div>
            )}
        </Card.Body>
    </Card>
);
