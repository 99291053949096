import { Editor, Node, NodeEntry, Path, Transforms } from 'slate';
import { not } from '@wedo/utils';
import { Plugin } from '../Editor';
import { isBlockEmpty, reorderBlocks, selectedBlock } from '../utils/block';
import { isEmpty as isTextEmpty, isLine, selectedLine } from './linePlugin';
import { createParagraphBlock, Paragraph } from './paragraphPlugin';

const tryDeleteVoidElement = <T extends Node = Node>(editor: Editor, siblingElement: NodeEntry<T>) => {
    const above = Editor.above(editor, { match: editor.isVoid, voids: true });
    if (above != null) {
        const [aboveNode, abovePath] = above;
        if (abovePath.length > 1) {
            Transforms.removeNodes(editor, { match: editor.isVoid, voids: true });
            Transforms.select(editor, abovePath);
        } else {
            const { id, type, children, order, updatedBy, updated_at, ...props } = aboveNode;
            Transforms.setNodes(
                editor,
                {
                    type: Paragraph,
                    order,
                    updatedBy,
                    updated_at,
                    ...Object.fromEntries(Object.entries(props).map(([key]) => [key, null])),
                },
                { at: abovePath }
            );
            Transforms.select(editor, [...abovePath, 0]);
        }
        return true;
    }

    const voidNode = Editor.above(editor, { match: not(Editor.isEditor), mode: 'highest', voids: true });
    if (voidNode != null && Editor.isVoid(editor, voidNode[0])) {
        return false;
    }

    if (siblingElement == null) {
        return false;
    }
    const [siblingNode, siblingPath] = siblingElement;
    if (editor.isVoid(siblingNode)) {
        const [block, blockPath] = selectedBlock(editor);
        if (isBlockEmpty(block)) {
            // If the selected block is not a paragraph, abort...
            if (block.type !== Paragraph) {
                return false;
            }
            // ...otherwise, if the selected block is empty, delete the block
            Transforms.removeNodes(editor, { mode: 'highest' });
            Transforms.select(editor, Path.isAfter(siblingPath, blockPath) ? blockPath : siblingPath);
            return true;
        }

        const lineNode = selectedLine(editor);
        if (lineNode != null && isTextEmpty(lineNode[0])) {
            // If only the selected text is empty, just delete the text...
            Transforms.removeNodes(editor, { match: isLine });
        } else {
            // ...otherwise, delete nothing and just select the sibling element
            Transforms.select(editor, siblingPath);
        }

        return true;
    }
    return false;
};

export const voidPlugin = (): Plugin => ({
    isSelectable: (editor, element) => !Editor.isVoid(editor, element),
    deleteForward: (editor) => tryDeleteVoidElement(editor, Editor.next(editor)),
    deleteBackward: (editor) => tryDeleteVoidElement(editor, Editor.previous(editor)),
    onKeyDown: (editor, event) => {
        if (event.key === 'Enter') {
            const selected = selectedBlock(editor);
            if (selected != null) {
                const [selectedNode, selectedPath] = selected;
                if (Editor.isVoid(editor, selectedNode)) {
                    event.preventDefault();
                    Transforms.insertNodes(editor, createParagraphBlock(), {
                        at: Path.next(selectedPath),
                        select: true,
                    });
                    reorderBlocks(editor);
                    return true;
                }
            }
        }
        return false;
    },
});
