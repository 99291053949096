import { useNavigate } from 'react-router-dom';
import { useModal } from '@wedo/design-system';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { Notification } from 'Shared/types/notification';

export const useTemplateAction = () => {
    const { open } = useModal();
    const navigate = useNavigate();

    return (notification: Notification) => {
        switch (notification.event_key) {
            case 'commented_checklist_template':
            case 'updated_comment_checklist_template':
            case 'deleted_comment_checklist_template':
                navigate(`/templates/${notification.object_id}/feed`);
                break;
            case 'added_member_to_checklist_template':
            case 'removed_member_from_checklist_template':
            case 'promoted_checklist_template_member':
            case 'revoked_checklist_template_member':
            case 'requested_checklist_template_membership':
            case 'refused_checklist_template_membership':
            case 'changed_checklist_template_privacy':
                open(TemplateSettingsModal, { templateId: notification.object_id, defaultIndex: 1 });
                break;
            default:
                navigate(`/templates/${notification.object_id}/checklists`);
        }
    };
};
