import { t } from '@lingui/macro';
import { create } from 'zustand';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { MeetingRole, MeetingRolePermissions } from 'Shared/types/meetingRole';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingUserItem } from 'Shared/types/meetingUserItem';

interface TrackedChangeObject {
    changed?: boolean;
    added?: boolean;
}
export interface ChangedMeetingUser extends MeetingUser, TrackedChangeObject {}
export interface ChangedMeetingUserItem extends MeetingUserItem, TrackedChangeObject {}
type AttributeValue = string | number | boolean | Id;

export const getCustomRole = (): MeetingRole => ({
    id: 'custom',
    meeting_id: null,
    name: t`Custom`,
    color: '',
    code: 'custom',
    permissions: {} as MeetingRolePermissions,
});

export type State = {
    meetingUsers: ChangedMeetingUser[];
    meetingUserItems: ChangedMeetingUserItem[];
    meetingRoles: MeetingRole[];
    hasChanged: boolean;
};

export type Actions = {
    setMeetingUsers: (meetingUsers: ChangedMeetingUser[]) => void;
    setMeetingUserItems: (meetingUserItems: ChangedMeetingUserItem[]) => void;
    setMeetingRoles: (meetingRoles: MeetingRole[]) => void;
    setHasChanged: (hasChanged: boolean) => void;
    updateMeetingUser: (
        meetingUser: MeetingUser,
        { attribute, value }: { attribute: string; value: AttributeValue }
    ) => void;
    updateMeetingUserItem: (
        meetingUserItem: MeetingUserItem,
        { attribute, value }: { attribute: string; value: AttributeValue }
    ) => void;
    removeMeetingUser: (meetingUser: MeetingUser) => void;
};

export const useMeetingAccessStore = create<State & Actions>()((set) => ({
    meetingUsers: EmptyArray as ChangedMeetingUser[],
    meetingUserItems: EmptyArray as ChangedMeetingUserItem[],
    meetingRoles: EmptyArray as MeetingRole[],
    hasChanged: false,
    setMeetingUsers: (meetingUsers: ChangedMeetingUser[]) => set(() => ({ meetingUsers })),
    setMeetingUserItems: (meetingUserItems: ChangedMeetingUserItem[]) => set(() => ({ meetingUserItems })),
    setMeetingRoles: (meetingRoles: MeetingRole[]) => set(() => ({ meetingRoles })),
    setHasChanged: (hasChanged: boolean) => set(() => ({ hasChanged })),

    updateMeetingUser: (
        meetingUser: MeetingUser,
        change: { attribute: string; value: string | number | boolean | Id }
    ) =>
        set((state) => ({
            hasChanged: true,
            meetingUsers: state.meetingUsers.map((item) => {
                if (item.id === meetingUser.id) {
                    return { ...item, [change.attribute]: change.value, changed: true };
                }
                return item;
            }),
        })),
    updateMeetingUserItem: (
        meetingUserItem: MeetingUserItem,
        change: { attribute: string; value: string | number | boolean | Id }
    ) =>
        set((state) => ({
            hasChanged: true,
            meetingUserItems: state.meetingUserItems.map((item) => {
                if (
                    item.meeting_section_id.toString() === meetingUserItem.meeting_section_id.toString() &&
                    item.meeting_user_id.toString() === meetingUserItem.meeting_user_id.toString()
                ) {
                    return { ...item, [change.attribute]: change.value, changed: true };
                }
                return item;
            }),
        })),
    removeMeetingUser: (meetingUser: MeetingUser) =>
        set((state) => ({
            hasChanged: true,
            meetingUsers: state.meetingUsers.filter((item) => {
                return item.id !== meetingUser.id;
            }),
        })),
}));
