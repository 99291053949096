import { msg } from '@lingui/macro';
import { HttpMethod, Id } from '@wedo/types';
import { ResetParams } from 'Shared/services/auth';
import { baseApi, configureTag } from 'Shared/services/base';
import { CacheTag } from 'Shared/services/cacheTag';
import { currentOrganizationTag } from 'Shared/services/organization';
import { ApiError, TransformParameter } from 'Shared/types/apiError';
import { Network } from 'Shared/types/network';
import { Organization } from 'Shared/types/organization';
import { User } from 'Shared/types/user';

const { tag, tagType } = configureTag(CacheTag.ONBOARDING);

const verify = tag('verify');
const onboardingUserTag = tag('onboardingUser');
const networkTag = tag('network');

type OnboardingUser = {
    firstName: string;
    lastName: string;
    password: string;
    jobTitle: string;
    language: string;
};

const onboardingError = {
    duplicateShortName: {
        code: 'DuplicateError',
        path: 'Duplicated network short name',
        message: msg`This organization URL is already taken. Please choose another one.`,
    },
    invalidCredentials: {
        code: 'ForbiddenError',
        path: 'Invalid credentials.',
        message: msg`The server is unable to authenticate the user.`,
    },
    unauthorized: {
        code: 'ForbiddenError',
        path: 'Resource not authorized',
        message: msg`You do not have the required authorizations.`,
    },
    tokenExpired: {
        code: 'ValidationError',
        path: 'TokenExpired',
        message: msg`The access token has expired. You can try to connect on wedo or ask your administrator for a new invitation.`,
    },
} satisfies TransformParameter;

const endpoints = baseApi.enhanceEndpoints({ addTagTypes: [tagType] }).injectEndpoints({
    endpoints: (build) => ({
        activateUserInNetwork: build.mutation<User, { userId: Id; token: string }>({
            query: ({ userId, token }) => ({
                method: HttpMethod.Put,
                params: { token },
                url: `/api/onboarding/users/${userId}/activate`,
            }),
            transformErrorResponse: (error) => error.transform(onboardingError),
        }),

        verifyToken: build.mutation<boolean, { token: string }>({
            query: ({ token }) => ({
                url: '/api/onboarding/verify_token',
                params: { token },
                body: { token },
                method: HttpMethod.Post,
            }),
            invalidatesTags: [verify],
            transformErrorResponse: (error: ApiError) => error.transform(onboardingError),
        }),

        verifyInviteToken: build.mutation<boolean, { token: string; userId: Id }>({
            query: ({ token, userId }) => ({
                url: `/api/onboarding/${userId}/verify_invite_token`,
                params: { token },
                body: { token },
                method: HttpMethod.Post,
            }),
            invalidatesTags: [verify],
            transformErrorResponse: (error: ApiError) => error.transform(onboardingError),
        }),

        getIsInviteTokenValid: build.query<true | {}, { token: string; userId: Id }>({
            query: ({ token, userId }) => ({
                url: `/api/onboarding/${userId}/verify_invite_token`,
                params: { token },
                body: { token },
                method: HttpMethod.Post,
            }),
        }),

        verifySubdomain: build.mutation<boolean, { subdomain: string; token: string; user?: User }>({
            query: ({ subdomain, token, user }) => ({
                url: '/api/onboarding/verify_subdomain',
                params: { token, user },
                body: { subdomain },
                method: HttpMethod.Post,
            }),
            transformErrorResponse: (error: ApiError) => error.transform(onboardingError),
        }),

        verifyUserSubdomain: build.mutation<boolean, { subdomain: string; token: string; userId: Id }>({
            query: ({ subdomain, token, userId }) => ({
                url: `/api/onboarding/${userId}/verify_subdomain`,
                params: { token },
                body: { subdomain },
                method: HttpMethod.Post,
            }),
            transformErrorResponse: (error: ApiError) => error.transform(onboardingError),
        }),

        getOnboardingUser: build.query<User, { token: string }>({
            query: ({ token }) => ({
                url: '/api/onboarding/user',
                params: { token },
                method: HttpMethod.Get,
            }),
            providesTags: [onboardingUserTag],
        }),

        getInvitedOnboardingUser: build.query<User, { token: string; userId: Id }>({
            query: ({ token, userId }) => ({
                url: `/api/onboarding/${userId}/user`,
                params: { token },
                method: HttpMethod.Get,
            }),
            providesTags: [onboardingUserTag],
        }),

        getIsRestrictedDomain: build.query<{ isRestricted: boolean }, string>({
            query: (domain) => ({
                url: '/api/onboarding/is_restricted_domain',
                params: { domain },
                method: HttpMethod.Get,
            }),
        }),

        createUser: build.mutation<User, { user: OnboardingUser; token: string }>({
            query: ({ user, token }) => ({
                url: '/api/onboarding/user',
                params: { token },
                body: { ...user, token },
                method: HttpMethod.Post,
            }),
            invalidatesTags: [onboardingUserTag],
        }),

        updateOnboardingUser: build.mutation<User, { user: OnboardingUser; token: string; userId: Id }>({
            query: ({ user, token, userId }) => ({
                url: `/api/onboarding/users/${userId}`,
                params: { token },
                body: {
                    token,
                    password: user.password,
                    userData: {
                        id: userId,
                        first_name: user.firstName,
                        last_name: user.lastName,
                        language_code: user.language,
                        title: user.jobTitle,
                    },
                },
                method: HttpMethod.Put,
            }),
            invalidatesTags: [onboardingUserTag],
        }),

        finishOnboarding: build.mutation({
            query: ({ onboarding, token }) => ({
                url: `/api/onboarding/finish`,
                body: onboarding,
                params: { token },
                method: HttpMethod.Post,
            }),
        }),

        finishOnboardingForUser: build.mutation({
            query: ({ onboarding, token, userId }) => ({
                url: `/api/onboarding/${userId}/finish`,
                body: onboarding,
                params: { token },
                method: HttpMethod.Post,
            }),
        }),

        getOnboardingUserNetworks: build.query<Network[], { token: string }>({
            query: ({ token }) => ({
                url: `/api/onboarding/networks`,
                params: { token },
            }),
            providesTags: [verify, networkTag],
            transformErrorResponse: (error: ApiError) => error.transform(onboardingError),
        }),

        onboardingRequestNetworkAccess: build.mutation<void, { networkId: Id; user: { id: Id; token: string } }>({
            query: ({ networkId, user }) => ({
                url: `/api/onboarding/request_network_access`,
                body: {
                    network_id: networkId,
                    user: { id: user.id },
                },
                params: { token: user.token },
                method: HttpMethod.Post,
            }),
            transformErrorResponse: (error: ApiError) => error.transform(onboardingError),
            invalidatesTags: [networkTag],
        }),

        clearOnboardingTrial: build.mutation<Organization, Organization>({
            query: (organization) => ({
                url: `/api/admin/onboarding/organisations/${organization.id}/clear`,
                method: HttpMethod.Put,
                body: organization,
            }),
            invalidatesTags: [networkTag, currentOrganizationTag],
        }),

        resetOnboardingPassword: build.mutation<{ link: string; user: User }, ResetParams>({
            query: ({ validate, token, userId, firstLogin, password, userData, withAuthLink }) => ({
                url: '/api/onboarding/reset_password',
                method: HttpMethod.Post,
                body: {
                    password,
                    reset_token: token,
                    user_id: userId,
                    ...(userData && { userData }),
                    ...(firstLogin && { first_login: firstLogin }),
                },
                params: {
                    ...(validate && { validate }),
                    ...(withAuthLink != null && { withAuthLink }),
                },
            }),
            transformErrorResponse: (error) => {
                return error.transform(onboardingError);
            },
        }),
    }),
});

export const {
    useActivateUserInNetworkMutation,
    useVerifyTokenMutation,
    useVerifyInviteTokenMutation,
    useVerifySubdomainMutation,
    useVerifyUserSubdomainMutation,
    useGetIsInviteTokenValidQuery,
    useGetOnboardingUserQuery,
    useGetInvitedOnboardingUserQuery,
    useGetIsRestrictedDomainQuery,
    useCreateUserMutation,
    useUpdateOnboardingUserMutation,
    useGetOnboardingUserNetworksQuery,
    useOnboardingRequestNetworkAccessMutation,
    useClearOnboardingTrialMutation,
    useFinishOnboardingMutation,
    useFinishOnboardingForUserMutation,
    useResetOnboardingPasswordMutation,
} = endpoints;
