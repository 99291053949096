import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { faRepeatAlt } from '@fortawesome/pro-regular-svg-icons';
import { i18n as i18nCore } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Select, useModal } from '@wedo/design-system';
import { isLastWeekOfMonth } from '@wedo/utils';
import { MeetingFormCustomRecurrenceModal } from 'Shared/components/meeting/addMeetingModal/MeetingFormCustomRecurrenceModal';
import {
    getNthDayText,
    getRRuleWeekOfMonth,
    getWeekDay,
    getWeekDayShort,
    RecurrenceOption,
} from 'Shared/components/meeting/addMeetingModal/MeetingRecurrenceUtils';
import { formRowClasses, formRowIconClasses } from './FormStyles';

type MeetingFormRecurrenceProps = {
    recurrence: string;
    onChange: (title: string) => void;
    startAt: Date;
    isError?: boolean;
    disabled?: boolean;
};

export const weekDaysShort = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const getRecurrenceOptions = (startAt: Date, i18n: typeof i18nCore) => {
    const weekDay = getWeekDay(startAt, i18n);
    const nthDayText = getNthDayText(getRRuleWeekOfMonth(startAt));
    const nthDay = getRRuleWeekOfMonth(startAt);
    const weekDayShort = getWeekDayShort(startAt);

    const monthly: RecurrenceOption = {
        label: (
            <Trans>
                Monthly, on the {nthDayText} {weekDay}
            </Trans>
        ),
        slug: 'monthly-x-weekday',
        value: `FREQ=MONTHLY;WKST=MO;BYDAY=${weekDayShort};BYSETPOS=${nthDay}`,
    };
    const monthlyLast: RecurrenceOption = {
        label: (
            <Trans>
                Monthly, on the {getNthDayText(-1)} {weekDay}
            </Trans>
        ),
        slug: 'monthly-last-weekday',
        value: `FREQ=MONTHLY;WKST=MO;BYDAY=${weekDayShort};BYSETPOS=-1`,
    };
    const quarterly: RecurrenceOption = {
        label: (
            <Trans>
                Each quarter, on the {nthDayText} {weekDay}
            </Trans>
        ),
        slug: 'quarter-x-weekday',
        value: `FREQ=MONTHLY;INTERVAL=3;WKST=MO;BYDAY=${weekDayShort};BYSETPOS=${nthDay}`,
    };
    const quarterlyLast: RecurrenceOption = {
        label: (
            <Trans>
                Each quarter, on the {getNthDayText(-1)} {weekDay}
            </Trans>
        ),
        slug: 'quarter-last-weekday',
        value: `FREQ=MONTHLY;INTERVAL=3;WKST=MO;BYDAY=${weekDayShort};BYSETPOS=-1`,
    };
    const res: RecurrenceOption[] = [];
    res.push({
        label: t`Doesn't repeat or repeats in an irregular way`,
        slug: 'once',
        value: 'once',
    });
    res.push({
        label: <Trans>Weekly, each {weekDay}</Trans>,
        slug: 'weekly',
        value: `FREQ=WEEKLY;WKST=MO;BYDAY=${weekDayShort}`,
    });
    res.push({
        label: (
            <Trans>
                Every {2} weeks, on {weekDay}
            </Trans>
        ),
        slug: 'every-2-weeks',
        value: `FREQ=WEEKLY;INTERVAL=2;WKST=MO;BYDAY=${weekDayShort}`,
    });
    const isLast = isLastWeekOfMonth(startAt);
    const isFourthAndLast = nthDay === 4 && isLastWeekOfMonth(startAt);

    if (isLast) {
        res.push(monthlyLast);
        if (isFourthAndLast) {
            res.push(monthly);
        }
    } else {
        res.push(monthly);
    }

    if (isLast) {
        res.push(quarterlyLast);
        if (isFourthAndLast) {
            res.push(quarterly);
        }
    } else {
        res.push(quarterly);
    }

    res.push({
        label: t`Every weekday (Monday to Friday)`,
        slug: 'every-weekday',
        value: 'FREQ=WEEKLY;INTERVAL=1;WKST=MO;BYDAY=MO,TU,WE,TH,FR',
    });
    return res;
};

export const MeetingFormRecurrence = ({
    recurrence,
    onChange,
    startAt,
    isError,
    disabled,
}: MeetingFormRecurrenceProps): JSX.Element => {
    const { i18n } = useLingui();
    const [yourCustomRecurrence, setYourCustomRecurrence] = useState(null);
    const recurrenceOptions = useMemo(() => getRecurrenceOptions(startAt, i18n), [startAt]);
    const { open: openModal } = useModal();
    /**
     * When the recurrence value is missing from the select options we set that recurrence value as the "Your custom recurrence" value
     * @param recurrence - the recurrence string
     */
    const setCustomRecurrenceIfNotInList = (recurrence: string): void => {
        for (let i = 0; i < recurrenceOptions.length; i++) {
            if (recurrenceOptions[i].value === recurrence) {
                return;
            }
        }
        setYourCustomRecurrence(recurrence);
    };

    // Keeps track of the selected slug value
    const selectedSlug = useMemo(() => {
        for (let i = 0; i < recurrenceOptions.length; i++) {
            if (recurrence === recurrenceOptions[i].value) {
                return recurrenceOptions[i].slug;
            }
        }
        return undefined;
    }, [recurrence]);

    useEffect(() => {
        setCustomRecurrenceIfNotInList(recurrence);
    }, [recurrence != null]);

    // Selects the option that corresponds to the previously selected one when the date changes
    useEffect(() => {
        if (selectedSlug) {
            for (let i = 0; i < recurrenceOptions.length; i++) {
                if (recurrenceOptions[i].slug === selectedSlug) {
                    onChange(recurrenceOptions[i].value);
                    return;
                }
            }
            // if we don't find an option with the same slug value we select the "weekly" option
            onChange(recurrenceOptions[1].value);
        }
    }, [startAt]);

    useEffect(() => {
        if (yourCustomRecurrence != null) {
            onChange(yourCustomRecurrence);
        }
    }, [yourCustomRecurrence]);

    const handleCustomRecurrenceSet = (e) => {
        setYourCustomRecurrence(e);
    };
    const handleChange = (e: string) => {
        if (e === 'custom-recurrence') {
            openModal(MeetingFormCustomRecurrenceModal, {
                onOk: handleCustomRecurrenceSet,
                initialDate: startAt,
                currentRRule: recurrence,
            });
        } else {
            onChange(e);
        }
    };

    return (
        <>
            <div className={formRowClasses}>
                <div className={formRowIconClasses}>
                    <FontAwesomeIcon icon={faRepeatAlt} />
                </div>
                <div className="-ml-1 flex-1 overflow-hidden p-1">
                    <Select
                        disabled={disabled}
                        className={'w-full'}
                        data-testid="select-recurrence"
                        value={recurrence}
                        placeholder={t`Recurrence`}
                        customRenderSelected={(selectedOption: string) => {
                            const recurrenceOption = recurrenceOptions.find((o) => o.value === selectedOption);
                            if (recurrenceOption != null) {
                                return recurrenceOption.label;
                            }
                            return t`Your custom recurrence`;
                        }}
                        onChange={handleChange}
                    >
                        {recurrenceOptions.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                        {yourCustomRecurrence && (
                            <Select.Option key="your-custom" value={yourCustomRecurrence}>
                                <Trans>Your custom recurrence</Trans>
                            </Select.Option>
                        )}
                        <Select.Option key="custom" value="custom-recurrence">
                            <Trans>Custom recurrence</Trans>...
                        </Select.Option>
                    </Select>
                </div>
            </div>
            {isError && (
                <div className={clsx(formRowClasses, '-mt-2.5')}>
                    <div className={formRowIconClasses} />
                    <div className={'flex-1 text-sm text-red-500'}>{t`You must choose a recurrence`}</div>
                </div>
            )}
        </>
    );
};
