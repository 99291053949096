import { Skeleton } from '@wedo/design-system';

export const TopicContentSkeleton = () => (
    <div className={'flex flex-col gap-2 p-2'}>
        <div className={'flex flex-row gap-2'}>
            {/* toolbar skeleton */}
            <Skeleton className={'h-8'} />
            <Skeleton className={'h-8'} />
            <Skeleton className={'h-8'} />
            <Skeleton className={'h-8'} />
            <Skeleton className={'h-8'} />
            <Skeleton className={'h-8'} />
        </div>
        <Skeleton className={'h-16'} />
        <Skeleton className={'h-16'} />
    </div>
);
