import { useLingui } from '@lingui/react';
import { Text, View } from '@react-pdf/renderer';
import React, { ReactNode } from 'react';
import { I18n } from '@lingui/core';
import { colors } from '../colors';
import { Icon } from '../icon/Icon';
import { type CustomField } from '../types';
import { Color, Spacing } from '../types';
import { formatShortDate, getIconFromName } from '../utils';

type CustomFieldRowProps = {
    spacing: Spacing;
    color: Color;
    fontSize: Record<string | number | symbol, number>;
    children: ReactNode;
    label: string;
    icon: string;
    isLast: boolean;
    isGroup?: boolean;
};

type TaskCustomFieldsProps = {
    customFields: Array<CustomField>;
    spacing: Spacing;
    color: Color;
    fontSize: Record<string | number | symbol, number>;
};

const isFirstSetOccurrence = (allCustomFields: CustomField[], index: number, customField: CustomField) => {
    const previousCustomFields = allCustomFields.slice(0, index);
    return !previousCustomFields.find(({ custom_field_id }) => custom_field_id === customField.custom_field_id);
};

const renderCustomFieldSet = (customField: CustomField, index: number, allCustomFields: CustomField[]) => {
    if (!isFirstSetOccurrence(allCustomFields, index, customField)) {
        return '';
    }

    return allCustomFields
        .filter(({ custom_field_id }) => custom_field_id === customField.custom_field_id)
        .map(({ option_label }) => option_label)
        .join(', ');
};

const customFieldRender = (customField: CustomField, i18n: I18n, customFields: CustomField[], index: number) => {
    switch (customField.type) {
        case 'short_text':
            return customField.short_text_value;
        case 'numeric':
            return customField.numeric_value;
        case 'date':
            return formatShortDate(customField.date_value, i18n.locale);
        case 'set':
            return renderCustomFieldSet(customField, index, customFields);
        case 'enum':
            return customField.option_label;
        case 'attachment':
            return customField.attachment_value;
        default:
            return null;
    }
};

const CustomFieldRow = ({ spacing, color, fontSize, children, label, icon, isLast, isGroup }: CustomFieldRowProps) => {
    return (
        <View
            key={label}
            style={{
                flexDirection: 'row',
                gap: spacing.normal,
                borderBottom: !isLast ? `1px solid ${color.borderColor}` : undefined,
                paddingHorizontal: spacing.normal,
                paddingVertical: spacing.small,
            }}
        >
            {icon != null && icon.length > 0 && (
                <Icon
                    icon={getIconFromName(icon)}
                    style={{
                        color: color.secondaryText,
                        width: fontSize.normal,
                        height: fontSize.normal,
                    }}
                />
            )}
            <View
                style={{
                    flexDirection: isGroup ? 'column' : 'row',
                    gap: spacing.normal,
                    flex: 1,
                }}
            >
                <Text style={{ fontWeight: 'bold' }}>{label}</Text>
                <View style={{ flex: isGroup ? undefined : 1 }}>{children}</View>
            </View>
        </View>
    );
};

export const TaskCustomFields = ({ customFields, spacing, color, fontSize }: TaskCustomFieldsProps) => {
    const { i18n } = useLingui();

    const simpleCustomFields = customFields
        .filter((customField) => customField.group_id == null)
        .sort((customFieldA, customFieldB) => customFieldA.order - customFieldB.order);

    const customFieldsGroups = Object.values(
        customFields.reduce((groups, customField) => {
            if (customField.group_id != null) {
                if (groups[customField.group_id] == null) {
                    groups[customField.group_id] = [customField];
                } else {
                    groups[customField.group_id].push(customField);
                }
            }
            return groups;
        }, {})
    ).sort(([customFieldA], [customFieldB]) => customFieldA.group_order - customFieldB.group_order);

    return (
        <>
            {simpleCustomFields.map(
                (customField, index, array) =>
                    (customField.type !== 'set' || isFirstSetOccurrence(array, index, customField)) && (
                        <CustomFieldRow
                            key={index}
                            spacing={spacing}
                            color={color}
                            fontSize={fontSize}
                            label={customField.label}
                            icon={customField.icon}
                            isLast={customFieldsGroups.length === 0 && index === simpleCustomFields.length - 1}
                        >
                            <Text style={{ color: colors.black }}>
                                {customFieldRender(customField, i18n, array, index)}
                            </Text>
                        </CustomFieldRow>
                    )
            )}
            {customFieldsGroups.map((customFieldGroup, index) => {
                const groupFields = customFieldGroup
                    .sort((customFieldA, customFieldB) => customFieldA.order - customFieldB.order)
                    .map((customField, index, array) => [
                        customField.label,
                        customFieldRender(customField, i18n, array, index),
                    ])
                    .filter(([, value]) => value != null);
                return (
                    <CustomFieldRow
                        key={customFieldGroup[0].group_label}
                        spacing={spacing}
                        color={color}
                        fontSize={fontSize}
                        label={customFieldGroup[0].group_label}
                        icon={customFieldGroup[0].group_icon}
                        isLast={index === customFieldsGroups.length - 1}
                        isGroup={true}
                    >
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                            {groupFields.map(([label, value]) => (
                                <View style={{ flexDirection: 'row', width: '50%' }}>
                                    <Text>
                                        <Text>{label} </Text>
                                        <Text style={{ color: colors.black }}>{value}</Text>
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </CustomFieldRow>
                );
            })}
        </>
    );
};
