import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Modal, Steps } from '@wedo/design-system';
import { EmptyFunction } from '@wedo/utils';

type AddUserLayoutProps = {
    children: React.ReactNode;
    step: number;
    onClose?: () => void;
};

export const AddUserBody: FC<AddUserLayoutProps> = ({ children, step }) => (
    <>
        <Steps className="!rounded-none">
            <Steps.Step index={1} currentIndex={step}>
                <Trans>User</Trans>
            </Steps.Step>
            <Steps.Step index={2} currentIndex={step}>
                <Trans>Team</Trans>
            </Steps.Step>
            <Steps.Step index={3} currentIndex={step} last>
                <Trans>Invite user</Trans>
            </Steps.Step>
        </Steps>
        <Modal.Body>{children}</Modal.Body>
    </>
);

export const AddUserFooter: FC<AddUserLayoutProps> = ({ children, step, onClose = EmptyFunction }) => (
    <Modal.Footer>
        <Button onClick={onClose}>{step < 3 ? <Trans>Close</Trans> : <Trans>No, not now</Trans>}</Button>
        {children}
    </Modal.Footer>
);
