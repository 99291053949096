import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@wedo/design-system';
import { ColleagueActivityHeatmap } from 'Pages/UserPage/components/ColleagueActivityHeatmap';
import { ColleagueStats } from 'Pages/UserPage/components/ColleagueStats';
import { ColleagueTaskDistribution } from 'Pages/UserPage/components/ColleagueTaskDistribution';
import { ColleagueTaskHistory } from 'Pages/UserPage/components/ColleagueTaskHistory';
import { ColleagueTaskPriorities } from 'Pages/UserPage/components/ColleagueTaskPriorities';
import { ColleagueWorkspaces } from 'Pages/UserPage/components/ColleagueWorkspaces';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { UserCard } from 'Shared/components/user/UserCard';
import { useGetUserQuery } from 'Shared/services/user';

export const ColleagueProfilePage = (): JSX.Element => {
    const { userId } = useParams();

    const { data: user, error, isLoading, refetch } = useGetUserQuery(userId, { skip: !userId });

    if (isLoading) {
        return (
            <div className="flex h-full items-center justify-center">
                <Spinner size="lg" />
            </div>
        );
    }

    return error != null ? (
        <div className="mt-6 flex w-full justify-center">
            <RetryComponent retryFunction={refetch} />
        </div>
    ) : (
        <div className="scrollbar-light mb-4 flex w-full flex-col gap-4 overflow-y-auto p-4 xl:flex-row">
            <div className="flex flex-col gap-4 xl:w-1/4">
                <UserCard user={user} userAvatarSize="2xl" hideMenu />
                <ColleagueStats user={user} />
                <ColleagueWorkspaces user={user} />
            </div>
            <div className="grid w-full auto-rows-min grid-cols-6 gap-4 xl:w-3/4">
                <ColleagueTaskPriorities />
                <ColleagueTaskDistribution />
                <ColleagueTaskHistory />
                <ColleagueActivityHeatmap />
            </div>
        </div>
    );
};
