import { type Query, type QueryClient } from '@tanstack/react-query';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { create } from 'zustand';

type InvalidationStore = {
    tags: Array<string>;
    previousTags: Array<string>;
};

const useInvalidationStore = create<InvalidationStore>()(() => ({ tags: [], previousTags: [] }));

const tagsMatch = (tags: Array<string>) => (expectedTags: Array<string | RegExp>) => {
    return expectedTags?.some((expectedTag) =>
        expectedTag instanceof RegExp ? tags.some((tag) => expectedTag.test(tag)) : tags.includes(expectedTag)
    );
};

const queryHasTags = (tags: Array<string>) => (query: Query) => {
    return tagsMatch(tags)(query.meta?.tags as Array<string | RegExp>);
};

export const invalidateQueries = async (client: QueryClient, tags: Array<string>) => {
    useInvalidationStore.setState({ tags, previousTags: [] });
    try {
        return await client.invalidateQueries({ predicate: queryHasTags(tags) });
    } finally {
        useInvalidationStore.setState({ tags: [], previousTags: tags });
    }
};

export const whenQueryIsInvalidated = (query: Query) => queryHasTags(useInvalidationStore.getState().tags)(query);

export const useInvalidationEvent = (f: (tags: Array<string>) => void, ...tags: Array<string | RegExp>) => {
    const fRef = useRef(f);

    const previousTags = useInvalidationStore(({ previousTags }) => previousTags);

    useLayoutEffect(() => {
        fRef.current = f;
    });

    useEffect(() => {
        if (tagsMatch(previousTags)(tags)) {
            f(previousTags);
        }
    }, [previousTags]);
};
