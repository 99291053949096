import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@lingui/macro';
import { useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import {
    useAddChecklistTasksMutation,
    useCreateChecklistSectionMutation,
    useGetChecklistQuery,
} from 'Shared/services/checklist';
import { invalidateTasks, useAddTasksMutation } from 'Shared/services/task';
import {
    invalidateTemplate,
    useAddChecklistTemplateTasksMutation,
    useGetChecklistTemplateQuery,
} from 'Shared/services/template';
import { useAddSectionMutation, useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Section } from 'Shared/types/section';
import { Task, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

type UseAddTaskInputProps = {
    templateId: Id;
    checklistId: Id;
    workspaceId: Id;
    assigneeId: Id;
    isWatcher?: boolean;
};

type NewTaskInfo = { name: string; sectionId?: Id; sectionOrder?: number; type?: TaskType };

export const useAddTaskInput = ({
    assigneeId,
    workspaceId,
    templateId,
    checklistId,
    isWatcher = false,
}: UseAddTaskInputProps) => {
    const dispatch = useDispatch();

    const [, setSearchParams] = useSearchParams(TasksPageSearchParams);
    const { show } = useNotification();
    const { currentUser } = useCurrentUserContext();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });
    const { data: checklist } = useGetChecklistQuery(checklistId, { skip: !checklistId });
    const { maxOrder, sections } = useTaskSections({ workspaceId, templateId, checklistId });
    const { setSelectedTasks, setRecentlyCreatedTaskId } = useTasksContext();

    const [addCommonTasks, { isLoading: isAddingCommonTasks }] = useAddTasksMutation();
    const [addChecklistTasks, { isLoading: isAddingChecklistTasks }] = useAddChecklistTasksMutation();
    const [addChecklistTemplateTasks, { isLoading: isAddingChecklistTemplateTasks }] =
        useAddChecklistTemplateTasksMutation();
    const [addWorkspaceSection, { isLoading: isAddWorkspaceSectionLoading }] = useAddSectionMutation();
    const [addChecklistSection, { isLoading: isAddChecklistSectionLoading }] = useCreateChecklistSectionMutation();

    const buildTaskObject = useCallback(
        (name: string, sectionId: Id, sectionOrder: number, { type } = { type: TaskType.Task }): Partial<Task> => ({
            name,
            description: '',
            checklist_id: checklistId,
            checklist_template_id: templateId,
            is_new: checklistId == null && templateId == null,
            planned_date: new Date().toISOString(),
            assignee:
                assigneeId != null
                    ? ({ id: assigneeId } as User)
                    : workspaceId == null && templateId == null && checklistId == null
                      ? ({ id: currentUser.id } as User)
                      : null,
            watchers: Array.from(
                new Set(
                    [isWatcher ? currentUser.id : null, assigneeId !== currentUser.id ? assigneeId : null].filter(
                        Boolean
                    )
                )
            ).map((id) => ({ id })),
            tags:
                workspaceId != null
                    ? [
                          {
                              id: workspaceId,
                              tag_section_id: sectionId,
                              order: sectionOrder,
                          } as unknown as Workspace,
                      ]
                    : [],
            checklist_section_id: checklistId || templateId ? sectionId : null,
            type,
        }),
        [assigneeId, isWatcher, checklistId, templateId, workspaceId]
    );

    const add = async (tasks: NewTaskInfo[]) => {
        const computedTasks = tasks.map(({ name, sectionId, sectionOrder, type }) =>
            buildTaskObject(name, sectionId, sectionOrder, { type })
        );

        if (templateId != null) {
            return addChecklistTemplateTasks({ tasks: computedTasks, templateId });
        }
        if (checklistId != null) {
            return addChecklistTasks({ tasks: computedTasks, checklistId });
        }
        return addCommonTasks({ tasks: computedTasks });
    };

    const addTasks = async (tasks: NewTaskInfo[], openPanel?: boolean) => {
        const result = await add(tasks);
        if ('data' in result) {
            invalidateCachedTasks();
            dispatch(invalidateTasks(result.data.map(({ id }) => ({ id }))));
            if (templateId) {
                dispatch(invalidateTemplate(templateId));
            }

            if (openPanel && result.data.length === 1) {
                // We set the groupedId to null so when the task will render it can set its real groupedId, as it would be a
                // lot of work to compute it here
                setSelectedTasks([{ ...result.data[0], groupedId: null }]);
                setRecentlyCreatedTaskId(result.data[0].id);
            }
        }

        return result;
    };

    const addSection = async (task: string, order = maxOrder + 1): Promise<Section> => {
        const sectionKeyIndex = task.indexOf(':');
        const sectionName = task.substring(sectionKeyIndex + 1).trim();
        if (sections.some((section) => section.name.toLowerCase().trim() === sectionName.toLowerCase().trim())) {
            show({
                type: 'danger',
                title: t`Can't create section`,
                message: t`Section with name ${sectionName} already exists, please use a different name`,
            });
            return undefined;
        }
        let response;
        if (workspace) {
            response = await addWorkspaceSection({
                workspaceId,
                name: sectionName,
                order,
            });
            setSearchParams((current) => ({ ...current, grouping: 'section' }));
        } else if (templateId || checklistId) {
            response = await addChecklistSection({
                checklistId: templateId ? template?.checklist_id : checklistId,
                name: sectionName,
                order,
            });
        }
        if ('error' in response) {
            show({
                type: 'danger',
                title: t`Unexpected error`,
                message: workspaceId
                    ? t`Failed to add section ${sectionName} in ${workspace?.name} workspace`
                    : templateId
                      ? t`Failed to add section ${sectionName} in ${template?.name} template`
                      : t`Failed to add section ${sectionName} in ${checklist?.name} checklist`,
            });
            return undefined;
        }
        return response.data;
    };

    const isAdding =
        isAddingCommonTasks ||
        isAddingChecklistTasks ||
        isAddingChecklistTemplateTasks ||
        isAddWorkspaceSectionLoading ||
        isAddChecklistSectionLoading;

    return { addTasks, addSection, isAdding };
};
