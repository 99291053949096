import { HttpMethod } from '@wedo/types';
import { WorkspaceStatus } from 'Shared/types/workspaceStatus';
import { tag as activityFeedTag, tagList as activityFeedTagList } from './activityFeed';
import { baseApi, configureTag, listId } from './base';
import { currentNetworkWorkspacesTag, workspaceTag } from './workspace';

const { tagType, tag } = configureTag('WorkspaceStatus');

export const workspaceStatusApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addWorkspaceStatus: build.mutation<WorkspaceStatus, { status: Partial<WorkspaceStatus> }>({
                query: ({ status }) => ({
                    url: `tags/${status.tag_id}/status`,
                    body: status,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: () => [tag(listId), currentNetworkWorkspacesTag],
            }),
            updateWorkspaceStatus: build.mutation<WorkspaceStatus, { status: Partial<WorkspaceStatus> }>({
                query: ({ status }) => ({
                    url: `tags/${status.tag_id}/status/${status.id}`,
                    body: status,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: () => [tag(listId)],
            }),
            deleteWorkspaceStatus: build.mutation<WorkspaceStatus, { status: Partial<WorkspaceStatus> }>({
                query: ({ status }) => ({
                    url: `tags/${status.tag_id}/status/${status.id}`,
                    body: status,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { status: { tag_id } }) => [
                    tag(listId),
                    workspaceTag(tag_id),
                    activityFeedTag(activityFeedTagList),
                ],
            }),
        }),
    });

export const { useAddWorkspaceStatusMutation, useUpdateWorkspaceStatusMutation, useDeleteWorkspaceStatusMutation } =
    workspaceStatusApi;
