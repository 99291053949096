import { v4 as uuidv4 } from 'uuid';
import { Id } from '@wedo/types';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export enum ParseModes {
    SectionsTopics = 'SectionsTopics',
    OnlySections = 'OnlySections',
    OnlyTopics = 'OnlyTopics',
}

export const getNextOrder = (list: MeetingTopic[] | MeetingSection[]) =>
    list.length > 0 ? list[list.length - 1].order + 1 : 0;

export const convertToSectionsTopics = (
    items: any[],
    prefix: string,
    startIndex: number,
    startingOrder: number,
    level: number,
    parentId: Id,
    meetingId: Id,
    parseModes: ParseModes,
    repeatEvery: boolean
): (Partial<MeetingSection> | Partial<MeetingTopic>)[] => {
    let order = startingOrder;
    return items.map((item, index) => {
        const displayId = startIndex + index + 1;
        const isSection = parseModes == ParseModes.OnlySections || item.childItems.length > 0;
        const id = uuidv4();
        const newSectionTopic: Partial<MeetingSection> | Partial<MeetingTopic> = {
            id: id,
            isSection: isSection,
            title: item.title,
            meeting_id: meetingId,
            order: order,
            level: isSection ? level : 0,
            parent_section_id: isSection ? parentId : undefined,
            meeting_section_id: !isSection ? parentId : undefined,
            display_id: prefix + displayId + '',
            childItems: convertToSectionsTopics(
                item.childItems,
                prefix + displayId + '.',
                0,
                order + 1,
                level + 1,
                id,
                meetingId,
                parseModes,
                repeatEvery
            ),
            originTitle: item.originTitle,
            firstBlock: item.firstBlock,
            duration: item.duration ?? 0,
            presenters: item.presenters || [],
            repeatEvery: 'repeatEvery' in item ? item.repeatEvery : repeatEvery ? 1 : 0,
        };
        order += item.childItems.length + 1;
        return newSectionTopic;
    });
};

export interface ClipboardItem {
    id: string;
    isSection: boolean;
    title: string;
    order: number;
    level: number;
    display_id: string;
    childItems: ClipboardItem[];
    parent_section_id?: Id;
    meeting_section_id?: Id;
    firstBlock?: string;
    originTitle?: string;
    repeatEvery: number;
    duration?: number;
}
