import React, { FC, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, EmptyState, Input, ItemGroup, Label } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { Section } from 'Shared/types/section';
import { closestY } from 'Shared/utils/dnd';
import { DraggableSectionCard } from './DraggableSectionCard';

type SectionsPanelProps = {
    sections: Array<Section>;
    className?: string;
    onDragEnd: (e: DragEndEvent) => void;
    onAddSection: (name: string) => Promise<boolean>;
    onDelete: (section: Section) => Promise<boolean>;
    onUpdate: (section: Partial<Section>) => Promise<boolean>;
    canChangeColor?: boolean;
};

export const SectionsPanel: FC<SectionsPanelProps> = ({
    className,
    onDragEnd,
    onAddSection,
    onDelete,
    onUpdate,
    sections,
    canChangeColor = false,
}) => {
    const { sensors, measuring } = useDndSortableVerticalStrategy();

    const [section, setSection, handleSection] = useInputState(EmptyString);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const allSectionNames = new Set(sections?.map((section) => section.name.toLowerCase()));
    const sectionIds = sections?.map(({ id }) => id);
    const currentNameIsAlreadyTaken = allSectionNames.has(section.toLowerCase().trim());
    const addSectionButtonIsDisabled = isEmpty(section.trim()) || currentNameIsAlreadyTaken;

    const handleInputAddSection = async () => {
        setIsLoading(true);
        const success = await onAddSection(section);
        if (success) {
            setSection(EmptyString);
        }
        setIsLoading(false);
    };

    return (
        <div className={className}>
            <Label>
                <Trans>Create sections to group tasks</Trans>
            </Label>

            <ItemGroup
                status={currentNameIsAlreadyTaken ? 'error' : 'default'}
                statusText={
                    currentNameIsAlreadyTaken && t`This name has already been taken, please use another section name`
                }
            >
                <Input
                    value={section}
                    onChange={handleSection}
                    placeholder={t`Section name`}
                    onPressEnter={!addSectionButtonIsDisabled && handleInputAddSection}
                    className="w-full"
                />
                <Button
                    icon={faPlus}
                    color="primary"
                    onClick={handleInputAddSection}
                    loading={isLoading}
                    disabled={addSectionButtonIsDisabled}
                />
            </ItemGroup>

            {isEmpty(sections) && (
                <EmptyState className="mt-6" icon={faBars}>
                    <EmptyState.Text>
                        <Trans>No sections</Trans>
                    </EmptyState.Text>
                </EmptyState>
            )}

            <DndContext
                sensors={sensors}
                collisionDetection={closestY}
                measuring={measuring}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext items={sectionIds} strategy={verticalListSortingStrategy}>
                    <div className="w-1/1 mt-4 grid grid-cols-1 gap-2">
                        {sections?.map((section) => (
                            <DraggableSectionCard
                                key={section.id}
                                section={section}
                                sections={sections}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                                canChangeColor={canChangeColor}
                            />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
        </div>
    );
};
