import { ReactNode } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Dropdown, Tooltip } from '@wedo/design-system';
import { useLoader } from '@wedo/utils/hooks';

type MemberAccessRightDropdownProps = {
    isModerator: boolean;
    entityType: 'team' | 'workspace' | 'template';
    onIsModeratorChanged: (moderator: boolean) => Promise<boolean>;
    isSelectModeratorDisabled?: boolean;
    isSelectReadWriteDisabled?: boolean;
    moderatorOptionTooltip?: ReactNode;
    readWriteOptionTooltip?: ReactNode;
};

export const MemberAccessRightDropdown = ({
    isModerator,
    entityType,
    onIsModeratorChanged,
    isSelectModeratorDisabled = false,
    isSelectReadWriteDisabled = false,
    moderatorOptionTooltip,
    readWriteOptionTooltip,
}: MemberAccessRightDropdownProps) => {
    const { isLoading, wrap } = useLoader();

    const isDropdownDisabled = (isSelectModeratorDisabled && isSelectReadWriteDisabled) || isLoading;

    const handleIsModeratorChanged = async (newIsModerator: boolean) => {
        if (newIsModerator !== isModerator) {
            await wrap(onIsModeratorChanged(newIsModerator));
        }
    };

    return (
        <Tooltip content={isDropdownDisabled && moderatorOptionTooltip}>
            <div>
                <Dropdown
                    size="sm"
                    className={clsx('min-w-[120px] !font-bold', isModerator ? '!text-blue-600' : '!text-green-600')}
                    label={isModerator ? t`Moderator` : t`Member`}
                    icon={faChevronDown}
                    disabled={isDropdownDisabled}
                    isLoading={isLoading}
                >
                    <Tooltip content={isSelectModeratorDisabled && moderatorOptionTooltip}>
                        <Dropdown.Item
                            selected={isModerator}
                            onClick={() => handleIsModeratorChanged(true)}
                            disabled={isSelectModeratorDisabled}
                        >
                            <div className="flex flex-col gap-0 max-w-[24rem]">
                                <span className="font-bold text-blue-600">
                                    <Trans>Moderator</Trans>
                                </span>
                                {entityType === 'team' && (
                                    <span className="text-xs font-light text-gray-600">
                                        <Trans>
                                            Can add/remove members in workspaces and templates part of this team, can
                                            change access rights for other members in this team
                                        </Trans>
                                    </span>
                                )}
                                {entityType === 'workspace' && (
                                    <span className="text-xs font-light text-gray-600">
                                        <Trans>
                                            Can add/remove workspace members, can change access rights for other members
                                        </Trans>
                                    </span>
                                )}
                                {entityType === 'template' && (
                                    <span className="text-xs font-light text-gray-600">
                                        <Trans>
                                            Can add/remove template members, can change access rights for other members
                                        </Trans>
                                    </span>
                                )}
                            </div>
                        </Dropdown.Item>
                    </Tooltip>

                    <Tooltip content={isSelectReadWriteDisabled && readWriteOptionTooltip}>
                        <Dropdown.Item
                            loading={isLoading}
                            selected={!isModerator}
                            onClick={() => handleIsModeratorChanged(false)}
                            disabled={isSelectReadWriteDisabled || isLoading}
                        >
                            <div className="flex flex-col gap-0 max-w-[24rem]">
                                <span className="font-bold text-green-600">
                                    <Trans>Member</Trans>
                                </span>
                                {entityType === 'team' && (
                                    <span className="text-xs font-light text-gray-600">
                                        <Trans>
                                            Can view team members, cannot add/remove members or change access rights for
                                            other members
                                        </Trans>
                                    </span>
                                )}
                                {entityType === 'workspace' && (
                                    <span className="text-xs font-light text-gray-600">
                                        <Trans>
                                            Can view workspace members, cannot add/remove members or change access
                                            rights for other members
                                        </Trans>
                                    </span>
                                )}
                                {entityType === 'template' && (
                                    <span className="text-xs font-light text-gray-600">
                                        <Trans>
                                            Can view template members, cannot add/remove members or change access rights
                                            for other members
                                        </Trans>
                                    </span>
                                )}
                            </div>
                        </Dropdown.Item>
                    </Tooltip>
                </Dropdown>
            </div>
        </Tooltip>
    );
};
