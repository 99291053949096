import { t } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { HttpMethod, Id } from '@wedo/types';
import { boolean, storage, string } from '@wedo/utils';
import { CacheTag } from 'Shared/services/cacheTag';
import { CURRENT_USER_TAG, userTag } from 'Shared/services/user';
import { LocalStorage } from 'Shared/types/localStorage';
import { Network } from 'Shared/types/network';
import { RtkQueryResponseObject } from 'Shared/types/rtkQuery';
import { User } from 'Shared/types/user';
import { FETCH_ERROR_RESPONSE_MESSAGE, fetchErrorOccurred } from 'Shared/utils/rtkQuery';
import { baseApi, configureTag } from './base';

const { tagType: authTagType } = configureTag(CacheTag.AUTH);

export const RequireAuthSearchParams = {
    token: string(),
    userId: string(),
    user_id: string(),
    rememberMe: boolean(),
    trustedDevice: boolean(),
    redirect: string(),
    error: string(),
    authenticationMethod: string(),
    logout: boolean(),
    login_saml: string(),
};

type LoginPayload = {
    email: string;
    password: string;
    old_auth_token?: string;
    rememberMe: boolean;
    trustedDevice: boolean;
};

type LoginTotpPayload = {
    email: string;
    password: string;
    rememberMe: boolean;
    trustedDevice: boolean;
    code: string;
};

type TokenLoginPayload = {
    token: string;
    user_id: string;
    rememberMe?: boolean;
    trustedDevice?: boolean;
    code?: string;
};

type TokenLoginTotpPayload = {
    token: string;
    user_id: string;
    code: string;
};

type GlobalTokenLoginPayload = {
    token: string;
    user_id: string;
};

export type ResetParams = {
    validate?: boolean;
    token: string;
    userId: Id;
    firstLogin?: boolean;
    password?: string;
    userData?: Partial<User>;
    withAuthLink?: boolean;
};

export type LoginResponse = {
    authToken?: string;
    global_login?: boolean;
    two_factor_authentication?: boolean;
    user: User;
};

const verifyUserPasswordError = () => ({
    incorrectPassword: {
        error: { message: 'Password wrong', type: 'NotAcceptableError' },
        alert: { title: t`Invalid password`, message: t`Invalid password` },
    },
});

const authTransformError = () => ({
    invalidEmail: { code: 'ValidationError', path: 'Invalid email', message: t`Invalid email address` },
    tokenExpired: {
        code: 'ValidationError',
        path: 'TokenExpired',
        message: t`Your link has expired. Please reset your password again.`,
    },
    tokenInvalid: {
        code: 'NotFoundError',
        path: 'Invalid token.',
        message: t`The token is invalid. Please reset your password again.`,
    },
    handledBySso: {
        code: 'BadRequestError',
        path: 'Network is handle by SSO',
        message: t`This account is handled by Single Sign-On. Please contact your IT department for help.`,
    },
});

type LogoutPayload = {
    authToken: string;
};

export const errorTransform = () => ({
    passwordForbidden: {
        code: 'ForbiddenError',
        path: 'Invalid credentials.',
        message: t`Email or password incorrect`,
    },
    passwordInvalid: { code: 'NotFoundError', path: 'Invalid credentials.', message: t`Email or password incorrect` },
    twoFactorNeeded: {
        code: 'NotAcceptableError',
        path: '2fa',
    },
});

const totpErrorTransform = () => ({
    totpForbidden: { code: 'ForbiddenError', path: 'Invalid credentials.', message: t`Code incorrect` },
    totpInvalid: { code: 'NotFoundError', path: 'Invalid credentials.', message: t`Code incorrect` },
});

const totpSmsRecoveryErrorTransform = () => ({
    phoneNumber: {
        path: 'Two factor not found',
        code: 'NotFoundError',
        message: t`The phone number is not associated with a two-factor authentication`,
    },
    smsCode: {
        path: 'Code wrong',
        code: 'NotFoundError',
        message: t`The code is invalid`,
    },
});

const totpBackupRecoveryErrorTransform = () => ({
    invalidCode: {
        path: 'Two factor not found',
        code: 'NotFoundError',
        message: t`The code is incorrect or already used`,
    },
    unknownCode: {
        path: 'No backup code specified',
        code: 'NotFoundError',
        message: t`The code is incorrect or already used`,
    },
});

export const authApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [authTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            checkSso: build.query<Network, string>({
                query: (shortName) => ({
                    url: '/api/networks/check_sso',
                    params: { short_name: shortName },
                }),
            }),
            login: build.mutation<LoginResponse, LoginPayload>({
                query: (payload) => ({
                    url: '/api/login',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                transformErrorResponse: (error) => error.transform(errorTransform()),
                invalidatesTags: (result) => {
                    if (result?.authToken && result.two_factor_authentication !== true) {
                        storage.setItem(LocalStorage.AuthToken, result.authToken);
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            loginTotp: build.mutation<LoginResponse, LoginTotpPayload>({
                query: (payload) => ({
                    url: '/api/login-totp',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                transformErrorResponse: (error) => error.transform(totpErrorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(LocalStorage.AuthToken, result.authToken);
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            globalLoginTotp: build.mutation<LoginResponse, LoginTotpPayload>({
                query: (payload) => ({
                    url: '/api/global-login-totp',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                transformErrorResponse: (error) => error.transform(totpErrorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(
                            LocalStorage.GlobalAuthToken,
                            JSON.stringify({ user_id: result.user.id, token: result.authToken })
                        );
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            globalLogin: build.mutation<LoginResponse, LoginPayload>({
                query: (payload) => ({
                    url: '/api/global-login',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                transformErrorResponse: (error) => error.transform(errorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(
                            LocalStorage.GlobalAuthToken,
                            JSON.stringify({ user_id: result.user.id, token: result.authToken })
                        );
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            totpRecoveryBackupCode: build.mutation<
                LoginResponse,
                { code: string; email: string; password: string; userId: Id }
            >({
                query: ({ code, email, password, userId }) => ({
                    url: '/api/two_factor_recovery_backup_code',
                    method: HttpMethod.Post,
                    body: {
                        code,
                        email,
                        password,
                        user_id: userId,
                    },
                }),
                transformErrorResponse: (error) => error.transform(totpBackupRecoveryErrorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(LocalStorage.AuthToken, result.authToken);
                    }
                    return [CURRENT_USER_TAG];
                },
            }),
            totpRecoverySms: build.mutation<{ message: string }, { phone: string; userId: Id }>({
                query: ({ phone, userId }) => ({
                    url: '/api/two_factor_recovery_sms',
                    method: HttpMethod.Post,
                    body: {
                        phone_number: phone,
                        user_id: userId,
                    },
                }),
                transformErrorResponse: (error) => error.transform(totpSmsRecoveryErrorTransform()),
            }),
            totpRecoverySmsConfirmation: build.mutation<
                LoginResponse,
                { phone: string; userId: Id; code: string; email: string; password: string }
            >({
                query: ({ phone, userId, code, email, password }) => ({
                    url: '/api/two_factor_recovery_sms_confirmation',
                    method: HttpMethod.Post,
                    body: {
                        phone_number: phone,
                        user_id: userId,
                        code,
                        email,
                        password,
                    },
                }),
                transformErrorResponse: (error) => error.transform(totpSmsRecoveryErrorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(LocalStorage.AuthToken, result.authToken);
                    }
                    return [CURRENT_USER_TAG];
                },
            }),
            tokenLogin: build.mutation<LoginResponse, TokenLoginPayload>({
                query: (payload) => ({
                    url: '/api/token-login',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(LocalStorage.AuthToken, result.authToken);
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            globalTokenLogin: build.mutation<LoginResponse, GlobalTokenLoginPayload>({
                query: (payload) => ({
                    url: '/api/global-token-login',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(
                            LocalStorage.GlobalAuthToken,
                            JSON.stringify({ user_id: result.user.id, token: result.authToken })
                        );
                    }
                    return [userTag(result?.user?.id), CURRENT_USER_TAG];
                },
            }),
            globalTokenLoginTotp: build.mutation<LoginResponse, TokenLoginTotpPayload>({
                query: (payload) => ({
                    url: '/api/global-token-login-totp',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                transformErrorResponse: (error) => error.transform(totpErrorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(LocalStorage.GlobalAuthToken, result.authToken);
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            tokenLoginTotp: build.mutation<LoginResponse, TokenLoginTotpPayload>({
                query: (payload) => ({
                    url: '/api/token-login-totp',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                transformErrorResponse: (error) => error.transform(totpErrorTransform()),
                invalidatesTags: (result) => {
                    if (result) {
                        storage.setItem(LocalStorage.AuthToken, result.authToken);
                    }
                    return [userTag(result?.user.id), CURRENT_USER_TAG];
                },
            }),
            logout: build.mutation<void, LogoutPayload>({
                query: (payload) => ({
                    url: '/api/logout',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: () => {
                    return [CURRENT_USER_TAG];
                },
            }),
            verifyUserPassword: build.mutation<void, string>({
                query: (password: string) => ({
                    url: '/api/users/2fa/verify-password',
                    body: { password },
                    method: HttpMethod.Post,
                }),
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as unknown as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    if ((response as unknown as RtkQueryResponseObject).data.errors) {
                        const resp = response as unknown as RtkQueryResponseObject;
                        if (isEqual(resp.data.errors[0], verifyUserPasswordError().incorrectPassword.error)) {
                            return verifyUserPasswordError().incorrectPassword.alert;
                        }
                    }
                    return response;
                },
            }),
            forgottenPassword: build.mutation<void, string>({
                query: (email) => ({
                    url: '/api/forgot',
                    method: HttpMethod.Post,
                    body: { email },
                }),
                transformErrorResponse: (error) => {
                    return error.transform(authTransformError());
                },
            }),
            sendMagicLink: build.mutation<void, string>({
                query: (email) => ({
                    url: '/api/magic-link',
                    method: HttpMethod.Post,
                    body: { email },
                }),
                transformErrorResponse: (error) => {
                    return error.transform(authTransformError());
                },
            }),
            resetForgottenPassword: build.mutation<User, ResetParams>({
                query: ({ validate, token, userId, firstLogin, password, userData }) => ({
                    url: '/api/reset',
                    method: HttpMethod.Post,
                    body: {
                        password,
                        reset_token: token,
                        user_id: userId,
                        ...(userData && { userData }),
                        ...(firstLogin && { first_login: firstLogin }),
                    },
                    params: {
                        ...(validate && { validate }),
                    },
                }),
                transformErrorResponse: (error) => {
                    return error.transform(authTransformError());
                },
            }),
            testResetToken: build.mutation<{ res: boolean }, ResetParams>({
                query: ({ token, userId }) => ({
                    url: '/api/test-reset-token',
                    method: HttpMethod.Post,
                    body: {
                        reset_token: token,
                        user_id: userId,
                    },
                }),
                transformErrorResponse: (error) => {
                    return error.transform(authTransformError());
                },
            }),
        }),
    });

export const {
    useVerifyUserPasswordMutation,
    useCheckSsoQuery,
    useLazyCheckSsoQuery,
    useLoginMutation,
    useLoginTotpMutation,
    useGlobalLoginTotpMutation,
    useGlobalLoginMutation,
    useGlobalTokenLoginTotpMutation,
    useTokenLoginMutation,
    useTokenLoginTotpMutation,
    useGlobalTokenLoginMutation,
    useLogoutMutation,
    useTotpRecoverySmsMutation,
    useTotpRecoverySmsConfirmationMutation,
    useTotpRecoveryBackupCodeMutation,
    useForgottenPasswordMutation,
    useSendMagicLinkMutation,
    useResetForgottenPasswordMutation,
    useTestResetTokenMutation,
} = authApi;
