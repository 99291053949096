import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Alert, Button, ContextModalProps, Modal, Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { PickMeetingTemplateStep } from 'Shared/components/meeting/addMeetingModal/PickMeetingTemplateStep';
import { createDefaultMeetingData, useAddMeeting } from 'Shared/components/meeting/addMeetingModal/useAddMeeting';
import { buildGetMeetingParameters, useGetMeetingQuery, useGetMeetingsQuery } from 'Shared/services/meeting';
import { Meeting } from 'Shared/types/meeting';
import { MeetingForm } from './MeetingForm';

type AddMeetingModalProps = {
    onDone: (meeting?: Meeting) => void;
    workspaceId?: Id;
    templateId?: Id;
} & ContextModalProps &
    PropsWithChildren;

export const AddMeetingModal = ({
    onDone,
    workspaceId,
    templateId = '',
    children,
    ...modalProps
}: AddMeetingModalProps): JSX.Element => {
    const createNewMeetingButtonRef = useRef<HTMLButtonElement>(null);
    const [meetingData, setMeetingData] = useState(createDefaultMeetingData(workspaceId));
    const [isDone, setIsDone] = useState(false);
    const [chooseTemplateCurrentStep, setChooseTemplateCurrentStep] = useState(templateId !== '' ? 1 : 0);
    // Selected template is the template you select at step 1
    const [selectedTemplateId, setSelectedTemplateId] = useState<Id>(templateId || '');
    const [activeTab, setActiveTab] = useState(0);

    const { currentData: template } = useGetMeetingQuery(
        buildGetMeetingParameters(isEmpty(templateId) ? selectedTemplateId : templateId),
        {
            skip: templateId === '' && selectedTemplateId === '',
        }
    );

    const { data: templates = EmptyArray } = useGetMeetingsQuery(
        {
            related: ['tag.team', 'meetingUsers', 'meetingUsers.user', 'meetingUsers.user.userEmail'],
            filter: 'last',
            tagId: workspaceId,
            deleted: false,
        },
        { skip: isDone }
    );

    const sameTitleMeeting = useMemo(() => {
        if (isEmpty(meetingData.title)) {
            return undefined;
        }
        return templates.find(
            (template) => template.title.trim().toLowerCase() === meetingData.title.trim().toLowerCase()
        );
    }, [templates, meetingData.title]);

    const handleTitleAlreadyExistsClick = () => {
        setSelectedTemplateId(sameTitleMeeting?.id || '');
        setChooseTemplateCurrentStep(1);
        setActiveTab(1);
    };

    const handleDone = async (meeting: Meeting) => {
        // We do this to prevent the message "We found a meeting with this name" to be displayed for a brief moment after adding the meeting
        setIsDone(true);
        await modalProps.close();
        onDone(meeting);
    };

    const { handleSave, updateMeetingData, errorField, errorMessage, isAdding } = useAddMeeting({
        onDone: handleDone,
        workspaceId,
        templateId,
        template,
        selectedTemplateId,
        meetingData,
        setMeetingData,
    });

    const handleSelectTemplate = (templateId: Id) => {
        setSelectedTemplateId(templateId);
        setChooseTemplateCurrentStep(1);
    };

    return (
        <Modal size={'lg'} {...modalProps} initialFocus={createNewMeetingButtonRef}>
            <Modal.Header title={<Trans>Add a meeting</Trans>} />

            <Tabs
                layout={'horizontal'}
                selectedIndex={activeTab}
                onChange={(activeTab) => {
                    if (activeTab === 0) {
                        setSelectedTemplateId('');
                        setChooseTemplateCurrentStep(0);
                    }
                    setActiveTab(activeTab);
                }}
            >
                <Tabs.Header>
                    <Tabs.Tab>{t`New`}</Tabs.Tab>
                    <Tabs.Tab
                        className={'!text-sm sm:!text-base'}
                        isDisabled={!templates || templates?.length === 0}
                    >{t`From existing`}</Tabs.Tab>
                </Tabs.Header>
                <Modal.Body>
                    <Tabs.Panels>
                        <Tabs.Panel>
                            <MeetingForm
                                mode={'add'}
                                isTitleAlreadyPresent={sameTitleMeeting != null}
                                onTitleAlreadyExistsClick={handleTitleAlreadyExistsClick}
                                errorField={errorField}
                                meetingData={meetingData}
                                updateMeetingData={updateMeetingData}
                                onSave={handleSave}
                            />
                            {errorMessage && <Alert type={'danger'} title={errorMessage} />}
                        </Tabs.Panel>
                        <Tabs.Panel>
                            {chooseTemplateCurrentStep === 0 ? (
                                <PickMeetingTemplateStep
                                    workspaceId={workspaceId}
                                    onSelectTemplate={handleSelectTemplate}
                                />
                            ) : (
                                <>
                                    <MeetingForm
                                        mode={'add'}
                                        template={template}
                                        templateSeriesMaster={template?.seriesMaster}
                                        errorField={errorField}
                                        meetingData={meetingData}
                                        updateMeetingData={updateMeetingData}
                                        onSave={handleSave}
                                    />
                                    {errorMessage && <Alert type={'danger'} title={errorMessage} />}
                                </>
                            )}
                        </Tabs.Panel>
                    </Tabs.Panels>
                </Modal.Body>
            </Tabs>
            <Modal.Footer>
                <div className="flex justify-end gap-1">
                    {chooseTemplateCurrentStep === 0 && activeTab === 1 ? (
                        <Button onClick={modalProps.close}>
                            <Trans>Close</Trans>
                        </Button>
                    ) : (
                        <div className="flex justify-end gap-1">
                            {templateId === '' && templates?.length > 0 && activeTab === 1 && (
                                <Button onClick={() => setChooseTemplateCurrentStep(0)}>
                                    <Trans>Back</Trans>
                                </Button>
                            )}
                            <Button
                                color={'primary'}
                                loading={isAdding}
                                onClick={() => handleSave()}
                                aria-label={template?.type === 'occurrence' ? t`Add next occurrence` : t`Add meeting`}
                            >
                                {template?.type === 'occurrence' ? (
                                    <Trans>Add next occurrence</Trans>
                                ) : (
                                    <Trans>Add meeting</Trans>
                                )}
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
