import React from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faInputText } from '@fortawesome/pro-duotone-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { EmptyState } from '@wedo/design-system';
import { DraggableGroupItemFieldsTableRow } from 'Pages/settings/customFields/components/CustomGroupItem/GroupItemFieldsTable/DraggableGroupItemFieldsTableRow';
import { useCustomFieldGroup } from 'Pages/settings/customFields/hooks/useCustomFieldGroup';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { CustomFieldGroup } from 'Shared/types/customField';
import { closestY } from 'Shared/utils/dnd';

interface GroupItemFieldsTableProps {
    group: CustomFieldGroup;
    className?: string;
    showArchived: boolean;
}

export const GroupItemFieldsTable: React.FC<GroupItemFieldsTableProps> = ({ className, showArchived, group }) => {
    const { customFieldIds, reOrderFields, optimisticUpdateFields } = useCustomFieldGroup(group);
    const { sensors, measuring } = useDndSortableVerticalStrategy();

    const handleDragEnd = (e: DragEndEvent) => {
        const itemId = e.active.id;
        const moveOverId = e.over.id;
        reOrderFields(itemId as string, moveOverId as string);
    };

    if (customFieldIds.length === 0) {
        return (
            <EmptyState className={'p-2'} icon={faInputText} size="md">
                <EmptyState.Text>
                    <Trans>No custom fields in this group</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestY}
            measuring={measuring}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
        >
            <SortableContext items={customFieldIds} strategy={verticalListSortingStrategy}>
                <div className={clsx('divide-y', className)}>
                    {optimisticUpdateFields &&
                        optimisticUpdateFields.map(
                            (field) =>
                                (!field.archived || showArchived) && (
                                    <DraggableGroupItemFieldsTableRow key={field.id} field={field} />
                                )
                        )}
                </div>
            </SortableContext>
        </DndContext>
    );
};
