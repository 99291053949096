import { useMemo } from 'react';
import { useRevalidator } from 'react-router-dom';
import { t } from '@lingui/macro';
import { ConfirmModal, UnexpectedErrorNotification, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import store from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import {
    currentNetworkTeamsTag,
    teamApi,
    teamTag,
    useGetTeamQuery,
    useRefuseJoinTeamAccessMutation,
} from 'Shared/services/team';
import { trpc, trpcUtils } from 'Shared/trpc';
import { User, UserRole } from 'Shared/types/user';
import { waitForQueryToBeFulfilled } from 'Shared/utils/rtkQuery';

export const useTeam = (teamId: string) => {
    const revalidator = useRevalidator();

    const sessionUser = useSessionUser();

    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { closeAnyOpenModals } = useModal();

    const { data: team, isLoading } = useGetTeamQuery({ teamId }, { skip: !teamId });

    const isCurrentUserTeamMember = useMemo(
        () => team?.userGroup?.members.some(({ user_id }) => user_id === sessionUser.id),
        [team?.userGroup?.members]
    );

    const isCurrentUserTeamModerator =
        sessionUser.role === UserRole.ADMIN ||
        team?.userGroup?.members.some(({ user_id, is_moderator }) => user_id === sessionUser.id && is_moderator);

    const invalidateTeam = async () => {
        store.dispatch(teamApi.util.invalidateTags([currentNetworkTeamsTag, teamTag(teamId)]));
        // TODO We resolve the promise only when the getTeam fetch has been fulfilled. It's a bit of a hack until we
        //  refactor how invalidation works
        await waitForQueryToBeFulfilled(teamApi.endpoints.getTeam.select({ teamId }));
    };

    const { mutateAsync: addMember } = trpc.team.addMember.useMutation({
        onSuccess: invalidateTeam,
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: removeMember } = trpc.team.removeMember.useMutation({
        onSuccess: async (data, variables) => {
            if (variables.userId === sessionUser.id) {
                closeAnyOpenModals();
                store.dispatch(teamApi.util.resetApiState());
                revalidator.revalidate();
                await trpcUtils().team.list.invalidate();
            } else {
                await invalidateTeam();
            }
        },
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: updateMember } = trpc.team.updateMember.useMutation({
        onSuccess: invalidateTeam,
        onError: () => show(UnexpectedErrorNotification),
    });

    const [refuseJoinRequest] = useRefuseJoinTeamAccessMutation();

    const confirmRemoveCurrentUserFromTeam = async () =>
        confirm(
            {
                type: 'danger',
                title: t`Remove yourself from the ${team?.name} team?`,
                content: t`You will lose access to this team, and all workspaces and templates inside this team.`,
                confirmText: t`Continue`,
            },
            ConfirmModal
        );

    const confirmRevokeCurrentUserModeratorRights = async () =>
        confirm(
            {
                type: 'warning',
                title: t`Are you sure you want to revoke your moderator rights?`,
                content: t`You will retain access to this team, but you won't be a moderator anymore.`,
            },
            ConfirmModal
        );

    const handleModeratorToggle = async (userId: string, isModerator: boolean) => {
        if (!isModerator && userId === sessionUser.id && !(await confirmRevokeCurrentUserModeratorRights())) {
            return;
        }
        await updateMember({ teamId, userId, isModerator });
    };

    const handleMemberDelete = async (user: User, { confirmBeforeDelete } = { confirmBeforeDelete: true }) => {
        if (user.id === sessionUser.id && confirmBeforeDelete) {
            const confirmRemove = await confirmRemoveCurrentUserFromTeam();
            if (!confirmRemove) {
                return '';
            }
        } else if (confirmBeforeDelete) {
            const confirmRemove = await confirm(
                {
                    type: 'danger',
                    title: t`Remove ${user?.full_name} from the ${team?.name} team?`,
                    content: t`This person will be removed from this team and all it's workspaces and templates.`,
                    confirmText: t`Continue`,
                },
                ConfirmModal
            );
            if (!confirmRemove) {
                return '';
            }
        }
        await removeMember({ teamId: team.id, userId: user.id });
        return user.id === sessionUser.id ? 'close' : '';
    };

    const handleAddMember = async (user: User) => {
        await addMember({ teamId: team.id, userId: user.id });
    };

    const handleAcceptRequest = async (userId: Id) => {
        await addMember({ teamId: team.id, userId });
    };

    const handleRefuseJoinRequest = async (userId: Id): Promise<boolean> => {
        const response = await refuseJoinRequest({ team_id: team.id, user_id: userId });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    return {
        team,
        isLoading,
        isCurrentUserTeamMember,
        isCurrentUserTeamModerator,
        handleModeratorToggle,
        handleMemberDelete,
        handleAddMember,
        handleAcceptRequest,
        handleRefuseJoinRequest,
    };
};
