import React from 'react';
import { ImageSettingsCard } from 'Pages/settings/general/components/ImageSettingsCard';
import { OrganisationCard } from 'Pages/settings/general/components/OrganisationCard';
import { Can } from 'Shared/components/Can';
import { Permission } from 'Shared/utils/rbac';

export const GeneralSettingsPage = () => {
    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <div className="mb-8 flex flex-col gap-6">
                <ImageSettingsCard />
                <OrganisationCard />
            </div>
        </Can>
    );
};
