import { FC, useMemo } from 'react';
import { faFile, faFiles, faImage } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag, Timeline } from '@wedo/design-system';
import { getFileExtension, isImageExtension } from '@wedo/utils';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceHistory } from 'Shared/types/workspace';

export const AddedAttachmentTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    const fileNames = (activity?.new_value as unknown as { filenames: string }).filenames;

    const extension = getFileExtension(fileNames);

    const fileIcon = useMemo(() => {
        if (isImageExtension(extension)) {
            return faImage;
        }
        return faFile;
    }, [extension]);

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faFiles}>
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> added a file{' '}
                    <Tag size="xs" icon={fileIcon} color="green">
                        {fileNames}
                    </Tag>{' '}
                    in the workspace.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
