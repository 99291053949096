import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { getMonth, getYear, setMonth } from 'date-fns';
import { FormatDate, Select } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { formatDate } from '@wedo/utils';
import { Meeting } from 'Shared/types/meeting';

export const MeetingPicker = ({
    meetings,
    selectedMeetingId,
    onSelectMeetingId,
    error = '',
    setError,
}: {
    meetings: Meeting[];
    selectedMeetingId: Id;
    onSelectMeetingId: (meetingId: Id) => void;
    error?: string;
    setError?: (error: string) => void;
}) => {
    const { i18n } = useLingui();

    const [filteredMeetings, setFilteredMeetings] = useState<Meeting[]>([]);
    const filteredGroupedMeetings: Meeting[][] = useMemo(
        () =>
            filteredMeetings.reduce((group, meeting) => {
                const month = `${getMonth(new Date(meeting.startAt))}-${getYear(new Date(meeting.startAt))}`;
                if (group[month] == null) {
                    group[month] = [];
                }
                group[month].push(meeting);
                return group;
            }, []),
        [filteredMeetings]
    );

    useEffect(() => {
        if (meetings != null) {
            setFilteredMeetings(meetings);
        }
    }, [meetings]);

    return (
        <Select
            onSearch={(search) => {
                setFilteredMeetings(
                    meetings.filter((meeting) => meeting.title.toLowerCase().includes(search.toLowerCase()))
                );
            }}
            isClearable
            value={selectedMeetingId}
            onChange={(meetingId: Id) => {
                setError?.('');
                onSelectMeetingId(meetingId);
            }}
            customRenderSelected={(meetingId: Id) =>
                filteredMeetings.find(({ id }) => Number(id) === Number(meetingId))?.title
            }
            status={error.length === 0 ? 'default' : 'error'}
            statusText={error}
        >
            {Object.keys(filteredGroupedMeetings).map((key) => (
                <Select.OptionGroup key={key} label={formatDate(setMonth(new Date(), key.split('-')[0]), 'LLLL', i18n)}>
                    {filteredGroupedMeetings[key].map((meeting) => {
                        return (
                            <Select.Option key={meeting.id} value={meeting.id}>
                                <div className="flex items-center justify-between mr-2 w-full gap-2">
                                    <div className="flex items-center shrink w-full max-w-full truncate">
                                        <div className={'flex gap-2 truncate'}>
                                            <FormatDate date={meeting.startAt} format={'shortDate'} />
                                            <div>-</div>
                                            <div className="truncate">{meeting.title}</div>
                                        </div>
                                    </div>
                                </div>
                            </Select.Option>
                        );
                    })}
                </Select.OptionGroup>
            ))}
        </Select>
    );
};
