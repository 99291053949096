import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import {
    faArrowAltDown,
    faArrowAltRight,
    faCheck,
    faMoon,
    faSquare2,
    faSunCloud,
    faSunrise,
    faTrash,
} from '@fortawesome/pro-duotone-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { addDays, addMonths, format, nextMonday } from 'date-fns';
import {
    Button,
    DatePicker,
    Popover,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { getDateFnsLocale } from '@wedo/utils';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';
import { Task } from 'Shared/types/task';
import { Panel } from './Panel';

type OrganizeAndPlanProps = {
    task: Task;
    onNext: () => void;
    isInGanttContext?: boolean;
};

export const TaskPlannedDatePanel = ({ task, onNext, isInGanttContext }: OrganizeAndPlanProps) => {
    const queryClient = useQueryClient();

    const { confirm } = useConfirm();

    const [updateTask, { isLoading: isUpdating }] = useUpdateTaskMutation();
    const { mutateAsync: updateStatus, isPending: isUpdateStatusLoading } = trpc.task.updateStatus.useMutation({});
    const isLoading = isUpdating || isUpdateStatusLoading;

    const reference = useRef(new Date());
    const [planning, setPlanning] = useState(false);
    const { show } = useNotification();

    const { i18n } = useLingui();
    const locale = getDateFnsLocale(i18n.locale);

    const updatePlannedDate = async (date: Date) => {
        const result = await updateTask({ id: task.id, planned_date: date.toString(), keepCache: isInGanttContext });

        if ('error' in result) {
            show(UnexpectedErrorNotification);
            return;
        }

        setPlanning(false);

        if (isInGanttContext) {
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'plannedDate')]);
        }

        onNext();
    };

    const handleToday = () => updatePlannedDate(reference.current);

    const handleLater = () => setPlanning(true);

    const handleDone = async () => {
        await updateStatus({
            taskIds: [task.id],
            completed: true,
            applyOn: ApplyOn.AllTasks,
        });
        if (isInGanttContext) {
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'completed')]);
        }
        onNext();
    };

    const handleDelete = async () => {
        if (
            await confirm({
                type: 'danger',
                title: t`Delete task?`,
                content: t`Do you really want to delete this task?`,
                confirmText: t`Delete`,
            })
        ) {
            await updateStatus({
                taskIds: [task.id],
                deleted: true,
                applyOn: ApplyOn.AllTasks,
            });
            if (isInGanttContext) {
                await invalidateQueries(queryClient, [taskQueryTag.removed()]);
            }
            onNext();
        }
    };

    const handleTomorrow = () => updatePlannedDate(addDays(reference.current, 1));

    const handleIn2Days = () => updatePlannedDate(addDays(reference.current, 2));

    const handleNextWeek = () => updatePlannedDate(nextMonday(reference.current));

    const handleInAMonth = () => updatePlannedDate(addMonths(reference.current, 1));

    const actions = [
        { color: 'text-blue-600', icon: faArrowAltDown, label: t`Today`, action: handleToday },
        { color: 'text-yellow-500', icon: faArrowAltRight, label: t`Later`, action: handleLater },
        { color: 'text-green-500', icon: faCheck, label: t`Done`, action: handleDone },
        { color: 'text-red-500', icon: faTrash, label: t`Delete`, action: handleDelete },
    ];

    const laterActions = [
        {
            color: 'text-yellow-500',
            icon: faSunrise,
            label: t`Tomorrow`,
            description: format(addDays(reference.current, 1), 'PPPP', { locale: locale }),
            action: handleTomorrow,
        },
        {
            color: 'text-orange-500',
            icon: faSquare2,
            label: t`In 2 days`,
            description: format(addDays(reference.current, 2), 'PPPP', { locale: locale }),
            action: handleIn2Days,
        },
        {
            color: 'text-green-500',
            icon: faSunCloud,
            label: t`Next week`,
            description: format(nextMonday(reference.current), 'PPPP', { locale: locale }),
            action: handleNextWeek,
        },
        {
            color: 'text-violet-500',
            icon: faMoon,
            label: t`In a month`,
            description: format(addMonths(reference.current, 1), 'PPPP', { locale: locale }),
            action: handleInAMonth,
        },
    ];

    return (
        <Panel className="grid grid-cols-2 grid-rows-2">
            {!planning ? (
                <>
                    {actions.map((action) => (
                        <Button
                            key={action.label}
                            disabled={isLoading}
                            className="flex-col justify-center !whitespace-normal !h-48"
                            onClick={action.action}
                        >
                            <FontAwesomeIcon icon={action.icon} className={clsx('h-18 w-18', action.color)} />
                            {action.label}
                        </Button>
                    ))}
                </>
            ) : (
                <>
                    {laterActions.map((action) => (
                        <Button
                            key={action.label}
                            disabled={isLoading}
                            className="flex-col justify-center !whitespace-normal !h-48"
                            onClick={action.action}
                        >
                            <FontAwesomeIcon icon={action.icon} className={clsx('h-18 w-18', action.color)} />
                            {action.label}
                            <p className="text-gray-500">{action.description}</p>
                        </Button>
                    ))}
                    <div className="col-span-2 justify-self-center">
                        <Popover text={t`Pick a date`} placement={'top'}>
                            <div className="w-72 bg-white p-4">
                                <DatePicker onChange={(date) => updatePlannedDate(date)} date={new Date()} />
                            </div>
                        </Popover>
                    </div>
                </>
            )}
        </Panel>
    );
};
