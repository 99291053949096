import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useAcceptUserMutation, useDeleteUserMutation } from 'Shared/services/admin';
import { trpcUtils } from 'Shared/trpc';
import { User } from 'Shared/types/user';

export const RequestedActionCell: FC<User> = ({ id, full_name }) => {
    const [accept, { isLoading: isAcceptLoading }] = useAcceptUserMutation();
    const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { isScimStrictMode } = useCurrentNetwork();

    const handleAccept = async () => {
        const result = await accept(id);

        if ('error' in result) {
            show(UnexpectedErrorNotification);
        } else {
            void trpcUtils().user.list.invalidate();
        }
    };

    const handleDelete = async () => {
        const confirmed = await confirm({
            type: 'danger',
            title: t`Permanently delete ${full_name}`,
            confirmText: t`Delete`,
        });

        if (!confirmed) {
            return;
        }

        const result = await deleteUser({ userId: id, task_action: '' });

        if ('error' in result) {
            show(UnexpectedErrorNotification);
        } else {
            void trpcUtils().user.list.invalidate();
        }
    };

    return (
        <div className={'flex gap-2'}>
            <Button
                loading={isAcceptLoading}
                color="success"
                onClick={handleAccept}
                disabled={isScimStrictMode}
                size={'sm'}
            >
                <Trans>Accept</Trans>
            </Button>
            <Button
                loading={isDeleteLoading}
                color="danger"
                onClick={handleDelete}
                disabled={isScimStrictMode}
                size={'sm'}
            >
                <Trans>Refuse</Trans>
            </Button>
        </div>
    );
};
