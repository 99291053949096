import { useLingui } from '@lingui/react';
import React, { FC, useMemo, useState } from 'react';
import {
    faBriefcase,
    faCalendar,
    faCalendarDay,
    faClipboardListCheck,
    faCog,
    faPeopleGroup,
    faSquareCheck,
    faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import { differenceInSeconds, startOfDay } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { search } from 'ss-search';
import { colors, CommandPalette, ContextModalProps, Modal, useModal } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useCommandPaletteModalKeyboardShortcuts } from 'Pages/AppPage/useCommandPaletteModalKeyboardShortcuts';
import { AddTemplateModal } from 'Pages/TemplatesPage/components/AddTemplateModal/AddTemplateModal';
import { accountSettings, organizationSettings, SettingLinkProps } from 'Pages/settings/menu';
import { Highlighter } from 'Shared/components/Highlighter';
import { AddMeetingModal } from 'Shared/components/meeting/addMeetingModal/AddMeetingModal';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { TemplateIcon } from 'Shared/components/template/TemplateIcon';
import { AddUserModal } from 'Shared/components/user/AddEditUserModal/AddUserModal';
import { AddWorkspaceModal } from 'Shared/components/workspace/AddWorkspaceModal/AddWorkspaceModal';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { useCommandPaletteSearchHistory } from 'Shared/hooks/useCommandPaletteSearchHistory';
import { useGetMeetingsQuery } from 'Shared/services/meeting';
import { trpc } from 'Shared/trpc';
import { CommandPaletteEvent, FilterItem } from 'Shared/types/commandPalette';
import { Meeting } from 'Shared/types/meeting';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { Workspace } from 'Shared/types/workspace';
import { Permission } from 'Shared/utils/rbac';

type SettingItem = Omit<SettingLinkProps, 'name'> & { name: string };

const settings = accountSettings.concat(organizationSettings);

const filterItems = <T,>(query: string, items: T[], searchFields: string[]): T[] =>
    query !== '' ? (search(items, searchFields, query) as T[]) : [];

const uniqueKey = (event: CommandPaletteEvent | FilterItem): string => `${event.name}-${event.id}`;

const CommandPaletteOption: FC<{ item: FilterItem; query: string }> = ({ item, query }) => {
    return (
        <CommandPalette.Option
            key={uniqueKey(item)}
            value={item}
            icon={item?.icon}
            iconComponent={item?.iconComponent}
            iconClassName={item?.iconClassName}
            shortcut={item?.shortcut}
        >
            <Highlighter
                autoEscape
                searchWords={query.split(' ')}
                highlightStyle={{ background: colors.blue['100'], color: colors.blue['600'] }}
                textToHighlight={item.name}
            />
        </CommandPalette.Option>
    );
};

const CommandPaletteGroup: FC<{
    title: string;
    items: FilterItem[];
    query: string;
}> = ({ title, items, query }) => (
    <CommandPalette.Group title={title}>
        {items.map((item) => (
            <CommandPaletteOption key={`${title}-${uniqueKey(item)}`} item={item} query={query} />
        ))}
    </CommandPalette.Group>
);

export const CommandPaletteModal: FC<ContextModalProps> = ({ close, ...modalProps }) => {
    const navigate = useNavigate();
    const { can } = useCurrentUserContext();
    const { open } = useModal();
    const { recentEvents, addToRecentEvents } = useCommandPaletteSearchHistory();
    const { i18n } = useLingui();

    const { data: teams } = trpc.team.list.useQuery({});
    const { data: workspaces } = trpc.workspace.list.useQuery({});
    const { data: templates } = trpc.template.list.useQuery({});

    const [query, setQuery] = useState<string>(EmptyString);

    useCommandPaletteModalKeyboardShortcuts(close);

    const AddTaskFilterItem: FilterItem = {
        name: t`Add new task...`,
        icon: faSquareCheck,
        iconClassName: 'h-4 w-4',
        shortcut: 'S',
        onClick: () => open(TaskModal),
        permission: Permission.ManageTasks,
    };

    const AddMeetingFilterItem: FilterItem = {
        name: t`Add new meeting...`,
        icon: faCalendarDay,
        iconClassName: 'h-4 w-4',
        shortcut: 'M',
        onClick: () =>
            open(AddMeetingModal, {
                onDone: (meeting: Meeting) => {
                    if ('id' in meeting) {
                        navigate(`/meetings/${meeting.id}`);
                    }
                },
            }),
        permission: Permission.AddMeeting,
    };

    const AddWorkspaceFilterItem: FilterItem = {
        name: t`Add new workspace...`,
        icon: faBriefcase,
        iconClassName: 'h-4 w-4',
        shortcut: 'E',
        onClick: () => open(AddWorkspaceModal),
        permission: Permission.AddWorkspace,
    };

    const AddTeamFilterItem: FilterItem = {
        name: t`Add new team...`,
        icon: faPeopleGroup,
        iconClassName: 'h-4 w-4',
        shortcut: 'I',
        onClick: () => open(AddTeamModal),
        permission: Permission.AddTeam,
    };

    const AddTemplateFilterItem: FilterItem = {
        name: t`Add new template...`,
        icon: faClipboardListCheck,
        iconClassName: 'h-4 w-4',
        shortcut: 'P',
        onClick: () => open(AddTemplateModal),
        permission: Permission.AddTemplate,
    };

    const AddUserFilterItem: FilterItem = {
        name: t`Invite new user...`,
        icon: faUserPlus,
        iconClassName: 'h-4 w-4',
        shortcut: 'U',
        onClick: () => open(AddUserModal),
        permission: Permission.ManageNetwork,
    };

    const quickActions: Array<FilterItem> = [
        AddTaskFilterItem,
        AddMeetingFilterItem,
        AddWorkspaceFilterItem,
        AddTeamFilterItem,
        AddTemplateFilterItem,
        AddUserFilterItem,
    ];

    const filteredQuickActions = (query !== '' ? filterItems(query, quickActions, ['name']) : quickActions).filter(
        (quickAction) => can(quickAction.permission)
    ) as Array<FilterItem>;

    const { data: meetings } = useGetMeetingsQuery({ related: ['tag'], deleted: false });

    const seriesMasterIdToCurrentMeeting = useMemo<Map<string, Meeting>>(() => {
        const result = new Map();
        if (!Array.isArray(meetings)) {
            return result;
        }
        if (isEmpty(query.trim())) {
            return result;
        }

        const today = startOfDay(new Date());
        for (const meeting of meetings) {
            if (result.has(meeting.series_master_id)) {
                const currentStartTime = new Date(meeting.start_at);
                const previousStartTime = new Date(result.get(meeting.series_master_id).start_at);

                const newMeetingDiff = differenceInSeconds(currentStartTime, today);
                const oldMeetingDiff = differenceInSeconds(previousStartTime, today);

                // if both meetings are in the past then take the one which is closer
                // if both meetings are in the future then take the one which is the next occurrence from today
                if ((newMeetingDiff < 0 && oldMeetingDiff < 0) || (newMeetingDiff >= 0 && oldMeetingDiff >= 0)) {
                    if (Math.abs(newMeetingDiff) < Math.abs(oldMeetingDiff)) {
                        result.set(meeting.series_master_id, meeting);
                    }
                }

                // else take the one which is in the future
                else if (newMeetingDiff >= 0) {
                    result.set(meeting.series_master_id, meeting);
                }
            } else {
                result.set(meeting.series_master_id, meeting);
            }
        }
        return result;
    }, [meetings, query]);

    const MeetingIconData = { icon: faCalendar, iconClassName: 'h-4 w-4' };

    const getMeetingItemFromEvent = (meeting: CommandPaletteEvent): FilterItem => ({
        ...MeetingIconData,
        id: meeting.id,
        name: meeting.name,
        event: { type: 'meeting', name: meeting.name, id: meeting.id },
        to: `/meetings/${meeting.id}`,
    });

    const getMeetingItem = (meeting: Meeting): FilterItem => ({
        ...MeetingIconData,
        id: meeting.id,
        name: meeting.title,
        to: `/meetings/${meeting.id}`,
        event: { type: 'meeting', name: meeting.title, id: meeting.id },
    });

    const filteredMeetings = filterItems(
        query,
        [...seriesMasterIdToCurrentMeeting.values()]?.map((meeting) => getMeetingItem(meeting)),
        ['name']
    );

    const getWorkspaceItem = (workspace: Workspace | CommandPaletteEvent): FilterItem => ({
        id: workspace.id,
        name: workspace.name,
        event: { type: 'workspace', name: workspace.name, id: workspace.id },
    });

    const getWorkspaceItemFromWorkspace = (workspace: Workspace): FilterItem => ({
        ...getWorkspaceItem(workspace),
        to: `/workspaces/${workspace.id}/${workspace?.settings?.default_tab ?? 'tasks'}`,
        iconComponent: <WorkspaceIcon workspace={workspace} className="-mr-1 !h-5 !w-5 -translate-x-0.5" />,
    });

    const getWorkspaceItemFromEvent = (workspace: CommandPaletteEvent): FilterItem => ({
        ...getWorkspaceItem(workspace),
        to: `/workspaces/${workspace.id}/${(workspace?.value as Workspace)?.settings?.default_tab ?? 'tasks'}`,
        iconComponent: (
            <WorkspaceIcon workspace={workspace?.value as Workspace} className="-mr-1 !h-5 !w-5 -translate-x-0.5" />
        ),
    });

    const filteredWorkspaces = filterItems(query, workspaces, ['name']).map((workspace) =>
        getWorkspaceItemFromWorkspace(workspace as unknown as Workspace)
    );

    const TeamItemIconData = { icon: faPeopleGroup, iconClassName: 'h-4 w-4' };

    const getTeamItem = (team: Team | CommandPaletteEvent): FilterItem => ({
        ...TeamItemIconData,
        id: team.id,
        name: team.name,
        event: { type: 'team', name: team.name, id: team.id },
        to: `/teams/${team.id}/workspaces`,
    });

    const filteredTeams = filterItems(query, teams, ['name']).map((team: Team) => getTeamItem(team));

    const getSettingItem = (setting: CommandPaletteEvent | SettingItem): FilterItem => ({
        icon: faCog,
        iconClassName: 'h-4 w-4',
        id: setting.name,
        name: setting.name,
    });

    const getSettingItemFromSetting = (setting: SettingItem): FilterItem => ({
        ...getSettingItem(setting),
        event: { type: 'settings', name: setting.name, id: setting.to as string },
        to: `/settings${setting.to}`,
    });

    const getSettingItemFromEvent = (setting: CommandPaletteEvent): FilterItem => ({
        ...getSettingItem(setting),
        event: { type: 'settings', name: setting.name, id: setting.id },
        to: `/settings${setting.id}`,
    });

    const translatedSettings: SettingItem[] = settings.map((setting) => {
        return { ...setting, name: i18n._(setting.searchableName ?? setting.name) };
    });

    const filteredSettings = filterItems(query, translatedSettings, ['name']).map((setting) =>
        getSettingItemFromSetting(setting)
    );

    const filteredRecentEvents = isEmpty(query.trim())
        ? recentEvents
        : (filterItems(query, recentEvents, ['name']) as unknown as CommandPaletteEvent[]);

    const getTemplateItem = (template: CommandPaletteEvent | Template): FilterItem => ({
        id: template.id,
        name: template.name,
        event: { type: 'template', name: template.name, id: template.id },
        to: `/templates/${template.id}/checklists`,
    });

    const getTemplateItemFromEvent = (template: CommandPaletteEvent): FilterItem => ({
        ...getTemplateItem(template),
        iconComponent: (
            <TemplateIcon
                template={template.value as Template}
                variant="light"
                size="sm"
                className="-mr-1 -translate-x-0.5"
            />
        ),
    });

    const getTemplateItemFromTemplate = (template: Template): FilterItem => ({
        ...getTemplateItem(template),
        iconComponent: <TemplateIcon template={template} variant="light" size="sm" />,
    });

    const filteredTemplates = (filterItems(query, templates, ['name']) as unknown as Template[]).map((template) =>
        getTemplateItemFromTemplate(template)
    );

    const handlePaletteClose = async () => {
        setQuery(EmptyString);
        return true;
    };

    const handleItemSelect = async (item: {
        to?: string;
        onClick?: () => void;
        name?: string;
        event: CommandPaletteEvent;
    }) => {
        if (item.event?.type === 'search') {
            void navigate({ pathname: '/search/topics', searchParams: { search: item.name, scope: 'all-words' } });
        } else if (item.onClick) {
            item.onClick();
        } else {
            void navigate(item.to);
        }
        await close();
        if (item?.event) {
            addToRecentEvents(item.event);
        }
    };

    return (
        <Modal {...modalProps} onBeforeClose={handlePaletteClose}>
            <CommandPalette
                query={query}
                onChange={(event) => setQuery(event.target.value)}
                onSelect={handleItemSelect}
            >
                <CommandPalette.Options>
                    {!isEmpty(query.trim()) && (
                        <div className="p-2 text-sm">
                            <CommandPalette.Option
                                value={{
                                    to: `/search`,
                                    value: query.trim(),
                                    name: query.trim(),
                                    event: { type: 'search', value: query.trim(), name: query.trim() },
                                }}
                            >
                                {t`Search for "${query}" in WEDO`}
                            </CommandPalette.Option>
                        </div>
                    )}

                    {!isEmpty(filteredRecentEvents) && (
                        <CommandPalette.Group title={t`Recent`}>
                            {filteredRecentEvents.map((event) => (
                                <>
                                    {event.type === 'search' && (
                                        <CommandPaletteOption
                                            item={{
                                                id: event.name,
                                                name: event.name,
                                                to: '/search',
                                                icon: faSearch,
                                                iconClassName: 'h-4 w-4',
                                                event: event,
                                            }}
                                            query={query}
                                            key={uniqueKey(event)}
                                        />
                                    )}

                                    {event.type === 'workspace' && (
                                        <CommandPaletteOption
                                            key={uniqueKey(event)}
                                            item={getWorkspaceItemFromEvent(event)}
                                            query={query}
                                        />
                                    )}

                                    {event.type === 'team' && (
                                        <CommandPaletteOption
                                            key={uniqueKey(event)}
                                            item={getTeamItem(event)}
                                            query={query}
                                        />
                                    )}

                                    {event.type === 'settings' && (
                                        <CommandPaletteOption
                                            key={uniqueKey(event)}
                                            item={getSettingItemFromEvent(event)}
                                            query={query}
                                        />
                                    )}

                                    {event.type === 'meeting' && (
                                        <CommandPaletteOption
                                            key={uniqueKey(event)}
                                            item={getMeetingItemFromEvent(event)}
                                            query={query}
                                        />
                                    )}

                                    {event.type === 'template' && (
                                        <CommandPaletteOption
                                            key={uniqueKey(event)}
                                            item={getTemplateItemFromEvent(event)}
                                            query={query}
                                        />
                                    )}
                                </>
                            ))}
                        </CommandPalette.Group>
                    )}

                    {filteredWorkspaces.length > 0 && (
                        <CommandPaletteGroup title={t`Workspaces`} items={filteredWorkspaces} query={query} />
                    )}

                    {filteredTeams.length > 0 && (
                        <CommandPaletteGroup title={t`Teams`} items={filteredTeams} query={query} />
                    )}

                    {filteredMeetings.length > 0 && (
                        <CommandPaletteGroup title={t`Meetings`} items={filteredMeetings} query={query} />
                    )}

                    {filteredQuickActions.length > 0 && (
                        <CommandPaletteGroup items={filteredQuickActions} title={t`Quick actions`} query={query} />
                    )}

                    {filteredTemplates.length > 0 && (
                        <CommandPaletteGroup items={filteredTemplates} title={t`Templates`} query={query} />
                    )}

                    {filteredSettings.length > 0 && (
                        <CommandPaletteGroup title={t`Settings`} items={filteredSettings} query={query} />
                    )}
                </CommandPalette.Options>
            </CommandPalette>
        </Modal>
    );
};
