import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { getUnixTime } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { Id } from '@wedo/types';
import { getSparseData } from '@wedo/utils';
import { useGetWorkspaceHistoryQuery } from 'Shared/services/dashboard';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';

export const useWorkspaceHistoryChart = (workspaceId: Id) => {
    const { data: history, isLoading: isLoadingHistory } = useGetWorkspaceHistoryQuery(workspaceId);
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);

    const startDate = workspace?.tagStatus?.start_date;
    const dueDate = workspace?.tagStatus?.due_date;

    const historySlice = useMemo(() => {
        if (!startDate || !dueDate) {
            return history;
        }
        if (isEmpty(history)) {
            return history;
        }

        const startDateEpochSeconds = getUnixTime(new Date(startDate));
        const dueDateDateEpochSeconds = getUnixTime(new Date(dueDate));

        const startIndex = history.created
            .map((created) => created[0])
            .findIndex((epochTime) => Number(epochTime) >= startDateEpochSeconds);

        const endIndex = history.created
            .map((created) => created[0])
            .findIndex((epochTime) => Number(epochTime) >= dueDateDateEpochSeconds);

        if (startIndex === -1) {
            return { completed: [], created: [] };
        }

        return {
            created: history.created.slice(startIndex, endIndex === -1 ? history.completed.length : endIndex + 1),
            completed: history.completed.slice(startIndex, endIndex === -1 ? history.completed.length : endIndex + 1),
        };
    }, [history, startDate, dueDate]);

    const createdLineData = useMemo(() => {
        const data = [];
        const createdStream = historySlice?.created;
        const completedStream = historySlice?.completed;
        for (let i = 0; i < createdStream?.length; i++) {
            data.push({ x: createdStream?.[i][0], y: createdStream?.[i][1] + completedStream?.[i][1] });
        }
        return { id: t`Created tasks`, data };
    }, [historySlice]);

    const completedLineData = useMemo(
        () => ({
            id: t`Completed tasks`,
            data: historySlice?.completed?.map((completed) => ({
                x: completed?.[0],
                y: completed?.[1],
            })),
        }),
        [historySlice]
    );

    const burndownLineData = useMemo(
        () => [
            {
                id: t`Created tasks`,
                data: historySlice?.created?.map((created) => ({
                    x: created?.[0],
                    y: created?.[1],
                })),
            },
        ],
        [historySlice]
    );

    const burnUpLineData = useMemo(() => [completedLineData, createdLineData], [createdLineData, completedLineData]);

    const xAxisLabels = useMemo(() => {
        const dates: string[] = createdLineData?.data?.map((datum) => datum.x) ?? [];
        return getSparseData(dates, 8);
    }, [createdLineData]);

    const isLoading = isLoadingHistory || isEmpty(burnUpLineData) || isEmpty(burndownLineData);

    return { xAxisLabels, burnUpLineData, burndownLineData, isLoading };
};
