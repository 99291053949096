import { FC, useEffect, useState } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, EmptyState, Form, Skeleton, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import {
    useGetOnboardingUserNetworksQuery,
    useOnboardingRequestNetworkAccessMutation,
} from 'Shared/services/onboarding';
import { trpcUtils } from 'Shared/trpc';

export const ChooseOrganizationStep: FC = () => {
    const { show } = useNotification();
    const { actions, ...store } = useOnboarding();

    const [pendingNetworksList, setPendingNetworksList] = useState(new Set<Id>());
    const [requestNetworkAccess] = useOnboardingRequestNetworkAccessMutation();

    const {
        isLoading,
        error,
        data: networks,
        refetch,
    } = useGetOnboardingUserNetworksQuery({
        token: store.token,
    });

    const addLoadingNetwork = (networkId: Id) => {
        setPendingNetworksList(new Set(pendingNetworksList).add(networkId));
    };

    const removeLoadingNetwork = (networkId: Id) => {
        const newLoadingNetwork = structuredClone(pendingNetworksList) as Set<Id>;
        newLoadingNetwork.delete(networkId);
        setPendingNetworksList(newLoadingNetwork);
    };

    const sendRequest = async (networkId: Id) => {
        addLoadingNetwork(networkId);

        const result = await requestNetworkAccess({
            networkId: networkId,
            user: { id: store.userId, token: store.token },
        });

        if ('error' in result) {
            show({
                title: t`Error`,
                message: result.error.message,
                type: 'danger',
            });
        }

        if ('data' in result) {
            show({
                title: t`Request sent`,
                message: t`An email was sent to the administrators of your organization. Once they've approved your request you'll have access to WEDO. You'll be notified once the request has been approved.`,
                type: 'success',
            });
            await trpcUtils().user.list.invalidate();
        }

        removeLoadingNetwork(networkId);
    };

    useEffect(() => {
        // if there are no networks, go to the next step
        if (networks != null && networks.length === 0) {
            actions.setStep(store.step + 1);
        }
    }, [networks]);

    const isNetworkActive = (networkId: Id) =>
        store.networks.some((network) => network.networkId === networkId && network.status === 'ACTIVE');

    if (isLoading) {
        return <Skeleton count={5} className="mb-2.5 h-3 w-[400px]" />;
    }

    return (
        <>
            {error ? (
                <EmptyState icon={faCircleExclamation} onClick={refetch}>
                    <EmptyState.Text>
                        <p>
                            <Trans>Error while fetching the networks. Click here to try again.</Trans>
                        </p>
                        <p>{error.message}</p>
                    </EmptyState.Text>
                </EmptyState>
            ) : (
                <Form layout="vertical" title={t`Join an existing organization`}>
                    <Form.Item>
                        <Trans>
                            We found at least one organization with the same domain as yours. You can either join one of
                            them or create your own organization.
                        </Trans>
                    </Form.Item>

                    {networks.map((network) => (
                        <Form.Item cols={6} key={network.id}>
                            <div className="flex items-center rounded-md border border-gray-600 p-2 px-4">
                                <div className="grow">
                                    {network.name} ({network.short_name})
                                    <div className="text-sm text-gray-600">@{network.domains?.[0].name}</div>
                                </div>
                                {network.isMember ? (
                                    isNetworkActive(network.id) ? (
                                        <Button href={`https://${network.short_name}.wedo.app`} color="primary">
                                            <Trans>Login</Trans>
                                        </Button>
                                    ) : (
                                        <Button disabled>
                                            <Trans>Waiting confirmation</Trans>
                                        </Button>
                                    )
                                ) : (
                                    <Button
                                        loading={pendingNetworksList.has(network.id)}
                                        color="primary"
                                        onClick={() => sendRequest(network.id)}
                                    >
                                        <Trans>Send request</Trans>
                                    </Button>
                                )}
                            </div>
                        </Form.Item>
                    ))}
                    <Form.Item cols={6}>
                        <div className="flex items-center rounded-md border border-gray-600 p-2 px-4">
                            <div className="grow">
                                <Trans>New organization</Trans>
                                <div className="text-sm text-gray-600">
                                    <Trans>Create your organization</Trans>
                                </div>
                            </div>
                            <Button onClick={() => actions.setStep(store.step + 1)} color="success">
                                <Trans>Create</Trans>
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            )}

            <OnboardingLayout.Buttons reverse>
                <Button href="mailto:info@wedo.com">
                    <Trans>Contact us</Trans>
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
