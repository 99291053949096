import { BaseQueryFn, createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { t } from '@lingui/macro';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { ResponseHandler } from '@reduxjs/toolkit/src/query/fetchBaseQuery';
import { isArray, isFunction, isObject, isString } from 'lodash-es';
import { HttpMethod, Id } from '@wedo/types';
import { getAuthToken, removeAuthToken, sleep } from '@wedo/utils';
import { clientVersion } from 'App/store/versionStore';
import { loginRequired, useWebSocketStore } from 'Shared/services/webSocket';
import { ApiError } from 'Shared/types/apiError';
import { errorCode, errorDetailType, errorMessage, errorProperty } from 'Shared/utils/rtkQuery';

export type Tag = {
    type: string;
    id: Id;
};

export interface Identified {
    id: Id;
}

export const listId = 'LIST';

export const resourceId = (type: string, id?: Id): string => (id != null ? `${type}-${id}` : null);

type ConfigureTagType = {
    tagType: string;
    tag: (id: Id) => Tag;
    tags: (result: Identified[]) => Tag[];
};

export const configureTag = (type: string): ConfigureTagType => ({
    tagType: type,
    tag: (id: Id) => ({ type, id }),
    tags: (result: Identified[]) => [...(result ?? []).map(({ id }) => ({ type, id }) as const)],
});

export const p = (strings: Array<string>) => (values: Array<string>) =>
    strings.map((s, i) => s + (values[i] || '')).join('');

export interface BaseQueryArgs {
    url: string | ((values: Array<string>) => string);
    params?: Record<string, unknown>;
    body?: unknown;
    responseHandler?: ResponseHandler;
    validateStatus?: (response: Response, body: unknown) => boolean;
    pathParams?: Array<string>;
    method?: HttpMethod;
}

const transformError = (error: FetchBaseQueryError): ApiError => {
    if (error === undefined) {
        return undefined;
    }

    const apiError = new ApiError(error.data);

    switch (error.status) {
        case 'FETCH_ERROR':
            return apiError
                .setMessage(t`Cannot connect to the server. Please verify your internet connection and try again.`)
                .setTitle(t`No internet connection`)
                .setCode('FETCH_ERROR');
        case 'PARSING_ERROR':
            return apiError
                .setMessage(t`The response from the server cannot be parsed. Please contact your IT department.`)
                .setCode('PARSING_ERROR');
        case 'TIMEOUT_ERROR':
            return apiError
                .setMessage(
                    t`The server took too long to response. Please verify your internet connection and try again.`
                )
                .setCode('TIMEOUT_ERROR');
        default:
            return apiError
                .setCode(errorCode(error))
                .addPath(String(error.status))
                .addPath(errorMessage(error))
                .addPath(errorProperty(error))
                .addPath(errorDetailType(error))
                .setMessage(t`Unexpected error`);
    }
};

export const baseQuery: BaseQueryFn<string | BaseQueryArgs, unknown, FetchBaseQueryError> = async (
    args: BaseQueryArgs & { headers?: (headers: Headers) => Headers },
    api,
    extraOptions
) => {
    let finalUrl;
    if (isString(args)) {
        finalUrl = args;
    } else {
        const { url, pathParams, params } = args;
        if (isFunction(url)) {
            if (pathParams.some((param) => param == null)) {
                return { data: null };
            }
            finalUrl = url(pathParams);
        } else {
            finalUrl = url;
        }
        if (params) {
            finalUrl +=
                '?' +
                new URLSearchParams(
                    Object.entries(params)
                        .map(([key, value]) => {
                            if (value == null) {
                                return [];
                            }
                            if (isArray(value)) {
                                return value.filter(Boolean).map((item) => [`${key}[]`, item]);
                            }
                            if (isObject(value)) {
                                return [[key, JSON.stringify(value)]];
                            }

                            return [[key, value]];
                        })
                        .flat()
                );
        }
    }

    let origin = '';
    if (typeof process === 'object') {
        origin = process?.env?.VITEST === 'true' ? 'http://localhost' : '';
    }
    return fetchBaseQuery({
        baseUrl: finalUrl.startsWith('/') ? origin : `${origin}/api`,
        prepareHeaders: (headers) => {
            headers.set('X-Client-Version', clientVersion());
            const token: string = getAuthToken();
            const socketId = useWebSocketStore.getState().socket?.id;
            if (socketId) {
                headers.set('X-Socket-Id', socketId);
            }
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            if (isFunction(args.headers)) {
                return args.headers(headers);
            }

            return headers;
        },
    })({ url: finalUrl, method: args.method, body: args.body }, api, extraOptions);
};

export const customBaseQuery: BaseQueryFn<string | BaseQueryArgs, unknown, ApiError, { retry: number }> = async (
    args,
    api,
    extraOptions = { retry: 1 }
) => {
    const result = await baseQuery(args, api, extraOptions);
    let returnValue: { data: unknown; meta?: unknown } | { error: ApiError; meta?: unknown };

    if ('error' in result) {
        returnValue = { ...result, error: transformError(result.error) };
    } else {
        returnValue = result;
    }

    if ('data' in returnValue) {
        // no error
        return returnValue;
    }

    if (result.error.status === 401) {
        // token has expired
        removeAuthToken();
        loginRequired();
        return returnValue;
    }

    if (result.error.status === 'FETCH_ERROR' && extraOptions.retry < 3) {
        // if fetch error, then retry in 300ms
        await sleep(300);
        return customBaseQuery(args, api, { ...extraOptions, retry: extraOptions.retry + 1 });
    }

    return returnValue;
};

export const baseApi = createApi({
    baseQuery: customBaseQuery,
    refetchOnReconnect: true,
    endpoints: () => ({}),
});

export const apiErrorLogger: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        // eslint-disable-next-line no-console
        console.error(
            'API ERROR',
            'status:',
            action?.payload?.status,
            'endpointName:',
            action?.meta?.arg?.endpointName,
            'originalArgs:',
            action?.meta?.arg?.originalArgs,
            'payload:',
            action?.payload?.data ?? action?.error
        );
    }
    return next(action);
};

export const fetchBinary = async (url: string, body = {}, method = HttpMethod.Post) => {
    try {
        const result = await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}` },
        });
        return await (result.status === 200 ? result.arrayBuffer() : Promise.reject());
    } catch (e) {
        return e;
    }
};
