import { useCallback, useEffect, useState, useTransition } from 'react';
import { useStore } from 'zustand';
import { waitForAllAnimations } from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import { useGanttContextStore } from './GanttContext';
import { TimelineDependency } from './TimelineDependency';
import { RenderDependencies } from './events';

// FIXME We could use anchor positioning once it's widely available. So we could anchor the bounding box and
//  re-render the arrows when the bounding box resizes.
//  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_anchor_positioning
export const TimelineDependencies = () => {
    const store = useGanttContextStore()!;
    const taskElements = useStore(store, ({ view }) => view.taskElements);
    const dependencies = useStore(store, ({ data }) => data.dependencies);

    const [render, setRender] = useState(0);
    const [, startTransition] = useTransition();

    const reRender = () => {
        startTransition(() => setRender((render) => (render + 1) % 1024));
    };

    const renderDependencies = useCallback(() => {
        requestAnimationFrame(async () => {
            await waitForAllAnimations(Array.from(store.getState().view.taskElements.values()), reRender);
            reRender();
        });
    }, []);

    useEffect(() => {
        reRender();
    }, [taskElements]);

    useEvent(RenderDependencies, renderDependencies, store.getState().eventBus);

    return Array.from(dependencies.values())
        .flatMap((dependencies) => dependencies)
        .map(({ fromId, toId }) => (
            <TimelineDependency
                key={`${fromId}-${toId}-${render}`}
                from={taskElements.get(fromId)}
                to={taskElements.get(toId)}
            />
        ));
};
