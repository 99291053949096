import { FC } from 'react';
import { i18n } from '@lingui/core';
import { ActivityLog } from '@wedo/db';
import { FormatDate, Tag, TagProps } from '@wedo/design-system';
import { useUser } from 'App/store/usersStore';
import { convertWorkspaceEventKey, FilterByModule } from 'Pages/settings/auditTrail/AuditTrailSettingsPage';
import { ChecklistModuleData } from 'Pages/settings/auditTrail/ChecklistModuleData';
import { MeetingModuleData } from 'Pages/settings/auditTrail/MeetingModuleData';
import { OrganizationModuleData } from 'Pages/settings/auditTrail/OrganizationModuleData';
import { TeamModuleData } from 'Pages/settings/auditTrail/TeamModuleData';
import { TemplateModuleData } from 'Pages/settings/auditTrail/TemplateModuleData';
import { UserModuleData } from 'Pages/settings/auditTrail/UserModuleData';
import { WorkspaceModuleData } from 'Pages/settings/auditTrail/WorkspaceModuleData';
import { ActivityLogObject } from 'Shared/types/activityLog';

const objectTypeToString = (activity: ActivityLogObject) => {
    const computedActivity = activity === ActivityLogObject.TEMPLATE ? ActivityLogObject.CHECKLIST : activity;
    return i18n._(FilterByModule.find(({ id }) => id === computedActivity).title);
};

const objectTypeToTagColor: Partial<Record<ActivityLogObject, TagProps['color']>> = {
    [ActivityLogObject.CHECKLIST]: 'green',
    [ActivityLogObject.TEMPLATE]: 'green',
    [ActivityLogObject.MAILING]: 'red',
    [ActivityLogObject.MEETING]: 'orange',
    [ActivityLogObject.NETWORK]: 'blue',
    [ActivityLogObject.ORGANIZATION]: 'blue',
    [ActivityLogObject.TEAM]: 'yellow',
    [ActivityLogObject.USER]: 'purple',
    [ActivityLogObject.WORKSPACE]: 'gray',
} as const;

export const AuditLog: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    const createdBy = useUser(auditLog.createdBy);
    const createdBySpan = <span className="text-xs font-semibold">{createdBy?.full_name}</span>;
    const eventKey = <span className="text-xs text-gray-500">{convertWorkspaceEventKey(auditLog.eventKey)}</span>;
    const infoBlock = (
        <>
            <ChecklistModuleData auditLog={auditLog} />
            <MeetingModuleData auditLog={auditLog} />
            <OrganizationModuleData auditLog={auditLog} />
            <TeamModuleData auditLog={auditLog} />
            <TemplateModuleData auditLog={auditLog} />
            <UserModuleData auditLog={auditLog} />
            <WorkspaceModuleData auditLog={auditLog} />
        </>
    );

    return (
        <div className="flex border rounded-lg shadow-sm p-1.5 justify-between max-w-full gap-2">
            <div className="flex items-start xl:items-center gap-2 flex-col xl:flex-row shrink min-w-0">
                <div className="flex gap-2 items-center shrink-0">
                    <Tag size="xs" className="!bg-gray-200 shrink-0">
                        <FormatDate format="yyyy-MM-dd HH:mm:ss" date={auditLog.createdAt} />
                    </Tag>
                    {createdBySpan}
                </div>

                <div className="shrink-0 flex items-center">{eventKey}</div>

                <div className="xl:p-0 inline-flex shrink truncate">{infoBlock}</div>

                <div className="flex shrink-0 text-xs font-semibold xl:hidden">
                    <Tag size="xs" color={objectTypeToTagColor[auditLog.objectType as ActivityLogObject]}>
                        {objectTypeToString(auditLog.objectType as ActivityLogObject)}
                    </Tag>
                </div>
            </div>

            <div className="display-none hidden xl:flex justify-end items-end shrink-0">
                <Tag size="xs" color={objectTypeToTagColor[auditLog.objectType as ActivityLogObject]}>
                    {objectTypeToString(auditLog.objectType as ActivityLogObject)}
                </Tag>
            </div>
        </div>
    );
};
