import { ReactNode } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Card, RadioGroup, Tooltip } from '@wedo/design-system';
import { SignatureTypeTag } from 'Shared/components/signature/SignatureTypeTag';
import { LegalFramework, SignatureType } from 'Shared/types/signature';

export const signatureTypes: Map<
    string,
    {
        acronym: ReactNode;
        name: ReactNode;
        description: ReactNode;
    }
> = new Map([
    [
        'SES',
        {
            acronym: <Trans>SES</Trans>,
            name: <Trans>Simple electronic signature</Trans>,
            description: <Trans>Free and non legally binding</Trans>,
        },
    ],
    [
        'AES',
        {
            acronym: <Trans>AES</Trans>,
            name: <Trans>Advanced electronic signature</Trans>,
            description: <Trans>Higher legal weight than SES</Trans>,
        },
    ],
    [
        'QES',
        {
            acronym: <Trans>QES</Trans>,
            name: <Trans>Qualified electronic signature</Trans>,
            description: <Trans>Highest legal weight, equal to a handwritten signature by EU and Swiss law</Trans>,
        },
    ],
]);

export const legalFrameworks = new Map([
    [
        'ESigA',
        {
            icon: 'ch.svg',
            text: <Trans>Swiss law (ESigA)</Trans>,
        },
    ],
    [
        'eIDAS',
        {
            icon: 'eu.svg',
            text: <Trans>EU law (eIDAS)</Trans>,
        },
    ],
]);

export const SignatureTypePicker = ({
    onTypeChange,
    onLegalFrameworkChange,
    signatureType,
    legalFramework,
    readonly,
}: {
    onTypeChange: (value: SignatureType) => void;
    onLegalFrameworkChange: (value: LegalFramework) => void;
    signatureType?: SignatureType;
    legalFramework?: LegalFramework;
    readonly?: boolean;
}) => {
    const handleTypeChange = (value: SignatureType) => {
        onTypeChange(value);
    };
    return (
        <div className={'flex flex-col gap-2'}>
            <div className={'font-medium text-sm'}>{t`Signature type`}</div>
            {readonly ? (
                <Card className={'py-2 px-4'}>
                    <div className={'flex gap-2 justify-between items-center'}>
                        <div>
                            <div className={'text-sm'}>{signatureTypes.get(signatureType).name}</div>
                            <div className={'text-xs flex items-center gap-1 text-gray-500'}>
                                <img
                                    className="h-3"
                                    alt=""
                                    src={`/assets/flag/${legalFrameworks.get(legalFramework)?.icon}`}
                                />
                                {legalFrameworks.get(legalFramework)?.text}
                            </div>
                        </div>
                        <SignatureTypeTag type={signatureType} />
                    </div>
                </Card>
            ) : (
                <>
                    <RadioGroup
                        className={'w-full'}
                        radioType={'buttonGroup'}
                        value={legalFramework}
                        name={'legal-framework'}
                        onChange={onLegalFrameworkChange}
                    >
                        {['ESigA', 'eIDAS'].map((key) => (
                            <RadioGroup.Radio disabled={key === 'eIDAS'} className={'flex-1'} key={key} value={key}>
                                <Tooltip
                                    className={'w-72'}
                                    placement={'top'}
                                    content={
                                        key === 'eIDAS'
                                            ? t`If you want to add an EU-compliant signature to your document please contact us`
                                            : undefined
                                    }
                                >
                                    <div className={'flex items-center gap-2 w-full justify-center'}>
                                        <div className={'flex justify-center w-8'}>
                                            <img
                                                className="h-4"
                                                alt=""
                                                src={`/assets/flag/${legalFrameworks.get(key)?.icon}`}
                                            />
                                        </div>
                                        <div>{legalFrameworks.get(key).text}</div>
                                    </div>
                                </Tooltip>
                            </RadioGroup.Radio>
                        ))}
                    </RadioGroup>
                    <RadioGroup
                        className={'mb-2'}
                        radioType={'panel'}
                        value={signatureType}
                        name={'signature-type'}
                        onChange={handleTypeChange}
                    >
                        {['SES', 'AES', 'QES'].map((key) => (
                            <RadioGroup.Radio key={key} value={key} disabled={key === 'AES'}>
                                <Tooltip
                                    content={
                                        key === 'AES'
                                            ? t`If you want to add an AES signature to your document please contact us`
                                            : ''
                                    }
                                >
                                    <div className={'flex gap-2 justify-between items-center'}>
                                        <div>
                                            <div>{signatureTypes.get(key).name}</div>
                                            <div
                                                className={clsx(
                                                    'text-xs',
                                                    signatureType === key ? 'text-gray-600' : 'text-gray-500'
                                                )}
                                            >
                                                {signatureTypes.get(key).description}
                                            </div>
                                        </div>
                                        <SignatureTypeTag type={key} />
                                    </div>
                                </Tooltip>
                            </RadioGroup.Radio>
                        ))}
                    </RadioGroup>
                </>
            )}
        </div>
    );
};
