import { useContext, useEffect, useRef, useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import { DateContext } from '~/components/FormatDate/DateContext';
import { DateLike } from '~/components/FormatDate/FormatDateRelative';

/**
 * Performs the argument calculation periodically and returns the result as
 * React state. The frequency of the calculation depends on the difference
 * between the `date` argument and the `baseDate` (default: current date).
 * When the date is close to now, the `calculation` recalculates more
 * frequently. At most calls `calculation` about once per second. If
 * `baseDate` is provided, the value is only calculated once and never update.
 */
export function useDateFunction<T>(
    date: DateLike,
    calculation: (date: Date, baseDate: Date, locale?: Locale) => T,
    opts: { baseDate?: DateLike; locale?: Locale } = {}
): T {
    const context = useContext(DateContext);
    const currDate = useRef(date);
    const calculateValue = (): T =>
        calculation(
            new Date(currDate.current),
            new Date(opts.baseDate || context.baseDate || new Date()),
            opts.locale || context.locale
        );
    const [value, setValue] = useState(() => calculateValue());
    const timer = useRef<NodeJS.Timeout>();
    currDate.current = date;

    const delay = (date: DateLike): number => {
        const baseDate = opts.baseDate || context.baseDate || new Date();
        const seconds = Math.abs(differenceInSeconds(new Date(date), new Date(baseDate)));
        return seconds < 30 ? 1000 : seconds < 120 ? 2000 : 60000;
    };

    const scheduleTimer = (): void => {
        setValue(calculateValue());
        timer.current = setTimeout(scheduleTimer, delay(date));
    };

    useEffect(() => {
        if (opts.baseDate || context.baseDate) {
            return null;
        }
        scheduleTimer();
        return () => clearTimeout(timer.current!);
    }, []);

    return value;
}
