import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import { Dialog, Transition } from '@headlessui/react';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import {
    ComponentPropsWithoutRef,
    forwardRef,
    Fragment,
    MutableRefObject,
    RefObject,
    useImperativeHandle,
} from 'react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { ModalContext, useModalContext } from './ModalContext';

const classes = {
    base: 'relative transform transition-all w-full',
    size: {
        sm: 'sm:max-w-md sm:my-8',
        md: 'sm:max-w-2xl sm:my-8',
        lg: 'sm:max-w-4xl sm:my-8',
        xl: 'sm:max-w-7xl sm:my-8',
        full: 'w-full h-[calc(100vh_-_2rem)] sm:h-screen',
    },
    modalBody: {
        base: 'rounded-lg bg-white text-left',
        full: 'flex flex-col bg-white text-left w-full h-[calc(100vh_-_2rem)] sm:h-screen rounded-lg sm:rounded-none',
    },
};

const ModalHeader: FC<PropsWithChildren<{ title: ReactNode; className?: string }>> = ({
    title,
    children,
    className,
}) => {
    const { onClose } = useModalContext();
    return (
        <div className={clsx('flex items-center justify-between py-4 pl-5 pr-4', className)}>
            <div className="flex-1 overflow-x-hidden">
                <Dialog.Title className="truncate text-lg font-medium leading-6 text-gray-900">{title}</Dialog.Title>
                {children}
            </div>
            <div className="ml-auto shrink-0">
                <div>
                    <button
                        type="button"
                        aria-label="Close"
                        className={
                            'inline-flex rounded-md p-1.5 text-gray-700 hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-offset-2 focus-visible:ring-2'
                        }
                        onClick={onClose}
                    >
                        <span className="sr-only">Dismiss</span>
                        <FontAwesomeIcon icon={faXmark} className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
};

const ModalBody: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
    <div className={clsx('max-h-full flex-1 overflow-y-auto px-4 pb-2 pt-1 md:px-10 md:pb-8 md:pt-6', className)}>
        {children}
    </div>
);

const ModalFooter: FC<PropsWithChildren<{ className?: string; autoPlaceChildren?: boolean }>> = ({
    children,
    className = '',
    autoPlaceChildren = true,
}) => (
    <div
        className={clsx(
            autoPlaceChildren && 'flex flex-col-reverse flex-wrap justify-end gap-3 sm:flex-row',
            'rounded-b-lg bg-gray-50 px-4 py-3 md:px-6 ',
            className
        )}
    >
        {children}
    </div>
);

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'full';

export type CloseSource = 'backdrop-or-esc' | 'api' | 'cross' | string;

export type ModalHandle = { close: (source?: CloseSource) => Promise<void> };

export type ModalProps = {
    onBeforeClose?: (source?: CloseSource) => Promise<boolean>;
    onClose?: () => void;
    onAfterEnter?: () => void;
    onBeforeLeave?: () => void;
    onAfterClose?: () => void;
    open?: boolean;
    size?: ModalSize;
    initialFocus?: MutableRefObject<HTMLElement | null> | undefined;
    position?: string;
    className?: string;
    panelClassName?: string;
    // Props that can be passed by the modals store, you could ignore them if you directly use the Modal
    modalRef?: RefObject<ModalHandle>;
    close?: (source?: CloseSource) => Promise<void>;
} & PropsWithChildren<ComponentPropsWithoutRef<'div'>>;

const ModalComponent = forwardRef<ModalHandle, ModalProps>(
    (
        {
            children,
            open,
            size = 'md',
            onBeforeClose = () => Promise.resolve(true),
            onClose,
            onAfterEnter,
            onBeforeLeave,
            onAfterClose,
            modalRef,
            // We extract the close prop to avoid passing it to the dialog, but it's useless to the Modal
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            close,
            position = 'items-start',
            panelClassName = '',
            className = '',
            ...props
        },
        ref
    ) => {
        const handleClose = (source: CloseSource) => () =>
            onBeforeClose(source)
                .catch(() => false)
                .then((close) => close && onClose?.());

        useImperativeHandle(modalRef ?? ref, () => ({
            close: (source: CloseSource = 'api') => handleClose(source)(),
        }));

        return (
            <ModalContext.Provider value={{ onClose: handleClose('cross') }}>
                <Transition
                    appear
                    show={open}
                    as={Fragment}
                    afterEnter={onAfterEnter}
                    beforeLeave={onBeforeLeave}
                    afterLeave={onAfterClose}
                >
                    <Dialog
                        className={clsx('relative z-40', className)}
                        onClose={handleClose('backdrop-or-esc')}
                        {...props}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>
                        <div className={clsx('fixed inset-0', size !== 'full' && 'overflow-y-auto')}>
                            <div className={clsx('flex min-h-full justify-center p-4 sm:p-0', position)}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-200"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className={clsx(classes.base, classes.size[size])}>
                                        <div
                                            className={clsx(
                                                size === 'full' ? classes.modalBody.full : classes.modalBody.base,
                                                panelClassName
                                            )}
                                        >
                                            {children}
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </ModalContext.Provider>
        );
    }
);

ModalComponent.displayName = 'Modal';
ModalHeader.displayName = 'Modal.Header';
ModalBody.displayName = 'Modal.Body';
ModalFooter.displayName = 'Modal.Footer';

export const Modal = Object.assign(ModalComponent, { Header: ModalHeader, Body: ModalBody, Footer: ModalFooter });
