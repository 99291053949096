import React, { ReactElement, useRef } from 'react';
import { faPeopleGroup } from '@fortawesome/pro-duotone-svg-icons';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Alert, Button, EmptyState, Skeleton, useModal } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { Can } from 'Shared/components/Can';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { PageHeader } from 'Shared/components/PageHeader';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { StatusRow } from 'Shared/components/displayPopover/StatusRow';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { TeamGridCard } from 'Shared/components/team/TeamGridCard';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useGetTeamsWithFilterQuery } from 'Shared/services/team';
import { EntityStatus } from 'Shared/types/workspace';
import { Permission } from 'Shared/utils/rbac';

const Tabs: Array<NavBarTab> = [
    {
        isDefault: true,
        to: { searchParams: { view: 'my_teams' } },
        matchSearchParams: ['view'],
        title: msg`My teams`,
    },
    {
        to: { searchParams: { view: 'not_member' } },
        matchSearchParams: ['view'],
        title: msg`Not member`,
    },
    {
        to: { searchParams: { view: 'all' } },
        matchSearchParams: ['view'],
        title: msg`All`,
    },
];

const TeamsPageSearchParams = {
    view: enumeration('my_teams', 'not_member', 'all').default('my_teams'),
    status: enumeration('open', 'deleted').default('open'),
    search: string(),
};

const TeamsInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const [{ view, status, search }] = useSearchParams(TeamsPageSearchParams);

    const { data, error, isError, isLoading, refetch } = useGetTeamsWithFilterQuery({
        filter: view,
        page: Math.ceil((offset + 1) / limit),
        pageSize: limit,
        status,
        search,
    });

    updateInfiniteScroll(data?.data);

    return (
        <>
            {error != null ? (
                <RetryComponent retryFunction={refetch} className="col-span-full" />
            ) : isLoading ? (
                <Skeleton count={5} className={'h-72'} />
            ) : isError ? (
                <Alert type="danger" title={error?.message ?? ''} className="col-span-full" />
            ) : (
                data.data.map((team) => <TeamGridCard key={team.id} team={team} />)
            )}
        </>
    );
};

const statusToText: Record<EntityStatus, ReactElement> = {
    open: <Trans id="Open teams">Open</Trans>,
    deleted: <Trans id="Deleted teams">Deleted</Trans>,
    archived: <Trans id="Archived ">Archived</Trans>,
};

export const TeamsPage = () => {
    const { open } = useModal();

    usePageTitle(t`Teams`);

    const [{ search, view, status }, setSearchParams] = useSearchParams(TeamsPageSearchParams);

    const handleStatusChange = (status: EntityStatus) => setSearchParams({ view, status });

    const toolbarRef = useRef<HTMLDivElement>(null);

    const handleSearch = (search: string) => {
        setSearchParams((searchParams) => ({ ...searchParams, search }), { replace: true });
    };

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: handleSearch,
        search: search,
        size: 'md',
    });

    return (
        <Can permission={Permission.ViewTeams} showNoAccess>
            <>
                <PageHeader title={t`Teams`} />
                <div className="grid-rows-header-content grid h-full max-h-full gap-6 overflow-hidden ">
                    <div className="flex flex-col gap-2 px-6" ref={toolbarRef}>
                        <NavBar basePath="/teams" tabs={Tabs}>
                            {toggleButton}
                            <StatusRow
                                status={status}
                                onStatusChange={(status) => handleStatusChange(status)}
                                statusToText={statusToText}
                                allowedStatus={['open', 'deleted']}
                            />

                            <Can permission={Permission.AddTeam}>
                                <Button
                                    title={t`Add team`}
                                    tooltipClassName="flex lg:hidden"
                                    icon={faAdd}
                                    color="primary"
                                    onClick={() => open(AddTeamModal)}
                                >
                                    <span className="hidden lg:flex">
                                        <Trans>Add team</Trans>
                                    </span>
                                </Button>
                            </Can>
                        </NavBar>
                        {searchInput}
                    </div>

                    <InfiniteScroll
                        key={view}
                        className="scrollbar-light grid auto-rows-min grid-cols-1 gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(22rem,1fr))]"
                        page={TeamsInfiniteScrollPage}
                        emptyPage={
                            <EmptyState size="lg" icon={faPeopleGroup} className="col-span-full">
                                <EmptyState.Text>
                                    {isEmpty(search) ? <Trans>No teams</Trans> : <Trans>No teams found</Trans>}
                                </EmptyState.Text>
                            </EmptyState>
                        }
                        size={20}
                    />
                </div>
            </>
        </Can>
    );
};
