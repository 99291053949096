import React, { ChangeEvent, ReactElement } from 'react';
import { Checkbox, Label } from '@wedo/design-system';

type CheckboxItemProps = {
    name: string;
    label: string | ReactElement;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
};

export const CheckboxItem = ({ name, label, isChecked, onChange }: CheckboxItemProps) => {
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.checked);

    return (
        <div className="relative flex items-start">
            <div className="flex h-5 items-center">
                <Checkbox id={name} name={name} checked={isChecked} onChange={handleChange} />
            </div>
            <div className="ml-3 text-sm">
                <Label htmlFor={name} color="text-black" inputType="inline">
                    {label}
                </Label>
            </div>
        </div>
    );
};
