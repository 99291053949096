import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faSync, faWifiSlash } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '@wedo/design-system';

type RetryComponentProps = {
    retryFunction: () => void;
    message?: JSX.Element;
    className?: string;
};

export const RetryComponent = ({ retryFunction, message, className = '' }: RetryComponentProps) => {
    return (
        <div className={clsx('flex h-full w-full flex-col items-center justify-center gap-4', className)}>
            <h2 className="text-7xl font-extrabold text-gray-500">
                <FontAwesomeIcon icon={faWifiSlash} />
            </h2>
            <p className="text-xl font-semibold text-gray-700">
                {message ? message : t`Oops. We couldn't fetch your data from the server.`}
            </p>
            <div className="flex justify-center gap-2">
                <Button color="primary" size="lg" icon={faSync} onClick={retryFunction}>
                    {t`Retry`}
                </Button>
            </div>
        </div>
    );
};
