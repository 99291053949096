import { isEqual } from 'lodash-es';
import { Element, Operation } from 'slate';
import { Id } from '@wedo/types';
import { isSoftDeleteOperation } from '../../utils/operation';

const keepIdAndOrder = (items: { id: Id; order: number }[]) => items?.map(({ id, order }) => ({ id, order })) ?? [];

export const computeChanges = (previousBlocks: Element[], nextBlocks: Element[], operations: Operation[]) => {
    const addedBlocks = nextBlocks
        .filter(
            (nextBlock) => !previousBlocks.some((block) => block.id === nextBlock.id) && nextBlock?.type !== 'hidden'
        )
        .map(
            ({
                id,
                type,
                attachments,
                children,
                task_id,
                vote_id,
                reference_vote_id,
                vote_type,
                vote_options,
                order,
                decoration,
            }) => ({
                id,
                type,
                attachments,
                children,
                task_id,
                vote_id,
                reference_vote_id,
                vote_type,
                vote_options,
                order,
                decoration,
            })
        );

    const updatedBlocks = nextBlocks
        .map(({ id, type, attachments, children, decoration, order }) => {
            const previousBlock = previousBlocks.find((block) => block.id === id);
            if (previousBlock != null) {
                const changes = {};
                if (type !== previousBlock.type) {
                    Object.assign(changes, { type });
                }
                const attachmentIds = keepIdAndOrder(attachments);
                if (!isEqual(attachmentIds, keepIdAndOrder(previousBlock.attachments))) {
                    Object.assign(changes, { attachments: attachmentIds });
                } else if (!isEqual(attachments ?? [], previousBlock.attachments ?? [])) {
                    Object.assign(changes, {
                        attachments: attachments.map(({ id, order }: { id: Id; order: number }) => {
                            return { id, order };
                        }),
                    });
                }

                if (!isEqual(children ?? [], previousBlock.children ?? [])) {
                    Object.assign(changes, { children });
                }
                if (order != null && order !== previousBlock.order) {
                    Object.assign(changes, { order });
                }
                if (decoration !== previousBlock.decoration) {
                    Object.assign(changes, { decoration: decoration === undefined ? null : decoration });
                }
                if (Object.keys(changes).length > 0) {
                    return { id, changes };
                }
            }
            return null;
        })
        .filter(Boolean);

    const softDeletedBlocks = operations.filter(isSoftDeleteOperation).map((operation) => operation.id);

    const deletedBlocks = previousBlocks
        .filter((previousBlock) => !nextBlocks.some((nextBlock) => nextBlock.id === previousBlock.id))
        .map((previousBlock) => previousBlock.id);

    return {
        addedBlocks,
        updatedBlocks,
        softDeletedBlocks,
        deletedBlocks,
    };
};
