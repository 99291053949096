import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Button, ContextModalProps, Modal, Textarea, useConfirm } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { AutolinkerWrapper } from 'Shared/components/AutlinkerWrapper';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useTask } from 'Shared/hooks/useTask';
import { TaskType } from 'Shared/types/task';

type DescriptionModalProps = { taskId: Id } & ContextModalProps & PropsWithChildren;

export const TaskDescriptionModal = ({ taskId, children, ...modalProps }: DescriptionModalProps) => {
    const descriptionInputRef = useRef<HTMLTextAreaElement>();

    const { confirm } = useConfirm();
    const { task, isTaskReadonly, handleTaskUpdate, isUpdating, updateError } = useTask(taskId);

    const [newDescription, setNewDescription] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (isEmpty(newDescription) && !isEmpty(task.description)) {
            setNewDescription(task.description);
        }
    }, [task.description]);

    const handleUpdate = async () => {
        const result = await handleTaskUpdate({ description: newDescription });
        if ('data' in result) {
            await modalProps.close();
        }
    };

    const handleBeforeClose = async (source: string) => {
        if (['cancel', 'backdrop-or-esc', 'cross'].includes(source)) {
            if (newDescription === task.description) {
                return true;
            }
            return confirm({}, ConfirmDiscardChangesModal);
        }
        return true;
    };

    useEffect(() => {
        if (isEditing) {
            descriptionInputRef.current.focus();
        }
    }, [isEditing]);

    return (
        <Modal size={'lg'} {...modalProps} onBeforeClose={handleBeforeClose}>
            <Modal.Header title={task?.name} />
            <Modal.Body>
                <div className="relative">
                    {/* setting the height to 1px ensures height: 100% on the children works*/}
                    {isEditing ? (
                        <Textarea
                            debounce
                            ref={descriptionInputRef}
                            rows={10}
                            wrapperClassName={'min-h-[400px] !px-3 !py-2.5 -ml-px -mt-px h-full w-full'}
                            className={'!text-base'}
                            disabled={isTaskReadonly}
                            placeholder={
                                task?.type === TaskType.Task
                                    ? t`Add a description to the task...`
                                    : t`Add a description to the milestone...`
                            }
                            value={newDescription}
                            onBlur={(e) => {
                                setIsEditing(false);
                                setNewDescription(e.target.value);
                            }}
                            onChange={(e) => setNewDescription(e.target.value)}
                            status={updateError ? 'error' : 'default'}
                            statusText={updateError?.message ?? ''}
                        />
                    ) : (
                        <div
                            aria-label={t`Edit description`}
                            onClick={() => !isTaskReadonly && setIsEditing(true)}
                            className={clsx(
                                '-mt-px -ml-px border-1 min-h-[400px] w-full border border-transparent px-3 py-2.5 text-left enabled:hover:bg-gray-200 disabled:opacity-60',
                                isTaskReadonly ? 'cursor-not-allowed text-gray-500' : 'rounded hover:border-gray-200'
                            )}
                        >
                            <div className={'whitespace-pre-wrap'}>
                                <AutolinkerWrapper
                                    text={
                                        newDescription ||
                                        (task?.type === TaskType.Task
                                            ? t`Add a description to the task...`
                                            : t`Add a description to the milestone...`)
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => modalProps.close('cancel')}>
                    {task.description === newDescription ? t`Close` : t`Cancel`}
                </Button>
                {!isTaskReadonly && (
                    <Button loading={isUpdating} color="primary" onClick={handleUpdate}>{t`Save`}</Button>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
