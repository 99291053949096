import { Trans as TransReact } from '@lingui/react';
import { FC } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { RadioGroup } from '@wedo/design-system';
import { ChargebeeFrequency } from '@wedo/types';

const FrequencyOptions: Array<{ id: ChargebeeFrequency; label: MessageDescriptor }> = [
    { id: 'monthly', label: msg`Monthly` },
    { id: 'yearly', label: msg`Yearly` },
];

type FrequencySelectorRadioGroupProps = {
    value: ChargebeeFrequency;
    onChange: (value: ChargebeeFrequency) => void;
    isOptionDisabled: (value: ChargebeeFrequency) => boolean;
};

export const FrequencySelectorRadioGroup: FC<FrequencySelectorRadioGroupProps> = ({
    value,
    onChange,
    isOptionDisabled = () => false,
}) => {
    return (
        <RadioGroup value={value} name={'frequency'} onChange={onChange} radioType="buttonGroup" className="mt-1">
            {FrequencyOptions.map((option) => (
                <RadioGroup.Radio key={option.id} value={option.id} disabled={isOptionDisabled(option.id)}>
                    <TransReact id={option.label.id} />
                </RadioGroup.Radio>
            ))}
        </RadioGroup>
    );
};
