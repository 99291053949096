import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    faEllipsisVertical,
    faPlus,
    faPencil,
    faTrash,
    faAngleDown,
    faAngleRight,
    faWandMagicSparkles,
    faPalette,
} from '@fortawesome/pro-regular-svg-icons';
import { Plural, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { useStore } from 'zustand';
import { Tag, Dropdown, useConfirm, useModal, Button, colorPickerColors } from '@wedo/design-system';
import { OrganizeModal } from 'Pages/TasksPage/components/OrganizeModal/OrganizeModal';
import { trpc } from 'Shared/trpc';
import { AddUpdateSectionModal } from './AddUpdateSectionModal';
import { useGanttContextStore } from './GanttContext';
import { type GanttPageParams } from './GanttPage';
import { ListDragHandle } from './ListDragHandle';
import { type SectionWithTasks } from './types';
import { useHover } from './useHover';
import { durationInDays } from './utils';

type ListSectionProps = {
    section: SectionWithTasks;
    wbs: string;
};

export const ListSection = ({ section, wbs }: ListSectionProps) => {
    const { workspaceId } = useParams<GanttPageParams>();

    const { open: openModal } = useModal();
    const { confirm } = useConfirm();

    const store = useGanttContextStore()!;
    const isOpen = useStore(store, ({ view }) => !view.closedSections.has(section.id));
    const sectionMinDate = useStore(store, ({ data }) => data.dateWindows.get(section.id)?.minDate);
    const sectionMaxDate = useStore(store, ({ data }) => data.dateWindows.get(section.id)?.maxDate);

    const closedSectionsRef = useRef(new Set<string>());

    const duration = useMemo(() => durationInDays(sectionMinDate, sectionMaxDate), [sectionMinDate, sectionMaxDate]);

    const { mutateAsync: updateSection, isPending: isUpdatingSection } = trpc.workspace.updateSection.useMutation();
    const { mutateAsync: removeSection, isPending: isRemovingSection } = trpc.workspace.removeSection.useMutation();

    const handleToggleSection = (sectionId: string) => () => {
        store.setState((state) => {
            if (!state.view.closedSections.delete(sectionId)) {
                state.view.closedSections.add(sectionId);
            }
        });
    };

    const handleAddSection = () => {
        openModal(AddUpdateSectionModal, { eventBus: store.getState().eventBus, workspaceId, order: section.order });
    };

    const handleOrganize = () => {
        openModal(OrganizeModal, {
            tasks: section.tasks
                .filter((task) => !task.completed)
                .map((task) => ({ ...task, workspaces: [{ id: workspaceId }] })),
            workspaceId,
            isInGanttContext: true,
        });
    };

    const handleUpdateSection = (property: string) => () => {
        openModal(AddUpdateSectionModal, { eventBus: store.getState().eventBus, workspaceId, section, property });
    };

    const handleRemoveSection = async () => {
        const canRemoveSection = await confirm({
            type: 'danger',
            title: (
                <div>
                    <Trans>
                        Remove section <b>{section.name}</b>?
                    </Trans>
                </div>
            ),
            content: t`Tasks will be moved to "No section".`,
            cancelText: t`No, cancel`,
            confirmText: t`Yes, remove section`,
        });
        if (canRemoveSection) {
            await removeSection({ sectionId: section.id });
            store.getState().eventBus.dispatchRenderDependenciesEvent();
        }
    };

    const handleDragStart = () => {
        store.setState((state) => {
            closedSectionsRef.current = new Set(state.view.closedSections);
            state.view.closedSections = new Set(state.data.sections!.map(({ id }) => id));
        });
    };

    const handleDragEnd = () => {
        store.setState((state) => {
            state.view.closedSections = new Set(closedSectionsRef.current);
        });
    };

    const handleColorUpdate = (color: string) => async () => {
        await updateSection({ sectionId: section.id, color });
    };

    return (
        <div
            className="grid grid-cols-subgrid col-span-4 group"
            data-section-id={section.id !== '' ? section.id : undefined}
            data-order={section.id !== '' ? section.order : undefined}
            {...useHover(`section-${section.id}`)}
        >
            <div
                className="font-semibold px-2 tabular-nums flex items-center border-r-4 min-w-[calc(3.125rem+1px)]"
                style={{ borderColor: section.color }}
            >
                {section.id !== '' && (
                    <ListDragHandle type="section" onDragStart={handleDragStart} onDragEnd={handleDragEnd} />
                )}
                <span className={clsx(section.id !== '' && 'group-hover:hidden block')}>{wbs}</span>
            </div>
            <div className="font-semibold flex items-center overflow-hidden py-1 pr-2 relative flex-1">
                <Button
                    variant="ghost"
                    size="xs"
                    icon={isOpen ? faAngleDown : faAngleRight}
                    onClick={handleToggleSection(section.id)}
                    className="shrink-0 !w-7 flex justify-center"
                />
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">{section.name ?? t`No section`}</span>
            </div>
            <div className="border-r border-gray-200 flex items-center justify-center">
                {section.id !== '' ? (
                    <Dropdown variant="ghost" size="sm" icon={faEllipsisVertical}>
                        <Dropdown.Item icon={faPlus} onClick={handleAddSection}>
                            <Trans>Add section</Trans>
                        </Dropdown.Item>
                        <Dropdown.DividerItem />
                        {section.tasks.filter((task) => !task.completed).length > 0 && (
                            <Dropdown.Item
                                icon={faWandMagicSparkles}
                                onClick={handleOrganize}
                                loading={isRemovingSection}
                            >
                                <Trans>Organize</Trans>
                            </Dropdown.Item>
                        )}
                        <Dropdown.SubMenu icon={faPalette} label={t`Choose color`} disabled={isUpdatingSection}>
                            {colorPickerColors.map(([key, { name, value }]) => (
                                <Dropdown.Item key={key} onClick={handleColorUpdate(value)}>
                                    <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: value }} />
                                    {name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.SubMenu>
                        <Dropdown.Item
                            icon={faPencil}
                            onClick={handleUpdateSection('name')}
                            loading={isRemovingSection}
                        >
                            <Trans>Rename section</Trans>
                        </Dropdown.Item>
                        <Dropdown.DividerItem />
                        <Dropdown.Item icon={faTrash} danger onClick={handleRemoveSection} loading={isRemovingSection}>
                            <Trans>Delete section</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                ) : (
                    <Tag size="xs" color="gray">
                        {section.tasks.length}
                    </Tag>
                )}
            </div>
            <div className="font-semibold relative px-2 flex items-center text-gray-500">
                {sectionMinDate != null && sectionMaxDate != null && duration > 0 && (
                    <Plural value={duration} one={`${duration} day`} other={`${duration} days`} />
                )}
            </div>
        </div>
    );
};
