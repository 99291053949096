import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Alert,
    Card,
    Label,
    Spinner,
    Switch,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { UpgradeToEnterpriseModal } from 'Shared/components/billing/UpgradeToEnterpriseModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useCurrentOrganisation } from 'Shared/hooks/useCurrentOrganisation';
import { useUpdateNetworkMutation } from 'Shared/services/network';

export const Force2faCard = () => {
    const { show } = useNotification();
    const { open } = useModal();
    const { isEnterprise, isLoading: isSubscriptionLoading } = useCurrentOrganisation();

    const { network } = useCurrentNetwork();
    const [updateNetwork, { isLoading: isUpdateNetworkLoading }] = useUpdateNetworkMutation();
    const [force2faToggle, setForce2faToggle] = useState<boolean>(false);

    const isLoading = isSubscriptionLoading || isUpdateNetworkLoading;

    useEffect(() => {
        setForce2faToggle(network?.force_2fa || false);
    }, [network]);

    const handleToggle = async (value: boolean) => {
        if (!(await isEnterprise())) {
            open(UpgradeToEnterpriseModal, {
                content: (
                    <Trans>
                        To enable <b>two-factor authentication enforcing</b> in your organization you need to upgrade to
                        the enterprise subscription model
                    </Trans>
                ),
            });
            return;
        }
        const response = await updateNetwork({ config: { force_2fa: value } });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return;
        }
        setForce2faToggle(true);
    };

    return (
        <Card className="mt-6">
            <Card.Header
                className={clsx(!force2faToggle && 'rounded-b-lg')}
                title={t`Enforce two-factor authentication`}
                description={t`All users in your organization must enable two-factor authentication`}
                actions={
                    <Switch.Group>
                        <div className="flex items-center">
                            {isLoading && (
                                <Spinner className="mr-2 h-5 w-5 animate-spin fill-blue-800 text-blue-800 text-opacity-20" />
                            )}
                            <Switch checked={force2faToggle} onChange={(value) => handleToggle(value)} />
                        </div>
                    </Switch.Group>
                }
            />

            {force2faToggle && (
                <Card.Body>
                    <Alert
                        type="info"
                        title={t`Each user from your organization will have to enable two-factor authentication the next time they log in.`}
                    />
                    {network?.isSaml && (
                        <Alert
                            type="warning"
                            className="mt-2"
                            title={t`This requirement won't be enforced on users that log in with SAML authentication. Enforcing 2FA for SAML users must be implemented on the identity provider (IDP) side.`}
                        />
                    )}
                    <Label className="mt-4">
                        <Trans>
                            You can verify who enabled 2FA from the user management{' '}
                            <Link to="/settings/users" className="text-blue-500 hover:text-blue-700">
                                user management
                            </Link>{' '}
                            page.
                        </Trans>
                    </Label>
                </Card.Body>
            )}
        </Card>
    );
};
