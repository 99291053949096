import React, { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Form, Input, Modal } from '@wedo/design-system';
import { useCheckPasswordMutation, useDisableTotpMutation } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

export const DisableTotpModal: FC<ContextModalProps> = ({ close, ...modalProps }) => {
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [checkPassword, { isLoading: isLoadingCheck }] = useCheckPasswordMutation();
    const [disableTotp, { isLoading: isLoadingDisable }] = useDisableTotpMutation();

    const handleSubmit = async () => {
        const result = await checkPassword(password);

        if ('error' in result) {
            let error = '';

            switch (errorMessage(result.error)) {
                case 'Password wrong':
                    error = t`Invalid password`;
                    break;
                case 'TypeError: Failed to fetch':
                    error = t`Network error`;
                    break;
                default:
                    error = t`Unexpected error`;
            }

            setError(error);
            return;
        }

        const disableResult = await disableTotp(password);

        if ('data' in disableResult) {
            void close();
        } else if ('error' in disableResult) {
            let error = t`Unexpected error`;

            if (errorMessage(disableResult.error) === 'TypeError: Failed to fetch') {
                error = t`Network error`;
            }

            setError(error);
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Two-factor authentication`} />
            <Modal.Body>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <Form.Item htmlFor={`password`} label={t`Enter your password`}>
                        <Input
                            id={'password'}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            status={error === '' ? 'default' : 'error'}
                            statusText={error}
                        />
                    </Form.Item>
                </Form>
                <Alert type={'warning'} title={t`Disabling 2FA`} className="mt-4">
                    <Trans>Once disabled, only your email and password will be needed to log in to your account.</Trans>
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="danger" loading={isLoadingCheck || isLoadingDisable} onClick={handleSubmit}>
                    <Trans>Disable two-factor authentication</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
