export type Schema<T> = {
    default: (value: T) => Schema<T>;
    validate: (value: string[]) => T;
    options: (options: Record<string, unknown>) => Schema<T>;
};

export const schema = <T>(): Schema<T> => ({
    default(value: T) {
        this.defaultValue = value;
        return this;
    },
    validate() {
        return this.defaultValue;
    },
    options(options: Record<string, unknown>) {
        this.optionValues = options;
        return this;
    },
});

export const string = (): Schema<string> => ({
    ...schema<string>(),
    validate(value: string[]) {
        return value[0] ?? this.defaultValue;
    },
});

export const enumeration = <T extends string[]>(...values: T): Schema<(typeof values)[number]> => ({
    ...schema<(typeof values)[number]>(),
    validate(value: string[]) {
        return values.includes(value[0])
            ? value[0]
            : this.optionValues?.allowUnknown
            ? value[0] ?? this.defaultValue
            : this.defaultValue;
    },
});

export const number = (): Schema<number> => ({
    ...schema<number>(),
    validate(value: string[]) {
        const parsed = parseFloat(value[0]);
        return !Number.isNaN(parsed) ? parsed : this.defaultValue;
    },
});

export const boolean = (): Schema<boolean> => ({
    ...schema<boolean>(),
    validate(value: string[]) {
        return value[0] === 'true' || value[0] === '1' || value[0] === '' || this.defaultValue;
    },
});

export const array = <T>(itemSchema: Schema<T>): Schema<T[]> => ({
    ...schema<T[]>(),
    validate(value: string[]) {
        if (this.defaultValue != null && value.length === 0) {
            return this.defaultValue;
        }

        return value.map((value) => itemSchema.validate([value])).filter((value) => value != null);
    },
});
