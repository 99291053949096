import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { ButtonVariant, Popover } from '@wedo/design-system';
import { TaskStatusDropdownItem } from 'Pages/TasksPage/components/TasksToolbar/TaskStatusDropdownItem';
import { TaskStatus } from 'Shared/types/task';
import { TaskStatues } from 'Shared/utils/task';

type TaskStatusPopoverProps = {
    variant?: ButtonVariant;
    size?: 'sm' | 'md' | 'lg';
    label?: ReactNode;
    icon?: IconDefinition;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    statuses?: TaskStatus[];
    onChange?: (statuses: TaskStatus[]) => void;
    allowedStatuses?: TaskStatus[];
    isActive?: boolean;
};

export const TaskStatusDropdownItems = ({
    statuses,
    onChange,
    allowedStatuses = ['todo', 'completed', 'deleted'],
}: {
    statuses: TaskStatus[];
    onChange?: (status: TaskStatus[]) => void;
    allowedStatuses?: TaskStatus[];
}) => {
    return TaskStatues.map((taskStatus) => (
        <TaskStatusDropdownItem
            key={taskStatus}
            status={taskStatus}
            statuses={statuses}
            onChange={onChange}
            isDisabled={!allowedStatuses.includes(taskStatus)}
        />
    ));
};

// || statuses.length !== 2 || !statuses.every((status) => ['todo', 'completed'].includes(status))

export const TaskStatusPopover: FC<TaskStatusPopoverProps> = ({
    variant,
    size,
    label,
    icon,
    onClick,
    onChange,
    statuses,
    allowedStatuses,
    isActive = false,
}) => (
    <Popover
        text={label ?? t`Status`}
        placement="bottom"
        icon={icon ?? 'status'}
        variant={variant ?? 'text'}
        active={isActive}
        size={size ?? 'md'}
        onClick={onClick}
    >
        <div className="bg-white flex flex-col p-1 gap-1">
            <TaskStatusDropdownItems statuses={statuses} allowedStatuses={allowedStatuses} onChange={onChange} />
        </div>
    </Popover>
);
