import { HttpMethod, Id } from '@wedo/types';
import { ActivityLog } from 'Shared/types/activityLog';
import { Circle, CircleMember } from 'Shared/types/governance';
import { baseApi, configureTag, listId } from './base';

export const { tagType: circleTagType, tag: circleTag } = configureTag('Circle');
export const { tag: circleActivitiesTag } = configureTag('CircleActivities');
const governanceActivitiesTag = 'GovernanceActivities';

type fetchImportSchemaProps = {
    schemaType?: 'example' | 'open-api' | 'json';
};

type DeleteCircleProps = {
    id: Id;
    cascade?: boolean;
};

type CreateCircleMemberProps = {
    circle_id: Id;
    user_id: Id;
    is_core?: boolean;
    order?: number;
    draft?: boolean;
    scope?: string;
};
type DeleteCircleMemberProps = {
    circle_id: Id;
    user_id: Id;
};
type UpdateCircleMemberProps = {
    circle_id: Id;
    user_id: Id;
} & Partial<CircleMember>;

type MirrorRoleProps = {
    id: Id;
    destination_circle_id: Id;
    copy_members: boolean;
};

type CopyCircleProps = {
    id: Id;
    destination_circle_id: Id;
    copy_members?: boolean;
    copy_children?: boolean;
};

type DuplicateCircleProps = {
    id: Id;
    copy_members?: boolean;
    copy_children?: boolean;
};

type ImportGovernanceProps = {
    parent_circle_id: Id;
    organization: { circles?: Partial<Circle>; roles?: Partial<Circle> };
};

export const circleApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [circleTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            fetchCircle: build.query<Circle, { id: Id }>({
                query: ({ id }) => ({
                    url: `governance/circles/${id}`,
                }),
                providesTags: (result) => [circleTag(result?.id)],
            }),
            fetchCircles: build.query<Circle[], void>({
                query: () => ({ url: 'governance' }),
                providesTags: () => [circleTag(listId)],
            }),
            createCircle: build.mutation<Circle, Partial<Circle>>({
                query: (body) => ({ url: 'governance/circles', method: HttpMethod.Post, body }),
                invalidatesTags: () => [circleTag(listId), governanceActivitiesTag],
            }),
            updateCircle: build.mutation<Circle, Partial<Circle>>({
                query: (body) => ({
                    url: `governance/circles/${body.id}`,
                    method: HttpMethod.Put,
                    body,
                }),
                onQueryStarted: ({ id, ...patch }, { dispatch, queryFulfilled }) => {
                    // Optimistic Update
                    const patchResult = dispatch(
                        circleApi.util.updateQueryData('fetchCircles', undefined, (draft) =>
                            draft.map((circle) => (circle.id !== id ? circle : { ...circle, ...patch }))
                        )
                    );
                    queryFulfilled.catch(patchResult.undo);
                },
                invalidatesTags: (result, error, query) => {
                    const invalidationTable = [circleTag(query.id), circleActivitiesTag(query.id)];
                    if (query.name || query.draft || query.color) {
                        invalidationTable.push(circleTag(listId));
                    }
                    return invalidationTable;
                },
            }),
            deleteCircle: build.mutation<void, DeleteCircleProps>({
                query: ({ id, cascade = false }) => ({
                    url: `governance/circles/${id}`,
                    params: cascade ? { cascade: true } : {},
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: () => [circleTag(listId), governanceActivitiesTag],
            }),
            createCircleMember: build.mutation<CircleMember, CreateCircleMemberProps>({
                query: (body) => ({
                    url: `governance/circles/${body.circle_id}/members`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { circle_id }) => [
                    circleTag(listId),
                    circleTag(circle_id),
                    circleActivitiesTag(circle_id),
                ],
            }),
            updateCircleMember: build.mutation<CircleMember, UpdateCircleMemberProps>({
                query: (body) => ({
                    url: `governance/circles/${body.circle_id}/members/${body.user_id}`,
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { circle_id }) => [
                    circleTag(listId),
                    circleTag(circle_id),
                    circleActivitiesTag(circle_id),
                ],
            }),
            deleteCircleMember: build.mutation<void, DeleteCircleMemberProps>({
                query: (body) => ({
                    url: `governance/circles/${body.circle_id}/members/${body.user_id}`,
                    method: HttpMethod.Delete,
                    body,
                }),
                invalidatesTags: (result, error, { circle_id }) => [
                    circleTag(listId),
                    circleTag(circle_id),
                    circleActivitiesTag(circle_id),
                ],
            }),
            mirrorRole: build.mutation<Circle, MirrorRoleProps>({
                query: (body) => ({
                    url: `governance/circles/${body.id}/mirror`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { id }) => [
                    circleTag(listId),
                    circleActivitiesTag(id),
                    governanceActivitiesTag,
                ],
            }),
            copyCircle: build.mutation<Circle, CopyCircleProps>({
                query: (body) => ({
                    url: `governance/circles/${body.id}/copy`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { id }) => [
                    circleTag(listId),
                    circleActivitiesTag(id),
                    governanceActivitiesTag,
                ],
            }),
            duplicateCircle: build.mutation<Circle, DuplicateCircleProps>({
                query: (body) => ({
                    url: `governance/circles/${body.id}/duplicate`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { id }) => [
                    circleTag(listId),
                    circleActivitiesTag(id),
                    governanceActivitiesTag,
                ],
            }),
            fetchImportSchema: build.query<any, fetchImportSchemaProps>({
                query: (params) => ({ url: 'governance/schema', params }),
                providesTags: (result, error, { schemaType }) => [
                    schemaType == null ? 'governanceImportSchema-json' : 'governanceImportSchema-' + schemaType,
                ],
            }),
            importGovernance: build.mutation<Circle, ImportGovernanceProps>({
                query: (body) => ({
                    url: 'governance/import',
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: () => [circleTag(listId), governanceActivitiesTag],
            }),
            //--------------------------
            // Governance Activity Logs
            //--------------------------
            fetchCircleActivities: build.query<ActivityLog[], { id }>({
                query: ({ id }) => ({
                    url: `governance/circles/${id}/activities`,
                }),
                providesTags: (result, error, { id }) => [circleActivitiesTag(id)],
            }),
            fetchGovernanceActivities: build.query<ActivityLog[], void>({
                query: () => ({ url: 'governance/activities' }),
                providesTags: () => [governanceActivitiesTag],
            }),
        }),
    });

export const {
    useFetchCirclesQuery,
    useFetchCircleQuery,
    useFetchImportSchemaQuery,
    useImportGovernanceMutation,
    useCreateCircleMutation,
    useUpdateCircleMutation,
    useDeleteCircleMutation,
    useCreateCircleMemberMutation,
    useUpdateCircleMemberMutation,
    useDeleteCircleMemberMutation,
    useMirrorRoleMutation,
    useDuplicateCircleMutation,
    useCopyCircleMutation,
    useFetchCircleActivitiesQuery,
    useFetchGovernanceActivitiesQuery,
} = circleApi;
