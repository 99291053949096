import { useLingui } from '@lingui/react';
import React from 'react';
import { Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { intervalToDuration, milliseconds } from 'date-fns';
import { Tooltip } from '@wedo/design-system';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { formatDurationWithLocale } from 'Shared/components/meeting/FormatMeetingDateTime';
import { trpc } from 'Shared/trpc';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const TableOfContentsFooter = () => {
    const { meetingId, meeting } = useMeetingContext();
    const { data: topics = [] } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const { i18n } = useLingui();

    const totalDuration = topics?.reduce((total: number, topic: MeetingTopic) => total + topic.duration, 0);
    const totalDurationObject =
        totalDuration &&
        intervalToDuration({
            start: 0,
            end: totalDuration * 60 * 1000,
        });
    const meetingDuration = meeting?.start_at
        ? intervalToDuration({ start: new Date(meeting?.start_at), end: new Date(meeting?.end_at) })
        : null;
    const isOvertime =
        totalDurationObject && meetingDuration && milliseconds(totalDurationObject) > milliseconds(meetingDuration);

    return totalDuration > 0 && meetingDuration && totalDurationObject ? (
        <div className={'flex h-full w-full items-center justify-center text-sm'}>
            <Tooltip content={isOvertime && <Trans>Duration of topics exceed meeting duration</Trans>}>
                <div className={clsx(isOvertime && 'font-bold text-red-500', 'text-gray-800')}>
                    {formatDurationWithLocale(totalDurationObject, i18n)}
                </div>
            </Tooltip>
            <span>&nbsp;/&nbsp;</span>
            {formatDurationWithLocale(meetingDuration, i18n)}
        </div>
    ) : null;
};
