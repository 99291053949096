import React, { FC } from 'react';
import { faPen, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Breadcrumb, Button, Divider, useModal } from '@wedo/design-system';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { WorkspaceSettingsModal } from 'Shared/components/workspace/WorkspaceSettingsModal/WorkspaceSettingsModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useGetTeamQuery } from 'Shared/services/team';
import { Workspace } from 'Shared/types/workspace';

type WorkspaceDescriptionProps = {
    workspace: Workspace;
    onClose?: () => void;
    showBreadcrumb?: boolean;
    showEditButton?: boolean;
    hideScrollbar?: boolean;
};

export const WorkspaceDescription: FC<WorkspaceDescriptionProps> = ({
    workspace,
    onClose,
    showBreadcrumb = false,
    showEditButton = false,
    hideScrollbar = false,
}) => {
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(workspace);
    const { data: team } = useGetTeamQuery(
        { teamId: workspace?.team_id },
        { skip: workspace?.team != null || workspace?.team_id == null }
    );

    const workspaceTeam = workspace?.team ?? team;

    return (
        <div className="flex flex-col max-h-[20rem] w-80 overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg md:w-[34rem] text-gray-700">
            <div className="flex items-start justify-between px-2 pt-2">
                <div className="flex items-center pt-0.5 gap-2">
                    <WorkspaceIcon workspace={workspace} />
                    <div className="line-clamp-2">{workspace?.name}</div>
                </div>

                <div className="flex gap-1">
                    {isCurrentUserModerator && showEditButton && (
                        <Button
                            aria-label={t`Edit workspace settings`}
                            icon={faPen}
                            size="sm"
                            disabled={workspace?.deleted}
                            onClick={() => open(WorkspaceSettingsModal, { workspaceId: workspace?.id })}
                        />
                    )}
                    {onClose && <Button icon={faTimes} size="sm" onClick={onClose} />}
                </div>
            </div>

            <Divider />

            {showBreadcrumb && workspaceTeam && (
                <Breadcrumb className="ml-3">
                    <Breadcrumb.Item to="/teams">
                        <Trans>Teams</Trans>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item to={`/teams/${workspaceTeam.id}/workspaces`}>{workspaceTeam.name}</Breadcrumb.Item>
                    <Breadcrumb.Item>{workspace?.name}</Breadcrumb.Item>
                </Breadcrumb>
            )}

            <div className={clsx('scrollbar-light  px-3', !hideScrollbar && 'overflow-y-auto')}>
                <DescriptionEditor isReadOnly html={workspace?.description} placeholder={t`No description`} />
            </div>
        </div>
    );
};
