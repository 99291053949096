import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { PartialUser } from 'Pages/settings/users/utils/user';

type InviteTabProps = {
    user: PartialUser;
};

export const InviteTab: FC<InviteTabProps> = ({ user }) => {
    const fullName = `${user.first_name} ${user.last_name}`;

    return (
        <div>
            <Trans>
                Would you like to invite <strong>{fullName}</strong> now?
            </Trans>
        </div>
    );
};
