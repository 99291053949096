import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel } from '@fortawesome/pro-regular-svg-icons';
import { Editor } from 'slate';
import { Plugin } from '../Editor';
import {
    breakBlockInside,
    breakBlockOutside,
    createBlock,
    resetOrMergeBackwardIfPossible,
    resetOrMergeForwardIfPossible,
} from '../utils/block';
import { isIn } from '../utils/slate';
import { textAlignFromDecoration } from './paragraphPlugin';

export const Decision = 'decision';

export const createDecisionBlock = () => {
    return createBlock({ type: Decision });
};

export const decisionPlugin = (): Plugin => ({
    renderElement: (editor, { element, children, attributes }) => {
        return (
            element.type === Decision && (
                <div
                    data-block-id={element.id}
                    className="bg-green-50 py-1 pl-2 pr-6 text-green-700 relative"
                    style={textAlignFromDecoration(element)}
                    {...attributes}
                >
                    <FontAwesomeIcon
                        icon={faGavel}
                        className="pointer-events-none absolute right-2 top-2 h-3 text-green-500"
                    />
                    {children}
                </div>
            )
        );
    },
    deleteForward: (editor: Editor) => resetOrMergeForwardIfPossible(editor, Decision),
    deleteBackward: (editor: Editor) => resetOrMergeBackwardIfPossible(editor, Decision),
    insertBreak: (editor: Editor) =>
        isIn(editor, Decision, () => {
            const [, [selectedIndex]] = Editor.node(editor, editor.selection) ?? [];
            const isAtBlockEnd = Editor.isEnd(editor, editor.selection.anchor, [selectedIndex]);
            if (isAtBlockEnd) {
                return breakBlockOutside(editor);
            }
            return breakBlockInside(editor);
        }),
    insertSoftBreak: (editor: Editor) => isIn(editor, Decision, () => breakBlockInside(editor)),
});
