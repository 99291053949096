import { useMemo } from 'react';
import { useStore } from 'zustand';
import { useGanttContextStore } from './GanttContext';
import { type SectionWithTasks } from './types';
import { useHover } from './useHover';
import { durationInDays, daysSinceEpoch } from './utils';

type TimelineSectionProps = {
    section: SectionWithTasks;
};

export const TimelineSection = ({ section }: TimelineSectionProps) => {
    const store = useGanttContextStore()!;
    const sectionMinDate = useStore(store, ({ data }) => data.dateWindows.get(section.id)?.minDate);
    const sectionMaxDate = useStore(store, ({ data }) => data.dateWindows.get(section.id)?.maxDate);
    const zoomView = useStore(store, ({ view }) => view.zoom.view);

    const hoverProps = useHover(`section-${section.id}`);

    const [start, duration] = useMemo(() => {
        return [daysSinceEpoch(sectionMinDate ?? sectionMaxDate), durationInDays(sectionMinDate, sectionMaxDate)];
    }, [sectionMinDate, sectionMaxDate]);

    const arrowWidth = useMemo(
        () =>
            zoomView === 'year'
                ? duration < 2
                    ? 2
                    : duration < 3
                      ? 4
                      : 8
                : zoomView === 'quarter'
                  ? duration < 2
                      ? 4
                      : 8
                  : 8,
        [zoomView, duration]
    );

    return start == null ? (
        <div {...hoverProps} />
    ) : (
        <div className="flex items-center" {...hoverProps}>
            <div
                className="-mt-1 h-2 transition-transform-width relative rounded-t-sm flex justify-between"
                style={{
                    backgroundColor: section.color,
                    transform: `translateX(calc((${start} - var(--start-day)) * var(--column-width)))`,
                    width: `calc(${duration} * var(--column-width))`,
                }}
            >
                {duration > 0 && (
                    <>
                        <svg height="8" width={arrowWidth} fill={section.color} className="mt-2">
                            <polygon points={`0,0 ${arrowWidth},0 0,8`} />
                        </svg>
                        <svg height="8" width={arrowWidth} fill={section.color} className="mt-2">
                            <polygon points={`0,0 ${arrowWidth},0 ${arrowWidth},8`} />
                        </svg>
                    </>
                )}
            </div>
        </div>
    );
};
