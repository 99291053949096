import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { faCheck, faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { timeToString } from '~/components/TimePicker/utils/time';
import { Time } from '~/hooks/useCalendar';
import { MILLISECONDS_PER_HOUR } from '@wedo/utils';

type TimeItemProps = {
    active: boolean;
    selected: boolean;
    value: Time;
};

const baseClass =
    'flex items-center justify-between gap-2 p-2 my-1 rounded-md text-sm relative select-none hover:cursor-pointer hover:bg-hover';
const activeClass = 'bg-hover cursor-pointer';
const selectedClass = 'bg-highlight text-blue-700 font-semibold';
const unselectedClass = 'text-gray-900';

export const TimeItem = forwardRef<HTMLDivElement, TimeItemProps>(
    ({ value, selected = false, active = false }, ref): JSX.Element => (
        <div ref={ref} className={clsx(baseClass, active && activeClass, selected ? selectedClass : unselectedClass)}>
            {timeToString(value)}
            {value.offsetShift !== 0 && (
                <>
                    <FontAwesomeIcon
                        title={value.offsetShift > 0 ? t`Time skips forward 1 hour` : t`Time goes back 1 hour`}
                        className={'ml-2'}
                        flip={value.offsetShift > 0 ? 'horizontal' : null}
                        icon={faClockRotateLeft}
                    />
                </>
            )}
            <FontAwesomeIcon icon={faCheck} className={clsx(!selected && 'invisible')} />
        </div>
    )
);
