import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

type WebSocketStore = {
    isConnected: boolean;
    isEnabled: boolean;
};

export const useWebSocket = create<WebSocketStore>()(
    subscribeWithSelector<WebSocketStore>(() => ({
        isConnected: false,
        isEnabled: true,
    }))
);
