import React, { useContext, useMemo } from 'react';
import { t } from '@lingui/macro';
import { Signature } from '@wedo/db';
import { Button, useModal } from '@wedo/design-system';
import { useSessionUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { EditMeetingAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingAccessModal';
import { SignatureModalSignatoryList } from 'Pages/meeting/components/RequestMeetingSignaturesModal/SignatureModalSignatoryList';
import { useSignatureReminder } from 'Pages/meeting/components/RequestMeetingSignaturesModal/hooks/useSignatureReminder';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';

export const SignatureModalSignatories = () => {
    const { meetingId, signatureRequest } = useContext(SignatureModalContext);
    const { open: openModal } = useModal();
    const { send: sendReminder, isLoading: isSendingReminder } = useSignatureReminder();
    const currentUser = useSessionUser();
    const { meeting } = useMeeting(meetingId);
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const everyoneHasAnswered = useMemo(() => {
        if (signatureRequest == null) {
            return false;
        }
        return signatureRequest.signatures.every((signature: Signature) => signature.status !== 'PENDING');
    }, [signatureRequest]);

    return (
        <div className={'flex flex-col gap-2'}>
            <div className={'text-sm font-medium flex justify-between items-center'}>
                <div className={'self-end'}>{t`Signatories`}</div>
                {signatureRequest == null ? (
                    <div className={'flex justify-end'}>
                        <Button
                            size={'sm'}
                            onClick={() =>
                                openModal(EditMeetingAccessModal, {
                                    meetingId: meetingId,
                                    mode: 'signatory',
                                    size: 'md',
                                })
                            }
                        >{t`Manage signatories`}</Button>
                    </div>
                ) : (
                    !everyoneHasAnswered &&
                    isEditor && (
                        <div className={'flex justify-end'}>
                            <Button
                                loading={isSendingReminder}
                                size={'sm'}
                                onClick={() =>
                                    sendReminder({
                                        meeting,
                                        signatureRequest,
                                    })
                                }
                            >{t`Send reminders`}</Button>
                        </div>
                    )
                )}
            </div>
            <div className={'flex flex-col mb-2'}>
                <SignatureModalSignatoryList />
            </div>
        </div>
    );
};
