import React, { FC, useState } from 'react';
import { faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, ContextModalProps, Input, Label, Modal } from '@wedo/design-system';
import { EmptyString, isValidEmail } from '@wedo/utils';
import { useAddAuthorizedEmailMutation } from 'Shared/services/authorizedEmails';
import { RtkQueryTransformedError } from 'Shared/types/rtkQuery';
import { isSuccess } from 'Shared/utils/rtkQuery';

export const AddNewEmailAddressModal: FC<ContextModalProps> = ({ ...modalProps }) => {
    const [addAuthorizedEmail, { isLoading, error, isError }] = useAddAuthorizedEmailMutation();
    const [name, setName] = useState<string>(EmptyString);

    const [email, setEmail] = useState<string>(EmptyString);
    const [emailFocussedOut, setEmailFocussedOut] = useState<boolean>(false);
    const emailIsValid = isValidEmail(email);

    const errorInEmailInput = (!emailIsValid && emailFocussedOut) || isError;

    const buttonDisabled = isEmpty(name) || isEmpty(email) || !emailIsValid;

    const closeModal = () => modalProps.onClose();

    const handleAddEmail = async () => {
        if (await isSuccess(addAuthorizedEmail({ name: name.trim(), email: email.trim() }))) {
            closeModal();
        }
    };

    const handleEnterPress = () => {
        if (!buttonDisabled) {
            void handleAddEmail();
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Add email address`} />

            <Modal.Body>
                <Label className="mb-3">
                    <Trans>Name</Trans>
                    <Input
                        placeholder={t`Printer name, ticketing system ...`}
                        leadingIcon={faUser}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Label>

                <Label className="mb-3 mt-8">
                    <Trans>Email</Trans>
                    <Input
                        placeholder={'jane.doe@example.ch'}
                        leadingIcon={faEnvelope}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        status={errorInEmailInput ? 'error' : 'default'}
                        statusText={
                            errorInEmailInput
                                ? (error as RtkQueryTransformedError)?.title === 'Duplicate Email Found'
                                    ? t`This email address is already in use.`
                                    : t`Please enter a valid email address`
                                : ''
                        }
                        onBlur={() => setEmailFocussedOut(true)}
                        onPressEnter={handleEnterPress}
                    />
                </Label>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeModal}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button color="primary" loading={isLoading} onClick={handleAddEmail} disabled={buttonDisabled}>
                    <Trans>Add email</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
