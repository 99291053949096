import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from '@wedo/design-system';
import { useAppDispatch } from 'App/store';
import { selectSelectedTaskId, taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { TaskModal } from 'Shared/components/task/TaskModal';

export const useSelectedTaskModal = () => {
    const dispatch = useAppDispatch();

    const { open } = useModal();

    const selectedTaskId = useSelector(selectSelectedTaskId);

    useEffect(() => {
        if (selectedTaskId != null) {
            open(TaskModal, {
                taskId: selectedTaskId,
                onBeforeClose: async () => {
                    dispatch(taskSelected({ taskId: null }));
                    return true;
                },
            });
        }
    }, [selectedTaskId]);
};
