import { FC } from 'react';
import { t } from '@lingui/macro';
import { ContextModalProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { DuplicateEntityModal } from 'Shared/components/duplicate/DuplicateEntityModal/DuplicateEntityModal';
import { useDuplicateTemplateMutation, useGetChecklistTemplateQuery } from 'Shared/services/template';

type DuplicateTemplateModalProps = { templateId: Id } & ContextModalProps;

export const DuplicateTemplateModal: FC<DuplicateTemplateModalProps> = ({ templateId, ...modalProps }) => {
    const navigate = useNavigate();
    const { data: template } = useGetChecklistTemplateQuery(templateId);

    const [duplicateTemplate] = useDuplicateTemplateMutation();

    const handleDuplicate = async (config, payload) => {
        const response = await duplicateTemplate({
            templateId,
            config: {
                ...config,
                referenceDateValue: new Date().toISOString(),
                scaleDate: true,
            },
            template: {
                name: payload?.name,
                description: template?.description,
                team_id: payload?.teamId,
            },
        });

        if ('error' in response) {
            return Promise.resolve(false);
        }
        await modalProps.close();
        void navigate(`/templates/${response?.data?.id}/checklists`);
        return Promise.resolve(true);
    };

    return (
        <DuplicateEntityModal
            {...modalProps}
            entity={template}
            entityName="template"
            onDuplicate={handleDuplicate}
            modalTitle={t`Duplicate ${template?.name} template`}
            showColor={false}
        />
    );
};
