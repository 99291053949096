import { t } from '@lingui/macro';
import { Button, Label } from '@wedo/design-system';
import { Day } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/ReccurrenceUtils';

interface RecurrenceRepeatFormProps {
    labelColNb?: number;
    wrapperColNb?: number;
    weekDays?: Day[];
    toggleDay?: (day: Day) => void;
}

export const RecurrenceRepeatForm = ({ weekDays, toggleDay }: RecurrenceRepeatFormProps): JSX.Element => {
    const handleClick = (day: Day) => {
        if (weekDays.find((i) => i.selected && i.index !== day.index)) {
            toggleDay(day);
        }
    };
    return (
        <>
            <Label className={'col-span-3 text-end'}>{t`Repeat on`}</Label>
            <div className={'col-span-9 flex flex-wrap gap-1'}>
                {weekDays.map((day, index) => {
                    return (
                        <Button size={'sm'} key={index} active={day.selected} onClick={() => handleClick(day)}>
                            {day.label}
                        </Button>
                    );
                })}
            </div>
        </>
    );
};
