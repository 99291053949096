import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { useDeleteCustomFieldMutation } from 'Shared/services/customFields';
import { CustomField } from 'Shared/types/customField';

interface ModalContentProps {
    field: CustomField;
}

const ModalContent: React.FC<ModalContentProps> = ({ field }) => {
    return (
        <Trans>
            Are you sure you want to delete the field <span className="bold font-mono">{field.label}</span>?
        </Trans>
    );
};

export const useDeleteCustomFieldModal = () => {
    const { confirm } = useConfirm();
    const [deleteField] = useDeleteCustomFieldMutation();

    const open = async (field: CustomField) => {
        await confirm({
            title: t`Delete custom field`,
            content: <ModalContent field={field} />,
            confirmText: t`Delete`,
            type: 'danger',
            onConfirm: () => deleteField(field.id).unwrap(),
        });
    };

    return { open };
};
