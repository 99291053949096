import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { ModalType, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { numericCompare } from '@wedo/utils';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { MeetingUsersAvatarGroup } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUsersAvatarGroup';
import { useSelectedTopicId } from 'Pages/meeting/components/MeetingView/MeetingView';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { useDeleteMeetingSectionsMutation, useUpdateMeetingSectionsMutation } from 'Shared/services/meetingSection';
import { useUpdateTopicsMutation, useUpdateTopicsOrderMutation } from 'Shared/services/meetingTopic';
import { trpcUtils } from 'Shared/trpc';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic, MeetingTopicSubmission } from 'Shared/types/meetingTopic';
import { TocTree, TreeMethods, TreeNodeRenderOptions } from './TocTree';
import { TreeNode } from './TreeNode';
import {
    createFlatTree,
    createTopicSubmissionsTree,
    createTreeFromArray,
    getAfterSectionObj,
    getDeleteSectionIds,
    getInsideSectionObj,
    hasTopicsInside,
    TreeNodeModel,
} from './utils';

export type TocTreeTopicSubmissions = Array<{
    meetingTopic: MeetingTopic;
    meetingTopicSubmission: MeetingTopicSubmission;
}>;

type TableOfContentsTreeProps = {
    treeRef: MutableRefObject<TreeMethods>;
    topics: MeetingTopic[];
    sections: MeetingSection[];
    canManageTopics: boolean;
    canManageSections: boolean;
    onTopicClick: (MeetingTopic: MeetingTopic) => void;
    onAddTopic: (topic: Partial<MeetingTopic>) => void;
    onAddSection: (section: Partial<MeetingSection>) => void;
    lastAddedTocSection?: Id;
    lastAddedTocTopic?: Id;
    handleTopicRef: (topicId: Id) => (element: HTMLDivElement) => void;
    handleSectionRef: (sectionId: Id) => (element: HTMLDivElement) => void;
    topicSubmissions: TocTreeTopicSubmissions;
};

export const TableOfContentsTree = ({
    treeRef,
    topics,
    sections,
    canManageTopics,
    canManageSections,
    onTopicClick,
    onAddTopic,
    onAddSection,
    lastAddedTocSection,
    lastAddedTocTopic,
    handleTopicRef,
    handleSectionRef,
    topicSubmissions,
}: TableOfContentsTreeProps) => {
    const selectedTopicId = useSelectedTopicId();

    const { confirm } = useConfirm();
    const { show } = useNotification();
    const { meetingId, meeting } = useMeetingContext();

    const [updateSections] = useUpdateMeetingSectionsMutation();
    const [deleteSections] = useDeleteMeetingSectionsMutation();
    const [updateTopics] = useUpdateTopicsMutation();
    const [updateTopicsOrder, { isLoading: isUpdatingOrder }] = useUpdateTopicsOrderMutation();

    const totalTopicsDuration = topics?.reduce((total: number, topic: MeetingTopic) => total + topic.duration, 0);

    const treeData = useMemo(() => {
        return [
            ...createTopicSubmissionsTree(topicSubmissions),
            ...createFlatTree(topics, sections, canManageSections, canManageTopics),
        ];
    }, [topicSubmissions, topics, sections, canManageTopics, canManageSections]);

    const handleDrop = async (newTreeData: TreeNodeModel[]) => {
        if (isUpdatingOrder) {
            return;
        }
        const newTree = createTreeFromArray(newTreeData, null);
        const result = await updateTopicsOrder({ meetingId: meetingId, tree: newTree });
        void trpcUtils().meetingTopic.get.invalidate(selectedTopicId);
        if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleSectionChange = useCallback(
        async (sectionId: Id, changes: Partial<MeetingSection>) => {
            let oldTitle: string;
            if (changes?.title) {
                oldTitle = treeData.find((el) => el.isSection === true && el.id === sectionId)?.item?.title || '';
                // optimistic update of title
                trpcUtils().meetingSection.listByMeetingId.setData(meetingId, (cachedSections: MeetingSection[]) => [
                    ...cachedSections.filter(({ id }) => id !== sectionId),
                    { ...cachedSections.find(({ id }) => id === sectionId), title: changes.title },
                ]);
            }
            try {
                await updateSections({ meetingId, sections: [{ id: sectionId, changes }] }).unwrap();
            } catch (e) {
                if (changes?.title) {
                    // revert optimistic update in case of error
                    trpcUtils().meetingSection.listByMeetingId.setData(
                        meetingId,
                        (cachedSections: MeetingSection[]) => [
                            ...cachedSections.filter(({ id }) => id !== sectionId),
                            { ...cachedSections.find(({ id }) => id === sectionId), title: oldTitle },
                        ]
                    );
                }
                show(UnexpectedErrorNotification);
            }
        },
        [meetingId]
    );

    const handleTopicChange = useCallback(
        async (topicId: Id, changes: Partial<MeetingTopic>) => {
            let oldTitle: string;
            if (changes?.title) {
                oldTitle = treeData.find((el) => !el.isSection && el.id === topicId)?.item?.title || '';
                // optimistic update of title
                trpcUtils().meetingTopic.listByMeetingId.setData(meetingId, (cachedTopics: MeetingTopic[]) => [
                    ...cachedTopics.filter(({ id }) => id !== topicId),
                    { ...cachedTopics.find(({ id }) => id === topicId), title: changes.title },
                ]);
            }
            try {
                await updateTopics({
                    meetingId,
                    topics: [
                        {
                            id: topicId,
                            changes: changes,
                        },
                    ],
                }).unwrap();
            } catch (e) {
                if (changes?.title) {
                    // revert optimistic update in case of error
                    trpcUtils().meetingTopic.listByMeetingId.setData(meetingId, (cachedTopics: MeetingTopic[]) => [
                        ...cachedTopics.filter(({ id }) => id !== topicId),
                        { ...cachedTopics.find(({ id }) => id === topicId), title: oldTitle },
                    ]);
                }
                show(UnexpectedErrorNotification);
            } finally {
                void trpcUtils().meetingTopic.get.invalidate(topicId);
            }
        },
        [meetingId]
    );

    const handleAddTopic = useCallback(
        (section: MeetingSection) => {
            const sectionsAndTopics = [...topics, ...sections].sort((a, b) =>
                numericCompare(a.display_id, b.display_id)
            );
            const { order, display_id, parent_section_id } = getInsideSectionObj(section, sectionsAndTopics);
            onAddTopic({
                order: order,
                display_id: display_id,
                meeting_section_id: parent_section_id,
            });
        },
        [topics, sections]
    );

    const handleAddSectionInside = useCallback(
        (section: MeetingSection) => {
            const sectionsAndTopics = [...topics, ...sections].sort((a, b) =>
                numericCompare(a.display_id, b.display_id)
            );
            const newId = uuidv4();
            const sectionObject: Partial<MeetingSection> = {
                id: newId,
                title: '',
                meeting_id: section.meeting_id,
                ...getInsideSectionObj(section, sectionsAndTopics),
            };
            onAddSection(sectionObject);
        },
        [topics, sections]
    );

    const handleAddSectionAfter = useCallback((section: MeetingSection) => {
        const newId = uuidv4();
        const sectionObject: Partial<MeetingSection> = {
            id: newId,
            title: '',
            meeting_id: section.meeting_id,
            ...getAfterSectionObj(section),
        } as Partial<MeetingSection>;
        onAddSection(sectionObject);
    }, []);

    const handleConfirmDelete = useCallback(
        async (applyOn: APPLY_ON, selectedSection: MeetingSection) => {
            if (applyOn) {
                const sectionIds = getDeleteSectionIds(selectedSection, [...topics, ...sections], []);
                try {
                    await deleteSections({
                        meetingId: meetingId,
                        applyOn: applyOn,
                        sections: sectionIds,
                    }).unwrap();
                } catch (e) {
                    show(UnexpectedErrorNotification);
                }
            }
        },
        [meetingId, topics, sections]
    );

    const handleDeleteSection = useCallback(
        async (section: MeetingSection) => {
            if (hasTopicsInside(section, [...topics, ...sections])) {
                await confirm({
                    type: 'primary',
                    isCancelButtonVisible: false,
                    title: t`You can't delete this section because there is at least one topic inside`,
                });
                return;
            }
            const applyOn: APPLY_ON = await confirm(
                {
                    showAll: false,
                    title: (
                        <div>
                            <Trans>
                                Do you really want to delete <strong>{section?.title}</strong>?
                            </Trans>
                        </div>
                    ),
                    defaultOption: APPLY_ON.FUTURE_MEETINGS,
                    type: ModalType.Danger,
                },
                ConfirmSaveMeetingModal
            );
            await handleConfirmDelete(applyOn, section);
        },
        [topics, sections]
    );

    const renderNode = useCallback(
        (
            node: TreeNodeModel,
            {
                indentationWidth,
                depth,
                childCount,
                displayId,
                isOpen,
                isForbiddenDrop,
                onCollapse,
            }: TreeNodeRenderOptions
        ) => {
            return (
                <TreeNode
                    key={node.id}
                    node={node}
                    indentationWidth={indentationWidth}
                    depth={depth}
                    childCount={childCount}
                    overrideDisplayId={displayId}
                    isError={isForbiddenDrop}
                    isOpen={isOpen}
                    onCollapse={node.isSection ? () => onCollapse(node.id) : undefined}
                    latestAddedSection={lastAddedTocSection}
                    latestAddedTopic={lastAddedTocTopic}
                    onTopicClick={onTopicClick}
                    onSectionChange={handleSectionChange}
                    onTopicChange={handleTopicChange}
                    onAddTopic={handleAddTopic}
                    onAddSectionInside={handleAddSectionInside}
                    onAddSectionAfter={handleAddSectionAfter}
                    onDeleteSection={handleDeleteSection}
                    handleTopicRef={handleTopicRef}
                    handleSectionRef={handleSectionRef}
                    showDuration={totalTopicsDuration > 0}
                />
            );
        },
        [
            onTopicClick,
            handleSectionChange,
            handleAddTopic,
            handleAddSectionInside,
            handleAddSectionAfter,
            handleDeleteSection,
        ]
    );

    return (
        <div className={clsx('flex h-full w-full flex-col overflow-y-hidden', isUpdatingOrder && 'opacity-70')}>
            <div className={'scrollbar-light grow overflow-y-auto px-2'}>
                <button
                    id="toc-attendees"
                    onClick={() => onTopicClick(null)}
                    className={clsx(
                        'shadow-xs mb-1 flex w-full justify-between rounded-md border border-gray-300 px-2 py-1.5 text-sm font-medium',
                        !selectedTopicId ? 'bg-blue-200' : 'bg-gray-100'
                    )}
                >
                    <Trans>Attendees</Trans>
                    <div>
                        <MeetingUsersAvatarGroup
                            meetingUsers={meeting?.meetingUsers?.filter((meetingUser) => meetingUser.is_attendee)}
                            size={'xs'}
                            maxDisplayed={8}
                        />
                    </div>
                </button>
                <TocTree
                    ref={treeRef}
                    tree={treeData}
                    isUpdating={isUpdatingOrder}
                    canManageTopics={canManageTopics}
                    canManageSections={canManageSections}
                    onDrop={handleDrop}
                    renderNode={renderNode}
                />
            </div>
        </div>
    );
};
