import React, { MouseEvent, useMemo } from 'react';
import {
    faBoxArchive,
    faClone,
    faEllipsisV,
    faFileExport,
    faFolderOpen,
    faPeopleGroup,
    faPlay,
    faShare,
    faSliders,
    faTrash,
    faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    ButtonProps,
    Dropdown,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { useSessionUser } from 'App/store/usersStore';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { FavoriteToggleDropdownItem } from 'Shared/components/button/FavoriteToggleDropdownItem';
import { DuplicateTemplateModal } from 'Shared/components/template/DuplicateTemplateModal';
import { MoveTemplateTeamModal } from 'Shared/components/template/MoveTemplateTeamModal';
import { StartChecklistModal } from 'Shared/components/template/StartChecklistModal';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useAddTemplateUserMutation, useUpdateTemplateMutation } from 'Shared/services/template';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Template } from 'Shared/types/template';

const useTemplateFavoriteToggle = (template: Template) => {
    const { mutateAsync: toggleFavorite, isLoading } = trpc.template.toggleFavorite.useMutation({
        onSuccess: () => Promise.all([trpcUtils().team.list.invalidate(), trpcUtils().template.list.invalidate()]),
    });

    const isFavorite = template?.meta?.inFavs;

    const handleFavoriteToggle = async (event: MouseEvent) => {
        event.stopPropagation();
        await toggleFavorite({ templateId: template.id });
    };

    return { handleFavoriteToggle, isLoading, isFavorite };
};

type TemplateDropdownProps = {
    template: Template;
    size?: ButtonProps['size'];
};

export const TemplateDropdown = ({ template, size = 'md' }: TemplateDropdownProps) => {
    const sessionUser = useSessionUser();
    const { open } = useModal();
    const { confirm } = useConfirm();
    const { show } = useNotification();
    const isCurrentUserMember = useMemo(
        () => template?.userGroup?.members.some(({ user_id }) => user_id === sessionUser.id),
        [template?.userGroup?.members]
    );
    const { isCurrentUserModerator } = useManageMembers(template);

    const { handleFavoriteToggle, isLoading, isFavorite } = useTemplateFavoriteToggle(template);

    const [updateTemplate] = useUpdateTemplateMutation();
    const [addTemplateUser] = useAddTemplateUserMutation();

    const isTemplateActive = !template?.deleted && !template?.archived;

    const handleExport = () => {
        open(ExportTasksModal, {
            grouping: 'section',
            order: 'section',
            statuses: ['todo'],
            templateId: template?.id,
            view: 'all',
        });
    };

    const handleArchive = () => {
        void confirm({
            type: 'primary',
            title: t`Do you want to archive the ${template?.name} template?`,
            content: t`You won't be able to start or edit the template anymore.`,
            confirmText: t`Archive`,
            onConfirm: () => updateTemplate({ templateId: template?.id, template: { archived: true } }).unwrap(),
        });
    };

    const handleUnarchive = () => {
        void confirm({
            type: 'primary',
            title: t`Unarchive ${template?.name} template?`,
            content: t`Do you want to unarchive the ${template?.name} template?`,
            confirmText: t`Unarchive`,
            onConfirm: () => updateTemplate({ templateId: template?.id, template: { archived: false } }).unwrap(),
        });
    };

    const handleDelete = () => {
        void confirm({
            type: 'danger',
            title: t`Delete ${template.name}`,
            content: t`You won't be able to start or edit the template anymore.`,
            confirmText: t`Delete ${template.name}`,
            onConfirm: () => updateTemplate({ templateId: template?.id, template: { deleted: true } }).unwrap(),
        });
    };

    const handleRestore = () => {
        void confirm({
            type: 'success',
            title: t`Restore ${template.name}`,
            content: t`Do you want to restore the ${template.name} template?`,
            confirmText: t`Restore ${template.name}`,
            onConfirm: () => updateTemplate({ templateId: template?.id, template: { deleted: false } }).unwrap(),
        });
    };

    const handleJoinTemplate = async () => {
        const response = await addTemplateUser({ templateId: template?.id, userId: sessionUser.id });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    if (template?.deleted) {
        return (
            isCurrentUserModerator && (
                <Dropdown icon={faEllipsisV} size={size} className={'shrink-0'}>
                    <Dropdown.Item icon={faShare} onClick={handleRestore}>
                        <Trans>Restore</Trans>
                    </Dropdown.Item>
                </Dropdown>
            )
        );
    }

    return (
        <Dropdown icon={faEllipsisV} size={size} className={'shrink-0'}>
            {isTemplateActive && (
                <Dropdown.Item
                    icon={faPlay}
                    disabled={template?.meta?.nbTaskInChecklist === 0}
                    onClick={() => open(StartChecklistModal, { templateId: template?.id })}
                >
                    <Trans>Start a checklist</Trans>
                </Dropdown.Item>
            )}

            {isCurrentUserModerator && (
                <>
                    <Dropdown.Item
                        icon={faSliders}
                        onClick={() => open(TemplateSettingsModal, { templateId: template?.id })}
                    >
                        <Trans>Settings</Trans>
                    </Dropdown.Item>
                </>
            )}

            {!template?.archived && (
                <FavoriteToggleDropdownItem
                    isFavorite={isFavorite}
                    onClick={handleFavoriteToggle}
                    isLoading={isLoading}
                />
            )}

            {!isCurrentUserMember && (
                <Dropdown.Item icon={faUserPlus} onClick={handleJoinTemplate}>
                    <Trans>Join template</Trans>
                </Dropdown.Item>
            )}

            <Dropdown.DividerItem />

            {isCurrentUserModerator && isTemplateActive && (
                <Dropdown.Item
                    icon={faPeopleGroup}
                    onClick={() => open(MoveTemplateTeamModal, { templateId: template?.id })}
                >
                    <Trans>Move to another team</Trans>
                </Dropdown.Item>
            )}

            <Dropdown.Item icon={faFileExport} onClick={handleExport}>
                <Trans>Export</Trans>
            </Dropdown.Item>

            {template?.meta.imModerator && (
                <Dropdown.Item
                    icon={faClone}
                    onClick={() => open(DuplicateTemplateModal, { templateId: template?.id })}
                >
                    <Trans>Duplicate</Trans>
                </Dropdown.Item>
            )}
            {!template?.archived && isCurrentUserModerator && (
                <Dropdown.Item icon={faBoxArchive} onClick={handleArchive}>
                    <Trans>Archive</Trans>
                </Dropdown.Item>
            )}

            {template?.archived && isCurrentUserModerator && (
                <Dropdown.Item icon={faFolderOpen} onClick={handleUnarchive}>
                    <Trans>Unarchive</Trans>
                </Dropdown.Item>
            )}

            {isCurrentUserModerator && <Dropdown.DividerItem />}

            {isCurrentUserModerator && (
                <Dropdown.Item danger icon={faTrash} onClick={handleDelete}>
                    <Trans>Delete</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
