import { useCallback, useMemo } from 'react';
import { isArray } from 'lodash-es';
import { useCustomFieldGroups } from 'Pages/settings/customFields/hooks/useCustomFieldGroups';
import { useCustomFields } from 'Shared/hooks/useCustomFields';
import {
    useUpdateCustomFieldGroupOrderMutation,
    useUpdateCustomFieldOrderMutation,
} from 'Shared/services/customFields';
import { ApiError } from 'Shared/types/apiError';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { isGroup } from 'Shared/utils/customFields';
import { isFetchError } from 'Shared/utils/rtkQuery';

export const useCombinedFields = () => {
    const {
        customFields,
        totalFields,
        numberArchivedFields,
        maxOrderFields,
        isError: customFieldsHasError,
        error: customFieldsError,
        refetch: refetchCustomFields,
        isLoadingFields,
    } = useCustomFields();

    const {
        customFieldGroups,
        totalGroups,
        numberArchivedGroups,
        maxOrder: maxOrderGroups,
        isError: groupsHasError,
        error: customFieldGroupsError,
        refetch: refetchCustomFieldGroups,
        isLoading: isLoadingGroups,
    } = useCustomFieldGroups();

    const isFetching = isLoadingFields || isLoadingGroups;

    const isError: boolean = groupsHasError || customFieldsHasError;

    const fetchErrorOccurred =
        isError && (isFetchError(customFieldsError as ApiError) || isFetchError(customFieldGroupsError as ApiError));

    const [updateFieldOrder, { isLoading: updateFieldOrderIsLoading }] = useUpdateCustomFieldOrderMutation();
    const [updateGroupOrder, { isLoading: updateGroupOrderIsLoading }] = useUpdateCustomFieldGroupOrderMutation();
    const updateOrderIsLoading = updateFieldOrderIsLoading || updateGroupOrderIsLoading;

    const refetch = () => {
        void refetchCustomFields();
        void refetchCustomFieldGroups();
    };

    const getId = (item: CustomField | CustomFieldGroup) => (isGroup(item) ? `group-${item.id}` : `field-${item.id}`);

    const combinedList = useMemo<Array<CustomField | CustomFieldGroup>>(() => {
        if (customFieldGroups && customFields) {
            const list = [...customFields, ...customFieldGroups];
            list.sort((a, b) => a.order - b.order);
            return list;
        }
        return [];
    }, [customFields, customFieldGroups]);

    const itemIdToIndexMapping = useMemo<Map<string, number>>(() => {
        const result = new Map<string, number>();
        if (isArray(combinedList)) {
            for (let index = 0; index < combinedList.length; index += 1) {
                result.set(getId(combinedList[index]), index);
            }
        }
        return result;
    }, [combinedList]);

    const itemIds = useMemo<Array<string>>(() => [...itemIdToIndexMapping.keys()], [itemIdToIndexMapping]);

    const isLoading = !customFields || !customFieldGroups;

    const totalItems = totalFields + totalGroups;

    const archivedItems = numberArchivedFields + numberArchivedGroups;

    const maxOrder = Math.max(maxOrderFields, maxOrderGroups);

    const updateItemOrders = useCallback(
        (payload: Array<{ id: string; order: number; isGroup: boolean }>) => {
            for (const item of payload) {
                if (item.isGroup) {
                    void updateGroupOrder({ groupId: item.id, order: item.order });
                } else {
                    void updateFieldOrder({ customFieldId: item.id, order: item.order });
                }
            }
        },
        [updateFieldOrder, updateGroupOrder]
    );

    const reOrderItems = useCallback(
        (fromId: string, overItemId: string): void => {
            if (fromId === overItemId) return;
            const fromIndex = itemIdToIndexMapping.get(fromId);
            const overIndex = itemIdToIndexMapping.get(overItemId);

            const updateOrderPayload: Array<{ id: string; order: number; isGroup: boolean }> = [];
            updateOrderPayload.push({
                id: combinedList[fromIndex].id,
                order: combinedList[overIndex].order,
                isGroup: isGroup(combinedList[fromIndex]),
            });

            if (fromIndex > overIndex) {
                for (let i = overIndex; i < fromIndex; i += 1) {
                    updateOrderPayload.push({
                        id: combinedList[i].id,
                        order: combinedList[i].order + 1,
                        isGroup: isGroup(combinedList[i]),
                    });
                }
            } else {
                for (let i = fromIndex + 1; i <= overIndex; i += 1) {
                    updateOrderPayload.push({
                        id: combinedList[i].id,
                        order: combinedList[i].order - 1,
                        isGroup: isGroup(combinedList[i]),
                    });
                }
            }

            updateItemOrders(updateOrderPayload);
        },
        [combinedList, itemIdToIndexMapping, updateItemOrders]
    );

    return {
        combinedList,
        isLoading,
        totalItems,
        archivedItems,
        maxOrder,
        itemIds,
        reOrderItems,
        getId,
        updateOrderIsLoading,
        fetchErrorOccurred,
        refetch,
        isFetching,
    };
};
