import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentProps, FC } from 'react';
import { faCircle as faCircleReg } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { getColorId, Tooltip } from '@wedo/design-system';
import { TemplateIconProps } from 'Shared/components/template/TemplateIcon';
import { Workspace } from 'Shared/types/workspace';

const wrapperSize: Record<TemplateIconProps['size'], string> = {
    xs: 'h-3 w-3',
    sm: 'h-5 w-5',
    md: 'h-6 w-6',
    lg: 'h-8 w-8',
};

const iconSize: Record<keyof typeof wrapperSize, string> = {
    xs: 'h-1 w-1',
    sm: 'h-2 w-2',
    md: 'h-3 w-3',
    lg: 'h-4 w-4',
};

const NoWorkspaceIcon = ({
    className,
    size,
    ...props
}: ComponentProps<'div'> & { size?: keyof typeof wrapperSize }): JSX.Element => (
    <div
        className={clsx(wrapperSize[size], 'flex shrink-0 items-center justify-center border rounded', className)}
        {...props}
    >
        <FontAwesomeIcon icon={faCircleReg} className={clsx(iconSize[size], 'color-gray-900 text-xs')} />
    </div>
);

export type WorkspaceIconProps = {
    workspace?: Pick<Workspace, 'name' | 'color'> | null;
    showTooltip?: boolean;
    size?: keyof typeof wrapperSize;
    showName?: boolean;
} & ComponentProps<'div'>;

export const WorkspaceIcon: FC<WorkspaceIconProps> = ({
    workspace,
    className,
    showTooltip = false,
    size = 'md',
    showName,
    ...props
}) => {
    if (!workspace) {
        return <NoWorkspaceIcon className={className} size={size} {...props} />;
    }

    const colorId = getColorId(workspace?.color?.background);

    return (
        <Tooltip delay={300} content={showTooltip ? workspace?.name || t`Doesn't belong to a workspace` : undefined}>
            <div
                className={clsx(
                    'flex shrink-0 items-center justify-center rounded max-w-[200px]',
                    `bg-${colorId.toString()}-100`,
                    `text-${colorId.toString()}-700`,
                    className
                )}
                {...props}
            >
                <div className={clsx(wrapperSize[size] ?? wrapperSize.md, 'flex shrink-0 items-center justify-center')}>
                    <FontAwesomeIcon icon={faCircleReg} className={iconSize[size] ?? iconSize.md} />
                </div>
                {showName && <div className={'truncate text-xs pr-2 hidden @2xl:block'}>{workspace?.name}</div>}
            </div>
        </Tooltip>
    );
};
