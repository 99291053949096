import { AnyAction } from '@reduxjs/toolkit';
import { Descendant, Editor, Operation, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { useModalStore } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { not, or, tryOrValue } from '@wedo/utils';
import { hasInvalidationTag, isInvalidationAction, onInvalidation } from 'App/store/invalidationStore';
import { deserializeHtml } from 'Shared/components/editor/plugins/copyPastePlugin/deserializeHtml';
import { serializeBlocks } from 'Shared/components/editor/plugins/copyPastePlugin/serializeBlocks';
import { resourceId } from 'Shared/services/base';
import { meetingBlockTag } from 'Shared/services/meetingBlock';
import { tagType as MeetingTopicTagType } from 'Shared/services/meetingTopic';
import { onWebSocketEvent } from 'Shared/services/webSocket';
import { trpcUtils } from 'Shared/trpc';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { User } from 'Shared/types/user';
import { Plugin } from '../../Editor';
import { isNoOpOperation, isRefreshBlocksOperation, isSaveOperation, isSkipSaveOperation } from '../../utils/operation';
import { RetryModal } from './RetryModal';
import { SavingIndicator } from './SavingIndicator';
import { computeChanges } from './computeChanges';
import { synchronizeSelection } from './synchronizeSelection';
import { updateBlocks } from './updateBlocks';
import { getState, setState } from './useServerBlocksPluginStore';

const SavingDebounceDelay = 900;
const MaximumSavingDelay = 3000;

export type Changes = {
    addedBlocks: Partial<MeetingBlock>[];
    updatedBlocks: { id: Id; changes: Partial<MeetingBlock> }[];
    deletedBlocks: Id[];
};

const clearEditorHistory = (editor: Editor) => {
    (editor as HistoryEditor).history = { undos: [], redos: [] };
};

export const synchronizeEditor = (editor: Editor, blocks: Descendant[]) => {
    let selection = editor.selection;
    const selectedAnchorBlockId = editor.children[selection?.anchor.path[0]]?.id;
    const selectedFocusBlockId = editor.children[selection?.focus.path[0]]?.id;
    Transforms.deselect(editor);

    editor.children = blocks;

    selection = synchronizeSelection(editor.children, selection, selectedAnchorBlockId, selectedFocusBlockId);

    if (selection != null) {
        Transforms.select(editor, selection);
    }

    editor.onChange();
};

export const refreshEditor = (editor: Editor, meetingId: Id, topicId: Id) => {
    trpcUtils()
        .meetingTopic.listBlocks.fetch(
            { topicId, related: ['subtasks'] },
            { staleTime: 0, trpc: { context: { skipBatch: true } } }
        )
        .then((blocks: Descendant[]) => {
            const normalizedBlocks = deserializeHtml(serializeBlocks(blocks), null, false).map((block) => ({
                ...block,
                meeting_topic_id: topicId,
            }));
            if (getState(meetingId, topicId, (state) => state?.status ?? 'idle') === 'idle') {
                setState(meetingId, topicId, (state) => {
                    state.blocks = normalizedBlocks;
                    state.replaceBlocks = false;
                });
                synchronizeEditor(editor, normalizedBlocks);
            }
        });
};

const tryComputeChanges = (meetingId: Id, topicId: Id, children: Descendant[], operations: Operation[]) => {
    try {
        return computeChanges(
            getState(meetingId, topicId, (state) => state?.blocks ?? []),
            children,
            operations
        );
    } catch (error) {
        return { addedBlocks: [], updatedBlocks: [], deletedBlocks: [] };
    }
};

const trySaveBlocks = async (editor: Editor, changes: Changes, meetingId: Id, topicId: Id, children: Descendant[]) => {
    try {
        const hasChanges =
            changes.addedBlocks.length > 0 || changes.updatedBlocks.length > 0 || changes.deletedBlocks.length > 0;

        const savingPromise = hasChanges ? updateBlocks(topicId, changes) : Promise.resolve(children);

        setState(meetingId, topicId, (state) => {
            state.status = 'saving';
            state.savingPromise = savingPromise;
        });

        const savedBlocks = await savingPromise;

        const replaceBlocks = getState(meetingId, topicId, (state) => state?.replaceBlocks);

        setState(meetingId, topicId, (state) => {
            state.status = 'idle';
            state.pendingBlocks = null;
            state.pendingTimeout = null;
            state.savingPromise = null;
            state.savingAbortController = null;
            // If we have to replace blocks, we use the blocks from the server instead of the local blocks as the last
            // saved blocks
            state.blocks = replaceBlocks ? savedBlocks : children;
            state.replaceBlocks = false;
            state.startTimestamp = null;
        });

        return [savedBlocks, replaceBlocks];
    } catch (error) {
        await tryOrValue(() => {
            return error.text().then((text) => {
                try {
                    const json = JSON.parse(text);
                    return json.errors
                        .map((error) => {
                            return error.property?.details.map((detail) => detail.message).join(', ') ?? error.message;
                        })
                        .join(', ');
                } catch (error) {
                    return text;
                }
            });
        }, Promise.resolve());
        useModalStore.getState().actions.open(RetryModal, { editor, meetingId, topicId, error });
        setState(meetingId, topicId, (state) => {
            state.status = 'error';
            state.pendingBlocks = null;
            state.pendingTimeout = null;
            state.savingPromise = null;
            state.savingAbortController = null;
            state.replaceBlocks = false;
            state.startTimestamp = null;
        });
        return [[], false];
    }
};

const trySynchronizeEditor = (
    editor: Editor,
    currentUser: User,
    changes: Changes,
    savedBlocks: MeetingBlock[],
    replaceBlocks: boolean
) => {
    HistoryEditor.withoutSaving(editor, () => {
        try {
            // We may have to update blocks task or vote ids
            savedBlocks.forEach((block) => {
                const index = editor.children.findIndex(({ id }) => id === block.id);
                if (block.task_id != null && editor.children[index]?.task_id == null) {
                    Transforms.setNodes(editor, { task_id: block.task_id }, { at: [index] });
                } else if (block.vote_id != null && editor.children[index]?.vote_id == null) {
                    Transforms.setNodes(editor, { vote_id: block.vote_id }, { at: [index] });
                } else if (
                    block.type === 'image' &&
                    editor.children[index]?.type === 'image' &&
                    block.children[0]?.url?.startsWith('data:image')
                ) {
                    Transforms.setNodes(editor, { url: block.children[0]?.url }, { at: [index, 0], voids: true });
                }
                // We update the updatedBy attributes
                if (changes.updatedBlocks.find(({ id }) => id === block.id) != null && index !== -1) {
                    Transforms.setNodes(
                        editor,
                        { updated_by: currentUser.id, updated_at: new Date() },
                        { at: [index] }
                    );
                }
            });

            const hasChanges =
                changes.addedBlocks.length > 0 || changes.updatedBlocks.length > 0 || changes.deletedBlocks.length > 0;

            // We may have to replace the editor children if a concurrent update has occurred
            if (hasChanges && replaceBlocks) {
                synchronizeEditor(editor, savedBlocks);
                clearEditorHistory(editor);
            }
        } catch (error) {
            // Ignore Slate errors
        }
    });
};

const saveBlocks = async (
    editor: Editor,
    meetingId: Id,
    topicId: Id,
    children: Descendant[],
    currentUser: User,
    operations: Operation[]
) => {
    const changes = tryComputeChanges(meetingId, topicId, children, operations);
    const [savedBlocks, replaceBlocks] = await trySaveBlocks(editor, changes, meetingId, topicId, children);
    trySynchronizeEditor(editor, currentUser, changes, savedBlocks, replaceBlocks);
};

export const serverBlocksPlugin = (
    meetingId: Id,
    topicId: Id,
    currentUser: User,
    initialBlocks?: MeetingBlock[],
    savingDebounceDelay = SavingDebounceDelay
): Plugin => ({
    initialize: (editor) => {
        const stateBlocks = getState(meetingId, topicId, (state) => state?.blocks);
        if ((stateBlocks == null || stateBlocks.length === 0) && initialBlocks != null) {
            setState(meetingId, topicId, (state) => {
                state.blocks = deserializeHtml(serializeBlocks(initialBlocks), null, false).map((block) => ({
                    ...block,
                    meeting_topic_id: topicId,
                }));
            });
        }

        // When initialing an editor, we first show cached blocks...
        const blocks = getState(meetingId, topicId, (state) => state?.pendingBlocks ?? state?.blocks);
        if (blocks != null) {
            Transforms.deselect(editor);
            editor.children = blocks;
            editor.onChange();
        }
        // ...and we then fetch fresh blocks to eventually update the editor
        if (!initialBlocks) {
            refreshEditor(editor, meetingId, topicId);
        }

        const invalidationTag = meetingBlockTag(resourceId(MeetingTopicTagType, topicId));

        const replaceBlocks = (options?: { clearHistory: boolean }) => {
            setState(meetingId, topicId, (state) => {
                state.replaceBlocks = true;
            });
            if (getState(meetingId, topicId, (state) => state?.status ?? 'idle') === 'idle') {
                refreshEditor(editor, meetingId, topicId);
                if (options?.clearHistory) {
                    clearEditorHistory(editor);
                }
            }
        };

        // ...we subscribe the WS action to react to blocks invalidation coming from other users...
        const offWebSocketEvent = onWebSocketEvent('action', (action: AnyAction) => {
            if (isInvalidationAction(action) && hasInvalidationTag(action, invalidationTag)) {
                // If an invalidation is coming from other users, we clear the editor history because it would be too
                // hard to manage concurrent history updates
                replaceBlocks({ clearHistory: true });
            }
        });

        // ...and we subscribe to local invalidation
        const offInvalidation = onInvalidation(invalidationTag, (action) => {
            // We replace blocks after a local invalidation only if it's not coming from web socket, otherwise, we would
            // replace blocks twice because we already react to web socket invalidation
            if (!action.isFromWebSocket) {
                replaceBlocks();
            }
        });

        return () => {
            // Do not forget to unsubscribe from WS actions and invalidation
            offWebSocketEvent();
            offInvalidation();
        };
    },
    onChange: async (editor, children) => {
        // If there is no topic id, we can ignore the changes as the topic may not be ready yet
        if (topicId == null) {
            return false;
        }

        // We remove selection operations as they don't update the content
        const operations = editor.operations.filter(not(or(Operation.isSelectionOperation, isNoOpOperation)));

        if (operations.length === 0) {
            return false;
        }

        // If we only update the updated properties, we can ignore the changes
        if (
            operations.every(
                (operation) =>
                    operation.type === 'set_node' &&
                    operation.newProperties &&
                    Object.keys(operation.newProperties).length > 0 &&
                    Object.keys(operation.newProperties).every((newProp) =>
                        ['updatedBy', 'updated_at'].includes(newProp)
                    )
            )
        ) {
            return false;
        }

        if (operations.some(isSkipSaveOperation)) {
            return false;
        }

        let startTimestamp = getState(meetingId, topicId, (state) => state?.startTimestamp);
        if (startTimestamp == null) {
            startTimestamp = new Date().getTime();
            setState(meetingId, topicId, (state) => {
                state.startTimestamp = startTimestamp;
            });
        }

        // If there is already a pending timeout running, clear it
        const pendingTimeout = getState(meetingId, topicId, (state) => state?.pendingTimeout);
        if (pendingTimeout != null) {
            clearTimeout(pendingTimeout);
            setState(meetingId, topicId, (state) => {
                state.pendingTimeout = null;
            });
        }

        // We update the pending blocks so even if the user switch topics, it can still see its pending changes if it goes
        // back to the topic
        setState(meetingId, topicId, (state) => {
            state.pendingBlocks = children;
        });

        // If there is already a save running, we wait for it to finish and then immediately trigger a new save to not
        // make the chained saving too long
        const savingPromise = getState(meetingId, topicId, (state) => state?.savingPromise);
        if (savingPromise != null) {
            // If there is already an abort controller, abort it...
            let savingAbortController = getState(meetingId, topicId, (state) => state?.savingAbortController);
            if (savingAbortController != null) {
                savingAbortController.abort();
            }
            try {
                // ...otherwise, we create a new abort controller and race between the abort signal promise and saving
                // promise. If the abort signal promise (i.e. if it was aborted) then the save is skipped
                savingAbortController = new AbortController();
                setState(meetingId, topicId, (state) => {
                    state.savingAbortController = savingAbortController;
                });
                await Promise.race([
                    new Promise((resolve, reject) => savingAbortController.signal.addEventListener('abort', reject)),
                    savingPromise,
                ]);
            } catch (error) {
                // If the save has been aborted, we do not try to plan a new save
                if (error.type === 'abort') {
                    return false;
                }
            }
        }
        if (operations.some(isSaveOperation) || new Date().getTime() - (startTimestamp ?? 0) >= MaximumSavingDelay) {
            setState(meetingId, topicId, (state) => {
                state.startTimestamp = null;
            });
            // If one of the operation tell us to save now, skip the pending state and save...
            void saveBlocks(editor, meetingId, topicId, children, currentUser, operations);
        } else if (operations.every(isRefreshBlocksOperation)) {
            refreshEditor(editor, meetingId, topicId);
        } else {
            // ...otherwise, plan a save after a delay
            void setState(meetingId, topicId, (state) => {
                state.status = 'pending';
                state.pendingTimeout = setTimeout(
                    () => saveBlocks(editor, meetingId, topicId, children, currentUser, operations),
                    savingDebounceDelay
                ) as unknown as number;
            });
        }

        return false;
    },
    renderAfter: (editor) => (
        <SavingIndicator key="SavingIndicator" editor={editor} meetingId={meetingId} topicId={topicId} />
    ),
});
