import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { Select } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useUser } from 'App/store/usersStore';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { MeetingRole } from 'Shared/types/meetingRole';
import { DevFeature } from 'Shared/types/user';
import { can, Permission } from 'Shared/utils/rbac';
import { getRole } from 'Shared/utils/user';
import { DEFAULT_MEETING_ROLES, DEFAULT_MEETING_ROLES as DEFAULT_ROLES } from '../RBAC/DefaultMeetingRoles';

export const defaultRoleLabels = [
    { code: DEFAULT_ROLES.EDITOR, name: `Editor` },
    { code: DEFAULT_ROLES.PARTICIPANT, name: `Participant` },
    { code: DEFAULT_ROLES.READER, name: `Reader` },
    { code: DEFAULT_ROLES.NO_ACCESS, name: `No Access` },
];

export const isDefaultRoleName = (role: MeetingRole) => {
    const label = defaultRoleLabels.find((defaultRole) => defaultRole.code === role?.code);
    if (label) {
        return label.name === role?.name || label.name === role?.name;
    }
    return false;
};

export const getRoleName = (role: MeetingRole): string => {
    if (!role) {
        return null;
    }
    return (isDefaultRoleName(role) ? getRole(role?.code) : role?.name).trim();
};

export const TagRole = ({
    meetingRole,
    defaultRoleName,
}: {
    meetingRole: MeetingRole;
    defaultRoleName?: string;
}): JSX.Element => (
    <div className={'font-bold'} style={{ color: meetingRole.color }}>
        {getRoleName(meetingRole)}
        {defaultRoleName &&
            Object.values<string>(DEFAULT_ROLES).includes(meetingRole.code) &&
            !isDefaultRoleName(meetingRole) &&
            getRole(meetingRole.code) !== meetingRole.name && (
                <span className="text-gray-400 whitespace-pre font-normal text-sm"> ({getRole(meetingRole.code)})</span>
            )}
    </div>
);

type Props = {
    roles: MeetingRole[];
    meetingRoleSelected: string;
    userId: Id;
    hasCustomRoles?: boolean;
    onUpdateRole: (role: string) => void;
    isDisabled?: boolean;
    className?: string;
    customFooter?: React.ReactNode;
};
export const RoleSelector = ({
    roles,
    meetingRoleSelected,
    userId,
    hasCustomRoles = false,
    onUpdateRole,
    isDisabled,
    className = '',
    customFooter = '',
}: Props): JSX.Element => {
    const user = useUser(userId);
    const { network } = useCurrentNetwork();

    const hasAllowExternalAsEditorFlag = useHasFeature(null, network, DevFeature.AllowExternalAsEditor);

    const getRoleColor = (selectedRole = meetingRoleSelected) => {
        const role = roles.find((i) => i.id.toString() === selectedRole);
        return role ? role.color : null;
    };

    const filteredRoles = useMemo(
        () =>
            roles?.filter((role) => {
                if (role.code === DEFAULT_ROLES.EDITOR && !hasAllowExternalAsEditorFlag) {
                    return can(user?.role, Permission.ManageMeeting);
                }
                if (!can(user?.role, Permission.HaveNonEditorRoles)) {
                    return (
                        role.code === DEFAULT_ROLES.NO_ACCESS ||
                        role.code === DEFAULT_ROLES.READER ||
                        role.code === DEFAULT_ROLES.CUSTOM
                    );
                }
                return true;
            }),
        [roles, user]
    );

    return (
        <Select
            customRenderSelected={(value: string) =>
                hasCustomRoles ? (
                    <div className="font-bold" style={{ color: getRoleColor() }}>
                        <Trans>Custom</Trans>
                        <span>
                            &nbsp;(
                            {getRoleName(roles.find(({ id }) => id === meetingRoleSelected))?.toLowerCase()})
                        </span>
                    </div>
                ) : (
                    <div className="font-bold" style={{ color: getRoleColor() }}>
                        {getRoleName(roles.find(({ id }) => id === value))}
                    </div>
                )
            }
            value={hasCustomRoles ? DEFAULT_MEETING_ROLES.CUSTOM : meetingRoleSelected}
            onChange={(value: string) => onUpdateRole(value)}
            disabled={isDisabled}
            className={className}
            listOptionsClassName="flex flex-col"
        >
            <div className="overflow-y-auto">
                {filteredRoles?.map((meetingRole) => (
                    <Select.Option key={meetingRole.id.toString()} value={meetingRole.id.toString()}>
                        <div
                            className={'font-bold'}
                            style={{ color: getRoleColor(meetingRole.id.toString()) }}
                            data-testid={`role-selector-${userId}-${meetingRole.id}`}
                        >
                            {getRoleName(meetingRole)}
                        </div>
                    </Select.Option>
                ))}
            </div>
            {customFooter}
        </Select>
    );
};
