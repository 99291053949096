import { useCallback, useLayoutEffect, useState } from 'react';
import { useEvent } from './useEvent';

export const useIsOverflowing = <T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, boolean] => {
    const [ref, setRef] = useState<T | null>(null);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

    const handleSize = useCallback(() => {
        if (ref) {
            if (ref?.scrollWidth > ref?.clientWidth || ref?.scrollHeight > ref?.clientHeight) {
                setIsOverflowing(true);
            } else {
                setIsOverflowing(false);
            }
        }
    }, [ref?.scrollWidth, ref?.clientWidth, ref?.scrollHeight, ref?.clientHeight]);

    useEvent('resize', handleSize);

    useLayoutEffect(() => {
        handleSize();
    }, [ref?.scrollWidth, ref?.clientWidth, ref?.scrollHeight, ref?.clientHeight]);

    return [setRef, isOverflowing];
};
