import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { faCheck, faUserSlash } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, colors, Tooltip } from '@wedo/design-system';
import { onEnter } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { Highlighter } from 'Shared/components/Highlighter';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { User } from 'Shared/types/user';

const classes = {
    base: 'relative flex rounded-none flex-row gap-1 py-0.5 my-px hover:bg-gray-100 cursor-pointer snap-start',
    selected: 'bg-gray-100',
    disabled: 'bg-gray-100 !cursor-not-allowed opacity-60',
};

type UserItemProps = {
    user: User;
    isSelected?: boolean;
    isChecked?: boolean;
    searchWords: string[];
    onUserSelected: (user: User) => Promise<void>;
    className?: string;
};

export const UserItem = forwardRef<HTMLButtonElement & HTMLAnchorElement, UserItemProps>(
    ({ user, isSelected = false, isChecked = false, searchWords, onUserSelected, className }, ref) => {
        const { isLoading, wrap } = useLoader();

        const handleClick = async () => {
            await wrap(onUserSelected(user));
        };

        return (
            <Button
                ref={ref}
                variant={'ghost'}
                id={`user-${user.id}`}
                onKeyDown={onEnter(handleClick)}
                onClick={handleClick}
                loading={isLoading}
                className={clsx(classes.base, isSelected && classes.selected, className)}
                spinnerClassName="w-7 flex justify-center"
            >
                {!isLoading && <UserAvatar user={user} size="sm" />}
                <span className="truncate text-sm leading-7">
                    <Highlighter
                        searchWords={searchWords}
                        highlightStyle={{ background: colors.yellow[300] }}
                        textToHighlight={user.full_name}
                    />
                </span>
                {isChecked && (
                    <div className="absolute right-0 flex h-7 items-center pr-2 text-blue-600">
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                )}
            </Button>
        );
    }
);

type UserItemButtonProps = {
    user: User;
    onUserSelected: (user: User) => void;
};

export const UserItemButton = ({ user, onUserSelected }: UserItemButtonProps) => {
    return (
        <Button variant="text" size="noText" className="!bg-transparent" onClick={() => onUserSelected(user)}>
            <UserAvatar user={user} size="sm" />
        </Button>
    );
};
