import React from 'react';
import {
    faArrowAltRight,
    faBriefcase,
    faDownload,
    faEllipsisV,
    faExternalLink,
    faHistory,
    faLink,
    faPen,
    faReply,
    faSearchPlus,
    faTrash,
    faUnlink,
    faUpload,
} from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { ButtonVariant, Dropdown, Size, useConfirm, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { preventDefault, stopPropagation } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { EditUrlFileModal } from 'Shared/components/file/EditUrlFileModal';
import { RenameAttachmentModal } from 'Shared/components/file/RenameAttachmentModal';
import { FileVersioningModal } from 'Shared/components/file/fileDetails/FileVersioningModal';
import { MoveFolderModal } from 'Shared/components/file/fileList/MoveFolderModal';
import { DownloadHistoryModal } from 'Shared/components/file/fileList/table/DownloadHistoryModal';
import { useDownloadAttachment } from 'Shared/hooks/useDownloadAttachment';
import {
    invalidateAttachment,
    invalidateGetAttachments,
    invalidateGetFolders,
    useAddToTagMutation,
    useRestoreAttachmentVersionMutation,
} from 'Shared/services/attachment';
import { Attachment, AttachmentRelation, AttachmentVersion } from 'Shared/types/attachment';
import { Workspace } from 'Shared/types/workspace';
import { getAttachmentUrl, isAttachmentPreviewable, isUrlFile } from 'Shared/utils/attachment';
import { Permission } from 'Shared/utils/rbac';

type AttachmentMenuProps = {
    attachment: Partial<Attachment>;
    relation: Partial<AttachmentRelation>;
    isReadonly?: boolean;
    isDisabled?: boolean;
    isAll?: boolean;
    isVersion?: boolean;
    workspaceId?: Id;
    workspaces?: Workspace[];
    onReload?: (attachmentId?: Id, attachmentRelation?: AttachmentRelation, refreshTopicEditor?: boolean) => void;
    onDelete?: (attachmentId: Id) => void;
    variant?: ButtonVariant;
    className?: string;
    size?: Size | 'xs';
};
export const AttachmentMenu = ({
    attachment,
    relation,
    isReadonly,
    isDisabled,
    isAll,
    isVersion,
    workspaceId,
    workspaces,
    variant,
    className,
    size = 'sm',
    onReload,
    onDelete,
}: AttachmentMenuProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [addToTag] = useAddToTagMutation();
    const [restoreAttachment] = useRestoreAttachmentVersionMutation();

    const { can, currentUserId } = useCurrentUserContext();
    const { confirm } = useConfirm();
    const { open: openModal } = useModal();
    const { downloadAttachment } = useDownloadAttachment(attachment);

    const isUrlFileLink = isUrlFile(attachment);
    const attachmentTag = !isAll && !isVersion ? attachment?.tags?.[0] : undefined;
    const canDeleteFile = !isReadonly && !attachmentTag && (!isVersion || !attachment?.lock);
    const canRemoveFile = !isReadonly && attachmentTag;

    const handleOpen = () => {
        window.open(attachment?.preview_url || attachment?.open_url, '_blank');
    };

    const handleOpenLocation = () => {
        navigate({
            pathname: `/workspaces/${attachmentTag.id}/files`,
            searchParams: {
                view: 'shared',
                folder: attachment?.folder_id?.toString(),
                file: attachment.id.toString(),
            },
        });
    };

    const handleRenameClick = () => {
        openModal(RenameAttachmentModal, {
            attachment,
            relation,
            onDone: (res: { attachment: Attachment; attachmentVersion: AttachmentVersion }) => {
                if (res?.attachment != null) {
                    onReload(res.attachment.id);
                }
            },
        });
    };

    const handleMoveClick = () => {
        openModal(MoveFolderModal, {
            workspaceId,
            selectedFiles: [{ ...attachment, type: 'file' }],
            onDone: (attachments: Attachment[]) => {
                if (attachments) {
                    dispatch(invalidateGetAttachments());
                    dispatch(invalidateGetFolders());
                    onReload();
                }
            },
        });
    };

    const handleVersioning = () => {
        openModal(FileVersioningModal, {
            attachmentId: attachment?.id,
        });
    };

    const handleReplaceClick = () => {
        openModal(AddAttachmentModal, {
            relation,
            allowedSources: ['upload'],
            attachmentId: attachment.id,
            onDone: (attachments: Attachment[]) => {
                if (attachments?.length > 0) {
                    attachments.map((attachment) => dispatch(invalidateAttachment(attachment.id)));
                    dispatch(invalidateAttachment(attachment.id));
                    onReload(attachments[0].id);
                }
            },
        });
    };

    const handleRestoreClick = async () => {
        await confirm({
            type: 'primary',
            title: t`Do you want to restore the file?`,
            confirmText: t`Restore`,
            onConfirm: async () => {
                await restoreAttachment({ attachmentId: attachment.attachment_id, attachmentVersionId: attachment.id });
                onReload();
                dispatch(invalidateAttachment(attachment.id));
                dispatch(invalidateGetAttachments());
            },
        });
    };

    const handleDeleteClick = async () => {
        await confirm({
            type: 'danger',
            title: attachmentTag
                ? t`Are you sure you want to remove this file?`
                : t`Are you sure you want to delete this file?`,
            onConfirm: () => onDelete(attachment?.id),
            confirmText: attachmentTag ? t`Remove` : t`Delete`,
            content: <div className="font-bold truncate max-w-80">{attachment?.filename}</div>,
        });
    };

    const handleAddTo = async (workspaceId: Id) => {
        await addToTag({ tagId: workspaceId, attachmentId: attachment.id });
        onReload();
        dispatch(invalidateAttachment(attachment.id));
    };

    const handleMenuClick = (): void => {
        if (attachment == null || attachment?.download_url == null || isDisabled) {
            onReload();
        }
    };

    const handleEditUrlFile = () => {
        void openModal(EditUrlFileModal, {
            attachment,
            relation: { ...relation, tag_id: workspaceId },
            onDone: (attachment: Attachment) => onReload(attachment.id, relation, true),
        });
    };

    const handleDownloadHistory = () => {
        openModal(DownloadHistoryModal, { attachmentId: attachment.id });
    };

    return (
        <Dropdown
            size={size}
            icon={faEllipsisV}
            variant={variant}
            onOpen={handleMenuClick}
            data-testid="attachment-dropdown"
            className={className}
        >
            {isAttachmentPreviewable(attachment) && (
                <Dropdown.Item icon={faSearchPlus} onClick={handleOpen} disabled={isDisabled}>
                    <Trans id="The action of opening">Open</Trans>
                </Dropdown.Item>
            )}

            {!isUrlFileLink && (
                <Dropdown.Item icon={faDownload} onClick={() => downloadAttachment(attachment)} disabled={isDisabled}>
                    <Trans>Download</Trans>
                </Dropdown.Item>
            )}

            {isUrlFileLink && (
                <Dropdown.Item
                    icon={faLink}
                    onClick={stopPropagation(preventDefault(() => window.open(getAttachmentUrl(attachment))))}
                    disabled={isDisabled}
                >
                    <Trans>Open link</Trans>
                </Dropdown.Item>
            )}

            {!isReadonly && !attachmentTag && !isVersion && can(Permission.ManageFiles) && !isUrlFileLink && (
                <Dropdown.Item icon={faPen} onClick={handleRenameClick} disabled={isDisabled}>
                    <Trans>Rename</Trans>
                </Dropdown.Item>
            )}

            {!isReadonly && can(Permission.ManageFiles) && isUrlFileLink && !attachmentTag && (
                <Dropdown.Item icon={faPen} onClick={handleEditUrlFile}>
                    <Trans>Edit</Trans>
                </Dropdown.Item>
            )}

            {!isReadonly &&
                !isAll &&
                !attachmentTag &&
                can(Permission.ManageFiles) &&
                workspaces &&
                workspaces.map((workspace) => (
                    <Dropdown.Item
                        key={workspace.id}
                        icon={faBriefcase}
                        onClick={() => handleAddTo(workspace.id)}
                        disabled={isDisabled}
                    >
                        <span className="overflow-hidden">
                            <Trans>
                                Share with <b>{workspace.name}</b>
                            </Trans>
                        </span>
                    </Dropdown.Item>
                ))}

            {isAll && attachment?.tags?.length > 0 && can(Permission.ManageFiles) && (
                <Dropdown.Item icon={faArrowAltRight} onClick={handleMoveClick}>
                    <Trans>Move to</Trans>
                </Dropdown.Item>
            )}

            {!isReadonly && !attachmentTag && !isVersion && can(Permission.ManageFiles) && !isUrlFileLink && (
                <Dropdown.Item icon={faUpload} onClick={handleReplaceClick}>
                    <Trans>Replace file</Trans>
                </Dropdown.Item>
            )}

            {!isReadonly && isVersion && can(Permission.ManageFiles) && (
                <Dropdown.Item icon={faReply} onClick={handleRestoreClick}>
                    <Trans>Restore</Trans>
                </Dropdown.Item>
            )}

            {isAll && can(Permission.ManageFiles) && !isUrlFileLink && (
                <>
                    <Dropdown.Item icon={faHistory} onClick={handleVersioning}>
                        <Trans>Versioning</Trans>
                    </Dropdown.Item>

                    <Dropdown.Item icon="downloadHistory" onClick={handleDownloadHistory}>
                        <Trans>Download history</Trans>
                    </Dropdown.Item>
                </>
            )}

            {!isReadonly && attachmentTag && (
                <Dropdown.Item icon={faExternalLink} onClick={handleOpenLocation}>
                    <Trans>Open file location</Trans>
                </Dropdown.Item>
            )}

            {(canDeleteFile || canRemoveFile) && can(Permission.ManageFiles) && <Dropdown.DividerItem />}

            {canDeleteFile &&
                (can(Permission.ManageFiles) || attachment?.currentVersion?.created_by === currentUserId) && (
                    <Dropdown.Item icon={faTrash} onClick={handleDeleteClick} danger={true}>
                        <Trans>Delete file</Trans>
                    </Dropdown.Item>
                )}

            {canRemoveFile && can(Permission.ManageFiles) && (
                <Dropdown.Item icon={faUnlink} onClick={handleDeleteClick} danger={true}>
                    <Trans>Remove file</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
