import React from 'react';
import { BasicCustomFieldModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/BasicCustomFieldModalForm';
import { CustomFieldModalSectionProps } from 'Pages/settings/customFields/types/customFieldModalSectionProps';
import { CustomFieldType, NumberCustomField } from 'Shared/types/customField';

export const NumberModalForm: React.FC<CustomFieldModalSectionProps<NumberCustomField>> = ({
    onSuccess,
    onCancel,
    field,
    groupId,
}) => {
    return (
        <BasicCustomFieldModalForm
            onSuccess={onSuccess}
            onCancel={onCancel}
            fieldType={CustomFieldType.Number}
            field={field}
            groupId={groupId}
        />
    );
};
