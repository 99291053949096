import React, { useEffect, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import {
    Button,
    Card,
    Input,
    ItemGroup,
    SavedSuccessNotification,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { DIGITS, EmptyString, EN_LETTERS, getBreakpointValue, isAlpha } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { CheckCompliance } from 'Shared/components/CheckCompliance';
import { useGetAdminNetworkQuery, useGetCurrentNetworkQuery, useUpdateNetworkMutation } from 'Shared/services/network';
import { ApiError } from 'Shared/types/apiError';
import { Network } from 'Shared/types/network';

const VALID_SHORT_NAME_CHARS = new Set([...EN_LETTERS, '-', '_', ...DIGITS]);

export const OrganisationCard = () => {
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { width: viewportWidth } = useWindowSize();

    const { data: network } = useGetCurrentNetworkQuery();
    const { data: adminNetwork } = useGetAdminNetworkQuery();
    const [updateNetwork, { isLoading }] = useUpdateNetworkMutation();

    const [shortName, setShortName, handleShortName] = useInputState(EmptyString);
    const [name, setName, handleName] = useInputState(EmptyString);

    const hasUserChangedShortName = !isEqual(network?.short_name?.trim(), shortName?.trim());
    const hasUserChangedName = !isEqual(network?.name?.trim(), name?.trim());
    const shortNameIsBetween3and20chars = shortName?.trim()?.length <= 20 && shortName?.trim()?.length >= 3;
    const isNameValidLength = name?.trim()?.length >= 3 && name?.trim()?.length <= 50;
    const isUpdateNameDisabled = !isNameValidLength || !hasUserChangedName;

    const hasChanged = hasUserChangedName || hasUserChangedShortName;

    const doesShortNameContainOnlyLowercase = useMemo<boolean>(() => {
        for (let i = 0; i < shortName?.length; i++) {
            if (isAlpha(shortName[i]) && !VALID_SHORT_NAME_CHARS.has(shortName[i])) {
                return false;
            }
        }
        return true;
    }, [shortName]);

    const doesShortNameContainOnlyAllowedSpecialChars = useMemo<boolean>(() => {
        for (let i = 0; i < shortName?.length; i++) {
            if (!isAlpha(shortName[i]) && !VALID_SHORT_NAME_CHARS.has(shortName[i])) {
                return false;
            }
        }
        return true;
    }, [shortName]);

    const doesShortNameContainValidChars =
        doesShortNameContainOnlyLowercase && doesShortNameContainOnlyAllowedSpecialChars;

    const isUpdateShortNameDisabled =
        !shortNameIsBetween3and20chars || !doesShortNameContainValidChars || !hasUserChangedShortName;

    const isSaveDisabled = isUpdateShortNameDisabled && isUpdateNameDisabled;

    useEffect(() => {
        setShortName(network?.short_name);
        setName(network?.name);
    }, [network]);

    const updateOrganisation = async () => {
        const updateObject: Partial<Network> = {};
        if (hasUserChangedName) {
            updateObject.name = name;
        }
        if (hasUserChangedShortName) {
            updateObject.short_name = shortName;
        }

        const response = await updateNetwork(updateObject);
        if ('error' in response) {
            const error = response.error as ApiError;
            if (error.matches({ code: 'ConflictError' })) {
                show({
                    type: 'danger',
                    title: t`${shortName} is a reserved keyword and can't be used for the URL`,
                });
            } else if (error.matches({ code: 'DuplicateError' })) {
                show({
                    type: 'danger',
                    title: t`The URL ${shortName} is already in use, please choose some other URL`,
                });
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            show(SavedSuccessNotification);
        }
    };

    const handleUpdateOrganisationShortName = async () => {
        await confirm({
            type: 'warning',
            title: t`Warning`,
            content: (
                <div>
                    <p className="mt-2">
                        <Trans>If you change your URL, every user in your organization will need to:</Trans>
                    </p>
                    <p>
                        <ul className="ml-4 mt-1 list-disc">
                            <li>
                                <Trans>Update their bookmarks</Trans>
                            </li>
                            <li>
                                <Trans>Update their ICS link for calendar sync</Trans>
                            </li>
                            {adminNetwork?.config?.saml?.enable && (
                                <li className="font-bold">
                                    <Trans>Update your SAML identity provider</Trans>
                                </li>
                            )}
                        </ul>
                    </p>
                </div>
            ),
            confirmText: t`Update URL`,
            onConfirm: updateOrganisation,
        });
    };

    const handleSave = async () => {
        if (hasUserChangedShortName) {
            await handleUpdateOrganisationShortName();
        } else {
            await updateOrganisation();
        }
    };

    const handleReset = async () => {
        setName(network?.name);
        setShortName(network?.short_name);
    };

    return (
        <Card>
            <Card.Header title={t`Organisation`} />

            <Card.Body>
                <div>
                    <h4 className="mb-2 text-base font-semibold">
                        <Trans>Name</Trans>
                    </h4>

                    <Input
                        value={name}
                        onChange={handleName}
                        onPressEnter={!isSaveDisabled && handleSave}
                        className="w-full"
                    />

                    {hasUserChangedName && (
                        <div className="mt-4">
                            <CheckCompliance text={t`Between 3 and 50 characters`} isPassing={isNameValidLength} />
                        </div>
                    )}
                </div>

                <div className="mt-5">
                    <h4 className="mb-2 text-base font-semibold">
                        <Trans>URL</Trans>
                    </h4>
                    <ItemGroup>
                        {viewportWidth >= getBreakpointValue('sm') ? <Input.Addon text={'https://'} /> : undefined}
                        <Input value={shortName} onChange={handleShortName} className="w-full" />
                        <Input.Addon text={'.wedo.app'} />
                    </ItemGroup>

                    {hasUserChangedShortName && (
                        <div className="mt-4">
                            <CheckCompliance
                                text={t`Between 3 and 20 characters`}
                                isPassing={shortNameIsBetween3and20chars}
                            />
                            <CheckCompliance
                                text={t`Contains only lowercase letters`}
                                isPassing={doesShortNameContainOnlyLowercase}
                            />
                            <CheckCompliance
                                text={t`Contains only allowed special characters`}
                                isPassing={doesShortNameContainOnlyAllowedSpecialChars}
                            />
                        </div>
                    )}
                </div>
            </Card.Body>

            <Card.Footer>
                <Button onClick={handleSave} color="primary" disabled={isSaveDisabled} loading={isLoading}>
                    <Trans>Save</Trans>
                </Button>
                {hasChanged && (
                    <Button onClick={handleReset} className="ml-3">
                        <Trans>Reset</Trans>
                    </Button>
                )}
            </Card.Footer>
        </Card>
    );
};
