import { useLocation } from 'react-router-dom';
import { msg, t } from '@lingui/macro';
import { ExtendableSearchInput } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { PageNotFoundErrorCard } from 'Pages/AppPage/RoutingError';
import { SignaturesList } from 'Pages/SignaturesPage/components/SignaturesList';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { DevFeature } from 'Shared/types/user';
import { SignaturesPageSearchParams } from 'Shared/utils/signature';

const SignaturesTabs: NavBarTab[] = [
    {
        title: msg`Pending`,
        matchSearchParams: ['view'],
        to: { searchParams: { view: 'pending' } },
        isDefault: true,
    },
    {
        title: msg`All`,
        matchSearchParams: ['view'],
        to: { searchParams: { view: 'all' } },
    },
];

export const SignaturesPage = () => {
    usePageTitle(t`Signatures`);
    const currentUser = useSessionUser();
    const { network: currentNetwork } = useCurrentNetwork();
    const location = useLocation();
    const hasSignatureFeature = useHasFeature(currentUser, currentNetwork, DevFeature.Signature);

    const [searchParams, setSearchParams] = useSearchParams(SignaturesPageSearchParams);
    const { search } = searchParams;
    const setSearch = (search: string) => {
        setSearchParams({ ...searchParams, search });
    };

    if (!hasSignatureFeature) {
        return <PageNotFoundErrorCard />;
    }

    return (
        <div className="flex h-full flex-col overflow-hidden">
            <div className="pt-1 flex flex-col overflow-hidden">
                <div className="px-6 shrink-0">
                    <NavBar basePath={location.pathname} tabs={SignaturesTabs}>
                        <ExtendableSearchInput onSearch={setSearch} search={search} />
                    </NavBar>
                </div>
                <div className="px-6 my-4 overflow-auto">{<SignaturesList />}</div>
            </div>
        </div>
    );
};
