import { FC } from 'react';
import { faUserTimes } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { TimeLineItem } from 'Shared/components/history/TimeLineItem';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useGetUserQuery } from 'Shared/services/user';
import { User } from 'Shared/types/user';

export const RefuseMembershipTimeLineItem: FC<TimeLineItem> = ({ activity, entityType }) => {
    const requester = activity?.old_value as unknown as User;

    const { data: user } = useGetUserQuery(requester?.id);
    const { currentUserId } = useCurrentUserContext();

    if (entityType === 'workspace') {
        if (currentUserId === requester?.id) {
            return (
                <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faUserTimes}>
                    <p>
                        <Trans>
                            <UserFullName fullName={activity?.created_by?.full_name} /> refused your request to join the
                            workspace.
                        </Trans>
                    </p>
                </Timeline.Item>
            );
        }

        return (
            <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faUserTimes}>
                <p>
                    <Trans>
                        <UserFullName fullName={activity?.created_by?.full_name} /> refused membership to the workspace{' '}
                        to <UserAvatar user={user} wrapperClassName="inline-flex align-bottom mr-1" />{' '}
                        <span>{requester?.full_name}</span>.
                    </Trans>
                </p>
            </Timeline.Item>
        );
    }

    if (currentUserId === requester?.id) {
        return (
            <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faUserTimes}>
                <p>
                    <Trans>
                        <UserFullName fullName={activity?.created_by?.full_name} /> refused your request to join the
                        template.
                    </Trans>
                </p>
            </Timeline.Item>
        );
    }

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faUserTimes}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> refused membership to the template to{' '}
                    <UserAvatar user={user} wrapperClassName="inline-flex align-bottom mr-1" />{' '}
                    <span>{requester?.full_name}</span>.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
