import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { UnexpectedErrorNotification, UploadButton, useNotification } from '@wedo/design-system';
import { UploadUsersReturn, useUploadUsersMutation } from 'Shared/services/admin';
import { ApiError } from 'Shared/types/apiError';

type ChooseAFileButtonProps = {
    onUpload: (data: UploadUsersReturn[]) => void;
};

export const ChooseFileButton: FC<ChooseAFileButtonProps> = ({ onUpload }) => {
    const [uploadUsers] = useUploadUsersMutation();
    const { show } = useNotification();

    const handleUpload = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        const result = await uploadUsers(formData);

        if ('data' in result) {
            onUpload(result.data);
        }

        if ('error' in result && result.error instanceof ApiError) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <UploadButton color="primary" onFileSelect={handleUpload} accept=".xlsx">
            <Trans>Upload users file</Trans>
        </UploadButton>
    );
};
