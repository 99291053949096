import { useCallback, useMemo } from 'react';
import { NavigateOptions, useSearchParams as useUrlSearchParams } from 'react-router-dom';
import { getObjectWithoutKeys } from '../object';
import { stripSearchParams } from '../router';
import { type Schema } from '../schema';

export const useSearchParams = <
    T extends Record<string, Schema<unknown>>,
    R = { [K in keyof T]: T[K] extends Schema<infer U> ? U : never },
>(
    schema?: T
): [
    R,
    (value: Partial<R> | ((previousValue: R) => Partial<R>), options?: NavigateOptions) => void,
    (params: [keyof R]) => void,
] => {
    const [urlSearchParams, setUrlSearchParams] = useUrlSearchParams();

    const searchParams = useMemo(() => {
        return schema == null
            ? Array.from(urlSearchParams.keys()).reduce(
                  (result, key) => ({ ...result, [key]: urlSearchParams.getAll(key) }),
                  {}
              )
            : Object.fromEntries(
                  Object.entries(schema).map(([key, schema]) => [key, schema.validate(urlSearchParams.getAll(key))])
              );
    }, [urlSearchParams]) as R;

    const setSearchParams = useCallback(
        (value, options) =>
            setUrlSearchParams(stripSearchParams(value instanceof Function ? value(searchParams) : value), options),
        [setUrlSearchParams]
    );

    const removeSearchParams = useCallback(
        (params: [keyof R]) => setSearchParams(getObjectWithoutKeys(searchParams, params), { replace: true }),
        [searchParams, setSearchParams]
    );
    return [searchParams, setSearchParams, removeSearchParams];
};
