import { LocaleDefinition } from '@wedo/types';

export const de: LocaleDefinition = {
    DATETIME_FORMATS: {
        AMPMS: ['vorm.', 'nachm.'],
        DAY: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        FIRSTDAYOFWEEK: 0,
        MONTH: [
            'Januar',
            'Februar',
            'M\u00e4rz',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        SHORTDAY: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        SHORTMONTH: [
            'Jan.',
            'Feb.',
            'M\u00e4rz',
            'Apr.',
            'Mai',
            'Juni',
            'Juli',
            'Aug.',
            'Sep.',
            'Okt.',
            'Nov.',
            'Dez.',
        ],
        WEEKENDRANGE: [5, 6],
        fullDate: 'eeee, d. MMMM y',
        longDate: 'd. MMMM y',
        medium: 'dd.MM.y HH:mm:ss',
        mediumDate: 'dd.MM.y',
        mediumTime: 'HH:mm:ss',
        short: 'dd.MM.Y HH:mm',
        shortDate: 'dd.MM.y',
        shortTime: 'HH:mm',
    },
    NUMBER_FORMATS: {
        DECIMAL_SEP: ',',
        GROUP_SEP: '.',
    },
};
