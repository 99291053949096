import { I18n } from '@lingui/core';
import { format as dfnsFormatDate } from 'date-fns';
import { getDateFnsLocale } from '../date';
import { getLocale } from './locales/getLocale';

export const formatDate = (rawDate: string | Date, format: string, i18n?: I18n): string => {
    const date = new Date(rawDate);
    // If date is invalid (when date is passed as undefined)
    if (isNaN(date.getTime())) {
        return null;
    }
    if (i18n) {
        const locale = getLocale(i18n.locale);
        if (locale.DATETIME_FORMATS[format]) {
            return dfnsFormatDate(date, locale.DATETIME_FORMATS[format], { locale: getDateFnsLocale(i18n.locale) });
        }
        return dfnsFormatDate(date, format, { locale: getDateFnsLocale(i18n.locale) });
    }
    return dfnsFormatDate(date, format);
};

export const formatTime = (date: Date | string) => {
    if (typeof date === 'string') {
        return dfnsFormatDate(new Date(date), 'HH:mm');
    }
    return dfnsFormatDate(date, 'HH:mm');
};
