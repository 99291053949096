import React, { FC, useState } from 'react';
import { Plural, t } from '@lingui/macro';
import { ConfirmModal, ContextModalProps } from '@wedo/design-system';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { ApplyOnRadioGroup } from 'Shared/components/task/TaskDetail/ApplyOnRadioGroup';
import { Task, TaskType } from 'Shared/types/task';
import { getNumberOfOpenSubTasks } from 'Shared/utils/task';

type ConfirmCompleteTaskWithSubtasksProps = { task: Task } & ContextModalProps;

export const ConfirmSubTasksTitle: FC<{ task: Task; openSubTaskCount: number }> = ({ task, openSubTaskCount }) => {
    return task?.type === TaskType.Task ? (
        <Plural
            value={openSubTaskCount}
            one={`This task has 1 open subtask`}
            other={`This task has ${openSubTaskCount} open subtasks`}
        />
    ) : (
        <Plural
            value={openSubTaskCount}
            one={`This milestone has 1 open subtask`}
            other={`This milestone has ${openSubTaskCount} open subtasks`}
        />
    );
};

export const ConfirmCompleteTaskWithSubtasks = ({ task, ...modalProps }: ConfirmCompleteTaskWithSubtasksProps) => {
    const [applyOn, setApplyOn] = useState(ApplyOn.AllTasks);

    const openSubTaskCount = getNumberOfOpenSubTasks(task);
    const completedTasksCount = applyOn === ApplyOn.OnlyCurrentTask ? 1 : openSubTaskCount + 1;

    return (
        <ConfirmModal
            {...modalProps}
            onConfirm={async () => applyOn}
            title={<ConfirmSubTasksTitle task={task} openSubTaskCount={openSubTaskCount} />}
            confirmText={<Plural value={completedTasksCount} one={`Complete task`} other={`Complete all subtasks`} />}
            type="warning"
            content={
                <ApplyOnRadioGroup
                    value={applyOn}
                    onChange={setApplyOn}
                    allOptionText={t`Complete all open subtasks`}
                    currentTaskOptionText={t`Only complete current task`}
                />
            }
        />
    );
};
