import React, { FC, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { TagSelect, TagSelectProps, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString, isEmpty } from '@wedo/utils';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useUpdateWorkspaceMutation } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { Workspace } from 'Shared/types/workspace';

type WorkspaceTagSelectProps = {
    value: Id[];
    onChange: (values: Id[]) => void;
    canAdd?: boolean;
    canEdit?: boolean;
} & Pick<TagSelectProps, 'size' | 'forceSingleLine' | 'inputClassName' | 'placeholder'>;

export const WorkspaceTagSelect: FC<WorkspaceTagSelectProps> = ({
    value,
    onChange,
    canEdit = false,
    canAdd = false,
    size = 'md',
    forceSingleLine = false,
    inputClassName = EmptyString,
    placeholder = EmptyString,
}) => {
    const { show } = useNotification();
    const { data: workspaces, isLoading } = trpc.workspace.list.useQuery();

    const [updateWorkspace] = useUpdateWorkspaceMutation();

    const workspaceContextValues = workspaces?.map(({ id, name, color }) => ({ id, name, color: color.background }));

    const [search, setSearch] = useState<string>(EmptyString);
    const [editingIds, { add: addEditingId, remove: removeEditingId }] = useSet<Id>();

    const filteredWorkspaces = useMemo<Workspace[]>(() => {
        if (isEmpty(search)) {
            return workspaces;
        }
        return workspaces?.filter(({ name }) => name.toLowerCase().includes(search.trim().toLowerCase()));
    }, [search, workspaces]);

    const handleEditWorkspaceName = async (workspace: Workspace, name: string) => {
        addEditingId(workspace.id);
        const response = await updateWorkspace({ id: workspace.id, name });
        removeEditingId(workspace.id);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleWorkspaceColorChange = async (workspace: Workspace, color: string) => {
        addEditingId(workspace.id);
        const response = await updateWorkspace({
            id: workspace.id,
            color: { text: workspace.color.text, background: color },
        });
        removeEditingId(workspace.id);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <TagSelect
            multiple
            forceSingleLine={forceSingleLine}
            contextValues={workspaceContextValues}
            value={value}
            onChange={onChange}
            search={search}
            onSearch={setSearch}
            placeholder={placeholder}
            size={size}
            inputClassName={inputClassName}
        >
            {filteredWorkspaces?.length === 0 && !isEmpty(search) && workspaces?.length > 0 && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No workspaces found</Trans>
                </div>
            )}
            {workspaces?.length === 0 && !isLoading && isEmpty(search) && !canAdd && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No workspaces in network.</Trans>
                </div>
            )}
            {workspaces?.length === 0 && !isLoading && isEmpty(search) && canAdd && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No workspaces in network, start typing to create new workspaces.</Trans>
                </div>
            )}
            {filteredWorkspaces?.map((workspace) => (
                <TagSelect.Option
                    isLoading={editingIds?.has(workspace.id)}
                    key={workspace.id}
                    id={workspace.id}
                    name={workspace.name}
                    color={workspace.color.background}
                    onRemove={undefined}
                    onEdit={canEdit ? (name: string) => handleEditWorkspaceName(workspace, name) : undefined}
                    onColorChange={
                        canEdit ? (color: string) => handleWorkspaceColorChange(workspace, color) : undefined
                    }
                />
            ))}
        </TagSelect>
    );
};
