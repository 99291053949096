import React, { FC, useMemo } from 'react';
import { EmailToTaskButton } from 'Shared/components/settings/EmailToTaskButton';
import { useGetSurrogateKeyQuery } from 'Shared/services/surrogateKey';
import { User } from 'Shared/types/user';

type UserEmailToTaskProps = {
    user: User;
    className?: string;
};

export const UserEmailToTask: FC<UserEmailToTaskProps> = ({ user, className }) => {
    const {
        data: userSurrogateKey,
        isLoading,
        isError,
    } = useGetSurrogateKeyQuery({ id: user.id, property: 'user_id' });

    const email = useMemo(() => {
        if (typeof user?.id !== typeof '') {
            return '';
        }

        return `"${user.full_name}" <${userSurrogateKey?.key}@mail.wedo.app>`;
    }, [user, userSurrogateKey?.key]);

    return <EmailToTaskButton disabled={isError || isLoading} email={email} className={className} />;
};
