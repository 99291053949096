import { Editor } from 'slate';
import { Plugin } from '../../Editor';
import { paste } from './paste';
import { serializeBlocks } from './serializeBlocks';

export const HtmlContentType = 'text/html';
export const RtfContentType = 'text/rtf';
export const TextContentType = 'text/plain';

const PasteableContentType = ['application/x-slate-nodes', TextContentType, HtmlContentType, 'Files'];

const DropableContentType = ['application/x-slate-nodes', TextContentType, HtmlContentType];

export const copyPastePlugin = (): Plugin => ({
    onBeforeInput: (editor, event) => {
        if (
            event.inputType === 'insertFromPaste' &&
            event.dataTransfer?.types?.some((type) => PasteableContentType.includes(type)) === true
        ) {
            event.preventDefault();
            void paste(editor, event.dataTransfer);
            return true;
        }
        return false;
    },
    onDrop: (editor, event) => {
        if (event.dataTransfer?.types?.some((type) => DropableContentType.includes(type))) {
            event.preventDefault();
            void paste(editor, event.dataTransfer);
            return true;
        }
        return false;
    },
    onPaste: (editor, event) => {
        if (event.clipboardData?.types?.some((type) => PasteableContentType.includes(type))) {
            event.preventDefault();
            void paste(editor, event.clipboardData);
            return true;
        }
        return false;
    },
    setFragmentData: (editor, data) => {
        if (editor.selection != null) {
            const html = serializeBlocks(Editor.fragment(editor, editor.selection));
            data.setData(HtmlContentType, html);
            data.setData(
                TextContentType,
                Array.from(
                    new DOMParser()
                        .parseFromString(html, HtmlContentType)
                        .body.querySelectorAll('p, [data-wedo-attachments], [data-wedo-task], [data-wedo-vote]')
                )
                    .map((element) => element.innerText)
                    .join('\n')
            );
            return true;
        }
        return false;
    },
});
