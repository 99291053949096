import React from 'react';
import { useParams } from 'react-router-dom';
import { faCircleDashed } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Alert, EmptyState, Skeleton, Table } from '@wedo/design-system';
import { parseSort, stringifySort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { ChecklistsPageSearchParams } from 'Pages/ChecklistsPage/ChecklistsPage';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useGetChecklistsQuery } from 'Shared/services/checklist';
import { useGetChecklistsByTemplateQuery, useGetChecklistTemplateQuery } from 'Shared/services/template';
import { ChecklistListViewTableRow } from './ChecklistListViewTableRow';

export type SortColumn = 'checklist.name' | 'tasks' | 'progression' | 'reference_date';

const ChecklistsInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { templateId, workspaceId } = useParams();
    const [{ search, status, sort }] = useSearchParams(ChecklistsPageSearchParams);

    const params = {
        id: workspaceId ?? templateId,
        search,
        archived: status === 'archived' ? true : status === 'open' ? false : undefined,
        deleted: status === 'deleted' ? true : status === 'open' ? false : undefined,
        ...parseSort(sort),
        offset,
        limit,
    };

    const checklists = useGetChecklistsQuery(params, { skip: workspaceId == null });
    const templateChecklists = useGetChecklistsByTemplateQuery(params, { skip: templateId == null });
    const { data, isLoading, isFetching, isError, error } = workspaceId != null ? checklists : templateChecklists;

    updateInfiniteScroll(data?.data);

    return (
        <>
            {isLoading ? (
                Array.from({ length: 4 }, (_, i) => (
                    <Table.Row key={i}>
                        {Array.from({ length: 4 }, (_, j) => (
                            <Table.Cell key={j}>
                                <Skeleton className={'h-10'} />
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))
            ) : isError ? (
                <Alert type="danger" title={error?.message ?? ''} />
            ) : (
                data.data.map((checklist) => (
                    <ChecklistListViewTableRow key={checklist.id} checklist={checklist} isLoading={isFetching} />
                ))
            )}
        </>
    );
};

export const ChecklistsListView = () => {
    const [{ search, sort }, setSearchParams] = useSearchParams(ChecklistsPageSearchParams);

    const { sortColumn, sortDirection } = parseSort(sort);

    const { templateId } = useParams();
    const { data: template } = useGetChecklistTemplateQuery(templateId);
    const { isCurrentUserModerator } = useManageMembers(template);

    const handleSort = (column: SortColumn) => {
        setSearchParams((searchParams) => ({
            ...searchParams,
            sort: stringifySort(column, sortDirection === 'descending' ? 'ascending' : 'descending'),
        }));
    };

    const sortProps = (name: SortColumn) => ({
        onSort: () => handleSort(name),
        sortDirection: name === sortColumn ? sortDirection : null,
    });

    return (
        <div className={'@container flex flex-col px-6 overflow-y-auto pb-4'}>
            <Table wrapperClassName={'max-h-full'} className={'overflow-hidden !flex !flex-col !h-full'} hoverable>
                <Table.Head trClassName={'flex w-full'}>
                    <Table.HeadCell
                        className={'@2xl:w-[40%] @lg:w-[50%] @md:w-[60%] w-[80%]'}
                        {...sortProps('checklist.name')}
                    >
                        <Trans>Name</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className={'@2xl:table-cell hidden w-[10%]'} {...sortProps('tasks')}>
                        <Trans>Tasks</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell
                        className={'@lg:w-[20%] @md:w-[30%] hidden @md:table-cell'}
                        {...sortProps('progression')}
                    >
                        <Trans>Progression</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className={'@lg:table-cell hidden w-[20%]'} {...sortProps('reference_date')}>
                        <Trans>Reference date</Trans>
                    </Table.HeadCell>

                    {isCurrentUserModerator && <Table.HeadCell className={'w-[20%] @md:w-[10%]'} />}
                </Table.Head>
                <InfiniteScroll
                    as="tbody"
                    className="divide-y divide-gray-200 bg-white"
                    page={ChecklistsInfiniteScrollPage}
                    emptyPage={
                        <Table.Row>
                            <Table.Cell colSpan={4} className={'flex items-center justify-center'}>
                                <EmptyState size="lg" icon={faCircleDashed}>
                                    <EmptyState.Text>
                                        {isEmpty(search) ? (
                                            <Trans>No checklists</Trans>
                                        ) : (
                                            <Trans>No checklists found</Trans>
                                        )}
                                    </EmptyState.Text>
                                </EmptyState>
                            </Table.Cell>
                        </Table.Row>
                    }
                    size={20}
                />
            </Table>
        </div>
    );
};
