import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { isEmpty, isString } from 'lodash-es';
import { Alert } from '~/components/Alert';
import { Button } from '~/components/Button/Button';
import { Input } from '~/components/Input/Input';
import { FaIconName } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { getIconFromName, icons } from './utils';

export type IconPickerInlineProps = {
    icon: IconDefinition | FaIconName;
    onClick: (icon: IconDefinition) => void;
    canDeselectIcon?: boolean;
    isBorderVisible?: boolean;
};

export const IconPickerInline: React.FC<IconPickerInlineProps> = ({
    icon,
    onClick,
    canDeselectIcon = false,
    isBorderVisible = true,
}) => {
    const [search, setSearch] = useState<string>(EmptyString);

    const renderedIcon = isString(icon) ? getIconFromName(icon) : icon;

    const filteredIcons = useMemo<Array<IconDefinition>>(() => {
        if (isEmpty(search)) return icons;
        return icons.filter((icon) => icon.iconName.includes(search));
    }, [search]);

    return (
        <div className={clsx('w-80 bg-white p-3', isBorderVisible && 'rounded-md border border-gray-300')}>
            <Input
                leadingIcon={faSearch}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search icons"
                className="mb-2"
            />

            <div className="scrollbar-light max-h-72 overflow-auto">
                <div className="m-1 grid grid-cols-6 gap-2">
                    {filteredIcons.map((i, index) => (
                        <Button
                            key={`${i.iconName}-${index}`}
                            className="!rounded-md"
                            onClick={() => onClick(i)}
                            active={i.iconName === renderedIcon?.iconName}
                            variant="text"
                        >
                            <FontAwesomeIcon icon={i} aria-hidden="true" />
                        </Button>
                    ))}
                </div>

                {isEmpty(filteredIcons) && (
                    <Alert type={'info'} title="No icons found 😔">
                        Try altering your search criteria.
                    </Alert>
                )}
            </div>

            {canDeselectIcon && (
                <Button size="sm" className="mt-3" onClick={() => onClick(null)} disabled={!renderedIcon} icon={faBan}>
                    Remove Icon
                </Button>
            )}
        </div>
    );
};
