import { Alert } from './Alert';

type Error = {
    title?: string;
    message?: string;
};

type ErrorBannerProps = {
    error: Error;
    className?: string;
};

export const ErrorBanner = ({ error, className }: ErrorBannerProps) => {
    return (
        <Alert title={error.title ?? error.toString()} type="danger" className={className}>
            {error.message && error.message}
        </Alert>
    );
};
