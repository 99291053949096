import React, { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { colors, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { GroupedTask } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { Layout } from 'Pages/TasksPage/types';
import { Task } from 'Shared/components/task/Task';
import { Task as TaskType } from 'Shared/types/task';

export const TaskSubtasksIcon = ({ task }: { task: TaskType }) => {
    const count = task.subtasks?.length;
    const completedSubtasks = task.subtasks?.filter(({ completed }) => completed).length;
    return (
        <div className="h-4 w-4">
            <Tooltip
                content={
                    <Trans>
                        {completedSubtasks} of {count} subtasks are completed
                    </Trans>
                }
            >
                <PieChart
                    lineWidth={60}
                    startAngle={270}
                    data={[
                        { value: completedSubtasks, color: colors.green[500] },
                        { value: count - completedSubtasks, color: colors.gray[300] },
                    ]}
                />
            </Tooltip>
        </div>
    );
};

export const getSubTasksCompletionPercentage = (subTasks: TaskType[]) => {
    if (subTasks == null || subTasks?.length === 0) {
        return 0;
    }
    const completedSubTasks = subTasks.filter(({ completed }) => completed);
    return Math.floor((completedSubTasks.length / subTasks.length) * 100);
};

export const TaskSubTasks: FC<{
    subTasks: TaskType[];
    isEditable: boolean;
    isListReadonly: boolean;
    isTemplate: boolean;
    onSelectTask: (e: React.MouseEvent<HTMLDivElement>, task: GroupedTask) => void;
    layout: Layout;
    depth: number;
    workspaceId?: Id;
}> = ({ subTasks, isEditable, isListReadonly, isTemplate, onSelectTask, layout, depth, workspaceId }) => (
    <>
        {layout === 'kanban' && <hr className="w-full" />}
        {subTasks?.map((subTask) => (
            <Task
                key={subTask.id}
                task={{ ...subTask, groupedId: subTask.id.toString() } as GroupedTask}
                className={clsx(`-mt-px`, layout === 'kanban' && '!block')}
                style={{ paddingLeft: `${depth}rem` }}
                isEditable={isEditable}
                isSubtask
                isTemplate={isTemplate}
                onSelectTask={onSelectTask}
                layout={layout}
                isListReadonly={isListReadonly}
                depth={depth + 1}
                workspaceId={workspaceId}
            />
        ))}
    </>
);
