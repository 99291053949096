export type SortDirection = 'ascending' | 'descending';

export type Sort = {
    sortColumn: string;
    sortDirection: string;
};

export const parseSort = <T extends string>(sort: `-${T}` | T): { sortColumn: T; sortDirection: SortDirection } => {
    const isDescending = sort.startsWith('-');
    return {
        sortColumn: isDescending ? (sort.substring(1) as T) : (sort as T),
        sortDirection: isDescending ? 'descending' : 'ascending',
    };
};

export const stringifySort = <T extends string, S extends SortDirection>(property: T, direction: S): `-${T}` | T =>
    `${direction === 'descending' ? '-' : ''}${property}` as T;
