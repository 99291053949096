import { HttpMethod } from '@wedo/types';
import { baseApi, configureTag } from './base';

const { tagType, tag } = configureTag('Token');

export const tokenApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getToken: build.query<string, void>({
                query: () => ({ url: 'users/api_access/token' }),
                providesTags: () => [tag(tagType)],
                transformResponse: (baseQueryReturnValue: string | Record<string, unknown>): string => {
                    if (typeof baseQueryReturnValue !== typeof '') {
                        return '';
                    }

                    return baseQueryReturnValue as string;
                },
            }),
            deleteToken: build.mutation<{ result: string }, void>({
                query: () => ({ url: 'users/api_access/token', method: HttpMethod.Delete }),
                invalidatesTags: (_, error) => {
                    if (error === undefined) {
                        return [tag(tagType)];
                    }
                    return [];
                },
            }),
            createToken: build.mutation<{ result: string }, void>({
                query: () => ({ url: 'users/api_access/token', method: HttpMethod.Put }),
                invalidatesTags: (_, error) => {
                    if (error === undefined) {
                        return [tag(tagType)];
                    }

                    return [];
                },
            }),
        }),
    });

export const { useGetTokenQuery, useDeleteTokenMutation, useCreateTokenMutation } = tokenApi;
