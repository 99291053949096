import { de } from './de';
import { deCH } from './de-ch';
import { deDE } from './de-de';
import { en } from './en';
import { enGB } from './en-gb';
import { enUS } from './en-us';
import { fr } from './fr';
import { frCH } from './fr-ch';
import { frFR } from './fr-fr';
import { it } from './it';
import { itCH } from './it-ch';
import { itIT } from './it-it';

export const getLocale = (locale: string) => {
    switch (locale.toLowerCase()) {
        case 'de':
            return de;
        case 'de-ch':
            return deCH;
        case 'de-de':
            return deDE;
        case 'en-gb':
            return enGB;
        case 'en-us':
            return enUS;
        case 'fr':
            return fr;
        case 'fr-ch':
            return frCH;
        case 'fr-fr':
            return frFR;
        case 'it':
            return it;
        case 'it-ch':
            return itCH;
        case 'it-it':
            return itIT;
        default:
            return en;
    }
};
