import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { faWandMagicSparkles } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, CodeInput, Form, Input, ItemGroup, Label } from '@wedo/design-system';
import { isValidEmail, string } from '@wedo/utils';
import { useInputState, useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { useGlobalLogin } from 'Pages/SignInPage/hooks/useLogin';
import { SignInFooter } from 'Shared/components/SignInFooter';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import {
    useGlobalTokenLoginTotpMutation,
    useSendMagicLinkMutation,
    useTokenLoginTotpMutation,
} from 'Shared/services/auth';
import { ApiError } from 'Shared/types/apiError';

const MagicLinkSearchParams = {
    email: string().default(''),
    error: string(),
    token: string(),
    userId: string(),
};

export const MagicLinkPage = () => {
    const navigate = useNavigate();
    const [{ email: emailParam, userId, token, error: errorParam }] = useSearchParams(MagicLinkSearchParams);
    const { isGlobal } = useCurrentNetwork();
    const handleGlobalLogin = useGlobalLogin();

    const [sendMagicLink, { isLoading, isSuccess }] = useSendMagicLinkMutation();
    const [tokenLoginTotp, { isLoading: isTokenLoginLoading }] = useTokenLoginTotpMutation();
    const [globalTokenLoginTotp, { isLoading: isGlobalTokenLoginLoading }] = useGlobalTokenLoginTotpMutation();

    const loginTotp = isGlobal ? globalTokenLoginTotp : tokenLoginTotp;
    const isLoadingTotp = isTokenLoginLoading || isGlobalTokenLoginLoading;

    const [email, , handleSetEmail] = useInputState(emailParam);
    const [error, setError] = useState(null);
    const [code, setCode] = useState('');

    const handleSendMagicLink = () => {
        if (!isValidEmail(email)) {
            setError(t`Invalid email address`);
            return;
        }
        void sendMagicLink(email);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
        handleSetEmail(e);
        setError(null);
    };

    const handleConfirmCode = async (code: string) => {
        const result = await loginTotp({
            token: token,
            user_id: userId,
            code,
        });
        if ('error' in result && result.error instanceof ApiError) {
            setError(result.error.message);
            return;
        }
        if ('data' in result) {
            if (isGlobal) {
                await handleGlobalLogin(result.data);
                // Wait a few ms to let the LoginContext be updated
                setTimeout(() => {
                    void navigate('/signin', { replace: false });
                }, 100);
                return;
            }
            void navigate('/');
        }
    };

    return (
        <div className="w-full gap-1">
            {errorParam ? (
                <>
                    {errorParam === '2fa' && (
                        <Form>
                            <Form.Item aria-live="polite" label={t`Confirmation code`} htmlFor="totp" cols={6}>
                                <ItemGroup status="error" statusText={error}>
                                    <CodeInput
                                        label={t`Confirmation code`}
                                        autofocus
                                        onValidate={handleConfirmCode}
                                        onChange={(code) => {
                                            setCode(code);
                                            if (code.length < 6) {
                                                setError(null);
                                            }
                                        }}
                                    />
                                </ItemGroup>
                            </Form.Item>
                            <Form.Item cols={6}>
                                <Button
                                    type="submit"
                                    loading={isLoadingTotp}
                                    color="primary"
                                    className="w-full bg-gradient-to-l from-blue-600 to-blue-800 hover:bg-gradient-to-r"
                                    onClick={() => handleConfirmCode(code)}
                                >
                                    <Trans>Login</Trans>
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                    {errorParam === 'invalid_token' && (
                        <Alert type={'danger'} className={'w-full max-w-4xl'} title={'Invalid or expired login link'}>
                            Request another login link or contact your IT administrator.
                        </Alert>
                    )}
                </>
            ) : (
                <>
                    <Form layout="vertical" onSubmit={handleSendMagicLink}>
                        {!isSuccess && (
                            <Form.Item label={t`Login email address`} htmlFor={`email`} cols={6}>
                                <Input
                                    id={`email`}
                                    value={email}
                                    onChange={handleChange}
                                    status={error ? 'error' : 'default'}
                                    statusText={error}
                                />
                            </Form.Item>
                        )}
                        <Form.Item cols={6}>
                            {isSuccess ? (
                                <>
                                    <Alert
                                        type="success"
                                        title={t`If an account associated with the provided email exists, you will receive an email with the login link`}
                                    />
                                    <Label className="mt-4">
                                        <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2" />
                                        <Trans>
                                            With this login link, you will not have to log in with your credentials.
                                            Click on the link from the email we just sent you and you will be
                                            authenticated.
                                        </Trans>
                                    </Label>
                                </>
                            ) : (
                                <Button
                                    type="submit"
                                    loading={isLoading}
                                    color="primary"
                                    className="w-full bg-gradient-to-l from-blue-600 to-blue-800 hover:bg-gradient-to-r"
                                    icon={faWandMagicSparkles}
                                >
                                    <Trans>Send login link</Trans>
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </>
            )}

            <div className="mt-4">
                <Link to={'/signin'} className="text-blue-600 hover:text-blue-700">
                    <FontAwesomeIcon icon={faChevronLeft} /> <Trans>Return to login page</Trans>
                </Link>
            </div>

            <SignInFooter />
        </div>
    );
};
