import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { CheckboxTree, colors } from '@wedo/design-system';
import { colorHexFromKey } from '@wedo/design-system/src/lib/components/ColorPicker/ColorPicker';
import { type Settings } from '@wedo/pdf/meeting/store';
import { getAuthToken, getLocale, getUserTimeZone } from '@wedo/utils';
import { generateErrorPdf } from 'Pages/meeting/components/MeetingExportModal/generateErrorPdf';
import { checkboxTreeToTopicsTree } from 'Pages/meeting/components/MeetingExportModal/utils/checkboxTreeToTopicsTree';
import {
    formatDateTimeLocation,
    formatMeetingDateTime,
    formatMeetingTitle,
} from 'Shared/components/meeting/FormatMeetingDateTime';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { type Meeting } from 'Shared/types/meeting';

const fontSizes = {
    small: '12px',
    medium: '16px',
    large: '22px',
};

const fontFamilies = {
    arial: 'Arial',
    calibri: 'Calibri',
    garamond: 'Garamond',
    helvetica: 'Helvetica',
    lato: 'Lato',
    montserrat: 'Montserrat',
    openSans: 'Open Sans',
    poppins: 'Poppins',
    roboto: 'Roboto',
    timesNewRoman: 'Times New Roman',
    verdana: 'Verdana',
};

const blocksToDisplay = {
    attachment: 'attachment',
    customFields: 'custom_fields',
    decision: 'decision',
    image: 'image',
    note: 'note',
    paragraph: 'paragraph',
    subTasks: 'subtasks',
    taskActivities: 'task_activities',
    taskDescriptions: 'task_description',
    task: 'task',
    vote: 'vote',
};

const elementsToDisplay = {
    attendees: 'attendees',
    date: 'date',
    footer: 'footer',
    location: 'location',
    logo: 'logo',
    nextMeeting: 'next_meeting',
    pageNumbering: 'page',
    presenters: 'topic_presenters',
    signatures: 'signatures',
    title: 'title',
    topicsDuration: 'topic_duration',
    topicsNumbering: 'section_topic_numbers',
    userTimeZone: 'user_timezone',
    workspace: 'workspace',
};

export const generateLegacyMeetingPdfConfig = (
    meeting: Meeting,
    topicsTree: CheckboxTree,
    settings: Settings,
    i18n: I18n,
    useBasicSignatureLayout: boolean
) => {
    const locale = getLocale(i18n.locale);
    const [marginTop, marginRight, marginBottom, marginLeft] = settings.margins;
    const nextMeeting = meeting.nextMeetings?.[0];
    const useUserTimeZone = settings.elementsToDisplay.some((element) => element === 'userTimeZone');
    return {
        meeting_id: meeting.id,
        title: settings.title,
        dateTime: formatMeetingDateTime(meeting, i18n, false, useUserTimeZone),
        userTimeZone: useUserTimeZone ? getUserTimeZone() : meeting.start_at_time_zone,
        location: meeting.location,
        footer: settings.footer,
        attendeesView: settings.attendeesView, // TODO
        items: checkboxTreeToTopicsTree(topicsTree).children,
        displayElements: settings.elementsToDisplay
            .map((element) => ({ id: elementsToDisplay[element] }))
            .concat(settings.blocksToDisplay.map((block) => ({ id: blocksToDisplay[block] }))),
        displayAttachments: [],
        dateFormat: locale.DATETIME_FORMATS.shortDate,
        nextMeetingTitle: nextMeeting != null ? formatMeetingTitle(nextMeeting, i18n) : null,
        nextMeetingSubTitle: nextMeeting != null ? formatDateTimeLocation(nextMeeting, i18n) : null,
        layout: {
            size: 'A4',
            orientation: settings.orientation,
            margin: {
                top: marginTop,
                right: marginRight,
                bottom: marginBottom,
                left: marginLeft,
            },
        },
        style: {
            mainFontFamily: fontFamilies[settings.fontFamily],
            fontSize: fontSizes[settings.fontSize],
        },
        colors: {
            linkColor: colors.blue['500'],
            headingDefaultColor: colors.gray['700'],
            headingDefaultBg: colors.gray['50'],
            heading0Color: colors.gray['900'],
            heading0Bg: colors.gray['300'],
            heading1Color: colors.gray['800'],
            heading1Bg: colors.gray['200'],
            heading2Color: colors.gray['700'],
            heading2Bg: colors.gray['100'],
            heading3Color: colors.gray['700'],
            heading3Bg: colors.gray['100'],
            attendeeTableHeaderColor: colors.gray['600'],
            attendeeTableHeaderBg: colors.gray['100'],
            lightBorder: colors.gray['200'],
            lightTextColor: colors.gray['600'],
            lightBackgroundColor: colors.gray['200'],
            paragraphBorder: colors.gray['300'],
            decisionBorder: colors.green['300'],
            decisionBackground: colors.green['50'],
            decisionText: colors.green['600'],
            voteBorderColor: colors.gray['400'],
            voteIconColor: colors.gray['700'],
            voteInnerBordersColor: colors.blue['900'],
            voteBackgroundColor: 'rgb(246,246,250)',
            noteBorder: colors.yellow['300'],
            noteBackground: colors.yellow['50'],
            noteText: colors.yellow['700'],
            joinMeetingLink: colors.green['300'],
            attachmentMarkerColor: colors.blue['700'],
            completedTaskColor: colors.green['500'],
            deletedTaskColor: colors.red['500'],
            titleColor: colorHexFromKey(settings.titleColor),
            subTitleColor: colorHexFromKey(settings.subTitleColor),
            mainFontColor: colorHexFromKey(settings.textColor),
        },
        startPageNumber: settings.pageNumbering === 'default' ? 0 : settings.pageNumbering,
        translations: {
            attendeeTable: {
                presentAbr: t`Ids.presentFirstLetter`,
                absentAbr: t`Ids.absentFirstLetter`,
                excusedAbr: t`Ids.excusedFirstLetter`,
                onlineAbr: t`Ids.onlineFirstLetter`,
                headerTitle: t`Job title`,
                headerAttendee: t`Attendee`,
                headerLocation: t`Location`,
                headerContact: t`Contact`,
                headerAttendance: t`Attendance`,
                headerExcuse: t`Excuse`,
            },
            time: t`Time`,
            location: t`Location`,
            attendees: t`Attendees`,
            present: t`Present`,
            present_plural: t({
                id: 'Ids.presentPlural',
                message: 'Present',
                comment: '"Present" in plural for other languages',
            }),
            presenter: t`Presenter:`,
            absent: t`Absent`,
            absent_plural: t({
                id: 'Ids.absentPlural',
                message: 'Absent',
                comment: '"Absent" in plural for other languages',
            }),
            excused: t`Excused`,
            excused_plural: t({
                id: 'Ids.excusedPlural',
                message: 'Excused',
                comment: '"Excused" in plural for other languages',
            }),
            online: t`Online`,
            online_plural: t({
                id: 'Ids.onlinePlural',
                message: 'Online',
                comment: '"Online" in plural for other languages',
            }),
            name: t`Name`,
            assignee: t`Assignee`,
            date: t`Date`,
            tags: t`Workspaces`,
            due_date: t`Due date`,
            pending_tasks: t`Pending tasks`,
            completed_tasks: t`Other completed tasks`,
            deleted_tasks: t`Other deleted tasks`,
            next_meeting: t`Next meeting`,
            vote: {
                translationAbstained: t`Abstained`,
                translationRequestedDiscussion: t`Requested discussion`,
                translationClosed: t`This vote is closed`,
                translationOngoing: t`This vote is still ongoing`,
                translationAllVotersVoted: t`All voters have voted`,
                translationPendingVotes: t`Pending votes`,
                translationSelectedOutcome: t`Selected outcome`,
            },
            changed_due_date: t`changed the due date`,
            to: t`to`,
            from: t`from`,
            removed_due_date: t`removed the due date`,
        },
        processor: 'new',
        useBasicSignatureLayout,
    };
};

export const generateLegacyMeetingPdf = async (
    meeting: Meeting,
    topicsTree: CheckboxTree,
    settings: Settings,
    i18n: I18n,
    useBasicSignatureLayout = true
) => {
    const { instance } = await getSharedPdfViewerInstance();

    const config = generateLegacyMeetingPdfConfig(meeting, topicsTree, settings, i18n, useBasicSignatureLayout);

    try {
        const data = await fetch(`/api/meetings/${meeting.id}/pdf`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}` },
            body: JSON.stringify(config),
        }).then((response) => {
            if (response.status === 200) {
                return response.arrayBuffer();
            }
            return Promise.reject();
        });
        return new Blob([data], { type: 'application/pdf' });
    } catch (error) {
        console.error(error);
        return await instance.Core.createDocument(
            await generateErrorPdf(t`An error occured while rendering meeting PDF`),
            {
                extension: 'pdf',
            }
        );
    }
};
