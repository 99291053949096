import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { getIdMapping, getKeyMapping } from '@wedo/utils';
import { useTasksContext } from 'App/contexts/TasksContext';
import { useGroupedTasks } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { useGetChecklistSectionsQuery } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetWorkspaceSectionsQuery } from 'Shared/services/workspace';
import { maxTaskSectionOrder } from 'Shared/utils/task';
import { isValidWorkspaceId } from 'Shared/utils/workspace';

type UseTaskSectionsProps = {
    workspaceId: Id;
    templateId: Id;
    checklistId: Id;
};

export const useTaskSections = ({ workspaceId, templateId, checklistId }: UseTaskSectionsProps) => {
    const { tasks } = useTasksContext();
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });
    const { data: workspaceSections } = useGetWorkspaceSectionsQuery(workspaceId, {
        skip: !isValidWorkspaceId(workspaceId),
    });
    const { data: checklistSections } = useGetChecklistSectionsQuery(checklistId, { skip: !checklistId });
    const { data: templateSections } = useGetChecklistSectionsQuery(template?.checklist_id, {
        skip: !template?.checklist_id,
    });

    const sectionGroups = useGroupedTasks(tasks, workspaceId, checklistId, templateId, 'section');
    const keyToSectionGroup = getKeyMapping(sectionGroups, 'key');

    const sections = useMemo(() => {
        if (workspaceId) return workspaceSections;
        if (templateId) return templateSections;
        if (checklistId) return checklistSections;
        return [];
    }, [workspaceId, templateId, checklistId, workspaceSections, templateSections, checklistSections]);

    const sectionNames = new Set(sections?.map(({ name }) => name?.trim()));

    const maxOrder = useMemo(
        () => sections?.reduce((previousValue, currentValue) => Math.max(previousValue, currentValue?.order), -1),
        [sections]
    );

    const sectionIdToMaxOrder = new Map(
        [...keyToSectionGroup.keys()].map((sectionId) => [
            sectionId as Id,
            maxTaskSectionOrder(keyToSectionGroup.get(sectionId)?.tasks),
        ])
    );

    const nameToSectionMapping = useMemo(
        () => new Map(sections?.map((section) => [section.name, section])),
        [sections]
    );

    return {
        sections,
        sectionIdToSections: getIdMapping(sections),
        sectionNames,
        maxOrder,
        sectionIdToMaxOrder,
        sectionGroups,
        nameToSectionMapping,
    };
};
