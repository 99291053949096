import { useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash-es';
import { NotificationHourBadge } from 'Pages/settings/notifications/types/notification-badge';
import { getEmailHourBadges } from 'Pages/settings/notifications/utils/badge.utils';
import { NotificationHours, useGetSettingsQuery } from 'Shared/services/settings';

export const useNotificationHours = () => {
    const { data } = useGetSettingsQuery();

    const notificationHoursSaved = useMemo<NotificationHours>(() => data?.notification_hours?.value?.hours, [data]);

    const notificationHoursBadgesSaved = useMemo(() => {
        const badges = [...getEmailHourBadges()];
        if (isArray(notificationHoursSaved)) {
            notificationHoursSaved.map((hour) => (badges[hour - 1].selected = true));
        }
        return badges;
    }, [notificationHoursSaved]);

    const [notificationHourBadges, setNotificationHourBadges] = useState<Array<NotificationHourBadge>>();

    useEffect(() => setNotificationHourBadges(notificationHoursBadgesSaved), [notificationHoursBadgesSaved]);

    const notificationHours = useMemo<NotificationHours>(() => {
        if (!isArray(notificationHourBadges)) return notificationHoursSaved;
        const result = [];
        for (let index = 0; index < notificationHourBadges.length; index++) {
            if (notificationHourBadges[index].selected) {
                result.push(index + 1);
            }
        }
        return result;
    }, [notificationHourBadges, notificationHoursSaved]);

    return { notificationHoursSaved, notificationHours, notificationHourBadges, setNotificationHourBadges };
};
