import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { colors } from '@wedo/design-system';
import { sectionQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { type GanttPageParams } from './GanttPage';

const EmptySection = { id: '', color: colors.gray['500'], order: -1 };

export const useSections = () => {
    const { workspaceId } = useParams<GanttPageParams>();

    const { data } = trpc.workspace.listSections.useQuery(
        { workspaceId: workspaceId! },
        { meta: { tags: [sectionQueryTag.added(), sectionQueryTag.updated('*'), sectionQueryTag.removed()] } }
    );

    const sections = useMemo(
        () =>
            data == null
                ? null
                : [EmptySection].concat(
                      data.map((section) => ({ ...section, color: section.color ?? colors.gray['500'] }))
                  ),
        [data]
    );

    return { sections };
};
