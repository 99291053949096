import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { AttachmentIcon, getFaIconForAttachment } from 'Shared/components/file/AttachmentIcon';
import { Attachment } from 'Shared/types/attachment';
import { isUrlFile } from 'Shared/utils/attachment';

const classes = {
    base: 'group mt-2 flex m-auto border border-gray-300 rounded p-1 text-6xl justify-center items-center',
    image: {
        base: 'm-auto max-h-full max-w-full',
        clickable: {
            on: 'group-hover:opacity-80',
            off: '',
        },
    },
    size: {
        sm: 'w-24 h-24',
        lg: 'w-56 h-56',
    },
    clickable: {
        on: 'cursor-pointer hover:opacity-80',
        off: 'cursor-default',
    },
};

const isImage = (attachment: Partial<Attachment>): boolean => attachment?.mimetype.includes('image');

type PreviewProps = {
    size: 'sm' | 'lg';
    attachment: Partial<Attachment>;
    onClick?: () => void;
};
export const Preview = ({ size, attachment, onClick }: PreviewProps) => {
    return (
        <div
            className={clsx(classes.base, classes.size[size], classes.clickable[onClick ? 'on' : 'off'])}
            onClick={onClick}
            role="button"
            onKeyDown={onClick}
            tabIndex={0}
        >
            {isImage(attachment) ? (
                <img
                    className={clsx(classes.image.base, classes.image.clickable[onClick ? 'on' : 'off'])}
                    src={attachment?.preview_url}
                    alt={t`Attachment preview`}
                />
            ) : isUrlFile(attachment) ? (
                <AttachmentIcon attachment={attachment} size="lg" />
            ) : (
                getFaIconForAttachment(attachment)
            )}
        </div>
    );
};
