import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { Size } from '~/types';

const classes = {
    base: 'inline-flex justify-center items-center border font-mono transition-all',
    size: {
        sm: 'text-sm px-1 min-w-[1.4rem] min-h-[1.4rem] rounded-md',
        md: 'text-base px-2 min-w-[2.2rem] min-h-[2.2rem] rounded-md',
        lg: 'text-lg px-4 min-w-[2.5rem] min-h-[2.5rem] rounded-lg',
    },
    state: {
        default: 'text-gray-700 bg-white bg-opacity-50',
        pressed: 'bg-blue-800 bg-opacity-50 text-gray-100',
    },
};

type KeyboardKeyProps = {
    keyStroke: string;
    size?: 'sm' | 'md' | 'lg';
    pressed?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
};

export const KeyboardKey: FC<KeyboardKeyProps> = ({
    keyStroke,
    pressed = false,
    size = 'sm',
    disabled = false,
    className,
    onClick,
}) => {
    if (onClick) {
        return (
            <Button
                variant={'ghost'}
                className={clsx(
                    classes.base,
                    pressed ? classes.state.pressed : classes.state.default,
                    classes.size[size],
                    className
                )}
                disabled={disabled}
                onClick={onClick}
            >
                {keyStroke}
            </Button>
        );
    }

    return (
        <div
            className={clsx(
                classes.base,
                pressed ? classes.state.pressed : classes.state.default,
                classes.size[size],
                className
            )}
        >
            {keyStroke}
        </div>
    );
};

const keyBoardShortcutClasses = {
    plus: {
        size: {
            sm: 'text-xs',
            md: 'text-sm',
            lg: 'text-base',
        },
    },
};

type KeyboardShortcutProps = {
    keyStrokes: string[] | { key: string; pressed: boolean }[];
    size?: Size;
    showPlus?: boolean;
};

export const KeyboardShortcut = ({ keyStrokes, size = 'sm', showPlus = false }: KeyboardShortcutProps) => {
    const keyComponents = useMemo(() => {
        let keyComponents = keyStrokes.map((k) => (
            <KeyboardKey pressed={k?.pressed ?? false} key={k?.key ?? k} keyStroke={k?.key ?? k} size={size} />
        ));
        if (showPlus) {
            keyComponents = keyComponents.reduce((prev, curr, index) => [
                prev,
                <div className={keyBoardShortcutClasses.plus.size[size]} key={index}>
                    +
                </div>,
                curr,
            ]);
        }
        return keyComponents;
    }, [keyStrokes, size, showPlus]);

    return <div className={'flex items-center gap-1'}>{keyComponents}</div>;
};
