import React, { useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';

type DuplicateAttachmentModalProps = {
    filename: string;
    resolve: () => void;
    hideReplace?: boolean;
} & ContextModalProps;
export const DuplicateAttachmentModal = ({
    filename,
    resolve,
    hideReplace = false,
    onAfterClose,
    close,
    ...modalProps
}: DuplicateAttachmentModalProps) => {
    const choice = useRef('');

    const handleDone = async (choiceParam: string) => {
        choice.current = choiceParam;
        return close();
    };

    const handleBeforeClose = async (source: string) => {
        return source === 'api' || source === 'cross';
    };

    const handleAfterClose = () => {
        onAfterClose();
        resolve(choice.current);
    };

    return (
        <Modal {...modalProps} onBeforeClose={handleBeforeClose} onAfterClose={handleAfterClose}>
            <Modal.Header title={t`Replace or keep both`} />
            <Modal.Body>
                <div>
                    <Trans>This location already contains a file named:</Trans>&nbsp;
                    <b>{filename}</b>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleDone('ignore')}>
                    <Trans>Ignore</Trans>
                </Button>
                {!hideReplace && (
                    <Button color="primary" onClick={() => handleDone('replace')}>
                        <Trans>Replace</Trans>
                    </Button>
                )}
                <Button color="success" onClick={() => handleDone('keep_both')}>
                    <Trans>Keep both</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
