import React, { FC } from 'react';
import { Plural, Trans } from '@lingui/macro';
import { Button, Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { InternalAttachmentSelector } from 'Shared/components/file/InternalAttachmentSelector/InternalAttachmentSelector';
import { Attachment } from 'Shared/types/attachment';

type InternalSourceTabPanelProps = {
    workspaceId: Id;
    onAttachmentSelected: React.Dispatch<React.SetStateAction<Attachment[]>>;
    numberOfAttachmentsSelected: number;
    closeModal: () => void;
    onSubmit: () => void;
};

export const InternalSourceTabPanel: FC<InternalSourceTabPanelProps> = ({
    workspaceId,
    onAttachmentSelected,
    closeModal,
    numberOfAttachmentsSelected,
    onSubmit,
}) => {
    return (
        <Tabs.Panel>
            <InternalAttachmentSelector workspaceId={workspaceId} onAttachmentSelected={onAttachmentSelected} />
            <div className="flex gap-2 justify-end mt-8">
                <Button onClick={closeModal}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={onSubmit}>
                    <Plural
                        value={numberOfAttachmentsSelected}
                        one={`Attach 1 file`}
                        other={`Attach ${numberOfAttachmentsSelected} files`}
                    />
                </Button>
            </div>
        </Tabs.Panel>
    );
};
