import React, { FC } from 'react';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { getColorId, Tag, TooltipProps } from '@wedo/design-system';
import { WorkspaceDescription } from 'Shared/components/workspace/WorkspaceDescription';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { Workspace } from 'Shared/types/workspace';
import { getWorkspaceTaskParams, WorkspaceSettingsToTab } from 'Shared/utils/workspace';

type WorkspaceTagProps = {
    workspace: Workspace;
    onRemove?: (workspace: Workspace) => void;
    isLink?: boolean;
    isRemoveLoading?: boolean;
};

export const WorkspaceTag: FC<WorkspaceTagProps> = ({ isRemoveLoading = false, workspace, onRemove, isLink }) => {
    const { grouping, layout } = getWorkspaceTaskParams(camelToSnake(workspace) as Workspace);
    const colorId = getColorId(workspace?.color?.background);

    return (
        <Tag
            color="none"
            title={<WorkspaceDescription showBreadcrumb showEditButton workspace={workspace} />}
            tooltipProps={
                {
                    className: '!p-0',
                    color: 'light',
                    placement: 'bottom',
                    delay: 400,
                    showTooltipOnHover: true,
                } as TooltipProps
            }
            className={clsx(
                'flex items-center !rounded-md !p-0 font-medium text-sm !gap-0',
                `bg-${colorId.toString()}-100`,
                `text-${colorId.toString()}-700`
            )}
            removeClassName={clsx(`bg-${colorId.toString()}-200`, `!text-${colorId.toString()}-700`)}
            size="xs"
            href={
                isLink
                    ? {
                          pathname: `/workspaces/${workspace.id}/${
                              WorkspaceSettingsToTab[workspace.settings?.default_tab] ?? 'tasks'
                          }`,
                          searchParams: { grouping, layout },
                      }
                    : null
            }
            onRemove={onRemove ? () => onRemove(workspace) : null}
            isRemoveLoading={isRemoveLoading}
        >
            <WorkspaceIcon workspace={workspace} />
            <div className={clsx('inline truncate', !onRemove && 'pr-1')}>{workspace?.name}</div>
        </Tag>
    );
};
