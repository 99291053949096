import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { TemplateDescriptionCard } from 'Pages/TemplateOverviewPage/TemplateDescriptionCard';
import { TemplateMembersCard } from 'Pages/TemplateOverviewPage/TemplateMembersCard';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';

export const TemplateOverviewPage = () => {
    const { templateId } = useParams<{ templateId: string }>();
    const { data: template } = useGetChecklistTemplateQuery(templateId);

    usePageTitle(`${template?.name} | ${t`Overview`}`);

    return (
        <div className="scrollbar-light overflow-y-auto">
            <div className="mx-6 mb-10 mt-6 flex flex-col  gap-6 sm:mx-10">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <TemplateDescriptionCard template={template} />
                    <TemplateMembersCard template={template} />
                </div>
            </div>
        </div>
    );
};
