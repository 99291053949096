import { useLingui } from '@lingui/react';
import { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { addDays } from 'date-fns';
import { isEqual } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    DatePicker,
    FormatDate,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useFormatDate,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getDateFnsLocale } from '@wedo/utils';
import { useGetChecklistQuery, useUpdateChecklistMutation } from 'Shared/services/checklist';

type ReplanChecklistModalProps = { checklistId: Id } & ContextModalProps;

export const ReplanChecklistModal: FC<ReplanChecklistModalProps> = ({ checklistId, ...modalProps }) => {
    const { data: checklist } = useGetChecklistQuery(checklistId);
    const { i18n } = useLingui();
    const { locale } = i18n;
    const { show } = useNotification();

    const [updateChecklist, { isLoading }] = useUpdateChecklistMutation();

    const [referenceDate, setReferenceDate] = useState<Date>();

    const formattedDate = useFormatDate(referenceDate, 'yyyy-MM-d', { locale: getDateFnsLocale(locale) });
    const startDate = addDays(referenceDate, checklist?.meta?.minDate);
    const endDate = addDays(referenceDate, checklist?.meta?.maxDate);

    const originalReferenceDate = new Date(checklist?.reference_date);
    const hasUserMadeChanges = !isEqual(originalReferenceDate, referenceDate);

    const handleReplan = async () => {
        if (!hasUserMadeChanges) {
            await modalProps.close();
            return;
        }

        const response = await updateChecklist({
            checklistId,
            checklist: { reference_date: formattedDate },
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            await modalProps.close();
        }
    };

    useEffect(() => {
        if (checklist != null) {
            setReferenceDate(new Date(checklist.reference_date));
        }
    }, [checklist]);

    return (
        <Modal {...modalProps} size="sm">
            <Modal.Header title={t`Replan ${checklist?.name} checklist`} />

            <Modal.Body>
                <Label>
                    <Trans>Reference date</Trans>
                </Label>

                <div className="my-4 ">
                    <DatePicker onChange={setReferenceDate} date={referenceDate} className="w-full" />
                </div>

                <Label>
                    <Trans>Task due dates range</Trans>
                </Label>

                <div className="text-sm italic text-gray-700">
                    <p>
                        <Trans>
                            Start date: <FormatDate format="PPP" date={startDate} />
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            End date: <FormatDate format="PPP" date={endDate} />
                        </Trans>
                    </p>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button onClick={handleReplan} color="primary" loading={isLoading}>
                    <Trans>Replan</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
