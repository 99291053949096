import { forwardRef, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, ButtonProps } from '~/components/Button/Button';
import { EmptyFunction } from '@wedo/utils';

/**
 * Upload file button. Choose if you want a multiple file selection or not
 * - multiple=true -> use onFilesSelect as return function contains selected files
 * - multiple=false (by default) -> use onFileSelect as return function contains selected single file
 */
type FileUploadButtonProps = {
    onFileSelect?: (file: File, filePath: string) => void;
    onFilesSelect?: (files: File[], filePath: string) => void;
    accept?: string;
    displayFileName?: boolean;
    multiple?: boolean;
    className?: string;
} & Omit<ButtonProps, 'onClick'>;

export const UploadButton = forwardRef<HTMLButtonElement & HTMLAnchorElement, FileUploadButtonProps>(
    (
        {
            onFileSelect = EmptyFunction,
            onFilesSelect = EmptyFunction,
            className,
            accept = '*',
            displayFileName = false,
            multiple = false,
            children,
            ...buttonProps
        },
        ref
    ) => {
        const fileInput = useRef<HTMLInputElement>();
        const [fileName, setFileName] = useState<string>('');

        const handleFileSelect = (files: File[], filePath: string): void => {
            if (multiple) {
                onFilesSelect(files, filePath);
            } else if (files && files.length > 0) {
                fileInput.current.value = '';
                onFileSelect(files[0], filePath);
            }
            setFileName(files.map((f) => f.name).join(', '));
        };

        return (
            <div className={clsx('inline-flex items-center gap-2')}>
                <input
                    type="file"
                    ref={fileInput}
                    className="hidden"
                    onChange={(e) => handleFileSelect(Array.from(e.target.files), e.target.value)}
                    multiple={multiple}
                    accept={accept}
                />
                <Button onClick={() => fileInput.current.click()} ref={ref} className={className} {...buttonProps}>
                    {children}
                </Button>
                {displayFileName && <span className={'text-sm text-gray-700'}>{fileName}</span>}
            </div>
        );
    }
);
