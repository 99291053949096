import { FC, PropsWithChildren } from 'react';
import { TreeProps } from '~/components/Tree/components/TreeFull';
import { TreeItem } from '~/components/Tree/components/TreeItem';

export const TreeRoot: FC<PropsWithChildren & Omit<TreeProps, 'isRoot'>> = ({ children, ...props }) => {
    return (
        <TreeItem {...props} isRoot={true}>
            {children}
        </TreeItem>
    );
};
