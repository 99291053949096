import { useContext } from 'react';
import { storage, stringifySearchParams } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { RequireAuthSearchParams } from 'Shared/services/auth';
import { LocalStorage } from 'Shared/types/localStorage';

export const useSamlLogin = () => {
    const {
        loginState: { chosenNetwork },
        updateLoginState,
    } = useContext(LoginContext);
    const { isGlobal } = useCurrentNetwork();
    const [{ redirect }] = useSearchParams(RequireAuthSearchParams);

    const { network: currentNetwork } = useCurrentNetwork();

    const goToSaml = (email?: string) => {
        if (__ENVIRONMENT__ !== 'test' && (email || !isGlobal)) {
            const networkId = chosenNetwork ?? currentNetwork?.id ?? '-1';
            if (redirect) {
                storage.setItem(LocalStorage.Redirect, redirect);
            }

            window.location.replace(
                '/api/login-saml?' +
                    stringifySearchParams({ networkd_id: networkId !== '-1' ? String(networkId) : undefined, email })
            );

            return;
        }

        updateLoginState({ page: 'samlChoice' });
    };

    return { goToSaml };
};
