import React, { FC, JSX, ReactNode, Suspense, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { createBrowserRouter, Outlet, useLocation, useMatches } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { defaultQueryClient } from '~/modules/reactQuery/client';
import { datadogRum } from '@datadog/browser-rum';
import { differenceInDays } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useStore } from 'zustand';
import { Modals } from '@wedo/design-system';
import { getAuthToken, getLocalStorageSearchParams, Minute, storage } from '@wedo/utils';
import { useEvent, useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { useInterval } from '@wedo/utils/hooks/useInterval';
import { CurrentUserContextProvider, useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import store, { Action } from 'App/store';
import { UsersStore, useSessionUser } from 'App/store/usersStore';
import { clientVersion, getAssetsHash, versionStore } from 'App/store/versionStore';
import { AppPage } from 'Pages/AppPage/AppPage';
import { PageNotFoundErrorCard, RoutingError } from 'Pages/AppPage/RoutingError';
import { AuthenticationPage } from 'Pages/AuthenticationPage/AuthenticationPage';
import { LoadingRedirectPage } from 'Pages/AuthenticationPage/LoadingRedirectPage';
import { BadgesPage } from 'Pages/BadgesPage/BadgesPage';
import { CalendarPage } from 'Pages/CalendarPage/CalendarPage';
import { ChecklistPage } from 'Pages/ChecklistPage/ChecklistPage';
import { ChecklistsPage } from 'Pages/ChecklistsPage/ChecklistsPage';
import { DevTools } from 'Pages/DevTest/DevTools';
import { ErrorPage } from 'Pages/ErrorPage/ErrorPage';
import { FeedPage } from 'Pages/FeedPage/FeedPage';
import { FilesPage } from 'Pages/FilesPage/FilesPage';
import { ForgotPasswordPage } from 'Pages/ForgotPasswordPage/ForgotPasswordPage';
import { ForgotTotpPage } from 'Pages/ForgotTotpPage/ForgotTotpPage';
import { GanttPage } from 'Pages/GanttPage/GanttPage';
import { InboxPage } from 'Pages/InboxPage/InboxPage';
import { MeetingListPage } from 'Pages/MeetingListPage/MeetingListPage';
import { MeetingPage } from 'Pages/MeetingPage/MeetingPage';
import { MyTopicsPage } from 'Pages/MyTopicsPage/MyTopicsPage';
import { NotificationsPage } from 'Pages/NotificationsPage/NotificationsPage';
import { ResetPasswordPage } from 'Pages/ResetPasswordPage/ResetPasswordPage';
import { SearchPage } from 'Pages/SearchPage/SearchPage';
import { FilesSearchPage } from 'Pages/SearchPage/components/FilesSearchPage/FilesSearchPage';
import { MeetingBlocksSearchPage } from 'Pages/SearchPage/components/MeetingBlocksSearchPage/MeetingBlocksSearchPage';
import { TopicsSearchPage } from 'Pages/SearchPage/components/TopicsSearchPage/TopicsSearchPage';
import { LoginContextProvider } from 'Pages/SignInPage/LoginContext';
import { SignInPage } from 'Pages/SignInPage/SignInPage';
import { SignaturesPage } from 'Pages/SignaturesPage/SignaturesPage';
import { TaskPage } from 'Pages/TaskPage/TaskPage';
import { TasksPage } from 'Pages/TasksPage/TasksPage';
import { TeamPage } from 'Pages/TeamPage/TeamPage';
import { TeamsPage } from 'Pages/TeamsPage/TeamsPage';
import { TemplateOverviewPage } from 'Pages/TemplateOverviewPage/TemplateOverviewPage';
// noinspection DuplicatedCode
import { TemplatePage } from 'Pages/TemplatePage/TemplatePage';
import { TemplatesPage } from 'Pages/TemplatesPage/TemplatesPage';
import { TrialEndedPage } from 'Pages/TrialEndedPage/TrialEndedPage';
import { UserPage } from 'Pages/UserPage/UserPage';
import { ColleagueProfilePage } from 'Pages/UserPage/components/ColleagueProfilePage';
import { UsersPage } from 'Pages/UsersPage/UsersPage';
import { VotesPage } from 'Pages/VotesPage/VotesPage';
import { WorkspaceOverviewPage } from 'Pages/WorkspaceOverviewPage/WorkspaceOverviewPage';
import { WorkspacePage } from 'Pages/WorkspacePage/WorkspacePage';
import { WorkspacesPage } from 'Pages/WorkspacesPage/WorkspacesPage';
import { CircleMemberProfile } from 'Pages/governance/CircleMember/CircleMemberProfile';
import { GovernancePage } from 'Pages/governance/GovernancePage';
import { MagicLinkPage } from 'Pages/magicLinkPage/MagicLinkPage';
import { OnboardingPage } from 'Pages/onboarding/OnboardingPage';
import { SettingsPage } from 'Pages/settings/SettingsPage';
import { ApiSettingsPage } from 'Pages/settings/api/ApiSettingsPage';
import { AuditTrailSettingsPage } from 'Pages/settings/auditTrail/AuditTrailSettingsPage';
import { AuthenticationSettingsPage } from 'Pages/settings/authentication/AuthenticationSettingsPage';
import { AuthorizedEmailSettingsPage } from 'Pages/settings/authorizedEmails/AuthorizedEmailSettingsPage';
import { BillingSettingsPage } from 'Pages/settings/billing/BillingSettingsPage';
import { CalendarSyncSettingsPage } from 'Pages/settings/calendarSync/CalendarSyncSettingsPage';
import { CustomFieldSettingsPage } from 'Pages/settings/customFields/CustomFieldSettingsPage';
import { EmailToTaskSettingsPage } from 'Pages/settings/emailToTask/EmailToTaskSettingsPage';
import { EmailSettingsPage } from 'Pages/settings/emails/EmailSettingsPage';
import { GeneralSettingsPage } from 'Pages/settings/general/GeneralSettingsPage';
import { LabsSettingsPage } from 'Pages/settings/labs/LabsSettingsPage';
import { OrganizationLabsSettingsPage } from 'Pages/settings/labs/OrganizationLabsSettingsPage';
import { LicensesPage } from 'Pages/settings/licenses/LicensesPage';
import { NotificationsSettingsPage } from 'Pages/settings/notifications/NotificationsSettingsPage';
import { OrganisationPreferencesPage } from 'Pages/settings/preferences/OrganisationPreferencesPage';
import { PreferencesPage } from 'Pages/settings/preferences/PreferencesPage';
import { ProfileSettingsPage } from 'Pages/settings/profile/ProfileSettingsPage';
import { SecuritySettingsPage } from 'Pages/settings/security/SecuritySettingsPage';
import { SignatureSettingsPage } from 'Pages/settings/signature/SignatureSettingsPage';
import { TeamsSettingsPage } from 'Pages/settings/teams/TeamsSettingsPage';
import { TermsSettingsPage } from 'Pages/settings/termsAndPolicies/TermsSettingsPage';
import { UsersSettingsPage } from 'Pages/settings/users/UsersSettingsPage';
import { SubscriptionPage } from 'Pages/subscription/SubscriptionPage';
import { PdfViewerContainer } from 'Shared/components/pdfViewer/PdfViewerContainer';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useTokenExpired } from 'Shared/hooks/useTokenExpired';
import { useWebSocketEvent } from 'Shared/hooks/useWebSocketEvent';
import { RequireAuthSearchParams } from 'Shared/services/auth';
import { checklistApi } from 'Shared/services/checklist';
import { buildGetMeetingParameters, meetingApi } from 'Shared/services/meeting';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { taskApi } from 'Shared/services/task';
import { teamApi } from 'Shared/services/team';
import { templateApi } from 'Shared/services/template';
import { useGetCurrentUserQuery, userApi } from 'Shared/services/user';
import { closeWebSocket, openWebSocket } from 'Shared/services/webSocket';
import { workspaceApi } from 'Shared/services/workspace';
import { TrpcProvider, TrpcProviderNoAuth } from 'Shared/trpc';
import { LocalStorage } from 'Shared/types/localStorage';
import { directQuery } from 'Shared/utils/rtkQuery';

const WebSocket = () => {
    const handleAction = useCallback(({ type, payload }: Action) => {
        store.dispatch({ type, payload, isFromWebSocket: true });
    }, []);

    useEffect(() => {
        void openWebSocket();
        return () => {
            void closeWebSocket();
        };
    }, []);

    useWebSocketEvent('action', handleAction);

    return <></>;
};

const CheckVersion = (): JSX.Element => {
    const location = useLocation();
    const inactiveStartTimeRef = useRef(null);
    const newVersionAvailable = useStore(versionStore, (state) => state.newVersionAvailable);

    useInterval(
        async () => {
            const { hash } = versionStore.getState();
            const response = await fetch('/');
            const text = await response.text();
            const remoteHash = getAssetsHash(new DOMParser().parseFromString(text, 'text/html'));
            if (hash !== remoteHash) {
                versionStore.setState({ newVersionAvailable: true });
            }
        },
        newVersionAvailable ? null : 5 * Minute
    );

    useInterval(
        async () => {
            const inactiveTime = inactiveStartTimeRef.current != null ? Date.now() - inactiveStartTimeRef.current : 0;
            if (inactiveTime > 5 * Minute) {
                window.location.reload();
            }
        },
        newVersionAvailable ? Minute : null
    );

    useEffect(() => {
        const { newVersionAvailable } = versionStore.getState();
        if (newVersionAvailable) {
            window.location.reload();
        }
    }, [location.pathname]);

    const handleBlur = useCallback(() => {
        inactiveStartTimeRef.current = Date.now();
    }, []);

    const handleFocus = useCallback(() => {
        inactiveStartTimeRef.current = null;
    }, []);

    useEvent('blur', handleBlur);
    useEvent('focus', handleFocus);

    return null;
};

const Telemetry = () => {
    const sessionUser = useSessionUser();

    useEffect(() => {
        if (__ENVIRONMENT__ !== 'production') {
            return () => {};
        }

        datadogRum.init({
            applicationId: '49db2401-23e4-4c0d-b81f-1801e6e38d7f',
            clientToken: 'pub4bf2908aa3550f3be3dd3bb718b44e4b',
            site: 'datadoghq.eu',
            service: 'frontend',
            env: __ENVIRONMENT__,
            version: clientVersion(),
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask',
            proxy: ({ path, parameters }) => {
                return `/t/rum${path}?${parameters}`;
            },
            allowedTracingUrls: [
                {
                    match: (url: string) =>
                        url.startsWith(location.origin + '/api') || url.startsWith(location.origin + '/rpc'),
                    propagatorTypes: ['tracecontext'],
                },
            ],
        });
        if (sessionUser != null) {
            datadogRum.setUser({ id: sessionUser.id.toString(), name: sessionUser.full_name });
        }
        return () => {
            datadogRum.stopSession();
        };
    }, []);

    return null;
};

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
    const matches = useMatches();
    const navigate = useNavigate();
    const authToken = getAuthToken();

    const [{ token, redirect, authenticationMethod }] = useSearchParams(RequireAuthSearchParams);

    const { loginRequired } = useTokenExpired();

    const { data: currentUser, isLoading: isCurrentUserLoading } = useGetCurrentUserQuery(null, {
        skip: authToken == null,
    });
    const { network } = useCurrentNetwork();
    const { data: organization } = useGetOrganizationQuery();

    if (authenticationMethod === 'saml') {
        localStorage.setItem(LocalStorage.AuthenticationMethod, 'saml');
    }

    const handle = matches
        .slice()
        .reverse()
        .find((match) => match.handle !== undefined).handle as { requiredAuth: boolean };

    const isAuthRequired = () => {
        return handle.requiredAuth;
    };

    const handleLoginRequired = useCallback(() => {
        if (isAuthRequired()) {
            loginRequired();
        }
    }, []);

    useEvent('loginRequired', handleLoginRequired, document);

    useEvent('storage', (event) => {
        // logging out from any tab should log us out
        if (!getAuthToken() && isAuthRequired()) {
            loginRequired();
            return;
        }

        // logging in from any tab should log us in
        if (event.key === LocalStorage.AuthToken && event.newValue && event.oldValue === null && network) {
            if (redirect) {
                navigate(redirect);
            } else {
                navigate({ pathname: '/tasks', searchParams: getLocalStorageSearchParams('/tasks') });
            }
        }
    });

    useLayoutEffect(() => {
        const isOrganizationInTrial = organization?.free_trial_end_date && organization?.status === 'in_trial';
        const trialEndDate = new Date(organization?.free_trial_end_date ?? new Date());
        const daysRemaining = differenceInDays(trialEndDate, new Date()) + 1;
        const authToken = storage.getItem(LocalStorage.AuthToken);

        if (
            isOrganizationInTrial &&
            daysRemaining <= 0 &&
            !location.pathname.startsWith('/expired') &&
            !location.pathname.startsWith('/onboarding/subscribe')
        ) {
            navigate('/expired');
        } else if (
            organization?.status === 'pending' &&
            'id' in currentUser &&
            authToken &&
            !location.pathname.startsWith('/onboarding') &&
            !location.pathname.startsWith('/onboarding_user')
        ) {
            navigate(`/onboarding_user/${currentUser.id}/${authToken}`);
        }
    }, [organization]);

    if (!isAuthRequired()) {
        return children;
    }

    // For retro-compatibility:
    if (!isEmpty(location.hash)) {
        const redirectUrl = new URL(location.href);
        const hash = redirectUrl.hash.slice(2).split('?');
        const pathName = hash[0];
        const search = hash[1];
        redirectUrl.pathname += pathName;
        redirectUrl.hash = '';
        if (search) {
            redirectUrl.search = search;
        }
        window.location.replace(redirectUrl);
        return <></>;
    }

    // Show the loading screen if we're still loading the current user
    if (isCurrentUserLoading) {
        return <LoadingRedirectPage />;
    }

    // If we can't validate the token, redirect to the login page
    if (!currentUser && token == null && !isCurrentUserLoading) {
        loginRequired();
        return null;
    }

    if (authToken == null) {
        return null;
    }

    return (
        <TrpcProvider queryClient={defaultQueryClient}>
            <WebSocket />
            <Suspense>
                <Telemetry />
            </Suspense>
            {children}
        </TrpcProvider>
    );
};

const WithIntercom = ({ children }: { children: ReactNode }) => {
    const { setUnreadIntercomMessageCount } = useCurrentUserContext();

    return (
        <IntercomProvider
            appId={__INTERCOM_APP_ID__}
            autoBoot
            autoBootProps={{ hideDefaultLauncher: true }}
            onUnreadCountChange={setUnreadIntercomMessageCount}
        >
            {children}
        </IntercomProvider>
    );
};

const Home: FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        void navigate({ pathname: '/tasks', searchParams: getLocalStorageSearchParams('/tasks') });
    }, []);

    return <div />;
};

export const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <TrpcProviderNoAuth queryClient={defaultQueryClient}>
                <CheckVersion />
                <RequireAuth>
                    <CurrentUserContextProvider>
                        <WithIntercom>
                            <LoginContextProvider>
                                <>
                                    <Modals />
                                    <Outlet />
                                </>
                            </LoginContextProvider>
                        </WithIntercom>
                    </CurrentUserContextProvider>
                </RequireAuth>
            </TrpcProviderNoAuth>
        ),
        errorElement: <ErrorPage />,
        handle: { requiredAuth: true },
        children: [
            {
                handle: { requiredAuth: false },
                children: [
                    {
                        path: '/onboarding/subscribe/*',
                        element: <SubscriptionPage />,
                    },
                    {
                        path: '/onboarding/:token/*',
                        element: <OnboardingPage />,
                    },
                    {
                        path: '/onboarding_user/:userId/:token/*',
                        element: <OnboardingPage />,
                    },
                    {
                        path: '/expired',
                        element: <TrialEndedPage />,
                    },
                ],
            },
            {
                element: (
                    <LoginContextProvider>
                        <AuthenticationPage />
                    </LoginContextProvider>
                ),
                errorElement: <ErrorPage />,
                handle: { requiredAuth: false },
                children: [
                    {
                        path: '/signin',
                        element: <SignInPage />,
                    },
                    {
                        path: '/lost',
                        element: <ForgotPasswordPage />,
                    },
                    {
                        path: '/magic-link',
                        element: (
                            <IntercomProvider
                                appId={__INTERCOM_APP_ID__}
                                autoBoot
                                autoBootProps={{ hideDefaultLauncher: true }}
                            >
                                <MagicLinkPage />
                            </IntercomProvider>
                        ),
                    },
                    {
                        path: '/reset-password',
                        element: <ResetPasswordPage />,
                    },
                    {
                        path: '/lost-totp',
                        element: <ForgotTotpPage />,
                    },
                ],
            },
            {
                element: (
                    <Suspense fallback={<LoadingRedirectPage />}>
                        <UsersStore>
                            <AppPage />
                            <PdfViewerContainer />
                        </UsersStore>
                    </Suspense>
                ),
                handle: { requiredAuth: true },
                children: [
                    {
                        errorElement: <RoutingError />,
                        children: [
                            {
                                index: true,
                                element: <Home />,
                            },

                            {
                                path: '/inbox',
                                element: <InboxPage />,
                                children: [
                                    { path: 'notifications', element: <NotificationsPage /> },
                                    { path: 'votes', element: <VotesPage /> },
                                    { path: 'signatures', element: <SignaturesPage /> },
                                ],
                            },
                            {
                                path: '/badges',
                                element: <BadgesPage />,
                            },
                            {
                                path: '/me/tasks/*',
                                element: <Home />,
                            },
                            {
                                path: '/dev-tools',
                                element: <DevTools />,
                            },
                            {
                                path: '/tasks',
                                element: <TasksPage defaultView="me" defaultStatuses={['todo']} />,
                            },
                            {
                                path: '/tasks/:taskId',
                                loader: async ({ params: { taskId } }) =>
                                    directQuery(taskApi.endpoints.getTask, { id: taskId }),
                                element: <TaskPage />,
                            },
                            {
                                path: '/meetings',
                                element: <MeetingListPage />,
                            },
                            {
                                path: '/meetings/upcoming/:status',
                                element: <MeetingListPage />,
                            },
                            {
                                path: '/meetings/:meetingId',
                                loader: async ({ params: { meetingId } }) =>
                                    directQuery(meetingApi.endpoints.getMeeting, buildGetMeetingParameters(meetingId)),
                                element: <MeetingPage />,
                            },
                            {
                                path: '/topics',
                                element: <MyTopicsPage />,
                            },
                            {
                                path: '/governance',
                                element: <GovernancePage />,
                            },
                            {
                                path: '/feed/:filter?',
                                element: <FeedPage type="me" />,
                            },

                            {
                                path: '/search',
                                element: <SearchPage />,
                                children: [
                                    { path: 'topics', element: <TopicsSearchPage /> },
                                    { path: 'blocks', element: <MeetingBlocksSearchPage /> },
                                    {
                                        path: 'tasks',
                                        element: (
                                            <TasksPage
                                                defaultView="all"
                                                defaultStatuses={['todo']}
                                                defaultOrder={'rank'}
                                            />
                                        ),
                                    },
                                    { path: 'files', element: <FilesSearchPage /> },
                                ],
                            },
                            {
                                path: '/workspaces',
                                element: <WorkspacesPage />,
                            },
                            {
                                path: '/workspaces/:workspaceId',
                                loader: async ({ params: { workspaceId } }) =>
                                    directQuery(workspaceApi.endpoints.getWorkspace, workspaceId),
                                element: <WorkspacePage />,
                                children: [
                                    { path: 'overview', element: <WorkspaceOverviewPage /> },
                                    {
                                        path: 'tasks',
                                        element: <TasksPage defaultView="all" defaultStatuses={['todo']} />,
                                    },
                                    { path: 'gantt', element: <GanttPage /> },
                                    { path: 'meetings', element: <MeetingListPage /> },
                                    { path: 'calendar', element: <CalendarPage /> },
                                    { path: 'files', element: <FilesPage /> },
                                    { path: 'checklists', element: <ChecklistsPage /> },
                                    { path: 'feed/:filter?', element: <FeedPage type="workspace" /> },
                                ],
                            },
                            {
                                path: '/templates',
                                element: <TemplatesPage />,
                            },
                            {
                                path: '/templates/:templateId',
                                loader: async ({ params: { templateId } }) =>
                                    directQuery(templateApi.endpoints.getChecklistTemplate, templateId),
                                element: <TemplatePage />,
                                children: [
                                    { path: 'overview', element: <TemplateOverviewPage /> },
                                    { path: 'checklists', element: <ChecklistsPage /> },
                                    { path: 'feed/:filter?', element: <FeedPage type="template" /> },
                                    {
                                        path: 'tasks',
                                        element: (
                                            <TasksPage
                                                defaultView="all"
                                                defaultStatuses={['todo']}
                                                defaultOrder="section"
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: '/checklists/:checklistId',
                                loader: async ({ params: { checklistId } }) =>
                                    directQuery(checklistApi.endpoints.getChecklist, checklistId),
                                element: <ChecklistPage />,
                                children: [
                                    {
                                        path: 'tasks',
                                        element: (
                                            <TasksPage
                                                defaultView="all"
                                                defaultStatuses={['todo', 'completed', 'deleted']}
                                                defaultOrder="section"
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: '/teams',
                                element: <TeamsPage />,
                            },
                            {
                                path: '/teams/:teamId',
                                loader: async ({ params: { teamId } }) =>
                                    directQuery(teamApi.endpoints.getTeam, { teamId }),
                                element: <TeamPage />,
                                children: [
                                    { path: 'workspaces', element: <WorkspacesPage /> },
                                    { path: 'meetings', element: <MeetingListPage /> },
                                    { path: 'templates', element: <TemplatesPage /> },
                                    { path: 'feed/:filter?', element: <FeedPage type="team" /> },
                                ],
                            },
                            {
                                path: '/users',
                                element: <UsersPage />,
                            },
                            {
                                path: '/users/:userId',
                                loader: async ({ params: { userId } }) =>
                                    directQuery(userApi.endpoints.getUser, userId),
                                element: <UserPage />,
                                children: [
                                    { path: 'profile', element: <ColleagueProfilePage /> },
                                    {
                                        path: 'tasks',
                                        element: <TasksPage defaultView="all" defaultStatuses={['todo']} />,
                                    },
                                    {
                                        path: 'badges',
                                        element: <BadgesPage />,
                                    },
                                    { path: 'feed/:filter?', element: <FeedPage type="colleague" /> },
                                    { path: 'governance/:filter?', element: <CircleMemberProfile /> },
                                ],
                            },
                            {
                                path: '/settings',
                                element: <SettingsPage />,
                                children: [
                                    { path: 'profile', element: <ProfileSettingsPage /> },
                                    { path: 'preferences', element: <PreferencesPage /> },
                                    { path: 'emails', element: <EmailSettingsPage /> },
                                    { path: 'security', element: <SecuritySettingsPage /> },
                                    { path: 'notifications', element: <NotificationsSettingsPage /> },
                                    { path: 'ics', element: <CalendarSyncSettingsPage /> },
                                    { path: 'email-to-task', element: <EmailToTaskSettingsPage /> },
                                    { path: 'api', element: <ApiSettingsPage /> },
                                    { path: 'labs', element: <LabsSettingsPage /> },
                                    { path: 'general', element: <GeneralSettingsPage /> },
                                    {
                                        path: 'organisation-preferences',
                                        element: <OrganisationPreferencesPage />,
                                    },
                                    { path: 'users', element: <UsersSettingsPage /> },
                                    { path: 'signature', element: <SignatureSettingsPage /> },
                                    { path: 'teams', element: <TeamsSettingsPage /> },
                                    { path: 'auth', element: <AuthenticationSettingsPage /> },
                                    { path: 'audit-trail', element: <AuditTrailSettingsPage /> },
                                    { path: 'custom-fields/:status?', element: <CustomFieldSettingsPage /> },
                                    { path: 'authorized-emails', element: <AuthorizedEmailSettingsPage /> },
                                    { path: 'terms', element: <TermsSettingsPage /> },
                                    { path: 'licenses', element: <LicensesPage /> },
                                    { path: 'billing', element: <BillingSettingsPage /> },
                                    { path: 'organization-labs', element: <OrganizationLabsSettingsPage /> },
                                ],
                            },
                            { path: '*', element: <PageNotFoundErrorCard /> },
                        ],
                    },
                ],
            },
            { path: '*', element: <PageNotFoundErrorCard /> },
        ],
    },
]);
