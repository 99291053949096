import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { Button, Checkbox, ContextModalProps, Label, Modal } from '@wedo/design-system';
import { CircleSearchSelect } from 'Pages/governance/CircleSearchSelect';
import { getCircleBreadcrumb } from 'Pages/governance/utils';
import { useCopyCircleMutation } from 'Shared/services/governance';
import { Circle, Root } from 'Shared/types/governance';

type CopyCircleModalProps = {
    circles: HierarchyCircularNode<Circle | Root>[];
    circle: HierarchyCircularNode<Circle>;
} & ContextModalProps;

export const CopyCircleModal = ({ circle, circles, ...modalProps }: CopyCircleModalProps) => {
    const [copyCircle] = useCopyCircleMutation();
    const [copyChildren, setCopyChildren] = useState(false);
    const [copyMembers, setCopyMembers] = useState(false);
    const [selectedCircle, setSelectedCircle] = useState(null);

    const { title, breadcrumb } = useMemo(
        () => ({
            title: circle.data.type === 'circle' ? t`Copy circle` : t`Copy role`,
            breadcrumb: getCircleBreadcrumb(circle.data, circles),
        }),
        [circle]
    );

    return (
        <Modal {...modalProps}>
            <Modal.Header
                title={
                    <>
                        <div>{title}</div>
                        <div className="text-xs text-gray-700">{breadcrumb}</div>
                    </>
                }
            />
            <Modal.Body>
                <CircleSearchSelect
                    label={t`Destination`}
                    circles={circles.filter((c) => c.id !== circle.id)}
                    value={selectedCircle}
                    setValue={setSelectedCircle}
                />

                <div className="my-2 flex items-center gap-2">
                    <Checkbox
                        id="copy-members"
                        checked={copyMembers}
                        onChange={(e) => setCopyMembers(e.target.checked)}
                    />
                    <Label inputType="inline" htmlFor="copy-members">{t`Copy members`}</Label>
                </div>
                {circle.data.type === 'circle' && (
                    <div className="flex items-center gap-2">
                        <Checkbox
                            id="copy-children"
                            checked={copyChildren}
                            onChange={(e) => setCopyChildren(e.target.checked)}
                        />
                        <Label inputType="inline" htmlFor="copy-children">{t`Copy child circles and roles`}</Label>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button
                    disabled={selectedCircle == null}
                    onClick={async () => {
                        await copyCircle({
                            id: circle.data.id,
                            destination_circle_id: selectedCircle !== 'root' ? selectedCircle : null,
                            copy_members: copyMembers,
                            copy_children: copyChildren,
                        });
                        modalProps.close();
                    }}
                    color="primary"
                >{t`Copy`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
