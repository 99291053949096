import { useEffect, useState } from 'react';
import { sleep } from '@wedo/utils';
import { useEvent } from './useEvent';

const IsOnlineTimeout = 500;
const IsOnlinePollingInterval = 1000;

const fetchServerInfo = async () => {
    const { abort, signal } = new AbortController();
    const timeout = setTimeout(abort, IsOnlineTimeout);
    try {
        const response = await fetch('/api/info', { signal });
        if (!response.ok) {
            throw response;
        }
    } finally {
        clearTimeout(timeout);
    }
};

export const useNetworkStatus = (): boolean => {
    const [isOnline, setIsOnline] = useState<boolean>(() =>
        typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true
    );
    const [isFetching, setIsFetching] = useState(false);

    const checkConnection = async () => {
        if (isFetching) {
            return;
        }

        // eslint-disable-next-line no-constant-condition
        while (true) {
            setIsFetching(true);
            try {
                await fetchServerInfo();
                setIsOnline(true);
                break;
            } catch {
                setIsOnline(false);
                await sleep(IsOnlinePollingInterval);
            } finally {
                setIsFetching(false);
            }
        }
    };

    useEffect(() => {
        void checkConnection();
    }, []);

    useEvent('offline', () => {
        setIsOnline(false);
        void checkConnection();
    });
    useEvent('online', checkConnection);

    return isOnline;
};
