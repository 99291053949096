import React, { forwardRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Input, Label, Modal } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useAddUserEmailMutation } from 'Shared/services/user-email';
import { RtkQueryTransformedError } from 'Shared/types/rtkQuery';
import { UserEmail } from 'Shared/types/user-email';

type AddNewEmailModalBodyProps = {
    password: string;
    onSuccess: (email: UserEmail) => void;
    close: () => void;
};

export const AddNewEmailModalBody = forwardRef<HTMLInputElement, AddNewEmailModalBodyProps>(
    ({ password, onSuccess, close }, ref) => {
        const [addUserEmail, { isLoading, error: addUserEmailError }] = useAddUserEmailMutation();

        const [email, setEmail] = useState<string>(EmptyString);

        const handleAddNewEmail = async () => {
            const response = await addUserEmail({ emailAddress: email, password });
            if ('data' in response) {
                onSuccess(response.data);
            }
        };

        return (
            <>
                <Modal.Body>
                    <Label>
                        <Trans>Enter new email</Trans>

                        <Input
                            ref={ref}
                            placeholder={t`Email address`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onPressEnter={!isEmpty(email) && handleAddNewEmail}
                            status={addUserEmailError ? 'error' : 'default'}
                            statusText={addUserEmailError && (addUserEmailError as RtkQueryTransformedError).message}
                        />
                    </Label>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="ml-auto" onClick={close} disabled={isLoading}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleAddNewEmail}
                        disabled={isEmpty(email)}
                        loading={isLoading}
                        data-testid="add-email"
                    >
                        <Trans>Next</Trans>
                    </Button>
                </Modal.Footer>
            </>
        );
    }
);
