import React from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { isEmpty } from 'lodash-es';
import { Button } from '@wedo/design-system';
import { CustomGroupItemActions } from 'Pages/settings/customFields/components/CustomGroupItem/CustomGroupItemActions';
import { GroupItemFieldsTable } from 'Pages/settings/customFields/components/CustomGroupItem/GroupItemFieldsTable/GroupItemFieldsTable';
import { ItemContainer } from 'Pages/settings/customFields/components/Item/ItemContainer';
import { ItemLabel } from 'Pages/settings/customFields/components/Item/ItemLabel';
import { LastUpdatedByAvatar } from 'Pages/settings/customFields/components/LastUpdatedByAvatar';
import { ArchivedTag } from 'Pages/settings/customFields/components/tags/ArchivedTag';
import { useGetUserQuery } from 'Shared/services/user';
import { CustomFieldGroup } from 'Shared/types/customField';

export type CustomGroupItemProps = {
    group: CustomFieldGroup;
    areArchivedItemsVisible: boolean;
    className?: string;
    isCollapsed?: boolean;
    collapseGroup?: () => void;
};

export const CustomGroupItem: React.FC<CustomGroupItemProps> = ({
    group,
    areArchivedItemsVisible,
    className,
    isCollapsed,
    collapseGroup,
}) => {
    const { data: createdBy } = useGetUserQuery(group?.created_by, { skip: isEmpty(group?.created_by) });

    return (
        <ItemContainer className={className}>
            <div className={'flex max-w-full items-center justify-between overflow-hidden bg-gray-100 px-4 py-2'}>
                <div className="flex items-center gap-1 truncate">
                    <Button
                        variant="text"
                        icon={isCollapsed ? faChevronRight : faChevronDown}
                        onClick={collapseGroup}
                        size="sm"
                    />
                    <ItemLabel label={group?.label} icon={group?.icon} />
                </div>

                <div className="flex items-center gap-2">
                    {group?.archived && <ArchivedTag />}
                    <LastUpdatedByAvatar createdBy={createdBy} createdAt={group?.created_at} />
                    <CustomGroupItemActions group={group} />
                </div>
            </div>

            {!isCollapsed && (
                <GroupItemFieldsTable className="my-2 ml-8 mr-4" group={group} showArchived={areArchivedItemsVisible} />
            )}
        </ItemContainer>
    );
};
