import { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { GanttPageParams } from './GanttPage';

const PageSize = 30;

export const useInfiniteTasks = () => {
    const { workspaceId } = useParams<GanttPageParams>();

    const tryFetchNextPageRef = useRef<() => Promise<void>>();

    const { data, fetchNextPage, isFetching, hasNextPage } = trpc.task.gantt.list.useInfiniteQuery(
        { workspaceId: workspaceId!, limit: PageSize },
        {
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
            trpc: { context: { skipBatch: true } },
            meta: { tags: [taskQueryTag.added(), taskQueryTag.removed(), taskQueryTag.updated('*', 'order')] },
        }
    );

    const tasks = useMemo(() => data?.pages.flatMap((tasks) => tasks), [data]);

    tryFetchNextPageRef.current = useCallback(async () => {
        if (!isFetching && hasNextPage) {
            await fetchNextPage();
        }
    }, [isFetching, hasNextPage]);

    const tryFetchNextPage = useCallback(() => tryFetchNextPageRef.current!(), []);

    return { tasks, tryFetchNextPage };
};
