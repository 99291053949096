import { QueryCache, QueryClient } from '@tanstack/react-query';
import { removeAuthToken } from '@wedo/utils';

export const defaultQueryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            if (error?.data?.code === 'UNAUTHORIZED') {
                removeAuthToken();
            }
        },
    }),
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
            retry: (failureCount, error) => {
                if (error.data != null && error.data.httpStatus.toString().startsWith('4')) {
                    return false;
                }

                return failureCount < 3;
            },
        },
        mutations: {
            onError: (error) => {
                if (error?.data?.code === 'UNAUTHORIZED') {
                    removeAuthToken();
                }
            },
        },
    },
});
