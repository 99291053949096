import React from 'react';
import { Trans } from '@lingui/macro';
import { Tooltip, Skeleton, FormatDateDistance } from '@wedo/design-system';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { User } from 'Shared/types/user';

type LastUpdatedByAvatarProps = {
    createdBy: User;
    createdAt: string | Date;
};

export const LastUpdatedByAvatar: React.FC<LastUpdatedByAvatarProps> = ({ createdBy, createdAt }) => {
    return (
        <Tooltip
            content={
                <Trans>
                    Created by {createdBy?.full_name} <FormatDateDistance date={createdAt} />
                </Trans>
            }
        >
            {!createdBy ? (
                <Skeleton className="h-5 w-5 rounded-full" />
            ) : (
                <UserAvatar user={createdBy} size="xs" showTooltip={false} />
            )}
        </Tooltip>
    );
};
