import { useMemo } from 'react';
import { isArray, isEmpty } from 'lodash-es';
import { Id } from '@wedo/types';
import { useGetCustomFieldsQuery } from 'Shared/services/customFields';
import { CustomField, CustomFieldType } from 'Shared/types/customField';

export const useCustomFields = () => {
    const { data: customFields, isLoading: isLoadingFields, isError, error, refetch } = useGetCustomFieldsQuery();

    const useDropdownFields = (workspaceId: Id) =>
        useMemo<Array<CustomField>>(() => {
            if (!isArray(customFields)) return [];
            return customFields.filter(
                (field) =>
                    field.type === CustomFieldType.Dropdown &&
                    (field.is_global || field.tags.some((tag) => tag.id === workspaceId))
            );
        }, [workspaceId]);

    const totalFields = useMemo<number>(() => (customFields ? customFields.length : 0), [customFields]);

    const numberArchivedFields = useMemo<number>(() => {
        if (!customFields) return 0;
        let count = 0;
        for (const field of customFields) {
            if (field.archived) count++;
        }
        return count;
    }, [customFields]);

    const maxOrderFields = useMemo<number>(() => {
        if (isEmpty(customFields)) return -1;
        let max = 0;
        for (const field of customFields) {
            max = Math.max(max, field.order);
        }
        return max;
    }, [customFields]);

    return {
        customFields,
        totalFields,
        numberArchivedFields,
        maxOrderFields,
        isLoadingFields,
        useDropdownFields,
        isError,
        error,
        refetch,
    };
};
