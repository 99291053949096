import { FC } from 'react';
import { t } from '@lingui/macro';
import { ContextModalProps, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import store from 'App/store';
import { MoveEntityToAnotherTeamModal } from 'Shared/components/team/MoveEntityToAnotherTeamModal';
import { invalidateWorkspaceById, useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc, trpcUtils } from 'Shared/trpc';

type MoveWorkspaceToAnotherTeamModalProps = { workspaceId: Id } & ContextModalProps;

export const MoveWorkspaceToAnotherTeamModal: FC<MoveWorkspaceToAnotherTeamModalProps> = ({
    workspaceId,
    ...modalProps
}) => {
    const { show } = useNotification();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);

    const { mutateAsync: move } = trpc.workspace.move.useMutation({
        onError: () => show(UnexpectedErrorNotification),
        onSuccess: () =>
            Promise.all([
                trpcUtils().team.list.invalidate(),
                trpcUtils().workspace.list.invalidate(),
                store.dispatch(invalidateWorkspaceById(workspaceId)),
            ]),
    });

    const handleMoveWorkspaceToTeam = async (teamId: Id) => {
        await move({ workspaceId, teamId });
    };

    return (
        <MoveEntityToAnotherTeamModal
            {...modalProps}
            entity={workspace}
            entityName="workspace"
            onMoveEntityToTeam={handleMoveWorkspaceToTeam}
            modalTitle={t`Move ${workspace?.name} to another team`}
        />
    );
};
