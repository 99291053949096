import type { RefObject } from 'react';
import { useStore } from 'zustand';
import { useGanttContextStore } from './GanttContext';
import { TimelineDependency } from './TimelineDependency';

type TimelineFloatingDependencyProps = {
    timelineRef: RefObject<HTMLElement>;
};

export const TimelineFloatingDependency = ({ timelineRef }: TimelineFloatingDependencyProps) => {
    const store = useGanttContextStore()!;

    const floatingDependency = useStore(store, ({ view }) => view.floatingDependency);

    if (floatingDependency?.pointerPosition == null) {
        return null;
    }

    return (
        <TimelineDependency
            from={
                floatingDependency.direction === 'from'
                    ? floatingDependency.element
                    : floatingDependency.canConnect
                      ? floatingDependency.hoverElement
                      : undefined
            }
            to={
                floatingDependency.direction === 'to'
                    ? floatingDependency.element
                    : floatingDependency.canConnect
                      ? floatingDependency.hoverElement
                      : undefined
            }
            pointerPosition={floatingDependency.pointerPosition}
            className="z-10"
            isDashed={true}
            timelineRef={timelineRef}
        />
    );
};
