import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Button, UnexpectedErrorNotification, useModal, useNotification } from '@wedo/design-system';
import { MaxUsersReachedModal } from 'Pages/settings/users/components/MaxUsersReachedModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useInviteUserMutation } from 'Shared/services/admin';
import { inviteUserError } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { User } from 'Shared/types/user';

export const InvitationPendingCell: FC<User> = ({ id }) => {
    const [sendInvitation, invitationStatus] = useInviteUserMutation();
    const { show } = useNotification();
    const { open } = useModal();
    const { isScimStrictMode } = useCurrentNetwork();

    const handleInvitation = async () => {
        const result = await sendInvitation({ user_id: id });

        if ('error' in result && result.error instanceof ApiError) {
            if (result.error.matches(inviteUserError.MaxUser) || result.error.matches(inviteUserError.MaxLight)) {
                open(MaxUsersReachedModal);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            void trpcUtils().user.list.invalidate();
        }
    };

    return (
        <Button
            size={'sm'}
            variant={'outlined'}
            color="success"
            onClick={handleInvitation}
            loading={invitationStatus.isLoading}
            disabled={isScimStrictMode}
        >
            <Trans>Send invitation</Trans>
        </Button>
    );
};
