import { FC, useContext, useEffect, useState } from 'react';
import { storage } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { BackupCodePage } from 'Pages/ForgotTotpPage/components/BackupCodePage';
import { PhoneNumberPage } from 'Pages/ForgotTotpPage/components/PhoneNumberPage';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { LocalStorage } from 'Shared/types/localStorage';

type CodeType = 'sms' | 'backup-code';

export const ForgotTotpPage: FC = () => {
    const [codeType, setCodeType] = useState<CodeType>('sms');
    const navigate = useNavigate();
    const { loginState, updateLoginState } = useContext(LoginContext);

    const handleLogin = (authToken: string) => {
        updateLoginState({ authToken });
        storage.setItem(LocalStorage.AuthToken, authToken);
    };

    useEffect(() => {
        if (loginState.userId === '') {
            navigate('/', { replace: true });
        }
    }, [loginState.userId]);

    switch (codeType) {
        case 'sms':
            return <PhoneNumberPage onLogin={handleLogin} onChange={() => setCodeType('backup-code')} />;

        case 'backup-code':
            return <BackupCodePage onLogin={handleLogin} onChange={() => setCodeType('sms')} />;

        default:
            return null;
    }
};
