import React from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { Button, Checkbox } from '@wedo/design-system';
import { type Store } from '@wedo/pdf/meeting/store';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { type Attachment } from 'Shared/types/attachment';
import { isAttachmentPreviewableOrOffice } from 'Shared/utils/attachment';
import { closestY } from 'Shared/utils/dnd';
import { useSetting } from './store';

type AttachmentItemProps = {
    store: Store;
    attachment: Attachment;
    onAttachmentsChecked: (id: string) => void;
    isDisabled: boolean;
};

const AttachmentItem = ({ store, attachment, onAttachmentsChecked, isDisabled }: AttachmentItemProps) => {
    const attachmentsToDisplay = useSetting(store, 'attachmentsToDisplay');

    const { draggableStyle } = useDndSortableVerticalStrategy();
    const { listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: attachment.id });
    const style = draggableStyle(transform, transition, isDragging);

    const isPreviewable = isAttachmentPreviewableOrOffice(attachment);

    return (
        <div className="flex" ref={setNodeRef} {...listeners} style={style}>
            <Checkbox
                key={attachment.id}
                name={attachment.id.toString()}
                disabled={!isPreviewable || isDisabled}
                onChange={({ target }) => onAttachmentsChecked(target.name)}
                checked={
                    attachmentsToDisplay.find(({ id, isSelected }) => id === attachment.id.toString() && isSelected) !=
                    null
                }
            />

            <Button
                variant="ghost"
                className={clsx(
                    'select-none px-2 !whitespace-break-spaces text-left',
                    isDragging ? 'cursor-grabbing' : 'cursor-grab'
                )}
                onClick={() => isPreviewable && onAttachmentsChecked(attachment.id.toString())}
            >
                {attachment.name}
            </Button>
        </div>
    );
};

type MeetingExportAttachmentsListProps = {
    store: Store;
    attachments: Array<Attachment>;
    onAttachmentsChecked: (id: string) => void;
    onAttachmentsDragged: (event: DragEndEvent) => void;
    isDisabled: boolean;
};

export const MeetingExportAttachmentsList = ({
    store,
    attachments,
    onAttachmentsChecked,
    onAttachmentsDragged,
    isDisabled,
}: MeetingExportAttachmentsListProps) => {
    const { sensors, measuring } = useDndSortableVerticalStrategy();

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestY}
            measuring={measuring}
            onDragEnd={onAttachmentsDragged}
            modifiers={[restrictToVerticalAxis]}
        >
            <SortableContext items={attachments.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
                {attachments.map((attachment) => (
                    <AttachmentItem
                        key={attachment.id}
                        store={store}
                        attachment={attachment}
                        onAttachmentsChecked={onAttachmentsChecked}
                        isDisabled={isDisabled}
                    />
                ))}
            </SortableContext>
        </DndContext>
    );
};
