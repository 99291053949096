import React from 'react';
import { Dropdown } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { TaskStatus } from 'Shared/types/task';
import { pluralTaskStatusToLabel } from 'Shared/utils/task';

type StatusItemProps = {
    status: TaskStatus;
    statuses: TaskStatus[];
    onChange?: (statuses: TaskStatus[]) => void;
    isDisabled?: boolean;
};

export const TaskStatusDropdownItem = ({ status, statuses, onChange, isDisabled }: StatusItemProps) => {
    const [, setSearchParams] = useSearchParams();

    const isChecked = statuses.includes(status);

    const handleCheckboxChange = () => {
        const newStatus = !isChecked ? [...statuses, status] : statuses.filter((s) => s !== status);
        if (onChange != null) {
            onChange(newStatus);
        } else {
            setSearchParams((searchParams) => ({
                ...searchParams,
                status: newStatus,
            }));
        }
    };

    const handleChange = () => {
        const newStatus = [status];
        if (onChange != null) {
            onChange(newStatus);
        } else {
            setSearchParams((searchParams) => ({
                ...searchParams,
                status: newStatus,
            }));
        }
    };

    return (
        <Dropdown.CheckboxItem
            onCheckboxChange={handleCheckboxChange}
            isCheckboxDisabled={(isChecked && statuses.length <= 1) || isDisabled}
            checked={isChecked}
            onChange={handleChange}
            disabled={isDisabled}
        >
            {pluralTaskStatusToLabel()[status]}
        </Dropdown.CheckboxItem>
    );
};
