import React from 'react';
import { faGavel } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { NavLink } from '@wedo/design-system';
import { FeedItem } from 'Pages/activityFeed/FeedItem/FeedItem';
import { ReadOnlyEditor } from 'Shared/components/editor/ReadOnlyEditor';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { ActivityLog } from 'Shared/types/activityLog';
import { MeetingBlock } from 'Shared/types/meetingBlock';

export const FeedItemDecision = ({ activityFeed }: { activityFeed: ActivityLog }): JSX.Element => {
    const meetingBlock = activityFeed.object as MeetingBlock;

    const NavWrapper = ({ children }: { children: JSX.Element }) => (
        <NavLink
            to={{
                pathname: `/meetings/${meetingBlock.topic?.meeting?.id}`,
                searchParams: { meeting_topic_id: meetingBlock.topic?.id as string },
            }}
        >
            {children}
        </NavLink>
    );
    return (
        <FeedItem icon={faGavel} color="green">
            <FeedItem.Wrapper color="green" isDark>
                <NavWrapper>
                    <div className="px-6">
                        <ReadOnlyEditor meetingId={null} blocks={[meetingBlock]} />
                    </div>
                </NavWrapper>
            </FeedItem.Wrapper>
            <FeedItem.Footer
                createdAt={new Date(activityFeed.created_at)}
                createdBy={activityFeed.created_by}
                customFooter={
                    <div>
                        <span>{t`Decision taken during the meeting`}</span>&nbsp;
                        <b>
                            <NavWrapper>
                                <FormatMeetingTitle
                                    title={meetingBlock.topic?.meeting?.title}
                                    startAt={meetingBlock.topic?.meeting?.start_at}
                                />
                            </NavWrapper>
                        </b>
                    </div>
                }
            />
        </FeedItem>
    );
};
