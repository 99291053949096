import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faEye, faEyeSlash, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Button, colors, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { useTasksContext } from 'App/contexts/TasksContext';
import { getUser } from 'App/store/usersStore';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { User } from 'Shared/types/user';

export const WatchersRow: FC<BulkEditTaskRowProps<Id[]>> = ({ value = [], onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonWatcherIds } = useBulkTasksEditPane(selectedTasks);
    const { workspaceId, checklistId, templateId } = useParams<TasksPageParams>();
    const { contextUsers, contextTitle } = useContextUsers({ checklistId, templateId, workspaceId });
    const { currentUserId, currentUser } = useCurrentUserContext();

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const isCurrentUserWatchingTask = value.includes(currentUserId);

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(commonWatcherIds);
        } else if (change === 'edit') {
            onChange(commonWatcherIds);
        } else if (change === 'clear') {
            onChange([]);
        }
    };

    const handleRemoveWatcher = (watcher: User) => onChange(value.filter((id) => id !== watcher.id));

    const handleAddWatcher = (watcher: User) => onChange([...value, watcher.id]);

    return (
        <BulkEditRow isResetHidden label={t`Watchers`} changeOption={changeValue} onChangeOptionChange={handleChange}>
            <div className={'flex flex-wrap items-center gap-2 mt-4'}>
                <Button
                    variant={'text'}
                    iconColor={value?.length === 0 ? colors.gray[400] : colors.gray[500]}
                    icon={!isCurrentUserWatchingTask ? faEye : faEyeSlash}
                    size={'sm'}
                    shape={'circle'}
                    title={!isCurrentUserWatchingTask ? t`Watch task` : t`Unwatch task`}
                    onClick={
                        !isCurrentUserWatchingTask
                            ? () => handleAddWatcher(currentUser)
                            : () => handleRemoveWatcher(currentUser)
                    }
                />

                {value.map((watcherId: Id) => {
                    const watcher = getUser(watcherId);
                    return (
                        <Tooltip key={watcherId} content={watcher?.full_name}>
                            <UserAvatar
                                size={'sm'}
                                onRemove={() => handleRemoveWatcher(watcher)}
                                removeButtonLabel={t`Remove ${watcher?.full_name} from watchers`}
                                user={watcher}
                            />
                        </Tooltip>
                    );
                })}

                <UserPicker
                    contextTitle={contextTitle}
                    contextUsers={contextUsers}
                    variant={value.length > 0 ? 'filled' : 'text'}
                    icon={value.length > 0 && faUserPlus}
                    size={value.length > 0 ? 'sm' : 'md'}
                    title={value.length > 0 && t`Add watcher`}
                    shape={value.length > 0 ? 'circle' : 'default'}
                    onUserSelected={handleAddWatcher}
                    usersToHide={value.map(getUser)}
                >
                    {value.length === 0 ? t`Add watcher` : undefined}
                </UserPicker>
            </div>
        </BulkEditRow>
    );
};
