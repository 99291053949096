import React, { CSSProperties, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faEllipsisV, faPen, faTrash, faUserLock } from '@fortawesome/pro-regular-svg-icons';
import { plural, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { AutoTooltipText, Dropdown, Skeleton, Tooltip, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue, stopPropagationOnMouseEvents } from '@wedo/utils';
import { useElementSize, useIsOverflowing } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { EditMeetingUserAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingUserAccessModal';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import {
    useMeetingPresentShares,
    useMeetingPresentVoters,
    useMeetingQuorumAttained,
    useMeetingTotalShares,
    useMeetingVoters,
} from 'Pages/meeting/components/Vote/VoteHooks';
import { shouldDisplayShares } from 'Pages/meeting/components/Vote/VoteUtils';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { updateGetMeetingCache } from 'Shared/services/meeting';
import { MeetingStatus } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingWrapperContainerContext } from '../MeetingView/MeetingViewBody';
import { AttendanceEditable, AttendanceReadonly } from './AttendanceEditable';
import { AttendeeRemark } from './AttendeeRemark';
import { applyOnAction } from './AttendeesEditable';

// Div with content that works with dnd-kit
const SelectableDiv = ({
    css,
    children,
}: {
    css: CSSProperties;
} & PropsWithChildren) => {
    return (
        <div {...stopPropagationOnMouseEvents} className={'min-w-0 max-w-fit cursor-text'} style={css}>
            {children}
        </div>
    );
};

const getAttendeeColumnsInfo = (
    attendees: MeetingUser[],
    containerSize: {
        width: number;
        height: number;
    },
    readonly = false
) => {
    if (!attendees) {
        return [];
    }

    let showTitle = false;
    let showLocation = false;
    let showRemark = false;
    let showContact = true;
    if (!readonly) {
        showRemark = true;
    }
    attendees.forEach((attendee) => {
        if (attendee.user_data.title) {
            showTitle = true;
        }
        if (attendee.remark) {
            showRemark = true;
        }
        if (attendee.user_data.location) {
            showLocation = true;
        }
    });
    if (containerSize != null) {
        if (containerSize?.width < getBreakpointValue('md')) {
            showTitle = false;
        }
        if (containerSize?.width < getBreakpointValue('lg')) {
            showContact = false;
            showLocation = false;
        }
    }
    return [
        {
            //photo
            show: true,
            width: '1fr',
        },
        {
            name: t`Attendees`,
            show: true,
            width: '3fr',
        },
        {
            name: t`Title`,
            show: showTitle,
            width: '3fr',
        },
        {
            name: t`Location`,
            show: showLocation,
            width: '2fr',
        },
        {
            name: t`Contact`,
            show: showContact,
            width: '3fr',
        },
        {
            name: t`Remark`,
            show: showRemark,
            width: '2fr',
        },
        {
            name: t`Attendance`,
            show: true,
            width: '1fr',
        },
        {
            //options
            show: true,
            width: '1fr',
        },
    ];
};

type AttendeesColumnInfo = {
    show: boolean;
    width: string;
    name?: string;
};

const getGridTemplateColumnsValue = (attendeesColumnsInfo: AttendeesColumnInfo[]) => {
    return attendeesColumnsInfo
        ?.map((column) => {
            return column.show ? column.width : null;
        })
        .join(' ');
};

const AttendeesTableHeaderRow = ({ columnInfo }: { columnInfo: AttendeesColumnInfo[] }) => {
    return (
        <>
            {columnInfo?.map((column, i) => (
                <div className={clsx(column.show ? 'block' : 'hidden', 'min-w-0')} key={`attendance-header-col-${i}`}>
                    {column.name}
                </div>
            ))}
        </>
    );
};

const attendeesTableClasses = [
    'group relative grid h-[58px] min-h-0 w-full min-w-0 max-w-full items-center gap-2 border-b border-black border-opacity-5 px-2 py-3 text-sm',
];

const attendeesRowClasses = (isBeingDragged: boolean, readonly: boolean) => [
    ...attendeesTableClasses,
    isBeingDragged ? 'z-50 cursor-grabbing bg-blue-100' : readonly ? 'cursor-auto' : 'cursor-grab',
];

interface AttendeeRowProps {
    attendee: MeetingUser;
    onUpdate?: applyOnAction;
    onEdit?: (attendee: MeetingUser) => void;
    onRemove?: (attendee: MeetingUser) => void;
    attendeesColumnInfo: AttendeesColumnInfo[];
    isBeingDragged?: boolean;
    readonly?: boolean;
    className?: string;
}

const AttendeeRow = ({
    attendee,
    onUpdate,
    onEdit,
    onRemove,
    attendeesColumnInfo,
    isBeingDragged,
    readonly = false,
    className,
}: AttendeeRowProps) => {
    const { meeting, meetingId } = useMeetingContext();
    const { open } = useModal();
    const { currentUser } = useCurrentUserContext();

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: attendee?.id as string });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const [readonlyRemarkRef, isReadonlyRemarkOverflowing] = useIsOverflowing();

    const handleEditRights = () => {
        open(EditMeetingUserAccessModal, { meetingId, userId: attendee.user_id });
    };

    return attendee ? (
        <div
            className={clsx(attendeesRowClasses(isBeingDragged, readonly), 'grow', className)}
            ref={setNodeRef}
            style={{
                ...style,
                gridTemplateColumns: getGridTemplateColumnsValue(attendeesColumnInfo),
            }}
            {...attributes}
            {...listeners}
        >
            <div className={clsx(attendeesColumnInfo?.[0].show ? 'block' : 'hidden', 'pl-3')}>
                <MeetingUserAvatar user={attendee} size="sm" />
            </div>
            <SelectableDiv css={{ display: attendeesColumnInfo?.[1].show ? 'block' : 'none' }}>
                <div className="text-sm">
                    <span className="whitespace-nowrap">
                        <AutoTooltipText>
                            {attendee.user_id != null
                                ? meeting.status === MeetingStatus.LOCKED
                                    ? attendee.user_data?.full_name || attendee.user.full_name
                                    : attendee.user.full_name
                                : attendee.user_data.external_full_name}
                        </AutoTooltipText>
                    </span>
                </div>
                {attendee.user_data.organisation_name ? (
                    <span className="text-gray-600 whitespace-nowrap">
                        <AutoTooltipText>{attendee.user_data.organisation_name}</AutoTooltipText>
                    </span>
                ) : null}
            </SelectableDiv>
            <SelectableDiv css={{ display: attendeesColumnInfo?.[2].show ? 'block' : 'none' }}>
                <span className="whitespace-nowrap">
                    <AutoTooltipText>{attendee.user_data.title}</AutoTooltipText>
                </span>
            </SelectableDiv>
            <SelectableDiv css={{ display: attendeesColumnInfo?.[3].show ? 'block' : 'none' }}>
                <AutoTooltipText>{attendee.user_data.location}</AutoTooltipText>
            </SelectableDiv>
            <SelectableDiv css={{ display: attendeesColumnInfo?.[4].show ? 'block' : 'none' }}>
                <AutoTooltipText>
                    {/* If the meeting's locked, we want to prioritise displaying the email that was saved at the time of the lock */}
                    {/* If not, we want to prioritise displaying the current email of the user */}
                    {meeting.status === MeetingStatus.LOCKED
                        ? attendee.user_data.email_address ||
                          attendee.user?.userEmail?.email_address ||
                          attendee.user?.email_address
                        : attendee.user?.userEmail?.email_address ||
                          attendee?.user?.email_address ||
                          attendee.user_data?.email_address}
                </AutoTooltipText>
                {attendee.user_data.phone_number && (
                    <div>
                        <AutoTooltipText>{attendee.user_data.phone_number}</AutoTooltipText>
                    </div>
                )}
            </SelectableDiv>
            {attendeesColumnInfo?.[5].show &&
                (!readonly ? (
                    <AttendeeRemark attendeeId={attendee.id} remark={attendee.remark} onUpdate={onUpdate} />
                ) : (
                    <Tooltip content={isReadonlyRemarkOverflowing ? attendee.remark : undefined}>
                        <div className={'min-w-0 max-w-fit cursor-text'}>
                            <div className="truncate" ref={readonlyRemarkRef}>
                                {attendee.remark}
                            </div>
                        </div>
                    </Tooltip>
                ))}
            {attendeesColumnInfo?.[6].show &&
                (!readonly ? (
                    <AttendanceEditable attendeeId={attendee.id} attendance={attendee.attendance} onUpdate={onUpdate} />
                ) : (
                    <AttendanceReadonly attendance={attendee.attendance} />
                ))}
            {attendeesColumnInfo?.[7].show && !readonly && (
                <div className={'flex justify-center'} {...stopPropagationOnMouseEvents}>
                    <Dropdown
                        icon={faEllipsisV}
                        aria-label={t`Options for ${
                            attendee.user_id != null ? attendee.user?.full_name : attendee.user_data.external_full_name
                        }`}
                    >
                        <Dropdown.Item icon={faPen} onClick={() => onEdit(attendee)}>
                            <Trans>Edit attendee</Trans>
                        </Dropdown.Item>
                        {attendee.user_id != null && attendee.user_id !== currentUser.id && (
                            <Dropdown.Item icon={faUserLock} onClick={handleEditRights}>
                                <Trans>Edit rights</Trans>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item icon={faTrash} danger onClick={() => onRemove(attendee)}>
                            <Trans>Remove</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            )}
        </div>
    ) : null;
};

type AttendeesTableProps = {
    attendees: MeetingUser[];
    onUpdate?: applyOnAction;
    handleEdit?: (attendee: MeetingUser) => void;
    handleRemove?: (attendee: MeetingUser) => void;
    isReadonly?: boolean;
};
export const AttendeesTable = ({
    attendees,
    onUpdate,
    handleEdit,
    handleRemove,
    isReadonly = false,
}: AttendeesTableProps): JSX.Element => {
    const containerRef = useContext(MeetingWrapperContainerContext);
    const containerSize = useElementSize(containerRef?.current ? containerRef : undefined);

    const dispatch = useAppDispatch();
    const { meetingId, meeting } = useMeetingContext();
    const { sensors } = useDndSortableVerticalStrategy();

    const [items, setItems] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const [draggedNode, setDraggedNode] = useState(null);

    useEffect(() => {
        setItems(attendees);
    }, [attendees]);

    const attendeesColumnsInfo = getAttendeeColumnsInfo(attendees, containerSize);
    const attendeesColumnsInfoReadonly = getAttendeeColumnsInfo(attendees, containerSize, true);

    // Vote related
    const voters = useMeetingVoters(meetingId);
    const totalShares = useMeetingTotalShares(meetingId);
    const presentVoters = useMeetingPresentVoters(meetingId);
    const presentShares = useMeetingPresentShares(meetingId);
    const displayShares = shouldDisplayShares(voters);
    const quorumAttained = useMeetingQuorumAttained(meetingId);

    const handleDragStart = ({ active }: DragStartEvent) => {
        setActiveId(active.id);
        const activeItem = items.find((item) => item.id === active.id);
        setDraggedNode(activeItem);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex((attendee) => attendee.id === active.id);
                const newIndex = items.findIndex((attendee) => attendee.id === over.id);

                const newItems = arrayMove(items, oldIndex, newIndex);

                const changes: {
                    id: Id;
                    changes: Partial<MeetingUser>;
                }[] = [];
                newItems.forEach((attendee, index) => {
                    if (attendee.order !== index) {
                        changes.push({
                            id: attendee.id,
                            changes: { order: index },
                        });
                    }
                });
                dispatch(updateGetMeetingCache(meetingId, { meetingUsers: newItems }));
                if (changes.length > 0) {
                    onUpdate(APPLY_ON.FUTURE_MEETINGS, changes);
                }

                return newItems;
            });
        }
        setActiveId(null);
    };

    return (
        <>
            <div
                style={{
                    gridTemplateColumns: getGridTemplateColumnsValue(
                        !isReadonly ? attendeesColumnsInfo : attendeesColumnsInfoReadonly
                    ),
                }}
                className={clsx(attendeesTableClasses, '!bg-gray-50 text-xs font-bold uppercase !text-gray-500')}
            >
                <AttendeesTableHeaderRow
                    columnInfo={!isReadonly ? attendeesColumnsInfo : attendeesColumnsInfoReadonly}
                />
            </div>
            {meeting == null ? (
                <div className={'grid gap-3 p-5'}>
                    <Skeleton className={'h-8 rounded-[5px]'} count={4} />
                </div>
            ) : !isReadonly ? (
                <div>
                    <DndContext
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        onDragStart={handleDragStart}
                    >
                        <SortableContext
                            items={items?.map((attendee) => attendee.id.toString())}
                            strategy={verticalListSortingStrategy}
                        >
                            {items?.map((attendee) => {
                                return (
                                    <AttendeeRow
                                        className={activeId != null && attendee.id === activeId && 'opacity-0'}
                                        attendeesColumnInfo={attendeesColumnsInfo}
                                        key={attendee.id}
                                        attendee={attendee}
                                        onUpdate={onUpdate}
                                        onEdit={() => handleEdit(attendee)}
                                        onRemove={() => handleRemove(attendee)}
                                    />
                                );
                            })}
                        </SortableContext>
                        <DragOverlay modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
                            {draggedNode && (
                                <AttendeeRow
                                    isBeingDragged={activeId === draggedNode.id}
                                    attendeesColumnInfo={attendeesColumnsInfo}
                                    key={draggedNode.id}
                                    attendee={draggedNode}
                                    onUpdate={onUpdate}
                                    onEdit={() => handleEdit(draggedNode)}
                                    onRemove={() => handleRemove(draggedNode)}
                                />
                            )}
                        </DragOverlay>
                    </DndContext>
                </div>
            ) : (
                items?.map((attendee) => (
                    <AttendeeRow
                        attendeesColumnInfo={attendeesColumnsInfoReadonly}
                        key={attendee.id}
                        attendee={attendee}
                        readonly={true}
                    />
                ))
            )}
            {meeting?.settings?.vote?.require_quorum &&
                totalShares > 0 &&
                (quorumAttained ? (
                    <div className={'p-2 text-end text-green-600'}>
                        {`${t`Quorum reached`} (${displayShares ? presentShares : presentVoters?.length}/${
                            displayShares
                                ? plural(totalShares, {
                                      one: '# share',
                                      other: '# shares',
                                  })
                                : voters?.length
                        })`}
                    </div>
                ) : (
                    <div className={'p-2 text-end text-red-600'}>
                        {`${t`Quorum not reached`} (${displayShares ? presentShares : presentVoters?.length}/${
                            displayShares
                                ? plural(totalShares, {
                                      one: '# share',
                                      other: '# shares',
                                  })
                                : voters?.length
                        })`}
                    </div>
                ))}
        </>
    );
};
