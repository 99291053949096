import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans as TransReact } from '@lingui/react';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { faChevronDown, faFont, faFontCase, faSearch, faSpellCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { msg, t } from '@lingui/macro';
import clsx from 'clsx';
import { Dropdown, Input, ItemGroup } from '@wedo/design-system';
import { EventFor } from '@wedo/types';
import { array, EmptyString, enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { setDiff } from '@wedo/utils/set';
import { FileSearchFilter, SearchFilterSelect } from 'Pages/SearchPage/components/FilesSearchPage/SearchFilterSelect';
import { TypeFilterSelect } from 'Pages/SearchPage/components/MeetingBlocksSearchPage/TypeFilterSelect';
import { OrderBy, OrderByFilterSelect } from 'Pages/SearchPage/components/TopicsSearchPage/OrderByFilterSelect';
import { LabelSelect } from 'Shared/components/file/fileList/LabelSelect';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { TaskStatusPopover } from 'Shared/components/task/TaskStatusPopover';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetMeetingBlocksQuery } from 'Shared/services/meetingBlock';
import { trpc } from 'Shared/trpc';
import { SearchType } from 'Shared/types/search';

const searchTypeFilters = [
    { id: 'all-words', label: msg`Contains all these words`, icon: faFontCase },
    { id: 'extended', label: msg`Contains at least one of these words`, icon: faFont },
    { id: 'strict', label: msg`Contains these exact words`, icon: faSpellCheck },
] as const;

export const CommonSearchPageSearchParams = {
    search: string(),
    scope: string(),
};

const DefaultPageSize = 20;

const SearchPageSearchParams = {
    // common
    search: string(),
    scope: enumeration('all-words', 'extended', 'strict').default('all-words'),

    // Files
    filter: enumeration('default', 'filename_only').default('default'),
    labels: array(string()),
    workspaceId: string(),

    // Blocks
    order: enumeration('rank', 'by_meeting_date').default('rank'),
    types: array(enumeration('paragraph', 'decision', 'task', 'attachment', 'vote')),

    // Tasks
    status: array(enumeration('completed', 'deleted', 'todo')),

    // Topics + Blocks + tasks
    workspaces: array(string()),
};

export const SearchPage = () => {
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(SearchPageSearchParams);
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const { search, scope, filter, labels, workspaces, order, types, status, workspaceId } = searchParams;

    const { data: topics } = trpc.meetingTopic.search.useQuery(
        {
            search: search ?? EmptyString,
            searchType: scope,
            workspaceIds: workspaces?.map(Number),
            orderBy: order,
            excludeDeletedMeetings: true,
            offset: 0,
            limit: DefaultPageSize,
        },
        {
            staleTime: 0,
            trpc: { context: { skipBatch: true } },
        }
    );

    const { data: meetingBlocks } = useGetMeetingBlocksQuery(
        {
            search: search ?? EmptyString,
            searchType: scope,
            types,
            tagIds: workspaces,
            orderBy: order,
            related: [
                'topic',
                'topic.meeting',
                'topic.meeting.tag',
                'topic.meeting.meetingUsers',
                'task',
                'task.recurrence',
                'task.checklist',
                'task.checklist.checklistTemplate',
                'attachments.currentVersion',
                'attachments.tags',
                'updated_by',
            ],
            offset: 0,
            limit: 20,
        },
        { refetchOnMountOrArgChange: true }
    );

    const { data: tasks = [], isSuccess: isTaskSearchSuccess } = trpc.task.search.useQuery(
        {
            workspaceIds: [],
            orderBy: 'rank',
            excludeDeletedMeetings: true,
            statuses: ['todo', 'completed'],
            search: search ?? EmptyString,
            searchType: scope,
            page: 1,
            pageSize: 50,
        },
        { trpc: { context: { skipBatch: true } } }
    );

    const { data: attachments } = trpc.attachment.search.useQuery({
        search: search ?? EmptyString,
        searchType: scope,
        workspaceId,
        filter,
        labels,
        offset: 0,
        limit: 20,
    });

    const selectedSearchFilter = searchTypeFilters.filter((filter) => filter.id === scope)[0];

    const tabs = useMemo<NavBarTab[]>(
        () => [
            {
                to: {
                    pathname: '/topics',
                    searchParams: { search, scope },
                },
                title: t`Topics`,
                count: topics?.length ?? 0,
                showCountPlus: topics?.length === DefaultPageSize,
            },
            {
                to: {
                    pathname: '/blocks',
                    searchParams: { search, scope },
                },
                title: t`Meeting blocks`,
                count: meetingBlocks?.length ?? 0,
            },
            {
                to: {
                    pathname: '/tasks',
                    searchParams: { search, scope, status: ['todo', 'completed'] },
                },
                title: t`Tasks`,
                count: tasks?.length,
                showCountPlus: tasks?.length === 50,
            },
            {
                to: {
                    pathname: '/files',
                    searchParams: { search, scope },
                },
                title: t`Files`,
                count: attachments?.length ?? 0,
                showCountPlus: attachments?.length === 20,
            },
        ],
        [search, scope, topics, meetingBlocks, tasks, attachments]
    );

    useEffect(() => {
        if (search !== EmptyString && pathname.endsWith('/tasks') && isTaskSearchSuccess) {
            void registerBadgeActivity('SEARCH_TASK_1');
        }
    }, [isTaskSearchSuccess, pathname]);

    usePageTitle(t`Search`);

    const handleSearchTypeChange = (scope: SearchType) => {
        setSearchParams({ ...searchParams, search, scope });
    };

    const handleSearch = (event: EventFor<'input', 'onChange'>) => {
        setSearchParams((searchParams) => ({ ...searchParams, search: event.target.value }), { replace: true });
    };

    const handleUpdateFilter = (filter: FileSearchFilter) => {
        setSearchParams((searchParams) => ({ ...searchParams, filter }));
    };

    const handleUpdateLabels = (labels: string[]) => setSearchParams((searchParams) => ({ ...searchParams, labels }));

    const handleUpdateWorkspaces = (newWorkspaces: string[]) => {
        // a workspace option was added, either normal workspace or not in workspace
        if (newWorkspaces.length > workspaces.length) {
            const workspaceAdded = [...setDiff(new Set(newWorkspaces), new Set(workspaces))][0];
            // user has selected in no workspace option
            if (workspaceAdded === '-1') {
                setSearchParams((searchParams) => ({
                    ...searchParams,
                    workspaces: ['-1'],
                }));
            } else {
                // user has added another workspace
                setSearchParams((searchParams) => ({
                    ...searchParams,
                    workspaces: newWorkspaces.filter((workspace) => workspace !== '-1'),
                }));
            }
        } else {
            // user has unselected an option
            setSearchParams((searchParams) => ({
                ...searchParams,
                workspaces: newWorkspaces,
            }));
        }
    };

    const handleUpdateOrder = (order: OrderBy) => setSearchParams((searchParams) => ({ ...searchParams, order }));

    const handleUpdateWorkspace = (workspaceId: string) =>
        setSearchParams((searchParams) => ({ ...searchParams, workspaceId }));

    const workspaceFilter = (
        <WorkspaceSelect
            isMultiple
            value={workspaces}
            onChange={handleUpdateWorkspaces}
            placeholder={t`Filter by workspace`}
            wrapperClassName="max-w-[15rem]"
        />
    );

    return (
        <>
            <div className="flex flex-col items-center justify-center gap-4 border-b border-b-gray-200 bg-white px-6 py-3 shadow-sm">
                <ItemGroup className="grid w-full grid-cols-[1fr,auto] bg-white md:w-5/6 xl:w-4/6">
                    <Input
                        debounce={250}
                        value={search}
                        onChange={handleSearch}
                        leadingIcon={faSearch}
                        placeholder={t`Search WEDO`}
                    />

                    <Dropdown
                        label={
                            <div className="flex items-center justify-center gap-2 md:justify-start">
                                <FontAwesomeIcon icon={faChevronDown} className="text-gray-700" />
                                <span className="hidden md:block">
                                    <TransReact id={selectedSearchFilter.label.id} />
                                </span>
                            </div>
                        }
                    >
                        {searchTypeFilters.map((filter) => (
                            <Dropdown.Item
                                key={filter.id}
                                onClick={() => handleSearchTypeChange(filter.id)}
                                className={clsx(filter.id === scope && 'bg-blue-200 font-semibold text-blue-600')}
                            >
                                <TransReact id={filter.label.id} />
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </ItemGroup>

                <NavBar tabs={tabs} basePath="/search" />

                <div className="w-full">
                    {pathname.endsWith('/topics') && (
                        <div className="flex flex-wrap justify-between gap-2">
                            {workspaceFilter}
                            <OrderByFilterSelect value={order} onChange={handleUpdateOrder} />
                        </div>
                    )}

                    {pathname.endsWith('/blocks') && (
                        <div className="flex w-full flex-wrap justify-between gap-2">
                            <div className="flex flex-wrap items-center gap-2">
                                {workspaceFilter}
                                <TypeFilterSelect
                                    value={types}
                                    onChange={(types) => {
                                        setSearchParams((searchParams) => ({ ...searchParams, types }));
                                    }}
                                />
                            </div>
                            <OrderByFilterSelect value={order} onChange={handleUpdateOrder} />
                        </div>
                    )}

                    {pathname.endsWith('/files') && (
                        <div className="flex flex-wrap gap-2">
                            <WorkspaceSelect
                                isClearable
                                hideNoWorkspace
                                value={workspaceId}
                                onChange={handleUpdateWorkspace}
                                wrapperClassName="max-w-[16rem]"
                            />
                            <SearchFilterSelect value={filter} onChange={handleUpdateFilter} />
                            <LabelSelect
                                forceSingleLine
                                value={labels}
                                onChange={handleUpdateLabels}
                                inputClassName="w-64"
                            />
                        </div>
                    )}

                    {pathname.endsWith('/tasks') && (
                        <div className="flex w-full flex-wrap justify-between gap-2">
                            {workspaceFilter}
                            <TaskStatusPopover statuses={status} variant="filled" icon={faCaretDown} />
                        </div>
                    )}
                </div>
            </div>
            <Outlet />
        </>
    );
};
