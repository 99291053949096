import { useState } from 'react';
import { t } from '@lingui/macro';
import { Select } from '@wedo/design-system';
import { getTimezoneLabel, sortedTimezones } from '@wedo/utils';

const { Option } = Select;

type TimeZonePickerProps = {
    value: string;
    onChange: (title: string) => void;
    disabled?: boolean;
};

export const TimeZonePicker = ({ value, onChange, disabled }: TimeZonePickerProps) => {
    const [filteredSearchTimezones, setFilteredSearchTimezones] = useState(sortedTimezones);
    return (
        <Select
            aria-label={t`Time zone`}
            value={value}
            disabled={disabled}
            customRenderSelected={(value: string) => {
                return getTimezoneLabel(sortedTimezones.find((tz) => tz[0] === value)?.[0] ?? value);
            }}
            onChange={onChange}
            onSearch={(search) =>
                setFilteredSearchTimezones(
                    sortedTimezones.filter((tz) => getTimezoneLabel(tz[0]).toLowerCase().includes(search.toLowerCase()))
                )
            }
        >
            {filteredSearchTimezones.map((tz) => {
                return (
                    <Option value={tz[0]} key={tz[0]}>
                        {getTimezoneLabel(tz[0])}
                    </Option>
                );
            })}
        </Select>
    );
};
