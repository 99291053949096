import { createWSClient, wsLink } from '@trpc/react-query';
import { AuthenticatedWebSocket } from '~/modules/ws/AuthenticatedWebSocket';
import { Second } from '@wedo/utils';

export const authenticatedWsLink = wsLink({
    client: createWSClient({
        WebSocket: AuthenticatedWebSocket,
        retryDelayMs: (retryAttempt) => {
            const maxDelay = Math.min(90 * Second, 2 ** retryAttempt * 10 * Second);
            const jitter = Math.floor(Math.random() * (maxDelay / 2));
            return maxDelay / 2 + jitter;
        },
        url:
            (location.protocol === 'https:' ? 'wss://' : 'ws://') +
            `${location.host}/rpc` +
            (__ENVIRONMENT__ === 'development' || __E2E__ === '1' ? '/live' : ''),
    }),
});
