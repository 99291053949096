import { FC, useState } from 'react';
import { faChevronLeft, faChevronRight, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Form, Input, ItemGroup } from '@wedo/design-system';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { NewOrganizationType } from 'Pages/onboarding/utils/onboardingStore';

export const WorkspaceStep: FC = () => {
    const [name, setName] = useState('');
    const { updateKey, actions, getZodResult, isFinishLoading, zodErrors, ...store } = useOnboarding();

    const addWorkspace = (name: string) => {
        if (name.trim() === '') {
            return;
        }

        const newWorkspaces = structuredClone(store.workspaceNames) as string[];
        newWorkspaces.push(name);

        updateKey({ key: 'workspaces', value: newWorkspaces });
        setName('');
    };

    const deleteWorkspace = (index: number) => {
        const newWorkspaces = structuredClone(store.workspaceNames) as string[];

        newWorkspaces.splice(index, 1);
        updateKey({ key: 'workspaces', value: newWorkspaces });
    };

    const handleWorkspaceUpdate = (value: string, index: number) => {
        if (index >= store.workspaceNames.length) {
            return;
        }

        const newWorkspaces = structuredClone(store.workspaceNames) as NewOrganizationType['workspaceNames'];
        newWorkspaces[index] = value;

        updateKey({ key: 'workspaces', value: newWorkspaces });
    };

    const handleNext = async () => {
        let workspaces = [...store.workspaceNames];
        if (name.trim() !== '') {
            workspaces = [...workspaces, name];
            updateKey({ key: 'workspaces', value: workspaces });
            setName('');
        }
        const workspaceErrors = getZodResult({ ...store, workspaceNames: workspaces })?.error?.errors?.find(
            (error) => error.path[0] === 'workspaceNames'
        );
        if (workspaceErrors) {
            return;
        }
        actions.setStep(store.step + 1);
    };

    const getError = (index: number) => {
        return zodErrors?.find((error) => error.path[0] === 'workspaceNames' && error.path[1] === index);
    };

    return (
        <>
            <Form layout="vertical" title={t`Create your workspaces`} onSubmit={() => addWorkspace(name)}>
                <Form.Item>
                    <Trans>A workspace is a way to group people around a project, weekly meeting or client.</Trans>
                </Form.Item>
                {store.workspaceNames.map((workspace, index) => (
                    <Form.Item key={index}>
                        <ItemGroup
                            status={getError(index)?.message ? 'error' : 'default'}
                            statusText={getError(index)?.message ?? ''}
                        >
                            <Input
                                className="grow"
                                value={workspace}
                                onChange={(e) => handleWorkspaceUpdate(e.target.value, index)}
                            />
                            <Button
                                color="danger"
                                onClick={() => deleteWorkspace(index)}
                                position="end"
                                icon={faTrash}
                            />
                        </ItemGroup>
                    </Form.Item>
                ))}
                <Form.Item>
                    <ItemGroup>
                        <Input className="grow" value={name} onChange={(e) => setName(e.target.value)} />
                        <Button color="primary" type="submit" position="end" icon={faPlus} />
                    </ItemGroup>
                </Form.Item>
            </Form>

            <OnboardingLayout.Buttons>
                <Button onClick={() => actions.setStep(store.step - 1)} icon={faChevronLeft} iconPosition="start">
                    <Trans>Previous</Trans>
                </Button>

                <Button
                    disabled={zodErrors?.some((error) => error.path[0] === 'workspaceNames')}
                    onClick={handleNext}
                    loading={isFinishLoading}
                    color={store.workspaceNames.length > 0 || !isEmpty(name) ? 'primary' : 'default'}
                    icon={faChevronRight}
                    iconPosition={'end'}
                >
                    {store.workspaceNames.length > 0 || !isEmpty(name) ? <Trans>Next</Trans> : <Trans>Skip</Trans>}
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
