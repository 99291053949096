import { useMemo } from 'react';
import clsx from 'clsx';
import { Bottom, type Horizontal, Left, type OppositeDirection, Right, Top, type Vertical } from '@wedo/utils';

const Radius = 4;
const Margin = 4;
const StrokeWidth = 2;
const ArrowWidth = 6;
const ArrowHeight = 8;

export const angle = <D1 extends Horizontal | Vertical, D2 extends OppositeDirection<D1>>(dir1: D1, dir2: D2) => {
    const isClockwise = (dir2 - dir1 + 4) % 4 === 1;
    const isLeft = dir1 === Left || dir2 === Left;
    const isTop = dir1 === Top || dir2 === Top;
    return `a${Radius} ${Radius} 0 0 ${isClockwise ? 1 : 0} ${isLeft ? -Radius : Radius},${isTop ? -Radius : Radius}`;
};

export const triangle = (x: number, y: number) => {
    return `${x},${y} ${x + ArrowWidth},${y + ArrowHeight / 2} ${x},${y + ArrowHeight}`;
};

type TimelineDependencyProps = {
    from: HTMLElement | undefined;
    to: HTMLElement | undefined;
    pointerPosition?: DOMPoint;
    className?: string;
    isDashed?: boolean;
};

export const TimelineDependency = ({ from, to, pointerPosition, className, isDashed }: TimelineDependencyProps) => {
    const arrow = useMemo(() => {
        const timelineElement = (from ?? to)?.parentElement.parentElement;
        if (timelineElement == null || (pointerPosition == null && (from == null || to == null))) {
            return null;
        }
        const containerRect = timelineElement.getBoundingClientRect();
        const halfRowHeight = parseFloat(getComputedStyle(timelineElement).getPropertyValue('--row-height')) / 2;
        const fromRect =
            from?.getBoundingClientRect() ?? new DOMRect(pointerPosition!.x, pointerPosition!.y, 0, halfRowHeight);
        const toRect =
            to?.getBoundingClientRect() ?? new DOMRect(pointerPosition!.x, pointerPosition!.y, 0, halfRowHeight);
        const halfFromRectHeight = fromRect.height / 2;
        const halfToRectHeight = toRect.height / 2;
        const isUpward = toRect.top < fromRect.top;
        const isBackward = toRect.left - Radius <= fromRect.right + Radius;
        const verticalDirection = isUpward ? Top : Bottom;
        const { top, left, width, height } = new DOMRect(
            (isBackward ? toRect.left - ArrowWidth - Radius : fromRect.right) - containerRect.left,
            (isUpward ? toRect.top : fromRect.top) - containerRect.top,
            isBackward ? fromRect.right - toRect.left + Radius * 2 + ArrowWidth : toRect.left - fromRect.right,
            isUpward ? fromRect.bottom - toRect.top : toRect.bottom - fromRect.top
        );
        const y = isUpward ? height - halfFromRectHeight : halfFromRectHeight;
        const sign = isUpward ? -1 : 1;
        const edge = isBackward
            ? `M${width - Radius},${y} ${angle(Right, verticalDirection)} v${(height - toRect.height - Radius * 2 - halfFromRectHeight - StrokeWidth / 2 - Margin) * sign} ${angle(verticalDirection, Left)} h${-width + Radius * 2} ${angle(Left, verticalDirection)} v${(StrokeWidth / 2 + Margin) * sign} ${angle(verticalDirection, Right)}`
            : `M0,${y} ${angle(Right, verticalDirection)} v${(height - halfFromRectHeight - halfToRectHeight - Radius * 2) * sign} ${angle(verticalDirection, Right)} h${width - Radius * 2 - ArrowWidth}`;
        const head = triangle(
            isBackward ? Radius : width - ArrowWidth,
            (isUpward ? halfToRectHeight : height - halfToRectHeight) - ArrowHeight / 2
        );
        return { edge, head, top, left, width, height };
    }, [from, to, pointerPosition]);

    return (
        arrow != null && (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={clsx('absolute pointer-events-none fill-gray-500 stroke-gray-500', className)}
                overflow="visible"
                viewBox={`0 0 ${arrow.width} ${arrow.height}`}
                style={{
                    transform: `translate(${arrow.left}px, ${arrow.top}px)`,
                    width: `${arrow.width}px`,
                    height: `${arrow.height}px`,
                }}
            >
                <path fill="none" strokeWidth={StrokeWidth} d={arrow.edge} strokeDasharray={isDashed ? 3 : undefined} />
                <polygon stroke="none" points={arrow.head} />
            </svg>
        )
    );
};
