import React, { useContext, useEffect, useRef } from 'react';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Button, type CloseSource, useModal } from '@wedo/design-system';
import {
    customFetch,
    Minute,
    Second,
    withAuth,
    withFormDataBody,
    withMethod,
    withReadableStreamErrorHandler,
    withUrl,
} from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { once, race, retry, timeout } from '@wedo/utils/promise';
import { useSessionUser } from 'App/store/usersStore';
import { SignatoryInformationModal } from 'Pages/SignDocumentPage/SignatoryInformationModal';
import { SignModal } from 'Pages/SignaturesPage/SignModal';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { SignButtonProp } from 'Pages/SignaturesPage/SignatureModalSignActions';
import { InsufficientAssuranceLevelModal } from 'Pages/SignaturesPage/components/InsufficientAssuranceLevelModal';
import { UserSignatureCanvas } from 'Pages/settings/signature/UserSignatureCanvas';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { trpc } from 'Shared/trpc';
import { FullSignatureRequest, SignatureSettings } from 'Shared/types/signature';
import { defaultValuesToSignatureSettings } from 'Shared/utils/signature';
import { SignatureQuotaAttainedModal } from './SignatureQuotaAttainedModal';

const getMessage = (attachment: FullSignatureRequest['attachment']) => {
    const meetingRelation = attachment.attachmentRelations.find(({ meeting }) => meeting != null);

    return meetingRelation != null
        ? formatMeetingTitle(
              {
                  title: meetingRelation.meeting.title,
                  start_at: meetingRelation.meeting.startAt,
              },
              i18n
          )
        : attachment.attachmentVersion.filename;
};

export const SignExternalButton = ({ onDone, className = '' }: SignButtonProp) => {
    const currentUser = useSessionUser();
    const { data: signatureSettings } = trpc.user.getSettingsByKey.useQuery('signature');
    const { mutateAsync: poll } = trpc.signature.poll.useMutation();
    const { setConsentUrl, signatureLock, signatureRequest, isSigning, setIsSigning, setErrorMessage } =
        useContext(SignatureModalContext);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { open: openModal, close: closeModal } = useModal();
    const abortControllerRef = useRef(null);
    const { wrap, isLoading } = useLoader();

    const canSign = signatureLock?.isLockValid && signatureLock?.userId === Number(currentUser.id) && !isSigning;

    const handleSignatureError = (errorMessage: string) => {
        switch (errorMessage) {
            case 'componentUnmounted':
                break;
            case 'insufficientAssuranceLevel':
                openModal(InsufficientAssuranceLevelModal, {
                    signatureType: signatureRequest.type,
                    legalFramework: signatureRequest.legalFramework,
                });
                break;
            case 'quotaExceeded':
                openModal(SignatureQuotaAttainedModal);
                break;
            default:
                setErrorMessage(errorMessage);
        }

        onDone(null);
    };

    const pollSignature = async () => {
        const retryFn = async (retrySignal: AbortSignal, signal: AbortSignal) => {
            if (retrySignal.aborted) {
                return { status: 'timedOut', data: null };
            }

            if (signal.aborted) {
                return { status: 'componentUnmounted', data: null };
            }

            let result;

            try {
                result = await poll(signatureRequest.attachment.attachmentVersion.id);
            } catch (e) {
                return { status: e.message, data: null };
            }

            if (result.status === 'Pending') {
                setConsentUrl(result.consentUrl);
                throw new Error();
            }

            return result;
        };

        const result = await race(
            retry((retrySignal) => retryFn(retrySignal, abortControllerRef.current.signal), Second),
            timeout(15 * Minute)
        );

        if (result.status !== 'Success') {
            throw new Error(result.status);
        }

        return result;
    };

    const sign = async (signatureSettings: SignatureSettings) => {
        setIsSigning(true);
        setErrorMessage(null);
        setConsentUrl('');
        abortControllerRef.current = new AbortController();

        const image = await new Promise<Blob>((resolve) => {
            return canvasRef.current.toBlob((result) => resolve(result), 'image/png');
        });

        const formData = new FormData();
        formData.set('attachmentVersionId', signatureRequest.attachment.attachmentVersion.id.toString());
        formData.set('language', currentUser.language_code.substring(0, 2));
        formData.set('legalFramework', signatureRequest.legalFramework);
        formData.set('message', getMessage(signatureRequest.attachment));
        formData.set('phone', signatureSettings.phone);
        formData.set('type', signatureRequest.type);
        formData.set('signatureImage', new File([image], `${signatureRequest.id}.png`, { type: 'image/png' }));

        try {
            const result = await customFetch(
                withAuth,
                withFormDataBody(formData),
                withMethod('POST'),
                withReadableStreamErrorHandler,
                withUrl('/rpc/signature.signExternal')
            );

            const {
                result: { data },
            } = await result.json();
            setConsentUrl(data.consentUrl);

            openModal(SignModal, {
                onBeforeClose: async (source: CloseSource) => {
                    if (source === 'backdrop-or-esc') {
                        return false;
                    }
                    if (!abortControllerRef.current.signal.aborted) {
                        abortControllerRef.current.abort();
                    }
                    return true;
                },
            });

            onDone((await pollSignature()).data);
            void closeModal(SignModal);
        } catch (e) {
            handleSignatureError(e.message);
        } finally {
            setIsSigning(false);
        }
    };

    const checkSignatoryInformation = () => {
        if (signatureSettings?.phone == null) {
            openModal(SignatoryInformationModal, {
                onDone: sign,
            });
        } else {
            void sign(signatureSettings);
        }
    };

    useEffect(() => {
        wrap(async () => {
            const { instance } = await getSharedPdfViewerInstance();
            if (instance.Core.documentViewer.getDocument() == null) {
                await once(instance.Core.documentViewer, 'documentLoaded');
            }
        });

        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <>
            <Button
                className={className}
                color="success"
                onClick={checkSignatoryInformation}
                disabled={!canSign || isLoading}
                loading={isSigning}
            >
                <Trans>Sign</Trans>
            </Button>
            <div className="hidden">
                <UserSignatureCanvas
                    ref={canvasRef}
                    settings={defaultValuesToSignatureSettings(signatureSettings, currentUser)}
                    type={signatureRequest?.type}
                />
            </div>
        </>
    );
};
