import { Skeleton } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { DisableTotpCard } from 'Pages/settings/security/components/DisableTotpCard';
import { EnableTotpCard } from 'Pages/settings/security/components/EnableTotpCard';
import { NoDisableTotpCard } from 'Pages/settings/security/components/NoDisableTotpCard';
import { useGetMultiFactorQuery } from 'Shared/services/settingsSecurity';

export const TotpSwitch = (): JSX.Element => {
    const { isLoading, data } = useGetMultiFactorQuery();
    const { currentUser } = useCurrentUserContext();

    if (isLoading) {
        return (
            <div className="flex flex-col gap-2">
                return <Skeleton count={3} className="h-4" />
            </div>
        );
    }

    if (data?.active) {
        if (currentUser?.userNetwork?.network?.isForceTwoFactor === true) {
            return <NoDisableTotpCard totpActiveDate={data.updated_at} languageCode={currentUser.language_code} />;
        }

        return <DisableTotpCard />;
    }

    return <EnableTotpCard />;
};
