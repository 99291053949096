import { Node, Point, Element } from 'slate';
import { Id } from '@wedo/types';
import { tryOrZero } from '@wedo/utils';

export const synchronizePoint = (blocks: Element[], previousPoint: Point, previousBlockId: Id) => {
    const selectedBlockIndex = blocks.findIndex((block) => block.id === previousBlockId);
    if (selectedBlockIndex === -1) {
        return null;
    }

    const previousSelectedBlockIndex = previousPoint.path[0];
    const path = [...previousPoint.path];
    if (previousSelectedBlockIndex !== selectedBlockIndex) {
        path[0] = selectedBlockIndex;
    }
    let offset = previousPoint.offset;
    const textLength = tryOrZero(() => Node.leaf({ children: blocks }, path).text.length);
    if (offset > textLength) {
        offset = textLength;
    }
    return { path, offset };
};
