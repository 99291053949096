import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { useLingui } from '@lingui/react';
import React, { Fragment, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { faSliders, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, NavLink } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { Can } from 'Shared/components/Can';
import { EnterpriseTag } from 'Shared/components/EnterpriseTag';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { trpc } from 'Shared/trpc';
import { DevFeature } from 'Shared/types/user';
import { getPlanType } from 'Shared/utils/chargebee';
import { Permission } from 'Shared/utils/rbac';
import { accountSettings, organizationSettings } from './menu';

export const SettingsPage = () => {
    const { i18n } = useLingui();
    usePageTitle(i18n._(msg`Settings`));
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const { data: subscription } = trpc.subscription.get.useQuery();

    const { network: currentNetwork } = useCurrentNetwork();
    const { currentUser } = useCurrentUserContext();
    const hasSignatureFeature = useHasFeature(currentUser, currentNetwork, DevFeature.Signature);

    const filteredAccountSettings = useMemo(() => {
        return accountSettings.filter(({ to }) => (to === '/signature' && hasSignatureFeature) || to !== '/signature');
    }, [accountSettings, hasSignatureFeature]);
    return (
        <>
            <div className="flex h-full overflow-hidden">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute right-0 top-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-0 flex-1 overflow-y-auto pb-4">
                                        <nav aria-label="Sidebar">
                                            <div className="space-y-1 px-2">
                                                <div className="px-1 pb-1 pt-3 text-sm font-bold uppercase">
                                                    <Trans>My settings</Trans>
                                                </div>
                                                {filteredAccountSettings.map(
                                                    ({ icon, name, permission, to }, index) => (
                                                        <Can permission={permission} key={index}>
                                                            <NavLink
                                                                onClick={() => setSidebarOpen(false)}
                                                                className={(isActive) =>
                                                                    clsx(
                                                                        'flex items-center rounded-md px-2 py-2 text-sm font-medium',
                                                                        isActive
                                                                            ? 'bg-highlight text-gray-800'
                                                                            : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                                                    )
                                                                }
                                                                prefix="/settings"
                                                                to={to}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={icon}
                                                                    className="mr-4 h-4 w-4 text-gray-600"
                                                                    aria-hidden="true"
                                                                />
                                                                <Trans id={name.id} />
                                                            </NavLink>
                                                        </Can>
                                                    )
                                                )}
                                            </div>
                                            <Can permission={Permission.ManageNetwork}>
                                                <div className="space-y-1 px-2">
                                                    <div className="px-1 pb-1 pt-3 text-sm font-bold uppercase">
                                                        <Trans>Organization settings</Trans>
                                                    </div>
                                                    {organizationSettings.map(({ icon, name, to }, index) => (
                                                        <NavLink
                                                            onClick={() => setSidebarOpen(false)}
                                                            key={index}
                                                            className={(isActive) =>
                                                                clsx(
                                                                    'flex items-center rounded-md px-2 py-2 text-sm font-medium',
                                                                    isActive
                                                                        ? 'bg-highlight text-gray-800'
                                                                        : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                                                )
                                                            }
                                                            prefix="/settings"
                                                            to={to}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={icon}
                                                                className="mr-4 h-4 w-4 text-gray-700"
                                                                aria-hidden="true"
                                                            />
                                                            <Trans id={name.id} />
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </Can>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className="w-14 flex-shrink-0" aria-hidden="true">
                                {/* Force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:flex-shrink-0">
                    <div className="flex w-72 flex-col">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-300 bg-white">
                            <div className="flex flex-1 flex-col overflow-y-auto">
                                <nav className="flex-1" aria-label="Sidebar">
                                    <div className="space-y-1 px-2">
                                        <div className="px-1 pb-1 pt-3 text-sm font-bold uppercase">
                                            <Trans>My settings</Trans>
                                        </div>
                                        {filteredAccountSettings.map(({ icon, name, permission, to }, index) => (
                                            <Can permission={permission} key={index}>
                                                <NavLink
                                                    className={(isActive) =>
                                                        clsx(
                                                            'flex items-center rounded-md px-2 py-2 text-sm font-medium',
                                                            isActive
                                                                ? 'bg-highlight text-blue-950'
                                                                : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                                                        )
                                                    }
                                                    prefix="/settings"
                                                    to={to}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={icon}
                                                        className="mr-4 h-4 w-4"
                                                        aria-hidden="true"
                                                    />
                                                    <Trans id={name.id} />
                                                </NavLink>
                                            </Can>
                                        ))}
                                        <Can permission={Permission.ManageNetwork}>
                                            <>
                                                <div className="px-1 pb-1 pt-3 text-sm font-bold uppercase">
                                                    <Trans>Organization settings</Trans>
                                                </div>
                                                {organizationSettings.map(
                                                    ({ icon, name, enterpriseOnly, to }, index) => (
                                                        <NavLink
                                                            key={index}
                                                            className={(isActive) =>
                                                                clsx(
                                                                    'flex items-center rounded-md px-2 py-2 text-sm font-medium',
                                                                    isActive
                                                                        ? 'bg-highlight text-blue-950'
                                                                        : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
                                                                )
                                                            }
                                                            prefix="/settings"
                                                            to={to}
                                                        >
                                                            <div className="flex justify-between items-center gap-2 w-full">
                                                                <div>
                                                                    <FontAwesomeIcon
                                                                        icon={icon}
                                                                        className="mr-4 h-4 w-4"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <Trans id={name.id} />
                                                                </div>
                                                                {enterpriseOnly &&
                                                                    getPlanType(subscription) !== 'enterprise' && (
                                                                        <EnterpriseTag />
                                                                    )}
                                                            </div>
                                                        </NavLink>
                                                    )
                                                )}
                                            </>
                                        </Can>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
                    <div className="lg:hidden">
                        <div className="border-b border-gray-200 bg-gray-200 px-1.5 py-1">
                            <Button
                                aria-label={t`Open sidebar`}
                                color={'gray'}
                                variant={'filled'}
                                className={'!border-gray-200 !bg-gray-200 hover:!bg-gray-300'}
                                icon={faSliders}
                                onClick={() => setSidebarOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="relative z-0 flex flex-1 overflow-hidden">
                        <main className="relative z-0 flex-1 overflow-y-auto bg-gray-50 px-5 focus:outline-none xl:order-last">
                            {/* Start main area*/}
                            <div className="mx-auto my-2 max-w-5xl lg:my-5">
                                <Outlet />
                            </div>
                            {/* End main area */}
                        </main>
                    </div>
                </div>
            </div>
        </>
    );
};
