import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import {
    OptionItem,
    OptionItemProps,
} from 'Pages/settings/customFields/components/CustomFieldModalContent/OptionsModalContent/OptionItem';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';

type DraggableOptionItemProps = OptionItemProps;

export const DraggableOptionItem: React.FC<DraggableOptionItemProps> = ({
    option,
    onColorChange,
    onTextChange,
    onCheckLabelIsValid,
    onDelete,
}) => {
    const { draggableStyle } = useDndSortableVerticalStrategy();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: option.label,
        data: {
            cardType: 'option',
            option,
        },
    });

    const style = draggableStyle(transform, transition, isDragging);

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={clsx(
                'group/drag flex w-full items-center gap-2',
                isDragging && 'cursor-grabbing',
                !isDragging && 'cursor-grab'
            )}
        >
            <OptionItem
                option={option}
                onColorChange={onColorChange}
                onTextChange={onTextChange}
                onDelete={onDelete}
                onCheckLabelIsValid={onCheckLabelIsValid}
            />
        </div>
    );
};
