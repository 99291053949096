import React from 'react';
import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';
import { archiveIcon } from 'Pages/settings/customFields/utils/action';

export const ArchivedTag = () => {
    return (
        <Tag color="blue" size="sm" icon={archiveIcon}>
            <Trans>Archived</Trans>
        </Tag>
    );
};
