import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';

export const SubdomainPointer: FC = () => (
    <div className="h-52 bg-white">
        <FontAwesomeIcon icon={faArrowUp} size="3x" className="-mt-5 ml-64 text-red-500" />
        <div className="ml-24 text-2xl text-gray-700">
            <Trans>Choose your subdomain</Trans>
        </div>
    </div>
);
