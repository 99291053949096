import React from 'react';
import { Id } from '@wedo/types';
import { useDraggedTasksStore } from 'Pages/TasksPage/components/TasksList/TasksList';
import { CustomDragOverlay } from 'Shared/components/dragOverlay/CustomDragOverlay';
import { Task } from 'Shared/components/task/Task';
import { TaskFilter, TaskLayout } from 'Shared/types/task';

type TaskDragOverlayProps = {
    workspaceId: Id;
    checklistId: Id;
    layout: TaskLayout;
    view: TaskFilter;
};

export const TaskDragOverlay = ({ workspaceId, checklistId, layout, view }: TaskDragOverlayProps) => {
    const draggedTasks = useDraggedTasksStore((state) => state.draggedTasks);

    return (
        <CustomDragOverlay items={draggedTasks}>
            {draggedTasks.length > 0 && (
                <Task
                    view={view}
                    layout={layout}
                    className="relative shadow-md"
                    task={draggedTasks[0]}
                    isEditable={true}
                    workspaceId={workspaceId}
                    checklistId={checklistId}
                />
            )}
        </CustomDragOverlay>
    );
};
