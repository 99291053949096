import React, { FC, FormEvent, ReactNode, useEffect, useReducer, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Skeleton, Tabs, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { addEditUserReducer, SET_WHOLE_USER } from 'Pages/settings/users/utils/addEditUserReducer';
import { PartialUser, partialUserToAddUserArg } from 'Pages/settings/users/utils/user';
import { EditTeamTab } from 'Shared/components/user/AddEditUserModal/EditTeamTab';
import { EditUserForm } from 'Shared/components/user/AddEditUserModal/EditUserForm';
import { ManageUserPicture } from 'Shared/components/user/ManageUserPicture';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { adminFieldError, useEditUserMutation, useUpdateUserTeamsMutation } from 'Shared/services/admin';
import { useUpdateCurrentUserMutation } from 'Shared/services/user';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { UserRole } from 'Shared/types/user';

type EditUserModalProps = {
    user: PartialUser;
    onDone?: () => void;
    children: ReactNode;
} & ContextModalProps;

export const EditUserModal: FC<EditUserModalProps> = ({ user: userParam, onDone, close, children, ...modalProps }) => {
    const { isScimStrictMode } = useCurrentNetwork();
    const currentUser = useSessionUser();
    const { data: userLicenses } = trpc.userLicense.list.useQuery();
    const [user, dispatch] = useReducer(addEditUserReducer, null);
    const { show } = useNotification();
    const [editUser, { error: editUserError, isLoading: isEditUserLoading }] = useEditUserMutation();
    const [editMe] = useUpdateCurrentUserMutation();
    const { mutateAsync: addLicense, isLoading: isAddingLicense } = trpc.userLicense.add.useMutation();
    const { mutateAsync: removeLicense, isLoading: isRemovingLicense } = trpc.userLicense.remove.useMutation();
    const [updateUserTeams, { isLoading: isUpdateTeamsLoading }] = useUpdateUserTeamsMutation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTeams, setSelectedTeams] = useState<
        {
            id: Id;
        }[]
    >([]);
    const [previousUserTeams, setPreviousUserTeams] = useState([]);

    const hasGovernanceLicense = userLicenses?.some(
        (license) => license.license === 'governance' && license.userId === Number(userParam.id)
    );

    const isLoading = isEditUserLoading || isUpdateTeamsLoading || isAddingLicense || isRemovingLicense;

    if (user?.teams && previousUserTeams.length !== user.teams.length) {
        setPreviousUserTeams(user?.teams ?? []);
        setSelectedTeams(user.teams);
    }

    // set the user to the value of the parameter or to the default user.
    const [oldUserParam, setOldUserParam] = useState<PartialUser | false>(null);
    if (oldUserParam !== userParam) {
        setOldUserParam(userParam);
        dispatch({ type: SET_WHOLE_USER, value: userParam });
    }

    const handleSaveTeams = async () => {
        const added = selectedTeams
            .filter(({ id }) => !user.teams.some((userTeam) => userTeam.id === id))
            .map(({ id }) => id);
        const removed = user.teams
            ?.filter(({ id }) => !selectedTeams.some((team) => team.id === id))
            .map(({ id }) => id);

        if (added?.length > 0 || removed?.length > 0) {
            const result = await updateUserTeams({
                userId: user.id,
                added,
                removed,
            });
            if ('error' in result && result?.error instanceof ApiError) {
                if (result?.error.matchesSome(adminFieldError)) {
                    show({
                        title: t`Error`,
                        message: t`There's an error in the tab "User".`,
                        type: 'danger',
                    });
                } else {
                    show({
                        title: t`Error`,
                        message: result?.error.message,
                        type: 'danger',
                    });
                }
            }
            if ('data' in result) {
                await close();
            }
        }
    };

    const handleSaveUser = async (e?: FormEvent) => {
        e?.preventDefault();

        let result;
        if (user.id === currentUser.id) {
            result = await editMe({
                display_name: user.display_name,
                first_name: user.first_name,
                last_name: user.last_name,
                initials: user.initials,
                title: user.title,
                language_code: user.language_code,
            });
        } else {
            result = await editUser(partialUserToAddUserArg(user));
        }

        if (hasGovernanceLicense && !user.userLicenses?.includes('governance')) {
            await removeLicense({ userId: user.id, license: 'governance' });
        } else if (!hasGovernanceLicense && user.userLicenses?.includes('governance')) {
            await addLicense({ userId: user.id, license: 'governance' });
        }

        if ('data' in result) {
            await trpcUtils().user.list.invalidate();
            await trpcUtils().userLicense.invalidate();
            await trpcUtils().subscription.get.invalidate();
            await close(result.data as PartialUser);
        }
    };

    const handleSave = () => {
        void handleSaveUser();
        void handleSaveTeams();
        onDone?.();
    };

    const handleClose = () => {
        void close();
        if (selectedTab === 1) {
            onDone?.();
        }
    };

    useEffect(() => {
        if (userLicenses == null) {
            return;
        }
        const filteredLicenses = userLicenses?.filter((license) => license.userId === Number(userParam.id));
        if (filteredLicenses?.some((license) => license.license === 'governance')) {
            dispatch({ type: 'governanceAddon', value: 'true' });
        }
    }, [userLicenses]);

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={isScimStrictMode ? t`View user` : t`Edit user`} />

            <Tabs onChange={setSelectedTab}>
                <Tabs.Header>
                    <Tabs.Tab>
                        <Trans>User</Trans>
                    </Tabs.Tab>
                    <Tabs.Tab>
                        <Trans>Photo</Trans>
                    </Tabs.Tab>
                    {currentUser.role === UserRole.ADMIN && (
                        <Tabs.Tab>
                            <Trans>Team</Trans>
                        </Tabs.Tab>
                    )}
                </Tabs.Header>
                <Modal.Body>
                    {!user ? (
                        <div className="flex flex-col gap-2">
                            <Skeleton count={5} className={'h-4'} />
                        </div>
                    ) : (
                        <Tabs.Panels>
                            <Tabs.Panel>
                                <EditUserForm
                                    user={user}
                                    userApiError={editUserError}
                                    onChange={(type, value) => dispatch({ type, value })}
                                    isReadonly={isScimStrictMode}
                                    onEnterPress={handleSave}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <div className="flex justify-center py-8">
                                    <ManageUserPicture userId={user.id} />
                                </div>
                            </Tabs.Panel>
                            {currentUser.role === UserRole.ADMIN && (
                                <Tabs.Panel>
                                    <EditTeamTab
                                        user={user}
                                        selectedTeams={selectedTeams}
                                        setSelectedTeams={setSelectedTeams}
                                        onChange={(newUser) => dispatch({ type: SET_WHOLE_USER, value: newUser })}
                                        isReadonly={isScimStrictMode}
                                    />
                                </Tabs.Panel>
                            )}
                        </Tabs.Panels>
                    )}
                </Modal.Body>
            </Tabs>
            <Modal.Footer>
                <Button onClick={handleClose}>
                    <Trans>Close</Trans>
                </Button>
                {!isScimStrictMode && selectedTab !== 1 && (
                    <Button loading={isLoading} color="primary" onClick={handleSave}>
                        <Trans>Save</Trans>
                    </Button>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
