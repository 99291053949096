export enum LocalStorage {
    Language = 'lang',
    AuthenticationMethod = 'authentication-method',
    ShowDesktopNotificationBanner = 'show-desktop-notification-banner',
    CommandPaletteActions = 'command-palette-actions',
    AuthToken = 'authToken',
    DeviceToken = 'deviceToken',
    GlobalAuthToken = 'globalAuthToken',
    Redirect = 'redirect',
    ShowTasksAbsoluteDueDate = 'showTasksAbsoluteDueDate',
    SignatureMessage = 'series-sign-message-',
    MeetingCommentsCollapsed = 'meetingDisplayComments',
}
