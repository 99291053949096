import { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Avatar, Checkbox, Table, contrastingColor } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { PartialUser } from 'Pages/settings/users/utils/user';
import { GetAdminTeamsReturn } from 'Shared/services/admin';
import { Member } from 'Shared/types/userGroup';

type EditTeamTableProps = {
    teams: GetAdminTeamsReturn;
    user: PartialUser;
    selectedTeams: { id: Id }[];
    onTeamSelect: (team: { id: Id }, select: boolean) => void;
    isReadonly: boolean;
};

export const EditTeamTable: FC<EditTeamTableProps> = ({ teams, user, selectedTeams, onTeamSelect, isReadonly }) => {
    if (!teams?.data) {
        return null;
    }

    return (
        <Table size="condensed" hoverable={true} striped={true}>
            <Table.Head>
                {!isReadonly && <Table.HeadCell></Table.HeadCell>}
                <Table.HeadCell>
                    <Trans>Name</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="@lg:block hidden">
                    <Trans>Members</Trans>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body>
                {teams.data.map((team) => {
                    const isSelected = selectedTeams.some(({ id }) => id === team.id);

                    const membersList = team.userGroup.members.filter((member) => member.user.id !== user.id);
                    if (isSelected) {
                        membersList.unshift({ user } as Member);
                    }

                    const isLastMember = membersList.length < 2 && isSelected;

                    return (
                        <Table.Row
                            key={team.id}
                            title={isLastMember ? t`You cannot remove the last member of a team.` : null}
                            className={clsx(isLastMember && 'cursor-not-allowed')}
                            onClick={() => !isLastMember && !isReadonly && onTeamSelect({ id: team.id }, !isSelected)}
                        >
                            {!isReadonly && (
                                <Table.Cell>
                                    <Checkbox readOnly={true} checked={isSelected} disabled={isLastMember} />
                                </Table.Cell>
                            )}
                            <Table.Cell>{team.name}</Table.Cell>
                            <Table.Cell className="@lg:block hidden w-52">
                                <Avatar.Group>
                                    <>
                                        {membersList.slice(0, 3).map((member) => (
                                            <Avatar
                                                key={member.user.id}
                                                initials={member.user.initials}
                                                style={{
                                                    backgroundColor: member.user.color.background,
                                                    color: contrastingColor(member.user.color.background),
                                                }}
                                                img={member.user.photo_url}
                                            />
                                        ))}
                                        {membersList.length > 3 && <Avatar.Counter total={membersList.length - 3} />}
                                    </>
                                </Avatar.Group>
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};
