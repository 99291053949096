import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps, forwardRef, useMemo } from 'react';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { HelperText } from '~/components/HelperText/HelperText';
import { InputStatus } from '~/components/Input/Input';
import { KeyboardKey } from '~/components/KeyboardShortcut/KeyboardShortcut';
import { useDebounceInput } from '~/hooks/useDebounceInput';
import { Spinner } from '../Spinner/Spinner';

const classes = {
    wrapper: {
        base: 'break-words grid group flex-1 w-full overflow-auto focus-within:z-10 focus-within:ring-offset-2 relative',
        idle: 'focus-within:ring-blue-600',
        border: {
            on: 'rounded-lg border focus-within:ring-2',
            off: 'border-0',
        },
        debouncing: 'focus-within:ring-gray-500',
        disabled: 'disabled bg-gray-100 text-gray-600 cursor-not-allowed',
        size: {
            sm: 'p-1',
            md: 'p-1.5',
            lg: 'p-2',
        },
    },
    size: {
        sm: 'text-xs',
        md: 'text-sm',
        lg: 'text-lg',
    },
    base: 'block w-full p-2.5 rounded-lg border',
    status: {
        default:
            'border-gray-300 text-gray-900 placeholder-gray-500 focus-within:border-gray-300 focus-within:ring-blue-600',
        error: 'border-red-300 text-red-800 placeholder-red-300 focus-within:border-red-300 focus-within:ring-red-500',
        success:
            'border-green-300 text-green-800 placeholder-green-300 focus-within:border-green-300 focus-within:ring-green-500',
    },

    withIcon: {
        leading: 'pl-10',
        trailing: 'pr-10',
    },
    icon: {
        base: 'pointer-events-none absolute',
        leading: {
            size: {
                sm: 'left-1 ',
                md: 'left-1.5',
                lg: 'left-2',
            },
        },
        trailing: {
            size: {
                sm: 'right-1',
                md: 'right-1.5',
                lg: 'right-2 ',
            },
        },
        wrapper: {
            size: {
                sm: 'top-1 flex items-center',
                md: 'top-1.5 flex items-center',
                lg: 'top-2 flex items-center',
            },
        },
        size: {
            sm: 'h-4 w-4',
            md: 'h-4 w-4 my-0.5',
            lg: 'h-4 w-4 my-1.5',
        },
    },
};

export interface TextareaProps extends Omit<ComponentProps<'textarea'>, 'ref'> {
    status?: InputStatus;
    statusText?: string;
    helperText?: string;
    debounce?: boolean | number;
    onPressEnter?: () => void;
    reset?: boolean;
    size?: 'sm' | 'md' | 'lg';
    borderless?: boolean;
    wrapperClassName?: string;
    iconClassName?: string;
    inputClassName?: string;
    spacingElementClassName?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    (
        {
            helperText,
            status = 'default',
            statusText,
            disabled,
            onChange,
            onBlur,
            debounce = false,
            onPressEnter,
            reset,
            value,
            rows = 1,
            size = 'md',
            borderless = false,
            className,
            wrapperClassName,
            inputClassName,
            iconClassName,
            spacingElementClassName,
            ...props
        },
        ref
    ) => {
        const delay = useMemo(() => {
            return typeof debounce === 'number' ? debounce : debounce === true ? 500 : 0;
        }, [debounce]);

        const { internalValue, handleChange, handleBlur, isDebouncing } = useDebounceInput({
            delay,
            value,
            onChange,
            onBlur,
            reset,
        });
        const hasTrailingContent = useMemo(() => {
            if (status !== 'default') {
                return true;
            }
            return status === 'default' && value != null && value !== '' && onPressEnter != null;
        }, [status, onPressEnter, value, isDebouncing]);
        const valueToUse = delay > 0 ? internalValue : value;
        return (
            <>
                <div
                    className={clsx(
                        classes.wrapper.base,
                        isDebouncing ? classes.wrapper.debouncing : classes.wrapper.idle,
                        borderless
                            ? classes.wrapper.border.off
                            : [classes.wrapper.border.on, classes.wrapper.size[size]],
                        classes.status[status] != null ? classes.status[status] : classes.status.default,
                        disabled && !borderless && classes.wrapper.disabled,
                        wrapperClassName
                    )}
                >
                    <textarea
                        className={clsx(
                            'ignore-marker col-start-1 col-end-2 row-start-1 row-end-2 resize-none overflow-hidden whitespace-pre-wrap break-words border-0 bg-transparent p-0 focus:ring-0 disabled:text-gray-400 group-[.disabled]:cursor-not-allowed',
                            classes.size[size],
                            hasTrailingContent && classes.withIcon.trailing,
                            className,
                            inputClassName
                        )}
                        ref={ref}
                        value={valueToUse}
                        rows={rows}
                        onKeyDown={(e) => {
                            if (onPressEnter != null && e.key === 'Enter' && e.shiftKey === false) {
                                e.preventDefault();
                                if (internalValue || value) {
                                    onPressEnter();
                                }
                            }
                            if (props.onKeyDown != null) {
                                props.onKeyDown(e);
                            }
                        }}
                        onChange={delay > 0 ? handleChange : onChange}
                        onBlur={delay > 0 ? handleBlur : onBlur}
                        disabled={disabled}
                        {...props}
                    />
                    {/*The space is to help the sizing when doing a single line break*/}
                    <span
                        className={clsx(
                            'pointer-events-none col-start-1 col-end-2 row-start-1 row-end-2 whitespace-pre-wrap break-words text-transparent',
                            hasTrailingContent && classes.withIcon.trailing,
                            classes.size[size],
                            spacingElementClassName,
                            className
                        )}
                    >
                        {valueToUse}&nbsp;
                    </span>
                    {onPressEnter != null && status === 'default' && (
                        <KeyboardKey
                            onClick={onPressEnter}
                            keyStroke={'⏎'}
                            size={'sm'}
                            disabled={isEmpty(value)}
                            className={clsx(
                                classes.icon.trailing.size[size],
                                'absolute top-1.5 cursor-pointer opacity-0 group-focus-within:opacity-100',
                                isEmpty(value) && 'pointer-events-none !opacity-0',
                                iconClassName
                            )}
                        />
                    )}
                    {status === 'error' && (
                        <div
                            className={clsx(
                                classes.icon.base,
                                classes.icon.wrapper.size[size],
                                classes.icon.trailing.size[size],
                                iconClassName
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className={clsx(classes.icon.size[size], 'text-red-500')}
                            />
                        </div>
                    )}
                    {status === 'success' && (
                        <div
                            className={clsx(
                                classes.icon.base,
                                classes.icon.wrapper.size[size],
                                classes.icon.trailing.size[size],
                                iconClassName
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className={clsx(classes.icon.size[size], 'text-green-500')}
                            />
                        </div>
                    )}
                    {status === 'loading' && (
                        <div
                            className={clsx(
                                classes.icon.base,
                                classes.icon.wrapper.size[size],
                                classes.icon.trailing.size[size],
                                iconClassName
                            )}
                        >
                            <Spinner
                                className={clsx('fill-blue-600 text-gray-900 text-opacity-20', classes.icon.size[size])}
                            />
                        </div>
                    )}
                </div>
                {helperText && <HelperText className={'mt-2'}>{helperText}</HelperText>}
                {statusText && <HelperText status={status}>{statusText}</HelperText>}
            </>
        );
    }
);

Textarea.displayName = 'Textarea';
