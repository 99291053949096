import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { faCog, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    ContextModalProps,
    Modal,
    Tabs,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { GeneralPanel } from 'Shared/components/team/SettingsModal/components/GeneralPanel';
import { MembersPanel } from 'Shared/components/team/SettingsModal/components/MembersPanel';
import { UpdateMeetingsAccessModal } from 'Shared/components/team/UpdateMeetingsAccessModal';
import { useTeam } from 'Shared/hooks/useTeam';
import { useLazyGetMeetingsQuery } from 'Shared/services/meeting';
import { transformError, useUpdateTeamMutation } from 'Shared/services/team';
import { ApiError } from 'Shared/types/apiError';
import { type Member } from 'Shared/types/userGroup';

type SettingsModalProps = {
    teamId: Id;
    defaultIndex?: number;
    isReadOnly?: boolean;
} & ContextModalProps &
    PropsWithChildren;

export const SettingsModal: FC<SettingsModalProps> = ({
    teamId,
    defaultIndex = 0,
    isReadOnly = false,
    children,
    onAfterClose,
    ...modalProps
}) => {
    const { open: openModal } = useModal();
    const { team, isLoading, isCurrentUserTeamModerator, isCurrentUserTeamMember } = useTeam(teamId);
    const { show: showNotification } = useNotification();
    const { width: viewportWidth } = useWindowSize();
    const [getTeamMeetings] = useLazyGetMeetingsQuery();

    const [updateTeam, { isLoading: updateTeamIsLoading }] = useUpdateTeamMutation();

    const initialMembersRef = useRef<Array<Member>>();

    const [selectedTab, setSelectedTab] = useState(defaultIndex);
    const [teamName, setTeamName] = useState<string>(team?.name ?? '');

    const isSaveDisabled = teamName.trim() === '' || updateTeamIsLoading;
    const showGeneralTab = (!team || isCurrentUserTeamModerator) && isCurrentUserTeamMember;

    const handleSave = async () => {
        const result = await updateTeam({ ...team, name: teamName });
        if ('data' in result) {
            await modalProps.close();
            return;
        }

        if (result.error instanceof ApiError && result.error.matchesSome(transformError())) {
            showNotification({
                message: result.error.message,
                type: 'danger',
            });

            return;
        }

        showNotification(UnexpectedErrorNotification);
    };

    const handleAfterClose = async () => {
        const initialUserIds = new Set(initialMembersRef.current.map(({ user_id }) => user_id));
        const currentUserIds = new Set(team.userGroup.members.map(({ user_id }) => user_id));

        const addedMembers = team.userGroup.members.filter(({ user_id }) => !initialUserIds.has(user_id));
        const deletedMembers = initialMembersRef.current.filter(({ user_id }) => !currentUserIds.has(user_id));

        if (addedMembers.length > 0 || deletedMembers.length > 0) {
            const teamMeetings = await getTeamMeetings({ teamId });
            if (teamMeetings.data.length > 0) {
                openModal(UpdateMeetingsAccessModal, {
                    teamId,
                    deletedMembers: new Map(deletedMembers.map(({ user }) => [user.id, user])),
                    addedMembers: new Map(addedMembers.map(({ user }) => [user.id, user])),
                    title: t`Update meeting access rights for ${team?.name}`,
                    description: t`Following your changes on the team members, update access rights to all meetings belonging to the workspaces inside the ${team?.name} team.`,
                });
            }
        }

        onAfterClose();
    };

    const handleUserDeleted = (action) => {
        if (action === 'close') {
            modalProps.close();
        }
    };

    useEffect(() => {
        if (!showGeneralTab) {
            setSelectedTab(0);
        }
    }, [showGeneralTab]);

    useEffect(() => {
        if (team != null && initialMembersRef.current == null) {
            initialMembersRef.current = team.userGroup.members;
        }
    }, [team]);

    if (isLoading) {
        return;
    }

    return (
        <Modal
            {...modalProps}
            onAfterClose={handleAfterClose}
            size="lg"
            panelClassName={'max-h-[calc(100vh_-_4rem)] flex flex-col'}
        >
            <Modal.Header title={t`Team settings`} />

            <Tabs
                selectedIndex={selectedTab}
                onChange={setSelectedTab}
                layout="vertical"
                className="h-full overflow-hidden"
            >
                <Tabs.Header>
                    {showGeneralTab && (
                        <Tabs.Tab icon={faCog}>
                            <Trans>General</Trans>
                        </Tabs.Tab>
                    )}

                    <Tabs.Tab icon={faUsers}>
                        <Trans>Members</Trans>
                    </Tabs.Tab>
                </Tabs.Header>

                <div className="hidden min-h-[400px] w-px bg-gray-200 md:block" />

                <Tabs.Panels
                    className={clsx(
                        viewportWidth < getBreakpointValue('md') && 'p-5',
                        '!overflow-auto py-2 w-full scrollbar-light'
                    )}
                >
                    {showGeneralTab && (
                        <GeneralPanel
                            teamId={teamId}
                            teamName={teamName}
                            onNameChange={setTeamName}
                            onSave={handleSave}
                        />
                    )}

                    <MembersPanel isReadOnly={isReadOnly} teamId={teamId} onUserDeleted={handleUserDeleted} />
                </Tabs.Panels>
            </Tabs>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>

                {selectedTab === 0 && showGeneralTab && (
                    <Button
                        onClick={handleSave}
                        disabled={isSaveDisabled}
                        loading={updateTeamIsLoading}
                        color="primary"
                    >
                        <Trans>Save</Trans>
                    </Button>
                )}
            </Modal.Footer>

            {children}
        </Modal>
    );
};
