import React, { FC, PropsWithChildren, ReactNode, useId, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Alert,
    Button,
    ColorPickerPopover,
    ContextModalProps,
    Form,
    getRandomWedoColor,
    Input,
    ItemGroup,
    Modal,
    Select,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { LanguageCode, LanguageMap } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { adminErrorTransform, useAddUserMutation } from 'Shared/services/admin';
import { ApiError } from 'Shared/types/apiError';
import { UserRole } from 'Shared/types/user';
import {
    calculateInitials,
    emailError as getEmailError,
    initialsError as getInitialsError,
    requiredAndNoSpecialError,
} from 'Shared/utils/user';

const defaultFormFields = {
    firstname: '',
    lastname: '',
    email: '',
    color: '',
    jobTitle: '',
    initials: '',
    language: '',
};

const verifyIsFormValid = (errorsMap: Map<FormFields, string>) => {
    for (const error of errorsMap.values()) {
        if (error !== '') {
            return false;
        }
    }

    return true;
};

type FormFields = keyof typeof defaultFormFields;

type AddExternalModalProps = ContextModalProps & PropsWithChildren;
export const AddExternalUserModal: FC<AddExternalModalProps> = ({ children, close, ...modalProps }) => {
    const id = useId();
    const { confirm: showConfirm } = useConfirm();
    const { show: showNotification } = useNotification();
    const { currentUser } = useCurrentUserContext();
    const [createUser, { isLoading: isCreateUserLoading }] = useAddUserMutation();
    const [formFields, setFormFields] = useState({
        ...defaultFormFields,
        language: currentUser.language_code,
        color: getRandomWedoColor(),
    });
    const [errors, setErrors] = useState<Map<FormFields, string>>(new Map());
    const [serverError, setServerError] = useState<ReactNode>('');

    const checkErrors = () => {
        const errorsMap = new Map();

        errorsMap.set('firstname', requiredAndNoSpecialError(formFields.firstname));
        errorsMap.set('lastname', requiredAndNoSpecialError(formFields.lastname));
        errorsMap.set('email', getEmailError(formFields.email));
        errorsMap.set('initials', getInitialsError(formFields.initials));

        return errorsMap;
    };

    const setValue = (key: FormFields) => {
        return (value: string | React.ChangeEvent<HTMLInputElement>) => {
            const text = typeof value === 'string' ? value : value.target.value;

            if (serverError !== '') {
                setServerError('');
            }

            if ((errors.get(key) ?? '') !== '') {
                setErrors((errors) => {
                    return errors.set(key, '');
                });
            }

            const modifications = { [key]: text };

            if (key === 'firstname' || key === 'lastname') {
                const firstname = key === 'firstname' ? text : formFields.firstname;
                const lastname = key === 'lastname' ? text : formFields.lastname;
                modifications.initials = calculateInitials(firstname, lastname, formFields.initials);
            }

            setFormFields({ ...formFields, ...modifications });
        };
    };

    const getStatus = (key: FormFields) => {
        return (errors.get(key) ?? '') === '' ? 'default' : 'error';
    };

    const getText = (error: FormFields) => {
        return errors.get(error) ?? '';
    };

    const getId = (name: string) => {
        return name + id;
    };

    const confirmCreateExternal = async () => {
        const creationResult = await createUser({
            can_add_external: false,
            color: { text: '#000000', background: formFields.color },
            email: formFields.email.trim(),
            firstName: formFields.firstname.trim(),
            initials: formFields.initials,
            invite: true,
            language: formFields.language,
            lastName: formFields.lastname.trim(),
            photoUrl: '',
            saml: false,
            title: formFields.jobTitle.trim(),
            teams: [],
            userType: UserRole.EXTERNAL,
        });

        if ('error' in creationResult) {
            if (creationResult.error instanceof ApiError && creationResult.error.matchesSome(adminErrorTransform)) {
                setServerError(creationResult.error.message);
                return;
            }

            setServerError(UnexpectedErrorNotification.message);
            return;
        }

        showNotification({
            type: 'success',
            message: (
                <Trans>
                    Successfully added{' '}
                    <strong>
                        {creationResult.data.first_name} {creationResult.data.last_name}
                    </strong>
                </Trans>
            ),
        });

        void close();
    };

    const handleCreateExternal = () => {
        const errorsMap = checkErrors();
        setErrors(errorsMap);
        if (!verifyIsFormValid(errorsMap)) {
            return;
        }

        void showConfirm({
            type: 'primary',
            size: 'md',
            title: (
                <span>
                    <Trans>
                        Do you really want to add{' '}
                        <strong>
                            {formFields.firstname} {formFields.lastname}
                        </strong>
                    </Trans>
                </span>
            ),
            content: (
                <Trans>
                    An invitation will be sent to the following email address: <strong>{formFields.email}</strong>
                </Trans>
            ),
            onConfirm: confirmCreateExternal,
        });
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Add an external user`} />

            <Modal.Body>
                <Form id={getId('form')} layout="vertical" onSubmit={handleCreateExternal}>
                    <Form.Item cols={3} label={t`First name`} htmlFor={getId('firstname')}>
                        <Input
                            id={getId('firstname')}
                            onChange={setValue('firstname')}
                            value={formFields.firstname}
                            status={getStatus('firstname')}
                            statusText={getText('firstname')}
                        />
                    </Form.Item>

                    <Form.Item cols={3} label={t`Last name`} htmlFor={getId('lastname')}>
                        <Input
                            id={getId('lastname')}
                            onChange={setValue('lastname')}
                            value={formFields.lastname}
                            status={getStatus('lastname')}
                            statusText={getText('lastname')}
                        />
                    </Form.Item>

                    <Form.Item cols={3} label={t`Initials`} htmlFor={getId('initials')}>
                        <ItemGroup status={getStatus('initials')} statusText={getText('initials')}>
                            <Input id={getId('initials')} onChange={setValue('initials')} value={formFields.initials} />

                            <ColorPickerPopover
                                showSelectedColor
                                classNameButton="rounded-l-none"
                                color={formFields.color}
                                onChange={setValue('color')}
                            />
                        </ItemGroup>
                    </Form.Item>

                    <Form.Item cols={3} label={t`Email`} htmlFor={getId('email')}>
                        <Input
                            id={getId('email')}
                            onChange={setValue('email')}
                            value={formFields.email}
                            status={getStatus('email')}
                            statusText={getText('email')}
                        />
                    </Form.Item>

                    <Form.Item cols={3} label={t`Job title`} htmlFor={getId('job-title')}>
                        <Input id={getId('job-title')} onChange={setValue('jobTitle')} value={formFields.jobTitle} />
                    </Form.Item>

                    <Form.Item cols={3} label={t`Language`} htmlFor={getId('language')}>
                        <Select
                            value={formFields.language}
                            onChange={(value: LanguageCode) => setValue('language')(value)}
                            customRenderSelected={(value: string) => LanguageMap.get(value)}
                        >
                            {[...LanguageMap.keys()].map((key) => (
                                <Select.Option key={key} value={key}>
                                    {LanguageMap.get(key)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {serverError !== '' && (
                        <Form.Item>
                            <Alert type="danger">{serverError}</Alert>
                        </Form.Item>
                    )}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button disabled={isCreateUserLoading} onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button form={getId('form')} loading={isCreateUserLoading} color="primary" type="submit">
                    <Trans>Create the user</Trans>
                </Button>
            </Modal.Footer>

            {children}
        </Modal>
    );
};
