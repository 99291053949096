import { Descendant, Editor, Operation } from 'slate';
import { Plugin } from '../Editor';

export const onChangePlugin = (onChange: (editor: Editor, children: Descendant[]) => void): Plugin => ({
    onChange: (editor, children) => {
        if (!editor.operations.every(Operation.isSelectionOperation)) {
            onChange(editor, children);
        }
        return false;
    },
});
