import { Element } from 'slate';
import { createParagraphBlock } from '../paragraphPlugin';
import { HtmlContentType, RtfContentType, TextContentType } from './copyPastePlugin';
import { deserializeHtml } from './deserializeHtml';

export const extractBlocks = (data: DataTransfer): [Array<Element>, 'html' | 'text'] => {
    try {
        const html = data.getData(HtmlContentType);
        const rtf = data.getData(RtfContentType);
        const blocks = deserializeHtml(html, rtf).map((block) => {
            block.id = crypto.randomUUID();
            return block;
        });
        if (blocks.length === 0) {
            throw new Error('No blocks were extracted from HTML');
        }
        return [blocks, 'html'];
    } catch (error) {
        console.warn(error);
        return [
            data.getData(TextContentType)?.split('\n').map(createParagraphBlock) ?? [createParagraphBlock()],
            'text',
        ];
    }
};
