import { FC } from 'react';
import { Dropdown } from '@wedo/design-system';
import { FieldTypes, Operators } from 'Pages/TasksPage/components/utils';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';
import { FieldConditionFilter, Operator } from 'Shared/types/filter';

type FieldConditionOperatorDropdownProps = {
    field: FieldConditionFilter;
    value: Operator;
    onChange: (value: Operator) => void;
};

export const FieldConditionOperatorDropdown: FC<FieldConditionOperatorDropdownProps> = ({ field, value, onChange }) => {
    const { getFilterType } = useCurrentTasksFilter();

    const fieldType = getFilterType(field);

    if (!fieldType) {
        return null;
    }

    const options = FieldTypes[fieldType]?.map((operator) => ({
        label: Operators[operator],
        id: operator,
    }));

    return (
        <Dropdown size="sm" position="middle" label={Operators[value]}>
            {options.map((option) => (
                <Dropdown.Item onClick={() => onChange(option.id)} key={option.id}>
                    {option.label}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};
