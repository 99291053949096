import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { VoteHistoryModal } from 'Pages/meeting/components/Vote/VoteDetail/VoteHistoryModal';

interface VoteDetailFooterProps {
    voteId: Id;
    meetingId: Id;
}

export const VoteDetailFooter = ({ voteId, meetingId }: VoteDetailFooterProps): JSX.Element => {
    const { open: openModal } = useModal();
    return (
        <Button
            variant={'text'}
            icon={faHistory}
            onClick={() => openModal(VoteHistoryModal, { voteId, meetingId })}
            aria-label={t`Vote history`}
            title={t`Vote history`}
        />
    );
};
