import React from 'react';
import { Trans } from '@lingui/macro';
import { UserRole } from 'Shared/types/user';

type UserRoleDescriptionProps = {
    role: UserRole;
};

export const UserRoleDescription: React.FC<UserRoleDescriptionProps> = ({ role }) => {
    switch (role) {
        case UserRole.USER:
            return <Trans>Standard account.</Trans>;

        case UserRole.LIGHT:
            return (
                <Trans>
                    Can complete, comment and attach files to tasks assigned to them, and access meetings in read-only
                    mode.
                </Trans>
            );

        case UserRole.ADMIN:
            return <Trans>Can manage users, teams and the subscription.</Trans>;

        case UserRole.EXTERNAL:
            return <Trans>Reserved to people outside the organization.</Trans>;

        default:
            return null;
    }
};
