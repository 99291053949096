import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useCallback, useState } from 'react';
import { useReadOnly, useSlateStatic } from 'slate-react';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { formatDistance } from 'date-fns';
import { type Element, Transforms } from 'slate';
import { useStore } from 'zustand';
import { getDateFnsLocale } from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import { useUser } from 'App/store/usersStore';
import { reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { AddBlockItem } from './AddBlockItem';
import { OptionsItem } from './OptionsItem';
import { type Store } from './store';

type ContextMenuProps = {
    store: Store;
    element: Element;
    topicId: string;
    sectionId: string;
};

export const ContextMenu = ({ store, element, topicId, sectionId }: ContextMenuProps) => {
    const { i18n } = useLingui();

    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();

    const [isOpen, setIsOpen] = useState(false);

    const activeBlockId = useStore(store, ({ activeBlockId }) => activeBlockId);

    const updatedBy = useUser(element.updated_by);

    const handleMouseDown = ({ button }: MouseEvent) => {
        if (button === 0) {
            Object.assign(document.body.style, { userSelect: 'none' });
            document.documentElement.style.setProperty('cursor', 'grabbing', 'important');
            store.setState({ activeBlockId: element.id });
        }
    };

    const handleMouseMove = useCallback(({ target, pageY }: MouseEvent) => {
        const activeBlockId = store.getState().activeBlockId;
        if (activeBlockId != null) {
            const overElement = (target as HTMLElement).closest('[data-block-id]') as HTMLElement;
            const overBlockId = overElement?.dataset.blockId;
            if (overBlockId != null) {
                const overIndex = editor.children.findIndex(({ id }) => id === overBlockId);
                const activeIndex = editor.children.findIndex(({ id }) => id === activeBlockId);
                const { top, height } = overElement.getBoundingClientRect();
                const newOverIndex = pageY < top + height / 2 ? overIndex - 1 : overIndex;
                store.setState({
                    overBlockId:
                        overIndex === -1 || newOverIndex === activeIndex - 1 || newOverIndex === activeIndex
                            ? null
                            : editor.children[newOverIndex]?.id ?? 'topic',
                });
            }
        }
    }, []);

    const handleMouseUp = useCallback(() => {
        const { activeBlockId, overBlockId } = store.getState();
        if (activeBlockId != null && overBlockId != null) {
            const activeIndex = editor.children.findIndex(({ id }) => id === activeBlockId);
            const overIndex = editor.children.findIndex(({ id }) => id === overBlockId);
            const newOverIndex = overBlockId === 'topic' ? 0 : overIndex > activeIndex ? overIndex : overIndex + 1;
            Transforms.moveNodes(editor, { at: [activeIndex], to: [newOverIndex] });
            reorderBlocks(editor);
            forceSave(editor);
        }
        store.setState({ activeBlockId: null, overBlockId: null });
        Object.assign(document.body.style, { userSelect: 'auto' });
        document.documentElement.style.cursor = 'auto';
    }, []);

    useEvent('mousemove', handleMouseMove);
    useEvent('mouseup', handleMouseUp);

    return (
        <div
            contentEditable={false}
            className={clsx(
                '-mt-2 relative select-none',
                activeBlockId == null && 'has-[+div:hover]:opacity-100 hover:opacity-100',
                !isOpen && 'opacity-0'
            )}
        >
            <div
                className={clsx(
                    'absolute top-2 flex justify-between',
                    isReadOnly
                        ? '-left-[calc(1.5rem+1px)] -right-[calc(1.5rem+2px)]'
                        : '-left-12 -right-[calc(3rem+2px)]'
                )}
            >
                <div className="border bg-gray-300 border-gray-300 flex rounded-md gap-px overflow-hidden">
                    <AddBlockItem
                        element={element}
                        blockId={element.id}
                        topicId={topicId}
                        sectionId={sectionId}
                        onOpen={setIsOpen}
                    />
                    {!isReadOnly && (
                        <FontAwesomeIcon
                            tabIndex={-1}
                            icon={faGripVertical}
                            className="h-3.5 w-3.5 bg-white p-1 text-gray-500 cursor-grab outline-none"
                            onMouseDown={handleMouseDown}
                        />
                    )}
                </div>
                <div className="border bg-gray-300 border-gray-300 overflow-hidden rounded-md flex gap-px">
                    <UserAvatar
                        size="xs"
                        user={updatedBy}
                        wrapperClassName="bg-white p-0.5"
                        className="!h-[1.125rem] !w-[1.125rem]"
                        tooltipContent={
                            updatedBy != null
                                ? t`Last updated by ${updatedBy?.full_name} ${formatDistance(
                                      new Date(element.updated_at ?? new Date()),
                                      new Date(),
                                      { addSuffix: true, locale: getDateFnsLocale(i18n?.locale) }
                                  )}`
                                : null
                        }
                    />
                    {!isReadOnly && (
                        <OptionsItem node={element} topicId={topicId} sectionId={sectionId} onOpen={setIsOpen} />
                    )}
                </div>
            </div>
        </div>
    );
};
