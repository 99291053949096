import { getWeekdays } from '@wedo/utils';

export const getEmailHourBadges = () => {
    const result = [];
    for (let i = 1; i <= 24; i++) {
        result.push({ id: String(i), label: String(i), selected: false });
    }
    return result;
};

export const getEmailDayBadges = (locale: Intl.Locale) => {
    const result = [];
    const weekDays = getWeekdays(locale);
    for (const day of weekDays) {
        result.push({ id: day.id, label: day.shortHand, selected: false });
    }
    return result;
};
