import { useLingui } from '@lingui/react';
import { ReactNode, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    addDays,
    compareAsc,
    compareDesc,
    endOfDay,
    intlFormat,
    isAfter,
    isBefore,
    isSameDay,
    parse,
    startOfDay,
    subDays,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Id } from '@wedo/types';
import { isValidDate } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useUsers } from 'App/store/usersStore';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useEntityMembers } from 'Shared/hooks/useEntityMembers';
import { CustomField } from 'Shared/types/customField';
import { Section } from 'Shared/types/section';
import { Task, TaskOrder, TaskStatus } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { CustomFieldPrefix } from '../constants';
import { useSections } from './useSections';
import { useWorkspaceCustomFields } from './useWorkspaceCustomFields';

const UpcomingWeekDays = [
    <Trans key={1}>Next sunday</Trans>,
    <Trans key={2}>Next monday</Trans>,
    <Trans key={3}>Next tuesday</Trans>,
    <Trans key={4}>Next wednesday</Trans>,
    <Trans key={5}>Next thursday</Trans>,
    <Trans key={6}>Next friday</Trans>,
    <Trans key={7}>Next saturday</Trans>,
];

const PastWeekDays = [
    <Trans key={1}>Last sunday</Trans>,
    <Trans key={2}>Last monday</Trans>,
    <Trans key={3}>Last tuesday</Trans>,
    <Trans key={4}>Last wednesday</Trans>,
    <Trans key={5}>Last thursday</Trans>,
    <Trans key={6}>Last friday</Trans>,
    <Trans key={7}>Last saturday</Trans>,
];

export type DateCategory =
    | 'overdue'
    | 'yesterday'
    | 'today'
    | 'tomorrow'
    | 'relative_future'
    | 'relative_past'
    | 'later'
    | 'someday'
    | 'raw_past'
    | 'raw_future'
    | 'empty';

type TaskTypes = 'ongoing' | 'all';

export const getRelativeAndRawGroupsAndLabels = ({
    date,
    reference,
    locale,
}: {
    date: Date;
    reference: Date;
    locale: string;
}): {
    label: ReactNode;
    category: DateCategory;
} => {
    const stringDate = intlFormat(date, { month: 'long', day: 'numeric', year: 'numeric' }, { locale });
    if (isSameDay(date, subDays(reference, 1))) {
        return { label: <Trans>Yesterday</Trans>, category: 'yesterday' };
    }
    if (isBefore(date, startOfDay(reference))) {
        if (isAfter(date, subDays(reference, 7))) {
            return {
                label: (
                    <>
                        {PastWeekDays[date.getDay()]} - {stringDate}
                    </>
                ),
                category: 'relative_past',
            };
        }
        return {
            label: stringDate,
            category: 'raw_past',
        };
    }
    if (isAfter(date, endOfDay(reference))) {
        if (isBefore(date, addDays(reference, 7))) {
            return {
                label: (
                    <>
                        {UpcomingWeekDays[date.getDay()]} - {stringDate}
                    </>
                ),
                category: 'relative_future',
            };
        }
        return {
            label: stringDate,
            category: 'raw_future',
        };
    }
    return null;
};

export const getDefaultSortDateGroupAndLabel = ({
    date,
    reference,
    locale,
    typesOfTasks,
}: {
    date: Date;
    reference: Date;
    locale: string;
    typesOfTasks: TaskTypes;
}): {
    label: ReactNode;
    category: DateCategory;
} => {
    if (date == null || !isValidDate(date)) {
        return {
            label: typesOfTasks === 'ongoing' ? <Trans>Some day</Trans> : <Trans>No date</Trans>,
            category: 'someday',
        };
    }
    if (isSameDay(date, reference)) {
        return { label: <Trans>Today</Trans>, category: 'today' };
    }
    if (isSameDay(date, addDays(reference, 1))) {
        return { label: <Trans>Tomorrow</Trans>, category: 'tomorrow' };
    }

    if (typesOfTasks === 'ongoing') {
        if (isBefore(date, startOfDay(reference))) {
            return { label: <Trans>Overdue</Trans>, category: 'overdue' };
        }
        if (isAfter(date, endOfDay(addDays(reference, 1)))) {
            return { label: <Trans>Later</Trans>, category: 'later' };
        }
    } else {
        return getRelativeAndRawGroupsAndLabels({ date, reference, locale });
    }
    return null;
};
export const getPlannedDateGroupAndLabel = ({
    date,
    reference,
    locale,
    typesOfTasks,
}: {
    date: Date;
    reference: Date;
    locale: string;
    typesOfTasks: TaskTypes;
}): {
    category: DateCategory;
    label: ReactNode;
} => {
    if (date == null || !isValidDate(date)) {
        return { category: 'empty', label: t`No start date` };
    }
    if ((isBefore(date, endOfDay(reference)) && typesOfTasks === 'ongoing') || isSameDay(date, reference)) {
        return { label: <Trans>Today</Trans>, category: 'today' };
    }
    if (isSameDay(date, subDays(reference, 1))) {
        return { label: <Trans>Yesterday</Trans>, category: 'yesterday' };
    }

    if (isSameDay(date, addDays(reference, 1))) {
        return { label: <Trans>Tomorrow</Trans>, category: 'tomorrow' };
    }
    return getRelativeAndRawGroupsAndLabels({ date, reference, locale });
};
export const getDueDateGroupAndLabel = ({
    date,
    reference,
    locale,
    typesOfTasks,
    taskIsDeletedOrCompleted,
}: {
    date: Date;
    reference: Date;
    locale: string;
    typesOfTasks: TaskTypes;
    taskIsDeletedOrCompleted: boolean;
}): {
    category: DateCategory;
    label: ReactNode;
} => {
    if (date == null || !isValidDate(date)) {
        return { category: 'empty', label: t`No due date` };
    }
    if (!taskIsDeletedOrCompleted && isBefore(date, startOfDay(reference)) && typesOfTasks === 'ongoing') {
        return { label: <Trans>Overdue</Trans>, category: 'overdue' };
    }
    if (isSameDay(date, subDays(reference, 1))) {
        return { label: <Trans>Yesterday</Trans>, category: 'yesterday' };
    }

    if (isSameDay(date, reference)) {
        return { label: <Trans>Today</Trans>, category: 'today' };
    }
    if (isSameDay(date, addDays(reference, 1))) {
        return { label: <Trans>Tomorrow</Trans>, category: 'tomorrow' };
    }
    return getRelativeAndRawGroupsAndLabels({ date, reference, locale });
};

export const getDateCategoryAndLabel = (
    date: Date,
    reference: Date,
    locale: string,
    sort: 'default_date' | 'planned_date' | 'due_date',
    typesOfTasks: TaskTypes,
    taskIsDeletedOrCompleted: boolean
): {
    category: DateCategory;
    label: ReactNode;
} => {
    if (sort === 'due_date') {
        return getDueDateGroupAndLabel({ date, reference, locale, typesOfTasks, taskIsDeletedOrCompleted });
    }
    if (sort === 'planned_date') {
        return getPlannedDateGroupAndLabel({ date, reference, locale, typesOfTasks });
    }
    return getDefaultSortDateGroupAndLabel({ date, reference, locale, typesOfTasks });
};

export type GroupedTask = Task & {
    groupedId: string;
    originalGroupKey: string;
    groupKey: string;
};

export type Group = {
    key: string;
    tasks: GroupedTask[];
    label?: string | JSX.Element;
    flagged?: boolean;
    draggable?: boolean;
    droppable?: boolean;
    order?: number;
    color?: string;
    type?:
        | 'default_date'
        | 'planned_date'
        | 'due_date'
        | 'custom_field'
        | 'priority'
        | 'checklist_section'
        | 'workspace_section'
        | 'user'
        | 'workspace';
};

type GroupKey = Omit<Group, 'tasks'> & {
    groupedId?: string;
};

type GroupProperties = Omit<Group, 'key' | 'label' | 'tasks'>;

const EmojiRegex = /^(\p{Emoji}(\u200d\p{Emoji})*)/u;

const DateFormat = 'yyyy-MM-dd';

const DateOrderingDefaultOngoing: {
    [key: string]: number;
} = { overdue: 0, today: 1, tomorrow: 2, relative_future: 3, later: 4, someday: 5 };

const DateOrderingDefaultAll: {
    [key: string]: number;
} = {
    raw_future: 0,
    relative_future: 1,
    tomorrow: 2,
    today: 3,
    yesterday: 4,
    relative_past: 5,
    raw_past: 6,
    someday: 7,
};

const DateOrderingPlannedDate: {
    [key: string]: number;
} = {
    raw_past: -3,
    relative_past: -2,
    yesterday: -1,
    today: 0,
    tomorrow: 1,
    relative_future: 2,
    raw_future: 3,
    empty: 4,
};

const DateOrderingDueDateOngoing: {
    [key: string]: number;
} = { overdue: 0, today: 1, tomorrow: 2, relative_future: 3, raw_future: 4, empty: 5 };

const DateOrderingDueDateAll: {
    [key: string]: number;
} = {
    raw_past: -2,
    relative_past: -1,
    yesterday: 0,
    today: 1,
    tomorrow: 2,
    relative_future: 3,
    raw_future: 4,
    empty: 5,
};

const integerSorter = ([a]: [string, Group], [b]: [string, Group]): number => {
    return parseInt(a, 10) - parseInt(b, 10);
};

const stringSorter =
    (useOrder = false) =>
    ([a, groupA]: [string, Group], [b, groupB]: [string, Group]): number =>
        useOrder ? groupA.order - groupB.order : a.localeCompare(b, undefined, { sensitivity: 'accent' });

const labelSorter =
    () =>
    ([a, groupA]: [string, Group], [b, groupB]: [string, Group]): number =>
        (a === '' ? a : (groupA.label as string)).localeCompare(b === '' ? a : (groupB.label as string), undefined, {
            sensitivity: 'accent',
        });

const dateSorter =
    (reference: Date, dateParam: 'default_date' | 'planned_date' | 'due_date', typesOfTasks: 'ongoing' | 'all') =>
    ([a]: [string, Group], [b]: [string, Group]) => {
        let sortingArray;
        const splitA = a.split('/');
        const splitB = b.split('/');
        const categoryA = splitA[0];
        const categoryB = splitB[0];
        const stringDateA = splitA[1];
        const stringDateB = splitB[1];

        if (dateParam === 'planned_date') {
            sortingArray = DateOrderingPlannedDate;
        } else if (dateParam === 'due_date') {
            if (typesOfTasks === 'ongoing') {
                sortingArray = DateOrderingDueDateOngoing;
            } else {
                sortingArray = DateOrderingDueDateAll;
            }
        } else if (typesOfTasks === 'ongoing') {
            sortingArray = DateOrderingDefaultOngoing;
        } else {
            sortingArray = DateOrderingDefaultAll;
        }
        if (sortingArray[categoryA] == null && sortingArray[categoryB] == null) {
            return compareAsc(parse(categoryA, DateFormat, reference), parse(categoryB, DateFormat, reference));
        }
        const diff = sortingArray[categoryA] - sortingArray[categoryB];
        if (diff === 0 && splitA.length > 1) {
            if (dateParam === 'default_date' && typesOfTasks === 'all') {
                return compareDesc(
                    parse(stringDateA, DateFormat, reference),
                    parse(stringDateB, DateFormat, reference)
                );
            }
            return compareAsc(parse(stringDateA, DateFormat, reference), parse(stringDateB, DateFormat, reference));
        }
        return diff;
    };

const emptyGroup = (key: string, label: string, properties?: GroupProperties): Group => ({
    ...properties,
    key,
    label,
    tasks: [],
});

const groupBy = (
    tasks: Task[],
    sorter: ([a]: [string, Group], [b]: [string, Group]) => number,
    predicate: (task: Task) => GroupKey | GroupKey[],
    initialGroups: {
        [group: string]: Group;
    } = {}
): Group[] => {
    return Object.entries(
        tasks.reduce((groups, task) => {
            [predicate(task)].flat().forEach(({ key, groupedId, ...properties }) => {
                if (groups[key] == null) {
                    groups[key] = { ...properties, key, tasks: [] };
                }
                groups[key].tasks.push({
                    ...task,
                    groupedId: groupedId ?? task.id.toString(),
                    originalGroupKey: groups[key].key,
                    groupKey: groups[key].key,
                });
            });
            return groups;
        }, initialGroups)
    )
        .sort(sorter)
        .map(([, group]) => group);
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const dateToString = (date: string | Date) => {
    return formatInTimeZone(date, timezone, 'yyyy-MM-dd');
};

export const groupByDate = (
    tasks: Task[],
    dateProperty: 'default_date' | 'planned_date' | 'due_date',
    status: TaskStatus[],
    locale: string,
    initialGroups?: {
        [group: string]: Group;
    }
): Group[] => {
    const now = startOfDay(new Date());

    const hasDeletedOrCompletedTasks = status.includes('deleted') || status.includes('completed');

    return groupBy(
        tasks,
        dateSorter(now, dateProperty, hasDeletedOrCompletedTasks ? 'all' : 'ongoing'),
        (task) => {
            let stringDate: string;
            if (dateProperty === 'default_date') {
                if (task.deleted || task.completed) {
                    stringDate = task.deleted ? dateToString(task.deleted_at) : dateToString(task.completed_at);
                } else if (task.due_date != null) {
                    stringDate = isBefore(new Date(task.due_date), startOfDay(now))
                        ? task.due_date
                        : task.planned_date &&
                            isBefore(new Date(task.planned_date), startOfDay(now)) &&
                            !hasDeletedOrCompletedTasks
                          ? dateToString(new Date())
                          : task.planned_date?.substring(0, 10);
                } else {
                    stringDate =
                        task.planned_date &&
                        isBefore(new Date(task.planned_date), startOfDay(now)) &&
                        !hasDeletedOrCompletedTasks
                            ? dateToString(new Date())
                            : task.planned_date?.substring(0, 10);
                }
            } else {
                stringDate = task[dateProperty]?.substring(0, 10);
            }

            const date = stringDate ? new Date(stringDate) : null;

            const { category, label } = getDateCategoryAndLabel(
                date,
                now,
                locale,
                dateProperty,
                hasDeletedOrCompletedTasks ? 'all' : 'ongoing',
                task.completed || task.deleted
            );
            return {
                key:
                    category.startsWith('raw') || category.startsWith('relative')
                        ? `${category}/${stringDate}`
                        : category,
                label: label,
                type: dateProperty,
                flagged: category === 'overdue',
                droppable: category !== 'overdue',
                draggable: category !== 'overdue' || dateProperty !== 'default_date',
            } as Group;
        },
        initialGroups
    );
};

export const groupByName = (tasks: Task[]): Group[] =>
    groupBy(tasks, stringSorter(), (task) => {
        const match = EmojiRegex.exec(task.name.trim());
        const key = task.name.trim()[0]?.toUpperCase() ?? '';
        const label = match != null ? match[0] : key;
        return {
            key,
            label: label === '' ? t`No name` : label,
            droppable: false,
            draggable: false,
        };
    });

export const groupByCustomFields = (tasks: Task[], customFieldId: string, customFields: CustomField[]): Group[] => {
    return groupBy(
        tasks,
        stringSorter(true),
        (task) => {
            const customFieldValue = task.custom_fields?.find((value) => value.custom_field_id === customFieldId);
            const label =
                customFieldValue == null
                    ? ''
                    : customFields
                          .find(({ id }) => id === customFieldValue.custom_field_id)
                          ?.options.find(({ id }) => id === customFieldValue.custom_field_option_id)?.label ?? '';
            return { key: label };
        },
        customFields.length > 0
            ? (Object.fromEntries(
                  [['', emptyGroup(customFieldId, t`Other`, { type: 'custom_field' })]].concat(
                      (customFields.find((customField) => customField.id === customFieldId)?.options || []).map(
                          (option) => [
                              option.label,
                              emptyGroup(`${customFieldId}-${option.id}`, option.label, { type: 'custom_field' }),
                          ]
                      )
                  )
              ) as {
                  [group: string]: Group;
              })
            : {}
    );
};

export const groupByPriority = (tasks: Task[]): Group[] =>
    groupBy(tasks, integerSorter, (task) => ({ key: task.priority.toString() }), {
        0: emptyGroup('0', t`No priority`, { type: 'priority' }),
        1: emptyGroup('1', t`Not important and not urgent`, { type: 'priority' }),
        2: emptyGroup('2', t`Not important and urgent`, { type: 'priority' }),
        3: emptyGroup('3', t`Important and not urgent`, { type: 'priority' }),
        4: emptyGroup('4', t`Important and urgent`, { type: 'priority' }),
    });

export const groupByWorkspaceSection = (
    tasks: Task[],
    workspaceId: Id,
    sections: {
        [id: string]: Section;
    }
): Group[] =>
    groupBy(
        tasks,
        stringSorter(true),
        (task) => {
            const workspace = task.workspaces?.find(({ id }) => id === workspaceId);
            return { key: sections[workspace?.tag_section_id]?.name ?? '' };
        },
        Object.fromEntries(
            Object.entries(sections)
                .map(([id, { name, color, order }]) => [
                    name,
                    emptyGroup(id, name, { type: 'workspace_section', color, order }),
                ])
                .concat([['', emptyGroup('', t`No section`, { type: 'workspace_section', order: -1 })]])
        ) as {
            [group: string]: Group;
        }
    );

export const groupByChecklistSection = (
    tasks: Task[],
    sections: {
        [id: string]: Section;
    }
): Group[] =>
    groupBy(
        tasks,
        stringSorter(true),
        (task) => ({ key: sections[task.checklist_section_id]?.name ?? '' }),
        Object.fromEntries(
            Object.entries(sections)
                .map(([id, { name, color, order }]) => [
                    name,
                    emptyGroup(id, name, { type: 'checklist_section', color, order }),
                ])
                .concat([
                    [
                        '',
                        emptyGroup('', t`No section`, {
                            type: 'checklist_section',
                        }),
                    ],
                ])
        ) as {
            [group: string]: Group;
        }
    );

export const groupByStatus = (tasks: Task[]): Group[] =>
    groupBy(tasks, integerSorter, (task) => ({
        key: task.deleted ? '0' : task.completed ? '1' : '2',
        label: task.deleted ? t`Deleted tasks` : task.completed ? t`Completed tasks` : t`Open tasks`,
        droppable: false,
        draggable: false,
    }));

export const groupByUser = (tasks: Task[], users: User[], hideEmptyGroups = false): Group[] =>
    groupBy(
        tasks,
        stringSorter(),
        (task) => ({
            key: users.find(({ id }) => id === task.assignee_id)?.full_name ?? '',
            label: users.find(({ id }) => id === task.assignee_id)?.full_name ?? t`No assignee`,
        }),
        hideEmptyGroups
            ? {}
            : (Object.fromEntries(
                  Object.entries(users)
                      .map(([, { id, full_name }]) => [
                          full_name,
                          emptyGroup(id.toString(), full_name, { type: 'user' }),
                      ])
                      .concat([
                          [
                              '',
                              emptyGroup('', t`No assignee`, {
                                  type: 'user',
                                  order: Object.keys(users).length,
                              }),
                          ],
                      ])
              ) as {
                  [group: string]: Group;
              })
    );

export const groupByWorkspace = (tasks: Task[], currentUser: User): Group[] =>
    groupBy(tasks, labelSorter(), (task) =>
        task.workspaces?.length > 0
            ? task.workspaces.map((workspace) => ({
                  key: workspace.id.toString(),
                  label: workspace.name,
                  type: 'workspace',
                  droppable: currentUser.tags?.some(({ id }) => id === workspace.id) ?? false,
                  // When grouping by workspace, the grouped id is a combination of the workspace id and the task is as
                  // a task may be present in multiple workspaces
                  groupedId: `${workspace.id}-${task.id}`,
              }))
            : emptyGroup('', t`No workspace`, { type: 'workspace' })
    );

const defaultDateGroup = (key: string, label: string, properties?: GroupProperties): Group => ({
    ...properties,
    key,
    label,
    type: 'default_date',
    tasks: [],
});

const allTasksGroups = (tasks: Task[]): Group[] => [
    {
        key: '',
        label: t`All tasks`,
        tasks: tasks.map((task) => ({ ...task, groupedId: task.id.toString(), originalGroupKey: '', groupKey: '' })),
    },
];

export const useGroupedTasks = (
    tasks: Task[],
    workspaceId: Id,
    checklistId: Id,
    templateId: Id,
    order: TaskOrder,
    hideEmptyGroups = false
): Group[] => {
    const i18nContext = useLingui();
    const [{ status }] = useSearchParams(TasksPageSearchParams);

    const { currentUser } = useCurrentUserContext();
    const { customFields } = useWorkspaceCustomFields(
        workspaceId,
        checklistId,
        ({ type }) => type === 'enum' || type === 'set'
    );
    const { workspaceSections, checklistSections, checklistTemplateSections } = useSections(
        workspaceId,
        checklistId,
        templateId
    );
    const allUsers = useUsers();

    const members = useEntityMembers({ workspaceId, checklistId, templateId });

    return useMemo(
        () => {
            const inverse = (order || '').startsWith('-');
            const orderValue = inverse ? order.slice(1) : order || '';
            // We explicitly do not depend on order, because we only wants to update the groups whenever the tasks change
            // (and tasks change whenever the order change)
            let groupedList;
            switch (orderValue) {
                case 'alphanumeric':
                    groupedList = groupByName(tasks);
                    break;
                case 'default':
                    groupedList = groupByDate(
                        tasks,
                        'default_date',
                        status,
                        i18nContext.i18n.locale,
                        hideEmptyGroups || status.includes('deleted') || status.includes('completed')
                            ? {}
                            : {
                                  today: defaultDateGroup('today', t`Today`),
                                  tomorrow: defaultDateGroup('tomorrow', t`Tomorrow`),
                                  later: defaultDateGroup('later', t`Later`),
                                  someday: defaultDateGroup('someday', t`Someday`),
                              }
                    );
                    break;
                case 'due_date':
                    groupedList = groupByDate(tasks, 'due_date', status, i18nContext.i18n.locale);
                    break;
                case 'planned_date':
                    groupedList = groupByDate(tasks, 'planned_date', status, i18nContext.i18n.locale);
                    break;
                case 'priority':
                    groupedList = groupByPriority(tasks);
                    break;
                case 'section':
                    groupedList =
                        workspaceId != null
                            ? groupByWorkspaceSection(tasks, workspaceId, workspaceSections)
                            : checklistId != null
                              ? groupByChecklistSection(tasks, checklistSections)
                              : templateId != null
                                ? groupByChecklistSection(tasks, checklistTemplateSections)
                                : allTasksGroups(tasks);
                    break;
                case 'status':
                    groupedList = groupByStatus(tasks);
                    break;
                case 'tag':
                    groupedList = groupByWorkspace(tasks, currentUser);
                    break;
                case 'user':
                    groupedList = groupByUser(
                        tasks,
                        allUsers.filter(
                            (user: User) =>
                                members.some((member) => member.id === user.id) ||
                                tasks.some(({ assignee_id }) => assignee_id === user.id)
                        ),
                        hideEmptyGroups
                    );
                    break;
                default:
                    groupedList = orderValue.startsWith(CustomFieldPrefix)
                        ? groupByCustomFields(tasks, orderValue.substring(CustomFieldPrefix.length), customFields)
                        : allTasksGroups(tasks);
            }
            return inverse ? groupedList.reverse() : groupedList;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            order,
            tasks,
            customFields,
            workspaceId,
            workspaceSections,
            checklistId,
            templateId,
            checklistSections,
            checklistTemplateSections,
            currentUser,
        ]
    );
};
