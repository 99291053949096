import { VirtualElement } from '@floating-ui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useMemo } from 'react';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Editor, Node, Path, Point, Range as SlateRange, Transforms } from 'slate';
import { search, tokenize } from 'ss-search';
import { create } from 'zustand';
import { Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { preventDefault, tryOrValue, wordAt } from '@wedo/utils';
import { getUsers } from 'App/store/usersStore';
import { UserItem } from 'Shared/components/user/UserPicker/UserItem';
import { User } from 'Shared/types/user';
import { Plugin, useEditorIsFocused } from '../Editor';
import { Popover } from '../components/Popover';

const Mention = 'mention';

const Prefix = '@';

type MentionsStore = {
    origin: {
        range: Range;
        point: Point;
    };
    filter: string;
    filteredUsers: User[];
    selectedUser: User;
    reset: () => void;
};

const useMentionsStore = create<MentionsStore>()((set) => ({
    origin: null,
    filter: '',
    filteredUsers: null,
    selectedUser: null,
    reset: () => set(() => ({ origin: null, filter: '', filteredUsers: null, selectedUser: null })),
}));

const filterUser = (users: User[], filter: string) =>
    search(users, ['full_name', 'first_name', 'last_name', 'email_address', 'initials'], filter) as User[];

const selectedWord = (editor: Editor): [string, SlateRange] => {
    const selection = editor.selection ?? {
        anchor: useMentionsStore.getState().origin.point,
        focus: useMentionsStore.getState().origin.point,
    };
    const { anchor, focus } = selection;
    const [node, path] = Editor.node(editor, selection);
    const value = Node.string(node);
    const start = Point.isBefore(focus, anchor) ? focus : anchor;
    const end = Point.isAfter(anchor, focus) ? anchor : focus;
    const [startWord, startOffset, endOffset] = wordAt(value, start.offset);
    const [endWord] = wordAt(value, end.offset);
    return startWord !== endWord || !startWord.startsWith(Prefix)
        ? [null, null]
        : [
              startWord,
              Editor.range(editor, { anchor: { path, offset: startOffset }, focus: { path, offset: endOffset } }),
          ];
};

const insertMention = (editor: Editor, user = useMentionsStore.getState()?.selectedUser) => {
    const state = useMentionsStore.getState();
    const [word, range] = selectedWord(editor);
    if (word != null) {
        Transforms.select(editor, range);
        Transforms.insertNodes(editor, {
            type: 'mention',
            userId: user.id,
            userFullName: user.full_name,
            children: [{ text: '' }],
        });
        Transforms.move(editor);
    }
    state.reset();
    return user;
};

const MentionElement = ({ element, children, attributes }: RenderElementProps) => {
    const focused = useEditorIsFocused();
    const selected = useSelected();

    return (
        <div className="inline-block" {...attributes}>
            <span
                contentEditable={false}
                className={clsx(
                    'rounded-md border border-gray-300 bg-white px-2 py-1',
                    focused && selected && 'ring-2 ring-blue-600 ring-offset-2'
                )}
            >
                {element.userFullName}
            </span>
            {children}
        </div>
    );
};

type MentionsElementProps = {
    onMention: (user: User) => void;
};

const MentionsElement = ({ onMention }: MentionsElementProps) => {
    const editor = useSlateStatic();
    const parentRef = React.useRef<HTMLDivElement>();

    const origin = useMentionsStore((state) => state.origin);
    const filter = useMentionsStore((state) => state.filter);
    const filteredUsers = useMentionsStore((state) => state.filteredUsers);
    const selectedUser = useMentionsStore((state) => state.selectedUser);

    const referenceElement = useMemo<VirtualElement>(() => {
        const rect = origin?.range.getBoundingClientRect();
        return {
            getBoundingClientRect: () => {
                // TODO Need to handle scroll
                return rect;
            },
        };
    }, [origin]);

    const handleScrollIntoView = (userId: Id) => (element: HTMLButtonElement & HTMLAnchorElement) => {
        if (element != null && userId === selectedUser?.id) {
            element.scrollIntoView({ block: 'nearest', inline: 'start' });
        }
    };

    const handleClick = async (user: User) => {
        onMention(insertMention(editor, user));
    };

    const getUser = (index: number) => filteredUsers?.[index];

    const rowVirtualizer = useVirtualizer({
        count: filteredUsers?.length ?? 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 35,
        getItemKey: (index) => getUser(index)?.id ?? index,
    });

    return (
        origin != null && (
            <Popover referenceElement={referenceElement} yOffset={4} className="z-20">
                <div className="scrollbar-light flex max-h-[23.625rem] w-48 snap-y flex-col gap-0.5 overflow-y-auto overflow-hidden">
                    {filteredUsers == null ? (
                        <div className="flex flex-col gap-0.5">
                            <Skeleton count={5} className="h-9" />
                        </div>
                    ) : filteredUsers.length === 0 ? (
                        <div className="flex h-9 items-center p-1 text-sm">
                            <Trans>No users found</Trans>
                        </div>
                    ) : (
                        <div ref={parentRef} className="overflow-y-auto scrollbar-light">
                            <div className="w-full relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                                {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                                    <div
                                        role="button"
                                        onMouseDown={preventDefault()}
                                        onKeyDown={preventDefault()}
                                        tabIndex={0}
                                        onClick={() => handleClick(getUser(virtualItem.index))}
                                        key={virtualItem.key}
                                        className="absolute w-full top-0 left-0"
                                        style={{
                                            height: `${virtualItem.size}px`,
                                            transform: `translateY(${virtualItem.start}px)`,
                                        }}
                                    >
                                        <UserItem
                                            ref={handleScrollIntoView(getUser(virtualItem.index).id)}
                                            user={getUser(virtualItem.index)}
                                            onUserSelected={() => handleClick(getUser(virtualItem.index))}
                                            className="w-full items-center pl-2"
                                            isSelected={getUser(virtualItem.index)?.id === selectedUser?.id}
                                            searchWords={tokenize(filter)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Popover>
        )
    );
};

type FocusedMentionsElementProps = {
    onMention: (user: User) => void;
};

const FocusedMentionsElement = ({ onMention }: FocusedMentionsElementProps) => {
    const focused = useEditorIsFocused();
    return focused && <MentionsElement onMention={onMention} />;
};

export const mentionsPlugin = (onMention: (user: User) => void): Plugin => ({
    onChange: (editor) => {
        const state = useMentionsStore.getState();

        if (state.origin != null) {
            // If, after the editor changes, there is no selection or if the selection is different from the original
            // path, we hide the command menu
            if (
                editor.selection == null ||
                !Path.equals(editor.selection.anchor.path, state.origin.point.path) ||
                !Path.equals(editor.selection.focus.path, state.origin.point.path)
            ) {
                state.reset();
                return false;
            }

            const [word] = selectedWord(editor);

            if (word == null) {
                state.reset();
                return false;
            }

            const filter = word.substring(1);

            const filteredUsers = filterUser(getUsers(), filter);
            // If the previous time we filter the users there were no results, and, if again, there is still no
            // results, we hide the users menu
            if (filteredUsers.length === 0 && state.filteredUsers.length === 0) {
                state.reset();
            } else {
                useMentionsStore.setState(({ selectedUser }) => ({
                    filter,
                    filteredUsers,
                    // If the selected user is not from the filtered users anymore, select the first user in the
                    // list
                    selectedUser: filteredUsers.some(({ id }) => id === selectedUser?.id)
                        ? selectedUser
                        : filteredUsers[0],
                }));
            }
        }

        return false;
    },
    onBlur: () => {
        const state = useMentionsStore.getState();
        if (state.origin != null) {
            state.reset();
            return true;
        }
        return false;
    },
    onKeyDown: (editor, event) => {
        const state = useMentionsStore.getState();
        if (state.origin != null) {
            if (event.key === 'Escape' || event.key === 'Space') {
                state.reset();
            } else if (event.key === 'ArrowDown' && state.filteredUsers.length > 1) {
                event.preventDefault();
                useMentionsStore.setState(({ filteredUsers, selectedUser }) => ({
                    selectedUser:
                        filteredUsers[
                            (filteredUsers.findIndex(({ id }) => id === selectedUser.id) + 1) % filteredUsers.length
                        ],
                }));
            } else if (event.key === 'ArrowUp' && state.filteredUsers.length > 1) {
                event.preventDefault();
                useMentionsStore.setState(({ filteredUsers, selectedUser }) => ({
                    selectedUser:
                        filteredUsers[
                            (filteredUsers.findIndex(({ id }) => id === selectedUser.id) - 1 + filteredUsers.length) %
                                filteredUsers.length
                        ],
                }));
            } else if (event.key === 'Enter' && state.selectedUser != null) {
                event.preventDefault();
                onMention(insertMention(editor));
            }
            return true;
        }
        if (event.key === Prefix) {
            const { anchor, focus } = editor.selection;
            const start = Point.isBefore(focus, anchor) ? focus : anchor;
            const end = Point.isAfter(anchor, focus) ? anchor : focus;
            const beforePoint = Editor.before(editor, start, { unit: 'character' });
            const afterPoint = Editor.after(editor, end, { unit: 'character' });
            const before = tryOrValue(() => Editor.string(editor, { anchor: beforePoint, focus: start }), ' ');
            const after = tryOrValue(() => Editor.string(editor, { anchor: end, focus: afterPoint }), ' ');

            // We only display the mentions menu if the @ character is surrounded by spaces
            if (
                (before === ' ' || !Path.equals(beforePoint.path, start.path)) &&
                (after === ' ' || !Path.equals(afterPoint.path, end.path))
            ) {
                useMentionsStore.setState({
                    filteredUsers: filterUser(getUsers(), useMentionsStore.getState().filter),
                    origin: { range: ReactEditor.toDOMRange(editor, Editor.range(editor, start, end)), point: start },
                });
            }
            return true;
        }
        return false;
    },
    render: () => <FocusedMentionsElement key="FocusedMentionsElement" onMention={onMention} />,
    isVoid: (_, element) => element.type === Mention,
    isInline: (_, element) => element.type === Mention,
    renderElement: (_, { element, children, attributes }) =>
        element.type === Mention && (
            <MentionElement element={element} attributes={attributes}>
                {children}
            </MentionElement>
        ),
});
