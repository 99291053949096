import { i18n, Messages } from '@lingui/core';
import { detect, fromNavigator } from '@lingui/detect-locale';
import { getDateFnsLocale, storage } from '@wedo/utils';
import { LocalStorage } from 'Shared/types/localStorage';

const getLocaleFromLanguageCode = (languageCode: string): string => {
    const lang = languageCode.split('-')[0];

    if (['fr', 'de', 'it'].includes(lang)) {
        return lang;
    }

    return 'en';
};

const defaultLangFallback = () => 'en';

const detectLocale = () => detect(storage.getItem(LocalStorage.Language), fromNavigator(), defaultLangFallback);

type UserLanguageProps = {
    setUserLanguage: (lang: string) => void;
    dynamicActivate: () => Promise<void>;
    userLanguage: Locale;
};

export const useUserLanguage = (): UserLanguageProps => {
    const setUserLanguage = (language: string) => {
        storage.setItem(LocalStorage.Language, language);
        window.dispatchEvent(new Event(`storage/${LocalStorage.Language}`));
    };

    const dynamicActivate = async () => {
        const languageCode = getDateFnsLocale(detectLocale());
        const locale = getLocaleFromLanguageCode(languageCode.code);

        const { messages }: { messages: Messages } = await import(`../../../../../locales/locale-${locale}.ts`);
        i18n.load(languageCode.code, messages);
        i18n.activate(languageCode.code);
    };

    return { setUserLanguage, dynamicActivate, userLanguage: getDateFnsLocale(detectLocale()) };
};
