import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Modal, Steps } from '@wedo/design-system';

type ImportModalLayoutProps = { children: React.ReactNode | React.ReactNode[]; step: number };

export const ImportModalBody: FC<ImportModalLayoutProps> = ({ children, step }) => (
    <>
        <Modal.Header title={t`Import users`} />
        <Steps className={'!rounded-none'}>
            <Steps.Step index={1} currentIndex={step}>
                <Trans>Choose a file</Trans>
            </Steps.Step>
            <Steps.Step index={2} currentIndex={step}>
                <Trans>Verify and validate</Trans>
            </Steps.Step>
            <Steps.Step index={3} currentIndex={step} last>
                <Trans>Result</Trans>
            </Steps.Step>
        </Steps>
        <Modal.Body>
            <div className="flex h-full grow flex-col">{children}</div>
        </Modal.Body>
    </>
);

export const ImportModalFooter: FC<ImportModalLayoutProps> = ({ children }) => <Modal.Footer>{children}</Modal.Footer>;
