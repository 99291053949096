import { createContext, useContext } from 'react';
import { FormLayout } from './Form';

type FormContext = {
    layout: FormLayout;
};

export const FormContext = createContext<FormContext | undefined>(undefined);

export function useFormContext(): FormContext {
    const context = useContext(FormContext);

    if (!context) {
        throw new Error('useFormContext should be used within the FormContext provider!');
    }

    return context;
}
