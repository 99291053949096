import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Children, cloneElement, FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

type AccordionItemProps = {
    children: ReactNode[] | ReactNode;
    title: ReactNode;
    actions?: ReactNode;
    isOpen?: boolean;
    onClick?: () => void;
    className?: string;
};

const AccordionItem: FC<AccordionItemProps> = ({
    children,
    title,
    actions = null,
    isOpen: openProp = null,
    onClick = null,
    className,
}) => {
    const contentRef = useRef<HTMLDivElement>();
    const isOpenRef = useRef<boolean>();

    const [internalOpen, setInternalOpen] = useState(false);
    const [isButtonHovered, setIsButtonHovered] = useState(false);

    const isOpen = openProp ?? internalOpen;

    const handleClick = () => {
        setInternalOpen(!internalOpen);
        onClick?.();
    };

    const handleTransitionEnd = () => {
        if (!isOpen) {
            contentRef.current.style.display = 'none';
        }
    };

    useEffect(() => {
        isOpenRef.current = isOpen;
        if (isOpen) {
            contentRef.current.style.display = 'block';
        }
        contentRef.current.style.height = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
        contentRef.current.style.opacity = isOpen ? '1' : '0';
    }, [isOpen]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (!isOpenRef.current) {
                return;
            }
            contentRef.current.style.height = 'auto';
            contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
        });
        observer.observe(contentRef.current, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, []);

    return (
        <div className="rounded-md border border-gray-200">
            <h1
                className={clsx(
                    'flex w-full items-center bg-gray-100 text-left font-medium text-gray-800',
                    isButtonHovered && (openProp === null || onClick !== null) && 'hover:bg-gray-200'
                )}
            >
                <button
                    onMouseEnter={() => setIsButtonHovered(true)}
                    onMouseLeave={() => setIsButtonHovered(false)}
                    className={clsx(
                        'flex grow items-center gap-2 rounded-md py-2 pl-4 text-left',
                        openProp !== null && onClick === null && 'cursor-default',
                        onClick !== null &&
                            'focus:outline-none focus:ring-blue-600 focus:ring-offset-2 focus-visible:ring-2'
                    )}
                    aria-expanded={isOpen}
                    onClick={handleClick}
                >
                    <FontAwesomeIcon
                        className={clsx(
                            'h-3 w-3 transition-transform duration-150 motion-reduce:transition-none',
                            isOpen && 'rotate-90'
                        )}
                        icon={faChevronRight}
                    />{' '}
                    <span className="grow">{title}</span>
                </button>
                {actions !== null && <div className="mx-3">{actions}</div>}
            </h1>
            <div
                ref={contentRef}
                className={clsx(
                    `w-full overflow-hidden transition-[height_opacity] duration-150 motion-reduce:transition-none`
                )}
                onTransitionEnd={handleTransitionEnd}
            >
                <div className={clsx('px-4 py-2', className)}>{children}</div>
            </div>
        </div>
    );
};

const AccordionComponent: FC<{
    children: ReactElement<AccordionItemProps> | ReactElement<AccordionItemProps>[];
    defaultOpenItem?: number;
    className?: string;
}> = ({ children, className, defaultOpenItem }) => {
    const [openItem, setOpenItem] = useState<number>(defaultOpenItem ?? null);

    const handleClick = (index: number) => {
        setOpenItem(openItem === index ? null : index);
    };

    return (
        <div className={clsx('flex flex-col gap-2', className)}>
            {Children.map(Children.toArray(children).filter(Boolean), (child, index) =>
                cloneElement(child, { key: index, isOpen: openItem === index, onClick: () => handleClick(index) })
            )}
        </div>
    );
};

export const Accordion = Object.assign(AccordionComponent, { Item: AccordionItem });
