import React from 'react';
import { faEllipsisV, faPen, faTrash, faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Dropdown, useModal } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { AddNewEmailModal } from 'Pages/settings/emails/AddNewEmailModal/AddNewEmailModal';
import { useDeleteEmailModal } from 'Pages/settings/emails/DeleteEmailModal/useDeleteEmailModal';
import { useSetPrimaryEmailModal } from 'Pages/settings/emails/SetPrimaryEmailModal/useSetPrimaryEmailModal';
import { useUserEmails } from 'Shared/hooks/useUserEmails';
import { UserEmail } from 'Shared/types/user-email';

interface EmailOptionsDropdownProps {
    email: UserEmail;
}

export const EmailOptionsDropdown: React.FC<EmailOptionsDropdownProps> = ({ email }) => {
    const { open } = useModal();
    const { open: openDeleteEmailModal } = useDeleteEmailModal();
    const { open: openSetPrimaryEmailModal } = useSetPrimaryEmailModal();
    const { isPrimaryEmail } = useUserEmails();
    const { currentUser } = useCurrentUserContext();
    const isNetworkSaml = currentUser.userNetwork.network.isSaml;

    return (
        <Dropdown
            icon={faEllipsisV}
            variant="outlined"
            size="sm"
            data-testid={`email-dropdown-button-${email.email_address}`}
        >
            {!email.verified && (
                <Dropdown.Item onClick={() => open(AddNewEmailModal, { userEmail: email })} icon={faPen}>
                    <Trans>Verify email</Trans>
                </Dropdown.Item>
            )}
            {!isNetworkSaml && (
                <>
                    <Dropdown.Item
                        onClick={() => openSetPrimaryEmailModal(email)}
                        disabled={isPrimaryEmail(email) || !email.verified}
                        icon={faUserShield}
                    >
                        <Trans>Set as primary email</Trans>
                    </Dropdown.Item>

                    <Dropdown.DividerItem />
                </>
            )}
            <Dropdown.Item
                onClick={() => openDeleteEmailModal(email)}
                disabled={isPrimaryEmail(email)}
                icon={faTrash}
                danger
            >
                <Trans>Delete</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};
