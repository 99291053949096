import { RenderLeafProps } from 'slate-react';
import clsx from 'clsx';
import { getColorId } from '@wedo/design-system';

export const DefaultLeaf = ({ attributes, leaf, children }: RenderLeafProps) => {
    const backgroundColorId = getColorId(leaf.backgroundColor, null);
    return (
        <span
            {...attributes}
            style={{
                color: leaf.color,
                backgroundColor:
                    leaf.backgroundColor != null && backgroundColorId == null ? leaf.backgroundColor : undefined,
                fontWeight: leaf.bold ? 700 : undefined,
                fontStyle: leaf.italic ? 'italic' : undefined,
                textDecoration: [leaf.underlined && 'underline', leaf.strikethrough && 'line-through']
                    .filter(Boolean)
                    .join(' '),
            }}
            className={clsx(leaf.backgroundColor != null && backgroundColorId != null && `bg-${backgroundColorId}-200`)}
        >
            {children}
        </span>
    );
};
