import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Card } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { User } from 'Shared/types/user';

export const Leaderboard = ({ users }: { users: User & { badgeScore: number }[] }) => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const currentUser = useSessionUser();
    return (
        <div className="overflow-y-auto flex flex-col gap-2 pb-2 pr-2">
            {(users || []).map((user, index) => (
                <Card
                    onClick={() =>
                        user.id === currentUser.id ? navigate(`/badges`) : navigate(`/users/${user.id}/badges`)
                    }
                    key={user.id}
                    className={clsx(
                        'cursor-pointer hover:!bg-blue-100 transition-all',
                        ((userId != null && user.id === userId) || (userId == null && currentUser.id === user.id)) &&
                            '!bg-blue-200',
                        index === 0 ? 'text-sm' : 'text-sm'
                    )}
                >
                    <Card.Body className="!p-2 !px-4 flex items-center justify-between flex-row min-w-0 gap-2 max-w-full ">
                        <div className="flex-row flex items-center gap-2 max-w-full min-w-0">
                            <div
                                className={clsx(
                                    'font-bold text-center order-1',
                                    [0, 1, 2].includes(index) ? 'text-3xl -ml-0.5' : 'w-6'
                                )}
                            >
                                {index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : index + 1}
                            </div>
                            <div className="flex items-center justify-between order-2  max-w-full min-w-0">
                                <div className="flex items-center gap-2 min-w-0 shrink">
                                    <UserAvatar showTooltip={false} user={user} size={'md'} />
                                    <div
                                        className={clsx(
                                            index === 0 ? 'font-bold' : 'font-medium',
                                            'block truncate shrink min-w-0'
                                        )}
                                    >
                                        {user.full_name}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="font-bold text-sm shrink-0">
                            <Trans>{user.badgeScore} pts</Trans>
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
};
