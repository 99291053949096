import React from 'react';
import { OptionsModalContent } from 'Pages/settings/customFields/components/CustomFieldModalContent/OptionsModalContent/OptionsModalContent';
import { CustomFieldModalSectionProps } from 'Pages/settings/customFields/types/customFieldModalSectionProps';
import { CustomFieldType, MultipleChoiceCustomField } from 'Shared/types/customField';

type MultipleChoiceSectionProps = CustomFieldModalSectionProps<MultipleChoiceCustomField>;

export const MultipleChoiceModalForm: React.FC<MultipleChoiceSectionProps> = ({
    onCancel,
    onSuccess,
    field,
    groupId,
}) => {
    return (
        <OptionsModalContent
            onCancel={onCancel}
            onSuccess={onSuccess}
            field={field}
            fieldType={CustomFieldType.MultipleChoice}
            groupId={groupId}
        />
    );
};
