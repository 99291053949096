import React, { useContext, useMemo } from 'react';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { useCurrentUserVote } from 'Pages/meeting/components/Vote/VoteHooks';
import { RatingChart } from 'Pages/meeting/components/Vote/VoteRatingChart';
import { VoteRatingLinearScaleStats } from 'Pages/meeting/components/Vote/VoteRatingLinearScaleStats';
import { VoteRatingStars } from 'Pages/meeting/components/Vote/VoteRatingStars';
import { getVoteAverage } from 'Pages/meeting/components/Vote/VoteUtils';
import { MeetingCardContainerContext } from 'Shared/components/meeting/MeetingCardContainerContext';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { Vote } from 'Shared/types/vote';

interface VoteBodyRatingProps {
    vote: Vote;
    voteOptionMode: string;
    readOnly: boolean;
    canVote: boolean;
    isChangingAnswer: boolean;
    onChange: (value) => void;
    selectedOptionId?: Id;
    meetingId: Id;
}

export const VoteBodyRating = ({
    vote,
    voteOptionMode,
    isChangingAnswer,
    readOnly,
    canVote,
    onChange,
    selectedOptionId,
    meetingId,
}: VoteBodyRatingProps) => {
    const containerRef = useContext(MeetingCardContainerContext);
    const containerSize = useElementSize(containerRef);
    const { meeting } = useMeeting(meetingId);

    const currentUserVote = useCurrentUserVote(vote);

    const selectedRating = useMemo(
        () =>
            parseInt(
                vote?.voteOptions?.find(
                    (option) => option.id === (currentUserVote ? currentUserVote.vote_option_id : selectedOptionId)
                )?.value || '0',
                10
            ),
        [vote?.voteOptions, selectedOptionId, currentUserVote]
    );

    const shouldDisplayResults = useMemo(() => {
        return (
            vote?.status === 'closed' ||
            meeting?.status === 'locked' ||
            (currentUserVote != null &&
                !isChangingAnswer &&
                meeting?.meetingUsers.some((mu) => mu.can_vote) &&
                (vote?.voteAnswers?.filter((s) => s.status === 'voted') || []).length > 0) ||
            (!meeting?.meetingUsers.some((mu) => mu.can_vote) &&
                vote?.voteOptions?.some((option) => option.vote_count > 0))
        );
    }, [
        vote?.status,
        vote?.voteAnswers,
        vote?.voteOptions,
        meeting?.status,
        meeting?.meetingUsers,
        currentUserVote,
        isChangingAnswer,
    ]);

    return (
        <div className={'mb-4'} onClick={(e) => e.stopPropagation()}>
            <RatingChart
                size={
                    containerSize?.width >= getBreakpointValue('md')
                        ? 'md'
                        : containerSize?.width >= getBreakpointValue('sm')
                          ? 'sm'
                          : null
                }
                hidden={!shouldDisplayResults}
                vote={vote}
                meeting={meeting}
            />
            {isChangingAnswer || !shouldDisplayResults ? (
                <VoteRatingStars
                    vote={vote}
                    value={selectedRating}
                    onChange={(value) =>
                        onChange(vote?.voteOptions?.find((option) => option.value === value.toString())?.id)
                    }
                    size={
                        containerSize?.width >= getBreakpointValue('md')
                            ? 'md'
                            : containerSize?.width >= getBreakpointValue('sm')
                              ? 'sm'
                              : null
                    }
                    disabled={
                        vote?.status === 'closed' ||
                        meeting?.status === 'locked' ||
                        meeting?.deleted ||
                        readOnly ||
                        !canVote ||
                        (currentUserVote && !isChangingAnswer)
                    }
                />
            ) : (
                <VoteRatingStars
                    size={
                        containerSize?.width >= getBreakpointValue('md')
                            ? 'md'
                            : containerSize?.width >= getBreakpointValue('sm')
                              ? 'sm'
                              : null
                    }
                    allowHalf={true}
                    vote={vote}
                    value={getVoteAverage(vote, meeting)}
                    disabled={true}
                />
            )}
            {shouldDisplayResults && (
                <div />
                // TODO
                // <StyledRateStarShares
                //     css={{
                //         opacity: voteOptionMode === 'vote' || isChangingAnswer ? 0 : 1,
                //         height: voteOptionMode === 'vote' || isChangingAnswer ? 0 : 'auto',
                //     }}
                //     size={
                //         containerSize?.width >= getBreakpointValue('md')
                //             ? 'md'
                //             : containerSize?.width >= getBreakpointValue('sm')
                //             ? 'sm'
                //             : null
                //     }
                // >
                //     {vote?.voteOptions?.map((option) => {
                //         const shares = getOptionCount({
                //             voteOption: option,
                //             vote,
                //             meetingUsers: meeting?.meetingUsers,
                //         });
                //         return (
                //             <StyledRateStarShare key={option.value}>
                //                 {shares != null && getFormattedNumber(shares, i18n.locale)}
                //             </StyledRateStarShare>
                //         );
                //     })}
                // </StyledRateStarShares>
            )}
            {shouldDisplayResults && (
                <VoteRatingLinearScaleStats
                    vote={vote}
                    meeting={meeting}
                    hide={voteOptionMode === 'vote' || isChangingAnswer}
                />
            )}
        </div>
    );
};
