import { createContext, ReactNode } from 'react';
import { AlertIcons } from '~/components/Alert';
import { EmptyFunction } from '@wedo/utils';

export type NotificationData = {
    id: string;
    type: keyof typeof AlertIcons;
    title: ReactNode;
    message: ReactNode;
    delay: number;
};

export type NotificationContextProps = {
    notifications: NotificationData[];
    remove: (id: string) => void;
    add: (notification: Partial<NotificationData>) => void;
    replace: (idPrefix: string, notification: Partial<NotificationData>) => void;
};

export const initialState: NotificationContextProps = {
    notifications: [],
    remove: EmptyFunction,
    add: EmptyFunction,
    replace: EmptyFunction,
};

export const NotificationContext = createContext<NotificationContextProps>(initialState);
