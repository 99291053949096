import { addMilliseconds } from 'date-fns';
import { format, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

export const timezones = {
    'Pacific/Niue': 'Niue',
    'Pacific/Pago_Pago': 'Pago Pago',
    'Pacific/Honolulu': 'Hawaii Time',
    'Pacific/Rarotonga': 'Rarotonga',
    'Pacific/Tahiti': 'Tahiti',
    'Pacific/Marquesas': 'Marquesas',
    'America/Anchorage': 'Alaska Time',
    'Pacific/Gambier': 'Gambier',
    'America/Los_Angeles': 'Pacific Time',
    'America/Tijuana': 'Pacific Time - Tijuana',
    'America/Vancouver': 'Pacific Time - Vancouver',
    'America/Whitehorse': 'Pacific Time - Whitehorse',
    'Pacific/Pitcairn': 'Pitcairn',
    'America/Dawson_Creek': 'Mountain Time - Dawson Creek',
    'America/Denver': 'Mountain Time',
    'America/Edmonton': 'Mountain Time - Edmonton',
    'America/Hermosillo': 'Mountain Time - Hermosillo',
    'America/Mazatlan': 'Mountain Time - Chihuahua, Mazatlan',
    'America/Phoenix': 'Mountain Time - Arizona',
    'America/Yellowknife': 'Mountain Time - Yellowknife',
    'America/Belize': 'Belize',
    'America/Chicago': 'Central Time',
    'America/Costa_Rica': 'Costa Rica',
    'America/El_Salvador': 'El Salvador',
    'America/Guatemala': 'Guatemala',
    'America/Managua': 'Managua',
    'America/Mexico_City': 'Central Time - Mexico City',
    'America/Regina': 'Central Time - Regina',
    'America/Tegucigalpa': 'Central Time - Tegucigalpa',
    'America/Winnipeg': 'Central Time - Winnipeg',
    'Pacific/Galapagos': 'Galapagos',
    'America/Bogota': 'Bogota',
    'America/Cancun': 'America Cancun',
    'America/Cayman': 'Cayman',
    'America/Guayaquil': 'Guayaquil',
    'America/Havana': 'Havana',
    'America/Iqaluit': 'Eastern Time - Iqaluit',
    'America/Jamaica': 'Jamaica',
    'America/Lima': 'Lima',
    'America/Nassau': 'Nassau',
    'America/New_York': 'Eastern Time',
    'America/Panama': 'Panama',
    'America/Port-au-Prince': 'Port-au-Prince',
    'America/Rio_Branco': 'Rio Branco',
    'America/Toronto': 'Eastern Time - Toronto',
    'Pacific/Easter': 'Easter Island',
    'America/Caracas': 'Caracas',
    'America/Asuncion': 'Asuncion',
    'America/Barbados': 'Barbados',
    'America/Boa_Vista': 'Boa Vista',
    'America/Campo_Grande': 'Campo Grande',
    'America/Cuiaba': 'Cuiaba',
    'America/Curacao': 'Curacao',
    'America/Grand_Turk': 'Grand Turk',
    'America/Guyana': 'Guyana',
    'America/Halifax': 'Atlantic Time - Halifax',
    'America/La_Paz': 'La Paz',
    'America/Manaus': 'Manaus',
    'America/Martinique': 'Martinique',
    'America/Port_of_Spain': 'Port of Spain',
    'America/Porto_Velho': 'Porto Velho',
    'America/Puerto_Rico': 'Puerto Rico',
    'America/Santo_Domingo': 'Santo Domingo',
    'America/Thule': 'Thule',
    'Atlantic/Bermuda': 'Bermuda',
    'America/St_Johns': 'Newfoundland Time - St. Johns',
    'America/Araguaina': 'Araguaina',
    'America/Argentina/Buenos_Aires': 'Buenos Aires',
    'America/Bahia': 'Salvador',
    'America/Belem': 'Belem',
    'America/Cayenne': 'Cayenne',
    'America/Fortaleza': 'Fortaleza',
    'America/Godthab': 'Godthab',
    'America/Maceio': 'Maceio',
    'America/Miquelon': 'Miquelon',
    'America/Montevideo': 'Montevideo',
    'America/Paramaribo': 'Paramaribo',
    'America/Recife': 'Recife',
    'America/Santiago': 'Santiago',
    'America/Sao_Paulo': 'Sao Paulo',
    'Antarctica/Palmer': 'Palmer',
    'Antarctica/Rothera': 'Rothera',
    'Atlantic/Stanley': 'Stanley',
    'America/Noronha': 'Noronha',
    'Atlantic/South_Georgia': 'South Georgia',
    'America/Scoresbysund': 'Scoresbysund',
    'Atlantic/Azores': 'Azores',
    'Atlantic/Cape_Verde': 'Cape Verde',
    'Africa/Abidjan': 'Abidjan',
    'Africa/Accra': 'Accra',
    'Africa/Bissau': 'Bissau',
    'Africa/Casablanca': 'Casablanca',
    'Africa/El_Aaiun': 'El Aaiun',
    'Africa/Monrovia': 'Monrovia',
    'America/Danmarkshavn': 'Danmarkshavn',
    'Atlantic/Canary': 'Canary Islands',
    'Atlantic/Faroe': 'Faeroe',
    'Atlantic/Reykjavik': 'Reykjavik',
    'Etc/GMT': 'Greenwich Mean Time',
    'Europe/Dublin': 'Dublin',
    'Europe/Lisbon': 'Lisbon',
    'Europe/London': 'London',
    'Africa/Algiers': 'Algiers',
    'Africa/Ceuta': 'Ceuta',
    'Africa/Lagos': 'Lagos',
    'Africa/Ndjamena': 'Ndjamena',
    'Africa/Tunis': 'Tunis',
    'Africa/Windhoek': 'Windhoek',
    'Europe/Amsterdam': 'Amsterdam',
    'Europe/Andorra': 'Andorra',
    'Europe/Belgrade': 'Central European Time - Belgrade',
    'Europe/Berlin': 'Berlin',
    'Europe/Brussels': 'Brussels',
    'Europe/Budapest': 'Budapest',
    'Europe/Copenhagen': 'Copenhagen',
    'Europe/Gibraltar': 'Gibraltar',
    'Europe/Luxembourg': 'Luxembourg',
    'Europe/Madrid': 'Madrid',
    'Europe/Malta': 'Malta',
    'Europe/Monaco': 'Monaco',
    'Europe/Oslo': 'Oslo',
    'Europe/Paris': 'Paris',
    'Europe/Prague': 'Central European Time - Prague',
    'Europe/Rome': 'Rome',
    'Europe/Stockholm': 'Stockholm',
    'Europe/Tirane': 'Tirane',
    'Europe/Vienna': 'Vienna',
    'Europe/Warsaw': 'Warsaw',
    'Europe/Zurich': 'Zurich',
    'Africa/Cairo': 'Cairo',
    'Africa/Johannesburg': 'Johannesburg',
    'Africa/Maputo': 'Maputo',
    'Africa/Tripoli': 'Tripoli',
    'Asia/Amman': 'Amman',
    'Asia/Beirut': 'Beirut',
    'Asia/Damascus': 'Damascus',
    'Asia/Gaza': 'Gaza',
    'Asia/Jerusalem': 'Jerusalem',
    'Asia/Kuching': 'Kuching',
    'Asia/Nicosia': 'Nicosia',
    'Europe/Athens': 'Athens',
    'Europe/Bucharest': 'Bucharest',
    'Europe/Chisinau': 'Chisinau',
    'Europe/Helsinki': 'Helsinki',
    'Europe/Istanbul': 'Istanbul',
    'Europe/Kaliningrad': 'Moscow - Kaliningrad',
    'Europe/Kiev': 'Kiev',
    'Europe/Riga': 'Riga',
    'Europe/Sofia': 'Sofia',
    'Europe/Tallinn': 'Tallinn',
    'Europe/Vilnius': 'Vilnius',
    'Africa/Khartoum': 'Khartoum',
    'Africa/Nairobi': 'Nairobi',
    'Antarctica/Syowa': 'Syowa',
    'Asia/Baghdad': 'Baghdad',
    'Asia/Qatar': 'Qatar',
    'Asia/Riyadh': 'Riyadh',
    'Europe/Minsk': 'Minsk',
    'Europe/Moscow': 'Moscow - Moscow',
    'Asia/Tehran': 'Tehran',
    'Asia/Baku': 'Baku',
    'Asia/Dubai': 'Dubai',
    'Asia/Tbilisi': 'Tbilisi',
    'Asia/Yerevan': 'Yerevan',
    'Europe/Samara': 'Moscow - Samara',
    'Indian/Mahe': 'Mahe',
    'Indian/Mauritius': 'Mauritius',
    'Indian/Reunion': 'Reunion',
    'Asia/Kabul': 'Kabul',
    'Antarctica/Mawson': 'Mawson',
    'Asia/Aqtau': 'Aqtau',
    'Asia/Aqtobe': 'Aqtobe',
    'Asia/Ashgabat': 'Ashgabat',
    'Asia/Dushanbe': 'Dushanbe',
    'Asia/Karachi': 'Karachi',
    'Asia/Tashkent': 'Tashkent',
    'Asia/Yekaterinburg': 'Moscow - Yekaterinburg',
    'Indian/Kerguelen': 'Kerguelen',
    'Indian/Maldives': 'Maldives',
    'Asia/Calcutta': 'India Standard Time',
    'Asia/Colombo': 'Colombo',
    'Asia/Katmandu': 'Katmandu',
    'Antarctica/Vostok': 'Vostok',
    'Asia/Almaty': 'Almaty',
    'Asia/Bishkek': 'Bishkek',
    'Asia/Dhaka': 'Dhaka',
    'Asia/Omsk': 'Moscow - Omsk, Novosibirsk',
    'Asia/Thimphu': 'Thimphu',
    'Indian/Chagos': 'Chagos',
    'Asia/Rangoon': 'Rangoon',
    'Indian/Cocos': 'Cocos',
    'Antarctica/Davis': 'Davis',
    'Asia/Bangkok': 'Bangkok',
    'Asia/Hovd': 'Hovd',
    'Asia/Jakarta': 'Jakarta',
    'Asia/Krasnoyarsk': 'Moscow - Krasnoyarsk',
    'Asia/Saigon': 'Hanoi',
    'Asia/Ho_Chi_Minh': 'Ho Chi Minh',
    'Indian/Christmas': 'Christmas',
    'Antarctica/Casey': 'Casey',
    'Asia/Brunei': 'Brunei',
    'Asia/Choibalsan': 'Choibalsan',
    'Asia/Hong_Kong': 'Hong Kong',
    'Asia/Irkutsk': 'Moscow - Irkutsk',
    'Asia/Kuala_Lumpur': 'Kuala Lumpur',
    'Asia/Macau': 'Macau',
    'Asia/Makassar': 'Makassar',
    'Asia/Manila': 'Manila',
    'Asia/Shanghai': 'China Time - Beijing',
    'Asia/Singapore': 'Singapore',
    'Asia/Taipei': 'Taipei',
    'Asia/Ulaanbaatar': 'Ulaanbaatar',
    'Australia/Perth': 'Western Time - Perth',
    'Asia/Pyongyang': 'Pyongyang',
    'Asia/Dili': 'Dili',
    'Asia/Jayapura': 'Jayapura',
    'Asia/Seoul': 'Seoul',
    'Asia/Tokyo': 'Tokyo',
    'Asia/Yakutsk': 'Moscow - Yakutsk',
    'Pacific/Palau': 'Palau',
    'Australia/Adelaide': 'Central Time - Adelaide',
    'Australia/Darwin': 'Central Time - Darwin',
    'Antarctica/DumontDUrville': "Dumont D'Urville",
    'Asia/Magadan': 'Moscow - Magadan',
    'Asia/Vladivostok': 'Moscow - Yuzhno-Sakhalinsk',
    'Australia/Brisbane': 'Eastern Time - Brisbane',
    'Australia/Hobart': 'Eastern Time - Hobart',
    'Australia/Sydney': 'Eastern Time - Melbourne, Sydney',
    'Pacific/Chuuk': 'Truk',
    'Pacific/Guam': 'Guam',
    'Pacific/Port_Moresby': 'Port Moresby',
    'Pacific/Efate': 'Efate',
    'Pacific/Guadalcanal': 'Guadalcanal',
    'Pacific/Kosrae': 'Kosrae',
    'Pacific/Norfolk': 'Norfolk',
    'Pacific/Noumea': 'Noumea',
    'Pacific/Pohnpei': 'Ponape',
    'Asia/Kamchatka': 'Moscow - Petropavlovsk-Kamchatskiy',
    'Pacific/Auckland': 'Auckland',
    'Pacific/Fiji': 'Fiji',
    'Pacific/Funafuti': 'Funafuti',
    'Pacific/Kwajalein': 'Kwajalein',
    'Pacific/Majuro': 'Majuro',
    'Pacific/Nauru': 'Nauru',
    'Pacific/Tarawa': 'Tarawa',
    'Pacific/Wake': 'Wake',
    'Pacific/Wallis': 'Wallis',
    'Pacific/Apia': 'Apia',
    'Pacific/Enderbury': 'Enderbury',
    'Pacific/Fakaofo': 'Fakaofo',
    'Pacific/Tongatapu': 'Tongatapu',
    'Pacific/Kiritimati': 'Kiritimati',
};

/**
 * Returns a "pretty" label from a timezone code
 * @param tz - timezone string
 */
export const getTimezoneLabel = (tz: string): string => {
    const formattedShift = format(utcToZonedTime(new Date(), tz), 'XXX', { timeZone: tz });
    return `(GMT${formattedShift !== 'Z' ? formattedShift : '+00:00'}) ${timezones[tz] ?? tz}`;
};

/**
 * Used to sort timezones by their shift
 * @param tz1 - timezone 1
 * @param tz2 - timezone 2
 */
export const compareTimezones = (tz1: [string, string], tz2: [string, string]): number => {
    const offsetTz1 = getTimezoneOffset(tz1[0], new Date());
    const offsetTz2 = getTimezoneOffset(tz2[0], new Date());

    if (offsetTz1 < offsetTz2) {
        return -1;
    }
    if (offsetTz1 > offsetTz2) {
        return 1;
    }
    return 0;
};

export const sortedTimezones = Object.entries(timezones).sort(compareTimezones);

/** returns the browser's timezone using the Intl object (e.g. "Europe/Zurich") */
export const getUserTimeZone = (): string => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getMeetingDatesAsDate = ({ meeting }) => {
    const startDate: Date = typeof meeting.start_at === 'string' ? new Date(meeting.start_at) : meeting.start_at;
    const endDate: Date = typeof meeting.end_at === 'string' ? new Date(meeting.end_at) : meeting.end_at;
    return { startDate, endDate };
};

export const moveDateToTimezone = ({ date, fromTimezone, toTimezone }): Date => {
    if (fromTimezone === toTimezone) {
        return date;
    }
    const fromTimezoneOffset = getTimezoneOffset(fromTimezone, date);
    const toTimezoneOffset = getTimezoneOffset(toTimezone, date);
    return addMilliseconds(date, toTimezoneOffset - fromTimezoneOffset);
};

export const moveMeetingDatesFromToTimezone = ({ meeting, fromTimezone, toTimezone }) => {
    const { startDate, endDate } = getMeetingDatesAsDate({ meeting });
    const newStartAt = moveDateToTimezone({ date: startDate, fromTimezone: fromTimezone, toTimezone: toTimezone });
    const newEndAt = moveDateToTimezone({ date: endDate, fromTimezone: fromTimezone, toTimezone: toTimezone });
    return { startDate: newStartAt, endDate: newEndAt };
};

export const moveMeetingDatesToMeetingTimezone = ({ meeting }) => {
    const userTimezone = getUserTimeZone();
    const meetingTimezone = meeting?.start_at_time_zone;
    return moveMeetingDatesFromToTimezone({ meeting, fromTimezone: userTimezone, toTimezone: meetingTimezone });
};

export const moveMeetingDatesToUserTimezone = ({ meeting }) => {
    const userTimezone = getUserTimeZone();
    const meetingTimezone = meeting?.start_at_time_zone;
    return moveMeetingDatesFromToTimezone({ meeting, fromTimezone: meetingTimezone, toTimezone: userTimezone });
};
