import React, { FC, ReactNode, useEffect, useRef, useState, JSX } from 'react';
import { useMarker } from 'react-mark.js';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { ScrollableContainer } from '@wedo/design-system';
import { useSearchParams, useIsInViewport } from '@wedo/utils/hooks';
import { CommonSearchPageSearchParams } from 'Pages/SearchPage/SearchPage';

export type InfiniteScrollPageProps = {
    offset: number;
    limit: number;
    updateInfiniteScroll: (data: unknown[]) => void;
};

type InfiniteScrollProps<T = {}> = {
    page: FC<InfiniteScrollPageProps & T>;
    emptyPage?: ReactNode;
    pagesWrapper?: (children: ReactNode) => ReactNode;
    size?: number;
    props?: T;
    className?: string;
    as?: keyof JSX.IntrinsicElements;
    lastItem?: ReactNode;
    isSearchHighlighted?: boolean;
};

export const InfiniteScroll = <T,>({
    size = 10,
    page: Page,
    emptyPage,
    props,
    className,
    isSearchHighlighted = false,
    as,
    lastItem,
}: InfiniteScrollProps<T>) => {
    const bottomOfList = useRef<HTMLDivElement>();

    const [{ search }] = useSearchParams(CommonSearchPageSearchParams);
    const { marker, markerRef } = useMarker<HTMLDivElement>();

    const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
    const [offset, setOffset] = useState<number>(0);
    const [lastComputedOffset, setLastComputedOffset] = useState<number>(-1);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const showLastItem = !canLoadMore || isEmpty;
    const isBottomOfListVisible = useIsInViewport(bottomOfList);

    const updateInfiniteScroll = (offset: number) => (data: unknown[]) => {
        // We are rendering a page when this function is called, that's why we use requestAnimationFrame to render
        // the InfiniteScroll in the next tick
        if (data?.length === 0) {
            if (offset === 0) {
                requestAnimationFrame(() => {
                    setIsEmpty(true);
                    setCanLoadMore(false);
                });
            } else {
                requestAnimationFrame(() => {
                    setCanLoadMore(false);
                });
            }
        } else if (data?.length > 0) {
            requestAnimationFrame(() => {
                setIsEmpty(false);
                setCanLoadMore(data.length >= size);
                setTimeout(() => setLastComputedOffset(offset), 300);
            });
        }
    };

    const loadMore = useDebouncedCallback(() => setOffset(offset + size), 150);

    marker?.unmark();
    if (isSearchHighlighted && search) {
        setTimeout(() => {
            marker?.mark(search, { className: 'bg-blue-200 text-blue-800', exclude: ['.ignore-marker'] });
        }, 300);
    }

    useEffect(() => {
        if (offset > lastComputedOffset) {
            return;
        }
        if (isBottomOfListVisible && canLoadMore) {
            loadMore();
        }
    }, [isBottomOfListVisible, offset, lastComputedOffset]);

    return (
        <div ref={markerRef} className="flex h-full max-h-full flex-col overflow-hidden">
            {isEmpty && emptyPage}
            <ScrollableContainer isVertical as={as} className={clsx(className)}>
                {Array.from(Array(Math.ceil((offset + 1) / size))).map((_, index) => {
                    return (
                        <Page
                            {...props}
                            key={index}
                            offset={index * size}
                            limit={size}
                            updateInfiniteScroll={updateInfiniteScroll(offset)}
                        />
                    );
                })}
                {showLastItem && lastItem}
                <div ref={bottomOfList} />
            </ScrollableContainer>
        </div>
    );
};
