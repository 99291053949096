import React, { FC } from 'react';
import { Select } from '@wedo/design-system';
import { LanguageList, LanguageMap, LanguageCode } from '@wedo/utils';

type LanguageSelectorProps = {
    id?: string;
    placeholder?: string;
    isDisabled?: boolean;
    value: LanguageCode;
    onChange: (value: LanguageCode) => void;
    className?: string;
};

export const LanguageSelector: FC<LanguageSelectorProps> = ({
    id,
    placeholder,
    isDisabled = false,
    value,
    onChange,
    className,
}) => {
    return (
        <Select
            id={id}
            placeholder={placeholder}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
            customRenderSelected={(value: LanguageCode) => LanguageMap.get(value)}
            className={className}
        >
            {LanguageList.map((lang: string) => (
                <Select.Option key={lang} value={lang}>
                    {LanguageMap.get(lang)}
                </Select.Option>
            ))}
        </Select>
    );
};
