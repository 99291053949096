import { formatRelative } from 'date-fns';
import { useDateFunction } from '~/components/FormatDate/useDateFunction';

export type DateLike = Date | number | string;

export const useFormatDateRelative = (
    date: DateLike,
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    }
): string =>
    useDateFunction<string>(date, (date: Date, baseDate: Date, locale?: Locale) =>
        formatRelative(date, baseDate, { ...options, locale })
    );

export type FormatRelativeDateProps = {
    date: DateLike;
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    className?: string;
};

export const FormatDateRelative = ({ date, locale, weekStartsOn, className }: FormatRelativeDateProps): JSX.Element => {
    const text = useFormatDateRelative(date, { locale, weekStartsOn });
    return (
        <time className={className} dateTime={new Date(date).toISOString()}>
            {text}
        </time>
    );
};
