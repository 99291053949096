import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { MeetingRole } from 'Shared/types/meetingRole';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingSettings } from 'Shared/types/meetingSettings';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { MeetingUser } from 'Shared/types/meetingUser';
import { User } from 'Shared/types/user';
import { Workspace } from './workspace';

export enum MeetingStatus {
    PREPARATION = 'preparation',
    AGENDA_READY = 'agenda_ready',
    ONGOING = 'ongoing',
    FINISHED = 'finished',
    LOCKED = 'locked',
}

export enum MeetingStatusExtension {
    WAITING_SIGNATURE = 'waiting_signature',
    SIGNED = 'signed',
    UNLOCKED = 'unlocked',
    RELOCKED = 'relocked',
}

export type MeetingExtendedStatus = MeetingStatus | MeetingStatusExtension;

export enum MeetingPermission {
    VIEW_MEETING_AND_AGENDA = 'viewMeeting',
    VIEW_TOPIC_CONTENT = 'viewTopicContent',
    VIEW_FILES = 'viewFiles',
    EDIT_TOPIC_CONTENT = 'editTopicContent',
    CAN_VOTE = 'canVote',
    MANAGE_TOPIC = 'manageTopic',
    MANAGE_SECTIONS = 'manageSections',
    MANAGE_MEETING = 'manageMeeting',
}

export enum MeetingStateValue {
    CURRENT = 'current',
    PREVIOUS = 'previous',
    PAST = 'past',
    FUTURE = 'future',
    DELETED = 'deleted',
}

export interface Meeting {
    network_id: Id;
    id: Id;
    title: string;
    type: 'singleInstance' | 'occurrence' | 'exception';
    status?: MeetingStatus;
    extendedStatus?: MeetingExtendedStatus;
    start_at: string;
    end_at: string;
    deleted?: boolean;
    tag_id?: Id;
    locked_at?: string;
    locked_by?: Id;
    unlocked_at?: string;
    unlocked_by?: Id;
    series_master_id?: Id;
    recurrence_pattern?: string;
    location?: string;
    start_at_time_zone?: string;
    end_at_time_zone?: string;
    disabledDateBefore?: string;
    settings: Partial<MeetingSettings>;
    // Related
    startAt?: string;
    state?: MeetingStateValue;
    currentMeeting?: Meeting;
    nextMeetings?: Partial<Meeting>[];
    tag?: Workspace;
    workspace?: Workspace;
    seriesMaster?: Meeting;
    topics?: MeetingTopic[];
    sections?: MeetingSection[];
    meetingUsers?: MeetingUser[];
    timeZone?: string;
    // TODO sortRoleByImportance
    roles?: MeetingRole[];
}

const LockedOrDeletedPermissions = [
    MeetingPermission.VIEW_MEETING_AND_AGENDA,
    MeetingPermission.VIEW_FILES,
    MeetingPermission.VIEW_TOPIC_CONTENT,
    MeetingPermission.MANAGE_MEETING,
];

const checkPermission = (meeting: Meeting, meetingRole: MeetingRole, permission: MeetingPermission) => {
    return meetingRole?.permissions[meeting.status]?.includes(permission) ?? false;
};

export const userHasPermission = (
    user: User,
    meeting: Meeting,
    permission: MeetingPermission,
    meetingSectionId?: Id
) => {
    if (user?.id == null || meeting?.id == null) {
        return false;
    }
    if (
        (meeting.status === MeetingStatus.LOCKED || meeting.deleted) &&
        !LockedOrDeletedPermissions.includes(permission)
    ) {
        return false;
    }

    const meetingUser = meeting.meetingUsers?.find((meetingUser) => meetingUser.user_id === user.id);
    if (meetingSectionId != null) {
        const item = meetingUser?.items?.find((item) => item.meeting_section_id === meetingSectionId);
        const meetingSectionRole = meeting.roles?.find((meetingRole) => meetingRole.id === item?.meeting_role_id);
        if (meetingSectionRole != null) {
            return checkPermission(meeting, meetingSectionRole, permission);
        }
    }
    const meetingRole = meeting.roles?.find((meetingRole) => meetingRole.id === meetingUser?.meeting_role_id);
    return checkPermission(meeting, meetingRole, permission);
};

const useUserHasPermission = (user: User, meeting: Meeting, permission: MeetingPermission, meetingSectionId?: Id) => {
    const { isLoading, hasPermission } = useMemo(() => {
        return user?.id == null || meeting?.id == null
            ? {
                  isLoading: true,
                  hasPermission: false,
              }
            : {
                  isLoading: false,
                  hasPermission: userHasPermission(user, meeting, permission, meetingSectionId),
              };
    }, [
        user?.id,
        meeting?.id,
        meeting?.meetingUsers,
        meeting?.roles,
        meeting?.deleted,
        meeting?.status,
        meetingSectionId,
    ]);
    return { hasPermission, isLoading };
};

export const useUserHasMeetingPermission = (user: User, meeting: Meeting, permission: MeetingPermission) => {
    return useUserHasPermission(user, meeting, permission);
};

export const useUserHasMeetingSectionPermission = (
    user: User,
    meeting: Meeting,
    meetingSectionId: Id,
    permission: MeetingPermission
) => {
    return useUserHasPermission(user, meeting, permission, meetingSectionId);
};

export enum UserMeetingPermission {
    Editor = 'editor',
    Participant = 'participant',
    Reader = 'reader',
    NoAccess = 'noAccess',
}
