import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { storage } from '../localStorage';
import { useEvent } from './useEvent';

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useLocalStorage = <T>(key: string, initialValue?: T): [T, SetValue<T>] => {
    const getLocalStorageOrDefaultValue = useCallback((): T => {
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = storage.getItem(key);
            return item != null ? (JSON.parse(item ?? '') as T) : initialValue;
        } catch (error) {
            return initialValue;
        }
    }, [initialValue, key]);

    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(getLocalStorageOrDefaultValue);

    const setValue: SetValue<T> = useCallback((value) => {
        try {
            const newValue = value instanceof Function ? value(storedValue) : value;
            storage.setItem(key, JSON.stringify(newValue));
            setStoredValue(newValue);
            window.dispatchEvent(new Event('local-storage'));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Error setting localStorage key “${key}”:`, error);
        }
    }, []);

    const handleStorageChange = useCallback(
        (event: StorageEvent | CustomEvent) => {
            if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
                return;
            }
            setStoredValue(getLocalStorageOrDefaultValue());
        },
        [key, getLocalStorageOrDefaultValue]
    );

    // initially set the value from local storage
    useEffect(() => setStoredValue(getLocalStorageOrDefaultValue()), []);

    // if value changes in other documents, not this one
    useEvent('storage', handleStorageChange);

    // this is a custom event, triggered in setValue
    useEvent('local-storage', handleStorageChange);

    return [storedValue, setValue];
};
