import { useContext } from 'react';
import { Id } from '@wedo/types';
import { getLocalStorageSearchParams } from '@wedo/utils';
import { useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { LoginResponse, RequireAuthSearchParams } from 'Shared/services/auth';
import { useLazyGetNetworkAuthLinkQuery } from 'Shared/services/network';

export const useLogin = () => {
    const [{ redirect }] = useSearchParams(RequireAuthSearchParams);
    const navigate = useNavigate();

    return async () => {
        void navigate(redirect ?? { pathname: '/tasks', searchParams: getLocalStorageSearchParams('/tasks') });
    };
};

export const useNetworkLogin = () => {
    const [getNetworkAuthLink, { isLoading, error }] = useLazyGetNetworkAuthLinkQuery();

    const login = async (networkId: Id, userId: Id, token: string) => {
        const result = await getNetworkAuthLink({
            networkId: networkId,
            token: token,
        });

        if ('data' in result) {
            const url = new URL(result.data);

            url.searchParams.set('redirect', '/settings/security');
            url.searchParams.set('token', token);
            url.searchParams.set('userId', String(userId));

            window.location.assign(url.href);
        }
    };

    return { login, isLoading, error };
};

export const useGlobalLogin = ({ navigateToNetworksList = false } = {}) => {
    const { updateLoginState } = useContext(LoginContext);
    const { login } = useNetworkLogin();

    return async (loginResponse: LoginResponse) => {
        if (navigateToNetworksList || loginResponse.user.userNetworks?.length > 1) {
            updateLoginState({
                page: 'networkList',
                networks: loginResponse.user.userNetworks.map((userNetwork) => userNetwork.network),
                authToken: loginResponse.authToken,
                user: loginResponse.user,
            });
        } else {
            void login(loginResponse.user.userNetworks[0].network.id, loginResponse.user.id, loginResponse.authToken);
        }
    };
};
