import React from 'react';
import { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { useDeleteCustomFieldGroupMutation } from 'Shared/services/customFields';
import { CustomFieldGroup } from 'Shared/types/customField';

export const useDeleteGroupModal = () => {
    const { confirm } = useConfirm();
    const [deleteGroup] = useDeleteCustomFieldGroupMutation();

    const open = useCallback(
        async (group: CustomFieldGroup) => {
            await confirm({
                type: 'danger',
                title: t`Delete group ${group.label}`,
                content: (
                    <Trans>
                        Are you sure you want to delete the group <span className="bold font-mono">{group.label}</span>?
                    </Trans>
                ),
                confirmText: t`Delete`,
                onConfirm: () => deleteGroup(group.id).unwrap(),
            });
        },
        [confirm, deleteGroup]
    );

    return { open };
};
