import { forwardRef, useRef } from 'react';
import { camelToSnake } from 'caseparser';
import { Editor } from 'slate';
import { useMeetingContext } from 'App/contexts';
import { useSessionUser } from 'App/store/usersStore';
import { InlineTopicSubmission } from 'Shared/components/meeting/topicView/InlineTopicSubmission';
import { TopicSubmissionCard } from 'Shared/components/meeting/topicView/TopicSubmissionCard';
import { trpc } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';

export const TopicSubmission = forwardRef<HTMLDivElement, { topicId: string; isInline?: boolean }>(
    ({ topicId, isInline = false }, ref) => {
        const editorRef = useRef<Editor>();

        const currentUser = useSessionUser();
        const { meeting } = useMeetingContext();

        const { data: topic, isLoading: isLoadingTopic } = trpc.meetingTopic.get.useQuery(topicId, {
            select: camelToSnake,
        });
        const { data: meetingTopicSubmission, isLoading: isLoadingSubmission } =
            trpc.meetingTopic.getSubmissionForTopic.useQuery({ topicId }, { staleTime: 0 });

        const { hasPermission: canManageTopicSubmission } = useUserHasMeetingPermission(
            currentUser,
            meeting,
            MeetingPermission.MANAGE_MEETING
        );
        const isLoading = isLoadingSubmission || isLoadingTopic;

        if (isLoading) {
            return null;
        }
        return (
            <div ref={ref}>
                {isInline ? (
                    <InlineTopicSubmission
                        meeting={meeting}
                        topic={topic}
                        topicSubmission={meetingTopicSubmission}
                        editorRef={editorRef}
                        canEdit={canManageTopicSubmission}
                    />
                ) : (
                    <TopicSubmissionCard
                        meeting={meeting}
                        topic={topic}
                        topicSubmission={meetingTopicSubmission}
                        editorRef={editorRef}
                        canEdit={canManageTopicSubmission}
                    />
                )}
            </div>
        );
    }
);
