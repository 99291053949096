import { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { type Setting, type Store } from '@wedo/pdf/meeting/store';
import { serializableStorageKey, useSetting } from './store';

type ResetButtonProps = {
    store: Store;
    property: Setting;
    isDisabled: boolean;
    onReset: () => void;
};

export const ResetButton = ({ store, property, isDisabled, onReset }: ResetButtonProps) => {
    const value = useSetting(store, property);

    const isResettable = useMemo(() => {
        const storageKey = serializableStorageKey(store.getState().meeting.id, property);
        if (storageKey != null) {
            const settings = JSON.parse(localStorage.getItem(storageKey) ?? '{}');
            return settings[property] != null;
        }
        return false;
    }, [value]);

    return (
        isResettable && (
            <Button disabled={isDisabled} variant="link" onClick={onReset}>
                <Trans>Reset</Trans>
            </Button>
        )
    );
};
