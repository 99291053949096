import { MouseEvent } from 'react';
import { ButtonProps } from '@wedo/design-system';
import store from 'App/store';
import { FavoriteToggleButton } from 'Shared/components/button/FavoriteToggleButton';
import { FavoriteToggleDropdownItem } from 'Shared/components/button/FavoriteToggleDropdownItem';
import { invalidateTeams } from 'Shared/services/team';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Team } from 'Shared/types/team';

const useTeamFavoriteToggle = (team: Team) => {
    const { mutateAsync: toggleFavorite, isLoading } = trpc.team.toggleFavorite.useMutation({
        onSuccess: () =>
            Promise.all([
                store.dispatch(invalidateTeams()),
                trpcUtils().team.list.invalidate(),
                trpcUtils().template.list.invalidate(),
                trpcUtils().workspace.list.invalidate(),
            ]),
    });

    const isFavorite = team?.is_favorite;

    const handleTeamFavoriteToggle = (event: MouseEvent) => {
        event.stopPropagation();
        void toggleFavorite({ teamId: team.id });
    };

    return { team, isLoading, isFavorite, handleTeamFavoriteToggle };
};

type TeamFavoriteProps = {
    team: Team;
};

type TeamFavoriteButtonProps = TeamFavoriteProps & {
    size?: ButtonProps['size'];
};

const TeamFavoriteButton = ({ team, size = 'sm' }: TeamFavoriteButtonProps) => {
    const { isFavorite, isLoading, handleTeamFavoriteToggle } = useTeamFavoriteToggle(team);

    return (
        <FavoriteToggleButton
            isFavorite={isFavorite}
            onClick={handleTeamFavoriteToggle}
            isLoading={isLoading}
            size={size}
        />
    );
};

const TeamFavoriteDropdownItem = ({ team }: TeamFavoriteProps) => {
    const { isFavorite, isLoading, handleTeamFavoriteToggle } = useTeamFavoriteToggle(team);

    return (
        <FavoriteToggleDropdownItem isFavorite={isFavorite} onClick={handleTeamFavoriteToggle} isLoading={isLoading} />
    );
};

export const TeamFavoriteToggle = Object.assign(
    {},
    { Button: TeamFavoriteButton, DropdownItem: TeamFavoriteDropdownItem }
);
