import { Id } from '@wedo/types';
import { storage } from '@wedo/utils';

export const ZUSTAND_STORAGE = {
    getItem: (name: string) => {
        const str = storage.getItem(name);
        const object = JSON.parse(str);
        return {
            state: {
                ...object.state,
                selectedColumns: new Set(JSON.parse(str).state.selectedColumns),
            },
            version: object.version,
        };
    },

    setItem: (name: string, newValue: { state: any; version: Id }) => {
        const str = JSON.stringify({
            state: {
                ...newValue.state,
                selectedColumns: Array.from(newValue.state.selectedColumns.keys()),
            },
            version: newValue.version,
        });

        storage.setItem(name, str);
    },

    removeItem: (name: string) => {
        storage.removeItem(name);
    },
};
