import { useLingui } from '@lingui/react';
import { useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash-es';
import { DAY_OF_WEEK_TO_NUMBER, getWeekDayNumberToLocalOrder } from '@wedo/utils';
import { NotificationDayBadge } from 'Pages/settings/notifications/types/notification-badge';
import { getEmailDayBadges } from 'Pages/settings/notifications/utils/badge.utils';
import { NotificationDays, useGetSettingsQuery } from 'Shared/services/settings';

export const useNotificationDays = () => {
    const { data } = useGetSettingsQuery();
    const { i18n } = useLingui();
    const locale = i18n.locale ?? 'en';

    const dayOfWeekOrderMapping = useMemo(() => getWeekDayNumberToLocalOrder(locale), [locale]);

    const notificationDaysSaved = useMemo<NotificationDays>(() => data?.notification_days?.value?.days, [data]);

    const notificationDayBadgesSaved = useMemo<Array<NotificationDayBadge>>(() => {
        if (notificationDaysSaved === undefined) return undefined;
        const badges = [...getEmailDayBadges(locale as unknown as Intl.Locale)] as Array<NotificationDayBadge>;
        if (isArray(notificationDaysSaved)) {
            notificationDaysSaved.map((dayNumber) => (badges[dayOfWeekOrderMapping.get(dayNumber)].selected = true));
        }
        return badges;
    }, [notificationDaysSaved, locale, dayOfWeekOrderMapping]);

    const [notificationDayBadges, setNotificationDayBadges] = useState<Array<NotificationDayBadge>>();

    useEffect(() => {
        setNotificationDayBadges(notificationDayBadgesSaved);
    }, [notificationDayBadgesSaved]);

    const notificationDays = useMemo<NotificationDays>(() => {
        if (!isArray(notificationDayBadges)) return notificationDaysSaved;
        const result = [];
        for (const badge of notificationDayBadges) {
            if (badge.selected) {
                result.push(DAY_OF_WEEK_TO_NUMBER.get(badge.id));
            }
        }
        return result.sort() as unknown as NotificationDays;
    }, [notificationDayBadges, notificationDaysSaved]);

    return {
        notificationDaysSaved,
        notificationDays,
        notificationDayBadges,
        setNotificationDayBadges,
    };
};
