import { useNavigate } from 'react-router-dom';
import { useModal } from '@wedo/design-system';
import { SettingsModal } from 'Shared/components/team/SettingsModal/SettingsModal';
import { Notification } from 'Shared/types/notification';

export const useTeamAction = () => {
    const { open } = useModal();
    const navigate = useNavigate();

    return (notification: Notification) => {
        switch (notification.event_key) {
            case 'commented_team':
            case 'updated_comment_team':
            case 'deleted_comment_team':
                navigate(`/teams/${notification.object_id}/feed`);
                break;
            default:
                open(SettingsModal, { teamId: notification.object_id, defaultIndex: 1 });
        }
    };
};
