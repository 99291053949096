import { t } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { ActivitiesTable } from 'Shared/components/activity/ActivitiesTable/ActivitiesTable';
import { useGetMeetingActivityLogsQuery } from 'Shared/services/meeting';
import { getIcon, getIconColor, getText, isImportant } from './MeetingActivityLogsValues';

export const MeetingHistoryModal = ({
    meetingId,
    children,
    ...modalProps
}: { meetingId: Id } & ContextModalProps): JSX.Element => {
    const { data: activityLogs = EmptyArray, isLoading: isLoadingActivityLogs } = useGetMeetingActivityLogsQuery(
        { meetingId },
        { refetchOnMountOrArgChange: true }
    );

    return (
        <Modal size={'md'} {...modalProps}>
            <Modal.Header title={t`Meeting history`} />
            <Modal.Body>
                {isLoadingActivityLogs ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-2" />
                    </div>
                ) : (
                    <ActivitiesTable
                        activities={activityLogs}
                        getIcon={getIcon}
                        getIconColor={getIconColor}
                        getText={(activity) => getText(activity, activityLogs)}
                        isImportant={isImportant}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
