import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faInfinity } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

export const GridCardMetric: FC<{ value: number; text: string }> = ({ value, text }) => {
    return (
        <div className="flex flex-col items-center gap-2">
            {value === Number.POSITIVE_INFINITY ? (
                <div>
                    <FontAwesomeIcon className="text-3xl" icon={faInfinity} />
                </div>
            ) : (
                <div className={clsx('text-3xl')}>{Math.abs(value)}</div>
            )}
            <div
                className={clsx(
                    'text-center text-xs sm:text-base',
                    value === Number.POSITIVE_INFINITY && 'translate-y-1'
                )}
            >
                {text}
            </div>
        </div>
    );
};
