import React, { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Button, ContextModalProps, Form, Modal, Steps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSet } from '@wedo/utils/hooks/useSet';
import { MeetingPicker } from 'Shared/components/meeting/MeetingPicker';
import { SelectableTopicTree } from 'Shared/components/meeting/topicView/SelectableTopicTree';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { trpc } from 'Shared/trpc';
import { Meeting } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type ChooseMeetingTopicSubmissionModalProps = {
    topicTitle: string;
    onDone: (meetingId: Id, topicIds?: string[]) => void;
    sourceMeeting?: Meeting;
    defaultSelectedTopicId?: string;
} & ContextModalProps;

export const SubmitTopicModal: FC<ChooseMeetingTopicSubmissionModalProps> = ({
    topicTitle,
    onDone,
    defaultSelectedTopicId,
    sourceMeeting,
    ...modalProps
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [meetingId, setMeetingId] = useState<Id>();
    const [workspaceId, setWorkspaceId] = useState<string>();
    const [error, setError] = useState<string>('');
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedTopics, actions] = useSet<Id>(new Set(defaultSelectedTopicId ? [defaultSelectedTopicId] : []));
    const [filteredTopics, setFilteredTopics] = useState<MeetingTopic[]>([]);
    const { data: topics } = trpc.meetingTopic.listByMeetingId.useQuery(
        sourceMeeting?.id,
        {
            select: camelToSnake,
        },
        { enabled: sourceMeeting != null }
    );
    const { data: sections } = trpc.meetingSection.listByMeetingId.useQuery(
        sourceMeeting?.id,
        {
            select: camelToSnake,
        },
        { enabled: sourceMeeting != null }
    );

    const { data: meetings = [], isLoading } = trpc.meeting.listForSubmission.useQuery(
        {
            workspaceId: workspaceId === '-1' ? null : workspaceId == null ? undefined : workspaceId,
            sourceMeetingId: sourceMeeting?.id,
        },
        { keepPreviousData: true }
    ) as { data: Meeting[] };

    const handleSubmit = () => {
        setIsSubmitting(true);
        setError('');
        if (meetingId == null) {
            setError(t`You must choose a meeting to submit this topic to`);
            return;
        }
        try {
            onDone(meetingId, Array.from(selectedTopics));
            void modalProps.close();
        } catch (e) {
            setIsSubmitting(false);
        }
    };
    useEffect(() => {
        setFilteredTopics(topics ?? []);
    }, [topics]);

    useEffect(() => {
        if (!isLoading && meetings != null && !meetings.some(({ id }) => meetingId === id)) {
            setMeetingId(null);
        }
    }, [meetings, isLoading]);

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={t`Submit topic`} />
            {sourceMeeting != null && (
                <Steps className={'!rounded-none'} currentIndex={currentStep}>
                    <Steps.Step>{t`Choose topics to submit`}</Steps.Step>
                    <Steps.Step>{t`Choose destination`}</Steps.Step>
                </Steps>
            )}
            <Modal.Body>
                {sourceMeeting != null && currentStep === 1 ? (
                    <SelectableTopicTree
                        onlyTopics
                        sections={sections}
                        meetingId={meetingId}
                        selectedTopicsSet={selectedTopics}
                        selectedTopicsSetActions={actions}
                        topics={filteredTopics}
                        onSearch={(search) =>
                            setFilteredTopics(
                                topics?.filter((topic) =>
                                    `${topic.display_id} ${topic.title}`.toLowerCase().includes(search.toLowerCase())
                                )
                            )
                        }
                    />
                ) : (
                    <Form>
                        <Form.Item label={t`Choose meeting`} cols={4}>
                            <MeetingPicker
                                meetings={meetings}
                                selectedMeetingId={meetingId}
                                onSelectMeetingId={setMeetingId}
                                error={error}
                                setError={setError}
                            />
                        </Form.Item>
                        <Form.Item label={t`Filter by workspace`} cols={2}>
                            <WorkspaceSelect
                                isClearable
                                value={workspaceId}
                                onChange={(workspaceId: string) => {
                                    setWorkspaceId(workspaceId);
                                }}
                            />
                        </Form.Item>
                    </Form>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                {currentStep === 1 && sourceMeeting != null ? (
                    <Button color={'primary'} onClick={() => setCurrentStep(2)} disabled={selectedTopics.size === 0}>
                        <Trans>Next</Trans>
                    </Button>
                ) : (
                    <>
                        {sourceMeeting != null && (
                            <Button onClick={() => setCurrentStep(1)}>
                                <Trans>Previous</Trans>
                            </Button>
                        )}
                        <Button
                            color="primary"
                            loading={isSubmitting}
                            disabled={meetingId == null}
                            onClick={handleSubmit}
                        >
                            <Trans>Submit</Trans>
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};
