import { Id } from '@wedo/types';
import { MeetingAccessRightsData } from 'Shared/components/workspace/UpdateMeetingAccessTable/UpdateMeetingsAccessTable';
import { UserMeetingPermission } from 'Shared/types/meeting';
import { User } from 'Shared/types/user';

export const prepareMeetingAccessData = (
    addedMembers: Map<Id, User>,
    deletedMembers: Map<Id, User>
): MeetingAccessRightsData => {
    const result = new Map<Id, { user: User; updateAccess: boolean; role: UserMeetingPermission; added: boolean }>();
    for (const user of addedMembers.values()) {
        result.set(user.id, { user, updateAccess: true, role: UserMeetingPermission.Reader, added: true });
    }
    for (const user of deletedMembers.values()) {
        result.set(user.id, { user, updateAccess: true, role: UserMeetingPermission.NoAccess, added: false });
    }
    return result;
};
