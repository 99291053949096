import clsx from 'clsx';
import { MobileSidebar } from 'Pages/AppPage/Sidebar/MobileSidebar';
import { useSidebarStore } from 'Pages/AppPage/Sidebar/sidebarStore';
import { SidebarBody } from './SidebarBody';
import { SidebarFooter } from './SidebarFooter';
import { SidebarHeader } from './SidebarHeader';

type SidebarProps = {
    isCollapsed: boolean;
    isMobileCollapsed: boolean;
    setIsCollapsed: (collapsed: boolean) => void;
    setIsMobileCollapsed: (collapsed: boolean) => void;
};

export const Sidebar = ({ isCollapsed, setIsCollapsed, isMobileCollapsed, setIsMobileCollapsed }: SidebarProps) => {
    const { isFloatingDrawerOpen } = useSidebarStore();

    return (
        <>
            <MobileSidebar isCollapsed={isMobileCollapsed} setIsCollapsed={setIsMobileCollapsed} />

            <div
                className={clsx(
                    'lg:grid-rows-header-content-footer delay-100 duration-200 transition hidden h-full max-h-full overflow-hidden bg-gray-900 lg:grid z-20',
                    isCollapsed ? 'w-14' : 'w-64',
                    isCollapsed && isFloatingDrawerOpen && 'bg-gray-950'
                )}
            >
                <SidebarHeader isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <SidebarBody isCollapsed={isCollapsed} />
                <SidebarFooter isCollapsed={isCollapsed} />
            </div>
        </>
    );
};
