import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Editor } from 'slate';
import { useStore } from 'zustand';
import { tryOrNull } from '@wedo/utils';
import type { Store } from 'Shared/components/editor/plugins/commentPlugin/store';

const useTarget = (editor: Editor, meetingBlockId: string) => {
    const [target, setTarget] = useState();

    useEffect(() => {
        setTarget(
            tryOrNull(() =>
                ReactEditor.toDOMNode(
                    editor,
                    editor.children.find(({ id }) => id === meetingBlockId)
                )
            )
        );
    }, [meetingBlockId]);

    return target;
};

type CommentsWrapperProps = {
    store: Store;
    children: ReactNode;
};

export const CommentsWrapper = ({ store, children }: CommentsWrapperProps) => {
    const editor = useSlateStatic();

    const overMeetingBlockId = useStore(store, ({ overMeetingBlockId }) => overMeetingBlockId);
    const highlightedMeetingBlockId = useStore(store, ({ highlightedMeetingBlockId }) => highlightedMeetingBlockId);

    const overTarget = useTarget(editor, overMeetingBlockId);
    const highlightedTarget = useTarget(editor, highlightedMeetingBlockId);

    return (
        <>
            {children}
            {overTarget != null &&
                createPortal(
                    <div
                        contentEditable={false}
                        className="pointer-events-none absolute ring-2 ring-offset-1 ring-blue-500 inset-0 rounded-sm bg-white/50"
                    />,
                    overTarget
                )}
            {highlightedTarget != null &&
                createPortal(
                    <div
                        contentEditable={false}
                        className="pointer-events-none absolute bg-yellow-300 rounded-lg opacity-20 inset-0"
                    />,
                    highlightedTarget
                )}
        </>
    );
};
