import { Svg, Path, Rect, Defs, LinearGradient, Stop } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const JiraIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Rect x="89.1228" y="256" width="236" height="236" transform="rotate(-45 89.1228 256)" fill="white" />
            <Path
                d="M500.013 242.008L277.563 26.8553L256 6L88.548 167.959L11.9868 242.008C4.0044 249.739 4.0044 262.261 11.9868 269.992L164.973 417.959L256 506L423.452 344.041L426.045 341.533L500.013 269.992C507.996 262.261 507.996 249.739 500.013 242.008ZM256 329.918L179.575 256L256 182.082L332.425 256L256 329.918Z"
                fill="#2684FF"
            />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M255.999 182.083C205.962 133.681 205.718 55.2861 255.453 6.59436L88.2056 168.289L179.233 256.33L255.999 182.083Z"
                fill="url(#paint0_linear_1800_26608)"
            />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M332.629 255.802L256 329.918C280.151 353.263 293.721 384.934 293.721 417.959C293.721 450.984 280.151 482.655 256 506L423.657 343.843L332.629 255.802Z"
                fill="url(#paint1_linear_1800_26608)"
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_1800_26608"
                    x1="242.352"
                    y1="107.307"
                    x2="114.865"
                    y2="162.97"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset="0.18" stopColor="#0052CC" />
                    <Stop offset="1" stopColor="#2684FF" />
                </LinearGradient>
                <LinearGradient
                    id="paint1_linear_1800_26608"
                    x1="270.603"
                    y1="403.835"
                    x2="397.86"
                    y2="348.568"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset="0.18" stopColor="#0052CC" />
                    <Stop offset="1" stopColor="#2684FF" />
                </LinearGradient>
            </Defs>
        </Svg>
    );
};
