import React, { useContext, useMemo } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { getVoteAverage, getVoteMedian } from 'Pages/meeting/components/Vote/VoteUtils';
import { MeetingCardContainerContext } from 'Shared/components/meeting/MeetingCardContainerContext';
import { Meeting } from 'Shared/types/meeting';
import { Vote } from 'Shared/types/vote';

interface VoteRatingLinearScaleStatsProps {
    vote: Vote;
    meeting: Meeting;
    hide?: boolean;
}

export const VoteRatingLinearScaleStats = ({ vote, meeting, hide = false }: VoteRatingLinearScaleStatsProps) => {
    const containerRef = useContext(MeetingCardContainerContext);
    const containerSize = useElementSize(containerRef);

    const average = useMemo(() => {
        const average = getVoteAverage(vote, meeting);
        return Number.isInteger(average) ? average : average.toFixed(2);
    }, [vote?.voteAnswers, meeting?.meetingUsers]);

    const median = useMemo(() => {
        return getVoteMedian(vote, meeting);
    }, [vote?.voteAnswers, meeting?.meetingUsers]);

    return (
        <div
            className={clsx(
                'mt-2 flex justify-center gap-4 transition',
                containerSize?.width >= getBreakpointValue('md') ? 'text-sm' : 'text-xs',
                hide ? 'h-0 opacity-0' : 'h-auto opacity-100'
            )}
        >
            <div className={'text-blue-500'}>
                <>
                    {t`Average`}: <div className={'inline font-bold'}>{average}</div>
                </>
            </div>
            <div className={'text-blue-500'}>
                <>
                    {t`Median`}: <div className={'inline font-bold'}>{median}</div>
                </>
            </div>
        </div>
    );
};
