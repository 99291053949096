import { useMatch as useRouterMatch, useSearchParams } from 'react-router-dom';

/**
 * A hook to tell if a route match some path name and search params
 *
 * E.g. useMatch('/path', { value: 1, option: 2 }), will match '/path'
 *      useMatch('/path', { value: 1, option: 2 }, ['value']), will NOT match '/path' or '/path?value=2', but will match '/path?value=1'
 *
 * @param pathname The path name to be matched
 * @param searchParams The search params to be matched
 * @param matchSearchParams An optional array of search param key we want to match
 * @param isDefault An optional boolean value that tells us whether this link is the default one to be used in case nothing is selected
 */
export const useMatch = <S = Record<string, string | string[]>>(
    pathname?: string,
    searchParams?: S,
    matchSearchParams?: (keyof S)[],
    isDefault: boolean = false
) => {
    const matchPathname = useRouterMatch(pathname ?? '');
    const [currentSearchParams] = useSearchParams();
    return (
        // We match a route if the path name match...
        matchPathname != null &&
        // ...or if:
        // - the route is a default one and there is no search params matching the expected ones
        // - we don't care about search params
        // - or if searchParams is null
        ((isDefault &&
            (currentSearchParams.keys().next().value == null ||
                (matchSearchParams != null &&
                    matchSearchParams.some((searchParam) => !currentSearchParams.has(searchParam as string))))) ||
            matchSearchParams == null ||
            searchParams == null ||
            Object.entries(searchParams).every(([key, value]) =>
                // - or if the search param is not one we want to match
                // - or if the value of the search param match the one from the URL
                !(matchSearchParams as string[]).includes(key) || Array.isArray(value)
                    ? currentSearchParams.getAll(key).every(value.includes)
                    : value === currentSearchParams.get(key)
            ))
    );
};
