import { pdf } from '@react-pdf/renderer';
import { createElement } from 'react';
import { t } from '@lingui/macro';
import { MeetingPdf, type MeetingPdfProps } from '@wedo/pdf/meeting/MeetingPdf';
import {
    customFetch,
    mark,
    measure,
    withAuth,
    withHeader,
    withJsonBody,
    withMethod,
    withUnauthorizedHandler,
    withUrl,
} from '@wedo/utils';
import { once } from '@wedo/utils/promise';
import { clientVersion } from 'App/store/versionStore';
import { generateErrorPdf } from 'Pages/meeting/components/MeetingExportModal/generateErrorPdf';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import MeetingPdfWorker from './worker?worker';

const worker = !['development', 'test'].includes(__ENVIRONMENT__) ? new MeetingPdfWorker() : null;

const logPerformance = async (meetingId: string, duration: number, error?: string) => {
    try {
        await customFetch(
            withAuth,
            withUnauthorizedHandler,
            withHeader('X-Client-Version', clientVersion()),
            withUrl(`/api/log`),
            withMethod('POST'),
            withJsonBody({
                type: 'meetingPdfExport',
                message: error,
                meetingId,
                duration,
            })
        );
    } catch (error) {
        // Ignore error
    }
};

export const generateMeetingPdf = async (props: MeetingPdfProps) => {
    const start = mark();
    const { instance } = await getSharedPdfViewerInstance();
    try {
        if (worker == null) {
            const blob = await pdf(createElement(MeetingPdf, props)).toBlob();
            void logPerformance(props.meeting.id, measure(start, mark()).duration);
            return blob;
        }
        worker.postMessage(props);
        const {
            data: { blob, error },
        } = await once(worker, 'message');
        if (error != null) {
            throw error;
        }
        void logPerformance(props.meeting.id, measure(start, mark()).duration);
        return blob;
    } catch (error) {
        console.error(error);
        void logPerformance(props.meeting.id, measure(start, mark()).duration, error?.stack ?? error?.toString());
        return await instance.Core.createDocument(
            await generateErrorPdf(t`An error occurred while rendering meeting PDF`),
            { extension: 'pdf' }
        );
    }
};
