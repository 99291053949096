import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { useRemoveUserEmailMutation } from 'Shared/services/user-email';
import { UserEmail } from 'Shared/types/user-email';

interface ModalContentProps {
    emailAddress: string;
}

const ModalContent = ({ emailAddress }: ModalContentProps): JSX.Element => {
    return (
        <Trans>
            Are you sure you want to delete your email <span className="font-semibold"> {emailAddress}</span>?
        </Trans>
    );
};

export const useDeleteEmailModal = () => {
    const { confirm } = useConfirm();
    const [deleteUserEmail] = useRemoveUserEmailMutation();

    const open = (email: UserEmail) =>
        confirm({
            title: t`Delete email`,
            confirmText: t`Delete`,
            content: <ModalContent emailAddress={email.email_address} />,
            onConfirm: () => deleteUserEmail(email).unwrap(),
            type: 'danger',
        });

    return { open };
};
