import { AnyAction } from '@reduxjs/toolkit';
import { create } from 'zustand';
import { Id } from '@wedo/types';
import { Tag } from 'Shared/services/base';

export const isInvalidationAction = (action: AnyAction) => {
    return action.type === 'api/invalidateTags';
};

export const hasInvalidationTag = (action: AnyAction, tag: Tag) => {
    return (action.payload as Tag[]).some(({ type, id }) => type === tag.type && id === tag.id);
};

export const hasInvalidationTagType = (action: AnyAction, tagType: Id) => {
    return (action.payload as Tag[]).some(({ type }) => type === tagType);
};

type InvalidationStore = {
    action: AnyAction;
};

export const useInvalidationStore = create<InvalidationStore>()(() => ({
    action: null,
}));

export const invalidationReducer = (state: unknown, action: AnyAction) => {
    if (isInvalidationAction(action)) {
        useInvalidationStore.setState({ action });
    }
    return state ?? {};
};

export const onInvalidation = (tag: Tag, handler: (action: AnyAction) => void) => {
    return useInvalidationStore.subscribe(({ action }) => {
        if (hasInvalidationTag(action, tag)) {
            handler(action);
        }
    });
};
