import { useId, useMemo } from 'react';
import { t } from '@lingui/macro';
import { Descendant, Editor as SlateEditor } from 'slate';
import { User } from 'Shared/types/user';
import { Editor } from './Editor';
import { copyPastePlugin } from './plugins/copyPastePlugin/copyPastePlugin';
import { deserializeHtml } from './plugins/copyPastePlugin/deserializeHtml';
import { serializeBlocks } from './plugins/copyPastePlugin/serializeBlocks';
import { headingPlugin } from './plugins/headingPlugin';
import { keyboardPlugin } from './plugins/keyboardPlugin';
import { linePlugin } from './plugins/linePlugin';
import { linkPlugin } from './plugins/linkPlugin';
import { listPlugin } from './plugins/listPlugin';
import { mentionsPlugin } from './plugins/mentionsPlugin';
import { onChangePlugin } from './plugins/onChangePlugin';
import { onInitializePlugin } from './plugins/onInitializePlugin';
import { createParagraphBlock, paragraphPlugin } from './plugins/paragraphPlugin';
import { DividerItem, toolbarPlugin } from './plugins/toolbarPlugin/toolbarPlugin';

type FeedEditorProps = {
    html: string;
    onMention?: (user: User) => void;
    onChange?: (html: string) => void;
    isReadOnly?: boolean;
    hasAutoFocus?: boolean;
};

export const FeedEditor = ({ html, onMention, onChange, isReadOnly, hasAutoFocus }: FeedEditorProps) => {
    const componentId = useId();

    const handleInitialize = (editor: SlateEditor) => {
        const children = deserializeHtml(html);
        editor.children = children.length === 0 ? [createParagraphBlock()] : children;
        editor.onChange();
    };

    const handleChange = (editor: SlateEditor, children: Descendant[]) => {
        onChange(serializeBlocks(children));
    };

    const plugins = useMemo(
        () =>
            [
                linkPlugin(),
                listPlugin(),
                headingPlugin(),
                paragraphPlugin(true),
                linePlugin(''),
                mentionsPlugin(onMention),
                copyPastePlugin(),
                onInitializePlugin(handleInitialize),
                onChangePlugin(handleChange),
                !isReadOnly &&
                    toolbarPlugin(
                        [
                            'h1',
                            'h2',
                            DividerItem,
                            'bold',
                            'italic',
                            'underlined',
                            'strikethrough',
                            DividerItem,
                            'list',
                            DividerItem,
                            'link',
                            DividerItem,
                            'reset',
                        ],
                        componentId,
                        'min-h-[2.5rem] !top-0 border-b  !justify-start pl-1'
                    ),
                keyboardPlugin(),
            ].filter(Boolean),
        [isReadOnly, html]
    );

    return (
        <>
            <div id={componentId} />
            <Editor
                plugins={plugins}
                hasAutoFocus={hasAutoFocus}
                placeholder={t`Type a note here...`}
                className="bg-yellow-50 p-3"
                isReadOnly={isReadOnly}
            />
        </>
    );
};
