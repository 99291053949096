import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { addDays, addMonths, nextMonday, startOfYear } from 'date-fns';
import { formatDate } from '../i18n';

const Monday = nextMonday(new Date());

const FirstJanuary = startOfYear(new Date());

export const useDateTime = () => {
    const linguiContext = useLingui();
    const { i18n } = linguiContext;

    const abbreviatedDays = useMemo(
        () => [...Array(7).keys()].map((offset) => formatDate(addDays(Monday, offset), 'EEE', i18n)),
        [linguiContext]
    );

    const abbreviatedMonths = useMemo(
        () => [...Array(12).keys()].map((offset) => formatDate(addMonths(FirstJanuary, offset), 'LLL', i18n)),
        [linguiContext]
    );

    return { abbreviatedDays, abbreviatedMonths };
};
