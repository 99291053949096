import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

export type CardHeaderProps = {
    title: ReactNode;
    description?: ReactNode;
    actions?: ReactNode;
    className?: string;
};

const CardHeader = ({ title, description, actions, className }: CardHeaderProps): JSX.Element => (
    <div
        className={clsx(
            'flex flex-nowrap items-center justify-between gap-2 rounded-t-[0.45rem] border-b border-gray-200 bg-white px-4 py-5 h-[75px]',
            className
        )}
    >
        <div className="overflow-hidden">
            <h3 className="truncate text-lg font-medium leading-6 text-gray-900">{title}</h3>
            {description && <p className="mt-1 text-sm text-gray-700">{description}</p>}
        </div>
        {actions && <div className="flex-shrink-0">{actions}</div>}
    </div>
);

export type CardBodyProps = PropsWithChildren<{
    className?: string;
}>;

const CardBody = ({ children, className }: CardBodyProps): JSX.Element => (
    <div className={clsx('flex-1 px-4 py-5 sm:px-6', className)}>{children}</div>
);

export type CardFooterProps = PropsWithChildren<{
    className?: string;
}>;

const CardFooter = ({ children, className }: CardFooterProps): JSX.Element => {
    return <div className={clsx('rounded-b-lg bg-gray-50 px-4 py-5', className)}>{children}</div>;
};

export type CardProps = PropsWithChildren<{
    id?: string;
    className?: string;
}>;

export const Card = ({ children, className, ...props }: CardProps): JSX.Element => {
    return (
        <div
            {...props}
            className={clsx('relative flex flex-col rounded-lg border border-gray-200 bg-white shadow-sm', className)}
        >
            {children}
        </div>
    );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;
