import React, { FC } from 'react';
import clsx from 'clsx';

type TitleProps = {
    className?: string;
    children: JSX.Element[] | JSX.Element;
};

export const Title: FC<TitleProps> = ({ className, children }) => (
    <h3 className={clsx('mb-2 text-lg font-medium leading-6 text-gray-900', className)}>{children}</h3>
);
