const SvgMimeType = 'image/svg+xml';

const loadSvg = (svg: string, width?: number, height?: number): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const svgDocument = new DOMParser().parseFromString(svg, SvgMimeType);
        const image = new Image();
        if (width != null && height != null) {
            const svgElement = svgDocument.documentElement as unknown as SVGSVGElement;
            image.width = svgElement.viewBox.baseVal.width;
            image.height = svgElement.viewBox.baseVal.height;
        }
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = `data:${SvgMimeType};utf8,${encodeURIComponent(svg).replace(/'/g, '%27').replace(/"/g, '%22')}`;
    });
};

export const convertSvgToPng = async (url: string, width?: number, height?: number) => {
    const response = await fetch(url);
    if (response.headers.get('content-type') === SvgMimeType) {
        const svg = await response.text();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const svgImage = await loadSvg(svg, width, height);
        const scale = width != null && height != null ? Math.min(width / svgImage.width, height / svgImage.height) : 1;
        canvas.width = svgImage.width * scale;
        canvas.height = svgImage.height * scale;
        context.drawImage(svgImage, 0, 0, svgImage.width * scale, svgImage.height * scale);
        return canvas.toDataURL();
    }
    return url;
};
