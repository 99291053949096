import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import {
    ComponentPropsWithoutRef,
    CSSProperties,
    forwardRef,
    MouseEvent as ReactMouseEvent,
    TouchEvent as ReactTouchEvent,
    useCallback,
    useRef,
} from 'react';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import {
    faDownload,
    faObjectsAlignCenterHorizontal,
    faObjectsAlignLeft,
    faObjectsAlignRight,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Editor, Range, Transforms } from 'slate';
import { Button } from '@wedo/design-system';
import { preventDefault, remToPx } from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import { forceSave, skipSave } from 'Shared/components/editor/utils/operation';
import { Plugin, useEditorIsFocused } from '../Editor';
import { type ImageElement as ImageElementType } from '../types';
import { createBlock } from '../utils/block';

export const Image = 'image';

const BrokenImageDataUrlSvg =
    'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgODAwIDYwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjIiPjxwYXRoIHN0eWxlPSJmaWxsOiNkZGQiIGQ9Ik0wIDBoODAwdjYwMEgweiIvPjxwYXRoIGQ9Im0zMTMuNCAyMjMtNi00LjYtOS41IDEyIDYgNC43TDQ4Ni43IDM3N2w2IDQuNyA5LjUtMTItNi00LjctMTQuNC0xMS4yVjIyOC41SDMyMC40bC03LTUuNFptMjYuOCAyMC45aDEyNi4ydjk4TDQ0NSAzMjUuMyA0MjEuNSAyOTNsLTYuMi04LjYtNi4yIDguNi0yIDIuOC0yNy41LTIxLjNhMTUuMyAxNS4zIDAgMCAwLTE5LjEtMTQuOUwzNDAuMiAyNDRabTExMy4yIDEyNy42LTY0LjItNTAuNi04LjkgMTIuNC0xNi4zLTE2LjQtNS40IDYtMjMgMjUuNi0yIDIuMXYtNzMuNWwtMTUuMy0xMnYxMDYuNGgxMzUuMVoiIHN0eWxlPSJmaWxsOiNhYWE7ZmlsbC1ydWxlOm5vbnplcm8iLz48L3N2Zz4=';

type Position = 'start' | 'end';

type Align = 'flex-start' | 'flex-end' | 'center';

type Decoration = {
    size: {
        width: number;
        height: number;
        maxWidth: number;
    };
    textAlign: Align;
};

const Alignments = {
    'flex-start': 'text-start',
    center: 'text-center',
    'flex-end': 'text-end',
} as const;

const AlignTools = [
    { align: 'flex-start', icon: faObjectsAlignLeft },
    { align: 'center', icon: faObjectsAlignCenterHorizontal },
    { align: 'flex-end', icon: faObjectsAlignRight },
] as const;

const MinWidth = 100;

const PathStyleS3UrlRegex = /https:\/\/sos-.+?\.exo\.io/;

const normalizeUrl = (url: string) => {
    if (url.startsWith('https://files.wedo.') || url.startsWith('https://files-dev.wedo.')) {
        const { pathname, search } = new URL(url);
        const [, , , ...path] = pathname.split('/');
        return `/files/${path.join('/')}${search}`;
    }
    if (PathStyleS3UrlRegex.test(url)) {
        const { pathname, search } = new URL(url);
        const [, , ...path] = pathname.split('/');
        return `/files/${path.join('/')}${search}`;
    }
    return url;
};

const computeEditorWidth = (editorElement: HTMLElement) => {
    const editorStyle = getComputedStyle(editorElement);
    return (
        editorElement.clientWidth -
        parseFloat(editorStyle.paddingLeft) -
        parseFloat(editorStyle.paddingRight) -
        // There is a right margin of 0.5rem in blocks
        remToPx(0.5)
    );
};

export const computeAdjustedDimension = (editor: Editor, width: number, height: number) => {
    // Image height may be greater than the editor width...
    const editorElement = ReactEditor.toDOMNode(editor, editor);
    const editorWidth = computeEditorWidth(editorElement);
    // ...so we compute adjusted dimensions if needed
    const adjustedWidth = Math.min(editorWidth, width);
    const adjustedHeight = adjustedWidth !== width ? height / (width / editorWidth) : height;
    return { adjustedWidth, adjustedHeight };
};

export const createImageBlock = (url: string, align: Align, width: number, height: number, maxWidth: number) => {
    return createBlock({
        type: Image,
        children: [{ text: '', url }],
        decoration: JSON.stringify({
            size: { width, height, maxWidth },
            textAlign: align,
        }),
    });
};

const extensionFromMimeType = (mimeType: string) => {
    switch (mimeType) {
        case 'image/png':
            return 'png';
        case 'image/gif':
            return 'gif';
        default:
            return 'jpg';
    }
};

type HandleProps = {
    position: Position;
} & ComponentPropsWithoutRef<'div'>;

const Handle = forwardRef<HTMLDivElement, HandleProps>(({ position, ...props }, ref) => {
    return (
        <div
            className={clsx(
                'absolute -right-2 top-0 flex h-full w-3 cursor-col-resize items-center',
                position === 'start' ? '-left-3' : '-right-3 justify-end'
            )}
            {...props}
        >
            <div
                ref={ref}
                className="h-20 w-1 rounded-full bg-gray-500 opacity-0 transition-opacity group-hover:opacity-100 group-[.is-dragging]:opacity-0"
            ></div>
        </div>
    );
});

type ToolbarProps = {
    element: ImageElementType;
    url: string;
    align: string;
    onAlign: (align: Align) => void;
    floatingStyles: CSSProperties;
};

const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(({ url, align, onAlign, floatingStyles }, ref) => {
    const editor = useSlateStatic();

    const handleAlign = (align: Align) => () => {
        onAlign(align);
    };

    const handleDelete = () => {
        Transforms.removeNodes(editor, { voids: true, mode: 'highest', match: (node) => !Editor.isEditor(node) });
        forceSave(editor);
    };

    const handleDownload = async () => {
        const link = document.createElement('a');
        if (url.startsWith('data:image/')) {
            const [extension] = url.substring('data:image/'.length).split(';', 1);
            link.href = url;
            link.download = `image.${extension}`;
        } else {
            let absoluteUrl = location.origin + url;
            if (PathStyleS3UrlRegex.test(url)) {
                const [, , ...segments] = new URL(url).pathname.split('/');
                absoluteUrl = `${location.origin}/${segments.join('/')}`;
            }
            const blob = await fetch(absoluteUrl).then((response) => response.blob());
            const extension = extensionFromMimeType(blob.type);
            link.href = URL.createObjectURL(blob);
            link.download = `${new URL(absoluteUrl).pathname.split('/').pop()}.${extension}`;
        }
        link.click();
    };

    return (
        <div
            ref={ref}
            className="z-10 flex gap-1 rounded-md border border-gray-400 bg-white p-1 shadow-md group-[.is-dragging]:hidden"
            style={floatingStyles}
        >
            {AlignTools.map(({ align: toolAlign, icon }) => (
                <Button
                    key={toolAlign}
                    active={align === toolAlign}
                    variant="text"
                    icon={icon}
                    onMouseDown={preventDefault()}
                    onClick={handleAlign(toolAlign)}
                />
            ))}
            <div className="border-r border-r-gray-300" />
            <Button variant="text" icon={faDownload} onMouseDown={preventDefault()} onClick={handleDownload} />
            <Button
                variant="text"
                color="danger"
                icon={faTrash}
                onMouseDown={preventDefault()}
                onClick={handleDelete}
            />
        </div>
    );
});

type ImageElementProps = Omit<RenderElementProps, 'element'> & {
    element: ImageElementType;
};

const ImageElement = ({ element, children, attributes }: ImageElementProps) => {
    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();
    const selected = useSelected();
    const focused = useEditorIsFocused();

    const url = normalizeUrl(element.children[0].url);

    const decoration = useRef(element.decoration != null ? (JSON.parse(element.decoration) as Decoration) : null);

    const width = useRef(decoration.current?.size?.width ?? 0);

    const editorWidth = useRef(0);
    const startHandleRef = useRef<HTMLDivElement>();
    const endHandleRef = useRef<HTMLDivElement>();
    const startPoint = useRef<{ x: number; width: number; position: Position }>(null);

    const isSelected = !isReadOnly && selected && focused && Range.isCollapsed(editor.selection);

    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [offset(10), flip(), shift()],
    });

    const updateDecoration = () => {
        Transforms.setNodes(
            editor,
            { decoration: JSON.stringify(decoration.current) },
            { at: ReactEditor.findPath(editor, element) }
        );
        if (
            ((refs.reference.current as HTMLDivElement).firstElementChild as HTMLImageElement).src ===
            BrokenImageDataUrlSvg
        ) {
            skipSave(editor);
        } else {
            forceSave(editor);
        }
    };

    const handleMouseDown =
        (position: Position) => (event: ReactMouseEvent<HTMLDivElement> | ReactTouchEvent<HTMLDivElement>) => {
            editorWidth.current = computeEditorWidth(
                (refs.reference.current as HTMLDivElement).closest('[data-slate-editor="true"]')
            );
            Object.assign(document.body.style, { pointerEvents: 'none', userSelect: 'none' });
            document.documentElement.style.cursor = 'col-resize';
            const x = 'touches' in event ? event.touches.item(0).pageX : event.pageX;
            startPoint.current = { x, width: width.current, position };
            // To keep aspect-ratio, whenever the user start to resize the image, we reset the height, so it can be
            // computed automatically
            (refs.reference.current as HTMLDivElement).style.height = null;
            const handle = (position === 'start' ? startHandleRef : endHandleRef).current;
            handle.classList.remove('bg-gray-500');
            handle.classList.add('bg-blue-500');
            handle.style.opacity = '1';
        };

    const handleMouseMove = useCallback((event: MouseEvent | TouchEvent) => {
        if (startPoint.current != null) {
            const x = 'touches' in event ? event.touches.item(0).pageX : event.pageX;
            width.current = Math.min(
                editorWidth.current,
                Math.min(
                    decoration.current.size.maxWidth,
                    Math.max(
                        MinWidth,
                        startPoint.current.width +
                            (startPoint.current.position === 'start'
                                ? startPoint.current.x - x
                                : x - startPoint.current.x)
                    )
                )
            );
            (refs.reference.current as HTMLDivElement).style.width = `${width.current}px`;
        }
    }, []);

    const handleMouseUp = useCallback(() => {
        if (startPoint.current != null) {
            Object.assign(document.body.style, { pointerEvents: 'auto', userSelect: 'auto' });
            document.documentElement.style.cursor = 'auto';
            const handle = (startPoint.current.position === 'start' ? startHandleRef : endHandleRef).current;
            handle.classList.remove('bg-blue-500');
            handle.classList.add('bg-gray-500');
            handle.style.opacity = null;
            startPoint.current = null;
            decoration.current.size.width = width.current;
            decoration.current.size.height = refs.reference.current.getBoundingClientRect().height;
            updateDecoration();
        }
    }, []);

    const handleLoad = () => {
        const firstElementChild = (refs.reference.current as HTMLDivElement).firstElementChild as HTMLImageElement;
        if (decoration.current == null && !isReadOnly) {
            const naturalWidth = firstElementChild.naturalWidth;
            const naturalHeight = firstElementChild.naturalHeight;
            const { adjustedWidth, adjustedHeight } = computeAdjustedDimension(editor, naturalWidth, naturalHeight);
            decoration.current = {
                size: { width: adjustedWidth, height: adjustedHeight, maxWidth: naturalWidth },
                textAlign: 'center',
            };
            width.current = adjustedWidth;
            updateDecoration();
        } else if (decoration.current != null && decoration.current.size.maxWidth == null && !isReadOnly) {
            decoration.current.size.maxWidth = firstElementChild.naturalWidth;
            updateDecoration();
        }
    };

    const handleError = () => {
        if (!isReadOnly) {
            Transforms.setNodes(
                editor,
                { url: BrokenImageDataUrlSvg },
                { at: [...ReactEditor.findPath(editor, element), 0], voids: true }
            );
            skipSave(editor);
        }
    };

    const handleAlign = (align: Align) => {
        decoration.current.textAlign = align;
        updateDecoration();
    };

    useEvent('mousemove', handleMouseMove);
    useEvent('touchmove', handleMouseMove);
    useEvent('mouseup', handleMouseUp);
    useEvent('touchend', handleMouseUp);

    return (
        <div
            data-block-id={element.id}
            {...attributes}
            className={clsx(
                'inline-block w-full py-1 align-top relative',
                Alignments[decoration.current?.textAlign ?? 'center']
            )}
        >
            <div contentEditable={false} className="group relative inline-block max-w-full">
                {isSelected && !isReadOnly && (
                    <Handle
                        ref={startHandleRef}
                        position="start"
                        onMouseDown={handleMouseDown('start')}
                        onTouchStart={handleMouseDown('start')}
                    />
                )}
                <div
                    ref={refs.setReference}
                    className="max-w-full"
                    style={{
                        width: `${width.current}px`,
                        aspectRatio:
                            decoration.current?.size == null
                                ? `auto`
                                : `${decoration.current.size.width / decoration.current.size.height}`,
                    }}
                >
                    <img
                        alt=""
                        src={url}
                        onLoad={handleLoad}
                        onError={handleError}
                        draggable={false}
                        className={clsx(
                            'inline-block',
                            isSelected && 'ring-2 ring-blue-500 ring-offset-2 group-[.is-dragging]:ring-0'
                        )}
                    />
                </div>
                {isSelected && !isReadOnly && (
                    <Handle
                        ref={endHandleRef}
                        position="end"
                        onMouseDown={handleMouseDown('end')}
                        onTouchStart={handleMouseDown('end')}
                    />
                )}
                {isSelected && !isReadOnly && (
                    <Toolbar
                        ref={refs.setFloating}
                        floatingStyles={floatingStyles}
                        url={url}
                        align={decoration.current?.textAlign ?? 'center'}
                        onAlign={handleAlign}
                        element={element}
                    />
                )}
            </div>
            {children}
        </div>
    );
};

export const imagePlugin = (): Plugin => ({
    isVoid: (editor, element) => element.type === Image,
    renderElement: (editor, { element, children, attributes }) => {
        return (
            element.type === Image && (
                <ImageElement element={element} attributes={attributes}>
                    {children}
                </ImageElement>
            )
        );
    },
});
