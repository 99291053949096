import React from 'react';
import { FaIconName } from '@wedo/types';
import { IconTag } from 'Pages/settings/customFields/components/tags/IconTag';

type CardLabelProps = {
    icon?: FaIconName;
    label: string;
};

export const ItemLabel: React.FC<CardLabelProps> = ({ label, icon }) => {
    return (
        <span className="@2xl:text-base flex max-w-full items-center gap-3 overflow-hidden text-sm text-gray-800">
            {icon && <IconTag className={'shrink-0'} icon={icon} />}
            <span className={'flex-1 truncate'}>{label}</span>
        </span>
    );
};
