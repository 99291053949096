import { Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const FigmaIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M255.667 256C255.667 209.976 292.976 172.667 339 172.667C385.024 172.667 422.333 209.976 422.333 256C422.333 302.024 385.024 339.333 339 339.333C292.976 339.333 255.667 302.024 255.667 256Z"
                fill="#1ABCFE"
            />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M89 422.667C89 376.643 126.31 339.333 172.333 339.333H255.667V422.667C255.667 468.69 218.357 506 172.333 506C126.31 506 89 468.69 89 422.667Z"
                fill="#0ACF83"
            />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M255.667 6V172.667H339C385.024 172.667 422.333 135.357 422.333 89.3333C422.333 43.3096 385.024 6 339 6H255.667Z"
                fill="#FF7262"
            />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M89 89.3333C89 135.357 126.31 172.667 172.333 172.667H255.667V6H172.333C126.31 6 89 43.3096 89 89.3333Z"
                fill="#F24E1E"
            />
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M89 256C89 302.024 126.31 339.333 172.333 339.333H255.667V172.667H172.333C126.31 172.667 89 209.976 89 256Z"
                fill="#A259FF"
            />
        </Svg>
    );
};
