import { Id } from '@wedo/types';
import { tryParseInt } from '@wedo/utils';
import { Meeting } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { Vote, VoteAnswer, VoteOption } from 'Shared/types/vote';

export type VoteOptionMode = 'editable' | 'vote' | 'results-detail' | 'results-voted' | 'results-count';

export const getTotalShares = (meetingUsers: MeetingUser[]): number =>
    meetingUsers?.filter((mu) => mu.can_vote).reduce((prev, cur) => prev + cur.shares, 0);

export const getTotalSharesFromVoteAnswerArray = (answers: VoteAnswer[], meetingUsers: MeetingUser[]): number => {
    // Shares can be denormalized in answers when a vote is anonymous, because answers are not linked to a user
    return (
        answers?.reduce(
            (result, answer) =>
                result + (answer.shares ?? meetingUsers.find(({ user_id }) => user_id === answer.user_id)?.shares ?? 0),
            0
        ) ?? 0
    );
};

export const shouldDisplayShares = (meetingUsers: MeetingUser[]): boolean => {
    return meetingUsers?.some((u) => u.can_vote && u.shares !== 1);
};

export const getPendingVotesCount = (vote: Vote, voteAnswers: VoteAnswer[], meetingUsers: MeetingUser[]): number => {
    if (vote?.computedPendingVotes != null) {
        return vote?.computedPendingVotes;
    }
    const possibleVoters = meetingUsers?.filter((mu) => mu.can_vote);
    if (possibleVoters == null || possibleVoters.length === 0) {
        return 0;
    }
    const distinctAnswers = voteAnswers
        ?.filter((a) => a.status !== 'requested_discussion')
        .filter((answer, i, array) => array.indexOf(answer) === i);
    const usersWithNoAnswer = possibleVoters.filter(
        (v) => distinctAnswers?.find((a) => a.user_id === v.user_id) == null
    );
    return usersWithNoAnswer?.length;
};

export const getMeetingUserShares = (userId: Id, meetingUsers: MeetingUser[]): number => {
    return meetingUsers?.find((mu) => mu.user_id === userId && mu.can_vote)?.shares || 0;
};

export const getTotalVotedShares = (
    vote: Vote,
    meeting: Meeting,
    excludeDiscussions = false,
    excludeAbstained = false
): number => {
    let answers = vote?.voteAnswers;
    if (excludeDiscussions) {
        answers = answers?.filter((a) => a.status !== 'requested_discussion');
    }
    if (excludeAbstained) {
        answers = answers?.filter((a) => a.status !== 'abstained');
    }

    if (vote.computedVotedShares != null) {
        return vote.computedVotedShares;
    }

    if (meeting?.meetingUsers?.some((mu) => mu.can_vote)) {
        return getTotalSharesFromVoteAnswerArray(answers, meeting?.meetingUsers);
    }

    return (
        vote?.voteOptions?.reduce((prev, cur) => prev + cur.vote_count, 0) +
        (!excludeAbstained ? vote?.abstained_count || 0 : 0)
    );
};

/**
 * @returns average value for vote answers
 * (Vote of type "linear_scale" and "rating" only)
 */
export const getVoteAverage = (vote: Vote, meeting: Meeting): number => {
    const [totalManualShares, totalManualScore] = (vote?.voteOptions || []).reduce(
        ([totalShares, totalScore], option) => [
            totalShares + (option.vote_count || 0),
            totalScore + (option.vote_count || 0) * tryParseInt(option.value),
        ],
        [0, 0]
    );

    const [totalAnswersShares, totalAnswersScore] = (vote?.voteAnswers || [])
        .filter((a) => a.status !== 'requested_discussion' && a.status !== 'abstained')
        .reduce(
            ([totalShares, totalScore], answer) => {
                const shares = answer.shares ?? getMeetingUserShares(answer.user_id, meeting?.meetingUsers);
                return [totalShares + shares, totalScore + shares * tryParseInt(answer.voteOption.value)];
            },
            [0, 0]
        );

    const totalShares = totalManualShares + totalAnswersShares;

    return totalShares > 0 ? (totalManualScore + totalAnswersScore) / totalShares : 0;
};

/**
 * @returns median of vote answers
 * (Vote of type "linear_scale" and "rating" only)
 */
export const getVoteMedian = (vote: Vote, meeting: Meeting): number => {
    const manualVotes = (vote?.voteOptions || []).reduce(
        (scores, option) => scores.concat(Array(option.vote_count || 0).fill(tryParseInt(option.value))),
        []
    );

    const answersVotes = (vote?.voteAnswers || [])
        .filter((a) => a.status !== 'requested_discussion' && a.status !== 'abstained')
        .reduce(
            (scores, answer) =>
                scores.concat(
                    Array(answer.shares ?? getMeetingUserShares(answer.user_id, meeting?.meetingUsers)).fill(
                        tryParseInt(answer.voteOption.value)
                    )
                ),
            []
        );

    const votes = manualVotes.concat(answersVotes).sort();

    if (votes.length === 0) {
        return 0;
    }

    const half = Math.floor(votes.length / 2);
    if (votes.length % 2 === 0) {
        return (votes[half - 1] + votes[half]) / 2.0;
    }

    return votes[half];
};

/**
 * @returns amount of votes / shares for a voteOption or value
 * */
export const getOptionCount = ({
    voteOption,
    vote,
    meetingUsers,
}: {
    voteOption?: VoteOption;
    vote: Vote;
    meetingUsers: MeetingUser[];
}): number => {
    const totalShares = getTotalShares(meetingUsers);
    let count = 0;
    if (totalShares > 0) {
        if (voteOption.id === 'abstained' && vote.computedAbstainedShares) {
            return vote.computedAbstainedShares;
        }
        if (voteOption.id === 'requested_discussion' && vote.computedRequestedDiscussionShares) {
            return vote.computedRequestedDiscussionShares;
        }
        if (voteOption.computedShares != null) {
            return voteOption.computedShares;
        }
        voteOption.voteAnswers?.forEach(({ user_id, shares }) => {
            count += shares ?? getMeetingUserShares(user_id, meetingUsers);
        });
    } else {
        if (voteOption.id === 'abstained') {
            return vote?.abstained_count;
        }
        count = voteOption.vote_count;
    }
    return count;
};

/**
 *  @returns fraction of total votes for vote option
 *  (Vote of type "single" and "multiple" only)
 *  (only takes in consideration users that voted)
 */
export const getVoteOptionRelativeShareFraction = (option: VoteOption, vote: Vote, meeting: Meeting): number => {
    const totalVotedShares = getTotalVotedShares(vote, meeting);
    const currentOptionShares = getOptionCount({ voteOption: option, meetingUsers: meeting?.meetingUsers, vote });
    if (currentOptionShares > 0 && totalVotedShares > 0) {
        return currentOptionShares / totalVotedShares;
    }
    return 0;
};

/**
 *  @returns fraction of total votes for vote option
 *  (Vote of type "linear_scale" and "rating" only)
 */
export const getValueRelativeShareFraction = (option: VoteOption, vote: Vote, meeting: Meeting): number => {
    const totalVotedShares = getTotalVotedShares(vote, meeting);
    const currentOptionShares = getOptionCount({ voteOption: option, vote, meetingUsers: meeting?.meetingUsers });
    if (currentOptionShares > 0 && totalVotedShares > 0) {
        return currentOptionShares / totalVotedShares;
    }
    return 0;
};

/**
 * @returns object containing the vote option that received the most votes, and the amount of votes
 */
export const getHighestVoteAndCount = (
    vote: Vote,
    meetingUsers: MeetingUser[],
    ignoreAbstain = false
): {
    option: Id | number;
    count: number;
    type: string;
} => {
    const totalShares = getTotalShares(meetingUsers);
    let highestOption;
    let highestCount = -1;
    const abstainedCount = vote?.voteAnswers
        .filter((a) => a.status === 'abstained')
        .reduce((prev, { user_id, shares }) => {
            if (totalShares > 0) {
                return prev + (shares ?? getMeetingUserShares(user_id, meetingUsers));
            }
            return prev + 1;
        }, 0);

    vote?.voteOptions?.forEach((o) => {
        const currentCount = getOptionCount({ voteOption: o, vote, meetingUsers });
        if (currentCount > highestCount) {
            highestCount = currentCount;
            highestOption = o.id;
        }
    });

    if (!ignoreAbstain) {
        if (abstainedCount > highestCount) {
            highestCount = abstainedCount;
            highestOption = 'abstained';
        }
    }

    return { option: highestOption, count: highestCount, type: totalShares > 0 ? 'shares' : 'count' };
};

export const hasUserVotedInClosedVote = (userId: Id, meetingVotes: Vote[]) => {
    for (let i = 0; i < meetingVotes.length; i++) {
        if (meetingVotes[i].voteAnswers.some((a) => a.user_id === userId) && meetingVotes[i].status === 'closed') {
            return true;
        }
    }
    return false;
};

export const createVoteWithRelated = (vote: Vote) => {
    if (vote == null) {
        return vote;
    }

    return {
        ...vote,
        voteAnswers: vote.voteAnswers.map((answer) => ({
            ...answer,
            voteOption: vote.voteOptions.find(({ id }) => id === answer.vote_option_id),
        })),
        voteOptions: vote.voteOptions.map((option) => ({
            ...option,
            voteAnswers: vote.voteAnswers.filter(({ vote_option_id }) => option.id === vote_option_id),
        })),
    };
};
