import clsx from 'clsx';
import { Descendant, Editor, Transforms } from 'slate';
import { Plugin } from '../Editor';
import {
    breakBlockInside,
    breakBlockOutside,
    createBlock,
    isBlockEmpty,
    resetOrMergeForwardIfPossible,
} from '../utils/block';
import { isIn } from '../utils/slate';

export const Paragraph = 'paragraph';

export const textAlignFromDecoration = (element: Descendant) => ({
    textAlign: element.decoration,
});

export const createParagraphBlock = (text = '') => {
    return createBlock({ type: Paragraph }, text);
};

export const paragraphPlugin = (isLight: boolean = false): Plugin => ({
    renderElement: (editor, { element, children, attributes }) => {
        return (
            // We treat heading as paragraph if they are not already handled by the heading plugin
            (element.type === Paragraph || element.type === 'heading') && (
                <div
                    data-block-id={element.id}
                    className={clsx('relative', !isLight && 'py-1 px-2')}
                    style={textAlignFromDecoration(element)}
                    {...attributes}
                >
                    {children}
                </div>
            )
        );
    },
    deleteForward: (editor: Editor) => resetOrMergeForwardIfPossible(editor, Paragraph),
    insertBreak: (editor: Editor) => isIn(editor, Paragraph, () => breakBlockOutside(editor)),
    insertSoftBreak: (editor: Editor) => isIn(editor, Paragraph, () => breakBlockInside(editor)),
    onOutsideClick: (editor, event) => {
        if (!editor.isReadOnly) {
            const lastBlock = editor.children[editor.children.length - 1];
            if (lastBlock == null || lastBlock.type !== Paragraph || !isBlockEmpty(lastBlock)) {
                event.preventDefault();
                Transforms.insertNodes(editor, createParagraphBlock(), { at: [editor.children.length], select: true });
                return true;
            }
        }
        return false;
    },
});
