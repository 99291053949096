import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { FC, PropsWithChildren } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import clsx from 'clsx';

const classes = {
    base: 'relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none focus-visible:ring-2 focus:ring-blue-600 focus:ring-offset-2',
    checked: {
        true: 'bg-blue-600',
        false: 'bg-gray-300',
    },
    disabled: 'opacity-30 cursor-not-allowed hover:bg-none',
};

export type SwitchProps = {
    className?: string;
    checked: boolean;
    label?: string;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
    loading?: boolean;
};

const SwitchComponent = ({
    className,
    checked = false,
    label,
    disabled = false,
    onChange,
    loading = false,
}: SwitchProps) => (
    <HeadlessSwitch
        checked={checked}
        disabled={disabled}
        onChange={() => !disabled && onChange?.(!checked)}
        className={clsx(classes.base, classes.checked[checked?.toString()], disabled && classes.disabled, className)}
    >
        <span className="sr-only">{label}</span>
        <span
            className={clsx(
                checked ? 'translate-x-6' : 'translate-x-1',
                'flex h-4 w-4 transform items-center justify-center rounded-full bg-white transition'
            )}
        >
            {loading && (
                <FontAwesomeIcon
                    icon={faSpinnerThird}
                    className="animate-spin text-xs text-blue-600"
                    aria-hidden="true"
                />
            )}
        </span>
    </HeadlessSwitch>
);

SwitchComponent.displayName = 'Switch';

const LabelComponent: FC<{ disabled?: boolean; className?: string } & PropsWithChildren> = ({
    disabled = false,
    children,
    className,
}) => {
    return (
        <HeadlessSwitch.Label className={clsx(disabled ? 'cursor-not-allowed' : 'cursor-pointer', className)}>
            {children}
        </HeadlessSwitch.Label>
    );
};

export const Switch = Object.assign(SwitchComponent, { Group: HeadlessSwitch.Group, Label: LabelComponent });
