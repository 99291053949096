import { HttpMethod, Id } from '@wedo/types';
import { AttachmentAnnotation } from 'Shared/types/attachmentAnnotation';
import { baseApi, configureTag } from './base';

const { tag, tagType } = configureTag('AttachmentAnnotation');
export const tagList: Id = 'list';

export const attachmentApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAttachmentAnnotations: build.query<AttachmentAnnotation, Id>({
                query: (attachmentVersionId) => ({
                    url: `attachments/${attachmentVersionId}/annotations`,
                }),
                providesTags: () => [tag(tagList)],
            }),
            updateAttachmentAnnotation: build.mutation<
                Partial<AttachmentAnnotation>,
                { attachmentVersionId: Id; annotation_xfdf: string }
            >({
                query: ({ attachmentVersionId, annotation_xfdf }) => ({
                    url: `attachments/${attachmentVersionId}/annotation`,
                    method: HttpMethod.Post,
                    body: { annotation_xfdf },
                }),
                invalidatesTags: () => [tag(tagList)],
            }),
        }),
    });

export const { useLazyGetAttachmentAnnotationsQuery, useUpdateAttachmentAnnotationMutation } = attachmentApi;
