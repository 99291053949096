import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import { useSectionsPanel } from 'Shared/components/sections/SectionsPanel/useSectionsPanel';
import {
    checklistApi,
    useGetChecklistSectionsQuery,
    useUpdateChecklistSectionMutation,
} from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';

export const useTemplateSections = (templateId: Id) => {
    const dispatch = useAppDispatch();

    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: templateId === undefined });
    const checklistId = template?.checklist_id;
    const { data: sections } = useGetChecklistSectionsQuery(checklistId, { skip: checklistId === undefined });

    const [updateChecklistSection] = useUpdateChecklistSectionMutation();

    const { handleDragEnd, maxOrder } = useSectionsPanel({
        sections,
        updateItemOrders: (payload, sortedSections) => {
            dispatch(checklistApi.util.updateQueryData('getChecklistSections', checklistId, () => sortedSections));

            for (const item of payload) {
                void updateChecklistSection({ checklistId, sectionId: item.id, section: { order: item.order } });
            }
        },
    });

    return { handleDragEnd, sections, maxOrder };
};
