import { useContext } from 'react';
import { t } from '@lingui/macro';
import { useChangeStage } from 'Pages/settings/security/hooks/useChangeStage';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { ResetError } from 'Pages/settings/security/utils/type';
import { useCheckTotpMutation } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

type CheckFn = (totp: string) => Promise<void>;

type TotpFn = () => { check: CheckFn; isLoading: boolean };

/** Verify the 6-digit code received by the user by scanning the QRCode */
export const useTotp: TotpFn = () => {
    const { state, dispatch } = useContext(TotpContext);
    const { next } = useChangeStage();
    const [checkTotp, { isLoading }] = useCheckTotpMutation();

    const check: CheckFn = async (totp) => {
        dispatch({ type: ResetError });

        const result = await checkTotp({ secret: state.qrCode.secret, totp });

        if ('data' in result) {
            dispatch({ type: 'authenticators', value: state.authenticators.concat(result.data.id) });
            next();
        } else if ('error' in result) {
            let error: string;

            switch (errorMessage(result.error)) {
                case 'Invalid digit code':
                    error = t`Invalid digit code`;
                    break;
                case 'TypeError: Failed to fetch':
                    error = t`Network error`;
                    break;
                default:
                    error = t`Unexpected error`;
            }

            dispatch({ type: 'error', value: error });
        }
    };

    return { check, isLoading };
};
