import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Switch, useConfirm } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import {
    invalidateAttachment,
    invalidateGetAttachments,
    useAddAttachmentWorkspaceMutation,
    useRemoveAttachmentWorkspaceMutation,
} from 'Shared/services/attachment';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Attachment } from 'Shared/types/attachment';
import { useNavigate } from '@wedo/utils/hooks';

type FileDetailWorkspaceProps = {
    attachment: Attachment;
    workspaceId: Id;
    onClose: () => void;
};

export const FileDetailWorkspace = ({ attachment, workspaceId, onClose }: FileDetailWorkspaceProps) => {
    const dispatch = useAppDispatch();
    const { data: currentWorkspace } = useGetWorkspaceQuery(workspaceId);
    const navigate = useNavigate();
    const { confirm } = useConfirm();

    const [addWorkspace] = useAddAttachmentWorkspaceMutation();
    const [removeWorkspace] = useRemoveAttachmentWorkspaceMutation();

    const [switchValue, setSwitchValue] = useState<boolean>(false);

    useEffect(() => {
        setSwitchValue(attachment?.tags.length > 0);
    }, [attachment]);

    const handleRefreshAndClose = () => {
        dispatch(invalidateGetAttachments());
        dispatch(invalidateAttachment(attachment.id));
        onClose();
    };

    const handleChange = async (value: boolean): Promise<void> => {
        if (value && !switchValue) {
            setSwitchValue(value);
            await addWorkspace({ attachmentId: attachment.id, workspaceId: currentWorkspace.id });
            void navigate({
                pathname: `/workspaces/${workspaceId}/files`,
                searchParams: {
                    view: 'shared',
                    folder: attachment?.folder_id?.toString(),
                    file: attachment.id.toString(),
                },
            });
            handleRefreshAndClose();
            return;
        }
        void confirm({
            type: 'warning',
            title: t`Do you want to stop sharing the file with the workspace?`,
            onConfirm: async () => {
                await removeWorkspace({ attachmentId: attachment.id, workspaceId: currentWorkspace.id });
                handleRefreshAndClose();
            },
        });
    };

    return (
        <>
            {(attachment.tasks.length > 0 || attachment.meetingBlocks.length > 0) && (
                <Switch.Group>
                    <div className="mx-4 my-6 flex items-center justify-between">
                        <Switch.Label>
                            <Trans>
                                Shared in <b>{currentWorkspace?.name}</b>
                            </Trans>
                        </Switch.Label>
                        <Switch checked={switchValue} onChange={handleChange} />
                    </div>
                </Switch.Group>
            )}
        </>
    );
};
