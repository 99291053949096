import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ZUSTAND_STORAGE } from 'Pages/TasksPage/utils/zustandStorage';
import { ExportTasksDensity, ExportTasksOrientation } from 'Shared/services/task';

export type PdfColumns =
    | 'name'
    | 'startDate'
    | 'tags'
    | 'dueDate'
    | 'priority'
    | 'assignee'
    | 'customFields'
    | 'subTasks'
    | 'description'
    | 'comments'
    | 'attachments';

const defaultPdfColumns = new Set<PdfColumns>([
    'name',
    'dueDate',
    'priority',
    'assignee',
    'subTasks',
    'description',
    'comments',
]);

type PdfExportState = {
    selectedColumns: Set<PdfColumns>;
    orientation: ExportTasksOrientation;
    density: ExportTasksDensity;
    hasSignature: boolean;
    setSelectedColumns: (value: PdfExportState['selectedColumns']) => void;
    setOrientation: (value: PdfExportState['orientation']) => void;
    setDensity: (value: PdfExportState['density']) => void;
    setHasSignature: (value: PdfExportState['hasSignature']) => void;
};

export const useExportTaskPdfStore = create(
    persist<PdfExportState>(
        (set) => ({
            selectedColumns: defaultPdfColumns,
            orientation: 'portrait',
            density: 'medium',
            hasSignature: false,
            setSelectedColumns: (value) => {
                set({ selectedColumns: value });
            },
            setOrientation: (value) => {
                set({ orientation: value });
            },
            setDensity: (value) => {
                set({ density: value });
            },
            setHasSignature: (value) => {
                set({ hasSignature: value });
            },
        }),
        {
            name: 'useTaskExportPdfStore',
            version: 2,
            migrate: (persistedState, version) => {
                if (version < 2 && persistedState.density === 'normal') {
                    persistedState.density = 'medium';
                }
                return persistedState;
            },
            storage: ZUSTAND_STORAGE,
        }
    )
);
