import { HttpMethod, NumberRange } from '@wedo/types';
import { baseApi, configureTag } from 'Shared/services/base';
import { CacheTag } from 'Shared/services/cacheTag';

export const { tagType: settingsTagType, tag: settingsTag } = configureTag(CacheTag.SETTINGS);

export type NotificationDays = NumberRange<0, 7>;

export type NotificationHour = NumberRange<1, 25>;

export type NotificationHours = Array<NotificationHour>;

const CURRENT_USER_SETTINGS_TAG = settingsTag('current-user-settings');

export interface NotificationSettingsResponse {
    notification_days: {
        id: '1';
        key: 'notification_days';
        value: {
            days: NotificationDays;
        };
        user_id: string;
        created_at: string;
        updated_at: string;
    };
    notification_hours: {
        id: '2';
        key: 'notification_hours';
        value: {
            hours: NotificationHours;
        };
        user_id: string;
        created_at: string;
        updated_at: string;
    };
    periodic_summary: {
        id: '3';
        key: 'periodic_summary';
        value: {
            frequency: 'weekly' | 'daily' | 'none';
        };
        user_id: string;
        created_at: string;
        updated_at: string;
    };
}

export type PeriodicSummary = 'weekly' | 'daily' | 'none';

export interface UpdateNotificationSettingsPayload {
    notification_days: {
        days: NotificationDays;
    };
    notification_hours: {
        hours: NotificationHours;
    };
    periodic_summary: {
        frequency: PeriodicSummary;
    };
}

export const settingsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [settingsTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getSettings: build.query<NotificationSettingsResponse, void>({
                query: () => ({
                    url: 'users/me/settings',
                    method: HttpMethod.Get,
                }),
                providesTags: [CURRENT_USER_SETTINGS_TAG],
            }),

            updateSettings: build.mutation<void, UpdateNotificationSettingsPayload>({
                query: (args) => ({
                    url: 'users/me/settings',
                    method: HttpMethod.Put,
                    body: args,
                }),
                invalidatesTags: [CURRENT_USER_SETTINGS_TAG],
            }),
        }),
    });

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
