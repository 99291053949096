import { useParams } from 'react-router-dom';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { type GanttPageParams } from './GanttPage';

export const useSubTasks = (taskId: string, isEnabled: boolean) => {
    const { workspaceId } = useParams<GanttPageParams>();

    const { data: subTasks, isLoading } = trpc.task.gantt.listSubTasks.useQuery(
        { parentTaskId: taskId, workspaceId: workspaceId! },
        { enabled: isEnabled, meta: { tags: [taskQueryTag.updated(taskId, 'subTasks')] } }
    );

    return { subTasks, isLoading };
};
