import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Button, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import {
    ConfirmUsersReturn,
    UploadUsersReturn,
    useConfirmUsersMutation,
    useValidateUsersMutation,
} from 'Shared/services/admin';
import { trpcUtils } from 'Shared/trpc';

type VerifyUploadButtonProps = {
    users: UploadUsersReturn[];
    onError: (users: UploadUsersReturn[]) => void;
    onSuccess: (result: ConfirmUsersReturn) => void;
};

export const VerifyUploadButton: FC<VerifyUploadButtonProps> = ({ users, onError, onSuccess }) => {
    const [validate, validateStatus] = useValidateUsersMutation();
    const [confirm, confirmStatus] = useConfirmUsersMutation();
    const { show } = useNotification();

    const isLoading = validateStatus.isLoading || confirmStatus.isLoading;

    const handleConfirm = async () => {
        const result = await confirm({ users });

        if ('data' in result) {
            onSuccess(result.data);
        } else if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleValidate = async () => {
        const result = await validate({ users });

        if ('data' in result) {
            if ((result.data as { status: string }).status !== undefined) {
                await handleConfirm();
                await trpcUtils().user.list.invalidate();
            } else {
                onError(result.data as UploadUsersReturn[]);
            }
        } else if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Button color="primary" onClick={handleValidate} loading={isLoading}>
            <Trans>Launch import</Trans>
        </Button>
    );
};
