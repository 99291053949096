import { isEqual } from 'lodash-es';
import { Id } from '@wedo/types';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';

type UseHasUserMadeChangesProps = {
    selectedTasks: Task[];
    assignee: User;
    priority: Task['priority'];
    plannedDate: string;
    dueDate: string;
    sectionId: Id;
    relativePlannedDate: number;
    relativeDueDate: number;
    workspaceIds: Array<Id>;
    watcherIds: Array<Id>;
};

export const useHasUserMadeChanges = ({
    assignee,
    priority,
    plannedDate,
    selectedTasks,
    dueDate,
    sectionId,
    relativePlannedDate,
    relativeDueDate,
    workspaceIds,
    watcherIds,
}: UseHasUserMadeChangesProps) => {
    const {
        commonAssignee,
        commonPriority,
        commonPlannedDate,
        commonDueDate,
        commonRelativePlannedDate,
        commonRelativeDueDate,
        commonSectionId,
        commonWorkspaceIds,
        commonWatcherIds,
    } = useBulkTasksEditPane(selectedTasks);

    const hasUserChangedAssignee = assignee !== undefined;
    const hasUserChangedPriority = priority !== undefined;
    const hasUserChangedPlannedDate = plannedDate !== undefined;
    const hasUserChangedDueDate = dueDate !== undefined;
    const hasUserChangedRelativePlannedDate = relativePlannedDate !== undefined;
    const hasUserChangedRelativeDueDate = relativeDueDate !== undefined;
    const hasUserChangedSectionId = sectionId !== undefined;
    const hasUserChangedWorkspaceIds = !isEqual(new Set(workspaceIds), new Set(commonWorkspaceIds));
    const hasUserChangedWatchers = !isEqual(new Set(watcherIds), new Set(commonWatcherIds));

    const hasUserChangedAssigneeStrict = hasUserChangedAssignee && !isEqual(assignee, commonAssignee);
    const hasUserChangedPriorityStrict = hasUserChangedPriority && !isEqual(priority, commonPriority);
    const hasUserChangedPlannedDateStrict = hasUserChangedPlannedDate && !isEqual(plannedDate, commonPlannedDate);
    const hasUserChangedDueDateStrict = hasUserChangedDueDate && !isEqual(dueDate, commonDueDate);
    const hasUserChangedRelativePlannedDateStrict =
        hasUserChangedRelativePlannedDate && !isEqual(relativePlannedDate, commonRelativePlannedDate);
    const hasUserChangedRelativeDueDateStrict =
        hasUserChangedRelativeDueDate && !isEqual(relativeDueDate, commonRelativeDueDate);
    const hasUserChangedSectionIdStrict = hasUserChangedSectionId && !isEqual(sectionId, commonSectionId);

    const hasUserMadeChanges =
        hasUserChangedAssignee ||
        hasUserChangedPriority ||
        hasUserChangedPlannedDate ||
        hasUserChangedDueDate ||
        hasUserChangedRelativePlannedDate ||
        hasUserChangedRelativeDueDate ||
        hasUserChangedSectionId ||
        hasUserChangedWorkspaceIds ||
        hasUserChangedWatchers;

    return {
        hasUserChangedAssignee,
        hasUserChangedPriority,
        hasUserChangedPlannedDate,
        hasUserChangedDueDate,
        hasUserChangedRelativePlannedDate,
        hasUserChangedRelativeDueDate,
        hasUserChangedSectionId,
        hasUserChangedWorkspaceIds,
        hasUserChangedWatchers,
        hasUserChangedAssigneeStrict,
        hasUserChangedPriorityStrict,
        hasUserChangedPlannedDateStrict,
        hasUserChangedDueDateStrict,
        hasUserChangedRelativePlannedDateStrict,
        hasUserChangedRelativeDueDateStrict,
        hasUserChangedSectionIdStrict,
        hasUserMadeChanges,
    };
};
