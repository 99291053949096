import { t } from '@lingui/macro';
import { Dropdown, FormatDate, Tooltip } from '@wedo/design-system';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const OccurrencesDropdownLinkItems = ({ occurrences }: { occurrences: Partial<MeetingTopic>[] }) => {
    return occurrences?.map((occurrence) => (
        <Dropdown.LinkItem
            key={`occurrences_${occurrence.meeting.id}_${occurrence.id}`}
            to={{
                pathname: `/meetings/${occurrence.meeting.id}`,
                searchParams: { topicId: occurrence.id },
            }}
        >
            <Tooltip content={t`This meeting is locked`}>
                <FormatDate format={'PPP'} date={new Date(occurrence.meeting?.start_at)} />
            </Tooltip>
        </Dropdown.LinkItem>
    ));
};
