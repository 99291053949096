import React from 'react';
import { TokenCard } from 'Pages/settings/api/components/TokenCard/TokenCard';
import { WebhookCard } from 'Pages/settings/api/components/WebhookCard/WebhookCard';
import { Can } from 'Shared/components/Can';
import { Permission } from 'Shared/utils/rbac';

// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions, react/function-component-definition
export function ApiSettingsPage(): JSX.Element {
    return (
        <Can permission={Permission.AccessAllSettings} showNoAccess>
            <div className="flex flex-col gap-8">
                <TokenCard />
                <WebhookCard />
            </div>
        </Can>
    );
}
