import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { addMinutes, isAfter } from 'date-fns';
import { Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { formatTime } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useSelectedTopicId } from 'Pages/meeting/components/MeetingView/MeetingView';
import { MeetingTopicSubmissionItem, SubmissionsSection } from 'Pages/meeting/components/TableOfContents/utils';
import { SubmittedTopicDropdown } from 'Shared/components/meeting/topicView/SubmittedTopicDropdown';
import { TopicPresenter } from 'Shared/components/meeting/topicView/TopicPresenter';
import {
    MeetingPermission,
    useUserHasMeetingPermission,
    useUserHasMeetingSectionPermission,
} from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { TopicDropdown } from '../../TopicDropdown';
import { getTopicRowClasses, rowNumberClasses, rowTitleClasses } from '../Topic';
import { TopicStatusIcon } from '../TopicStatusIcon';
import { TocElementInput } from './TocElement';

export const TopicDuration = ({
    duration,
    startAt,
    meetingEndDate,
}: {
    duration: number;
    startAt: Date;
    meetingEndDate: Date;
}) => {
    return (
        <Tooltip content={duration + t`min`}>
            <div
                className={clsx(
                    'mx-1 text-xs font-bold text-gray-600',
                    isAfter(addMinutes(startAt, duration), meetingEndDate) && 'text-red-500'
                )}
            >
                {formatTime(startAt)}
            </div>
        </Tooltip>
    );
};

type Props = {
    topic: MeetingTopic;
    onTopicClick: (MeetingTopic: MeetingTopic) => void;
    onChange: (id: Id, changes: Partial<MeetingTopic>) => void;
    canRename?: boolean;
    isError?: boolean;
    isDragging?: boolean;
    displayId: string;
    lastAddedTopic: Id;
    handleTopicRef: (topicId: Id) => (element: HTMLDivElement) => void;
    showDuration: boolean;
};

export const TableOfContentsTopic = ({
    topic,
    displayId,
    isDragging = false,
    onChange,
    canRename = true,
    isError = false,
    onTopicClick,
    lastAddedTopic,
    handleTopicRef,
    showDuration = false,
}: Props) => {
    const selectedTopicId = useSelectedTopicId();

    const { meeting } = useMeetingContext();
    const { currentUser } = useCurrentUserContext();

    const { hasPermission: canManageTopic } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.MANAGE_TOPIC
    );

    const { hasPermission: currentUserCanManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const [isEditing, setIsEditing] = useState(false);
    const [currentTitleInputValue, setCurrentTitleInputValue] = useState(topic?.title);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleEditComplete = () => {
        onChange(topic.id, { title: currentTitleInputValue });
        setIsEditing(false);
    };

    useEffect(() => {
        if (topic?.title.length === 0 && lastAddedTopic === topic.id) {
            setIsEditing(true);
        }
    }, [lastAddedTopic]);

    return (
        <div
            className={clsx(
                getTopicRowClasses({ isError, isDragging, selected: !isDragging && selectedTopicId === topic.id })
            )}
        >
            <div
                ref={handleTopicRef(topic.id)}
                className="relative flex flex-grow items-start gap-2"
                id={`toc-topic-${topic.id}`}
            >
                <button
                    className="absolute -bottom-1 -left-1 -right-1 -top-1 w-full"
                    onClick={() => onTopicClick(topic)}
                    onDoubleClick={() => canRename && setIsEditing(canManageTopic)}
                />
                <TopicStatusIcon topic={topic} className="shrink-0 mt-0.5" />
                {!meeting?.settings?.hide_topic_numbering && <div className={rowNumberClasses}>{displayId}</div>}
                {isEditing ? (
                    <TocElementInput
                        label={t`Topic name`}
                        initialValue={topic?.title}
                        currentValue={currentTitleInputValue}
                        setCurrentValue={setCurrentTitleInputValue}
                        onComplete={handleEditComplete}
                    />
                ) : (
                    <div className={clsx(rowTitleClasses)}>{topic.title}</div>
                )}
                <div className="flex items-center gap-1 flex-wrap justify-center">
                    {topic.presenters?.length > 0 && (
                        <div className="pt-0.5">
                            <TopicPresenter
                                meetingId={topic.meeting_id}
                                topicId={topic.id}
                                canEdit={false}
                                size="xs"
                                hideName={true}
                            />
                        </div>
                    )}
                    {showDuration && topic?.start_at && (
                        <TopicDuration
                            duration={topic?.duration}
                            startAt={new Date(topic?.start_at)}
                            meetingEndDate={new Date(meeting?.end_at)}
                        />
                    )}
                </div>
            </div>

            {topic?.can_manage_topic &&
                topic.meeting_section_id === SubmissionsSection &&
                currentUserCanManageMeeting && (
                    <div className="self-start mt-0.5">
                        <SubmittedTopicDropdown
                            topicId={topic.id}
                            submissionId={(topic as unknown as MeetingTopicSubmissionItem).submissionId}
                            size="xs"
                            variant="text"
                        />
                    </div>
                )}

            {topic?.can_manage_topic && topic.meeting_section_id !== SubmissionsSection && (
                <div className="self-start mt-0.5">
                    <TopicDropdown topic={topic} displayMode="toc" onEdit={handleEdit} />
                </div>
            )}
        </div>
    );
};
