import { ComponentPropsWithRef, forwardRef, MouseEventHandler, useMemo } from 'react';
import { NavLink as RouterNavLink, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { EmptyString, getLocalStorageSearchParams, stringifySearchParams } from '@wedo/utils';
import { useMatch } from '@wedo/utils/hooks';

const prefixPath = (prefix: string = '', path: string = '') => {
    const prefixedPath = prefix + path;
    return prefixedPath !== '' ? prefixedPath : undefined;
};

export type NavLinkOptions = {
    to: string | { pathname?: string; searchParams?: Record<string, string | string[]> };
    prefix?: string;
    matchPathname?: string;
    matchSearchParams?: string[];
    keepSearchParams?: string[];
    isDefault?: boolean;
};

export type NavLinkProps = NavLinkOptions & {
    className?: string | ((isActive: boolean) => string);
    disabled?: boolean;
} & Omit<ComponentPropsWithRef<'a'>, 'className'>;

export const getPathNameFromTo = (to: NavLinkProps['to']): string => {
    if (typeof to === 'string') {
        return to;
    }
    return to?.pathname;
};

export const useNavLinkOptions = ({
    to,
    keepSearchParams,
    matchSearchParams,
    matchPathname,
    isDefault,
    prefix = EmptyString,
}: NavLinkOptions) => {
    const [currentSearchParams] = useSearchParams();

    const pathname = typeof to === 'string' ? prefix + to : prefixPath(prefix, to?.pathname);
    const searchParams = typeof to === 'string' ? undefined : to?.searchParams;

    const combinedSearchParams = useMemo(() => {
        const result = { ...searchParams };
        if (!Array.isArray(keepSearchParams)) {
            return result;
        }
        for (const param of keepSearchParams) {
            result[param] = currentSearchParams.getAll(param);
        }
        return result;
    }, [currentSearchParams, searchParams, keepSearchParams]);

    const match = useMatch(
        matchPathname != null ? prefixPath(prefix, matchPathname) : pathname,
        searchParams,
        matchSearchParams,
        isDefault
    );

    return { to: { pathname, searchParams: combinedSearchParams }, isMatch: match };
};

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
    (
        {
            to,
            prefix = '',
            matchPathname,
            matchSearchParams,
            keepSearchParams,
            isDefault,
            className,
            children,
            disabled = false,
            ...props
        },
        ref
    ) => {
        const {
            to: { pathname, searchParams },
            isMatch,
        } = useNavLinkOptions({ to, prefix, keepSearchParams, matchSearchParams, isDefault, matchPathname });

        const localStorageSearchParams = getLocalStorageSearchParams(pathname);

        const computedTo = {
            pathname,
            search: stringifySearchParams({ ...searchParams, ...localStorageSearchParams }),
        };

        const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
            if (disabled) {
                e.stopPropagation();
                e.preventDefault();
            }
        };

        return (
            <RouterNavLink
                {...props}
                onClick={handleClick}
                ref={ref}
                to={computedTo}
                className={typeof className === 'function' ? clsx(className(isMatch)) : className}
            >
                {children}
            </RouterNavLink>
        );
    }
);
