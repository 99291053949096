import { useMemo, useState } from 'react';
import { faChevronDown, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { Plural, Trans } from '@lingui/macro';
import { Dropdown, ExtendableSearchInput, Label } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { isEmpty } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { JoinRequestsTable, JoinRequestsTableProps } from 'Shared/components/team/JoinRequestsTable';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { ManageMembersTable } from 'Shared/components/userGroup/ManageMembersTable';
import { useIsUserGroupModerator } from 'Shared/hooks/useIsUserGroupModerator';
import { useGetTeamQuery } from 'Shared/services/team';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

export type PrivacyType = 'public' | 'private';

export type ManageMembersModalBodyProps = {
    entity: Workspace | Template | Team;
    isReadOnly?: boolean;
    canRemoveMyself?: boolean;
    onToggleModerator: (userId: Id, value: boolean) => Promise<boolean>;
    onAddUser: (user: User) => Promise<boolean>;
    onDeleteUser: (user: User) => Promise<boolean>;
    onPrivacyChange?: (privacy: PrivacyType) => Promise<boolean>;
    tableBodyClassName?: string;
} & JoinRequestsTableProps;

export const ManageMembersModalBody = ({
    entity,
    onAddUser,
    onDeleteUser,
    onToggleModerator,
    onPrivacyChange,
    joinRequests,
    onAcceptRequest,
    onRefuseRequest,
    isReadOnly = false,
    canRemoveMyself = true,
    tableBodyClassName,
}: ManageMembersModalBodyProps) => {
    const sessionUser = useSessionUser();

    const [search, setSearch] = useState('');

    const { isLoading: isPrivacyChanging, wrap: wrapPrivacyChange } = useLoader();
    const { isLoading: isAddingUser, wrap: wrapUserAdd } = useLoader();

    const { data: team } = useGetTeamQuery({ teamId: entity?.team_id }, { skip: entity?.team_id === undefined });

    const usersToHide = useMemo(() => {
        const members = entity?.private
            ? entity?.userGroup?.members ?? []
            : (team?.userGroup?.members ?? []).concat(entity?.userGroup?.members ?? []);
        return members.map(({ user }) => user);
    }, [entity?.userGroup?.members, team?.userGroup?.members]);

    const isModerator = useIsUserGroupModerator(sessionUser, entity);

    const handleAddUser = async (user: User): Promise<boolean> => {
        await wrapUserAdd(onAddUser(user));
    };

    const handlePrivacyChange = (updatedPrivacy: PrivacyType) => async () => {
        if ((entity.private && updatedPrivacy === 'public') || (!entity.private && updatedPrivacy === 'private')) {
            await wrapPrivacyChange(onPrivacyChange(updatedPrivacy));
        }
    };

    return (
        <>
            {team != null && (
                <div className="flex justify-between mt-2 mb-6 border p-2 rounded-md items-center">
                    <div className="flex gap-2">
                        <TeamIcon size="lg" className="self-center" team={team} />
                        <div className="flex flex-col">
                            <div className="text-sm">
                                {entity.private ? (
                                    <Trans>Members of {team.name} team don't have access</Trans>
                                ) : (
                                    <Trans>Members of {team.name} team have access</Trans>
                                )}
                            </div>
                            <div className="text-left text-xs text-gray-600">
                                <Plural
                                    value={team.userGroup.members.length}
                                    one="1 member"
                                    other={`${team.userGroup.members.length} members`}
                                />
                            </div>
                        </div>
                    </div>

                    <Dropdown
                        size="sm"
                        icon={faChevronDown}
                        iconPosition="end"
                        isLoading={isPrivacyChanging}
                        disabled={!isModerator}
                        label={entity.private ? <Trans>No access</Trans> : <Trans>Full access</Trans>}
                    >
                        <Dropdown.Item selected={!entity.private} onClick={handlePrivacyChange('public')}>
                            <Trans>Full access</Trans>
                        </Dropdown.Item>
                        <Dropdown.Item selected={entity.private} onClick={handlePrivacyChange('private')}>
                            <Trans>No access</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            )}

            <div className="mb-2 flex items-center justify-between">
                <Label>
                    <Trans>Members</Trans>
                </Label>

                <div className="flex gap-2">
                    <ExtendableSearchInput onSearch={setSearch} search={search} delay={0} />
                    {isEmpty(search) && !isReadOnly && (
                        <UserPicker
                            keepOpenAfterSelection
                            onUserSelected={handleAddUser}
                            loading={isAddingUser}
                            usersToHide={usersToHide}
                            icon={faUserPlus}
                            color="primary"
                            disabled={isReadOnly}
                        >
                            <Trans>Add member</Trans>
                        </UserPicker>
                    )}
                </div>
            </div>

            <ManageMembersTable
                tableBodyClassName={tableBodyClassName}
                isReadOnly={isReadOnly}
                canRemoveMyself={canRemoveMyself}
                search={search}
                entity={entity}
                onUserDelete={onDeleteUser}
                onModeratorToggle={onToggleModerator}
            />

            {isModerator && (
                <JoinRequestsTable
                    className="mt-6"
                    joinRequests={joinRequests}
                    onAcceptRequest={onAcceptRequest}
                    onRefuseRequest={onRefuseRequest}
                />
            )}
        </>
    );
};
