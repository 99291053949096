import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { Organization } from 'Shared/types/organization';

export const IndustryOptions = new Map<Organization['cf_industry'], MessageDescriptor>([
    ['marketing-digital-agency', msg`Marketing / Digital Agency`],
    ['media-entertainment', msg`Media & entertainment`],
    ['software-technology', msg`Software / Technology`],
    ['consulting', msg`Consulting`],
    ['construction', msg`Construction`],
    ['education', msg`Education`],
    ['energy-utilities', msg`Energy & utilities`],
    ['financial-services', msg`Financial Services`],
    ['health-medical', msg`Health / Medical`],
    ['legal-services-law-practice', msg`Legal Services / Law Practice`],
    ['manufacturing', msg`Manufacturing`],
    ['non-profit-organization', msg`Non-profit organization`],
    ['public-sector', msg`Public Sector`],
    ['real-estate', msg` Real Estate`],
    ['research-development', msg`Research & Development`],
    ['retail', msg`Retail`],
    ['tourism-hospitality', msg`Tourism / hospitality`],
    ['telecommunications', msg`Telecommunications`],
    ['other', msg`Other`],
]);

export const CompanySizeOptions = ['1-10', '11-50', '51-100', '101-500', '501-2500', '2500+'] as const;
