import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { MutableRefObject, useEffect, useRef } from 'react';
import { faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { sortBy } from 'lodash-es';
import { Button, getIconFromName, ItemGroup, Skeleton, Tooltip, useConfirm, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskCustomFieldGroupModal } from 'Shared/components/task/TaskDetail/modals/TaskCustomFieldGroupModal';
import { TASK_DETAIL_ICON_CLASS } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { getCustomFieldGroupValueLabel } from 'Shared/components/task/TaskDetail/shared/getCustomFieldGroupValueLabel';
import { useTask } from 'Shared/hooks/useTask';
import { useGetCustomFieldGroupsQuery } from 'Shared/services/customFields';
import {
    useGetCustomFieldGroupValuesQuery,
    useRemoveTaskCustomFieldGroupValueMutation,
} from 'Shared/services/taskCustomFieldGroupValue';
import { CustomFieldGroup, CustomFieldGroupValue } from 'Shared/types/customField';
import { TaskDetailRow } from '../shared/TaskDetailRow';

const TaskDetailCustomFieldGroup = ({
    taskId,
    customFieldGroup,
    values = [],
}: {
    taskId: Id;
    customFieldGroup: CustomFieldGroup;
    values: CustomFieldGroupValue[];
    newlyAddedGroupValueRef: MutableRefObject<CustomFieldGroupValue | null>;
}) => {
    const { isTaskReadonly } = useTask(taskId);
    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const { confirm: showConfirm } = useConfirm();

    const [removeTaskCustomFieldGroupValue] = useRemoveTaskCustomFieldGroupValueMutation();

    const removeGroupValue = async (value: CustomFieldGroupValue) => {
        await showConfirm({
            title: t`Delete custom field group`,
            confirmText: t`Delete`,
            content: t`Are you sure you want to delete this custom field group?`,
            type: 'danger',
            onConfirm: () =>
                removeTaskCustomFieldGroupValue({
                    taskId: taskId,
                    customFieldGroupId: customFieldGroup.id,
                    customFieldGroupValueId: value.id,
                }).unwrap(),
        });
    };

    return (
        <div className={'flex flex-wrap gap-2'}>
            {values?.map((value) => (
                <ItemGroup key={value.id} className={'max-w-full shrink'}>
                    <Button
                        size="sm"
                        className={'!inline-block max-w-full shrink truncate'}
                        onClick={() =>
                            openModal(TaskCustomFieldGroupModal, {
                                taskId,
                                customFieldGroupId: customFieldGroup.id,
                                customFieldGroupValueId: value.id,
                            })
                        }
                    >
                        {getCustomFieldGroupValueLabel(value, customFieldGroup, i18n)}
                    </Button>
                    <Button
                        aria-label={t`Delete custom field group`}
                        size="sm"
                        disabled={isTaskReadonly}
                        className={'shrink-0'}
                        icon={faXmark}
                        onClick={() => removeGroupValue(value)}
                    />
                </ItemGroup>
            ))}
            {((isTaskReadonly && values?.length === 0) ||
                (!isTaskReadonly && (values?.length === 0 || customFieldGroup?.multiple_values))) && (
                <Button
                    className={clsx('!max-w-full', values.length > 0 && ' !justify-start')}
                    disabled={isTaskReadonly}
                    variant={values.length > 0 ? 'filled' : 'text'}
                    icon={values.length > 0 && faPlus}
                    size={values.length > 0 ? 'sm' : 'md'}
                    title={values.length > 0 && t`Add ${customFieldGroup?.label}`}
                    onClick={() =>
                        openModal(TaskCustomFieldGroupModal, { taskId, customFieldGroupId: customFieldGroup.id })
                    }
                >
                    {values.length === 0 ? (
                        <div className={'text-select-none truncate'}>{t`Add ${customFieldGroup?.label}`}</div>
                    ) : undefined}
                </Button>
            )}
        </div>
    );
};

type TaskDetailCustomFieldGroupsProps = {
    taskId: Id;
};

export const TaskDetailCustomFieldGroups = ({ taskId }: TaskDetailCustomFieldGroupsProps) => {
    const { task } = useTask(taskId);
    const newlyAddedGroupValue = useRef(null); // Used for opening a modal whenever a new group value is added

    const { open: openModal } = useModal();

    const { data: customFieldGroups } = useGetCustomFieldGroupsQuery(null, {
        selectFromResult: ({ data = [] }) => ({
            data: sortBy(
                data.filter((customFieldGroup) => {
                    return (
                        customFieldGroup.is_global ||
                        task?.tags.some((taskWorkspace) =>
                            customFieldGroup.tags.some(({ id }) => id === taskWorkspace?.id)
                        ) ||
                        customFieldGroup.checklists.some(({ id }) => id === task?.checklist_id)
                    );
                }),
                ['order', 'id']
            ),
        }),
    });
    const { data: customFieldGroupValues, currentData = null } = useGetCustomFieldGroupValuesQuery(taskId, {
        skip: !taskId,
    });

    const isCustomFieldValueLoading = currentData === null;

    useEffect(() => {
        if (newlyAddedGroupValue.current != null) {
            openModal(TaskCustomFieldGroupModal, {
                customFieldGroupId: newlyAddedGroupValue.current.custom_field_group_id,
                customFieldGroupValueId: newlyAddedGroupValue.current.id,
            });
            newlyAddedGroupValue.current = null;
        }
    }, [newlyAddedGroupValue.current?.id]);

    return (
        <>
            {customFieldGroups?.map((customFieldGroup) => {
                const values = (customFieldGroupValues ?? []).filter(
                    (value) => value.custom_field_group_id === customFieldGroup.id
                );
                if (customFieldGroup.archived && values.length === 0) {
                    return null;
                }

                return (
                    <TaskDetailRow key={customFieldGroup.id}>
                        <TaskDetailRow.IconWrapper>
                            <Tooltip content={customFieldGroup.label}>
                                <FontAwesomeIcon
                                    className={clsx(
                                        values.length > 0 ? 'text-gray-500' : 'text-gray-400',
                                        TASK_DETAIL_ICON_CLASS
                                    )}
                                    icon={getIconFromName(customFieldGroup?.icon)}
                                />
                            </Tooltip>
                        </TaskDetailRow.IconWrapper>
                        {isCustomFieldValueLoading && <Skeleton className="h-8" />}
                        {!isCustomFieldValueLoading && (
                            <TaskDetailRow.Content>
                                <TaskDetailCustomFieldGroup
                                    taskId={taskId}
                                    customFieldGroup={customFieldGroup}
                                    values={values}
                                    newlyAddedGroupValueRef={newlyAddedGroupValue}
                                />
                            </TaskDetailRow.Content>
                        )}
                        <TaskDetailRow.Addon />
                    </TaskDetailRow>
                );
            })}
        </>
    );
};
