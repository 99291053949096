import { Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const SlackIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Path
                d="M457.927 236.766C484.477 236.766 506 215.243 506 188.693V188.693C506 162.143 484.475 140.62 457.925 140.62V140.62C431.373 140.62 409.846 162.145 409.846 188.697V236.766H457.927ZM323.308 236.766C349.86 236.766 371.385 215.241 371.385 188.689V54.0815C371.385 27.5294 349.86 6.00464 323.308 6.00464V6.00464C296.756 6.00464 275.231 27.5294 275.231 54.0816V188.689C275.231 215.241 296.756 236.766 323.308 236.766V236.766Z"
                fill="#2EB67D"
            />
            <Path
                d="M54.0728 275.234C27.523 275.234 6.00004 296.757 6.00004 323.307V323.307C6.00004 349.857 27.525 371.38 54.0749 371.38V371.38C80.627 371.38 102.154 349.855 102.154 323.303V275.234H54.0728ZM188.692 275.234C162.14 275.234 140.615 296.759 140.615 323.311V457.918C140.615 484.471 162.14 505.995 188.692 505.995V505.995C215.245 505.995 236.769 484.471 236.769 457.918V323.311C236.769 296.759 215.245 275.234 188.692 275.234V275.234Z"
                fill="#E01E5A"
            />
            <Path
                d="M275.233 457.927C275.233 484.477 296.756 506 323.306 506V506C349.856 506 371.378 484.475 371.378 457.925V457.925C371.378 431.373 349.854 409.846 323.302 409.846L275.233 409.846L275.233 457.927ZM275.233 323.308C275.233 349.86 296.758 371.385 323.31 371.385L457.917 371.385C484.469 371.385 505.994 349.86 505.994 323.308V323.308C505.994 296.755 484.469 275.231 457.917 275.231L323.31 275.231C296.758 275.231 275.233 296.755 275.233 323.308V323.308Z"
                fill="#ECB22E"
            />
            <Path
                d="M236.767 54.0729C236.767 27.523 215.244 6.00005 188.694 6.00005V6.00005C162.144 6.00005 140.622 27.525 140.622 54.0749V54.0749C140.622 80.6271 162.146 102.154 188.698 102.154L236.767 102.154L236.767 54.0729ZM236.767 188.692C236.767 162.14 215.242 140.615 188.69 140.615L54.083 140.615C27.5308 140.615 6.00611 162.14 6.00611 188.692V188.692C6.0061 215.245 27.5309 236.769 54.083 236.769L188.69 236.769C215.242 236.769 236.767 215.245 236.767 188.692V188.692Z"
                fill="#36C5F0"
            />
        </Svg>
    );
};
