import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Card, Skeleton, useModal } from '@wedo/design-system';
import { ChargebeeInvoice } from '@wedo/types';
import { AllInvoicesModal } from 'Pages/settings/billing/components/AllInvoicesModal/AllInvoicesModal';
import { InvoiceItem } from 'Pages/settings/billing/components/InvoicesCard/InvoiceItem';
import { cardFooterPosition } from 'Pages/settings/billing/utils/style';

type InvoicesCardProps = {
    invoices: Array<ChargebeeInvoice>;
};

export const InvoicesCard: React.FC<InvoicesCardProps> = ({ invoices }) => {
    const { open } = useModal();

    if (!invoices) {
        return <Skeleton className="h-64" />;
    }

    return (
        <Card>
            <Card.Header title={t`Invoices`} />

            <Card.Body className={'!px-2'}>
                <div className="-mt-2 w-full gap-2 divide-y">
                    {invoices.slice(0, 5).map((invoice) => (
                        <InvoiceItem key={invoice.id} invoice={invoice} />
                    ))}
                </div>
            </Card.Body>

            {invoices.length > 0 && (
                <Card.Footer className={cardFooterPosition}>
                    <Button onClick={() => open(AllInvoicesModal)} size="sm">
                        <Trans>See all invoices</Trans>
                    </Button>
                </Card.Footer>
            )}
        </Card>
    );
};
