import React, { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { GoogleCalendarHelpLink } from 'Shared/components/calendar/CalendarSync/CalendarHelpLink/GoogleCalendarHelpLink';
import { OutlookCalendarHelpLink } from 'Shared/components/calendar/CalendarSync/CalendarHelpLink/OutlookCalendarHelpLink';
import { CalendarSyncItem } from 'Shared/components/calendar/CalendarSync/CalendarSyncItem';
import { CopyLinkSection } from 'Shared/components/calendar/CalendarSync/CopyLinkSection/CopyLinkSection';
import { SyncItemSection } from 'Shared/components/calendar/CalendarSync/SyncItemSection/SyncItemSection';
import { trpc } from 'Shared/trpc';

type CalendarSyncProps = {
    workspaceId?: Id;
};

export const CalendarSync: FC<CalendarSyncProps> = ({ workspaceId }) => {
    const { currentUser } = useCurrentUserContext();

    const [itemIncluded, setItemIncluded] = useState<Set<CalendarSyncItem>>(
        new Set([CalendarSyncItem.TaskDue, CalendarSyncItem.Meeting])
    );

    const { data: hash } = trpc.ical.getCurrentUserHash.useQuery(workspaceId ?? currentUser?.id);

    return (
        <>
            <SyncItemSection onChange={setItemIncluded} itemIncluded={itemIncluded} />

            <CopyLinkSection
                hash={hash}
                userId={currentUser?.id}
                itemIncluded={itemIncluded}
                additionalParams={workspaceId != null ? new Map([['tag_id', String(workspaceId)]]) : null}
            />

            <h4 className="my-4 text-base font-semibold">
                <Trans>How to import in your calendar</Trans>
            </h4>

            <div className="mb-8 flex flex-wrap gap-4">
                <OutlookCalendarHelpLink />
                <GoogleCalendarHelpLink />
            </div>

            <Alert type={'info'} title={t`How it works`}>
                <Trans>
                    The initial sync to your calendar should be instant. Updates in WEDO may take time to reach your
                    calendar.
                </Trans>
            </Alert>

            <Alert type={'warning'} title={t`Warning`} className="mt-4">
                <Trans>Anyone with this link has access to your tasks, so be careful how you share it.</Trans>
            </Alert>
        </>
    );
};
