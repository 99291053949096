import React, { FC, PropsWithChildren, useMemo } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { ProgressBar, Steps } from '@wedo/design-system';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';

const OnboardingButtons: FC<{ children: React.ReactNode[] | React.ReactNode; reverse?: boolean }> = ({
    children,
    reverse = false,
}) => {
    if (Array.isArray(children) && children.length > 1) {
        return (
            <div className={clsx('mt-8 flex ', reverse && 'flex-row-reverse')}>
                {children[0]}
                <div className="grow"></div>
                {children.slice(1)}
            </div>
        );
    }

    return (
        <div className={clsx('mt-8 flex', reverse && 'flex-row-reverse')}>
            <div className="grow"></div>
            {children}
        </div>
    );
};

const OnboardingForm: FC<PropsWithChildren<{ hideSteps?: boolean }>> = ({ children, hideSteps = false }) => {
    const { step, authLink, willContinueWithOrganizationStep } = useOnboarding();

    const currentStepIndex = useMemo(() => {
        switch (step) {
            case 0:
            case 1:
                // to make the step appear as complete once we get the auth_link
                return isEmpty(authLink) ? 1 : 2;
            case 8:
                // to make the step appear as complete once we get the auth_link
                return isEmpty(authLink) ? 2 : 3;
            default:
                return 2;
        }
    }, [step, authLink]);

    const accountCreationProgressPercentage = useMemo(() => {
        switch (step) {
            case 0:
                return 5;
            case 1:
                return isEmpty(authLink) ? 50 : 100;
            default:
                return 100;
        }
    }, [step, authLink]);

    const organisationProgressPercentage = useMemo(() => {
        switch (step) {
            case 0:
            case 1:
                return 0;
            case 2:
            case 3:
                return 5;
            case 4:
                return 20;
            case 5:
                return 40;
            case 6:
                return 60;
            case 7:
                return 80;
            case 8:
                return isEmpty(authLink) ? 90 : 100;
            default:
                return 0;
        }
    }, [step, authLink]);

    return (
        <div className="flex w-[620px] flex-col p-8">
            <img alt={t`WEDO logo`} src="/assets/logo/logo.svg" className="mb-8 w-36" />
            {!hideSteps && (
                <div className="py-2">
                    <Steps currentIndex={currentStepIndex} className={'mb-2 w-full overflow-hidden'}>
                        <Steps.Step className={'overflow-hidden'} index={1}>
                            <>
                                <div className={'mb-1'}>{t`Account`}</div>

                                <ProgressBar
                                    size={'thin'}
                                    color={accountCreationProgressPercentage === 100 ? 'neon' : 'royal'}
                                    percent={accountCreationProgressPercentage}
                                    showPercent={false}
                                />
                            </>
                        </Steps.Step>
                        {willContinueWithOrganizationStep && (
                            <Steps.Step className={'overflow-hidden'} index={2}>
                                <>
                                    <div className={'mb-1'}>{t`Organization`}</div>
                                    {step > 2 && (
                                        <ProgressBar
                                            size={'thin'}
                                            color={organisationProgressPercentage === 100 ? 'neon' : 'royal'}
                                            percent={organisationProgressPercentage}
                                            showPercent={false}
                                        />
                                    )}
                                </>
                            </Steps.Step>
                        )}
                    </Steps>
                </div>
            )}
            <div className="mt-8 flex grow flex-col justify-start">{children}</div>
        </div>
    );
};

export const OnboardingLayoutComponent: FC<PropsWithChildren> = ({ children }) => (
    <div className="min-w-screen flex min-h-screen flex-col bg-white">{children}</div>
);

const OnboardingPreview: FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className="center hidden grow items-center justify-center bg-gradient-to-br from-blue-500 to-blue-700 lg:flex">
            {children}
        </div>
    );
};

const OnboardingPage: FC<{ children: React.ReactNode }> = ({ children }) => {
    return <div className="flex grow justify-center">{children}</div>;
};

export const OnboardingLayout = Object.assign(OnboardingLayoutComponent, {
    Form: OnboardingForm,
    Preview: OnboardingPreview,
    Buttons: OnboardingButtons,
    Page: OnboardingPage,
});
