export const ResetState = Symbol('totp-reset-state');

export const ResetError = Symbol('totp-reset-error');

export type TotpReducerType = (state: TotpType, action: TotpActionType) => TotpType;

export enum StageEnable {
    Password,
    QrCode,
    PhoneNumber,
    SecurityCode,
    Done,
}

export enum StageDisable {
    Password,
    Disable,
    Done,
}

type TotpActionTypeAttribute = {
    type: keyof TotpType;
    value: string | string[] | StageEnable | StageDisable | QrCodeType | boolean;
};

type TotpActionTypeSpecial = {
    type: typeof ResetState | typeof ResetError;
};

export type TotpActionType = TotpActionTypeSpecial | TotpActionTypeAttribute;

export type TotpType = {
    stage: StageEnable | StageDisable;
    error: string;
    password: string;
    qrCode: QrCodeType;
    totp: string;
    phoneNumber: string;
    phoneCode: string;
    securityCode: string[];
    authenticators: string[];
    force2fa: boolean;
};

export type QrCodeType = { image: string; secret: string };
