import { tryOrValue } from './function';

export const numericCompare = new Intl.Collator(undefined, { numeric: true }).compare;

export const tryParseInt = (value: string, defaultValue = 0) => {
    return tryOrValue(() => {
        const parsedValue = parseInt(value, 10);
        return isNaN(parsedValue) ? defaultValue : value;
    }, defaultValue) as number;
};

export const getFormattedNumber = (count: number, langCode: string): string => {
    const formatter = Intl.NumberFormat(langCode, { notation: 'compact' });
    return formatter.format(count);
};

export const isPositive = (value: number): boolean => value >= 0;

export const getPositive = (value: number): number => {
    if (isPositive(value)) return value;
    return -value;
};
