import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Card, Skeleton, useModal } from '@wedo/design-system';
import { SwissCantonCode, ChargebeeBillingAddress } from '@wedo/types';
import { getSwissCantonName } from '@wedo/utils';
import { EditBillingAddressModal } from 'Pages/settings/billing/components/EditBillingAddressModal';
import { cardFooterPosition } from 'Pages/settings/billing/utils/style';

type BillingAddressCardProps = {
    address: ChargebeeBillingAddress;
    swissCanton: SwissCantonCode;
};

export const BillingAddressCard: React.FC<BillingAddressCardProps> = ({ address, swissCanton }) => {
    const { open } = useModal();

    if (!address) {
        return <Skeleton className="h-64" />;
    }

    return (
        <Card>
            <Card.Header title={t`Billing address`} />

            <Card.Body className="text-sm">
                <p>
                    {address.first_name} {address.last_name}
                </p>
                <div className="text-gray-700">
                    <p>{address.company}</p>
                    <p>{address.line1}</p>
                    <p>{address.line2}</p>
                    <p>{address.line3}</p>
                    <p>{getSwissCantonName(swissCanton)}</p>
                    <p>
                        {address.country} - {address.zip} {address.city}
                    </p>
                </div>
            </Card.Body>

            <Card.Footer className={cardFooterPosition}>
                <Button size="sm" onClick={() => open(EditBillingAddressModal)}>
                    <Trans>Edit billing address</Trans>
                </Button>
            </Card.Footer>
        </Card>
    );
};
