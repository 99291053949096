import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { DatePicker, FormatDate, Popover } from '@wedo/design-system';
import { useTasksContext } from 'App/contexts/TasksContext';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';

export const StartDateRow: FC<BulkEditTaskRowProps<string>> = ({ value, onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonPlannedDate } = useBulkTasksEditPane(selectedTasks);

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(undefined);
        } else if (change === 'edit') {
            onChange(commonPlannedDate);
        } else if (change === 'clear') {
            onChange(null);
        }
    };

    return (
        <BulkEditRow
            label={t`Start date`}
            changeOption={changeValue}
            onChangeOptionChange={handleChange}
            isResetDisabled={commonPlannedDate === null}
        >
            <Popover
                className="mt-2"
                text={
                    <>
                        <FontAwesomeIcon icon={faCalendar} />
                        {value ? (
                            <FormatDate format={'PPP'} date={value ? new Date(value) : new Date()} />
                        ) : (
                            t`Choose start date`
                        )}
                    </>
                }
            >
                <div className="bg-white p-2">
                    <DatePicker
                        onChange={(date) => onChange(date.toISOString())}
                        date={value ? new Date(value) : new Date()}
                    />
                </div>
            </Popover>
        </BulkEditRow>
    );
};
