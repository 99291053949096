import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Dropdown, Label, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { storage } from '@wedo/utils';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { OrdersLabels } from 'Pages/TasksPage/components/TasksToolbar/OrderItem';
import { SortDropdownItems } from 'Pages/TasksPage/components/TasksToolbar/SortDropdown';
import { CustomFieldPrefix } from 'Pages/TasksPage/constants';
import { useWorkspaceCustomFields } from 'Pages/TasksPage/hooks/useWorkspaceCustomFields';
import { DisplayPopover } from 'Shared/components/displayPopover/DisplayPopover';
import { KanbanLayout, ListLayout } from 'Shared/components/displayPopover/utils';
import { CustomFieldType } from 'Shared/types/customField';
import { TaskLayout, TaskOrder, TaskStatus } from 'Shared/types/task';

export type TasksListSearchParams = {
    layout: TaskLayout;
    grouping: TaskOrder; // group by
    order: TaskOrder; // sort by
    status: TaskStatus[];
};

export type TaskDisplayPopoverProps = {
    workspaceId?: Id;
    hideLayout?: boolean;
    label?: string;
    params: TasksListSearchParams;
    onParamsChange: (params: TasksListSearchParams) => void;
    onReset: () => void;
};

export const TaskDisplayPopover: FC<TaskDisplayPopoverProps> = ({
    workspaceId,
    label,
    params = { layout: 'list', grouping: 'default', order: 'default', status: ['todo'] },
    onParamsChange,
    onReset,
    hideLayout = false,
}) => {
    const { workspaceId: pageWorkspaceId, checklistId } = useParams<TasksPageParams>();
    const { pathname } = useLocation();
    const { setSelectedTasks } = useTasksContext();

    const { customFields } = useWorkspaceCustomFields(
        workspaceId || pageWorkspaceId,
        checklistId,
        ({ type }) => type === CustomFieldType.Dropdown
    );

    const getLabelFromValue = (value: string, property?: 'grouping' | 'order') => {
        if (property === 'order' && (params.grouping === 'section' || params.grouping === '-section')) {
            return t`Manual`;
        }
        if (isEmpty(value)) {
            return t`By default`;
        }
        const absoluteValue = value.startsWith('-') ? value.slice(1) : value;
        if (absoluteValue.startsWith(CustomFieldPrefix)) {
            return customFields.find((cf) => cf.id === absoluteValue.split(CustomFieldPrefix)[1])?.label;
        }
        return OrdersLabels[absoluteValue];
    };

    const saveSearchParamsInLocalStorage = ({
        order = params.order,
        grouping = params.grouping,
        layout = params.layout,
        status = params.status,
    }: {
        order?: TaskOrder;
        grouping?: TaskOrder;
        layout?: TaskLayout;
        status?: TaskStatus[];
    }) => storage.setItem(pathname, JSON.stringify({ grouping, order, layout, status }));

    const handleGroupingChange = (grouping: TaskOrder) => {
        if (grouping === 'default') {
            onParamsChange({ ...params, order: 'default', grouping });
            saveSearchParamsInLocalStorage({ order: 'default', grouping });
        } else {
            onParamsChange({ ...params, grouping });
            saveSearchParamsInLocalStorage({ grouping });
        }
    };

    const handleOrderChange = (order: TaskOrder) => {
        if (params.grouping === 'default' || params.grouping == null) {
            onParamsChange({ ...params, grouping: order, order });
            saveSearchParamsInLocalStorage({ order, grouping: order });
        } else {
            onParamsChange({ ...params, order });
            saveSearchParamsInLocalStorage({ order });
        }
    };

    return (
        <DisplayPopover onReset={onReset} label={label}>
            {!hideLayout && (
                <>
                    <Label className={'px-4 pt-4 pb-2 text-sm text-gray-600'}>{t`Layout`}</Label>

                    <DisplayPopover.LayoutGrid
                        layout={params.layout}
                        layouts={[ListLayout, KanbanLayout]}
                        onLayoutChange={(layout: TaskLayout) => {
                            setSelectedTasks([]);
                            onParamsChange({ ...params, layout });
                            saveSearchParamsInLocalStorage({ layout });
                        }}
                        className="mx-4"
                    />
                </>
            )}

            <DisplayPopover.Rows className="px-4">
                <DisplayPopover.Row title={t`Group by`}>
                    <Dropdown
                        placement="bottom-end"
                        showChevron
                        size={'sm'}
                        label={getLabelFromValue(params.grouping, 'grouping')}
                    >
                        <SortDropdownItems
                            workspaceId={workspaceId || pageWorkspaceId}
                            onChange={handleGroupingChange}
                            param={'grouping'}
                            value={params.grouping}
                        />
                    </Dropdown>
                </DisplayPopover.Row>

                <DisplayPopover.Row title={t`Sort by`}>
                    <Tooltip
                        content={
                            params.grouping === 'section' || params.grouping === '-section'
                                ? t`When grouping by section, tasks are manually ordered`
                                : undefined
                        }
                    >
                        <Dropdown
                            showChevron
                            placement="bottom-end"
                            disabled={params.grouping === 'section' || params.grouping === '-section'}
                            label={getLabelFromValue(params.order, 'order')}
                            size={'sm'}
                        >
                            <SortDropdownItems
                                workspaceId={workspaceId || pageWorkspaceId}
                                onChange={handleOrderChange}
                                param={'order'}
                                value={params.order}
                            />
                        </Dropdown>
                    </Tooltip>
                </DisplayPopover.Row>
            </DisplayPopover.Rows>
        </DisplayPopover>
    );
};
