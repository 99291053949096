import { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { EmptyFunction } from '@wedo/utils';

export type UseDebounceInputProps = {
    delay?: number;
    value: string | number | readonly string[];
    onChange: ChangeEventHandler;
    onBlur: FocusEventHandler;
    reset?: boolean;
};

export const useDebounceInput = ({
    delay = 500,
    value = '',
    onChange = EmptyFunction,
    onBlur,
    reset = null,
}: UseDebounceInputProps) => {
    const [internalValue, setInternalValue] = useState(value);
    const [oldValue, setOldValue] = useState(value);
    const [oldReset, setOldReset] = useState(reset);
    const [isDebouncing, setIsDebouncing] = useState(false);

    const debouncedOnChange = useDebouncedCallback((e) => {
        setIsDebouncing(false);
        onChange(e);
    }, delay);

    const handleBlur = (e) => {
        debouncedOnChange.flush();
        if (onBlur) {
            onBlur(e);
        }
    };

    const handleChange = (e) => {
        setIsDebouncing(true);
        setInternalValue(e.target.value);
        debouncedOnChange(e);
    };

    if (oldValue !== value) {
        setOldValue(value);

        if (!debouncedOnChange.isPending()) {
            setInternalValue(value);
        }
    }

    if (oldReset !== reset) {
        setOldReset(reset);

        if (!debouncedOnChange.isPending()) {
            setInternalValue(value);
        }
    }

    return {
        internalValue,
        isDebouncing,
        handleChange,
        handleBlur,
    };
};
