import { countBy, flatMap, isFinite as isFiniteNumber } from 'lodash-es';

const regexLevel1 = /^(\d+[.)]|[a-zA-Z]+[.)]|•|||o|[IVX]+\.|[-])(\s+)/;
const regexLevel2 = /^(\d+[.]*)+(\s+)/;

export const TEXT_WITHOUT_BULLETS_REGEX = /^((\d+[.)])+|(\d+[.)]+\d)+|[a-zA-Z]+[.)]|•|||o|[IVX]+\.|-)(\s+)/;

const isIntNumber = (str: string) => {
    return isFiniteNumber(parseInt(str, 10));
};
const detectIndexLevel = (row: string) => {
    const value = row.trim();
    let prefix = value.match(regexLevel1);
    if (!prefix) {
        prefix = value.match(regexLevel2);
    }
    if (prefix) {
        const collection = prefix[0].trim().split('.');
        const count = countBy(collection, isIntNumber);
        if (count && count.true) {
            return count.true;
        }
    }

    let found = regexLevel1.exec(value);
    if (found) {
        return 1;
    }
    found = regexLevel2.exec(value);
    if (found) {
        return 2;
    }

    return 1;
};

export type Item = { title: string; originTitle: string; childItems: Item[] };
export const rowToItem = (row: string): Item => {
    let value = row.trim();
    const originTitle = value;
    value = value.replace(regexLevel1, '');
    value = value.replace(regexLevel2, '');
    value = value.trim();
    const cells = value.split('\t');
    if (cells.length > 0) {
        let name = cells[0].trim();
        name = name.substring(0, 500);
        if (name) {
            return {
                title: name,
                originTitle,
                childItems: [] as Item[],
            };
        }
    }
    return null;
};

const rowsToItems = (rows: string[]) => {
    const items = [] as Item[];
    rows.forEach((row) => {
        if (row !== '' && row !== null) {
            const level = detectIndexLevel(row);
            if (level === 1) {
                if (rowToItem(row)) {
                    items.push(rowToItem(row));
                }
            } else if (items.length > 0 && rowToItem(row)) {
                items[items.length - 1].childItems.push(rowToItem(row));
            } else if (rowToItem(row)) {
                items.push(rowToItem(row));
            }
        }
    });
    return items;
};

export const parseTextData = (text: string, detectChildren: boolean): Item[] => {
    if (!text) {
        return [];
    }
    let rows = text.split('\r\n');
    if (rows.length <= 1) {
        rows = text.split('\n');
    }
    if (rows.length >= 1) {
        let items = rowsToItems(rows);
        if (!detectChildren) {
            items = flatMap(items, (item) => {
                return [{ ...item, childItems: [] }].concat(item.childItems);
            });
        }

        return items;
    }
    return [];
};
