import { sanitizeUrl } from '@braintree/sanitize-url';
import { BLANK_URL } from '@braintree/sanitize-url/dist/constants';
import z from 'zod';

export const isValidUrl = (url: string): boolean => {
    return z
        .string()
        .url()
        .refine((value) => sanitizeUrl(value) !== BLANK_URL)
        .safeParse(url).success;
};
