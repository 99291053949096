import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    Placement,
    shift,
    size as fSize,
    useFloating,
} from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, Transition } from '@headlessui/react';
import {
    Children,
    CSSProperties,
    FC,
    forwardRef,
    Fragment,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { faSpinner, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp, faCheck, faMagnifyingGlass, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { HelperText } from '~/components/HelperText/HelperText';
import { Input, InputStatus } from '~/components/Input/Input';
import { Spinner } from '~/components/Spinner/Spinner';
import { EmptyFunction } from '@wedo/utils';

export type OptionGroupProps = {
    children: ReactNode;
    className?: string;
    label: string;
};

const OptionGroup = ({ label, className, children }: OptionGroupProps) => {
    return (
        <div>
            <div className={clsx('truncate px-2 pb-1 pt-2 font-semibold text-gray-800', className)}>{label}</div>
            <div className="pl-1">{children}</div>
        </div>
    );
};

export type OptionProps = {
    children: ReactNode;
    value: string;
    // eslint-disable-next-line react/boolean-prop-naming
    disabled?: boolean;
    showSelection?: boolean;
    style?: CSSProperties;
    className?: string;
    checkPosition?: 'left' | 'right';
    isLoading?: boolean;
};

const optionClasses = {
    checkPosition: {
        right: 'right-0',
        left: 'left-2',
    },
    container: {
        checkPosition: {
            right: 'pl-2 pr-6',
            left: 'pl-8',
        },
    },
};

const Option: FC<OptionProps> = ({
    value,
    disabled = false,
    children,
    showSelection = true,
    className = '',
    style,
    checkPosition = 'right',
    isLoading = false,
}) => {
    return (
        <Listbox.Option
            key={value}
            className={({ selected, active }) =>
                clsx(
                    'relative mx-1 mt-1 select-none rounded-md py-2 text-sm',
                    active ? 'bg-hover cursor-pointer text-gray-800' : 'text-gray-800',
                    selected ? 'bg-highlight text-blue-700' : 'text-gray-800',
                    disabled && 'cursor-not-allowed opacity-50 hover:bg-none',
                    selected ? 'font-semibold' : 'font-normal',
                    optionClasses.container.checkPosition[checkPosition],
                    className
                )
            }
            value={value}
            disabled={disabled}
            style={style}
        >
            {({ selected, active }) => (
                <>
                    <span className={'block truncate'}>{children}</span>

                    {showSelection ? (
                        <span
                            className={clsx(
                                active ? 'text-blue-600' : 'text-blue-500',
                                'absolute inset-y-0 flex items-center pr-1.5',
                                optionClasses.checkPosition[checkPosition]
                            )}
                        >
                            {!isLoading && selected && (
                                <FontAwesomeIcon icon={faCheck} className="h-4 w-4" aria-hidden="true" />
                            )}
                            {isLoading && <Spinner color="blue" className="h-4 w-4" />}
                        </span>
                    ) : null}
                </>
            )}
        </Listbox.Option>
    );
};

const classes = {
    size: {
        sm: 'h-[1.875rem] pl-2 text-xs',
        md: 'h-[2.125rem] pl-2.5 text-sm',
        lg: 'h-[2.5rem] pl-3 text-lg',
    },
    position: {
        none: 'rounded-md',
        start: 'rounded-l-md rounded-r-none focus:z-10',
        middle: 'rounded-none -ml-px focus:z-10',
        end: 'rounded-l-none rounded-r-md -ml-px focus:z-10',
    },
    status: {
        default: 'border-gray-300 text-gray-900 placeholder-gray-500 focus-visible:ring-blue-600 focus-visible:z-10',
        error: 'border-red-300 text-red-800 placeholder-red-300 focus-visible:border-red-300 focus-visible:ring-red-500 focus-visible:z-10',
        success:
            'border-green-300 text-green-800 placeholder-green-300 focus-visible:border-green-300 focus-visible:ring-green-500 focus-visible:z-10',
        loading: 'border-gray-300 text-gray-900 placeholder-gray-500 focus-visible:ring-blue-600 focus-visible:z-10',
    },
};

type BaseSelectProps<T extends string | string[] | number | number[]> = {
    id?: string;
    label?: string;
    placeholder?: string | JSX.Element;
    value: T;
    onChange: (value: T) => void;
    onSearch?: (value: string) => void;
    onAdd?: () => void;
    customRenderSelected?: (value: T) => ReactNode;
    disabled?: boolean;
    className?: string;
    wrapperClassName?: string;
    popoverClassName?: string;
    listOptionsClassName?: string;
    portalPlacement?: Placement;
    children?: ReactNode;
    size?: 'sm' | 'md' | 'lg';
    position?: 'none' | 'start' | 'middle' | 'end';
    isClearable?: boolean;
    emptyMessage?: ReactNode;
    onBlur?: () => void;
    status?: InputStatus;
    statusText?: string;
    helperTextClassName?: string;
};

type MultipleSelectProps = BaseSelectProps<string[] | number[]> & { multiple: true };

type SingleSelectProps = BaseSelectProps<string | number> & { multiple?: false };

export type SelectProps = MultipleSelectProps | SingleSelectProps;

const SelectComponent = forwardRef<HTMLInputElement, SelectProps>(
    (
        {
            id,
            className,
            wrapperClassName,
            popoverClassName,
            listOptionsClassName,
            label,
            placeholder = '',
            value,
            children,
            customRenderSelected,
            onSearch,
            onAdd,
            disabled,
            isClearable = false,
            size = 'md',
            position = 'none',
            portalPlacement = 'bottom',
            emptyMessage,
            onBlur = EmptyFunction,
            status,
            statusText,
            helperTextClassName,
            ...props
        },
        ref
    ) => {
        const [floatingWidth, setFloatingWidth] = useState(0);
        const [showPortal, setShowPortal] = useState(false);
        const searchInputRef = useRef<HTMLInputElement>();

        const { x, y, strategy, refs } = useFloating<HTMLInputElement>({
            placement: portalPlacement,
            whileElementsMounted: autoUpdate,
            middleware: [
                fSize({
                    apply: ({ elements }) => {
                        setFloatingWidth(elements.reference.getBoundingClientRect().width);
                    },
                }),
                offset(8),
                flip(),
                shift(),
            ],
        });

        const floatingStyle = {
            position: strategy,
            left: x ?? 0,
            top: y ?? 0,
            width: floatingWidth,
        };

        useImperativeHandle(ref, () => ({
            ...refs.reference.current,
            focus: () => refs?.reference?.current?.focus(),
        }));

        useLayoutEffect(() => {
            if (searchInputRef?.current) {
                searchInputRef.current.focus();
            }
        }, [searchInputRef?.current]);

        // TODO: Could probably be improved to cover more cases
        const defaultRenderSelected = (value) => {
            if (value == null) {
                return placeholder;
            }
            let valueArray = value;
            if (!Array.isArray(valueArray)) {
                valueArray = [valueArray];
            }

            const selectedElements = Children.toArray(children)
                .filter((c) => (typeof c === 'string' && valueArray.includes(c)) || valueArray.includes(c.props?.value))
                .map((e) => (typeof e === 'string' ? e : e.props?.children));
            if (selectedElements.length > 0) {
                return <p>{selectedElements.join(', ')}</p>;
            }
            return placeholder;
        };

        const clearValue = () => {
            if (props.multiple) {
                props.onChange([]);
            } else {
                props.onChange(null);
            }
        };

        const showClearable = isClearable && value != null && value.length > 0;

        return (
            <>
                <Listbox value={value} {...props} ref={ref}>
                    {({ open }) => {
                        useEffect(() => {
                            if (onSearch != null && open) {
                                onSearch('');
                            }
                            if (open && !disabled) {
                                setShowPortal(true);
                            }
                        }, [open]);
                        return (
                            <div className={clsx('relative grow', wrapperClassName)}>
                                {label && (
                                    <Listbox.Label
                                        className={clsx('mb-1 block text-sm font-medium text-gray-700', className)}
                                    >
                                        {label}
                                    </Listbox.Label>
                                )}
                                <>
                                    <Listbox.Button
                                        id={id}
                                        ref={refs.setReference}
                                        className={clsx(
                                            'relative flex w-full max-w-full grow items-center justify-between gap-1 border border-gray-300 pr-2 text-left focus:ring-blue-600 sm:text-sm',
                                            disabled
                                                ? 'cursor-not-allowed border-gray-300 bg-gray-100 !text-gray-400 hover:cursor-not-allowed'
                                                : 'cursor-default bg-white hover:cursor-pointer focus:outline-none focus:ring-offset-2 focus-visible:ring-2',
                                            classes.size[size],
                                            classes.position[position],
                                            classes.status[status],
                                            (value == null || value.length === 0) && 'text-gray-500',
                                            className
                                        )}
                                        disabled={disabled}
                                    >
                                        <span className={clsx(showClearable && 'mr-3', 'block flex-1 truncate')}>
                                            {value != null && value.length > 0
                                                ? customRenderSelected != null
                                                    ? customRenderSelected(value)
                                                    : defaultRenderSelected(value)
                                                : placeholder}
                                        </span>
                                        {status === 'loading' ? (
                                            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                                        ) : (
                                            <span className="fa-layers fa-fw pointer-events-none ml-2 flex items-center text-gray-300">
                                                <FontAwesomeIcon icon={faCaretUp} transform="up-4" aria-hidden="true" />
                                                <FontAwesomeIcon
                                                    icon={faCaretDown}
                                                    transform="down-4"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        )}
                                    </Listbox.Button>
                                    {showPortal && (
                                        <FloatingPortal>
                                            <Transition
                                                afterLeave={() => {
                                                    setShowPortal(false);
                                                    onBlur();
                                                }}
                                                as={Fragment}
                                                leave="transition-opacity ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options
                                                    className={clsx(
                                                        popoverClassName,
                                                        'z-40 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                                                    )}
                                                    ref={refs.setFloating}
                                                    style={floatingStyle}
                                                >
                                                    {onSearch != null && (
                                                        <div className="flex">
                                                            <Input
                                                                aria-label={t`Search`}
                                                                onChange={(e) => onSearch(e.target.value)}
                                                                trailingIcon={faMagnifyingGlass}
                                                                size="sm"
                                                                className="mx-2 mb-2 flex-grow"
                                                                inputClassName="!w-full"
                                                                ref={searchInputRef}
                                                            />
                                                            {onAdd && (
                                                                <Button
                                                                    icon={faPlus}
                                                                    color="primary"
                                                                    size="sm"
                                                                    className="mr-1"
                                                                    onClick={onAdd}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                    <div
                                                        className={clsx('max-h-60 overflow-auto', listOptionsClassName)}
                                                    >
                                                        {children != null && children.length > 0 ? (
                                                            children
                                                        ) : emptyMessage ? (
                                                            emptyMessage
                                                        ) : (
                                                            <div className="mx-1 mt-1 rounded-md px-2 py-2 text-sm text-gray-700">
                                                                <Trans>Nothing found</Trans>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Listbox.Options>
                                            </Transition>
                                        </FloatingPortal>
                                    )}
                                </>
                                {showClearable && (
                                    <Button
                                        className="absolute right-6 top-1/2 -translate-y-1/2 transform"
                                        size="xs"
                                        variant="text"
                                        icon={faXmark}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                                clearValue();
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            clearValue();
                                        }}
                                    />
                                )}
                                {statusText && (
                                    <HelperText className={clsx('mt-2', helperTextClassName)} status={status}>
                                        {statusText}
                                    </HelperText>
                                )}
                            </div>
                        );
                    }}
                </Listbox>
            </>
        );
    }
);

SelectComponent.displayName = 'Select';
Option.displayName = 'Select.Option';
OptionGroup.displayName = 'Select.OptionGroup';

export const Select = Object.assign(SelectComponent, { Option: Option, OptionGroup: OptionGroup });
