import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { DatePicker, DatePickerShortcuts, Popover, Skeleton, FormatDate } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/TaskDetailCustomFields';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldDateInput = ({
    taskId,
    value,
    customField,
    onUpdate,
}: {
    taskId: Id;
    value: CustomFieldValue;
    customField: CustomField;
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
}) => {
    const { isTaskReadonly } = useTask(taskId);

    return (
        <Popover
            className={'!max-w-full !justify-start'}
            text={
                value?.date_value ? (
                    <div className={'truncate'}>
                        <FormatDate date={value?.date_value} format={'shortDate'} /> ({customField.label})
                    </div>
                ) : (
                    <div className={'truncate'}>{t`Select ${customField.label}`}</div>
                )
            }
            disabled={isTaskReadonly}
            variant="text"
        >
            <div tabIndex={-1} className={'flex flex-col items-center justify-center gap-2 bg-white p-3 outline-0'}>
                <DatePickerShortcuts
                    className={'mb-3'}
                    onChange={(date) => onUpdate(customField.id, value?.id, { date_value: date })}
                />
                <DatePicker
                    date={value?.date_value ? new Date(value?.date_value) : new Date()}
                    onChange={(date) => onUpdate(customField.id, value?.id, { date_value: date })}
                />
            </div>
        </Popover>
    );
};

export const TaskDetailCustomFieldDate = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    const singleValue = useMemo(() => values?.[0], [values]);
    const hasValue = !isEmpty(singleValue?.date_value);

    return (
        <TaskDetailCustomFieldWrapper
            taskId={taskId}
            customField={customField}
            hasValue={hasValue}
            onDelete={!isEmpty(singleValue?.date_value) ? () => onDelete(customField.id, [singleValue.id]) : undefined}
        >
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldDateInput
                    taskId={taskId}
                    value={singleValue}
                    customField={customField}
                    onUpdate={onUpdate}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
