import { t } from '@lingui/macro';
import { HttpMethod, Id } from '@wedo/types';
import store from 'App/store';
import { CacheTag } from 'Shared/services/cacheTag';
import { userTag } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';
import { TransformParameter } from 'Shared/types/apiError';
import { UserMeetingPermission } from 'Shared/types/meeting';
import { GetTeamsResponse, Team, TeamFilter, TeamStatus } from '../types/team';
import { baseApi, configureTag } from './base';

export const { tagType: teamTagType, tags: teamTags, tag: teamTag } = configureTag(CacheTag.TEAM);

export const currentNetworkTeamsTag = teamTag('current-network-teams-tag');

export const transformError = () =>
    ({
        DuplicateName: {
            code: 'DuplicateError',
            message: t`The team name already exists, try again with another name`,
        },
    }) satisfies TransformParameter;

type GetTeamsFilterParams = {
    filter?: TeamFilter;
    page?: number;
    pageSize?: number;
    status?: TeamStatus;
    search?: string;
};

export const teamApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [teamTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getTeamsWithFilter: build.query<GetTeamsResponse, GetTeamsFilterParams>({
                query: ({ filter, page, pageSize, status, search }) => ({
                    url: 'teams',
                    params: {
                        filter,
                        page,
                        pageSize,
                        status,
                        search,
                    },
                }),
                providesTags: [currentNetworkTeamsTag],
            }),

            getTeam: build.query<Team, { teamId: Id }>({
                query: ({ teamId }) => `teams/${teamId}`,
                providesTags: (result, error, arg) =>
                    [currentNetworkTeamsTag, teamTag(arg.teamId)].concat(
                        (result?.userGroup?.members ?? []).map(({ user }) => userTag(user.id))
                    ),
            }),

            deleteTeam: build.mutation<Team, { teamId: Id }>({
                query: ({ teamId }) => ({
                    url: `teams/${teamId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: () => {
                    void trpcUtils().team.list.invalidate();
                    return [currentNetworkTeamsTag];
                },
            }),

            updateTeam: build.mutation<Team, Team>({
                query: (team) => ({
                    url: `teams/${team.id}`,
                    method: HttpMethod.Put,
                    body: team,
                }),
                transformErrorResponse: (error) => error.transform(transformError()),
                invalidatesTags: (result, error, { id }) => {
                    void trpcUtils().team.list.invalidate();
                    return [currentNetworkTeamsTag, teamTag(id)];
                },
            }),

            getTeams: build.query<GetTeamsResponse, { search: string; page: number; pageSize: number }>({
                query: ({ search, page, pageSize }) => ({
                    url: `teams`,
                    params: {
                        search,
                        page,
                        pageSize,
                    },
                }),
                providesTags: [currentNetworkTeamsTag],
            }),

            addTeam: build.mutation<Team, { teamName: string }>({
                query: ({ teamName }) => ({
                    url: 'teams',
                    method: HttpMethod.Post,
                    body: {
                        name: teamName,
                    },
                }),
                invalidatesTags: () => {
                    void trpcUtils().team.list.invalidate();
                    return [currentNetworkTeamsTag];
                },
            }),

            updateMeetingAccess: build.mutation<
                Team,
                {
                    teamId: Id;
                    payload: Array<{ userId: Id; meetingRole: UserMeetingPermission }>;
                }
            >({
                query: ({ teamId, payload }) => ({
                    url: `teams/${teamId}/members-meeting`,
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: [currentNetworkTeamsTag],
            }),

            updateTeamLogo: build.mutation<string, { form: FormData; teamId: Id }>({
                query: ({ teamId, form }) => ({
                    url: `teams/${teamId}/logo`,
                    method: HttpMethod.Post,
                    body: form,
                }),
                invalidatesTags: [currentNetworkTeamsTag],
            }),

            deleteTeamLogo: build.mutation<string, { teamId: Id }>({
                query: ({ teamId }) => ({
                    url: `teams/${teamId}/logo`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentNetworkTeamsTag],
            }),

            restoreDeletedTeam: build.mutation<Team, Team>({
                query: (team) => ({
                    url: `/api/teams/${team.id}`,
                    method: HttpMethod.Put,
                    body: { ...team, deleted: false },
                }),
                invalidatesTags: (result, error, team) => [currentNetworkTeamsTag, teamTag(team.id)],
            }),

            requestJoinTeamAccess: build.mutation<Team, { team_id: Id; user_id: Id }>({
                query: ({ team_id, user_id }) => ({
                    url: `/api/teams/${team_id}/join_members`,
                    method: HttpMethod.Post,
                    body: { team_id, user_id },
                }),
                invalidatesTags: [currentNetworkTeamsTag],
            }),

            refuseJoinTeamAccess: build.mutation<Team, { team_id: Id; user_id: Id }>({
                query: ({ team_id, user_id }) => ({
                    url: `/api/teams/${team_id}/join_request/${user_id}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentNetworkTeamsTag],
            }),
        }),
    });

export const {
    useGetTeamsQuery,
    useGetTeamQuery,
    useLazyGetTeamQuery,
    useGetTeamsWithFilterQuery,
    useDeleteTeamMutation,
    useUpdateTeamMutation,
    useAddTeamMutation,
    useUpdateMeetingAccessMutation,
    useUpdateTeamLogoMutation,
    useDeleteTeamLogoMutation,
    useRestoreDeletedTeamMutation,
    useRequestJoinTeamAccessMutation,
    useRefuseJoinTeamAccessMutation,
} = teamApi;

export const getCachedTeam = (teamId: Id) => store.dispatch(teamApi.endpoints.getTeam.initiate({ teamId }));
export const invalidateTeams = () => teamApi.util.invalidateTags([currentNetworkTeamsTag]);
