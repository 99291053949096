import { useMemo } from 'react';
import {
    faIndustry,
    faUserAstronaut,
    faShieldCheck,
    faEnvelopeOpenText,
    faTriangleExclamation,
} from '@fortawesome/pro-duotone-svg-icons';
import { faNotdef } from '@fortawesome/pro-duotone-svg-icons/faNotdef';
import { Trans } from '@lingui/macro';
import { Avatar, Card } from '@wedo/design-system';
import { FakeBrowser } from 'Pages/onboarding/components/FakeBrowser/FakeBrowser';
import { SubdomainPointer } from 'Pages/onboarding/components/FakeBrowser/SubdomainPointer';
import { WedoPage } from 'Pages/onboarding/components/FakeBrowser/WedoPage';
import { useInvitedOnboardingUser } from 'Pages/onboarding/hooks/useInvitedOnboardingUser';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';

export const OnboardingPreview = ({ error }: { error?: { title: string; description: string } }) => {
    const store = useOnboarding();
    const { invitedUser, isSaml } = useInvitedOnboardingUser();

    const getIcon = () => {
        if (error) {
            return faTriangleExclamation;
        }
        switch (store.step) {
            case 0:
                return faUserAstronaut;
            case 1:
                return faShieldCheck;
            case 2:
            case 3:
                return faIndustry;
            case 6:
                return faEnvelopeOpenText;
            default:
                return faNotdef;
        }
    };
    const getTitle = () => {
        if (error) {
            return error.title;
        }
        switch (store.step) {
            case 0:
                return `${store.firstName} ${store.lastName || '\u00A0'}`;
            case 1:
            case 6:
                return <Trans>Tip</Trans>;
            case 2:
            case 3:
                return store.organizationName || '\u00A0';
            default:
                return '';
        }
    };

    const description = useMemo(() => {
        if (error) {
            return error.description;
        }
        switch (store.step) {
            case 0:
                return store.jobTitle || '\u00A0';
            case 1:
                return invitedUser && isSaml ? (
                    <Trans>SAML users can log in using their configured SAML application.</Trans>
                ) : (
                    <Trans>Once your account is active you will be able to enable Two-Factor Authentication.</Trans>
                );
            case 2:
            case 3:
                return store.organizationWebsite || '\u00A0';
            case 6:
                return <Trans>Invite teammates you&apos;d like to explore WEDO with.</Trans>;
            default:
                return '';
        }
    }, [error, isSaml, invitedUser, store]);

    const getFakeBrowserPreview = () => {
        switch (store.step) {
            case 4:
                return <SubdomainPointer />;
            case 5:
            case 7:
            case 8:
                return (
                    <WedoPage
                        email={store.email}
                        name={store.firstName}
                        team={store.teamName}
                        workspaces={store.workspaceNames}
                        organizationName={store.organizationName}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {[0, 1, 2, 3, 6].includes(store.step) || error ? (
                <Card className="h-[480px] w-[480px] items-center justify-center p-4">
                    <Avatar size="3xl" icon={getIcon()} />
                    <div className="mt-4 text-center text-xl font-bold text-gray-800">{getTitle()}</div>
                    <div className="mt-4 text-center">{description}</div>
                </Card>
            ) : (
                <FakeBrowser>{getFakeBrowserPreview()}</FakeBrowser>
            )}
        </>
    );
};
