import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Tag, Tooltip } from '@wedo/design-system';

export const EnterpriseTag = () => {
    return (
        <Tooltip
            delay={300}
            content={t`In order to use this feature you'll have to upgrade your subscription to the Enterprise plan`}
        >
            <Tag size="xs" color="green">
                <Trans>Enterprise</Trans>
            </Tag>
        </Tooltip>
    );
};
