import { useCurrentUserContext } from 'App/contexts';
import { usePendingTasksStore } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { Task } from 'Shared/types/task';
import { Permission } from 'Shared/utils/rbac';

export const useTaskStatus = (task: Task) => {
    const { can } = useCurrentUserContext();
    const { completedTasks, deletedTasks } = usePendingTasksStore(({ completedTasks, deletedTasks }) => ({
        completedTasks,
        deletedTasks,
    }));

    const isDeleted = deletedTasks.has(task?.id) || task?.deleted;
    const isCompleted = task?.completed || completedTasks.has(task?.id);
    const canEditTask = can(Permission.ManageTasks);
    const isTaskReadonly = !canEditTask || task?.deleted || task?.completed;

    return { isDeleted, isCompleted, isTaskReadonly };
};
