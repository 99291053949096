export const getTopicRowClasses = ({
    selected,
    isDragging,
    isError,
}: {
    selected?: boolean;
    isDragging?: boolean;
    isError?: boolean;
}) => [
    'border-gray-200 border min-h-[32px] p-1 rounded-md flex items-center gap-2 flex-grow shadow-sm text-sm',
    !selected && !isError && !isDragging && 'bg-gray-50',
    !isError && !isDragging && ' cursor-pointer',
    selected ? 'bg-blue-200 border-blue-400 border' : 'hover:bg-gray-100',
    isError && 'bg-red-200 border-red-900 border hover:bg-red-200 cursor-not-allowed',
    isDragging && 'bg-blue-400 border-blue-900 border border-dashed cursor-grabbing',
];

export const rowNumberClasses = 'leading-4 my-1';
export const rowTitleClasses = 'leading-4 flex-1 my-1 break-all';
