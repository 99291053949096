import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useRef } from 'react';
import { faMapMarker } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { addMilliseconds, differenceInMilliseconds, isBefore, subMilliseconds } from 'date-fns';
import { Input } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getUserTimeZone, onEnter } from '@wedo/utils';
import { Meeting } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { formRowClasses, formRowIconClasses } from './FormStyles';
import { MeetingFormAttendees } from './MeetingFormAttendees';
import { MeetingFormDateTime } from './MeetingFormDateTime';
import { MeetingFormRecurrence } from './MeetingFormRecurrence';
import { MeetingFormWorkspace } from './MeetingFormWorkspace';
import { MeetingTitleInput } from './MeetingTitleInput';

type MeetingPropsFormProps = {
    template?: Meeting;
    templateSeriesMaster?: Meeting;
    meetingData: Partial<Meeting>;
    updateMeetingData: (data: Partial<Meeting>) => void;
    errorField?: string;
    mode?: 'add' | 'edit';
    onSave?: () => void;
    isTitleAlreadyPresent?: boolean;
    onTitleAlreadyExistsClick?: () => void;
};

export const MeetingForm = ({
    template,
    templateSeriesMaster,
    meetingData,
    updateMeetingData,
    errorField,
    mode,
    onSave,
    isTitleAlreadyPresent,
    onTitleAlreadyExistsClick,
}: MeetingPropsFormProps) => {
    const { i18n } = useLingui();

    const meetingTitleInput = useRef<HTMLInputElement>();

    useEffect(() => {
        if ('current' in meetingTitleInput) {
            setTimeout(() => meetingTitleInput.current?.focus(), 100);
        }
    }, [meetingTitleInput]);

    const handleAttendeeAdded = (attendee: MeetingUser) =>
        updateMeetingData({
            meetingUsers: [...meetingData.meetingUsers, attendee],
        });

    const handleAttendeeUpdated = (changes: { id: Id; changes: Partial<MeetingUser> }[]) =>
        updateMeetingData({
            meetingUsers: meetingData.meetingUsers.map((item) => ({
                ...item,
                ...(changes.find((change) => change.id === item.id)?.changes || {}),
            })),
        });

    const handleAttendeeRemoved = (attendee: Partial<MeetingUser>) =>
        updateMeetingData({
            meetingUsers: meetingData.meetingUsers
                .filter((item) => item.id !== attendee.id)
                .map((item, order) => ({ ...item, order })),
        });

    const handleStartAtChange = (value: Date) => {
        updateMeetingData({
            start_at: value.toISOString(),
            end_at: addMilliseconds(
                value,
                differenceInMilliseconds(new Date(meetingData.end_at), new Date(meetingData.start_at))
            ).toISOString(),
        });
    };

    const handleEndAtChange = (value: Date) => {
        if (isBefore(value, new Date(meetingData.start_at))) {
            const disabledBeforeDate = new Date(meetingData.disabledDateBefore);
            let newStartAt = subMilliseconds(
                value,
                differenceInMilliseconds(new Date(meetingData.end_at), new Date(meetingData.start_at))
            );
            if (isBefore(newStartAt, disabledBeforeDate)) {
                newStartAt = disabledBeforeDate;
            }
            updateMeetingData({
                start_at: newStartAt.toISOString(),
            });
        }
        updateMeetingData({ end_at: value.toISOString() });
    };

    const handleRecurrenceChange = (recurrence: string) => {
        if (recurrence !== meetingData.recurrence_pattern) {
            updateMeetingData({ recurrence_pattern: recurrence });
        }
    };
    const handleTimeZoneChange = (tz: string) => updateMeetingData({ timeZone: tz });
    return (
        <>
            <MeetingTitleInput
                titleAlreadyExists={isTitleAlreadyPresent}
                onAlreadyExistsClick={onTitleAlreadyExistsClick}
                ref={meetingTitleInput}
                disabled={template?.type === 'occurrence' && mode !== 'edit'}
                title={meetingData.title}
                isError={errorField === 'title'}
                onChange={(event) => updateMeetingData({ title: event.target.value })}
                onKeyDown={onEnter(onSave)}
            />

            {template == null && (
                <MeetingFormWorkspace
                    value={meetingData.tag_id}
                    onChange={(value: string) => updateMeetingData({ tag_id: value })}
                />
            )}
            <MeetingFormAttendees
                isDisabled={template?.type === 'occurrence' && mode !== 'edit'}
                meetingUsers={meetingData.meetingUsers}
                onAdd={handleAttendeeAdded}
                onUpdate={handleAttendeeUpdated}
                onRemove={handleAttendeeRemoved}
            />

            <MeetingFormDateTime
                startAt={new Date(meetingData.start_at)}
                endAt={new Date(meetingData.end_at)}
                disabledDateBefore={meetingData.disabledDateBefore ? new Date(meetingData.disabledDateBefore) : null}
                localeCode={i18n.locale}
                disabled={template?.type === 'occurrence' && mode !== 'edit'}
                onStartAtChange={handleStartAtChange}
                onEndAtChange={handleEndAtChange}
                timeZone={meetingData.timeZone ? meetingData.timeZone : getUserTimeZone()}
                handleTimeZoneChange={handleTimeZoneChange}
            />

            <MeetingFormRecurrence
                disabled={mode === 'add' && template != null}
                recurrence={
                    templateSeriesMaster ? templateSeriesMaster.recurrence_pattern : meetingData.recurrence_pattern
                }
                onChange={handleRecurrenceChange}
                startAt={new Date(meetingData.start_at)}
                isError={errorField === 'recurrence_pattern'}
            />

            <div className={formRowClasses}>
                <div className={formRowIconClasses}>
                    <FontAwesomeIcon icon={faMapMarker} />
                </div>
                <div className="-ml-1 flex-1 overflow-hidden p-1">
                    <Input
                        disabled={template?.type === 'occurrence' && mode !== 'edit'}
                        value={meetingData.location}
                        onChange={(event) => updateMeetingData({ location: event.target.value })}
                        type="text"
                        placeholder={t`Location`}
                        maxLength={500}
                    />
                </div>
            </div>
        </>
    );
};
