import React from 'react';
import { BasicCustomFieldModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/BasicCustomFieldModalForm';
import { CustomFieldModalSectionProps } from 'Pages/settings/customFields/types/customFieldModalSectionProps';
import { CustomFieldType, ShortTextCustomField } from 'Shared/types/customField';

export const ShortTextModalForm: React.FC<CustomFieldModalSectionProps<ShortTextCustomField>> = ({
    onCancel,
    field,
    onSuccess,
    groupId,
}) => {
    return (
        <BasicCustomFieldModalForm
            onSuccess={onSuccess}
            onCancel={onCancel}
            fieldType={CustomFieldType.ShortText}
            field={field}
            groupId={groupId}
        />
    );
};
