import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Incident, Maintenance } from 'Shared/types/statusPage';

export const statusPageApi = createApi({
    reducerPath: 'statusPageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://${__STATUS_PAGE_HOSTNAME__}/api/v2/`,
    }),
    endpoints: (build) => ({
        getOngoingIncident: build.query<Incident, void>({
            query: () => '/incidents/unresolved.json',
            transformResponse: (response: { incidents: Incident[] }) => {
                return response.incidents.length > 0 ? response.incidents[0] : null;
            },
        }),
        getNextMaintenance: build.query<Maintenance, void>({
            query: () => '/scheduled-maintenances/upcoming.json',
            transformResponse: (response: { scheduled_maintenances: Maintenance[] }) => {
                return response.scheduled_maintenances.length > 0 ? response.scheduled_maintenances[0] : null;
            },
        }),
        getActiveMaintenance: build.query<Maintenance, void>({
            query: () => '/scheduled-maintenances/active.json',
            transformResponse: (response: { scheduled_maintenances: Maintenance[] }) => {
                return response.scheduled_maintenances.length > 0 ? response.scheduled_maintenances[0] : null;
            },
        }),
    }),
});

export const { useGetNextMaintenanceQuery, useGetOngoingIncidentQuery, useGetActiveMaintenanceQuery } = statusPageApi;
