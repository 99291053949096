import { FC } from 'react';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag, Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceHistory } from 'Shared/types/workspace';

export const AddedWorkspaceSectionTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faBars}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> added section{' '}
                    <Tag size="xs">{activity?.new_value?.name}</Tag> in the workspace.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
