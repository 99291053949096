import clsx from 'clsx';
import { getColorId } from '@wedo/design-system';
import { CustomFieldOption } from 'Shared/types/customFieldOption';

export const CustomFieldSelectColoredOptionLabel = ({
    option,
    className = '',
}: {
    option: CustomFieldOption;
    className?: string;
}) => {
    if (!option) {
        return null;
    }
    const colorId = getColorId(option.color?.background);
    return (
        <div
            className={clsx(
                'w-fit rounded-full px-2',
                option.color && 'px-2',
                'bg-' + colorId + '-200',
                'text-' + colorId + '-800',
                className
            )}
        >
            {option.label}
        </div>
    );
};