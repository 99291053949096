import { createContext, FC, ReactNode, useState } from 'react';
import { Id } from '@wedo/types';
import { storage } from '@wedo/utils';
import { LocalStorage } from 'Shared/types/localStorage';
import { Network } from 'Shared/types/network';
import { User } from 'Shared/types/user';

type LoginUser = {
    loginState: {
        authToken: string;
        chosenNetwork: Id;
        page: 'password' | 'totp' | 'networkList' | 'forgotPassword' | 'forgotTotp' | 'samlChoice' | 'saml';
        email: string;
        fullName: string;
        networks: Network[];
        password: string;
        rememberMe: boolean;
        totp: string;
        trustedDevice: boolean;
        userId: Id;
        user: User;
    };
    updateLoginState: (newState: Partial<LoginUser['loginState']>) => void;
};

const defaultValue: LoginUser['loginState'] = {
    authToken: '',
    chosenNetwork: null,
    page: 'password',
    email: '',
    fullName: '',
    networks: [],
    password: '',
    rememberMe: false,
    totp: '',
    trustedDevice: false,
    userId: '',
    user: null,
};

export const LoginContext = createContext<LoginUser>(null);

export const LoginContextProvider: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    const initialValue = { ...defaultValue, trustedDevice: storage.getItem(LocalStorage.DeviceToken) !== null };

    const [loginState, setLoginState] = useState(initialValue);

    const updateLoginState: LoginUser['updateLoginState'] = (newLoginState) => {
        setLoginState((oldLoginState) => ({
            ...oldLoginState,
            ...newLoginState,
        }));
    };

    return <LoginContext.Provider value={{ loginState, updateLoginState }}>{children}</LoginContext.Provider>;
};
