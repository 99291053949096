import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Form, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { ChangePassword } from 'Shared/components/ChangePassword';
import { useResetForgottenPasswordMutation, useTestResetTokenMutation } from 'Shared/services/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

const ResetPasswordParams = {
    id: string(),
    email: string(),
    token: string(),
};

export const ResetPasswordPage: FC = () => {
    const navigate = useNavigate();
    const { show } = useNotification();
    const [resetPassword, { isLoading: isResetLoading, error: resetError }] = useResetForgottenPasswordMutation();
    const [testToken, { error: testTokenError }] = useTestResetTokenMutation();

    const [{ id: userId, email: userEmail, token }] = useSearchParams(ResetPasswordParams);

    const [password, setPassword] = useState(null);

    useEffect(() => {
        testToken({ token, userId });
    }, []);

    const submit = async () => {
        const resetResult = await resetPassword({ password, token, userId });

        if ('error' in resetResult) {
            show(UnexpectedErrorNotification);
            return;
        }

        navigate('/signin');
    };

    return (
        <div className="flex flex-col gap-4">
            <h1 className="text-lg">
                <Trans>
                    Reset the password for <strong>{userEmail}</strong>
                </Trans>
            </h1>

            {testTokenError ? (
                <>
                    <Alert className="mb-2" type="danger" title={testTokenError.message} />
                    <Link to={'/signin'} className="text-blue-600 hover:text-blue-700">
                        <FontAwesomeIcon icon={faChevronLeft} /> <Trans>Return to login page</Trans>
                    </Link>
                </>
            ) : (
                <>
                    <p>
                        {t`You forgot your password? That's okay, enter a new one in the field below. Use at least eight characters with a mix of numbers, special characters, uppercase and lowercase letters.`}
                    </p>

                    <Form layout="vertical" onSubmit={submit}>
                        <ChangePassword onChange={(password) => setPassword(password)} onSubmit={submit} />

                        <Form.Item cols={6}>
                            {resetError && <Alert className="mb-2" type="danger" title={resetError.message} />}
                            <div className="text-right">
                                <Button
                                    type="submit"
                                    disabled={password === null}
                                    onClick={submit}
                                    loading={isResetLoading}
                                    color="primary"
                                >
                                    <Trans>Update password</Trans>
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </>
            )}
        </div>
    );
};
