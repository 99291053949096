import { useContext } from 'react';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { ResetError } from 'Pages/settings/security/utils/type';

export const useChangeStage = (): { next: () => void; previous: () => void } => {
    const { state, dispatch } = useContext(TotpContext);

    const next = (): void => {
        dispatch({ type: 'stage', value: state.stage + 1 });
    };

    const previous = (): void => {
        dispatch({ type: ResetError });
        dispatch({ type: 'stage', value: state.stage - 1 });
    };

    return { next, previous };
};
