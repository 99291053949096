import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';

type UpgradeToEnterpriseModalProps = ContextModalProps & { content: string };

export const UpgradeToEnterpriseModal: React.FC<UpgradeToEnterpriseModalProps> = ({ content, ...modalProps }) => {
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Upgrade to enterprise`} />

            <Modal.Body>
                <p>{content}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" href="/settings/billing" onClick={modalProps.close}>
                    <Trans>Upgrade your plan</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
