import { Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { formatInTimeZone } from 'date-fns-tz';
import { renderBlocks } from './Blocks';
import { useMeetingPdfContext } from './MeetingPdf';
import { type Comment } from './types';
import { numericCompare } from './utils';

const flattenComment = (comment: Comment) => {
    return {
        type: 'note',
        meetingTopicId: comment.meetingTopicId,
        meetingBlockId: comment.meetingBlockId,
        children: comment.value?.flatMap(({ children }) => children) ?? [],
    };
};

export const Topics = () => {
    const {
        settings,
        meeting,
        sections,
        topics,
        blocks,
        comments,
        users,
        fontSize,
        spacing,
        borderRadius,
        color,
        isElementVisible,
        isBlockVisible,
        userTimeZone,
    } = useMeetingPdfContext();

    const privateComments = settings.blocksToDisplay.includes('note') ? comments.map(flattenComment) : [];

    const topicsAndSections = [...sections, ...topics]
        .sort((a, b) => numericCompare(a.display_id, b.display_id))
        .filter(({ id }) => settings.topicsToDisplay?.includes(id));

    return topicsAndSections.map(({ id, display_id, duration, title, start_at, presenters }) => {
        const topicBlocks = blocks.reduce(
            (result, block) => {
                if (block.meeting_topic_id === id && isBlockVisible[block.type] === true) {
                    result.push(block);
                }
                result.push(
                    ...privateComments.filter(
                        ({ meetingTopicId, meetingBlockId }) => meetingTopicId === id && meetingBlockId === block.id
                    )
                );
                return result;
            },
            privateComments.filter(
                ({ meetingTopicId, meetingBlockId }) => meetingTopicId === id && meetingBlockId == null
            )
        );

        const depth = display_id.split('.').length;

        const useUserTimeZone = settings.elementsToDisplay.includes('userTimeZone');

        return (
            <Fragment key={id}>
                <View style={{ gap: spacing.small }} wrap={false}>
                    <View
                        wrap={false}
                        style={{
                            alignItems: 'center',
                            flexGrow: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingHorizontal: spacing.medium,
                            paddingVertical: spacing.small,
                            borderRadius: borderRadius.normal,
                            fontSize: depth === 1 ? fontSize.large : depth === 2 ? fontSize.medium : fontSize.normal,
                            backgroundColor:
                                depth === 1
                                    ? color.background.dark
                                    : depth === 2
                                      ? color.background.normal
                                      : color.background.light,
                            gap: spacing.extraLarge,
                        }}
                    >
                        <Text
                            style={{ flex: 1 }}
                        >{`${isElementVisible.topicsNumbering && meeting.settings?.hide_topic_numbering !== true ? display_id + ' ' : ''}${title}`}</Text>
                        {isElementVisible.topicsDuration && duration != null && start_at != null && (
                            <View style={{ fontSize: fontSize.small, alignItems: 'flex-end' }}>
                                <Text>
                                    {formatInTimeZone(
                                        new Date(start_at),
                                        useUserTimeZone ? userTimeZone : meeting.start_at_time_zone,
                                        'HH:mm'
                                    )}
                                </Text>
                                <Text style={{ color: color.secondaryText }}>
                                    <Trans>{duration} min</Trans>
                                </Text>
                            </View>
                        )}
                    </View>
                    {isElementVisible.presenters && presenters?.length > 0 && (
                        <View
                            wrap={false}
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                flexWrap: 'wrap',
                                fontSize: fontSize.small,
                                gap: spacing.small,
                                color: color.secondaryDarkText,
                            }}
                        >
                            {presenters.map(({ id, user_id }) => (
                                <Text
                                    key={id}
                                    style={{
                                        borderRadius: borderRadius.small,
                                        paddingVertical: spacing.px,
                                        paddingHorizontal: spacing.normal,
                                        backgroundColor: color.background.dark,
                                    }}
                                >
                                    {users.find(({ id }) => id === user_id)?.full_name}
                                </Text>
                            ))}
                        </View>
                    )}
                </View>
                {topicBlocks.length > 0 && (
                    <View style={{ display: 'flex', flexDirection: 'column', gap: spacing.normal }}>
                        {renderBlocks(topicBlocks, [])}
                    </View>
                )}
            </Fragment>
        );
    });
};
