import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook,
    faAddressCard,
    faAdjust,
    faAlarmClock,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAllergies,
    faAmbulance,
    faAmericanSignLanguageInterpreting,
    faAnchor,
    faAngleDoubleRight,
    faArchive,
    faArrowAltDown,
    faArrowAltFromLeft,
    faArrowAltSquareDown,
    faArrowAltSquareRight,
    faArrowAltToLeft,
    faArrowAltToRight,
    faArrowFromLeft,
    faArrows,
    faArrowsH,
    faArrowsV,
    faAudioDescription,
    faBadge,
    faBadgeCheck,
    faBalanceScale,
    faBalanceScaleRight,
    faBarcode,
    faBatteryBolt,
    faBatteryFull,
    faBatteryHalf,
    faBatteryThreeQuarters,
    faBicycle,
    faBinoculars,
    faBirthdayCake,
    faBlanket,
    faBlind,
    faBomb,
    faBook,
    faBookmark,
    faBookOpen,
    faBoxes,
    faBoxFull,
    faBoxOpen,
    faBoxUsd,
    faBraille,
    faBriefcase,
    faBriefcaseMedical,
    faBroom,
    faBuilding,
    faBullhorn,
    faBullseye,
    faCalculator,
    faCalendarAlt,
    faCalendarEdit,
    faCalendarExclamation,
    faCapsules,
    faCar,
    faCertificate,
    faChalkboard,
    faChalkboardTeacher,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChessPawn,
    faChessQueen,
    faChevronDoubleRight,
    faCircle,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faClone,
    faCloud,
    faCode,
    faCodeBranch,
    faCodeCommit,
    faCog,
    faCogs,
    faCommentAltDots,
    faCommentAltExclamation,
    faCommentAltSmile,
    faCommentCheck,
    faCommentExclamation,
    faComments,
    faCommentSmile,
    faCopy,
    faCrow,
    faCrown,
    faDna,
    faDollarSign,
    faDolly,
    faDollyFlatbedAlt,
    faDonate,
    faEdit,
    faEllipsisH,
    faEuroSign,
    faExchange,
    faExclamation,
    faExpandWide,
    faExternalLink,
    faExternalLinkAlt,
    faExternalLinkSquare,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileEdit,
    faFileExclamation,
    faFileMedicalAlt,
    faFlag,
    faFolder,
    faFolderOpen,
    faFootballHelmet,
    faFrog,
    faGavel,
    faGlasses,
    faGlassMartini,
    faGlobe,
    faHandPointRight,
    faHandPointUp,
    faHandshakeAlt,
    faHeartbeat,
    faHockeySticks,
    faHomeHeart,
    faHospital,
    faHospitalAlt,
    faHourglassEnd,
    faHourglassHalf,
    faHourglassStart,
    faHSquare,
    faIdCardAlt,
    faInboxIn,
    faInboxOut,
    faIndustryAlt,
    faInfoCircle,
    faInfoSquare,
    faJackOLantern,
    faLaptop,
    faLevelDown,
    faLink,
    faListAlt,
    faListUl,
    faMobile,
    faMobileAlt,
    faMoneyBillAlt,
    faNotesMedical,
    faPaperPlane,
    faPenAlt,
    faPencilAlt,
    faPennant,
    faPeopleCarry,
    faPersonCarry,
    faPhone,
    faPiggyBank,
    faPills,
    faPlay,
    faPlusSquare,
    faProjectDiagram,
    faPuzzlePiece,
    faRandom,
    faRedo,
    faRoad,
    faRocket,
    faSearch,
    faSearchMinus,
    faShareAlt,
    faShield,
    faShieldAlt,
    faShieldCheck,
    faShoePrints,
    faSitemap,
    faStarExclamation,
    faStoreAlt,
    faTachometer,
    faTag,
    faTaxi,
    faTh,
    faThumbsUp,
    faUsdCircle,
    faUser,
    faUserAlt,
    faUserCheck,
    faUserClock,
    faUserEdit,
    faUserFriends,
    faUserMd,
    faUsers,
    faUserShield,
    faUserTie,
    faVenusMars,
    faVolumeOff,
    faWalking,
    faWarehouse,
    faWarehouseAlt,
    faWatch,
    faWindowMaximize,
    faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import { FaIconName } from '@wedo/types';

export const icons: Array<IconDefinition> = [
    faAddressBook,
    faAddressCard,
    faAdjust,
    faAlarmClock,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAllergies,
    faAmbulance,
    faAmericanSignLanguageInterpreting,
    faAnchor,
    faAngleDoubleRight,
    faArchive,
    faArrowAltDown,
    faArrowAltFromLeft,
    faArrowAltSquareDown,
    faArrowAltSquareRight,
    faArrowAltToLeft,
    faArrowAltToRight,
    faArrowFromLeft,
    faArrows,
    faArrowsH,
    faArrowsV,
    faAudioDescription,
    faBadge,
    faBadgeCheck,
    faBalanceScale,
    faBalanceScaleRight,
    faBarcode,
    faBatteryBolt,
    faBatteryFull,
    faBatteryHalf,
    faBatteryThreeQuarters,
    faBicycle,
    faBinoculars,
    faBirthdayCake,
    faBlanket,
    faBlind,
    faBomb,
    faBook,
    faBookmark,
    faBookOpen,
    faBoxes,
    faBoxFull,
    faBoxOpen,
    faBoxUsd,
    faBraille,
    faBriefcase,
    faBriefcaseMedical,
    faBroom,
    faBuilding,
    faBullhorn,
    faBullseye,
    faCalculator,
    faCalendarAlt,
    faCalendarEdit,
    faCalendarExclamation,
    faCapsules,
    faCar,
    faCertificate,
    faChalkboard,
    faChalkboardTeacher,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChessPawn,
    faChessQueen,
    faChevronDoubleRight,
    faCircle,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faClone,
    faCloud,
    faCode,
    faCodeBranch,
    faCodeCommit,
    faCog,
    faCogs,
    faCommentAltDots,
    faCommentAltExclamation,
    faCommentAltSmile,
    faCommentCheck,
    faCommentExclamation,
    faComments,
    faCommentSmile,
    faCopy,
    faCrow,
    faCrown,
    faDna,
    faDollarSign,
    faDolly,
    faDollyFlatbedAlt,
    faDonate,
    faEdit,
    faEllipsisH,
    faEuroSign,
    faExchange,
    faExclamation,
    faExpandWide,
    faExternalLink,
    faExternalLinkAlt,
    faExternalLinkSquare,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileEdit,
    faFileExclamation,
    faFileMedicalAlt,
    faFlag,
    faFolder,
    faFolderOpen,
    faFootballHelmet,
    faFrog,
    faGavel,
    faGlasses,
    faGlassMartini,
    faGlobe,
    faHandPointRight,
    faHandPointUp,
    faHandshakeAlt,
    faHeartbeat,
    faHockeySticks,
    faHomeHeart,
    faHospital,
    faHospitalAlt,
    faHourglassEnd,
    faHourglassHalf,
    faHourglassStart,
    faHSquare,
    faIdCardAlt,
    faInboxIn,
    faInboxOut,
    faIndustryAlt,
    faInfoCircle,
    faInfoSquare,
    faJackOLantern,
    faLaptop,
    faLevelDown,
    faLink,
    faListAlt,
    faListUl,
    faMobile,
    faMobileAlt,
    faMoneyBillAlt,
    faNotesMedical,
    faPaperPlane,
    faPenAlt,
    faPencilAlt,
    faPennant,
    faPeopleCarry,
    faPersonCarry,
    faPhone,
    faPiggyBank,
    faPills,
    faPlay,
    faPlusSquare,
    faProjectDiagram,
    faPuzzlePiece,
    faRandom,
    faRedo,
    faRoad,
    faRocket,
    faSearch,
    faSearchMinus,
    faShareAlt,
    faShield,
    faShieldAlt,
    faShieldCheck,
    faShoePrints,
    faSitemap,
    faStarExclamation,
    faStoreAlt,
    faTachometer,
    faTag,
    faTaxi,
    faTh,
    faThumbsUp,
    faUsdCircle,
    faUser,
    faUserAlt,
    faUserCheck,
    faUserClock,
    faUserEdit,
    faUserFriends,
    faUserMd,
    faUsers,
    faUserShield,
    faUserTie,
    faVenusMars,
    faVolumeOff,
    faWalking,
    faWarehouse,
    faWarehouseAlt,
    faWatch,
    faWindowMaximize,
    faWrench,
];

const iconNameToIcon = new Map<FaIconName, IconDefinition>(icons.map((icon) => [`fa-${icon.iconName}`, icon]));

iconNameToIcon.set('fa-pen-alt', faPenAlt);
iconNameToIcon.set('fa-shield-alt', faShieldAlt);
iconNameToIcon.set('fa-user-md', faUserMd);
iconNameToIcon.set('fa-user-edit', faUserEdit);
iconNameToIcon.set('fa-user-friends', faUserFriends);
iconNameToIcon.set('fa-home-heart', faHomeHeart);
iconNameToIcon.set('fa-hospital-alt', faHospitalAlt);
iconNameToIcon.set('fa-info-circle', faInfoCircle);
iconNameToIcon.set('fa-list-alt', faListAlt);

export const getIconFromName = (iconName: FaIconName): IconDefinition => iconNameToIcon.get(iconName);
