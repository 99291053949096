import React from 'react';
import clsx from 'clsx';
import { useStore } from 'zustand';
import { type Store } from './store';

type DropIndicatorProps = {
    store: Store;
    blockId?: string;
};

export const DropIndicator = ({ store, blockId = 'topic' }: DropIndicatorProps) => {
    const overBlockId = useStore(store, ({ overBlockId }) => overBlockId);

    return (
        overBlockId === blockId && (
            <div contentEditable={false} className={clsx('relative', blockId !== 'topic' && '-mt-2 -mx-2')}>
                <div
                    className={clsx(
                        'absolute h-[2px] bg-blue-500 w-full',
                        blockId !== 'topic' ? '-bottom-[5px]' : '-bottom-[9px]'
                    )}
                >
                    <div className="absolute -left-px -top-[3px] border-l-[6px] border-l-blue-500 border-transparent border-t-[4px] border-b-[4px]" />
                </div>
            </div>
        )
    );
};
