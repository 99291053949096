import { useRef } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import { type Element, Path, Transforms } from 'slate';
import { Dropdown } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useMeetingContext } from 'App/contexts';
import { focusEditor } from 'Shared/components/editor/Editor';
import { AddBlockDropdownItems } from 'Shared/components/editor/components/ToolbarAddBlock';
import { reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { MeetingBlock } from 'Shared/types/meetingBlock';

type AddBlockItemProps = {
    element: Element;
    blockId: string;
    topicId: Id;
    sectionId: Id;
    onOpen: (isOpen: boolean) => void;
};

export const AddBlockItem = ({ element, blockId, topicId, sectionId, onOpen }: AddBlockItemProps) => {
    const editor = useSlateStatic();

    const editorRef = useRef(editor);

    const { meeting } = useMeetingContext();

    const handleSelectBlock = (blocks: MeetingBlock[]) => {
        blocks.forEach((block) => {
            Transforms.insertNodes(editor, block, {
                at: Path.next(ReactEditor.findPath(editor, element)),
                select: true,
            });
        });
        reorderBlocks(editor);
        forceSave(editor);
        focusEditor(editor);
    };

    const handleOpen = () => {
        onOpen(true);
    };

    const handleClose = () => {
        onOpen(false);
    };

    return (
        <Dropdown
            variant="ghost"
            className={'flex !w-auto !rounded-none bg-white text-gray-500 outline-0 hover:bg-gray-100'}
            iconClassName={'!p-1'}
            icon={faPlus}
            aria-label={t`Add a block in the editor`}
            focusItemOnOpen={false}
            dropdownClassName="editor-component"
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <AddBlockDropdownItems
                editorRef={editorRef}
                meetingId={meeting?.id}
                workspaceId={meeting?.tag_id}
                onCreateBlocks={handleSelectBlock}
                blockId={blockId}
                sectionId={sectionId}
                topicId={topicId}
            />
        </Dropdown>
    );
};
