import { faCreditCardAlt, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, EmptyState } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { BillingAddressCard } from 'Pages/settings/billing/components/BillingAddressCard';
import { CancelSubscriptionBanner } from 'Pages/settings/billing/components/CancelSubscriptionBanner';
import { InvoicesCard } from 'Pages/settings/billing/components/InvoicesCard/InvoicesCard';
import { PaymentDetailsCard } from 'Pages/settings/billing/components/PaymentDetailsCard';
import { SubscriptionCard } from 'Pages/settings/billing/components/SubscriptionCard';
import { UsagesCard } from 'Pages/settings/billing/components/UsagesCard';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { SUBSCRIPTION_ADDRESS_STEP_URL } from 'Pages/subscription/utils';
import { Can } from 'Shared/components/Can';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { ApiError } from 'Shared/types/apiError';
import { getHasSignatureFeature } from 'Shared/utils/chargebee';
import { Permission } from 'Shared/utils/rbac';
import { isFetchError } from 'Shared/utils/rtkQuery';

export const BillingSettingsPage = () => {
    const navigate = useNavigate();
    const { data: organization, isError, error, isLoading, refetch } = useGetOrganizationQuery();
    const { can } = useCurrentUserContext();
    const { hasExistingSubscription } = useSubscriptionPage();

    const fetchErrorOccurred = isError && isFetchError(error as ApiError);
    const currencyCode = organization?.preferred_currency_code ?? 'CHF';

    const hasSignatureFeature = getHasSignatureFeature(organization?.subscription);

    if (fetchErrorOccurred) {
        return (
            <div className="flex justify-center">
                <RetryComponent retryFunction={refetch} />
            </div>
        );
    }

    if (!can(Permission.ManageNetwork)) {
        return (
            <EmptyState icon={faWarning}>
                <EmptyState.Text>
                    <Trans>You do not have permission to view this page.</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    if (!hasExistingSubscription && !isLoading) {
        return (
            <EmptyState icon={faCreditCardAlt} className="py-6">
                <EmptyState.Text>
                    <Trans>You are currently on a trial plan, click below to start a subscription.</Trans>
                </EmptyState.Text>
                <Button
                    className="mt-6"
                    color="primary"
                    onClick={() => {
                        navigate(SUBSCRIPTION_ADDRESS_STEP_URL);
                    }}
                >
                    <Trans>Start subscription</Trans>
                </Button>
            </EmptyState>
        );
    }

    return (
        <Can showNoAccess permission={Permission.ManageNetwork}>
            <div className="flex flex-col gap-4">
                <SubscriptionCard
                    subscription={organization?.subscription}
                    invoiceEstimate={organization?.renewal_estimate?.invoice_estimate}
                />
                {hasSignatureFeature && <UsagesCard />}

                <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                    <BillingAddressCard address={organization?.billing_address} swissCanton={organization?.cf_canton} />
                    <PaymentDetailsCard
                        nextBillingAt={new Date(organization?.renewal_estimate?.subscription_estimate?.next_billing_at)}
                        billingEmail={organization?.billing_address?.email}
                        card={organization?.card}
                        subscription={organization?.subscription}
                        currency={currencyCode}
                        totalUsers={Number(organization?.users_count)}
                        lightUsers={Number(organization?.light_users_count)}
                    />
                    <InvoicesCard invoices={organization?.invoices_list} />
                </div>

                <CancelSubscriptionBanner />
            </div>
        </Can>
    );
};
