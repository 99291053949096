import clsx from 'clsx';

export type SkeletonProps = {
    count?: number;
    className?: string;
    shape?: 'circle' | 'square';
};
export const Skeleton = ({ count = 1, shape = 'square', className }: SkeletonProps): JSX.Element => {
    return (
        <>
            {Array.from({ length: count }, (_, i) => (
                <div
                    role="status"
                    key={`skeleton-item-${i}`}
                    className={clsx(
                        'w-full animate-pulse bg-gray-200 opacity-80',
                        shape === 'circle' ? 'rounded-full' : 'rounded-md',
                        className
                    )}
                />
            ))}
        </>
    );
};
