import { useMemo } from 'react';
import { type Template } from 'Shared/types/template';
import { type Workspace } from 'Shared/types/workspace';

export const groupMembers = ({ private: isPrivate, userGroup, team }: Workspace | Template) => {
    const members =
        isPrivate || team == null
            ? [...userGroup.members]
            : Array.from(
                  new Map(
                      team.userGroup.members.concat(userGroup.members).map((member) => [member.user_id, member])
                  ).values()
              );
    return members.sort(({ user: { full_name: firstFullName } }, { user: { full_name: secondFullName } }) =>
        firstFullName.localeCompare(secondFullName)
    );
};

export const useGroupMembers = ({ private: isPrivate, userGroup, team }: Workspace | Template) => {
    return useMemo(() => groupMembers({ private: isPrivate, userGroup, team }), [isPrivate, userGroup, team]);
};
