/** expect the format "hh[:mm]" and return [hour, minute] as numbers */
import { getTimezoneOffset } from 'date-fns-tz';
import { Time, useCalendar } from '~/hooks/useCalendar';
import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from '@wedo/utils';

export const numberToPaddedString = (digits: number) => {
    return String(digits).padStart(2, '0');
};

export const timeToString = (time: Time): string => {
    return `${numberToPaddedString(time.hour)}:${numberToPaddedString(time.minute)}`;
};

/** Expect the format "hh[:mm] and return [hour, minute] as numbers */
export const formatTime24 = (value: string): [number, number] => {
    const [hourStr, minuteStr] = value.split(':');
    return [Number(hourStr), minuteStr ? Number(minuteStr) : 0];
};

/** Expect the format "hh[:mm] am|pm" and return [hour, minute] as numbers */
export const formatTime12 = (value: string): [number, number] => {
    const [time, part] = [value.match(/^[\d:]+/)[0], value.match(/am|pm$/)[0]];
    const [hourStr, minuteStr] = time.split(':');
    // eslint-disable-next-line prefer-const
    let [hour, minute] = [Number(hourStr), minuteStr ? Number(minuteStr) : 0];

    // on a 12 hours clock 0 and 13+ don't exist
    if (hour === 0 || hour >= 13) {
        hour = 25; // set an invalid time
    }

    if (hour === 12) {
        hour = 0;
    }

    if (part === 'pm') {
        hour += 12;
    }

    return [hour, minute];
};

/** Convert hours and minutes into a Time object
 * - Return Time if successful
 * - Return false if unsuccessful
 */
export const numberToTime = (hour: number, minute: number, calendar: ReturnType<typeof useCalendar>): Time | false => {
    if (hour > 23 || minute > 59) {
        return false;
    }

    const midnight: number = calendar.hourOfDayList()[0].timestamp;

    const offsetMidnight = getTimezoneOffset(calendar.timezone, midnight);

    let timestamp = midnight + hour * MILLISECONDS_PER_HOUR + minute * MILLISECONDS_PER_MINUTE;

    const offsetLater = getTimezoneOffset(calendar.timezone, timestamp);

    timestamp += offsetMidnight - offsetLater;

    return {
        hour,
        minute,
        offsetShift: 0,
        timestamp,
    };
};

/** From a string "hh:mm" return the Time object
 * - Return Time if successful
 * - Return false if unsuccessful
 * */
const clock24IncompleteRegex = /^\d{1,2}:?(?:\d{1,2})?$/;
const clock12IncompleteRegex = /^\d{1,2}:?(?:\d{1,2})?( )*(am|pm)$/;
const clock24CompleteRegex = /^\d{1,2}:\d{1,2}$/;
const clock12CompleteRegex = /^\d{1,2}:\d{1,2}( )*(am|pm)$/;
export const stringToTime = (timeStr: string, calendar: ReturnType<typeof useCalendar>): Time | string | false => {
    const value = timeStr.trim().toLowerCase();

    if (clock24IncompleteRegex.test(value)) {
        const [hour, minute] = formatTime24(value);

        if (clock24CompleteRegex.test(value)) return numberToTime(hour, minute, calendar);

        return `${numberToPaddedString(hour)}:`;
    }

    if (clock12IncompleteRegex.test(value)) {
        const [hour, minute] = formatTime12(value);

        if (clock12CompleteRegex.test(value)) return numberToTime(hour, minute, calendar);

        return `${numberToPaddedString(hour)}:`;
    }

    return false;
};
