import React from 'react';
import { faWebhook } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Card, EmptyState, useModal, Skeleton } from '@wedo/design-system';
import { Title } from 'Pages/settings/api/components/Title';
import { AddWebhookModal } from 'Pages/settings/api/components/WebhookCard/AddWebhookModal';
import { WebhookTable } from 'Pages/settings/api/components/WebhookCard/WebhookTable';
import { useGetWebhookListQuery } from 'Shared/services/webhook';

export const WebhookCard = (): JSX.Element => {
    const { isLoading, isError, isSuccess, data, refetch, error } = useGetWebhookListQuery();
    const { open } = useModal();

    const handleOpen = () => open(AddWebhookModal);

    return (
        <Card>
            <Card.Header
                title={t`Webhooks`}
                actions={
                    <Button onClick={handleOpen} color="primary" icon={faPlus}>
                        <Trans>Add webhook</Trans>
                    </Button>
                }
            />
            <Card.Body>
                {isLoading && (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-4" />
                    </div>
                )}
                {!isLoading && isError && (
                    <>
                        <Alert type="danger" title={error.message} className="mb-4" />
                        <Button color="primary" onClick={refetch}>
                            <Trans>Retry</Trans>
                        </Button>
                    </>
                )}
                {!isLoading && isSuccess && data.length > 0 && (
                    <>
                        <Title level={2}>
                            <Trans>Active webhooks</Trans>
                        </Title>
                        <WebhookTable webhookList={data} />
                    </>
                )}
                {!isLoading && isSuccess && data.length === 0 && (
                    <EmptyState icon={faWebhook}>
                        <EmptyState.Text>{t`No webhooks`}</EmptyState.Text>
                    </EmptyState>
                )}
            </Card.Body>
        </Card>
    );
};
