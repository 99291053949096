import { t } from '@lingui/macro';

export const byteUnits = () => [t`B`, t`KB`, t`MB`, t`GB`, t`TB`, t`PB`];

export const getFormattedBytes = (bytes: number, precision = 1): string => {
    if (isNaN(bytes) || !isFinite(bytes) || bytes === 0) {
        return '-';
    }
    const number = Math.floor(Math.log(Number(bytes)) / Math.log(1024));
    return parseFloat((Number(bytes) / 1024 ** Math.floor(number)).toFixed(precision)) + ' ' + byteUnits()[number];
};

export const totalBytes = (value: number, postfix: 'kb' | 'mb' | 'gb'): number => {
    switch (postfix) {
        case 'kb':
            return value * 2 ** 10;
        case 'mb':
            return value * 2 ** 20;
        case 'gb':
            return value * 2 ** 30;
        default:
            return -1;
    }
};
