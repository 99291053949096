import { FC } from 'react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';
import {
    legalFrameworks,
    signatureTypes,
} from 'Pages/meeting/components/RequestMeetingSignaturesModal/SignatureTypePicker';
import { SignatureTypeTag } from 'Shared/components/signature/SignatureTypeTag';
import { LegalFramework, SignatureType } from 'Shared/types/signature';

const getSwisscomLink = () => {
    switch (i18n.locale.substring(0, 2)) {
        case 'de':
            return 'https://srsident.trustservices.swisscom.com/';
        case 'fr':
        case 'en':
        case 'it':
        default:
            return 'https://srsident.trustservices.swisscom.com/en/';
    }
};

type InsufficientAssuranceLevelModalProps = {
    signatureType: SignatureType;
    legalFramework: LegalFramework;
} & ContextModalProps;

export const InsufficientAssuranceLevelModal: FC<InsufficientAssuranceLevelModalProps> = ({
    legalFramework,
    signatureType,
    ...modalProps
}) => {
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Insufficient identification level`} />
            <Modal.Body>
                <div className="flex gap-8">
                    <div>
                        <div className="font-bold">
                            <Trans>You're invited to sign with:</Trans>
                        </div>
                        <div className="flex items-center gap-2">
                            <SignatureTypeTag type={signatureType} />
                            {signatureTypes.get(signatureType).name} ({signatureTypes.get(signatureType).acronym})
                            {' - '}
                            {legalFrameworks.get(legalFramework).text}
                        </div>

                        <div className="font-bold mt-4">
                            <Trans>You don't have the required identification level yet.</Trans>
                        </div>
                        <Trans>
                            To sign the document you must increase the legal weight of your signature. You must verify
                            your identity with Swisscom.
                        </Trans>
                    </div>
                    <img className="w-52" alt="" src="/assets/signing-hand.svg" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>

                <Button color={'primary'} href={getSwisscomLink()} target="_blank">
                    <Trans>Verify my identity</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
