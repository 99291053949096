import { useParams } from 'react-router-dom';
import { whenQueryIsInvalidated } from '~/modules/reactQuery/invalidation';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { GanttPageParams } from './GanttPage';
import { type Task } from './types';

export const useTask = (initialTask: Task) => {
    const { workspaceId } = useParams<GanttPageParams>();

    const { data: task = initialTask } = trpc.task.gantt.get.useQuery(
        { taskId: initialTask.id, workspaceId },
        {
            enabled: whenQueryIsInvalidated,
            meta: {
                tags: [
                    taskQueryTag.updated(initialTask.id, 'assigneeId'),
                    taskQueryTag.updated(initialTask.id, 'blockedTaskIds'),
                    taskQueryTag.updated(initialTask.id, 'completed'),
                    taskQueryTag.updated(initialTask.id, 'dueDate'),
                    taskQueryTag.updated(initialTask.id, 'name'),
                    taskQueryTag.updated(initialTask.id, 'plannedDate'),
                    taskQueryTag.updated(initialTask.id, 'sectionId'),
                    taskQueryTag.updated(initialTask.id, 'subTasks'),
                    taskQueryTag.updated(initialTask.id, 'type'),
                ],
            },
        }
    );

    return task;
};
