import { create } from 'zustand';

export const createStore = () => {
    return create<{
        collapsedIds: Array<string>;
        highlightedMeetingBlockId: string;
        activeMeetingTopicId: string;
        activeMeetingBlockId: string;
        overMeetingBlockId: string;
        blockPlaceholders: Map<string, HTMLDivElement>;
    }>()(() => ({
        collapsedIds: null,
        highlightedMeetingBlockId: null,
        activeMeetingTopicId: null,
        activeMeetingBlockId: null,
        overMeetingBlockId: null,
        blockPlaceholders: new Map(),
    }));
};

export type Store = ReturnType<typeof createStore>;
