import { createContext, useContext } from 'react';

type ModalContextType = {
    onClose?: () => void;
};

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModalContext = (): ModalContextType => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error('useModalContext should be used within the ModalContext provider!');
    }

    return context;
};
