import { FC, ReactNode } from 'react';
import { Checkbox, Label } from '@wedo/design-system';

type TaskOptionCheckboxProps = {
    isChecked: boolean;
    onChange: (value: boolean) => void;
    name: string;
    label: ReactNode;
};

export const TaskOptionCheckbox: FC<TaskOptionCheckboxProps> = ({ isChecked, onChange, name, label }) => {
    return (
        <div className="flex gap-2">
            <Checkbox id={name} checked={isChecked} onChange={() => onChange(!isChecked)} />
            <Label className="-my-0.5" htmlFor={name}>
                {label}
            </Label>
        </div>
    );
};
