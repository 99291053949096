import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Card } from '@wedo/design-system';
import { dateFormat } from 'Pages/settings/security/utils/dateFormat';

export type TotpNoDisableSpecType = {
    totpActiveDate: string;
    languageCode: string;
};

export const NoDisableTotpCard = ({ totpActiveDate, languageCode }: TotpNoDisableSpecType): JSX.Element => (
    <Card>
        <Card.Header title={t`Two-factor authentication`} />
        <Card.Body>
            <div className="flex items-center gap-2">
                <FontAwesomeIcon className="text-success text-3xl" icon={faSquareCheck} />
                <Trans>Two-factor authentication is activated for your account since:</Trans>{' '}
                {dateFormat(totpActiveDate, languageCode, false)}
            </div>
        </Card.Body>
    </Card>
);
