import { useCallback } from 'react';
import { useCurrentUserContext } from 'App/contexts';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { DefaultPreferences } from 'Shared/types/network';

export const usePreferences = () => {
    const { currentUser } = useCurrentUserContext();
    const { network } = useCurrentNetwork();

    return useCallback(
        (key: keyof DefaultPreferences, initialValue?: unknown) => {
            return (
                currentUser?.userNetwork?.config?.defaultPreferences?.[key] ??
                network?.defaultPreferences?.[key] ??
                initialValue
            );
        },
        [currentUser, network]
    );
};
