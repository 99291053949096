import { ReactNode, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import {
    Card,
    NoInternetErrorNotification,
    RadioGroup,
    Spinner,
    Switch,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { UpgradeToEnterpriseModal } from 'Shared/components/billing/UpgradeToEnterpriseModal';
import { useCurrentOrganisation } from 'Shared/hooks/useCurrentOrganisation';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useGetAdminNetworkQuery, useUpdateNetworkConfigMutation } from 'Shared/services/network';
import { ApiError } from 'Shared/types/apiError';
import { DevFeature } from 'Shared/types/user';
import { isFetchError } from 'Shared/utils/rtkQuery';
import { IdentityProviderMetadataSection } from './IdentityProviderMetadataSection';
import { StandardIdentityProviderConfigSection } from './StandardIdentityProviderConfigSection';

type AuthenticationType = {
    id: 'SIPC' | 'XML';
    label: ReactNode;
};

const standardIdentityProviderConfiguration: AuthenticationType = {
    id: 'SIPC',
    label: <Trans>Standard identity provider configuration</Trans>,
};

const identityProviderMetadata: AuthenticationType = {
    id: 'XML',
    label: <Trans>Identity provider metadata (XML)</Trans>,
};

export const SAML_CARD_CACHE_KEY = 'samlCardCacheKey';

export const SamlAuthCard = () => {
    const { open } = useModal();
    const { show } = useNotification();

    const { data: network } = useGetAdminNetworkQuery();
    const [updateConfig, { isLoading: isUpdateConfigLoading, isError }] = useUpdateNetworkConfigMutation({
        fixedCacheKey: SAML_CARD_CACHE_KEY,
    });
    const { isEnterprise, isLoading: isSubscriptionLoading } = useCurrentOrganisation();
    const hasAllowSamlFlag = useHasFeature(null, network, DevFeature.AllowSaml);

    const [authenticationType, setAuthenticationType] = useState<string>(EmptyString);
    const [samlToggle, setSamlToggle] = useState<boolean>(false);

    const config = network?.config?.saml;
    const samlEnabled = config?.enable ?? false;
    const idpEnabled = !!config?.idp_metadata;

    const isLoading = isSubscriptionLoading || isUpdateConfigLoading;

    useEffect(() => {
        setAuthenticationType(idpEnabled ? identityProviderMetadata.id : standardIdentityProviderConfiguration.id);
    }, [idpEnabled]);

    useEffect(() => {
        setSamlToggle(samlEnabled);
    }, [samlEnabled]);

    const handleToggle = async (toggle: boolean) => {
        if (!hasAllowSamlFlag && !(await isEnterprise())) {
            open(UpgradeToEnterpriseModal, {
                content: (
                    <Trans>
                        To enable <b>Single Sign-On with SAML</b> in your organization you need to upgrade to the
                        enterprise subscription model
                    </Trans>
                ),
            });
        } else if (toggle) {
            setSamlToggle(toggle);
        } else if (samlEnabled) {
            void updateConfig({ ...config, enable: false }).then((response) => {
                if ('error' in response) {
                    const error = response.error as ApiError;
                    if (isFetchError(error)) {
                        show(NoInternetErrorNotification);
                    } else {
                        show(UnexpectedErrorNotification);
                    }
                }
            });
        } else {
            setSamlToggle(false);
        }
    };

    const hasUserMadeChanges =
        !isEqual(samlToggle, samlEnabled) ||
        !isEqual(
            authenticationType,
            idpEnabled ? identityProviderMetadata.id : standardIdentityProviderConfiguration.id
        );

    return (
        <Card>
            <Card.Header
                className={clsx(!samlToggle && 'rounded-b-lg')}
                title={t`Single Sign-On with SAML`}
                description={t`Standard protocol used for securely exchanging authentication, used for implementing single sign-on (SSO)`}
                actions={
                    <Switch.Group>
                        <div className="flex items-center">
                            {isLoading && (
                                <Spinner className="mr-2 h-5 w-5 animate-spin fill-blue-800 text-blue-800 text-opacity-20" />
                            )}
                            <Switch
                                checked={samlToggle}
                                onChange={(value) => handleToggle(value)}
                                disabled={isLoading && !isError}
                            />
                        </div>
                    </Switch.Group>
                }
            />

            {samlToggle && (
                <Card.Body>
                    <div>
                        <RadioGroup
                            value={authenticationType}
                            name="authentication-type-radio-group"
                            onChange={(v) => setAuthenticationType(v as string)}
                        >
                            <RadioGroup.Radio
                                id={standardIdentityProviderConfiguration.id}
                                value={standardIdentityProviderConfiguration.id}
                            >
                                {standardIdentityProviderConfiguration.label}
                            </RadioGroup.Radio>

                            <RadioGroup.Radio id={identityProviderMetadata.id} value={identityProviderMetadata.id}>
                                {identityProviderMetadata.label}
                            </RadioGroup.Radio>
                        </RadioGroup>

                        {authenticationType === standardIdentityProviderConfiguration.id && (
                            <StandardIdentityProviderConfigSection
                                className="mt-8"
                                config={config}
                                hasMadeChanges={hasUserMadeChanges}
                            />
                        )}
                        {authenticationType === identityProviderMetadata.id && (
                            <IdentityProviderMetadataSection
                                className="mt-8"
                                config={config}
                                hasMadeChanges={hasUserMadeChanges}
                            />
                        )}
                    </div>
                </Card.Body>
            )}
        </Card>
    );
};
