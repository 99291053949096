import {
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
} from '@floating-ui/react';
import React, { KeyboardEvent, useRef, useState } from 'react';
import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import { faLinkSlash, faPen } from '@fortawesome/pro-regular-svg-icons';
import { faCopy, faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Editor, Element, Path, Text, Transforms } from 'slate';
import { Button, Form, Input, useNotification } from '@wedo/design-system';
import { tryOrNull } from '@wedo/utils';
import { Plugin } from 'Shared/components/editor/Editor';
import { reorderBlocks } from 'Shared/components/editor/utils/block';
import { is } from 'Shared/components/editor/utils/node';
import { isIn } from 'Shared/components/editor/utils/slate';
import { createParagraphBlock } from './paragraphPlugin';

export const Link = 'link';

const isLink = is(Link);

const URL_REGEX =
    /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z\d.-:]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z\d.-]+)((?:\/[+~%/.\w\-_]*)?\??[-+=&;%@.\w_]*#?[.!/#-\\\w]*)?)$/;

const parseValidUrl = (url: string) => (URL_REGEX.test(url) && !url.startsWith('http') ? 'https://' + url : url);

export const unLink = (editor: Editor, path?: Path) => {
    Transforms.unwrapNodes(editor, { at: path, match: is(Link) });
};

export const toggleLink = (editor: Editor) => {
    const text = tryOrNull(() => Editor.string(editor, editor.selection));
    const url = URL_REGEX.test(text) ? text : '';
    Transforms.wrapNodes(editor, { type: Link, url }, { match: Text.isText, split: true, mode: 'lowest' });
    Transforms.collapse(editor, { edge: 'end' });
};

const parseTitle = (element: Element) => element?.children?.[0].text;

const LinkElement = ({ element, attributes, children }: RenderElementProps) => {
    const editor = useSlateStatic();
    const isReadOnly = useReadOnly();
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(element?.url);
    const [title, setTitle] = useState<string>(parseTitle(element));
    const ref = useRef<HTMLInputElement>();
    const { show } = useNotification();

    const { ref: slateRef, ...slateAttributes } = attributes;

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: (isOpen) => {
            if (!isEdit) {
                setIsOpen(isOpen);
            }
        },
        whileElementsMounted: autoUpdate,
        placement: 'bottom-start',
        middleware: [
            offset({
                mainAxis: 0,
            }),
            flip(),
            shift(),
        ],
    });

    const cancelAndClose = () => {
        setIsEdit(false);
        setIsOpen(false);
    };

    const hover = useHover(context, { enabled: !isReadOnly, delay: 100, handleClose: safePolygon() });
    const dismiss = useDismiss(context, {
        outsidePress: () => {
            cancelAndClose();
            return true;
        },
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss]);

    const [previousUrl, setPreviousUrl] = useState<string>(null);
    if (previousUrl !== element?.url) {
        setPreviousUrl(element?.url);
        setValue(element?.url);
        if ('url' in element && element?.url.length === 0) {
            if (editor.selection != null) {
                ReactEditor.deselect(editor);
            }
            setTimeout(() => {
                setIsOpen(true);
                setIsEdit(true);
                requestAnimationFrame(() => {
                    ref.current?.focus();
                });
            }, 100);
        }
    }
    const [previousTitle, setPreviousTitle] = useState<string>(null);
    if (previousTitle !== parseTitle(element)) {
        setPreviousTitle(parseTitle(element));
        setTitle(parseTitle(element));
    }

    const handleSave = () => {
        if (value.trim().length === 0) {
            // remove link if the URL is empty
            unLink(editor, ReactEditor.findPath(editor, element));
            return;
        }
        Editor.withoutNormalizing(editor, () => {
            Transforms.setNodes(
                editor,
                { ['url']: value },
                { match: isLink, at: ReactEditor.findPath(editor, element) }
            );
            Transforms.insertText(editor, title, { at: ReactEditor.findPath(editor, element) });
        });
        cancelAndClose();
    };

    const handleOpen = (force?: boolean) =>
        (!isOpen || force) && window.open(parseValidUrl(element.url), '_blank', 'noreferrer');

    const handleSetRef = (element: HTMLAnchorElement) => {
        slateRef(element);
        refs.setReference(element);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            setValue(element?.url);
            cancelAndClose();
            e.preventDefault();
        } else if (e.key === 'Enter') {
            handleSave();
            e.preventDefault();
        }
    };

    const handleUnlink = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        unLink(editor, ReactEditor.findPath(editor, element));
        cancelAndClose();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(element.url);
        show({
            title: t`Link copied to clipboard`,
            type: 'success',
        });
    };

    return (
        <>
            <a
                ref={handleSetRef}
                className={'relative cursor-pointer text-blue-500 underline'}
                onClick={() => !isReadOnly && handleOpen()}
                href={value}
                target="_blank"
                rel="noreferrer"
                {...slateAttributes}
                {...getReferenceProps()}
            >
                {children}
            </a>
            {isOpen && (
                <div
                    ref={refs.setFloating}
                    className="z-10 flex gap-1 rounded-md border border-gray-400 bg-white p-2 shadow-md"
                    style={floatingStyles}
                    contentEditable={false}
                    {...getFloatingProps()}
                >
                    {isEdit ? (
                        <div className="min-w-[300px] sm:min-w-[400px]">
                            <Form>
                                <Form.Item label={t`Url`} htmlFor={`url`}>
                                    <Input
                                        ref={ref}
                                        id="url"
                                        name="url"
                                        value={value}
                                        size="sm"
                                        onChange={(e) => setValue(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        onPressEnter={handleSave}
                                        autoFocus
                                    />
                                </Form.Item>
                                <Form.Item label={t`Title`} htmlFor={`title`}>
                                    <Input
                                        id="title"
                                        name="title"
                                        value={title}
                                        size="sm"
                                        onChange={(e) => setTitle(e.target.value)}
                                        onPressEnter={handleSave}
                                    />
                                </Form.Item>
                                <div className="flex justify-end gap-2 sm:col-span-6">
                                    <Button
                                        variant="outlined"
                                        icon={faLinkSlash}
                                        iconClassName="mr-2"
                                        className="px-1 text-black hover:bg-gray-50"
                                        onClick={handleUnlink}
                                    >
                                        <Trans>Remove link</Trans>
                                    </Button>
                                    <Button color="primary" onClick={handleSave}>
                                        <Trans>Save</Trans>
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <>
                            <Button
                                size="sm"
                                variant="ghost"
                                className="max-w-[200px] truncate text-gray-400 hover:cursor-pointer hover:underline sm:max-w-[500px]"
                                iconClassName="mx-1 pl-1"
                                icon={faGlobe}
                                onClick={() => handleOpen(true)}
                            >
                                {element?.url}
                            </Button>
                            <Button size="sm" icon={faCopy} title={t`Copy`} onClick={handleCopy} />
                            <Button size="sm" icon={faPen} onClick={() => setIsEdit(true)}>
                                <Trans>Edit</Trans>
                            </Button>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export const linkPlugin = (): Plugin => ({
    renderElement: (editor, { element, children, attributes }) =>
        element.type === Link && (
            <LinkElement element={element} attributes={attributes}>
                {children}
            </LinkElement>
        ),
    isInline: (editor, element) => element.type === Link,
    insertBreak: (editor: Editor) =>
        isIn(editor, Link, (above) => {
            Transforms.insertNodes(editor, [createParagraphBlock()], {
                at: Path.next([above[1][0]]),
                mode: 'highest',
                select: true,
            });
            reorderBlocks(editor);
            return true;
        }),
    onPaste: (editor, event) => {
        if (editor.selection != null) {
            const data = event.clipboardData.getData('text/plain');
            if (URL_REGEX.test(data)) {
                const link = data.match(URL_REGEX)[0];
                Transforms.insertText(editor, data);
                // Select only link and wrap it in a link element
                Transforms.wrapNodes(
                    editor,
                    { type: 'link', url: link },
                    {
                        at: {
                            anchor: {
                                ...editor.selection.anchor,
                                offset: editor.selection.anchor.offset - data.length + data.indexOf(link),
                            },
                            focus: {
                                ...editor.selection.anchor,
                                offset: editor.selection.focus.offset - data.length + data.indexOf(link) + link.length,
                            },
                        },
                        split: true,
                    }
                );
                event.preventDefault();
                return true;
            }
        }
        return false;
    },
});
