import { Id } from '@wedo/types';
import { Mention } from 'Shared/types/Mention';
import { Attachment } from 'Shared/types/attachment';
import { User } from 'Shared/types/user';

export enum COMMENT_OBJECT_TYPE {
    TAG = 'tag',
    CHECKLIST_TEMPLATE = 'checklist_template',
    NETWORK = 'network',
    TEAM = 'team',
    TASK = 'task',
}

export interface Comment {
    id: Id;
    object_id: Id;
    object_type: COMMENT_OBJECT_TYPE;
    comment: string;
    updated_by: Id | User;
    network_id: Id;

    // Related
    mentions?: Partial<Mention>[];
    attachments?: Partial<Attachment>[];
}
