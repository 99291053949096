import { create } from 'zustand';
import { EmptyArray } from '@wedo/utils';
import { FileItem, TreeItem } from 'Shared/types/attachment';

export type State = {
    files: TreeItem[];
    selectedFilesIds: FileItem[];
};

export type Actions = {
    setFiles: (files: TreeItem[]) => void;
    setSelectedFilesIds: (selectedFilesIds: FileItem[]) => void;
};

export const useFilesStore = create<State & Actions>()((set) => ({
    files: EmptyArray as TreeItem[],
    selectedFilesIds: EmptyArray as FileItem[],
    setFiles: (files: TreeItem[]) => set(() => ({ files })),
    setSelectedFilesIds: (selectedFilesIds: FileItem[]) => set(() => ({ selectedFilesIds })),
}));
