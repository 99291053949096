import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, CloseSource, ContextModalProps, Modal, useConfirm } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ManageMeetingUserItems } from 'Pages/meeting/components/EditMeetingAccessModal/ManageMeetingUserItems';
import { useMeetingAccessStore } from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessStore';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';

type Props = {
    userId: Id;
} & ContextModalProps &
    PropsWithChildren;
export const EditMeetingUserItemsModal = ({ userId, children, ...modalProps }: Props): JSX.Element => {
    const { confirm } = useConfirm();

    const [hasChanged, setHasChanged] = useState(false);

    const {
        meetingUsers,
        meetingUserItems,
        updateMeetingUser,
        setMeetingUserItems,
        setHasChanged: setHasChangedStore,
    } = useMeetingAccessStore();

    const meetingUser = useMemo(() => meetingUsers.find(({ user_id }) => user_id === userId), [userId, meetingUsers]);
    const { meeting } = useMeeting(meetingUser?.meeting_id);

    const savedMeetingUser = useMemo(
        () => meeting?.meetingUsers?.find(({ id }) => id === meetingUser?.id),
        [meeting, meetingUser]
    );

    useEffect(() => {
        setHasChanged(false);
    }, [userId]);

    const handleHasChanged = (hasChanged: boolean) => {
        setHasChangedStore(hasChanged);
        setHasChanged(hasChanged);
    };

    const handleUpdateMeetingUserRole = (meetingRoleId: Id) =>
        updateMeetingUser(meetingUser, {
            attribute: 'meeting_role_id',
            value: meetingRoleId,
        });

    const handleRevert = async (close = true): Promise<boolean> => {
        const discard = await confirm({}, ConfirmDiscardChangesModal);
        if (discard) {
            if (savedMeetingUser) {
                handleUpdateMeetingUserRole(savedMeetingUser.meeting_role_id);
            }
            setHasChanged(false);
            setMeetingUserItems([
                ...meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id !== meetingUser.id),
                ...(meeting.meetingUsers.find(({ id }) => id === meetingUser.id)?.items ?? []),
            ]);
            if (close) {
                void modalProps.close();
            }
        }
        return Promise.resolve(Boolean(discard));
    };

    const handleBeforeClose = (source?: CloseSource): Promise<boolean> => {
        if (hasChanged && ['cancel', 'backdrop-or-esc', 'cross'].includes(source)) {
            return handleRevert();
        }
        return Promise.resolve(true);
    };

    return (
        <Modal size={'lg'} onBeforeClose={handleBeforeClose} {...modalProps}>
            <Modal.Header title={<Trans>{meetingUser.user.full_name} access rights</Trans>} />
            <Modal.Body>
                <ManageMeetingUserItems userId={userId} onChanged={handleHasChanged} />
            </Modal.Body>
            <Modal.Footer>
                <div className="flex gap-2">
                    <Button key="revert" onClick={() => handleRevert(false)} disabled={!hasChanged}>
                        <Trans>Revert</Trans>
                    </Button>
                    <Button key="confirm" color="primary" onClick={() => modalProps.close()}>
                        <Trans>Confirm</Trans>
                    </Button>
                </div>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
