import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { formatCurrency } from '@wedo/utils';

export const useFormattedCurrency = () => {
    const {
        i18n: { locale },
    } = useLingui();

    const getFormattedCurrency = useCallback(
        (value: number, currency?: string) => formatCurrency({ value, currency, locale }),
        [locale]
    );
    return { getFormattedCurrency };
};
