import { Element, Text } from 'slate';
import { colors, colors as themeColors, getColorId } from '@wedo/design-system';
import { tryOrValue } from '@wedo/utils';
import store from 'App/store';

type List = {
    id: string;
    level: number;
    tag: 'ol' | 'ul';
};

type Context = {
    list: List;
};

const serializeStyle = (properties: Array<[string, string]>, raw = false) => {
    const style = properties
        .filter(([, value]) => value != null)
        .map(([key, value]) => `${key}:${value}`)
        .join(';');
    return style !== '' ? (raw ? style : ` style="${style}"`) : '';
};

const serializeText = (element: Element): Node => {
    const style = serializeStyle(
        [
            ['color', element.color],
            ['background-color', colors[getColorId(element.backgroundColor, null)]?.['200'] ?? element.backgroundColor],
        ],
        true
    );
    const span = document.createElement('span');
    span.textContent = element.text;
    if (style !== '') {
        span.style = style;
    }
    let result = span.outerHTML;
    if (element.bold) {
        result = `<b>${result}</b>`;
    }
    if (element.italic) {
        result = `<i>${result}</i>`;
    }
    if (element.underlined) {
        result = `<u>${result}</u>`;
    }
    if (element.strikethrough) {
        result = `<s>${result}</s>`;
    }
    return result;
};

const serializeAttachment = (element: Element) => {
    return `<div data-wedo-id="${element.id}" data-updated-at="${element.updated_at}" data-updated-by="${element.updated_by}" ${
        (element.attachments?.length ?? 0) > 0
            ? ` data-type="attachment" data-attachments="${JSON.stringify(element.attachments.map(({ id, currentVersion, host, location, tags }) => [id, currentVersion.filename, host, location?.url, tags])).replaceAll('"', '&quot;')}"`
            : ''
    }>${element.attachments
        ?.map(({ currentVersion }) => currentVersion.filename)
        .concat(element.filenames ?? [])
        .join(', ')}</div>`;
};

const serializeBlock = (element: Element, context: Context) => {
    return `<div data-wedo-id="${element.id}" data-updated-at="${element.updated_at}" data-updated-by="${element.updated_by}" data-type="${element.type}"${serializeStyle(
        [
            ['color', { decision: themeColors.green['700'], note: themeColors.yellow['700'] }[element.type]],
            ['background-color', { decision: themeColors.green['100'], note: themeColors.yellow['100'] }[element.type]],
        ]
    )}>${serializeElements(element.children, context)}</div>`;
};

const serializeHeading = (element: Element, context: Context) => {
    const tag = ['', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'][element.level];
    return `<${tag}>${serializeElements(element.children, context)}</${tag}>`;
};

const serializeImage = (element: Element) => {
    const { size, textAlign } = tryOrValue(() => JSON.parse(element.decoration) ?? {}, {});
    return `<img data-wedo-id="${element.id}" data-updated-at="${element.updated_at}" data-updated-by="${element.updated_by}" src="${element.children[0].url}"${size?.width != null ? ` width="${size.width}"` : ''}${size?.height != null ? ` height="${size.height}"` : ''}${size?.maxWidth != null ? ` data-max-width="${size.maxWidth}"` : ''}${textAlign != null ? ` data-align="${textAlign}"` : ''}/>`;
};

const serializeLink = ({ type, ...element }: Element, context: Context) => {
    return element.url != null
        ? `<a href="${element.url}">${element.children != null ? serializeElements(element.children, context) : serializeText(element)}</a>`
        : serializeText(element);
};

const serializeList = (element: Element, context: Context) => {
    const tag = element.type === 'numbered-list' ? 'ol' : 'ul';
    return `<${tag}>${serializeElements(element.children, context)}</${tag}>`;
};

const serializeListItem = (element: Element, context: Context) => {
    return `<li>${serializeElements(element.children, context)}</li>`;
};

const closeLegacyList = (context: Context) => {
    if (context.list != null) {
        const result = `</li></${context.list.tag}>`.repeat(context.list.level);
        context.list = null;
        return result;
    }
    return '';
};

const serializeLegacyListItem = (
    { ['numbered-list-item']: numberedList, ['bulleted-list-item']: bulletedList, ...element }: Element,
    context: Context
) => {
    const id = element.index;
    const level = element.level;
    const tag = numberedList === true ? 'ol' : 'ul';
    const list = context.list;
    context.list = { id, level, tag };
    const item =
        element.url != null ? `<a href="${element.url}">${serializeText(element)}</a>` : serializeText(element);
    if (list == null) {
        return `<${tag}><li>${item}`;
    }
    if (list.tag !== tag) {
        return `</li></${list.tag}>`.repeat(list.level) + `<${tag}><li>${item}`;
    }
    if (list.level < level) {
        return `</li><li><${tag}><li>${item}`;
    }
    if (list.level > level) {
        return `</li></${list.tag}></li><li>${item}`;
    }
    if (list.id !== id) {
        return `</li><li>${item}`;
    }
    return item;
};

const serializeMention = (element: Element) => {
    return `<span data-type="mention" data-user-id="${element.userId}">${element.userFullName}</span>`;
};

const serializeParagraph = (element: Element, context: Context) => {
    return `<p${serializeStyle([['text-align', element.align]])}>${serializeElements(element.children, context)}</p>`;
};

const serializeTask = (element: Element) => {
    const task =
        element.task ??
        Object.entries(store.getState().api.queries).find(([key]) =>
            key.startsWith(`getTask({"id":"${element.task_id}"`)
        )?.[1].data;
    return `<div data-wedo-id="${element.id}" data-updated-at="${element.updated_at}" data-updated-by="${element.updated_by}" data-type="task" data-task-id="${element.task_id}"><input type="checkbox" checked="${task?.completed}"/>${task?.name}</div>`;
};

const serializeVote = (element: Element) => {
    const vote =
        element.vote ??
        Object.entries(store.getState().api.queries).find(([key]) =>
            key.startsWith(`getVote({"id":"${element.vote_id}"`)
        )?.[1].data;
    return `<div data-wedo-id="${element.id}" data-updated-at="${element.updated_at}" data-updated-by="${element.updated_by}" data-type="vote" data-vote-id="${element.vote_id}">${vote?.title}</div>`;
};

const serializeElement = (element: Element, context: Context) => {
    if (element['numbered-list-item'] === true || element['bulleted-list-item'] === true) {
        return serializeLegacyListItem(element, context);
    }
    switch (element.type) {
        case 'attachment':
            return serializeAttachment(element);
        case 'decision':
        case 'paragraph':
            return serializeBlock(element, context);
        case 'heading':
            return serializeHeading(element, context);
        case 'image':
            return serializeImage(element);
        case 'link':
            return serializeLink(element, context);
        case 'bulleted-list':
        case 'invisible-list':
        case 'list':
        case 'numbered-list':
        case 'todo-list':
            return serializeList(element, context);
        case 'list-item':
            return serializeListItem(element, context);
        case 'mention':
            return serializeMention(element);
        case 'text':
            return serializeParagraph(element, context);
        case 'task':
            return serializeTask(element);
        case 'vote':
            return serializeVote(element);
        default:
            return Text.isText(element) ? serializeText(element) : '';
    }
};

const serializeElements = (elements: Array<Element>, context: Context): string => {
    return elements.map((element) => serializeElement(element, context)).join('') + closeLegacyList(context);
};

export const serializeBlocks = (elements: Array<Element>) => {
    return serializeElements(elements, {});
};
