import { FC } from 'react';
import { faTag } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { WorkspaceHistory } from 'Shared/types/workspace';

export const RemovedStatusTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faTag}>
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> removed the workspace status{' '}
                    <WorkspaceStatusTag status={activity?.old_value?.status} className="inline-flex align-middle" />
                </Trans>
            </p>
        </Timeline.Item>
    );
};
