import { FC, useState } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { ChooseFileButton } from 'Pages/settings/users/components/ImportModal/ChooseFileButton';
import { ChooseFileTab } from 'Pages/settings/users/components/ImportModal/ChooseFileTab';
import { ImportModalBody, ImportModalFooter } from 'Pages/settings/users/components/ImportModal/ImportModalLayout';
import { ResultTab } from 'Pages/settings/users/components/ImportModal/ResultTab';
import { VerifyUploadButton } from 'Pages/settings/users/components/ImportModal/VerifyUploadButton';
import { VerifyUploadTab } from 'Pages/settings/users/components/ImportModal/VerifyUploadTab';
import { ConfirmUsersReturn, UploadUsersReturn } from 'Shared/services/admin';

export type ImportModalSwitchProps = {
    tabIndex: number;
    onClose: () => void;
    onStepChange: (step: number) => void;
};

export const ImportModalSwitch: FC<ImportModalSwitchProps> = ({ tabIndex, onClose, onStepChange }) => {
    const [users, setUsers] = useState<UploadUsersReturn[]>([]);
    const [result, setResult] = useState<ConfirmUsersReturn>(null);
    const [hasError, setHasError] = useState(0);

    const handleUpload = (data: UploadUsersReturn[]) => {
        setUsers(data);
        onStepChange(tabIndex + 1);
    };

    const handleSuccess = (data: ConfirmUsersReturn) => {
        setResult(data);
        onStepChange(tabIndex + 1);
        setHasError(0);
    };

    const handleError = (data: UploadUsersReturn[]) => {
        setUsers(data);
        setHasError(hasError + 1);
    };

    const handleUsersChange = (data: UploadUsersReturn[]) => {
        setUsers(data);
    };

    switch (tabIndex) {
        case 1:
            return (
                <>
                    <ImportModalBody step={1}>
                        <ChooseFileTab />
                    </ImportModalBody>
                    <ImportModalFooter step={1}>
                        <Button onClick={() => onStepChange(tabIndex - 1)}>
                            <Trans>Close</Trans>
                        </Button>
                        <ChooseFileButton onUpload={handleUpload} />
                    </ImportModalFooter>
                </>
            );
        case 2:
            return (
                <>
                    <ImportModalBody step={2}>
                        <VerifyUploadTab users={users} hasError={hasError} onChange={handleUsersChange} />
                    </ImportModalBody>
                    <ImportModalFooter step={2}>
                        <Button onClick={() => onStepChange(tabIndex - 1)} icon={faChevronLeft}>
                            <Trans>Back</Trans>
                        </Button>
                        {users.length > 0 ? (
                            <VerifyUploadButton users={users} onError={handleError} onSuccess={handleSuccess} />
                        ) : (
                            <Button color="primary" onClick={() => onStepChange(0)}>
                                <Trans>Close</Trans>
                            </Button>
                        )}
                    </ImportModalFooter>
                </>
            );
        case 3:
            return (
                <>
                    <ImportModalBody step={3}>
                        <ResultTab result={result} />
                    </ImportModalBody>
                    <ImportModalFooter step={3}>
                        <Button color="primary" onClick={onClose}>
                            <Trans>Finish</Trans>
                        </Button>
                    </ImportModalFooter>
                </>
            );
        default:
            return null;
    }
};
