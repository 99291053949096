import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';

export const CalendarPage = () => {
    const { workspaceId } = useParams();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });

    usePageTitle(`${workspace?.name} | ${t`Calendar`}`);

    return (
        <div>
            <h2>Calendar</h2>
        </div>
    );
};
