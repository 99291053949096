import { useMemo } from 'react';
import { isArray, isEmpty } from 'lodash-es';
import { useGetCustomFieldGroupsQuery } from 'Shared/services/customFields';
import { CustomFieldGroup } from 'Shared/types/customField';

export const useCustomFieldGroups = () => {
    const { data: customFieldGroups, isLoading, isError, error, refetch } = useGetCustomFieldGroupsQuery();

    const totalGroups = useMemo<number>(() => (customFieldGroups ? customFieldGroups.length : 0), [customFieldGroups]);

    const numberArchivedGroups = useMemo<number>(() => {
        if (!customFieldGroups) return 0;
        let count = 0;
        for (const group of customFieldGroups) {
            if (group.archived) count++;
        }
        return count;
    }, [customFieldGroups]);

    const maxOrder = useMemo<number>(() => {
        if (isEmpty(customFieldGroups)) return -1;
        let max = 0;
        for (const group of customFieldGroups) {
            max = Math.max(max, group.order);
        }
        return max;
    }, [customFieldGroups]);

    const idToGroupMapping = useMemo<Map<string, CustomFieldGroup>>(() => {
        const result = new Map<string, CustomFieldGroup>();
        if (!isArray(customFieldGroups)) return result;
        for (const group of customFieldGroups) {
            result.set(group.id, group);
        }
        return result;
    }, [customFieldGroups]);

    const getGroup = (groupId: string): CustomFieldGroup => idToGroupMapping.get(groupId);

    return {
        customFieldGroups,
        isLoading,
        totalGroups,
        numberArchivedGroups,
        maxOrder,
        getGroup,
        isError,
        error,
        refetch,
    };
};
