import { useState } from 'react';
import { faCopy, faEllipsisV, faEnvelope, faTrash, faUserPen } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Dropdown, UnexpectedErrorNotification, useModal, useNotification } from '@wedo/design-system';
import { useSessionUser, useUser } from 'App/store/usersStore';
import { EditUserModal } from 'Shared/components/user/AddEditUserModal/EditUserModal';
import { DeleteUserModal } from 'Shared/components/user/DeleteUserModal';
import { usePasswordResetModal } from 'Shared/hooks/usePasswordResetModal';
import { useLazyGetSurrogateKeyQuery } from 'Shared/services/surrogateKey';
import { User, UserRole } from 'Shared/types/user';

export const UserOptionsMenu = ({ user: userParam, onDelete }: { user: User; onDelete?: () => void }) => {
    const currentUser = useSessionUser();
    const user = useUser(userParam.id);
    const isCurrentUserAdmin = currentUser.role === UserRole.ADMIN;

    const { open: openModal } = useModal();
    const { show: showNotification } = useNotification();

    const { open: resetPassword } = usePasswordResetModal(user?.userEmail?.email_address, user?.full_name);
    const [isMailLoading, setIsMailLoading] = useState(false);
    const [getUserSurrogateKey] = useLazyGetSurrogateKeyQuery();

    const handleCopyEmailToTask = async () => {
        setIsMailLoading(true);
        const surrogateKey = await getUserSurrogateKey({ id: user?.id, property: 'user_id' });
        if ('error' in surrogateKey) {
            showNotification(UnexpectedErrorNotification);
        }
        setIsMailLoading(false);
        await navigator.clipboard.writeText(`"${user?.full_name}" <${surrogateKey.data.key}@mail.wedo.app>`);
        showNotification({
            title: t`Email address copied to clipboard`,
            type: 'success',
        });
    };

    const canEditUser =
        isCurrentUserAdmin ||
        currentUser.id === user?.id ||
        (user?.role === UserRole.EXTERNAL && currentUser.userNetwork.can_add_external === true);

    return (
        <Dropdown icon={faEllipsisV} aria-label={t`User options`}>
            <Dropdown.Item loading={isMailLoading} icon={faCopy} onClick={handleCopyEmailToTask}>
                <Trans>Copy email to task</Trans>
            </Dropdown.Item>
            <>
                {canEditUser && (
                    <Dropdown.Item icon={faUserPen} onClick={() => openModal(EditUserModal, { user })}>
                        <Trans>Edit user</Trans>
                    </Dropdown.Item>
                )}
                {isCurrentUserAdmin && (
                    <Dropdown.Item icon={faEnvelope} onClick={resetPassword}>
                        <Trans>Send password reset</Trans>
                    </Dropdown.Item>
                )}
                {currentUser.id !== user?.id && isCurrentUserAdmin && (
                    <>
                        <Dropdown.DividerItem />
                        <Dropdown.Item
                            danger={true}
                            icon={faTrash}
                            onClick={() =>
                                openModal(DeleteUserModal, {
                                    fullName: user?.full_name,
                                    userId: user?.id,
                                    onDelete: onDelete,
                                })
                            }
                        >
                            <Trans>Delete user</Trans>
                        </Dropdown.Item>
                    </>
                )}
            </>
        </Dropdown>
    );
};
