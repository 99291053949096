import { useEffect, useRef, useState } from 'react';
import { CollapsiblePaneHandle } from '@wedo/design-system';
import { enumeration, getObjectWithoutKeys, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { MeetingDeletedMessage } from 'Pages/meeting/components/MeetingView/MeetingDeletedMessage';
import { scrollIntoView } from 'Pages/meeting/components/TableOfContents/utils';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { MeetingStateValue } from 'Shared/types/meeting';
import { meetingStatusToMeetingView } from 'Shared/utils/meeting';
import { MeetingViewMode } from '../../MeetingViewMode';
import { currentWorkspaceSelected } from '../../MeetingViewSlice';
import { MeetingHeader } from '../MeetingHeader/MeetingHeader';
import { MeetingViewBody } from './MeetingViewBody';

export const MeetingViewSearchParams = {
    topicId: string(),
    meeting_topic_id: string(), // For compatibility reasons (old copy permalink)
    viewMode: enumeration(MeetingViewMode.DocumentView, MeetingViewMode.TopicView).options({ allowUnknown: true }),
};

export const getSearchParamsWithoutTopicId = (searchParams: Record<string, any>) => {
    return getObjectWithoutKeys(searchParams, ['topicId', 'meeting_topic_id']);
};

export const useSelectedTopicId = () => {
    const [searchParams] = useSearchParams(MeetingViewSearchParams);
    // We also check the "meeting_topic_id" parameter as that is the old permalink
    return searchParams.topicId || searchParams.meeting_topic_id;
};

export const MeetingView = () => {
    const dispatch = useAppDispatch();
    const tocPaneRef = useRef<CollapsiblePaneHandle>();
    const getDefaultPreferences = usePreferences();

    const { meeting, meetingError, refetchMeeting } = useMeetingContext();
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const [isTocCollapsed, setIsTocCollapsed] = useState(false);

    useEffect(() => {
        if (!meeting || searchParams?.viewMode || !meeting?.status) {
            return;
        }
        const viewMode =
            getDefaultPreferences('defaultMeetingView', null)?.[meeting?.status] ??
            meetingStatusToMeetingView[meeting?.status];
        setSearchParams(
            (searchParams) => ({
                ...searchParams,
                viewMode: viewMode,
            }),
            { replace: true }
        );
    }, [meeting?.id]);

    useEffect(() => {
        if (meeting?.tag_id) {
            dispatch(currentWorkspaceSelected({ workspaceId: meeting.tag_id }));
        }
    }, [meeting?.tag_id]);

    const handleViewChange = (mode: MeetingViewMode) => {
        setSearchParams({ ...searchParams, viewMode: mode, topicId: undefined });
        scrollIntoView('toc-attendees');
        scrollIntoView('attendees-table');
    };

    return (
        <>
            {meetingError == null ? (
                <div className="flex flex-1 flex-col overflow-hidden">
                    {meeting?.state === MeetingStateValue.DELETED && <MeetingDeletedMessage />}
                    <MeetingHeader />
                    <MeetingViewBody
                        tocPaneRef={tocPaneRef}
                        onTocToggle={setIsTocCollapsed}
                        onViewChange={handleViewChange}
                        isTocCollapsed={isTocCollapsed}
                    />
                </div>
            ) : (
                <div className={'h-64'}>
                    <RetryComponent retryFunction={refetchMeeting} />
                </div>
            )}
        </>
    );
};
