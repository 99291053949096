import React from 'react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    Card,
    FormatDate,
    NoInternetErrorNotification,
    Skeleton,
    Tag,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { ChargebeeInvoiceEstimate, ChargebeeSubscription } from '@wedo/types';
import { cardFooterPosition } from 'Pages/settings/billing/utils/style';
import { useReactivateSubscriptionMutation } from 'Shared/services/organization';
import { ApiError } from 'Shared/types/apiError';
import { ChargebeeSubscriptionStatusToString } from 'Shared/utils/chargebee';
import { isFetchError } from 'Shared/utils/rtkQuery';
import { ActiveSubscriptionDetails } from './ActiveSubscriptionDetails';

type SubscriptionCardProps = {
    subscription: ChargebeeSubscription;
    invoiceEstimate: ChargebeeInvoiceEstimate;
    className?: string;
};

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ subscription, invoiceEstimate, className }) => {
    const { show } = useNotification();

    const [reactivateSubscription, { isLoading: isReactivatingSubscription }] = useReactivateSubscriptionMutation();

    const handleReactivateSubscription = () => {
        void reactivateSubscription().then((response) => {
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else {
                    show(UnexpectedErrorNotification);
                }
            }
        });
    };

    if (subscription == null || invoiceEstimate == null) {
        return <Skeleton className="h-96" />;
    }

    const isActive = subscription?.status === 'active';

    return (
        <Card className={className}>
            <Card.Header
                title={t`Subscription`}
                actions={
                    <Tag color={isActive ? 'green' : 'red'}>
                        {ChargebeeSubscriptionStatusToString[subscription.status] &&
                            i18n._(ChargebeeSubscriptionStatusToString[subscription.status])}
                    </Tag>
                }
            />

            <Card.Body>
                {isActive && <ActiveSubscriptionDetails invoiceEstimate={invoiceEstimate} />}
                {subscription.status === 'non_renewing' && (
                    <div>
                        <Trans>
                            Your subscription will be cancelled on the{' '}
                            <FormatDate format="PPPP" date={subscription.current_term_end} className="font-semibold" />
                        </Trans>
                    </div>
                )}
            </Card.Body>

            {subscription.status === 'non_renewing' && (
                <Card.Footer className={cardFooterPosition}>
                    <Button
                        disabled={isReactivatingSubscription}
                        color="primary"
                        onClick={handleReactivateSubscription}
                        variant="filled"
                    >
                        <Trans>Reactivate my subscription</Trans>
                    </Button>
                </Card.Footer>
            )}
        </Card>
    );
};
