import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentProps, FC } from 'react';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Tooltip } from '@wedo/design-system';
import { Checklist } from 'Shared/types/checklist';

type ChecklistIconProps = { showTooltip?: boolean; checklist?: Checklist } & ComponentProps<'div'>;

export const ChecklistIcon: FC<ChecklistIconProps> = ({ checklist, showTooltip = false, className, ...props }) => {
    return (
        <Tooltip content={showTooltip && checklist?.name}>
            <div
                className={clsx('flex h-6 w-6 shrink-0 items-center justify-center rounded bg-gray-200', className)}
                {...props}
            >
                <FontAwesomeIcon icon={faCircleCheck} className="h-3 w-3 text-gray-800" />
            </div>
        </Tooltip>
    );
};
