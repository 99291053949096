import React, { FC, useRef } from 'react';
import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { Trans, t } from '@lingui/macro';
import { Editor as SlateEditor } from 'slate';
import { Button, ContextModalProps, Modal, NavLink, Spinner, useConfirm } from '@wedo/design-system';
import { getUser } from 'App/store/usersStore';
import { TopicSubmissionDropdown } from 'Pages/MyTopicsPage/components/TopicSubmissionDropdown';
import { TopicSubmissionHistory } from 'Pages/MyTopicsPage/components/TopicSubmissionHistory';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { TopicPresenters } from 'Shared/components/meeting/TopicPresenters';
import { TopicSubmissionTag } from 'Shared/components/meeting/TopicSubmissionTag';
import { TopicDurationPopover } from 'Shared/components/meeting/topicView/TopicDuration';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingTopic, MeetingTopicSubmission, TopicSubmissionStatus } from 'Shared/types/meetingTopic';
import { getTopicSubmissionStatus } from 'Shared/utils/meetingTopicSubmission';

type TopicSubmissionStatusModalProps = {
    meetingTopic: MeetingTopic;
    meetingTopicSubmission: MeetingTopicSubmission;
    completedTopic?: MeetingTopic;
} & ContextModalProps;

export const revokeSubmissionConfirmContent = {
    type: 'danger',
    title: t`Revoke topic submission`,
    content: (
        <p>
            <Trans>
                Revoking this submission will revert this topic back to draft and you can then edit and re-submit it.
            </Trans>
        </p>
    ),
    confirmText: t`Revoke submission`,
};

export const TopicSubmissionStatusModal: FC<TopicSubmissionStatusModalProps> = ({
    meetingTopic,
    meetingTopicSubmission,
    completedTopic,
    children,
    ...modalProps
}) => {
    const editorRef = useRef<SlateEditor>();
    const { confirm } = useConfirm();
    const { mutateAsync: removeSubmission } = trpc.meetingTopic.removeSubmission.useMutation({
        onSuccess: () => trpcUtils().meetingTopic.listSubmissions.invalidate(),
    });

    const { data: submittedMeeting } = trpc.meeting.getForSubmission.useQuery(
        { meetingId: meetingTopicSubmission?.meetingId },
        {
            enabled: meetingTopicSubmission?.meetingId != null,
        }
    ) as { data: MeetingTopic };

    const { data: topics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingTopicSubmission?.meetingId, {
        enabled:
            meetingTopicSubmission?.type === TopicSubmissionStatus.ACCEPTED &&
            meetingTopicSubmission?.meetingId != null,
    }) as { data: MeetingTopic[] };

    const generatedTopic = topics?.find((topic) => topic.topicSeriesId === meetingTopic?.topicSeriesId);

    const submissionStatus = getTopicSubmissionStatus(meetingTopic, meetingTopicSubmission, completedTopic);

    const handleRevokeSubmission = async () => {
        if (await confirm(revokeSubmissionConfirmContent)) {
            const result = await removeSubmission({ topicSubmissionId: meetingTopicSubmission.id });
            if (result != null) {
                void trpcUtils().meetingTopic.listSubmissions.invalidate();
                void modalProps.close();
            }
        }
    };

    const topicId = [TopicSubmissionStatus.ACCEPTED, TopicSubmissionStatus.COMPLETED].includes(submissionStatus)
        ? completedTopic != null
            ? completedTopic
            : generatedTopic != null
              ? generatedTopic.id
              : undefined
        : meetingTopicSubmission.topicId;

    if (meetingTopic == null) {
        return (
            <Modal {...modalProps} size="lg">
                <Modal.Header title={t`Topic submission status`} />

                <Modal.Body>
                    <div className="flex justify-center p-4">
                        <Spinner color="blue" size="lg" />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal {...modalProps} size="lg">
            {([TopicSubmissionStatus.SUBMITTED, TopicSubmissionStatus.ACCEPTED].includes(submissionStatus) ||
                (submissionStatus === TopicSubmissionStatus.REJECTED && meetingTopicSubmission.reason != null)) && (
                <div className="border-b px-4 py-2 w-full outline-none text-sm bg-yellow-50 rounded-t-md">
                    {submissionStatus === TopicSubmissionStatus.SUBMITTED ? (
                        <Trans>
                            This topic has been submitted to{' '}
                            {submittedMeeting.canAccessMeeting ? (
                                <NavLink
                                    className="underline hover:text-blue-700 text-blue-500"
                                    to={{
                                        pathname: `/meetings/${meetingTopicSubmission.meetingId}`,
                                        searchParams: {
                                            viewMode: MeetingViewMode.TopicView,
                                            topicId: topicId,
                                        },
                                    }}
                                >
                                    {submittedMeeting?.title}
                                </NavLink>
                            ) : (
                                submittedMeeting?.title
                            )}
                            . A topic that has been submitted for review to a meeting can't be edited anymore.
                        </Trans>
                    ) : submissionStatus === TopicSubmissionStatus.ACCEPTED ? (
                        <Trans>
                            This topic has been accepted in{' '}
                            {submittedMeeting.canAccessMeeting ? (
                                <NavLink
                                    className="underline hover:text-blue-700 text-blue-500"
                                    to={{
                                        pathname: `/meetings/${meetingTopicSubmission.meetingId}`,
                                        searchParams: {
                                            viewMode: MeetingViewMode.TopicView,
                                            topicId: topicId,
                                        },
                                    }}
                                >
                                    {submittedMeeting?.title}
                                </NavLink>
                            ) : (
                                submittedMeeting?.title
                            )}
                            . A topic that has been accepted in a meeting can't be edited anymore.
                        </Trans>
                    ) : (
                        submissionStatus === TopicSubmissionStatus.REJECTED &&
                        meetingTopicSubmission.reason != null && (
                            <div>
                                <b>
                                    <Trans>Reason for rejection:</Trans>
                                </b>{' '}
                                {meetingTopicSubmission.reason}
                            </div>
                        )
                    )}
                </div>
            )}

            <div className="p-4 flex justify-between gap-2 border-b">
                <div className="w-full outline-none text-xl font-semibold">{meetingTopic.title}</div>
                <div className="flex gap-2 items-center">
                    <TopicSubmissionTag submissionType={submissionStatus} />
                    <TopicSubmissionDropdown
                        meetingTopic={meetingTopic}
                        meetingTopicSubmission={meetingTopicSubmission}
                        onDone={() => modalProps.close()}
                        hasMeetingAccess={submittedMeeting?.canAccessMeeting}
                    />
                </div>
            </div>

            <div className="flex gap-1 items-center justify-end w-full border-b px-2">
                <TopicPresenters
                    disabled
                    topicPresenters={meetingTopic.presenters.map(({ userId }) => getUser(userId))}
                    wrapperClassName="pt-1"
                />
                <TopicDurationPopover disabled duration={meetingTopic.duration} />
            </div>

            <TopicEditor
                isReadOnly
                isStatic
                editorRef={editorRef}
                topic={
                    submissionStatus === TopicSubmissionStatus.COMPLETED
                        ? completedTopic != null
                            ? completedTopic
                            : generatedTopic
                        : meetingTopic
                }
                viewMode={MeetingViewMode.TopicView}
                className="min-h-[300px] rounded-b-lg z-20"
            />

            <TopicSubmissionHistory topicId={meetingTopic.id} topicSubmission={meetingTopicSubmission} />

            <Modal.Footer>
                <div className="flex gap-2 items-center">
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>
                    {meetingTopicSubmission.status == null && (
                        <Button onClick={handleRevokeSubmission} color="danger" icon={faRotateLeft}>
                            <Trans>Revoke submission</Trans>
                        </Button>
                    )}
                </div>
            </Modal.Footer>

            {children}
        </Modal>
    );
};
