import { useState, useCallback } from 'react';
import { Draft, freeze, produce } from 'immer';

export const useImmer = <T>(initialValue: T | (() => T)): [T, (value: T | ((draft: Draft<T>) => void)) => T] => {
    const [value, setValue] = useState(() =>
        freeze(initialValue instanceof Function ? initialValue() : initialValue, true)
    );
    return [
        value,
        useCallback((setter) => {
            let nextValue: T;
            if (setter instanceof Function) {
                setValue((value) => (nextValue = produce(value, setter)));
            } else {
                setValue((nextValue = freeze(setter)));
            }
            return nextValue;
        }, []),
    ];
};
