import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { faCalendarAlt, faLock } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    AutoTooltipText,
    Button,
    ContextModalProps,
    FormatDateDistance,
    Modal,
    Skeleton,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import { AttachmentMenu } from 'Shared/components/file/AttachmentItem/AttachmentMenu';
import { Preview } from 'Shared/components/file/fileDetails/Preview';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import {
    invalidateAttachment,
    invalidateGetAttachmentVersions,
    useDeleteAttachmentVersionsMutation,
    useGetAttachmentQuery,
    useGetAttachmentVersionsQuery,
} from 'Shared/services/attachment';
import { Attachment } from 'Shared/types/attachment';

type VersioningFileProps = {
    attachment: Partial<Attachment>;
    showMenu?: boolean;
};
const VersioningFile = ({ attachment, showMenu = false }: VersioningFileProps) => {
    const [deleteAttachmentVersions] = useDeleteAttachmentVersionsMutation();
    const dispatch = useAppDispatch();
    const { show } = useNotification();

    const handleReload = () => {
        dispatch(invalidateAttachment(attachment.attachment_id));
        dispatch(invalidateGetAttachmentVersions());
    };

    const handleDelete = async () => {
        const res = await deleteAttachmentVersions({ versions: [{ id: attachment.id }] });
        if ('error' in res) {
            show(UnexpectedErrorNotification);
            return;
        }
        dispatch(invalidateAttachment(attachment.attachment_id));
        dispatch(invalidateGetAttachmentVersions());
    };

    return (
        <div className="my-5 flex w-full">
            <Preview size="sm" attachment={attachment} />
            <div className="flex w-full flex-col justify-between p-3">
                <div className="flex justify-between">
                    <div className="max-w-[14rem] font-bold">
                        <AutoTooltipText tooltipText={attachment.filename}>{attachment.filename}</AutoTooltipText>
                    </div>
                </div>
                {attachment?.lock && (
                    <div className="flex text-xs text-gray-600">
                        <FontAwesomeIcon icon={faLock} className="mr-1" />
                        <Trans>Locked meeting</Trans>
                    </div>
                )}
                <div className="flex flex-row gap-4 text-xs">
                    <div>
                        <Tooltip content={t`Updated at`}>
                            <div className="flex flex-row gap-1">
                                <FontAwesomeIcon icon={faCalendarAlt} className="text-lg" />
                                <FormatDateDistance date={attachment.updated_at} hideTooltip />
                            </div>
                        </Tooltip>
                    </div>

                    <div className="flex flex-row">
                        <Tooltip content={t`Updated By`}>
                            <div className="flex flex-row gap-1">
                                <UserAvatar user={attachment.updated_by} size="xs" showTooltip={false} />
                                <span className="ml-1">{attachment.updated_by.full_name}</span>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            {showMenu && (
                <div className="mt-2">
                    <AttachmentMenu
                        attachment={attachment}
                        relation={{}}
                        onReload={handleReload}
                        onDelete={handleDelete}
                        isVersion
                    />
                </div>
            )}
        </div>
    );
};

type FileVersioningModalProps = {
    attachmentId: Id;
    children?: ReactNode;
} & ContextModalProps;

export const FileVersioningModal = ({ attachmentId, children, ...modalProps }: FileVersioningModalProps) => {
    const { data: attachment } = useGetAttachmentQuery({ id: attachmentId }, { skip: !attachmentId });
    const { data: versions, isLoading } = useGetAttachmentVersionsQuery({ id: attachmentId }, { skip: !attachmentId });

    return (
        <Modal size="sm" {...modalProps}>
            <Modal.Header title={t`Versioning`} />
            <Modal.Body>
                {!attachment || isLoading ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-4" />
                    </div>
                ) : (
                    <div className="flex flex-col">
                        <div className="mb-2">
                            <div className="leading-6 text-gray-700">
                                <Trans>Current version</Trans>
                            </div>
                            <VersioningFile attachment={{ ...attachment, ...attachment.currentVersion }} />
                        </div>
                        {versions.length > 0 && (
                            <div className="mb-2">
                                <div className="leading-6 text-gray-700">
                                    <Trans>Previous versions</Trans>
                                </div>
                                {versions.map((version) => (
                                    <VersioningFile showMenu key={version.id} attachment={version} />
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
