import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Banner, useNotification } from '@wedo/design-system';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useRestoreMeetingMutation } from 'Shared/services/meeting';
import { ApiError } from 'Shared/types/apiError';

export const MeetingDeletedMessage = (): JSX.Element => {
    const { meetingId } = useMeetingContext();

    const [restoreMeeting] = useRestoreMeetingMutation();
    const { show: showNotification } = useNotification();

    const handleRestore = async () => {
        const res = await restoreMeeting({ meetingId });
        if (
            'error' in res &&
            res.error instanceof ApiError &&
            res.error?.data?.errors[0]?.message === 'deleted meeting cannot be the same date as an existing occurrence'
        ) {
            showNotification({
                type: 'danger',
                title: t`There is already another meeting with the same date in the series`,
            });
        }
    };

    return (
        <Banner type={'danger'} icon={faTrash} buttonText={t`Restore`} onButtonClick={handleRestore}>
            <Trans>This meeting has been deleted.</Trans>
        </Banner>
    );
};
