import { KeyboardEvent } from 'react';
import { useEvent, UseEventTarget } from './useEvent';

export const useCmdKeyboardShortcut = (
    key: string,
    handler: () => void,
    options: { target: UseEventTarget } = { target: window }
) => {
    useEvent(
        'keydown',
        (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === key) {
                event.preventDefault();
                event.stopPropagation();
                handler();
            }
        },
        options.target
    );
};
