import { useDispatch } from 'react-redux';
import { t } from '@lingui/macro';
import { ConfirmModal, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { useSessionUser } from 'App/store/usersStore';
import { PrivacyType } from 'Shared/components/userGroup/ManageMembersModalBody';
import { isUserGroupMember } from 'Shared/hooks/useIsUserGroupMember';
import { isUserGroupModerator } from 'Shared/hooks/useIsUserGroupModerator';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { templateApi, templateTag, useGetChecklistTemplateQuery } from 'Shared/services/template';
import { trpc } from 'Shared/trpc';
import { User } from 'Shared/types/user';
import { waitForQueryToBeFulfilled } from 'Shared/utils/rtkQuery';

export const useTemplateManageMembers = (templateId: string) => {
    const sessionUser = useSessionUser();

    const dispatch = useDispatch();
    const { show } = useNotification();
    const { confirm } = useConfirm();

    const { data: template } = useGetChecklistTemplateQuery(templateId);

    const { confirmMoveToPrivate, confirmRemoveCurrentUser } = useManageMembers(template);

    const invalidateTemplate = async () => {
        dispatch(templateApi.util.invalidateTags([templateTag(templateId)]));
        // TODO We resolve the promise only when the getChecklistTemplate fetch has been fulfilled. It's a bit of a hack
        //  until we refactor how invalidation works
        await waitForQueryToBeFulfilled(templateApi.endpoints.getChecklistTemplate.select(templateId));
    };

    const { mutateAsync: addMember } = trpc.template.addMember.useMutation({
        onSuccess: invalidateTemplate,
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: removeMember } = trpc.template.removeMember.useMutation({
        onSuccess: invalidateTemplate,
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: updateMember } = trpc.template.updateMember.useMutation({
        onSuccess: invalidateTemplate,
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: togglePrivacy } = trpc.template.togglePrivacy.useMutation({
        onSuccess: invalidateTemplate,
        onError: () => show(UnexpectedErrorNotification),
    });

    const handleAddUser = async (user: User) => {
        await addMember({ templateId, userId: user.id });
    };

    const handleDeleteUser = async (user: User) => {
        const isTeamMember = isUserGroupMember(user, template.team);
        if (!template.private && isTeamMember) {
            const confirmDelete = await confirmMoveToPrivate(user);
            if (!confirmDelete) {
                return '';
            }
        }
        if (sessionUser.id === user.id && ((template.team_id && template.private) || !template?.team_id)) {
            const confirmDelete = await confirmRemoveCurrentUser();
            if (!confirmDelete) {
                return '';
            }
        }
        await removeMember({ templateId, userId: user.id });
        return user.id === sessionUser.id ? 'close' : '';
    };

    const handleModeratorToggle = async (userId: string, isModerator: boolean) => {
        const isTemplateMember = isUserGroupMember(sessionUser, template, true);
        const isTemplateModerator = isUserGroupModerator(sessionUser, template, true);
        if (userId === sessionUser.id && !isModerator && isTemplateMember && isTemplateModerator) {
            const confirmRemove = await confirm(
                {
                    type: 'warning',
                    title: t`Are you sure you want to remove yourself as a moderator?`,
                    content: t`You will still remain a member of ${template?.name} template, but you will no longer be able to add/remove members.`,
                },
                ConfirmModal
            );
            if (!confirmRemove) {
                return;
            }
        }
        await updateMember({ templateId, userId, isModerator });
    };

    const handleTogglePrivacy = async (updatedPrivacy: PrivacyType) => {
        if (updatedPrivacy === 'public') {
            const confirmUpdate = await confirm(
                {
                    type: 'warning',
                    title: t`Are you sure you want to restore access to the team?`,
                    content: t`All members of the ${template?.team?.name} team will be able to access this template.`,
                    confirmText: t`Restore access`,
                },
                ConfirmModal
            );
            if (!confirmUpdate) {
                return;
            }
        }
        await togglePrivacy({ templateId });
    };

    return {
        template,
        handleAddUser,
        handleDeleteUser,
        handleModeratorToggle,
        handleTogglePrivacy,
    };
};
