import React from 'react';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Dropdown, useModal } from '@wedo/design-system';
import { EditCustomFieldModal } from 'Pages/settings/customFields/components/EditCustomFieldModal/EditCustomFieldModal';
import { useArchiveFieldModal } from 'Pages/settings/customFields/hooks/useArchiveFieldModal';
import { useDeleteCustomFieldModal } from 'Pages/settings/customFields/hooks/useDeleteCustomFieldModal';
import { archiveIcon, deleteIcon, editIcon, unArchiveIcon } from 'Pages/settings/customFields/utils/action';
import { useUnArchiveCustomFieldMutation } from 'Shared/services/customFields';
import { CustomField } from 'Shared/types/customField';

interface CustomFieldDropdownProps {
    customField: CustomField;
}

export const CustomFieldDropdown: React.FC<CustomFieldDropdownProps> = ({ customField }) => {
    const [unarchiveField] = useUnArchiveCustomFieldMutation();
    const { open: openDeleteModal } = useDeleteCustomFieldModal();
    const { open: openArchiveModal } = useArchiveFieldModal();
    const { open } = useModal();

    const isInGroup = !!customField.custom_field_group_id;

    return (
        <Dropdown icon={faEllipsisV} size="sm" data-testid={isInGroup ? 'field-dropdown-group' : 'field-dropdown'}>
            <Dropdown.Item
                icon={editIcon}
                onClick={() =>
                    open(EditCustomFieldModal, { field: customField, groupId: customField.custom_field_group_id })
                }
            >
                <Trans>Edit</Trans>
            </Dropdown.Item>
            {!customField?.archived && (
                <Dropdown.Item icon={archiveIcon} onClick={() => openArchiveModal(customField)}>
                    <Trans>Archive</Trans>
                </Dropdown.Item>
            )}
            {customField?.archived && (
                <Dropdown.Item icon={unArchiveIcon} onClick={() => unarchiveField(customField.id)}>
                    <Trans>Unarchive</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.DividerItem />
            <Dropdown.Item onClick={() => openDeleteModal(customField)} icon={deleteIcon} danger={true}>
                <Trans>Delete</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};
