export const remToPx = (rem: number) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const waitForAllAnimations = (elements: Array<HTMLElement>, onUpdate?: () => void) =>
    new Promise<void>((resolve) => {
        const animations = elements.flatMap((element) => element.getAnimations());
        const update = () => {
            const isRunning = animations.some(({ playState }) => playState === 'running');
            if (isRunning) {
                onUpdate?.();
                requestAnimationFrame(update);
            } else {
                resolve();
            }
        };
        update();
    });
