import React, { FC } from 'react';
import { Trans, t } from '@lingui/macro';
import { Select, SelectProps } from '@wedo/design-system';
import { SectionNumber } from 'Pages/meeting/components/TableOfContents/TocSection';
import { trpc } from 'Shared/trpc';
import { Meeting } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';

type MeetingSectionsSelectProps = { meeting: Meeting; value: string; onChange: (value: string) => void } & Pick<
    SelectProps,
    'className' | 'wrapperClassName' | 'popoverClassName' | 'position'
>;

export const MeetingSectionsSelect: FC<MeetingSectionsSelectProps> = ({
    meeting,
    value,
    onChange,
    className,
    wrapperClassName,
    popoverClassName,
    position,
}) => {
    const { data: sections = [] } = trpc.meetingSection.listByMeetingId.useQuery(meeting?.id);

    const selectedSection = sections.find((section) => section.id === value);

    return (
        <Select
            placeholder={t`Choose a section`}
            value={value}
            onChange={onChange}
            className={className}
            wrapperClassName={wrapperClassName}
            popoverClassName={popoverClassName}
            position={position}
            customRenderSelected={(sectionId: string) => {
                if (sectionId === 'no-section') {
                    return t`No section`;
                }
                return (
                    <div className="flex gap-1">
                        <SectionNumber
                            displayId={selectedSection?.displayId}
                            hideSectionNumbering={meeting?.settings?.hide_section_numbering}
                        />
                        {selectedSection?.title}
                    </div>
                );
            }}
        >
            <Select.Option key="no-section" value="no-section">
                <Trans>No section</Trans>
            </Select.Option>
            {sections.map((section: MeetingSection) => (
                <Select.Option key={section.id} value={section.id}>
                    <div className="flex gap-1">
                        <SectionNumber
                            displayId={section?.displayId}
                            hideSectionNumbering={meeting?.settings?.hide_section_numbering}
                        />
                        {section.title}
                    </div>
                </Select.Option>
            ))}
        </Select>
    );
};
