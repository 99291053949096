import { Children, ComponentProps, createRef, forwardRef, ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { Spinner } from '~/components/Spinner/Spinner';

export const classes = {
    base: 'h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 shrink-0',
    disabled: 'opacity-50 bg-gray-300 border-gray-400 cursor-not-allowed',
};

export type CheckboxProps = {
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    isIndeterminate?: boolean;
    children?: ReactNode | undefined;
} & Omit<ComponentProps<'input'>, 'type' | 'ref' | 'className'>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        { className, disabled = false, loading = false, isIndeterminate, children, ...props }: CheckboxProps,
        forwardedRef
    ) => {
        const ref = forwardedRef ?? createRef();

        useEffect(() => {
            if (ref.current != null) {
                ref.current.indeterminate = isIndeterminate;
            }
        }, [isIndeterminate]);

        if (loading) {
            return <Spinner color="blue" className="h-4 w-4 hover:cursor-wait" />;
        }

        const checkbox = (
            <input
                ref={ref}
                disabled={disabled}
                className={clsx(classes.base, disabled ? classes.disabled : 'cursor-pointer', className)}
                type="checkbox"
                {...props}
            />
        );

        if (Children.count(children) > 0) {
            return (
                <label className="flex items-center gap-2 text-sm">
                    {checkbox}
                    {children}
                </label>
            );
        }

        return checkbox;
    }
);

Checkbox.displayName = 'Checkbox';
