import { t } from '@lingui/macro';
import {
    customFetch,
    mark,
    measure,
    withAuth,
    withHeader,
    withJsonBody,
    withMethod,
    withUnauthorizedHandler,
    withUrl,
} from '@wedo/utils';
import store from 'App/store';
import { clientVersion } from 'App/store/versionStore';
import { generateErrorPdf } from 'Pages/meeting/components/MeetingExportModal/generateErrorPdf';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { attachmentApi } from 'Shared/services/attachment';
import { Attachment } from 'Shared/types/attachment';

const logPerformance = async (attachmentId: string, duration: number, error?: string) => {
    try {
        await customFetch(
            withAuth,
            withUnauthorizedHandler,
            withHeader('X-Client-Version', clientVersion()),
            withUrl(`/api/log`),
            withMethod('POST'),
            withJsonBody({
                type: 'attachmentPdfExport',
                message: error,
                attachmentId,
                duration,
            })
        );
    } catch (error) {
        // Ignore error
    }
};

export const generateAttachmentPdf = async ({ id, name }: Attachment) => {
    const start = mark();
    const { instance } = await getSharedPdfViewerInstance();
    try {
        const { data } = await store.dispatch(attachmentApi.endpoints.getAttachment.initiate({ id }));
        const documentUrl = `${location.origin}${data.download_url}`;
        const document = await instance.Core.createDocument(documentUrl, { loadAsPDF: true, filename: name });
        void logPerformance(id.toString(), measure(start, mark()).duration);
        return document;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        void logPerformance(id.toString(), measure(start, mark()).duration, error?.stack ?? error?.toString());
        return await instance.Core.createDocument(
            await generateErrorPdf(t`An error occured while rendering attachment "${name}"`),
            { extension: 'pdf' }
        );
    }
};
