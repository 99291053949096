import React from 'react';
import { faParagraph } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { EmptyState, Skeleton } from '@wedo/design-system';
import { array, EmptyString, enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { CommonSearchPageSearchParams } from 'Pages/SearchPage/SearchPage';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { useSelectedTaskModal } from 'Shared/hooks/useSelectedTaskModal';
import { useSelectedVoteModal } from 'Shared/hooks/useSelectedVoteModal';
import { useGetMeetingBlocksQuery } from 'Shared/services/meetingBlock';
import { BlockSearchItem } from './BlockSearchItem';

const MeetingBlocksSearchPageSearchParams = {
    ...CommonSearchPageSearchParams,
    workspaces: array(string()),
    order: enumeration('rank', 'by_meeting_date').default('rank'),
    types: array(enumeration('paragraph', 'decision', 'task', 'attachment', 'vote')),
};

const BlocksInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const [{ search, scope, workspaces, types, order }] = useSearchParams(MeetingBlocksSearchPageSearchParams);

    const { data, isLoading } = useGetMeetingBlocksQuery(
        {
            search: search ?? EmptyString,
            searchType: scope,
            types,
            tagIds: workspaces,
            orderBy: order,
            related: [
                'topic',
                'topic.meeting',
                'topic.meeting.tag',
                'topic.meeting.meetingUsers',
                'task',
                'task.recurrence',
                'task.checklist',
                'task.checklist.checklistTemplate',
                'attachments.currentVersion',
                'attachments.tags',
                'updated_by',
            ],
            offset,
            limit,
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data);

    return (
        <>
            {isLoading ? (
                <Skeleton count={5} className="h-24" />
            ) : (
                data.map((block) => <BlockSearchItem key={block.id} meetingBlock={block} />)
            )}
        </>
    );
};

export const MeetingBlocksSearchPage = () => {
    useSelectedTaskModal();

    useSelectedVoteModal();

    return (
        <div className="flex h-full max-h-full flex-col overflow-hidden">
            <InfiniteScroll
                className="scrollbar-light flex flex-col gap-4 p-6"
                page={BlocksInfiniteScrollPage}
                emptyPage={
                    <EmptyState icon={faParagraph} size="lg">
                        <EmptyState.Text className="ignore-marker">
                            <Trans>No meeting blocks found</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                }
                size={20}
                isSearchHighlighted={true}
            />
        </div>
    );
};
