import { useLingui } from '@lingui/react';
import React from 'react';
import { faHistory } from '@fortawesome/pro-duotone-svg-icons';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, EmptyState, IconName, Timeline } from '@wedo/design-system';
import { RumErrorBoundary } from 'Shared/components/RumErrorBoundary';
import { GetTextParam } from 'Shared/components/task/TaskActivityLogsModal/TaskActivityLogsValues';
import { ActivityLog } from 'Shared/types/activityLog';

const ErrorFallback = ({ error, resetError }: { error: Error; resetError: () => void }) => {
    return (
        <div className={'flex min-h-[450px] w-full flex-col items-center justify-center bg-red-100 text-red-500'}>
            <div className={'text-5xl'}>
                <Trans>The activity log has crashed</Trans>
            </div>
            <div className={'mb-5 text-xl'}>
                <Trans>Click on the reload button to retry.</Trans>
            </div>
            <Button onClick={resetError} size={'large'} color="danger">
                <Trans>Reload</Trans>
            </Button>
            <div className={'mb-3 mt-7 text-base'}>
                <Trans>If it persists, please contact support with the following error message:</Trans>
            </div>
            <pre className={'max-h-32'}>{error.stack}</pre>
        </div>
    );
};

type ActivitiesTableProps = {
    activities: ActivityLog[];
    getIcon: (activityLog: ActivityLog) => IconDefinition | IconName;
    getText: (params: GetTextParam, activities?: ActivityLog[]) => JSX.Element;
    getDescription?: (activity: ActivityLog) => JSX.Element;
    isImportant?: (activityLog: ActivityLog) => boolean;
    getIconColor?: (
        activityLog: ActivityLog,
        activities: ActivityLog[]
    ) => 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange';
    getIconTextColor?: (activityLog: ActivityLog, activities?: ActivityLog[]) => string;
};
export const ActivitiesTable = ({
    activities,
    getIcon,
    getText,
    getDescription,
    isImportant,
    getIconColor,
    getIconTextColor,
}: ActivitiesTableProps): JSX.Element => {
    const { i18n } = useLingui();

    return (
        <RumErrorBoundary fallback={ErrorFallback}>
            {!activities || activities.length === 0 ? (
                <EmptyState icon={faHistory} size="md">
                    <EmptyState.Text>
                        <Trans>No activities found</Trans>
                    </EmptyState.Text>
                </EmptyState>
            ) : (
                <Timeline>
                    {activities.map((activity) =>
                        getText({ activity, i18n }, activities) != null ? (
                            <Timeline.Item
                                key={activity.id}
                                isImportant={isImportant?.(activity)}
                                icon={getIcon(activity)}
                                dateTime={activity.created_at}
                                iconColor={getIconColor?.(activity, activities)}
                                iconTextColor={getIconTextColor?.(activity)}
                            >
                                <div className="flex flex-col">
                                    <div>{getText({ activity, i18n }, activities)}</div>
                                    {getDescription?.(activity) && (
                                        <div className="text-xs italic text-gray-500">{getDescription(activity)}</div>
                                    )}
                                </div>
                            </Timeline.Item>
                        ) : (
                            <></>
                        )
                    )}
                </Timeline>
            )}
        </RumErrorBoundary>
    );
};
