import { LocaleDefinition } from '@wedo/types';

export const en: LocaleDefinition = {
    DATETIME_FORMATS: {
        AMPMS: ['AM', 'PM'],
        DAY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        FIRSTDAYOFWEEK: 6,
        MONTH: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        SHORTDAY: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        SHORTMONTH: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        WEEKENDRANGE: [5, 6],
        fullDate: 'eeee, MMMM d y',
        longDate: 'MMMM d, y',
        medium: 'MMM d, y h:mm:ss a',
        mediumDate: 'MMM d, y',
        mediumTime: 'h:mm:ss a',
        short: 'M/d/y h:mm a',
        shortDate: 'M/d/y',
        shortTime: 'h:mm a',
    },
    NUMBER_FORMATS: {
        DECIMAL_SEP: '.',
        GROUP_SEP: ',',
    },
};
