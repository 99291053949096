import React, { useEffect, useMemo, useState } from 'react';
import { faChevronDown, faEllipsisV, faEye, faEyeSlash, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { isEmpty } from 'lodash-es';
import {
    Alert,
    Bubble,
    Button,
    Dropdown,
    EmptyState,
    Input,
    ItemGroup,
    Skeleton,
    Spinner,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray, getBreakpointValue } from '@wedo/utils';
import { useLocalStorage } from '@wedo/utils/hooks/useLocalStorage';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { DEFAULT_MEETING_ROLES } from 'Pages/meeting/components/RBAC/DefaultMeetingRoles';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import {
    buildGetMeetingParameters,
    invalidateMeetingList,
    useGetMeetingQuery,
    useGetMeetingsQuery,
    useUpdateMeetingSettingsMutation,
} from 'Shared/services/meeting';
import { Meeting, MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingSettings } from 'Shared/types/meetingSettings';
import { MeetingUser } from 'Shared/types/meetingUser';

const NotAuthorizedTemplateAlert = ({ template }: { template: Meeting }) => {
    const editorRoleId = template?.roles.find((r) => r.code === DEFAULT_MEETING_ROLES.EDITOR).id;
    const getEditorsList = () =>
        template?.meetingUsers
            .filter((mu) => mu.meeting_role_id === editorRoleId)
            .map((meetingUser) => meetingUser.user.full_name)
            .join(', ');

    return (
        <Alert
            title={
                <Trans>
                    You do not have the rights to add the next meeting of <b>{template?.title}</b>
                </Trans>
            }
            className={'mt-2'}
            type="warning"
        >
            <Trans>
                Please request permission to one of the meeting editors: <b>{getEditorsList()}</b>
            </Trans>
        </Alert>
    );
};

export const PickMeetingTemplateStep = ({
    workspaceId,
    onSelectTemplate,
}: {
    workspaceId: Id;
    onSelectTemplate: (templateId: Id) => void;
}) => {
    const dispatch = useAppDispatch();
    const { currentUser } = useCurrentUserContext();
    const { show: showNotification } = useNotification();
    const { width: viewPortWidth } = useWindowSize();

    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [search, setSearch] = useState<string>();

    const [showNonAttendedMeetings, setShowNonAttendedMeetings] = useLocalStorage<boolean>(
        'add-meeting-show-non-attended',
        true
    );
    const [showRecurrentMeetings, setShowRecurrentMeetings] = useLocalStorage<boolean>(
        'add-meeting-show-recurrent',
        false
    );
    const [showOldMeetings, setShowOldMeetings] = useLocalStorage<boolean>('add-meeting-show-old', false);
    const [showHiddenTemplates, setShowHiddenTemplates] = useLocalStorage<boolean>('add-meeting-show-hidden', false);

    const { data: template, isLoading: isLoadingTemplate } = useGetMeetingQuery(
        buildGetMeetingParameters(selectedTemplateId),
        {
            skip: selectedTemplateId === '',
            refetchOnMountOrArgChange: true,
        }
    );
    const { hasPermission: canManageTemplate, isLoading: loadingPermission } = useUserHasMeetingPermission(
        currentUser,
        template,
        MeetingPermission.MANAGE_MEETING
    );
    const { data: templates = EmptyArray, isLoading: isLoadingTemplates } = useGetMeetingsQuery({
        related: ['tag.team', 'meetingUsers', 'meetingUsers.user', 'meetingUsers.user.userEmail'],
        filter: 'last',
        tagId: workspaceId,
        deleted: false,
    });

    const [updateMeetingSettings] = useUpdateMeetingSettingsMutation();

    const hiddenMeetings = useMemo(() => templates.filter((t) => t.settings?.hide_template), [templates]);
    const oldMeetings = useMemo(
        () => templates.filter((t) => differenceInDays(new Date(), new Date(t.end_at)) >= 400),
        [templates]
    );
    const nonAttendedMeetings = useMemo(
        () =>
            templates.filter((t) =>
                t.meetingUsers?.some((u: MeetingUser) => u.user_id === currentUser?.id && !u.is_attendee)
            ),
        [templates]
    );
    const recurrentMeetings = useMemo(() => templates.filter((t) => t.type === 'occurrence'), [templates]);

    const nonFilterProneMeetings = useMemo(() => {
        return templates
            .filter((t) => !t.settings?.hide_template)
            .filter((t) => t.type !== 'occurrence')
            .filter((t) => differenceInDays(new Date(), new Date(t.end_at)) < 400)
            .filter((t) => t.meetingUsers?.some((u: MeetingUser) => u.user_id === currentUser?.id && u.is_attendee));
    }, [templates]);

    const filteredTemplates = useMemo(() => {
        let filteredTemplates = [...nonFilterProneMeetings];
        if (showRecurrentMeetings) {
            recurrentMeetings.forEach((m) => {
                if (!filteredTemplates.some((template) => template.id === m.id)) {
                    filteredTemplates.push(m);
                }
            });
        }
        if (showNonAttendedMeetings) {
            nonAttendedMeetings.forEach((m) => {
                if (!filteredTemplates.some((template) => template.id === m.id)) {
                    filteredTemplates.push(m);
                }
            });
        }
        if (showOldMeetings) {
            oldMeetings.forEach((m) => {
                if (!filteredTemplates.some((template) => template.id === m.id)) {
                    filteredTemplates.push(m);
                }
            });
        }
        if (!showHiddenTemplates) {
            filteredTemplates = filteredTemplates.filter((ft) => !hiddenMeetings.some((hm) => hm.id === ft.id));
        } else {
            hiddenMeetings.forEach((hm) => {
                if (!filteredTemplates.some((ft) => ft.id === hm.id)) {
                    filteredTemplates.push(hm);
                }
            });
        }

        return filteredTemplates;
    }, [showRecurrentMeetings, showOldMeetings, showHiddenTemplates, showNonAttendedMeetings, nonFilterProneMeetings]);

    const filteredSearchedTemplates = useMemo(() => {
        return filteredTemplates.filter((t) => !search || t.title.toLowerCase().includes(search.toLowerCase()));
    }, [filteredTemplates, search]);

    const activeFilterCount = useMemo(() => {
        return [showNonAttendedMeetings, showRecurrentMeetings, showOldMeetings, showHiddenTemplates].filter(Boolean)
            .length;
    }, [showNonAttendedMeetings, showRecurrentMeetings, showOldMeetings, showHiddenTemplates]);

    const templatesSortedByTeam = useMemo(() => {
        const templatesSortedByTeam = [...(filteredSearchedTemplates || [])];

        const compareFunction = (a: Meeting, b: Meeting) => {
            let textToCompareA = a.tag?.team?.name || '';
            let textToCompareB = b.tag?.team?.name || '';
            if (textToCompareA === '' && textToCompareB === '') {
                textToCompareA = a.tag?.name || '';
                textToCompareB = b.tag?.name || '';
            }
            if (textToCompareA === '' && textToCompareB === '') {
                textToCompareA = a.title || '';
                textToCompareB = b.title || '';
            }

            return textToCompareA.localeCompare(textToCompareB);
        };
        templatesSortedByTeam.sort(compareFunction);
        return templatesSortedByTeam;
    }, [filteredSearchedTemplates]);

    const handleChangeHideTemplate = async (template: Partial<Meeting>, settings: MeetingSettings) => {
        try {
            await updateMeetingSettings({ meetingId: template.id, applyOn: APPLY_ON.THIS_MEETING, settings })
                .unwrap()
                .then(() => {
                    dispatch(invalidateMeetingList());
                });
        } catch (e) {
            showNotification({ title: t`You do not have the rights to manage this meeting`, type: 'danger' });
        }
    };

    const handleClickHide = async (e: React.MouseEvent, hide: boolean, template: Partial<Meeting>) => {
        e.stopPropagation();
        const settings = template?.settings || {};
        await handleChangeHideTemplate(template, { ...settings, hide_template: hide });
    };

    useEffect(() => {
        if (template && canManageTemplate && !loadingPermission) {
            onSelectTemplate(template.id);
        }
    }, [template, canManageTemplate, loadingPermission]);

    return isLoadingTemplates ? (
        <Skeleton className="h-16 rounded-[10px]" />
    ) : (
        <>
            <div className={'flex flex-col gap-4'}>
                <div className={'my-4 flex justify-between gap-2'}>
                    <Input
                        className={'grow'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        leadingIcon={faSearch}
                    />
                    <div className={'relative'}>
                        <Dropdown
                            title={viewPortWidth < getBreakpointValue('sm') ? t`Filters` : undefined}
                            icon={viewPortWidth >= getBreakpointValue('sm') ? faChevronDown : faFilter}
                            label={viewPortWidth >= getBreakpointValue('sm') ? t`Filters` : undefined}
                        >
                            <Dropdown.CheckboxItem
                                checked={showNonAttendedMeetings}
                                onChange={() => setShowNonAttendedMeetings(!showNonAttendedMeetings)}
                            >
                                {t`Non-attended meetings`}
                            </Dropdown.CheckboxItem>
                            <Dropdown.CheckboxItem
                                checked={showRecurrentMeetings}
                                onChange={() => setShowRecurrentMeetings(!showRecurrentMeetings)}
                            >
                                {t`Recurring meetings`}
                            </Dropdown.CheckboxItem>
                            <Dropdown.CheckboxItem
                                checked={showOldMeetings}
                                onChange={() => setShowOldMeetings(!showOldMeetings)}
                            >
                                {t`Old meetings`}
                            </Dropdown.CheckboxItem>
                            <Dropdown.DividerItem />
                            <Dropdown.CheckboxItem
                                checked={showHiddenTemplates}
                                onChange={() => setShowHiddenTemplates(!showHiddenTemplates)}
                            >
                                {t`Hidden meetings`}
                            </Dropdown.CheckboxItem>
                        </Dropdown>
                        <Bubble
                            color={'gray'}
                            size="sm"
                            className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2"
                            text={`${activeFilterCount}`}
                        />
                    </div>
                </div>
            </div>
            {!isEmpty(search)
                ? templatesSortedByTeam.length === 0 && <EmptyState icon={faSearch}>{t`No meetings found`}</EmptyState>
                : filteredTemplates.length === 0 && (
                      <EmptyState icon={faFilter}>{t`No meetings for the current filter configuration`}</EmptyState>
                  )}

            <div className={'grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3'}>
                {templatesSortedByTeam.map((template) => (
                    <ItemGroup key={template.id} className={'!h-auto w-full'}>
                        <Button
                            className={clsx(
                                template.settings?.hide_template && '!bg-gray-50 !text-gray-400 hover:!bg-gray-100',
                                'relative grow !whitespace-normal border-b border-l border-t border-gray-300 px-5 py-3 pl-10 text-start !text-sm hover:bg-gray-100'
                            )}
                            variant={'ghost'}
                            size={'lg'}
                            key={template.id}
                            onClick={() => {
                                setSelectedTemplateId(template.id);
                            }}
                        >
                            {(loadingPermission || isLoadingTemplate) && template.id === selectedTemplateId ? (
                                <Spinner className={'absolute left-2 top-1/2 -translate-y-1/2'} size={'xs'} />
                            ) : (
                                <WorkspaceIcon
                                    className={'absolute left-2 top-1/2 -translate-y-1/2'}
                                    workspace={template?.tag}
                                    showTooltip
                                />
                            )}
                            <span className={'line-clamp-2'}>{template.title}</span>
                        </Button>
                        <Dropdown
                            className={clsx(
                                template.settings?.hide_template && '!bg-gray-50 !text-gray-400 hover:!bg-gray-100',
                                'flex !h-auto shrink-0 items-center justify-center border-b border-r border-t border-gray-300 text-start hover:bg-gray-100'
                            )}
                            variant="ghost"
                            size={'lg'}
                            icon={faEllipsisV}
                        >
                            <Dropdown.Item
                                icon={template.settings?.hide_template ? faEye : faEyeSlash}
                                disabled={
                                    selectedTemplateId === template.id && !canManageTemplate && !loadingPermission
                                }
                                onClick={(e) => handleClickHide(e, !template.settings?.hide_template, template)}
                            >
                                {template.settings?.hide_template ? <Trans>Show</Trans> : <Trans>Hide</Trans>}
                            </Dropdown.Item>
                        </Dropdown>
                    </ItemGroup>
                ))}
            </div>
            {selectedTemplateId !== '' && !canManageTemplate && !loadingPermission && (
                <NotAuthorizedTemplateAlert template={template} />
            )}
        </>
    );
};
