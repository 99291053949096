import React from 'react';
import { t } from '@lingui/macro';
import { useCurrentUserContext } from 'App/contexts';
import { CalendarHelpLink } from './CalendarHelpLink';

export const GoogleCalendarHelpLink = () => {
    const { userLanguage } = useCurrentUserContext();

    return (
        <CalendarHelpLink
            title={t`Google Calendar`}
            href={`https://support.google.com/calendar/answer/37100?hl=${userLanguage}`}
            logo={'/assets/google_calendar.svg'}
            alt={t`Google Calendar logo`}
        />
    );
};
