import { FC, ForwardedRef } from 'react';
import {
    faCalendar,
    faClockRotateLeft,
    faCog,
    faEnvelope,
    faInputText,
    faListAlt,
    faPause,
    faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tabs } from '@wedo/design-system';

type TabsHeaderProps = {
    generalButtonRef: ForwardedRef<HTMLButtonElement>;
    isReadonly?: boolean;
    onChange?: (tabKey: string) => void;
};

export const TabsHeader: FC<TabsHeaderProps> = ({ generalButtonRef, isReadonly = false, onChange }) => {
    return (
        <Tabs.Header>
            {!isReadonly && (
                <Tabs.Tab icon={faCog} ref={generalButtonRef} onClick={onChange}>
                    <Trans>General</Trans>
                </Tabs.Tab>
            )}

            <Tabs.Tab icon={faUsers} onClick={onChange}>
                <Trans>Members</Trans>
            </Tabs.Tab>

            {!isReadonly && (
                <Tabs.Tab icon={faListAlt} onClick={onChange}>
                    <Trans>Task settings</Trans>
                </Tabs.Tab>
            )}

            {!isReadonly && (
                <Tabs.Tab icon={faPause} iconClassName="fa-rotate-90" onClick={onChange}>
                    <Trans>Sections</Trans>
                </Tabs.Tab>
            )}

            {!isReadonly && (
                <Tabs.Tab icon={faInputText} onClick={onChange}>
                    <Trans>Custom fields</Trans>
                </Tabs.Tab>
            )}

            <Tabs.Tab icon={faCalendar} onClick={onChange}>
                <Trans>Calendar sync</Trans>
            </Tabs.Tab>

            <Tabs.Tab icon={faEnvelope} onClick={onChange}>
                <Trans>Email to task</Trans>
            </Tabs.Tab>

            <Tabs.Tab icon={faClockRotateLeft} tabKey="history" onClick={onChange}>
                <Trans>History</Trans>
            </Tabs.Tab>
        </Tabs.Header>
    );
};
