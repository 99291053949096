import { hexToBase64 } from '@wedo/utils';

export const convertWordImages = (document: Document, html: string, rtf: string) => {
    Array.from(document.body.querySelectorAll('img')).forEach((img) => {
        const vShapes = img.getAttribute('v:shapes');
        const match = html.match(new RegExp(`id="${vShapes}"\\s+o:spid="_.+?_i(.+?)"`)) ?? vShapes?.match(/_.+?_i(.+)/);
        if (match != null) {
            const index = rtf.indexOf(`\\shplid${match[1]}`);
            const idIndex = rtf.indexOf('\\*\\blipuid', index);
            const startIndex = rtf.indexOf('}', idIndex);
            const endIndex = rtf.indexOf('}', startIndex + 1);
            const pngIndex = rtf.indexOf('\\pngblip', index);
            const mimeType = pngIndex > -1 && pngIndex < idIndex ? 'image/png' : 'image/jpeg';
            const data = rtf.substring(startIndex + 1, endIndex).replace(/\s+/g, '');
            const image = document.createElement('img');
            image.src = `data:${mimeType};base64,${hexToBase64(data)}`;
            img.replaceWith(image);
        }
    });
};

export const convertWordLists = (document: Document, html: string) => {
    let lists: Element[] = [];
    let currentLevel = 1;
    let currentListId = 1;
    Array.from(document.body.children).forEach((element) => {
        if (element.className.startsWith('MsoList') || element.getAttribute('style')?.includes('mso-list')) {
            element.querySelector('[style="mso-list:Ignore"], [style="mso-list:\\aIgnore"]')?.parentElement.remove();
            const [, listItemId, level, listId] = (
                element.getAttribute('style').match(/mso-list:l(\d+) level(\d+) lfo(\d+)/) ?? [0, 0, 1, 0]
            ).map(Number);
            const type =
                html.match(
                    new RegExp(`@list l${listItemId}:level\\d+[\\s\\S]*?{[\\s\\S]*?mso-level-number-format:(.+?);`)
                )?.[1] ?? 'bullet';
            const listType = type !== 'bullet' && type !== 'image' ? 'OL' : 'UL';
            if (lists.length === 0 || (level === 1 && lists.length > 0 && listId !== currentListId)) {
                const list = document.createElement(listType);
                lists = [list];
                currentLevel = 1;
                currentListId = listId;
                element.parentElement.insertBefore(list, element);
            }
            if (level > currentLevel) {
                const parentList = lists[lists.length - 1];
                const list = document.createElement(parentList.tagName);
                lists.push(list);
                const item = document.createElement('li');
                item.appendChild(list);
                parentList.appendChild(item);
            } else if (level < currentLevel) {
                Array.from(Array(currentLevel - level)).forEach(() => lists.pop());
            }
            const item = document.createElement('li');
            item.append(element);
            lists[lists.length - 1].appendChild(item);
            currentLevel = level;
        } else {
            lists = [];
            currentLevel = 1;
        }
    });
};
