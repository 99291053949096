import { isEmpty } from 'lodash-es';
import { CheckboxTree, flattenTree } from '@wedo/design-system';

export type SectionTree = { key: string; children: SectionTree[] };

export const checkboxTreeToTopicsTree = (tree: CheckboxTree): SectionTree => {
    const topicTree: SectionTree = { key: tree.id, children: [] };
    let nodesToVisit: SectionTree[] = [topicTree];
    const flattenedTree = flattenTree(tree);

    const keyToId = (key: string): string => {
        if (isEmpty(key)) {
            return key;
        }

        return key.slice(key.indexOf('_') + 1);
    };

    while (nodesToVisit.length > 0) {
        const currentTopicNode = nodesToVisit[0];

        const children =
            flattenedTree.filter(
                (child) => child.checked !== false && child.parent?.id === keyToId(currentTopicNode.key)
            ) ?? [];

        if (children.length > 0) {
            currentTopicNode.children = children.map((child) => {
                let prefix: string;
                if ((child.payload as any)?.parent_section_id !== undefined) {
                    prefix = 'section_';
                } else {
                    prefix = 'topic_';
                }

                return {
                    key: prefix + child.id,
                    children: null,
                };
            });

            nodesToVisit = nodesToVisit.concat(currentTopicNode.children);
        }

        nodesToVisit = nodesToVisit.slice(1);
    }

    return topicTree;
};
