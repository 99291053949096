import { useCallback, useRef, useState } from 'react';

export const useLoader = () => {
    const isLoadingRef = useRef(false);
    const [isLoading, setIsLoading] = useState(false);

    const wrap = useCallback(<T>(promise: Promise<T> | (() => Promise<T>)) => {
        isLoadingRef.current = true;
        setIsLoading(true);
        return (typeof promise === 'function' ? promise() : promise).finally(() => {
            isLoadingRef.current = false;
            setIsLoading(false);
        });
    }, []);

    return { isLoading, isLoadingRef, wrap };
};
