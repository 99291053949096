import { Font } from '@react-pdf/renderer';
import {
    faAddressBook,
    faAddressCard,
    faAdjust,
    faAlarmClock,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAllergies,
    faAmbulance,
    faAmericanSignLanguageInterpreting,
    faAnchor,
    faAngleDoubleRight,
    faArchive,
    faArrowAltDown,
    faArrowAltFromLeft,
    faArrowAltSquareDown,
    faArrowAltSquareRight,
    faArrowAltToLeft,
    faArrowAltToRight,
    faArrowFromLeft,
    faArrows,
    faArrowsH,
    faArrowsV,
    faAudioDescription,
    faBadge,
    faBadgeCheck,
    faBalanceScale,
    faBalanceScaleRight,
    faBarcode,
    faBatteryBolt,
    faBatteryFull,
    faBatteryHalf,
    faBatteryThreeQuarters,
    faBicycle,
    faBinoculars,
    faBirthdayCake,
    faBlanket,
    faBlind,
    faBomb,
    faBook,
    faBookmark,
    faBookOpen,
    faBoxes,
    faBoxFull,
    faBoxOpen,
    faBoxUsd,
    faBraille,
    faBriefcase,
    faBriefcaseMedical,
    faBroom,
    faBuilding,
    faBullhorn,
    faBullseye,
    faCalculator,
    faCalendarAlt,
    faCalendarEdit,
    faCalendarExclamation,
    faCapsules,
    faCar,
    faCertificate,
    faChalkboard,
    faChalkboardTeacher,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChessPawn,
    faChessQueen,
    faChevronDoubleRight,
    faCircle,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faClone,
    faCloud,
    faCode,
    faCodeBranch,
    faCodeCommit,
    faCog,
    faCogs,
    faCommentAltDots,
    faCommentAltExclamation,
    faCommentAltSmile,
    faCommentCheck,
    faCommentExclamation,
    faComments,
    faCommentSmile,
    faCopy,
    faCrow,
    faCrown,
    faDna,
    faDollarSign,
    faDolly,
    faDollyFlatbedAlt,
    faDonate,
    faEdit,
    faEllipsisH,
    faEuroSign,
    faExchange,
    faExclamation,
    faExpandWide,
    faExternalLink,
    faExternalLinkAlt,
    faExternalLinkSquare,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileEdit,
    faFileExclamation,
    faFileMedicalAlt,
    faFlag,
    faFolder,
    faFolderOpen,
    faFootballHelmet,
    faFrog,
    faGavel,
    faGlasses,
    faGlassMartini,
    faGlobe,
    faHandPointRight,
    faHandPointUp,
    faHandshakeAlt,
    faHeartbeat,
    faHockeySticks,
    faHomeHeart,
    faHospital,
    faHospitalAlt,
    faHourglassEnd,
    faHourglassHalf,
    faHourglassStart,
    faHSquare,
    faIdCardAlt,
    faInboxIn,
    faInboxOut,
    faIndustryAlt,
    faInfoCircle,
    faInfoSquare,
    faJackOLantern,
    faLaptop,
    faLevelDown,
    faLink,
    faListAlt,
    faListUl,
    faMobile,
    faMobileAlt,
    faMoneyBillAlt,
    faNotesMedical,
    faPaperPlane,
    faPenAlt,
    faPencilAlt,
    faPennant,
    faPeopleCarry,
    faPersonCarry,
    faPhone,
    faPiggyBank,
    faPills,
    faPlay,
    faPlusSquare,
    faProjectDiagram,
    faPuzzlePiece,
    faRandom,
    faRedo,
    faRoad,
    faRocket,
    faSearch,
    faSearchMinus,
    faShareAlt,
    faShield,
    faShieldAlt,
    faShieldCheck,
    faShoePrints,
    faSitemap,
    faStarExclamation,
    faStoreAlt,
    faTachometer,
    faTag,
    faTaxi,
    faTh,
    faThumbsUp,
    faUsdCircle,
    faUser,
    faUserAlt,
    faUserCheck,
    faUserClock,
    faUserEdit,
    faUserFriends,
    faUserMd,
    faUsers,
    faUserShield,
    faUserTie,
    faVenusMars,
    faVolumeOff,
    faWalking,
    faWarehouse,
    faWarehouseAlt,
    faWatch,
    faWindowMaximize,
    faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import { intlFormat } from 'date-fns';
import { colors } from './colors';
import arimoBoldItalic from './meeting/fonts/arimo-bold-italic.ttf';
import arimoBold from './meeting/fonts/arimo-bold.ttf';
import arimoItalic from './meeting/fonts/arimo-italic.ttf';
import arimo from './meeting/fonts/arimo.ttf';
import carlitoBoldItalic from './meeting/fonts/carlito-bold-italic.ttf';
import carlitoBold from './meeting/fonts/carlito-bold.ttf';
import carlitoItalic from './meeting/fonts/carlito-italic.ttf';
import carlito from './meeting/fonts/carlito.ttf';

export const priorityColor = {
    0: colors.gray['500'],
    1: colors.green['500'],
    2: colors.yellow['500'],
    3: colors.orange['500'],
    4: colors.red['500'],
};

export const fontStyles = {
    bold: { fontWeight: 'bold', fontStyle: 'normal' },
    boldItalic: { fontWeight: 'bold', fontStyle: 'italic' },
    italic: { fontWeight: 'normal', fontStyle: 'italic' },
    normal: { fontWeight: 'normal', fontStyle: 'normal' },
} as const;

export const fontFamilies = {
    arial: {
        bold: arimoBold,
        boldItalic: arimoBoldItalic,
        italic: arimoItalic,
        normal: arimo,
    },
    calibri: {
        bold: carlitoBold,
        boldItalic: carlitoBoldItalic,
        italic: carlitoItalic,
        normal: carlito,
    },
    garamond: {
        bold: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-400-normal.woff', import.meta.url).href,
    },
    lato: {
        bold: new URL('@fontsource/lato/files/lato-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/lato/files/lato-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/lato/files/lato-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/lato/files/lato-latin-400-normal.woff', import.meta.url).href,
    },
    montserrat: {
        bold: new URL('@fontsource/montserrat/files/montserrat-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/montserrat/files/montserrat-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/montserrat/files/montserrat-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/montserrat/files/montserrat-latin-400-normal.woff', import.meta.url).href,
    },
    openSans: {
        bold: new URL('@fontsource/open-sans/files/open-sans-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/open-sans/files/open-sans-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/open-sans/files/open-sans-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/open-sans/files/open-sans-latin-400-normal.woff', import.meta.url).href,
    },
    poppins: {
        bold: new URL('@fontsource/poppins/files/poppins-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/poppins/files/poppins-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/poppins/files/poppins-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/poppins/files/poppins-latin-400-normal.woff', import.meta.url).href,
    },
    roboto: {
        bold: new URL('@fontsource/roboto/files/roboto-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/roboto/files/roboto-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/roboto/files/roboto-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/roboto/files/roboto-latin-400-normal.woff', import.meta.url).href,
    },
    // TODO I can't find a free alternative to Verdana, so let's pretend Arimo is the same...
    verdana: {
        bold: arimoBold,
        boldItalic: arimoBoldItalic,
        italic: arimoItalic,
        normal: arimo,
    },
} as const;

export const FontFamilies = [
    'arial',
    'calibri',
    'garamond',
    'helvetica',
    'lato',
    'montserrat',
    'openSans',
    'poppins',
    'roboto',
    'timesNewRoman',
    'verdana',
] as const;

export type FontFamily = (typeof FontFamilies)[number];

export const fontSizes = {
    small: { small: 6, medium: 8, large: 10 },
    normal: { small: 8, medium: 10, large: 12 },
    medium: { small: 10, medium: 12, large: 14 },
    large: { small: 12, medium: 14, large: 16 },
    extraLarge: { small: 18, medium: 20, large: 22 },
} as const;

export const registerFont = (font: FontFamily) => {
    // Remove hyphenation (Word splitting)
    Font.registerHyphenationCallback((word) => [word]);

    // TODO Handle bold and italic for native fonts
    //   Waiting for this issue https://github.com/diegomura/react-pdf/issues/649
    if (font === 'timesNewRoman') {
        return 'Times-Roman';
    }
    if (font === 'helvetica') {
        return 'Helvetica';
    }
    const fontFile = fontFamilies[font];
    if (fontFile != null) {
        Font.register({
            family: font,
            fonts: Object.entries(fontStyles).map(([style, props]) => ({ src: fontFamilies[font][style], ...props })),
        });
    }
    return font;
};

export const mmToPx = (mm: number) => {
    // We use the standard 72dpi (https://react-pdf.org/styling#valid-units)
    // 1in is 25.4mm
    return Math.round((72 / 25.4) * mm);
};

export const formatShortDate = (date: string, locale: string): string => {
    return intlFormat(new Date(date), { day: 'numeric', month: 'numeric', year: 'numeric' }, { locale });
};

const icons = new Map(
    [
        faAddressBook,
        faAddressCard,
        faAdjust,
        faAlarmClock,
        faAlignCenter,
        faAlignJustify,
        faAlignLeft,
        faAllergies,
        faAmbulance,
        faAmericanSignLanguageInterpreting,
        faAnchor,
        faAngleDoubleRight,
        faArchive,
        faArrowAltDown,
        faArrowAltFromLeft,
        faArrowAltSquareDown,
        faArrowAltSquareRight,
        faArrowAltToLeft,
        faArrowAltToRight,
        faArrowFromLeft,
        faArrows,
        faArrowsH,
        faArrowsV,
        faAudioDescription,
        faBadge,
        faBadgeCheck,
        faBalanceScale,
        faBalanceScaleRight,
        faBarcode,
        faBatteryBolt,
        faBatteryFull,
        faBatteryHalf,
        faBatteryThreeQuarters,
        faBicycle,
        faBinoculars,
        faBirthdayCake,
        faBlanket,
        faBlind,
        faBomb,
        faBook,
        faBookmark,
        faBookOpen,
        faBoxes,
        faBoxFull,
        faBoxOpen,
        faBoxUsd,
        faBraille,
        faBriefcase,
        faBriefcaseMedical,
        faBroom,
        faBuilding,
        faBullhorn,
        faBullseye,
        faCalculator,
        faCalendarAlt,
        faCalendarEdit,
        faCalendarExclamation,
        faCapsules,
        faCar,
        faCertificate,
        faChalkboard,
        faChalkboardTeacher,
        faChartBar,
        faChartLine,
        faChartPie,
        faCheck,
        faCheckCircle,
        faCheckSquare,
        faChessPawn,
        faChessQueen,
        faChevronDoubleRight,
        faCircle,
        faClipboard,
        faClipboardCheck,
        faClipboardList,
        faClock,
        faClone,
        faCloud,
        faCode,
        faCodeBranch,
        faCodeCommit,
        faCog,
        faCogs,
        faCommentAltDots,
        faCommentAltExclamation,
        faCommentAltSmile,
        faCommentCheck,
        faCommentExclamation,
        faComments,
        faCommentSmile,
        faCopy,
        faCrow,
        faCrown,
        faDna,
        faDollarSign,
        faDolly,
        faDollyFlatbedAlt,
        faDonate,
        faEdit,
        faEllipsisH,
        faEuroSign,
        faExchange,
        faExclamation,
        faExpandWide,
        faExternalLink,
        faExternalLinkAlt,
        faExternalLinkSquare,
        faFile,
        faFileAlt,
        faFileCheck,
        faFileEdit,
        faFileExclamation,
        faFileMedicalAlt,
        faFlag,
        faFolder,
        faFolderOpen,
        faFootballHelmet,
        faFrog,
        faGavel,
        faGlasses,
        faGlassMartini,
        faGlobe,
        faHandPointRight,
        faHandPointUp,
        faHandshakeAlt,
        faHeartbeat,
        faHockeySticks,
        faHomeHeart,
        faHospital,
        faHospitalAlt,
        faHourglassEnd,
        faHourglassHalf,
        faHourglassStart,
        faHSquare,
        faIdCardAlt,
        faInboxIn,
        faInboxOut,
        faIndustryAlt,
        faInfoCircle,
        faInfoSquare,
        faJackOLantern,
        faLaptop,
        faLevelDown,
        faLink,
        faListAlt,
        faListUl,
        faMobile,
        faMobileAlt,
        faMoneyBillAlt,
        faNotesMedical,
        faPaperPlane,
        faPenAlt,
        faPencilAlt,
        faPennant,
        faPeopleCarry,
        faPersonCarry,
        faPhone,
        faPiggyBank,
        faPills,
        faPlay,
        faPlusSquare,
        faProjectDiagram,
        faPuzzlePiece,
        faRandom,
        faRedo,
        faRoad,
        faRocket,
        faSearch,
        faSearchMinus,
        faShareAlt,
        faShield,
        faShieldAlt,
        faShieldCheck,
        faShoePrints,
        faSitemap,
        faStarExclamation,
        faStoreAlt,
        faTachometer,
        faTag,
        faTaxi,
        faTh,
        faThumbsUp,
        faUsdCircle,
        faUser,
        faUserAlt,
        faUserCheck,
        faUserClock,
        faUserEdit,
        faUserFriends,
        faUserMd,
        faUsers,
        faUserShield,
        faUserTie,
        faVenusMars,
        faVolumeOff,
        faWalking,
        faWarehouse,
        faWarehouseAlt,
        faWatch,
        faWindowMaximize,
        faWrench,
    ]
        .map((icon) => [`fa-${icon.iconName}`, icon])
        .concat([
            ['fa-pen-alt', faPenAlt],
            ['fa-shield-alt', faShieldAlt],
            ['fa-user-md', faUserMd],
            ['fa-user-edit', faUserEdit],
            ['fa-user-friends', faUserFriends],
            ['fa-home-heart', faHomeHeart],
            ['fa-hospital-alt', faHospitalAlt],
            ['fa-info-circle', faInfoCircle],
            ['fa-list-alt', faListAlt],
        ])
);

export const getIconFromName = (iconName: string) => {
    return icons.get(iconName);
};
