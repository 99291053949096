import { FC } from 'react';
import { t } from '@lingui/macro';
import { ContextModalProps, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import store from 'App/store';
import { MoveEntityToAnotherTeamModal } from 'Shared/components/team/MoveEntityToAnotherTeamModal';
import { invalidateTemplate, useGetChecklistTemplateQuery } from 'Shared/services/template';
import { trpc, trpcUtils } from 'Shared/trpc';

type MoveTemplateTeamModalProps = { templateId: Id } & ContextModalProps;

export const MoveTemplateTeamModal: FC<MoveTemplateTeamModalProps> = ({ templateId, ...modalProps }) => {
    const { show } = useNotification();
    const { data: template } = useGetChecklistTemplateQuery(templateId);

    const { mutateAsync: move } = trpc.template.move.useMutation({
        onError: () => show(UnexpectedErrorNotification),
        onSuccess: () =>
            Promise.all([
                trpcUtils().team.list.invalidate(),
                trpcUtils().template.list.invalidate(),
                store.dispatch(invalidateTemplate(templateId)),
            ]),
    });

    const handleMoveTemplateToTeam = async (teamId: Id) => {
        await move({ templateId, teamId });
    };

    return (
        <MoveEntityToAnotherTeamModal
            {...modalProps}
            entity={template}
            entityName="template"
            onMoveEntityToTeam={handleMoveTemplateToTeam}
            modalTitle={t`Move ${template?.name} to another team`}
        />
    );
};
