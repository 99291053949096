import { HttpMethod } from '@wedo/types';
import { CacheTag } from 'Shared/services/cacheTag';
import { CURRENT_USER_TAG } from 'Shared/services/user';
import { MultiFactor, User } from 'Shared/types/user';
import { baseApi, configureTag } from './base';

export const {
    tagType: settingsSecurityTagType,
    tag: settingsSecurityTag,
    tags: settingsSecurityTags,
} = configureTag(CacheTag.SETTINGS);

export const TOTP = settingsSecurityTag('totp');
export const ACTIVE_SESSION = settingsSecurityTag('session-active');

export const settingsSecurity = baseApi
    .enhanceEndpoints({
        addTagTypes: [settingsSecurityTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMultiFactor: build.query<MultiFactor, void>({
                query: () => 'users/2fa/me',
                providesTags: [TOTP],
            }),
            changePassword: build.mutation<User, { passwordOld: string; passwordNew: string }>({
                query: ({ passwordOld, passwordNew }) => ({
                    url: 'users/me/password',
                    method: HttpMethod.Put,
                    body: { id: null, oldPassword: passwordOld, password: passwordNew },
                }),
            }),
            checkPassword: build.mutation<{ message: 'success' }, string>({
                query: (password: string) => ({
                    url: 'users/2fa/verify-password',
                    body: { password },
                    method: HttpMethod.Post,
                }),
            }),
            getQrCode: build.query<{ image: string; secret: string }, void>({
                query: () => ({ url: 'users/2fa/qr-code' }),
            }),
            checkTotp: build.mutation<{ id: string; message: string }, { totp: string; secret: string }>({
                query: ({ totp, secret }) => ({
                    url: 'users/2fa/totp',
                    body: { digit_code: totp, secret },
                    method: HttpMethod.Post,
                }),
            }),
            getSecurityCode: build.query<string[], void>({
                query: () => 'users/2fa/backup-codes',
            }),
            postSecurityCode: build.mutation<{ id: string; message: string }, string[]>({
                query: (backupCode) => ({
                    url: 'users/2fa/backup-codes',
                    body: { backup_codes: backupCode },
                    method: HttpMethod.Post,
                }),
            }),
            enableTotp: build.mutation<
                {
                    id: string;
                    user_id: string;
                    type: string;
                    active: boolean;
                    created_at: string;
                    updated_at: string;
                },
                { authenticators: string[]; password: string }
            >({
                query: (params) => ({
                    url: 'users/2fa/enable-authenticator',
                    body: params,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: [TOTP, CURRENT_USER_TAG],
            }),
            disableTotp: build.mutation<{ message: string }, string>({
                query: (password: string) => ({
                    url: 'users/2fa/remove-authenticator',
                    body: { password },
                    method: HttpMethod.Put,
                }),
                invalidatesTags: [TOTP],
            }),
        }),
    });

export const {
    useGetMultiFactorQuery,
    useChangePasswordMutation,
    useCheckPasswordMutation,
    useGetQrCodeQuery,
    useCheckTotpMutation,
    useGetSecurityCodeQuery,
    usePostSecurityCodeMutation,
    useEnableTotpMutation,
    useDisableTotpMutation,
} = settingsSecurity;
