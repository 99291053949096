import React, { ReactNode, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import {
    Button,
    Checkbox,
    ContextModalProps,
    Form,
    Input,
    Modal,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { isNullOrWhitespace } from '@wedo/utils';
import { CircleSearchSelect } from 'Pages/governance/CircleSearchSelect';
import { useCreateCircleMutation } from 'Shared/services/governance';
import { Circle, Root } from 'Shared/types/governance';

type AddCircleModalProps = {
    title: ReactNode;
    parentCircleId: Id;
    circles: HierarchyCircularNode<Circle | Root>[];
    type: 'circle' | 'role';
} & ContextModalProps;

export const AddCircleModal = ({ title, parentCircleId, circles, type, ...modalProps }: AddCircleModalProps) => {
    const [createCircle, { isLoading }] = useCreateCircleMutation();
    const { show: showNotification } = useNotification();

    const [destinationCircleId, setDestinationCircleId] = useState(parentCircleId);
    const [circleName, setCircleName] = useState('');
    const [isDraft, setIsDraft] = useState(false);

    const nameInputRef = useRef();

    const submitAndCloseForm = async () => {
        const result = await createCircle({
            name: circleName,
            draft: isDraft,
            type,
            parent_circle_id: destinationCircleId !== 'root' ? destinationCircleId : undefined,
        });

        if (result.error?.data != null) {
            if (result.error?.data?.errors?.[0]?.message === 'User cannot administrate circle') {
                showNotification({
                    title: t`Cannot administrate circle`,
                    type: 'danger',
                    message: t`Please ask one of the circle administrators to add you as an administrator of the circle`,
                });
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        }

        await modalProps.close();
    };

    return (
        <Modal {...modalProps} initialFocus={nameInputRef}>
            <Modal.Header title={title} />
            <Modal.Body>
                <Form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        await submitAndCloseForm();
                    }}
                    layout={'horizontal'}
                >
                    <Form.Item label={t`Name`} htmlFor={'name'}>
                        <Input
                            ref={nameInputRef}
                            name="name"
                            id={'name'}
                            value={circleName}
                            onChange={(e) => setCircleName(e.target.value)}
                            autoFocus={true}
                        />
                    </Form.Item>
                    <Form.Item htmlFor={'parent_circle_id'} label={t`Parent circle`}>
                        <CircleSearchSelect
                            circles={circles}
                            name={'parent_circle_id'}
                            value={destinationCircleId}
                            setValue={setDestinationCircleId}
                        />
                    </Form.Item>
                    <Form.Item htmlFor={'draft'} label={t`Mark as draft`}>
                        <Tooltip content={t`Only circle members can see circles and roles marked as draft`}>
                            <Checkbox
                                checked={isDraft}
                                id="draft"
                                onChange={(e) => setIsDraft(e.target.checked)}
                                name="draft"
                            />
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    disabled={isNullOrWhitespace(circleName) || destinationCircleId === undefined}
                    color={'primary'}
                    onClick={submitAndCloseForm}
                    loading={isLoading}
                >
                    <Trans>Confirm</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
