import { Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const GoogleDriveIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Path
                d="M256.047 175.612L177.39 36.8003C180.002 34.1413 183.007 32.3972 186.011 31.225C162.816 38.8589 152.173 64.9058 152.173 64.9058L8.4797 318.427C6.45782 326.375 5.89614 333.552 6.14888 339.87H162.984L256.047 175.612Z"
                fill="#34A853"
            />
            <Path
                d="M256.044 175.612L349.106 339.87H505.942C506.194 333.552 505.633 326.375 503.611 318.427L359.917 64.9058C359.917 64.9058 349.247 38.8589 326.079 31.225C329.056 32.3972 332.089 34.1413 334.7 36.8003L256.044 175.612Z"
                fill="#FBBC05"
            />
            <Path
                d="M256.044 175.618L334.701 36.8059C332.089 34.1469 329.056 32.4028 326.08 31.2306C322.654 30.1155 318.975 29.3721 314.987 29.172H310.831H201.257H197.101C193.141 29.3435 189.435 30.0869 186.009 31.2306C183.032 32.4028 179.999 34.1469 177.388 36.8059L256.044 175.618Z"
                fill="#188038"
            />
            <Path
                d="M163.01 339.871L85.252 477.11C85.252 477.11 82.6685 475.852 79.1583 473.25C90.1943 481.742 100.922 483.543 100.922 483.543H406.309C423.158 483.543 426.668 477.11 426.668 477.11C426.724 477.082 426.752 477.053 426.808 477.024L349.107 339.871H163.01Z"
                fill="#4285F4"
            />
            <Path
                d="M163.016 339.871H6.18031C6.96659 358.513 15.0541 369.406 15.0541 369.406L20.9512 379.642C21.3725 380.242 21.6252 380.585 21.6252 380.585L34.4585 403.115L63.2701 453.265C64.1126 455.295 65.0955 457.153 66.1345 458.926C66.5276 459.526 66.8926 460.184 67.3139 460.756C67.4262 460.927 67.5385 461.099 67.6508 461.271C71.2172 466.303 75.2048 470.191 79.1924 473.25C82.7026 475.881 85.2862 477.11 85.2862 477.11L163.016 339.871Z"
                fill="#1967D2"
            />
            <Path
                d="M349.106 339.871H505.942C505.156 358.513 497.068 369.406 497.068 369.406L491.171 379.642C490.75 380.242 490.497 380.585 490.497 380.585L477.664 403.115L448.852 453.265C448.01 455.295 447.027 457.153 445.988 458.926C445.595 459.526 445.23 460.184 444.808 460.756C444.696 460.927 444.584 461.099 444.471 461.271C440.905 466.303 436.917 470.191 432.93 473.25C429.42 475.881 426.836 477.11 426.836 477.11L349.106 339.871Z"
                fill="#EA4335"
            />
        </Svg>
    );
};
