import React from 'react';
import { Avatar, type AvatarSizes, type TooltipProps } from '@wedo/design-system';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { User } from 'Shared/types/user';

type UsersAvatarGroupProps = {
    users: Array<User>;
    showTooltip?: boolean;
    maxDisplayed?: number;
    size?: AvatarSizes;
    className?: string;
    tooltipPlacement?: TooltipProps['placement'];
};

export const UsersAvatarGroup: React.FC<UsersAvatarGroupProps> = ({
    users,
    showTooltip = true,
    maxDisplayed = 7,
    size = 'md',
    className,
    tooltipPlacement = 'right',
}) => {
    return (
        <Avatar.Group size={size} className={className} maxAvatars={maxDisplayed}>
            {users?.map((user) => (
                <UserAvatar
                    showTooltip={showTooltip}
                    key={user?.id ?? user?.full_name}
                    user={user}
                    size={size}
                    tooltipPlacement={tooltipPlacement}
                />
            ))}
        </Avatar.Group>
    );
};
