import { createContext, MutableRefObject, ReactNode, useContext, useRef, useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { EmptyFunction } from '@wedo/utils';
import { Attachment, AttachmentRelation } from 'Shared/types/attachment';

type Data = {
    instance: WebViewerInstance;
    pdf: Attachment;
    list: Attachment[];
    relation: AttachmentRelation;
    search: string;
    isAnnotationChanged: boolean;
};
export type PdfViewerContextProps = {
    data: MutableRefObject<Data>;
    setData: (data: Partial<Data>) => void;
    clearData: () => void;
};

export const initialState: PdfViewerContextProps = {
    data: null,
    setData: EmptyFunction,
    clearData: EmptyFunction,
};

export const PdfViewerContext = createContext<PdfViewerContextProps>(initialState);
export const usePdfViewerContext = (): PdfViewerContextProps => useContext(PdfViewerContext);

export const PdfViewerContextProvider = ({ children }: { children: ReactNode }) => {
    const [data, setData] = useState<Data>({
        instance: null,
        isAnnotationChanged: false,
        pdf: null,
        list: null,
        relation: null,
        search: null,
    });

    const ref = useRef<Data>();
    ref.current = data;

    const value = {
        data: ref,
        setData: (newData: Partial<Data>) => setData({ ...ref.current, ...newData }),
        clearData: () =>
            setData({
                ...ref.current,
                pdf: null,
                list: null,
                relation: null,
                search: null,
                isAnnotationChanged: false,
            }),
    };

    return <PdfViewerContext.Provider value={value}>{children}</PdfViewerContext.Provider>;
};
