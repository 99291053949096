import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { DatePicker, FormatDate, Label, Popover } from '@wedo/design-system';
import { FormRecurrence } from 'Shared/types/formRecurrence';

export type RecurrenceStartsOnInputProps = {
    state: FormRecurrence;
    handleValueChange?: (key: string, value: any) => void;
};
export const RecurrenceStartsOnInput = ({ state, handleValueChange }: RecurrenceStartsOnInputProps): JSX.Element => {
    const today = new Date();

    const changeDateAndClosePopover = (key: string, value: any, close: () => void) => {
        handleValueChange(key, value);
        close();
    };

    return (
        <>
            <Label className={'col-span-2 text-end'} inputType={'inline'}>{t`Starts on`}</Label>
            <Popover
                wrapperClassName={'col-span-4'}
                className={'w-44 justify-between'}
                iconPosition={'end'}
                icon={faCalendar}
                text={
                    state.startsOn ? (
                        <>
                            <FormatDate date={state.startsOn} format={'shortDate'} />
                        </>
                    ) : (
                        t`Select start date`
                    )
                }
            >
                {({ close }) => (
                    <div
                        tabIndex={-1}
                        className={'flex flex-col items-center justify-center gap-2 bg-white p-3 outline-0'}
                    >
                        <DatePicker
                            date={state.startsOn ? new Date(state.startsOn) : today}
                            onChange={(value) => changeDateAndClosePopover('startsOn', value, close)}
                        />
                    </div>
                )}
            </Popover>
        </>
    );
};
