import { FC } from 'react';
import { i18n } from '@lingui/core';
import { Tag, type TagProps } from '@wedo/design-system';
import { TopicSubmissionStatus } from 'Shared/types/meetingTopic';
import { TopicSubmissionStatusToString } from 'Shared/utils/meetingTopicSubmission';

type TopicSubmissionTagProps = {
    submissionType: TopicSubmissionStatus;
} & Pick<TagProps, 'size'>;

const topicSubmissionTypeToColor: Record<TopicSubmissionStatus, TagProps['color']> = {
    [TopicSubmissionStatus.DRAFT]: 'yellow',
    [TopicSubmissionStatus.SUBMITTED]: 'blue',
    [TopicSubmissionStatus.ACCEPTED]: 'green',
    [TopicSubmissionStatus.REJECTED]: 'red',
    [TopicSubmissionStatus.COMPLETED]: 'green',
};

export const TopicSubmissionTag: FC<TopicSubmissionTagProps> = ({ submissionType, size = 'md' }) => {
    if (submissionType == null) {
        return null;
    }

    return (
        <Tag
            dark={submissionType === TopicSubmissionStatus.COMPLETED}
            color={topicSubmissionTypeToColor[submissionType]}
            size={size}
        >
            {i18n._(TopicSubmissionStatusToString[submissionType])}
        </Tag>
    );
};
