import React, { FC } from 'react';
import { faCheck, faEllipsisV, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Dropdown, DropdownProps } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks/useSearchParams';
import { useAcceptTopicSubmission } from 'Pages/MyTopicsPage/hooks/useAcceptTopicSubmission';
import { useRejectTopicSubmission } from 'Pages/MyTopicsPage/hooks/useRejectTopicSubmission';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';

type SubmittedTopicDropdownProps = { topicId: string; submissionId: string; sectionId?: string } & Partial<
    Pick<DropdownProps, 'size' | 'variant'>
>;

export const SubmittedTopicDropdown: FC<SubmittedTopicDropdownProps> = ({
    topicId,
    sectionId,
    submissionId,
    size = 'sm',
    variant = 'text',
}) => {
    const [, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const { acceptSubmission } = useAcceptTopicSubmission(topicId, submissionId);
    const { rejectSubmission } = useRejectTopicSubmission(topicId, submissionId);

    const handleReject = async () => {
        if (await rejectSubmission()) {
            setSearchParams((current) => ({ ...current, topicId: undefined }));
        }
    };

    const handleAcceptSubmission = async () => {
        if (await acceptSubmission(sectionId)) {
            setSearchParams((current) => ({ ...current, topicId: undefined }));
        }
    };

    return (
        <Dropdown icon={faEllipsisV} size={size} variant={variant}>
            <Dropdown.Item icon={faCheck} onClick={handleAcceptSubmission}>
                <Trans>Accept topic</Trans>
            </Dropdown.Item>
            <Dropdown.Item danger icon={faTimes} onClick={handleReject}>
                <Trans>Reject topic</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};
