import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag, TagColor, Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { Workspace, WorkspaceHistory } from 'Shared/types/workspace';
import { workspaceViewToIcon, workspaceViewToString } from 'Shared/utils/workspace';

const workspaceViewToColor: Record<Workspace['default_view'], TagColor> = {
    list: 'blue',
    kanban: 'green',
};

type ViewTagProps = {
    view: Workspace['default_view'];
};

const ViewTag: FC<ViewTagProps> = ({ view }) => {
    const { i18n } = useLingui();
    return (
        <Tag size="xs" icon={workspaceViewToIcon[view]} color={workspaceViewToColor[view]}>
            {i18n._(workspaceViewToString[view])}
        </Tag>
    );
};

export const ChangedDefaultViewTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={workspaceViewToIcon.list}>
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> changed the workspace view from{' '}
                    <ViewTag view={activity.old_value.default_view} /> <FontAwesomeIcon icon={faArrowRight} />{' '}
                    <ViewTag view={activity.new_value.default_view} />
                </Trans>
            </p>
        </Timeline.Item>
    );
};
