import { LocaleDefinition } from '@wedo/types';

export const enGB: LocaleDefinition = {
    DATETIME_FORMATS: {
        AMPMS: ['a.m.', 'p.m.'],
        DAY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        FIRSTDAYOFWEEK: 0,
        MONTH: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        SHORTDAY: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        SHORTMONTH: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        WEEKENDRANGE: [5, 6],
        fullDate: 'eeee, d MMMM y',
        longDate: 'd MMMM y',
        medium: 'd MMM y HH:mm:ss',
        mediumDate: 'd MMM y',
        mediumTime: 'HH:mm:ss',
        short: 'dd/MM/y HH:mm',
        shortDate: 'dd/MM/y',
        shortTime: 'HH:mm',
    },
    NUMBER_FORMATS: {
        DECIMAL_SEP: '.',
        GROUP_SEP: ',',
    },
};
