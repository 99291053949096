import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, splitLink } from '@trpc/react-query';
import { ReactNode, useEffect, useState } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { createStore } from 'zustand';
import { authenticatedHttpBatchLink } from '~/modules/trpc/authenticatedHttpBatchLink';
import { authenticatedHttpLink } from '~/modules/trpc/authenticatedHttpLink';
import { authenticatedWsLink } from '~/modules/trpc/authenticatedWsLink';
import { invalidationLink } from '~/modules/trpc/invalidationLink';
import { testHttpLink } from '~/modules/trpc/testHttpLink';
import { useWebSocket } from '~/modules/ws/useWebSocket';
import { type AppRouter } from '@wedo/backend';
import { useBadgesStore } from '@wedo/design-system';
import { getAuthToken } from '@wedo/utils';
import store from 'App/store';

export const trpc = createTRPCReact<AppRouter>();

type TrpcStoreState = {
    utils: ReturnType<typeof trpc.useContext>;
};

const trpcStore = createStore<TrpcStoreState>()(() => ({
    utils: null,
}));

export const trpcUtils = () => trpcStore.getState().utils;

const TrpcStore = (): JSX.Element => {
    const utils = trpc.useUtils();

    if (__ENVIRONMENT__ === 'test' && trpcStore.getState().utils == null) {
        trpcStore.setState({ utils });
    }

    useEffect(() => {
        trpcStore.setState({ utils });
    }, [utils]);

    return null;
};

const TrpcReduxInvalidationSubscriber = ({ queryClient }: { queryClient: QueryClient }) => {
    const isEnabled = useWebSocket(({ isEnabled }) => isEnabled);

    trpc.invalidation.subscribe.useSubscription(null, {
        onData: (tags) => {
            if (typeof tags[0] === 'string') {
                return invalidateQueries(queryClient, tags);
            }
            // Legacy invalidation architecture
            tags.forEach(({ type, tag }) => {
                if (type === 'api/invalidateTags') {
                    store.dispatch({ type, payload: [tag], isFromWebSocket: true });
                } else if (type === 'invalidate') {
                    const utils = trpcUtils();

                    if (tag.procedure != null) {
                        utils[tag.router][tag.procedure].invalidate(tag.input);
                    } else if (tag.router != null) {
                        utils[tag.router].invalidate();
                    } else {
                        void utils.invalidate();
                    }
                }
            });
        },
        enabled: isEnabled && getAuthToken() != null,
    });
    return null;
};

const TrpcBadgeSubscriber = () => {
    const isEnabled = useWebSocket(({ isEnabled }) => isEnabled);

    trpc.badge.subscribe.useSubscription(null, {
        onData: (unlockedBadges) => {
            unlockedBadges.badges.forEach((badge) => {
                useBadgesStore.getState().actions.add(badge, crypto.randomUUID());
            });
            void trpcUtils().badge.list.invalidate();
            void trpcUtils().badge.listUserScores.invalidate();
        },
        enabled: isEnabled && getAuthToken() != null,
    });
    return null;
};

type TrpcProviderProps = {
    queryClient: QueryClient;
    children: ReactNode;
};

export const TrpcProvider = ({ queryClient, children }: TrpcProviderProps) => {
    const [client] = useState(() => {
        return trpc.createClient({
            links:
                __ENVIRONMENT__ === 'test' && __E2E__ !== '1'
                    ? [invalidationLink(queryClient), testHttpLink]
                    : [
                          invalidationLink(queryClient),
                          splitLink({
                              condition: ({ type }) => type === 'subscription',
                              true: authenticatedWsLink,
                              false: splitLink({
                                  condition: ({ context }) => context?.skipBatch === true,
                                  true: authenticatedHttpLink,
                                  false: authenticatedHttpBatchLink,
                              }),
                          }),
                      ],
        });
    });
    return (
        <trpc.Provider client={client} queryClient={queryClient}>
            <TrpcStore />
            {(__ENVIRONMENT__ !== 'test' || __E2E__ === '1') && (
                <>
                    <TrpcReduxInvalidationSubscriber queryClient={queryClient} />
                    <TrpcBadgeSubscriber />
                </>
            )}
            {children}
        </trpc.Provider>
    );
};

export const TrpcProviderNoAuth = ({ queryClient, children }: TrpcProviderProps) => {
    const [client] = useState(() => {
        return trpc.createClient({
            links: __ENVIRONMENT__ === 'test' && __E2E__ !== '1' ? [testHttpLink] : [authenticatedHttpLink],
        });
    });

    return (
        <trpc.Provider client={client} queryClient={queryClient}>
            {children}
        </trpc.Provider>
    );
};
