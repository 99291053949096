import { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isArray, isEmpty } from 'lodash-es';
import { useUpdateCustomFieldOrderMutation } from 'Shared/services/customFields';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';

export const useCustomFieldGroup = (group: CustomFieldGroup) => {
    const [updateFieldOrder, { isLoading: updateFieldIsLoading }] = useUpdateCustomFieldOrderMutation();

    const customFields = useMemo<Array<CustomField>>(() => group?.customFields, [group]);

    const [optimisticUpdateFields, setOptimisticUpdateFields] = useState<Array<CustomField>>([]);

    useEffect(() => {
        setOptimisticUpdateFields(customFields);
    }, [customFields]);

    const totalArchivedFields = useMemo<number>(() => {
        if (!isArray(group?.customFields)) return 0;
        let count = 0;
        for (const field of group.customFields) {
            if (field.archived) count++;
        }
        return count;
    }, [group?.customFields]);

    const totalFields = useMemo<number>(() => group?.customFields?.length, [group?.customFields]);

    const maxOrder = useMemo<number>(() => {
        if (isArray(customFields) && !isEmpty(customFields)) {
            return customFields[customFields.length - 1].order;
        }
        return -1;
    }, [customFields]);

    const customFieldIds = useMemo<Array<string>>(() => {
        const result = [];
        if (isArray(customFields)) {
            for (const field of customFields) {
                result.push(field.id);
            }
        }
        return result;
    }, [customFields]);

    const fieldIdToIndexMapping = useMemo<Map<string, number>>(() => {
        const result = new Map<string, number>();
        if (isArray(customFields)) {
            for (let i = 0; i < customFields.length; i++) {
                result.set(customFields[i].id, i);
            }
        }
        return result;
    }, [customFields]);

    const updateFieldOrders = useCallback(
        (payload: Array<{ fieldId: string; order: number }>) => {
            for (const action of payload) {
                void updateFieldOrder({ customFieldId: action.fieldId, order: action.order });
            }
        },
        [updateFieldOrder]
    );

    const reOrderFields = useCallback(
        (moveId: string, overId: string): void => {
            if (moveId === overId) return;

            const fromIndex = fieldIdToIndexMapping.get(moveId);
            const overIndex = fieldIdToIndexMapping.get(overId);

            const updatedFields = cloneDeep<Array<CustomField>>(customFields);

            updatedFields[fromIndex].order = updatedFields[overIndex].order;

            const payload = [{ fieldId: moveId, order: updatedFields[overIndex].order }];

            if (fromIndex > overIndex) {
                for (let i = overIndex; i < fromIndex; i++) {
                    updatedFields[i].order++;
                    payload.push({ fieldId: updatedFields[i].id, order: updatedFields[i].order });
                }
                setOptimisticUpdateFields([
                    ...updatedFields.slice(0, overIndex),
                    updatedFields[fromIndex],
                    ...updatedFields.slice(overIndex, fromIndex),
                    ...updatedFields.slice(fromIndex + 1),
                ]);
            } else {
                for (let i = fromIndex + 1; i <= overIndex; i++) {
                    updatedFields[i].order--;
                    payload.push({ fieldId: updatedFields[i].id, order: updatedFields[i].order });
                }
                setOptimisticUpdateFields([
                    ...updatedFields.slice(0, fromIndex),
                    ...updatedFields.slice(fromIndex + 1, overIndex + 1),
                    updatedFields[fromIndex],
                    ...updatedFields.slice(overIndex + 1),
                ]);
            }

            updateFieldOrders(payload);
        },
        [customFields, fieldIdToIndexMapping, updateFieldOrders]
    );

    return {
        group,
        customFields,
        customFieldIds,
        totalFields,
        totalArchivedFields,
        maxOrder,
        reOrderFields,
        updateFieldIsLoading,
        optimisticUpdateFields,
    };
};
