import React, { useEffect } from 'react';
import { useMarker } from 'react-mark.js';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Button, Skeleton, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useAppDispatch } from 'App/store';
import { onInvalidation } from 'App/store/invalidationStore';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { TaskAddAttachmentModal } from 'Pages/meeting/components/AddAttachmentModal/TaskAddAttachmentModal';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { AttachmentItem } from 'Shared/components/file/AttachmentItem/AttachmentItem';
import { DroppableFile } from 'Shared/components/file/DroppableFile';
import { useCanEditTask } from 'Shared/components/task/CanTask';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { invalidateTaskAttachments } from 'Shared/services/attachment';
import { tag as tagTask } from 'Shared/services/task';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Attachment } from 'Shared/types/attachment';
import { mark } from 'Shared/utils/marker';

type TaskDetailAttachmentsProps = {
    taskId: string;
    currentWorkspaceId: Id;
};

export const TaskDetailAttachments = ({ taskId, currentWorkspaceId }: TaskDetailAttachmentsProps) => {
    const dispatch = useAppDispatch();
    const { isLoadingTask, task, isTaskReadonly = false } = useTask(taskId);

    const { open: openModal } = useModal();
    const canEditTask = useCanEditTask(task);
    const { data: currentWorkspace } = useGetWorkspaceQuery(currentWorkspaceId, { skip: !currentWorkspaceId });
    const {
        data: attachments,
        isLoading: isLoadingAttachments,
        refetch,
    } = trpc.attachment.list.useQuery({ taskId }, { enabled: taskId != null, select: camelToSnake });
    const invalidateOnSuccess = () => {
        invalidateCachedTasks();
        void trpcUtils().attachment.list.invalidate({ taskId });
        dispatch(invalidateTaskAttachments(taskId));
    };
    const { mutateAsync: addAttachmentRelations } = trpc.attachment.addRelations.useMutation({
        onSuccess: invalidateOnSuccess,
    });
    const { mutateAsync: removeAttachmentRelations } = trpc.attachment.removeRelations.useMutation({
        onSuccess: invalidateOnSuccess,
    });
    const { currentUser } = useCurrentUserContext();

    const { markerRef: attachmentMarkerRef, marker: attachmentMarker } = useMarker<HTMLDivElement>();
    const [{ search }] = useSearchParams(TasksPageSearchParams);

    mark(search, attachmentMarker);

    useEffect(() => onInvalidation(tagTask(taskId), refetch), []);

    const handleRemoveClick = async (attachment: Attachment) => {
        await removeAttachmentRelations([{ attachmentId: attachment.id, taskId }]);
    };

    const handleDropFile = async (files: FileList) => {
        openModal(AddAttachmentModal, {
            isOnlyFromDisk: true,
            files: [...files],
            onDone: async (attachments: Attachment[]) => {
                await addAttachmentRelations(
                    attachments.map((a) => {
                        return { attachmentId: a.id, taskId };
                    })
                );
            },
        });
    };

    const canUpdateAttachments =
        !task?.completed && !task?.deleted && (canEditTask || task?.assignee_id === currentUser?.id);

    if (!canUpdateAttachments && (!attachments || attachments.length === 0)) {
        return null;
    }

    const isAttachmentReadonly = (attachment: Attachment) => {
        return isTaskReadonly && (attachment?.created_by !== currentUser.id || task.deleted || task.completed);
    };

    return (
        <>
            {attachments?.length > 0 && (
                <div className={'px-4 pb-2 pt-2 text-sm font-semibold text-gray-500'}>{t`Attachments`}</div>
            )}
            <TaskDetailRow>
                <TaskDetailRow.Content className="px-1.5">
                    <div ref={attachmentMarkerRef}>
                        {!isLoadingTask && !isLoadingAttachments ? (
                            <DroppableFile
                                readonly={!canUpdateAttachments}
                                className={'!justify-start'}
                                onDrop={handleDropFile}
                                iconOnly
                            >
                                <div className={'grid-wrap grid w-full grid-cols-2 gap-2'}>
                                    {attachments?.map((attachment: Attachment) => (
                                        <AttachmentItem
                                            key={attachment.id}
                                            attachment={attachment}
                                            relation={{ task_id: taskId }}
                                            workspaceId={currentWorkspace?.id}
                                            workspaces={task?.tags}
                                            attachmentList={attachments.filter((a) => a.open_url || a.preview_url)}
                                            onDelete={() => handleRemoveClick(attachment)}
                                            isReadonly={isAttachmentReadonly(attachment)}
                                        />
                                    ))}
                                    {canUpdateAttachments && (
                                        <div>
                                            <Button
                                                disabled={!canUpdateAttachments}
                                                className="ignore-marker"
                                                icon={faPlus}
                                                size="sm"
                                                onClick={() =>
                                                    openModal(TaskAddAttachmentModal, {
                                                        taskId,
                                                        workspaceId: currentWorkspace?.id,
                                                    })
                                                }
                                            >
                                                {t`Attach a file`}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </DroppableFile>
                        ) : (
                            <Skeleton className="h-8" />
                        )}
                    </div>
                </TaskDetailRow.Content>
            </TaskDetailRow>
        </>
    );
};
