import React, { useEffect, useRef } from 'react';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import { Button, EmptyState, Skeleton, useModal } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { Can } from 'Shared/components/Can';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { PageHeader } from 'Shared/components/PageHeader';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { AddUserModal } from 'Shared/components/user/AddEditUserModal/AddUserModal';
import { UserCard } from 'Shared/components/user/UserCard';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useGetUsersWithFilterQuery } from 'Shared/services/user';
import { trpc } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { Permission } from 'Shared/utils/rbac';
import { isFetchError } from 'Shared/utils/rtkQuery';

const UsersPageSearchParams = {
    view: enumeration('all', 'admins', 'users', 'externals', 'lights').default('all'),
    search: string(),
};

const UsersInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const [{ view, search }] = useSearchParams(UsersPageSearchParams);

    const { data, isLoading, isError, error, refetch } = useGetUsersWithFilterQuery({
        filter: view,
        search,
        offset,
        limit,
    });

    updateInfiniteScroll(data);

    return (
        <>
            {isLoading ? (
                <Skeleton count={5} className="h-96" />
            ) : isError && isFetchError(error as ApiError) ? (
                <RetryComponent retryFunction={refetch} className="col-span-full" />
            ) : (
                data.map((user) => (
                    <UserCard key={user.id} user={user} userAvatarSize="2xl" href={`/users/${user.id}/profile`} />
                ))
            )}
        </>
    );
};

const tabs = [
    {
        isDefault: true,
        to: { searchParams: { view: 'all' } },
        matchSearchParams: ['view'],
        title: msg({ id: 'All users', message: 'All' }),
        keepSearchParams: ['search'],
    },
    {
        to: { searchParams: { view: 'admins' } },
        matchSearchParams: ['view'],
        title: msg`Administrators`,
        keepSearchParams: ['search'],
    },
    {
        to: { searchParams: { view: 'users' } },
        matchSearchParams: ['view'],
        title: msg`Users`,
        keepSearchParams: ['search'],
    },
    {
        to: { searchParams: { view: 'externals' } },
        matchSearchParams: ['view'],
        title: msg`Externals`,
        keepSearchParams: ['search'],
    },
    {
        to: { searchParams: { view: 'lights' } },
        matchSearchParams: ['view'],
        title: 'Lights',
        keepSearchParams: ['search'],
    },
] as NavBarTab[];

export const UsersPage = () => {
    usePageTitle(t`Users`);
    const { open } = useModal();
    const [{ view, search }, setSearchParams] = useSearchParams(UsersPageSearchParams);
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const toolbarRef = useRef<HTMLDivElement>(null);

    const handleSearch = (search: string) => {
        setSearchParams((searchParams) => ({ ...searchParams, search }), { replace: true });
    };
    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: handleSearch,
        search: search,
        size: 'md',
    });

    useEffect(() => {
        void registerBadgeActivity('VIEW_USERS_PAGE_1');
    }, []);

    return (
        <Can permission={Permission.ViewUsers} showNoAccess>
            <>
                <PageHeader title={t`Users`} />
                <div className="grid-rows-header-content grid h-full max-h-full gap-6 overflow-hidden">
                    <div className="flex flex-col gap-2 px-6" ref={toolbarRef}>
                        <NavBar basePath="/users" tabs={tabs}>
                            {toggleButton}
                            <Can permission={Permission.ManageNetwork}>
                                <Button
                                    title={t`Add user`}
                                    tooltipClassName="flex lg:hidden"
                                    icon={faAdd}
                                    color="primary"
                                    onClick={() => open(AddUserModal)}
                                >
                                    <span className="hidden lg:flex">
                                        <Trans>Add user</Trans>
                                    </span>
                                </Button>
                            </Can>
                        </NavBar>
                        {searchInput}
                    </div>

                    <InfiniteScroll
                        key={view}
                        className="scrollbar-light grid auto-rows-auto grid-cols-1 content-start gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(20rem,1fr))]"
                        page={UsersInfiniteScrollPage}
                        emptyPage={
                            <EmptyState size="lg" icon={faUsers}>
                                <EmptyState.Text>
                                    {search ? <Trans>No users found</Trans> : <Trans>No users</Trans>}
                                </EmptyState.Text>
                            </EmptyState>
                        }
                        size={20}
                    />
                </div>
            </>
        </Can>
    );
};
