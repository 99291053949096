import React from 'react';
import { NavLink } from '@wedo/design-system';
import { User } from 'Shared/types/user';

type UserElementProps = {
    user: Partial<User>;
};

export const UserLink = ({ user }: UserElementProps) => {
    return (
        user && (
            <NavLink className="shrink truncate" to={'/users/' + user.id + '/tasks'}>
                <span className={'truncate'}>{user.full_name}</span>
            </NavLink>
        )
    );
};
