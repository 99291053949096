import React, { createContext, useContext, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { HierarchyCircularNode } from 'd3';
import { Button, Card, ContextModalProps, Modal, Tabs, Tag, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { useGovernanceStore } from 'Pages/governance/governanceStore';
import { getNodeFromCircleId } from 'Pages/governance/utils';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useFetchCirclesQuery } from 'Shared/services/governance';
import { Circle, Root } from 'Shared/types/governance';
import { User } from 'Shared/types/user';

type GroupedRoles = {
    name: string;
    roles: Circle[];
};

export const CircleMemberProfileModalContext = createContext<{ allCircles: Circle[] }>(null);

export const CircleMemberProfileTabsHeader = ({ userCircles }: { userCircles: Circle[] }) => {
    const numberOfRoles = userCircles?.filter((circle) => circle.type === 'role').length;
    const numberOfCircles = userCircles?.filter((circle) => circle.type === 'circle').length;
    return (
        <Tabs.Header>
            <Tabs.Tab>
                <Trans>Roles</Trans> ({numberOfRoles})
            </Tabs.Tab>
            <Tabs.Tab>
                <Trans>Circles</Trans> ({numberOfCircles})
            </Tabs.Tab>
        </Tabs.Header>
    );
};

const getCircleNameById = (id: Id, circles: Circle[]) => {
    const circle = circles.find((circle) => circle.id === id);
    return circle?.name;
};

export const CircleMemberProfileTabsRoles = ({
    roles,
    onSelectNode,
}: {
    roles: Circle[];
    onSelectNode?: (circleId?: Id) => void;
}) => {
    const HTMLTag = onSelectNode != null ? `button` : `div`;
    const { allCircles } = useContext(CircleMemberProfileModalContext);

    const groupedRoles: GroupedRoles[] = [];
    roles?.forEach((role) => {
        let found = false;
        groupedRoles.forEach((groupedRole) => {
            if (
                role.linked_circle_id != null
                    ? groupedRole.name === getCircleNameById(role.linked_circle_id, allCircles)
                    : groupedRole.name === role.name
            ) {
                groupedRole.roles.push(role);
                found = true;
            }
        });
        if (!found) {
            groupedRoles.push({
                name: role.name != null ? role.name : getCircleNameById(role.linked_circle_id, allCircles),
                roles: [role],
            });
        }
    });

    groupedRoles.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    return (
        <>
            {groupedRoles?.length > 0 ? (
                <div className="flex flex-col gap-2">
                    {groupedRoles.map((role) => {
                        if (role.roles.length > 1) {
                            return (
                                <Card
                                    className={clsx(HTMLTag === 'button' && 'hover:bg-gray-100 transition-colors')}
                                    key={role.name}
                                >
                                    <Card.Body>
                                        <div className="font-medium text-sm">{role.name}</div>
                                        <ul className="list-disc ml-4">
                                            {role.roles.map((r) => (
                                                <li key={r.id} className="text-gray-600 text-sm">
                                                    <HTMLTag
                                                        className={clsx(
                                                            HTMLTag === 'button' && 'hover:underline',
                                                            'text-start'
                                                        )}
                                                        key={r.name}
                                                        onClick={() => onSelectNode(r.id)}
                                                    >
                                                        {getCircleNameById(r.parent_circle_id, allCircles) ??
                                                            t`General`}
                                                    </HTMLTag>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            );
                        }
                        return (
                            <HTMLTag
                                className={'group/button text-start'}
                                key={role.name}
                                onClick={() => onSelectNode(role.roles[0].id)}
                            >
                                <Card
                                    className={clsx(
                                        HTMLTag === 'button' && 'group-hover/button:bg-gray-100 transition-colors'
                                    )}
                                    key={role.name}
                                >
                                    <Card.Body>
                                        <div className="font-medium text-sm">{role.name}</div>
                                        <span className="text-start text-gray-600 text-sm">
                                            {getCircleNameById(role.roles[0].parent_circle_id, allCircles) ??
                                                t`General`}
                                        </span>
                                    </Card.Body>
                                </Card>
                            </HTMLTag>
                        );
                    })}
                </div>
            ) : (
                t`This user currently has no roles`
            )}
        </>
    );
};

export const CircleMemberProfileTabsCircles = ({
    circles,
    userId,
    onSelectNode,
}: {
    circles: Circle[];
    userId: Id;
    onSelectNode?: (circleId?: Id) => void;
}) => {
    const HTMLTag = onSelectNode != null ? `button` : `div`;
    const { allCircles } = useContext(CircleMemberProfileModalContext);

    return (
        <>
            {circles?.length > 0 ? (
                <div className="flex flex-col gap-2">
                    {circles.map((circle) => (
                        <HTMLTag
                            className={'text-start group/button'}
                            key={circle.id}
                            onClick={() => onSelectNode(circle.id)}
                        >
                            <Card
                                className={clsx(
                                    HTMLTag === 'button' && 'group-hover/button:bg-gray-100 transition-colors'
                                )}
                            >
                                <Card.Body>
                                    <div className="flex flex-row items-center">
                                        <div className="flex-1 overflow-hidden">
                                            <div className="truncate font-medium text-sm">{circle.name}</div>
                                            <div className="truncate text-start text-gray-600 text-sm">
                                                {getCircleNameById(circle.parent_circle_id, allCircles) ?? t`General`}
                                            </div>
                                        </div>
                                        <div>
                                            {circle.members.find((member) => member.user_id === userId)?.is_admin && (
                                                <Tag size={'sm'} color="blue" className="ml-2">
                                                    <Trans>Administrator</Trans>
                                                </Tag>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </HTMLTag>
                    ))}
                </div>
            ) : (
                t`This user is not a member of any circles`
            )}
        </>
    );
};

export const CircleMemberProfileTabsBody = ({
    userId,
    userCircles,
    onSelectNode,
}: {
    userId: Id;
    userCircles: Circle[];
    onSelectNode?: (circleId?: Id) => void;
}) => {
    const roles = userCircles?.filter((circle) => circle.type === 'role');
    const circles = userCircles?.filter((circle) => circle.type === 'circle');
    return (
        <Tabs.Panels>
            <Tabs.Panel>
                <CircleMemberProfileTabsRoles roles={roles} onSelectNode={onSelectNode} />
            </Tabs.Panel>
            <Tabs.Panel>
                <CircleMemberProfileTabsCircles userId={userId} onSelectNode={onSelectNode} circles={circles} />
            </Tabs.Panel>
        </Tabs.Panels>
    );
};

export const filterCirclesByUser = (circles: Circle[], userId: Id) => {
    return (circles ?? [])
        .filter((circle) => circle.members.find((member) => member.user_id === userId))
        .map((role) => {
            if (role.name == null) {
                const name = circles.find((r) => r.id === role.linked_circle_id)?.name;
                return { ...role, name };
            }
            return role;
        });
};

export const CircleMemberProfileModalBody = ({
    root,
    user,
    onSelectNode,
}: {
    user: User;
    root: HierarchyCircularNode<Circle | Root>;
    onSelectNode?: (node?: HierarchyCircularNode<Circle>) => void;
}) => {
    const { currentData: circles, isLoading, isFetching } = useFetchCirclesQuery();
    const userCircles = filterCirclesByUser(circles, user.id);
    const { selectAndZoomOnNode } = useGovernanceStore();

    const handleSelectNode = (circleId: Id) => {
        const node = getNodeFromCircleId(root, circleId);
        selectAndZoomOnNode(node);
        onSelectNode(node);
    };

    return (
        <>
            {!isLoading && !isFetching && (
                <CircleMemberProfileModalContext.Provider value={{ allCircles: circles }}>
                    <Tabs>
                        <CircleMemberProfileTabsHeader userCircles={userCircles} />
                        <div className={'pt-2'}>
                            <CircleMemberProfileTabsBody
                                userId={user.id}
                                userCircles={userCircles}
                                onSelectNode={handleSelectNode}
                            />
                        </div>
                    </Tabs>
                </CircleMemberProfileModalContext.Provider>
            )}
        </>
    );
};

type CircleMemberProfileModalProps = {
    user: User;
    root: HierarchyCircularNode<Circle | Root>;
} & ContextModalProps;

export const CircleMemberProfileModal = ({ root, user, ...modalProps }: CircleMemberProfileModalProps) => {
    const { currentData: circles } = useFetchCirclesQuery();
    const userCircles = circles?.filter((circle) => circle.members.find((member) => member.user_id === user.id));
    const refClose = useRef();
    const navigate = useNavigate();
    const { selectAndZoomOnNode } = useGovernanceStore();

    const handleSelectNode = (circleId: Id) => {
        const node = getNodeFromCircleId(root, circleId);
        selectAndZoomOnNode(node);
        void modalProps.close();
    };

    return (
        <Modal {...modalProps} position="">
            <Modal.Header
                title={
                    <header>
                        <Tooltip content={t`Go to user profile`} delay={300}>
                            <button
                                className="flex gap-2 items-center text-start"
                                onClick={() => navigate(`/users/${user.id}/profile`)}
                            >
                                <UserAvatar user={user} showTooltip={false} />
                                <div>
                                    <h1 className="text-lg">{user?.full_name}</h1>
                                    {user?.userNetwork?.title && (
                                        <div className="text-sm text-gray-500">{user.userNetwork.title}</div>
                                    )}
                                </div>
                            </button>
                        </Tooltip>
                    </header>
                }
            />
            <Tabs>
                <CircleMemberProfileModalContext.Provider value={{ allCircles: circles }}>
                    <CircleMemberProfileTabsHeader userCircles={userCircles} />
                    <Modal.Body>
                        <CircleMemberProfileTabsBody
                            userId={user.id}
                            userCircles={userCircles}
                            onSelectNode={handleSelectNode}
                        />
                    </Modal.Body>
                </CircleMemberProfileModalContext.Provider>
            </Tabs>
            <Modal.Footer>
                <Button ref={refClose} onClick={modalProps.close}>
                    {t`Close`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
