import { useIntercom } from 'react-use-intercom';
import { removeAuthToken, storage } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { useCommandPaletteSearchHistory } from 'Shared/hooks/useCommandPaletteSearchHistory';
import { useLogoutMutation } from 'Shared/services/auth';
import { baseApi } from 'Shared/services/base';
import { LocalStorage } from 'Shared/types/localStorage';

const getLogoutUrl = () => {
    const protocol = window.location.protocol;
    const splitHost = window.location.host.split('.');

    if (splitHost.length > 1) {
        splitHost.splice(0, 1, 'login');
        return new URL(protocol + '//' + splitHost.join('.'));
    }

    if (__ENVIRONMENT__ === 'development') {
        return new URL(window.location.origin);
    }

    return new URL('https://login.wedo.swiss');
};

export const useLogout = () => {
    const { shutdown } = useIntercom();
    const { clearCurrentUserCmdPaletteData } = useCommandPaletteSearchHistory();
    const dispatch = useAppDispatch();

    const [logoutMutation] = useLogoutMutation();

    return async () => {
        await logoutMutation({ authToken: storage.getItem(LocalStorage.AuthToken) });
        dispatch(baseApi.util.resetApiState());
        removeAuthToken();
        removeAuthToken(LocalStorage.GlobalAuthToken);
        localStorage.removeItem(LocalStorage.AuthenticationMethod);
        shutdown();
        clearCurrentUserCmdPaletteData();

        const logoutUrl = getLogoutUrl();

        if (logoutUrl.host !== window.location.host) {
            logoutUrl.href += 'signin?logout';
        }

        window.open(logoutUrl, '_self');
    };
};
