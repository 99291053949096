import { useModal } from '@wedo/design-system';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { Notification } from 'Shared/types/notification';

export const useTaskAction = () => {
    const { open } = useModal();
    const { setSelectedTasks } = useTasksContext();

    return (notification: Notification) => {
        setSelectedTasks([{ id: notification.object_id, groupedId: null }]);
        open(TaskModal, { taskId: notification.object_id });
    };
};
