import React, { FC } from 'react';
import { Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ManageMembersModalBody } from 'Shared/components/userGroup/ManageMembersModalBody';
import { useWorkspaceManageMembers } from 'Shared/components/workspace/WorkspaceManageMembers/useWorkspaceManageMembers';
import { useManageMembers } from 'Shared/hooks/useManageMembers';

type ManageMembersProps = {
    workspaceId: Id;
    isReadOnly: boolean;
    onUserDeleted: (action: string) => void;
};

export const ManageMembersPanel: FC<ManageMembersProps> = ({
    workspaceId,
    isReadOnly: isReadonlyInitial,
    onUserDeleted,
}) => {
    const {
        workspace,
        handleTogglePrivacy,
        handleModeratorToggle,
        handleDeleteUser: deleteUser,
        handleAddUser,
    } = useWorkspaceManageMembers(workspaceId);
    const { isCurrentUserModerator } = useManageMembers(workspace);

    const isReadOnly = isReadonlyInitial || !isCurrentUserModerator;

    const handleDeleteUser = async (user) => {
        onUserDeleted(await deleteUser(user));
    };

    return (
        <Tabs.Panel className="text-center">
            <ManageMembersModalBody
                isReadOnly={isReadOnly}
                onDeleteUser={handleDeleteUser}
                onToggleModerator={handleModeratorToggle}
                onAddUser={handleAddUser}
                onPrivacyChange={handleTogglePrivacy}
                entity={workspace}
            />
        </Tabs.Panel>
    );
};
