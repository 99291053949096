import { MouseEvent } from 'react';
import {
    faEllipsisV,
    faEllipsisVertical,
    faFileExport,
    faFolderOpen,
    faInbox,
    faPlay,
    faShare,
    faSliders,
    faTasks,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { ButtonPosition, Dropdown, Size, useConfirm, useModal } from '@wedo/design-system';
import { useTasksContext } from 'App/contexts/TasksContext';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { ChecklistSettingsModal } from 'Shared/components/checklist/ChecklistSettingsModal/ChecklistSettingsModal';
import { ReplanChecklistModal } from 'Shared/components/checklist/ReplanChecklistModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useUpdateChecklistMutation } from 'Shared/services/checklist';
import { Checklist } from 'Shared/types/checklist';

type CheckListDropdownProps = {
    checklist: Checklist;
    position?: ButtonPosition;
    size?: Size;
};

export const ChecklistDropdown = ({ checklist, size, position }: CheckListDropdownProps) => {
    const { open } = useModal();
    const { confirm } = useConfirm();

    const { isCurrentUserModerator } = useManageMembers(checklist?.checklistTemplate);
    const { selectedTasks } = useTasksContext();
    const [updateChecklist] = useUpdateChecklistMutation();

    const handleArchive = () => {
        void confirm({
            type: 'primary',
            title: t`Archive ${checklist.name}`,
            content: t`Do you want to archive the ${checklist.name} checklist?`,
            confirmText: t`Archive`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, checklist: { archived: true } }).unwrap(),
        });
    };

    const handleUnarchive = () => {
        void confirm({
            type: 'primary',
            title: t`Unarchive ${checklist.name}`,
            content: t`Do you want to unarchive the ${checklist.name} checklist?`,
            confirmText: t`Unarchive`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, checklist: { archived: false } }).unwrap(),
        });
    };

    const handleDelete = () => {
        void confirm({
            type: 'danger',
            title: t`Delete ${checklist.name}`,
            content: t`Do you want to delete the ${checklist.name} checklist?`,
            confirmText: t`Delete`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, checklist: { deleted: true } }).unwrap(),
        });
    };

    const handleRestore = () => {
        void confirm({
            type: 'success',
            title: t`Restore ${checklist.name}`,
            content: t`Do you want to restore the ${checklist.name} checklist?`,
            confirmText: t`Restore`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, checklist: { deleted: false } }).unwrap(),
        });
    };

    const handleExport = () => {
        open(ExportTasksModal, {
            checklistId: checklist?.id,
            grouping: 'section',
            isExportingAllTasks: true,
            order: 'section',
            selectedTasks: selectedTasks.map(({ id }) => id),
            statuses: ['todo', 'completed', 'deleted'],
            view: 'all',
        });
    };

    if (checklist == null) {
        return <Dropdown icon={faEllipsisV} size={size} />;
    }

    return (
        <Dropdown
            icon={faEllipsisVertical}
            size={size}
            position={position}
            onClick={(event: MouseEvent) => event.stopPropagation()}
        >
            {isCurrentUserModerator && (
                <Dropdown.LinkItem icon={faTasks} to={`/templates/${checklist.checklist_template_id}/tasks`}>
                    <Trans>Edit template tasks</Trans>
                </Dropdown.LinkItem>
            )}

            <Dropdown.Item
                icon={faSliders}
                onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    open(ChecklistSettingsModal, { checklistId: checklist.id });
                }}
            >
                <Trans>Settings</Trans>
            </Dropdown.Item>

            <Dropdown.Item icon={faFileExport} onClick={handleExport}>
                <Trans>Export</Trans>
            </Dropdown.Item>

            <Dropdown.Item
                icon={faPlay}
                onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    open(ReplanChecklistModal, { checklistId: checklist.id });
                }}
            >
                <Trans>Replan checklist</Trans>
            </Dropdown.Item>

            <Dropdown.DividerItem />

            {!checklist.archived && (
                <Dropdown.Item
                    icon={faInbox}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleArchive();
                    }}
                >
                    <Trans>Archive</Trans>
                </Dropdown.Item>
            )}

            {checklist.archived && (
                <Dropdown.Item
                    icon={faFolderOpen}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleUnarchive();
                    }}
                >
                    <Trans>Unarchive</Trans>
                </Dropdown.Item>
            )}

            {!checklist.deleted && (
                <Dropdown.Item
                    icon={faTrash}
                    danger
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleDelete();
                    }}
                >
                    <Trans>Delete</Trans>
                </Dropdown.Item>
            )}

            {checklist.deleted && (
                <Dropdown.Item
                    icon={faShare}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleRestore();
                    }}
                >
                    <Trans>Restore</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
