import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { faMagnifyingGlass, faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { take } from 'lodash-es';
import { tokenize } from 'ss-search';
import { Button, ButtonProps, EmptyState, Input, ItemGroup, Popover } from '@wedo/design-system';
import { onEnter } from '@wedo/utils';
import { UserItemGroup } from 'Shared/components/user/UserPicker/UserItemGroup';
import { UserPickerVirtualScroll } from 'Shared/components/user/UserPicker/UserPickerVirtualScroll';
import { filterUsers, useFilteredUsers, useSelectedUsers } from 'Shared/components/user/UserPicker/userPickerHelpers';
import { useGetMyAssigneesQuery } from 'Shared/services/task';
import { User } from 'Shared/types/user';

export type UserPickerProps = {
    onUserSelected?: (user: User) => void;
    onAddRow?: (fullName: string) => void;
    usersToHide?: Partial<User>[];
    showNobody?: boolean;
    isStatic?: boolean;
    isReadonly?: boolean;
    isMultiple?: boolean;
    keepOpenAfterSelection?: boolean;
    isLoading?: boolean;
    placement?: ButtonProps['tooltipPlacement'];
    contextUsers?: User[];
    contextTitle?: string;
    showSearchBar?: boolean;
    showRecent?: boolean;
    searchValue?: string;
    currentIndex?: number;
    onFilteredUsers?: (users: User[]) => void;
    wrapperClassName?: string;
    className?: string;
} & ButtonProps;

export const UserPicker = React.forwardRef<HTMLDivElement, UserPickerProps>(
    (
        {
            onUserSelected,
            usersToHide,
            children,
            onAddRow,
            placement = 'bottom-end',
            contextUsers,
            contextTitle,
            searchValue = null,
            currentIndex,
            onFilteredUsers,
            className,
            wrapperClassName,
            isLoading = false,
            showNobody = false,
            isReadonly = false,
            isStatic,
            isMultiple = false,
            keepOpenAfterSelection = false,
            showSearchBar = true,
            showRecent = true,
            ...buttonProps
        },
        ref
    ) => {
        const { data: recentUsers } = useGetMyAssigneesQuery({ limit: 6 });

        const [searchText, setSearchText] = useState<string>('');
        const [searchWords, setSearchWords] = useState<string[]>([]);
        const [selectedIndex, setSelectedIndex] = useState<number>(-1);
        const [rowValue, setRowValue] = useState<string>('');

        const { filteredUsers } = useFilteredUsers(usersToHide, searchText);
        const { selectedUsers } = useSelectedUsers(usersToHide, searchText);
        const allUsers = isMultiple ? [...selectedUsers, ...filteredUsers] : [...filteredUsers];

        const filteredContextUser = useMemo(
            () =>
                take(
                    filterUsers(
                        contextUsers?.length > 0 ? contextUsers : recentUsers ?? [],
                        usersToHide,
                        false,
                        searchText
                    ),
                    showNobody ? 5 : 6
                ),
            [recentUsers, contextUsers, usersToHide, searchText]
        );

        const [prevSearchValue, setPrevSearchValue] = useState(searchValue);
        if (searchValue && searchValue !== prevSearchValue) {
            setPrevSearchValue(searchValue);
            setSearchText(searchValue);
        }

        const [prevCurrentIndex, setPrevCurrentIndex] = useState(currentIndex);
        if (currentIndex !== prevCurrentIndex) {
            setPrevCurrentIndex(currentIndex);
            setSelectedIndex(currentIndex);
        }

        const [prevFilteredUsers, setPrevFilteredUsers] = useState(filteredUsers);
        if (onFilteredUsers && filteredUsers?.length !== prevFilteredUsers?.length) {
            setPrevFilteredUsers(filteredUsers);
            onFilteredUsers(filteredUsers);
        }

        const handleSearch = (value: string) => {
            setSearchText(value);
            setSearchWords(tokenize(value));
            if (value && value.length > 0) {
                setSelectedIndex(0);
            }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, close: () => void): void => {
            const keyCode = e.code;
            if (keyCode === 'ArrowUp' || keyCode === 'ArrowDown') {
                let newIndex = selectedIndex + (keyCode === 'ArrowUp' ? -1 : 1);
                newIndex = Math.max(newIndex, 0);
                newIndex = Math.min(newIndex, allUsers.length - 1);
                setSelectedIndex(newIndex);
                // Scroll to workspace if needed
                setTimeout(() => {
                    const el = document.querySelector(`#user-${allUsers[newIndex].id}`);
                    if (el) {
                        el.scrollIntoView({
                            block: 'nearest',
                            inline: 'start',
                        });
                    }
                }, 50);
                e.preventDefault();
                e.stopPropagation();
            } else if (keyCode === 'Enter' && selectedIndex < allUsers.length) {
                onUserSelected(allUsers[selectedIndex]);
                e.preventDefault();
            } else if (keyCode === 'Escape') {
                close();
                e.preventDefault();
            }
        };

        const handleUserSelected = async (user: User, close: () => void) => {
            if (!keepOpenAfterSelection) {
                close();
            }
            return onUserSelected(user);
        };

        const handleAddRow = (e: React.KeyboardEvent<unknown> | React.MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault();
            if (rowValue.trim().length > 0) {
                setRowValue('');
                onAddRow(rowValue);
            }
        };

        return (
            <Popover
                wrapperClassName={wrapperClassName}
                text={children}
                className={className}
                placement={placement}
                disabled={isReadonly}
                isStatic={isStatic}
                {...buttonProps}
            >
                {({ close }) => (
                    <div className="relative space-y-2 bg-white" ref={ref}>
                        {isLoading && (
                            <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white opacity-30">
                                <FontAwesomeIcon icon={faSpinner} className="fa-pulse text-3xl" />
                            </div>
                        )}
                        <div className="p-2">
                            <Input
                                trailingIcon={faMagnifyingGlass}
                                placeholder={t`Search...`}
                                value={searchText}
                                disabled={isLoading}
                                onChange={(e) => handleSearch(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, close)}
                                className={clsx(!showSearchBar && 'hidden')}
                            />
                            {showRecent && filteredContextUser?.length > 0 && (
                                <UserItemGroup
                                    title={contextTitle || showRecent ? t`Recent` : t`Nobody`}
                                    users={showRecent ? filteredContextUser : []}
                                    onUserSelected={(user) => handleUserSelected(user, close)}
                                />
                            )}
                        </div>
                        <div
                            tabIndex={-1}
                            className="scrollbar-light flex h-64 snap-y flex-col gap-0.5 overflow-y-auto overflow-x-hidden"
                        >
                            {showSearchBar && filteredUsers?.length > 0 && showRecent && (
                                <div className="my-1 text-xs font-bold text-gray-800 pl-2">
                                    <Trans>All users</Trans>
                                </div>
                            )}
                            {allUsers?.length > 0 && (
                                <UserPickerVirtualScroll
                                    showNobody={showNobody}
                                    searchWords={searchWords}
                                    onUserSelected={(user) => handleUserSelected(user, close)}
                                    users={allUsers}
                                    selectedUsers={selectedUsers}
                                    selectedIndex={selectedIndex}
                                />
                            )}
                            {allUsers?.length === 0 && !isLoading && searchText?.trim()?.length > 0 && (
                                <EmptyState size="md">
                                    <EmptyState.Text>
                                        <Trans>No users found</Trans>
                                    </EmptyState.Text>
                                </EmptyState>
                            )}
                        </div>
                        {onAddRow && (
                            <div className="px-1 pt-1 pb-2">
                                <div className="text-xs font-bold text-gray-800">
                                    <Trans>Add row</Trans>
                                </div>
                                <ItemGroup>
                                    <Input
                                        placeholder={t`Full name`}
                                        value={rowValue}
                                        onKeyDown={onEnter(handleAddRow)}
                                        onChange={(e) => setRowValue(e.target.value)}
                                    />
                                    <Button
                                        color="primary"
                                        icon={faPlus}
                                        onClick={handleAddRow}
                                        disabled={rowValue.length === 0}
                                    />
                                </ItemGroup>
                            </div>
                        )}
                    </div>
                )}
            </Popover>
        );
    }
);
