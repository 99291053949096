import { createContext, Dispatch } from 'react';
import { StageEnable, TotpActionType, TotpType } from 'Pages/settings/security/utils/type';

export const totpContextInit: TotpType = Object.freeze({
    stage: StageEnable.Password,
    error: '',
    password: '',
    qrCode: { image: '', secret: '' },
    totp: '',
    phoneNumber: '',
    phoneCode: '',
    securityCode: [],
    authenticators: [],
    force2fa: false,
});

export const TotpContext = createContext<{
    state: TotpType;
    dispatch: Dispatch<TotpActionType>;
}>({
    state: totpContextInit,
    dispatch: () => totpContextInit,
});
