import { t } from '@lingui/macro';
import { format } from 'date-fns';
import { useMeetingContext } from 'App/contexts';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const RevisitTopicLabel = ({ topic }: { topic: MeetingTopic }) => {
    const { meeting } = useMeetingContext();
    const { userLanguage: userLocale } = useUserLanguage();

    if (meeting == null || topic == null) {
        return null;
    }
    let revisitLabel = t`Revisit`;
    const nextOccurrence = topic.next_occurrences?.find((occurrence) => occurrence.id != null);
    if (nextOccurrence) {
        const date = format(new Date(nextOccurrence.meeting.start_at), 'P', {
            locale: userLocale,
        });
        revisitLabel = topic.revisited ? t`Revisited on the ${date}` : t`Revisit on the ${date}`;
    } else if ((topic.repeat_every > 0 || meeting.nextMeetings?.length === 0) && topic.revisited) {
        revisitLabel = t`Revisited`;
    }
    return <>{revisitLabel}</>;
};
