import { t } from '@lingui/macro';
import webViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { colors } from '@wedo/design-system';
import { onEsc } from '@wedo/utils';
import { useSessionUser } from 'App/store/usersStore';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { Attachment } from 'Shared/types/attachment';

const createCloseButton = (onClick: () => void) => ({
    dataElement: 'close',
    type: 'actionButton',
    img: '/assets/toolbar-close.svg',
    title: t`Close`,
    onClick,
});

const createDownloadPdfButton = (onClick: () => void) => ({
    dataElement: 'download-pdf',
    type: 'actionButton',
    img: '/assets/toolbar-download-pdf.svg',
    title: t`Download as PDF`,
    onClick,
});

const createDownloadButton = (onClick: () => void) => ({
    dataElement: 'download',
    type: 'actionButton',
    img: '/assets/toolbar-download.svg',
    title: t`Download original file`,
    onClick,
});

const createPrintButton = (instance: WebViewerInstance) => ({
    dataElement: 'print',
    type: 'actionButton',
    img: 'icon-header-print-line',
    title: t`Print`,
    onClick: async () => {
        instance.UI.print();
    },
});

const createEditButton = (onClick: () => void) => ({
    dataElement: 'edit',
    type: 'actionButton',
    img: '/assets/toolbar-edit.svg',
    title: t`Edit`,
    onClick,
});

const createRevertButton = (onClick: () => void) => ({
    dataElement: 'revert',
    type: 'actionButton',
    img: '/assets/toolbar-revert.svg',
    title: t`Revert`,
    onClick,
});

const createSaveButton = (onClick: () => void) => ({
    dataElement: 'save',
    type: 'actionButton',
    img: '/assets/toolbar-save.svg',
    title: t`Save`,
    onClick,
});

type Props = {
    onEdit: (isEditing: boolean) => void;
    onDownload: () => void;
    onDownloadPdf: () => void;
    onClose: () => void;
    onSave: () => void;
    onDocumentLoaded: () => void;
    onError: () => void;
};

export const refreshHeaderButtons = (instance: WebViewerInstance, isEdition: boolean) => {
    if (isEdition) {
        instance.UI.disableElements(['edit', 'download']);
        instance.UI.enableElements(['revert', 'save']);
    } else {
        instance.UI.enableElements(['edit', 'download']);
        instance.UI.disableElements(['revert', 'save']);
    }
};

export const configureWebViewer = (instance: WebViewerInstance, attachment: Attachment, search: string) => {
    // Currently the Word edition is disabled
    // const isWord = isAttachmentWord(attachment as Attachment);
    // if (isWord) {
    //     refreshHeaderButtons(instance, false);
    // } else {
    instance.UI.disableElements(['edit', 'revert', 'save']);
    // }

    if (attachment == null) {
        instance.UI.disableElements(['ribbons', 'close']);
        instance.UI.setTheme('light');
    }

    if (search?.trim().length > 0) {
        instance.Core.documentViewer.addEventListener('documentLoaded', () => {
            instance.UI.searchTextFull(search);
        });
    } else {
        instance.Core.documentViewer.addEventListener('documentLoaded', () => {
            instance.UI.searchTextFull('');
            instance.UI.closeElements(['searchPanel', 'searchOverlay']);
        });
    }
};

export const useWebViewer = ({
    onClose,
    onEdit,
    onDownload,
    onDownloadPdf,
    onSave,
    onDocumentLoaded,
    onError,
}: Props) => {
    const { setData } = usePdfViewerContext();
    const currentUser = useSessionUser();

    return async ({
        viewerElement,
        doc,
        filename,
        useBetaUi = false,
    }: {
        viewerElement: HTMLDivElement;
        doc: string;
        filename: string;
        useBetaUi?: boolean;
    }): Promise<WebViewerInstance> => {
        const instance = await webViewer(
            {
                path: '/pdf-viewer',
                licenseKey: __PDFTRON_LICENCE_KEY__,
                initialDoc: doc,
                filename: filename,
                loadAsPDF: true,
                annotationUser: currentUser?.id,
                fullAPI: useBetaUi ? true : undefined,
                ui: useBetaUi ? 'beta' : undefined,
            },
            viewerElement
        );
        setData({ instance });

        // Default print quality = high
        instance.UI.setPrintQuality(5);

        instance.Core.documentViewer.addEventListener('documentLoaded', onDocumentLoaded);

        if (useBetaUi) {
            const { Core, UI } = instance;

            UI.setTheme('light');

            UI.setToolMode(Core.Tools.ToolNames.PAN);

            // instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous) doesn't work
            // See https://community.apryse.com/t/displaymodemanager-setdisplaymode-rendering-the-document-a-second-time/6730
            Core.documentViewer
                .getDisplayModeManager()
                .setDisplayMode(new Core.DisplayMode(Core.documentViewer, Core.DisplayModes.Continuous));

            const style = UI.iframeWindow.document.documentElement.style;
            style.setProperty('--document-background-color', colors.gray['50']);

            const leftGroup = new UI.Components.GroupedItems({
                dataElement: 'leftGroup',
                items: [new UI.Components.Zoom()],
                alwaysVisible: true,
            });

            const centerGroup = new UI.Components.GroupedItems({
                dataElement: 'centerGroup',
                items: [{ type: 'pageControls' }],
                alwaysVisible: true,
            });

            const rightGroup = new UI.Components.GroupedItems({
                dataElement: 'rightGroup',
                items: [
                    new UI.Components.PresetButton({ buttonType: 'downloadButton' }),
                    new UI.Components.CustomButton({
                        dataElement: 'printButton',
                        title: t`Print`,
                        onClick: () => UI.printInBackground(),
                        img: 'icon-header-print-line',
                    }),
                ],
                alwaysVisible: true,
            });

            const topHeader = new UI.Components.ModularHeader({
                dataElement: 'default-top-header',
                placement: 'top',
                grow: 0,
                gap: 12,
                justifyContent: 'space-between',
                stroke: true,
                dimension: { borderWidth: 1 },
                style: {
                    padding: '8px 12px',
                    borderColor: colors.gray['200'],
                },
                items: [leftGroup, centerGroup, rightGroup],
            });

            UI.setModularHeaders([topHeader]);

            UI.disableElements(['toolsOverlay']);

            return instance;
        }

        // Set language from user & default theme
        void instance.UI.setLanguage(currentUser?.language_code);
        instance.UI.setTheme('dark');

        // Hide unwanted menus
        instance.UI.disableElements([
            'annotationPopup',
            'leftPanelButton',
            'menuButton',
            'toolbarGroup-Edit',
            'toolbarGroup-FillAndSign',
            'toolbarGroup-Forms',
            'toolbarGroup-Insert',
        ]);

        // Set default view
        instance.UI.setToolbarGroup('toolbarGroup-View');

        // Create new action buttons
        instance.UI.setHeaderItems((header) => {
            header.headers.default.splice(1, 1); //remove first divider
            header.push(createEditButton(() => onEdit(true)));
            header.push(createRevertButton(() => onEdit(false)));
            header.push(createSaveButton(onSave));

            header.push(createDownloadButton(onDownload));
            header.push(createDownloadPdfButton(onDownloadPdf));
            header.push(createPrintButton(instance));
            header.push(createCloseButton(onClose));
        });

        instance.Core.documentViewer.addEventListener('keyDown', onEsc(onClose));
        instance.UI.addEventListener(instance.UI.Events.LOAD_ERROR, onError);

        // Annotations
        instance.Core.annotationManager.addEventListener('annotationChanged', async ({ imported }) => {
            // Imported annotations should not trigger a save
            if (imported) {
                return;
            }
            setData({ isAnnotationChanged: true });
        });
        instance.Core.annotationManager.setAnnotationDisplayAuthorMap(() => currentUser.full_name);

        return instance;
    };
};
