import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { useSetPrimaryEmailMutation } from 'Shared/services/user-email';
import { UserEmail } from 'Shared/types/user-email';

interface ModalContentProps {
    emailAddress: string;
}

const ModalContent = ({ emailAddress }: ModalContentProps): JSX.Element => {
    return (
        <Trans>
            Are you sure you want to change your primary email address to{' '}
            <span className="font-semibold">{emailAddress}</span>?
        </Trans>
    );
};

export const useSetPrimaryEmailModal = () => {
    const { confirm } = useConfirm();
    const [setPrimaryEmail] = useSetPrimaryEmailMutation();

    const open = (email: UserEmail) =>
        confirm({
            title: t`Change primary email address`,
            confirmText: t`Change`,
            content: <ModalContent emailAddress={email.email_address} />,
            onConfirm: () => setPrimaryEmail(email).unwrap(),
            type: 'warning',
        });

    return { open };
};
