import React, { FC, useMemo } from 'react';
import { faUserXmark } from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Input, Select, Table, Tag, Tooltip } from '@wedo/design-system';
import { LanguageList } from '@wedo/utils';
import { UserField } from 'Pages/settings/users/components/ImportModal/VerifyUploadTab';
import { UploadUsersReturn } from 'Shared/services/admin';
import { UserRole } from 'Shared/types/user';
import { emailError, getFormattedUserRole, requiredAndNoSpecialError } from 'Shared/utils/user';

const StatusMessages = {
    failed: msg`Failed`,
    ready: msg`Ready`,
    ignored: msg`Ignored`,
};

const ReasonMessages = {
    EmailFormatInvalid: msg`The email address format is invalid.`,
    UserInfoMissing: msg`Some required fields are empty.`,
    DuplicateUser: msg`The email address is already in the list.`,
    UserAlreadyInNetwork: msg`A user with this email address already exists in your organization.`,
    ExternalWithNetworkDomain: msg`External users' email domain must be different from the organization's domain.`,
    ExternalNotAuthorized: msg`External users are not allowed in your organization.`,
};

const TableCellInput = ({
    field,
    value,
    errors,
    hasError,
    onChange,
}: {
    field: UserField;
    value: string;
    errors: Map<string, string>;
    hasError: boolean;
    onChange: (field: UserField, value: string) => void;
}) => (
    <Table.Cell className={clsx(hasError && 'relative pb-5')}>
        <Input
            className="[&>input]:m-0 [&>input]:w-auto"
            size="sm"
            value={value}
            onChange={(e) => onChange(field, e.target.value)}
            status={errors.get(field) ? 'error' : 'default'}
            statusText={errors.get(field)}
            helperTextClassName="absolute !text-xs mt-0"
        />
    </Table.Cell>
);

type VerifyUploadRowProps = {
    user: UploadUsersReturn;
    index: number;
    onDelete: (index: number) => void;
    onChange: (index: number, field: UserField, value: string) => void;
};

export const VerifyUploadRow: FC<VerifyUploadRowProps> = ({ user, index, onDelete, onChange }) => {
    const handleChange = (field: UserField, value: string) => {
        onChange(index, field, value);
    };

    const errors = useMemo(() => {
        const errors = new Map<string, string>();
        errors.set('first_name', requiredAndNoSpecialError(user.user.first_name));
        errors.set('email', emailError(user.email));

        return errors;
    }, [user]);

    const hasError = useMemo(() => Array.from(errors.values()).some((value) => value?.length > 0), [errors]);

    return (
        <Table.Row className={clsx(errors.get('email') ? 'pb-5' : null)}>
            <TableCellInput
                field="first_name"
                value={user.user.first_name}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <TableCellInput
                field="last_name"
                value={user.user.last_name}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <TableCellInput
                field="email"
                value={user.email}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <TableCellInput
                field="phone_number"
                value={user.phone_number}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <TableCellInput
                field="organisation_name"
                value={user.organisation_name}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <TableCellInput
                field="department"
                value={user.department}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <TableCellInput
                field="title"
                value={user.title}
                errors={errors}
                hasError={hasError}
                onChange={handleChange}
            />
            <Table.Cell className={clsx(hasError && 'pb-5')}>
                <Select
                    value={user.role}
                    customRenderSelected={(role) => <span>{getFormattedUserRole(role)}</span>}
                    onChange={(e) => handleChange('role', e)}
                >
                    {Object.values(UserRole).map((role) => (
                        <Select.Option key={role} value={role}>
                            {getFormattedUserRole(role)}
                        </Select.Option>
                    ))}
                </Select>
            </Table.Cell>
            <Table.Cell className={clsx(hasError && 'pb-5')}>
                <Select value={user.user.language_code} onChange={(e) => handleChange('language_code', e)}>
                    {LanguageList.map((lang) => (
                        <Select.Option key={lang} value={lang}>
                            {lang}
                        </Select.Option>
                    ))}
                </Select>
            </Table.Cell>
            <Table.Cell className="text-center">
                <Tooltip
                    content={
                        ReasonMessages[user.reason] != null ? <Trans id={ReasonMessages[user.reason]} /> : user.reason
                    }
                >
                    <Tag
                        className="cursor-default"
                        color={user.status === 'ready' ? 'green' : user.status === 'ignored' ? 'yellow' : 'red'}
                    >
                        <Trans id={StatusMessages[user.status]} />
                    </Tag>
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                <Button
                    title={t`Remove user`}
                    color="danger"
                    variant="outlined"
                    onClick={() => onDelete(index)}
                    icon={faUserXmark}
                />
            </Table.Cell>
        </Table.Row>
    );
};
