import { createContext, useContext } from 'react';

export type TableContextType = {
    striped?: boolean;
    hoverable?: boolean;
    wrapperClassName?: string;
    size?: 'default' | 'condensed';
};

export const TableContext = createContext<TableContextType | undefined>(undefined);

export const useTableContext = (): TableContextType => {
    const context = useContext(TableContext);

    if (!context) {
        throw new Error('useTableContext should be used within the TableContext provider!');
    }

    return context;
};
