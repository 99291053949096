import React, { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmModal, ContextModalProps } from '@wedo/design-system';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { ApplyOnRadioGroup } from 'Shared/components/task/TaskDetail/ApplyOnRadioGroup';

export const ConfirmBulkDeleteTasksWithSubtasks: FC<ContextModalProps> = ({ ...modalProps }) => {
    const [applyOn, setApplyOn] = useState(ApplyOn.AllTasks);

    return (
        <ConfirmModal
            {...modalProps}
            type="danger"
            onConfirm={async () => applyOn}
            title={t`Some tasks have open subtasks`}
            content={
                <div className="flex flex-col gap-4">
                    <p>
                        <Trans>
                            Some tasks have open subtasks. Do you want to delete only the tasks or both the tasks and
                            their subtasks?
                        </Trans>
                    </p>
                    <ApplyOnRadioGroup
                        value={applyOn}
                        onChange={setApplyOn}
                        allOptionText={t`Delete tasks and their subtasks`}
                        currentTaskOptionText={t`Delete only tasks`}
                    />
                </div>
            }
            confirmText={applyOn === ApplyOn.AllTasks ? t`Delete tasks and their subtasks` : t`Delete only tasks`}
        />
    );
};
