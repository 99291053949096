import React from 'react';
import { faDownload, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Tag } from '@wedo/design-system';
import { download } from '@wedo/utils';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { Attachment } from 'Shared/types/attachment';

export const FeedItemAttachment = ({ attachments }: { attachments: Partial<Attachment>[] }) => {
    const { setData } = usePdfViewerContext();

    const handleAttachmentClick = (attachment: Partial<Attachment>) => {
        if (attachment.preview_url != null || attachment.open_url != null) {
            setData({ pdf: attachment as Attachment });
            return;
        }

        download(attachment.download_url, attachment.filename);
    };

    if (attachments == null || attachments.length === 0) {
        return null;
    }

    return (
        <div className="px-2 py-1 border-t flex flex-wrap items-center gap-2">
            {attachments.map((attachment) => {
                return (
                    <Tag
                        key={attachment.id}
                        icon={
                            attachment.preview_url != null || attachment.open_url != null
                                ? faMagnifyingGlass
                                : attachment.download_url != null
                                ? faDownload
                                : undefined
                        }
                        size="xs"
                        onClick={() => handleAttachmentClick(attachment)}
                    >
                        {attachment.filename}
                    </Tag>
                );
            })}
        </div>
    );
};
