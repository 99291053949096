import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { format } from 'date-fns';
import { Badge, Button, ContextModalProps, getBadgeInfo, Modal } from '@wedo/design-system';
import { getDateFnsLocale } from '@wedo/utils';
import { getUser, useSessionUser } from 'App/store/usersStore';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { trpc } from 'Shared/trpc';
import { Badge as BadgeType } from 'Shared/types/badge';

export const BadgeModal = ({ badge, ...modalProps }: { badge: BadgeType } & ContextModalProps) => {
    const { i18n } = useLingui();
    const currentUser = useSessionUser();
    const badgeInfo = getBadgeInfo(badge.id);
    const locale = getDateFnsLocale(i18n.locale);
    const { data: usersWithBadge } = trpc.badge.listUsersWithBadge.useQuery(badge.id);
    const filteredUsersWithBadge = usersWithBadge?.filter(({ userId }) => Number(currentUser.id) !== userId);
    return (
        <Modal size={'sm'} {...modalProps}>
            <Modal.Header title={<Trans>Badge detail</Trans>} />
            <Modal.Body>
                <div className="flex flex-col gap-2 justify-center items-center">
                    <Badge
                        id={badge.id}
                        points={badge.value}
                        icon={badgeInfo.icon}
                        type={badge.type}
                        isLocked={(badge.userBadges ?? []).length === 0}
                        isStatic
                        isRepeating={badge.id.endsWith('_R')}
                        timesAchieved={badge.userBadges?.length > 1 ? badge.userBadges?.length : null}
                        quantity={badgeInfo.quantity}
                    />
                    <div className="font-bold text-center">{badgeInfo.name}</div>
                    <div className="flex gap-2 text-sm">
                        <div>
                            <Trans>{badge.value} pts</Trans>
                        </div>
                        /
                        <div>
                            {(badge.userBadges ?? []).length === 0 ? (
                                <Trans>Not yet obtained</Trans>
                            ) : badge.id.endsWith('_R') ? (
                                <div>
                                    <Trans>Last obtained:</Trans>{' '}
                                    {format(new Date(badge.userBadges[0].obtainedAt), 'PPP', { locale })}
                                </div>
                            ) : (
                                <div>
                                    <Trans>Obtained:</Trans>{' '}
                                    {format(new Date(badge.userBadges[0].obtainedAt), 'PPP', { locale })}
                                </div>
                            )}
                        </div>
                    </div>
                    {badge.id.endsWith('_R') && (
                        <div className="text-xs flex gap-1 items-center ">
                            <FontAwesomeIcon icon={faSync} />
                            <div>
                                <Trans>Can be obtained multiple times</Trans>
                            </div>
                            {badge.userBadges?.length >= 1 && (
                                <div>
                                    (<Trans>Obtained {badge.userBadges.length}x</Trans>)
                                </div>
                            )}
                        </div>
                    )}
                    {badgeInfo.description && (
                        <div className={'text-sm text-gray-800 text-center'}>{badgeInfo.description}</div>
                    )}
                    {filteredUsersWithBadge?.length > 0 && (
                        <div className="w-full">
                            <div className="font-bold mb-2 text-center text-sm">
                                <Trans>Colleagues with badge</Trans>
                            </div>
                            <div className="flex gap-2 flex-wrap justify-center">
                                {filteredUsersWithBadge.map(({ userId }) => (
                                    <div key={userId}>
                                        <UserAvatar user={getUser(userId)} key={userId} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
