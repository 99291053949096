import { type ChangeEvent, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ColorPicker, Form, Input, Modal, type ModalProps } from '@wedo/design-system';
import { trpc } from 'Shared/trpc';
import { type EventBus } from './events';
import { type Section } from './types';

type AddEditSectionModalProps = {
    eventBus: EventBus;
    workspaceId: string;
    order?: number;
    section?: Section;
    property?: 'color' | 'name';
} & ModalProps;

export const AddUpdateSectionModal = ({
    eventBus,
    workspaceId,
    order = 0,
    section,
    property,
    close,
    ...modalProps
}: AddEditSectionModalProps) => {
    const nameInputRef = useRef();

    const [name, setName] = useState(section?.name ?? '');
    const [color, setColor] = useState(section?.color);

    const { mutateAsync: addSection, isPending: isAddingSection } = trpc.workspace.addSection.useMutation();
    const { mutateAsync: updateSection, isPending: isUpdatingSection } = trpc.workspace.updateSection.useMutation();
    const isLoading = isAddingSection || isUpdatingSection;

    const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setName(target.value);
    };

    const handleUpdate = async () => {
        if (section == null) {
            await addSection({ workspaceId, name, color, order });
            eventBus.dispatchRenderDependenciesEvent();
        } else {
            await updateSection({ sectionId: section.id, name, color });
        }
        close();
    };

    return (
        <Modal {...modalProps} size="sm" initialFocus={nameInputRef}>
            <Modal.Header title={section != null ? t`Update section` : t`Add section`} />
            <Modal.Body>
                <Form layout={property != null ? undefined : 'horizontal'}>
                    {(property === 'name' || property == null) && (
                        <Form.Item label={property === 'name' ? undefined : t`Name`}>
                            <Input
                                ref={nameInputRef}
                                type="text"
                                // FIXME This should work instead of setting custom provider attributes on elements...
                                // autoComplete="off"
                                data-1p-ignore
                                value={name}
                                onChange={handleNameChange}
                            />
                        </Form.Item>
                    )}
                    {(property === 'color' || property == null) && (
                        <Form.Item label={property === 'color' ? undefined : t`Color`}>
                            <ColorPicker color={color} onChange={setColor} className="[&>div:first-child]:hidden" />
                        </Form.Item>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close} disabled={isLoading}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={handleUpdate} color="primary" loading={isLoading}>
                    {section != null ? <Trans>Update</Trans> : <Trans>Add</Trans>}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
