import { t } from '@lingui/macro';
import clsx from 'clsx';

export const SignatureTypeTag = ({ type, disabled = false }: { type: 'AES' | 'QES' | 'SES'; disabled?: boolean }) => {
    const translatedType = type === 'QES' ? t`QES` : type === 'AES' ? t`AES` : t`SES`;
    return (
        <div
            className={clsx(
                disabled
                    ? 'bg-gray-300'
                    : type === 'SES'
                      ? 'bg-gradient-lollipop'
                      : type === 'AES'
                        ? 'bg-gradient-blue'
                        : 'bg-gradient-neon',
                'relative w-12 h-8 rounded-[0.5rem] shrink-0'
            )}
        >
            <div
                className={clsx(
                    disabled ? 'bg-gray-100' : 'bg-white',
                    'absolute top-1/2 h-[1.70rem] items-center flex left-1/2 w-[2.7rem] rounded-[0.375rem] transform -translate-x-1/2 -translate-y-1/2'
                )}
            >
                <div
                    className={clsx(
                        type === 'SES'
                            ? 'bg-gradient-lollipop'
                            : type === 'AES'
                              ? 'bg-gradient-blue'
                              : 'bg-gradient-neon',
                        disabled ? 'text-gray-300' : 'text-transparent',
                        'w-full text-sm font-bold text-center inline-block bg-clip-text'
                    )}
                >
                    {translatedType}
                </div>
            </div>
        </div>
    );
};
