import React from 'react';
import { Avatar, type AvatarSizes, type BasePlacement } from '@wedo/design-system';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { MeetingUser } from 'Shared/types/meetingUser';

type MeetingUsersAvatarGroupProps = {
    meetingUsers: Array<MeetingUser>;
    maxDisplayed?: number;
    size?: AvatarSizes;
    className?: string;
    tooltipPlacement?: BasePlacement;
    showTooltip?: boolean;
};

export const MeetingUsersAvatarGroup: React.FC<MeetingUsersAvatarGroupProps> = ({
    meetingUsers,
    maxDisplayed = 7,
    size = 'md',
    className,
    tooltipPlacement = 'right',
    showTooltip = true,
}) => {
    return (
        <Avatar.Group size={size} className={className} maxAvatars={maxDisplayed}>
            {meetingUsers?.map((meetingUser) => (
                <MeetingUserAvatar
                    user={meetingUser}
                    size={size}
                    key={meetingUser?.id}
                    tooltipPlacement={tooltipPlacement}
                    showTooltip={showTooltip}
                />
            ))}
        </Avatar.Group>
    );
};
