import { t } from '@lingui/macro';
import { ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { VoteDetail } from 'Pages/meeting/components/Vote/VoteDetail/VoteDetail';

type VoteModalProps = { voteId: Id; meetingId: Id } & ContextModalProps;

export const VoteModal = ({ voteId, meetingId, children, close, ...modalProps }: VoteModalProps) => {
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Update vote`} />
            <Modal.Body>
                <VoteDetail voteId={voteId} meetingId={meetingId} />
            </Modal.Body>
            {children}
        </Modal>
    );
};
