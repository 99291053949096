import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    faArrowUpRightFromSquare,
    faCalendar,
    faCreditCard,
    faListTimeline,
    faTimes,
    faWarning,
} from '@fortawesome/pro-regular-svg-icons';
import { i18n, MessageDescriptor } from '@lingui/core';
import { msg, t, Trans } from '@lingui/macro';
import { addDays, differenceInDays } from 'date-fns';
import { ActivityLog } from '@wedo/db';
import { Button, Card, DatePicker, EmptyState, Popover, Select, Skeleton } from '@wedo/design-system';
import { formatDate, stopPropagation } from '@wedo/utils';
import { useSessionUser } from 'App/store/usersStore';
import { AuditLog } from 'Pages/settings/auditTrail/AuditLog';
import { Can } from 'Shared/components/Can';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { trpc } from 'Shared/trpc';
import { ActivityLogObject } from 'Shared/types/activityLog';
import { UserRole } from 'Shared/types/user';
import { getPlanType } from 'Shared/utils/chargebee';
import { Permission } from 'Shared/utils/rbac';

export const FilterByModule: Array<{ id: ActivityLogObject; title: MessageDescriptor }> = [
    { id: ActivityLogObject.CHECKLIST, title: msg`Checklist` },
    { id: ActivityLogObject.MAILING, title: msg`Mailing` },
    { id: ActivityLogObject.MEETING, title: msg`Meeting` },
    { id: ActivityLogObject.NETWORK, title: msg`Network` },
    { id: ActivityLogObject.ORGANIZATION, title: msg`Organisation` },
    { id: ActivityLogObject.TEAM, title: msg`Team` },
    { id: ActivityLogObject.USER, title: msg`User` },
    { id: ActivityLogObject.WORKSPACE, title: msg`Workspace` },
] as const;

const ModuleToEventKeys: Partial<Record<ActivityLogObject, Array<string>>> = {
    [ActivityLogObject.CHECKLIST]: [
        'created_checklist',
        'deleted_checklist',
        'restored_checklist',
        'archived_checklist',
        'unarchived_checklist',
        'changed_checklist_template_privacy',
        'archived_checklist_template',
        'unarchived_checklist_template',
        'deleted_checklist_template',
        'restored_checklist_template',
        'added_member_to_checklist_template',
        'removed_member_from_checklist_template',
        'promoted_checklist_template_member',
        'created_checklist_template',
    ],
    [ActivityLogObject.MAILING]: ['invite_user_sent'],
    [ActivityLogObject.MEETING]: [
        'added_meeting',
        'created_meeting',
        'deleted_meeting',
        'restored_meeting',
        'meeting_locked',
        'meeting_unlocked',
        'added_meeting_user',
        'added_meeting_role',
        'updated_meeting_role',
        'deleted_meeting_role',
        'changed_meeting_settings',
    ],
    [ActivityLogObject.NETWORK]: ['enforced_two_factor_authentication', 'disabled_enforced_two_factor_authentication'],
    [ActivityLogObject.ORGANIZATION]: ['created_subscription', 'updated_subscription'],
    [ActivityLogObject.TEAM]: [
        'created_team',
        'deleted_team',
        'added_team_member',
        'removed_team_member',
        'promoted_team_member',
        'revoked_team_member',
    ],
    [ActivityLogObject.USER]: [
        'added_user',
        'deleted_user',
        'user_profile_update',
        'updated_user',
        'removed_user_photo',
        'changed_user_photo',
    ],
    [ActivityLogObject.WORKSPACE]: [
        'changed_tag_settings',
        'added_member_to_tag',
        'removed_member_from_tag',
        'promoted_tag_member',
        'revoked_tag_member',
        'created_tag',
        'duplicated_tag',
        'archived_tag',
        'unarchived_tag',
        'deleted_tag',
        'restored_tag',
    ],
};

const AllEventKeys = Object.values(ModuleToEventKeys).flat();

export const convertWorkspaceEventKey = (key: string | number) => String(key).replace('tag', 'workspace');

type AuditTrailInfiniteScrollPageProps = InfiniteScrollPageProps & {
    startDate: string;
    endDate: string;
    eventKeys: string[];
};

const AuditTrailInfiniteScrollPage: FC<AuditTrailInfiniteScrollPageProps> = ({
    offset,
    limit,
    updateInfiniteScroll,
    startDate,
    endDate,
    eventKeys,
}) => {
    const {
        isLoading,
        mutateAsync: getAuditTrailLogs,
        data: auditLogs = [],
    } = trpc.activity.listAuditTrail.useMutation();

    useEffect(() => {
        void getAuditTrailLogs({ startDate, endDate, eventKeys, limit, offset }).then(updateInfiniteScroll);
    }, [startDate, endDate, limit, offset, eventKeys]);

    if (isLoading) {
        return (
            <div className="mb-2 flex flex-col gap-2">
                <Skeleton count={5} className="h-10" />
            </div>
        );
    }

    return (
        <>
            {auditLogs.map((log: ActivityLog) => (
                <AuditLog auditLog={log} key={log.id} />
            ))}
        </>
    );
};

export const AuditTrailSettingsPage = () => {
    const currentUser = useSessionUser();
    const { data: subscription, isLoading: isLoadingSubscription } = trpc.subscription.get.useQuery();

    const [modules, setModules] = useState<ActivityLogObject[]>([]);
    const [eventKeys, setEventKeys] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const dateRangeText = useMemo(() => {
        if (startDate == null && endDate == null) {
            return t`Filter by date range`;
        }
        if (endDate == null) {
            return `${formatDate(startDate, 'PP', i18n)} - ...`;
        }
        if (startDate == null) {
            return `... - ${formatDate(endDate, 'PP', i18n)}`;
        }
        return `${formatDate(startDate, 'PP', i18n)} - ${formatDate(endDate, 'PP', i18n)}`;
    }, [startDate, endDate]);

    const computedModules = modules.length === 0 ? FilterByModule.map(({ id }) => id) : modules;

    const computedEventKeys =
        eventKeys.length === 0
            ? modules.length === 0
                ? AllEventKeys
                : modules.map((module) => ModuleToEventKeys[module]).flat()
            : eventKeys;

    const clearDateRange = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    };

    const handleStartDateChange = (date: Date) => {
        if (date > new Date(endDate)) {
            const dateDifference = differenceInDays(new Date(endDate), new Date(startDate)) + 1;
            setEndDate(addDays(new Date(endDate), dateDifference).toISOString());
        }
        setStartDate(date.toISOString());
    };

    if (isLoadingSubscription) {
        return (
            <Card>
                <Card.Header title={t`Audit trail`} />

                <Card.Body>
                    <div className="mb-2 flex flex-col gap-2">
                        <Skeleton count={5} className="h-10" />
                    </div>
                </Card.Body>
            </Card>
        );
    }

    // this page should only be available to ADMIN users with enterprise subscription
    if (currentUser.role !== UserRole.ADMIN) {
        return (
            <Card>
                <Card.Header title={t`Audit trail`} />
                <Card.Body>
                    <EmptyState icon={faWarning} className="py-10">
                        <EmptyState.Text>
                            <Trans>You do not have access to this page</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                </Card.Body>
            </Card>
        );
    }

    if (getPlanType(subscription) !== 'enterprise') {
        return (
            <Card>
                <Card.Header title={t`Audit trail`} />
                <Card.Body>
                    <EmptyState icon={faCreditCard} className="py-10">
                        <EmptyState.Text>
                            <div className="flex flex-col gap-4 items-center">
                                <Trans>
                                    You need to upgrade to the enterprise subscription plan to access this feature
                                </Trans>
                                <Button href="/settings/billing" color="primary" icon={faArrowUpRightFromSquare}>
                                    <Trans>Go to billing settings</Trans>
                                </Button>
                            </div>
                        </EmptyState.Text>
                    </EmptyState>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Can showNoAccess permission={Permission.ManageNetwork}>
            <Card>
                <Card.Header title={t`Audit trail`} />

                <Card.Body>
                    <div className="flex gap-2 items-center justify-start flex-wrap">
                        <Select
                            isClearable
                            multiple
                            placeholder={t`Filter by module`}
                            value={modules}
                            onChange={(e: ActivityLogObject[]) => setModules(e)}
                            wrapperClassName="w-full md:max-w-[20rem]"
                            customRenderSelected={(value) =>
                                value.map((e) => i18n._(FilterByModule.find(({ id }) => id === e).title)).join(', ')
                            }
                        >
                            {FilterByModule.map(({ id, title }) => (
                                <Select.Option key={id} value={id}>
                                    {i18n._(title)}
                                </Select.Option>
                            ))}
                        </Select>

                        <Select
                            isClearable
                            multiple
                            placeholder={t`Filter by key`}
                            value={eventKeys}
                            onChange={(e: string[]) => setEventKeys(e)}
                            wrapperClassName="w-full md:max-w-[15rem]"
                            customRenderSelected={(value) => value.map(convertWorkspaceEventKey).join(', ')}
                        >
                            {computedModules.map((module) => (
                                <Select.OptionGroup
                                    key={module}
                                    label={i18n._(FilterByModule.find(({ id }) => id === module).title)}
                                    className="!text-gray-600 !text-base"
                                >
                                    {ModuleToEventKeys[module].map((eventKey) => (
                                        <Select.Option key={eventKey} value={eventKey}>
                                            {convertWorkspaceEventKey(eventKey)}
                                        </Select.Option>
                                    ))}
                                </Select.OptionGroup>
                            ))}
                        </Select>

                        <Popover
                            text={
                                <div className="flex items-center gap-1">
                                    <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                                    <div>{dateRangeText}</div>
                                    {(startDate != null || endDate != null) && (
                                        <Button
                                            onClick={stopPropagation(clearDateRange)}
                                            icon={faTimes}
                                            variant="text"
                                            size="xs"
                                            shape="circle"
                                        />
                                    )}
                                </div>
                            }
                        >
                            <div className="p-2 bg-white flex items-start gap-4 flex-col lg:flex-row">
                                <DatePicker
                                    date={startDate == null ? new Date() : new Date(startDate)}
                                    onChange={handleStartDateChange}
                                />
                                <DatePicker
                                    date={endDate == null ? new Date() : new Date(endDate)}
                                    onChange={(date) => setEndDate(date.toISOString())}
                                    minDate={startDate == null ? undefined : new Date(startDate)}
                                />
                            </div>
                        </Popover>
                    </div>

                    <InfiniteScroll
                        page={AuditTrailInfiniteScrollPage}
                        props={{ startDate, endDate, eventKeys: computedEventKeys }}
                        emptyPage={
                            <EmptyState className="mt-16" icon={faListTimeline}>
                                <EmptyState.Text>
                                    <Trans>No audit logs found</Trans>
                                </EmptyState.Text>
                            </EmptyState>
                        }
                        className="flex flex-col gap-2 my-6"
                        size={20}
                    />
                </Card.Body>
            </Card>
        </Can>
    );
};
