import React from 'react';
import { useParams } from 'react-router-dom';
import { faCircleDashed } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { EmptyState, Skeleton } from '@wedo/design-system';
import { parseSort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { ChecklistsPageSearchParams } from 'Pages/ChecklistsPage/ChecklistsPage';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { ChecklistGridCard } from 'Shared/components/checklist/ChecklistGridCard';
import { useGetChecklistsQuery } from 'Shared/services/checklist';
import { useGetChecklistsByTemplateQuery } from 'Shared/services/template';

const ChecklistsInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { templateId, workspaceId } = useParams();
    const [{ search, status, sort }] = useSearchParams(ChecklistsPageSearchParams);

    const params = {
        id: workspaceId ?? templateId,
        search,
        archived: status === 'archived' ? true : status === 'open' ? false : undefined,
        deleted: status === 'deleted' ? true : status === 'open' ? false : undefined,
        ...parseSort(sort),
        offset,
        limit,
    };

    const checklists = useGetChecklistsQuery(params, { skip: !workspaceId });
    const templateChecklists = useGetChecklistsByTemplateQuery(params, { skip: !templateId });
    const { data, isLoading, isError, refetch } = workspaceId != null ? checklists : templateChecklists;

    updateInfiniteScroll(data?.data);

    if (isLoading) {
        return <Skeleton count={5} className={'h-72'} />;
    }
    if (isError) {
        return <RetryComponent className="col-span-full" retryFunction={refetch} />;
    }

    return (
        <>
            {data.data.map((checklist) => (
                <ChecklistGridCard key={checklist.id} checklist={checklist} />
            ))}
        </>
    );
};

export const ChecklistsGridView = () => {
    const [{ search }] = useSearchParams(ChecklistsPageSearchParams);

    return (
        <InfiniteScroll
            className="scrollbar-light grid auto-rows-min grid-cols-1 gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(22rem,1fr))]"
            page={ChecklistsInfiniteScrollPage}
            emptyPage={
                <div>
                    <EmptyState size="lg" icon={faCircleDashed} className="col-span-full">
                        <EmptyState.Text>
                            {isEmpty(search) ? <Trans>No checklists</Trans> : <Trans>No checklists found</Trans>}
                        </EmptyState.Text>
                    </EmptyState>
                </div>
            }
            size={20}
        />
    );
};
