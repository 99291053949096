import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faBan, faFolderOpen } from '@fortawesome/pro-regular-svg-icons';
import { faFolder, faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Tag, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { stopPropagationOnMouseEvents } from '@wedo/utils';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useSessionUser } from 'App/store/usersStore';
import { SubmissionsSectionDropdown } from 'Pages/meeting/components/TableOfContents/SubmissionsSectionDropdown';
import { SubmissionsSection } from 'Pages/meeting/components/TableOfContents/utils';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { EditSectionRoleModal } from '../EditMeetingAccessModal/EditSectionRoleModal';
import { rowNumberClasses, rowTitleClasses } from '../Topic';
import { SectionMenu } from './SectionMenu';
import { TocElementInput } from './TocElement';

type SectionNumberProps = {
    hideSectionNumbering: boolean;
    displayId: string;
    marginRight?: string;
    className?: string;
};

export const SectionNumber = ({ hideSectionNumbering, displayId, className }: SectionNumberProps) => {
    return <div className={clsx(hideSectionNumbering && 'hidden', className)}>{displayId}</div>;
};

export const tocSectionIconClasses = (selected: boolean) => {
    return ['flex h-6 w-6 items-center justify-center text-gray-500', selected && 'text-white'];
};

type tocSectionClassesProps = {
    isDragging?: boolean;
    isError?: boolean;
    isSelectable?: boolean;
    isSelected?: boolean;
};

export const tocSectionClasses = ({
    isSelectable,
    isDragging = false,
    isError = false,
    isSelected = false,
}: tocSectionClassesProps) => {
    return [
        'select-none border border-gray-300 bg-gray-100 min-h-[30px] p-1 rounded-md flex items-start gap-2 flex-grow shadow-sm text-sm text-gray-900 border hover:bg-gray-200',
        isDragging && 'cursor-grabbing border-dashed border-blue-900 bg-blue-400 text-white hover:cursor-grabbing',
        isError && 'bg-red-200',
        isSelectable ? 'cursor-pointer' : isSelectable !== undefined && 'cursor-auto !text-gray-600',
        isSelected && 'bg-gray-500 hover:bg-gray-500 cursor-pointer text-white',
    ];
};

type TableOfContentsSectionProps = {
    displayId: string;
    isOpen: boolean;
    isError?: boolean;
    isDragging?: boolean;
    isSelected?: boolean;
    canAddAfter: boolean;
    section: MeetingSection;
    onChange: (id: Id, changes: Partial<MeetingSection>) => void;
    onDelete: (item: MeetingSection) => void;
    onAddTopic: (item: MeetingSection) => void;
    onAddSectionInside: (item: MeetingSection) => void;
    onAddSectionAfter: (item: MeetingSection) => void;
    onClick: (id: Id) => void;
    lastAddedTocSection?: Id;
    handleSectionRef: (sectionId: Id) => (element: HTMLDivElement) => void;
};

export const TableOfContentsSection = ({
    displayId,
    isOpen,
    isError,
    isDragging,
    isSelected,
    section,
    onChange,
    onDelete,
    onAddTopic,
    canAddAfter,
    onAddSectionInside,
    onAddSectionAfter,
    onClick,
    lastAddedTocSection,
    handleSectionRef,
}: TableOfContentsSectionProps) => {
    const currentUser = useSessionUser();

    const { meeting } = useMeetingContext();
    const { open: openModal } = useModal();

    const [isEditing, setIsEditing] = useState(false);
    const [currentTitleInputValue, setCurrentTitleInputValue] = useState(section?.title);

    const { hasPermission: currentUserCanManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const isTopicSubmissionsSection = section.id === SubmissionsSection;

    const handleMenuClick = (key: string) => {
        switch (key) {
            case 'delete':
                onDelete(section);
                break;
            case 'edit':
                setIsEditing(true);
                break;
            case 'editSectionRights':
                openModal(EditSectionRoleModal, { section });
                break;
            case 'addTopic':
                onAddTopic(section);
                break;
            case 'addSectionInside':
                onAddSectionInside(section);
                break;
            case 'addSectionAfter':
                onAddSectionAfter(section);
                break;
            default:
                break;
        }
    };

    const handleEditComplete = () => {
        onChange(section.id, { title: currentTitleInputValue });
        setIsEditing(false);
    };

    useEffect(() => {
        if (section?.title.length === 0 && lastAddedTocSection === section.id) {
            setIsEditing(true);
        }
    }, [lastAddedTocSection]);

    return (
        <>
            {section?.can_access ? (
                <div
                    ref={handleSectionRef(section.id)}
                    id={`toc-section-${section.id}`}
                    className={clsx(tocSectionClasses({ isDragging, isError, isSelected }))}
                    onClick={() => onClick(section?.id)}
                >
                    <div className={clsx(tocSectionIconClasses(isSelected))}>
                        <FontAwesomeIcon
                            className={clsx(isOpen && '-mr-[0.1rem]')}
                            icon={
                                isOpen
                                    ? isTopicSubmissionsSection
                                        ? faChevronDown
                                        : faFolderOpen
                                    : isTopicSubmissionsSection
                                      ? faChevronRight
                                      : faFolder
                            }
                        />
                    </div>
                    {!meeting?.settings?.hide_section_numbering && !isTopicSubmissionsSection && (
                        <div className={rowNumberClasses}>{displayId}</div>
                    )}
                    {!isEditing ? (
                        <div className={clsx(rowTitleClasses, isTopicSubmissionsSection && 'font-semibold')}>
                            {section?.title}
                        </div>
                    ) : (
                        <TocElementInput
                            label={t`Section name`}
                            initialValue={section?.title}
                            currentValue={currentTitleInputValue}
                            setCurrentValue={setCurrentTitleInputValue}
                            onComplete={handleEditComplete}
                        />
                    )}
                    {(section?.can_manage || section?.can_manage_topic) &&
                        isTopicSubmissionsSection &&
                        currentUserCanManageMeeting && (
                            <div className={'flex gap-2 items-center'} {...stopPropagationOnMouseEvents}>
                                {section.nb_topics > 0 && (
                                    <div>
                                        <Tag className={'font-mono'} dark color="green" size="xs">
                                            {section.nb_topics}
                                        </Tag>
                                    </div>
                                )}
                                <div>
                                    <SubmissionsSectionDropdown />
                                </div>
                            </div>
                        )}

                    {(section?.can_manage || section?.can_manage_topic) && !isTopicSubmissionsSection && (
                        <div {...stopPropagationOnMouseEvents}>
                            <SectionMenu
                                section={section}
                                size="xs"
                                sectionIdentifier={
                                    !meeting?.settings?.hide_section_numbering ? section?.display_id : section?.title
                                }
                                eventHandler={handleMenuClick}
                                canAddAfter={canAddAfter}
                                canManage={section?.can_manage}
                                canManageTopic={section?.can_manage_topic}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={clsx(tocSectionClasses({}), 'cursor-pointer bg-gray-50 text-gray-500')}>
                    <div className={clsx(tocSectionIconClasses(false), 'text-gray-400')}>
                        <FontAwesomeIcon icon={faFolder} />
                    </div>
                    {!meeting?.settings?.hide_section_numbering && <div className={rowNumberClasses}>{displayId}</div>}
                    <div className={clsx(rowTitleClasses, 'italic')}>
                        <Trans>You do not have access</Trans>
                    </div>
                    <div
                        style={{
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faBan}
                            className="text-grey-500"
                            title={t`You do not have access to this section`}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
