import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from '@wedo/design-system';
import { useAppDispatch } from 'App/store';
import { selectSelectedVoteId, selectSelectedVoteMeetingId, voteSelected } from 'Pages/meeting/MeetingViewSlice';
import { VoteModal } from 'Pages/meeting/components/Vote/VoteModal/VoteModal';

export const useSelectedVoteModal = () => {
    const dispatch = useAppDispatch();

    const { open } = useModal();

    const selectedVoteId = useSelector(selectSelectedVoteId);
    const selectedVoteMeetingId = useSelector(selectSelectedVoteMeetingId);

    useEffect(() => {
        if (selectedVoteId != null && selectedVoteMeetingId != null) {
            open(VoteModal, {
                voteId: selectedVoteId,
                meetingId: selectedVoteMeetingId,
                onBeforeClose: async () => {
                    dispatch(voteSelected({ voteId: null }));
                    return true;
                },
            });
        }
    }, [selectedVoteId, selectedVoteMeetingId]);
};
