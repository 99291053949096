import React, { FC, MutableRefObject, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Button, CollapsiblePaneHandle, ContextModalProps, FormatDate, Modal, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { setDiff } from '@wedo/utils/set';
import { getUser } from 'App/store/usersStore';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { BulkEditChangeTableRow } from 'Pages/TasksPage/components/BulkTasksEditPane/BulkEditConfirmationModal/BulkEditChangeTableRow';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { useHasUserMadeChanges } from 'Pages/TasksPage/components/BulkTasksEditPane/useHasUserMadeChanges';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { TaskPriorityIcon } from 'Shared/components/task/TaskPriority/TaskPriorityIcon';
import { UserData } from 'Shared/components/user/UserData';
import { UserTag } from 'Shared/components/user/UserTag';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { trpc } from 'Shared/trpc';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { taskPriority } from 'Shared/utils/task';

type BulkEditTasksConfirmationModalProps = {
    panelRef: MutableRefObject<CollapsiblePaneHandle>;
    assignee: User;
    priority: Task['priority'];
    plannedDate: string;
    dueDate: string;
    relativePlannedDate: number;
    relativeDueDate: number;
    sectionId: Id;
    workspaceIds: Array<Id>;
    watcherIds: Array<Id>;
} & ContextModalProps;

export const BulkEditTasksConfirmationModal: FC<BulkEditTasksConfirmationModalProps> = ({
    panelRef,
    selectedTasks,
    assignee,
    priority,
    plannedDate,
    dueDate,
    relativePlannedDate,
    relativeDueDate,
    sectionId,
    workspaceIds,
    watcherIds,
    ...modalProps
}) => {
    const {
        commonWorkspaceIds,
        commonWatcherIds,
        handleChooseCommonAssignee,
        handleChooseCommonPriority,
        handleChooseCommonStartDate,
        handleChooseCommonDueDate,
        handleChooseCommonRelativePlannedDate,
        handleChooseCommonRelativeDueDate,
        handleChooseCommonSection,
        handleChooseCommonWorkspaceIds,
        handleChooseCommonWatchers,
    } = useBulkTasksEditPane(selectedTasks);

    const { workspaceId, templateId, checklistId } = useParams<TasksPageParams>();
    const { sectionIdToSections } = useTaskSections({ workspaceId, templateId, checklistId });

    const { data: workspaces = [] } = trpc.workspace.list.useQuery({});
    const {
        hasUserChangedAssignee,
        hasUserChangedPriority,
        hasUserChangedPlannedDate,
        hasUserChangedDueDate,
        hasUserChangedRelativePlannedDate,
        hasUserChangedRelativeDueDate,
        hasUserChangedSectionId,
        hasUserChangedAssigneeStrict,
        hasUserChangedPriorityStrict,
        hasUserChangedPlannedDateStrict,
        hasUserChangedDueDateStrict,
        hasUserChangedRelativePlannedDateStrict,
        hasUserChangedRelativeDueDateStrict,
        hasUserChangedSectionIdStrict,
        hasUserChangedWorkspaceIds,
        hasUserChangedWatchers,
    } = useHasUserMadeChanges({
        selectedTasks,
        assignee,
        priority,
        dueDate,
        plannedDate,
        relativePlannedDate,
        relativeDueDate,
        sectionId,
        workspaceIds,
        watcherIds,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const section = sectionIdToSections.get(sectionId ?? EmptyString);
    const workspacesToBeAdded = setDiff(new Set<Id>(workspaceIds), new Set(commonWorkspaceIds));

    const workspacesToBeRemoved = setDiff(new Set(commonWorkspaceIds), new Set(workspaceIds));
    const watchersToBeAdded = setDiff(new Set<Id>(watcherIds), new Set(commonWatcherIds));
    const watchersToBeRemoved = setDiff(new Set(commonWatcherIds), new Set(watcherIds));

    const handleBulkUpdate = async () => {
        setIsLoading(true);
        await Promise.all([
            hasUserChangedAssigneeStrict && handleChooseCommonAssignee(assignee),
            hasUserChangedPriorityStrict && handleChooseCommonPriority(priority),
            hasUserChangedPlannedDateStrict &&
                handleChooseCommonStartDate(plannedDate === null ? null : new Date(plannedDate)),
            hasUserChangedDueDateStrict && handleChooseCommonDueDate(dueDate === null ? null : new Date(dueDate)),
            hasUserChangedRelativePlannedDateStrict && handleChooseCommonRelativePlannedDate(relativePlannedDate),
            hasUserChangedRelativeDueDateStrict && handleChooseCommonRelativeDueDate(relativeDueDate),
            hasUserChangedSectionIdStrict && handleChooseCommonSection(sectionId),
            hasUserChangedWorkspaceIds && handleChooseCommonWorkspaceIds(workspaceIds),
            hasUserChangedWatchers && handleChooseCommonWatchers(watcherIds),
        ]);
        void modalProps.close();
        panelRef?.current?.close();
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Confirm changes`} />

            <Modal.Body>
                <div className="mb-4">
                    <Trans>
                        You're about to make changes to <span className="font-semibold">{selectedTasks.length}</span>{' '}
                        tasks
                    </Trans>
                </div>
                <Table>
                    <Table.Head>
                        <Table.HeadCell>
                            <Trans>Field</Trans>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Trans>Action</Trans>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Trans>New value</Trans>
                        </Table.HeadCell>
                    </Table.Head>

                    <Table.Body>
                        {hasUserChangedAssignee && (
                            <BulkEditChangeTableRow
                                fieldName={t`Assignee`}
                                changeType={assignee === null ? 'clear' : 'edit'}
                            >
                                {assignee === null ? ' -- ' : <UserData user={assignee} hideUserRole />}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedPriority && (
                            <BulkEditChangeTableRow
                                fieldName={t`Priority`}
                                changeType={priority === 0 ? 'clear' : 'edit'}
                            >
                                {priority === 0 ? (
                                    <span className="flex items-center gap-2">
                                        <TaskPriorityIcon priority={0} size="sm" />
                                        {taskPriority[0].label}
                                    </span>
                                ) : (
                                    <span className="flex items-center gap-2">
                                        <TaskPriorityIcon priority={priority} size="sm" />
                                        {taskPriority[priority as Task['priority']].label}
                                    </span>
                                )}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedSectionId && (
                            <BulkEditChangeTableRow
                                fieldName={t`Section`}
                                changeType={sectionId === null ? 'clear' : 'edit'}
                            >
                                {sectionId === null ? (
                                    <span className="flex items-center gap-2">
                                        <Trans>No section</Trans>
                                    </span>
                                ) : (
                                    <span className="flex items-center gap-2">{section?.name}</span>
                                )}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedPlannedDate && (
                            <BulkEditChangeTableRow
                                fieldName={t`Start date`}
                                changeType={plannedDate === null ? 'clear' : 'edit'}
                            >
                                {plannedDate === null ? '--' : <FormatDate date={new Date(plannedDate)} format="PPP" />}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedDueDate && (
                            <BulkEditChangeTableRow
                                fieldName={t`Due date`}
                                changeType={dueDate === null ? 'clear' : 'edit'}
                            >
                                {dueDate === null ? '--' : <FormatDate date={new Date(dueDate)} format="PPP" />}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedRelativePlannedDate && (
                            <BulkEditChangeTableRow fieldName={t`Relative start date`} changeType="edit">
                                {relativePlannedDate > 0 && t`${relativePlannedDate} days after reference`}
                                {relativePlannedDate < 0 && t`${Math.abs(relativePlannedDate)} days before reference`}
                                {relativePlannedDate === 0 && t`At reference date`}
                                {relativePlannedDate === null && t`No date`}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedRelativeDueDate && (
                            <BulkEditChangeTableRow fieldName={t`Relative due date`} changeType="edit">
                                {relativeDueDate > 0 && t`${relativeDueDate} days after reference`}
                                {relativeDueDate < 0 && t`${Math.abs(relativeDueDate)} days before reference`}
                                {relativeDueDate === 0 && t`At reference date`}
                                {relativeDueDate === null && t`No date`}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWorkspaceIds && workspacesToBeAdded.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Workspaces to be added`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...workspacesToBeAdded.keys()].map((workspaceId) => (
                                        <WorkspaceTag
                                            key={workspaceId}
                                            workspace={(workspaces as Workspace[]).find(({ id }) => id === workspaceId)}
                                        />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWorkspaceIds && workspacesToBeRemoved.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Workspaces to be removed`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...workspacesToBeRemoved.keys()].map((workspaceId) => (
                                        <WorkspaceTag
                                            key={workspaceId}
                                            workspace={(workspaces as Workspace[]).find(({ id }) => id === workspaceId)}
                                        />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWatchers && watchersToBeAdded.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Watchers to be added`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...watchersToBeAdded.keys()].map((watcherId) => (
                                        <UserTag key={watcherId} user={getUser(watcherId)} />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWatchers && watchersToBeRemoved.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Watchers to be removed`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...watchersToBeRemoved.keys()].map((watcherId) => (
                                        <UserTag key={watcherId} user={getUser(watcherId)} />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}
                    </Table.Body>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button color="primary" onClick={handleBulkUpdate} loading={isLoading}>
                    <Trans>Confirm changes</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
