import { FC, useCallback, useState } from 'react';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { Plural, t, Trans } from '@lingui/macro';
import { Layer, ResponsiveLine } from '@nivo/line';
import { format, fromUnixTime } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';
import { Card, colors, EmptyState, FormatDate, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useEvent } from '@wedo/utils/hooks/useEvent';
import { useWorkspaceHistoryChart } from 'Pages/workspace/info/charts/useWorkspaceHistoryChart';

type LineLayerProps = {
    points: Array<{
        data: {
            x: number;
            y: number;
        };
    }>;
    xScale: (x: number) => number;
    yScale: (y: number) => number;
};

const LineLayer: FC<LineLayerProps> = ({ points, xScale, yScale }) => {
    if (points?.length === 0) {
        return null;
    }

    const y1 = points[0].data.y;
    const x1 = points[0].data.x;
    const x2 = points[points.length - 1].data.x;

    return (
        <svg viewBox={`${xScale(x1)} ${yScale(0)} ${xScale(x2)} ${yScale(points[0].data.y)}`}>
            <line
                x1={xScale(x1)}
                y1={yScale(y1)}
                x2={xScale(x2)}
                y2={yScale(0)}
                className="stroke-gray-400"
                strokeDasharray="5, 5"
            />
        </svg>
    );
};

export const WorkspaceBurndownChart: FC<{
    workspaceId: Id;
}> = ({ workspaceId }) => {
    const { xAxisLabels, burndownLineData: lineData, isLoading } = useWorkspaceHistoryChart(workspaceId);
    const [windowResizing, setWindowResizing] = useState(false);

    const clearWindowResizing = useDebouncedCallback(() => {
        setWindowResizing(false);
    }, 100);

    const handleResize = useCallback(() => {
        setWindowResizing(true);
        clearWindowResizing();
    }, []);

    useEvent('resize', handleResize);

    if (isLoading || windowResizing) {
        return <Skeleton className="h-80" />;
    }

    if (lineData[0]?.data?.length === 0) {
        return (
            <Card>
                <Card.Header title={t`Burndown chart`} />
                <Card.Body>
                    <EmptyState icon={faChartLine}>
                        <EmptyState.Text>
                            <Trans>No tasks created in this period</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card>
            <Card.Header title={t`Burndown chart`} />
            <Card.Body>
                <div className="h-80">
                    <ResponsiveLine
                        useMesh
                        data={lineData}
                        enablePoints={(lineData[0]?.data?.length ?? 0) < 10}
                        enableGridX={false}
                        margin={{ top: 30, right: 30, bottom: 45, left: 35 }}
                        curve="monotoneX"
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 'auto',
                            stacked: true,
                            reverse: false,
                        }}
                        colors={[colors.orange[600]]}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            tickValues: xAxisLabels,
                            format: (unixEpoch) => format(fromUnixTime(unixEpoch), 'd MMM yy'),
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        tooltip={({ point }) => {
                            const tasks = point.data.y as number;
                            return (
                                <div className="flex flex-col bg-white p-2 shadow-md">
                                    <FormatDate
                                        format="PPP"
                                        date={fromUnixTime(Number(point.data.x))}
                                        className="text-xs font-semibold"
                                    />
                                    <span className="text-xs">
                                        <Plural
                                            value={tasks}
                                            one={'1 task remaining'}
                                            other={`${tasks} tasks remaining`}
                                        />
                                    </span>
                                </div>
                            );
                        }}
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -20,
                                translateY: -35,
                                itemsSpacing: 30,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'square',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        layers={[
                            'grid',
                            'markers',
                            'axes',
                            'areas',
                            'lines',
                            'points',
                            'slices',
                            'mesh',
                            'legends',
                            LineLayer as Layer,
                        ]}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};
