import { useCallback } from 'react';
import { useNavigate as useRouterNavigate, NavigateOptions } from 'react-router-dom';
import { stringifySearchParams } from '../router';

export const useNavigate = () => {
    const navigate = useRouterNavigate();

    return useCallback(
        (
            to: string | { pathname?: string; searchParams?: Record<string, string | string[]> },
            options?: NavigateOptions
        ) => {
            navigate(
                typeof to === 'string' ? to : { pathname: to.pathname, search: stringifySearchParams(to.searchParams) },
                options
            );
        },
        [navigate]
    );
};
