import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Alert, Button, Checkbox, ContextModalProps, Form, Modal, Select, Steps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { useSet } from '@wedo/utils/hooks/useSet';
import { BlockTypesPicker } from 'Pages/meeting/components/BlockTypesPicker';
import { SectionNumber } from 'Pages/meeting/components/TableOfContents/TocSection';
import { MeetingPicker } from 'Shared/components/meeting/MeetingPicker';
import { SelectableTopicTree } from 'Shared/components/meeting/topicView/SelectableTopicTree';
import { useDefaultImportBlocks } from 'Shared/components/meeting/topicView/TopicImportBlocksModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { buildGetMeetingParameters, useGetCurrentMeetingQuery, useGetMeetingQuery } from 'Shared/services/meeting';
import { useCopyTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc } from 'Shared/trpc';
import { Meeting, MeetingPermission, MeetingStatus } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { levelFromDisplayId } from 'Shared/utils/meeting';

type CopyMeetingTopicsModalProps = {
    topicId?: Id;
    meetingId?: Id;
    afterClose?: () => void;
} & PropsWithChildren &
    ContextModalProps;

export const CopyMeetingTopicsModal = ({
    meetingId,
    topicId,
    afterClose,
    ...modalProps
}: CopyMeetingTopicsModalProps): ReactElement => {
    const { meeting } = useMeeting(meetingId);
    const defaultImportBlocks = useDefaultImportBlocks(meeting);

    const [isSuccess, setIsSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<Id>(undefined);
    const [selectedMeetingId, setSelectedMeetingId] = useState<Id>();
    const [selectedSectionId, setSelectedSectionId] = useState<Id>();
    const [copyFutureSections, setCopyFutureSections] = useState(true);

    const { data: selectedMeeting } = useGetMeetingQuery(buildGetMeetingParameters(selectedMeetingId));
    const [copyTopics, { isLoading: isCopyLoading }] = useCopyTopicsMutation();
    const navigate = useNavigate();
    const [
        selectedTopics,
        { add: addTopic, remove: removeTopic, toggle: toggleTopic, reset: resetTopics, has: hasTopic },
    ] = useSet<Id>(new Set());
    const [
        selectedSections,
        { add: addSection, has: hasSection, remove: removeSection, toggle: toggleSection, reset: resetSections },
    ] = useSet<Id>(new Set());

    const [selectedBlocks, setSelectedBlocks] = useState(defaultImportBlocks);

    const { data: meetingsByTagId = [] } = trpc.meeting.list.useQuery({
        workspaceId:
            selectedWorkspaceId === '-1' ? null : selectedWorkspaceId == null ? undefined : selectedWorkspaceId,
        permissions: [MeetingPermission.EDIT_TOPIC_CONTENT],
        statuses: [
            MeetingStatus.ONGOING,
            MeetingStatus.PREPARATION,
            MeetingStatus.AGENDA_READY,
            MeetingStatus.FINISHED,
        ],
    }) as { data: Meeting[] };

    const { data: selectedMeetingSections } = trpc.meetingSection.listByMeetingId.useQuery(selectedMeetingId, {
        enabled: selectedMeetingId != null,
        select: camelToSnake,
    });

    const { data: topics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const { data: sections } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const [filteredTopics, setFilteredTopics] = useState<MeetingTopic[]>([]);
    const [filteredSections, setFilteredSections] = useState<MeetingSection[]>([]);

    const { data: currentMeetingOfSelectedWorkspace } = useGetCurrentMeetingQuery(
        { tagId: selectedWorkspaceId },
        { skip: !selectedWorkspaceId }
    );

    const reset = () => {
        setCurrentStep(1);
        setSelectedWorkspaceId(null);
        setSelectedMeetingId(null);
        setSelectedSectionId(null);
        setIsSuccess(false);
        setError(null);
        void modalProps.close();
    };

    useEffect(() => {
        if (topicId) {
            resetTopics();
            addTopic(topicId);
        }
    }, [topicId]);

    useEffect(() => {
        setFilteredTopics(topics || []);
    }, [topics]);
    useEffect(() => {
        setFilteredSections(sections || []);
    }, [sections]);

    useEffect(() => {
        if (currentMeetingOfSelectedWorkspace?.id) {
            // setSelectedMeetingId(currentMeetingOfSelectedWorkspace.id);
            setSelectedSectionId('-1');
        }
    }, [currentMeetingOfSelectedWorkspace?.id]);

    const handleConfirm = async () => {
        try {
            const res = await copyTopics({
                meetingId: meetingId,
                destinationMeetingId: selectedMeetingId,
                sourceTopicIds: [...selectedTopics],
                sourceSectionIds: [...selectedSections],
                destinationSectionId: selectedSectionId,
                blocksToCopy: selectedBlocks,
                copyFutureSections: copyFutureSections && selectedSections?.size > 0,
            });
            if (res?.error?.data?.errors?.length > 0) {
                setIsSuccess(false);
                if (
                    res?.error?.data?.errors[0].message === 'Cannot manage meeting' ||
                    res?.error?.data?.errors[0].message === 'You are not member of this tag' ||
                    res?.error?.data?.errors[0].message.startsWith('You cannot copy')
                ) {
                    setError(t`You don't have enough permissions to copy topics into this meeting.`);
                } else {
                    setError(t`An unknown error occurred, please try again later.`);
                }
            } else {
                setIsSuccess(true);
                setError(null);
            }
        } catch (error) {
            setIsSuccess(false);
            setError(t`An unknown error occurred, please try again later.`);
        }
    };

    const handleWorkspaceChange = (value: Id) => {
        setSelectedWorkspaceId(value);
        setSelectedMeetingId(null);
        setSelectedSectionId(null);
    };

    const handleMeetingChange = (value: Id) => {
        if (value) {
            setSelectedMeetingId(value);
            setSelectedSectionId('-1');
        }
    };

    const handleSectionChange = (value: Id) => setSelectedSectionId(value);

    const handleOnClose = () => reset();

    const getSections = () =>
        selectedMeetingSections?.map((section: MeetingSection) => (
            <Select.Option key={section.id} value={section.id}>
                <div
                    style={{
                        fontSize: `${Math.max(14 - levelFromDisplayId(section.display_id) * 1.2, 10)}px`,
                        fontWeight: Math.max(700 - levelFromDisplayId(section.display_id) * 100, 500),
                    }}
                >
                    <div className={'flex gap-1'}>
                        <SectionNumber
                            displayId={section.display_id}
                            hideSectionNumbering={selectedMeeting?.settings?.hide_section_numbering}
                        />
                        {section.title}
                    </div>
                </div>
            </Select.Option>
        ));

    const navigateAndCloseModal = (selectedMeetingId: Id) => {
        navigate(`/meetings/${selectedMeetingId}`);
        void modalProps.close();
    };

    return (
        <Modal {...modalProps} size={'lg'} afterClose={afterClose}>
            <Modal.Header title={t`Copy to...`} />
            <Steps className={'!rounded-none'} currentIndex={currentStep}>
                <Steps.Step>{t`Choose topics to copy`}</Steps.Step>
                <Steps.Step>{t`Choose destination`}</Steps.Step>
            </Steps>
            <Modal.Body>
                {currentStep === 1 ? (
                    <SelectableTopicTree
                        meetingId={meeting?.id}
                        selectedTopicsSet={selectedTopics}
                        selectedSectionsSet={selectedSections}
                        selectedTopicsSetActions={{
                            add: addTopic,
                            has: hasTopic,
                            remove: removeTopic,
                            toggle: toggleTopic,
                            reset: resetTopics,
                        }}
                        selectedSectionsSetActions={{
                            add: addSection,
                            has: hasSection,
                            remove: removeSection,
                            toggle: toggleSection,
                            reset: resetSections,
                        }}
                        topics={filteredTopics}
                        sections={filteredSections}
                        onSearch={(searchString) => {
                            setFilteredSections(
                                (sections || []).filter(
                                    (s: MeetingSection) =>
                                        (s.display_id + ' ' + s.title)
                                            .toLowerCase()
                                            .indexOf(searchString.toLowerCase()) > -1
                                )
                            );
                            setFilteredTopics(
                                (topics || []).filter(
                                    (t: MeetingTopic) =>
                                        (t.display_id + ' ' + t.title)
                                            .toLowerCase()
                                            .indexOf(searchString.toLowerCase()) > -1
                                )
                            );
                        }}
                    />
                ) : (
                    <div>
                        {!isSuccess ? (
                            <>
                                <Form name="export" layout="vertical">
                                    <Form.Item>
                                        <BlockTypesPicker
                                            meeting={meeting}
                                            selectedBlocks={selectedBlocks}
                                            onChange={setSelectedBlocks}
                                        />
                                    </Form.Item>

                                    <Form.Item cols={4} label={<b>1. {t`Choose meeting`}</b>}>
                                        <MeetingPicker
                                            meetings={meetingsByTagId}
                                            selectedMeetingId={selectedMeetingId}
                                            onSelectMeetingId={handleMeetingChange}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        cols={2}
                                        style={{ marginTop: '$2' }}
                                        label={<b>{t`Filter by workspace`}</b>}
                                    >
                                        <WorkspaceSelect
                                            value={String(selectedWorkspaceId)}
                                            onChange={(v: string) => handleWorkspaceChange(v)}
                                            nullSelectionLabel={t`All workspaces`}
                                        />
                                    </Form.Item>
                                    <Form.Item cols={4} label={<b>2. {t`Choose destination section`}</b>}>
                                        <Select
                                            value={selectedSectionId}
                                            placeholder={t`Select a section`}
                                            disabled={!selectedMeetingId}
                                            onChange={handleSectionChange}
                                            customRenderSelected={(selected: string) =>
                                                selectedMeetingSections?.find((s: MeetingSection) => s.id === selected)
                                                    ?.title || t`insert at the end`
                                            }
                                        >
                                            {getSections()}
                                            <Select.Option value={'-1'}>({t`insert at the end`})</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    {selectedSections?.size > 0 && (
                                        <Form.Item>
                                            <Checkbox
                                                onChange={(e) => setCopyFutureSections(e.target.checked)}
                                                checked={copyFutureSections}
                                            >
                                                <Trans>Also create copied sections in future meetings</Trans>
                                            </Checkbox>
                                        </Form.Item>
                                    )}
                                </Form>
                            </>
                        ) : (
                            <Alert type={'success'}>
                                <Trans>Topics have been successfully copied to the destination section.</Trans>
                            </Alert>
                        )}
                        {error && <Alert type={'danger'}>{error}</Alert>}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button key="close" onClick={handleOnClose}>
                    <Trans>Close</Trans>
                </Button>
                {currentStep === 1 ? (
                    <Button
                        color={'primary'}
                        onClick={() => setCurrentStep(2)}
                        disabled={selectedTopics.size === 0 && selectedSections.size === 0}
                    >
                        <Trans>Next</Trans>
                    </Button>
                ) : !isSuccess ? (
                    <>
                        <Button key="previous" onClick={() => setCurrentStep(1)}>
                            <Trans>Previous</Trans>
                        </Button>
                        <Button
                            disabled={!selectedSectionId || !selectedMeetingId}
                            color={'primary'}
                            loading={isCopyLoading}
                            onClick={handleConfirm}
                        >
                            <Trans>Confirm</Trans>
                        </Button>
                    </>
                ) : (
                    <Button color={'primary'} onClick={() => navigateAndCloseModal(selectedMeetingId)}>
                        <Trans>Go to destination meeting</Trans>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
