import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Card, Skeleton } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { ChangePasswordCard } from 'Pages/settings/security/components/ChangePasswordCard';
import { SessionsCard } from 'Pages/settings/security/components/SessionsCard';
import { TotpSwitch } from 'Pages/settings/security/components/TotpSwitch';

export const SecuritySettingsPage = () => {
    const { currentUser, isLoading } = useCurrentUserContext();
    const isNetworkSaml = currentUser?.userNetwork?.network?.isSaml === true && currentUser.userAccount.saml === true;

    return (
        <div className="flex flex-col gap-8 pb-10">
            {isLoading && <Skeleton className="h-28" />}

            {!isLoading && isNetworkSaml && (
                <Card>
                    <Card.Header title={t`External authentication`} />
                    <Card.Body>
                        <Trans>
                            Your authentication is not handled by WEDO, please ask your IT department if you want to
                            change your authentication information.
                        </Trans>
                    </Card.Body>
                </Card>
            )}

            {!isLoading && !isNetworkSaml && (
                <>
                    <ChangePasswordCard />
                    <TotpSwitch />
                </>
            )}

            <SessionsCard />
        </div>
    );
};
