import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { t } from '@lingui/macro';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { isEqual, isString } from 'lodash-es';
import { EmptyString } from '@wedo/utils';
import store from 'App/store';
import { ApiError } from 'Shared/types/apiError';
import { RtkQueryResponseObject } from 'Shared/types/rtkQuery';

type ServerError = {
    data: {
        errors: {
            message: string;
            type: string;
            property?: {
                details: {
                    path: string[];
                    type: string;
                }[];
            };
        }[];
    };
    status: number;
};

type RtkError = { error: string };

const isServerError = (error: unknown): error is ServerError => {
    if ((error as ServerError).data === undefined) {
        return false;
    }

    if (!Array.isArray((error as ServerError).data?.errors)) {
        return false;
    }

    if ((error as ServerError).data.errors.length === 0) {
        return false;
    }

    return typeof (error as ServerError).data.errors[0].message === typeof '';
};

const isRtkError = (error: unknown): error is RtkError => typeof (error as RtkError).error === typeof '';

const isError = (error: unknown): error is Error => typeof (error as Error).message === typeof '';

// Retrieve the message from an error returned by RTK Query
export const errorMessage = (error: ServerError | RtkError | Error | string | unknown): string => {
    if (isServerError(error)) {
        return error.data.errors.map((error) => error.message).join('\n');
    }
    if (isRtkError(error)) {
        return error.error;
    }
    if (isError(error)) {
        return error.message;
    }
    if (typeof error === typeof '') {
        return error as string;
    }
    if (typeof (error as Error)?.message === typeof '') {
        return (error as Error).message;
    }
    // eslint-disable-next-line no-console
    console.error(error);
    return 'Unexpected error';
};

export const errorCode = (error: ServerError | unknown): string => {
    if (error?.data == null && error?.status != null) {
        return String(error.status);
    }

    if (isServerError(error)) {
        return error.data.errors[0].type;
    }

    if (typeof (error as { status: string }).status === typeof '') {
        return (error as { status: string }).status;
    }

    return '';
};

export const errorProperty = (error: ServerError | unknown): string => {
    if (isServerError(error)) {
        if (isString(error.data.errors[0].property)) {
            return error.data.errors[0].property;
        }
        return error.data.errors[0].property?.details[0].path[0] || '';
    }

    return '';
};

export const errorDetailType = (error: ServerError | unknown): string => {
    if (isServerError(error)) {
        return error.data.errors[0].property?.details?.[0].type ?? error.data.errors[0].type ?? EmptyString;
    }

    return '';
};

export const FETCH_ERROR_RESPONSE = Object.freeze({
    status: 'FETCH_ERROR',
    error: 'TypeError: Failed to fetch',
});

export const FETCH_ERROR_RESPONSE_MESSAGE = Object.freeze({
    title: t`No internet connection`,
    message: t`Please check your connection with the internet and try again.`,
});

export const fetchErrorOccurred = (response: RtkQueryResponseObject): boolean =>
    isEqual(response, FETCH_ERROR_RESPONSE);

export const isFetchError = (error: ApiError): boolean => error.code === 'FETCH_ERROR';

export const isSuccess = async <T extends MutationDefinition<unknown, BaseQueryFn, string, unknown>>(
    mutation: MutationActionCreatorResult<T>
): Promise<boolean> =>
    mutation
        .unwrap()
        .then(() => true)
        .catch(() => false);

/** Query an rtkQuery endpoint without using a hook */
export const directQuery = async (endpoint: { initiate: any; select: any; name: string }, params: Object) => {
    const result = endpoint.select(params)(store.getState());
    if (result?.data != null) {
        // Resource is already in cache
        return result?.data;
    }

    document.dispatchEvent(new CustomEvent('loadingBar', { detail: 'start' }));
    const requestResult = await store.dispatch(endpoint.initiate(params));
    document.dispatchEvent(new CustomEvent('loadingBar', { detail: 'end' }));

    if (requestResult.error != null || requestResult.isError) {
        throw requestResult.error;
    }

    return requestResult.data;
};

export const waitForQueryToBeFulfilled = (endpoint) => {
    return new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
            const { status } = endpoint(store.getState());
            if (status === 'fulfilled') {
                unsubscribe();
                resolve();
            } else if (status !== 'pending') {
                unsubscribe();
                reject();
            }
        });
    });
};
