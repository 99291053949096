import { FC, useEffect, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { colorPickerColorsMap, ColorPickerPopover, colors, getColorId, Input, Label, Tabs } from '@wedo/design-system';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { Workspace } from 'Shared/types/workspace';
import { LogoSection } from './LogoSection';

type GeneralPanelProps = {
    workspace: Workspace;
    name: string;
    onNameChange: (name: string) => void;
    color: string;
    onColorChange: (color: string) => void;
    description: string;
    onDescriptionChange: (value: string) => void;
    handleSave: () => void;
};

export const GeneralPanel: FC<GeneralPanelProps> = ({
    workspace,
    name,
    onNameChange,
    color,
    onColorChange,
    description,
    onDescriptionChange,
    handleSave,
}) => {
    const isNameEmpty = isEmpty(name?.trim());
    const colorId = getColorId(color);

    const nameRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if ('current' in nameRef) {
            setTimeout(() => nameRef.current?.focus(), 100);
        }
    }, [nameRef]);

    return (
        <Tabs.Panel>
            <div className="flex flex-wrap gap-4">
                <div className="flex-1">
                    <Label>
                        <Trans>Name</Trans>
                    </Label>

                    <Input
                        ref={nameRef}
                        value={name}
                        onChange={(e) => onNameChange(e.target.value)}
                        onPressEnter={handleSave}
                        className="w-full"
                        status={isNameEmpty ? 'error' : 'default'}
                        statusText={isNameEmpty && t`Workspace must have a name`}
                    />
                </div>
                <div>
                    <Label>
                        <Trans>Color</Trans>
                    </Label>
                    <ColorPickerPopover
                        icon={null}
                        color={colors[colorId][500]}
                        onChange={onColorChange}
                        showSelectedColor={true}
                        canDeselectColor={false}
                        classNameButton="w-full min-w-[120px]"
                    >
                        {colorPickerColorsMap[colorId].name}
                    </ColorPickerPopover>
                </div>
            </div>

            <Label className="mt-6">
                <Trans>Description</Trans>
            </Label>

            <DescriptionEditor html={description} onChange={onDescriptionChange} />
            
            <Label className="mb-2 mt-8">
                <Trans>Workspace logo</Trans>
            </Label>

            <LogoSection workspace={workspace} />
        </Tabs.Panel>
    );
};
