import { FloatingPortal } from '@floating-ui/react';
import { useContext } from 'react';
import { NotificationContext } from './NotificationContext';
import { NotificationItem } from './NotificationItem';

export const NotificationsContainer = () => {
    const { notifications, remove } = useContext(NotificationContext);
    return (
        <FloatingPortal>
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {notifications.map((notification) => (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            onClose={() => remove(notification.id)}
                        />
                    ))}
                </div>
            </div>
        </FloatingPortal>
    );
};
