import { FC } from 'react';
import clsx from 'clsx';
import { Button, Tooltip } from '@wedo/design-system';
import { Task } from 'Shared/types/task';
import { taskPriority } from 'Shared/utils/task';

const priorities = Object.values(taskPriority);

type TaskPriorityPickerProps = {
    onChange: (priority: Task['priority']) => void;
    hideTooltip?: boolean;
};

export const TaskPriorityPicker: FC<TaskPriorityPickerProps> = ({ onChange, hideTooltip = false }) => {
    return (
        <>
            {priorities.map((p, index) => (
                <Tooltip content={!hideTooltip && p.label} key={index}>
                    <Button
                        className={'!w-auto p-2 hover:bg-gray-100'}
                        iconClassName={clsx('!w-5 !h-5', p.className)}
                        icon={p.icon}
                        variant="ghost"
                        onClick={() => onChange(p.value as unknown as Task['priority'])}
                        aria-label={p.label.props.message}
                    />
                </Tooltip>
            ))}
        </>
    );
};
