import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faArrowRight, faCog } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceTabs } from 'Shared/components/workspace/utils';
import { Tab, WorkspaceHistory, WorkspaceTab } from 'Shared/types/workspace';

type TabNameProps = {
    tab: Tab;
};

const TabName: FC<TabNameProps> = ({ tab }) => (
    <span className="italic">{WorkspaceTabs.find(({ type }) => type === tab).label}</span>
);

const enum TabState {
    Added,
    Removed,
    Inactive,
    Active,
}

const classes: Record<TabState, string> = {
    [TabState.Added]: 'text-blue-600',
    [TabState.Removed]: 'text-red-500 line-through',
    [TabState.Active]: 'text-gray-600',
    [TabState.Inactive]: 'text-gray-400',
};

const additionSubtractionSign: Record<TabState, '+' | '-' | ''> = {
    [TabState.Added]: '+',
    [TabState.Removed]: '',
    [TabState.Active]: '',
    [TabState.Inactive]: '',
};

const getActiveTabs = (list: WorkspaceTab[]) => list?.filter(({ active }) => active).map(({ type }) => type);

export const ChangeWorkspaceSettingsTimelineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    const newTabs = new Set(getActiveTabs(activity.new_value.settings.tabs));
    const oldTabs = new Set(
        getActiveTabs(activity.old_value.settings?.tabs) ??
            (['info', 'meetings', 'tasks', 'files', 'checklists'] as Tab[])
    );

    const newDefaultTab = activity.new_value.settings.default_tab;
    const oldDefaultTab = activity.old_value.settings?.default_tab ?? 'tasks';

    const updatedDefaultTab = !isEqual(oldDefaultTab, newDefaultTab);
    const updatedTabs = !isEqual(oldTabs, newTabs);

    const getTabState = (tab: Tab): TabState => {
        if (!oldTabs.has(tab) && newTabs.has(tab)) {
            return TabState.Added;
        }
        if (oldTabs.has(tab) && !newTabs.has(tab)) {
            return TabState.Removed;
        }
        if (oldTabs.has(tab) && newTabs.has(tab)) {
            return TabState.Active;
        }
        return TabState.Inactive;
    };

    const TabsDiff = () => (
        <div className="mt-1 flex gap-2">
            {WorkspaceTabs.map((tab) => (
                <span key={tab.type} className={classes[getTabState(tab.type)]}>
                    {additionSubtractionSign[getTabState(tab.type)]}
                    {WorkspaceTabs.find(({ type }) => type === tab.type).label}
                </span>
            ))}
        </div>
    );

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faCog}>
            <p>
                {updatedDefaultTab && !updatedTabs && (
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> updated the default tab from{' '}
                        <TabName tab={oldDefaultTab} /> <FontAwesomeIcon icon={faArrowRight} />{' '}
                        <TabName tab={newDefaultTab} />
                    </Trans>
                )}
                {!updatedDefaultTab && updatedTabs && (
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> updated visible tabs <TabsDiff />
                    </Trans>
                )}
                {updatedDefaultTab && updatedTabs && (
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> updated the default tab from{' '}
                        <TabName tab={oldDefaultTab} /> <FontAwesomeIcon icon={faArrowRight} />{' '}
                        <TabName tab={newDefaultTab} /> and updated visible tabs <TabsDiff />
                    </Trans>
                )}
            </p>
        </Timeline.Item>
    );
};
