import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import {
    Button,
    Card,
    SavedSuccessNotification,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { UseStateSetter } from '@wedo/types';
import { DesktopNotificationsSection } from 'Pages/settings/notifications/components/DesktopNotifications/DesktopNotificationsSection';
import { EmailNotificationsSection } from 'Pages/settings/notifications/components/EmailNotificationsSection';
import { PeriodicReportsSection } from 'Pages/settings/notifications/components/PeriodicReportsSection';
import { useNotificationDays } from 'Pages/settings/notifications/hooks/useNotificationDays';
import { useNotificationHours } from 'Pages/settings/notifications/hooks/useNotificationHours';
import { usePeriodicSummary } from 'Pages/settings/notifications/hooks/usePeriodicSummary';
import { NotificationDayBadge, NotificationHourBadge } from 'Pages/settings/notifications/types/notification-badge';
import { UpdateNotificationSettingsPayload, useUpdateSettingsMutation } from 'Shared/services/settings';
import { isSuccess } from 'Shared/utils/rtkQuery';

export const NotificationsSettingsPage = () => {
    const { show } = useNotification();
    const { periodicSummarySaved, periodicSummary, setPeriodicSummary } = usePeriodicSummary();
    const { notificationDaysSaved, notificationDays, notificationDayBadges, setNotificationDayBadges } =
        useNotificationDays();
    const { notificationHoursSaved, notificationHours, notificationHourBadges, setNotificationHourBadges } =
        useNotificationHours();

    const [updateSettings, { isLoading: updateSettingsIsLoading }] = useUpdateSettingsMutation();

    const toggleBadge = (
        setValue: UseStateSetter<Array<NotificationDayBadge | NotificationHourBadge>>,
        index: number
    ): void => {
        setValue((badges) => {
            const updated = [...badges];
            updated[index].selected = !updated[index].selected;
            return updated;
        });
    };

    const toggleNotificationDayBadge = (index: number): void => toggleBadge(setNotificationDayBadges, index);

    const toggleNotificationHourBadge = (index: number): void => toggleBadge(setNotificationHourBadges, index);

    const userMadeChanges =
        !isEqual(notificationDays, notificationDaysSaved) ||
        !isEqual(notificationHours, notificationHoursSaved) ||
        !isEqual(periodicSummary, periodicSummarySaved);

    const updateSettingsPayload = useMemo<UpdateNotificationSettingsPayload>(
        () => ({
            notification_days: { days: notificationDays },
            notification_hours: { hours: notificationHours },
            periodic_summary: { frequency: periodicSummary },
        }),
        [periodicSummary, notificationDays, notificationHours]
    );

    const handleUpdateNotificationSettings = async () => {
        if (await isSuccess(updateSettings(updateSettingsPayload))) {
            show(SavedSuccessNotification);
        } else {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <>
            <Card>
                <Card.Header title={t`Email notifications`} />
                <Card.Body>
                    <EmailNotificationsSection
                        notificationDayBadges={notificationDayBadges}
                        toggleNotificationDayBadge={toggleNotificationDayBadge}
                        notificationHourBadges={notificationHourBadges}
                        toggleNotificationHourBadge={toggleNotificationHourBadge}
                    />

                    <PeriodicReportsSection value={periodicSummary} onClick={(item) => setPeriodicSummary(item)} />

                    <Button
                        onClick={handleUpdateNotificationSettings}
                        disabled={!userMadeChanges}
                        color="primary"
                        className="mt-6"
                        loading={updateSettingsIsLoading}
                    >
                        <Trans>Save changes</Trans>
                    </Button>
                </Card.Body>
            </Card>
            <Card className="mt-8">
                <Card.Header title={t`Desktop notifications`} />

                <Card.Body>
                    <DesktopNotificationsSection />
                </Card.Body>
            </Card>
        </>
    );
};
