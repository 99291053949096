import React, { useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Input,
    Modal,
    NoInternetErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useDeleteTeamMutation } from 'Shared/services/team';
import { ApiError } from 'Shared/types/apiError';
import { Team } from 'Shared/types/team';
import { isFetchError } from 'Shared/utils/rtkQuery';

type DeleteTeamModalProps = { team: Team } & ContextModalProps;

export const DeleteTeamModal: React.FC<DeleteTeamModalProps> = ({ team, ...modalProps }) => {
    const { show } = useNotification();

    const [deleteTeam, { isLoading }] = useDeleteTeamMutation();

    const teamNameInput = useRef<HTMLInputElement>();

    const [nameNotSameError, setNameNotSameError] = useState<boolean>(false);
    const [teamName, setTeamName] = useState<string>(EmptyString);

    const handleDeleteTeam = async () => {
        if (teamName.trim().toLowerCase() !== team.name.toLowerCase().trim()) {
            setNameNotSameError(true);
            return;
        }
        setNameNotSameError(false);
        const response = await deleteTeam({ teamId: team.id });
        if ('error' in response) {
            if (isFetchError(response.error as ApiError)) {
                show(NoInternetErrorNotification);
            }
        } else {
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} initialFocus={teamNameInput}>
            <Modal.Header title={t`Delete team ${team?.name}`} />

            <Modal.Body>
                <p className="text-gray-700">
                    <Trans>
                        Deleting the team will delete the workspaces, checklists and tasks associated with it. All team
                        members will no longer have access to any of the content.
                    </Trans>
                </p>

                <p className="mt-4 text-gray-700">
                    <Trans>To confirm, enter the team name and click on delete.</Trans>
                </p>

                <Input
                    ref={teamNameInput}
                    className="mt-4"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    status={nameNotSameError ? 'error' : 'default'}
                    statusText={nameNotSameError && t`The team name that you have entered doesn't match ${team.name}.`}
                    onPressEnter={handleDeleteTeam}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button color="danger" onClick={handleDeleteTeam} loading={isLoading}>
                    <Trans>Delete {team.name}</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
