import { ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { DEFAULT_MEETING_ROLES as MR } from 'Pages/meeting/components/RBAC/DefaultMeetingRoles';
import { MEETING_PERMISSIONS as MP } from 'Pages/meeting/components/RBAC/MeetingPermissions';
import { MEETING_STATUSES as MS } from 'Pages/meeting/components/RBAC/MeetingStatuses';

const ROLES = [MR.EDITOR, MR.PARTICIPANT, MR.READER, MR.NO_ACCESS];

export const PERMISSIONS: { code: string; name: ReactNode; description?: ReactNode }[] = [
    {
        code: MP.VIEW_MEETING_AND_AGENDA,
        name: <Trans>Can view the meeting and the agenda</Trans>,
    },
    {
        code: MP.VIEW_FILES,
        name: <Trans>Can view files</Trans>,
    },
    {
        code: MP.VIEW_TOPIC_CONTENT,
        name: <Trans>Can view topic content</Trans>,
    },
    {
        code: MP.CAN_VOTE,
        name: <Trans>Can vote</Trans>,
    },
    {
        code: MP.EDIT_TOPIC_CONTENT,
        name: <Trans>Can modify topic content</Trans>,
    },
    {
        code: MP.MANAGE_TOPIC,
        name: <Trans>Can manage topic</Trans>,
        description: <Trans>Can add, update, delete, postpone, revisit and address a topic</Trans>,
    },
    {
        code: MP.MANAGE_SECTIONS,
        name: <Trans>Can modify sections</Trans>,
    },
    {
        code: MP.MANAGE_MEETING,
        name: <Trans>Can modify the meeting settings</Trans>,
        description: <Trans>Can edit attendees, access rights, settings and the meeting status</Trans>,
    },
];

// First level: a meeting permission,
// Second level: an array of all other permissions that force it to being checked
// For instance, if you check "Can modify topic content" you must also have the "Can view topic content" access and the others above it
export const DEPENDENCIES = [];

DEPENDENCIES[MP.VIEW_MEETING_AND_AGENDA] = [
    MP.VIEW_FILES,
    MP.VIEW_TOPIC_CONTENT,
    MP.CAN_VOTE,
    MP.EDIT_TOPIC_CONTENT,
    MP.MANAGE_TOPIC,
    MP.MANAGE_SECTIONS,
    MP.MANAGE_MEETING,
];
DEPENDENCIES[MP.VIEW_TOPIC_CONTENT] = [
    MP.CAN_VOTE,
    MP.EDIT_TOPIC_CONTENT,
    MP.MANAGE_TOPIC,
    MP.MANAGE_SECTIONS,
    MP.MANAGE_MEETING,
];
DEPENDENCIES[MP.VIEW_FILES] = [MP.EDIT_TOPIC_CONTENT];
DEPENDENCIES[MP.EDIT_TOPIC_CONTENT] = [MP.MANAGE_TOPIC, MP.MANAGE_SECTIONS, MP.MANAGE_MEETING];
DEPENDENCIES[MP.MANAGE_TOPIC] = [MP.MANAGE_SECTIONS, MP.MANAGE_MEETING];
DEPENDENCIES[MP.MANAGE_SECTIONS] = [MP.MANAGE_MEETING];
DEPENDENCIES[MP.MANAGE_MEETING] = [];
DEPENDENCIES[MP.CAN_VOTE] = [];

// What options can't be changed by the user
// Applied to every step except "Locked"
export const RESTRICTIONS = {
    [MR.EDITOR]: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
        MP.MANAGE_MEETING,
    ],
    [MR.PARTICIPANT]: [MP.MANAGE_MEETING],
    [MR.READER]: [MP.EDIT_TOPIC_CONTENT, MP.MANAGE_TOPIC, MP.MANAGE_SECTIONS, MP.MANAGE_MEETING],
    [MR.NO_ACCESS]: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.VIEW_TOPIC_CONTENT,
        MP.CAN_VOTE,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
        MP.MANAGE_MEETING,
    ],
    customRole: [MP.MANAGE_MEETING],
};

// What options can't be changed by the user
// Applied only to the "Locked" step
export const LOCKED_RESTRICTIONS = {
    [MR.EDITOR]: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.CAN_VOTE,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
        MP.MANAGE_MEETING,
    ],
    [MR.PARTICIPANT]: [MP.EDIT_TOPIC_CONTENT, MP.CAN_VOTE, MP.MANAGE_TOPIC, MP.MANAGE_SECTIONS, MP.MANAGE_MEETING],
    [MR.READER]: [MP.EDIT_TOPIC_CONTENT, MP.CAN_VOTE, MP.MANAGE_TOPIC, MP.MANAGE_SECTIONS, MP.MANAGE_MEETING],
    [MR.NO_ACCESS]: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.CAN_VOTE,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
        MP.MANAGE_MEETING,
    ],
    customRole: [MP.EDIT_TOPIC_CONTENT, MP.CAN_VOTE, MP.MANAGE_TOPIC, MP.MANAGE_SECTIONS, MP.MANAGE_MEETING],
};

// Default permissions. Use for the new permissions (permission not saved in database)
export const FALLBACK_PERMISSIONS = {
    [MR.EDITOR]: {
        [MS.PREPARATION]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
            MP.MANAGE_MEETING,
        ],
        [MS.AGENDA_READY]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.CAN_VOTE,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
            MP.MANAGE_MEETING,
        ],
        [MS.ONGOING]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.CAN_VOTE,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
            MP.MANAGE_MEETING,
        ],
        [MS.FINISHED]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.CAN_VOTE,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
            MP.MANAGE_MEETING,
        ],
        [MS.LOCKED]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT, MP.MANAGE_MEETING],
    },
    [MR.PARTICIPANT]: {
        [MS.PREPARATION]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
        ],
        [MS.AGENDA_READY]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.CAN_VOTE,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
        ],
        [MS.ONGOING]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.CAN_VOTE,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
        ],
        [MS.FINISHED]: [
            MP.VIEW_MEETING_AND_AGENDA,
            MP.VIEW_FILES,
            MP.VIEW_TOPIC_CONTENT,
            MP.CAN_VOTE,
            MP.EDIT_TOPIC_CONTENT,
            MP.MANAGE_TOPIC,
            MP.MANAGE_SECTIONS,
        ],
        [MS.LOCKED]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT],
    },
    [MR.READER]: {
        [MS.PREPARATION]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT],
        [MS.AGENDA_READY]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT, MP.CAN_VOTE],
        [MS.ONGOING]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT, MP.CAN_VOTE],
        [MS.FINISHED]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT, MP.CAN_VOTE],
        [MS.LOCKED]: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT],
    },
    [MR.NO_ACCESS]: {
        [MS.PREPARATION]: [],
        [MS.AGENDA_READY]: [],
        [MS.ONGOING]: [],
        [MS.FINISHED]: [],
        [MS.LOCKED]: [],
    },
};

export const getFallbackPermission = (role: string, status: string): string[] => {
    role = ROLES.includes(role) ? role : MR.PARTICIPANT; // Custom role have participant fallback permissions

    return FALLBACK_PERMISSIONS[role][status];
};
