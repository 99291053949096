import React, { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Modal,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { TasksContextProvider, useTasksContext } from 'App/contexts/TasksContext';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { TaskDetail } from 'Shared/components/task/TaskDetail/TaskDetail';
import { useAddTaskMutation } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';

type TaskModalProps = { workspaceId?: Id; taskId?: Id } & ContextModalProps;

const ModalContent: FC<TaskModalProps> = ({ workspaceId, taskId: initialTaskId, children, ...modalProps }) => {
    const navigate = useNavigate();
    const { show: showNotification } = useNotification();
    const { confirm } = useConfirm();
    const { currentUser } = useCurrentUserContext();
    const { selectedTasks, setSelectedTasks } = useTasksContext();

    const [addTask] = useAddTaskMutation();
    const { mutateAsync: updateStatus, isLoading } = trpc.task.updateStatus.useMutation();
    const [newTaskId, setNewTaskId] = useState<Id>();

    const setSelectTaskStore = (id: Id) => setSelectedTasks([{ id, groupedId: null }]);
    const taskId = selectedTasks?.[0]?.id;

    useEffect(() => {
        // no taskId -> create an empty task
        if (initialTaskId == null) {
            addTask({ name: '', assignee: currentUser, watchers: [currentUser] }).then((res) => {
                if ('data' in res) {
                    setNewTaskId(res.data.id);
                    setSelectTaskStore(res.data.id);
                }
                if ('error' in res) {
                    showNotification(UnexpectedErrorNotification);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (initialTaskId != null) {
            setSelectTaskStore(initialTaskId);
        }
    }, [initialTaskId]);

    const handleCancel = async () => {
        await updateStatus({
            taskIds: [newTaskId],
            deleted: true,
            applyOn: ApplyOn.AllTasks,
        });
        void modalProps.close();
    };

    const handleSave = async () => {
        showNotification({
            type: 'success',
            title: (
                <Button variant="link" className="text-white" onClick={() => navigate(`/tasks/${newTaskId}`)}>
                    <Trans>Task created successfully</Trans>
                </Button>
            ),
        });
        void modalProps.close();
    };

    const handleBeforeClose = async (source: string) => {
        if (!taskId && source === 'backdrop-or-esc') {
            return confirm({
                type: 'danger',
                title: t`Discard changes`,
                content: <Trans>Do you want to remove this task?</Trans>,
                cancelText: t`Close`,
                confirmText: t`Delete`,
                onConfirm: handleCancel,
            });
        }
        return true;
    };

    if (taskId == null) {
        return null;
    }

    return (
        <Modal onBeforeClose={handleBeforeClose} {...modalProps}>
            <TaskDetail.Header hideTasksToExport taskId={taskId} />
            <Modal.Body className="!px-0 !py-0">
                <TaskDetail workspaceId={workspaceId} taskId={taskId.toString()} />
            </Modal.Body>
            <Modal.Footer>
                {initialTaskId != null ? (
                    <Button onClick={modalProps.close}>Close</Button>
                ) : (
                    <>
                        <Button color="danger" variant="outlined" loading={isLoading} onClick={handleCancel}>
                            Delete
                        </Button>
                        <Button color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};

export const TaskModal: FC<TaskModalProps> = (attributes) => {
    return (
        <TasksContextProvider params={{}}>
            <ModalContent {...attributes} />
        </TasksContextProvider>
    );
};
