import React, { FC, useContext, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { GlobalSaml } from 'Pages/SignInPage/components/GlobalSaml';
import { NetworkList } from 'Pages/SignInPage/components/NetworkList';
import { useSamlLogin } from 'Pages/SignInPage/hooks/useSamlLogin';
import { useLazyGetUserSamlInfoQuery } from 'Shared/services/user';
import { LocalStorage } from 'Shared/types/localStorage';
import { LocalLogin } from './components/LocalLogin';

export const SignInPage: FC = () => {
    const {
        loginState: { email, page },
        updateLoginState,
    } = useContext(LoginContext);

    const { goToSaml } = useSamlLogin();

    const [getUserSamlInfo, { data: { is_saml: isSaml } = { is_saml: false } }] = useLazyGetUserSamlInfoQuery();

    const debouncedGetSamlInfo = useDebouncedCallback(() => {
        if (email.trim() !== '') {
            getUserSamlInfo({ email });
        } else if (page === 'saml') {
            updateLoginState({ page: 'password' });
        }
    }, 500);

    useEffect(debouncedGetSamlInfo, [email]);

    useEffect(() => {
        if (isSaml) {
            updateLoginState({ page: 'saml' });
        } else if (page !== 'networkList') {
            updateLoginState({ page: 'password' });
        }
    }, [isSaml]);

    useEffect(() => {
        if (
            localStorage.getItem(LocalStorage.AuthToken) == null &&
            localStorage.getItem(LocalStorage.AuthenticationMethod) === 'saml'
        ) {
            goToSaml();
        }
    }, []);

    switch (page) {
        case 'password':
        case 'totp':
        case 'saml':
            return <LocalLogin />;
        case 'networkList':
            return <NetworkList />;
        case 'samlChoice':
            return <GlobalSaml />;
        default:
            return null;
    }
};
