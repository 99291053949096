import React, { FC } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { ButtonPosition, Dropdown, getIconFromName } from '@wedo/design-system';
import { Fields, fields, PreSetFilter } from 'Pages/TasksPage/components/utils';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';
import { FieldConditionFilter } from 'Shared/types/filter';
import { customFieldTypeToIcon } from 'Shared/utils/customFields';

type AddConditionDropdownProps = {
    value: FieldConditionFilter;
    onChange: (value: FieldConditionFilter) => void;
    icon?: IconDefinition;
    position?: ButtonPosition;
    hideLabel?: boolean;
};

export const AddConditionDropdown: FC<AddConditionDropdownProps> = ({
    value,
    onChange,
    icon,
    hideLabel = false,
    position = 'start',
}) => {
    const { customFields, customFieldGroups, allCustomFields } = useCurrentTasksFilter();

    const getCustomFieldFromId = (customFieldId: string) =>
        allCustomFields?.find((customField) => customField.id === customFieldId);

    const getCustomFieldIcon = (customFieldId: string) => {
        const customField = getCustomFieldFromId(customFieldId);
        return getIconFromName(customField?.icon) ?? customFieldTypeToIcon[customField?.type];
    };

    return (
        <Dropdown
            size="sm"
            icon={icon ?? Fields[value as PreSetFilter]?.icon ?? getCustomFieldIcon(value)}
            position={position}
            label={
                hideLabel
                    ? undefined
                    : Fields[value as PreSetFilter]?.name ?? (
                          <span className="truncate max-w-[3rem] md:max-w-[6rem]">
                              {allCustomFields?.find((customField) => customField.id === value)?.label}
                          </span>
                      )
            }
        >
            {fields.map((field) => (
                <Dropdown.Item key={field.value} onClick={() => onChange(field.value)} icon={field.icon}>
                    {field.label}
                </Dropdown.Item>
            ))}
            {customFields.length > 0 && (
                <Dropdown.DividerItem>
                    <div className="whitespace-nowrap">
                        <Trans>Custom fields</Trans>
                    </div>
                </Dropdown.DividerItem>
            )}

            {customFields.length > 0 &&
                customFields.map((customField) => (
                    <Dropdown.Item
                        icon={getCustomFieldIcon(customField.id)}
                        key={customField.id}
                        onClick={() => onChange(customField.id)}
                    >
                        <span className="truncate max-w-[15rem]">{customField.label}</span>
                    </Dropdown.Item>
                ))}

            {customFieldGroups?.length > 0 && (
                <>
                    {customFieldGroups.map((group) => (
                        <>
                            <Dropdown.DividerItem key={`group-${group.id}`}>
                                <div className="whitespace-nowrap">{group.label}</div>
                            </Dropdown.DividerItem>

                            {group.customFields?.map((customField) => (
                                <Dropdown.Item
                                    icon={getCustomFieldIcon(customField.id)}
                                    key={`${group.id}-${customField.id}`}
                                    onClick={() => onChange(customField.id)}
                                >
                                    <span className="truncate max-w-[15rem]">{customField.label}</span>
                                </Dropdown.Item>
                            ))}
                        </>
                    ))}
                </>
            )}
        </Dropdown>
    );
};
