import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { taskActivitiesTag } from 'Shared/services/task';
import { FormRecurrence } from 'Shared/types/formRecurrence';
import { Task } from 'Shared/types/task';
import { baseApi, configureTag } from './base';

const { tagType, tag } = configureTag('Task');

export const taskRecurrenceApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addTaskRecurrence: build.mutation<
                Task,
                {
                    taskId: Id;
                    recurrence: Partial<FormRecurrence>;
                }
            >({
                query: ({ taskId, recurrence }) => ({
                    url: `tasks/${taskId}/recurrence`,
                    body: recurrence,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [tag(taskId), taskActivitiesTag(taskId)];
                },
            }),
            updateTaskRecurrence: build.mutation<
                Task,
                {
                    taskId: Id;
                    recurrence: Partial<FormRecurrence>;
                }
            >({
                query: ({ taskId, recurrence }) => ({
                    url: `tasks/${taskId}/recurrence`,
                    body: recurrence,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [tag(taskId), taskActivitiesTag(taskId)];
                },
            }),
            removeTaskRecurrence: build.mutation<
                Task,
                {
                    taskId: Id;
                }
            >({
                query: ({ taskId }) => ({ url: `tasks/${taskId}/recurrence`, method: HttpMethod.Delete }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [tag(taskId), taskActivitiesTag(taskId)];
                },
            }),
        }),
    });

export const { useAddTaskRecurrenceMutation, useUpdateTaskRecurrenceMutation, useRemoveTaskRecurrenceMutation } =
    taskRecurrenceApi;
