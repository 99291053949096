import { FC, useCallback, useState } from 'react';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { Plural, t, Trans } from '@lingui/macro';
import { ResponsiveLine } from '@nivo/line';
import { format, fromUnixTime } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';
import { Card, colors, EmptyState, FormatDate, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useEvent } from '@wedo/utils/hooks/useEvent';
import { useWorkspaceHistoryChart } from 'Pages/workspace/info/charts/useWorkspaceHistoryChart';

export const WorkspaceBurnUpChart: FC<{ workspaceId: Id }> = ({ workspaceId }) => {
    const { xAxisLabels, burnUpLineData: lineData, isLoading } = useWorkspaceHistoryChart(workspaceId);
    const [windowResizing, setWindowResizing] = useState(false);

    const clearWindowResizing = useDebouncedCallback(() => {
        setWindowResizing(false);
    }, 100);

    const handleResize = useCallback(() => {
        setWindowResizing(true);
        clearWindowResizing();
    }, []);

    useEvent('resize', handleResize);

    if (isLoading || windowResizing) {
        return <Skeleton className="h-80" />;
    }

    if (lineData[0]?.data?.length === 0) {
        return (
            <Card>
                <Card.Header title={t`Burnup chart`} />
                <Card.Body>
                    <EmptyState icon={faChartLine}>
                        <EmptyState.Text>
                            <Trans>No tasks created or completed in this period</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card>
            <Card.Header title={t`Burnup chart`} />
            <Card.Body>
                <div className="h-80">
                    <ResponsiveLine
                        data={lineData}
                        enablePoints={(lineData[0]?.data?.length ?? 0) < 10}
                        enableGridX={false}
                        margin={{ top: 30, right: 30, bottom: 45, left: 45 }}
                        curve="monotoneX"
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false,
                        }}
                        colors={[colors.green[600], colors.blue[600]]}
                        tooltip={({ point }) => {
                            const tasks = point.data.y as number;
                            return (
                                <div className="flex flex-col bg-white p-2 shadow-md">
                                    <FormatDate
                                        format="PPP"
                                        date={fromUnixTime(Number(point.data.x))}
                                        className="text-xs font-semibold"
                                    />
                                    <span className="text-xs">
                                        {point.serieId === t`Completed tasks` && (
                                            <Plural
                                                value={tasks}
                                                one={'1 task completed'}
                                                other={`${tasks} tasks completed`}
                                            />
                                        )}
                                        {point.serieId === t`Created tasks` && (
                                            <Plural
                                                value={tasks}
                                                one={'1 task created'}
                                                other={`${tasks} tasks created`}
                                            />
                                        )}
                                    </span>
                                </div>
                            );
                        }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,
                            legendPosition: 'middle',
                            tickValues: xAxisLabels,
                            format: (unixEpoch) => format(fromUnixTime(unixEpoch), 'd MMM yy'),
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: -40,
                            legendPosition: 'middle',
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -20,
                                translateY: -35,
                                itemsSpacing: 30,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'square',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};
