import { i18n, MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { capitalize } from 'lodash-es';

export const allObjectType = msg({ id: 'webhook.objectType', message: 'All' });

export const allEventAction = msg({ id: 'webhook.eventAction', message: 'All' });

export const displayObjectAction = (text: string, nullValue: MessageDescriptor): string => {
    return text === 'null' || text === null ? i18n._(nullValue) : capitalize(text);
};
