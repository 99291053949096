import { PropsWithChildren, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Label, Modal, Select, useConfirm } from '@wedo/design-system';
import { useAppDispatch } from 'App/store';
import { signatureQuotaOptions } from 'Pages/settings/billing/utils/signatureQuotaOptions';
import { invalidateCurrentNetwork } from 'Shared/services/network';
import { trpc } from 'Shared/trpc';
import { isMeteredSignatureAddonId } from 'Shared/utils/chargebee';

export const ManageQuotaModal = ({
    currentQuota,
    children,
    ...modalProps
}: { currentQuota: number | 'no-quota' } & ContextModalProps & PropsWithChildren) => {
    const dispatch = useAppDispatch();
    const { data: subscription } = trpc.subscription.get.useQuery();
    const meteredSignatureAddon = subscription?.subscription_items.find((item) =>
        isMeteredSignatureAddonId(item.item_price_id)
    );
    const usedSignatures = meteredSignatureAddon?.metered_quantity ?? 0;
    const { confirm: showConfirm } = useConfirm();
    const { mutate: updateQuota } = trpc.signature.updateQuota.useMutation({
        onSuccess: () => {
            dispatch(invalidateCurrentNetwork());
            void modalProps.close();
        },
    });
    const [quota, setQuota] = useState<number | 'no-quota'>(currentQuota);

    const handleSave = async () => {
        let shouldUpdate = true;
        if (quota !== 'no-quota' && usedSignatures >= quota) {
            shouldUpdate = await showConfirm({
                title: t`Update quota`,
                type: 'primary',
                confirmText: t`Update`,
                content: t`If you update your signature quota to ${quota} you won't be able to use any more signatures until the next billing cycle.`,
            });
        }
        if (shouldUpdate) {
            updateQuota(quota);
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Manage quota`} />
            <Modal.Body>
                <div className="mb-2">
                    <Label>
                        <Trans>Signature quota</Trans>
                    </Label>
                    <Select
                        onChange={(value: string) => setQuota(value !== 'no-quota' ? Number(value) : value)}
                        value={quota.toString()}
                    >
                        {signatureQuotaOptions.map((option) => (
                            <Select.Option key={option} value={option.toString()}>
                                {option === 'no-quota' ? t`No quota` : option}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <Alert type={'info'} title={t`How it works`}>
                    <Trans>
                        By setting a quota, you can limit the number of AES and QES signatures users in your
                        organization can use during the active billing period. Only used signatures will be billed.
                    </Trans>
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                {currentQuota !== quota && (
                    <Button onClick={handleSave} color="primary">
                        <Trans>Save</Trans>
                    </Button>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
