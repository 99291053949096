import { Editor, Operation } from 'slate';
import { HistoryEditor } from 'slate-history';
import { Id } from '@wedo/types';
import { type SoftDeleteOperation as SoftDeleteOperationType } from '../types';

export const SkipSaveOperation = 'skipSave';

export const isSkipSaveOperation = (operation: Operation) => operation.type === SkipSaveOperation;

export const SaveOperation = 'save';

export const isSaveOperation = (operation: Operation) => operation.type === SaveOperation;

export const SoftDeleteOperation = 'softDelete';

export const isSoftDeleteOperation = (operation: Operation): operation is SoftDeleteOperationType =>
    operation.type === SoftDeleteOperation;

export const NoOpOperation = 'noOp';

export const isNoOpOperation = (operation: Operation) => operation.type === NoOpOperation;

export const RefreshBlocksOperation = 'refreshBlocks';
export const isRefreshBlocksOperation = (operation: Operation) => operation.type === RefreshBlocksOperation;

export const skipSave = (editor: Editor) => {
    HistoryEditor.withoutSaving(editor, () => {
        editor.apply({ type: SkipSaveOperation });
    });
};

export const forceSave = (editor: Editor) => {
    HistoryEditor.withoutSaving(editor, () => {
        editor.apply({ type: SaveOperation });
    });
};

export const softDelete = (editor: Editor, id: Id) => {
    HistoryEditor.withoutSaving(editor, () => {
        editor.apply({ type: SoftDeleteOperation, id });
    });
};

export const forceRefreshBlocks = (editor: Editor) => {
    HistoryEditor.withoutSaving(editor, () => {
        editor.apply({ type: RefreshBlocksOperation });
    });
};
