import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { forwardRef, useMemo, useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faCalendarDay, faClock, faGlobe, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { differenceInHours, endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import { Avatar, Button, Popover, Tag, Tooltip } from '@wedo/design-system';
import { getTimezoneLabel, getUserTimeZone } from '@wedo/utils';
import { AttendanceReadonly } from 'Pages/meeting/components/MeetingEditAttendees/AttendanceEditable';
import { MeetingHeaderLocation } from 'Pages/meeting/components/MeetingHeader/MeetingPopover';
import { formatMeetingDateTime } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingStatusTag } from 'Shared/components/meeting/MeetingStatusTag';
import { type Meeting } from './types';
import { durationInDays, daysSinceEpoch } from './utils';

export type TimelineMeetingHandle = {
    element: HTMLElement;
    meeting: Meeting;
};

type TimelineMeetingProps = {
    meetings: Array<Meeting>;
};

export const TimelineMeeting = forwardRef<TimelineMeetingHandle, TimelineMeetingProps>(({ meetings }, ref) => {
    const { i18n, _ } = useLingui();

    const [meeting, setMeeting] = useState(() => (meetings.length > 1 ? null : meetings[0]));

    const [start, duration] = useMemo(() => {
        if (meetings.length > 1) {
            const { minDate, maxDate } = meetings.reduce(
                (result, meeting) => {
                    if (result.minDate == null) {
                        result.minDate = meeting.start_at;
                    } else {
                        result.minDate = isBefore(new Date(meeting.start_at), new Date(result.minDate))
                            ? meeting.start_at
                            : result.minDate;
                    }
                    if (result.maxDate == null) {
                        result.maxDate = meeting.end_at;
                    } else {
                        result.maxDate = isAfter(new Date(meeting.end_at), new Date(result.maxDate))
                            ? meeting.end_at
                            : result.maxDate;
                    }
                    return result;
                },
                { minDate: null, maxDate: null }
            );
            return [
                daysSinceEpoch(minDate),
                Math.ceil(differenceInHours(endOfDay(new Date(maxDate)), startOfDay(new Date(minDate))) / 24),
            ];
        }
        const meeting = meetings[0];
        return [
            daysSinceEpoch(meeting.start_at),
            durationInDays(startOfDay(new Date(meeting.start_at)), endOfDay(new Date(meeting.end_at))),
        ];
    }, [meetings]);

    const handleRef = (element: HTMLElement) => {
        ref?.({ element, meeting });
    };

    const meetingDetail = useMemo(() => {
        if (meeting == null) {
            return null;
        }
        const userTimeZone = getTimezoneLabel(getUserTimeZone());
        const meetingTimeZone = getTimezoneLabel(meeting.start_at_time_zone);
        const meetingStartAt = formatMeetingDateTime(meeting, i18n, false, false);
        const userStartAt = formatMeetingDateTime(meeting, i18n);
        return (
            <>
                {meetings.length === 1 && (
                    <div className="flex flex-col px-2 py-3 border-b border-gray-200 gap-1">
                        <div className="px-2 text-xl font-medium">{meeting.title}</div>
                        <MeetingStatusTag meetingStatus={meeting.extendedStatus} />
                    </div>
                )}
                <div className="flex flex-col gap-3 py-3 pr-2">
                    {meetingStartAt === userStartAt ? (
                        <div className="flex px-2 items-center">
                            <FontAwesomeIcon icon={faClock} className="h-4 w-4 px-4 text-gray-600" />
                            <div>{formatMeetingDateTime(meeting, i18n)}</div>
                        </div>
                    ) : (
                        <>
                            <div className="flex px-2 items-center">
                                <FontAwesomeIcon icon={faClock} className="h-4 w-4 px-4 text-gray-600" />
                                <div>
                                    <div>{userStartAt}</div>
                                    <div className="text-xs text-gray-400">{userTimeZone}</div>
                                </div>
                            </div>
                            <div className="flex px-2 items-center">
                                <FontAwesomeIcon icon={faGlobe} className="h-4 w-4 px-4 text-gray-600" />
                                <div>
                                    <div>{meetingStartAt}</div>
                                    <div className="text-xs text-gray-400">{meetingTimeZone}</div>
                                </div>
                            </div>
                        </>
                    )}
                    {meeting.location != null && meeting.location !== '' && (
                        <div className="px-2">
                            <MeetingHeaderLocation location={meeting.location} />
                        </div>
                    )}
                </div>
                <div className="flex max-h-60 flex-col gap-1 overflow-y-auto py-3 pl-2 pr-4.5 border-t border-gray-200">
                    {meeting.meetingUsers
                        .filter(({ is_attendee }) => is_attendee)
                        .map((attendee) => (
                            <div key={attendee.id} className="flex items-center">
                                <Avatar
                                    img={attendee.user?.photo != null ? `/files/${attendee.user.photo}` : undefined}
                                    size="sm"
                                    wrapperClassName="px-2.5"
                                />
                                <div className="flex-1 truncate">
                                    {attendee.user?.full_name ?? attendee.user_data.external_full_name}
                                </div>
                                <div className="shrink-0">
                                    {attendee.attendance && <AttendanceReadonly attendance={attendee.attendance} />}
                                </div>
                            </div>
                        ))}
                </div>
                <div className="flex justify-center bg-gray-50 px-2 py-3 border-t border-gray-200">
                    <Link to={`/meetings/${meeting.id}`}>
                        <Button icon={faArrowUpRightFromSquare} size="sm" onClick={() => {}}>
                            <Trans>Go to meeting</Trans>
                        </Button>
                    </Link>
                </div>
            </>
        );
    }, [meetings, meeting, _]);

    const handleToggleMeeting = (meetingToOpen: Meeting) => () => {
        if (meeting == null || meeting !== meetingToOpen) {
            setMeeting(meetingToOpen);
        } else {
            setMeeting(null);
        }
    };

    useEffect(() => setMeeting(meetings[0]), [meetings]);

    return (
        <Popover
            variant="ghost"
            placement="bottom"
            className="flex w-full bg-gradient-blue text-white"
            wrapperClassName="!absolute h-full flex items-center justify-center transition-transform cursor-pointer"
            wrapperStyle={{
                transform: `translateX(calc((${start} - var(--start-day)) * var(--column-width)))`,
                minWidth: meetings.length > 1 ? `calc(${duration - 1} * var(--column-width) + 1.625rem)` : '1.625rem',
                width: `calc(${duration} * var(--column-width))`,
            }}
            text={
                <Tooltip
                    content={
                        meetings.length > 1 ? (
                            <ul className="list-disc pl-4">
                                {meetings.map((meeting) => (
                                    <li key={meeting.id}>{meeting.title}</li>
                                ))}
                            </ul>
                        ) : (
                            meetings[0].title
                        )
                    }
                    wrapperClassName="w-full"
                >
                    <>
                        <FontAwesomeIcon ref={handleRef} icon={faCalendarDay} className="h-3.5 w-3.5 p-1.5" />
                        {meetings.length > 1 && (
                            <Tag
                                dark
                                color="red"
                                className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2"
                                size="xs"
                            >
                                {meetings.length}
                            </Tag>
                        )}
                    </>
                </Tooltip>
            }
        >
            <div
                className={clsx(
                    'flex w-screen flex-col border border-gray-200 bg-white text-sm md:w-[24rem]',
                    meetings.length > 1 && 'divide-y divide-gray-200'
                )}
            >
                {meetings.length > 1
                    ? meetings.map((meetingToOpen) => (
                          <Fragment key={meetingToOpen.id}>
                              <button
                                  className="text-xl flex items-center gap-2 justify-between pl-2 pr-4"
                                  onClick={handleToggleMeeting(meetingToOpen)}
                              >
                                  <div className="flex flex-col py-3 gap-1 text-left">
                                      <div className="font-medium px-2">{meetingToOpen.title}</div>
                                      <MeetingStatusTag meetingStatus={meetingToOpen.extendedStatus} />
                                  </div>
                                  <FontAwesomeIcon
                                      className={clsx(
                                          'h-3 w-3 transition-transform',
                                          meeting === meetingToOpen && 'rotate-90'
                                      )}
                                      icon={faChevronRight}
                                  />
                              </button>
                              {meeting === meetingToOpen && meetingDetail}
                          </Fragment>
                      ))
                    : meetingDetail}
            </div>
        </Popover>
    );
});
