import { ComponentProps, forwardRef } from 'react';
import clsx from 'clsx';

const classes = {
    base: 'h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600',
    disabled: 'opacity-50 bg-gray-300 border-gray-500 cursor-not-allowed hover:bg-none',
};

export type RadioProps = {
    className?: string;
    disabled?: boolean;
} & Omit<ComponentProps<'input'>, 'type' | 'ref' | 'className'>;

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({ className, disabled = false, ...props }: RadioProps, ref) => {
        return (
            <input
                ref={ref}
                disabled={disabled}
                className={clsx(classes.base, disabled ? classes.disabled : 'cursor-pointer', className)}
                type="radio"
                {...props}
            />
        );
    }
);
Radio.displayName = 'Radio';
