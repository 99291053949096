import { FC, useContext, useId } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Checkbox, CodeInput, ItemGroup, Label } from '@wedo/design-system';
import { LoginContext } from 'Pages/SignInPage/LoginContext';

type TotpModalProps = {
    id: string;
    label: string;
    error: string;
    onValidate: () => void;
};

export const TotpInput: FC<TotpModalProps> = ({ id, label, error: error, onValidate }) => {
    const {
        loginState: { trustedDevice },
        updateLoginState,
    } = useContext(LoginContext);
    const inputId = useId();

    return (
        <div className="flex flex-col gap-4">
            <ItemGroup status="error" statusText={error}>
                <CodeInput
                    id={id}
                    label={label}
                    autofocus
                    onValidate={onValidate}
                    onChange={(code) => updateLoginState({ totp: code })}
                />
            </ItemGroup>
            <div className="flex items-center gap-2">
                <Checkbox
                    checked={trustedDevice}
                    onChange={(e) => updateLoginState({ trustedDevice: e.target.checked })}
                    id={inputId + 'browser'}
                />
                <Label inputType="inline" htmlFor={inputId + 'browser'}>
                    <Trans>Don't ask again on this browser</Trans>
                </Label>
            </div>
            <Link to="/lost-totp" className="text-sm font-medium text-blue-600 hover:text-blue-700">
                <Trans>An issue with two-factor authentication?</Trans>
            </Link>
        </div>
    );
};
