import React, { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';

export const meetingNotificationHandler: NotificationHandlerFn = (notification) => {
    let notificationObject: { icon: IconDefinition; text: ReactNode };
    const createdBy = <strong>{notification.created_by.full_name}</strong>;
    const topicName = <strong>{notification.activity_log?.new_value?.title}</strong>;
    const meetingName = <strong>{formatMeetingTitle(notification.meeting, i18n)}</strong>;
    switch (notification.event_key) {
        case 'accepted_meeting_topic':
            notificationObject = {
                icon: faCheck,
                text: (
                    <Trans>
                        {createdBy} accepted your {topicName} topic in {meetingName}
                    </Trans>
                ),
            };
            break;
        case 'rejected_meeting_topic':
            notificationObject = {
                icon: faCheck,
                text: (
                    <Trans>
                        {createdBy} rejected your {topicName} topic in {meetingName}
                    </Trans>
                ),
            };
            break;
        default:
            break;
    }
    return notificationObject;
};
