import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { useConfirm } from '@wedo/design-system';
import { ConfirmWithTextareaModal } from 'Pages/MyTopicsPage/components/ConfirmWithTextareaModal';
import { trpc, trpcUtils } from 'Shared/trpc';

export const useRejectTopicSubmission = (topicId: string, topicSubmissionId: string) => {
    const { confirm } = useConfirm();
    const { data: topic } = trpc.meetingTopic.get.useQuery(topicId, { select: camelToSnake });

    const { mutateAsync: rejectTopicSubmission } = trpc.meetingTopic.rejectSubmission.useMutation({
        onSuccess: () => {
            void trpcUtils().meetingTopic.getSubmissionForTopic.invalidate({ topicId });
            void trpcUtils().meeting.listTopicSubmissions.invalidate();
        },
    });

    const rejectSubmission = async () => {
        const shouldRejectSubmission = await confirm(
            {
                type: 'danger',
                title: t`Reject topic`,
                content: t`Are you sure you want to reject the topic ${topic?.title}?`,
                confirmText: t`Reject`,
                inputLabel: t`Reason`,
            },
            ConfirmWithTextareaModal
        );
        if (shouldRejectSubmission !== false) {
            await rejectTopicSubmission({ topicSubmissionId, reason: shouldRejectSubmission });
        }

        return shouldRejectSubmission !== false;
    };

    return { rejectSubmission };
};
