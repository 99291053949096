import { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Id } from '@wedo/types';
import { ColorTuple } from 'Shared/types/colorTuple';
import { Section } from 'Shared/types/section';
import { TaskOrder } from 'Shared/types/task';
import { Team } from 'Shared/types/team';
import { UsageStatistics } from 'Shared/types/usageStatistics';
import { UserGroup } from 'Shared/types/userGroup';
import { Status, WorkspaceStatus } from 'Shared/types/workspaceStatus';
import { User } from './user';

export type WorkspaceWatcher = {
    id: Id;
    tag_id: Id;
    user_id: Id;
    created_by: Id;
    created_at: string;
    updated_at: string;
};

export type Tab = 'overview' | 'meetings' | 'tasks' | 'files' | 'checklists' | 'feed' | 'gantt';
export type WorkspaceTab = { type: Tab; label: ReactNode; active: boolean; icon: IconDefinition };

export const EntityStatuses = ['open', 'archived', 'deleted'] as const;
export type EntityStatus = (typeof EntityStatuses)[number];

export type Workspace = {
    id: Id;
    name: string;
    description: string;
    color: ColorTuple;
    private: boolean;
    archived: boolean;
    archived_at: null | string;
    archived_by: null | string;
    deleted: boolean;
    deleted_at: null | string;
    deleted_by: null | string;
    network_id: Id;
    created_by: Id;
    updated_by: Id;
    created_at: string;
    updated_at: string;
    user_group_id: Id;
    order: number;
    default_view: 'list' | 'kanban';
    default_group_by: TaskOrder;
    stats: null | UsageStatistics;
    team_id: Id;
    tag_status_id: null;
    status?: Status;
    external_id: null;
    settings: null | {
        tabs: WorkspaceTab[];
        default_tab: Tab;
    };
    userGroup: UserGroup;
    team: Team;
    logo_url: null | string;
    daysLeft?: number;
    sections?: Section[];
    tag_section_id?: Id;
    tagStatus?: WorkspaceStatus;
    meta: {
        inFavs: boolean;
        nbUnassignedTasks: number;
        nbOpenTasks: number;
        nbMyTasks: number;
        nbCompletedTasks: number;
        watchers: Array<WorkspaceWatcher>;
        imWatcher: boolean;
        imMember: boolean;
        hasAccess: boolean;
        requested: boolean;
        imModerator: boolean;
        percentage: null;
    };

    // related
    isFavorite?: boolean;
    teamId?: string;
};

type HistoryEventKey = 'default_view' | 'default_group_by' | 'description' | 'name' | 'color' | 'settings';

export type WorkspaceHistory = {
    id: Id;
    object_id: Id;
    object_type: 'tag';
    event_key:
        | `changed_tag_${HistoryEventKey}`
        | 'created_tag'
        | 'deleted_comment_tag'
        | 'duplicated_tag'
        | 'renamed_tag'
        | 'archived_tag'
        | 'unarchived_tag'
        | 'deleted_tag'
        | 'restored_tag'
        | 'added_tag_status'
        | 'added_member_to_tag'
        | 'removed_member_from_tag'
        | 'changed_tag_privacy'
        | 'revoked_tag_member'
        | 'promoted_tag_member'
        | 'requested_tag_membership'
        | 'refused_tag_membership'
        | 'commented_tag'
        | 'updated_comment_tag'
        | 'added_tag_attachment'
        | 'added_watcher_to_tag'
        | 'removed_watcher_from_tag'
        | 'added_section_to_tag'
        | 'removed_tag_section'
        | 'updated_tag_section'
        | 'updated_tag_status'
        | 'removed_tag_status'
        | 'added_tag_to_favorites'
        | 'removed_tag_from_favorites';
    old_value: Workspace;
    new_value: Workspace;
    created_by: User;
    network_id: Id;
    created_at: string;
    updated_at: string;
    client_version: string;
    server_version: string;
    event_action: null;
    object_uuid: null;
};
