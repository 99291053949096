import React from 'react';
import { useParams } from 'react-router-dom';
import { faCircleDashed } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Alert, EmptyState, Skeleton, Table } from '@wedo/design-system';
import { parseSort, stringifySort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { TemplatesPageSearchParams } from 'Pages/TemplatesPage/TemplatesPage';
import { TemplateTableRow } from 'Pages/TemplatesPage/components/TemplateTableRow';
import { SortColumn } from 'Pages/TemplatesPage/types';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { useGetAllChecklistTemplateQuery } from 'Shared/services/template';

const TemplatesInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { teamId } = useParams();
    const [{ view, status, sort }] = useSearchParams(TemplatesPageSearchParams);

    const { data, isLoading, isError, error } = useGetAllChecklistTemplateQuery(
        {
            filter: view,
            status,
            ...parseSort(sort),
            teamId,
            offset,
            limit,
            related: ['userGroup.members.user'],
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data?.data);

    return (
        <>
            {isLoading ? (
                <Skeleton count={5} className={'h-10'} />
            ) : isError ? (
                <Alert type="danger" title={error?.message ?? ''} />
            ) : (
                data.data.map((template) => <TemplateTableRow key={template.id} template={template} />)
            )}
        </>
    );
};

export const TemplateTable = () => {
    const [{ view, sort, status }, setSearchParams] = useSearchParams(TemplatesPageSearchParams);

    const { sortColumn, sortDirection } = parseSort(sort);

    const handleSort = (column: SortColumn) => {
        setSearchParams((searchParams) => ({
            ...searchParams,
            sort: stringifySort(column, sortDirection === 'descending' ? 'ascending' : 'descending'),
        }));
    };

    const sortProps = (name: SortColumn) => ({
        onSort: () => handleSort(name),
        sortDirection: name === sortColumn ? sortDirection : null,
    });

    return (
        <div className="@container flex flex-col px-6 pb-4 overflow-hidden">
            <Table wrapperClassName={'max-h-full'} className={'overflow-hidden !flex !flex-col !h-full'} hoverable>
                <Table.Head trClassName={'flex w-full'} className="bg-gray-100">
                    <Table.HeadCell className="w-[60%] @md:w-[45%] @lg:w-[50%]" {...sortProps('name')}>
                        <Trans>Name</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell
                        className="@3xl:w-[15%] w-[20%] whitespace-nowrap text-center [&>span]:float-none"
                        {...sortProps('checklists')}
                    >
                        <Trans>Checklists</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell
                        className="@3xl:w-[15%] w-[20%] hidden @md:table-cell whitespace-nowrap text-center [&>span]:float-none"
                        {...sortProps('tasks')}
                    >
                        <Trans>Tasks</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden @3xl:table-cell @3xl:w-[15%]">
                        <Trans>Moderators</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className={'w-[20%] @md:w-[15%] @lg:w-[10%] @3xl:w-[5%]'}></Table.HeadCell>
                </Table.Head>
                <InfiniteScroll
                    key={`${view}-${status}`}
                    as="tbody"
                    className="divide-y divide-gray-200 bg-white"
                    page={TemplatesInfiniteScrollPage}
                    emptyPage={
                        <Table.Row className={'flex items-center justify-center'}>
                            <Table.Cell colSpan={4} className="py-6">
                                <EmptyState icon={faCircleDashed}>
                                    <EmptyState.Text>
                                        <Trans>No templates</Trans>
                                    </EmptyState.Text>
                                </EmptyState>
                            </Table.Cell>
                        </Table.Row>
                    }
                    size={20}
                />
            </Table>
        </div>
    );
};
