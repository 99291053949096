import React, { FC } from 'react';
import { type AvatarSizes, BasePlacement } from '@wedo/design-system';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { MeetingUser } from 'Shared/types/meetingUser';
import { getInitials } from 'Shared/utils/user';

type MeetingUserAvatarProps = {
    user: MeetingUser;
    size?: AvatarSizes;
    isStacked?: boolean;
    tooltipPlacement?: BasePlacement;
    showTooltip?: boolean;
};

export const MeetingUserAvatar: FC<MeetingUserAvatarProps> = ({
    user,
    size = 'md',
    isStacked,
    showTooltip = true,
    tooltipPlacement,
}) => {
    if (user?.user_id != null) {
        return (
            <UserAvatar
                user={user?.user}
                size={size}
                isStacked={isStacked}
                showTooltip={showTooltip}
                tooltipPlacement={tooltipPlacement}
            />
        );
    }

    return (
        <UserAvatar
            showTooltip={showTooltip}
            tooltipPlacement={tooltipPlacement}
            user={{
                ...user?.user,
                initials: getInitials(user?.user_data?.external_full_name),
                color: { background: '#000', text: '#fff' },
            }}
            size={size}
            isStacked={isStacked}
        />
    );
};
