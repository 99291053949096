import React from 'react';
import {
    faEmptySet,
    faLock,
    faStickyNote,
    faUnlockAlt,
    faUserPlus,
    faUserSecret,
    faUserTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';
import { User } from 'Shared/types/user';

export const checklistTemplateNotificationHandler: NotificationHandlerFn = (
    notification,
    currentUser = { id: null }
) => {
    const createdBy = <strong>{notification.created_by.full_name}</strong>;
    const templateName = <strong>{notification.checklistTemplate.name}</strong>;
    const oldValue = notification.activity_log.old_value as { user: User; private: boolean };
    const newValue = notification.activity_log.new_value as { user: User; private: boolean };

    switch (notification.event_key) {
        case 'added_member_to_checklist_template':
            return {
                icon: faUserPlus,
                text:
                    notification.people_count > 1 ? (
                        <Trans>
                            {createdBy} assigned <strong>{notification.people_count}</strong> users to the template{' '}
                            {templateName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} assigned one user to the template {templateName}
                        </Trans>
                    ),
            };
        case 'removed_member_from_checklist_template':
            return {
                icon: faUserTimes,
                text:
                    notification.people_count > 1 ? (
                        <Trans>
                            {createdBy} removed <strong>{notification.people_count}</strong> users from the template{' '}
                            {templateName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} removed one user from the template {templateName}
                        </Trans>
                    ),
            };
        case 'promoted_checklist_template_member':
            return {
                icon: faUserSecret,
                text:
                    currentUser.id === notification.created_by.id ? (
                        <Trans>
                            {createdBy} promoted you as moderator of the template {templateName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} promoted <strong>{newValue.user?.full_name}</strong> as moderator of the
                            template {templateName}
                        </Trans>
                    ),
            };
        case 'revoked_checklist_template_member':
            return {
                icon: faUserSecret,
                text:
                    currentUser.id === notification.created_by.id ? (
                        <Trans>
                            {createdBy} removed you as moderator of the template {templateName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} removed <strong>{newValue.user?.full_name}</strong> as moderator of the template{' '}
                            {templateName}
                        </Trans>
                    ),
            };
        case 'requested_checklist_template_membership':
            return {
                icon: faUserPlus,
                text: (
                    <Trans>
                        {createdBy} requested membership for the template {templateName}
                    </Trans>
                ),
            };
        case 'refused_checklist_template_membership':
            return {
                icon: faUserTimes,
                text: (
                    <>
                        {currentUser.id === notification.created_by.id ? (
                            <Trans>
                                {createdBy} refused your membership request to the template {templateName}
                            </Trans>
                        ) : (
                            <Trans>
                                {createdBy} refused membership to the template {templateName} to{' '}
                                <strong>{oldValue.user?.full_name}</strong>
                            </Trans>
                        )}
                    </>
                ),
            };
        case 'changed_checklist_template_privacy':
            return {
                icon: newValue.private ? faLock : faUnlockAlt,
                text: newValue.private ? (
                    <Trans>
                        {createdBy} changed the privacy of the template {templateName} to private
                    </Trans>
                ) : (
                    <Trans>
                        {createdBy} changed the privacy of the template {templateName} to public
                    </Trans>
                ),
            };
        case 'commented_checklist_template':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> mentioned you in a note
                    </Trans>
                ),
            };
        case 'updated_comment_checklist_template':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        <b>{createdBy}</b> edited a note you were mentioned in
                    </Trans>
                ),
            };
        case 'deleted_comment_checklist_template':
            return {
                icon: faStickyNote,
                text: (
                    <Trans>
                        {' '}
                        <b>{createdBy}</b> deleted a note you were mentioned in
                    </Trans>
                ),
            };
        default:
            return { icon: faEmptySet, text: <></> };
    }
};
