import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { t } from '@lingui/macro';
import { getAuthToken } from '@wedo/utils';
import { useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { LoadingRedirectPage } from 'Pages/AuthenticationPage/LoadingRedirectPage';
import { useTokenLogin } from 'Pages/SignInPage/hooks/useTokenLogin';
import { StatusBanner } from 'Shared/components/StatusBanner';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useLogout } from 'Shared/hooks/useLogout';
import { RequireAuthSearchParams } from 'Shared/services/auth';
import { ApiError } from 'Shared/types/apiError';
import { LocalStorage } from 'Shared/types/localStorage';

export const AuthenticationPage = () => {
    const navigate = useNavigate();
    const [{ token, error, logout }, , removeSearchParams] = useSearchParams(RequireAuthSearchParams);

    const { network, isGlobal, error: networkError } = useCurrentNetwork();
    const tokenLogin = useTokenLogin();
    const handleLogout = useLogout();
    const authToken = getAuthToken();
    const globalAuthToken = getAuthToken(LocalStorage.GlobalAuthToken);

    const isResetPasswordPage = location?.pathname === '/reset-password';

    useEffect(() => {
        if (authToken) {
            void navigate('/');
            return;
        }
        if (token && !isResetPasswordPage) {
            void tokenLogin();
            return;
        }
        if (logout) {
            removeSearchParams(['logout']);
            void handleLogout();
        }
    }, []);

    useEffect(() => {
        if (isGlobal && globalAuthToken && !token) {
            void tokenLogin(true);
        }
    }, [isGlobal]);

    if (token && !error && !isResetPasswordPage) {
        return <LoadingRedirectPage />;
    }

    if ((networkError as ApiError)?.matches({ path: 'Network not found' }) && window.location.port === '') {
        const domain = new URL(window.location.href).hostname;
        const domains = domain.split('.');
        domains.splice(0, 1);
        window.location.replace('https://login.' + domains.join('.'));
    }

    return (
        <>
            <div className="flex min-h-screen flex-col bg-white">
                <StatusBanner />
                <div className="flex min-h-screen flex-row">
                    <div className="relative flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div className="mt-5 flex justify-center lg:justify-start">
                                <img
                                    src={network?.logo_url ?? '/assets/logo/logo.svg'}
                                    alt={t`Logo`}
                                    className="max-h-32 max-w-[50%]"
                                />
                            </div>

                            <div className="mt-8">
                                <div className="mt-6">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <div className="absolute inset-0 bg-gradient-to-bl from-blue-600 to-blue-800" />
                    </div>
                </div>
            </div>
        </>
    );
};
