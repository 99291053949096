import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Editor, Range, Transforms } from 'slate';
import { colorPickerColorsMap, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import store from 'App/store';
import { onInvalidation } from 'App/store/invalidationStore';
import { voteSelected } from 'Pages/meeting/MeetingViewSlice';
import { getVoteIcon, VoteComponent, VoteHandle } from 'Pages/meeting/components/Vote/Vote';
import { createVoteWithRelated } from 'Pages/meeting/components/Vote/VoteUtils';
import { createBlock } from 'Shared/components/editor/utils/block';
import { is } from 'Shared/components/editor/utils/node';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { isIn } from 'Shared/components/editor/utils/slate';
import { resourceId } from 'Shared/services/base';
import {
    buildGetVoteBlockParameters,
    tag as meetingVoteTag,
    tagType as meetingVoteTagType,
    useGetVoteQuery,
} from 'Shared/services/meetingVote';
import { Plugin, useEditorIsFocused } from '../Editor';
import { VoteElement as VoteElementType } from '../types';

export const Vote = 'vote';

export const createVoteBlock = () => {
    return {
        ...createBlock({
            type: Vote,
            vote_id: null as Id,
            vote_type: 'single',
            vote_options: [
                { color: colorPickerColorsMap.green.value, value: t`Yes`, order: 0 },
                { color: colorPickerColorsMap.red.value, value: t`No`, order: 1 },
            ],
            children: [{ text: '' }],
        }),
    };
};

type VoteElementProps = RenderElementProps & {
    isReadOnly: boolean;
    isVoteOnly: boolean;
    meetingId: Id;
    element: VoteElementType;
};

const VoteElement = ({ isReadOnly, isVoteOnly, meetingId, element, children, attributes }: VoteElementProps) => {
    const [canUseQuery, setCanUseQuery] = useState(window.location.pathname === '/votes');
    const voteRef = useRef<VoteHandle>();

    const {
        data: vote = createVoteWithRelated(element.vote),
        error,
        isError,
        isLoading,
    } = useGetVoteQuery(buildGetVoteBlockParameters(element.vote_id, meetingId), {
        skip: element.vote_id == null || (element.vote != null && !canUseQuery),
    });

    const isNotFound = useMemo(() => error != null && 'status' in error && error.status === 404, [error]);

    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useEditorIsFocused();
    const isSelected = selected && focused && Range.isCollapsed(editor.selection);

    // We should focus the vote element if the element doesn't have a vote id (because it means the vote element is
    // being created)
    const shouldFocusVote = useRef(element.vote_id == null);

    useEffect(() => {
        // Once we have a vote, check if we should focus
        if (vote != null && shouldFocusVote.current) {
            shouldFocusVote.current = false;
            voteRef.current.focus();
        }
    }, [vote]);

    useEffect(() => {
        const invalidationTag = meetingVoteTag(resourceId(meetingVoteTagType, vote?.id));
        return onInvalidation(invalidationTag, () => setCanUseQuery(true));
    }, []);

    const className = isSelected && !isReadOnly && 'ring-2 ring-blue-500 ring-offset-2 group-[.is-dragging]:ring-0';

    return (
        <div data-block-id={element.id} {...attributes} className="relative">
            {(isLoading && vote == null) || isNotFound ? (
                <div className="flex w-full flex-col gap-2" contentEditable={false}>
                    <Skeleton className="h-48" />
                </div>
            ) : !isNotFound && (isError || element.vote_id == null) ? (
                <div contentEditable={false} className={clsx('bg-gray-50 w-full py-1 px-2', className)}>
                    <div className={'flex shrink-0 items-center justify-between gap-4 self-start p-1'}>
                        <div>
                            <Trans>This vote cannot be displayed</Trans> error: {isError}
                        </div>
                        <div className={'mr-[calc(-1_*_0.5rem)] text-sm text-gray-700'}>{getVoteIcon(vote?.type)}</div>
                    </div>
                </div>
            ) : (
                <VoteComponent
                    ref={voteRef}
                    meetingId={meetingId}
                    readOnly={isReadOnly && !isVoteOnly}
                    voteOnly={isVoteOnly}
                    vote={vote}
                    topicId={element.meeting_topic_id}
                    className={className}
                    onReload={() => setCanUseQuery(true)}
                />
            )}
            {children}
        </div>
    );
};

export const votePlugin = ({
    isReadOnly,
    isVoteOnly,
    meetingId,
    canDeleteVote,
}: {
    isReadOnly: boolean;
    isVoteOnly: boolean;
    meetingId: Id;
    canDeleteVote: boolean;
}): Plugin => {
    const guardDelete = (editor: Editor) =>
        isIn(editor, Vote, () => {
            if (!canDeleteVote) {
                return true;
            }
            Transforms.removeNodes(editor, { mode: 'highest' });
            forceSave(editor);
            store.dispatch(voteSelected({ voteId: null, meetingId }));
            return true;
        });

    return {
        apply: (editor, operation) => {
            if (operation.type === 'remove_node' && operation.node.type === 'vote' && !canDeleteVote) {
                return true;
            }
            return false;
        },
        isVoid: (editor, element) => element.type === Vote,
        deleteForward: guardDelete,
        deleteBackward: guardDelete,
        deleteFragment: async (editor) => {
            const result = Editor.nodes(editor, { match: is(Vote) });
            return result.next().value != null && !canDeleteVote;
        },
        renderElement: (editor, { element, children, attributes }) => {
            return (
                element.type === Vote && (
                    <VoteElement
                        attributes={attributes}
                        element={element}
                        isReadOnly={isReadOnly}
                        isVoteOnly={isVoteOnly}
                        meetingId={meetingId}
                    >
                        {children}
                    </VoteElement>
                )
            );
        },
    };
};
