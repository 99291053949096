import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { SidebarHeader } from 'Pages/AppPage/Sidebar/SidebarHeader';
import { SidebarBody } from './SidebarBody';
import { SidebarFooter } from './SidebarFooter';

type MobileSidebarProps = {
    isCollapsed: boolean;
    setIsCollapsed: (collapsed: boolean) => void;
};

export const MobileSidebar = ({ isCollapsed, setIsCollapsed }: MobileSidebarProps) => {
    const { pathname: location } = useLocation();
    const previousLocation = usePrevious(location);

    // Close on navigate
    useEffect(() => {
        if (!isEmpty(location) && !isEmpty(previousLocation) && location !== previousLocation) {
            setIsCollapsed(true);
        }
    }, [location, previousLocation]);

    return (
        <Transition.Root show={!isCollapsed} as={Fragment}>
            <Dialog as="div" className="relative z-20 lg:hidden" onClose={() => setIsCollapsed(true)}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 text-white focus:outline-none">
                            <SidebarHeader isMobile setIsCollapsed={setIsCollapsed} />
                            <div className="scrollbar-dark flex-1 overflow-y-auto">
                                <nav aria-label="Sidebar">
                                    <SidebarBody isMobile />
                                </nav>
                            </div>
                            <SidebarFooter isMobile />
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
