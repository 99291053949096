import React, { useState } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import { Button, Modal } from '@wedo/design-system';
import { FaIconName } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { CustomFieldNameLabel } from 'Pages/settings/customFields/components/shared/CustomFieldNameLabel';
import { FieldNameInput } from 'Pages/settings/customFields/components/shared/FieldNameInput';
import {
    AvailabilityOption,
    IsCustomFieldGlobalRadio,
} from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';
import { useCombinedFields } from 'Pages/settings/customFields/hooks/useCombinedFields';
import { useCustomFieldGroup } from 'Pages/settings/customFields/hooks/useCustomFieldGroup';
import { useCustomFieldGroups } from 'Pages/settings/customFields/hooks/useCustomFieldGroups';
import {
    getFieldAvailability,
    getRandomIconName,
    MIN_FIELD_LABEL_LENGTH,
} from 'Pages/settings/customFields/utils/field';
import { useAddCustomFieldMutation, useUpdateCustomFieldMutation } from 'Shared/services/customFields';
import { CustomField, CustomFieldType } from 'Shared/types/customField';
import { isSuccess } from 'Shared/utils/rtkQuery';

interface BasicCustomFieldSectionProps {
    onSuccess: () => void;
    onCancel: () => void;
    fieldType: CustomFieldType;
    groupId?: string;
    field?: CustomField;
}

export const BasicCustomFieldModalForm: React.FC<BasicCustomFieldSectionProps> = ({
    field,
    onSuccess,
    groupId,
    onCancel,
    fieldType,
}) => {
    const [addField, { isLoading: addFieldIsLoading }] = useAddCustomFieldMutation();
    const [updateField, { isLoading: updateFieldIsLoading }] = useUpdateCustomFieldMutation();
    const { getGroup } = useCustomFieldGroups();
    const { maxOrder } = useCombinedFields();
    const { maxOrder: maxOrderGroup } = useCustomFieldGroup(getGroup(groupId));

    const isInGroup = !!field?.custom_field_group_id || !!groupId;

    const [text, setText] = useState<string>(field ? field.label : EmptyString);
    const [icon, setIcon] = useState<FaIconName>(
        isInGroup ? (EmptyString as FaIconName) : field?.icon ?? getRandomIconName()
    );
    const [availability, setAvailability] = useState<AvailabilityOption>(getFieldAvailability(field));

    const isLoading = addFieldIsLoading || updateFieldIsLoading;

    const hasUserMadeChanges =
        !isEqual(field?.label.trim(), text.trim()) ||
        !isEqual(field?.icon, icon) ||
        !isEqual(field?.is_global, availability.isGlobal);

    const hasIcon = !isEmpty(icon);

    const isButtonDisabled =
        text.trim().length < MIN_FIELD_LABEL_LENGTH || (!hasUserMadeChanges && !!field) || (!hasIcon && !isInGroup);

    const handleAddField = async () => {
        if (
            await isSuccess(
                addField({
                    label: text,
                    object_type: 'task',
                    order: (groupId ? maxOrderGroup : maxOrder) + 1,
                    is_global: availability.isGlobal,
                    icon,
                    custom_field_group_id: groupId ? groupId : null,
                    options: [],
                    type: fieldType,
                })
            )
        ) {
            onSuccess();
        }
    };

    const handleIconChange = (icon: IconDefinition): void => {
        setIcon(icon === null ? (EmptyString as FaIconName) : `fa-${icon.iconName}`);
    };

    const handleUpdateField = async () => {
        if (
            await isSuccess(
                updateField({
                    id: field.id,
                    label: text,
                    is_global: availability.isGlobal,
                    icon,
                } as CustomField)
            )
        ) {
            onSuccess();
        }
    };

    const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter' || isButtonDisabled) return;

        if (field) {
            void handleUpdateField();
        } else {
            void handleAddField();
        }
    };

    return (
        <>
            <Modal.Header title={field ? t`Edit custom field` : t`Add custom field`} />

            <Modal.Body>
                <CustomFieldNameLabel />

                <FieldNameInput
                    value={text}
                    onChange={setText}
                    onKeyDown={handleEnterKey}
                    icon={icon}
                    onIconChange={handleIconChange}
                    isInGroup={isInGroup}
                    shortName={field?.short_name}
                />

                {!isInGroup && (
                    <IsCustomFieldGlobalRadio
                        title={t`Is this custom field global?`}
                        availability={availability}
                        onClick={setAvailability}
                    />
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onCancel} disabled={isLoading}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button
                    color="primary"
                    loading={isLoading}
                    onClick={field ? handleUpdateField : handleAddField}
                    disabled={isButtonDisabled}
                >
                    {field ? t`Update` : t`Save`}
                </Button>
            </Modal.Footer>
        </>
    );
};
