export const intersection = <T>(set1: Set<T>, set2: Set<T>): Set<T> => {
    const result = new Set<T>();
    for (const item of set1) {
        if (set2.has(item)) result.add(item);
    }
    return result;
};

// TODO: remove the usages of this function when we migrate to Node 22
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/difference
export const setDiff = <T>(set1: Set<T>, set2: Set<T>): Set<T> => {
    const result = new Set<T>();
    for (const item of set1) {
        if (!set2.has(item)) result.add(item);
    }
    return result;
};
