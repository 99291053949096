import { colors } from '@wedo/design-system';
import { type State } from '@wedo/pdf/meeting/store';
import { Stamp } from 'Shared/components/pdfViewer/SharedPdfViewer';

const FontSize = 8;
const FontColor = parseInt(colors.gray['400'].substring(1), 16);

export const stampAttachment = (state: State, fileName: string): Stamp => {
    return async (instance, document, [pageRangeStart, pageRangeEnd]) => {
        const { PDFNet } = instance.Core;

        const stamper = await PDFNet.Stamper.create(PDFNet.Stamper.SizeType.e_font_size, FontSize, 0);
        await stamper.setPosition(0, 8);
        await stamper.setFont(await PDFNet.Font.create(document, PDFNet.Font.StandardType1Font.e_helvetica));
        await stamper.setFontColor(
            await PDFNet.ColorPt.init(
                ((FontColor >> 16) & 255) / 255.0,
                ((FontColor >> 8) & 255) / 255.0,
                (FontColor & 255) / 255.0,
                0
            )
        );
        await stamper.setAsBackground(false);
        await stamper.setAlignment(
            PDFNet.Stamper.HorizontalAlignment.e_horizontal_center,
            PDFNet.Stamper.VerticalAlignment.e_vertical_top
        );
        await stamper.setTextAlignment(PDFNet.Stamper.TextAlignment.e_align_left);
        await stamper.stampText(document, fileName, await PDFNet.PageSet.createRange(pageRangeStart, pageRangeEnd));
    };
};
