import { io, Socket } from 'socket.io-client';
import { create } from 'zustand';
import { getAuthToken } from '@wedo/utils';
import { clientVersion } from 'App/store/versionStore';

type SocketEventType = 'connection' | 'disconnection' | 'reconnection' | 'error';

type WebSocketStore = {
    socket: Socket;
    connectPromise: Promise<void>;
    rooms: Map<string, unknown>;
    events: {
        timestamp: number;
        type: SocketEventType;
        message: string;
    }[];
    addEvent: (type: SocketEventType, message?: string) => void;
    isConnected: boolean;
    setIsConnected: (value: boolean) => void;
};

export const useWebSocketStore = create<WebSocketStore>()((set) => ({
    socket: null,
    connectPromise: null,
    rooms: new Map(),
    events: [],
    isConnected: false,
    addEvent: (type, message = '') => {
        set((state) => {
            const events = [{ timestamp: new Date().getTime(), type, message }].concat(state.events).slice(0, 100);
            return { events };
        });
    },
    setIsConnected: (value) => {
        set((state) => {
            if (state.isConnected !== value) {
                return { isConnected: value };
            }

            return {};
        });
    },
}));

// TODO: remove the global object "window.useWebsocketStore" and the array "events" once the WebSocket is stable
window.useWebsocketStore = useWebSocketStore;

export const loginRequired = () => {
    document.dispatchEvent(new CustomEvent('loginRequired'));
};

export const emit = async (action: string, payload: unknown) => {
    const { socket, connectPromise } = useWebSocketStore.getState();
    await connectPromise;
    return socket?.timeout(5000).emitWithAck(action, payload);
};

const connectSocket = () => {
    const socket = useWebSocketStore.getState().socket;

    const connectPromise = new Promise<void>((resolve, reject) => {
        socket.on('connect', () => {
            useWebSocketStore.getState().addEvent('connection');
            useWebSocketStore.getState().setIsConnected(true);
            resolve();
        });
        socket.on('connect_error', (error: any) => {
            useWebSocketStore.getState().addEvent('error', error.message);
            useWebSocketStore.getState().setIsConnected(false);
            if (error.data?.type === 'UnauthorizedError') {
                loginRequired();
            }
            reject(error);
        });
        socket.on('disconnect', (reason) => {
            useWebSocketStore.getState().addEvent('disconnection', reason);
            useWebSocketStore.getState().setIsConnected(false);
            if (reason === 'io server disconnect') {
                loginRequired();
            }
        });
        socket.io.on('reconnect', () => {
            useWebSocketStore.getState().addEvent('reconnection');
            useWebSocketStore.getState().setIsConnected(false);
            useWebSocketStore.getState().rooms.forEach((payload, room) => {
                void emit(`${room}/join`, payload);
            });
        });
        socket.connect();
    });

    useWebSocketStore.setState({ connectPromise });

    return connectPromise;
};

export const openWebSocket = () => {
    useWebSocketStore.setState({
        socket: io('//' + location.host, {
            auth: { token: `Bearer ${getAuthToken()}` },
            autoConnect: false,
            transports: ['websocket'],
            extraHeaders: {
                'X-Client-Version': clientVersion(),
            },
        }),
    });

    return connectSocket();
};

export const closeWebSocket = () => {
    const { socket, connectPromise } = useWebSocketStore.getState();
    connectPromise?.then(() => socket.close()) ?? socket?.close();
    useWebSocketStore.setState({ socket: null, connectPromise: null });
};

export const onWebSocketEvent = <T>(name: string, handler: (data: T) => void) => {
    useWebSocketStore.getState().connectPromise?.then(() => useWebSocketStore.getState().socket.on(name, handler));
    return () => {
        useWebSocketStore
            .getState()
            .connectPromise?.then(() => useWebSocketStore.getState().socket?.off(name, handler));
    };
};
