import { PropsWithChildren, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { sortBy } from 'lodash-es';
import {
    ContextModalProps,
    Button,
    Modal,
    useConfirm,
    useNotification,
    UnexpectedErrorNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    useAddMeetingRolesMutation,
    useDeleteMeetingRolesMutation,
    useUpdateMeetingRolesMutation,
} from 'Shared/services/meetingRole';
import { MeetingRole } from 'Shared/types/meetingRole';
import { MeetingUserItem } from 'Shared/types/meetingUserItem';
import { ManageMeetingRoles } from './ManageMeetingRoles';
import { sortRoleByImportance } from './utils';

interface TrackedChangeObject {
    changed?: boolean;
    added?: boolean;
}

interface ChangedMeetingRole extends MeetingRole, TrackedChangeObject {}

type EditUsersRolesModalProps = {} & ContextModalProps & PropsWithChildren;

export const EditUsersRolesModal = ({ meetingId, children, ...modalProps }: EditUsersRolesModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const [addMeetingRoles, { isLoading: isAddingMeetingRoles }] = useAddMeetingRolesMutation();
    const [updateMeetingRoles, { isLoading: isUpdatingMeetingRoles }] = useUpdateMeetingRolesMutation();
    const [deleteMeetingRoles, { isLoading: isDeletingMeetingRoles }] = useDeleteMeetingRolesMutation();

    const [meetingUserItems, setMeetingUserItems] = useState<MeetingUserItem[]>([]);
    const [meetingRoles, setMeetingRoles] = useState<ChangedMeetingRole[]>(sortBy(meeting.roles, sortRoleByImportance));
    const [hasChanged, setHasChanged] = useState(false);

    const isLoading = isAddingMeetingRoles || isUpdatingMeetingRoles || isDeletingMeetingRoles;

    useEffect(() => {
        setMeetingUserItems(meeting?.meetingUsers?.map((user) => user.items).flat());
    }, [meeting?.meetingUsers]);

    useEffect(() => {
        setMeetingRoles(sortBy(meeting.roles, sortRoleByImportance));
        setHasChanged(false);
    }, []);

    const handleCancel = async () => {
        if (!hasChanged) {
            await modalProps.close();
            return;
        }
        const discardChanges = await confirm({}, ConfirmDiscardChangesModal);
        if (discardChanges) {
            await modalProps.close();
        }
    };

    const handleSaveConfirm = async () => {
        try {
            const addedObjects: ChangedMeetingRole[] = [];
            const changeObjects: { id: Id; changes: Pick<MeetingRole, 'name' | 'color' | 'permissions'> }[] = [];
            const removeObjects = meeting.roles
                .filter((mr) => !meetingRoles.find((mr2) => mr2.id === mr.id))
                .map((role) => role.id);
            meetingRoles.forEach((item) => {
                if (item.added) {
                    addedObjects.push(item);
                } else if (item.changed) {
                    changeObjects.push({
                        id: item.id,
                        changes: {
                            name: item.name,
                            color: item.color,
                            permissions: item.permissions,
                        },
                    });
                }
            });
            if (changeObjects.length > 0) {
                await updateMeetingRoles({ meetingId: meetingId, meetingRoles: changeObjects }).unwrap();
            }
            if (removeObjects.length > 0) {
                const response = await deleteMeetingRoles({ meetingId: meetingId, meetingRoles: removeObjects });
                if ('error' in response) {
                    throw new Error(t`The role can't be deleted because it's linked to at least one attendee.`);
                }
            }
            if (addedObjects.length > 0) {
                await addMeetingRoles({ meetingId: meetingId, meetingRoles: addedObjects }).unwrap();
            }
            await modalProps.close();
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const handleUpdateMeetingRole = (meetingRole: MeetingRole, change) => {
        setHasChanged(true);
        setMeetingRoles((current) => {
            return current.map((item) => {
                if (item.id === meetingRole.id) {
                    return { ...item, [change.attribute]: change.value, changed: true };
                }
                return item;
            });
        });
    };

    const handleAddMeetingRole = (meetingRole: MeetingRole) => {
        setHasChanged(true);
        setMeetingRoles((current) => {
            return [...current, { ...meetingRole, added: true }];
        });
    };

    const handleDeleteMeetingRole = (meetingRole: MeetingRole) => {
        setHasChanged(true);
        setMeetingRoles((current) => {
            return current.filter((item) => {
                return item.id !== meetingRole.id;
            });
        });
    };

    const handleCanDeleteRole = (meetingRole: MeetingRole) => {
        const meetingUser = meeting.meetingUsers.find((mu) => mu.meeting_role_id === meetingRole.id);
        if (meetingUser) {
            return false;
        }
        const meetingUserItem = meetingUserItems.find((mui) => mui.meeting_role_id === meetingRole.id);
        return !meetingUserItem;
    };
    const handleSave = async () => {
        if (!hasChanged) {
            await modalProps.close();
            return;
        }
        await handleSaveConfirm();
    };
    return (
        <>
            <Modal size="lg" handleCancel={handleCancel} {...modalProps}>
                <Modal.Header title={t`Manage roles`} />
                <Modal.Body>
                    <ManageMeetingRoles
                        meetingRoles={meetingRoles}
                        onUpdate={handleUpdateMeetingRole}
                        onAdd={handleAddMeetingRole}
                        onDelete={handleDeleteMeetingRole}
                        canDelete={handleCanDeleteRole}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button color={'primary'} loading={isLoading} onClick={handleSave}>
                        <Trans>Save</Trans>
                    </Button>
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
