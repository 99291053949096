import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { faAngleDown, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Plural } from '@lingui/macro';
import clsx from 'clsx';
import { Avatar, Button, Icon, Spinner, Tooltip, useDebounceInput } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { useEvent, useLoader } from '@wedo/utils/hooks';
import appStore from 'App/store';
import { useUser } from 'App/store/usersStore';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { type User } from 'Shared/types/user';
import { useGanttContextStore, useSelectedTaskId } from './GanttContext';
import { ListDragHandle } from './ListDragHandle';
import { toggleSubTasksEvent } from './events';
import { type Section, type Task } from './types';
import { useHover } from './useHover';
import { useSubTasks } from './useSubTasks';
import { useTask } from './useTask';
import { computeDateWindows, durationInDays } from './utils';

type ListTaskProps = {
    task: Task;
    section: Section;
    wbs: string;
    level?: number;
};

export const ListTask = ({ task: initialTask, section, wbs, level = 1 }: ListTaskProps) => {
    const store = useGanttContextStore()!;
    const queryClient = useQueryClient();

    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(store.getState().view.focusedTaskId === initialTask.id);
    const wasOpen = useRef(false);

    const task = useTask(initialTask);

    const hoverProps = useHover(`task-${task.id}`);

    const previousSubTasksRef = useRef<Array<Task>>(null);

    const { subTasks, isLoading: isLoadingSubTasks } = useSubTasks(task.id, isOpen);

    const [updateTask] = useUpdateTaskMutation();
    const { isLoading: isUpdating, wrap } = useLoader();

    const assignee = useUser(task.assigneeId);
    const duration = useMemo(() => durationInDays(task.plannedDate, task.dueDate), [task.plannedDate, task.dueDate]);
    const { internalValue, handleChange, handleBlur } = useDebounceInput({
        value: task.name,
        onChange: () =>
            wrap(async () => {
                await updateTask({ id: task.id, name: internalValue, keepCache: true });
                await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'name')]);
            }),
        onBlur: () => setIsEditing(false),
    });

    const selectedTaskId = useSelectedTaskId();

    const handleToggleSubTasksClick = () => {
        store.getState().eventBus.dispatchToggleSubTasksEvent(task.id);
    };

    const handleClick = () => {
        appStore.dispatch(taskSelected({ taskId: task.id }));
    };

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const handleInputRef = (element: HTMLInputElement) => {
        element?.focus();
        if (store.getState().view.focusedTaskId != null) {
            element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            store.setState((state) => {
                state.view.focusedTaskId = null;
            });
        }
    };

    const handleToggleSubTasks = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);

    const handleToggleTask = async () => {
        wrap(async () => {
            await updateTask({ id: task.id, completed: !task.completed, keepCache: true });
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'completed')]);
        });
    };

    const handleChangeAssignee = (user: User) => {
        wrap(async () => {
            await updateTask({ id: task.id, assignee_id: user?.id ?? null, keepCache: true });
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'assigneeId')]);
        });
    };

    const handleDragStart = () => {
        if (isOpen) {
            wasOpen.current = true;
            store.getState().eventBus.dispatchToggleSubTasksEvent(task.id);
        }
    };

    const handleDragEnd = () => {
        if (wasOpen.current) {
            store.getState().eventBus.dispatchToggleSubTasksEvent(task.id);
        }
    };

    useEvent(toggleSubTasksEvent(task.id), handleToggleSubTasks, store.getState().eventBus);

    useEffect(() => {
        if (subTasks != null) {
            store.setState((state) => {
                state.data.tasks = isOpen
                    ? (previousSubTasksRef.current != null
                          ? state.data.tasks!.filter(
                                (task) => !previousSubTasksRef.current?.some((subTask) => subTask.id === task.id)
                            )
                          : state.data.tasks!
                      ).concat(subTasks)
                    : state.data.tasks!.filter((task) => !subTasks.some((subTask) => subTask.id === task.id));
                state.data.dateWindows = computeDateWindows(state.data.tasks!, state.data.sectionsById!);
            });
            store.getState().eventBus.dispatchRenderDependenciesEvent();
            previousSubTasksRef.current = subTasks;
        }
    }, [isOpen, subTasks]);

    return (
        <>
            <div
                className={clsx(
                    'grid grid-cols-subgrid col-span-4 group bg-white',
                    selectedTaskId === task.id && '!bg-blue-200',
                    isOpen && 'is-open'
                )}
                data-parent-task-id={task.parentTaskId}
                data-task-id={task.id}
                data-section-id={task.sectionId}
                data-order={task.order}
                {...hoverProps}
            >
                <div className="px-2 tabular-nums flex items-center border-r-4" style={{ borderColor: section.color }}>
                    {task.parentTaskId == null && (
                        <ListDragHandle type="task" onDragStart={handleDragStart} onDragEnd={handleDragEnd} />
                    )}
                    <span className={clsx(task.parentTaskId == null && 'group-hover:hidden block')}>{wbs}</span>
                </div>
                <div className="flex items-center overflow-hidden border-r border-gray-200">
                    <div
                        className={clsx(
                            'flex items-center overflow-hidden py-1 pr-2 relative flex-1',
                            task.type === 'milestone' && 'font-semibold'
                        )}
                        style={{ paddingLeft: `calc(1.75rem + ${level - 1}rem)` }}
                    >
                        {task.hasSubtasks && (
                            <Button
                                loading={isLoadingSubTasks}
                                variant="ghost"
                                size="xs"
                                icon={isOpen ? faAngleDown : faAngleRight}
                                onClick={handleToggleSubTasksClick}
                                className="shrink-0 absolute !w-7 -translate-x-full flex justify-center"
                            />
                        )}
                        <div className="flex items-center gap-2 overflow-hidden flex-1">
                            {isUpdating ? (
                                <Spinner className="h-5 w-5" color="gray" />
                            ) : (
                                <Icon
                                    name={
                                        task.completed
                                            ? 'completedTask'
                                            : level > 1
                                              ? 'openSubtask'
                                              : task.type === 'milestone'
                                                ? 'openMilestone'
                                                : 'openTask'
                                    }
                                    className={clsx(
                                        'h-5 w-5 cursor-pointer',
                                        task.completed
                                            ? 'text-green-500'
                                            : selectedTaskId === task.id
                                              ? 'text-gray-400'
                                              : 'text-gray-300'
                                    )}
                                    onClick={isUpdating ? undefined : handleToggleTask}
                                />
                            )}
                            {isEditing ? (
                                <input
                                    ref={handleInputRef}
                                    className="outline-none w-full bg-transparent"
                                    value={internalValue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            ) : (
                                <button
                                    onClick={handleClick}
                                    onDoubleClick={!task.completed ? handleDoubleClick : undefined}
                                    className="overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-left h-5"
                                >
                                    {task.name}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative px-2 flex items-center border-r border-gray-200">
                    <div className="flex gap-1 items-center">
                        <UserPicker
                            showNobody
                            variant="ghost"
                            onUserSelected={handleChangeAssignee}
                            disabled={task.completed}
                            className="disabled:!opacity-100"
                        >
                            {assignee != null ? (
                                <Tooltip content={assignee.full_name}>
                                    <Avatar
                                        img={assignee.photo != null ? `/files/${assignee.photo}` : undefined}
                                        size="xs"
                                        className="shadow-sm"
                                    />
                                </Tooltip>
                            ) : (
                                <Avatar size="xs" className="shadow-sm" />
                            )}
                        </UserPicker>
                    </div>
                </div>
                <div className="px-2 flex items-center text-gray-500">
                    {task.type !== 'milestone' && (task.plannedDate != null || task.dueDate != null) && (
                        <Plural value={duration} one={`${duration} day`} other={`${duration} days`} />
                    )}
                </div>
            </div>
            {isOpen &&
                subTasks?.map((subTask, index) => (
                    <ListTask
                        key={subTask.id}
                        task={subTask}
                        section={section}
                        wbs={section.id === '' ? '' : `${wbs}.${index + 1}`}
                        level={level + 1}
                    />
                ))}
        </>
    );
};
