import React from 'react';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, Card, Label, Tag, useModal } from '@wedo/design-system';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { Template } from 'Shared/types/template';
import { getAllModerators } from 'Shared/utils/userGroup';

export const TemplateMembersCard = ({ template }: { template: Template }) => {
    const { open } = useModal();

    const allModerators = getAllModerators(template?.userGroup?.members);
    const { isCurrentUserModerator } = useManageMembers(template);

    return (
        <Card>
            <Card.Header
                title={<Trans>Members</Trans>}
                actions={
                    isCurrentUserModerator && (
                        <Button
                            size="sm"
                            icon={faPen}
                            onClick={() => open(TemplateSettingsModal, { templateId: template?.id, defaultIndex: 1 })}
                        >
                            <Trans>Edit</Trans>
                        </Button>
                    )
                }
            />
            <Card.Body>
                <Label>
                    <Trans>Template moderators</Trans>{' '}
                    <Tag size="xs" color="gray">
                        {allModerators?.length}
                    </Tag>
                </Label>

                <div className="mt-2 grid w-full grid-cols-[repeat(auto-fill,_minmax(3rem,1fr))] gap-2">
                    {allModerators?.map((member) => <UserAvatar key={member.user_id} user={member.user} size="lg" />)}
                </div>

                <Label className="mt-6">
                    <Trans>All members</Trans>{' '}
                    <Tag size="xs" color="gray">
                        {template?.userGroup?.members?.length}
                    </Tag>
                </Label>

                <div className="mt-2 grid w-full grid-cols-[repeat(auto-fill,_minmax(3rem,1fr))] gap-2">
                    {template?.userGroup?.members?.map((member) => (
                        <UserAvatar key={member.user_id} user={member.user} size="lg" />
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
};
