import React, { FC, useState } from 'react';
import { Plural, t } from '@lingui/macro';
import { ConfirmModal, ContextModalProps } from '@wedo/design-system';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { ApplyOnRadioGroup } from 'Shared/components/task/TaskDetail/ApplyOnRadioGroup';
import { ConfirmSubTasksTitle } from 'Shared/components/task/TaskDetail/ConfirmCompleteTaskWithSubtasks';
import { Task } from 'Shared/types/task';
import { getNumberOfOpenSubTasks } from 'Shared/utils/task';

type ConfirmDeleteTasksWithSubtasksProps = { task: Task } & ContextModalProps;

export const ConfirmDeleteTasksWithSubtasks: FC<ConfirmDeleteTasksWithSubtasksProps> = ({ task, ...modalProps }) => {
    const [applyOn, setApplyOn] = useState(ApplyOn.AllTasks);

    const openSubTaskCount: number = getNumberOfOpenSubTasks(task);
    const completedTasksCount = applyOn === ApplyOn.OnlyCurrentTask ? 1 : openSubTaskCount + 1;

    return (
        <ConfirmModal
            {...modalProps}
            type="danger"
            onConfirm={async () => applyOn}
            title={<ConfirmSubTasksTitle task={task} openSubTaskCount={openSubTaskCount} />}
            confirmText={<Plural value={completedTasksCount} one={`Delete task`} other={`Delete all subtasks`} />}
            content={
                <ApplyOnRadioGroup
                    value={applyOn}
                    onChange={setApplyOn}
                    allOptionText={t`Delete all open subtasks`}
                    currentTaskOptionText={t`Only delete current task`}
                />
            }
        />
    );
};
