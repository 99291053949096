import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faArrowLeft, faArrowRight, faLock, faPlus, faRotateRight, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { useOnboardingStore } from 'Pages/onboarding/utils/onboardingStore';

export const FakeBrowser: FC<{ children: React.ReactNode }> = ({ children }) => {
    const store = useOnboardingStore();

    return (
        <div>
            <div className="z-10 flex items-center rounded-t-md py-2">
                <div className="flex h-10 items-center gap-2 rounded-br-md rounded-tl-md bg-gray-800 px-4">
                    <div className="h-3 w-3 rounded-full bg-red-500" />
                    <div className="h-3 w-3 rounded-full bg-yellow-400" />
                    <div className="h-3 w-3 rounded-full bg-green-400" />
                </div>

                <div>
                    <div className="-mb-2 h-4 bg-gray-800"></div>
                    <div className="flex h-8 w-52 items-center rounded-t-md bg-gray-700 px-4 pb-2 pt-2 text-gray-200">
                        <img alt={t`WEDO logo`} src="/assets/logo/light.svg" className="h-5" />
                        <div className="grow" />
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>

                <div className="flex h-10 items-center rounded-bl-md bg-gray-800 pl-4 pr-44 pt-2">
                    <FontAwesomeIcon icon={faPlus} className="text-white" />
                </div>
            </div>

            <div className="-mt-4 bg-gray-700 pt-2">
                <div className="flex items-center gap-6 border-b border-gray-400 py-2 pl-6 text-gray-200">
                    <FontAwesomeIcon className="text-gray-500" icon={faArrowLeft} size="lg" />
                    <FontAwesomeIcon className="text-gray-500" icon={faArrowRight} size="lg" />
                    <FontAwesomeIcon icon={faRotateRight} size="lg" />
                    <div className="w-44 grow overflow-hidden whitespace-nowrap rounded-l-3xl bg-gray-800 px-6 py-2 text-lg">
                        <FontAwesomeIcon icon={faLock} className="mr-4 text-gray-400" size="sm" />
                        <span className="text-gray-400">https://</span>
                        <span className="text-white">{store.subdomain}</span>
                        <span className="text-gray-400">.wedo.app</span>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};
