import { AssuranceLevelCard } from 'Shared/components/signature/components/AssuranceLevelCard';
import { LegalFrameworkEnum } from 'Shared/utils/signature';

export const AssuranceLevelCardContent = ({
    assuranceLevels,
}: {
    assuranceLevels: { esiga: string; eidas: string };
}) => {
    if (assuranceLevels == null) {
        return null;
    }

    return (
        <div className="flex-col flex gap-4 ">
            <AssuranceLevelCard legalFramework={LegalFrameworkEnum.CH} signatureType={assuranceLevels.esiga} />
            <AssuranceLevelCard legalFramework={LegalFrameworkEnum.EU} signatureType={assuranceLevels.eidas} />
        </div>
    );
};
