import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faArrowRight, faBars } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag, Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceHistory } from 'Shared/types/workspace';

type SectionNameTagProps = {
    name: string;
};

const SectionNameTag: FC<SectionNameTagProps> = ({ name }) => <Tag size="xs">{name}</Tag>;

export const UpdatedSectionTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faBars}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> renamed section from{' '}
                    <SectionNameTag name={activity?.old_value?.name} /> <FontAwesomeIcon icon={faArrowRight} />{' '}
                    <SectionNameTag name={activity?.new_value?.name} />
                </Trans>
            </p>
        </Timeline.Item>
    );
};
