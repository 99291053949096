import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useLocalStorageSearchParams, useSearchParams } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { WorkspaceGrid } from 'Pages/WorkspacesPage/components/WorkspaceGrid';
import { WorkspaceTable } from 'Pages/WorkspacesPage/components/WorkspaceTable';
import { Can } from 'Shared/components/Can';
import { PageHeader } from 'Shared/components/PageHeader';
import { WorkspacesDisplayPopover } from 'Shared/components/displayPopover/WorkspacesDisplayPopover';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { type NavBarTab } from 'Shared/components/layout/NavBar/types';
import { AddWorkspaceModal } from 'Shared/components/workspace/AddWorkspaceModal/AddWorkspaceModal';
import { isUserGroupMember } from 'Shared/hooks/useIsUserGroupMember';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useGetTeamQuery } from 'Shared/services/team';
import { Permission } from 'Shared/utils/rbac';

const Tabs: Array<NavBarTab> = [
    {
        isDefault: true,
        to: { searchParams: { view: 'all' } },
        matchSearchParams: ['view'],
        title: msg({ id: 'All workspaces', message: 'All' }),
        keepSearchParams: ['status', 'layout'],
    },
    {
        to: { searchParams: { view: 'has_status' } },
        matchSearchParams: ['view'],
        title: msg({ id: 'Workspace with a status', message: 'With status' }),
        keepSearchParams: ['status', 'layout'],
    },
];

const statusList = ['open', 'archived', 'deleted'] as const;
type StatusType = (typeof statusList)[number];

export const WorkspacesPageSearchParams = {
    view: enumeration('me', 'not_member', 'has_status', 'all').default('all'),
    status: enumeration('open', 'archived', 'deleted').default('open'),
    sort: enumeration(
        'tag.name',
        'tag_status.status',
        'progression',
        'tag_status.due_date',
        '-tag.name',
        '-tag_status.status',
        '-progression',
        '-tag_status.due_date'
    ).default('tag.name'),
    layout: enumeration('grid', 'list').default('grid'),
    search: string(),
};

export const WorkspacesPage = () => {
    const sessionUser = useSessionUser();

    const toolbarRef = useRef<HTMLDivElement>(null);

    const [{ status, layout, search }, setSearchParams] = useSearchParams(WorkspacesPageSearchParams);

    const { open } = useModal();
    const { teamId } = useParams();
    const { data: team } = useGetTeamQuery({ teamId }, { skip: !teamId });

    usePageTitle(() => {
        if (team) {
            return `${team?.name} | ${t`Workspaces`}`;
        }
        return t({ id: 'Title of page workspaces', message: 'Workspaces' });
    });

    useLocalStorageSearchParams(`/teams/${team?.id}/workspaces`, ['layout', 'status']);
    useLocalStorageSearchParams(`/workspaces`, ['layout', 'status']);

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        handleSearch: (search) => setSearchParams({ search }),
        search,
        containerRef: toolbarRef,
        size: 'md',
    });

    const handleStatusChange = (status: StatusType) => setSearchParams((searchParams) => ({ ...searchParams, status }));

    const handleLayoutChange = (layout: 'list' | 'grid') =>
        setSearchParams((searchParams) => ({ ...searchParams, layout }));

    const handleReset = () => setSearchParams({ status: 'open', layout: 'grid' });

    const page = (
        <>
            {!teamId && <PageHeader title={t`Workspaces`} />}
            <div className="flex h-full max-h-full flex-col gap-6 overflow-hidden">
                <div className="px-6" ref={toolbarRef}>
                    <NavBar basePath={teamId != null ? `/teams/${teamId}/workspaces` : '/workspaces'} tabs={Tabs}>
                        {toggleButton}

                        <WorkspacesDisplayPopover
                            layout={layout}
                            status={status}
                            onLayoutChange={handleLayoutChange}
                            onStatusChange={handleStatusChange}
                            onReset={handleReset}
                        />

                        {!team?.deleted && (team == null || isUserGroupMember(sessionUser, team)) && (
                            <Can permission={Permission.AddWorkspace}>
                                <Button
                                    aria-label={t`Add workspace`}
                                    color="primary"
                                    icon={faPlus}
                                    onClick={() => open(AddWorkspaceModal, { teamId })}
                                >
                                    <span className="hidden lg:flex">
                                        <Trans>Add workspace</Trans>
                                    </span>
                                </Button>
                            </Can>
                        )}
                    </NavBar>

                    <div className="mt-2">{searchInput}</div>
                </div>
                {layout === 'list' ? <WorkspaceTable /> : <WorkspaceGrid />}
            </div>
        </>
    );

    if (teamId) {
        return page;
    }

    return (
        <Can permission={Permission.ViewWorkspaces} showNoAccess>
            {page}
        </Can>
    );
};
