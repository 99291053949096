import { Children, cloneElement, ReactElement, useId, useMemo } from 'react';
import clsx from 'clsx';
import { Label } from '~/components/Label/Label';
import { Radio, RadioProps } from '~/components/Radio/Radio';

type RadioType = 'radio' | 'button' | 'buttonGroup' | 'panel' | 'card';

type RadioGroupProps = {
    children: JSX.Element | JSX.Element[];
    radioType?: RadioType;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    value: string | boolean | number;
    name?: string;
    onChange: (selectedValue: string | boolean | number) => void;
    className?: string;
};

export type RadioGroupRadioProps = {
    type?: RadioType;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    position?: 'none' | 'start' | 'middle' | 'end';
    labelClassName?: string;
    wrapperClassName?: string;
    description?: string;
} & RadioProps;

const radioWrapperTypeBase = {
    base: 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-600 flex cursor-pointer items-center justify-center rounded-md border font-medium focus:outline-none',
    size: {
        xs: 'p-1 text-[10px]',
        sm: 'text-xs px-2.5 h-[1.875rem]',
        md: 'text-sm px-3 h-[2.125rem]',
        lg: 'text-lg px-4 h-[2.5rem]',
    },
    checked: {
        true: 'bg-blue-50 text-blue-800 border-blue-600 z-10',
        false: 'bg-white border-gray-300 text-gray-800 hover:bg-gray-50',
    },
    disabled: { true: 'opacity-50 !cursor-not-allowed', false: 'cursor-pointer focus:outline-none' },
};

const radioInputTypeBase = { base: 'sr-only' };

const classes = {
    wrapper: {
        radioType: {
            radio: 'flex flex-col gap-2',
            button: '',
            buttonGroup: 'flex',
            panel: '',
            card: 'flex',
        },
    },
    radio: {
        wrapper: {
            type: {
                button: radioWrapperTypeBase,
                buttonGroup: {
                    ...radioWrapperTypeBase,
                    position: {
                        none: '',
                        start: 'rounded-r-none focus-within:z-10',
                        middle: '!rounded-none -ml-px focus-within:z-10',
                        end: 'rounded-l-none -ml-px focus-within:z-10',
                    },
                },
                panel: {
                    ...radioWrapperTypeBase,
                    base: 'relative flex cursor-pointer p-4 focus:outline-none items-center',
                    checked: {
                        true: 'bg-blue-50 border-blue-600 z-10',
                        false: 'border-gray-200',
                    },
                    position: {
                        none: '',
                        start: 'rounded-t-md border',
                        middle: 'border -mt-px',
                        end: 'border rounded-b-md -mt-px',
                    },
                    disabled: {
                        true: 'bg-gray-100 opacity-60 !cursor-not-allowed',
                        false: '',
                    },
                },
            },
        },
        label: {
            type: {
                panel: {
                    base: 'block text-sm font-medium',
                    checked: {
                        true: 'text-blue-900',
                        false: 'text-gray-800',
                    },
                    disabled: {
                        true: 'bg-gray-200 !cursor-not-allowed',
                        false: '',
                    },
                },
            },
        },
        description: {
            type: {
                panel: {
                    base: 'block text-sm',
                    checked: {
                        true: 'text-blue-800',
                        false: 'text-gray-700',
                    },
                },
            },
        },
        input: {
            type: {
                button: radioInputTypeBase,
                buttonGroup: radioInputTypeBase,
                panel: 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer border-gray-300 text-blue-700 focus:ring-blue-600',
            },
        },
    },
};

const RadioGroupComponent = ({
    radioType = 'radio',
    size = 'md',
    name,
    children,
    value,
    onChange,
    className,
}: RadioGroupProps) => {
    const uniqueId = useId();
    const optionName = name ?? uniqueId;

    const childrenItems = useMemo(() => {
        const filteredChildren = Children.toArray(children).filter(Boolean) as ReactElement[];
        return filteredChildren.map((child, index) => {
            return cloneElement(child, {
                type: radioType,
                position:
                    radioType === 'buttonGroup' || radioType === 'panel'
                        ? index === 0
                            ? 'start'
                            : index === filteredChildren.length - 1
                              ? 'end'
                              : 'middle'
                        : null,
                name: optionName,
                checked: value === child.props.value,
                onChange: () => onChange(child.props.value),
                size: size,
            });
        });
    }, [children]);

    return <div className={clsx(classes.wrapper.radioType[radioType], className)}>{childrenItems}</div>;
};

const RadioGroupRadio = ({
    type = 'radio',
    id,
    children,
    className,
    labelClassName,
    wrapperClassName,
    size,
    description = '',
    position = 'none',
    ...props
}: RadioGroupRadioProps) => {
    const generatedId = useId();
    if (type === 'radio') {
        return (
            <div className={clsx('flex justify-start gap-2', wrapperClassName)}>
                <Radio id={id || generatedId} className={className} {...props} />
                <Label htmlFor={id || generatedId} className={labelClassName} inputType={'inline'}>
                    {children}
                </Label>
            </div>
        );
    }
    if (type === 'button') {
        return (
            <label
                className={clsx(
                    classes.radio.wrapper.type[type].base,
                    classes.radio.wrapper.type[type].checked[props.checked.toString()],
                    classes.radio.wrapper.type[type].disabled[(props.disabled ?? false).toString()],
                    classes.radio.wrapper.type[type].size[size],
                    className
                )}
            >
                <input
                    checked={props.checked}
                    type="radio"
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    className={classes.radio.input.type[type].base}
                    disabled={props.disabled}
                />
                {children}
            </label>
        );
    }
    if (type === 'buttonGroup') {
        return (
            <label
                className={clsx(
                    classes.radio.wrapper.type[type].base,
                    classes.radio.wrapper.type[type].checked[props.checked.toString()],
                    classes.radio.wrapper.type[type].disabled[(props.disabled ?? false).toString()],
                    classes.radio.wrapper.type[type].size[size],
                    classes.radio.wrapper.type[type].position[position],
                    className
                )}
            >
                <input
                    checked={props.checked}
                    type="radio"
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    className={clsx(classes.radio.input.type[type].base)}
                />
                {children}
            </label>
        );
    }
    if (type === 'panel') {
        return (
            <label
                className={clsx(
                    classes.radio.wrapper.type[type].base,
                    classes.radio.wrapper.type[type].checked[props.checked.toString()],
                    classes.radio.wrapper.type[type].position[position],
                    classes.radio.wrapper.type[type].disabled[props.disabled ? 'true' : 'false']
                )}
            >
                <Radio
                    checked={props.checked}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    name={props.name}
                    value={props.value}
                />
                <div className="ml-3 flex w-full flex-col">
                    <div
                        className={clsx(
                            classes.radio.label.type.panel.base,
                            classes.radio.label.type.panel.checked[props.checked.toString()]
                        )}
                    >
                        {children}
                    </div>
                    <span
                        className={clsx(
                            classes.radio.description.type.panel.base,
                            classes.radio.description.type.panel.checked[props.checked.toString()]
                        )}
                    >
                        {description}
                    </span>
                </div>
            </label>
        );
    }
    if (type === 'card') {
        return (
            <label
                className={clsx(
                    'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                    props.checked ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300',
                    className
                )}
            >
                <input
                    type="radio"
                    className="sr-only"
                    checked={props.checked}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    name={props.name}
                    value={props.value}
                />
                {children}
            </label>
        );
    }
    return <></>;
};

RadioGroupComponent.displayName = 'RadioGroup';
RadioGroupRadio.displayName = 'RadioGroup.Radio';

export const RadioGroup = Object.assign(RadioGroupComponent, { Radio: RadioGroupRadio });
