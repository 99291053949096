import React, { ForwardedRef, forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    MeasuringStrategy,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useConfirm } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useMeetingContext } from 'App/contexts';
import { confirmAcceptTopicSubmissionProps } from 'Pages/MyTopicsPage/hooks/useAcceptTopicSubmission';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { closestY } from 'Shared/utils/dnd';
import { getDropLocationData } from './treeUtils';
import {
    countChildren,
    MeetingTopicSubmissionItem,
    removeChildrenOf,
    SubmissionsSection,
    TreeNodeModel,
} from './utils';

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.WhileDragging,
    },
};
const indentationWidth = 20;

export type TreeNodeRenderOptions = {
    indentationWidth: number;
    depth: number;
    displayId: string;
    isOpen: boolean;
    isForbiddenDrop: boolean;
    childCount?: number;
    onCollapse?: (id: string) => void;
};

export type TreeMethods = {
    openAll(): void;
    closeAll(): void;
};

type TocTreeProps = {
    tree: TreeNodeModel[];
    isUpdating: boolean;
    canManageTopics: boolean;
    canManageSections: boolean;
    onDrop: (newTree: TreeNodeModel[]) => void;
    renderNode: (node: TreeNodeModel, options: TreeNodeRenderOptions) => ReactNode;
};
export const TocTree = forwardRef(
    (
        { tree, renderNode, onDrop, isUpdating, canManageTopics, canManageSections }: TocTreeProps,
        ref: ForwardedRef<TreeMethods>
    ) => {
        const [{ topicId }] = useSearchParams(MeetingViewSearchParams);

        const { confirm } = useConfirm();
        const { sensors } = useDndSortableVerticalStrategy();
        const { meetingId } = useMeetingContext();

        const { mutateAsync: acceptTopicSubmission } = trpc.meetingTopic.acceptSubmission.useMutation({
            onSuccess: () => {
                void trpcUtils().meetingTopic.getSubmissionForTopic.invalidate({ topicId });
                void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                void trpcUtils().meeting.listTopicSubmissions.invalidate({ meetingId });
            },
        });

        const [items, setItems] = useState(() => tree);
        const [collapsedIds, { has: isCollapsed, toggle: toggleCollapse, reset: unCollapseAll }] = useSet<string>(
            new Set()
        );
        const [draggedNode, setDraggedNode] = useState(null);
        const [overId, setOverId] = useState<string | null>(null);
        const [offsetLeft, setOffsetLeft] = useState(0);

        useEffect(() => {
            setItems(tree);
        }, [tree]);

        useImperativeHandle(ref, () => ({
            openAll: () => {
                unCollapseAll();
            },

            closeAll: () => {
                const firstLevelNodes = items.filter((node) => !node.parentId);
                firstLevelNodes.forEach((node) => !isCollapsed(node.id) && toggleCollapse(node.id));
            },
        }));

        const filteredNodes = useMemo(() => {
            if (!draggedNode && collapsedIds.size === 0) {
                return items;
            }
            return removeChildrenOf(items, draggedNode ? [draggedNode.id, ...collapsedIds] : [...collapsedIds]);
        }, [draggedNode, items, collapsedIds.size]);

        const dropLocationData =
            draggedNode && overId
                ? getDropLocationData(
                      filteredNodes,
                      draggedNode.id,
                      overId,
                      offsetLeft,
                      indentationWidth,
                      canManageSections,
                      canManageTopics
                  )
                : null;

        const isDraggedNode = (node: TreeNodeModel) => dropLocationData && draggedNode && node.id === draggedNode.id;

        const handleDragStart = ({ active }: DragStartEvent) => {
            if (isUpdating) {
                return;
            }

            const activeItem = tree.find((item) => item.id === active.id);
            setDraggedNode(activeItem);

            document.body.style.setProperty('cursor', 'grabbing');
        };

        const handleDragMove = ({ delta }: DragMoveEvent) => {
            setOffsetLeft(delta.x);
        };

        const handleDragOver = ({ over }: DragOverEvent) => {
            setOverId(String(over?.id) ?? null);
        };

        const resetState = () => {
            setOverId(null);
            setDraggedNode(null);
            setOffsetLeft(0);

            document.body.style.setProperty('cursor', '');
        };

        const handleDragCancel = () => {
            resetState();
        };

        const handleCollapse = (id: string) => {
            toggleCollapse(id);
        };

        const handleDragEnd = async ({ active, over }: DragEndEvent) => {
            resetState();

            if (dropLocationData && over && dropLocationData.canDrop && !isUpdating) {
                const { depth, parentId, displayId } = dropLocationData;
                const clonedItems: TreeNodeModel[] = JSON.parse(JSON.stringify(items));
                const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
                const draggedNodeIndex = clonedItems.findIndex(({ id }) => id === active.id);
                const draggedItem = items[draggedNodeIndex];
                const overItem = items[overIndex];
                const sectionId = overItem.isSection
                    ? (overItem.item as MeetingSection).id
                    : (overItem.item as MeetingTopic).meeting_section_id;

                clonedItems[draggedNodeIndex] = { ...draggedNode, depth, parentId, display_id: displayId };
                const sortedItems = arrayMove(clonedItems, draggedNodeIndex, overIndex);

                if ((draggedItem.item as MeetingTopic).meeting_section_id === SubmissionsSection) {
                    const shouldAccept = await confirm(confirmAcceptTopicSubmissionProps(draggedItem.item.title));

                    if (shouldAccept) {
                        await acceptTopicSubmission({
                            displayId,
                            order: overItem.item.order - 1,
                            topicSubmissionId: (draggedItem.item as MeetingTopicSubmissionItem).submissionId,
                            sectionId: sectionId == null ? undefined : sectionId,
                        });
                        setItems(sortedItems);
                    }
                    return;
                }

                setItems(sortedItems);
                onDrop(sortedItems);
            }
        };

        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestY}
                measuring={measuring}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <SortableContext items={filteredNodes} strategy={verticalListSortingStrategy}>
                    <div className="flex flex-col gap-1">
                        {filteredNodes.map((node) =>
                            renderNode(node, {
                                indentationWidth,
                                depth: isDraggedNode(node) ? dropLocationData.depth : node.depth,
                                displayId: isDraggedNode(node) ? dropLocationData.displayId : node.display_id,
                                isOpen: !isCollapsed(node.id),
                                isForbiddenDrop: isDraggedNode(node) ? !dropLocationData.canDrop : false,
                                onCollapse: node.isSection ? () => handleCollapse(node.id) : undefined,
                            })
                        )}
                    </div>
                </SortableContext>
                <DragOverlay modifiers={[restrictToFirstScrollableAncestor]}>
                    {draggedNode && dropLocationData
                        ? renderNode(draggedNode, {
                              indentationWidth,
                              depth: draggedNode.depth,
                              displayId: dropLocationData.displayId,
                              isOpen: false,
                              isForbiddenDrop: !dropLocationData.canDrop,
                              childCount: countChildren(tree, draggedNode.id),
                          })
                        : null}
                </DragOverlay>
            </DndContext>
        );
    }
);
