import React, { FC, useState } from 'react';
import { t } from '@lingui/macro';
import { isNumber } from 'lodash-es';
import { useTasksContext } from 'App/contexts/TasksContext';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { TaskPriorityIcon } from 'Shared/components/task/TaskPriority/TaskPriorityIcon';
import { TaskPriorityPickerPopover } from 'Shared/components/task/TaskPriority/TaskPriorityPickerPopover';
import { Task } from 'Shared/types/task';
import { taskPriority } from 'Shared/utils/task';

export const PriorityRow: FC<BulkEditTaskRowProps<Task['priority']>> = ({ value: priority, onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonPriority } = useBulkTasksEditPane(selectedTasks);

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(undefined);
        } else if (change === 'edit') {
            onChange(commonPriority);
        } else if (change === 'clear') {
            onChange(0);
        }
    };

    return (
        <BulkEditRow
            label={t`Priority`}
            data-testid="bulk-priority-popover"
            changeOption={changeValue}
            onChangeOptionChange={handleChange}
            isResetDisabled={commonPriority === 0}
        >
            <TaskPriorityPickerPopover
                variant="outlined"
                size="sm"
                className="mt-2"
                priority={priority}
                data-testid="bulk-priority-popover"
                onChange={(priority, close) => {
                    onChange(priority);
                    close();
                }}
                label={
                    isNumber(priority) ? (
                        <span className="flex items-center gap-2">
                            <TaskPriorityIcon priority={priority} size="sm" />
                            {taskPriority[priority].label}
                        </span>
                    ) : (
                        t`Choose priority`
                    )
                }
            />
        </BulkEditRow>
    );
};
