import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { NavLink, Tooltip } from '@wedo/design-system';
import { TranslatedNavBarLink } from 'Shared/components/layout/NavBar/types';

export const selectedNavBarLinkClasses =
    'text-gray-800 after:absolute relative after:-bottom-[calc(0.5rem-2px)] after:h-0.5 after:w-full after:bg-gray-500';

export const NavBarLink = forwardRef<HTMLAnchorElement, TranslatedNavBarLink>(
    ({ title, count, showCountPlus = false, className, onClick, hidden, tooltip, ...navLinkOptions }, ref) => {
        return (
            <Tooltip content={tooltip}>
                <NavLink
                    ref={ref}
                    onClick={onClick}
                    className={(isActive) =>
                        clsx(
                            'group inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-transparent',
                            isActive ? selectedNavBarLinkClasses : 'text-gray-500',
                            className
                        )
                    }
                    {...navLinkOptions}
                >
                    {title}
                    {count > 0 && (
                        <span
                            className={
                                'ml-2 rounded-full bg-gray-200 px-2 text-center text-xs text-gray-700 group-hover:bg-gray-400'
                            }
                        >
                            {count}
                            {showCountPlus && '+'}
                        </span>
                    )}
                </NavLink>
            </Tooltip>
        );
    }
);
