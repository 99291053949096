import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { getRandomWedoColor, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useInvitedOnboardingUser } from 'Pages/onboarding/hooks/useInvitedOnboardingUser';
import { useNewOrganizationSchema } from 'Pages/onboarding/hooks/useNewOrganizationSchema';
import {
    NewOrganizationAction,
    NewOrganizationType,
    UPDATE_USER,
    useOnboardingStore,
} from 'Pages/onboarding/utils/onboardingStore';
import {
    useActivateUserInNetworkMutation,
    useFinishOnboardingForUserMutation,
    useFinishOnboardingMutation,
    useGetIsRestrictedDomainQuery,
} from 'Shared/services/onboarding';
import { Team } from 'Shared/types/team';
import { User } from 'Shared/types/user';

export const useOnboarding = () => {
    const store = useOnboardingStore();
    const params = useParams();
    const navigate = useNavigate();

    const { data: { isRestricted: isRestrictedDomain } = { isRestrictedDomain: false } } =
        useGetIsRestrictedDomainQuery(store.domainFromEmail);

    const newOrganizationSchema = useNewOrganizationSchema({ isRestrictedDomain });

    const { show } = useNotification();
    const { invitedUser, isExistingNetworkLinkedToInvitation, isNetworkActive } = useInvitedOnboardingUser();
    const [searchParams] = useSearchParams();

    const [finishOnboardingMutation, { data: finishOnboardingResponse, isLoading: isFinishingOnboarding }] =
        useFinishOnboardingMutation();
    const [
        finishOnboardingForUserMutation,
        { data: finishOnboardingForUserResponse, isLoading: isFinishOnboardingForUserLoading },
    ] = useFinishOnboardingForUserMutation();
    const [sendActivateUser, { error: activateUserError }] = useActivateUserInNetworkMutation();

    const finishOnboardingData = invitedUser ? finishOnboardingForUserResponse : finishOnboardingResponse;

    const isFinishLoading = isFinishOnboardingForUserLoading || isFinishingOnboarding;
    const userId = params.userId ?? searchParams.get('user-id');

    const willContinueWithOrganizationStep =
        isEmpty(userId) || (isExistingNetworkLinkedToInvitation && !isNetworkActive);

    const getZodResult = (changes: Partial<NewOrganizationType>) => {
        const newState: NewOrganizationType = { ...store, ...changes };
        return newOrganizationSchema.safeParse(newState);
    };

    const zodSafeParse = newOrganizationSchema.safeParse(store);
    const zodErrors = zodSafeParse?.success === true ? [] : zodSafeParse?.error?.issues;

    const getError = (field: keyof NewOrganizationType) => zodErrors?.find((error) => error.path.includes(field));

    const getErrorAtIndex = (field: keyof NewOrganizationType, index: number) =>
        zodErrors?.find((error) => error.path.includes(field) && error.path[1] === index);

    const getUserChanges = (value: Partial<User> & { team?: Partial<Team>; network_id?: Id }) => {
        const changes: Partial<NewOrganizationType> = {};
        if (!value) {
            return {};
        }
        if (value.id) {
            changes.userId = value.id;
        }
        if (value.team?.network_id) {
            changes.networkId = value.team?.network_id;
        }
        if (value.first_name) {
            changes.firstName = value.first_name;
        }
        if (value.last_name) {
            changes.lastName = value.last_name;
        }
        const title =
            value?.title || value?.userNetworks?.find((un) => un?.network_id === value?.network_id)?.title || '';
        if (title) {
            changes.jobTitle = title;
        }
        if (value.language_code) {
            changes.language = value.language_code;
        }
        if (value.organization_name) {
            changes.organizationName = value.organization_name;
        }
        if (value.userNetworks) {
            changes.networks = value.userNetworks.map((network) => ({
                networkId: network.network_id,
                status: network.status,
            }));
        }
        const emailAddress = value?.userEmails?.[0]?.email_address;
        if (emailAddress) {
            changes.email = emailAddress;
        }
        return changes;
    };

    const updateKey = ({ key, value }: NewOrganizationAction) => {
        let changes: Partial<NewOrganizationType> = {};
        switch (key) {
            case UPDATE_USER:
                changes = getUserChanges(value);
                break;
            case 'networks':
                break;
            case 'originalUser':
                changes.originalUser = value;
                break;
            case 'invites':
                changes.invites = value.map((v) => v.trimStart());
                break;
            case 'workspaces':
                changes.workspaceNames = value.map((v: string) => v.trimStart());
                break;
            default:
                changes[key] = (value?.toString() || '')?.trimStart?.();
        }

        store.actions.updateState(changes);
    };

    const updateKeys = (updates: NewOrganizationAction[]) => {
        updates.forEach((update) => {
            updateKey(update);
        });
    };

    const finishOnboarding = async (): Promise<string> => {
        const userEmails = store.invites
            .filter((invite) => invite.trim() !== '')
            .map((invite) => ({ email: isRestrictedDomain ? invite : `${invite}@${store.domainFromEmail}` }));

        const onboardingObject = {
            user: {
                jobTitle: store.jobTitle,
                email: store.email,
            },
            organization: {
                name: store.organizationName,
                website: store.organizationWebsite,
                industry: store.organizationIndustry,
                size: store.organizationSize,
            },
            network: {
                teamName: store.teamName,
                userEmails: userEmails,
                workspaces: store.workspaceNames.map((name) => ({
                    name: name,
                    color: { background: getRandomWedoColor(), text: '#fff' },
                })),
                subdomain: store.subdomain,
            },
        };

        const finishOnboardingFunction = invitedUser ? finishOnboardingForUserMutation : finishOnboardingMutation;

        const res = await finishOnboardingFunction({
            onboarding: onboardingObject,
            token: store.token,
            userId: invitedUser?.id,
        });

        if ('error' in res) {
            if (
                res.error.code === 'ValidationError' &&
                res.error.data?.errors?.[0]?.property?.details?.[0]?.path?.[1] === 'userEmails'
            ) {
                show({
                    title: t`Invalid teammate email`,
                    message: t`At least one of the emails you provided is invalid`,
                    type: 'danger',
                });
            } else {
                show(UnexpectedErrorNotification);
            }
            return null;
        }

        if ('data' in res && 'authLink' in res.data) {
            updateKey({ key: 'authLink', value: res.data.authLink });
            return res.data.authLink;
        }
        return null;
    };

    const activateUser = async () => {
        await sendActivateUser({ userId: invitedUser.id, token: store.token });

        if (activateUserError) {
            show({
                type: 'danger',
                title: activateUserError.message,
            });

            return;
        }

        navigate('/');
    };

    return {
        ...store,
        updateKey,
        updateKeys,
        finishOnboarding,
        getZodResult,
        activateUser,
        getError,
        getErrorAtIndex,
        finishOnboardingData,
        isFinishLoading,
        willContinueWithOrganizationStep,
        zodErrors,
        isRestrictedDomain,
    };
};
