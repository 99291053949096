import { FC } from 'react';
import { Switch } from '@wedo/design-system';

type CopyRadioOptionProps = { label: string; isChecked: boolean; onChange: (value: boolean) => void };

export const CopyRadioOption: FC<CopyRadioOptionProps> = ({ label, isChecked, onChange }) => {
    return (
        <Switch.Group>
            <Switch.Label className="ml-6 select-none text-sm md:ml-0 md:text-right">{label}</Switch.Label>
            <Switch checked={isChecked} onChange={onChange} />
        </Switch.Group>
    );
};
