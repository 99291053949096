import { useLingui } from '@lingui/react';
import { addDays } from 'date-fns';
import { FormatDateRelative } from '@wedo/design-system';
import { getDateFnsLocale } from '@wedo/utils';

export const AutomaticDeletionCell = ({ userNetwork: { disabledAt } }: { userNetwork: { disabledAt: string } }) => {
    const { i18n } = useLingui();
    if (disabledAt == null) {
        return null;
    }
    const deletedAt = addDays(new Date(disabledAt), 30);
    return <FormatDateRelative date={deletedAt} locale={getDateFnsLocale(i18n.locale)} />;
};
