import { LocaleDefinition } from '@wedo/types';

export const frFR: LocaleDefinition = {
    DATETIME_FORMATS: {
        AMPMS: ['AM', 'PM'],
        DAY: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        FIRSTDAYOFWEEK: 0,
        MONTH: [
            'janvier',
            'f\u00e9vrier',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'ao\u00fbt',
            'septembre',
            'octobre',
            'novembre',
            'd\u00e9cembre',
        ],
        SHORTDAY: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        SHORTMONTH: [
            'janv.',
            'f\u00e9vr.',
            'mars',
            'avr.',
            'mai',
            'juin',
            'juil.',
            'ao\u00fbt',
            'sept.',
            'oct.',
            'nov.',
            'd\u00e9c.',
        ],
        WEEKENDRANGE: [5, 6],
        fullDate: 'eeee d MMMM y',
        longDate: 'd MMMM y',
        medium: 'd MMM y HH:mm:ss',
        mediumDate: 'd MMM y',
        mediumTime: 'HH:mm:ss',
        short: 'dd/MM/yyyy, HH:mm',
        shortDate: 'dd/MM/yyyy',
        shortTime: 'HH:mm',
    },
    NUMBER_FORMATS: {
        DECIMAL_SEP: ',',
        GROUP_SEP: '\u00a0',
    },
};
