import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';

type CaretProps = {
    icon: IconDefinition;
    onClick?: () => void;
    text: string;
    disabled?: boolean;
};

export const CaretNav = ({ icon, onClick = () => null, text, disabled = false }: CaretProps) => {
    return (
        <Button variant="outlined" disabled={disabled} onClick={() => !disabled && onClick()} size="sm">
            <span className="sr-only">{text}</span>
            <FontAwesomeIcon icon={icon} aria-hidden="true" />
        </Button>
    );
};
