import React from 'react';
import { t } from '@lingui/macro';
import { Card } from '@wedo/design-system';
import { CalendarSync } from 'Shared/components/calendar/CalendarSync/CalendarSync';

export const CalendarSyncSettingsPage = () => {
    return (
        <Card>
            <Card.Header title={t`Calendar sync`} />
            <Card.Body>
                <CalendarSync />
            </Card.Body>
        </Card>
    );
};
