import React, { FC } from 'react';
import { faCircleMinus, faHourglass } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Dropdown, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useAppDispatch } from 'App/store';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { taskActivitiesTag, taskApi } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';
import { Task } from 'Shared/types/task';

const arrayToMap = (dependencies: Array<{ taskId: Id; canInvertDependency: boolean }>) =>
    new Map(dependencies.map(({ taskId, canInvertDependency }) => [taskId, canInvertDependency]));

// isBlocking implies that main task is blocking item task
export const BlockedDropdown: FC<{ isBlocking?: boolean; mainTaskId: Id; itemTask: Task; isDisabled?: boolean }> = ({
    mainTaskId,
    itemTask,
    isBlocking = false,
    isDisabled = false,
}) => {
    const dispatch = useAppDispatch();

    const { isTaskReadonly: isItemTaskReadonly } = useTaskStatus(itemTask);
    const { data: allTasksBlockedByMainTask = [], isLoading: isBlockedTasksLoading } =
        trpc.task.listCanInvertDependency.useQuery(
            { blockedTaskId: Number(mainTaskId) },
            { enabled: mainTaskId != null }
        );
    const { data: allTasksBlockingMainTask = [], isLoading: isBlockingTasksLoading } =
        trpc.task.listCanInvertDependency.useQuery(
            { blockingTaskId: Number(mainTaskId) },
            { enabled: mainTaskId != null }
        );

    const { mutate: invertDependency, isLoading } = trpc.task.invertDependency.useMutation({
        onSuccess: () => {
            invalidateCachedTasks();
            dispatch(taskApi.util.invalidateTags([taskActivitiesTag(mainTaskId), taskActivitiesTag(itemTask.id)]));
        },
    });

    const canInvertBlockedTasks = arrayToMap(allTasksBlockedByMainTask);
    const canInvertBlockingTasks = arrayToMap(allTasksBlockingMainTask);
    const isDependencyGraphLoading = isBlockingTasksLoading || isBlockedTasksLoading;
    const canBeMovedToBlockedTask = !canInvertBlockedTasks.has(itemTask?.id) || canInvertBlockedTasks.get(itemTask?.id);
    const canBeMovedToBlockingTask =
        !canInvertBlockingTasks.has(itemTask?.id) || canInvertBlockingTasks.get(itemTask?.id);

    const handleSetToBlocked = async () => {
        invertDependency({ blockedTaskId: Number(itemTask.id), blockingTaskId: Number(mainTaskId) });
    };

    const handleSetToBlocking = async () => {
        invertDependency({ blockedTaskId: Number(mainTaskId), blockingTaskId: Number(itemTask.id) });
    };

    return (
        <Dropdown
            title={isBlocking ? t`Blocking` : t`Blocked by`}
            icon={isBlocking ? faCircleMinus : faHourglass}
            variant="text"
            className={clsx(isItemTaskReadonly ? '!text-gray-500' : isBlocking ? 'text-yellow-600' : 'text-red-600')}
            size="sm"
            disabled={isDisabled || isDependencyGraphLoading}
            iconClassName="w-3"
            loading={isLoading}
        >
            <Tooltip content={!canBeMovedToBlockedTask && <Trans>This will create a circular dependency</Trans>}>
                <Dropdown.Item
                    selected={!isBlocking}
                    icon={faHourglass}
                    disabled={!canBeMovedToBlockedTask}
                    onClick={handleSetToBlocked}
                    iconClassName={clsx('text-red-600 hover:text-red-800')}
                >
                    <Trans>Blocked by</Trans>
                </Dropdown.Item>
            </Tooltip>
            <Tooltip content={!canBeMovedToBlockingTask && <Trans>This will create a circular dependency</Trans>}>
                <Dropdown.Item
                    selected={isBlocking}
                    icon={faCircleMinus}
                    disabled={!canBeMovedToBlockingTask}
                    onClick={handleSetToBlocking}
                    iconClassName={isBlocking ? 'text-yellow-600' : 'text-yellow-600 hover:text-yellow-800'}
                >
                    <Trans>Blocking</Trans>
                </Dropdown.Item>
            </Tooltip>
        </Dropdown>
    );
};
