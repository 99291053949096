import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { DataAttributesCompany, IntercomProps, useIntercom } from 'react-use-intercom';
import {
    faAddressCard,
    faArrowRightFromBracket,
    faBook,
    faCode,
    faCog,
    faEarth,
    faInfoCircle,
    faLifeRing,
    faMessagesQuestion,
    faPhonePlus,
    faPresentationScreen,
    faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Bubble, Button, Dropdown, useModal } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useSessionUser } from 'App/store/usersStore';
import { clientVersion } from 'App/store/versionStore';
import { AboutModal } from 'Pages/AppPage/AboutModal';
import { IntercomBlockedModal } from 'Pages/AppPage/IntercomBlockedModal';
import { BadgeLevel } from 'Pages/BadgesPage/BadgeLevel';
import { DemoToolsModal } from 'Shared/components/DemoToolsModal';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useLogout } from 'Shared/hooks/useLogout';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { useGetCurrentNetworkQuery } from 'Shared/services/network';
import { trpc } from 'Shared/trpc';
import { UserRole } from 'Shared/types/user';
import { getScoreFromBadges } from 'Shared/utils/badge';

export const ProfileDropdown = ({
    isMobile,
    isCollapsed,
    className,
}: {
    isCollapsed?: boolean;
    isMobile?: boolean;
    className?: string;
}) => {
    const logout = useLogout();
    const { data: network } = useGetCurrentNetworkQuery();
    const currentUser = useSessionUser();
    const { open } = useModal();
    const { pathname } = useLocation();
    return (
        <Dropdown
            dropdownClassName={!isMobile ? 'w-60 !min-w-0' : 'w-[19rem] !max-w-[calc(100%_-_4.5rem)] '}
            variant={'ghost'}
            color="light"
            className={clsx('w-full flex-1 truncate', className)}
            title={isCollapsed && !isMobile && <Trans>Profile and settings</Trans>}
            aria-label={t`Profile and settings`}
            label={
                <div
                    className={clsx(
                        'flex items-center rounded-md py-1 hover:cursor-pointer hover:bg-gray-700',
                        isCollapsed ? 'justify-center pl-1.5 pr-1.5 text-center' : 'gap-3 pl-2 pr-2 text-start'
                    )}
                >
                    <UserAvatar user={currentUser} size="sm" showTooltip={false} />
                    <div
                        className={clsx(
                            'transition-width-opacity flex flex-col truncate',
                            isCollapsed ? 'w-0 opacity-0' : 'w-auto flex-1 opacity-100'
                        )}
                    >
                        <div className="truncate text-xs font-medium text-gray-200">{currentUser?.full_name}</div>
                        <div className="truncate text-xs text-gray-400">{currentUser?.userEmail?.email_address}</div>
                    </div>
                </div>
            }
        >
            {network?.organisation?.type === 'demo' && currentUser?.role === UserRole.ADMIN && (
                <Dropdown.Item
                    onClick={() => open(DemoToolsModal, {})}
                    icon={faPresentationScreen}
                    className="py-2 font-bold bg-gradient-blue !text-white hover:opacity-80"
                    iconClassName="font-bold !text-white"
                >
                    <Trans>Demo</Trans>
                </Dropdown.Item>
            )}

            {network?.organisation?.type === 'demo' && __ENVIRONMENT__ !== 'production' && (
                <Dropdown.LinkItem to="/dev-tools" icon={faCode} selected={pathname === '/dev-tools'}>
                    <Trans>Dev tools</Trans>
                </Dropdown.LinkItem>
            )}

            <Dropdown.LinkItem
                to={`/users/${currentUser?.id}/profile`}
                icon={faAddressCard}
                selected={pathname === `/users/${currentUser?.id}/profile`}
            >
                <Trans>Profile</Trans>
            </Dropdown.LinkItem>
            <Dropdown.LinkItem to="/settings/profile" icon={faCog} selected={pathname === '/settings/profile'}>
                <Trans>Settings</Trans>
            </Dropdown.LinkItem>
            <Dropdown.DividerItem />
            <Dropdown.Item onClick={() => logout()} icon={faArrowRightFromBracket}>
                <Trans>Log out</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};

type SidebarFooterProps = {
    isCollapsed?: boolean;
    isMobile?: boolean;
};

export const SidebarFooter = ({ isCollapsed = false, isMobile = false }: SidebarFooterProps) => {
    const { unreadIntercomMessageCount, userLanguage } = useCurrentUserContext();
    const currentUser = useSessionUser();
    const { data: session } = trpc.session.getCurrent.useQuery();
    const { data: currentUserBadges } = trpc.badge.list.useQuery({});
    const badgeScore = getScoreFromBadges(currentUserBadges);
    const { data: network } = useGetCurrentNetworkQuery();
    const { open } = useModal();
    const { show, update } = useIntercom();
    const navigate = useNavigate();
    const getPreference = usePreferences();
    const hasGamification = getPreference('gamification', false);
    const { mutateAsync: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    if (currentUser != null && network != null && session != null) {
        update({
            userId: currentUser.id.toString(),
            name: currentUser.first_name + ' ' + currentUser.last_name,
            email: currentUser.email_address,
            avatar: {
                type: 'avatar',
                imageUrl: currentUser.photo_url,
            },
            userHash: session.user.hash,
            languageOverride: userLanguage,
            company: {
                companyId: network.id,
                name: network.name,
            } as DataAttributesCompany,
            customAttributes: {
                full_name: currentUser.full_name,
                role: currentUser.role,
                title: currentUser.title,
                badge_level: currentUser.badge_level,
                badge_completion: currentUser.badge_completion,
                package_version: clientVersion(),
                short_name: network.short_name,
                status: network.organisation.status,
                type: network.organisation.type,
            },
        } as IntercomProps);
    }

    const handleShowIntercom = () => {
        if (show) {
            show();
        } else {
            open(IntercomBlockedModal);
        }
    };
    return (
        <>
            <div
                className={clsx(
                    'transition-width flex flex-col gap-2 overflow-hidden border-t border-gray-600 p-2 text-white'
                )}
            >
                <div className={clsx('flex items-center', isCollapsed ? 'flex-col gap-2' : 'gap-0.5')}>
                    <ProfileDropdown
                        className={isCollapsed && 'hidden'}
                        isCollapsed={isCollapsed}
                        isMobile={isMobile}
                    />
                    {!isCollapsed && hasGamification && (
                        <Button
                            onClick={() => navigate('/badges')}
                            variant="ghost"
                            className={'flex items-center rounded-md py-1 px-2 hover:cursor-pointer hover:bg-gray-700'}
                        >
                            <BadgeLevel score={badgeScore} size={32} />
                        </Button>
                    )}
                </div>

                <div className={clsx(isCollapsed ? 'flex gap-2 justify-center' : 'hidden')}>
                    <ProfileDropdown isCollapsed={isCollapsed} isMobile={isMobile} />
                </div>
                <div className={'relative flex justify-center'}>
                    <Dropdown
                        variant={'ghost'}
                        color="light"
                        offset={[0, 8]}
                        dropdownClassName={!isMobile ? 'w-60 !min-w-0' : 'w-[19rem] !max-w-[calc(100%_-_4.5rem)]'}
                        className={clsx(
                            'transition-width bg-gradient-neon relative flex h-8 w-full items-center justify-center overflow-hidden rounded-md px-2 py-0.5 text-gray-200 hover:bg-gray-700 hover:opacity-90',
                            isCollapsed ? 'w-10' : 'w-full'
                        )}
                        title={isCollapsed && <Trans>Help and support</Trans>}
                        label={
                            <>
                                <span className="sr-only">
                                    <Trans>Open help menu</Trans>
                                </span>
                                <FontAwesomeIcon
                                    icon={faLifeRing}
                                    className={clsx(
                                        'transition-width-opacity h-4 justify-self-center text-gray-100',
                                        isCollapsed ? 'w-4 opacity-100' : 'w-0 opacity-0'
                                    )}
                                    aria-hidden="true"
                                />
                                <div
                                    className={clsx('transition-width-opacity', isCollapsed ? 'w-0 opacity-0' : 'w-48')}
                                >
                                    <Trans>Help and support</Trans>
                                </div>
                            </>
                        }
                    >
                        <Dropdown.Item
                            onClick={handleShowIntercom}
                            icon={faMessagesQuestion}
                            className="py-2 font-bold bg-gradient-blue !text-white hover:opacity-80"
                            iconClassName="font-bold !text-white"
                        >
                            <div id="intercom-button" className="intercom-launcher flex">
                                <span className="intercom-text">
                                    <Trans>Chat with us</Trans>
                                </span>
                                {unreadIntercomMessageCount > 0 && (
                                    <Bubble className="ml-2" size={'md'} text={String(unreadIntercomMessageCount)} />
                                )}
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => window.open('https://wedo.com')} icon={faEarth}>
                            wedo.com
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={async () => {
                                await registerBadgeActivity('VISIT_HELP_CENTER_1');
                                window.open('https://help.wedo.com');
                            }}
                            icon={faBook}
                        >
                            <Trans>Help center</Trans>
                        </Dropdown.Item>

                        <Dropdown.Item
                            onClick={() => {
                                if (userLanguage === 'fr') {
                                    window.open('https://www.youtube.com/channel/UCepY2Z-X8fjO8oUIZxgG78w');
                                } else if (userLanguage === 'de') {
                                    window.open('https://www.youtube.com/channel/UC0GAct7PtRLLcl5HZPx6qkQ');
                                } else {
                                    window.open('https://www.youtube.com/channel/UCUL9bTsch6P0XdpMAf8IUpQ');
                                }
                            }}
                            icon={faVideo}
                        >
                            <Trans>YouTube</Trans>
                        </Dropdown.Item>

                        <Dropdown.Item
                            onClick={() => {
                                if (userLanguage === 'fr') {
                                    window.open('https://calendly.com/yann-wedo/support-fr');
                                } else if (userLanguage === 'de') {
                                    window.open('https://calendly.com/yann-wedo/support-de');
                                } else {
                                    window.open('https://calendly.com/yann-wedo/support-en');
                                }
                            }}
                            icon={faPhonePlus}
                        >
                            <Trans>Book a call</Trans>
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                window.location.href = 'mailto:chris@wedo.com?subject=' + t`WEDO Training Request`;
                            }}
                            icon={faPresentationScreen}
                        >
                            <Trans>Request training</Trans>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => open(AboutModal)} icon={faInfoCircle}>
                            <Trans>About</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                    {unreadIntercomMessageCount > 0 && (
                        <Bubble
                            size="md"
                            className={'absolute -right-1 -top-1'}
                            text={String(unreadIntercomMessageCount)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
