import { Editor } from 'slate';
import { tryOrFalse } from '@wedo/utils';

export type StyleFormat = 'bold' | 'italic' | 'underlined' | 'strikethrough';

export const hasStyle = (editor: Editor, style: string) => {
    return tryOrFalse(() => Editor.marks(editor)[style] === true);
};

export const format = (editor: Editor, code: StyleFormat) => {
    Editor.addMark(editor, code, !hasStyle(editor, code));
};
