import React, { useMemo, useReducer } from 'react';
import { t } from '@lingui/macro';
import { CloseSource, ContextModalProps, Modal, Steps } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { PhoneNumber } from 'Pages/settings/security/components/TotpModalContent/PhoneNumber';
import { QrCode } from 'Pages/settings/security/components/TotpModalContent/QrCode';
import { SecurityCode } from 'Pages/settings/security/components/TotpModalContent/SecurityCode';
import { VerifyPassword } from 'Pages/settings/security/components/TotpModalContent/VerifyPassword';
import { TotpContext, totpContextInit } from 'Pages/settings/security/utils/TotpContext';
import { totpReducer } from 'Pages/settings/security/utils/totpReducer';
import { StageEnable } from 'Pages/settings/security/utils/type';

export const EnableTotpModal = ({
    close,
    force2fa = false,
    children,
    ...modalProps
}: ContextModalProps & { force2fa: boolean }): JSX.Element => {
    const { currentUser } = useCurrentUserContext();
    const [state, dispatch] = useReducer(totpReducer, { ...totpContextInit, force2fa });

    const ModalContent = useMemo(() => {
        switch (state.stage) {
            case StageEnable.Password:
                return VerifyPassword;
            case StageEnable.QrCode:
                return QrCode;
            case StageEnable.PhoneNumber:
                return PhoneNumber;
            case StageEnable.SecurityCode:
                return SecurityCode;
            default:
                void close();
                return () => <div>&nbsp;</div>;
        }
    }, [state.stage, close]);

    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    const handleBeforeClose = async (source: CloseSource): Promise<boolean> =>
        source !== 'backdrop-or-esc' && (source !== 'cross' || !currentUser?.force_2fa);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Modal {...modalProps} size={'lg'} onBeforeClose={handleBeforeClose}>
            <Modal.Header title={t`Enable two-factor authentication`} />
            <Steps className={'!rounded-none'}>
                <Steps.Step index={1} currentIndex={state.stage + 1}>
                    {t`Verify password`}
                </Steps.Step>
                <Steps.Step index={2} currentIndex={state.stage + 1}>
                    {t`Scan QR code`}
                </Steps.Step>
                <Steps.Step index={3} currentIndex={state.stage + 1}>
                    {t`Verify phone`}
                </Steps.Step>
                <Steps.Step index={4} currentIndex={state.stage + 1}>
                    {t`Recovery codes`}
                </Steps.Step>
            </Steps>
            <TotpContext.Provider value={contextValue}>
                <ModalContent />
            </TotpContext.Provider>
            {children}
        </Modal>
    );
};
