import { FC, MouseEvent } from 'react';
import { faHeartCircleMinus, faHeartCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Dropdown } from '@wedo/design-system';

type FavoriteToggleDropdownItemProps = {
    isFavorite: boolean;
    onClick?: (event: MouseEvent) => void;
    isLoading?: boolean;
};

export const FavoriteToggleDropdownItem: FC<FavoriteToggleDropdownItemProps> = ({
    isFavorite,
    isLoading = false,
    onClick,
}) => {
    return (
        <Dropdown.Item icon={isFavorite ? faHeartCircleMinus : faHeartCirclePlus} onClick={onClick} loading={isLoading}>
            {!isFavorite && <Trans>Add to favorite</Trans>}
            {isFavorite && <Trans>Remove from favorite</Trans>}
        </Dropdown.Item>
    );
};
