import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useRef } from 'react';
import { faUserSlash } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { UserItem } from 'Shared/components/user/UserPicker/UserItem';
import { User } from 'Shared/types/user';

export type UserPickerVirtualScrollProps = {
    searchWords: string[];
    onUserSelected: (user: User) => Promise<void>;
    users: User[];
    selectedUsers: User[];
    selectedIndex: number;
    showNobody?: boolean;
};

export const UserPickerVirtualScroll = ({
    searchWords,
    onUserSelected,
    users,
    selectedUsers,
    selectedIndex,
    showNobody,
}: UserPickerVirtualScrollProps) => {
    const parentRef = useRef<HTMLDivElement>();

    const rowVirtualizer = useVirtualizer({
        count: users.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 35,
        getItemKey: (index) => users[index].id,
    });

    return (
        <div ref={parentRef} className="overflow-y-auto scrollbar-light">
            {showNobody && searchWords.length === 0 && (
                <div>
                    <Button
                        variant="text"
                        size="noText"
                        className="!justify-start px-2 py-1 hover:!bg-gray-100 w-full !bg-transparent flex !gap-1 items-center"
                        onClick={() => onUserSelected(null)}
                    >
                        <div className="border-1 h-7 w-7 rounded-full border border-gray-300 bg-gray-50">
                            <FontAwesomeIcon icon={faUserSlash} className="text-sm" />
                        </div>
                        <div className={'text-sm font-medium leading-7'}>{t`Nobody`}</div>
                    </Button>
                </div>
            )}
            <div className="w-full relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                    <div
                        key={virtualItem.key}
                        className="absolute w-full top-0 left-0"
                        style={{
                            height: `${virtualItem.size}px`,
                            transform: `translateY(${virtualItem.start}px)`,
                        }}
                    >
                        <UserItem
                            user={users[virtualItem.index]}
                            searchWords={searchWords}
                            onUserSelected={onUserSelected}
                            className="w-full items-center pl-2"
                            isSelected={virtualItem.index === selectedIndex}
                            isChecked={selectedUsers.some(({ id }) => virtualItem.key === id)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
