import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { faMagnifyingGlass, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { tokenize } from 'ss-search';
import { Select, SelectProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { isEmpty } from '@wedo/utils';
import { selectCurrentWorkspaceId } from 'Pages/meeting/MeetingViewSlice';
import { Highlighter } from 'Shared/components/Highlighter';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { trpc } from 'Shared/trpc';
import { Workspace } from 'Shared/types/workspace';
import { computeTeamWithWorkspacesAndTemplates } from 'Shared/utils/team';

interface WorkspaceSelectProps {
    withoutCurrent?: boolean;
    nullSelectionLabel?: string | JSX.Element;
    hideNoWorkspace?: boolean;
    disabled?: boolean;
    value?: string | string[];
    onChange?: (value: string | string[]) => void;
    container?: string;
    isMultiple?: boolean;
    className?: string;
    wrapperClassName?: string;
    size?: SelectProps['size'];
    position?: SelectProps['position'];
    placeholder?: string;
    isClearable?: boolean;
}

export const NoWorkspaceFound = () => (
    <div className="flex h-full flex-col items-center justify-center gap-2">
        <FontAwesomeIcon icon={faMagnifyingGlass} className="rounded-full bg-gray-100 p-4 text-2xl text-gray-500" />
        <p className="text-gray-700">
            <Trans>No workspaces found</Trans>
        </p>
    </div>
);

export const WorkspaceLabel = ({
    workspace,
    searchWords = [],
}: {
    workspace: Workspace;
    searchWords?: string[];
}): JSX.Element => (
    <div className="flex max-w-full items-center gap-2 truncate">
        <WorkspaceIcon workspace={workspace} size="sm" />
        <span className={'truncate'}>
            <Highlighter searchWords={searchWords} textToHighlight={workspace.name} />
        </span>
    </div>
);

const NoWorkspaceLabel = ({ nullSelectionLabel = null }: { nullSelectionLabel?: string | JSX.Element }) => (
    <div className={'flex max-w-full items-center gap-2 truncate'}>
        <WorkspaceIcon size="sm" />
        <span className={'truncate'}>
            {nullSelectionLabel ? nullSelectionLabel : <Trans>Not in a workspace</Trans>}
        </span>
    </div>
);

const renderWorkspaces = (
    workspaces: Workspace[],
    withoutCurrent?: boolean,
    currentWorkspaceId?: Id,
    searchWords?: string[]
): JSX.Element[] =>
    workspaces
        .filter((workspace) => !withoutCurrent || workspace.id !== currentWorkspaceId)
        .map((workspace) => (
            <Select.Option key={workspace.id} value={workspace.id.toString()} className="!py-1">
                <WorkspaceLabel workspace={workspace} searchWords={searchWords} />
            </Select.Option>
        ));

export const WorkspaceSelect = ({
    value,
    disabled,
    className = '',
    wrapperClassName = '',
    onChange,
    withoutCurrent = false,
    nullSelectionLabel,
    hideNoWorkspace,
    container,
    isMultiple = false,
    position,
    size = 'md',
    placeholder,
    isClearable = false,
}: WorkspaceSelectProps): ReactElement => {
    const { data: teams, isLoading } = trpc.team.list.useQuery({});
    const { data: workspaces } = trpc.workspace.list.useQuery({});

    const teamsWithWorkspacesAndTemplates = useMemo(
        () => computeTeamWithWorkspacesAndTemplates(teams, workspaces, null, true),
        [teams, workspaces]
    );

    const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);
    const [search, setSearch] = useState('');
    const [searchWords, setSearchWords] = useState<string[]>([]);

    const filteredTeamsAndWorkspaces = useMemo(
        () =>
            teamsWithWorkspacesAndTemplates.map((team) => {
                return {
                    ...team,
                    workspaces: team.workspaces?.filter((workspace) =>
                        workspace.name.toLowerCase().includes(search.toLowerCase().trim())
                    ),
                };
            }),
        [teamsWithWorkspacesAndTemplates, search]
    );

    const handleSearch = (value: string) => {
        setSearch(value);
        setSearchWords(tokenize(value));
    };

    const containerProps = container ? { getPopupContainer: () => document.getElementById(container) } : {};
    const computedPlaceholder = placeholder ?? t`Select workspace`;

    return (
        <Select
            position={position}
            value={value}
            onChange={(value: string | string[] | number | number[]) => onChange(value as string | string[])}
            onSearch={handleSearch}
            popoverClassName={'!min-w-[16rem]'}
            placeholder={isLoading ? <FontAwesomeIcon icon={faSpinner} className="fa-pulse" /> : computedPlaceholder}
            disabled={disabled}
            multiple={isMultiple}
            className={clsx(disabled && 'opacity-60 hover:cursor-not-allowed', className)}
            wrapperClassName={wrapperClassName}
            isClearable={isClearable}
            customRenderSelected={(valueA: string | string[] | number | number[]) => {
                const value = valueA as string | string[];
                if (value === '-1') {
                    return <NoWorkspaceLabel nullSelectionLabel={nullSelectionLabel} />;
                }
                if (value instanceof Array) {
                    const selected = workspaces?.filter(({ id }) => value.includes(id)) || [];
                    if (value.includes('-1')) {
                        selected.push({ name: t`Not in a workspace` } as Workspace);
                    }
                    return <div>{selected?.map((p) => p.name).join(', ')}</div>;
                }
                const workspace = workspaces?.find(({ id }) => id === value);
                return workspace && <WorkspaceLabel workspace={workspace} />;
            }}
            size={size}
            {...containerProps}
        >
            {!hideNoWorkspace && search.length === 0 && !(withoutCurrent && currentWorkspaceId == null) && (
                <Select.Option key="-1" value="-1" className="!py-1">
                    <NoWorkspaceLabel nullSelectionLabel={nullSelectionLabel} />
                </Select.Option>
            )}
            {filteredTeamsAndWorkspaces
                .filter((team) => team.workspaces?.length > 0)
                .map((team) =>
                    team.id == null ? (
                        renderWorkspaces(team.workspaces, withoutCurrent, currentWorkspaceId, searchWords)
                    ) : (
                        <Select.OptionGroup key={team.id} label={team.name} className="text-xs font-bold uppercase">
                            {renderWorkspaces(team.workspaces, withoutCurrent, currentWorkspaceId, searchWords)}
                        </Select.OptionGroup>
                    )
                )}
            {filteredTeamsAndWorkspaces.filter((team) => team.workspaces?.length > 0)?.length === 0 &&
                !isEmpty(search) && <NoWorkspaceFound />}
        </Select>
    );
};
