import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { Editor } from 'slate';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { TopicHeader } from 'Shared/components/meeting/topicView/TopicHeader';
import { MeetingPermission, useUserHasMeetingSectionPermission } from 'Shared/types/meeting';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type InlineTopicProps = {
    topic: MeetingTopic;
    initialBlocks: MeetingBlock[];
};

export const InlineTopic = forwardRef<HTMLDivElement, InlineTopicProps>(
    ({ topic, initialBlocks }, ref): JSX.Element => {
        const editorRef = useRef<Editor>();
        const { currentUser } = useCurrentUserContext();

        const containerRef = useRef<HTMLDivElement>();
        const contentHeight = useRef(0);
        const [placeholderHeights, setPlaceholderHeight] = useState({ minHeight: 0, height: 0 });

        const intersectionObserver = useMemo(() => {
            return new IntersectionObserver(
                ([{ isIntersecting, target }]) => {
                    const height = target.getBoundingClientRect().height;
                    setPlaceholderHeight({
                        minHeight: isIntersecting ? height : 0,
                        height: isIntersecting ? 0 : height,
                    });
                },
                {
                    root: document.getElementById('meeting-body'),
                    threshold: 0,
                }
            );
        }, []);

        const mutationObserver = useMemo(() => {
            return new MutationObserver(() => {
                if (containerRef.current.children.length > 1) {
                    const newContentHeight = containerRef.current.lastElementChild.getBoundingClientRect().height;
                    if (newContentHeight !== contentHeight.current) {
                        setPlaceholderHeight(({ minHeight, height }) => ({
                            minHeight: minHeight + (newContentHeight - contentHeight.current),
                            height,
                        }));
                        contentHeight.current = newContentHeight;
                    }
                }
            });
        }, []);

        const { hasPermission: canEditTopicContent } = useUserHasMeetingSectionPermission(
            currentUser,
            topic.meeting,
            topic.meeting_section_id,
            MeetingPermission.EDIT_TOPIC_CONTENT
        );

        const handleRef = useCallback((element: HTMLDivElement) => {
            ref(element);
            containerRef.current = element;
            if (element != null) {
                intersectionObserver.observe(element);
                mutationObserver.observe(element, { subtree: true, childList: true });
            } else {
                intersectionObserver.disconnect();
                mutationObserver.disconnect();
            }
        }, []);

        return (
            <div
                ref={handleRef}
                id={`topic-${topic.id}`}
                className="flex flex-col rounded bg-white gap-2"
                style={{ minHeight: `${placeholderHeights.minHeight}px` }}
            >
                {placeholderHeights.height > 0 ? (
                    <div style={{ height: `${placeholderHeights.height}px` }}></div>
                ) : (
                    <>
                        <TopicHeader editorRef={editorRef} topic={topic} />
                        <TopicEditor
                            shouldAutoHideToolbar
                            editorRef={editorRef}
                            topic={topic}
                            isReadOnly={!canEditTopicContent}
                            isVoteOnly={!canEditTopicContent}
                            initialBlocks={initialBlocks}
                            className="-my-2"
                            viewMode={MeetingViewMode.DocumentView}
                        />
                    </>
                )}
            </div>
        );
    }
);
