import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { ActivityLog } from '@wedo/db';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useUser } from 'App/store/usersStore';
import { User } from 'Shared/types/user';

const getChangedKeys = (oldUser: User, newUser: User) => {
    return [
        isEqual(oldUser.color, newUser.color) ? undefined : t`color`,
        isEqual(oldUser.title, newUser.title) ? undefined : `title`,
        isEqual(oldUser.initials, newUser.initials) ? undefined : t`initials`,
        isEqual(oldUser.department, newUser.department) ? undefined : t`department`,
        isEqual(oldUser.first_name, newUser.first_name) ? undefined : t`first name`,
        isEqual(oldUser.last_name, newUser.last_name) ? undefined : t`last name`,
        isEqual(oldUser.display_name, newUser.display_name) ? undefined : t`display name`,
        isEqual(oldUser.language_code, newUser.language_code) ? undefined : t`language code`,
        isEqual(oldUser.phone_number, newUser.phone_number) ? undefined : t`phone number`,

        isEqual(oldUser?.userNetwork?.role, newUser?.userNetwork?.role) ? undefined : t`role`,
        isEqual(oldUser?.userNetwork?.title, newUser?.userNetwork?.title) ? undefined : t`title`,
    ].filter(Boolean);
};

const UserLinkButton: FC<{ userId: Id }> = ({ userId }) => {
    const user = useUser(userId);

    return (
        <Button size="xs" variant="link" href={`/users/${userId}/profile`} className="w-full">
            <div className="text-xs group/meeting flex items-center gap-1 w-full font-semibold truncate">
                <span className="truncate">{user?.full_name}</span>
            </div>
        </Button>
    );
};

const UserProfileUpdatedText: FC<{ oldUser: User; newUser: User }> = ({ oldUser, newUser }) => {
    const changedKeys = getChangedKeys(oldUser, newUser);

    if (changedKeys.length === 0) {
        return (
            <div className="text-xs flex gap-2 items-center font-semibold">
                <Trans>
                    Updated <UserLinkButton userId={oldUser.id} />
                </Trans>
            </div>
        );
    }

    return (
        <div className="text-xs truncate flex gap-1 items-center font-semibold">
            <Trans>
                Changed {changedKeys.join(', ')} for <UserLinkButton userId={oldUser.id} />
            </Trans>
        </div>
    );
};

const UpdatedUserText: FC<{ oldUser: User; newUser: User }> = ({ oldUser, newUser }) => {
    const changedKeys = getChangedKeys(oldUser, newUser);

    if (changedKeys.includes(t`role`)) {
        return (
            <div className="text-xs truncate flex gap-1 items-center font-semibold">
                <Trans>
                    Changed user role for <UserLinkButton userId={oldUser?.id} /> from {oldUser?.userNetwork?.role}{' '}
                    {'-->'} {newUser?.userNetwork?.role}
                </Trans>
            </div>
        );
    }

    if (changedKeys.includes(t`title`)) {
        return (
            <div className="text-xs truncate flex gap-1 items-center font-semibold">
                <Trans>
                    Changed user title for <UserLinkButton userId={oldUser.id} /> from {oldUser?.userNetwork?.title}{' '}
                    {'-->'} {newUser?.userNetwork?.title}
                </Trans>
            </div>
        );
    }

    return (
        <div className="text-xs flex gap-2 items-center font-semibold">
            <Trans>
                Updated <UserLinkButton userId={oldUser.id} />
            </Trans>
        </div>
    );
};

const RemovedUserPhotoTitle: FC<{ userId: Id }> = ({ userId }) => {
    return (
        <div className="text-xs truncate flex gap-1 items-center font-semibold">
            <Trans>
                Removed user photo for <UserLinkButton userId={userId} />
            </Trans>
        </div>
    );
};

const ChangedUserPhotoTitle: FC<{ userId: Id }> = ({ userId }) => {
    return (
        <div className="text-xs truncate flex gap-1 items-center font-semibold">
            <Trans>
                Changed user photo for <UserLinkButton userId={userId} />
            </Trans>
        </div>
    );
};

export const UserModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    return (
        <>
            {auditLog.eventKey === 'added_user' && (
                <span className="text-xs">
                    <UserLinkButton userId={auditLog.objectId} />
                </span>
            )}

            {auditLog.eventKey === 'deleted_user' && (
                <span className="text-xs">
                    <UserLinkButton userId={auditLog.objectId} />
                </span>
            )}

            {auditLog.eventKey === 'user_profile_update' && (
                <UserProfileUpdatedText oldUser={auditLog.oldValue as User} newUser={auditLog.newValue as User} />
            )}

            {auditLog.eventKey === 'updated_user' && (
                <UpdatedUserText oldUser={auditLog.oldValue as User} newUser={auditLog.newValue as User} />
            )}

            {auditLog.eventKey === 'removed_user_photo' && <RemovedUserPhotoTitle userId={auditLog.objectId} />}

            {auditLog.eventKey === 'changed_user_photo' && <ChangedUserPhotoTitle userId={auditLog.objectId} />}
        </>
    );
};
