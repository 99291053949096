import { Svg, Rect, Path, G, Defs, ClipPath } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const BoxComIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Rect width="500" height="500" transform="translate(6 6)" fill="#0061D5" />
            <G clip-path="url(#clip0_1800_26483)">
                <Path
                    d="M453 340.141C458 347.141 457 356.141 451 361.141C444 366.141 434 365.141 429 359.141L394 314.141L360 358.141C355 365.141 345 365.141 338 360.141C331 355.141 330 346.141 335 339.141L375 287.141L335 235.141C330 228.141 332 218.141 338 213.141C345 208.141 355 210.141 360 216.141L394 261.141L429 218.141C434 211.141 443 210.141 451 215.141C458 220.141 458 230.141 453 237.141L414 288.141L453 340.141ZM271 334.141C245 334.141 224 314.141 224 288.141C224 263.141 245 242.141 271 242.141C297 242.141 318 263.141 318 288.141C317 314.141 296 334.141 271 334.141ZM133 334.141C107 334.141 86 314.141 86 288.141C86 263.141 107 242.141 133 242.141C159 242.141 180 263.141 180 288.141C180 314.141 159 334.141 133 334.141ZM271 212.141C242 212.141 216 228.141 203 252.141C190 228.141 164 212.141 134 212.141C116 212.141 100 218.141 87 227.141V163.141C87 155.141 80 148.141 72 148.141C63 148.141 56 155.141 56 163.141V289.141C57 331.141 91 364.141 133 364.141C163 364.141 189 347.141 202 323.141C215 347.141 241 364.141 270 364.141C313 364.141 348 330.141 348 287.141C349 246.141 314 212.141 271 212.141Z"
                    fill="white"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1800_26483">
                    <Rect width="400" height="216.949" fill="white" transform="translate(56 147.667)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};
