import { MarkJS } from 'react-mark.js';

export const mark = (search: string, marker: MarkJS, isUnmark = true) => {
    if (search != null && marker != null) {
        if (isUnmark) {
            marker.unmark();
        }
        setTimeout(() => {
            marker.mark(search, {
                className: 'bg-blue-200 text-blue-800',
                exclude: ['.ignore-marker'],
            });
        }, 300);
    }
};
