import React, { FC } from 'react';
import { fromNavigator } from '@lingui/detect-locale';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';
import { getPlatform, parseUserAgent } from '@wedo/utils';
import { clientVersion } from 'App/store/versionStore';
import { trpc } from 'Shared/trpc';

const PropItem: FC<{ label: string; value?: string }> = ({ label, value }) => (
    <div className="flex gap-2">
        <div className="font-semibold flex-1 text-right text-gray-700">{label}</div>
        <div className="flex-1 text-gray-500">{value}</div>
    </div>
);

export const AboutModal: FC<ContextModalProps> = ({ ...modalProps }) => {
    const { browser } = parseUserAgent();
    const browserLanguage = fromNavigator();
    const browserTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { data: versionInfo } = trpc.api.getVersion.useQuery();
    const { data: session } = trpc.session.getCurrent.useQuery();

    return (
        <Modal {...modalProps}>
            <Modal.Body>
                <div className="flex flex-col items-center">
                    <img alt={t`WEDO logo`} src="/assets/logo/logo.svg" className="mb-14 mt-6 w-36" />
                    <div className="flex flex-col text-sm justify-center gap-1 w-full">
                        <PropItem label={t`Environment`} value={__ENVIRONMENT__} />
                        <PropItem label={t`Version`} value={clientVersion() + ' - ' + versionInfo?.version} />
                        <PropItem label={t`Browser timezone`} value={browserTimezone} />
                        <PropItem
                            label={t`Short name`}
                            value={session?.networks.find(({ id }) => id === session.networkId).shortName}
                        />
                        <PropItem label={t`User language`} value={session?.user.languageCode} />
                        <PropItem label={t`Platform`} value={getPlatform()} />
                        <PropItem label={t`Browser`} value={browser.name} />
                        <PropItem label={t`Browser version`} value={browser.version} />
                        <PropItem label={t`Browser language`} value={browserLanguage} />
                    </div>
                    <span className="text-xs text-gray-500 mt-14 text-center">
                        {`Copyright © 2015-${new Date().getFullYear()} WEDO. All rights reversed.`}
                        <br />
                        WEDO is a registered trademark of WEDO SA.
                        <br />
                        Fribourg, Switzerland.
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
