import React from 'react';
import { t, Trans } from '@lingui/macro';
import { UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { useResetPasswordMutation } from 'Shared/services/admin';
import { ApiError } from 'Shared/types/apiError';

export const usePasswordResetModal = (email_address: string, full_name: string) => {
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const [resetPassword] = useResetPasswordMutation();

    const handlePasswordReset = async () => {
        const result = await resetPassword(email_address);

        if ('data' in result) {
            show({
                title: t`Password reset sent successfully`,
                type: 'success',
            });
        }

        if ('error' in result && result.error instanceof ApiError) {
            show(UnexpectedErrorNotification);
        }
    };

    const open = () =>
        confirm({
            title: t`Password reset`,
            confirmText: t`Confirm`,
            content: (
                <Trans>
                    Do you want to send a password reset link to{' '}
                    <strong>
                        {email_address} ({full_name})
                    </strong>
                    ?
                </Trans>
            ),
            onConfirm: handlePasswordReset,
            type: 'primary',
        });

    return { open };
};
