import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import {
    Button,
    Label,
    NoInternetErrorNotification,
    Textarea,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { useUpdateNetworkConfigMutation } from 'Shared/services/network';
import { ApiError } from 'Shared/types/apiError';
import { NetworkConfig } from 'Shared/types/network';
import { isFetchError } from 'Shared/utils/rtkQuery';
import { SAML_CARD_CACHE_KEY } from './SamlAuthCard';

type IdentityProviderMetadataSectionProps = {
    className?: string;
    config: NetworkConfig['saml'];
    hasMadeChanges: boolean;
};

export const IdentityProviderMetadataSection: React.FC<IdentityProviderMetadataSectionProps> = ({
    className,
    config,
    hasMadeChanges,
}) => {
    const { show } = useNotification();

    const [idpMetadata, setIdpMetadata] = useState<string>(config?.idp_metadata);
    const [isMetadataInvalid, setIsMetadataInvalid] = useState<boolean>(false);
    const [updateConfig, { isLoading }] = useUpdateNetworkConfigMutation({
        fixedCacheKey: SAML_CARD_CACHE_KEY,
    });

    const clearErrors = () => {
        setIsMetadataInvalid(false);
    };

    const hasUserMadeChanges = !isEqual(idpMetadata?.trim(), config?.idp_metadata) || hasMadeChanges;

    const isSaveButtonDisabled = !hasUserMadeChanges || isEmpty(idpMetadata?.trim());

    const handleSave = () => {
        void updateConfig({
            enable: true,
            idp_metadata: idpMetadata,
            entryPoint: '',
            issuer: '',
            certificate: '',
        }).then((response) => {
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else if (
                    error.matches({ path: 'Metadata is not valid' }) ||
                    error.matches({ path: 'EntryPoint is empty' })
                ) {
                    setIsMetadataInvalid(true);
                } else {
                    show(UnexpectedErrorNotification);
                }
            } else {
                clearErrors();
            }
        });
    };

    return (
        <div className={className}>
            <Label>
                <Trans>Identity Provider metadata (XML)</Trans>
                <Textarea
                    rows={10}
                    value={idpMetadata}
                    onChange={(e) => setIdpMetadata(e.target.value)}
                    status={isMetadataInvalid ? 'error' : 'default'}
                    statusText={isMetadataInvalid && t`Metadata is invalid`}
                    wrapperClassName="whitespace-pre-wrap break-words"
                />
            </Label>

            <Button
                color="primary"
                disabled={isSaveButtonDisabled || isLoading}
                onClick={handleSave}
                loading={isLoading}
                className="mt-6"
            >
                <Trans>Save</Trans>
            </Button>
        </div>
    );
};
