//TODO: retrieve this from the actual tailwind config
const media = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
};

export const getBreakpointValue = (breakpoint: string): number => {
    if (!media[breakpoint]) {
        return null;
    }
    return media[breakpoint];
};
