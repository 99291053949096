import { create } from 'zustand';
import { BadgeType } from '~/components/Badge/Badge';

export type BadgesStoreState = {
    badges: { id: string; name: string; value: number; type: BadgeType; identifier?: string }[];
};

export type BadgesStoreActions = {
    actions: {
        add: (badge, identifier?: string) => void;
        remove: (id: string) => void;
    };
};

export const useBadgesStore = create<BadgesStoreState & BadgesStoreActions>((set, get) => ({
    badges: [],
    actions: {
        add: (badge, identifier) => {
            set(({ badges }) => {
                return { badges: [...badges, { ...badge, identifier }].slice(-3) };
            });
        },
        remove: (id) => {
            set(({ badges }) => {
                return { badges: badges.filter((badge) => badge.identifier !== id) };
            });
        },
    },
}));
