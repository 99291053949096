import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPipe } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { getColorId } from '@wedo/design-system';

type TimelineTaskHandleProps = {
    position: 'start' | 'end';
    color: string;
    onPointerDown: () => void;
};

export const TimelineTaskHandle = ({ position, color, onPointerDown }: TimelineTaskHandleProps) => {
    return (
        <div
            className={clsx(
                `absolute w-2 h-full flex items-center justify-center bg-white border transition-transform-opacity border-${getColorId(color)}-300 opacity-0 group-[:hover:not(.connected)]:opacity-100 group-[:not(.connected)]:cursor-ew-resize`,
                position === 'start'
                    ? 'rounded-l-sm left-0 border-r-0 group-[:hover:not(.connected)]:-translate-x-full'
                    : 'rounded-r-sm right-0 border-l-0 group-[:hover:not(.connected)]:translate-x-full'
            )}
            onPointerDown={onPointerDown}
        >
            <FontAwesomeIcon icon={faPipe} className={`h-2 text-${getColorId(color)}-300`} />
        </div>
    );
};
