import { HttpMethod } from '@wedo/types';
import { currentNetworkWorkspacesTag } from 'Shared/services/workspace';
import { baseApi } from './base';
import { currentNetworkTeamsTag, teamTagType } from './team';

export const userApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [teamTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getNetworkData: build.query<string, void>({
                query: () => ({
                    url: '/api/networks/datasets/',
                    method: HttpMethod.Get,
                }),
            }),

            loadDataset: build.mutation<void, string>({
                query: (dataset) => ({
                    url: `/api/networks/datasets/${dataset}`,
                    method: HttpMethod.Post,
                    body: {},
                }),
                invalidatesTags: [currentNetworkTeamsTag, currentNetworkWorkspacesTag],
            }),

            uploadDataset: build.mutation<void, FormData>({
                query: (body) => ({
                    url: '/api/networks/datasets/',
                    method: HttpMethod.Post,
                    body: body,
                }),
                invalidatesTags: [currentNetworkTeamsTag, currentNetworkWorkspacesTag],
            }),

            resetNetworkData: build.mutation<void, void>({
                query: () => ({
                    url: '/api/networks/datasets/',
                    method: HttpMethod.Delete,
                    body: {},
                }),
                invalidatesTags: [currentNetworkTeamsTag, currentNetworkWorkspacesTag],
            }),
        }),
    });

export const {
    useLazyGetNetworkDataQuery,
    useLoadDatasetMutation,
    useResetNetworkDataMutation,
    useUploadDatasetMutation,
} = userApi;
