import { FC, useMemo } from 'react';
import { faUserXmark } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useLoader } from '@wedo/utils/hooks';
import { UserData } from 'Shared/components/user/UserData';
import { MemberAccessRightDropdown } from 'Shared/components/userGroup/MemberAccessRightDropdown';
import { User, UserRole } from 'Shared/types/user';
import { Member } from 'Shared/types/userGroup';
import { isLastModerator } from 'Shared/utils/userGroup';

type ManageMembersTableRowProps = {
    members: Member[];
    teamMembers: Member[];
    member: Member;
    canBeDeleted: (member: Member) => boolean;
    entityType: 'workspace' | 'template' | 'team';
    isReadOnly?: boolean;
    onToggleUser: (userId: Id, isModerator: boolean) => Promise<boolean>;
    onDeleteUser: (user: User) => Promise<void>;
};

const AccessRightReadonly: FC<{ isModerator: boolean }> = ({ isModerator }) => (
    <div
        className={clsx(
            'flex justify-center items-center text-xs h-[1.875rem] px-2.5 border border-gray-300 bg-white rounded flex-row min-w-[120px] font-bold',
            isModerator ? 'text-blue-600' : 'text-green-600'
        )}
    >
        {isModerator ? <Trans>Moderator</Trans> : <Trans>Member</Trans>}
    </div>
);

export const ManageMembersTableRow: FC<ManageMembersTableRowProps> = ({
    members,
    teamMembers,
    member,
    isReadOnly = false,
    canBeDeleted,
    entityType,
    onDeleteUser,
    onToggleUser,
}) => {
    const teamMember = teamMembers?.find(({ user_id }) => user_id === member.user_id);

    const moderatorAccessRightsIrrevocable = member.user.role === UserRole.ADMIN || isLastModerator(member, members);
    const isModeratorDisabled =
        isReadOnly || member.user.role === UserRole.LIGHT || member.user.role === UserRole.EXTERNAL;
    const isModerator = member.is_moderator || member.user.role === UserRole.ADMIN;
    const isReadWriteDisabled = isReadOnly || (isModerator && moderatorAccessRightsIrrevocable);

    const { isLoading: isDeleting, wrap } = useLoader();

    const moderatorDisabledTooltipMessage = useMemo(() => {
        if (member.user.role === UserRole.LIGHT) {
            return t`Light users can't be assigned as moderators`;
        }
        return '';
    }, [member.user.role]);

    const readWriteDisabledTooltipMessage = useMemo(() => {
        if (member.user.role === UserRole.ADMIN)
            return t`This user's moderator right can't be revoked as they are an Administrator`;
        if (isLastModerator(member, members))
            return t`This user's moderator access rights can't be revoked as this user is the last moderator`;
        if (teamMember?.is_moderator && entityType !== 'team') {
            return t`This user's moderator access rights can't be revoked as this user is moderator of the team`;
        }
        return '';
    }, [teamMember?.is_moderator, member.user.role]);

    const handleIsModeratorChanged = (isModerator: boolean) => {
        return onToggleUser(member.user_id, isModerator);
    };

    const handleDeleteUser = async () => {
        await wrap(onDeleteUser(member.user));
    };

    return (
        <Table.Row key={member.user_id} className="flex !w-full items-center justify-between">
            <Table.Cell className="w-full">
                <UserData user={member.user} />
            </Table.Cell>

            <Table.Cell className="px-2 text-center">
                {isReadOnly ? (
                    <AccessRightReadonly isModerator={isModerator} />
                ) : (
                    <MemberAccessRightDropdown
                        isModerator={isModerator}
                        entityType={entityType}
                        onIsModeratorChanged={handleIsModeratorChanged}
                        isSelectReadWriteDisabled={isReadWriteDisabled || isDeleting}
                        isSelectModeratorDisabled={isModeratorDisabled || isDeleting}
                        moderatorOptionTooltip={moderatorDisabledTooltipMessage}
                        readWriteOptionTooltip={readWriteDisabledTooltipMessage}
                    />
                )}
            </Table.Cell>

            {!isReadOnly && (
                <Table.Cell className="px-2 text-center">
                    <Button
                        icon={faUserXmark}
                        variant="text"
                        color="danger"
                        disabled={!canBeDeleted(member)}
                        loading={isDeleting}
                        size={'md'}
                        onClick={handleDeleteUser}
                    />
                </Table.Cell>
            )}
        </Table.Row>
    );
};
