import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { FeedItem } from 'Pages/activityFeed/FeedItem/FeedItem';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { ActivityLog } from 'Shared/types/activityLog';
import { Comment } from 'Shared/types/comment';
import { Task } from 'Shared/types/task';

export const FeedItemCommentedTask = ({ activityFeed }: { activityFeed: ActivityLog }): JSX.Element => {
    const { open } = useModal();
    const createdBy = activityFeed.created_by.full_name;
    const { task, comment } = activityFeed.object as { task: Task; comment: Comment };

    const handleClick = (): void => {
        open(TaskModal, { taskId: task.id });
    };
    return (
        <>
            <FeedItem icon={faComment} color="blue">
                <FeedItem.Wrapper color="blue" isDark>
                    <Button
                        variant="ghost"
                        className="cursor-pointer px-5 py-3 text-left !whitespace-normal"
                        onClick={handleClick}
                    >
                        <Trans>
                            <b>{createdBy}</b> commented the task <b>{task.name}</b>
                        </Trans>
                        <div className="mt-2 flex gap-1 italic">
                            <FontAwesomeIcon icon={faQuoteLeft} />
                            <>&nbsp;{comment.comment}&nbsp;</>
                            <FontAwesomeIcon icon={faQuoteRight} />
                        </div>
                    </Button>
                </FeedItem.Wrapper>
                <FeedItem.Footer createdAt={new Date(activityFeed.created_at)} createdBy={activityFeed.created_by} />
            </FeedItem>
        </>
    );
};
