import { KeyboardEvent } from 'react';
import { ReactEditor } from 'slate-react';
import { Editor, Node as SlateNode, Transforms } from 'slate';
import { Plugin } from 'Shared/components/editor/Editor';
import { selectedBlock } from 'Shared/components/editor/utils/block';
import { format } from 'Shared/components/editor/utils/text';

const isCtrlOrMetaKey = (event: KeyboardEvent) => event.ctrlKey || event.metaKey;

export const keyboardPlugin = (): Plugin => ({
    onKeyDown: (editor, event) => {
        if (isCtrlOrMetaKey(event)) {
            if (event.key === 'b' || event.key === 'g' || event.key === 'f') {
                format(editor, 'bold');
                return true;
            }
            if (event.key === 'i' || event.key === 'k') {
                format(editor, 'italic');
                return true;
            }
            if (event.key === 'u') {
                format(editor, 'underlined');
                return true;
            }
            if (event.key === 'a') {
                const selected = selectedBlock(editor);
                if (selected != null) {
                    event.preventDefault();
                    const [selectedNode, selectedPath] = selected;
                    if (
                        Editor.isVoid(editor, selectedNode) ||
                        SlateNode.string(selectedNode) === Editor.string(editor, editor.selection)
                    ) {
                        Transforms.setSelection(editor, {
                            anchor: Editor.start(editor, []),
                            focus: Editor.end(editor, []),
                        });
                    } else {
                        const [anchor, focus] = Editor.edges(editor, selectedPath);
                        Transforms.setSelection(editor, { anchor, focus });
                    }
                    return true;
                }
            }
        }
        if (event.key === 'Escape') {
            ReactEditor.deselect(editor);
        }
        return false;
    },
});
