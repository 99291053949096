import { faTextSize } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';

export const CustomFieldGroupTag = () => {
    return (
        <Tag icon={faTextSize}>
            <Trans>Group</Trans>
        </Tag>
    );
};
