import { Element } from 'slate';
import { Plugin } from '../Editor';

export const staticBlocksPlugin = (blocks: Element[]): Plugin => ({
    initialize: (editor) => {
        editor.children = blocks;
        editor.onChange();
    },
    renderElement: (editor, { element }, previousElement) => {
        return element.id != null && previousElement != null && <div className="relative">{previousElement}</div>;
    },
});
