import { KeyboardEvent } from 'react';
import { useEvent } from './useEvent';

export const useKey = (key: string, handler: (event: KeyboardEvent) => void) => {
    useEvent('keydown', (event: KeyboardEvent) => {
        if (event.key === key) {
            handler(event);
        }
    });
};
