import {
    ChargebeePaymentsDataConfig,
    ChargebeeRenewalEstimate,
    ChargebeeSubscription,
    HttpMethod,
    Id,
    SubscriptionData,
} from '@wedo/types';
import { LanguageCode } from '@wedo/utils';
import { CacheTag } from 'Shared/services/cacheTag';
import { Organization } from 'Shared/types/organization';
import { baseApi, configureTag } from './base';

export const { tag: organizationTag, tagType: organizationTagType } = configureTag(CacheTag.ORGANIZATION);

export const currentOrganizationTag = organizationTag('current-organization');
export const OrganizationSubscription = organizationTag('subscription');

const organizationApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [organizationTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getOrganization: build.query<Organization, void>({
                query: () => 'admin/organisation',
                providesTags: [currentOrganizationTag],
            }),

            updateOrganization: build.mutation<Organization, Partial<Organization>>({
                query: (organisation) => ({
                    url: 'admin/organisation',
                    method: HttpMethod.Put,
                    body: organisation,
                }),
                invalidatesTags: [currentOrganizationTag],
            }),

            getInvoiceUrl: build.query<{ download_url: string; open_url: string }, string>({
                query: (invoiceId) => `admin/organisation/invoices/${invoiceId}/pdf`,
                providesTags: [currentOrganizationTag],
            }),

            getSubscriptionEstimate: build.query<
                ChargebeeRenewalEstimate,
                SubscriptionData & { replace_coupon_list?: boolean }
            >({
                query: (payload) => ({
                    url: '/api/admin/organisation/estimate',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                providesTags: [currentOrganizationTag],
            }),

            getExistingSubscriptionHostedPage: build.query<
                ChargebeePaymentsDataConfig,
                SubscriptionData & { subscription: { id: Id } }
            >({
                query: (payload) => ({
                    url: '/api/admin/organisation/generate_checkout_existing',
                    method: HttpMethod.Post,
                    body: payload,
                }),
            }),

            getNewSubscriptionHostedPage: build.query<ChargebeePaymentsDataConfig, SubscriptionData>({
                query: (payload) => ({
                    url: '/api/admin/organisation/generate_checkout_new',
                    method: HttpMethod.Post,
                    body: payload,
                }),
            }),

            createSubscription: build.mutation<Organization, SubscriptionData>({
                query: (payload) => ({
                    url: '/api/admin/organisation/subscription',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: [currentOrganizationTag],
            }),

            updateSubscription: build.mutation<Organization, SubscriptionData>({
                query: (payload) => ({
                    url: '/api/admin/organisation/subscription',
                    method: HttpMethod.Put,
                    body: payload,
                }),
                invalidatesTags: [currentOrganizationTag],
            }),

            reactivateSubscription: build.mutation<void, void>({
                query: () => ({
                    url: 'api/admin/organisation/subscription/cancellation',
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentOrganizationTag],
            }),

            getUpdatedPaymentsMethodData: build.mutation<ChargebeePaymentsDataConfig, SubscriptionData>({
                query: (payload) => ({
                    url: '/api/admin/organisation/generate_update_payment_method',
                    method: HttpMethod.Post,
                    body: payload,
                }),
            }),

            getSubscription: build.query<ChargebeeSubscription, void>({
                query: () => `organisation/subscription`,
                providesTags: [OrganizationSubscription],
            }),

            applyCouponCode: build.mutation<{ status: 'valid' | 'invalid' }, string>({
                query: (couponCode) => ({
                    url: '/api/admin/coupon_codes',
                    method: HttpMethod.Post,
                    body: { coupon_code: couponCode },
                }),
            }),

            computeSubscriptionEstimate: build.mutation<ChargebeeRenewalEstimate, SubscriptionData>({
                query: (payload) => ({
                    url: '/api/admin/organisation/estimate',
                    method: HttpMethod.Post,
                    body: payload,
                }),
            }),

            refreshOrganizationStatus: build.mutation<Organization, void>({
                query: () => ({
                    url: '/api/organisation/refresh_status',
                    method: HttpMethod.Post,
                }),
                invalidatesTags: [currentOrganizationTag],
            }),

            createOrganizationTrial: build.mutation<
                Organization,
                {
                    full_name: string;
                    name: string;
                    email: string;
                    language_code: LanguageCode;
                }
            >({
                query: (payload) => ({
                    url: '/api/organisations/trials',
                    method: HttpMethod.Post,
                    body: payload,
                }),
            }),

            createPendingCustomer: build.mutation<
                Organization,
                {
                    name: string;
                    website: string;
                    type: string;
                    is_in_trial: 'true' | 'false';
                    industry: Organization['cf_industry'];
                    company_size: Organization['cf_company_size'];
                    sales_rep: string;
                    free_trial_end_date: string;
                    adminUser: {
                        first_name: string;
                        last_name: string;
                        email_address: string;
                        language_code: LanguageCode;
                    };
                    network: {
                        short_name: string;
                        domains: string[];
                    };
                    invite: boolean;
                }
            >({
                query: (payload) => ({
                    url: '/api/admin/organisations',
                    method: HttpMethod.Post,
                    body: payload,
                }),
            }),
        }),
    });

export const {
    useGetOrganizationQuery,
    useLazyGetOrganizationQuery,
    useUpdateOrganizationMutation,
    useGetInvoiceUrlQuery,
    useGetSubscriptionEstimateQuery,
    useCreateSubscriptionMutation,
    useUpdateSubscriptionMutation,
    useReactivateSubscriptionMutation,
    useGetUpdatedPaymentsMethodDataMutation,
    useGetExistingSubscriptionHostedPageQuery,
    useGetNewSubscriptionHostedPageQuery,
    useApplyCouponCodeMutation,
    useComputeSubscriptionEstimateMutation,
    useRefreshOrganizationStatusMutation,
    useLazyGetSubscriptionQuery,
    useCreateOrganizationTrialMutation,
    useCreatePendingCustomerMutation,
} = organizationApi;
