export const extractEmailDomainFromEmail = (email: string): string => {
    const emailSplit = email.split('@');

    if (emailSplit.length < 2) {
        return '';
    }

    return emailSplit[1];
};

export const emailProviders = [
    '@gmail.',
    '@yahoo.',
    '@hotmail.',
    '@aol.',
    '@msn.',
    '@wanadoo.',
    '@orange.',
    '@comcast.',
    '@live.',
    '@rediffmail.',
    '@free.',
    '@bluewin.',
    '@bluemail.',
    '@gmx.',
];
