import { FC, MutableRefObject, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, CollapsiblePaneHandle, ContextModalProps, Modal, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray, getIdMapping } from '@wedo/utils';
import { setDiff } from '@wedo/utils/set';
import { LabelTag } from 'Shared/components/LabelRectangle';
import { ClearTag } from 'Shared/components/bulkEdit/ClearTag';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { BulkEditTableRow } from 'Shared/components/file/BulkEditFilesPane/BulkEditTableRow';
import { useFiles } from 'Shared/hooks/files/useFiles';
import { useGetLabelsQuery } from 'Shared/services/label';
import { Attachment, TreeItem } from 'Shared/types/attachment';
import { Label } from 'Shared/types/label';

type BulkEditFilesConfirmModalProps = {
    labels: string[];
    changeOption: ChangeOption;
    panelRef?: MutableRefObject<CollapsiblePaneHandle>;
} & ContextModalProps;

export const BulkEditFilesConfirmModal: FC<BulkEditFilesConfirmModalProps> = ({
    labels,
    panelRef,
    changeOption,
    ...modalProps
}) => {
    const { selectedFiles, addLabelInFile, removeLabelFromFile, selectedFilesCommonLabelIds } = useFiles();
    const { data: allLabels = EmptyArray as Label[] } = useGetLabelsQuery();
    const allLabelsIdMapping = getIdMapping(allLabels);

    const labelIdMapping = useMemo(() => {
        const result = new Map<Id, Label>();
        for (const id of labels) {
            result.set(id, allLabelsIdMapping.get(id));
        }
        return result;
    }, [allLabelsIdMapping, labels]);

    const labelsToBeAdded = setDiff(new Set<string>(labels), selectedFilesCommonLabelIds);

    const labelsToBeRemoved = setDiff(selectedFilesCommonLabelIds, new Set(labels));

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updateLabelsForFile = async (file: TreeItem) => {
        const fileLabels = getIdMapping((file.object as Attachment).labels ?? []);
        // we will remove those labels from the files if they are not part of the labels selected by user and was
        // common to all selected files, or if user wants to clear all labels
        for (const [id] of fileLabels) {
            if ((!labelIdMapping.has(id) && selectedFilesCommonLabelIds.has(id)) || changeOption === 'clear') {
                await removeLabelFromFile(file.object.id, id);
            }
        }

        // adding labels in file that were initially not there, but are now present in the new common labels
        for (const [id, label] of labelIdMapping) {
            if (!fileLabels.has(id)) {
                await addLabelInFile(file.object.id, label.id);
            }
        }
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        for (const file of selectedFiles) {
            await updateLabelsForFile(file);
        }
        setIsLoading(false);
        void modalProps.close();
        panelRef?.current?.close();
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Confirm changes`} />

            <Modal.Body>
                <div className="mb-4">
                    <Trans>You're about to make changes to {selectedFiles.length} files</Trans>
                </div>

                {changeOption === 'clear' && (
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                                <Trans>Field</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Change</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Labels</Trans>
                            </Table.HeadCell>
                        </Table.Head>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Labels</Trans>
                                </Table.Cell>
                                <Table.Cell>
                                    <ClearTag />
                                </Table.Cell>
                                <Table.Cell>- -</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )}

                {changeOption !== 'clear' && (
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                                <Trans>Action</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Labels</Trans>
                            </Table.HeadCell>
                        </Table.Head>

                        <Table.Body>
                            {labelsToBeAdded.size > 0 && (
                                <BulkEditTableRow label={t`Labels to be added`}>
                                    <div className="flex flex-wrap gap-2">
                                        {[...labelsToBeAdded].map((label: string) => (
                                            <LabelTag key={label} label={allLabelsIdMapping.get(label)} />
                                        ))}
                                    </div>
                                </BulkEditTableRow>
                            )}

                            {labelsToBeRemoved.size > 0 && (
                                <BulkEditTableRow label={t`Labels to be removed`}>
                                    <div className="flex flex-wrap gap-2">
                                        {[...labelsToBeRemoved].map((label: string) => (
                                            <LabelTag key={label} label={allLabelsIdMapping.get(label)} />
                                        ))}
                                    </div>
                                </BulkEditTableRow>
                            )}
                        </Table.Body>
                    </Table>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={handleConfirm} color="primary" loading={isLoading}>
                    <Trans>Confirm changes</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
