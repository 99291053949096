import { HttpMethod, Id } from '@wedo/types';
import { ActivityLog } from 'Shared/types/activityLog';
import { Vote, VoteOption, VoteRequest } from 'Shared/types/vote';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag, tags } = configureTag('Vote');
export const meetingVotesTag: Id = 'meetingVotes';
export const userPendingVotesTag: Id = 'userPendingVotesTag';
export const meetingVoteRequestsTag: Id = 'meetingVoteRequests';
export const meetingUnreachableVotes: Id = 'meetingUnreachableVotes';
export const voteActivitiesTag = (id: Id) => tag(`VOTE-ACTIVITIES-${id}`);

interface GetVoteQueryParams {
    id?: Id;
    meetingId?: Id;
    related?: string[];
}

export const allowedVoteRelated = [
    'voteAnswers',
    'voteOptions',
    'voteAnswers.voteOption',
    'voteOptions.voteAnswers',
    'voteOptions.voteAnswers.user',
    'voteAnswers.user',
    'meetingBlocks',
    'meetingBlocks.topic',
];

export const buildGetVoteBlockParameters = (id: Id, meetingId: Id) => ({
    id,
    meetingId,
    related: [
        'voteAnswers',
        'voteOptions',
        'voteAnswers.voteOption',
        'voteOptions.voteAnswers',
        'voteOptions.voteAnswers.user',
        'voteAnswers.user',
        'meetingBlocks',
    ],
});

export interface VoteRequestMailParameters {
    subject: string;
    intro: string;
    meeting_title: string;
    due_date?: string;
}

export const voteApi = baseApi
    .enhanceEndpoints({ addTagTypes: [tagType, meetingVotesTag, userPendingVotesTag] })
    .injectEndpoints({
        endpoints: (build) => ({
            getVote: build.query<Vote, GetVoteQueryParams>({
                query: ({ meetingId, id, related }) => ({
                    url: `meetings/${meetingId}/vote/${id}`,
                    params: { related },
                }),

                providesTags: (result, error, { meetingId }) => [
                    tag(resourceId(tagType, result?.id)),
                    tag(resourceId(meetingVotesTag, meetingId)),
                ],
            }),
            getMeetingVotes: build.query<Vote[], GetVoteQueryParams>({
                query: ({ meetingId, related }) => ({
                    url: `meetings/${meetingId}/votes`,
                    params: { related },
                }),
                providesTags: (result, error, { meetingId }) => [tag(resourceId(meetingVotesTag, meetingId))],
            }),
            getVoteRequests: build.query<VoteRequest[], { meetingId: Id }>({
                query: ({ meetingId }) => ({
                    url: `meetings/${meetingId}/vote-requests`,
                }),
                providesTags: (result, error, { meetingId }) => [tag(resourceId(meetingVoteRequestsTag, meetingId))],
            }),
            getUnreachableVotes: build.query({
                query: ({ meetingId }) => ({
                    url: `meetings/${meetingId}/unreachable-votes`,
                }),
                providesTags: (result, error, { meetingId }) => [tag(resourceId(meetingUnreachableVotes, meetingId))],
            }),
            addVoteRequest: build.mutation<
                VoteRequest,
                {
                    meetingId: Id;
                    data: { recipients: Id[]; parameters: VoteRequestMailParameters };
                }
            >({
                query: ({ meetingId, data }) => ({
                    url: `meetings/${meetingId}/vote-requests`,
                    body: data,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(resourceId(meetingVoteRequestsTag, meetingId))],
            }),
            updateVote: build.mutation<
                Vote,
                {
                    meetingId: Id;
                    voteId: Id;
                    changes: Partial<Vote>;
                }
            >({
                query: ({ meetingId, voteId, changes }) => ({
                    url: `meetings/${meetingId}/vote/${voteId}`,
                    body: { changes },
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { voteId, meetingId }) => [
                    tag(resourceId(tagType, voteId)),
                    tag(resourceId(meetingVotesTag, meetingId)),
                ],
            }),
            updateVoteOptions: build.mutation<
                Vote,
                {
                    meetingId: Id;
                    voteId: Id;
                    data: {
                        addedVoteOptions?: Partial<VoteOption>[];
                        updatedVoteOptions?: { id: Id; changes: Partial<VoteOption> }[];
                        deletedVoteOptions?: Id[];
                    };
                }
            >({
                query: ({ meetingId, voteId, data }) => ({
                    url: `meetings/${meetingId}/vote/${voteId}/option`,
                    body: data,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { voteId, meetingId }) => [
                    tag(resourceId(tagType, voteId)),
                    tag(resourceId(meetingVotesTag, meetingId)),
                ],
            }),
            addVoteAnswer: build.mutation<
                Vote,
                {
                    meetingId: Id;
                    voteId: Id;
                    data: {
                        voteAnswers: {
                            user_id: Id;
                            vote_type: string;
                            status: string;
                            selectedOptionIds?: Id[];
                            discussion?: string;
                        }[];
                    };
                }
            >({
                query: ({ meetingId, voteId, data }) => ({
                    url: `meetings/${meetingId}/vote/${voteId}/answer`,
                    body: data,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { meetingId, voteId }) => [
                    tag(resourceId(tagType, voteId)),
                    tag(resourceId(meetingVotesTag, meetingId)),
                ],
            }),
            deleteVoteAnswers: build.mutation<
                Vote,
                {
                    meetingId: Id;
                    voteId: Id;
                    data: {
                        userIds: Id[];
                    };
                }
            >({
                query: ({ meetingId, voteId, data }) => ({
                    url: `meetings/${meetingId}/vote/${voteId}/delete-answers`,
                    body: data,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { meetingId, voteId }) => [
                    tag(resourceId(tagType, voteId)),
                    tag(resourceId(meetingVotesTag, meetingId)),
                ],
            }),
            updateMeetingVotesStatuses: build.mutation<
                Vote,
                {
                    meetingId: Id;
                    status: 'open' | 'closed';
                }
            >({
                query: ({ meetingId, status }) => ({
                    url: `meetings/${meetingId}/votes-statuses`,
                    body: { status },
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { meetingId }) => [resourceId(meetingVotesTag, meetingId)],
            }),
            resetVoteResults: build.mutation<
                Vote,
                {
                    meetingId: Id;
                    voteId: Id;
                }
            >({
                query: ({ meetingId, voteId }) => ({
                    url: `meetings/${meetingId}/vote/${voteId}/reset`,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { voteId }) => [tag(resourceId(tagType, voteId))],
            }),
            resetMeetingVotesResults: build.mutation<
                Vote,
                {
                    meetingId: Id;
                }
            >({
                query: ({ meetingId }) => ({
                    url: `meetings/${meetingId}/reset-votes`,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { meetingId }) => [resourceId(meetingVotesTag, meetingId)],
            }),
            //--------------------------
            // Vote Activity Logs
            //--------------------------
            getVoteActivityLogs: build.query<
                ActivityLog[],
                { meetingId: Id; voteId: Id; limit?: number; important?: boolean }
            >({
                query: ({ meetingId, voteId, important, limit }) => ({
                    url: `meetings/${meetingId}/vote/${voteId}/activities`,
                    params: { important, limit },
                }),
                providesTags: (result, error, { voteId }) => [voteActivitiesTag(voteId)],
            }),
        }),
    });

export const {
    useGetVoteQuery,
    useGetMeetingVotesQuery,
    useGetVoteRequestsQuery,
    useGetUnreachableVotesQuery,
    useGetVoteActivityLogsQuery,
    useUpdateVoteMutation,
    useUpdateVoteOptionsMutation,
    useAddVoteAnswerMutation,
    useDeleteVoteAnswersMutation,
    useAddVoteRequestMutation,
    useUpdateMeetingVotesStatusesMutation,
    useResetVoteResultsMutation,
    useResetMeetingVotesResultsMutation,
} = voteApi;

export const invalidateVotes = (meetingId: Id) =>
    voteApi.util.invalidateTags([tag(resourceId(meetingVotesTag, meetingId))]);
