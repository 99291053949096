import { useLingui } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { faArrowAltCircleRight, faEllipsisV, faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    Dropdown,
    ItemGroup,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { formatDate } from '@wedo/utils';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { ConfirmDeleteFutureTopicsModal } from 'Shared/components/meeting/topicView/ConfirmDeleteFutureTopicsModal';
import { RevisitTopicLabel } from 'Shared/components/meeting/topicView/RevisitTopicLabel';
import { invalidateMeetingTopic, useDeleteTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const handleRevisit = async (
    topic: MeetingTopic,
    onDeleteTopic: (meetingId: Id, topicId: Id, safe: boolean) => Promise<unknown>,
    handleTopicChange: (changes: Partial<MeetingTopic>, safe: boolean) => Promise<void>
) => {
    const nextOccurrence = topic?.next_occurrences?.find((occurrence) => occurrence.id != null);
    if (topic.revisited) {
        // If there is only one occurrence of this topic in the next meeting, delete this occurrence
        if (
            topic.next_occurrences?.filter((occurrence) => occurrence.id != null).length === 1 &&
            topic.repeat_every === 0
        ) {
            const res = await onDeleteTopic(nextOccurrence?.meeting.id, nextOccurrence.id, true);
            if (!res) {
                return;
            }
        }
        await handleTopicChange({ revisited: false }, false);
    } else if (!nextOccurrence && topic.next_occurrences?.length > 0) {
        // If there is no occurrence selected, by default, we revisit in the next meeting if any exists
        await handleTopicChange({ revisited: true, revisit_meeting_id: topic.next_occurrences[0].meeting.id }, false);
    } else {
        await handleTopicChange({ revisited: true }, false);
    }
};

type RevisitButtonProps = {
    topic: MeetingTopic;
    onTopicChange: (changes: Partial<MeetingTopic>, safe: boolean) => Promise<void>;
    containerSize: {
        width: number;
        height: number;
    };
};

export const RevisitTopicComponent = ({ topic, onTopicChange, containerSize }: RevisitButtonProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const { show } = useNotification();
    const { meeting } = useMeetingContext();
    const { confirm } = useConfirm();
    const { i18n } = useLingui();

    const [isRevisiting, setIsRevisiting] = useState<boolean>(false);

    const [deleteTopics] = useDeleteTopicsMutation();

    const isRepeating = topic?.repeat_every > 0 || topic?.next_occurrences?.some((occurrence) => occurrence.id != null);
    const { isFetching } = trpc.meetingTopic.listByMeetingId.useQuery(topic.meeting_id);

    const deleteTopic = (meetingId: Id, topicId: Id, safe: boolean) =>
        deleteTopics({ meetingId, topics: [topicId], safe });

    const handleDeleteTopic = async (meetingId: Id, topicId: Id): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            void deleteTopic(meetingId, topicId, true).then(async (result) => {
                if ('error' in result) {
                    const error = result.error as ApiError;
                    if (error.matches({ path: 'ConflictError' })) {
                        const res = await confirm<boolean>({}, ConfirmDeleteFutureTopicsModal);
                        if (res === true) {
                            await deleteTopic(meetingId, topicId, false);
                            dispatch(invalidateMeetingTopic(topicId));
                        }
                        resolve(res);
                    } else {
                        show(UnexpectedErrorNotification);
                    }
                }
                return resolve(true);
            });
        });
    };
    const handleRevisitMenuClick = async (meetingId: Id) => {
        setIsRevisiting(true);
        try {
            const occurrence = topic.next_occurrences?.find(
                (occurrence) => occurrence.meeting.id === meetingId && occurrence.id != null
            );
            if (occurrence) {
                // If there is already an occurrence in the selected meeting, delete it...
                const result = await deleteTopic(meetingId, occurrence.id, true);
                if ('error' in result) {
                    const error = result.error as ApiError;
                    if (error.matches({ path: 'ConflictError' })) {
                        const res = await confirm<boolean>({}, ConfirmDeleteFutureTopicsModal);
                        if (res === true) {
                            await deleteTopic(meetingId, occurrence.id, false);
                            dispatch(invalidateMeetingTopic(topic.id));
                        }
                    } else {
                        show(UnexpectedErrorNotification);
                    }
                }
            } else {
                // ...otherwise, create it
                await onTopicChange({ revisit_meeting_id: meetingId }, false);
            }
            dispatch(invalidateMeetingTopic(topic.id));
            void trpcUtils().meetingTopic.listByMeetingId.invalidate();
        } catch (e) {
            show(UnexpectedErrorNotification);
        } finally {
            setIsRevisiting(false);
        }
    };

    const hasRevisitDropdown = useMemo(
        () =>
            meeting?.nextMeetings?.length > 0 &&
            (topic.repeat_every || 0) === 0 &&
            !topic.revisited &&
            !topic.addressed,
        [meeting?.nextMeetings?.length, topic.repeat_every, topic.revisited]
    );

    if (topic.addressed) {
        return null;
    }

    return (
        <ItemGroup>
            {topic?.revisited || (isRepeating && topic?.addressed) ? (
                <div className={'text-sm'}>
                    <RevisitTopicLabel topic={topic} />{' '}
                    <Button
                        variant={'link'}
                        color={'primary'}
                        className={'px-0'}
                        onClick={() => handleRevisit(topic, handleDeleteTopic, onTopicChange)}
                    >
                        (<Trans>undo</Trans>)
                    </Button>
                </div>
            ) : (
                <Button
                    className={clsx('hover:text-blue-600')}
                    title={containerSize?.width < 450 && t`Revisit topic`}
                    icon={topic.repeat_every > 0 ? faSyncAlt : faArrowAltCircleRight}
                    onClick={() => handleRevisit(topic, handleDeleteTopic, onTopicChange)}
                    active={topic.revisited}
                >
                    {containerSize?.width >= 450 ? <RevisitTopicLabel topic={topic} /> : undefined}
                </Button>
            )}

            {hasRevisitDropdown && (
                <Dropdown title={t`Revisit on the...`} disabled={topic.repeat_every !== 0} icon={faEllipsisV}>
                    {topic?.next_occurrences?.map((occurrence) => (
                        <Dropdown.CheckboxItem
                            loading={isRevisiting || isFetching}
                            checked={occurrence.id != null}
                            key={occurrence.meeting.id}
                            onChange={() => handleRevisitMenuClick(occurrence.meeting.id)}
                        >
                            {t`On the ${formatDate(new Date(occurrence.meeting.start_at), 'shortDate', i18n)}`}
                        </Dropdown.CheckboxItem>
                    ))}
                </Dropdown>
            )}
        </ItemGroup>
    );
};
