import React, { FC, PropsWithChildren, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    Modal,
    Steps,
    UnexpectedErrorNotification,
    useNotification,
    contrastingColor,
    getRandomWedoColor,
} from '@wedo/design-system';
import { NumberRange } from '@wedo/types';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { WorkspaceInfoModalBody } from 'Shared/components/workspace/AddWorkspaceModal/WorkspaceInfoModalBody';
import { WorkspaceManageMembersModalBody } from 'Shared/components/workspace/WorkspaceManageMembers/WorkspaceManageMembersModalBody';
import { useAddWorkspaceMutation } from 'Shared/services/workspace';
import { Team } from 'Shared/types/team';
import { Workspace } from 'Shared/types/workspace';

type AddWorkspaceModalProps = { teamId: Id } & ContextModalProps;

export const AddWorkspaceModal: FC<PropsWithChildren<AddWorkspaceModalProps>> = ({
    teamId,
    children,
    ...modalProps
}) => {
    const navigate = useNavigate();
    const { show } = useNotification();

    const [addWorkspace, { isLoading }] = useAddWorkspaceMutation();

    const [step, setStep] = useState<NumberRange<1, 3>>(1);
    const [color, setColor] = useState<string>(getRandomWedoColor());
    const [name, setName] = useState<string>(EmptyString);
    const [description, setDescription] = useState<string>(EmptyString);
    const [team, setTeam] = useState<Partial<Team>>({ id: teamId });
    const [workspace, setWorkspace] = useState<Workspace>();

    const nameInput = useRef<HTMLInputElement>();

    const isNameEmpty = isEmpty(name?.trim());

    const handleSave = async () => {
        if (isNameEmpty) {
            nameInput?.current?.focus();
            return;
        }

        const response = await addWorkspace({
            teamId: team.id,
            workspace: { name, color: { text: contrastingColor(color), background: color }, description },
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return;
        }
        setWorkspace(response.data);
        setStep(2);
    };

    const handleFinish = () => {
        void modalProps.close();
        navigate(`/workspaces/${workspace.id}/${workspace?.settings?.default_tab ?? 'tasks'}`);
    };

    return (
        <Modal {...modalProps} initialFocus={nameInput} size="lg">
            <Modal.Header title={t`Add workspace`}>
                <p className="mt-1 text-gray-700">
                    <Trans>A workspace is a way to group people around a project, weekly meeting or client.</Trans>
                </p>
            </Modal.Header>

            <Steps className="rounded-none" currentIndex={step}>
                <Steps.Step index={1}>
                    <Trans>Workspace info</Trans>
                </Steps.Step>

                <Steps.Step index={2}>
                    <Trans>Members</Trans>
                </Steps.Step>
            </Steps>

            {step === 1 && (
                <WorkspaceInfoModalBody
                    name={name}
                    onNameChange={setName}
                    color={color}
                    onColorChange={setColor}
                    team={team}
                    onTeamChange={setTeam}
                    description={EmptyString}
                    onDescriptionChange={setDescription}
                    nameInputRef={nameInput}
                    onNext={handleSave}
                />
            )}

            {step === 2 && <WorkspaceManageMembersModalBody workspaceId={workspace.id} />}

            {step === 1 && (
                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button color="primary" onClick={handleSave} loading={isLoading}>
                        <Trans>Save</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {step === 2 && (
                <Modal.Footer>
                    <Button color="primary" onClick={handleFinish}>
                        <Trans>Finish</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {children}
        </Modal>
    );
};
