import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { isMessageDescriptor } from '@wedo/utils';

export const useTranslateTabs = <T extends { title?: string | MessageDescriptor }>(
    tabs: T[]
): (Omit<T, 'title'> & { title?: string })[] => {
    const linguiContext = useLingui();

    return useMemo<(Omit<T, 'title'> & { title?: string })[]>(() => {
        return tabs.map((tab) => {
            let title = tab.title;

            if (isMessageDescriptor(title)) {
                title = linguiContext.i18n._(title);
            }

            return { ...tab, title };
        });
    }, [linguiContext, tabs]);
};
