import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { ActivityLog } from '@wedo/db';
import { Button, useNavLinkOptions } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getLocalStorageSearchParams, stringifySearchParams } from '@wedo/utils';
import { getUser } from 'App/store/usersStore';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Workspace } from 'Shared/types/workspace';
import { getWorkspaceTaskParams, WorkspaceSettingsToTab } from 'Shared/utils/workspace';

const WorkspaceTitle: FC<{ workspace: Workspace }> = ({ workspace }) => {
    const { grouping, layout } = getWorkspaceTaskParams(workspace);

    const {
        to: { pathname, searchParams },
    } = useNavLinkOptions({
        to: {
            pathname: `/workspaces/${workspace?.id}/${
                WorkspaceSettingsToTab[workspace?.settings?.default_tab] ?? 'tasks'
            }`,
            searchParams: { grouping, layout },
        },
    });

    const localStorageSearchParams = getLocalStorageSearchParams(pathname);

    return (
        <Button
            size="xs"
            variant="link"
            className="w-full"
            href={`${pathname}?${stringifySearchParams({ ...searchParams, ...localStorageSearchParams })}`}
        >
            <div className="text-xs group/meeting flex items-center gap-1 w-full font-semibold">
                <span className="truncate">{workspace?.name}</span>
            </div>
        </Button>
    );
};

const WorkspaceUserTitle: FC<{ userId: Id; workspaceId: Id }> = ({ userId, workspaceId }) => {
    const user = getUser(userId);
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);

    return (
        <div className="flex gap-1 items-center text-xs truncate flex-wrap xl:flex-nowrap font-semibold">
            {user?.full_name} <Trans>in</Trans> <WorkspaceTitle workspace={workspace} />
        </div>
    );
};

export const WorkspaceModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    const workspace = { ...(auditLog.oldValue as Workspace), ...(auditLog.newValue as Workspace) };

    return (
        <>
            {auditLog.eventKey === 'created_tag' && <WorkspaceTitle workspace={workspace} />}
            {auditLog.eventKey === 'deleted_tag' && <WorkspaceTitle workspace={workspace} />}
            {auditLog.eventKey === 'restored_tag' && <WorkspaceTitle workspace={workspace} />}
            {auditLog.eventKey === 'archived_tag' && <WorkspaceTitle workspace={workspace} />}
            {auditLog.eventKey === 'unarchived_tag' && <WorkspaceTitle workspace={workspace} />}
            {auditLog.eventKey === 'duplicated_tag' && <WorkspaceTitle workspace={workspace} />}
            {auditLog.eventKey === 'changed_tag_settings' && <WorkspaceTitle workspace={workspace} />}

            {auditLog.eventKey === 'revoked_tag_member' && (
                <WorkspaceUserTitle
                    userId={(auditLog.newValue as { user_id: Id }).user_id}
                    workspaceId={auditLog.objectId}
                />
            )}
            {auditLog.eventKey === 'promoted_tag_member' && (
                <WorkspaceUserTitle
                    userId={(auditLog.newValue as { user_id: Id }).user_id}
                    workspaceId={auditLog.objectId}
                />
            )}
            {auditLog.eventKey === 'removed_member_from_tag' && (
                <WorkspaceUserTitle
                    userId={(auditLog.oldValue as { user_id: Id }).user_id}
                    workspaceId={auditLog.objectId}
                />
            )}
            {auditLog.eventKey === 'added_member_to_tag' && (
                <WorkspaceUserTitle
                    userId={(auditLog.newValue as { user_id: Id }).user_id}
                    workspaceId={auditLog.objectId}
                />
            )}
        </>
    );
};
