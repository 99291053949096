export const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180;

export const getRandomIntegerInRange = (start: number, end: number): number => {
    return start + Math.floor(Math.random() * (end - start));
};

export const getRandomItem = <T>(array: Array<T>): T => {
    const randomIndex = getRandomIntegerInRange(0, array.length);
    return array[randomIndex];
};

/** reverse the rows and columns\
 * \
 * The table:\
 * [1, 2, 3]\
 * [4, 5, 6]\
 * [7, 8, 9]\
 * \
 * Becomes:\
 * [1, 4, 7]\
 * [2, 5, 8]\
 * [3, 6, 9]*/
export const transpose = (matrix: unknown[][]) => {
    const transposedMatrix: string[][] = [];

    for (let columnIndex = 0; ; columnIndex++) {
        const column = matrix.reduce((result, row) => {
            if (columnIndex >= row.length) {
                return result;
            }

            return result.concat(row[columnIndex]);
        }, []);

        if (column.length === 0) {
            break;
        }

        transposedMatrix.push(column);
    }

    return transposedMatrix;
};
