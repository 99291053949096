import React, { useState } from 'react';
import { t } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import {
    Button,
    ColorPicker,
    ContextModalProps,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { useUpdateCircleMutation } from 'Shared/services/governance';
import { Circle } from 'Shared/types/governance';

type ChangeCircleColorModalProps = {
    circle: HierarchyCircularNode<Circle>;
} & ContextModalProps;

export const ChangeCircleColorModal = ({ circle, ...modalProps }: ChangeCircleColorModalProps): JSX.Element => {
    const [selectedColor, setSelectedColor] = useState(circle.data.color);
    const [updateCircle] = useUpdateCircleMutation();
    const { show: showNotification } = useNotification();

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Update ${circle.data.name}'s color`} />
            <Modal.Body>
                <div className={'flex flex-col items-center justify-center gap-2'}>
                    <ColorPicker color={selectedColor} onChange={setSelectedColor} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button
                    onClick={async () => {
                        await updateCircle({
                            id: circle.id,
                            color: selectedColor,
                        })
                            .unwrap()
                            .catch(() => showNotification(UnexpectedErrorNotification));
                        modalProps.close();
                    }}
                    color="primary"
                >{t`Save color`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
