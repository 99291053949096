import { FaIconName } from '@wedo/types';
import { getRandomItem } from '@wedo/utils';
import {
    availableOnAllTasks,
    availableOnSelectedWorkspaces,
    AvailabilityOption,
} from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';
import { CustomField } from 'Shared/types/customField';

export const getFieldAvailability = (field: CustomField): AvailabilityOption =>
    field?.is_global === false ? availableOnSelectedWorkspaces : availableOnAllTasks;

export const MIN_FIELD_LABEL_LENGTH = 2;

export const MIN_OPTION_LENGTH = 2;

const top15Icons: Array<FaIconName> = [
    'fa-battery-three-quarters',
    'fa-rectangle-list',
    'fa-battery-half',
    'fa-alarm-clock',
    'fa-address-book',
    'fa-building',
    'fa-clock',
    'fa-wrench',
    'fa-address-card',
    'fa-warehouse',
    'fa-clipboard-list',
    'fa-hourglass-half',
    'fa-align-justify',
    'fa-circle-check',
    'fa-chart-line',
];

export const getRandomIconName = (): FaIconName => getRandomItem(top15Icons);
