import { t } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { HttpMethod } from '@wedo/types';
import { CacheTag } from 'Shared/services/cacheTag';
import { CURRENT_USER_TAG } from 'Shared/services/user';
import { RtkQueryResponseObject, RtkQueryTransformedError } from 'Shared/types/rtkQuery';
import { UserEmail } from 'Shared/types/user-email';
import { FETCH_ERROR_RESPONSE_MESSAGE, fetchErrorOccurred } from 'Shared/utils/rtkQuery';
import { baseApi, configureTag } from './base';

const { tagType: emailTagType } = configureTag(CacheTag.EMAILS);

const addUserEmailError = () => ({
    DUPLICATE_EMAIL: {
        error: { message: 'EmailInUse', type: 'DuplicateError' },
        alert: { title: t`Duplicate email found`, message: t`This email address is already in use.` },
    },
    INVALID_EMAIL: {
        error: { message: 'InvalidEmail', type: 'ValidationError' },
        alert: {
            title: t`Invalid email address`,
            message: t`Invalid email address`,
        },
    },
    SAME_AS_NETWORK_DOMAIN: {
        error: { message: 'ExternalSameDomain', type: 'ExternalSameDomain' },
        alert: {
            title: t`Email address same as network domain email`,
            message: t`You are not allowed to add an email with the same domain as your network. Please contact your network administrator.`,
        },
    },
});

const deleteUserEmailError = () => ({
    USER_EMAIL_NOT_FOUND: {
        error: { message: 'User email not found', type: 'NotFoundError' },
        alert: (emailAddress: string): RtkQueryTransformedError => ({
            title: t`User email not found`,
            message: t`The user email ${emailAddress} was not found`,
        }),
    },

    USER_EMAIL_IN_USE: {
        error: { message: 'UserEmailUsed', type: 'ForbiddenError' },
        alert: (emailAddress: string): RtkQueryTransformedError => ({
            title: t`User email currently in use`,
            message: t`The user email ${emailAddress} can not be removed as it is currently in use in another network.`,
        }),
    },
});

const verifyUserEmailError = () => ({
    INVALID_VERIFICATION_CODE: {
        error: { message: 'Invalid verification code', type: 'ValidationError' },
        alert: { message: t`Incorrect verification code` },
    },
});

const SET_PRIMARY_EMAIL_ERROR = {
    USERS_CAN_CHANGE_FOR_HIMSELF_ERROR: {
        error: { message: 'User can change only for himself', type: 'NotAcceptableError' },
        alert: { title: t`You can only change the user email for yourself`, message: t`` },
    },
    USER_EMAIL_NOT_FOUND: {
        error: { message: 'User email not found', type: 'NotFoundError' },
        alert: { title: t`User email not found`, message: t`Please check the email and try again.` },
    },
};

export const userEmailApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [emailTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addUserEmail: build.mutation<UserEmail, { emailAddress: string; password: string }>({
                query: ({ emailAddress, password }) => ({
                    url: 'users/me/user-emails',
                    body: { email_address: emailAddress, password },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: [CURRENT_USER_TAG],
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    if (response.data.errors) {
                        if (isEqual(response.data.errors[0], addUserEmailError().INVALID_EMAIL.error)) {
                            return addUserEmailError().INVALID_EMAIL.alert;
                        }
                        if (isEqual(response.data.errors[0], addUserEmailError().DUPLICATE_EMAIL.error)) {
                            return addUserEmailError().DUPLICATE_EMAIL.alert;
                        }
                        if (isEqual(response.data.errors[0], addUserEmailError().SAME_AS_NETWORK_DOMAIN.error)) {
                            return addUserEmailError().SAME_AS_NETWORK_DOMAIN.alert;
                        }
                    }
                    return response;
                },
            }),

            removeUserEmail: build.mutation<UserEmail, UserEmail>({
                query: (userEmail: UserEmail) => ({
                    url: `users/me/user-emails/${userEmail.id}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [CURRENT_USER_TAG],
                transformErrorResponse: (response, meta, userEmail) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    if (response.data.errors) {
                        if (isEqual(response.data.errors[0], deleteUserEmailError().USER_EMAIL_IN_USE.error)) {
                            return deleteUserEmailError().USER_EMAIL_IN_USE.alert(userEmail.email_address);
                        }
                        if (isEqual(response.data.errors[0], deleteUserEmailError().USER_EMAIL_NOT_FOUND.error)) {
                            return deleteUserEmailError().USER_EMAIL_NOT_FOUND.alert(userEmail.email_address);
                        }
                    }
                    return response;
                },
            }),

            verifyEmail: build.mutation<UserEmail, { userEmailId: string; verificationCode: string }>({
                query: ({ userEmailId, verificationCode }) => ({
                    url: `users/me/user-emails/${userEmailId}`,
                    body: { verification_code: verificationCode },
                    method: HttpMethod.Put,
                }),
                invalidatesTags: [CURRENT_USER_TAG],
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    if (response.data.errors) {
                        if (isEqual(response.data.errors[0], verifyUserEmailError().INVALID_VERIFICATION_CODE.error)) {
                            return verifyUserEmailError().INVALID_VERIFICATION_CODE.alert;
                        }
                    }
                    return response;
                },
            }),

            setPrimaryEmail: build.mutation<UserEmail, UserEmail>({
                query: (userEmail: UserEmail) => ({
                    url: `users/me/user-emails/${userEmail.id}/set-main`,
                    body: userEmail,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: [CURRENT_USER_TAG],
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    if (response.data.errors) {
                        const error = response.data.errors[0];
                        if (isEqual(error, SET_PRIMARY_EMAIL_ERROR.USERS_CAN_CHANGE_FOR_HIMSELF_ERROR.error)) {
                            return SET_PRIMARY_EMAIL_ERROR.USERS_CAN_CHANGE_FOR_HIMSELF_ERROR.alert;
                        }
                        if (isEqual(error, SET_PRIMARY_EMAIL_ERROR.USER_EMAIL_NOT_FOUND.error)) {
                            return SET_PRIMARY_EMAIL_ERROR.USER_EMAIL_NOT_FOUND.alert;
                        }
                    }
                    return response;
                },
            }),
        }),
    });

export const {
    useAddUserEmailMutation,
    useRemoveUserEmailMutation,
    useVerifyEmailMutation,
    useSetPrimaryEmailMutation,
} = userEmailApi;
