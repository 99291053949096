import React, { useEffect, useRef, useState } from 'react';
import { faArrowAltCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { isEmpty } from 'lodash-es';
import {
    Alert,
    Button,
    CodeInput,
    ContextModalProps,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useUpdateMeetingStatusMutation } from 'Shared/services/meeting';
import { useUpdateTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { TopicsResolver } from '../TopicsResolver';

const min = 1000;
const max = 9999;

export const LockMeetingModal = ({
    meetingId,
    children,
    ...modalProps
}: { meetingId: Id } & ContextModalProps): JSX.Element => {
    const [userSkippedResolve, setUserSkippedResolve] = useState(false);
    const [updateStatus, { isLoading: isLocking }] = useUpdateMeetingStatusMutation();

    const { data: openTopics = [] } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: (data: MeetingTopic[]) => camelToSnake(data).filter((topic) => !topic.addressed && !topic.revisited),
    });

    const { show } = useNotification();

    const codeInputRef = useRef<HTMLInputElement>();

    const handleLockDone = async () => {
        const result = await updateStatus({ meetingId, status: 'locked' });
        if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
        await modalProps.close();
    };

    const [error, setError] = useState(null);
    const [code, setCode] = useState(EmptyString);
    const [confirmation, setConfirmation] = useState(EmptyString);

    const [updateTopics, { isLoading }] = useUpdateTopicsMutation();
    const updateAllTopics = (topics: Array<{ id: Id; changes: Partial<MeetingTopic> }>) =>
        updateTopics({ meetingId, topics });

    const isTopicRevisited = (openTopic: MeetingTopic) =>
        openTopic.next_occurrences?.some((occurrence) => occurrence.id != null);

    useEffect(() => {
        setCode(Math.floor(Math.random() * (max - min) + min) + '');
        setConfirmation('');
    }, []);

    const handleCancel = () => {
        setUserSkippedResolve(false);
        void modalProps.close();
    };
    const handleSave = async () => {
        setError(null);
        if (confirmation === code) {
            await handleLockDone();
        } else {
            setError('Invalid confirmation code.');
        }
    };

    useEffect(() => {
        if ('current' in codeInputRef && isEmpty(confirmation.trim())) {
            codeInputRef?.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeInputRef?.current]);

    const handleClickRevisitAll = async () => {
        const res = await updateAllTopics(
            openTopics.map((openTopic: MeetingTopic) => ({
                id: openTopic.id,
                changes:
                    openTopic.repeat_every === 0 &&
                    !isTopicRevisited(openTopic) &&
                    openTopic.next_occurrences?.length > 0
                        ? { revisited: true, revisit_meeting_id: openTopic.next_occurrences[0].meeting.id }
                        : { revisited: true },
            }))
        );

        if ('error' in res && res.error instanceof ApiError) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Modal {...modalProps} size={'lg'}>
            <Modal.Header title={openTopics.length > 0 && !userSkippedResolve ? t`Resolve topics` : t`Lock meeting`} />
            <Modal.Body>
                {openTopics?.length > 0 && !userSkippedResolve && (
                    <TopicsResolver
                        isTopicRevisited={isTopicRevisited}
                        openTopics={openTopics}
                        openMeetingId={meetingId}
                    />
                )}

                {(openTopics.length === 0 || userSkippedResolve) && (
                    <div className={'flex flex-col gap-2'}>
                        <h3 className={'font-bold'}>
                            <Trans>Do you want to lock this meeting?</Trans>
                        </h3>
                        <p className={'text-sm'}>
                            <Trans>
                                You won't be able to modify the meeting minutes after confirmation. Please type{' '}
                                <b>{code}</b> in the box to confirm.
                            </Trans>
                        </p>
                        <CodeInput
                            autofocus
                            ref={codeInputRef}
                            label={t`Confirmation code`}
                            length={4}
                            initialValue={confirmation}
                            onChange={(value) => setConfirmation(value)}
                            onValidate={handleSave}
                        />
                        {error && <Alert title={error} type={'danger'} />}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer autoPlaceChildren>
                {openTopics.length <= 0 || userSkippedResolve ? (
                    <>
                        <Button onClick={handleCancel}>
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button color={'primary'} onClick={handleSave} loading={isLocking}>
                            <Trans>Lock meeting</Trans>
                        </Button>
                    </>
                ) : (
                    <>
                        <Button size="sm" variant={'text'} onClick={() => setUserSkippedResolve(true)}>
                            <Trans>Skip</Trans>
                        </Button>
                        <Button
                            color="primary"
                            icon={faArrowAltCircleRight}
                            loading={isLoading}
                            onClick={handleClickRevisitAll}
                        >
                            <Trans>Revisit all</Trans>
                        </Button>
                    </>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
