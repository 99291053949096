import { HttpMethod, Id } from '@wedo/types';
import { CacheTag } from 'Shared/services/cacheTag';
import { CURRENT_USER_TAG } from 'Shared/services/user';
import { Network, NetworkConfig } from 'Shared/types/network';
import { baseApi, configureTag } from './base';

const { tagType: networkTagType, tag: networkTag } = configureTag(CacheTag.NETWORK);

export const currentNetworkTag = networkTag('current-network');

export const networkApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [networkTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getCurrentNetwork: build.query<Network, void>({
                query: () => '/api/networks/current',
                providesTags: (result) => [networkTag(result?.id), currentNetworkTag],
            }),

            getAdminNetwork: build.query<Network, void>({
                query: () => '/api/admin/network',
                providesTags: (result) => [networkTag(result?.id), currentNetworkTag],
            }),

            getNetworks: build.query<Network[], void>({
                query: () => '/api/networks',
                providesTags: [currentNetworkTag],
            }),

            getNetworkAuthLink: build.query<string, { networkId: Id; token?: string }>({
                query: ({ networkId, token }) =>
                    token
                        ? `/api/networks/${networkId}/global-auth-link?token=${token}`
                        : `/api/networks/${networkId}/auth-link`,
                providesTags: [],
            }),

            updateNetwork: build.mutation<Network, Partial<Network>>({
                query: (payload) => ({
                    url: '/api/admin/network',
                    method: HttpMethod.Put,
                    body: payload,
                }),
                invalidatesTags: (result, error, arg) => {
                    if (error) {
                        return [];
                    }
                    if ('short_name' in arg) {
                        const parts = location.host.split('.');
                        parts.splice(0, 1);
                        const host = `${arg.short_name}.${parts.join('.')}`;
                        const pathName = location.hash ? `/${location.hash}` : location.pathname;
                        const href = `${location.protocol}//${host}${pathName}`;
                        location.replace(href);
                    }
                    return [currentNetworkTag];
                },
            }),

            updateNetworkLogo: build.mutation<string, FormData>({
                query: (payload) => ({
                    url: '/api/networks/me/logo',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: [currentNetworkTag],
            }),

            deleteNetworkLogo: build.mutation<string, void>({
                query: () => ({
                    url: '/api/networks/me/logo',
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentNetworkTag],
            }),

            updateNetworkIcon: build.mutation<string, FormData>({
                query: (payload) => ({
                    url: '/api/networks/me/icon',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: [currentNetworkTag],
            }),

            deleteNetworkIcon: build.mutation<string, void>({
                query: () => ({
                    url: '/api/networks/me/icon',
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentNetworkTag],
            }),

            updateNetworkConfig: build.mutation<void, Partial<NetworkConfig>>({
                query: (payload) => ({
                    url: '/api/admin/network/config',
                    method: HttpMethod.Put,
                    body: payload,
                }),
                invalidatesTags: [currentNetworkTag, CURRENT_USER_TAG],
            }),
        }),
    });

export const invalidateCurrentNetwork = () => {
    return networkApi.util.invalidateTags([currentNetworkTag]);
};

export const {
    useGetCurrentNetworkQuery,
    useLazyGetCurrentNetworkQuery,
    useGetNetworksQuery,
    useGetAdminNetworkQuery,
    useLazyGetNetworkAuthLinkQuery,
    useUpdateNetworkMutation,
    useUpdateNetworkLogoMutation,
    useUpdateNetworkIconMutation,
    useUpdateNetworkConfigMutation,
    useDeleteNetworkLogoMutation,
    useDeleteNetworkIconMutation,
} = networkApi;
