import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { Signature } from '@wedo/db';
import { Alert, Button } from '@wedo/design-system';
import { formatDate } from '@wedo/utils';
import { useUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';

export const SignatureModalStatusBanner = () => {
    const { i18n } = useLingui();
    const { signatureRequest, changeSignatureStatus, isSigning, signature } = useContext(SignatureModalContext);
    const canceledByUser = useUser(signatureRequest?.canceledBy);

    return (
        signatureRequest != null && (
            <>
                {signatureRequest.isCanceled && canceledByUser != null ? (
                    <Alert type={'danger'}>{t`This signature request has been canceled by ${
                        canceledByUser.full_name
                    } on the ${formatDate(signatureRequest.canceledAt, 'shortDate', i18n)}`}</Alert>
                ) : (
                    signature?.status === 'REFUSED' && (
                        <Alert
                            type={'danger'}
                            title={
                                <>
                                    {t`You have refused to sign this document`}{' '}
                                    {!signatureRequest.isCanceled && (
                                        <Button
                                            onClick={() => changeSignatureStatus('PENDING')}
                                            disabled={isSigning}
                                            variant={'link'}
                                            size={'lg'}
                                            className={'!text-base !text-blue-500'}
                                        >
                                            ({t`undo`})
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    )
                )}
                {signatureRequest.signatures.every((signature: Signature) => signature.status === 'SIGNED') && (
                    <Alert title={t`The document has been signed by all parties.`} type={'success'} />
                )}
            </>
        )
    );
};
