import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import { Button, FormatDate } from '@wedo/design-system';
import { formatRange } from '@wedo/utils';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { useGanttContextStore } from 'Pages/GanttPage/GanttContext';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { BlockedDropdown } from 'Shared/components/task/TaskDetail/rows/taskDependency/BlockedDropdown';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { taskActivitiesTag, taskApi } from 'Shared/services/task';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Task, TaskType } from 'Shared/types/task';
import { TaskDetailRow } from '../../shared/TaskDetailRow';

// isBlocking implies that the main Task depends on task
export const TaskDependency: FC<{ mainTask: Task; task: Task; isBlocking?: boolean }> = ({
    mainTask,
    task,
    isBlocking = false,
}) => {
    const dispatch = useDispatch();
    const ganttContextStore = useGanttContextStore();

    const { isTaskReadonly } = useTaskStatus(mainTask);
    const { setSelectedTasks } = useTasksContext();
    const { pathname } = useLocation();

    const { mutate: deleteDependency, isLoading: isDeleteLoading } = trpc.task.removeDependency.useMutation({
        onSuccess: () => {
            if (ganttContextStore == null) {
                invalidateCachedTasks();
                dispatch(taskApi.util.invalidateTags([taskActivitiesTag(mainTask.id), taskActivitiesTag(task.id)]));
            } else {
                void trpcUtils().task.listDependencies.invalidate();
            }
        },
    });

    const noAccessToTask = task?.name === null;

    const handleDelete = async () => {
        await deleteDependency({
            blockedTaskId: isBlocking ? Number(task.id) : Number(mainTask?.id),
            blockingTaskId: isBlocking ? Number(mainTask?.id) : Number(task.id),
        });
    };

    const handleSelectTask = () => {
        if (pathname.startsWith('/meetings')) {
            dispatch(taskSelected({ taskId: task.id.toString() }));
        } else {
            setSelectedTasks([{ id: task.id.toString(), groupedId: task.id.toString() }]);
        }
    };

    return (
        <TaskDetailRow className="!py-0">
            <TaskDetailRow.Content>
                <div className="flex items-center text-sm w-full">
                    <BlockedDropdown
                        isBlocking={isBlocking}
                        mainTaskId={mainTask?.id}
                        itemTask={task}
                        isDisabled={isTaskReadonly || noAccessToTask}
                    />
                    <Button
                        variant="text"
                        className="overflow-hidden w-full !justify-between"
                        onClick={handleSelectTask}
                        size="sm"
                        disabled={noAccessToTask}
                        title={task.name}
                    >
                        <span className={clsx('truncate shrink')}>
                            {noAccessToTask
                                ? task.type === TaskType.Task
                                    ? t`You do not have access to this task`
                                    : t`You do not have access to this milestone`
                                : task.name}
                        </span>
                        <div>
                            {task?.checklist_id && (
                                <>
                                    <span className="shrink-0 text-gray-500">
                                        {task.relative_due_date && (
                                            <>
                                                {formatRange(
                                                    addDays(new Date(task.planned_date), task.relative_planned_date),
                                                    addDays(new Date(task.planned_date), task.relative_due_date),
                                                    i18n.locale
                                                )}
                                            </>
                                        )}
                                        {!task.relative_due_date && (
                                            <FormatDate
                                                format="PP"
                                                date={addDays(new Date(task.planned_date), task.relative_planned_date)}
                                            />
                                        )}
                                    </span>
                                </>
                            )}
                            {!task?.checklist_id && (
                                <>
                                    <span className="shrink-0 text-gray-500">
                                        {task?.planned_date && task?.due_date && (
                                            <>
                                                {formatRange(
                                                    new Date(task?.planned_date),
                                                    new Date(task?.due_date),
                                                    i18n.locale
                                                )}
                                            </>
                                        )}
                                        {task?.planned_date && !task?.due_date && (
                                            <FormatDate format="PP" date={task.planned_date} />
                                        )}
                                    </span>
                                </>
                            )}
                        </div>
                    </Button>
                </div>
            </TaskDetailRow.Content>

            <TaskDetailRow.Addon>
                <Button
                    icon={faTimes}
                    size="sm"
                    variant="text"
                    title={t`Delete`}
                    onClick={handleDelete}
                    disabled={isTaskReadonly || noAccessToTask}
                    loading={isDeleteLoading}
                    className="enabled:hover:bg-red-100 enabled:hover:text-red-500 focus:bg-red-100 focus:text-red-500"
                />
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
