import { FC } from 'react';
import { CheckboxTree } from '~/components/Tree/utils/tree';
import { TreeItem } from './TreeItem';

export type TreeProps = {
    value: CheckboxTree;
    isRoot?: boolean;
    onCheckboxClick?: (node: CheckboxTree) => void;
    onChange?: (tree: CheckboxTree) => void;
    onOpenClick?: (node: CheckboxTree) => void;
    isDisabled?: boolean;
};

export const TreeFull: FC<TreeProps> = ({
    onChange,
    onCheckboxClick,
    onOpenClick,
    value,
    isRoot = true,
    isDisabled,
}) => {
    return (
        <TreeItem
            key={value.id}
            isRoot={isRoot}
            value={value}
            onCheckboxClick={onCheckboxClick}
            onOpenClick={onOpenClick}
            onChange={onChange}
            isDisabled={isDisabled}
        >
            {value.children?.map((child) => (
                <TreeFull
                    key={child.id}
                    isRoot={false}
                    value={child}
                    onCheckboxClick={onCheckboxClick}
                    onOpenClick={onOpenClick}
                    onChange={onChange}
                    isDisabled={isDisabled}
                />
            ))}
        </TreeItem>
    );
};
