import { useLingui } from '@lingui/react';
import { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { intlFormat } from 'date-fns';
import { Tooltip } from '@wedo/design-system';
import { Day } from '@wedo/utils';

export const TimelineTodayMarker = () => {
    const { i18n } = useLingui();

    const [today] = useState(() => new Date());

    const start = useMemo(() => Math.trunc(today.getTime() / Day) + 0.5, [today]);

    return (
        <>
            <div
                className="absolute left-0 bottom-0 top-0 z-50 pointer-events-none"
                style={{ transform: `translateX(calc((${start} - var(--start-day)) * var(--column-width)))` }}
            >
                <Tooltip
                    content={
                        <div className="text-center">
                            <div className="font-bold">
                                <Trans>Today</Trans>
                            </div>
                            {intlFormat(today, { locale: i18n.locale })}
                        </div>
                    }
                >
                    <div className="sticky top-[calc(var(--row-height)*2-0.25rem)] -ml-1 h-2 w-2 bg-blue-500 rounded-full" />
                </Tooltip>
            </div>
            <div
                className="absolute h-full border-l border-blue-500 pointer-events-none z-30"
                style={{ transform: `translateX(calc((${start} - var(--start-day)) * var(--column-width)))` }}
            />
        </>
    );
};
