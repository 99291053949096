import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { FormatDate, ProgressBar, Table, Tooltip } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { ChecklistDropdown } from 'Shared/components/checklist/ChecklistDropdown';
import { Checklist } from 'Shared/types/checklist';

type ChecklistListViewTableRowProps = { checklist: Checklist; isLoading: boolean };

export const ChecklistListViewTableRow = ({ checklist, isLoading }: ChecklistListViewTableRowProps) => {
    const navigate = useNavigate();

    const nbOpenTasks = Number(checklist.nb_open_tasks);
    const nbCompletedTasks = Number(checklist.nb_completed_tasks);
    const percentage = Math.round((nbCompletedTasks / (nbOpenTasks + nbCompletedTasks)) * 100);

    const daysLeft = checklist ? differenceInDays(new Date(), new Date(checklist.reference_date)) : 0;

    const referenceDateLabel = daysLeft > 0 ? t`${daysLeft} days after` : t`${Math.abs(daysLeft)} days before`;

    const handleClick = () => {
        navigate(`/checklists/${checklist.id}/tasks`);
    };
    return (
        <>
            <Table.Row
                onClick={handleClick}
                className={clsx('hover:cursor-pointer flex w-full', isLoading && 'opacity-70')}
            >
                <Table.Cell className={'@2xl:w-[40%] @lg:w-[50%] @md:w-[60%] w-[80%]'}>{checklist.name}</Table.Cell>

                <Table.Cell className={'@2xl:table-cell hidden w-[10%]'}>{nbOpenTasks}</Table.Cell>

                <Table.Cell className={'@lg:w-[20%] @md:w-[30%] hidden @md:table-cell'}>
                    <ProgressBar percent={percentage} color="green" />
                </Table.Cell>

                <Table.Cell className={'@lg:table-cell hidden w-[20%]'}>
                    <Tooltip content={<FormatDate format="PPP" date={new Date(checklist.reference_date)} />}>
                        <span>{referenceDateLabel}</span>
                    </Tooltip>
                </Table.Cell>

                <Table.Cell className={'w-[20%] @md:w-[10%]'}>
                    <ChecklistDropdown checklist={checklist} size="sm" />
                </Table.Cell>
            </Table.Row>
        </>
    );
};
