import { useMemo } from 'react';

export type Validator = [boolean, string];

export const useValidators = <T>(value: T, validators: (value: T) => Validator[]) =>
    useMemo(
        () =>
            validators(value)
                .filter(([matched]) => matched)
                .map(([, message]) => message)
                .join('\n'),
        [value, validators]
    );
