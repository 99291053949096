import { Trans } from '@lingui/macro';
import { Card, ProgressBar } from '@wedo/design-system';
import { BadgeLevel, badgeLevelPoints, getBadgeLevel } from 'Pages/BadgesPage/BadgeLevel';

export const badgeLevelProgressBarColor = ['neon', 'summer', 'lollipop', 'red', 'pink', 'purple', 'indigo', 'royal'];

export const BadgeLevelCard = ({ score, isCurrentUser }: { score: number; isCurrentUser?: boolean }) => {
    const level = getBadgeLevel(score);
    const nextLevelPoints = badgeLevelPoints[level % badgeLevelPoints.length];
    const progress = Math.min((score / nextLevelPoints) * 100, 100);
    const remainingPoints = nextLevelPoints - score;
    return (
        <Card className="mr-2">
            <Card.Body className="!p-4 flex gap-1 items-center justify-between flex-nowrap">
                <BadgeLevel score={score} />
                <div className="mt-2 font-medium text-gray-700 w-full flex flex-col gap-2">
                    <div>
                        <Trans>Level {level}</Trans>
                        {level < badgeLevelPoints.length && (
                            <>
                                {' '}
                                - {score} / <Trans id={'pts as in points'}>{nextLevelPoints} pts</Trans>
                            </>
                        )}
                    </div>
                    <ProgressBar
                        percent={progress}
                        color={badgeLevelProgressBarColor[getBadgeLevel(score) - 1]}
                        size="thin"
                    />
                    <div className="text-sm text-gray-600">
                        {level < badgeLevelPoints.length ? (
                            <Trans>{remainingPoints} points to the next level</Trans>
                        ) : (
                            isCurrentUser && (
                                <Trans>
                                    You've achieved the highest badge level. Thanks for being an awesome WEDO user!
                                </Trans>
                            )
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
