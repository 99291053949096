import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ZUSTAND_STORAGE } from 'Pages/TasksPage/utils/zustandStorage';

export type CsvColumns =
    | 'status'
    | 'priority'
    | 'name'
    | 'description'
    | 'checklist'
    | 'tags'
    | 'planned_date'
    | 'due_date'
    | 'assignee'
    | 'created_by'
    | 'created_at'
    | 'completed_by'
    | 'completed_at'
    | 'deleted_by'
    | 'deleted_at'
    | 'attachments'
    | 'section';

const defaultSelectedColumns = new Set<CsvColumns>(['name', 'description', 'section', 'due_date']);

type CsvExportState = {
    selectedColumns: Set<CsvColumns>;
    selectedCustomFields: Array<string>;
    setSelectedColumns: (value: CsvExportState['selectedColumns']) => void;
    setSelectedCustomFields: (value: CsvExportState['selectedCustomFields']) => void;
};

export const useExportTaskCsvStore = create(
    persist<CsvExportState>(
        (set) => ({
            selectedColumns: defaultSelectedColumns,
            selectedCustomFields: [],
            setSelectedColumns: (value) => {
                set({ selectedColumns: value });
            },
            setSelectedCustomFields: (value) => {
                set({ selectedCustomFields: value });
            },
        }),
        {
            name: 'useTaskExportCsvStore',
            version: 1,
            storage: ZUSTAND_STORAGE,
        }
    )
);
