import React, { PropsWithChildren, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { differenceInMinutes, endOfDay, isBefore } from 'date-fns';
import {
    Alert,
    Button,
    ContextModalProps,
    Modal,
    ModalType,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import {
    cleanObjectNullAttribute,
    moveMeetingDatesFromToTimezone,
    moveMeetingDatesToMeetingTimezone,
    moveMeetingDatesToUserTimezone,
} from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    invalidateMeeting,
    invalidateMeetingSeries,
    usePreviousMeeting,
    useUpdateMeetingMutation,
} from 'Shared/services/meeting';
import { invalidateMeetingTopics } from 'Shared/services/meetingTopic';
import { useUpdateMeetingUsersMutation } from 'Shared/services/meetingUser';
import { ApiError } from 'Shared/types/apiError';
import { Meeting } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingForm } from './MeetingForm';

type EditMeetingModalFooterProps = {
    loading: boolean;
    onSave: () => void;
    close: () => Promise<void>;
};
const EditMeetingModalFooter = ({ loading, close, onSave }: EditMeetingModalFooterProps): JSX.Element => {
    return (
        <>
            <Button key="close" onClick={close}>
                <Trans>Close</Trans>
            </Button>
            <Button key="save" color={'primary'} loading={loading} onClick={onSave}>
                <Trans>Save</Trans>
            </Button>
        </>
    );
};

type EditMeetingModalProps = {
    meetingId: Id;
    onSave?: (title?: string) => void;
} & ContextModalProps &
    PropsWithChildren;

const getSimpleRecurrencePattern = (pattern: string) =>
    pattern &&
    [/BYDAY=\w\w/g, /BYMONTHDAY=\d*/g, /BYSETPOS=-?\d*/g].reduce(
        (result, attribute) => result.replace(attribute, ''),
        pattern
    );

export const EditMeetingModal = ({
    meetingId,
    onSave,
    children,
    ...modalProps
}: EditMeetingModalProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);

    const { confirm } = useConfirm();
    const dispatch = useAppDispatch();
    const { show: showNotification } = useNotification();

    const [meetingData, setMeetingData] = useState<Partial<Meeting> & { timeZone?: string }>({
        title: '',
        start_at: null,
        end_at: null,
        location: '',
        meetingUsers: [],
    });

    const [updateMeetingUsers] = useUpdateMeetingUsersMutation();
    const { data: previousMeeting } = usePreviousMeeting(meeting?.id, meeting?.series_master_id);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorField, setErrorField] = useState<string>();
    const [saving, setSaving] = useState(false);
    const [updateMeeting] = useUpdateMeetingMutation();

    const updateMeetingData = (newData: Partial<Meeting>) => setMeetingData((oldData) => ({ ...oldData, ...newData }));

    const recurrencePatternHasChanged = () =>
        getSimpleRecurrencePattern(meetingData?.recurrence_pattern) !==
        getSimpleRecurrencePattern(meeting.seriesMaster?.recurrence_pattern);

    const disabledFutureOnSave = () => meeting?.type === 'singleInstance' && meetingData?.recurrence_pattern === 'once';

    const disabledThisOnSave = () =>
        !disabledFutureOnSave() &&
        (!meeting?.nextMeetings ||
            meeting?.nextMeetings.length === 0 ||
            isBefore(new Date(meeting?.nextMeetings[0].start_at), endOfDay(new Date(meetingData?.start_at))));

    useEffect(() => {
        if (meeting != null) {
            setErrorMessage(undefined);

            const { startDate, endDate } = moveMeetingDatesToMeetingTimezone({
                meeting,
            });

            setMeetingData({
                type: meeting.type,
                title: meeting.title,
                series_master_id: meeting.series_master_id,
                location: meeting.location,
                start_at: startDate.toISOString(),
                end_at: endDate.toISOString(),
                recurrence_pattern: meeting.seriesMaster?.recurrence_pattern,
                timeZone: meeting.start_at_time_zone,
                start_at_time_zone: meeting.start_at_time_zone, // meeting time zone before any modification
                tag_id: meeting.tag_id,
                meetingUsers: [...meeting.meetingUsers.filter(({ is_attendee }) => is_attendee)],
                disabledDateBefore: previousMeeting?.end_at,
            });
        }
    }, [meeting, previousMeeting]);

    const handleConfirmModalDone = async (applyOn: APPLY_ON) => {
        if (applyOn == null) {
            return;
        }

        let newStartDate, newEndDate;
        const unshiftedDates = moveMeetingDatesToUserTimezone({ meeting: meetingData });
        newStartDate = unshiftedDates.startDate;
        newEndDate = unshiftedDates.endDate;
        if (meetingData.start_at_time_zone !== meetingData.timeZone) {
            const newDates = moveMeetingDatesFromToTimezone({
                meeting: { ...meetingData, start_at: newStartDate, end_at: newEndDate },
                fromTimezone: meetingData.timeZone,
                toTimezone: meetingData.start_at_time_zone,
            });
            newStartDate = newDates.startDate;
            newEndDate = newDates.endDate;
        }

        const changes: Partial<Meeting> & { timeZone?: string } = {
            title: meetingData.title !== meeting.title ? meetingData.title : undefined,
            location: meetingData.location !== meeting.location ? meetingData.location : undefined,
            start_at:
                differenceInMinutes(newStartDate, new Date(meeting.start_at)) !== 0
                    ? newStartDate.toISOString()
                    : undefined,
            end_at:
                differenceInMinutes(newEndDate, new Date(meeting.end_at)) !== 0 ? newEndDate.toISOString() : undefined,
            recurrence_pattern:
                meetingData.recurrence_pattern !== meeting.seriesMaster?.recurrence_pattern
                    ? meetingData.recurrence_pattern
                    : undefined,
            tag_id: meetingData.tag_id !== meeting.tag_id ? meetingData.tag_id : undefined,
            start_at_time_zone:
                meetingData.timeZone !== meetingData.start_at_time_zone ? meetingData.timeZone : undefined,
            end_at_time_zone:
                meetingData.timeZone !== meetingData.start_at_time_zone ? meetingData.timeZone : undefined,
        };

        const meetingUsersToAdd = meetingData.meetingUsers.filter(
            (meetingUser) =>
                !meeting.meetingUsers.some(
                    (item) => item.id === meetingUser.id || (item.user_id && item.user_id === meetingUser.user_id)
                )
        );
        const meetingUsersToDelete = meeting.meetingUsers
            .filter(({ is_attendee }) => is_attendee)
            .filter((meetingUser) => !meetingData.meetingUsers.find((item) => item.id === meetingUser.id))
            .map((mu) => mu.id);

        const meetingUsersToUpdate = meetingData.meetingUsers
            .map((meetingUser) => {
                const current = meeting.meetingUsers.find(
                    (item) => item.id === meetingUser.id || (item.user_id && item.user_id === meetingUser.user_id)
                );
                if (!current) {
                    return null;
                }
                const changes: Partial<MeetingUser> = {};
                Object.keys(meetingUser)
                    .filter((key) => current[key] !== meetingUser[key])
                    .forEach((key) => {
                        changes[key] = meetingUser[key];
                    });

                return Object.keys(changes).length > 0
                    ? {
                          id: current.id,
                          changes: changes,
                      }
                    : null;
            })
            .filter((meetingUser) => !!meetingUser);

        meeting.meetingUsers.forEach((meetingUser) => {
            // Users that are removed are only removed from the attendee list
            if (!meetingData.meetingUsers.some((mu) => mu.id === meetingUser.id && mu.is_attendee)) {
                meetingUsersToUpdate.push({ id: meetingUser.id, changes: { is_attendee: false, attendance: null } });
            }
            // Users that already have access to the meeting but are not attendees are added to the attendee list
            const meetingUserFromForm = meetingData.meetingUsers.find((mu) => mu.user_id === meetingUser.user_id);
            if (!meetingUser.is_attendee && meetingUserFromForm?.is_attendee) {
                meetingUsersToUpdate.push({
                    id: meetingUser.id,
                    changes: { is_attendee: true, attendance: 'present' },
                });
            }
        });

        cleanObjectNullAttribute(changes);

        if (
            Object.keys(changes).length === 0 &&
            meetingUsersToAdd.length === 0 &&
            meetingUsersToDelete.length === 0 &&
            meetingUsersToUpdate.length === 0
        ) {
            if (onSave != null) {
                onSave();
            }
            await modalProps.close();
            return;
        }

        setSaving(true);
        let success = true;
        if (Object.keys(changes).length > 0) {
            try {
                const result = await updateMeeting({ changes, meetingId: meeting.id, applyOn });
                if ('data' in result) {
                    if (Object.keys(changes).indexOf('recurrence_pattern') > -1) {
                        dispatch(invalidateMeeting(meeting.series_master_id));
                        if (meeting.series_master_id != null) {
                            dispatch(invalidateMeetingSeries(meeting.series_master_id));
                        }
                    }
                    if (Object.keys(changes).includes('start_at') || Object.keys(changes).includes('end_at')) {
                        dispatch(invalidateMeetingTopics(meeting.id));
                    }
                }
                if ('error' in result && result.error instanceof ApiError) {
                    if (
                        result.error.matches({
                            path: 'exception is in series',
                        }) ||
                        result.error.matches({
                            path: 'Instance cannot be before an existing occurrence',
                        })
                    ) {
                        setErrorMessage(
                            t`The date you've chosen is invalid as it would overlap an existing meeting in this series`
                        );
                    } else {
                        showNotification(UnexpectedErrorNotification);
                    }
                    setSaving(false);
                    return;
                }
            } catch (e) {
                success = false;
                showNotification(UnexpectedErrorNotification);
            }
        }
        const res = await updateMeetingUsers({
            meetingId: meeting.id,
            changes: {
                addedMeetingUsers: meetingUsersToAdd,
                updatedMeetingUsers: meetingUsersToUpdate,
            },
            applyOn: applyOn,
        });
        if ('error' in res && res?.error?.data?.errors?.length > 0) {
            showNotification(UnexpectedErrorNotification);
            success = false;
        }
        setSaving(false);
        if (success && onSave != null) {
            onSave();
        }
        await modalProps.close();
    };

    const handleSave = async (e) => {
        e.preventDefault();
        setErrorMessage(undefined);
        setErrorField(undefined);

        if (meetingData.title.trim().length < 1) {
            setErrorField('title');
            return;
        }

        const applyOn = await confirm(
            {
                type: ModalType.Question,
                disabledThis: disabledThisOnSave() || recurrencePatternHasChanged(),
                disabledFuture: disabledFutureOnSave(),
                showFuture: true,
                showAll: false,
                defaultOption: APPLY_ON.FUTURE_MEETINGS,
            },
            ConfirmSaveMeetingModal
        );
        await handleConfirmModalDone(applyOn);
    };

    return (
        <>
            <Modal size={'lg'} {...modalProps}>
                <Modal.Header title={t`Edit meeting`} />
                <Modal.Body>
                    <MeetingForm
                        mode={'edit'}
                        template={meeting}
                        meetingData={meetingData}
                        errorField={errorField}
                        hideRecurrence={meeting?.type === 'exception'}
                        updateMeetingData={updateMeetingData}
                        onSave={handleSave}
                    />
                    {errorMessage != null && <Alert type={'danger'} title={errorMessage} />}
                </Modal.Body>
                <Modal.Footer>
                    <EditMeetingModalFooter loading={saving} close={modalProps.close} onSave={handleSave} />
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
