import { create } from 'zustand';
import { ChargebeeFrequency, PlanType } from '@wedo/types';

export type State = {
    // plan
    planType: PlanType;
    userLicenses: number;
    couponCodes: string[];
    frequency: ChargebeeFrequency;
    currency: 'CHF' | 'EUR' | 'USD';

    // addons
    lightUserLicenses: number;
    signatureAddon: boolean;
    signatureQuota: number | 'no-quota';
    governanceLicenses: number;

    // confirm
    paymentMethod: 'invoice' | 'card';
    agree: boolean;
    onboardingCompleted: boolean;

    source?: 'manage_subscription' | 'manage_addons' | 'licenses';
};

export type Actions = {
    // plan
    setPlanType: (planType: PlanType) => void;
    setUserLicenses: (userLicenses: number) => void;
    setCouponCodes: (coupons: string[]) => void;
    setFrequency: (frequency: ChargebeeFrequency) => void;
    setCurrency: (currency: 'CHF' | 'EUR' | 'USD') => void;

    // addons
    setLightUserLicenses: (userLicenses: number) => void;
    setSignatureAddon: (active: boolean) => void;
    setSignatureQuota: (quota: number) => void;
    setGovernanceLicenses: (governanceLicenses: number) => void;

    // confirm
    setPaymentMethod: (paymentMethod: 'invoice' | 'card') => void;
    setAgree: (agree: boolean) => void;
    setOnboardingCompleted: (onboardingCompleted: boolean) => void;

    reset: () => void;
    setSource: (source: 'manage_subscription' | 'manage_addons' | 'licenses') => void;
};

const SubscriptionPageInitialState: State = {
    // plan
    planType: 'pro',
    userLicenses: 0,
    couponCodes: undefined,
    frequency: 'yearly',
    currency: 'CHF',

    // addons
    lightUserLicenses: 0,
    signatureAddon: undefined,
    signatureQuota: 'no-quota',
    governanceLicenses: 0,

    // confirm
    paymentMethod: 'card',
    agree: false,
    onboardingCompleted: false,

    source: null,
};

export const useSubscriptionStore = create<State & { actions: Actions }>()((set) => ({
    ...SubscriptionPageInitialState,

    actions: {
        // plan
        setPlanType: (planType) => set(() => ({ planType })),
        setUserLicenses: (userLicenses) => set(() => ({ userLicenses })),
        setCouponCodes: (couponCodes) => set(() => ({ couponCodes })),
        setFrequency: (frequency) => set(() => ({ frequency })),
        setCurrency: (currency) => set(() => ({ currency })),

        // addons
        setLightUserLicenses: (userLicenses) => set(() => ({ lightUserLicenses: userLicenses })),
        setSignatureAddon: (active) => set(() => ({ signatureAddon: active })),
        setSignatureQuota: (quota) => set(() => ({ signatureQuota: quota })),
        setGovernanceLicenses: (governanceLicenses) => set(() => ({ governanceLicenses })),

        // confirm
        setPaymentMethod: (paymentMethod) => set(() => ({ paymentMethod })),
        setAgree: (agree) => set(() => ({ agree })),
        setOnboardingCompleted: (onboardingCompleted) => set(() => ({ onboardingCompleted })),

        reset: () => set(SubscriptionPageInitialState),
        setSource: (source) => set(() => ({ source })),
    },
}));
