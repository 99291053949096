import { Id } from '@wedo/types';
import { ActivityLog } from 'Shared/types/activityLog';
import { baseApi, configureTag } from './base';

export const { tagType, tag, tags } = configureTag('ActivityFeed');
export const tagList: Id = 'list';

interface GetActivityFeedsQueryProps {
    filters?: string[];
    tags?: Id[];
    teams?: Id[];
    users?: Id[];
    checklistTemplates?: Id[];
    offset?: number;
    limit?: number;
}

export const activityFeedApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getActivityFeeds: build.query<ActivityLog[], GetActivityFeedsQueryProps>({
                query: (params) => ({ url: 'activity_feed', params: params as Record<string, unknown> }),
                providesTags: (result) => [...tags(result), tag(tagList)],
            }),
        }),
    });

export const { useGetActivityFeedsQuery } = activityFeedApi;

export const invalidateActivityFeeds = () => activityFeedApi.util.invalidateTags([tag(tagList)]);
