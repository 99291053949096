import { Fragment, useRef } from 'react';
import { useStore } from 'zustand';
import { colors } from '@wedo/design-system';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';
import { useGanttContextStore } from './GanttContext';
import { RowsBackgroundImage } from './GanttView';
import { TimelineDependencies } from './TimelineDependencies';
import { TimelineFloatingDependency } from './TimelineFloatingDependency';
import { TimelineHeader } from './TimelineHeader';
import { TimelineMeetings } from './TimelineMeetings';
import { TimelineMouseMarker } from './TimelineMouseMarker';
import { TimelineSection } from './TimelineSection';
import { TimelineTask } from './TimelineTask';
import { TimelineTodayMarker } from './TimelineTodayMarker';

export const WeekendBackgroundImage = `repeating-linear-gradient(to right, transparent, transparent calc(5 * var(--column-width) - 1px), ${colors.gray['50']} calc(5 * var(--column-width) - 1px), ${colors.gray['50']} calc(7 * var(--column-width)))`;

export const TimelineView = () => {
    const store = useGanttContextStore()!;
    const sectionsWithTasks = useStore(store, ({ data }) => data.sectionsWithTasks!);
    const closedSections = useStore(store, ({ view }) => view.closedSections);
    const zoom = useStore(store, ({ view }) => view.zoom);

    const timelineRef = useRef<HTMLDivElement>(null);

    const handleInfiniteScroll = () => {
        store.getState().eventBus.dispatchInfiniteScrollEvent();
    };

    return (
        <div
            ref={timelineRef}
            className="relative h-fit min-h-full grid auto-rows-[var(--row-height)] group/timeline flex-1 gap-px isolate"
            style={{
                '--column-width': `${zoom.columnWidth}px`,
                backgroundImage: `${RowsBackgroundImage}, ${WeekendBackgroundImage}`,
                backgroundPosition: '0 0, calc(mod(mod(var(--start-day) - 3, 7) + 6, 7) * -1 * var(--column-width)) 0',
            }}
        >
            <TimelineHeader />
            <TimelineMeetings />
            <TimelineDependencies />
            <TimelineFloatingDependency timelineRef={timelineRef} />
            {sectionsWithTasks.map(
                (section) =>
                    (section.id !== '' || section.tasks.length > 0) && (
                        <Fragment key={section.id}>
                            <TimelineSection section={section} />
                            {!closedSections.has(section.id) &&
                                section.tasks.map((task) => (
                                    <TimelineTask
                                        key={task.id}
                                        task={task}
                                        section={section}
                                        timelineRef={timelineRef}
                                    />
                                ))}
                            {!closedSections.has(section.id) && <div />}
                            {section.isLastSection && (
                                <InfiniteScrollSensor
                                    onVisible={handleInfiniteScroll}
                                    className="!absolute bottom-0 h-px w-full"
                                />
                            )}
                        </Fragment>
                    )
            )}
            <TimelineMouseMarker timelineRef={timelineRef} />
            <TimelineTodayMarker />
        </div>
    );
};
