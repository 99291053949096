import { useLingui } from '@lingui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { faCheck, faHistory, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Skeleton, Tag, Tooltip, useModal } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { EmptyArray, formatDate } from '@wedo/utils';
import appStore from 'App/store';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useUser } from 'App/store/usersStore';
import { useGanttContextStore } from 'Pages/GanttPage/GanttContext';
import { usePendingTasks } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { Can } from 'Shared/components/Can';
import { CanTask } from 'Shared/components/task/CanTask';
import { getText } from 'Shared/components/task/TaskActivityLogsModal/TaskActivityLogsValues';
import { TaskHistoryModal } from 'Shared/components/task/TaskActivityLogsModal/TaskHistoryModal';
import { TaskDropdown } from 'Shared/components/task/TaskDetail/TaskDropdown';
import { TaskDetailAssignee } from 'Shared/components/task/TaskDetail/rows/TaskDetailAssignee';
import { TaskDetailAttachments } from 'Shared/components/task/TaskDetail/rows/TaskDetailAttachments';
import { TaskDetailBreadcrumb } from 'Shared/components/task/TaskDetail/rows/TaskDetailBreadcrumb';
import { TaskDetailChecklist } from 'Shared/components/task/TaskDetail/rows/TaskDetailChecklist';
import { TaskDetailComments } from 'Shared/components/task/TaskDetail/rows/TaskDetailComments/TaskDetailComments';
import { TaskDetailCustomFieldGroups } from 'Shared/components/task/TaskDetail/rows/TaskDetailCustomFieldGroups';
import { TaskDetailCustomFields } from 'Shared/components/task/TaskDetail/rows/TaskDetailCustomFields';
import { TaskDetailDescription } from 'Shared/components/task/TaskDetail/rows/TaskDetailDescription';
import { TaskDetailDueDate } from 'Shared/components/task/TaskDetail/rows/TaskDetailDueDate';
import { TaskDetailMeetings } from 'Shared/components/task/TaskDetail/rows/TaskDetailMeetings';
import { TaskDetailName } from 'Shared/components/task/TaskDetail/rows/TaskDetailName';
import { TaskDetailPriority } from 'Shared/components/task/TaskDetail/rows/TaskDetailPriority';
import { TaskDetailRelativeDueDate } from 'Shared/components/task/TaskDetail/rows/TaskDetailRelativeDueDate';
import { TaskDetailRelativeStartDate } from 'Shared/components/task/TaskDetail/rows/TaskDetailRelativeStartDate';
import { TaskDetailStartDate } from 'Shared/components/task/TaskDetail/rows/TaskDetailStartDate';
import { TaskDetailWatchers } from 'Shared/components/task/TaskDetail/rows/TaskDetailWatchers';
import { TaskDetailWorkspaces } from 'Shared/components/task/TaskDetail/rows/TaskDetailWorkspaces';
import { TaskDetailSubTasks } from 'Shared/components/task/TaskDetail/rows/subTasks/TaskDetailSubTasks';
import { TaskDetailDependencies } from 'Shared/components/task/TaskDetail/rows/taskDependency/TaskDetailDependencies';
import { useConfirmCompleteTask } from 'Shared/hooks/useConfirmCompleteTask';
import { useTask } from 'Shared/hooks/useTask';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { useGetTaskMeetingsQuery } from 'Shared/services/meeting';
import { invalidateTasks, useGetTaskActivityLogsQuery } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';
import { TaskFilter, TaskType } from 'Shared/types/task';
import { Permission } from 'Shared/utils/rbac';

type TaskDetailComponentProps = {
    workspaceId?: Id;
    meetingId?: Id;
    taskId: string;
    defaultView?: TaskFilter;
    allowSubTasksDependencies?: boolean;
};

const TaskDetailComponent = ({
    workspaceId = null,
    meetingId,
    taskId,
    defaultView,
    allowSubTasksDependencies,
}: TaskDetailComponentProps) => {
    const { templateId, checklistId } = useParams();

    const { task } = useTask(taskId, meetingId);

    const { data: taskMeetings = EmptyArray } = useGetTaskMeetingsQuery(
        { taskId, limit: 1 },
        { refetchOnMountOrArgChange: true, skip: !taskId }
    );
    const ref = useRef<HTMLDivElement>();

    const isTaskTemplate = templateId != null;

    useLayoutEffect(() => {
        if (ref?.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [taskId]);

    useInvalidationEvent(() => appStore.dispatch(invalidateTasks([{ id: taskId }])), taskQueryTag.updated(taskId, '*'));

    return (
        <div className="min-h-full w-full pb-14" ref={ref}>
            <TaskDetailBreadcrumb taskId={taskId} />
            <TaskDetailName taskId={taskId} />
            <TaskDetailWorkspaces taskId={taskId} />
            <TaskDetailAssignee
                taskId={taskId}
                workspaceId={workspaceId}
                templateId={templateId}
                meetingId={meetingId}
                checklistId={checklistId}
                defaultView={defaultView}
            />
            <TaskDetailPriority taskId={taskId} />
            <TaskDetailChecklist taskId={taskId} />
            {taskMeetings?.length > 0 && <TaskDetailMeetings taskId={taskId} />}

            {task?.type === TaskType.Task && (
                <>
                    {!isTaskTemplate ? (
                        <TaskDetailStartDate taskId={taskId} />
                    ) : (
                        <TaskDetailRelativeStartDate taskId={taskId} />
                    )}
                </>
            )}

            {!isTaskTemplate ? <TaskDetailDueDate taskId={taskId} /> : <TaskDetailRelativeDueDate taskId={taskId} />}
            <TaskDetailCustomFields taskId={taskId} />
            <TaskDetailCustomFieldGroups taskId={taskId} />
            <TaskDetailDescription taskId={taskId} />
            <TaskDetailAttachments taskId={taskId} currentWorkspaceId={workspaceId} />
            <TaskDetailSubTasks taskId={taskId} />
            <TaskDetailDependencies taskId={taskId} allowSubTasksDependencies={allowSubTasksDependencies} />
            <TaskDetailWatchers
                taskId={taskId}
                templateId={templateId}
                checklistId={checklistId}
                meetingId={meetingId}
                workspaceId={workspaceId}
            />
            {taskId && !isTaskTemplate && <TaskDetailComments taskId={taskId} />}
        </div>
    );
};

type TaskDetailHeaderProps = {
    taskId: Id;
    meetingId?: Id;
    workspaceId?: Id;
    checklistId?: Id;
    userId?: Id;
    hideTasksToExport?: boolean;
    allowSubTasksDependencies?: boolean;
};

const TaskDetailHeader = ({
    taskId,
    meetingId,
    workspaceId,
    checklistId,
    userId,
    hideTasksToExport = false,
    allowSubTasksDependencies,
}: TaskDetailHeaderProps) => {
    const { i18n } = useLingui();
    const queryClient = useQueryClient();
    const ganttContextStore = useGanttContextStore();

    const { task } = useTask(taskId);
    const { isCompleted: isTaskCompleted } = useTaskStatus(task);
    const deletedByUser = useUser(Number(task?.deleted_by));
    const completedByUser = useUser(Number(task?.completed_by?.id));

    const { addToCompletedTasks, removeFromCompletedTasks, removeFromDeletedTasks } = usePendingTasks();
    const { templateId } = useParams();
    const { confirmCompleteTask } = useConfirmCompleteTask(task);

    const { mutateAsync: updateStatus, isLoading } = trpc.task.updateStatus.useMutation({
        onSuccess: () => invalidateCachedTasks(),
    });

    const handleTaskCompleteToggle = async () => {
        let applyOnResult = ApplyOn.OnlyCurrentTask;
        if (isTaskCompleted) {
            removeFromCompletedTasks(task);
        } else {
            const { confirm: shouldComplete, applyOn } = await confirmCompleteTask();
            applyOnResult = applyOn ?? applyOnResult;
            if (!shouldComplete) {
                return;
            }
            addToCompletedTasks(task);
        }
        await updateStatus({ taskIds: [task.id], completed: !isTaskCompleted, applyOn: applyOnResult });
        if (ganttContextStore != null) {
            invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'subTasks')]);
        }
    };

    const handleTaskRestore = async () => {
        await updateStatus({ taskIds: [task.id], deleted: false, applyOn: ApplyOn.OnlyCurrentTask });
        removeFromDeletedTasks(taskId);
    };

    return (
        <div className="flex flex-col border-b border-gray-200 py-2">
            <div className="flex items-center justify-between px-2">
                <div className="flex gap-2">
                    <CanTask task={task} permission={Permission.ManageTasks}>
                        {templateId == null &&
                            (task?.deleted === false ? (
                                <Button
                                    loading={isLoading}
                                    onClick={handleTaskCompleteToggle}
                                    icon={isTaskCompleted ? faUndo : faCheck}
                                    size="sm"
                                    className="ignore-marker"
                                >
                                    {isTaskCompleted ? (
                                        task?.type === TaskType.Milestone ? (
                                            <Trans>Restore milestone</Trans>
                                        ) : (
                                            <Trans>Restore task</Trans>
                                        )
                                    ) : task?.type === TaskType.Milestone ? (
                                        <Trans>Complete milestone</Trans>
                                    ) : (
                                        <Trans>Complete task</Trans>
                                    )}
                                </Button>
                            ) : (
                                task?.deleted === true && (
                                    <Button loading={isLoading} onClick={handleTaskRestore} size="sm">
                                        {task?.type === TaskType.Milestone ? (
                                            <Trans>Restore milestone</Trans>
                                        ) : (
                                            <Trans>Restore the task</Trans>
                                        )}
                                    </Button>
                                )
                            ))}
                    </CanTask>
                    {task?.deleted ? (
                        <Tooltip
                            content={t`Deleted by ${deletedByUser.full_name} on the ${formatDate(
                                task.deleted_at,
                                'PPP',
                                i18n
                            )}`}
                        >
                            <Tag
                                className="hidden sm:flex"
                                color="red"
                                title={
                                    task?.type === TaskType.Milestone
                                        ? t`This milestone has been deleted and cannot be modified`
                                        : t`This task has been deleted and cannot be modified`
                                }
                            >
                                {task?.type === TaskType.Milestone ? (
                                    <Trans>Milestone deleted</Trans>
                                ) : (
                                    <Trans>Task deleted</Trans>
                                )}
                            </Tag>
                        </Tooltip>
                    ) : (
                        task?.completed && (
                            <Tooltip
                                content={t`Completed by ${completedByUser.full_name} on the ${formatDate(
                                    task.completed_at,
                                    'PPP',
                                    i18n
                                )}`}
                            >
                                <Tag
                                    className="hidden sm:flex"
                                    color="green"
                                    title={t`This task is completed and can't be modified`}
                                >
                                    {task?.type === TaskType.Milestone ? (
                                        <Trans>Milestone completed</Trans>
                                    ) : (
                                        <Trans>Task completed</Trans>
                                    )}
                                </Tag>
                            </Tooltip>
                        )
                    )}
                </div>
                <TaskDropdown
                    task={task}
                    meetingId={meetingId}
                    workspaceId={workspaceId}
                    checklistId={checklistId}
                    hideTasksToExport={hideTasksToExport}
                    userId={userId}
                    allowSubTasksDependencies={allowSubTasksDependencies}
                />
            </div>
        </div>
    );
};

type TaskDetailFooterProps = {
    taskId: Id;
};

export const TaskDetailFooter = ({ taskId }: TaskDetailFooterProps) => {
    const { open: openModal } = useModal();
    const { i18n } = useLingui();
    const { lastActivityLog, isLoadingLastTaskActivityLog } = useGetTaskActivityLogsQuery(
        { taskId, important: true, limit: 1 },
        {
            selectFromResult: ({ data = [], isLoading }) => ({
                lastActivityLog: (data || []).length > 0 ? data[0] : null,
                isLoadingLastTaskActivityLog: isLoading,
            }),
            skip: !taskId,
        }
    );

    return (
        <Can permission={Permission.ManageTasks}>
            <div className={'flex flex-1 items-center justify-between'}>
                <Tooltip content={formatDate(lastActivityLog?.created_at, 'short', i18n)}>
                    {!isLoadingLastTaskActivityLog ? (
                        <div className={clsx('w-0 flex-1 truncate px-1 text-center text-sm text-gray-800')}>
                            {getText({ activity: lastActivityLog, i18n, includeMeeting: false })}
                        </div>
                    ) : (
                        <Skeleton className={'mx-auto h-5 w-full max-w-md'} />
                    )}
                </Tooltip>
                <Button
                    aria-label={t`Task history`}
                    title={t`Task history`}
                    variant="text"
                    icon={faHistory}
                    onClick={() => openModal(TaskHistoryModal, { taskId })}
                />
            </div>
        </Can>
    );
};

export const TaskDetail = Object.assign(TaskDetailComponent, { Header: TaskDetailHeader, Footer: TaskDetailFooter });
