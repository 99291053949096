import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, ExtendableSearchInput, Input } from '@wedo/design-system';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';

export const useResponsiveSearchInput = ({
    containerRef,
    search,
    handleSearch,
    title = t`Search`,
    size = 'md',
    handleBlur,
    handleFocus,
}: {
    containerRef: MutableRefObject<any>;
    search: string;
    handleSearch: (search: string) => void;
    title?: string;
    size?: 'sm' | 'md' | 'lg';
    handleBlur?: () => void;
    handleFocus?: () => void;
}) => {
    const { width: containerWidth = window.innerWidth } = useElementSize(containerRef);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [isSearchInputOpen, setIsSearchInputOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isSearchInputOpen) {
            searchInputRef.current?.focus();
        }
    }, [isSearchInputOpen, searchInputRef?.current]);

    useEffect(() => {
        if (search && !isSearchInputOpen && containerWidth < getBreakpointValue('lg')) {
            setIsSearchInputOpen(true);
        }
        if (containerWidth >= getBreakpointValue('lg')) {
            setIsSearchInputOpen(false);
        }
    }, [search, containerWidth]);

    return {
        toggleButton:
            containerWidth >= getBreakpointValue('lg') ? (
                <ExtendableSearchInput size={size} search={search} onSearch={handleSearch} />
            ) : (
                <Button
                    active={!isEmpty(search)}
                    title={title}
                    icon={faSearch}
                    size={size}
                    variant="text"
                    onClick={() => {
                        setIsSearchInputOpen((wasSearchOpen) => !wasSearchOpen);
                    }}
                />
            ),

        searchInput: isSearchInputOpen && (
            <Input
                debounce
                title={title}
                trailingIcon={faXmark}
                trailingIconOnClick={() => {
                    handleSearch('');
                    setIsSearchInputOpen(false);
                }}
                value={search}
                ref={searchInputRef}
                onChange={(e) => handleSearch(e.target.value)}
                leadingIcon={faSearch}
                className={'w-full'}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        ),
    };
};
