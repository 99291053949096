import React from 'react';
import { useParams } from 'react-router-dom';
import { faBriefcase, faCalendarDay, faClipboardListCheck, faRss } from '@fortawesome/pro-regular-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { msg, Trans } from '@lingui/macro';
import { Banner, Button, useModal } from '@wedo/design-system';
import { TeamDescriptionPopover } from 'Pages/TeamsPage/TeamDescriptionPopover';
import { PageHeaderTab, TabsPageHeader } from 'Shared/components/layout/TabsPageHeader';
import { SettingsModal } from 'Shared/components/team/SettingsModal/SettingsModal';
import { TeamDropdown } from 'Shared/components/team/TeamDropdown';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetTeamQuery } from 'Shared/services/team';

const Tabs: PageHeaderTab[] = [
    {
        to: 'workspaces',
        icon: faBriefcase,
        title: msg`Workspaces`,
    },
    {
        to: 'templates',
        icon: faClipboardListCheck,
        title: msg`Templates`,
    },
    {
        to: 'meetings',
        icon: faCalendarDay,
        title: msg`Meetings`,
    },
    {
        to: 'feed',
        icon: faRss,
        title: msg`Feed`,
        matchPathname: 'feed/*',
    },
];

export const TeamPage = () => {
    const { teamId } = useParams();
    const { data: team } = useGetTeamQuery({ teamId }, { skip: !teamId });
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(team);

    usePageTitle(team?.name);

    const actions = (
        <div className="flex items-center gap-2 text-gray-700">
            <div className="hidden items-center gap-2 md:flex">
                <Button
                    variant="ghost"
                    onClick={() =>
                        open(SettingsModal, { teamId, isReadOnly: !isCurrentUserModerator, defaultIndex: 1 })
                    }
                    shape="circle"
                >
                    <UsersAvatarGroup
                        users={team?.userGroup?.members?.map((member) => member?.user)}
                        size="sm"
                        maxDisplayed={3}
                        tooltipPlacement="bottom"
                    />
                </Button>
            </div>

            <TeamDropdown team={team} size="sm" />
        </div>
    );

    return (
        <>
            {team?.deleted && (
                <Banner type="danger" icon={faTrash}>
                    <Trans>The {team?.name} team has been deleted</Trans>
                </Banner>
            )}

            <TabsPageHeader
                title={<TeamDescriptionPopover team={team} />}
                actions={actions}
                baseLink={`/teams/${teamId}/`}
                tabs={Tabs}
            />
        </>
    );
};
