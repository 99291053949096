import { I18nProvider } from '@lingui/react';
import { Page, View, Document } from '@react-pdf/renderer';
import React, { createContext, useContext } from 'react';
import { type Messages, setupI18n } from '@lingui/core';
import { DesignPdfContext } from '~/types';
import { colors } from '../colors';
import { fontSizes, mmToPx, registerFont } from '../utils';
import { Attendees } from './Attendees';
import { Header } from './Header';
import { NextMeeting } from './NextMeeting';
import { Signatures } from './Signatures';
import { Topics } from './Topics';
import { BlocksToDisplay, type BlockToDisplay, ElementsToDisplay, type ElementToDisplay, type State } from './store';

export type MeetingPdfProps = State & {
    locale: {
        code: string;
        messages: Messages;
    };
    logo: string;
};

type MeetingPdfContextProps = MeetingPdfProps &
    DesignPdfContext & {
        isElementVisible: Record<ElementToDisplay, boolean>;
        isBlockVisible: Record<BlockToDisplay, boolean>;
    };

export const MeetingPdfContext = createContext<MeetingPdfContextProps>(null);

export const useMeetingPdfContext = () => useContext(MeetingPdfContext);

export const MeetingPdf = (props: MeetingPdfProps) => {
    const isElementVisible: Record<ElementToDisplay, boolean> = Object.fromEntries(
        ElementsToDisplay.map((id) => [id, props.settings.elementsToDisplay.includes(id)])
    );

    const isBlockVisible: Record<BlockToDisplay, boolean> = Object.fromEntries(
        BlocksToDisplay.map((id) => [id, props.settings.blocksToDisplay.includes(id)])
    );

    const fontSize: Record<keyof typeof fontSizes, number> = Object.fromEntries(
        Object.entries(fontSizes).map(([key, sizes]) => [key, sizes[props.settings.fontSize]])
    );

    const [paddingTop, paddingRight, paddingBottom, paddingLeft] = props.settings.margins;

    const minPresenceAhead = 50;

    const spacing = {
        page: {
            paddingTop: mmToPx(paddingTop),
            paddingRight: mmToPx(paddingRight),
            paddingBottom: mmToPx(paddingBottom),
            paddingLeft: mmToPx(paddingLeft),
        },
        px: 1,
        small: 2,
        normal: 4,
        medium: 6,
        large: 8,
        extraLarge: 12,
        signature: {
            small: 68,
            large: 175,
        },
    } as const;

    const borderRadius = {
        small: 2,
        normal: 3,
        full: '100%',
    } as const;

    const color = {
        primaryText: colors.black,
        secondaryText: colors.gray['500'],
        secondaryDarkText: colors.gray['700'],
        decisionText: colors.green['700'],
        decisionBackground: colors.green['50'],
        noteText: colors.yellow['700'],
        noteBackground: colors.yellow['50'],
        linkText: colors.blue['500'],
        borderColor: colors.gray['200'],
        background: {
            light: colors.gray['100'],
            normal: colors.gray['200'],
            dark: colors.gray['300'],
        },
    };

    const i18n = setupI18n({
        locale: props.locale.code,
        messages: {
            [props.locale.code]: props.locale.messages,
        },
    });

    return (
        <I18nProvider i18n={i18n}>
            <MeetingPdfContext.Provider
                value={{
                    ...props,
                    fontSize,
                    spacing,
                    borderRadius,
                    color,
                    isElementVisible,
                    isBlockVisible,
                    minPresenceAhead,
                }}
            >
                <Document>
                    <Page
                        size="A4"
                        orientation={props.settings.orientation}
                        style={{
                            flexDirection: 'column',
                            fontFamily: registerFont(props.settings.fontFamily),
                            fontSize: fontSize.normal,
                            paddingTop: spacing.page.paddingTop,
                            paddingRight: spacing.page.paddingRight,
                            paddingBottom: isElementVisible.footer
                                ? spacing.page.paddingBottom + fontSize.small + spacing.large
                                : spacing.page.paddingBottom,
                            paddingLeft: spacing.page.paddingLeft,
                            gap: spacing.extraLarge * 2,
                        }}
                    >
                        <Header />
                        {isElementVisible.attendees && props.meeting.meetingUsers.length > 0 && <Attendees />}
                        <View style={{ display: 'flex', flexDirection: 'column', gap: spacing.large }}>
                            <Topics />
                            {(isElementVisible.signatures || !props.useBasicSignatureLayout) && <Signatures />}
                            {isElementVisible.nextMeeting && <NextMeeting />}
                        </View>
                    </Page>
                </Document>
            </MeetingPdfContext.Provider>
        </I18nProvider>
    );
};
