import clsx from 'clsx';
import { useWebSocket } from '~/modules/ws/useWebSocket';

export const WebSocketStatusIndicator = () => {
    const { isConnected, isEnabled } = useWebSocket(({ isConnected, isEnabled }) => ({ isConnected, isEnabled }));

    return (
        <div
            className={clsx(
                'w-3 h-3 rounded-full',
                !isConnected ? 'bg-gray-400' : !isEnabled ? 'bg-red-500' : 'bg-green-500'
            )}
        >
            {isConnected && isEnabled && <div className="bg-green-500 animate-ping w-full h-full rounded-full" />}
        </div>
    );
};
