import { t, Trans } from '@lingui/macro';
import { Button, Card, useModal } from '@wedo/design-system';
import { DisableTotpModal } from 'Pages/settings/security/components/DisableTotpModal';

export const DisableTotpCard = (): JSX.Element => {
    const { open } = useModal();

    const handleOpen = (): void => {
        open(DisableTotpModal);
    };

    return (
        <Card>
            <Card.Header title={t`Two-factor authentication`} />
            <Card.Body>
                <Button onClick={handleOpen} color="danger">
                    <Trans>Disable two-factor authentication</Trans>
                </Button>
            </Card.Body>
        </Card>
    );
};
