import { useLingui } from '@lingui/react';
import { useParams } from 'react-router-dom';
import { msg } from '@lingui/macro';
import { Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import {
    CircleMemberProfileTabsCircles,
    CircleMemberProfileTabsRoles,
    filterCirclesByUser,
    CircleMemberProfileModalContext,
} from 'Pages/governance/CircleMember/CircleMemberProfileModal';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { useFetchCirclesQuery } from 'Shared/services/governance';

type GovernancePageParams = {
    filter: '' | 'circles';
};

const circlesTabTitle = msg`Circles`;
const rolesTabTitle = msg`Roles`;

export const CircleMemberProfile = () => {
    const { i18n } = useLingui();
    const { userId } = useParams();
    const { filter } = useParams<GovernancePageParams>();
    const { currentData: allCircles = [], isLoading, isFetching } = useFetchCirclesQuery();
    const navigate = useNavigate();

    const userCircles = filterCirclesByUser(allCircles, userId);
    const roles = (userCircles ?? []).filter((circle) => circle.type === 'role');
    const circles = (userCircles ?? []).filter((circle) => circle.type === 'circle');

    const tabs = [
        { to: '', title: `${i18n._(rolesTabTitle)} (${(roles ?? []).length})` },
        { to: '/circles', title: `${i18n._(circlesTabTitle)} (${(circles ?? []).length})` },
    ];

    const handleSelectNode = (circleId: Id) => {
        void navigate('/governance', { state: { circleId: circleId } });
    };

    return (
        <div className="mx-auto h-full max-h-full w-full px-6 overflow-y-scroll">
            <NavBar basePath={'/users/' + userId + '/governance'} tabs={tabs} />
            {isLoading || isFetching ? (
                <div className="mb-2 flex flex-col gap-2 py-3">
                    <Skeleton count={3} className="h-10" />
                </div>
            ) : (
                <div className="py-3">
                    <CircleMemberProfileModalContext.Provider value={{ allCircles }}>
                        {filter === 'circles' ? (
                            <CircleMemberProfileTabsCircles
                                userId={userId}
                                circles={circles}
                                onSelectNode={handleSelectNode}
                            />
                        ) : (
                            <CircleMemberProfileTabsRoles roles={roles} onSelectNode={handleSelectNode} />
                        )}
                    </CircleMemberProfileModalContext.Provider>
                </div>
            )}
        </div>
    );
};
