import { Svg, Path, Rect } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const LinkedInIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Rect x="6" y="6" width="500" height="500" rx="250" fill="#1275B1" />
            <Path
                d="M195.617 143.36C195.617 160.048 181.152 173.577 163.309 173.577C145.465 173.577 131 160.048 131 143.36C131 126.671 145.465 113.143 163.309 113.143C181.152 113.143 195.617 126.671 195.617 143.36Z"
                fill="white"
            />
            <Path d="M135.418 195.787H190.647V363.143H135.418V195.787Z" fill="white" />
            <Path
                d="M279.564 195.787H224.336V363.143H279.564C279.564 363.143 279.564 310.457 279.564 277.515C279.564 257.743 286.315 237.885 313.253 237.885C343.697 237.885 343.513 263.759 343.371 283.805C343.186 310.008 343.629 336.747 343.629 363.143H398.857V274.816C398.389 218.417 383.693 192.43 335.345 192.43C306.632 192.43 288.834 205.465 279.564 217.259V195.787Z"
                fill="white"
            />
        </Svg>
    );
};
