import React, { FC, useEffect, useState } from 'react';
import { faUsersSlash } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { EmptyState, Pagination, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { PartialUser } from 'Pages/settings/users/utils/user';
import { EditTeamTable } from 'Shared/components/user/AddEditUserModal/EditTeamTable';
import { useGetAdminTeamsQuery } from 'Shared/services/admin';

type EditTeamModalProps = {
    user: PartialUser;
    selectedTeams: { id: Id }[];
    setSelectedTeams: (teams: { id: Id }[]) => void;
    onChange?: (user: PartialUser) => void;
    isReadonly?: boolean;
};

export const EditTeamTab: FC<EditTeamModalProps> = ({
    user,
    selectedTeams,
    setSelectedTeams,
    onChange,
    isReadonly = false,
}) => {
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const { data: teams, isFetching: isTeamsLoading } = useGetAdminTeamsQuery({ search: '', page, pageSize });
    const [visitedPage, setVisitedPage] = useState<number[]>([]); // teams page view by the user

    useEffect(() => {
        if (teams && !visitedPage.includes(page)) {
            const userTeams = teams.data.filter((team) =>
                team.userGroup.members.some(({ user_id }) => user_id === user.id)
            );
            onChange?.({ ...user, teams: [...(user?.teams || []), ...userTeams] });
            setSelectedTeams([...selectedTeams, ...userTeams]);
            setVisitedPage([...visitedPage, page]);
        }
    }, [teams]);

    const handleTeamSelect = (team: { id: Id }, select: boolean) => {
        setSelectedTeams(select ? [...selectedTeams, team] : selectedTeams.filter(({ id }) => id !== team.id));
    };

    return (
        <div className="@container flex flex-col gap-4">
            {isTeamsLoading ? (
                <div className="flex flex-col gap-2">
                    <Skeleton count={5} className={'h-10'} />
                </div>
            ) : teams?.data.length > 0 ? (
                <>
                    <EditTeamTable
                        teams={teams}
                        user={user}
                        selectedTeams={selectedTeams}
                        onTeamSelect={handleTeamSelect}
                        isReadonly={isReadonly}
                    />
                    <div className="flex justify-center">
                        <Pagination
                            currentPage={page}
                            onPageChange={setPage}
                            totalCount={Number(teams.pagination.rowCount)}
                            pageSize={pageSize}
                        />
                    </div>
                </>
            ) : (
                <EmptyState icon={faUsersSlash} size="md">
                    <EmptyState.Text>
                        <Trans>No teams available</Trans>
                    </EmptyState.Text>
                </EmptyState>
            )}
        </div>
    );
};
