import { useQueryClient } from '@tanstack/react-query';
import { type ConsumerProps, createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStore } from 'zustand';
import { selectSelectedTaskId } from 'Pages/meeting/MeetingViewSlice';
import { InfiniteScrollEvent } from './events';
import { createStore } from './store';
import { useInfiniteTasks } from './useInfiniteTasks';
import { useSections } from './useSections';
import { computeDateWindows, groupTasks } from './utils';

type GanttContextProps = {
    isLoading: boolean;
    store: ReturnType<typeof createStore> | null;
};

const GanttContext = createContext<GanttContextProps>({
    isLoading: true,
    store: null,
});

type GanttContextProviderProps = {
    children: ReactNode;
};

export const GanttContextProvider = ({ children }: GanttContextProviderProps) => {
    const queryClient = useQueryClient();

    const { sections } = useSections();
    const { tasks, tryFetchNextPage } = useInfiniteTasks();

    const [store] = useState(() => {
        queryClient.removeQueries({ predicate: ({ queryKey: [segments] }) => segments.join('.') === 'task.gantt.get' });
        return createStore(sections, tasks);
    });

    const isLoading = useStore(store, ({ data }) => data.sectionsWithTasks == null);

    useEffect(() => {
        store.setState((state) => {
            if (sections != null) {
                state.data.sections = sections;
                state.data.sectionsById = new Map(sections.map((section) => [section.id, section]));
                if (state.data.tasks != null) {
                    state.data.dateWindows = computeDateWindows(state.data.tasks, state.data.sectionsById);
                    state.data.sectionsWithTasks = groupTasks(state.data.tasks, state.data.sectionsById);
                }
            }
        });
    }, [sections]);

    useEffect(() => {
        store.setState((state) => {
            if (tasks != null) {
                state.data.tasks = tasks;
                if (state.data.sectionsById != null) {
                    state.data.dateWindows = computeDateWindows(state.data.tasks, state.data.sectionsById);
                    state.data.sectionsWithTasks = groupTasks(state.data.tasks, state.data.sectionsById);
                }
            }
        });
    }, [tasks]);

    useEffect(() => {
        const eventBus = store.getState().eventBus;
        eventBus.addEventListener(InfiniteScrollEvent, tryFetchNextPage);
        return () => {
            eventBus.removeEventListener(InfiniteScrollEvent, tryFetchNextPage);
        };
    }, []);

    return <GanttContext.Provider value={{ isLoading, store }}>{children}</GanttContext.Provider>;
};

export const GanttContextConsumer = (props: ConsumerProps<GanttContextProps>) => {
    return <GanttContext.Consumer {...props} />;
};

export const useGanttContextStore = () => {
    return useContext(GanttContext).store;
};

export const useSelectedTaskId = () => {
    return useSelector(selectSelectedTaskId);
};
