import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import {
    type RefObject,
    useCallback,
    useMemo,
    useRef,
    useState,
    type PointerEvent as ReactPointerEvent,
    useEffect,
} from 'react';
import { invalidateQueries, useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/pro-duotone-svg-icons';
import { faDiamond } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { colors, getColorId } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Day } from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import appStore from 'App/store';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { useGanttContextStore, useSelectedTaskId } from './GanttContext';
import { TimelineTaskConnector } from './TimelineTaskConnector';
import { TimelineTaskHandle } from './TimelineTaskHandle';
import { toggleSubTasksEvent } from './events';
import { type Section, type Task } from './types';
import { useHover } from './useHover';
import { useSubTasks } from './useSubTasks';
import { useTask } from './useTask';
import {
    addTaskDependencies,
    durationInDays,
    GlobalDateWindow,
    isAfterOrEqual,
    isBeforeOrEqual,
    removeTaskDependencies,
    daysSinceEpoch,
    toLocalDateString,
} from './utils';

type DateProperty = 'plannedDate' | 'dueDate';

const useMemoState = <T extends unknown>(value: T) => {
    const previousState = useRef(value);
    const [state, setState] = useState(value);
    if (value !== previousState.current) {
        previousState.current = value;
        setState(value);
    }
    return [state, setState] as const;
};

type TimelineTaskProps = {
    task: Task;
    section: Section;
    timelineRef: RefObject<HTMLElement>;
};

export const TimelineTask = ({ task: initialTask, section, timelineRef }: TimelineTaskProps) => {
    const store = useGanttContextStore()!;
    const eventBus = store.getState().eventBus;

    const queryClient = useQueryClient();

    const ref = useRef<HTMLDivElement>();

    const dragStartRef = useRef<{ x: number; width: number; startDate: string; endDate: string }>(null);
    const wasDraggingRef = useRef(false);

    const [isOpen, setIsOpen] = useState(false);

    const selectedTaskId = useSelectedTaskId();

    const task = useTask(initialTask);

    const { subTasks } = useSubTasks(task.id, isOpen);

    const [updateTaskMutation] = useUpdateTaskMutation();

    const hoverProps = useHover(`task-${task.id}`);

    const [plannedDate, setPlannedDate] = useMemoState(task.plannedDate);
    const [dueDate, setDueDate] = useMemoState(task.dueDate);

    const [start, duration] = useMemo(
        () => [daysSinceEpoch(plannedDate ?? dueDate), durationInDays(plannedDate, dueDate)],
        [plannedDate, dueDate]
    );

    const updateTask = async (properties: { id: string; planned_date?: string | null; due_date?: string | null }) => {
        await updateTaskMutation({ ...properties, keepCache: true });
        await invalidateQueries(
            queryClient,
            [
                properties.due_date !== undefined && taskQueryTag.updated(initialTask.id, 'dueDate'),
                properties.planned_date !== undefined && taskQueryTag.updated(initialTask.id, 'plannedDate'),
            ].filter(Boolean)
        );
    };

    const handleClick = () => {
        if (!wasDraggingRef.current) {
            appStore.dispatch(taskSelected({ taskId: task.id }));
        }
    };

    const handleOverflowIconClick = () => {
        store
            .getState()
            .eventBus.dispatchScrollToDayEvent(
                start! - daysSinceEpoch(store.getState().data.dateWindows.get(GlobalDateWindow)!.minDate) - 1
            );
    };

    const handleToggleSubTasks = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);

    const handlePointerMove =
        (property?: DateProperty) =>
        ({ clientX }: PointerEvent) => {
            const { x, width } = dragStartRef.current!;
            const delta = Math.floor((clientX - x) / width);
            if (Math.abs(clientX - x) > 5) {
                wasDraggingRef.current = true;
            }
            if (property === 'plannedDate') {
                const newPlannedDate = new Date(new Date(plannedDate).getTime() + Day * delta);
                if (dueDate == null || isBeforeOrEqual(newPlannedDate, new Date(dueDate))) {
                    setPlannedDate((dragStartRef.current!.startDate = toLocalDateString(newPlannedDate)));
                    eventBus.dispatchRenderDependenciesEvent();
                }
            } else if (property === 'dueDate') {
                const newDueDate = new Date(new Date(dueDate).getTime() + Day * (delta + 1));
                if (plannedDate == null || isAfterOrEqual(newDueDate, new Date(plannedDate))) {
                    setDueDate((dragStartRef.current!.endDate = toLocalDateString(newDueDate)));
                    eventBus.dispatchRenderDependenciesEvent();
                }
            } else {
                const timelineX = timelineRef.current.getBoundingClientRect().x;
                const delta = Math.floor((clientX - timelineX - 8) / width) - Math.floor((x - timelineX - 8) / width);
                const newPlannedDate = new Date(new Date(plannedDate).getTime() + Day * delta);
                const newDueDate = new Date(new Date(dueDate).getTime() + Day * delta);
                setPlannedDate((dragStartRef.current!.startDate = toLocalDateString(newPlannedDate)));
                setDueDate((dragStartRef.current!.endDate = toLocalDateString(newDueDate)));
                eventBus.dispatchRenderDependenciesEvent();
            }
        };

    const handlePointerUp = (property?: DateProperty) => (event: PointerEvent) => {
        document.body.classList.remove('no-transition');
        const element = event.target as HTMLDivElement;
        element.parentElement!.classList.remove('dragging');
        element.onpointermove = null;
        element.onpointerup = null;
        if (property == null) {
            element.style.removeProperty('cursor');
        }
        element.releasePointerCapture(event.pointerId);
        if (property === 'plannedDate' && dragStartRef.current!.startDate != null) {
            void updateTask({
                id: task.id,
                planned_date: dragStartRef.current!.startDate,
                due_date: task.originalDueDate == null ? dueDate : undefined,
            });
        } else if (property === 'dueDate' && dragStartRef.current!.endDate != null) {
            void updateTask({
                id: task.id,
                planned_date: task.originalPlannedDate == null ? plannedDate : undefined,
                due_date: dragStartRef.current!.endDate,
            });
        } else if (dragStartRef.current!.startDate != null && dragStartRef.current!.endDate != null) {
            void updateTask({
                id: task.id,
                planned_date: dragStartRef.current!.startDate,
                due_date: dragStartRef.current!.endDate,
            });
        }
        dragStartRef.current = null;
    };

    const handlePointerDown =
        (property?: DateProperty) =>
        ({ target, pointerId, clientX }: ReactPointerEvent<HTMLButtonElement>) => {
            document.body.classList.add('no-transition');
            wasDraggingRef.current = false;
            const element = target as HTMLDivElement;
            element.parentElement!.classList.add('dragging');
            if (property == null) {
                element.style.cursor = 'grabbing';
            }
            const { left, right } = element.getBoundingClientRect();
            dragStartRef.current = {
                x: property === 'plannedDate' ? left : property === 'dueDate' ? right : clientX,
                width: store.getState().view.zoom.columnWidth,
            };
            element.onpointermove = handlePointerMove(property);
            element.onpointerup = handlePointerUp(property);
            element.setPointerCapture(pointerId);
        };

    const handleMouseEnter = () => {
        store.setState((state) => {
            state.view.hoveredTask = task;
        });
    };

    const handleMouseLeave = () => {
        store.setState((state) => {
            state.view.hoveredTask = null;
        });
    };

    const handleRef = useCallback((element: HTMLDivElement) => {
        ref.current = element;
        if (element == null) {
            removeTaskDependencies(store, task);
        } else {
            addTaskDependencies(store, task, element);
        }
    }, []);

    useEvent(toggleSubTasksEvent(task.id), handleToggleSubTasks, store.getState().eventBus);

    useInvalidationEvent(
        () => addTaskDependencies(store, task, ref.current!),
        taskQueryTag.updated(task.id, 'blockedTaskIds')
    );

    useEffect(() => {
        if (store.getState().view.hoveredTask != null) {
            store.setState((state) => {
                state.view.hoveredTask = { ...state.view.hoveredTask, plannedDate, dueDate };
            });
        }
    }, [plannedDate, dueDate]);

    return (
        <>
            {start == null ? (
                <div {...hoverProps} />
            ) : task.type === 'task' ? (
                <div
                    className={clsx('py-1 flex items-center group/row', selectedTaskId === task.id && '!bg-blue-200')}
                    style={{ minWidth: `calc((${start} - var(--start-day) + ${duration}) * var(--column-width))` }}
                    {...hoverProps}
                >
                    {(task.plannedDate != null || task.dueDate != null) && (
                        <>
                            <div className="z-20 sticky left-[calc(var(--list-view-width)+0.5rem)] h-full flex items-center">
                                <FontAwesomeIcon
                                    icon={faCircleChevronLeft}
                                    className="bg-white rounded-full cursor-pointer transition-opacity h-4 self-center absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-left]/row:pointer-events-auto group-[.is-overflowing-left]/row:opacity-100"
                                    onClick={handleOverflowIconClick}
                                />
                            </div>
                            <div
                                ref={handleRef}
                                data-task-id={task.id}
                                className="h-4 relative flex group peer transition-transform-width z-10"
                                style={{
                                    '--color-500': colors[getColorId(section.color)]['500'],
                                    transform: `translateX(calc((${start} - var(--start-day)) * var(--column-width)))`,
                                    width: `calc(${duration} * var(--column-width))`,
                                }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {!task.completed && (
                                    <>
                                        <TimelineTaskConnector task={task} section={section} direction="to" />
                                        <TimelineTaskHandle
                                            position="start"
                                            color={section.color}
                                            onPointerDown={handlePointerDown('plannedDate')}
                                        />
                                        <TimelineTaskConnector task={task} section={section} direction="from" />
                                        <TimelineTaskHandle
                                            position="end"
                                            color={section.color}
                                            onPointerDown={handlePointerDown('dueDate')}
                                        />
                                    </>
                                )}
                                <button
                                    className={clsx(
                                        'transition-background-color rounded-sm flex-1 group-[.connected]:border-2 group-[.connected]:ring-2 group-[.can-connect]:ring-[var(--color-500)] group-[.cannot-connect]:ring-red-500 group-[.cannot-connect_button]:cursor-not-allowed',
                                        task.completed
                                            ? `bg-${getColorId(section.color)}-100`
                                            : `bg-${getColorId(section.color)}-300 group-[:not(.connected)]:cursor-grab group-[:hover:not(.connected)]:rounded-none`
                                    )}
                                    onPointerDown={handlePointerDown()}
                                    onClick={handleClick}
                                />
                            </div>
                            <div className="flex-1" />
                            <div className="z-20 sticky right-2 h-full flex items-center">
                                <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                    className="bg-white rounded-full cursor-pointer transition-opacity h-4 -ml-4 absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-right]/row:pointer-events-auto group-[.is-overflowing-right]/row:opacity-100"
                                    onClick={handleOverflowIconClick}
                                />
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div
                        className={clsx('flex items-center group/row', selectedTaskId === task.id && '!bg-blue-200')}
                        {...hoverProps}
                    >
                        <div className="z-20 sticky left-[calc(var(--list-view-width)+0.5rem)] h-full flex items-center">
                            <FontAwesomeIcon
                                icon={faCircleChevronLeft}
                                className="bg-white rounded-full cursor-pointer transition-opacity h-4 self-center absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-left]/row:pointer-events-auto group-[.is-overflowing-left]/row:opacity-100"
                                onClick={handleOverflowIconClick}
                            />
                        </div>
                        <div
                            ref={handleRef}
                            data-task-id={task.id}
                            className="transition-transform group relative flex"
                            style={{
                                transform: `translateX(calc((${start + 1} - var(--start-day)) * var(--column-width) - 0.375rem - 1px))`,
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <TimelineTaskConnector task={task} section={section} direction="to" />
                            <FontAwesomeIcon
                                icon={faDiamond}
                                className="cursor-grab"
                                style={{ color: section.color }}
                                onClick={handleClick}
                                onPointerDown={handlePointerDown()}
                            />
                            <TimelineTaskConnector task={task} section={section} direction="from" />
                        </div>
                        <div className="flex-1" />
                        <div className="z-20 sticky right-2 h-full flex items-center">
                            <FontAwesomeIcon
                                icon={faCircleChevronRight}
                                className="bg-white rounded-full cursor-pointer transition-opacity h-4 -ml-4 absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-right]/row:pointer-events-auto group-[.is-overflowing-right]/row:opacity-100"
                                onClick={handleOverflowIconClick}
                            />
                        </div>
                    </div>
                    <div
                        className="absolute bottom-0 top-0 transition-transform border-l border-dashed pointer-events-none z-30"
                        style={{
                            transform: `translateX(calc((${start + 1} - var(--start-day)) * var(--column-width)))`,
                            borderColor: section.color,
                        }}
                    />
                </>
            )}
            {isOpen &&
                subTasks?.map((subTask) => (
                    <TimelineTask key={subTask.id} task={subTask} section={section} timelineRef={timelineRef} />
                ))}
        </>
    );
};
