import React, { useContext, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { customFetch, withAuth, withFormDataBody, withMethod, withUrl } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { once } from '@wedo/utils/promise';
import { useSessionUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { SignButtonProp } from 'Pages/SignaturesPage/SignatureModalSignActions';
import { UserSignatureCanvas } from 'Pages/settings/signature/UserSignatureCanvas';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { trpc } from 'Shared/trpc';
import { defaultValuesToSignatureSettings } from 'Shared/utils/signature';

export const SignSyncButton = ({ onDone, className = '' }: SignButtonProp) => {
    const { show: showNotification } = useNotification();
    const currentUser = useSessionUser();
    const { data: signatureSettings } = trpc.user.getSettingsByKey.useQuery('signature');
    const { signatureLock, signatureRequest } = useContext(SignatureModalContext);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const mySignature = signatureRequest.signatures.find(({ userId }) => userId === Number(currentUser.id));
    const [isSignatureLoading, setIsSignatureLoading] = useState(false);
    const { confirm } = useConfirm();
    const { wrap, isLoading: isLoadingDocument } = useLoader();

    const canSign =
        signatureLock?.isLockValid && signatureLock?.userId === Number(currentUser.id) && !isSignatureLoading;

    const sign = async () => {
        setIsSignatureLoading(true);

        const hasConfirmed = await confirm({
            type: 'success',
            title: t`Confirm signature`,
            content: t`Sign this document`,
        });

        if (!hasConfirmed) {
            setIsSignatureLoading(false);
            onDone(null);
            return;
        }

        try {
            const image = await new Promise<Blob>((resolve) => canvasRef.current.toBlob((result) => resolve(result)));

            const formData = new FormData();
            formData.append('attachmentVersionId', signatureRequest.attachment.attachmentVersion.id.toString());
            formData.append('signatureImage', new File([image], `${signatureRequest.id}.png`, { type: 'image/png' }));

            const rawResult = await customFetch(
                withAuth,
                withFormDataBody(formData),
                withMethod('POST'),
                withUrl('/rpc/signature.sign')
            );

            onDone((await rawResult.json()).result.data);
        } catch (e) {
            onDone(null);
            showNotification(UnexpectedErrorNotification);
        } finally {
            setIsSignatureLoading(false);
        }
    };

    useEffect(() => {
        wrap(async () => {
            const { instance } = await getSharedPdfViewerInstance();
            if (instance.Core.documentViewer.getDocument() == null) {
                await once(instance.Core.documentViewer, 'documentLoaded');
            }
        });
    }, []);

    if (mySignature == null) {
        return null;
    }

    return (
        <>
            <Button
                className={className}
                color="success"
                onClick={sign}
                disabled={!canSign || isLoadingDocument}
                loading={isSignatureLoading}
            >
                <Trans>Sign</Trans>
            </Button>
            <div className="hidden">
                <UserSignatureCanvas
                    ref={canvasRef}
                    settings={defaultValuesToSignatureSettings(signatureSettings, currentUser)}
                    type={signatureRequest?.type}
                />
            </div>
        </>
    );
};
