import { HttpMethod, Id } from '@wedo/types';
import { Comment } from 'Shared/types/comment';
import { baseApi, configureTag, listId } from './base';

const { tagType, tag } = configureTag('Comment');

type deleteCommentResponse = {
    message: string;
};

export const commentApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addComment: build.mutation<Comment, { objectType: string; objectId: string; body: FormData }>({
                query: ({ objectType, objectId, body }) => ({
                    url: `comments/${objectType}/${objectId}`,
                    body,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: () => [tag(listId)],
            }),
            updateComment: build.mutation<Comment, { commentId: Id; body: FormData }>({
                query: ({ commentId, body }) => ({
                    url: `comments/${commentId}`,
                    body,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { commentId }) => [tag(commentId)],
            }),
            deleteComment: build.mutation<deleteCommentResponse, { commentId: Id }>({
                query: ({ commentId }) => ({
                    url: `comments/${commentId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { commentId }) => [tag(commentId)],
            }),
        }),
    });

export const { useAddCommentMutation, useUpdateCommentMutation, useDeleteCommentMutation } = commentApi;

export const invalidateComments = (): void => {
    commentApi.util.invalidateTags([tag(listId)]);
};
