import { useContext, useState } from 'react';
import { storage } from '@wedo/utils';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useGlobalLogin, useLogin } from 'Pages/SignInPage/hooks/useLogin';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useGlobalLoginMutation, useLoginMutation } from 'Shared/services/auth';
import { ApiError } from 'Shared/types/apiError';
import { LocalStorage } from 'Shared/types/localStorage';

type PasswordAuthentication = {
    isLoading: boolean;
    isTotpRequired: boolean;
    authenticate: () => void;
    error: string;
};

export const usePasswordAuthentication = (): PasswordAuthentication => {
    const [localLogin, { isLoading: isLocalLoading, error: localError }] = useLoginMutation();
    const [globalLogin, { isLoading: isGlobalLoading, error: globalError }] = useGlobalLoginMutation();
    const {
        loginState: { email, password, rememberMe, trustedDevice },
        updateLoginState,
    } = useContext(LoginContext);
    const { isGlobal } = useCurrentNetwork();
    const [isTotpRequired, setIsTotpRequired] = useState(false);
    const loginSuccess = useLogin();
    const handleGlobalLogin = useGlobalLogin();

    const isLoading = isLocalLoading || isGlobalLoading;
    const error = localError || globalError;

    const authenticate: PasswordAuthentication['authenticate'] = async () => {
        const login = isGlobal ? globalLogin : localLogin;
        const result = await login({
            email,
            password,
            rememberMe,
            trustedDevice,
            old_auth_token: storage.getItem(LocalStorage.DeviceToken),
        });

        if ('error' in result) {
            return;
        }

        if ('data' in result) {
            updateLoginState({
                authToken: result.data.authToken,
                fullName: result.data.user.full_name,
                email,
                password,
                userId: result.data.user.id,
                user: result.data.user,
            });

            if (result.data.two_factor_authentication) {
                setIsTotpRequired(true);
                return;
            }

            if (trustedDevice) {
                storage.setItem(LocalStorage.DeviceToken, result.data.authToken);
            } else {
                storage.removeItem(LocalStorage.DeviceToken);
            }

            if (isGlobal) {
                await handleGlobalLogin(result.data);
            } else {
                await loginSuccess();
            }
        }
    };

    return { error: error instanceof ApiError ? error.message : null, isTotpRequired, authenticate, isLoading };
};
