import { Text as PdfText, View } from '@react-pdf/renderer';
import { faHourglass, faPen, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faDiamond } from '@fortawesome/pro-solid-svg-icons';
import { colors } from '../colors';
import { Icon } from '../icon/Icon';
import type { Task as TaskType } from '../types';
import { BorderRadius, Color, Spacing } from '../types';

export const TaskDescription = ({
    task,
    spacing,
    color,
    fontSize,
}: {
    task: TaskType;
    spacing: Spacing;
    color: Color;
    fontSize: Record<string | number | symbol, number>;
}) => {
    return (
        <View style={{ flexDirection: 'row', gap: spacing.normal }} wrap={false}>
            <Icon
                icon={faPen}
                style={{
                    color: color.secondaryText,
                    width: fontSize.normal,
                    height: fontSize.normal,
                    marginTop: spacing.px,
                }}
            />
            <PdfText style={{ flex: 1, color: colors.black }}>{task.description}</PdfText>
        </View>
    );
};

export const TaskStatusIcon = ({
    task,
    spacing,
    borderRadius,
    color,
}: {
    task: TaskType;
    spacing: Spacing;
    borderRadius: BorderRadius;
    color: Color;
}) => {
    return (
        <View style={{ height: 20, alignItems: 'center', flexDirection: 'row' }}>
            <View
                style={{
                    borderRadius: borderRadius.normal,
                    padding: spacing.px,
                    minWidth: 10,
                    width: 10,
                    height: 10,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(task.deleted
                        ? { backgroundColor: colors.red['500'] }
                        : task.completed
                          ? { backgroundColor: colors.green['400'] }
                          : { border: `1px solid ${color.borderColor}` }),
                }}
            >
                {task.deleted ? (
                    <Icon icon={faTrashCan} style={{ color: colors.white, width: '80%', height: '80%' }} />
                ) : task.completed ? (
                    <Icon icon={faCheck} style={{ color: colors.white, width: '95%', height: '95%' }} />
                ) : task.type === 'milestone' ? (
                    <Icon icon={faDiamond} style={{ color: color.borderColor, width: '95%', height: '95%' }} />
                ) : task.is_blocked ? (
                    <Icon icon={faHourglass} style={{ color: color.borderColor, width: '95%', height: '95%' }} />
                ) : (
                    <></>
                )}
            </View>
        </View>
    );
};
