import { FC, PropsWithChildren } from 'react';
import { Tag, TagColor, TagProps } from '@wedo/design-system';
import { MeetingExtendedStatus, MeetingStatus, MeetingStatusExtension } from 'Shared/types/meeting';
import { meetingStatusToText, meetingStatusToTextLight } from 'Shared/utils/meeting';

type MeetingStatusTagProps = {
    meetingStatus: MeetingExtendedStatus;
    size?: TagProps['size'];
    onClick?: () => void;
    mode?: 'light' | 'complete';
};

export const MeetingStatusTag: FC<MeetingStatusTagProps & PropsWithChildren> = ({
    meetingStatus,
    size = 'sm',
    onClick,
    children,
    mode = 'light',
}) => {
    let color: TagColor;

    const statusObject =
        mode === 'light' ? meetingStatusToTextLight[meetingStatus] : meetingStatusToText[meetingStatus];

    switch (meetingStatus) {
        case MeetingStatusExtension.SIGNED:
        case MeetingStatus.LOCKED:
            color = 'green';
            break;
        case MeetingStatusExtension.WAITING_SIGNATURE:
            color = 'blue';
            break;
        case MeetingStatusExtension.UNLOCKED:
            color = 'red';
            break;
        case MeetingStatusExtension.RELOCKED:
            color = 'yellow';
            break;
        default:
            color = 'gray';
    }
    return (
        <Tag
            className="!flex gap-1 !min-w-0 truncate w-fit"
            onClick={onClick}
            size={size}
            icon={statusObject?.icon}
            color={color}
        >
            <div className="truncate min-w-0">{statusObject?.text}</div>
            {children}
        </Tag>
    );
};
