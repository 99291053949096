import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { FC } from 'react';
import { faCaretRight, faEllipsisV, faPen, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Dropdown, Tooltip, useConfirm, useModal, FormatDate, FormatDateDistance } from '@wedo/design-system';
import { formatRange } from '@wedo/utils';
import { UpdateWorkspaceStatusModal } from 'Pages/workspace/info/UpdateWorkspaceStatusModal';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { WorkspaceStatusTitle } from 'Shared/components/workspace/WorkspaceStatusLabel';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { useDeleteWorkspaceStatusMutation } from 'Shared/services/workspaceStatus';
import { WorkspaceStatus } from 'Shared/types/workspaceStatus';

export const WorkspaceStatusItemDropdown = ({ status, onDone }: { status: WorkspaceStatus; onDone: () => void }) => {
    const [deleteWorkspaceStatus] = useDeleteWorkspaceStatusMutation();
    const { open } = useModal();
    const { confirm } = useConfirm();

    const handleDelete = async (): Promise<void> => {
        await confirm({
            title: t`Are you sure you want to delete this workspace status?`,
            confirmText: t`Delete status`,
            onConfirm: async () => {
                await deleteWorkspaceStatus({ status });
                onDone();
            },
            type: 'danger',
        });
    };

    const handleAdd = () => {
        open(UpdateWorkspaceStatusModal, {
            onDone: onDone,
            workspaceId: status.tag_id,
            status: status && {
                status: status.status,
                start_date: status.start_date,
                due_date: status.due_date,
            },
        });
    };

    const handleEdit = () => {
        open(UpdateWorkspaceStatusModal, {
            onDone: onDone,
            status: status,
            workspaceId: status.tag_id,
        });
    };

    return (
        <Dropdown icon={faEllipsisV}>
            <Dropdown.Item icon={faPlus} onClick={handleAdd}>
                <Trans>Create new status update</Trans>
            </Dropdown.Item>
            <Dropdown.DividerItem />
            <Dropdown.Item icon={faPen} onClick={handleEdit}>
                <Trans>Edit status update</Trans>
            </Dropdown.Item>
            <Dropdown.Item danger icon={faTrash} onClick={handleDelete}>
                <Trans>Delete status update</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};

type WorkspaceStatusItemProps = {
    status: WorkspaceStatus;
    previousStatus?: WorkspaceStatus;
    isEditable?: boolean;
    onDone?: () => void;
};
export const WorkspaceStatusItem = ({
    status,
    previousStatus,
    isEditable = false,
    onDone,
}: WorkspaceStatusItemProps): JSX.Element => (
    <>
        <div className="flex flex-col">
            <div className="mb-2 flex flex-row justify-between border-b border-gray-300 pb-2">
                <div className="flex items-center gap-2">
                    <div>
                        <UserAvatar user={status.updated_by} />
                    </div>
                    <div>
                        <div className="line-clamp-2 font-bold">{status.updated_by.full_name}</div>
                        <div className="text-xs">
                            <FormatDateDistance date={new Date(status.updated_at)} />
                        </div>
                    </div>
                </div>
                <div className={'flex items-start gap-2'}>
                    {previousStatus && (
                        <div className={'flex items-center gap-2'}>
                            <WorkspaceStatusTag status={previousStatus.status} />
                            <FontAwesomeIcon icon={faCaretRight} className="mx-1.5 " />
                        </div>
                    )}
                    <div className={'flex items-center gap-2'}>
                        <WorkspaceStatusTag status={status.status} />
                        {isEditable && <WorkspaceStatusItemDropdown status={status} onDone={onDone} />}
                    </div>
                </div>
            </div>
            <div className="mt-4 flex flex-col justify-between sm:flex-row">
                <div className="flex flex-col">
                    <WorkspaceStatusTitle status={status.status} title={status.title} />
                    <div className={'text-sm'}>{status.description}</div>
                </div>
                <div className="shrink-0 text-base font-bold lg:text-lg">
                    {status.start_date && (
                        <>
                            <FormatDate date={new Date(status.start_date)} format="mediumDate" />
                            &nbsp;-&nbsp;
                        </>
                    )}
                    {status.due_date && <FormatDate date={new Date(status.due_date)} format="mediumDate" />}
                </div>
            </div>
        </div>
    </>
);

export const WorkspaceStatusHeader: FC<{ status: WorkspaceStatus }> = ({ status }) => {
    return (
        <div className="flex shrink-0 items-center gap-2">
            <div className="hidden md:block">
                <UserAvatar user={status?.updated_by} />
            </div>
            <div>
                <div className="line-clamp-2 font-bold">{status?.updated_by?.full_name}</div>
                <div className="text-xs">
                    <FormatDateDistance date={new Date(status?.updated_at)} />
                </div>
            </div>
        </div>
    );
};

export const WorkspaceStatusHeaderActions: FC<{
    status: WorkspaceStatus;
    onReload: () => void;
    previousStatus?: WorkspaceStatus;
    isEditable?: boolean;
}> = ({ status, onReload, previousStatus, isEditable = false }) => {
    return (
        <div className="flex flex-col !flex-wrap items-center gap-2 md:flex-row">
            <div className={'flex items-start gap-2'}>
                {previousStatus && (
                    <div className="flex !flex-wrap items-center gap-2">
                        <WorkspaceStatusTag status={previousStatus.status} />
                        <FontAwesomeIcon icon={faCaretRight} className="mx-1.5 " />
                    </div>
                )}
                <div className="flex !flex-wrap items-center gap-2">
                    <WorkspaceStatusTag status={status.status} />
                    {isEditable && <WorkspaceStatusItemDropdown status={status} onDone={onReload} />}
                </div>
            </div>
        </div>
    );
};

export const WorkspaceStatusText: FC<{ status: WorkspaceStatus }> = ({ status }) => {
    const { i18n } = useLingui();
    return (
        <>
            <div className="flex justify-between">
                <WorkspaceStatusTitle status={status.status} title={status.title} />
                <Tooltip
                    content={
                        <span>
                            <FormatDate format="PP" date={new Date(status.start_date)} /> -{' '}
                            <FormatDate format="PP" date={new Date(status.due_date)} />
                        </span>
                    }
                >
                    <span className="text-base font-bold lg:text-lg">
                        {formatRange(new Date(status.start_date), new Date(status.due_date), i18n.locale)}
                    </span>
                </Tooltip>
            </div>
            {status.description && <div className="mt-2 text-sm">{status.description}</div>}
        </>
    );
};
