import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton } from '@wedo/design-system';

export const TeamLine: FC<{ text: string }> = ({ text }) => (
    <div className="my-2 flex items-center px-4 uppercase text-gray-200">
        <div className="w-56 truncate">
            {text !== null && <span className="font-bold">{text || '\u00A0'}</span>}
            {text === null && <Skeleton className="mb-1 h-3 w-full" />}
        </div>
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-gray-200" />
    </div>
);
