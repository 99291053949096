import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, useModal } from '@wedo/design-system';
import { isOSX } from '@wedo/utils';
import { CommandPaletteModal } from 'Pages/AppPage/CommandPaletteModal';

export const SearchButton: FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
    const { open } = useModal();

    return (
        <Button
            variant="ghost"
            onClick={() => open(CommandPaletteModal)}
            className="flex min-w-[40px] items-center justify-between overflow-hidden whitespace-nowrap rounded-md bg-gray-200 bg-opacity-10 py-1 pl-2 pr-1 text-sm text-gray-400 hover:bg-opacity-20 focus:outline-0"
            title={isCollapsed ? t`Search` : null}
        >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="ml-1.5 h-3 w-3 shrink-0" aria-hidden="true" />
            <div
                className={clsx(
                    'max-w-full flex-1 shrink truncate text-left transition-[margin,opacity,visibility,max-width]',
                    isCollapsed ? 'invisible max-w-0 opacity-0' : 'visible ml-2 max-w-full opacity-100'
                )}
            >
                <Trans>Search</Trans>
            </div>
            <div
                className={clsx(
                    'shrink truncate rounded-md bg-gray-700 px-1 text-xs text-gray-400 transition-[opacity,visibility,max-width]',
                    isCollapsed ? 'invisible max-w-0 opacity-0' : 'visible max-w-full opacity-100'
                )}
            >
                {isOSX() ? '⌘ K' : 'Ctrl K'}
            </div>
        </Button>
    );
};
