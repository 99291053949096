type DateFormatFn = (dateStr: string, languageCode: string, showTime?: boolean) => string;

export const dateFormat: DateFormatFn = (dateStr, languageCode, showTime = true) => {
    const date = new Date(dateStr);

    let options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    if (showTime) {
        options = {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
    }

    return new Intl.DateTimeFormat(languageCode || 'fr-ch', options).format(date);
};
