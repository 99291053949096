import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    FloatingPortal,
    offset,
    Placement,
    safePolygon,
    shift,
    useClick,
    useFloating,
    useHover,
    useInteractions,
    useMergeRefs,
    useRole,
} from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import { cloneElement, FC, Fragment, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { isForwardRef } from 'react-is';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

type TooltipColor = 'dark' | 'light';

const popperClasses: Record<TooltipColor, string> = {
    dark: 'whitespace-pre-wrap inline-block z-40 py-2 px-3 text-sm font-medium text-white rounded-lg shadow-sm bg-gray-800',
    light: 'whitespace-pre-wrap inline-block z-40 py-2 px-3 text-sm font-medium text-black rounded-lg shadow-sm bg-gray-200',
};

const arrowClasses: Record<TooltipColor, string> = {
    dark: 'fill-gray-800',
    light: 'fill-gray-200',
};

export type TooltipProps = {
    content?: ReactNode;
    children: JSX.Element;
    anchorRef?: MutableRefObject<Element>;
    placement?: Placement;
    delay?: number; // milliseconds
    className?: string;
    color?: TooltipColor;
    wrapperClassName?: string;
    isClickEnabled?: boolean;
    showTooltipOnHover?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({
    children,
    content,
    anchorRef,
    placement = 'top',
    delay = 0,
    className,
    wrapperClassName,
    color = 'dark',
    isClickEnabled,
    showTooltipOnHover = false,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { x, y, refs, strategy, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const role = useRole(context, { role: 'tooltip' });
    const hover = useHover(context, {
        enabled: !isClickEnabled,
        move: false,
        delay: { open: delay, close: 0 },
        handleClose: showTooltipOnHover ? safePolygon({ requireIntent: false }) : undefined,
    });
    const click = useClick(context, { enabled: isClickEnabled });

    const { getReferenceProps, getFloatingProps } = useInteractions([role, hover, click]);

    const childHasReference = typeof children.type === 'string' || isForwardRef(children);

    const referenceRef = useMergeRefs([refs.setReference, anchorRef]);

    const modifiedChildren = cloneElement(children, {
        ...(childHasReference && {
            ref: referenceRef,
            ...getReferenceProps,
        }),
        ...props,
        ...children.props,
    });

    useEffect(() => {
        if (isClickEnabled === false) {
            setIsOpen(false);
        }
    }, [isClickEnabled]);

    if (isEmpty(content)) {
        return modifiedChildren;
    }

    const floatingStyle = {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
    };

    const popper = isOpen && (
        <FloatingPortal>
            <Transition
                show={isOpen}
                as={Fragment}
                enter="transition-opacity ease-out duration-100"
                enterFrom="transform opacity-0 "
                enterTo="transform opacity-100 "
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 "
                leaveTo="transform opacity-0 "
            >
                <div
                    ref={refs.setFloating}
                    {...getFloatingProps}
                    style={floatingStyle}
                    role="tooltip"
                    className={clsx(popperClasses[color], className)}
                >
                    {content}
                    <FloatingArrow className={arrowClasses[color]} context={context} ref={arrowRef} />
                </div>
            </Transition>
        </FloatingPortal>
    );

    if (childHasReference) {
        return (
            <>
                {modifiedChildren} {popper}
            </>
        );
    }

    return (
        <>
            <span className={wrapperClassName} ref={referenceRef} {...getReferenceProps}>
                {modifiedChildren}
            </span>
            {popper}
        </>
    );
};
