import { HttpMethod, Id } from '@wedo/types';
import { AddCustomFieldPayload } from 'Pages/settings/customFields/types/addCustomFieldPayload';
import { CreateNewGroupPayload } from 'Pages/settings/customFields/types/createNewGroupPayload';
import { UpdateGroupPayload } from 'Pages/settings/customFields/types/updateGroupPayload';
import { baseApi, configureTag } from 'Shared/services/base';
import { CacheTag } from 'Shared/services/cacheTag';
import { currentNetworkWorkspacesTag, workspaceTag } from 'Shared/services/workspace';
import { CustomFieldGroup, CustomField } from 'Shared/types/customField';

export const { tagType: customFieldTagType, tag: customFieldTag } = configureTag(CacheTag.CUSTOM_FIELDS);

export const currentNetworkCustomFields = customFieldTag('custom-fields');
export const currentNetworkCustomFieldGroups = customFieldTag('custom-field-groups');

type CustomFieldParams = { isGlobal?: boolean; archived?: boolean };

export type CustomFieldRelation = { tag_id?: Id; checklist_id?: Id };

export const customFieldsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [customFieldTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getCustomFields: build.query<Array<CustomField>, CustomFieldParams | void>({
                query: (params) => ({
                    url: 'custom-fields',
                    params: {
                        archived: (params as CustomFieldParams)?.archived,
                        is_global: (params as CustomFieldParams)?.isGlobal,
                    },
                }),
                providesTags: [currentNetworkCustomFields],
            }),

            getCustomFieldGroups: build.query<Array<CustomFieldGroup>, CustomFieldParams | void>({
                query: (params) => ({
                    url: 'custom-field-groups',
                    params: {
                        archived: (params as CustomFieldParams)?.archived,
                        is_global: (params as CustomFieldParams)?.isGlobal,
                    },
                }),
                providesTags: [currentNetworkCustomFieldGroups],
            }),

            deleteCustomField: build.mutation<void, string>({
                query: (customFieldId) => ({
                    url: `custom-fields/${customFieldId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentNetworkCustomFields],
            }),

            addCustomField: build.mutation<CustomField, AddCustomFieldPayload>({
                query: (customField) => ({
                    url: 'custom-fields',
                    method: HttpMethod.Post,
                    body: customField,
                }),
                invalidatesTags: [currentNetworkCustomFields, currentNetworkCustomFieldGroups],
            }),

            updateCustomField: build.mutation<void, CustomField>({
                query: (customField) => ({
                    url: `custom-fields/${customField.id}`,
                    method: HttpMethod.Put,
                    body: customField,
                }),
                invalidatesTags: [currentNetworkCustomFields, currentNetworkCustomFieldGroups],
            }),

            archiveCustomField: build.mutation<void, string>({
                query: (customFieldId) => ({
                    url: `custom-fields/${customFieldId}`,
                    method: HttpMethod.Put,
                    body: { archived: true },
                }),
                invalidatesTags: [currentNetworkCustomFields],
            }),

            unArchiveCustomField: build.mutation<void, string>({
                query: (customFieldId) => ({
                    url: `custom-fields/${customFieldId}`,
                    method: HttpMethod.Put,
                    body: { archived: false },
                }),
                invalidatesTags: [currentNetworkCustomFields],
            }),

            addCustomFieldGroup: build.mutation<CustomFieldGroup, CreateNewGroupPayload>({
                query: (payload) => ({
                    url: 'custom-field-groups',
                    method: HttpMethod.Post,
                    body: payload,
                }),
                invalidatesTags: [currentNetworkCustomFieldGroups],
            }),

            deleteCustomFieldGroup: build.mutation<void, string>({
                query: (groupId) => ({
                    url: `custom-field-groups/${groupId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: [currentNetworkCustomFieldGroups],
            }),

            updateCustomFieldGroup: build.mutation<CustomFieldGroup, { groupId: string; payload: UpdateGroupPayload }>({
                query: ({ groupId, payload }) => ({
                    url: `custom-field-groups/${groupId}`,
                    method: HttpMethod.Put,
                    body: payload,
                }),
                invalidatesTags: [currentNetworkCustomFieldGroups],
            }),

            archiveCustomFieldGroup: build.mutation<CustomFieldGroup, string>({
                query: (groupId) => ({
                    url: `custom-field-groups/${groupId}`,
                    method: HttpMethod.Put,
                    body: { archived: true },
                }),
                invalidatesTags: [currentNetworkCustomFieldGroups],
            }),

            unArchiveCustomFieldGroup: build.mutation<CustomFieldGroup, string>({
                query: (groupId) => ({
                    url: `custom-field-groups/${groupId}`,
                    method: HttpMethod.Put,
                    body: { archived: false },
                }),
                invalidatesTags: [currentNetworkCustomFieldGroups],
            }),

            updateCustomFieldOrder: build.mutation<void, { customFieldId: string; order: number }>({
                query: ({ customFieldId, order }) => ({
                    url: `custom-fields/${customFieldId}`,
                    method: HttpMethod.Put,
                    body: { order },
                }),
                invalidatesTags: [currentNetworkCustomFields, currentNetworkCustomFieldGroups],
            }),

            updateCustomFieldGroupOrder: build.mutation<void, { groupId: string; order: number }>({
                query: ({ groupId, order }) => ({
                    url: `custom-field-groups/${groupId}`,
                    method: HttpMethod.Put,
                    body: { order },
                }),
                invalidatesTags: [currentNetworkCustomFields, currentNetworkCustomFieldGroups],
            }),

            activateCustomField: build.mutation<
                CustomFieldRelation,
                { customFieldId: Id; relation: CustomFieldRelation }
            >({
                query: ({ customFieldId, relation }) => ({
                    url: `custom-fields/${customFieldId}/relations`,
                    method: HttpMethod.Post,
                    body: relation,
                }),
                invalidatesTags: (result, error, { relation }) =>
                    relation?.tag_id
                        ? [workspaceTag(result?.tag_id), currentNetworkWorkspacesTag, currentNetworkCustomFields]
                        : [currentNetworkCustomFields],
            }),

            deActivateCustomField: build.mutation<void, { customFieldId: Id; relation: CustomFieldRelation }>({
                query: ({ customFieldId, relation }) => ({
                    url: `custom-fields/${customFieldId}/relations/delete`,
                    method: HttpMethod.Post,
                    body: relation,
                }),
                invalidatesTags: (result, error, { relation }) =>
                    relation?.tag_id
                        ? [workspaceTag(result?.id), currentNetworkWorkspacesTag, currentNetworkCustomFields]
                        : [currentNetworkCustomFields],
            }),

            activateCustomFieldGroup: build.mutation<
                CustomFieldRelation,
                { customFieldGroupId: Id; relation: CustomFieldRelation }
            >({
                query: ({ customFieldGroupId, relation }) => ({
                    url: `custom-field-groups/${customFieldGroupId}/relations`,
                    method: HttpMethod.Post,
                    body: relation,
                }),
                invalidatesTags: (result, error, { relation }) =>
                    relation?.tag_id
                        ? [workspaceTag(result?.tag_id), currentNetworkWorkspacesTag, currentNetworkCustomFieldGroups]
                        : [currentNetworkCustomFieldGroups],
            }),

            deActivateCustomFieldGroup: build.mutation<void, { customFieldGroupId: Id; relation: CustomFieldRelation }>(
                {
                    query: ({ customFieldGroupId, relation }) => ({
                        url: `custom-field-groups/${customFieldGroupId}/relations/delete`,
                        method: HttpMethod.Post,
                        body: relation,
                    }),
                    invalidatesTags: (result, error, { relation }) =>
                        relation?.tag_id
                            ? [workspaceTag(result?.id), currentNetworkWorkspacesTag, currentNetworkCustomFieldGroups]
                            : [currentNetworkCustomFieldGroups],
                }
            ),
        }),
    });

export const {
    useGetCustomFieldsQuery,
    useDeleteCustomFieldMutation,
    useAddCustomFieldMutation,
    useUpdateCustomFieldMutation,
    useArchiveCustomFieldMutation,
    useUnArchiveCustomFieldMutation,
    useUpdateCustomFieldOrderMutation,
    useAddCustomFieldGroupMutation,
    useDeleteCustomFieldGroupMutation,
    useGetCustomFieldGroupsQuery,
    useUpdateCustomFieldGroupMutation,
    useArchiveCustomFieldGroupMutation,
    useUnArchiveCustomFieldGroupMutation,
    useUpdateCustomFieldGroupOrderMutation,
    useActivateCustomFieldMutation,
    useDeActivateCustomFieldMutation,
    useActivateCustomFieldGroupMutation,
    useDeActivateCustomFieldGroupMutation,
} = customFieldsApi;
