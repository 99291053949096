import React, { FC, useEffect, useRef } from 'react';
import { useLocation, useParams, useSearchParams as useUrlSearchParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { CollapsiblePane, CollapsiblePaneHandle } from '@wedo/design-system';
import { array, EmptyString, enumeration, string } from '@wedo/utils';
import { useLocalStorageSearchParams, useMatch, useSearchParams } from '@wedo/utils/hooks';
import { invalidateCachedTasks, TasksContextProvider, useTasksContext } from 'App/contexts/TasksContext';
import { MissingOrUnauthorizedErrorCard } from 'Pages/AppPage/RoutingError';
import { AddTaskInput } from 'Pages/TasksPage/components/AddTaskInput/AddTaskInput';
import { BulkEditTasksPane } from 'Pages/TasksPage/components/BulkTasksEditPane/BulkEditTasksPane';
import { SelectableArea } from 'Pages/TasksPage/components/SelectableArea/SelectableArea';
import { TasksList } from 'Pages/TasksPage/components/TasksList/TasksList';
import { usePendingTasks } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { AddTaskButton } from 'Pages/TasksPage/components/TasksToolbar/AddTaskButton';
import { TasksToolbar } from 'Pages/TasksPage/components/TasksToolbar/TasksToolbar';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { useWorkspaceCustomFields } from 'Pages/TasksPage/hooks/useWorkspaceCustomFields';
import { Can } from 'Shared/components/Can';
import { PageHeader } from 'Shared/components/PageHeader';
import { TaskDetail } from 'Shared/components/task/TaskDetail/TaskDetail';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetChecklistQuery } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetUserQuery } from 'Shared/services/user';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { CustomFieldType } from 'Shared/types/customField';
import { TaskFilter, TaskLayout, TaskOrder, TaskStatus } from 'Shared/types/task';
import { Permission } from 'Shared/utils/rbac';
import { getWorkspaceTaskParams, isValidWorkspaceId } from 'Shared/utils/workspace';

export type TasksPageParams = {
    workspaceId: string;
    userId: string;
    checklistId: string;
    templateId: string;
};

export const validSortOrdersAsc: TaskOrder[] = [
    'alphanumeric',
    'completed_at',
    'default',
    'deleted_at',
    'due_date',
    'planned_date',
    'priority',
    'rank',
    'section',
    'status',
    'tag',
    'user',
    'none',
];
export const validSortOrdersDesc: TaskOrder[] = validSortOrdersAsc.map((order) => `-${order}` as TaskOrder);
export const validSortOrders: TaskOrder[] = [...validSortOrdersAsc, ...validSortOrdersDesc];
export const validLayouts: TaskLayout[] = ['list', 'kanban'];

export const ValidGroupBySearchParams = new Set(validSortOrders);
export const ValidLayoutSearchParams = new Set(validLayouts);

export const TasksPageSearchParams = {
    view: enumeration('me', 'assigned', 'watched', 'created', 'all', 'unassigned').options({ allowUnknown: true }),
    search: string(),
    scope: enumeration('all-words', 'extended', 'strict'),
    layout: enumeration(...validLayouts).default('list'),
    status: array(enumeration('completed', 'deleted', 'todo', 'linked')),
    order: enumeration(...validSortOrders).options({ allowUnknown: true }),
    grouping: enumeration(...validSortOrders).options({ allowUnknown: true }),
    workspaces: array(string()),
};

type TasksPageProps = {
    defaultView?: TaskFilter;
    defaultStatuses?: TaskStatus[];
    defaultOrder?: TaskOrder;
};

const TasksPageContent: FC<TasksPageProps> = ({ defaultView, defaultStatuses, defaultOrder }) => {
    const collapsiblePaneRef = useRef<CollapsiblePaneHandle>();

    const { pathname } = useLocation();
    const { workspaceId, userId, checklistId, templateId } = useParams<TasksPageParams>();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { invalidatedTasks } = usePendingTasks();
    const { selectedTasks, setSelectedTasks } = useTasksContext();

    const { customFields, isLoading } = useWorkspaceCustomFields(
        workspaceId,
        checklistId,
        ({ type }) => type === CustomFieldType.Dropdown
    );
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !isValidWorkspaceId(workspaceId) });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: templateId == null });
    const { data: user } = useGetUserQuery(userId, { skip: userId == null });
    const { data: checklist } = useGetChecklistQuery(checklistId, { skip: checklistId == null });
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const { isCurrentUserModerator } = useManageMembers(template);
    const { grouping: defaultWorkspaceGrouping, layout: defaultWorkspaceLayout } = getWorkspaceTaskParams(workspace);

    const defaultGrouping = defaultOrder ?? defaultWorkspaceGrouping;

    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);
    const {
        view = defaultView,
        search,
        scope,
        layout = defaultWorkspaceLayout,
        status = defaultStatuses,
        order = defaultOrder ?? 'default',
        grouping = defaultGrouping,
        workspaces,
    } = searchParams;
    const [urlSearchParams] = useUrlSearchParams();

    const isSearchPage = pathname.startsWith('/search');
    const statuses = status.length === 0 ? defaultStatuses : status;
    const isWorkspaceTasksPage = useMatch('/workspaces/:workspaceId/tasks');

    const handleTaskPaneClosed = () => setSelectedTasks([]);

    const handleSelectionEnds = (elements: HTMLElement[], clearSelection: () => void) => {
        // If no tasks were selected and no tasks are selected, just do nothing, it can prevent some useless re-render
        // We directly read from the state to get the last updated version of the selectedTasks
        if (elements.length === 0) {
            return;
        }

        const tasks = elements.map((element) => {
            const dataset = element.dataset;
            return {
                id: dataset.id,
                groupedId: dataset.groupedId,
            };
        });

        // If the selection is empty, close the pane
        if (tasks.length === 0) {
            collapsiblePaneRef.current.close();
        } else {
            setSelectedTasks(tasks);
        }

        // As we now have selected the tasks, we can clear the selection from the selectable area
        clearSelection();
    };

    const isValidCustomFieldOrder = (order: string) => {
        if (isLoading) {
            return true;
        }
        const absoluteOrder = order.startsWith('-') ? order.slice(1) : order;
        if (absoluteOrder.startsWith('custom-')) {
            const customFieldId = absoluteOrder.split('-')[1];
            return customFields.some((cf) => cf.id === customFieldId);
        }
        return false;
    };

    useEffect(() => {
        const sanitizedSearchParams = {
            ...(isWorkspaceTasksPage && urlSearchParams.size === 0
                ? { grouping: defaultGrouping, layout: defaultWorkspaceLayout }
                : {}),
            ...(order != null && !validSortOrders.includes(order) && !isValidCustomFieldOrder(order)
                ? { order: defaultOrder }
                : {}),
            ...(grouping != null && !validSortOrders.includes(grouping) && !isValidCustomFieldOrder(grouping)
                ? { grouping: defaultGrouping }
                : {}),
            ...(layout != null && !validLayouts.includes(layout) ? { layout: defaultWorkspaceLayout } : {}),
            ...(statuses != null &&
            !statuses.every((status) => ['completed', 'deleted', 'todo', 'linked'].includes(status))
                ? { status: defaultStatuses }
                : {}),
        };

        if (Object.keys(sanitizedSearchParams).length > 0) {
            setSearchParams((current) => ({ ...current, ...sanitizedSearchParams }), {
                replace: true,
            });
        }
    }, [pathname, customFields, isLoading]);

    usePageTitle(() => {
        if (workspace) {
            return `${workspace?.name} | ${t`Tasks`}`;
        }
        if (user) {
            return `${user?.full_name} | ${t`Tasks`}`;
        }
        if (checklist) {
            return `${checklist?.name} | ${t`Tasks`}`;
        }
        if (isMyTasksPage) {
            return t`My tasks`;
        }
        return EmptyString;
    });

    useLocalStorageSearchParams(`/tasks`, ['order', 'layout', 'grouping', 'status']);
    useLocalStorageSearchParams(`/templates/${templateId}/tasks`, ['order', 'layout', 'grouping', 'status']);
    useLocalStorageSearchParams(`/checklists/${checklistId}/tasks`, ['order', 'layout', 'grouping']);
    useLocalStorageSearchParams(`/users/${userId}/tasks`, ['order', 'layout', 'grouping', 'status']);

    useEffect(() => {
        setSelectedTasks([]);
    }, []);

    useEffect(() => {
        if (view === 'assigned') {
            void registerBadgeActivity('VIEW_ASSIGNED_TASKS_1');
        }
    }, [view]);

    useEffect(() => {
        let timeOut: ReturnType<typeof setTimeout>;
        if (
            selectedTasks.length === 1 &&
            isEmpty(selectedTasks[0].recurrence) &&
            selectedTasks?.map(({ id }) => id).some((id) => invalidatedTasks.has(id))
        ) {
            return () => null;
        }
        if (invalidatedTasks.size > 0) {
            timeOut = setTimeout(() => invalidateCachedTasks(), 1000);
        }
        return () => {
            clearTimeout(timeOut);
        };
    }, [invalidatedTasks, selectedTasks]);

    if (template && !isCurrentUserModerator) {
        return <MissingOrUnauthorizedErrorCard />;
    }

    return (
        <div className="flex h-full max-h-full flex-col overflow-hidden">
            {isMyTasksPage && <PageHeader title={t`My tasks`} />}
            {templateId != null && (
                <div className={'flex items-center justify-center bg-yellow-100 py-1 text-sm text-yellow-800'}>
                    <span>
                        <Trans>
                            You are editing the <b>{template?.name}</b> template's tasks
                        </Trans>
                    </span>
                </div>
            )}
            <div className="relative flex h-full max-h-full overflow-hidden">
                <SelectableArea
                    className={clsx(
                        'flex flex-col relative h-full max-h-full flex-1 gap-2 text-sm leading-5 text-gray-900',
                        layout === 'kanban' && 'overflow-hidden',
                        templateId != null && 'pt-4'
                    )}
                    onSelectionEnds={handleSelectionEnds}
                >
                    <div className="px-6 pb-1">
                        <div className={clsx('flex flex-col gap-3')}>
                            {templateId == null && !isSearchPage && (
                                <TasksToolbar
                                    view={view}
                                    order={order}
                                    grouping={grouping}
                                    statuses={statuses}
                                    isDefaultStatuses={
                                        statuses?.length === defaultStatuses?.length &&
                                        defaultStatuses.every((status) => statuses.includes(status))
                                    }
                                    workspaceId={workspaceId}
                                    checklistId={checklistId}
                                    templateId={templateId}
                                    userId={userId}
                                    search={search}
                                    scope={scope}
                                />
                            )}
                            {templateId && !isSearchPage && (
                                <div className="flex justify-end">
                                    <AddTaskButton />
                                </div>
                            )}
                            {scope == null && (
                                <Can permission={Permission.ManageTasks}>
                                    <AddTaskInput
                                        view={view}
                                        statuses={statuses}
                                        workspaceId={workspaceId}
                                        checklistId={checklistId}
                                        templateId={templateId}
                                        userId={userId}
                                    />
                                </Can>
                            )}
                        </div>
                    </div>
                    <TasksList
                        key={[
                            view,
                            ...statuses,
                            grouping,
                            order,
                            search,
                            workspaceId,
                            userId,
                            checklistId,
                            templateId,
                            scope,
                            workspaces.join('*'),
                        ].join('-')}
                        layout={layout}
                    />
                </SelectableArea>

                {selectedTasks.length > 0 && (
                    <CollapsiblePane
                        id="task-detail"
                        ref={collapsiblePaneRef}
                        isFloating={layout === 'kanban'}
                        onAfterClose={handleTaskPaneClosed}
                        className="task"
                        layout={selectedTasks.length >= 1 ? 'header-content-footer' : null}
                    >
                        {selectedTasks.length > 1 ? (
                            <BulkEditTasksPane
                                view={view}
                                order={order}
                                grouping={grouping}
                                statuses={statuses}
                                search={search}
                                scope={scope}
                                parentRef={collapsiblePaneRef}
                            />
                        ) : (
                            selectedTasks.length === 1 && (
                                <>
                                    <TaskDetail.Header
                                        hideTasksToExport
                                        taskId={selectedTasks[0].id}
                                        workspaceId={workspaceId}
                                        checklistId={checklistId}
                                        userId={userId}
                                    />
                                    <CollapsiblePane.Content>
                                        <TaskDetail
                                            workspaceId={workspaceId}
                                            taskId={selectedTasks[0].id.toString()}
                                            defaultView={defaultView}
                                        />
                                    </CollapsiblePane.Content>
                                    <CollapsiblePane.Footer>
                                        <TaskDetail.Footer taskId={selectedTasks[0].id} />
                                    </CollapsiblePane.Footer>
                                </>
                            )
                        )}
                    </CollapsiblePane>
                )}
            </div>
        </div>
    );
};

export const TasksPage: FC<TasksPageProps> = ({ ...props }) => {
    const { workspaceId, userId, checklistId, templateId } = useParams<TasksPageParams>();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !isValidWorkspaceId(workspaceId) });
    const { grouping: defaultWorkspaceGrouping } = getWorkspaceTaskParams(workspace);

    const defaultGrouping = props.defaultOrder ?? defaultWorkspaceGrouping;

    const [
        {
            view = props.defaultView,
            search,
            scope,
            status = props.defaultStatuses,
            order = props.defaultOrder ?? 'default',
            grouping = defaultGrouping,
            workspaces,
        },
    ] = useSearchParams(TasksPageSearchParams);

    const statuses = status.length === 0 ? props.defaultStatuses : status;

    return (
        <TasksContextProvider
            params={{
                view,
                statuses,
                grouping,
                order,
                search,
                searchType: scope,
                workspaceId,
                userId,
                checklistId,
                templateId,
                workspaces,
            }}
        >
            <TasksPageContent {...props} />
        </TasksContextProvider>
    );
};
