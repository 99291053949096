import { HTMLAttributes, ReactNode } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Tooltip } from '~/components/Tooltip/Tooltip';

const classes = {
    base: 'block text-sm font-medium',
    disabled: 'opacity-50 cursor-not-allowed hover:bg-none cursor-not-allowed',
    inputType: {
        inline: '',
        block: 'mb-1',
    },
};

export interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
    children?: ReactNode;
    value?: string;
    htmlFor?: string;
    disabled?: boolean;
    inputType?: 'inline' | 'block';
    isMandatory?: boolean;
}

export const Label = ({
    children,
    color = 'text-gray-600',
    disabled = false,
    value,
    className,
    inputType = 'block',
    isMandatory = false,
    ...props
}: LabelProps): JSX.Element => {
    return (
        <label
            className={clsx(
                classes.base,
                disabled ? classes.disabled : 'cursor-pointer',
                classes.inputType[inputType],
                color,
                className
            )}
            {...props}
        >
            {isMandatory ? (
                <Tooltip content={t`This field is mandatory`} placement="right">
                    <span>
                        {value ?? children ?? ''}
                        {<span className="text-red-500"> *</span>}
                    </span>
                </Tooltip>
            ) : (
                value ?? children ?? ''
            )}
        </label>
    );
};
