import { autoUpdate, flip, FloatingPortal, shift, size as fSize, useFloating } from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox, Transition } from '@headlessui/react';
import React, {
    FC,
    Fragment,
    PropsWithChildren,
    ReactNode,
    useState,
    KeyboardEvent,
    useEffect,
    useRef,
    useMemo,
} from 'react';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isString } from 'lodash-es';
import { ButtonPosition } from '~/components/Button/Button';
import { Popover } from '~/components/Popover/Popover';
import { Spinner } from '~/components/Spinner/Spinner';
import { Tag } from '~/components/Tag/Tag';
import { TagSelectContext } from '~/components/TagSelect/TagSelectContext';
import { TagSelectOption } from '~/components/TagSelect/TagSelectOption';
import { ValueTag } from '~/components/TagSelect/ValueTag';
import { getTagOptionClasses } from '~/components/TagSelect/utils';
import { Size } from '~/types';
import { Id } from '@wedo/types';
import { EmptyString, onBackSpace, onEnter, onEsc, getIdMapping, EmptyArray, onArrowLeft, isEmpty } from '@wedo/utils';
import { useEvent, useElementSize } from '@wedo/utils/hooks';
import { useClickAway } from '@wedo/utils/hooks/useClickAway';

export type TagSelectValue = { id: Id; name: string; color?: string };

type BaseTagSelectProps = {
    contextValues: TagSelectValue[];
    search?: string;
    onSearch?: (search: string) => void;
    tagRender?: (value: string) => ReactNode;
    placeholder?: string;
    onCreate?: (name: string) => void;
    isLoadingOnCreate?: boolean;
    onColorChange?: (color?: string) => void;
    size?: Size;
    position?: ButtonPosition;
    inputClassName?: string;
    floatingClassName?: string;
    forceSingleLine?: boolean;
};

type SingleTagSelectProps = BaseTagSelectProps & {
    value: Id;
    onChange: (value: Id) => void;
    multiple?: false;
};

type MultipleTagSelectProps = BaseTagSelectProps & {
    value: Id[];
    onChange: (value: Id[]) => void;
    multiple: true;
};

export type TagSelectProps = SingleTagSelectProps | MultipleTagSelectProps;

const TagSelectClasses = {
    size: {
        sm: 'min-h-[1.875rem] text-xs',
        md: 'min-h-[2.125rem] text-sm',
        lg: 'min-h-[2.5rem] text-lg',
    },
    input: {
        size: {
            sm: 'text-xs',
            md: 'text-sm',
            lg: 'text-base',
        },
    },
    position: {
        none: 'rounded-md',
        start: 'rounded-l-md rounded-r-none focus:z-10',
        middle: 'rounded-none -ml-px focus:z-10',
        end: 'rounded-l-none rounded-r-md -ml-px focus:z-10',
    },
};

const TagSelectComponent: FC<PropsWithChildren<TagSelectProps>> = ({
    search,
    onSearch,
    value,
    onChange,
    contextValues = EmptyArray,
    multiple,
    children,
    placeholder = EmptyString,
    onCreate,
    isLoadingOnCreate = false,
    size = 'md',
    position = 'none',
    inputClassName,
    floatingClassName,
    forceSingleLine = false,
}) => {
    const inputRef = useRef<HTMLInputElement>();
    const tagsContainerRef = useRef<HTMLDivElement>();

    const [floatingWidth, setFloatingWidth] = useState<number>(0);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [focusedTagIndex, setFocusedTagIndex] = useState<number>();
    const [minCharsToHideTags, setMinCharsToHideTags] = useState<number>(Number.POSITIVE_INFINITY);

    const idToValue = useMemo(() => getIdMapping(contextValues), [contextValues]);

    const { refs, floatingStyles, update } = useFloating<HTMLInputElement>({
        placement: 'bottom',
        whileElementsMounted: autoUpdate,
        middleware: [
            shift(),
            flip(),
            fSize({
                apply: ({ elements }) => {
                    setFloatingWidth(elements.reference.getBoundingClientRect().width);
                },
            }),
        ],
    });

    const floatingStyle = {
        ...floatingStyles,
        width: floatingWidth,
    };

    const showPlaceholder = (multiple && value.length === 0) || (!multiple && !value);

    const { width: tagsContainerWidth } = useElementSize(tagsContainerRef);
    const { width: inputWidth } = useElementSize(inputRef);

    const hideSelectedTags = useMemo<boolean>(() => {
        if (!forceSingleLine) return false;
        if (isEmpty(search)) return false;
        if (multiple && value?.length === 0) return false;
        if (!isFocused) return false;
        return search?.trim()?.length > minCharsToHideTags;
    }, [forceSingleLine, search, multiple, value, minCharsToHideTags, isFocused]);

    const maxDisplayed = useMemo(() => {
        if (!forceSingleLine || !multiple) return Number.POSITIVE_INFINITY;
        let width = 0;
        for (let index = 0; index < value?.length; index++) {
            const selectedTagId = value[index];
            const selectedTag = idToValue.get(selectedTagId);
            width += selectedTag?.name?.length * 7.5 + 45;
            if (width >= tagsContainerWidth) {
                return index === 0 ? 1 : index;
            }
        }
        return Number.POSITIVE_INFINITY;
    }, [forceSingleLine, multiple, value, idToValue, tagsContainerWidth]);

    const closeFloatingPopover = () => {
        setIsOpen(false);
        setFocusedTagIndex(undefined);
        onSearch(EmptyString);
    };

    const focusOnInput = () => {
        setIsFocused(true);
        requestAnimationFrame(() => inputRef?.current?.focus());
    };

    useEffect(() => {
        if (hideSelectedTags) return;
        if (!multiple || !forceSingleLine) setMinCharsToHideTags(Number.POSITIVE_INFINITY);
        setMinCharsToHideTags(Math.floor(inputWidth / 9));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputWidth]);

    useClickAway(refs.floating, () => {
        if (!isDropdownOpen) {
            closeFloatingPopover();
        }
    });

    useEvent(
        'keydown',
        onEsc(() => {
            if (!isDropdownOpen) {
                closeFloatingPopover();
            }
        })
    );

    const handleSearch = (search: string) => onSearch(search);

    const handleChange = (newValue: Id | Id[] | TagSelectValue | TagSelectValue[]) => {
        // remove from multiple
        if (multiple && Array.isArray(newValue) && newValue.length < value.length) {
            onChange(value.filter((id) => (newValue as Id[]).some((newId) => id === newId)));
            onSearch(EmptyString);
            requestAnimationFrame(update);
            return;
        }

        // add to multiple
        if (multiple && Array.isArray(newValue) && newValue.length > 0 && isString(newValue[newValue.length - 1])) {
            onChange(newValue as Id[]);
            onSearch(EmptyString);
            requestAnimationFrame(update);
            return;
        }

        // add new item to multiple
        if (multiple && Array.isArray(newValue) && newValue.length > 0) {
            onCreate((newValue[newValue.length - 1] as TagSelectValue).name);
            onSearch(EmptyString);
            requestAnimationFrame(update);
            return;
        }

        // setting in case of single select
        if (!multiple && !Array.isArray(newValue) && isString(newValue)) {
            onChange(newValue);
            closeFloatingPopover();
            return;
        }

        // add new item in case of single select
        if (!multiple && !Array.isArray(newValue)) {
            onCreate((newValue as TagSelectValue).name);
            onSearch(EmptyString);
            requestAnimationFrame(update);
            return;
        }

        onSearch(EmptyString);
    };

    const removeLastSelectedValue = () => {
        if (!multiple) return;
        onChange(value.slice(0, value.length - 1));
        requestAnimationFrame(update);
    };

    const handleRemoveTag = (item: TagSelectValue) => {
        if (!multiple) return;
        onChange(value.filter((id) => id !== item.id));
        requestAnimationFrame(() => {
            update();
            if (value.length === 1) {
                focusOnInput();
                setFocusedTagIndex(undefined);
            }
        });
    };

    const handleInputKeyDown = (event: KeyboardEvent) => {
        onBackSpace(() => {
            if (search.length > 0) return;
            if (multiple && value.length > 0) {
                removeLastSelectedValue();
            } else if (!multiple && value) {
                onChange(undefined);
                requestAnimationFrame(update);
            }
        })(event);
        onEnter(() => {
            setIsOpen(true);
        })(event);
        onArrowLeft((event: React.KeyboardEvent<HTMLInputElement>) => {
            event.stopPropagation();
            if (event.shiftKey || hideSelectedTags || event.target.selectionStart > 0) return;
            if (multiple && value?.length > 0) {
                inputRef?.current?.blur();
                if (value?.length > maxDisplayed) {
                    setFocusedTagIndex(maxDisplayed - 1);
                } else {
                    setFocusedTagIndex(value.length - 1);
                }
            } else if (!multiple && value) {
                inputRef?.current?.blur();
                setFocusedTagIndex(0);
            }
        })(event);
    };

    const handleArrowLeftPressInTag = () => {
        setFocusedTagIndex((current) => {
            if (multiple) {
                if (current === 0) return current;
                return current - 1;
            }
            return current;
        });
    };

    const handleArrowRightPressInTagForMultiple = () => {
        setFocusedTagIndex((current) => {
            if (multiple) {
                if (current === value.length - 1 || current === maxDisplayed - 1) {
                    focusOnInput();
                    return undefined;
                }
                return current + 1;
            }
            return current;
        });
    };

    const handleArrowRightPressInTagForSingle = () => {
        setFocusedTagIndex((current) => {
            if (multiple) {
                return current;
            }
            focusOnInput();
            return undefined;
        });
    };

    return (
        <Combobox multiple={multiple} value={value} onChange={handleChange}>
            {({ open }) => {
                useEffect(() => {
                    if (open) {
                        setIsOpen(true);
                    }
                }, [open]);

                return (
                    <div>
                        <div
                            ref={refs.setReference}
                            className={clsx(
                                'flex overflow-hidden border border-gray-300 bg-white hover:cursor-text focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm',
                                isFocused && 'ring-2 ring-blue-500 ring-offset-2',
                                TagSelectClasses.size[size],
                                TagSelectClasses.position[position],
                                inputClassName
                            )}
                            onClick={focusOnInput}
                        >
                            <div
                                ref={tagsContainerRef}
                                className={clsx(
                                    'flex w-full items-center gap-1 overflow-hidden pl-1',
                                    multiple && !forceSingleLine && 'flex-wrap content-center py-1'
                                )}
                            >
                                {multiple &&
                                    !hideSelectedTags &&
                                    value
                                        ?.slice(0, maxDisplayed)
                                        ?.map((selectedItem) => (
                                            <ValueTag
                                                showBorder
                                                size="sm"
                                                key={selectedItem}
                                                value={idToValue.get(selectedItem)}
                                                onRemove={() => handleRemoveTag(idToValue.get(selectedItem))}
                                                isFocused={value[focusedTagIndex] === selectedItem}
                                                onArrowLeftPress={handleArrowLeftPressInTag}
                                                onArrowRightPress={handleArrowRightPressInTagForMultiple}
                                            />
                                        ))}

                                {multiple && value && hideSelectedTags && <Tag icon={faTags}>{value?.length}</Tag>}

                                {multiple && value?.length > maxDisplayed && !hideSelectedTags && (
                                    <Popover
                                        text={<Tag size={'sm'}>+{value?.length - maxDisplayed}</Tag>}
                                        variant="ghost"
                                        shape="circle"
                                    >
                                        <div className="flex flex-wrap gap-2 bg-white p-2">
                                            {multiple &&
                                                value
                                                    ?.slice(maxDisplayed)
                                                    ?.map((selectedItem) => (
                                                        <ValueTag
                                                            size="sm"
                                                            key={selectedItem}
                                                            value={idToValue.get(selectedItem)}
                                                            onRemove={() =>
                                                                handleRemoveTag(idToValue.get(selectedItem))
                                                            }
                                                            isFocused={value[focusedTagIndex] === selectedItem}
                                                            onArrowLeftPress={handleArrowLeftPressInTag}
                                                            onArrowRightPress={handleArrowRightPressInTagForMultiple}
                                                        />
                                                    ))}
                                        </div>
                                    </Popover>
                                )}

                                {!multiple && value && !Array.isArray(value) && (
                                    <div>
                                        <ValueTag
                                            value={idToValue.get(value)}
                                            onRemove={() => onChange(undefined)}
                                            size={size}
                                            isFocused={focusedTagIndex === 0}
                                            onArrowRightPress={handleArrowRightPressInTagForSingle}
                                        />
                                    </div>
                                )}

                                <Combobox.Input
                                    ref={inputRef}
                                    className={clsx(
                                        'mx-0 h-1 flex-1 border-none px-0 text-gray-900 focus:ring-0',
                                        TagSelectClasses.input.size[size],
                                        !isFocused && !showPlaceholder && search.length === 0 && 'hidden'
                                    )}
                                    style={{
                                        width: search?.length > 0 ? `${search?.trim()?.length ?? 0}ch` : 0,
                                    }}
                                    value={search}
                                    placeholder={showPlaceholder ? placeholder : undefined}
                                    onChange={(event) => handleSearch(event.target.value)}
                                    onKeyDown={handleInputKeyDown}
                                    onFocus={() => {
                                        setIsOpen(true);
                                        setIsFocused(true);
                                    }}
                                    onBlur={() => setIsFocused(false)}
                                    onClick={() => setIsOpen(true)}
                                />
                            </div>

                            <Combobox.Button className="flex items-center pr-2">
                                <div className="fa-layers fa-fw pointer-events-none flex items-center text-gray-300">
                                    <FontAwesomeIcon icon={faCaretUp} transform="up-4" aria-hidden="true" />
                                    <FontAwesomeIcon icon={faCaretDown} transform="down-4" aria-hidden="true" />
                                </div>
                            </Combobox.Button>
                        </div>

                        <TagSelectContext.Provider value={{ isDropdownOpen, setIsDropdownOpen }}>
                            <FloatingPortal>
                                <Transition
                                    show={isOpen}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => onSearch(EmptyString)}
                                >
                                    <Combobox.Options
                                        static
                                        className={clsx(
                                            'absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                                            floatingClassName
                                        )}
                                        style={floatingStyle}
                                        ref={refs.setFloating}
                                    >
                                        {children}
                                        {search.length > 0 && onCreate && (
                                            <Combobox.Option
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onCreate(search);
                                                }}
                                                onSelect={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onCreate(search);
                                                }}
                                                value={{ name: search }}
                                                className={({ selected, active }) =>
                                                    getTagOptionClasses({ selected, active })
                                                }
                                            >
                                                <div className="flex items-center gap-1">
                                                    {isLoadingOnCreate && <Spinner className="h-4 w-4" color="blue" />}
                                                    <Trans>
                                                        Create{' '}
                                                        <ValueTag
                                                            size={size}
                                                            value={{ id: 'new-item', name: search }}
                                                        />
                                                    </Trans>
                                                </div>
                                            </Combobox.Option>
                                        )}
                                    </Combobox.Options>
                                </Transition>
                            </FloatingPortal>
                        </TagSelectContext.Provider>
                    </div>
                );
            }}
        </Combobox>
    );
};

export const TagSelect = Object.assign(TagSelectComponent, { Option: TagSelectOption });
