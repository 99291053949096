import { RefObject, useEffect } from 'react';
import { useEventCallback } from './useEventCallback';

const defaultEvents = ['mousedown', 'touchstart'];

export const useClickAway = <E extends Event = Event>(
    ref: RefObject<HTMLElement | null>,
    onClickAway: (event: E) => void,
    events: string[] = defaultEvents
) => {
    const handler = useEventCallback((event) => {
        const { current: element } = ref;
        if (element != null && !element.contains(event.target)) {
            onClickAway(event);
        }
    });

    useEffect(() => {
        for (const eventName of events) {
            document.addEventListener(eventName, handler);
        }

        return () => {
            for (const eventName of events) {
                document.removeEventListener(eventName, handler);
            }
        };
    }, [events]);
};
