import { i18n, MessageDescriptor } from '@lingui/core';

type MatchesFn = (param: { code?: string | null; path?: string | string[] }) => boolean;

export const UnknownError = Symbol('unknown error');

export type TransformParameter = {
    [key: string]: { code?: string | null; path?: string | string[]; message?: string | null | MessageDescriptor };
    [UnknownError]?: { code?: string | null; path?: string | string[]; message?: string | null | MessageDescriptor };
};

type TransformFn = (transformObject: TransformParameter) => ApiError;
type MatchSomeFn = (transformObject: TransformParameter) => boolean;

export class ApiError {
    private readonly _data: unknown;

    constructor(data?: unknown) {
        this._title = '';
        this._message = '';
        this._path = [];
        this._code = '';
        this._data = data ?? {};
    }

    private _path: string[];

    public get path(): string[] {
        return this._path;
    }

    private _title: string;

    public get title(): string {
        return this._title;
    }

    private _message: string;

    public get message(): string {
        return this._message;
    }

    private _code: string;

    public get code(): string {
        return this._code;
    }

    public get data(): unknown {
        return this._data;
    }

    public setCode = (c: string | null): ApiError => {
        if (c === null) {
            return this;
        }

        this._code = c;
        return this;
    };

    public setTitle = (title: string | null): ApiError => {
        if (typeof title !== typeof '') {
            return this;
        }

        this._title = title;
        return this;
    };

    public setMessage = (message: MessageDescriptor | string): ApiError => {
        if (message === null) {
            return this;
        }

        if (typeof message === 'object' && 'id' in message) {
            this._message = i18n._(message);
            return this;
        }

        if (typeof message === 'string') {
            this._message = message;
            return this;
        }

        return this;
    };

    public addPath = (c: string): ApiError => {
        if (typeof c !== typeof '') {
            return this;
        }

        this._path.push(c);
        return this;
    };

    public setPath = (c: string[] | string | null): ApiError => {
        if (c === null) {
            return this;
        }

        this._path = Array.isArray(c) ? structuredClone(c) : [c];
        return this;
    };

    public matches: MatchesFn = ({ code = null, path = [] }) => {
        if (code !== null && this._code !== code) {
            return false;
        }

        if (path === null) {
            return true;
        }

        if (Array.isArray(path)) {
            return path.every((p) => this._path.includes(p));
        }

        return this._path.includes(path);
    };

    public matchesSome: MatchSomeFn = (transformObject) =>
        Object.values(transformObject).some((obj) => this.matches(obj));

    /** Transforms the error object based on the transform object.
     * The key Symbol(UnknownError) will set the code, the path and the message
     * The other keys will look for the corresponding code and path, and set the message
     * A code, path or message of value NULL will not change the original message
     * */
    public transform: TransformFn = (transformObject) => {
        for (const key of Reflect.ownKeys(transformObject)) {
            const { code = null, path = null, message = null } = transformObject[key as keyof typeof transformObject];

            if (key === UnknownError) {
                return this.setCode(code).setPath(path).setMessage(message);
            }

            if (this.matches({ code, path })) {
                return this.setMessage(message);
            }
        }

        return this;
    };
}
