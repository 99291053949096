import { FC, KeyboardEvent, PropsWithChildren, useEffect, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    ContextModalProps,
    getCroppedImgBlob,
    Input,
    Label,
    NoInternetErrorNotification,
    PixelCrop,
    Tabs,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { fileTypeToImageExtension } from '@wedo/utils';
import { LogoSettings } from 'Pages/settings/shared/logo/LogoSettings';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { useDeleteTeamLogoMutation, useGetTeamQuery, useUpdateTeamLogoMutation } from 'Shared/services/team';
import { ApiError } from 'Shared/types/apiError';
import { isFetchError } from 'Shared/utils/rtkQuery';


type SettingsModalProps = {
    teamId: Id;
    teamName: string;
    onNameChange: (name: string) => void;
    onSave: () => void;
} & ContextModalProps &
    PropsWithChildren;

export const GeneralPanel: FC<SettingsModalProps> = ({ teamId, teamName, onNameChange, onSave }) => {
    const { show } = useNotification();
    const { data: team, isError: isTeamError, isLoading: isTeamLoading, refetch } = useGetTeamQuery({ teamId });

    const teamNameInput = useRef<HTMLInputElement>();

    const [updateTeamLogo, { isLoading: updateLogoIsLoading }] = useUpdateTeamLogoMutation();
    const [deleteTeamLogo, { isLoading: deleteLogoIsLoading }] = useDeleteTeamLogoMutation();

    useEffect(() => {
        if (team?.name !== undefined) {
            onNameChange(team.name);
        }
    }, [team?.name]);

    const isSaveButtonDisabled = teamName.trim() === '';

    const isTeamFetchError = isTeamError;

    const handleDeleteLogo = () => {
        void deleteTeamLogo({ teamId: team.id }).then((response) => {
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                }
            }
        });
    };

    const handleLogoEdit = async (file: File, crop: PixelCrop) => {
        let formData: FormData;
        if (file.type === 'image/svg+xml') {
            formData = new FormData();
            formData.append('attachments', file);
        } else {
            const croppedImage: Blob = await getCroppedImgBlob(file, crop);
            formData = new FormData();
            formData.append(
                'attachments',
                new File([croppedImage], `logo.${fileTypeToImageExtension(file.type)}`, { type: file.type })
            );
        }

        void updateTeamLogo({ teamId: team.id, form: formData }).then((response) => {
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                }
            }
        });
    };

    const handleEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !isSaveButtonDisabled) {
            void onSave();
        }
    };

    if (isTeamFetchError) {
        return (
            <Tabs.Panel>
                <div className="flex justify-center">
                    <RetryComponent retryFunction={refetch} />
                </div>
            </Tabs.Panel>
        );
    }

    return (
        <Tabs.Panel>
            <Label>
                <Trans>Team name</Trans>
            </Label>

            <Input
                ref={teamNameInput}
                value={teamName}
                onChange={(e) => onNameChange(e.target.value)}
                placeholder={t`Team name`}
                onKeyDown={handleEnterKey}
            />

            <Label className="mt-6">
                <Trans>Team logo</Trans>
            </Label>

            <p className="mb-4 mt-2 text-sm text-gray-600">
                <Trans>This logo will be used for all document exports in this team.</Trans>
            </p>

            <LogoSettings
                logo={team?.logo_url}
                logoMinWidth={200}
                logoMinHeight={80}
                isLogoLoading={isTeamLoading || updateLogoIsLoading}
                isDeleteLogoLoading={deleteLogoIsLoading}
                onImageEdit={handleLogoEdit}
                onLogoDelete={handleDeleteLogo}
            />
        </Tabs.Panel>
    );
};
