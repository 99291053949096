import { useMemo } from 'react';
import { find } from 'lodash-es';
import { search } from 'ss-search';
import { useActiveUsers } from 'App/store/usersStore';
import { User } from 'Shared/types/user';

export const filterUsers = (
    users: User[],
    usersToHide: Partial<User>[],
    inverse?: boolean,
    searchText?: string
): User[] => {
    let result: User[] = searchText
        ? (search(
              users,
              ['full_name', 'first_name', 'last_name', 'userEmail.email_address', 'userEmail.username', 'initials'],
              searchText
          ) as User[])
        : users ?? [];
    const usersToHideWithoutRowUsers = (usersToHide || []).filter((user) => user != null);
    if (usersToHideWithoutRowUsers && usersToHideWithoutRowUsers.length > 0) {
        const idProp = 'user_id' in usersToHideWithoutRowUsers[0] ? 'user_id' : 'id';
        result = result.filter((item) => {
            const findIt = find(usersToHideWithoutRowUsers, [idProp, item.id]);
            return inverse ? findIt : !findIt;
        });
    } else if (inverse) {
        result = [];
    }
    return result;
};

export const useSelectedUsers = (usersToHide: Partial<User>[], searchText: string): { selectedUsers: User[] } => {
    const users = useActiveUsers();

    return useMemo(
        () => ({
            selectedUsers: filterUsers(users, usersToHide, true, searchText),
        }),
        [users, usersToHide, searchText]
    );
};

export const useFilteredUsers = (usersToHide: Partial<User>[], searchText: string): { filteredUsers: User[] } => {
    const users = useActiveUsers();

    return useMemo(
        () => ({
            filteredUsers: filterUsers(users, usersToHide, false, searchText),
        }),
        [users, usersToHide, searchText]
    );
};
