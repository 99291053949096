import { FC, useEffect, useId, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { Form, Input } from '@wedo/design-system';
import { checkPasswordCompliance, PasswordRule } from '@wedo/utils';
import { CheckCompliance } from 'Shared/components/CheckCompliance';

export const ChangePassword: FC<{ onChange: (password: string) => void; onSubmit: () => void }> = ({
    onChange,
    onSubmit
}) => {
    const id = useId();
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');

    const passwordComplianceList = useMemo(() => checkPasswordCompliance(password), [password]);

    const isPasswordCompliant = passwordComplianceList.size === 4;

    const isPasswordMatching = repeatedPassword.length === 0 || repeatedPassword === password;

    const isPasswordAcceptable = isPasswordCompliant && isPasswordMatching && repeatedPassword.length > 0;

    useEffect(() => {
        if (isPasswordAcceptable) {
            onChange(password);
        } else {
            onChange(null);
        }
    }, [isPasswordAcceptable, password]);

    return (
        <>
            <Form.Item label={t`New password`} htmlFor={id + 'password'}>
                <Input
                    autoComplete="new-password"
                    id={id + 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                    type="password"
                    status={isPasswordCompliant ? 'success' : 'default'}
                    statusText={isPasswordCompliant ? t`Great! Your password is secure.` : ''}
                />
                {!isPasswordCompliant && (
                    <div className="mt-4 grid grid-cols-2 gap-2">
                        <CheckCompliance
                            text={t`One lowercase character`}
                            isPassing={passwordComplianceList.has(PasswordRule.Lowercase)}
                        />
                        <CheckCompliance
                            text={t`One number`}
                            isPassing={passwordComplianceList.has(PasswordRule.Number)}
                        />
                        <CheckCompliance
                            text={t`One special character`}
                            isPassing={passwordComplianceList.has(PasswordRule.Special)}
                        />
                        <CheckCompliance
                            text={t`8 characters minimum`}
                            isPassing={passwordComplianceList.has(PasswordRule.Size)}
                        />
                    </div>
                )}
            </Form.Item>

            <Form.Item label={t`Confirm password`} htmlFor={id + 'password-bis'}>
                <Input
                    autoComplete="new-password"
                    id={id + 'password-bis'}
                    type="password"
                    value={repeatedPassword}
                    onChange={(e) => setRepeatedPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                    status={!isPasswordMatching ? 'error' : 'default'}
                    statusText={!isPasswordMatching ? t`New passwords don't match` : ''}
                />
            </Form.Item>
        </>
    );
};
