import React, { FC } from 'react';
import { faClockRotateLeft, faPen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { EmptyState, Skeleton, Tabs, Timeline } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { AddEntityToFavoriteTimeLineItem } from 'Shared/components/history/AddEntityToFavoriteTimeLineItem';
import { AddUserToEntityTimelineItem } from 'Shared/components/history/AddUserToEntityTimelineItem';
import { AddedCommentTimeLineItem } from 'Shared/components/history/AddedCommentTimeLineItem';
import { ArchivedEntityTimeLineItem } from 'Shared/components/history/ArchivedEntityTimeLineItem';
import { ChangedDescriptionTimeLineItem } from 'Shared/components/history/ChangedDescriptionTimeLineItem';
import { ChangedNameTimeLineItem } from 'Shared/components/history/ChangedNameTimeLineItem';
import { ChangedPrivacyTimeLineItem } from 'Shared/components/history/ChangedPrivacyTimeLineItem';
import { CreatedEntityTimeLineItem } from 'Shared/components/history/CreatedEntityTimeLineItem';
import { DeletedEntityTimeLineItem } from 'Shared/components/history/DeletedEntityTimeLineItem';
import { PromotedMemberToModeratorTimeLineItem } from 'Shared/components/history/PromotedMemberToModeratorTimeLineItem';
import { RefuseMembershipTimeLineItem } from 'Shared/components/history/RefuseMembershipTimeLineItem';
import { RemoveFromFavoriteTimeLineItem } from 'Shared/components/history/RemoveFromFavoriteTimeLineItem';
import { RemoveUserFromEntityTimelineItem } from 'Shared/components/history/RemoveUserFromEntityTimelineItem';
import { RequestedMembershipTimeLineItem } from 'Shared/components/history/RequestedMembershipTimeLineItem';
import { RestoreEntityTimeLineItem } from 'Shared/components/history/RestoreEntityTimeLineItem';
import { RevokedMemberModeratorTimeLineItem } from 'Shared/components/history/RevokedMemberModeratorTimeLineItem';
import { UnarchiveEntityTimeLineItem } from 'Shared/components/history/UnarchiveEntityTimeLineItem';
import { UpdateCommentTimeLineItem } from 'Shared/components/history/UpdateCommentTimeLineItem';
import { useGetChecklistTemplateQuery, useGetTemplateHistoryQuery } from 'Shared/services/template';
import { TemplateHistory } from 'Shared/types/template';
import { AddedTaskToTemplateTimeLineItem } from './components/AddedTaskToTemplateTimeLineItem';
import { DeletedCommentTemplate } from './components/DeletedCommentTemplate';
import { DuplicatedTemplateTimeLineItem } from './components/DuplicatedTemplateTimeLineItem';

type ActivityItemProps = { activity: TemplateHistory };
const ActivityItem: FC<ActivityItemProps> = ({ activity }) => {
    switch (activity.event_key) {
        case 'deleted_comment_checklist_template':
            return <DeletedCommentTemplate activity={activity} />;
        case 'changed_checklist_template_privacy':
            return <ChangedPrivacyTimeLineItem activity={activity} entityType="template" />;
        case 'changed_checklist_template_description':
            return <ChangedDescriptionTimeLineItem activity={activity} entityType="template" />;
        case 'renamed_checklist_template':
            return <ChangedNameTimeLineItem activity={activity} entityType="template" />;

        case 'added_checklist_template_to_favorites':
            return <AddEntityToFavoriteTimeLineItem activity={activity} entityType="template" />;
        case 'removed_checklist_template_from_favorites':
            return <RemoveFromFavoriteTimeLineItem activity={activity} entityType="template" />;

        case 'archived_checklist_template':
            return <ArchivedEntityTimeLineItem activity={activity} entityType="template" />;
        case 'unarchived_checklist_template':
            return <UnarchiveEntityTimeLineItem activity={activity} entityType="template" />;
        case 'changed_checklist_template_deleted':
        case 'deleted_checklist_template':
            return <DeletedEntityTimeLineItem activity={activity} entityType="template" />;
        case 'restored_checklist_template':
            return <RestoreEntityTimeLineItem activity={activity} entityType="template" />;

        case 'added_member_to_checklist_template':
            return <AddUserToEntityTimelineItem activity={activity} entityType="template" />;
        case 'removed_member_from_checklist_template':
            return <RemoveUserFromEntityTimelineItem activity={activity} entityType="template" />;
        case 'promoted_checklist_template_member':
            return <PromotedMemberToModeratorTimeLineItem activity={activity} entityType="template" />;
        case 'revoked_checklist_template_member':
            return <RevokedMemberModeratorTimeLineItem activity={activity} entityType="template" />;

        case 'created_checklist_template':
            return <CreatedEntityTimeLineItem activity={activity} entityType="template" />;
        case 'duplicated_checklist_template':
            return <DuplicatedTemplateTimeLineItem activity={activity} closeModal={() => null} />;

        case 'updated_comment_checklist_template':
            return <UpdateCommentTimeLineItem activity={activity} entityType="template" />;
        case 'commented_checklist_template':
            return <AddedCommentTimeLineItem activity={activity} entityType="template" />;

        case 'requested_checklist_template_membership':
            return <RequestedMembershipTimeLineItem activity={activity} entityType="template" />;
        case 'refused_checklist_template_membership':
            return <RefuseMembershipTimeLineItem activity={activity} entityType="template" />;

        case 'added_task_to_checklist_template':
            return <AddedTaskToTemplateTimeLineItem activity={activity} />;

        default:
            return (
                <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faPen}>
                    {activity.event_key}
                </Timeline.Item>
            );
    }
};

type HistoryPanelProps = { templateId: Id };
export const HistoryPanel: FC<HistoryPanelProps> = ({ templateId }) => {
    const { data: template } = useGetChecklistTemplateQuery(templateId);
    const { data: history, isLoading } = useGetTemplateHistoryQuery(templateId, { skip: templateId === undefined });

    return (
        <Tabs.Panel>
            {isLoading && (
                <div className="flex flex-col gap-2">
                    <Skeleton count={9} className="h-6" />
                </div>
            )}

            {isEmpty(history) && !isLoading && (
                <EmptyState icon={faClockRotateLeft}>
                    <EmptyState.Text>
                        <Trans>No history for the {template?.name} template</Trans>
                    </EmptyState.Text>
                </EmptyState>
            )}

            {!isEmpty(history) && (
                <Timeline>
                    {history?.map((activity) => <ActivityItem key={activity.id} activity={activity} />)}
                </Timeline>
            )}
        </Tabs.Panel>
    );
};
