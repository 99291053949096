import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Modal, Skeleton } from '@wedo/design-system';
import { Title } from 'Pages/settings/security/components/TotpModalContent/Title';
import { useEnable } from 'Pages/settings/security/hooks/useEnable';
import { useSecurityCode } from 'Pages/settings/security/hooks/useSecurityCode';

export const SecurityCode = (): JSX.Element => {
    const { enable, isLoading: isLoadingEnable } = useEnable();
    const { codeList, isLoading } = useSecurityCode();

    return (
        <>
            <Modal.Body>
                <Title>
                    <Trans>Security codes</Trans>
                </Title>
                <p>
                    <Trans>
                        Finally, please
                        <strong> write these 8 security codes down </strong>
                        to recover your account if needed. Each code can be used
                        <strong> only once</strong>.
                    </Trans>
                </p>
                <div className="bg-info mt-4 border border-dashed border-black py-4 font-mono text-xl">
                    {isLoading ? (
                        <div className="flex flex-col gap-2">
                            <Skeleton count={5} className="h-2" />
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 justify-items-center">
                            {codeList.map((code) => (
                                <div key={code}>{code}</div>
                            ))}
                        </div>
                    )}
                </div>
                <Alert type={'info'} title={t`Once enabled`} className="mt-8">
                    <Trans>
                        You'll be required to provide a six-digit passcode every time you login, which will be generated
                        by the two-factor authentication app on your phone.
                    </Trans>
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button color="success" loading={isLoadingEnable} onClick={enable}>
                    <Trans>Enable two-factor authentication</Trans>
                </Button>
            </Modal.Footer>
        </>
    );
};
