import React from 'react';
import { faCheck, faEllipsisV, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Plural, t, Trans } from '@lingui/macro';
import { ButtonSize, ButtonVariant, Dropdown, useConfirm } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts';
import { ConfirmWithTextareaModal } from 'Pages/MyTopicsPage/components/ConfirmWithTextareaModal';
import {
    getSearchParamsWithoutTopicId,
    MeetingViewSearchParams,
} from 'Pages/meeting/components/MeetingView/MeetingView';
import { TocTreeTopicSubmissions } from 'Pages/meeting/components/TableOfContents/TableOfContentsTree';
import { trpc, trpcUtils } from 'Shared/trpc';

export const SubmissionsSectionDropdown = ({
    variant = 'text',
    size = 'xs',
}: {
    variant?: ButtonVariant;
    size?: ButtonSize;
}) => {
    const { confirm } = useConfirm();
    const { meetingId } = useMeetingContext();
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const { data: topicSubmissions = [] } = trpc.meeting.listTopicSubmissions.useQuery(
        { meetingId },
        { enabled: meetingId != null }
    );

    const invalidateData = () => {
        if (topicSubmissions.some(({ meetingTopic: { id } }) => id === searchParams.topicId)) {
            setSearchParams(getSearchParamsWithoutTopicId(searchParams));
        }
        void trpcUtils().meetingTopic.invalidate();
        void trpcUtils().meeting.listTopicSubmissions.invalidate({ meetingId });
    };

    const { mutateAsync: rejectAllSubmissions } = trpc.meetingTopic.rejectAllSubmissionsForMeeting.useMutation();
    const { mutateAsync: acceptAllSubmissions } = trpc.meetingTopic.acceptAllSubmissionsForMeeting.useMutation();

    const handleRejectAllTopics = async () => {
        const shouldRejectAll = await confirm(
            {
                type: 'danger',
                title: t`Reject all topics`,
                inputLabel: t`Reason`,
                content: (
                    <div className="flex flex-col gap-2">
                        <p>
                            <Trans>Are you sure you want to reject the following topics:</Trans>
                        </p>
                        <div className="ml-6">
                            <ul className="list-disc">
                                {(topicSubmissions as TocTreeTopicSubmissions).slice(0, 3).map(({ meetingTopic }) => (
                                    <li key={meetingTopic.id}>{meetingTopic.title}</li>
                                ))}
                                {topicSubmissions.length > 3 && (
                                    <Plural
                                        value={topicSubmissions.length - 3}
                                        one={`And 1 more topic`}
                                        other={`And ${topicSubmissions.length - 3} more topics ...`}
                                    />
                                )}
                            </ul>
                        </div>
                        <p>
                            <Trans>Topic creators can re-submit topics once they have been rejected.</Trans>
                        </p>
                    </div>
                ),
                confirmText: t`Reject all`,
            },
            ConfirmWithTextareaModal
        );

        if (shouldRejectAll !== false) {
            await rejectAllSubmissions({ meetingId, reason: shouldRejectAll });
            invalidateData();
        }
    };

    const handleAcceptAllTopics = async () => {
        const shouldAcceptAll = await confirm({
            type: 'primary',
            title: t`Accept all topics`,
            content: (
                <div className="flex flex-col gap-2">
                    <p>
                        <Trans>Are you sure you want to accept the following topics in this meeting:</Trans>
                    </p>
                    <div className="ml-6">
                        <ul className="list-disc">
                            {(topicSubmissions as TocTreeTopicSubmissions).slice(0, 3).map(({ meetingTopic }) => (
                                <li key={meetingTopic.id}>{meetingTopic.title}</li>
                            ))}
                            {topicSubmissions.length > 3 && (
                                <Plural
                                    value={topicSubmissions.length - 3}
                                    one={`And 1 more task`}
                                    other={`And ${topicSubmissions.length - 3} more topics ...`}
                                />
                            )}
                        </ul>
                    </div>
                    <p>
                        <Trans>You can modify topics and their content after accepting them.</Trans>
                    </p>
                </div>
            ),
            confirmText: t`Accept all`,
        });

        if (shouldAcceptAll) {
            await acceptAllSubmissions({ meetingId });
            invalidateData();
        }
    };

    return (
        <Dropdown size={size} icon={faEllipsisV} variant={variant} title={t`Submissions section`} className="my-0.5">
            <Dropdown.Item icon={faCheck} onClick={handleAcceptAllTopics}>
                <Trans>Accept all topics</Trans>
            </Dropdown.Item>
            <Dropdown.Item danger icon={faTimes} onClick={handleRejectAllTopics}>
                <Trans>Reject all topics</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};
