import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { HTMLTextAreaElement } from 'happy-dom';
import { isArray } from 'lodash-es';
import readXlsxFile, { Row } from 'read-excel-file';
import { Alert, Button, ContextModalProps, Modal, Steps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { isNullOrWhitespace } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { checkTitleMaxLength } from 'Pages/meeting/components/TableOfContents/utils';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { useAddTopicsAndSectionsMutation } from 'Shared/services/meeting';
import { trpc } from 'Shared/trpc';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { parseTextData, rowToItem } from 'Shared/utils/parseClipboard';
import { PasteTextStep } from './PasteTextStep';
import { PreviewStep } from './PreviewStep';
import { ClipboardItem, convertToSectionsTopics, getNextOrder, ParseModes } from './utils';

type ImportSettings = {
    firstRowsToRemove: number;
    level1: number;
    level2: number[];
    level3?: number;
    firstBlockColumnIndex: number;
    duration?: number;
    repeatEvery?: number;
};
const DefaultImportSettings: ImportSettings = {
    firstRowsToRemove: 1,
    level1: 0,
    level2: [1],
    level3: 2,
    firstBlockColumnIndex: 3,
    duration: 4,
    repeatEvery: 5,
};
const RaiffeisenImportSettings: ImportSettings = {
    firstRowsToRemove: 5,
    level1: 0,
    level2: [1, 2],
    firstBlockColumnIndex: 3,
};

export const ImportSectionsAndTopicsModal = ({
    meetingId,
    initialValue,
    children,
    ...modalProps
}: ContextModalProps & { meetingId: Id; initialValue?: string }): ReactElement => {
    const { currentUser } = useCurrentUserContext();
    const getDefaultPreferences = usePreferences();
    const { meeting } = useMeeting(meetingId);

    const [addTopicsAndSections] = useAddTopicsAndSectionsMutation();

    const textAreaRef = useRef<HTMLTextAreaElement>();

    const { data: existingSections } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: (data: MeetingSection[]) => camelToSnake(data).filter((section) => section.parent_section_id == null),
    });

    const { data: existingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: (data: MeetingTopic[]) => camelToSnake(data).filter((topic) => topic.meeting_section_id == null),
    });

    const [parseMode, setParseMode] = useState(ParseModes.SectionsTopics);
    const [textAreaContent, setTextAreaContent] = useState(initialValue);
    const [currentStep, setCurrentStep] = useState(1);
    const [rows, setRows] = useState<Row[]>(null);
    const [items, setItems] = useState<ClipboardItem[]>([]);
    const [error, setError] = useState(null);
    const [currentUserIsPresenter, setCurrentUserIsPresenter] = useState(
        getDefaultPreferences('defaultMeetingPresenterTopic', false) as boolean
    );
    const [repeatEvery, setRepeatEvery] = useState(getDefaultPreferences('defaultMeetingRepeatTopic', true) as boolean);
    const [importFirstBlock, setImportFirstBlock] = useState(true);
    const [hideTopicSectionNumbering, setHideTopicSectionNumbering] = useState<boolean>(
        meeting?.settings?.hide_section_numbering ?? false
    );

    const handleImportDone = async () => {
        const topicsToAdd: Partial<MeetingTopic>[] = [];
        const inlineSections = (items: ClipboardItem[]) => {
            const currentSections: Partial<MeetingSection>[] = [];
            items.forEach((item) => {
                if ('isSection' in item && item.isSection) {
                    const section = {
                        id: item.id,
                        section_series_id: item.id,
                        parent_section_id: item.parent_section_id,
                        title: item.title,
                        meeting_id: meetingId,
                        order: item.order,
                        display_id: item.display_id,
                        childSections: item.childItems ? inlineSections(item.childItems) : [],
                    };
                    currentSections.push(section);
                } else {
                    topicsToAdd.push({
                        id: item.id,
                        topic_series_id: item.id,
                        title: item.title,
                        meeting_id: meetingId,
                        meeting_section_id: item.meeting_section_id,
                        order: item.order,
                        display_id: item.display_id,
                        presenters: item.presenters.map(({ id }) => id),
                        duration: item.duration,
                        repeat_every: item.repeatEvery,
                        firstBlock: item.firstBlock,
                    });
                }
            });
            return currentSections;
        };
        const sectionsToAdd = inlineSections(items);
        if (sectionsToAdd.length > 0 || topicsToAdd.length > 0) {
            await addTopicsAndSections({
                meetingId: meetingId,
                sections: sectionsToAdd,
                topics: topicsToAdd,
                config: {
                    importFirstBlock,
                    hideTopicSectionNumbering,
                },
            });
        }
    };

    const handleDone = async () => {
        await handleImportDone();
        setTextAreaContent('');
        setCurrentStep(1);
        setItems([]);
        await modalProps.close();
    };
    const handleNext = () => {
        setError(null);
        setCurrentStep(2);
    };
    const handlePrevious = () => {
        setCurrentStep(1);
    };
    const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>, confirm?: boolean) => {
        setTextAreaContent(e.target.value);
        if (confirm) {
            handleNext();
        }
        setRows(null);
    };

    useEffect(() => {
        if (textAreaContent == null || textAreaContent.length <= 0) {
            return;
        }
        const items = parseTextData(textAreaContent, parseMode !== ParseModes.OnlyTopics);
        if (items.length > 0) {
            if (!checkTitleMaxLength(items)) {
                setCurrentStep(1);
                setError(t`Some lines are too long in your import, the limit for each line is 200 characters.`);
                return;
            }
            const existingItemsCount = existingSections.length + existingTopics.length;

            const startingOrder = Math.max(getNextOrder(existingSections), getNextOrder(existingTopics));

            if (currentUserIsPresenter) {
                items.forEach((item) => {
                    item.presenters = [currentUser];
                    item.childItems.forEach((subItem) => {
                        subItem.presenters = [currentUser];
                    });
                });
            }

            setItems(
                convertToSectionsTopics(
                    items,
                    '',
                    existingItemsCount,
                    startingOrder,
                    0,
                    null,
                    meetingId,
                    parseMode,
                    repeatEvery
                )
            );
        }
    }, [currentStep, parseMode]);

    useEffect(() => {
        if (rows == null || rows.length === 0) {
            return;
        }
        const config = rows[0][0]?.includes('RAIFFEISEN') ? RaiffeisenImportSettings : DefaultImportSettings;

        const items = [];
        let currentItem = null;
        rows.slice(config.firstRowsToRemove).forEach((row) => {
            if (!isArray(row) || rows.length < 2) {
                return;
            }

            const title =
                row[config.level1]?.length > 0
                    ? row[config.level1]?.toString()
                    : row[config.level2[0]]?.length > 0
                      ? config.level2.map((index) => row[index]).join(' ')
                      : row[config.level3]?.toString();

            if (title == null || title.length === 0) {
                return;
            }
            const item = {
                ...rowToItem(title),
                presenters: currentUserIsPresenter ? [currentUser] : [],
                firstBlock: config.firstBlockColumnIndex >= 0 ? row[config.firstBlockColumnIndex] : undefined,
                repeatEvery:
                    config.repeatEvery >= 0 && row[config.repeatEvery] != null && Number(row[config.repeatEvery]) <= 4
                        ? row[config.repeatEvery]
                        : repeatEvery
                          ? 1
                          : 0,
                duration: config.duration >= 0 ? row[config.duration] : undefined,
            };

            if (row[config.level1]?.length > 0 || items.length === 0 || parseMode === ParseModes.OnlyTopics) {
                items.push(item);
            } else if (config.level3 != null && row[config.level3]?.length > 0) {
                currentItem.childItems.push(item);
            } else {
                items[items.length - 1].childItems.push(item);
            }
            currentItem = item;
        });

        setItems(
            convertToSectionsTopics(
                items,
                '',
                existingSections.length + existingTopics.length,
                Math.max(getNextOrder(existingSections), getNextOrder(existingTopics)),
                0,
                null,
                meetingId,
                parseMode,
                repeatEvery
            )
        );
        setCurrentStep(2);
    }, [rows, parseMode]);

    const handleImportXlsx = (file: File) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.addEventListener('loadend', async () => {
            readXlsxFile(reader.result).then((rows) => {
                if (!isArray(rows) || rows.length === 0) {
                    return;
                }
                setRows(rows);
                setTextAreaContent(null);
            });
        });
    };

    const handleHideTopicSectionNumberingChange = (hideTopicSectionNumbering: boolean) => {
        const updateTitle = (item: ClipboardItem) =>
            hideTopicSectionNumbering ? item.originTitle : rowToItem(item.title).title;

        setHideTopicSectionNumbering(hideTopicSectionNumbering);
        setItems((items) =>
            items.map((item) => ({
                ...item,
                title: updateTitle(item),
                childItems: item.childItems.map((childItem) => ({
                    ...childItem,
                    title: updateTitle(childItem),
                    childItems: childItem.childItems,
                })),
            }))
        );
    };

    const handleUpdateTopic = (topicId: string, changes: Partial<ClipboardItem>) => {
        const updateItem = (item: ClipboardItem) => {
            return topicId == null || item.id === topicId
                ? { ...item, ...changes, childItems: item.childItems.map(updateItem) }
                : { ...item, childItems: item.childItems.map(updateItem) };
        };

        setItems(items.map(updateItem));
    };

    const handleRepeatEvery = (repeatEvery: boolean) => {
        setRepeatEvery(repeatEvery);
        handleUpdateTopic(null, { repeatEvery: repeatEvery ? 1 : 0 });
    };

    const handleCurrentUserIsPresenter = (currentUserIsPresenter: boolean) => {
        setCurrentUserIsPresenter(currentUserIsPresenter);
        handleUpdateTopic(null, { presenters: currentUserIsPresenter ? [currentUser] : [] });
    };

    return (
        <Modal size={'lg'} {...modalProps} initialFocus={textAreaRef}>
            <Modal.Header title={t`Import agenda`} />
            <Steps className={'!rounded-none'} currentIndex={currentStep}>
                <Steps.Step>{t`Paste your text`}</Steps.Step>
                <Steps.Step>{t`Preview your result`}</Steps.Step>
            </Steps>
            <Modal.Body>
                {currentStep === 1 ? (
                    <PasteTextStep
                        ref={textAreaRef}
                        textAreaContent={textAreaContent}
                        handleTextareaChange={handleTextareaChange}
                        handleImportXlsx={handleImportXlsx}
                        meetingId={meetingId}
                    />
                ) : (
                    <>
                        <PreviewStep
                            meetingId={meetingId}
                            items={items}
                            parseMode={parseMode}
                            currentUserIsPresenter={currentUserIsPresenter}
                            repeatEvery={repeatEvery}
                            importFirstBlock={importFirstBlock}
                            hideTopicSectionNumbering={hideTopicSectionNumbering}
                            onParseModeChange={(value) => setParseMode(value)}
                            onCurrentUserIsPresenterChange={handleCurrentUserIsPresenter}
                            onImportFirstBlockChange={setImportFirstBlock}
                            onRepeatEveryChange={handleRepeatEvery}
                            onHideTopicSectionNumberingChange={handleHideTopicSectionNumberingChange}
                            onUpdateTopic={handleUpdateTopic}
                        />
                    </>
                )}
                {error && <Alert type={'danger'} title={error} className="mt-4" />}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                {currentStep === 2 && (
                    <Button key="previous" onClick={handlePrevious}>
                        <Trans>Previous</Trans>
                    </Button>
                )}
                {currentStep === 2 ? (
                    <Button key="save" color={'primary'} onClick={handleDone}>
                        <Trans>Import</Trans>
                    </Button>
                ) : (
                    <Button
                        key="next"
                        color={'primary'}
                        onClick={handleNext}
                        disabled={isNullOrWhitespace(textAreaContent)}
                    >
                        <Trans>Next</Trans>
                    </Button>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
