import {
    faBallotCheck,
    faCalendarDay,
    faChartGantt,
    faCheck,
    faCircleInfo,
    faFileLines,
    faRss,
} from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { WorkspaceTab } from 'Shared/types/workspace';

export const WorkspaceTabs: WorkspaceTab[] = [
    { label: <Trans>Overview</Trans>, type: 'overview', active: true, icon: faCircleInfo },
    { label: <Trans>Meetings</Trans>, type: 'meetings', active: true, icon: faCalendarDay },
    { label: <Trans>Tasks</Trans>, type: 'tasks', active: true, icon: faCheck },
    { label: <Trans>Gantt</Trans>, type: 'gantt', active: false, icon: faChartGantt },
    { label: <Trans>Files</Trans>, type: 'files', active: true, icon: faFileLines },
    { label: <Trans>Checklists</Trans>, type: 'checklists', active: false, icon: faBallotCheck },
    { label: <Trans>Feed</Trans>, type: 'feed', active: true, icon: faRss },
];
