import React, { FC } from 'react';
import { ActivityLog } from '@wedo/db';
import { Button } from '@wedo/design-system';
import { ActivityLogObject } from 'Shared/types/activityLog';
import { Checklist } from 'Shared/types/checklist';

const ChecklistTitle: FC<{ checklist: Checklist }> = ({ checklist }) => {
    return (
        <Button size="xs" variant="link" href={`/checklists/${checklist?.id}/tasks`} className="w-full">
            <div className="text-xs group/meeting flex items-center gap-1 w-full font-semibold">
                <span className="truncate">{checklist?.name}</span>
            </div>
        </Button>
    );
};

export const ChecklistModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    if (auditLog.objectType !== ActivityLogObject.CHECKLIST) {
        return null;
    }

    if (auditLog.eventKey === 'created_checklist') {
        return <ChecklistTitle checklist={auditLog.newValue as Checklist} />;
    }

    return <ChecklistTitle checklist={auditLog.oldValue as Checklist} />;
};
