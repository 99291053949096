import { User } from 'Shared/types/user';


/** retrieve the network-id from a user, otherwise null */
export const getNetworkId = (user: User, allowMultiple = false) => {
    if (user.userNetworks && (user.userNetworks.length === 1 || allowMultiple)) {
        return user.userNetworks[0].network.id;
    }

    if (user.userNetwork) {
        return user.userNetwork.network.id;
    }

    return null;
};
