import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Card, Skeleton, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { AddTokenButton } from 'Pages/settings/api/components/TokenCard/AddTokenButton';
import { DisplayToken } from 'Shared/components/settings/DisplayToken';
import { useDeleteTokenMutation, useGetTokenQuery } from 'Shared/services/token';

export const TokenCard = (): JSX.Element => {
    const { show } = useNotification();
    const [deleteToken] = useDeleteTokenMutation();
    const { data: token, isFetching, isSuccess, isError, error, refetch } = useGetTokenQuery();
    const handleDelete = async () => {
        const result = await deleteToken();

        if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Card>
            <Card.Header title={t`Personal API Token`} />
            <Card.Body>
                {isFetching && <Skeleton count={2} className="h-10" />}
                {!isFetching && isSuccess && token === '' && <AddTokenButton />}
                {!isFetching && isSuccess && token !== '' && (
                    <>
                        <DisplayToken token={token} onDelete={handleDelete} />
                        <Alert className="mt-4" type="info" title={t`API documentation`}>
                            <Trans>You can access the API documentation by following this link:</Trans>{' '}
                            <Button
                                variant="link"
                                color="primary"
                                href="https://api.wedo.app/documentation"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://api.wedo.app/documentation
                            </Button>
                        </Alert>
                        <Alert className="mt-4" type="warning" title={t`Keep this token secret`}>
                            <Trans>
                                Anyone who gets a hold of it can read/write meetings and tasks as if they were you. You
                                should reset it if that ever happens.
                            </Trans>
                        </Alert>
                    </>
                )}
                {!isFetching && isError && (
                    <div>
                        <Alert type="danger" title={t`Error`} className="mb-4">
                            {error.message}
                        </Alert>
                        <Button color="primary" onClick={refetch}>
                            <Trans>Retry</Trans>
                        </Button>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};
