import { useState } from 'react';
import { useStore } from 'zustand';
import {
    createMeetingExportSettingsStore,
    DefaultSettings,
    type Setting,
    type Settings,
    type Store,
    type State,
} from '@wedo/pdf/meeting/store';
import { type Meeting } from 'Shared/types/meeting';

const SerializableMeetingExportSettings: Set<Setting> = new Set(['footer', 'title']);

const SerializableMeetingsExportSettings: Set<Setting> = new Set([
    'attendeesView',
    'blocksToDisplay',
    'elementsToDisplay',
    'fontFamily',
    'fontSize',
    'margins',
    'orientation',
    'subTitleColor',
    'textColor',
    'titleColor',
]);

export const MeetingsExportSettingsStorageKey = 'meeting-export-settings-all';

export const meetingExportSettingsStorageKey = (meetingId: string) => {
    return `meeting-export-settings-${meetingId}`;
};

export const serializableStorageKey = (meetingId: string, key: Setting) => {
    return SerializableMeetingExportSettings.has(key)
        ? meetingExportSettingsStorageKey(meetingId)
        : SerializableMeetingsExportSettings.has(key)
          ? MeetingsExportSettingsStorageKey
          : null;
};

export const getLocalSettings = (meeting: Meeting) => {
    const localMeetingsSettings = JSON.parse(localStorage.getItem(MeetingsExportSettingsStorageKey) ?? '{}');
    const localMeetingSettings =
        meeting != null ? JSON.parse(localStorage.getItem(meetingExportSettingsStorageKey(meeting.id)) ?? '{}') : {};
    return { ...localMeetingsSettings, ...localMeetingSettings };
};

export const useMeetingExportSettingsStore = (defaultData: Omit<State, 'settings'>, defaultSettings?: Settings) => {
    const [store] = useState(() => {
        const settings = {
            ...DefaultSettings,
            ...getLocalSettings(defaultData.meeting),
            ...(defaultSettings ?? {}),
        };
        return createMeetingExportSettingsStore(defaultData, settings);
    });
    return store;
};

export const useSetting = <T extends Setting>(store: Store, key: T): Settings[T] => {
    return useStore(store, ({ settings }) => settings[key]);
};

export const setSetting = <T extends Setting>(store: Store, key: T, value: Settings[T]) => {
    const storageKey = serializableStorageKey(store.getState().meeting.id, key);
    if (storageKey != null) {
        const settings = JSON.parse(localStorage.getItem(storageKey) ?? '{}');
        localStorage.setItem(storageKey, JSON.stringify({ ...settings, [key]: value }));
    }
    store.setState(({ settings }) => {
        settings[key] = value;
    });
};

export const resetSetting = <T extends Setting>(store: Store, key: T, value: Settings[T]) => {
    const storageKey = serializableStorageKey(store.getState().meeting.id, key);
    if (storageKey != null) {
        const { [key]: value, ...settings } = JSON.parse(localStorage.getItem(storageKey) ?? '{}');
        if (Object.keys(settings).length === 0) {
            localStorage.removeItem(storageKey);
        } else {
            localStorage.setItem(storageKey, JSON.stringify(settings));
        }
    }
    store.setState(({ settings }) => {
        settings[key] = value;
    });
};
