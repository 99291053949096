import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import { useSectionsPanel } from 'Shared/components/sections/SectionsPanel/useSectionsPanel';
import { checklistApi, useGetChecklistQuery, useUpdateChecklistSectionMutation } from 'Shared/services/checklist';

export const useChecklistSections = (checklistId: Id) => {
    const { data: checklist } = useGetChecklistQuery(checklistId);
    const sections = checklist?.sections;

    const dispatch = useAppDispatch();

    const [updateChecklistSection] = useUpdateChecklistSectionMutation();

    const { handleDragEnd, maxOrder } = useSectionsPanel({
        sections,
        updateItemOrders: (payload, sortedSections) => {
            dispatch(
                checklistApi.util.updateQueryData('getChecklist', checklistId, (checklist) => {
                    checklist.sections = sortedSections;
                })
            );

            for (const item of payload) {
                void updateChecklistSection({ checklistId, sectionId: item?.id, section: { order: item.order } });
            }
        },
    });

    return { sections, handleDragEnd, maxOrder };
};
