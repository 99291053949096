import React, { FC, PropsWithChildren } from 'react';
import { Label } from '@wedo/design-system';
import { ChangeOption, EditOptionsSelect } from './EditOptionSelect';

type BulkEditRowProps = {
    label: string;
    changeOption: ChangeOption;
    onChangeOptionChange: (changeOption: ChangeOption) => void;
    isResetDisabled?: boolean;
    isResetHidden?: boolean;
};

export const BulkEditRow: FC<PropsWithChildren<BulkEditRowProps>> = ({
    label,
    children,
    onChangeOptionChange,
    changeOption,
    isResetDisabled = false,
    isResetHidden = false,
    ...props
}) => {
    return (
        <div className="flex flex-col px-6" {...props}>
            <Label>{label}</Label>

            <EditOptionsSelect
                value={changeOption}
                onChange={onChangeOptionChange}
                isResetDisabled={isResetDisabled}
                isResetHidden={isResetHidden}
            />

            {changeOption === 'edit' && children}
        </div>
    );
};
