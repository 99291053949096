import { autoUpdate, flip, FloatingPortal, offset, useFloating, VirtualElement } from '@floating-ui/react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { ReactNode } from 'react';

type PopoverProps = {
    referenceElement: Element | VirtualElement;
    children: ReactNode;
    yOffset?: number;
    className?: string;
};

export const Popover = ({ referenceElement, children, yOffset = 0, className }: PopoverProps) => {
    const { x, y, strategy, refs } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: 'bottom-start',
        middleware: [flip(), offset(yOffset)],
    });

    if (refs.reference.current === null) {
        refs.setReference(referenceElement);
    }

    const floatingStyle = {
        position: strategy,
        left: x ?? 0,
        top: y ?? 0,
    };

    return (
        <FloatingPortal>
            <HeadlessPopover>
                <HeadlessPopover.Panel ref={refs.setFloating} style={floatingStyle} className={className} static>
                    <div className="flex flex-col gap-1 rounded-lg bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5">
                        {children}
                    </div>
                </HeadlessPopover.Panel>
            </HeadlessPopover>
        </FloatingPortal>
    );
};
