import { httpBatchLink } from '@trpc/client';
import { socketId } from '~/modules/ws/AuthenticatedWebSocket';
import { getAuthToken } from '@wedo/utils';

export const authenticatedHttpBatchLink = httpBatchLink({
    url: '/rpc',
    maxURLLength: 1024,
    headers: async () => ({
        authorization: getAuthToken(),
        'socket-id': socketId,
    }),
});
