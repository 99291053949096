import React, { useContext, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button } from '@wedo/design-system';
import { once } from '@wedo/utils/promise';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { SignExternalButton } from 'Pages/SignaturesPage/components/SignExternalButton';
import { SignSyncButton } from 'Pages/SignaturesPage/components/SignSyncButton';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { invalidateMeeting } from 'Shared/services/meeting';
import { trpcUtils } from 'Shared/trpc';

export type SignButtonProp = {
    onDone: (
        newSignatureBox: {
            pageIndex: number;
            signatureBoundingBox: { x1: number; x2: number; y1: number; y2: number };
        } | null
    ) => void;
    className?: string;
};

const SignButton = ({ onDone }: SignButtonProp) => {
    const { signatureRequest } = useContext(SignatureModalContext);

    if (signatureRequest.type === 'SES') {
        return <SignSyncButton className="flex-1" onDone={onDone} />;
    }

    return <SignExternalButton className="flex-1" onDone={onDone} />;
};

export const SignatureModalSignActions = () => {
    const { signature, signatureRequest, changeSignatureStatus, lockedByUser, meetingId, isSigning, signatureLock } =
        useContext(SignatureModalContext);
    const dispatch = useAppDispatch();
    const currentUser = useSessionUser();

    const signatureDone: SignButtonProp['onDone'] = async (newSignatureBox) => {
        if (newSignatureBox == null) {
            return;
        }

        void trpcUtils().signature.invalidate();
        void trpcUtils().attachment.invalidate();
        void trpcUtils().meeting.invalidate();
        dispatch(invalidateMeeting(meetingId));

        const { instance } = await getSharedPdfViewerInstance();

        await once(instance.Core.documentViewer, 'documentLoaded');

        const documentContainer = instance.Core.documentViewer.getScrollViewElement();
        const displayMode = instance.Core.documentViewer.getDisplayModeManager().getDisplayMode();
        const pageHeight = instance.Core.documentViewer.getPageHeight(newSignatureBox.pageIndex);

        const windowPoint = displayMode.pageToWindow(
            {
                x: newSignatureBox.signatureBoundingBox.x1,
                y: pageHeight - newSignatureBox.signatureBoundingBox.y2,
            },
            newSignatureBox.pageIndex
        );

        documentContainer.scrollTo(windowPoint.x, windowPoint.y);
    };

    useEffect(() => {
        void trpcUtils().signature.getRequestById.invalidate();
        void trpcUtils().signature.getRequestByMeetingId.invalidate();
        void trpcUtils().attachment.getSignedUrl.invalidate();
    }, [signatureLock?.isLockValid, signatureLock?.userId !== Number(currentUser.id)]);

    if (signature?.status !== 'PENDING' || signatureRequest.isCanceled) {
        return null;
    }

    return (
        <>
            <div className={'flex gap-2'}>
                <SignButton onDone={signatureDone} />
                <Button
                    className={'flex-1'}
                    disabled={isSigning}
                    variant={'filled'}
                    color="danger"
                    onClick={() => changeSignatureStatus('REFUSED')}
                >
                    <Trans>Refuse</Trans>
                </Button>
            </div>
            {signatureLock == null ? null : !signatureLock.isLockValid ? (
                <>
                    <Alert
                        className={'shadow-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'}
                        type={'warning'}
                        title={t`Your signature attempt has expired`}
                    >{t`Please refresh the page and try again in a few moments.`}</Alert>
                </>
            ) : (
                signatureLock.userId !== Number(currentUser.id) && (
                    <>
                        <Alert
                            type={'warning'}
                            title={t`The document is currently being signed by ${lockedByUser?.full_name}`}
                        >
                            <Trans>
                                This message will automatically disappear when the document is available for signing.
                            </Trans>
                        </Alert>
                    </>
                )
            )}
        </>
    );
};
