import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useCreateTokenMutation } from 'Shared/services/token';

export const AddTokenButton = (): JSX.Element => {
    const [generate, { isLoading }] = useCreateTokenMutation();
    const { show } = useNotification();

    const handleGenerate = async () => {
        const result = await generate();

        if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Button loading={isLoading} onClick={handleGenerate} color="primary">
            <Trans>Generate token</Trans>
        </Button>
    );
};
