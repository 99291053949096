import { useContext, useEffect } from 'react';
import { t } from '@lingui/macro';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { useGetSecurityCodeQuery, usePostSecurityCodeMutation } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

type SecurityCodeFn = () => { codeList: string[]; isLoading: boolean };

export const useSecurityCode: SecurityCodeFn = () => {
    const { state, dispatch } = useContext(TotpContext);
    const getCodeStatus = useGetSecurityCodeQuery();
    const [postCode, postCodeStatus] = usePostSecurityCodeMutation();

    const isLoading = getCodeStatus.isLoading || postCodeStatus.isLoading;

    useEffect(() => {
        if (getCodeStatus.isSuccess) {
            dispatch({ type: 'securityCode', value: structuredClone(getCodeStatus.data) });
        } else if (getCodeStatus.isError) {
            let error = t`Unexpected error`;

            if (errorMessage(getCodeStatus.error) === 'TypeError: Failed to fetch') {
                error = t`Network error`;
            }

            dispatch({ type: 'error', value: error });
        }
    }, [getCodeStatus.isSuccess, getCodeStatus.isError, getCodeStatus.isLoading]);

    useEffect(() => {
        if (state.securityCode.length > 0) {
            void postCode(state.securityCode);
        }
    }, [JSON.stringify(state.securityCode)]);

    useEffect(() => {
        if (postCodeStatus.isSuccess) {
            dispatch({ type: 'authenticators', value: state.authenticators.concat(postCodeStatus.data.id) });
        } else if (postCodeStatus.isError) {
            let error = t`Unexpected error`;

            if (errorMessage(postCodeStatus.error) === 'TypeError: Failed to fetch') {
                error = t`Network error`;
            }

            dispatch({ type: 'error', value: error });
        }
    }, [postCodeStatus.isSuccess, postCodeStatus.isError, postCodeStatus.isLoading]);

    return { codeList: state.securityCode, isLoading };
};
