import { HttpMethod, Id } from '@wedo/types';
import { baseApi, configureTag } from 'Shared/services/base';
import { Notification } from 'Shared/types/notification';

const { tagType, tag } = configureTag('Notifications');

export const tagNotifications = tag('notifications');

export const notificationsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            listNotifications: build.query<
                { meta: { unreadCount: number }; data: Notification[] },
                { limit?: number; offset?: number; read?: boolean }
            >({
                query: ({ offset = 0, limit = 20, read = null }) => ({
                    url: 'notifications',
                    params: { limit, offset, read },
                }),
                providesTags: [tagNotifications],
            }),
            updateNotification: build.mutation<void, { id: Id; read: boolean }>({
                query: ({ id, read }) => ({
                    url: `notifications/${id}`,
                    method: HttpMethod.Put,
                    body: { read },
                }),
                invalidatesTags: (_, error) => (error ? [] : [tagNotifications]),
            }),
            readAllNotifications: build.mutation<{ meta: { unreadCount: number }; data: Notification[] }, void>({
                query: () => ({
                    url: 'notifications/read',
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (_, error) => (error ? [] : [tagNotifications]),
            }),
        }),
    });

export const { useListNotificationsQuery, useReadAllNotificationsMutation, useUpdateNotificationMutation } =
    notificationsApi;
