import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { Form, Input } from '@wedo/design-system';
import { onEnter } from '@wedo/utils';
import { getUrlFileSource, urlFileSourceToIcon } from 'Shared/utils/attachment';

type UrlSourceFormProps = {
    linkInput: React.MutableRefObject<HTMLInputElement>;
    url: string;
    urlError: string;
    fileName: string;
    fileNameError?: string;
    onUrlChange: (url: string) => void;
    onFileNameChange: (fileName: string) => void;
    onSubmit: () => void;
};

export const UrlSourceForm: FC<UrlSourceFormProps> = ({
    linkInput,
    url,
    onUrlChange,
    urlError,
    fileName,
    fileNameError = '',
    onFileNameChange,
    onSubmit,
}) => {
    return (
        <Form>
            <Form.Item label={t`Link`} htmlFor="url">
                <Input
                    id="url"
                    ref={linkInput}
                    leadingIcon={urlFileSourceToIcon[getUrlFileSource(url)]}
                    value={url}
                    onChange={(e) => onUrlChange(e.target.value)}
                    status={urlError.length > 0 ? 'error' : 'default'}
                    statusText={urlError}
                    onKeyDown={onEnter(onSubmit)}
                    aria-label={t`Link input`}
                />
            </Form.Item>

            <Form.Item label={t`File name`} htmlFor="file-name">
                <Input
                    id="file-name"
                    value={fileName}
                    onChange={(e) => onFileNameChange(e.target.value)}
                    onKeyDown={onEnter(onSubmit)}
                    status={fileNameError.length > 0 ? 'error' : 'default'}
                    statusText={fileNameError}
                />
            </Form.Item>
        </Form>
    );
};
