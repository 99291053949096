import { FloatingPortal } from '@floating-ui/react';
import { BadgePopup, getBadgeInfo, useBadgesStore } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { usePreferences } from 'Shared/hooks/usePreferences';

export const BadgesContainer = () => {
    const {
        badges,
        actions: { remove },
    } = useBadgesStore();

    const getPreference = usePreferences();
    const navigate = useNavigate();
    const hasGamification = __ENVIRONMENT__ !== 'test' && getPreference('gamification', false);
    if (!hasGamification) {
        return <></>;
    }
    return (
        <FloatingPortal>
            <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-[100] flex items-end px-4 py-2">
                <div className="flex w-full justify-start flex-col items-center">
                    {badges.map((badge) => (
                        <BadgePopup
                            key={badge.identifier}
                            type={badge.type}
                            points={badge.value}
                            isRepeating={badge.id.endsWith('_R')}
                            icon={getBadgeInfo(badge.id).icon}
                            name={getBadgeInfo(badge.id).name}
                            quantity={getBadgeInfo(badge.id).quantity}
                            onClick={() => navigate({ pathname: `/badges` }, { state: { badgeId: badge.id } })}
                            onAnimationEnd={({ animationName }) => {
                                if (animationName !== 'badge-shine') {
                                    remove(badge.identifier);
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        </FloatingPortal>
    );
};
