import { createContext, ReactNode } from 'react';
import { DateLike } from '~/components/FormatDate/FormatDateRelative';
import { NumberRange } from '@wedo/types';

export const DateContext = createContext<{
    locale?: Locale;
    baseDate?: DateLike;
    weekStartsOn?: NumberRange<0, 7>;
    forceAbsolute?: boolean;
}>({});

export const DateProvider = ({
    locale,
    weekStartsOn,
    baseDate = null,
    children,
}: {
    locale: Locale;
    weekStartsOn: NumberRange<0, 7>;
    baseDate?: DateLike;
    children: ReactNode;
}): JSX.Element => <DateContext.Provider value={{ locale, baseDate, weekStartsOn }}>{children}</DateContext.Provider>;
