import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { msg, t, Trans } from '@lingui/macro';
import { Alert, Card, Switch, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useAppDispatch } from 'App/store';
import { type Feature } from 'Pages/settings/labs/types';
import { Can } from 'Shared/components/Can';
import { invalidateCurrentNetwork, useGetCurrentNetworkQuery } from 'Shared/services/network';
import { trpc } from 'Shared/trpc';
import { DevFeature } from 'Shared/types/user';
import { Permission } from 'Shared/utils/rbac';

const networkFeatures = [
    { id: 'reactPdf', label: msg`Use new PDF rendering engine` },
    { id: DevFeature.TopicSubmission, label: msg`Topic submission`, isDevFeature: true },
];

export const OrganizationLabsSettingsPage = () => {
    const dispatch = useAppDispatch();
    const { i18n } = useLingui();

    const { show } = useNotification();

    const { data: network } = useGetCurrentNetworkQuery();

    const { mutateAsync: toggleFeature, isPending: isTogglingFeature } = trpc.network.toggleFeature.useMutation();

    const features = useMemo(() => {
        return networkFeatures
            .filter(
                ({ isDevFeature }) =>
                    !isDevFeature || (network?.organisation?.type === 'demo' && __ENVIRONMENT__ !== 'production')
            )
            .map((feature) => {
                const value = network?.features?.[feature.id];
                return { ...feature, isEnabled: value != null && value !== false };
            });
    }, [network]);

    const handleToggleFeature = (feature: Feature) => async (value: boolean) => {
        try {
            await toggleFeature(feature.id);
            show({
                type: 'success',
                title: value
                    ? t`${i18n._(feature.label)} feature has been enabled`
                    : t`${i18n._(feature.label)} feature has been disabled`,
                delay: 2000,
            });
            dispatch(invalidateCurrentNetwork());
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Can permission={Permission.AccessAllSettings} showNoAccess>
            <Card>
                <Card.Header
                    title={t`Organization labs`}
                    description={t`Labs are beta features you can test before they are officially launched. Because of that, they may be unstable.`}
                />
                <Card.Body className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        {features.map((feature) => (
                            <Switch.Group key={feature.id}>
                                <div className="flex items-center gap-4">
                                    <Switch
                                        disabled={isTogglingFeature}
                                        checked={feature.isEnabled}
                                        onChange={handleToggleFeature(feature)}
                                        label={i18n._(feature.label)}
                                    />
                                    <Switch.Label>{i18n._(feature.label)}</Switch.Label>
                                </div>
                            </Switch.Group>
                        ))}
                    </div>
                    <Alert
                        type="info"
                        title={
                            features.length === 0 ? (
                                <Trans>No beta features to test at this time.</Trans>
                            ) : (
                                <Trans>
                                    If you have enabled or disabled a feature then you might need to refresh the page to
                                    see the changes.
                                </Trans>
                            )
                        }
                    />
                </Card.Body>
            </Card>
        </Can>
    );
};
