import { EmptyArray } from '@wedo/utils';

export const EmptyPagination = {
    data: EmptyArray,
    pagination: {
        rowCount: 0,
        pageSize: 20,
        page: 1,
        pageCount: 1,
    },
};
