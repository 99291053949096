import { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmModal, ContextModalProps, FormatDate, RadioGroup } from '@wedo/design-system';
import { Meeting } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export type PostponeTopicType = 'shift-instance' | 'shift-series';

export const PostponeTopicTypeConfirmModal: FC<
    { topic: MeetingTopic; destinationMeeting: Partial<Meeting> } & ContextModalProps
> = ({ topic, destinationMeeting, ...modalProps }) => {
    const [confirm, setConfirm] = useState<PostponeTopicType>('shift-instance');

    return (
        <ConfirmModal
            {...modalProps}
            type="warning"
            title={t`Only move this topic or all recurring topics in this series?`}
            confirmText={t`Postpone`}
            onConfirm={async () => confirm}
            defaultValue={null}
            content={
                <div className="flex gap-4 flex-col">
                    <p>
                        <Trans>
                            Do you want to move this topic to the meeting on the{' '}
                            <FormatDate format={'PPP'} date={destinationMeeting.start_at} /> and shift future topics
                            further as well?
                        </Trans>
                    </p>

                    <div>
                        <RadioGroup
                            value={confirm}
                            name={'postpone-topic'}
                            onChange={(e) => setConfirm(e as PostponeTopicType)}
                        >
                            <RadioGroup.Radio id="shift-instance" value="shift-instance">
                                <Trans>No, only this topic</Trans>
                            </RadioGroup.Radio>
                            <RadioGroup.Radio id="shift-series" value="shift-series">
                                <Trans>Yes, this topic and all further topics as well</Trans>
                            </RadioGroup.Radio>
                        </RadioGroup>
                    </div>
                </div>
            }
        />
    );
};
