import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { faCheckCircle, faCircleInfo, faListCheck, faRss } from '@fortawesome/pro-regular-svg-icons';
import { faInbox, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { msg, Trans } from '@lingui/macro';
import { Banner, Button, useModal } from '@wedo/design-system';
import { TemplateDescriptionPopover } from 'Pages/TemplatePage/TemplateDescriptionPopover';
import { Can } from 'Shared/components/Can';
import { PageHeaderTab, TabsPageHeader } from 'Shared/components/layout/TabsPageHeader';
import { TemplateDropdown } from 'Shared/components/template/TemplateDropdown';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetUserQuery } from 'Shared/services/user';
import { Permission } from 'Shared/utils/rbac';

const Tabs: PageHeaderTab[] = [
    {
        to: 'overview',
        icon: faCircleInfo,
        title: msg`Overview`,
    },
    {
        isDefault: true,
        to: 'checklists',
        icon: faCheckCircle,
        title: msg`Checklists`,
    },
    {
        to: 'feed',
        icon: faRss,
        title: msg`Feed`,
        matchPathname: 'feed/*',
    },
    {
        to: 'tasks',
        icon: faListCheck,
        title: msg`Template tasks`,
        matchPathname: 'tasks/*',
    },
];

export const TemplatePage = () => {
    const { templateId } = useParams();
    const { open } = useModal();
    const { data: template } = useGetChecklistTemplateQuery(templateId);
    const { data: deletedBy } = useGetUserQuery(template?.deleted_by, { skip: !template?.deleted_by });
    const { data: archivedBy } = useGetUserQuery(template?.archived_by, { skip: !template?.archived_by });
    const { isCurrentUserModerator } = useManageMembers(template);

    usePageTitle(template?.name);

    const filteredTabs = useMemo(
        () => Tabs.filter((tab) => isCurrentUserModerator || tab.to !== 'tasks'),
        [isCurrentUserModerator]
    );

    const actions = (
        <>
            <div className="hidden items-center gap-2 md:flex">
                <Button
                    variant="ghost"
                    shape="circle"
                    disabled={template?.deleted}
                    onClick={() => open(TemplateSettingsModal, { templateId, defaultIndex: 1 })}
                >
                    <UsersAvatarGroup
                        users={template?.userGroup?.members?.map((member) => member?.user)}
                        maxDisplayed={4}
                        size="sm"
                        tooltipPlacement="bottom"
                    />
                </Button>
            </div>
            <Can permission={Permission.ViewTemplates}>
                <TemplateDropdown template={template} size="sm" />
            </Can>
        </>
    );

    return (
        <>
            {template?.deleted && (
                <Banner type="danger" icon={faTrash}>
                    <Trans>
                        {template?.name} template has been deleted by {deletedBy?.full_name}
                    </Trans>
                </Banner>
            )}

            {template?.archived && !template?.deleted && (
                <Banner type="warning" icon={faInbox}>
                    <Trans>
                        {template?.name} template has been archived by {archivedBy?.full_name}
                    </Trans>
                </Banner>
            )}

            <TabsPageHeader
                actions={actions}
                baseLink={`/templates/${templateId}/`}
                tabs={filteredTabs}
                title={<TemplateDescriptionPopover template={template} />}
            />
        </>
    );
};
