import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { Editor, Path, Transforms } from 'slate';
import { DropZone, useModal } from '@wedo/design-system';
import { getImageDimension } from '@wedo/utils';
import { createAttachmentBlock, getAllSelectedAttachments } from 'Shared/components/editor/plugins/attachmentPlugin';
import { computeAdjustedDimension, createImageBlock } from 'Shared/components/editor/plugins/imagePlugin';
import { reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { resizeFile } from 'Shared/components/meeting/topicView/UploadImageModal/UploadImageModal';
import { Attachment } from 'Shared/types/attachment';
import { Plugin } from '../Editor';

type DropZoneElementProps = {
    editor: Editor;
    children: ReactNode;
    isDisabled: boolean;
};

const DropZoneElement = ({ editor, children, isDisabled }: DropZoneElementProps) => {
    const { open: openModal } = useModal();

    const handleFiles = (editor: Editor) => async (files: File[]) => {
        if (getAllSelectedAttachments(editor).length > 0) {
            return;
        }

        const insertAt =
            editor.selection == null ? [editor.children.length] : Path.next(editor.selection.anchor.path.slice(0, 1));
        if (files.length === 1 && files[0].type.startsWith('image/')) {
            const image = await resizeFile(files[0]);
            if (image != null) {
                const { width, height, naturalWidth } = await getImageDimension(image);
                const { adjustedWidth, adjustedHeight } = computeAdjustedDimension(editor, width, height);
                Transforms.insertNodes(
                    editor,
                    [createImageBlock(image, 'center', adjustedWidth, adjustedHeight, naturalWidth)],
                    { at: insertAt, select: true }
                );
            }
        } else if (files.length >= 1) {
            openModal(AddAttachmentModal, {
                files,
                allowedSources: ['upload', 'url'],
                onDone: (attachments: Attachment[]) => {
                    Transforms.insertNodes(
                        editor,
                        [
                            createAttachmentBlock(
                                attachments.map(({ id, currentVersion }) => ({
                                    id,
                                    currentVersion: { filename: currentVersion.filename },
                                })) as Attachment[]
                            ),
                        ],
                        { at: insertAt, select: true }
                    );
                },
            });
        }
        reorderBlocks(editor);
        forceSave(editor);
    };

    return (
        <DropZone
            className="h-full"
            isClickable={false}
            overlay={() => <FontAwesomeIcon icon={faArrowToBottom} className="text-5xl text-gray-800" />}
            onFiles={handleFiles(editor)}
            isMultiple={true}
            isDisabled={isDisabled}
            disabledClassName={null}
        >
            {children}
        </DropZone>
    );
};

export const dropZonePlugin = ({ isDisabled }: { isDisabled: boolean }): Plugin => ({
    renderWrapper: (editor, children) => (
        <DropZoneElement editor={editor} isDisabled={isDisabled}>
            {children}
        </DropZoneElement>
    ),
});
