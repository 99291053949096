import { HttpMethod, Id } from '@wedo/types';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { tag as meetingTag, tagType as MeetingTagType } from 'Shared/services/meeting';
import { tag as meetingTopicTag } from 'Shared/services/meetingTopic';
import { trpcUtils } from 'Shared/trpc';
import { MeetingSection } from 'Shared/types/meetingSection';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag, tags } = configureTag('MeetingSection');

export const meetingSectionApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addMeetingSections: build.mutation<unknown, { meetingId: Id; sections: Partial<MeetingSection>[] }>({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/sections`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
                    return [
                        meetingTag(meetingId),
                        tag(resourceId(MeetingTagType, meetingId)),
                        meetingTopicTag(resourceId(MeetingTagType, meetingId)),
                    ];
                },
            }),
            updateMeetingSections: build.mutation<
                unknown,
                {
                    meetingId: Id;
                    sections: { id: Id; changes: Partial<MeetingSection> }[];
                }
            >({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/sections`,
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    return [tag(resourceId(MeetingTagType, meetingId))];
                },
            }),
            deleteMeetingSections: build.mutation<unknown, { meetingId: Id; applyOn: APPLY_ON; sections: Id[] }>({
                query: ({ meetingId, applyOn, ...body }) => ({
                    url: `meetings/${meetingId}/sections/delete`,
                    params: { applyOn },
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    return [
                        tag(resourceId(MeetingTagType, meetingId)),
                        meetingTopicTag(resourceId(MeetingTagType, meetingId)),
                    ];
                },
            }),
        }),
    });

export const { useAddMeetingSectionsMutation, useUpdateMeetingSectionsMutation, useDeleteMeetingSectionsMutation } =
    meetingSectionApi;

export const invalidateMeetingSections = (meetingId: Id) =>
    meetingSectionApi.util.invalidateTags([tag(resourceId(MeetingTagType, meetingId))]);
