import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { useGetCustomFieldsQuery } from 'Shared/services/customFields';
import { CustomField } from 'Shared/types/customField';

export const useWorkspaceCustomFields = (
    workspaceId: Id,
    checklistId: Id,
    predicate: (customField: CustomField) => boolean = () => true
): { customFields: CustomField[]; isLoading: boolean } => {
    const { data, isLoading } = useGetCustomFieldsQuery();

    const customFields = useMemo(
        () =>
            data == null || data.length === 0
                ? (EmptyArray as CustomField[])
                : data.filter(
                      (customField) =>
                          predicate(customField) &&
                          (customField.is_global ||
                              customField.tags?.some((tag) => tag.id === workspaceId) ||
                              customField.checklists?.some((checklist) => checklist.id === checklistId))
                  ),
        [data, workspaceId, checklistId]
    );

    return { customFields, isLoading };
};
