import React from 'react';
import { Trans } from '@lingui/macro';
import { Table, Tag } from '@wedo/design-system';
import { EmailOptionsDropdown } from 'Pages/settings/emails/UserEmailsTable/EmailOptionsDropdown';
import { useUserEmails } from 'Shared/hooks/useUserEmails';
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UserEmailsTable = () => {
    const { userEmails, isPrimaryEmail } = useUserEmails();

    return (
        <Table>
            <Table.Head>
                <Table.HeadCell>
                    <Trans>Email address</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="@lg:block hidden text-center">
                    <Trans>Primary</Trans>
                </Table.HeadCell>
                <Table.HeadCell className={'text-center'}>
                    <Trans>Verified</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="w-20" />
            </Table.Head>

            <Table.Body>
                {userEmails?.map((email) => {
                    return (
                        <Table.Row key={email.email_address}>
                            <Table.Cell>
                                <span className="font-medium">{email.email_address}</span>
                                {isPrimaryEmail(email) && (
                                    <div className="@lg:hidden mt-1 block">
                                        <Tag color="blue" dark size="xs">
                                            <Trans>Primary email</Trans>
                                        </Tag>
                                    </div>
                                )}
                            </Table.Cell>

                            <Table.Cell className="@lg:block hidden text-center">
                                {isPrimaryEmail(email) && (
                                    <Tag color="blue" dark size="sm">
                                        <div className={'@lg:block hidden'}>
                                            <Trans>Primary email</Trans>
                                        </div>
                                        <div className={'@lg:hidden block'}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    </Tag>
                                )}
                            </Table.Cell>

                            <Table.Cell className={'text-center'}>
                                {email.verified && (
                                    <Tag color="green" size="sm">
                                        <div className={'@lg:block hidden'}>
                                            <Trans>Verified</Trans>
                                        </div>
                                        <div className={'@lg:hidden block'}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    </Tag>
                                )}
                                {!email.verified && (
                                    <Tag color="gray" size="sm">
                                        <div className={'@lg:block hidden'}>
                                            <Trans>Not verified</Trans>
                                        </div>
                                        <div className={'@lg:hidden block'}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                    </Tag>
                                )}
                            </Table.Cell>

                            <Table.Cell className="text-center">
                                <EmailOptionsDropdown email={email} />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};
