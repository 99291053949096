import { faCircle, faCirclePlus, faPenCircle } from '@fortawesome/pro-regular-svg-icons';
import {
    faBook,
    faGavel,
    faSquareCheck,
    faUser,
    faUserPlus,
    faTag,
    faCircleCheck,
    faSquarePlus,
    faPlay,
    faForward,
    faClockRotateLeft,
    faFolderPlus,
    faUpload,
    faCameraWeb,
    faFilePlus,
    faParagraph,
    faLockA,
    faCheckToSlot,
    faImage,
    faPeopleGroup,
    faFileExport,
    faCalendarPlus,
    faCubes,
    faFilePdf,
    faDownload,
    faArrowDownToLine,
    faSquareArrowDown,
    faLock,
    faRepeat,
    faCircleRight,
    faStopwatch,
    faEnvelopeCircleCheck,
    faCamera,
    faEnvelope,
    faBriefcase,
    faSlashForward,
    faCode,
    faUsers,
    faPaintbrush,
    faComment,
    faPencil,
    faEye,
    faDiagramSubtask,
    faInputText,
    faSquareEnvelope,
    faRotate,
    faFireFlameCurved,
    faTableList,
    faSearch,
    faCalendarExclamation,
    faCalendars,
    faQuestion,
    faGraduationCap,
    faBaby,
    faUniformMartialArts,
    faUserAstronaut,
    faHeadSideBrain,
    faCircleDashed,
    faPersonCircleQuestion,
    faPeopleArrows,
    faChartGantt,
    faHexagonCheck,
    faVectorCircle,
    faCirclePlus as faCirclePlusSolid,
    faFill,
    faFileImport,
    faFileSignature,
    faSignature,
    faHourglass,
    faCircleArrowUpRight,
    faCirclesOverlap,
} from '@fortawesome/pro-solid-svg-icons';
import { i18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';

const legacyLevel = {
    apprentice: msg`apprentice`,
    beginner: msg`beginner`,
    expert: msg`expert`,
    hero: msg`hero`,
    master: msg`master`,
};

export const getBadgeInfo = (id: string) => {
    switch (id) {
        case 'ADD_AUTHORIZED_EMAIL_1':
            return { name: t`Add an secondary email`, icon: faEnvelope };
        case 'ADD_FILE_FOLDER_1':
            return { name: t`Create a folder`, icon: faFolderPlus };
        case 'ADD_FILE_LABEL_1':
            return { name: t`Add a label to files`, icon: faTag };
        case 'ADD_FILE_VERSION_1':
            return { name: t`Add a new version to a file`, icon: faClockRotateLeft };
        case 'ADD_MEETING_BLOCK_10':
            return { name: t`Add ${10} blocks to topics`, icon: faCubes, quantity: 10 };
        case 'ADD_MEETING_BLOCK_100':
            return { name: t`Add ${100} blocks to topics`, icon: faCubes, quantity: 100 };
        case 'ADD_MEETING_BLOCK_500':
            return { name: t`Add ${500} blocks to topics`, icon: faCubes, quantity: 500 };
        case 'ADD_MEETING_BLOCK_1000_R':
            return { name: t`Add ${1000} blocks to topics`, icon: faCubes, quantity: 1000 };
        case 'ADD_MEETING_CONFERENCE_LINK_1':
            return { name: t`Add a conference call link to a meeting`, icon: faCameraWeb };
        case 'ADD_MEETING_SIGNATORIES_1':
            return { name: t`Add signatories to a meeting`, icon: faPeopleGroup };
        case 'ADD_MEETING_TOPIC_1':
            return { name: t`Create a topic in a meeting`, icon: faSquarePlus };
        case 'ADD_MEETING_TOPIC_50':
            return { name: t`Create ${50} topics in meetings`, icon: faSquarePlus, quantity: 50 };
        case 'ADD_MEETING_TOPIC_200':
            return { name: t`Create ${200} topics in meetings`, icon: faSquarePlus, quantity: 200 };
        case 'ADD_MEETING_TOPIC_500_R':
            return { name: t`Create ${500} topics in meetings`, icon: faSquarePlus, quantity: 500 };
        case 'ADD_TOPIC_DECISION_1':
            return { name: t`Add a decision in a topic`, icon: faGavel };
        case 'ADD_TOPIC_ATTACHMENT_1':
            return { name: t`Add an attachment in a topic`, icon: faFilePlus };
        case 'ADD_TOPIC_IMAGE_1':
            return { name: t`Add an image in a topic`, icon: faImage };
        case 'ADD_TOPIC_NOTE_1':
            return { name: t`Add a private note in a topic`, icon: faLockA };
        case 'ADD_TOPIC_PARAGRAPH_1':
            return { name: t`Add a paragraph in a topic`, icon: faParagraph };
        case 'ADD_TOPIC_PRESENTER_1':
            return { name: t`Add a presenter in a topic`, icon: faUserPlus };
        case 'ADD_TOPIC_TASK_1':
            return { name: t`Add a task in a topic`, icon: faSquareCheck };
        case 'ADD_TOPIC_VOTE_1':
            return { name: t`Add a vote in a topic`, icon: faCheckToSlot };
        case 'ADD_PROFILE_JOB_TITLE_1':
            return { name: t`Add your job title`, icon: faBriefcase };
        case 'ADD_PROFILE_PICTURE_1':
            return { name: t`Add a profile picture`, icon: faCamera };
        case 'ADD_TEMPLATE_MEMBER_1':
            return { name: t`Add members to a template`, icon: faUserPlus };
        case 'ADD_TEMPLATE_TASK_1':
            return { name: t`Add tasks to a template`, icon: faSquarePlus };
        case 'ADD_USER_1':
            return { name: t`Create a user`, icon: faUserPlus, isAdminOnly: true };
        case 'ADDRESS_TOPIC_1':
            return { name: t`Address a topic`, icon: faCircleCheck };
        case 'ADDRESS_TOPIC_50':
            return { name: t`Address ${50} topics`, icon: faCircleCheck, quantity: 50 };
        case 'ADDRESS_TOPIC_200':
            return { name: t`Address ${200} topics`, icon: faCircleCheck, quantity: 200 };
        case 'ADDRESS_TOPIC_500_R':
            return { name: t`Address ${500} topics`, icon: faCircleCheck, quantity: 500 };
        case 'ADD_WORKSPACE_MEMBER_1':
            return { name: t`Add members to workspace`, icon: faUserPlus };
        case 'ASSIGN_TASK_1':
            return { name: t`Assign a task`, icon: faUser };
        case 'ASSIGN_TASK_50':
            return { name: t`Assign ${50} tasks`, icon: faUser, quantity: 50 };
        case 'ASSIGN_TASK_200':
            return { name: t`Assign ${200} tasks`, icon: faUser, quantity: 200 };
        case 'ASSIGN_TASK_500_R':
            return { name: t`Assign ${500} tasks`, icon: faUser, quantity: 500 };
        case 'COMMENT_TASK_1':
            return { name: t`Comment a task`, icon: faComment };
        case 'COMMENT_TASK_50':
            return { name: t`Comment tasks ${50} times`, icon: faComment, quantity: 50 };
        case 'COMMENT_TASK_200':
            return { name: t`Comment tasks ${200} times`, icon: faComment, quantity: 200 };
        case 'COMMENT_TASK_500_R':
            return { name: t`Comment tasks ${500} times`, icon: faComment, quantity: 500 };
        case 'COMPLETE_TASK_1':
            return { name: t`Complete a task`, icon: faSquareCheck };
        case 'COMPLETE_TASK_100':
            return { name: t`Complete ${100} tasks`, icon: faSquareCheck, quantity: 100 };
        case 'COMPLETE_TASK_500':
            return { name: t`Complete ${500} tasks`, icon: faSquareCheck, quantity: 500 };
        case 'COMPLETE_TASK_1000_R':
            return { name: t`Complete ${1000} tasks`, icon: faSquareCheck, quantity: 1000 };
        case 'COPY_TOPIC_1':
            return { name: t`Copy topic to another meeting`, icon: faFileExport };
        case 'CREATE_API_TOKEN_1':
            return { name: t`Create a personal API token`, icon: faCode };
        case 'START_CHECKLIST_1':
            return { name: t`Start a checklist`, icon: faPlay };
        case 'CREATE_FILTER_1':
            return { name: t`Save a custom view`, icon: faTableList };
        case 'CREATE_MEETING_1':
            return { name: t`Create a meeting`, icon: faCalendarPlus };
        case 'CREATE_TASK_1':
            return { name: t`Create a task`, icon: faSquarePlus };
        case 'CREATE_TASK_100':
            return { name: t`Create ${100} tasks`, icon: faSquarePlus, quantity: 100 };
        case 'CREATE_TASK_500':
            return { name: t`Create ${500} tasks`, icon: faSquarePlus, quantity: 500 };
        case 'CREATE_TASK_1000_R':
            return { name: t`Create ${1000} tasks`, icon: faSquarePlus, quantity: 1000 };
        case 'CREATE_TASK_CUSTOM_FIELD_1':
            return { name: t`Create a custom field`, icon: faInputText, isAdminOnly: true };
        case 'CREATE_TASK_MAIL_TO_TASK_1':
            return { name: t`Create a task with mail to task`, icon: faSquareEnvelope };
        case 'CREATE_TASK_SUBTASK_1':
            return { name: t`Create a subtask`, icon: faDiagramSubtask };
        case 'CREATE_TEMPLATE_1':
            return { name: t`Create a template`, icon: faCircleDashed };
        case 'CREATE_TOPIC_MAIL_TO_TOPIC_1':
            return { name: t`Create a topic with mail to topic`, icon: faSquareEnvelope };
        case 'CREATE_WORKSPACE_1':
            return { name: t`Create a Workspace`, icon: faCirclePlus };
        case 'CREATE_WORKSPACE_TASK_10':
            return { name: t`Add 10 tasks to a workspace`, icon: faCircle };
        case 'EXPORT_MEETING_PDF_1':
            return { name: t`Export a meeting in PDF`, icon: faFilePdf };
        case 'EXPORT_TASK_LIST_1':
            return { name: t`Export a task list`, icon: faFileExport };
        case 'IMPORT_PAST_TOPIC_1':
            return { name: t`Import a past topic`, icon: faDownload };
        case 'IMPORT_TOPIC_PAST_CONTENT_1':
            return { name: t`Import topic content from a previous meeting`, icon: faArrowDownToLine };
        case 'IMPORT_TOPIC_TASK_1':
            return { name: t`Import a task in a topic`, icon: faSquareArrowDown };
        case 'LEGACY_RANK_APPRENTICE':
            return {
                name: t`Apprentice`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.apprentice)}" level in the first version of WEDO`,
                icon: faGraduationCap,
            };
        case 'LEGACY_RANK_BEGINNER':
            return {
                name: t`Beginner`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.beginner)}" level in the first version of WEDO`,
                icon: faBaby,
            };
        case 'LEGACY_RANK_EXPERT':
            return {
                name: t`Expert`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.expert)}" level in the first version of WEDO`,
                icon: faHeadSideBrain,
            };
        case 'LEGACY_RANK_HERO':
            return {
                name: t`Hero`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.hero)}" level in the first version of WEDO`,
                icon: faUserAstronaut,
            };
        case 'LEGACY_RANK_MASTER':
            return {
                name: t`Master`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.master)}" level in the first version of WEDO`,
                icon: faUniformMartialArts,
            };
        case 'LEGACY_REQUEST_JOIN_WORKSPACE_1':
            return { name: t`Requested to join a workspace`, icon: faPersonCircleQuestion };
        case 'LOCK_MEETING_1':
            return { name: t`Lock a meeting`, icon: faLock };
        case 'ORGANIZE_ASSIGN_1':
            return { name: t`Quickly assign tasks`, icon: faUsers };
        case 'ORGANIZE_PLAN_1':
            return { name: t`Quickly plan your tasks`, icon: faCalendars };
        case 'ORGANIZE_PRIORITIZE_1':
            return { name: t`Quickly prioritize your tasks`, icon: faFireFlameCurved };
        case 'REPLAN_CHECKLIST_1':
            return { name: t`Replan a checklist`, icon: faForward };
        case 'REQUEST_VOTES_1':
            return { name: t`Request votes`, icon: faCheckToSlot };
        case 'REVISIT_TOPIC_1':
            return { name: t`Revisit a topic`, icon: faCircleRight };
        case 'SEARCH_TASK_1':
            return { name: t`Search for a task`, icon: faSearch };
        case 'SET_TOPIC_DURATION_1':
            return { name: t`Set a topic's duration`, icon: faStopwatch };
        case 'SHARE_MEETING_BY_EMAIL_1':
            return { name: t`Share a meeting by email`, icon: faEnvelopeCircleCheck };
        case 'SHARE_MEETING_BY_EMAIL_30':
            return { name: t`Share ${30} meetings by email`, icon: faEnvelopeCircleCheck, quantity: 30 };
        case 'SHARE_MEETING_BY_EMAIL_100':
            return { name: t`Share ${100} meetings by email`, icon: faEnvelopeCircleCheck, quantity: 100 };
        case 'SHARE_MEETING_BY_EMAIL_300_R':
            return { name: t`Share ${300} meetings by email`, icon: faEnvelopeCircleCheck, quantity: 300 };
        case 'UPDATE_TOPIC_RECURRENCE_1':
            return { name: t`Update a topic's recurrence`, icon: faRepeat };
        case 'UPDATE_TASK_DESCRIPTION_1':
            return { name: t`Add a task description`, icon: faPencil };
        case 'UPDATE_TASK_DUE_DATE_1':
            return { name: t`Set a task's due date`, icon: faCalendarExclamation };
        case 'UPDATE_TASK_RECURRENCE_1':
            return { name: t`Define a task recurrence`, icon: faRotate };
        case 'UPDATE_TASK_WATCHER_1':
            return { name: t`Add a task watcher`, icon: faEye };
        case 'UPDATE_WORKSPACE_COLOR_1':
            return { name: t`Change a workspace color`, icon: faPaintbrush };
        case 'UPDATE_WORKSPACE_STATUS_1':
            return { name: t`Update the status of a workspace`, icon: faPenCircle };
        case 'UPLOAD_COMPANY_LOGO_1':
            return { name: t`Upload your company logo`, icon: faUpload, isAdminOnly: true };
        case 'UPLOAD_WORKSPACE_FILE_1':
            return { name: t`Upload a file in a workspace`, icon: faUpload };
        case 'USE_MEETING_COMMAND_1':
            return { name: t`Use the "/" command in a topic`, icon: faSlashForward };
        case 'VIEW_ASSIGNED_TASKS_1':
            return { name: t`View tasks that you delegated`, icon: faPeopleArrows };
        case 'VIEW_USERS_PAGE_1':
            return { name: t`Display the users page`, icon: faUsers };
        case 'SUBMIT_TOPIC_1':
            return { name: t`Submit a topic to a meeting`, icon: faCircleArrowUpRight, addon: 'topicSubmission' };
        case 'SUBMIT_TOPIC_50':
            return {
                name: t`Submit ${50} topics to meetings`,
                icon: faCircleArrowUpRight,
                quantity: 50,
                addon: 'topicSubmission',
            };
        case 'SUBMIT_TOPIC_200':
            return {
                name: t`Submit ${200} topics to meetings`,
                icon: faCircleArrowUpRight,
                quantity: 200,
                addon: 'topicSubmission',
            };
        case 'SUBMIT_TOPIC_500_R':
            return {
                name: t`Submit ${500} topics to meetings`,
                icon: faCircleArrowUpRight,
                quantity: 500,
                addon: 'topicSubmission',
            };

        case 'ACCEPT_TOPIC_1':
            return { name: t`Accept a submitted topic`, icon: faHexagonCheck, addon: 'topicSubmission' };
        case 'ACCEPT_TOPIC_50':
            return {
                name: t`Accept ${50} submitted topics`,
                icon: faHexagonCheck,
                quantity: 50,
                addon: 'topicSubmission',
            };
        case 'ACCEPT_TOPIC_200':
            return {
                name: t`Accept ${200} submitted topics`,
                icon: faHexagonCheck,
                quantity: 200,
                addon: 'topicSubmission',
            };
        case 'ACCEPT_TOPIC_500_R':
            return {
                name: t`Accept ${500} submitted topics`,
                icon: faHexagonCheck,
                quantity: 500,
                addon: 'topicSubmission',
            };

        case 'VIEW_GOVERNANCE_PAGE_1':
            return { name: t`Display the governance page`, icon: faVectorCircle, addon: 'governance' };
        case 'ADD_CIRCLE_1':
            return { name: t`Add a circle`, icon: faCirclePlusSolid, addon: 'governance' };
        case 'ADD_ROLE_1':
            return { name: t`Add a role`, icon: faCirclePlus, addon: 'governance' };
        case 'ADD_MEMBER_CIRCLE_1':
            return { name: t`Add a member to a circle or role`, icon: faUserPlus, addon: 'governance' };
        case 'UPDATE_CIRCLE_COLOR_1':
            return { name: t`Update the color of a circle or role`, icon: faFill, addon: 'governance' };
        case 'IMPORT_GOVERNANCE_1':
            return { name: t`Import one or more circles and roles`, icon: faFileImport, addon: 'governance' };
        case 'EXPORT_GOVERNANCE_1':
            return { name: t`Export your circles and roles`, icon: faFileExport, addon: 'governance' };
        case 'COPY_CIRCLE_1':
            return { name: t`Copy one of your circles or roles`, icon: faCirclesOverlap, addon: 'governance' };

        case 'REQUEST_SIGNATURES_1':
            return { name: t`Request signatures once`, icon: faFileSignature, addon: 'signature' };
        case 'REQUEST_SIGNATURES_30':
            return { name: t`Request signatures ${30} times`, icon: faFileSignature, quantity: 30, addon: 'signature' };
        case 'REQUEST_SIGNATURES_100':
            return {
                name: t`Request signatures ${100} times`,
                icon: faFileSignature,
                quantity: 100,
                addon: 'signature',
            };
        case 'REQUEST_SIGNATURES_300_R':
            return {
                name: t`Request signatures ${300} times`,
                icon: faFileSignature,
                quantity: 300,
                addon: 'signature',
            };

        case 'SIGN_MEETING_1':
            return { name: t`Sign a meeting`, icon: faSignature, addon: 'signature' };
        case 'SIGN_MEETING_30':
            return { name: t`Sign ${30} meetings`, icon: faSignature, quantity: 30, addon: 'signature' };
        case 'SIGN_MEETING_100':
            return { name: t`Sign ${100} meetings`, icon: faSignature, quantity: 100, addon: 'signature' };
        case 'SIGN_MEETING_300_R':
            return { name: t`Sign ${300} meetings`, icon: faSignature, quantity: 300, addon: 'signature' };

        case 'VOTE_1':
            return { name: t`Add your answer to a vote`, icon: faCheckToSlot };
        case 'VOTE_50':
            return { name: t`Add your answer to ${50} votes`, icon: faCheckToSlot, quantity: 50 };
        case 'VOTE_200':
            return { name: t`Add your answer to ${200} votes`, icon: faCheckToSlot, quantity: 200 };
        case 'VOTE_500_R':
            return { name: t`Add your answer to ${500} votes`, icon: faCheckToSlot, quantity: 500 };

        case 'ADD_TASK_DEPENDENCY_1':
            return { name: t`Add a dependency between tasks`, icon: faHourglass, addon: 'gantt' };
        case 'VIEW_GANTT_PAGE_1':
            return {
                name: t`Display the gantt chart`,
                description: t`Display the gantt chart for one of your workspaces`,
                icon: faChartGantt,
                addon: 'gantt',
            };

        case 'VISIT_HELP_CENTER_1':
            return {
                name: t`Visit the help center`,
                description: t`Visit the help center using the in-app button`,
                icon: faBook,
            };
        default:
            return { name: t`Unknown`, icon: faQuestion };
    }
};
