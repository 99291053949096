import React, { FC, useId, useState } from 'react';
import { faCheck, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Form, Input, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { checkPasswordCompliance, LanguageCode, PasswordRule, storage } from '@wedo/utils';
import { useSamlLogin } from 'Pages/SignInPage/hooks/useSamlLogin';
import { SuccessMessage } from 'Pages/onboarding/SuccessMessage';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useInvitedOnboardingUser } from 'Pages/onboarding/hooks/useInvitedOnboardingUser';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { CheckCompliance } from 'Shared/components/CheckCompliance';
import { useEditUserMutation } from 'Shared/services/admin';
import { useLoginMutation } from 'Shared/services/auth';
import {
    useActivateUserInNetworkMutation,
    useCreateUserMutation,
    useResetOnboardingPasswordMutation,
    useUpdateOnboardingUserMutation,
} from 'Shared/services/onboarding';
import { useLazyGetOrganizationQuery } from 'Shared/services/organization';
import { useLazyGetCurrentUserQuery } from 'Shared/services/user';
import { LocalStorage } from 'Shared/types/localStorage';

export const PasswordStep: FC = () => {
    const id = useId();

    const { show } = useNotification();
    const { updateKey, actions, willContinueWithOrganizationStep, ...store } = useOnboarding();
    const { isExistingNetworkLinkedToInvitation, isNetworkActive, invitedUser, isSaml } = useInvitedOnboardingUser();
    const { goToSaml } = useSamlLogin();

    const [fetchOrganization] = useLazyGetOrganizationQuery();
    const [fetchCurrentUser] = useLazyGetCurrentUserQuery();

    const [createUser] = useCreateUserMutation();
    const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetOnboardingPasswordMutation();
    const [localLogin] = useLoginMutation();
    const [updateOnboardingUser] = useUpdateOnboardingUserMutation();
    const [sendActivateUser] = useActivateUserInNetworkMutation();
    const [editUser] = useEditUserMutation();

    const [error, setError] = useState<string>();
    const passwordComplianceList = checkPasswordCompliance(store.password);
    const isPasswordCompliant = passwordComplianceList.size === 4;
    const userId = store.userId;

    if (error === null && store.passwordConfirmation.length > 0 && store.passwordConfirmation !== store.password) {
        setError(t`The passwords don't match`);
    }

    const handlePasswordChange = (password: string) => {
        setError(null);
        updateKey({ key: 'password', value: password });
    };

    const handleRepeatedPassword = (password: string) => {
        setError(null);
        updateKey({ key: 'passwordConfirmation', value: password });
    };

    const handleCreateUser = async () => {
        const response = await createUser({
            user: {
                firstName: store.firstName,
                lastName: store.lastName,
                password: store.password,
                jobTitle: store.jobTitle,
                language: store.language,
            },
            token: store.token,
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return;
        }

        actions.setStep(store.step + 1);
    };

    const handleUpdateUser = async () => {
        const response = await updateOnboardingUser({
            user: {
                firstName: store.firstName,
                lastName: store.lastName,
                password: store.password,
                jobTitle: store.jobTitle,
                language: store.language as LanguageCode,
            },
            token: store.token,
            userId: invitedUser?.id,
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return;
        }

        await sendActivateUser({ userId: invitedUser.id, token: store.token });

        const loginResponse = await localLogin({
            email: store.email,
            password: store.password,
            rememberMe: true,
            trustedDevice: true,
            old_auth_token: storage.getItem(LocalStorage.DeviceToken),
        });

        if ('error' in loginResponse) {
            show(UnexpectedErrorNotification);
            return;
        }

        if (!isEmpty(store.jobTitle)) {
            await editUser({ id: response.data.id, title: store.jobTitle });
        }

        updateKey({ key: 'authToken', value: loginResponse?.data?.authToken });

        const organization = await fetchOrganization().unwrap();
        const currentUser = await fetchCurrentUser().unwrap();
        if ('data' in organization) {
            updateKey({ key: 'organizationName', value: organization.name });
            updateKey({ key: 'organizationSize', value: organization.cf_company_size });
            updateKey({ key: 'organizationIndustry', value: organization.cf_industry });
            updateKey({ key: 'organizationWebsite', value: organization.website });
            updateKey({ key: 'subdomain', value: currentUser?.userNetwork?.network?.short_name });
        }

        actions.setStep(3);
    };

    const handleResetPassword = async () => {
        const resetPasswordResponse = await resetPassword({
            firstLogin: true,
            password: store.password,
            token: store.token,
            withAuthLink: true,
            userData: {
                first_name: store.firstName,
                last_name: store.lastName,
                title: store.jobTitle,
                language_code: store.language as LanguageCode,
            },
            userId,
        });

        if ('error' in resetPasswordResponse) {
            show(UnexpectedErrorNotification);
            return;
        }

        updateKey({ key: 'authLink', value: resetPasswordResponse?.data?.link });
    };

    const handleNext = async () => {
        if (!isPasswordCompliant || store.passwordConfirmation !== store.password) {
            if (store.password.length === 0) {
                setError(t`The password is required`);
            } else if (store.passwordConfirmation.length === 0) {
                setError(t`The password must be confirmed.`);
            }
            return;
        }

        if (isEmpty(userId)) {
            await handleCreateUser();
        } else {
            // When onboarding from an invitation, an account has already been created.
            // So we instead use the reset route, which does a bit more than resetting only the password
            if (isExistingNetworkLinkedToInvitation && !isNetworkActive) {
                await handleUpdateUser();
            } else {
                await handleResetPassword();
            }
        }
    };

    const authenticateViaSaml = async () => {
        const activateUserResponse = await sendActivateUser({ userId: invitedUser.id, token: store.token });

        if ('error' in activateUserResponse) {
            show(UnexpectedErrorNotification);
            return;
        }

        goToSaml(store.email);
    };

    if (invitedUser && isSaml && isExistingNetworkLinkedToInvitation && isNetworkActive) {
        return (
            <div>
                <p className="mx-2 mb-6">
                    <Trans>
                        There is a saml account registered with your email:{' '}
                        <span className="whitespace-nowrap rounded border bg-gray-50 px-1 font-mono text-sm">
                            {store.email}
                        </span>
                        , so you can directly log in via your SAML configured application.
                    </Trans>
                </p>
                <div className="flex justify-between">
                    <Button icon={faChevronLeft} onClick={() => actions.setStep(store.step - 1)}>
                        <Trans>Previous</Trans>
                    </Button>
                    <Button icon={faChevronRight} iconPosition="end" color="primary" onClick={authenticateViaSaml}>
                        <Trans>Authenticate via SAML</Trans>
                    </Button>
                </div>
            </div>
        );
    }

    return isEmpty(store.authLink) ? (
        <>
            <Form onKeyDown={(e) => e.key === 'Enter' && handleNext()} layout="vertical" title={t`Choose a password`}>
                <Form.Item>
                    <Trans>You will use your email address and this password to sign in to WEDO.</Trans>
                </Form.Item>

                <Form.Item label={t`New password`} htmlFor={id + 'password'}>
                    <Input
                        disabled={!isEmpty(store.authLink)}
                        id={id + 'password'}
                        value={store.password}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        type="password"
                        status={isPasswordCompliant && isEmpty(store.authLink) ? 'success' : 'default'}
                        statusText={
                            isPasswordCompliant && isEmpty(store.authLink) ? t`Great! Your password is secure.` : ''
                        }
                    />
                    {!isPasswordCompliant && (
                        <div className="mt-4 grid grid-cols-2 gap-2">
                            <CheckCompliance
                                text={t`One lowercase character`}
                                isPassing={passwordComplianceList.has(PasswordRule.Lowercase)}
                            />
                            <CheckCompliance
                                text={t`One number`}
                                isPassing={passwordComplianceList.has(PasswordRule.Number)}
                            />
                            <CheckCompliance
                                text={t`One special character`}
                                isPassing={passwordComplianceList.has(PasswordRule.Special)}
                            />
                            <CheckCompliance
                                text={t`8 characters minimum`}
                                isPassing={passwordComplianceList.has(PasswordRule.Size)}
                            />
                        </div>
                    )}
                </Form.Item>

                <Form.Item label={t`Confirm password`} htmlFor={id + 'password-bis'}>
                    <Input
                        disabled={!isEmpty(store.authLink)}
                        id={id + 'password-bis'}
                        type="password"
                        value={store.passwordConfirmation}
                        onChange={(e) => handleRepeatedPassword(e.target.value)}
                        status={error !== null ? 'error' : 'default'}
                        statusText={error ?? ''}
                    />
                </Form.Item>
            </Form>

            <OnboardingLayout.Buttons>
                <Button icon={faChevronLeft} onClick={() => actions.setStep(store.step - 1)}>
                    <Trans>Previous</Trans>
                </Button>

                <Button
                    loading={isResetPasswordLoading}
                    disabled={!store.password || !store.passwordConfirmation || !!error}
                    icon={willContinueWithOrganizationStep ? faChevronRight : faCheck}
                    color="primary"
                    iconPosition="end"
                    onClick={handleNext}
                >
                    {willContinueWithOrganizationStep ? (
                        <Trans>Create your account and continue</Trans>
                    ) : (
                        <Trans>Create your account</Trans>
                    )}
                </Button>
            </OnboardingLayout.Buttons>
        </>
    ) : (
        <SuccessMessage message={t`Account successfully created!`} />
    );
};
