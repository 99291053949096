import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { generateVoteOptionAbstained, generateVoteOptionRequestedDiscussion } from 'Pages/meeting/components/Vote/Vote';
import {
    useCurrentUserVote,
    useMeetingVoters,
    useShouldHideOtherUsersAnswers,
} from 'Pages/meeting/components/Vote/VoteHooks';
import { VoteOptionComponent } from 'Pages/meeting/components/Vote/VoteOption';
import {
    getOptionCount,
    getVoteOptionRelativeShareFraction,
    VoteOptionMode,
} from 'Pages/meeting/components/Vote/VoteUtils';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { Vote } from 'Shared/types/vote';

interface VoteBodySingleProps {
    vote: Vote;
    voteOptionMode: VoteOptionMode;
    canVote: boolean;
    readOnly: boolean;
    isChangingAnswer: boolean;
    isRequestingDiscussion: boolean;
    onChange: (value: string) => void;
    selectedOptionId?: Id;
    meetingId: Id;
}

export const VoteBodySingle = ({
    vote,
    canVote,
    voteOptionMode,
    isChangingAnswer,
    isRequestingDiscussion,
    readOnly,
    onChange,
    meetingId,
    selectedOptionId,
}: VoteBodySingleProps) => {
    const { meeting } = useMeeting(meetingId);
    const currentUserVote = useCurrentUserVote(vote);
    const voters = useMeetingVoters(meetingId);
    const hideOtherUsersAnswers = useShouldHideOtherUsersAnswers(vote, meetingId);

    const shouldDisplayAbstainedOption = useMemo(() => {
        if (['rating', 'linear_vote'].indexOf(vote?.type) > -1) {
            if (
                currentUserVote != null &&
                !isChangingAnswer &&
                (vote?.can_abstain === true || vote?.voteAnswers?.some((a) => a.status === 'abstained'))
            ) {
                return true;
            }
            return false;
        }
        return (
            vote?.can_abstain === true ||
            vote?.voteAnswers?.some((a) => a.status === 'abstained') ||
            (voters?.length === 0 && vote?.abstained_count > 0)
        );
    }, [
        vote?.can_abstain,
        vote?.voteAnswers,
        voters,
        vote?.abstained_count,
        vote?.type,
        currentUserVote,
        isChangingAnswer,
    ]);

    const abstainedOption = useMemo(
        () => generateVoteOptionAbstained(vote, voteOptionMode, isChangingAnswer),
        [vote, voteOptionMode, isChangingAnswer]
    );

    const requestedDiscussionOption = useMemo(
        () => generateVoteOptionRequestedDiscussion(vote, voteOptionMode, isChangingAnswer),
        [vote, voteOptionMode, isChangingAnswer]
    );

    const shouldDisplayRequestDiscussionOption = useMemo(() => {
        if (voters?.length === 0) {
            return false;
        }
        if (isRequestingDiscussion) {
            return true;
        }
        if (currentUserVote?.status === 'requested_discussion') {
            return true;
        }
        if (vote?.voteAnswers?.find((a) => a.status === 'requested_discussion') != null && currentUserVote != null) {
            if (meeting?.settings?.vote?.hide_results_until_closed && vote?.status === 'open') {
                return false;
            }
            return true;
        }
        return false;
    }, [
        voters,
        vote?.status,
        vote?.voteAnswers,
        currentUserVote,
        hideOtherUsersAnswers,
        isRequestingDiscussion,
        meeting?.settings?.vote?.hide_results_until_closed,
    ]);

    return (
        <>
            {vote?.voteOptions?.map((option) => (
                <VoteOptionComponent
                    meetingId={meetingId}
                    key={`vote-option-${option.id}`}
                    mode={voteOptionMode}
                    hideUserFaces={hideOtherUsersAnswers}
                    voteOption={option}
                    isDisabled={
                        vote?.status === 'closed' ||
                        meeting?.status === 'locked' ||
                        meeting?.deleted ||
                        readOnly ||
                        !canVote ||
                        (currentUserVote && !isChangingAnswer)
                    }
                    handleClick={(e) => {
                        e.stopPropagation();
                        onChange(option.id.toString());
                    }}
                    isSelected={
                        (!vote?.outcome_vote_option_id && option.id === selectedOptionId) ||
                        (vote?.status != null && vote?.outcome_vote_option_id === option.id)
                    }
                    voteOutcome={vote?.outcome_vote_option_id}
                    voteStatus={vote?.status}
                    fraction={getVoteOptionRelativeShareFraction(option, vote, meeting)}
                    voteCount={getOptionCount({ voteOption: option, vote, meetingUsers: meeting?.meetingUsers })}
                />
            ))}
            {shouldDisplayAbstainedOption && (
                <VoteOptionComponent
                    meetingId={meetingId}
                    key={'vote-option-abstained'}
                    mode={voteOptionMode}
                    isDisabled={
                        vote?.status === 'closed' ||
                        meeting?.status === 'locked' ||
                        meeting?.deleted ||
                        readOnly ||
                        !canVote ||
                        (currentUserVote && !isChangingAnswer)
                    }
                    handleClick={(e) => {
                        e.stopPropagation();
                        onChange('abstained');
                    }}
                    hideUserFaces={hideOtherUsersAnswers}
                    voteOption={abstainedOption}
                    voteOutcome={vote?.outcome_vote_option_id}
                    voteStatus={vote?.status}
                    isSelected={selectedOptionId === 'abstained'}
                    fraction={getVoteOptionRelativeShareFraction(abstainedOption, vote, meeting)}
                    voteCount={getOptionCount({
                        voteOption: abstainedOption,
                        vote,
                        meetingUsers: meeting?.meetingUsers,
                    })}
                />
            )}
            {shouldDisplayRequestDiscussionOption && (
                <VoteOptionComponent
                    meetingId={meetingId}
                    key={'vote-option-requested-discussion'}
                    mode={voteOptionMode}
                    isDisabled={
                        vote?.status === 'closed' ||
                        meeting?.status === 'locked' ||
                        meeting?.deleted ||
                        readOnly ||
                        !canVote ||
                        (currentUserVote && !isChangingAnswer)
                    }
                    hideUserFaces={hideOtherUsersAnswers}
                    handleClick={(e) => {
                        e.stopPropagation();
                        onChange('requested_discussion');
                    }}
                    voteOption={requestedDiscussionOption}
                    voteCount={getOptionCount({
                        voteOption: requestedDiscussionOption,
                        vote,
                        meetingUsers: meeting?.meetingUsers,
                    })}
                    voteOutcome={vote?.outcome_vote_option_id}
                    voteStatus={vote?.status}
                    fraction={getVoteOptionRelativeShareFraction(requestedDiscussionOption, vote, meeting)}
                    isSelected={selectedOptionId === 'requested_discussion'}
                />
            )}
        </>
    );
};
