import React, { MutableRefObject } from 'react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Editor } from 'slate';
import { useUser } from 'App/store/usersStore';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { documentHeadingClasses } from 'Pages/meeting/components/MeetingView/MeetingDocumentView';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { SubmittedTopicDropdown } from 'Shared/components/meeting/topicView/SubmittedTopicDropdown';
import { Meeting } from 'Shared/types/meeting';
import { MeetingTopic, MeetingTopicSubmission } from 'Shared/types/meetingTopic';

export const InlineTopicSubmission = ({
    meeting,
    topic,
    topicSubmission,
    editorRef,
    canEdit,
}: {
    meeting: Meeting;
    topic: MeetingTopic;
    topicSubmission: MeetingTopicSubmission;
    editorRef: MutableRefObject<Editor>;
    canEdit: boolean;
}) => {
    const submittedBy = useUser(topicSubmission.createdBy);
    return (
        <div id={`topic-${topic.id}`}>
            <div className={clsx(documentHeadingClasses(1))}>
                <div className={'py-2'}>
                    <div className="font-semibold max-w-full truncate">{topic.title}</div>
                    <div className="text-xs font-normal">
                        {topicSubmission.sourceMeetingId != null ? (
                            <Trans>
                                Submitted by {submittedBy?.full_name} from{' '}
                                <FormatMeetingTitle
                                    title={topicSubmission.sourceMeeting.title}
                                    startAt={topicSubmission.sourceMeeting.startAt}
                                />
                            </Trans>
                        ) : (
                            <Trans>Submitted by {submittedBy?.full_name}</Trans>
                        )}
                    </div>
                </div>
                {canEdit && (
                    <SubmittedTopicDropdown topicId={topic.id} submissionId={topicSubmission.id} variant={'filled'} />
                )}
            </div>
            <div className="flex min-h-[52px]">
                <TopicEditor
                    isReadOnly
                    isStatic
                    editorRef={editorRef}
                    topic={{ ...topic, meeting }}
                    viewMode={MeetingViewMode.TopicView}
                />
            </div>
        </div>
    );
};
