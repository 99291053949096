import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Button } from '@wedo/design-system';
import { TranslatedNavBarButton } from 'Shared/components/layout/NavBar/types';

export const NavBarButton = forwardRef<HTMLButtonElement & HTMLAnchorElement, TranslatedNavBarButton>(
    ({ title, onClick, icon, className }, ref) => {
        return (
            <Button
                ref={ref}
                variant="text"
                icon={icon}
                className={clsx('text-gray-600 hover:text-gray-800', className)}
                onClick={onClick}
            >
                {title}
            </Button>
        );
    }
);
