import React from 'react';
import clsx from 'clsx';
import { Button } from '@wedo/design-system';
import { CustomFieldGroup } from 'Shared/types/customField';

type ValueLabelsProps = {
    group: CustomFieldGroup;
    onClick: (label: string) => void;
    className?: string;
};

export const ValueLabels: React.FC<ValueLabelsProps> = ({ group, className, onClick }) => {
    return (
        <div className={clsx('flex flex-wrap gap-2', className)}>
            {group.customFields.map((field) => (
                <Button key={field.id} variant="link" color="primary" onClick={() => onClick(`[${field.short_name}]`)}>
                    [{field.short_name}]
                </Button>
            ))}
        </div>
    );
};
