import { useContext } from 'react';
import { t } from '@lingui/macro';
import { useChangeStage } from 'Pages/settings/security/hooks/useChangeStage';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { useEnableTotpMutation } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

export const useEnable = (): { enable: () => Promise<void>; isLoading: boolean } => {
    const { state, dispatch } = useContext(TotpContext);
    const [postEnable, { isLoading }] = useEnableTotpMutation();
    const { next } = useChangeStage();

    const enable = async (): Promise<void> => {
        const result = await postEnable({ authenticators: state.authenticators, password: state.password });

        if ('data' in result) {
            next();
        } else if ('error' in result) {
            let error = t`Unexpected error`;

            if (errorMessage(result.error) === 'TypeError: Failed to fetch') {
                error = t`Network error`;
            }

            dispatch({ type: 'error', value: error });
        }
    };

    return { enable, isLoading };
};
