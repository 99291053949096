import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isIntersecting, waitForAllAnimations } from '@wedo/utils';
import { useGanttContextStore } from './GanttContext';
import { TimelineMeeting, type TimelineMeetingHandle } from './TimelineMeeting';
import { type Meeting } from './types';
import { useMeetings } from './useMeetings';

type TimelineMeetingsOverlayHandle = {
    update: (meetings: Array<Meeting>) => void;
};

const TimelineMeetingsOverlay = forwardRef<TimelineMeetingsOverlayHandle, void>((props, ref) => {
    const [meetings, setMeetings] = useState<Array<Array<Meeting>>>([]);

    useImperativeHandle(ref, () => ({ update: setMeetings }));

    return meetings.map((meetings) => <TimelineMeeting key={meetings[0].start_at} meetings={meetings} />);
});

export const TimelineMeetings = () => {
    const store = useGanttContextStore()!;

    const meetings = useMeetings();

    const refs = useRef<Map<string, TimelineMeetingHandle>>(new Map());

    const overlaysRef = useRef<TimelineMeetingsOverlayHandle>(null);

    const handleRef = useCallback(
        async ({ element, meeting }: TimelineMeetingHandle) => {
            if (element == null) {
                refs.current.delete(meeting.id);
            } else {
                refs.current.set(meeting.id, {
                    element: element.parentElement!.parentElement!.parentElement!,
                    meeting,
                });
            }
            if (refs.current.size === meetings?.length) {
                await waitForAllAnimations(Array.from(refs.current.values()).map(({ element }) => element));
                const meetings = Array.from(refs.current.values())
                    .reduce(
                        (groups, ref) => {
                            if (groups.length === 0) {
                                return [[ref]];
                            }
                            const [lastGroup, ...firstGroups] = groups.toReversed();
                            const rect = ref.element.getBoundingClientRect();
                            if (
                                lastGroup.some(({ element }) => isIntersecting(element.getBoundingClientRect(), rect))
                            ) {
                                return [...firstGroups.reverse(), [...lastGroup, ref]];
                            }
                            return [...groups, [ref]];
                        },
                        [] as Array<Array<TimelineMeetingHandle>>
                    )
                    .map((group) => {
                        if (group.length < 2) {
                            return null;
                        }
                        return group.map(({ meeting }) => meeting);
                    })
                    .filter((meetings) => meetings != null);
                overlaysRef.current.update(meetings.toReversed());
            }
        },
        [meetings]
    );

    useEffect(() => {
        if (meetings != null) {
            store.getState().eventBus.dispatchRenderDependenciesEvent();
        }
    }, [meetings]);

    return (
        meetings?.length > 0 && (
            <div className="h-[calc(var(--row-height)+1px)] sticky top-[calc(var(--row-height)*2+2px)] border-b border-gray-200 bg-white z-50">
                {meetings.map((meeting) => (
                    <TimelineMeeting key={meeting.id} ref={handleRef} meetings={[meeting]} />
                ))}
                <TimelineMeetingsOverlay ref={overlaysRef} />
            </div>
        )
    );
};
