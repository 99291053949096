import React from 'react';

export const CalendarHelpLink = ({
    title,
    logo,
    alt,
    href,
}: {
    title: string;
    logo: string;
    alt: string;
    href: string;
}) => (
    <a
        href={href}
        className="flex flex-row items-center gap-4 rounded-md border border-gray-200 bg-gray-100 p-4 shadow-md hover:bg-gray-200"
        target="_blank"
        rel="noreferrer"
    >
        <img className="h-8" alt={alt} src={logo} />
        <span className="text-lg font-bold text-gray-800">{title}</span>
    </a>
);
