import React, { forwardRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Modal } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useVerifyEmailMutation } from 'Shared/services/user-email';
import { RtkQueryTransformedError } from 'Shared/types/rtkQuery';
import { UserEmail } from 'Shared/types/user-email';
import { isSuccess } from 'Shared/utils/rtkQuery';
import { VerificationCodeInput } from '../VerificationCodeInput';

type AddNewEmailVeryBodyProps = { userEmail?: UserEmail; close: () => void };

export const VerifyEmailModalBody = forwardRef<HTMLInputElement, AddNewEmailVeryBodyProps>(
    ({ userEmail, close }, ref) => {
        const [verifyUserEmail, { isLoading, error }] = useVerifyEmailMutation();
        const [verificationCode, setVerificationCode] = useState<string>(EmptyString);

        const handleVerifyUserEmail = async () => {
            if (await isSuccess(verifyUserEmail({ userEmailId: userEmail.id, verificationCode }))) {
                await close();
            }
        };

        return (
            <>
                <Modal.Body>
                    <VerificationCodeInput
                        ref={ref}
                        emailAddress={userEmail?.email_address}
                        verificationCode={verificationCode}
                        onVerificationCodeChange={(verificationCode) => setVerificationCode(verificationCode)}
                        error={error as RtkQueryTransformedError}
                        onPressEnter={verificationCode !== '' && handleVerifyUserEmail}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={close} disabled={isLoading}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleVerifyUserEmail}
                        disabled={verificationCode === ''}
                        loading={isLoading}
                    >
                        <Trans>Verify</Trans>
                    </Button>
                </Modal.Footer>
            </>
        );
    }
);
