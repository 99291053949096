import React from 'react';
import { t } from '@lingui/macro';
import { useCurrentUserContext } from 'App/contexts';
import { CalendarHelpLink } from './CalendarHelpLink';

const outlookLinks = {
    en: 'https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379',
    fr: 'https://support.microsoft.com/fr-fr/office/importer-des-calendriers-dans-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379',
    de: 'https://support.microsoft.com/de-de/office/kalender-nach-outlook-importieren-8e8364e1-400e-4c0f-a573-fe76b5a2d379',
};

export const OutlookCalendarHelpLink = () => {
    const { userLanguage } = useCurrentUserContext();
    const outlookHelpLink = outlookLinks[userLanguage] ?? outlookLinks.en;

    return (
        <CalendarHelpLink title={'Outlook'} href={outlookHelpLink} logo={'/assets/outlook.svg'} alt={t`Outlook logo`} />
    );
};
