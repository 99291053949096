import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Card, Label } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { Can } from 'Shared/components/Can';
import { EmailToTaskAlert } from 'Shared/components/settings/EmailToTaskAlert';
import { UserEmailToTask } from 'Shared/components/user/UserEmailToTask';
import { Permission } from 'Shared/utils/rbac';

export const EmailToTaskSettingsPage = () => {
    const { currentUser } = useCurrentUserContext();

    return (
        <Can permission={Permission.AccessAllSettings} showNoAccess>
            <Card>
                <Card.Header
                    title={t`Email to task`}
                    description={<Trans>Assign tasks to yourself by sending emails to your address</Trans>}
                />
                <Card.Body>
                    <Label>
                        <Trans>Your email address</Trans>
                    </Label>
                    <UserEmailToTask user={currentUser} />
                    <EmailToTaskAlert />
                </Card.Body>
            </Card>
        </Can>
    );
};
