import { FC } from 'react';
import { ActionCell } from 'Pages/settings/users/components/UserTable/ActionCell';
import { AutomaticDeletionCell } from 'Pages/settings/users/components/UserTable/AutomaticDeletionCell';
import { EmailCell } from 'Pages/settings/users/components/UserTable/EmailCell';
import { InvitationPendingCell } from 'Pages/settings/users/components/UserTable/InvitationPendingCell';
import { InvitationSentCell } from 'Pages/settings/users/components/UserTable/InvitationSentCell';
import { NameCell } from 'Pages/settings/users/components/UserTable/NameCell';
import { PictureCell } from 'Pages/settings/users/components/UserTable/PictureCell';
import { RequestedActionCell } from 'Pages/settings/users/components/UserTable/RequestedActionCell';
import { RoleCell } from 'Pages/settings/users/components/UserTable/RoleCell';
import { GetUsersArg } from 'Shared/services/admin';
import { User } from 'Shared/types/user';

export type ColumnDefinition = { name: string; sortColumn: string; className: string; component: FC<User> };

type ColumnType =
    | 'picture'
    | 'name'
    | 'email'
    | 'role'
    | 'invitationInvited'
    | 'invitationPending'
    | 'requestedAction'
    | 'automaticDeletion'
    | 'action';

const columnDefinition: Map<ColumnType, ColumnDefinition> = new Map([
    ['picture', { name: '', sortColumn: '', className: 'hidden sm:table-cell w-14', component: PictureCell }],
    ['name', { name: 'NAME', sortColumn: 'user.first_name', className: '', component: NameCell }],
    ['email', { name: 'EMAIL', sortColumn: '', className: 'hidden xl:table-cell', component: EmailCell }],
    ['role', { name: 'TYPE', sortColumn: 'role', className: 'hidden md:table-cell', component: RoleCell }],
    ['invitationInvited', { name: 'INVITATION', sortColumn: '', className: '', component: InvitationSentCell }],
    ['invitationPending', { name: 'INVITATION', sortColumn: '', className: '', component: InvitationPendingCell }],
    ['requestedAction', { name: '', sortColumn: '', className: '', component: RequestedActionCell }],
    [
        'automaticDeletion',
        { name: 'AUTOMATIC DELETION', sortColumn: '', className: '', component: AutomaticDeletionCell },
    ],
    ['action', { name: '', sortColumn: '', className: 'w-14 text-end', component: ActionCell }],
]);

export const TABLE_DEFINITION: Map<GetUsersArg['status'], ColumnDefinition[]> = new Map([
    [
        'active',
        [
            columnDefinition.get('picture'),
            columnDefinition.get('name'),
            columnDefinition.get('email'),
            columnDefinition.get('role'),
            columnDefinition.get('action'),
        ],
    ],
    [
        'invited',
        [
            columnDefinition.get('picture'),
            columnDefinition.get('name'),
            columnDefinition.get('email'),
            columnDefinition.get('role'),
            columnDefinition.get('invitationInvited'),
            columnDefinition.get('action'),
        ],
    ],
    [
        'pending',
        [
            columnDefinition.get('picture'),
            columnDefinition.get('name'),
            columnDefinition.get('email'),
            columnDefinition.get('role'),
            columnDefinition.get('invitationPending'),
            columnDefinition.get('action'),
        ],
    ],
    [
        'requested',
        [
            columnDefinition.get('picture'),
            columnDefinition.get('name'),
            columnDefinition.get('email'),
            columnDefinition.get('role'),
            columnDefinition.get('requestedAction'),
            columnDefinition.get('action'),
        ],
    ],
    [
        'disabled',
        [
            columnDefinition.get('picture'),
            columnDefinition.get('name'),
            columnDefinition.get('email'),
            columnDefinition.get('role'),
            columnDefinition.get('automaticDeletion'),
            columnDefinition.get('action'),
        ],
    ],
]);
