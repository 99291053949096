import { FC } from 'react';
import { AvatarSizes, Tag } from '@wedo/design-system';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { User } from 'Shared/types/user';

type UserTagProps = {
    user: User;
    userAvatarSize?: AvatarSizes;
};

export const UserTag: FC<UserTagProps> = ({ user, userAvatarSize = 'xs' }) => {
    return (
        <Tag color="gray" title={user?.full_name}>
            <div className="flex gap-1 items-center">
                <UserAvatar showTooltip={false} user={user} size={userAvatarSize} />
                <div className="truncate">{user?.full_name}</div>
            </div>
        </Tag>
    );
};
