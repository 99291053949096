import React, { ReactNode } from 'react';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';

interface Badge {
    id: Id;
    label: ReactNode;
    selected: boolean;
}

interface BadgeSelectorProps {
    badges: Array<Badge>;
    onClick: (index: number, badge: Badge) => void;
    className?: string;
}

export const BadgeSelector = ({ badges, onClick, className }: BadgeSelectorProps): JSX.Element => {
    return (
        <div className={className}>
            {badges &&
                badges.map((badge, index) => (
                    <Button
                        key={badge.id}
                        role="checkbox"
                        active={badge.selected}
                        aria-checked={badge.selected}
                        className="w-[2.2rem] justify-center"
                        onClick={() => onClick(index, badge)}
                    >
                        {badge.label}
                    </Button>
                ))}
        </div>
    );
};
