import { t } from '@lingui/macro';
import { UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useMeetingAccessStore } from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessStore';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    useAddMeetingUserItemsMutation,
    useDeleteMeetingUserItemsMutation,
    useUpdateMeetingUserItemsMutation,
    useUpdateMeetingUsersMutation,
} from 'Shared/services/meetingUser';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingUserItem } from 'Shared/types/meetingUserItem';

export const useSaveMeetingAccess = ({ meetingId }: { meetingId: Id }) => {
    const { show: showNotification } = useNotification();
    const { meeting } = useMeeting(meetingId);
    const { meetingUsers, meetingUserItems } = useMeetingAccessStore();

    const [updateMeetingUsers] = useUpdateMeetingUsersMutation();
    const [addMeetingUserItems] = useAddMeetingUserItemsMutation();
    const [updateMeetingUserItems] = useUpdateMeetingUserItemsMutation();
    const [deleteMeetingUserItems] = useDeleteMeetingUserItemsMutation();

    const handleSaveMeetingUsers = async (applyOn: APPLY_ON) => {
        const addedObjects: Partial<MeetingUser[]> = [];
        const changedObjects: { id: Id; changes: Partial<MeetingUser> }[] = [];
        const removedObjects = meeting.meetingUsers
            .filter((mu) => !meetingUsers.find((mu2) => mu2.id === mu.id))
            .map((mu) => mu.id);
        meetingUsers.forEach((item) => {
            if (item.added) {
                addedObjects.push(item);
            } else if (item.changed) {
                const current = meeting?.meetingUsers.find((mu) => mu.user_id === item.user_id);
                const changes = {
                    ...(item.meeting_role_id &&
                        item.meeting_role_id !== current.meeting_role_id && {
                            meeting_role_id: item.meeting_role_id,
                        }),
                    ...(item.signature != null &&
                        item.signature !== current.signature && { signature: item.signature }),
                    ...(item.can_vote != null && item.can_vote !== current.can_vote && { can_vote: item.can_vote }),
                    ...(item.shares != null && item.shares !== current.shares && { shares: item.shares }),
                    ...(item.is_attendee != null &&
                        item.is_attendee !== current.is_attendee && { is_attendee: item.is_attendee }),
                    ...(item.is_attendee != null &&
                        item.is_attendee !== current.is_attendee && {
                            attendance: item.is_attendee ? 'present' : null,
                        }),
                };
                if (Object.keys(changes).length > 0) {
                    changedObjects.push({
                        id: item.id,
                        changes: changes,
                    });
                }
            }
        });
        const changes = { addedMeetingUsers: [], updatedMeetingUsers: [], deletedMeetingUsers: [] };
        if (addedObjects.length > 0) {
            changes.addedMeetingUsers = addedObjects;
        }
        if (changedObjects.length > 0) {
            changes.updatedMeetingUsers = changedObjects;
        }
        if (removedObjects.length > 0) {
            changes.deletedMeetingUsers = removedObjects;
        }
        try {
            const res = await updateMeetingUsers({ meetingId, changes, applyOn });
            if ('error' in res && res?.error?.data?.errors?.length > 0) {
                if (
                    res?.error?.data?.errors[0].message ===
                    'There needs to be at least one meetingUser with the Editor role in a meeting'
                ) {
                    showNotification({
                        type: 'danger',
                        title: t`An error occurred when updating meeting users`,
                        message: t`We couldn't update the users list because one or more meetings would be left without an editor.`,
                    });
                } else {
                    showNotification(UnexpectedErrorNotification);
                }
            }
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const handleSaveMeetingUserItems = async (applyOn: APPLY_ON) => {
        const addedObjects: Partial<MeetingUserItem[]> = [];
        const changedObjects: { id: Id; changes: Partial<MeetingUserItem> }[] = [];
        const removedObjects: Id[] = [];

        meetingUserItems.forEach((item) => {
            if (item.added) {
                addedObjects.push(item);
            } else if (item.changed) {
                changedObjects.push({
                    id: item.id,
                    changes: {
                        meeting_role_id: item.meeting_role_id,
                    },
                });
            }
        });
        meeting?.meetingUsers?.forEach((meetingUser) => {
            meetingUser.items?.forEach((meetingUserItem) => {
                if (!meetingUserItems.some(({ id }) => id && id === meetingUserItem.id)) {
                    removedObjects.push(meetingUserItem.id);
                }
            });
        });

        if (removedObjects.length > 0) {
            await deleteMeetingUserItems({
                meetingId: meetingId,
                meetingUserItems: removedObjects,
                applyOn: applyOn,
            }).unwrap();
        }
        if (addedObjects.length > 0) {
            await addMeetingUserItems({
                meetingId: meetingId,
                meetingUserItems: addedObjects,
                applyOn: applyOn,
            }).unwrap();
        }
        if (changedObjects.length > 0) {
            await updateMeetingUserItems({
                meetingId: meetingId,
                meetingUserItems: changedObjects,
                applyOn: applyOn,
            }).unwrap();
        }
    };

    return { handleSaveMeetingUsers, handleSaveMeetingUserItems };
};
