import { FC } from 'react';
import { i18n, MessageDescriptor } from '@lingui/core';
import { msg, t, Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { Button, Select } from '@wedo/design-system';

export type TypeFilter = 'paragraph' | 'decision' | 'task' | 'attachment' | 'vote';

const typeFilterOptions: Array<{ id: TypeFilter; label: MessageDescriptor }> = [
    { id: 'paragraph', label: msg`Paragraph` },
    { id: 'decision', label: msg`Decision` },
    { id: 'task', label: msg`Task` },
    { id: 'attachment', label: msg`File` },
    { id: 'vote', label: msg`Vote` },
];

const allOptions = new Set<TypeFilter>(['paragraph', 'decision', 'task', 'attachment', 'vote']);

type TypeFilterSelectProps = {
    value: Array<TypeFilter>;
    onChange: (value: Array<TypeFilter>) => void;
};

const getTranslatedTypeFilterOption = (typeFilterOption: string | number): string => {
    const label = typeFilterOptions.filter(({ id }) => id === typeFilterOption)?.[0]?.label;
    if (label != null) {
        return i18n._(label);
    }
    return '';
};

export const TypeFilterSelect: FC<TypeFilterSelectProps> = ({ value, onChange }) => {
    const currentOptions = new Set(value);

    const handleAll = () => {
        if (isEqual(currentOptions, allOptions)) {
            return;
        }
        onChange(Array.from(allOptions));
    };

    const handleToggle = () => {
        const result: TypeFilter[] = [];
        for (const option of allOptions) {
            if (!currentOptions.has(option)) {
                result.push(option);
            }
        }
        onChange(result);
    };

    return (
        <div className="w-48">
            <Select
                multiple
                value={value}
                onChange={onChange}
                placeholder={t`Filter by type`}
                customRenderSelected={(values) => {
                    if (values.length === typeFilterOptions.length) return <Trans id="All types">All</Trans>;
                    return values.map((v) => getTranslatedTypeFilterOption(v)).join(', ');
                }}
            >
                <div className="sticky top-1 z-40 bg-white">
                    <div className="mb-2 mt-1 flex justify-center gap-2">
                        <Button variant="text" onClick={handleAll} disabled={isEqual(currentOptions, allOptions)}>
                            <Trans id="All types">All</Trans>
                        </Button>
                        <Button variant="text" onClick={handleToggle}>
                            <Trans>Toggle</Trans>
                        </Button>
                    </div>
                    <hr />
                </div>

                {typeFilterOptions.map((option) => (
                    <Select.Option value={option.id} key={option.id}>
                        <span>{i18n._(option.label)}</span>
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
