import React, { FC, PropsWithChildren, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Steps } from '@wedo/design-system';
import { NumberRange, Id } from '@wedo/types';
import { EmptyString, isEmpty } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { TemplateInfoModalBody } from 'Pages/TemplatesPage/components/AddTemplateModal/TemplateInfoModalBody';
import { ManageTemplateMembersModalBody } from 'Shared/components/template/TemplateManageMembers/ManageTemplateMembersModalBody';
import { useCreateTemplateMutation } from 'Shared/services/template';
import { ApiError } from 'Shared/types/apiError';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';

type AddTemplateModalProps = { teamId?: Id } & ContextModalProps;

export const AddTemplateModal: FC<PropsWithChildren & AddTemplateModalProps> = ({
    teamId,
    children,
    ...modalProps
}) => {
    const navigate = useNavigate();

    const nameInput = useRef<HTMLInputElement>();

    const [createTemplate, { isLoading, error }] = useCreateTemplateMutation();

    const [step, setStep] = useState<NumberRange<1, 3>>(1);
    const [name, setName] = useState<string>(EmptyString);
    const [description, setDescription] = useState<string>(EmptyString);
    const [team, setTeam] = useState<Partial<Team>>({ id: teamId });
    const [template, setTemplate] = useState<Template>();

    const isNameEmpty = isEmpty(name);

    const handleSave = async () => {
        if (isNameEmpty) {
            nameInput?.current?.focus();
            return;
        }

        const response = await createTemplate({
            description,
            name,
            team_id: team?.id as string,
        });

        if ('data' in response) {
            setTemplate(response.data);
            setStep(2);
        }
    };

    const handleFinish = () => {
        void modalProps.close();
        navigate(`/templates/${template.id}/tasks`);
    };

    return (
        <Modal {...modalProps} initialFocus={nameInput} size="lg">
            <Modal.Header title={t`Add template`} />

            <Steps className="rounded-none" currentIndex={step}>
                <Steps.Step index={1}>
                    <Trans>Template info</Trans>
                </Steps.Step>

                <Steps.Step index={2}>
                    <Trans>Members</Trans>
                </Steps.Step>
            </Steps>

            {step === 1 && (
                <TemplateInfoModalBody
                    name={name}
                    onNameChange={setName}
                    team={team}
                    onTeamChange={setTeam}
                    description={EmptyString}
                    onDescriptionChange={setDescription}
                    nameInputRef={nameInput}
                    onNext={handleSave}
                    nameError={error as ApiError}
                />
            )}

            {step === 2 && <ManageTemplateMembersModalBody templateId={template?.id} />}

            {step === 1 && (
                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button color="primary" onClick={handleSave} loading={isLoading}>
                        <Trans>Save</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {step === 2 && (
                <Modal.Footer>
                    <Button color="primary" onClick={handleFinish}>
                        <Trans>Finish</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {children}
        </Modal>
    );
};
