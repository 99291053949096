import React from 'react';
import { useCurrentUserContext } from 'App/contexts';
import { UserItemButton } from 'Shared/components/user/UserPicker/UserItem';
import { User } from 'Shared/types/user';

const classes = {
    header: 'mb-1 mt-2 text-xs font-bold text-gray-800',
};

type UserItemGroupProps = {
    title: string;
    showMe?: boolean;
    users: User[];
    onUserSelected: (user: User) => void;
};
export const UserItemGroup = ({ title, showMe, users, onUserSelected }: UserItemGroupProps) => {
    const { currentUser } = useCurrentUserContext();

    return (
        <div>
            <div className={classes.header}>{title}</div>
            <div className="flex flex-row gap-1">
                {showMe && <UserItemButton user={currentUser} onUserSelected={onUserSelected} />}
                {users?.map((user, index) => (
                    <UserItemButton key={index} user={user} onUserSelected={onUserSelected} />
                ))}
            </div>
        </div>
    );
};
