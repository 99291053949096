import { useLingui } from '@lingui/react';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { DatePicker, Input, ItemGroup, Label, Popover, RadioGroup } from '@wedo/design-system';
import { formatDate } from '@wedo/utils';
import { FormRecurrence } from 'Shared/types/formRecurrence';

export type RecurrenceEndsOnInputProps = {
    state: FormRecurrence;
    handleValueChange?: (key: string, value: any) => void;
};
export const RecurrenceEndsOnInput = ({ state, handleValueChange }: RecurrenceEndsOnInputProps): JSX.Element => {
    const { i18n } = useLingui();

    const today = new Date();

    const changeDateAndClosePopover = (key: string, value: any, close: () => void) => {
        handleValueChange(key, value);
        close();
    };

    return (
        <>
            <Label className={'col-span-2 self-start text-end'} inputType={'inline'}>{t`Ends`}</Label>
            <RadioGroup
                className="col-span-6"
                onChange={(value) => handleValueChange('ends', value)}
                value={state.ends}
                name="ends"
            >
                <RadioGroup.Radio
                    value={'neverEnds'}
                    id={'neverEnds'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center'}
                >
                    {t`never ends`}
                </RadioGroup.Radio>
                <RadioGroup.Radio
                    value={'endsAfter'}
                    id={'endsAfter'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center gap-2'}
                >
                    {t`ends after`}
                    <ItemGroup>
                        <Input
                            className={'w-16'}
                            inputClassName={'w-full'}
                            type={'number'}
                            value={state.count}
                            disabled={state.ends !== 'endsAfter'}
                            name="count"
                            onChange={(e) => handleValueChange('count', e.target.value)}
                            min={1}
                            max={999}
                        />
                        <Input.Addon text={state.count === 1 ? t`occurrence` : t`occurrences`} />
                    </ItemGroup>
                </RadioGroup.Radio>
                <RadioGroup.Radio
                    value={'endsOnDate'}
                    id={'endsOnDate'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center gap-2'}
                >
                    {t`ends on date`}
                    <Popover
                        className={'w-44 justify-between'}
                        iconPosition={'end'}
                        disabled={state.ends !== 'endsOnDate'}
                        icon={faCalendar}
                        text={state.until ? formatDate(state.until, 'shortDate', i18n) : t`Select end date`}
                    >
                        {({ close }) => (
                            <div
                                tabIndex={-1}
                                className={'flex flex-col items-center justify-center gap-2 bg-white p-3 outline-0'}
                            >
                                <DatePicker
                                    onChange={(value) => changeDateAndClosePopover('until', value, close)}
                                    date={state.until ? new Date(state.until) : today}
                                />
                            </div>
                        )}
                    </Popover>
                </RadioGroup.Radio>
            </RadioGroup>
        </>
    );
};
