import React, { ReactElement } from 'react';
import { Trans } from '@lingui/macro';
import { Label } from '@wedo/design-system';
import { AvailabilityItemRadio } from 'Pages/settings/customFields/components/shared/AvailabilityItemRadio';

type IsCustomFieldGlobalRadioProps = {
    availability: AvailabilityOption;
    onClick: (availability: AvailabilityOption) => void;
    title: string;
};

export type AvailabilityOption = {
    id: 'all-tasks' | 'choose-workspace';
    title: ReactElement;
    isGlobal: boolean;
};

export const availableOnAllTasks: AvailabilityOption = {
    id: 'all-tasks',
    title: <Trans>Yes, it must be available on all tasks</Trans>,
    isGlobal: true,
};

export const availableOnSelectedWorkspaces: AvailabilityOption = {
    id: 'choose-workspace',
    title: <Trans>No, I want to choose in which workspaces and checklists it's available</Trans>,
    isGlobal: false,
};

export const IsCustomFieldGlobalRadio: React.FC<IsCustomFieldGlobalRadioProps> = ({ title, availability, onClick }) => {
    return (
        <>
            <Label className="mt-10">{title}</Label>

            <fieldset className="mt-4">
                <div className="space-y-2">
                    <AvailabilityItemRadio
                        option={availableOnAllTasks}
                        isChecked={availability.id === availableOnAllTasks.id}
                        onClick={onClick}
                    />
                    <AvailabilityItemRadio
                        option={availableOnSelectedWorkspaces}
                        isChecked={availability.id === availableOnSelectedWorkspaces.id}
                        onClick={onClick}
                    />
                </div>
            </fieldset>
        </>
    );
};
