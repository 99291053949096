import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import { SignatureRequest } from '@wedo/db';
import { UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Meeting } from 'Shared/types/meeting';
import { User } from 'Shared/types/user';

type SendParam = {
    meeting: Meeting;
    signatureRequest: SignatureRequest;
    user?: User;
};

export const useSignatureReminder = () => {
    const { i18n } = useLingui();
    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();
    const { mutateAsync: sendReminder, isLoading } = trpc.signature.sendReminder.useMutation();

    const send = async ({ meeting, signatureRequest, user = null }: SendParam) => {
        const result = await confirm({
            title: t`Send a reminder`,
            type: 'primary',
            content:
                user == null
                    ? t`An email will be sent to all signatories who haven't yet responded.`
                    : t`An email will be sent to ${user.full_name}`,
        });

        if (result !== true) {
            return;
        }

        try {
            await sendReminder({
                signatureRequestId: signatureRequest?.id,
                subject: t`Reminder for ${formatMeetingTitle(meeting, i18n)}`,
                message: t`Hi,
                    A document is waiting for your signature on WEDO.`,
                ...(user != null && { userId: Number(user.id) }),
            });

            void trpcUtils().signature.invalidate();

            showNotification({
                type: 'success',
                title: t`Reminders successfully sent!`,
            });
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    return { send, isLoading };
};
