import React, { FC } from 'react';
import { Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { CalendarSync } from 'Shared/components/calendar/CalendarSync/CalendarSync';

export const CalendarSyncPanel: FC<{ workspaceId: Id }> = ({ workspaceId }) => {
    return (
        <Tabs.Panel>
            <CalendarSync workspaceId={workspaceId} />
        </Tabs.Panel>
    );
};
