import { useRef } from 'react';
import { useSelectedTopicId } from 'Pages/meeting/components/MeetingView/MeetingView';
import { MeetingCardContainerContext } from 'Shared/components/meeting/MeetingCardContainerContext';
import { TopicCard } from 'Shared/components/meeting/topicView/TopicCard';
import { MeetingEditAttendees } from '../MeetingEditAttendees/MeetingEditAttendees';

export const MeetingCardView = (): JSX.Element => {
    const selectedTopicId = useSelectedTopicId();
    const ref = useRef();

    return (
        <div className="mx-auto max-w-[1058px] bg-white" ref={ref}>
            <MeetingCardContainerContext.Provider value={ref}>
                {selectedTopicId ? <TopicCard topicId={selectedTopicId} /> : <MeetingEditAttendees />}
            </MeetingCardContainerContext.Provider>
        </div>
    );
};
