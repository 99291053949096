import { FC, useRef, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Button,
    ColorPickerPopover,
    ContextModalProps,
    Input,
    ItemGroup,
    Label,
    Modal,
    useNotification,
    UnexpectedErrorNotification,
    getRandomWedoColor,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString, onEnter } from '@wedo/utils';
import { useSearchParams, useInputState } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useWorkspaceSections } from 'Shared/hooks/useWorkspaceSections';
import { useAddSectionMutation, useGetWorkspaceQuery } from 'Shared/services/workspace';

type AddWorkspaceSectionModalProps = { workspaceId: Id } & ContextModalProps;
export const AddWorkspaceSectionModal: FC<AddWorkspaceSectionModalProps> = ({ workspaceId, ...modalProps }) => {
    const { show } = useNotification();

    const { data: workspace } = useGetWorkspaceQuery(workspaceId);
    const { maxOrder, sectionNames } = useWorkspaceSections(workspace);

    const [addWorkspaceSection, { isLoading: isAddWorkspaceSectionLoading }] = useAddSectionMutation();
    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);

    const inputRef = useRef<HTMLInputElement>();

    const [name, , handleNameChange] = useInputState(EmptyString);
    const [color, setColor] = useState<string>(getRandomWedoColor());

    const currentNameAlreadyUsed = sectionNames.has(name.trim().toLowerCase());
    const isAddButtonDisabled = currentNameAlreadyUsed || isEmpty(name.trim());

    const handleAddSection = async () => {
        const response = await addWorkspaceSection({ workspaceId, name, order: maxOrder + 1, color });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            setSearchParams({ ...searchParams, grouping: 'section' });
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} initialFocus={inputRef}>
            <Modal.Header title={t`Add section in ${workspace?.name} workspace`} />

            <Modal.Body>
                <Label>
                    <Trans>Section name</Trans>
                </Label>
                <ItemGroup
                    statusText={
                        currentNameAlreadyUsed && t`This section name is already in use, please choose a different name`
                    }
                    status={currentNameAlreadyUsed ? 'error' : 'default'}
                >
                    <ColorPickerPopover color={color} onChange={setColor} showSelectedColor />
                    <Input
                        ref={inputRef}
                        value={name}
                        onChange={handleNameChange}
                        onKeyDownCapture={!isAddButtonDisabled ? onEnter(handleAddSection) : undefined}
                        className="w-full"
                    />
                </ItemGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    color="primary"
                    icon={faPlus}
                    disabled={isAddButtonDisabled}
                    onClick={handleAddSection}
                    loading={isAddWorkspaceSectionLoading}
                >
                    <Trans>Add</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
