import React, { useState } from 'react';
import { ContextModalProps, Modal } from '@wedo/design-system';
import { CustomFieldSelectionSection } from 'Pages/settings/customFields/components/AddCustomFieldModal/CustomFieldSelectionSection';
import { AttachmentModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/AttachmentModalForm';
import { DateModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/DateModalForm';
import { DropdownModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/DropdownModalForm';
import { MultipleChoiceModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/MultipleChoiceModalForm';
import { NumberModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/NumberModalForm';
import { ShortTextModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/ShortTextModalForm';
import { CustomFieldType } from 'Shared/types/customField';

export type AddCustomFieldModalProps = { groupId?: string } & ContextModalProps;

export const AddCustomFieldModal: React.FC<AddCustomFieldModalProps> = ({ groupId, ...modalProps }) => {
    const [section, setSection] = useState<CustomFieldType | 'start'>('start');

    const closeModal = () => modalProps.close('cross');

    return (
        <Modal {...modalProps}>
            {section === 'start' && <CustomFieldSelectionSection onClick={setSection} />}

            {section === CustomFieldType.ShortText && (
                <ShortTextModalForm onCancel={closeModal} onSuccess={closeModal} groupId={groupId} />
            )}

            {section === CustomFieldType.Number && (
                <NumberModalForm onCancel={closeModal} onSuccess={closeModal} groupId={groupId} />
            )}

            {section === CustomFieldType.Date && (
                <DateModalForm onCancel={closeModal} onSuccess={closeModal} groupId={groupId} />
            )}

            {section === CustomFieldType.Attachment && (
                <AttachmentModalForm onCancel={closeModal} onSuccess={closeModal} groupId={groupId} />
            )}

            {section === CustomFieldType.MultipleChoice && (
                <MultipleChoiceModalForm onCancel={closeModal} onSuccess={closeModal} groupId={groupId} />
            )}

            {section === CustomFieldType.Dropdown && (
                <DropdownModalForm onCancel={closeModal} onSuccess={closeModal} groupId={groupId} />
            )}
        </Modal>
    );
};
