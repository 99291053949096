import { FC, PropsWithChildren } from 'react';
import { Table } from '@wedo/design-system';

type BulkEditTableRowProps = { label: string };

export const BulkEditTableRow: FC<PropsWithChildren<BulkEditTableRowProps>> = ({ children, label }) => {
    return (
        <Table.Row>
            <Table.Cell>{label}</Table.Cell>
            <Table.Cell>{children}</Table.Cell>
        </Table.Row>
    );
};
