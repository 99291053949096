import { HttpMethod, Id, WithRequired } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { CacheTag } from 'Shared/services/cacheTag';
import { checklistTag } from 'Shared/services/checklist';
import { taskWorkspaceTag } from 'Shared/services/taskWorkspace';
import { teamTags } from 'Shared/services/team';
import { userTags } from 'Shared/services/user';
import { workspaceTag, workspaceTags } from 'Shared/services/workspace';
import { ActivityLog } from 'Shared/types/activityLog';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { SearchType } from 'Shared/types/search';
import { Task, TaskFilter, TaskOrder, TasksExport, TaskStatus, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { baseApi, configureTag, Identified, listId, resourceId } from './base';
import { tag as taskWatcherTag } from './taskWatcher';

export const { tagType: taskTagType, tag, tags } = configureTag(CacheTag.TASK);

export const taskActivitiesTag = (id: Id) => tag(`TASK-ACTIVITIES-${id}`);

const tagTasksNotInMeetingTag = (tagId: Id, meetingId: Id) =>
    meetingId && tag(`TAG-${tagId}-NOT-IN-MEETING-${meetingId}`);

export const TaskSearchCountTag = tag(`task-search-count-tag`);

interface GetTasksQueryParams {
    tags?: Id[];
    filter?: string;
    filters?: TaskFilter[];
    status?: string;
    order?: string;
    tag_id?: Id;
    meeting_id?: Id;
    search?: string;
    searchType?: string;
    filterId?: Id;
    users?: Id[];
    checklist_id?: Id;
    checklist_template_id?: Id;
    pageSize?: number;
    page: number;
    only_count?: boolean;
}

interface GetTaskQueryParams {
    id?: Id;
    meetingId?: Id;
    related?: string[];
}

export type ExportTasksOrientation = 'portrait' | 'landscape';

export type ExportTasksDensity = 'small' | 'medium' | 'large';

export type ExportTasksMutationParams = {
    queryParameters: GetTasksQueryParams;
    options: {
        format: 'pdf' | 'excel' | 'csv';
        title: string;
        footer?: string;
        columns: { id: string; text: string; width: number | string }[];
        orientation?: ExportTasksOrientation;
        density?: ExportTasksDensity;
        locale?: string;
        dateFormat: string;
        groupBy?: string;
        groupByText?: { [key: string]: unknown };
        taskIds?: Id[];
        customFields?: (CustomField | CustomFieldGroup)[];
        signature?: {
            show: boolean;
            locationAndDateLabel: string;
            signatureLabel: string;
        };
    };
};

export type DuplicateTaskParams = {
    taskId: Id;
    name?: string;
    assignees?: { id: Id }[];
    type: TaskType;
    options?: {
        planned_date?: boolean;
        due_date?: boolean;
        customFields?: boolean;
        description?: boolean;
        subtasks?: boolean;
        attachments?: boolean;
        watchers?: boolean;
    };
};

export const taskApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [taskTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getTask: build.query<Task, GetTaskQueryParams>({
                query: ({ id, meetingId, related }) => ({
                    url: `tasks/${id}`,
                    params: { meeting_id: meetingId, related },
                }),
                providesTags: (result, error, { id }) => [tag(id), taskWorkspaceTag(resourceId(taskTagType, id))],
            }),

            getMyAssignees: build.query<User[], { limit?: number }>({
                query: (params) => ({ url: 'tasks/my_assignees', params }),
                providesTags: (result) => userTags(result),
            }),

            addTask: build.mutation<Task, Partial<Task> & { keepCache?: boolean }>({
                query: ({ keepCache, ...body }) => ({
                    url: 'tasks',
                    body,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { keepCache = false }) => {
                    if (!keepCache) {
                        invalidateCachedTasks();
                    }
                    return [tag(listId), ...workspaceTags(result?.tags as Identified[])];
                },
            }),

            addTasks: build.mutation<Task[], { tasks: Partial<Task>[] }>({
                query: (body) => ({
                    url: 'bulk-tasks',
                    body,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result) => {
                    invalidateCachedTasks();
                    return [tag(listId), ...workspaceTags(result?.tags as Identified[])];
                },
            }),

            updateTask: build.mutation<
                Task,
                Partial<Task> & { workspaceId?: Id; keepCache?: boolean; applyOn?: ApplyOn }
            >({
                query: ({ workspaceId, keepCache, applyOn, ...body }) => ({
                    url: `tasks/${body.id}`,
                    params: { workspace_id: workspaceId, applyOn },
                    body,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { id, assignee_id, keepCache = false }) => {
                    if (!keepCache) {
                        invalidateCachedTasks();
                    }
                    return [
                        tag(id),
                        taskActivitiesTag(id),
                        assignee_id !== undefined && taskWatcherTag(resourceId(taskTagType, id)),
                        TaskSearchCountTag,
                        ...workspaceTags(result.tags as Identified[]),
                        ...teamTags(result.tags?.map(({ team_id }) => ({ id: team_id }))),
                        result.checklist_id && checklistTag(result.checklist_id),
                        result.parent_task_id && tag(result.parent_task_id),
                    ].filter(Boolean);
                },
            }),

            updateTasks: build.mutation<
                Task[],
                { tasks: WithRequired<Partial<Task>, 'id'>[]; workspaceId?: Id; templateId?: Id; keepCache?: boolean }
            >({
                query: ({ tasks, workspaceId, templateId }) => ({
                    url: 'tasks',
                    params: { workspace_id: workspaceId, checklist_template_id: templateId },
                    body: tasks,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { tasks, workspaceId, keepCache = false }) => {
                    if (result != null && !keepCache) {
                        invalidateCachedTasks();
                        return [
                            ...tags(tasks),
                            tag(listId),
                            workspaceTag(workspaceId),
                            TaskSearchCountTag,
                            ...result
                                .filter(({ checklist_id }) => checklist_id)
                                .map(({ checklist_id }) => checklistTag(checklist_id)),
                        ];
                    }
                    return [];
                },
            }),

            //--------------------------
            // Task Activity Logs
            //--------------------------
            getTaskActivityLogs: build.query<ActivityLog[], { taskId: Id; limit?: number; important?: boolean }>({
                query: ({ taskId, important, limit }) => ({
                    url: `tasks/${taskId}/activities`,
                    params: { important, limit },
                }),
                providesTags: (result, error, { taskId }) => [taskActivitiesTag(taskId), tag(taskId)],
            }),

            exportTasks: build.mutation<TasksExport, ExportTasksMutationParams>({
                query: ({ queryParameters, options }) => {
                    const { format, ...body } = options;

                    return {
                        url: `tasks/${format}`,
                        params: queryParameters as unknown as Record<string, unknown>,
                        method: HttpMethod.Post,
                        body,
                    };
                },
            }),

            duplicateTask: build.mutation<{ tasks: Task[] }, DuplicateTaskParams>({
                query: ({ taskId, ...body }) => ({
                    url: `tasks/${taskId}/duplicate`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: () => {
                    invalidateCachedTasks();
                    return [tag(listId)];
                },
            }),
        }),
    });

export const {
    useGetTaskQuery,
    useGetMyAssigneesQuery,
    useAddTaskMutation,
    useAddTasksMutation,
    useUpdateTaskMutation,
    useUpdateTasksMutation,
    // Activity Logs
    useGetTaskActivityLogsQuery,
    useExportTasksMutation,
    useDuplicateTaskMutation,
} = taskApi;

export const buildGetTaskDetailParameters = (id: Id, meetingId?: Id) => ({
    id,
    meetingId,
    related: [
        'tags',
        'assignee',
        'completed_by',
        'recurrence',
        'customFields.customField',
        'customFields.attachments.currentVersion',
        'customFields.option',
        'customFieldGroups.customFieldGroup',
        'customFieldGroups.values.attachments',
        'customFieldGroups.values.option',
        'customFieldGroups.values.customField',
        'checklist',
        'checklist.checklistTemplate',
        'subtasks',
    ],
});

export const invalidateTasks = (tasks: Identified[]) => {
    return taskApi.util.invalidateTags(tags(tasks));
};

export const invalidateTagTaskNotInMeeting = (tagId: Id, meetingId: Id) =>
    taskApi.util.invalidateTags([tagTasksNotInMeetingTag(tagId, meetingId)]);

export const buildGetTasksParameters = ({
    view,
    statuses,
    order,
    grouping,
    search,
    workspaceId,
    userId,
    checklistId,
    templateId,
    meetingId,
    parentTaskId,
    searchType,
    page = 1,
    pageSize = 50,
    workspaces = [],
    related = [],
}: {
    view?: TaskFilter;
    statuses?: TaskStatus[];
    order?: TaskOrder;
    grouping?: TaskOrder;
    search?: string;
    workspaceId?: Id;
    userId?: Id;
    checklistId?: Id;
    templateId?: Id;
    meetingId?: Id;
    parentTaskId;
    searchType?: SearchType;
    page?: number;
    pageSize?: number;
    workspaces?: Id[];
    related?: string[];
}) => {
    const isCustomFilter = !isNaN(view as number);

    const orders = [order];
    const groupings = [grouping];

    return {
        filterId: isCustomFilter ? view : undefined,
        filters: isCustomFilter ? (EmptyArray as TaskFilter[]) : [view as TaskFilter],
        status: statuses.join('+'),
        tag_id: workspaceId,
        users: userId && [userId],
        checklist_id: checklistId,
        checklist_template_id: templateId,
        meeting_id: meetingId,
        parent_task_id: parentTaskId,
        search,
        searchType,
        order: orders.join(','),
        grouping: groupings.join(','),
        page,
        pageSize,
        tags: workspaces.length > 0 ? workspaces : undefined,
        related: related.length > 0 ? related : undefined,
    };
};
