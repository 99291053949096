import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { faEmptySet, faFolderOpen } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Checkbox, EmptyState, ExtendableSearchInput } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { numericCompare } from '@wedo/utils';
import { type Actions } from '@wedo/utils/hooks/useSet';
import { tocSectionClasses, tocSectionIconClasses } from 'Pages/meeting/components/TableOfContents/TocSection';
import { getTopicRowClasses, rowNumberClasses, rowTitleClasses } from 'Pages/meeting/components/Topic';
import { TopicStatusIcon } from 'Pages/meeting/components/TopicStatusIcon';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { levelFromDisplayId } from 'Shared/utils/meeting';

const isMeetingSection = (item: MeetingSection | MeetingTopic): item is MeetingSection =>
    (item as MeetingSection).section_series_id != null;

const canManageCurrentSection = (
    item: MeetingSection | MeetingTopic,
    sections: MeetingSection[],
    currentMeetingSections: MeetingSection[],
    canManageTopics: boolean
) => {
    if (currentMeetingSections == null) {
        return false;
    }
    const meetingSectionId = isMeetingSection(item) ? item.section_series_id : item.meeting_section_id;
    const section = sections?.find(({ id }) => id === meetingSectionId);
    if (section) {
        const currentSection = currentMeetingSections?.find(
            ({ section_series_id }) => section_series_id === section.section_series_id
        );
        if (currentSection) {
            return currentSection.can_manage_topic;
        }
    }
    return canManageTopics;
};

export const getSectionTopics = (
    sectionId: Id,
    sections: MeetingSection[],
    topics: MeetingTopic[],
    canSelectOnlyManagedTopic: boolean,
    canManageTopic: (topic: MeetingTopic | MeetingSection) => boolean
): Id[] => [
    ...topics
        .filter(
            (topic) => topic.meeting_section_id === sectionId && (!canSelectOnlyManagedTopic || canManageTopic(topic))
        )
        .map((topic) => topic.id),
    ...(sections || [])
        .filter((section) => section.parent_section_id === sectionId)
        .map((section) => getSectionTopics(section.id, sections, topics, canSelectOnlyManagedTopic, canManageTopic))
        .flat(),
];

const CheckableTopic = ({
    level,
    topic,
    isSelected,
    onToggle,
    children,
    canSelect,
    isDisabled,
}: {
    level: number;
    topic: MeetingTopic;
    isSelected: boolean;
    onToggle: () => void;
    children: ReactNode;
    canSelect: boolean;
    isDisabled: boolean;
}) => {
    return (
        <div style={{ marginLeft: `${20 * (level - 1)}px` }}>
            <div className={'flex items-center gap-1'}>
                {canSelect && <Checkbox checked={isSelected} onChange={onToggle} disabled={isDisabled} />}
                <div
                    className={clsx(getTopicRowClasses({ selected: isSelected }))}
                    onClick={() => !isDisabled && onToggle()}
                >
                    <TopicStatusIcon big={false} topic={topic} />
                    {children}
                </div>
            </div>
        </div>
    );
};

const CheckableSection = ({
    level,
    isSelected,
    onToggle,
    children,
    canSelect,
    canCheck,
    isDisabled,
}: {
    level: number;
    isSelected: boolean;
    onToggle: () => void;
    children: ReactNode;
    canSelect: boolean;
    canCheck: boolean;
    isDisabled: boolean;
}) => (
    <div style={{ marginLeft: `${20 * (level - 1) + (canCheck ? 0 : 20)}px` }}>
        <div className={'flex items-center gap-1'}>
            {canCheck && <Checkbox checked={isSelected} onChange={onToggle} disabled={isDisabled} />}
            <div
                className={clsx(tocSectionClasses({ isSelected, isSelectable: canSelect }))}
                onClick={() => !isDisabled && onToggle()}
            >
                <div className={clsx(tocSectionIconClasses(isSelected))}>
                    <FontAwesomeIcon
                        icon={faFolderOpen}
                        className={clsx(!canSelect && 'text-gray-500', isSelected && 'text-white')}
                    />
                </div>
                {children}
            </div>
        </div>
    </div>
);

interface SelectableTopicTreeProps {
    meetingId: Id;
    onlyTopics?: boolean;
    selectedTopicsSet: Set<Id>;
    selectedTopicsSetActions: Actions<Id>;
    selectedSectionsSet?: Set<Id>;
    selectedSectionsSetActions?: Actions<Id>;
    topics: MeetingTopic[];
    sections?: MeetingSection[];
    currentMeetingSections?: MeetingSection[];
    disableSelection?: boolean;
    canSelectOnlyManagedTopic?: boolean;
    canManageCurrentTopics?: boolean;
    onSearch: (text: string) => void;
}

export const SelectableTopicTree = ({
    meetingId,
    disableSelection = false,
    canSelectOnlyManagedTopic = false,
    canManageCurrentTopics = false,
    selectedTopicsSet = new Set(),
    selectedTopicsSetActions,
    selectedSectionsSet = new Set(),
    selectedSectionsSetActions,
    topics,
    sections,
    onlyTopics,
    currentMeetingSections,
    onSearch,
}: SelectableTopicTreeProps): JSX.Element => {
    const { data: meeting } = useGetMeetingQuery(buildGetMeetingParameters(meetingId));
    const [search, setSearch] = useState('');

    const canManageTopic = (item: MeetingSection | MeetingTopic) =>
        canManageCurrentSection(item, sections, currentMeetingSections, canManageCurrentTopics);

    const getChildSectionsDeep = (sectionId: Id): MeetingSection[] => [
        ...(sections || [])
            .filter((section) => section.parent_section_id === sectionId)
            .map((section) => [section, ...getChildSectionsDeep(section.id)])
            .flat(),
    ];

    const toggleSection = (id: Id) => {
        if (selectedSectionsSetActions != null) {
            if (selectedSectionsSet.has(id)) {
                selectedSectionsSetActions.remove(id);
            } else {
                selectedSectionsSetActions.add(id);
                getChildSectionsDeep(id).forEach((s) => selectedSectionsSetActions.add(s.id));
                getSectionTopics(id, sections, topics, canSelectOnlyManagedTopic, canManageTopic).forEach((t) =>
                    selectedTopicsSetActions.add(t)
                );
            }
        } else {
            const sectionTopics = getSectionTopics(id, sections, topics, canSelectOnlyManagedTopic, canManageTopic);
            let allSectionTopicsAreSelected = true;
            sectionTopics.forEach((t) => {
                if (!selectedTopicsSet.has(t)) {
                    allSectionTopicsAreSelected = false;
                }
            });
            if (allSectionTopicsAreSelected) {
                sectionTopics.forEach((t) => selectedTopicsSetActions.remove(t));
            } else {
                sectionTopics.forEach((t) => selectedTopicsSetActions.add(t));
            }
        }
    };

    const selectAllSections = () => {
        sections.forEach((s) => selectedSectionsSetActions.add(s.id));
    };
    const selectAllTopics = () => {
        topics.forEach((t) => (!canSelectOnlyManagedTopic || canManageTopic(t)) && selectedTopicsSetActions.add(t.id));
    };

    const deselectAllSections = () => {
        selectedSectionsSetActions.reset();
    };

    const toggleAll = () => {
        const topicsToRemove = Array.from(selectedTopicsSet);
        const sectionsToRemove = selectedSectionsSet != null ? Array.from(selectedSectionsSet) : [];
        selectAllTopics();
        topicsToRemove.forEach((t) => selectedTopicsSetActions.remove(t));
        if (sections != null) {
            selectAllSections();
            sectionsToRemove.forEach((s) => selectedSectionsSetActions.remove(s));
        }
    };
    const deselectAllTopics = () => {
        selectedTopicsSetActions.reset();
    };

    const selectAll = () => {
        selectAllTopics();
        selectAllSections();
    };

    const topicsAndSections = useMemo(() => {
        return [...topics, ...(sections || [])].sort((a, b) => numericCompare(a.display_id, b.display_id));
    }, [topics, sections]);

    useEffect(() => {
        onSearch(search);
    }, [search]);

    return (
        <>
            <div className="flex flex-wrap justify-between">
                <div className="flex flex-wrap gap-2">
                    <Button size="sm" onClick={selectAll}>
                        <Trans>Select All</Trans>
                    </Button>
                    <Button size="sm" onClick={toggleAll}>
                        <Trans>Toggle selection</Trans>
                    </Button>
                    {selectedSectionsSet != null && !onlyTopics && (
                        <>
                            <Button
                                size="sm"
                                onClick={() => {
                                    if (selectedTopicsSet.size > 0) {
                                        deselectAllTopics();
                                    }
                                    if (selectedSectionsSet != null && selectedSectionsSet.size === 0) {
                                        selectAllSections();
                                    }
                                }}
                            >
                                <Trans>Sections only</Trans>
                            </Button>
                            <Button
                                size="sm"
                                onClick={() => {
                                    if (selectedSectionsSet != null && selectedSectionsSet.size > 0) {
                                        deselectAllSections();
                                    }
                                    if (selectedTopicsSet.size === 0) {
                                        selectAllTopics();
                                    }
                                }}
                            >
                                <Trans>Topics only</Trans>
                            </Button>
                        </>
                    )}
                </div>
                <ExtendableSearchInput search={search} onSearch={setSearch} size="sm" />
            </div>
            <div className="my-2 text-sm">
                {onlyTopics ? (
                    <Trans>{selectedTopicsSet.size + selectedSectionsSet.size} topics selected</Trans>
                ) : (
                    <Trans>{selectedTopicsSet.size + selectedSectionsSet.size} topics and sections selected</Trans>
                )}
            </div>
            <div className={'mt-2 flex max-h-[calc(60vh_-_100px)] min-h-[300px] flex-col gap-1 overflow-y-auto pl-1'}>
                {topicsAndSections.length === 0 && (
                    <EmptyState icon={faEmptySet}>
                        <Trans>No section found</Trans>
                    </EmptyState>
                )}
                {topicsAndSections.map((item) =>
                    isMeetingSection(item) ? (
                        <CheckableSection
                            key={`chksct-${item.id}`}
                            isSelected={selectedSectionsSet != null && selectedSectionsSet.has(item.id)}
                            level={levelFromDisplayId(item.display_id)}
                            onToggle={() => toggleSection(item.id)}
                            canSelect={!disableSelection && selectedSectionsSet != null}
                            canCheck={!onlyTopics}
                            isDisabled={canSelectOnlyManagedTopic && !canManageTopic(item)}
                        >
                            {!meeting?.settings?.hide_section_numbering && (
                                <div className={clsx(rowNumberClasses, 'pt-0.5')}>{item.display_id}</div>
                            )}
                            <div className={clsx(rowTitleClasses, 'pt-0.5')}>{item.title}</div>
                        </CheckableSection>
                    ) : (
                        <CheckableTopic
                            topic={item}
                            key={`chktpc-${item.id}`}
                            isSelected={selectedTopicsSet != null && selectedTopicsSet.has(item.id)}
                            level={levelFromDisplayId(item.display_id)}
                            onToggle={() => selectedTopicsSetActions.toggle(item.id)}
                            canSelect={!disableSelection}
                            isDisabled={canSelectOnlyManagedTopic && !canManageTopic(item)}
                        >
                            {!meeting?.settings?.hide_topic_numbering && (
                                <div className={clsx(rowNumberClasses, 'pt-0.5')}>{item.display_id}</div>
                            )}
                            <div className={clsx(rowTitleClasses, 'pt-0.5')}>{item.title}</div>
                        </CheckableTopic>
                    )
                )}
            </div>
        </>
    );
};
