import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { getColorId, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { onSpace } from '@wedo/utils';
import { OrganizePanelProps } from 'Pages/TasksPage/types';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { WorkspacePicker } from 'Shared/components/workspace/WorkspacePicker';
import { useFavoriteWorkspaces } from 'Shared/hooks/useFavoriteWorkspaces';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { Workspace } from 'Shared/types/workspace';
import { Panel } from './Panel';

export const SharePanel = ({ task, onNext, isInGanttContext }: OrganizePanelProps) => {
    const queryClient = useQueryClient();

    const [updateTask] = useUpdateTaskMutation();
    const { show } = useNotification();
    const { data: favoriteWorkspaces } = useFavoriteWorkspaces();

    const handleUpdate = async (workspace: Workspace) => {
        if (task.workspaces.some((tag) => tag.id === workspace.id)) {
            onNext();
            return;
        }
        const result = await updateTask({
            id: task.id,
            workspaces: [...task.workspaces.map(({ id }) => id), workspace.id],
            keepCache: isInGanttContext,
        });

        if ('error' in result) {
            show(UnexpectedErrorNotification);
            return;
        }

        if (isInGanttContext) {
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'workspaces')]);
        }

        onNext();
    };

    return (
        <Panel className="flex flex-col items-center gap-4">
            <div className="flex flex-wrap gap-2 place-content-center">
                {favoriteWorkspaces?.map((workspace) => {
                    const colorId = getColorId(workspace?.color?.background);
                    return (
                        <div
                            key={workspace.id}
                            role="button"
                            tabIndex={0}
                            onKeyDown={onSpace(() => handleUpdate(workspace))}
                            className={clsx(
                                'flex cursor-pointer items-center justify-center rounded-md pl-1 pr-2 py-0.5 gap-1',
                                `bg-${colorId.toString()}-100`,
                                `hover:bg-${colorId.toString()}-200`,
                                `text-${colorId.toString()}-700`
                            )}
                            onClick={() => handleUpdate(workspace)}
                        >
                            <WorkspaceIcon workspace={workspace} />
                            {workspace.name}
                        </div>
                    );
                })}
            </div>
            <WorkspacePicker onWorkspaceSelect={handleUpdate}>
                <Trans>Search for a workspace</Trans>
            </WorkspacePicker>
        </Panel>
    );
};
