import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Button,
    colorPickerColorsMap,
    ColorPickerPopover,
    ContextModalProps,
    Form,
    Input,
    Modal,
} from '@wedo/design-system';
import { onEnter } from '@wedo/utils';
import { isDefaultRoleName } from 'Pages/meeting/components/EditMeetingAccessModal/RoleSelector';
import { MeetingRole } from 'Shared/types/meetingRole';
import { getRole } from 'Shared/utils/user';

type AddMeetingRoleModalProps = {
    meetingRole?: MeetingRole;
    allRoles: MeetingRole[];
    onDone: (meetingRole: MeetingRole) => void;
} & ContextModalProps;

export const AddMeetingRoleModal = ({
    meetingRole,
    allRoles,
    onDone,
    ...modalProps
}: AddMeetingRoleModalProps): JSX.Element => {
    const roleName = isDefaultRoleName(meetingRole) ? getRole(meetingRole?.code) : meetingRole?.name;
    const [name, setName] = useState(roleName || '');
    const [nameError, setNameError] = useState(null);
    const [id, setId] = useState(null);

    const randomColor =
        Object.values(colorPickerColorsMap)[(Math.random() * Object.keys(colorPickerColorsMap).length) | 0].value;
    const [color, setColor] = useState(randomColor);

    const nameInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (meetingRole) {
            setName(roleName);
            setColor(meetingRole.color);
            setId(meetingRole.id);
        }
    }, [meetingRole]);

    const validateName = () => {
        if (isEmpty(name)) {
            setNameError(t`Please enter a name`);
            return false;
        }

        setName(name.trim());

        if (
            allRoles.some(
                (role: MeetingRole) =>
                    role.id !== meetingRole?.id &&
                    ((!isDefaultRoleName(role) && role.name === name.trim()) ||
                        (isDefaultRoleName(role) && getRole(role.code) === name.trim()))
            )
        ) {
            setNameError(t`This role name already exists`);
            return false;
        }
        return true;
    };

    const handleConfirm = async () => {
        if (name && color && validateName()) {
            onDone({ name: name.trim(), color, id });
            await modalProps.close();
        }
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (nameError) {
            setNameError(null);
        }
        setName(e.target.value);
    };

    const handleColorSelected = (color: string) => {
        setColor(color);
    };

    return (
        <Modal {...modalProps} onCancel={() => onDone(null)} size={'md'} initialFocus={nameInputRef}>
            <Modal.Header
                title={
                    meetingRole ? (
                        <span>
                            <Trans>Edit role</Trans>
                        </span>
                    ) : (
                        <span>
                            <Trans>Add role</Trans>
                        </span>
                    )
                }
            />
            <Modal.Body>
                <Form layout={'horizontal'}>
                    <Form.Item label={t`Name`} htmlFor={'form-name'}>
                        <Input
                            ref={nameInputRef}
                            onChange={handleNameChange}
                            value={name}
                            status={nameError ? 'error' : 'default'}
                            statusText={nameError ? nameError : undefined}
                            id="form-name"
                            onBlur={validateName}
                            placeholder={t`Name`}
                            onKeyDown={onEnter(handleConfirm)}
                            maxLength={255}
                        />
                    </Form.Item>
                    <Form.Item label={t`Color`}>
                        <ColorPickerPopover
                            showSelectedColor
                            canDeselectColor={false}
                            color={color}
                            onChange={handleColorSelected}
                        >
                            <Trans>Choose color</Trans>
                        </ColorPickerPopover>
                    </Form.Item>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        onDone(null);
                        void modalProps.close();
                    }}
                >
                    <Trans>Close</Trans>
                </Button>
                <Button
                    disabled={!color || !name}
                    color={'primary'}
                    onClick={handleConfirm}
                    data-testid={'confirmAddRole'}
                >
                    {meetingRole ? <Trans>Confirm</Trans> : <Trans>Add role</Trans>}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
