import { useMemo } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Id } from '@wedo/types';
import { Section } from 'Shared/types/section';

type useSectionsPanelProps = {
    sections: Array<Section>;
    updateItemOrders: (payload: Array<{ id: Id; order: number }>, sortedSections: Array<Section>) => void;
};

export const useSectionsPanel = ({ sections, updateItemOrders }: useSectionsPanelProps) => {
    const maxOrder = useMemo<number>(() => {
        if (isEmpty(sections)) return -1;
        let maxOrder = -1;
        for (const section of sections) {
            maxOrder = Math.max(maxOrder, section.order);
        }
        return maxOrder;
    }, [sections]);

    const sectionIds = useMemo<Array<Id>>(() => {
        if (!Array.isArray(sections)) return [];
        return sections?.map(({ id }) => id);
    }, [sections]);

    const itemIdToIndexMapping = useMemo(() => {
        const result = new Map<Id, number>();
        if (!Array.isArray(sectionIds)) return result;

        for (let index = 0; index < sectionIds.length; index++) {
            result.set(sectionIds[index], index);
        }
        return result;
    }, [sectionIds]);

    const getSortedSections = (payload: Array<{ id: Id; order: number }>) => {
        const updatedSections = cloneDeep(sections);
        for (const item of payload) {
            updatedSections[itemIdToIndexMapping.get(item.id)].order = item.order;
        }
        return updatedSections.sort((a, b) => a.order - b.order);
    };

    const reOrderSections = (fromId: Id, overItemId: Id): void => {
        if (fromId === overItemId) return;
        const fromIndex = itemIdToIndexMapping.get(fromId);
        const overIndex = itemIdToIndexMapping.get(overItemId);

        const updateOrderPayload: Array<{ id: Id; order: number }> = [];
        updateOrderPayload.push({
            id: sections[fromIndex].id,
            order: sections[overIndex].order,
        });

        if (fromIndex > overIndex) {
            for (let i = overIndex; i < fromIndex; i += 1) {
                updateOrderPayload.push({
                    id: sections[i].id,
                    order: sections[i].order + 1,
                });
            }
        } else {
            for (let i = fromIndex + 1; i <= overIndex; i += 1) {
                updateOrderPayload.push({
                    id: sections[i].id,
                    order: sections[i].order - 1,
                });
            }
        }

        updateItemOrders(updateOrderPayload, getSortedSections(updateOrderPayload));
    };

    const handleDragEnd = (e: DragEndEvent) => {
        const itemId = e.active.id;
        const moveOverId = e.over.id;
        reOrderSections(itemId, moveOverId);
    };

    return { maxOrder, sectionIds, itemIdToIndexMapping, reOrderSections, handleDragEnd };
};
