import { HttpMethod, Id } from '@wedo/types';
import { taskActivitiesTag, taskTagType } from 'Shared/services/task';
import { CustomFieldGroupValue } from 'Shared/types/customField';
import { Task } from 'Shared/types/task';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag } = configureTag('CustomFieldGroupValue');

export const taskCustomFieldGroupValue = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getCustomFieldGroupValues: build.query<CustomFieldGroupValue[], Id>({
                query: (taskId) => ({ url: `tasks/${taskId}/custom-field-groups` }),
                transformResponse: (res: Task) => res.customFieldGroups,
                providesTags: (result, error, taskId) => [tag(resourceId(taskTagType, taskId))],
            }),
            addTaskCustomFieldGroupValue: build.mutation<
                Partial<CustomFieldGroupValue>,
                {
                    taskId: Id;
                    customFieldGroupId: Id;
                    values: { values: Partial<CustomFieldGroupValue> };
                }
            >({
                query: ({ taskId, customFieldGroupId, values }) => ({
                    url: `tasks/${taskId}/custom-field-groups/${customFieldGroupId}`,
                    body: values,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { taskId }) => [
                    tag(resourceId(taskTagType, taskId)),
                    taskActivitiesTag(taskId),
                ],
            }),
            removeTaskCustomFieldGroupValue: build.mutation<
                Partial<CustomFieldGroupValue>,
                {
                    taskId: Id;
                    customFieldGroupId: Id;
                    customFieldGroupValueId: Id;
                }
            >({
                query: ({ taskId, customFieldGroupId, customFieldGroupValueId }) => ({
                    url: `tasks/${taskId}/custom-field-groups/${customFieldGroupId}/values/${customFieldGroupValueId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { taskId }) => [
                    tag(resourceId(taskTagType, taskId)),
                    taskActivitiesTag(taskId),
                ],
            }),
            updateTaskCustomFieldGroupValue: build.mutation<
                Partial<CustomFieldGroupValue>,
                {
                    taskId: Id;
                    customFieldGroupId: Id;
                    customFieldGroupValueId: Id;
                    changes: { values: Partial<CustomFieldGroupValue> };
                }
            >({
                query: ({ taskId, customFieldGroupId, customFieldGroupValueId, changes }) => ({
                    url: `tasks/${taskId}/custom-field-groups/${customFieldGroupId}/values/${customFieldGroupValueId}`,
                    body: changes,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { taskId }) => [
                    tag(resourceId(taskTagType, taskId)),
                    taskActivitiesTag(taskId),
                ],
            }),
        }),
    });

export const {
    useGetCustomFieldGroupValuesQuery,
    useAddTaskCustomFieldGroupValueMutation,
    useRemoveTaskCustomFieldGroupValueMutation,
    useUpdateTaskCustomFieldGroupValueMutation,
} = taskCustomFieldGroupValue;
