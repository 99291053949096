import { FC } from 'react';
import {
    getCroppedImgBlob,
    NoInternetErrorNotification,
    PixelCrop,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { fileTypeToImageExtension } from '@wedo/utils';
import { LogoSettings } from 'Pages/settings/shared/logo/LogoSettings';
import { useDeleteWorkspaceLogoMutation, useUpdateWorkspaceLogoMutation } from 'Shared/services/workspace';
import { ApiError } from 'Shared/types/apiError';
import { Workspace } from 'Shared/types/workspace';
import { isFetchError } from 'Shared/utils/rtkQuery';

type LogoSectionProps = { workspace: Workspace };

export const LogoSection: FC<LogoSectionProps> = ({ workspace }) => {
    const { show } = useNotification();

    const [updateWorkspaceLogo, { isLoading: updateWorkspaceLogoIsLoading }] = useUpdateWorkspaceLogoMutation();
    const [deleteWorkspaceLogo, { isLoading: deleteWorkspaceLogoIsLoading }] = useDeleteWorkspaceLogoMutation();

    const handleLogoEdit = async (file: File, crop?: PixelCrop) => {
        let formData: FormData;
        if (file.type === 'image/svg+xml') {
            formData = new FormData();
            formData.append('attachments', file);
        } else {
            const croppedImage: Blob = await getCroppedImgBlob(file, crop);
            formData = new FormData();
            formData.append(
                'attachments',
                new File([croppedImage], `logo.${fileTypeToImageExtension(file.type)}`, { type: file.type })
            );
        }

        const response = await updateWorkspaceLogo({ workspaceId: workspace?.id, form: formData });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (isFetchError(error)) {
                show(NoInternetErrorNotification);
            } else {
                show(UnexpectedErrorNotification);
            }
        }
    };

    const handleDeleteLogo = async () => {
        const response = await deleteWorkspaceLogo({ workspaceId: workspace?.id });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (isFetchError(error)) {
                show(NoInternetErrorNotification);
            } else {
                show(UnexpectedErrorNotification);
            }
        }
    };

    return (
        <LogoSettings
            logoMinWidth={200}
            logoMinHeight={80}
            onImageEdit={handleLogoEdit}
            onLogoDelete={handleDeleteLogo}
            logo={workspace?.logo_url}
            isUpdateLogoLoading={updateWorkspaceLogoIsLoading}
            isDeleteLogoLoading={deleteWorkspaceLogoIsLoading}
            placeholderImage="logo"
        />
    );
};
