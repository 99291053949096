import React from 'react';
import { Trans } from '@lingui/macro';
import { Skeleton } from '@wedo/design-system';
import { BadgeSelector } from 'Pages/settings/notifications/components/BadgeSelector';
import { NotificationDayBadge, NotificationHourBadge } from 'Pages/settings/notifications/types/notification-badge';

interface EmailNotificationsSectionProps {
    notificationDayBadges: Array<NotificationDayBadge>;
    toggleNotificationDayBadge: (index: number) => void;
    notificationHourBadges: Array<NotificationHourBadge>;
    toggleNotificationHourBadge: (index: number) => void;
}

export const EmailNotificationsSection = ({
    notificationHourBadges,
    toggleNotificationHourBadge,
    notificationDayBadges,
    toggleNotificationDayBadge,
}: EmailNotificationsSectionProps) => {
    const isLoading = !notificationDayBadges || !notificationHourBadges;

    return (
        <>
            <h4 className="text-base font-semibold">
                <Trans>Select on which days you will get emails</Trans>
            </h4>

            {isLoading ? (
                <Skeleton className="mt-2 h-10" />
            ) : (
                <BadgeSelector
                    badges={notificationDayBadges}
                    onClick={toggleNotificationDayBadge}
                    className="mt-4 grid grid-cols-[repeat(7,_2.5rem)] gap-1 md:gap-2"
                />
            )}

            <h4 className="mt-8 text-base font-semibold">
                <Trans>Select at what time they arrive</Trans>
            </h4>

            {isLoading ? (
                <Skeleton className="mt-2 h-28" />
            ) : (
                <BadgeSelector
                    badges={notificationHourBadges}
                    onClick={toggleNotificationHourBadge}
                    className="mt-4 grid grid-cols-[repeat(6,_2.5rem)] gap-2 sm:grid-cols-[repeat(8,_2.5rem)] lg:grid-cols-[repeat(12,_2.5rem)]"
                />
            )}
        </>
    );
};
