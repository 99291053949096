import React, { FC, PropsWithChildren, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Modal,
    NoInternetErrorNotification,
    Steps,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id, NumberRange } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { TeamNameModalBody } from 'Shared/components/team/AddTeamModal/TeamNameModalBody';
import { ManageMembersModalBody } from 'Shared/components/userGroup/ManageMembersModalBody';
import { useTeam } from 'Shared/hooks/useTeam';
import { useAddTeamMutation } from 'Shared/services/team';
import { ApiError } from 'Shared/types/apiError';
import { isFetchError } from 'Shared/utils/rtkQuery';

type AddTeamModalProps = ContextModalProps;

export const AddTeamModal: FC<PropsWithChildren<AddTeamModalProps>> = ({ children, ...modalProps }) => {
    const navigate = useNavigate();
    const { show } = useNotification();

    const [addTeam, { isLoading, error }] = useAddTeamMutation();

    const [step, setStep] = useState<NumberRange<1, 3>>(1);
    const [teamId, setTeamId] = useState<Id>();
    const [teamName, setTeamName] = useState<string>(EmptyString);
    const location = useLocation();

    const {
        team,
        handleAddMember,
        handleMemberDelete,
        handleAcceptRequest,
        handleRefuseJoinRequest,
        handleModeratorToggle,
    } = useTeam(teamId);

    const teamNameInput = useRef<HTMLInputElement>();

    const addTeamButtonIsDisabled = teamName.length === 0;

    const handleAddTeam = async () => {
        const response = await addTeam({ teamName });
        if ('error' in response) {
            const error = response.error as ApiError;
            show(isFetchError(error) ? NoInternetErrorNotification : UnexpectedErrorNotification);
        } else {
            setTeamId(response.data.id);
            setStep(2);
        }
    };

    const handleFinish = () => {
        if (!location.pathname.includes('/settings/teams')) {
            navigate(`/teams/${teamId}/workspaces`);
        }
        void modalProps.close();
    };

    return (
        <Modal {...modalProps} initialFocus={teamNameInput} size="lg">
            <Modal.Header title={t`Add team`} />

            <Steps className="rounded-none" currentIndex={step}>
                <Steps.Step index={1}>
                    <Trans>Team name</Trans>
                </Steps.Step>

                <Steps.Step index={2}>
                    <Trans>Members</Trans>
                </Steps.Step>
            </Steps>

            {step === 1 && (
                <TeamNameModalBody
                    teamNameInput={teamNameInput}
                    name={teamName}
                    onNameChange={setTeamName}
                    onAddTeam={handleAddTeam}
                    isDuplicateError={(error as ApiError)?.matches({ code: 'DuplicateError' })}
                />
            )}

            {step === 2 && (
                <Modal.Body>
                    <ManageMembersModalBody
                        entity={team}
                        joinRequests={team?.join_requests}
                        onAcceptRequest={handleAcceptRequest}
                        onRefuseRequest={handleRefuseJoinRequest}
                        onDeleteUser={(user) => handleMemberDelete(user, { confirmBeforeDelete: false })}
                        onToggleModerator={handleModeratorToggle}
                        onAddUser={handleAddMember}
                        canRemoveMyself={false}
                    />
                </Modal.Body>
            )}

            {step === 1 && (
                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>

                    <Button
                        color="primary"
                        onClick={handleAddTeam}
                        loading={isLoading}
                        disabled={addTeamButtonIsDisabled}
                    >
                        <Trans>Add team</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {step === 2 && (
                <Modal.Footer>
                    <Button onClick={handleFinish} color="primary">
                        <Trans>Finish</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {children}
        </Modal>
    );
};
