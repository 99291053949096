import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faExclamationTriangle, faRotate, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { isBefore } from 'date-fns';
import { toDate } from 'date-fns-tz';
import {
    Button,
    DatePicker,
    DatePickerShortcuts,
    FormatDate,
    Popover,
    Skeleton,
    Tooltip,
    useModal,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getUserTimeZone } from '@wedo/utils';
import { TaskRecurrenceModal } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/TaskRecurrenceModal';
import { TaskDetailIcon } from 'Shared/components/task/TaskDetail/shared/TaskDetailIcon';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { useRemoveTaskRecurrenceMutation } from 'Shared/services/taskRecurrence';

type TaskDetailDueDateProps = {
    taskId: Id;
};

export const TaskDetailDueDate = ({ taskId }: TaskDetailDueDateProps) => {
    const { task, isTaskReadonly, handleTaskUpdate, taskRecurrence, isLoadingTask } = useTask(taskId);
    const { open: openModal } = useModal();
    const [removeTaskRecurrence] = useRemoveTaskRecurrenceMutation();

    const timeZone = getUserTimeZone();

    const today = new Date();

    const handleRemoveRecurrence = () => {
        removeTaskRecurrence({ taskId });
    };
    const onSelectDate = async (date: Date, closePopoverFn: () => void) => {
        await handleTaskUpdate({ due_date: date });
        closePopoverFn();
    };

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={taskRecurrence ? t`Recurring task due date` : t`Due date`} delay={300}>
                    {taskRecurrence ? (
                        <TaskDetailIcon type="recurrence" isActive={true} />
                    ) : (
                        <TaskDetailIcon type="due_date" isActive={!!task?.due_date} />
                    )}
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    taskRecurrence ? (
                        <Button
                            disabled={isTaskReadonly}
                            variant={'text'}
                            onClick={() => openModal(TaskRecurrenceModal, { taskId })}
                        >
                            <FormatDate date={toDate(task?.due_date, { timeZone })} format={'shortDate'} /> (
                            {t`due date`})
                        </Button>
                    ) : (
                        <Popover
                            text={
                                task?.due_date ? (
                                    <>
                                        <FormatDate date={toDate(task?.due_date, { timeZone })} format={'shortDate'} />{' '}
                                        ({t`due date`}){' '}
                                        {task?.due_date != null &&
                                            task?.planned_date != null &&
                                            isBefore(new Date(task?.due_date), new Date(task?.planned_date)) && (
                                                <Tooltip content={t`Due date is before start date`}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                </Tooltip>
                                            )}
                                    </>
                                ) : (
                                    <span className={'truncate'}>
                                        {task?.parent_task_id == null
                                            ? t`Select due date or recurrence`
                                            : t`Select due date`}
                                    </span>
                                )
                            }
                            disabled={isTaskReadonly}
                            className={'max-w-full justify-start truncate'}
                            variant="text"
                        >
                            {({ close }) => (
                                <div tabIndex={-1} className={'flex flex-col gap-2 bg-white p-3 outline-0'}>
                                    <DatePickerShortcuts onChange={(date: Date) => onSelectDate(date, close)} />
                                    <DatePicker
                                        timezone="UTC"
                                        date={
                                            task?.due_date != null
                                                ? new Date(task.due_date)
                                                : task?.planned_date != null
                                                  ? new Date(task.planned_date)
                                                  : today
                                        }
                                        onChange={(date) => onSelectDate(date, close)}
                                    />
                                    {task?.parent_task_id == null && (
                                        <Button
                                            variant={'outlined'}
                                            className="w-full"
                                            icon={faRotate}
                                            onClick={() => openModal(TaskRecurrenceModal, { taskId })}
                                        >
                                            {t`Set recurrence`}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Popover>
                    )
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {(task?.due_date != null || taskRecurrence != null) && !isTaskReadonly && (
                    <Button
                        variant={'text'}
                        icon={faXmark}
                        size={'sm'}
                        className={'enabled:hover:bg-red-100 enabled:hover:text-red-500'}
                        title={t`Remove`}
                        disabled={isTaskReadonly}
                        onClick={() =>
                            taskRecurrence != null ? handleRemoveRecurrence() : handleTaskUpdate({ due_date: null })
                        }
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
