import React, { FC, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    Input,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useInputState } from '@wedo/utils/hooks';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { useUpdateChecklistSectionMutation } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useUpdateSectionMutation } from 'Shared/services/workspace';
import { Section } from 'Shared/types/section';

type EditSectionNameModalProps = {
    section: Section;
    workspaceId?: Id;
    templateId?: Id;
    checklistId?: Id;
} & ContextModalProps;

export const EditSectionNameModal: FC<EditSectionNameModalProps> = ({
    section,
    workspaceId,
    checklistId,
    templateId,
    ...modalProps
}) => {
    const { show } = useNotification();
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });
    const { sections } = useTaskSections({ workspaceId, templateId, checklistId });

    const nameInputRef = useRef<HTMLInputElement>();

    const [updateSection, { isLoading: isUpdateWorkspaceSectionLoading }] = useUpdateSectionMutation();
    const [updateChecklistSection, { isLoading: isUpdateChecklistSectionLoading }] =
        useUpdateChecklistSectionMutation();

    const [name, , handleNameChange] = useInputState(section.name);

    const sectionWithSameName = sections.find((s) => s.name.toLowerCase().trim() === name.toLowerCase().trim());
    const currentNameIsAlreadyTaken = sectionWithSameName != null && sectionWithSameName?.id !== section.id;

    const isUpdateButtonDisabled = currentNameIsAlreadyTaken || isEmpty(name.trim()) || name === section.name;
    const isLoading = isUpdateChecklistSectionLoading || isUpdateWorkspaceSectionLoading;

    const updateWorkspaceSectionName = async () =>
        updateSection({
            workspaceId,
            sectionId: section.id,
            section: { name },
        });

    const updateTemplateSectionName = async () =>
        updateChecklistSection({
            checklistId: template?.checklist_id,
            sectionId: section.id,
            section: { name },
        });

    const updateChecklistSectionName = async () =>
        updateChecklistSection({
            checklistId,
            sectionId: section.id,
            section: { name },
        });

    const handleUpdate = async () => {
        let response;
        if (workspaceId) {
            response = await updateWorkspaceSectionName();
        } else if (checklistId) {
            response = updateChecklistSectionName();
        } else if (templateId) {
            response = updateTemplateSectionName();
        }
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} initialFocus={nameInputRef}>
            <Modal.Header title={t`Change section name for ${section.name}`} />

            <Modal.Body>
                <Input
                    ref={nameInputRef}
                    value={name}
                    onChange={handleNameChange}
                    status={currentNameIsAlreadyTaken ? 'error' : 'default'}
                    statusText={
                        currentNameIsAlreadyTaken && t`This name is already in use, please use a different section name`
                    }
                    onPressEnter={isUpdateButtonDisabled ? undefined : handleUpdate}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" disabled={isUpdateButtonDisabled} onClick={handleUpdate} loading={isLoading}>
                    <Trans>Update</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
