import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { useTasksContext } from 'App/contexts/TasksContext';
import { Task } from 'Shared/types/task';
import { getTaskSectionIdForWorkspace, maxTaskSectionOrder } from 'Shared/utils/task';

export const useWorkspaceTasks = (workspaceId: Id) => {
    const { tasks } = useTasksContext();

    const sectionToTasks = useMemo<Map<Id, Task[]>>(() => {
        const result = new Map<Id, Task[]>();
        if (!workspaceId || !tasks) {
            return result;
        }
        for (const task of tasks) {
            const sectionId = getTaskSectionIdForWorkspace(task, workspaceId);
            if (result.has(sectionId)) {
                result.get(sectionId).push(task);
            } else {
                result.set(sectionId, [task]);
            }
        }
        return result;
    }, [tasks, workspaceId]);

    const sectionIdToMaxOrder = useMemo<Map<Id, number>>(() => {
        const result = new Map<Id, number>();
        if (!workspaceId) {
            return result;
        }
        for (const [sectionId, taskList] of sectionToTasks.entries()) {
            result.set(sectionId, maxTaskSectionOrder(taskList));
        }
        return result;
    }, [sectionToTasks, workspaceId]);

    return { sectionIdToMaxOrder, sectionToTasks };
};
