import React, { FC } from 'react';
import { Tag } from '@wedo/design-system';
import { User } from 'Shared/types/user';
import { getFormattedUserRole, getUserRoleColorClasses } from 'Shared/utils/user';

export const RoleCell: FC<User> = ({ role }) => (
    <div className="flex items-center">
        <Tag size={'xs'} color={getUserRoleColorClasses(role)}>
            {getFormattedUserRole(role)}
        </Tag>
    </div>
);
