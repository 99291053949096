import { FC, useEffect, useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { ProgressBar } from '@wedo/design-system';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { AddonsForm } from 'Pages/subscription/components/Addons/AddonsForm';
import { BillingAddressForm } from 'Pages/subscription/components/BillingAddress/BillingAddressForm';
import { BillingAddressPreview } from 'Pages/subscription/components/BillingAddress/BillingAddressPreview';
import { ChoosePlanForm } from 'Pages/subscription/components/ChoosePlan/ChoosePlanForm';
import { PlanPreview } from 'Pages/subscription/components/ChoosePlan/PlanPreview';
import { ConfirmSubscriptionForm } from 'Pages/subscription/components/ConfirmSubscription/ConfirmSubscriptionForm';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import { getNumberOfUsers } from 'Shared/utils/organization';

type Page = 'plan' | 'address' | 'confirm' | 'addons';

const PageToFormComponent: Record<Page, FC> = {
    plan: ChoosePlanForm,
    addons: AddonsForm,
    address: BillingAddressForm,
    confirm: ConfirmSubscriptionForm,
} as const;

const PageToPreviewComponent: Record<Page, FC> = {
    plan: () => <PlanPreview className="w-[35rem]" />,
    addons: () => <PlanPreview className="w-[35rem]" />,
    address: BillingAddressPreview,
    confirm: () => <></>,
} as const;

export const SubscriptionPage = () => {
    const { initializeSubscriptionData, reset, organization } = useSubscriptionPage();
    const {
        userLicenses,
        onboardingCompleted,
        source,
        actions: { setSource },
    } = useSubscriptionStore();

    const {
        params: { page },
    } = useMatch('/onboarding/subscribe/:page');
    const { state: navigationState } = useLocation();

    const step = useMemo(() => {
        switch (page as Page) {
            case 'address':
                return 1;
            case 'plan':
                return 2;
            case 'addons':
                return 3;
            case 'confirm':
                return 4;
            default:
                return null;
        }
    }, [page]);

    const FormComponent = PageToFormComponent[page as Page];
    const PreviewComponent = PageToPreviewComponent[page as Page];

    useEffect(() => {
        setSource(navigationState?.source);
    }, []);

    useEffect(() => {
        const nbUsers = getNumberOfUsers(organization);
        const planDetailsAreInvalid = userLicenses === 0 || userLicenses < nbUsers;
        if (planDetailsAreInvalid && organization != null) {
            initializeSubscriptionData(organization);
        }
        return () => {
            if (!window.location.pathname.startsWith('/onboarding/subscribe')) {
                reset();
            }
        };
    }, [organization, userLicenses]);

    return (
        <OnboardingLayout>
            <OnboardingLayout.Page>
                <OnboardingLayout.Form hideSteps>
                    {source == null && (
                        <ProgressBar
                            percent={(step / 4) * 100}
                            showPercent={false}
                            className="mb-10"
                            color={onboardingCompleted ? 'green' : 'neon'}
                        />
                    )}
                    <FormComponent />
                </OnboardingLayout.Form>

                <OnboardingLayout.Preview>
                    <div className="mx-4">
                        <PreviewComponent />
                    </div>
                </OnboardingLayout.Preview>
            </OnboardingLayout.Page>
        </OnboardingLayout>
    );
};
