import { Selection } from 'slate/dist/interfaces/editor';
import { Id } from '@wedo/types';
import { synchronizePoint } from './synchronizePoint';

export const synchronizeSelection = (
    blocks: Element[],
    previousSelection: Selection,
    previousSelectedAnchorBlockId: Id,
    previousSelectedFocusBlockId: Id
) => {
    // If we receive blocks from the server and there is an active selection, we do our best to keep that selection
    if (previousSelection != null) {
        const anchor = synchronizePoint(blocks, previousSelection.anchor, previousSelectedAnchorBlockId);
        const focus = synchronizePoint(blocks, previousSelection.focus, previousSelectedFocusBlockId);
        // The selected block has been deleted, so we can't select it anymore, we then deselect the editor
        if (anchor !== null && focus !== null) {
            return { anchor, focus };
        }
    }
    return null;
};
