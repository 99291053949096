import React, { FC, MutableRefObject, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { ColorPickerPopover, Input, ItemGroup, Label, Modal } from '@wedo/design-system';
import { TeamsSelect } from 'Shared/components/team/TeamsSelect/TeamsSelect';
import { Team } from 'Shared/types/team';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';

type WorkspaceInfoModalBodyProps = {
    name: string;
    onNameChange: (name: string) => void;
    color: string;
    onColorChange: (color: string) => void;
    team: Partial<Team>;
    onTeamChange: (team: Team) => void;
    description: string;
    onDescriptionChange: (description: string) => void;
    nameInputRef: MutableRefObject<HTMLInputElement>;
    onNext: () => void;
};

export const WorkspaceInfoModalBody: FC<WorkspaceInfoModalBodyProps> = ({
    name,
    onNameChange,
    color,
    onColorChange,
    team,
    onTeamChange,
    description,
    onDescriptionChange,
    nameInputRef,
    onNext,
}) => {
    const [isNameDirty, setIsNameDirty] = useState<boolean>(false);

    const isNameEmpty = isEmpty(name?.trim());
    const isNameError = isNameEmpty && isNameDirty;

    return (
        <Modal.Body>
            <Label>
                <Trans>Name</Trans>
            </Label>

            <ItemGroup
                status={isNameError ? 'error' : 'default'}
                statusText={isNameError && t`The workspace name can't be empty`}
            >
                <ColorPickerPopover color={color} onChange={onColorChange} showSelectedColor canDeselectColor={false} />
                <Input
                    ref={nameInputRef}
                    placeholder={t`Workspace name`}
                    value={name}
                    onChange={(e) => onNameChange(e.target.value)}
                    className="w-full"
                    onBlur={() => setIsNameDirty(true)}
                    onPressEnter={!isNameEmpty && onNext}
                />
            </ItemGroup>

            <Label className="mt-6">
                <Trans>Team</Trans>
            </Label>

            <TeamsSelect teamId={team?.id as string} onChange={onTeamChange} />

            <Label className="mt-6">
                <Trans>Description</Trans>
            </Label>
            <DescriptionEditor html={description} onChange={onDescriptionChange} />
        </Modal.Body>
    );
};
