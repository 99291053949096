import React, { FC, useMemo } from 'react';
import { t } from '@lingui/macro';
import { UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useAppDispatch } from 'App/store';
import { DefaultPreferencesCard } from 'Pages/settings/preferences/DefaultPreferencesCard';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { invalidateCurrentUser } from 'Shared/services/user';
import { trpc } from 'Shared/trpc';
import { DefaultPreferences } from 'Shared/types/network';

export const PreferencesPage: FC = () => {
    const { show } = useNotification();
    const appDispatch = useAppDispatch();
    const { currentUser } = useCurrentUserContext();
    const { network } = useCurrentNetwork();

    const preferences = useMemo(
        () => ({ ...network?.defaultPreferences, ...currentUser?.userNetwork.config?.defaultPreferences }),
        [network, currentUser]
    );

    const { mutate: updateCurrentUser } = trpc.user.updateCurrentUserNetwork.useMutation({
        onSuccess: () => {
            appDispatch(invalidateCurrentUser());
        },
        onError: () => {
            show(UnexpectedErrorNotification);
        },
    });

    const handleSavePreferences = async (preferences: DefaultPreferences) => {
        return updateCurrentUser({ userNetwork: { config: { defaultPreferences: preferences } } });
    };

    return (
        <DefaultPreferencesCard title={t`My preferences`} preferences={preferences} onSave={handleSavePreferences} />
    );
};
