import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, Fragment, ReactElement } from 'react';
import { faFolderOpen, faSlash } from '@fortawesome/pro-regular-svg-icons';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Accordion, Dropdown, RadioGroup, Select, Switch } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useMeetingContext } from 'App/contexts';
import { repeatFrequencyItems } from 'Pages/meeting/RepeatFrequencyItems';
import { ClipboardItem, ParseModes } from 'Pages/meeting/components/TableOfContents/ImportSectionsAndTopicsModal/utils';
import { tocSectionClasses, tocSectionIconClasses } from 'Pages/meeting/components/TableOfContents/TocSection';
import { getTopicRowClasses, rowNumberClasses, rowTitleClasses } from 'Pages/meeting/components/Topic';
import { TopicStatusIcon } from 'Pages/meeting/components/TopicStatusIcon';
import { TopicPresenters } from 'Shared/components/meeting/TopicPresenters';
import { TopicDurationPopover } from 'Shared/components/meeting/topicView/TopicDuration';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { levelFromDisplayId } from 'Shared/utils/meeting';

const TopicRecurrence: FC<{ repeatEvery: number; onChange: (repeatEvery: number) => void }> = ({
    repeatEvery,
    onChange,
}) => {
    const repeatLabel = i18n._(repeatFrequencyItems[repeatEvery].text);
    return (
        <Dropdown
            title={repeatLabel}
            className={'flex w-[1.875rem] items-center justify-center !p-0'}
            size={'sm'}
            label={
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon className={clsx(repeatEvery > 0 && 'text-blue-500')} icon={faSyncAlt} />
                    {repeatEvery === 0 && <FontAwesomeIcon icon={faSlash} />}
                </span>
            }
            placement={'bottom-start'}
        >
            {repeatFrequencyItems.map((repeatOccurrence) => (
                <Dropdown.Item
                    selected={repeatEvery === repeatOccurrence.id}
                    key={`repeat-${repeatOccurrence.id}`}
                    onClick={() => onChange(repeatOccurrence.id)}
                >
                    {i18n._(repeatOccurrence.text)}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};

const PreviewSectionsTopics = ({
    meetingId,
    items,
    showPresenter,
    showFirstBlock,
    isTopicRepeat,
    hideTopicSectionNumbering,
    onUpdateTopic,
}: {
    meetingId: Id;
    items: ClipboardItem[];
    showPresenter: boolean;
    showFirstBlock: boolean;
    isTopicRepeat: boolean;
    hideTopicSectionNumbering: boolean;
    onUpdateTopic: (topicId: string, changes: Partial<ClipboardItem>) => void;
}): ReactElement => {
    const { meeting } = useMeetingContext();
    const { contextTitle, contextUsers } = useContextUsers({ meetingId });

    return (
        <>
            {items.map((item) => {
                if (item.isSection) {
                    return (
                        <div
                            key={item.id}
                            style={{ marginLeft: `${16 * (levelFromDisplayId(item.display_id) - 1)}px` }}
                            className="mt-2"
                        >
                            <div className={clsx('items-center', tocSectionClasses({}))}>
                                <div className={clsx(tocSectionIconClasses(false))}>
                                    <FontAwesomeIcon icon={faFolderOpen} />
                                </div>
                                {!meeting?.settings?.hide_section_numbering && !hideTopicSectionNumbering && (
                                    <div className={rowNumberClasses}>{item.display_id}</div>
                                )}
                                <div className={rowTitleClasses}>{item.title}</div>
                            </div>
                            {item.childItems && (
                                <PreviewSectionsTopics
                                    meetingId={meetingId}
                                    items={item.childItems}
                                    showFirstBlock={showFirstBlock}
                                    showPresenter={showPresenter}
                                    isTopicRepeat={isTopicRepeat}
                                    hideTopicSectionNumbering={hideTopicSectionNumbering}
                                    onUpdateTopic={onUpdateTopic}
                                />
                            )}
                        </div>
                    );
                }
                return (
                    <Fragment key={item.id}>
                        <div
                            className={clsx('mt-2', getTopicRowClasses({}))}
                            style={{ marginLeft: `${16 * (levelFromDisplayId(item.display_id) - 1)}px` }}
                        >
                            <TopicStatusIcon className="shrink-0" />
                            {!meeting?.settings?.hide_topic_numbering && !hideTopicSectionNumbering && (
                                <div className={rowNumberClasses}>{item.display_id}</div>
                            )}
                            <div className={rowTitleClasses}>{item.title}</div>
                            <TopicPresenters
                                topicPresenters={item.presenters}
                                onUserSelected={(user) => {
                                    onUpdateTopic(item.id, {
                                        presenters: item.presenters.some(({ id }) => id === user.id)
                                            ? item.presenters.filter(({ id }) => id !== user.id)
                                            : [...item.presenters, user],
                                    });
                                }}
                                wrapperClassName="flex"
                                contextUsers={contextUsers}
                                contextTitle={contextTitle}
                                userAvatarSize="sm"
                                maxDisplayed={3}
                            />
                            <TopicDurationPopover
                                duration={item?.duration || 0}
                                onUpdate={(duration: number) => {
                                    onUpdateTopic(item.id, { duration });
                                    return Promise.resolve({});
                                }}
                            />
                            <TopicRecurrence
                                repeatEvery={item.repeatEvery || 0}
                                onChange={(repeatEvery) => onUpdateTopic(item.id, { repeatEvery })}
                            />
                        </div>
                        {showFirstBlock && item.firstBlock != null && item.firstBlock?.length > 0 && (
                            <div className="text-xs italic mx-8 mt-1 max-h-10 text-gray-700 line-clamp-2 whitespace-pre-wrap">
                                {item.firstBlock}
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </>
    );
};
export const PreviewStep = ({
    meetingId,
    items,
    parseMode,
    repeatEvery,
    currentUserIsPresenter,
    importFirstBlock,
    hideTopicSectionNumbering,
    onRepeatEveryChange,
    onParseModeChange,
    onCurrentUserIsPresenterChange,
    onImportFirstBlockChange,
    onHideTopicSectionNumberingChange,
    onUpdateTopic,
}: {
    meetingId: Id;
    items: ClipboardItem[];
    parseMode: ParseModes;
    currentUserIsPresenter: boolean;
    repeatEvery: boolean;
    importFirstBlock: boolean;
    hideTopicSectionNumbering: boolean;
    onParseModeChange: (parseMode: ParseModes) => void;
    onRepeatEveryChange: (repeatEvery: boolean) => void;
    onCurrentUserIsPresenterChange: (isPresenter: boolean) => void;
    onImportFirstBlockChange: (importFirstBlock: boolean) => void;
    onHideTopicSectionNumberingChange: (importFirstBlock: boolean) => void;
    onUpdateTopic: (topicId: string, changes: Partial<ClipboardItem>) => void;
}): ReactElement => {
    return (
        <>
            <div className={'flex flex-col gap-2'}>
                <div>
                    <PreviewSectionsTopics
                        meetingId={meetingId}
                        items={items}
                        showPresenter={currentUserIsPresenter}
                        isTopicRepeat={repeatEvery}
                        showFirstBlock={importFirstBlock}
                        hideTopicSectionNumbering={hideTopicSectionNumbering}
                        onUpdateTopic={onUpdateTopic}
                    />
                </div>
            </div>
            <Accordion className="mt-8">
                <Accordion.Item title={<Trans>Advanced options</Trans>}>
                    <div className="divide-y divide-gray-200 text-sm">
                        <div className="relative flex items-center gap-2 p-2 rounded">
                            <div className="flex-grow">
                                <Trans>What do you want to import?</Trans>
                            </div>
                            <Select value={parseMode} onChange={onParseModeChange}>
                                <Select.Option
                                    value={ParseModes.SectionsTopics}
                                >{t`Sections and topics`}</Select.Option>
                                <Select.Option value={ParseModes.OnlySections}>{t`Only sections`}</Select.Option>
                                <Select.Option value={ParseModes.OnlyTopics}>{t`Only topics`}</Select.Option>
                            </Select>
                        </div>
                        <div className="relative flex items-center gap-2 p-2 rounded">
                            <button
                                className="absolute inset-0"
                                disabled={parseMode === ParseModes.OnlySections}
                                onClick={() => onRepeatEveryChange(!repeatEvery)}
                            ></button>
                            <div className="flex-grow">
                                <Trans>Repeat topics every meeting ?</Trans>
                            </div>
                            <Switch
                                checked={repeatEvery}
                                disabled={parseMode === ParseModes.OnlySections}
                                onChange={() => onRepeatEveryChange(!repeatEvery)}
                            />
                        </div>
                        <div className="relative flex items-center gap-2 p-2 rounded">
                            <button
                                className="absolute inset-0"
                                disabled={parseMode === ParseModes.OnlySections}
                                onClick={() => onCurrentUserIsPresenterChange(!currentUserIsPresenter)}
                            ></button>
                            <div className="flex-grow">
                                <Trans>Set myself as presenter of the topics ?</Trans>
                            </div>
                            <Switch
                                checked={currentUserIsPresenter}
                                disabled={parseMode === ParseModes.OnlySections}
                                onChange={() => onCurrentUserIsPresenterChange(!currentUserIsPresenter)}
                            />
                        </div>
                        {parseMode !== ParseModes.OnlySections && (
                            <div className="relative flex items-center gap-2 p-2 rounded">
                                <button
                                    className="absolute inset-0"
                                    onClick={() => onImportFirstBlockChange(!importFirstBlock)}
                                ></button>
                                <div className="flex-grow">
                                    <Trans>Import first paragraph</Trans>
                                </div>
                                <Switch
                                    checked={importFirstBlock}
                                    onChange={() => onImportFirstBlockChange(!importFirstBlock)}
                                />
                            </div>
                        )}
                        <div className="relative flex items-center gap-2 p-2 rounded">
                            <button
                                className="absolute inset-0"
                                onClick={() => onHideTopicSectionNumberingChange(!hideTopicSectionNumbering)}
                            ></button>
                            <div className="flex-grow">
                                <Trans>Use automatic section and topic numbering</Trans>
                            </div>
                            <RadioGroup
                                name={'numbering'}
                                radioType={'buttonGroup'}
                                onChange={onHideTopicSectionNumberingChange}
                                value={hideTopicSectionNumbering}
                                size="md"
                            >
                                <RadioGroup.Radio id={'radio-hide-numbering'} value={false}>
                                    <Trans>Yes</Trans>
                                </RadioGroup.Radio>
                                <RadioGroup.Radio id={'radio-show-numbering'} value={true}>
                                    <Trans>No</Trans>
                                </RadioGroup.Radio>
                            </RadioGroup>
                        </div>
                    </div>
                </Accordion.Item>
            </Accordion>
        </>
    );
};
