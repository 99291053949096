import React from 'react';
import { useParams } from 'react-router-dom';
import { faChartPie } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Card, colors, EmptyState, Skeleton } from '@wedo/design-system';
import { EmptyArray } from '@wedo/utils';
import { DonutChart, Item } from 'Pages/UserPage/components/DonutChart';
import { useGetUserStatsPrioritiesQuery } from 'Shared/services/dashboard';

const Priorities: Item[] = [
    {
        key: 'priority_4',
        title: <Trans>Important and urgent</Trans>,
        color: colors.red[300],
        value: 0,
    },
    {
        key: 'priority_3',
        title: <Trans>Important and not urgent</Trans>,
        color: colors.orange[300],
        value: 0,
    },
    {
        key: 'priority_2',
        title: <Trans>Not important and urgent</Trans>,
        color: colors.yellow[300],
        value: 0,
    },
    {
        key: 'priority_1',
        title: <Trans>Not important and not urgent</Trans>,
        color: colors.green[300],
        value: 0,
    },
    { key: 'priority_0', title: <Trans id="priority">Other</Trans>, color: colors.gray[300], value: 0 },
];
type Level = 'priority_0' | 'priority_1' | 'priority_2' | 'priority_3' | 'priority_4';

const isEmpty = (userPriorities: Item[]): boolean => {
    return userPriorities.every((item) => item.value === 0);
};

export const ColleagueTaskPriorities = (): JSX.Element => {
    const { userId } = useParams();

    const { data: userPriorities = EmptyArray, isLoading } = useGetUserStatsPrioritiesQuery(userId, {
        selectFromResult: ({ data, isLoading }) => {
            if (!data) {
                return { data: [], isLoading };
            }
            const sum = Priorities.reduce((prev: number, cur: Item) => prev + data[cur.key as Level], 0);
            return {
                data: Priorities.map((item) => ({
                    ...item,
                    value: data[item.key as Level],
                    percentage: Math.round((data[item.key as Level] / sum) * 100),
                })),
                isLoading: isLoading,
            };
        },
    });

    if (isLoading) {
        return <Skeleton className="col-span-6 xl:col-span-3" />;
    }

    return (
        <Card className="col-span-6 xl:col-span-3">
            <Card.Header title={t`Tasks priorities`} />
            <Card.Body>
                {isEmpty(userPriorities as Item[]) ? (
                    <div className="flex h-72 items-center justify-center">
                        <EmptyState icon={faChartPie}>
                            <EmptyState.Text>{t`No tasks`}</EmptyState.Text>
                        </EmptyState>
                    </div>
                ) : (
                    <div className="h-72">
                        <DonutChart data={userPriorities as Item[]} />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};
