import { ReactNode } from 'react';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { format as dfnsFormatDate } from 'date-fns';
import { getDateFnsLocale, getWeekOfMonth } from '@wedo/utils';
import { weekDaysShort } from 'Shared/components/meeting/addMeetingModal/MeetingFormRecurrence';

export const getNthDayText = (day: number): string => {
    switch (day) {
        case 1:
            return t`first`;
        case 2:
            return t`second`;
        case 3:
            return t`third`;
        case 4:
            return t`fourth`;
        case -1:
            return t`last`;
        default:
            return '?';
    }
};

export const getRRuleWeekOfMonth = (currentDate: Date) => {
    const weekOfMonth = getWeekOfMonth(currentDate);

    switch (weekOfMonth) {
        case 1:
        case 2:
        case 3:
        case 4:
            return weekOfMonth;
        case 5:
            return -1;
        default:
            return 0;
    }
};

export const getWeekDay = (currentDate: Date, i18n: I18n): string =>
    dfnsFormatDate(currentDate, 'EEEE', { locale: getDateFnsLocale(i18n?.locale) });

export const getWeekDayShort = (currentDate: Date): string => weekDaysShort[currentDate?.getDay()];

export type RecurrenceOption = {
    // What is displayed to the user
    label: ReactNode;
    // Here, the "slug" is an archetype of what the option is.
    // For instance, the option "Monthly, on the 16" would get the "monthly-x-day" slug, as would "Monthly, on the 4"
    slug: string;
    // The actual RRule value that will later be processed by the server
    value: string;
};
