import React, { FC, useContext } from 'react';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, Modal } from '@wedo/design-system';
import { useChangeStage } from 'Pages/settings/security/hooks/useChangeStage';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { StageEnable } from 'Pages/settings/security/utils/type';
import { useLogout } from 'Shared/hooks/useLogout';

type ModalFooterProps = {
    onNext?: () => void;
    isLoading?: boolean;
};

export const TotpModalFooter: FC<ModalFooterProps> = ({ onNext = null, isLoading = false }) => {
    const { next } = useChangeStage();
    const logout = useLogout();
    const {
        state: { force2fa },
        dispatch,
    } = useContext(TotpContext);

    const handleLogout = (): void => {
        dispatch({ type: 'stage', value: StageEnable.Done });
        setTimeout(() => {
            void logout();
        }, 300);
    };

    const handleNext = (): void => {
        if (onNext !== null) {
            onNext();
            return;
        }

        next();
    };

    return (
        <Modal.Footer>
            {force2fa && (
                <Button onClick={handleLogout} color="default" icon={faArrowRightFromBracket}>
                    <Trans>Log out</Trans>
                </Button>
            )}
            <Button onClick={handleNext} color="primary" loading={isLoading}>
                <Trans>Next</Trans>
            </Button>
        </Modal.Footer>
    );
};
