import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Input } from '@wedo/design-system';
import { Vote } from 'Shared/types/vote';

// TODO: implement
let Col,
    Row,
    Slider = 'div';

type VoteRatingSliderProps = {
    vote?: Vote;
    isDisabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const VoteRatingSlider = ({ vote, isDisabled, onChange }: VoteRatingSliderProps) => {
    const [sliderValue, setSliderValue] = useState(vote?.voteOptions?.length || 0);

    useEffect(() => {
        setSliderValue(vote?.voteOptions?.length || 0);
    }, [vote?.voteOptions]);

    return (
        <>
            <div>
                <Trans>Stars</Trans>
            </div>
            <Row gutter={[8, 8]}>
                <Col span={18}>
                    <Slider
                        min={1}
                        max={10}
                        disabled={isDisabled}
                        style={{ marginLeft: 0 }}
                        onChange={setSliderValue}
                        onAfterChange={onChange}
                        value={sliderValue}
                    />
                </Col>
                <Col span={6}>
                    <Input
                        type={'number'}
                        style={{ width: 'auto' }}
                        min={1}
                        max={10}
                        value={sliderValue}
                        disabled={isDisabled}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </>
    );
};
