import { isEmpty } from 'lodash-es';
import { type AvatarSizes, NavLink, Tag, Tooltip } from '@wedo/design-system';
import { useIsOverflowing } from '@wedo/utils/hooks';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserOptionsMenu } from 'Shared/components/user/UserOptionsMenu';
import { User } from 'Shared/types/user';
import { getFormattedUserRole, getUserRoleColorClasses } from 'Shared/utils/user';

type UserTileProps = {
    user: User;
    userAvatarSize?: AvatarSizes;
    href?: string;
    hideMenu?: boolean;
};

export const UserCard: React.FC<UserTileProps> = ({ user, userAvatarSize = 'md', href = null, hideMenu }) => {
    const [titleRef, isTitleOverflowing] = useIsOverflowing();
    const [emailOverflowing, isEmailOverflowing] = useIsOverflowing();

    return (
        <GridCard>
            <GridCard.Header isHoverable={!!href} title={user?.full_name}>
                <GridCard.Actions>
                    {!hideMenu && <div className="z-10">{<UserOptionsMenu user={user} />}</div>}
                </GridCard.Actions>
            </GridCard.Header>

            <GridCard.Body isHoverable={!!href}>
                <div className="flex max-w-full flex-col place-content-end items-center gap-2 overflow-hidden">
                    <UserAvatar user={user} size={userAvatarSize} showTooltip={false} />

                    <div className="flex max-w-full flex-col items-center gap-1">
                        <Tooltip
                            content={
                                isTitleOverflowing ? isEmpty(user.title?.trim()) ? <>&nbsp;</> : user.title : undefined
                            }
                        >
                            <div className={'max-w-full truncate'}>
                                <div ref={titleRef} className={'max-w-full truncate'}>
                                    {isEmpty(user.title?.trim()) ? <>&nbsp;</> : user.title}
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip content={isEmailOverflowing ? user.email_address || user.username : undefined}>
                            <div className={'max-w-full truncate'}>
                                <div ref={emailOverflowing} className={'max-w-full truncate'}>
                                    {user.email_address || user.username}
                                </div>
                            </div>
                        </Tooltip>

                        <Tag className="mt-4" color={getUserRoleColorClasses(user.role)}>
                            {getFormattedUserRole(user.role)}
                        </Tag>
                    </div>
                </div>
            </GridCard.Body>

            {href && <NavLink to={href} className="absolute z-0 h-full w-full" />}
        </GridCard>
    );
};
