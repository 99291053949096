import { I18nProvider } from '@lingui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { defaultQueryClient } from '~/modules/reactQuery/client';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { DebugTools } from '~/modules/debug/DebugTools';
import { Button, DateProvider, NotificationContextProvider, NotificationsContainer } from '@wedo/design-system';
import { Minute, Second } from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import store from 'App/store';
import { RumErrorBoundary } from 'Shared/components/RumErrorBoundary';
import { ErrorCard } from 'Shared/components/error/ErrorCard';
import { PdfViewerContextProvider } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';
import { useGetActiveMaintenanceQuery } from 'Shared/services/statusPage';
import { LocalStorage } from 'Shared/types/localStorage';
import { router as defaultRouter } from '../router';

const ActiveMaintenanceGuard = ({ children }: { children: ReactNode }) => {
    const [pollingInterval, setPollingInterval] = useState(5 * Minute);
    const { data: activeMaintenance } = useGetActiveMaintenanceQuery(null, {
        pollingInterval: pollingInterval,
    });

    useEffect(() => {
        if (activeMaintenance != null) {
            setPollingInterval(30 * Second);
        } else {
            setPollingInterval(5 * Minute);
        }
    }, [activeMaintenance]);

    if (activeMaintenance != null && activeMaintenance.status === 'in_progress') {
        return (
            <div className="w-full gap-6 items-center justify-items-center overflow-y-auto overflow-x-hidden px-12 grid grid-cols-1 py-12 max-w-4xl mx-auto">
                <img src="/assets/maintenance.svg" alt="" />

                <div className="flex flex-col gap-2 items-center">
                    <p className="text-2xl font-semibold text-gray-800 md:text-2xl">
                        <Trans>A maintenance is ongoing</Trans>
                    </p>
                    <p className="text-lg font-medium text-gray-500 text-center md:text-xl">
                        <Trans>WEDO is down for a scheduled maintenance, we will be back soon</Trans>
                    </p>
                </div>

                <p className="text-left text-base font-medium text-gray-800 self-start">
                    <Trans>
                        You can check the status of the maintenance on our{' '}
                        <a
                            href={activeMaintenance?.shortlink}
                            className="text-blue-500 hover:underline"
                            target="_blank"
                            rel="noreferrer"
                        >
                            status page
                        </a>
                    </Trans>
                </p>

                <div className="flex gap-4 w-full">
                    <Button onClick={() => window.location.reload()} color="gray" className="w-full">
                        <Trans>Reload the page</Trans>
                    </Button>
                </div>
            </div>
        );
    }
    return children;
};

export const Shell: FC<{
    router?: typeof defaultRouter;
}> = ({ router = defaultRouter }) => {
    const [queryClient] = useState(defaultQueryClient);

    const { userLanguage, dynamicActivate } = useUserLanguage();
    useEvent(`storage/${LocalStorage.Language}`, dynamicActivate);

    useEffect(() => {
        void dynamicActivate();
    }, []);

    return (
        <I18nProvider i18n={i18n}>
            <DateProvider locale={userLanguage} weekStartsOn={1}>
                <Provider store={store}>
                    <RumErrorBoundary
                        fallback={({ error }) => {
                            return <ErrorCard message={error.toString()} stack={error.stack} />;
                        }}
                    >
                        <QueryClientProvider client={queryClient}>
                            <ActiveMaintenanceGuard>
                                {(__ENVIRONMENT__ === 'development' || __ENVIRONMENT__ === 'review') && <DebugTools />}
                                <NotificationContextProvider>
                                    <PdfViewerContextProvider>
                                        <RouterProvider router={router} />
                                    </PdfViewerContextProvider>
                                    <NotificationsContainer />
                                </NotificationContextProvider>
                            </ActiveMaintenanceGuard>
                        </QueryClientProvider>
                    </RumErrorBoundary>
                </Provider>
            </DateProvider>
        </I18nProvider>
    );
};
