import { plural } from '@lingui/macro';
import { RecurrenceEndsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceEndsOnInput';
import { RecurrenceRepeatEveryInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatEveryInput';
import { RecurrenceRepeatOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatOnInput';
import { RecurrenceStartsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceStartsOnInput';
import { FormRecurrence, RecurrenceMonthDay, RecurrenceWeek, RecurrenceWeekDay } from 'Shared/types/formRecurrence';

export type RecurrenceMonthlyFormProps = {
    state: FormRecurrence;
    handleValueChange?: (name: string, value: number | string) => void;
    repeatEverySuffix?: string;
    toggleDay: (day: RecurrenceWeekDay | RecurrenceMonthDay, value: boolean) => void;
    toggleWeek: (week: RecurrenceWeek) => void;
};

export const RecurrenceMonthlyForm = ({
    state,
    handleValueChange,
    toggleDay,
    toggleWeek,
}: RecurrenceMonthlyFormProps): JSX.Element => {
    return (
        <div className={'grid grid-cols-12 items-center gap-x-3 gap-y-8'}>
            <RecurrenceStartsOnInput state={state} handleValueChange={handleValueChange} />
            <RecurrenceRepeatEveryInput
                state={state}
                repeatEverySuffix={plural(state.repeatEvery, { one: 'month', other: `months` })}
                handleValueChange={handleValueChange}
            />
            <RecurrenceRepeatOnInput
                handleValueChange={handleValueChange}
                state={state}
                toggleWeek={toggleWeek}
                toggleDay={toggleDay}
            />
            <RecurrenceEndsOnInput state={state} handleValueChange={handleValueChange} />
        </div>
    );
};
