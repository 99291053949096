import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { Select } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';

export const SectionRow: FC<BulkEditTaskRowProps<Id>> = ({ value, onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonSectionId } = useBulkTasksEditPane(selectedTasks);
    const { workspaceId, templateId, checklistId } = useParams<TasksPageParams>();
    const { sectionIdToSections } = useTaskSections({ workspaceId, templateId, checklistId });

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const sections = [...sectionIdToSections.values()].filter(({ id }) => id !== null);

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(undefined);
        } else if (change === 'edit') {
            if (!commonSectionId) {
                onChange(sections?.[0]?.id);
            } else {
                onChange(commonSectionId);
            }
        } else if (change === 'clear') {
            onChange(null);
        }
    };

    return (
        <BulkEditRow
            label={t`Section`}
            changeOption={changeValue}
            onChangeOptionChange={handleChange}
            isResetDisabled={commonSectionId === null}
        >
            <Select value={value} onChange={onChange} wrapperClassName="mt-2">
                {sections?.map((section) => (
                    <Select.Option key={section.id} value={section.id.toString()}>
                        {section.name}
                    </Select.Option>
                ))}
            </Select>
        </BulkEditRow>
    );
};
