import React from 'react';
import { ContextModalProps, Modal } from '@wedo/design-system';
import { AttachmentModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/AttachmentModalForm';
import { DateModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/DateModalForm';
import { DropdownModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/DropdownModalForm';
import { MultipleChoiceModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/MultipleChoiceModalForm';
import { NumberModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/NumberModalForm';
import { ShortTextModalForm } from 'Pages/settings/customFields/components/CustomFieldModalContent/ShortTextModalForm';
import {
    AttachmentCustomField,
    CustomField,
    CustomFieldType,
    DateCustomField,
    DropdownCustomField,
    MultipleChoiceCustomField,
    NumberCustomField,
    ShortTextCustomField,
} from 'Shared/types/customField';

type EditCustomFieldModalProps = {
    field: CustomField;
    groupId?: string;
} & ContextModalProps;

export const EditCustomFieldModal: React.FC<EditCustomFieldModalProps> = ({ field, groupId, ...modalProps }) => {
    const closeModal = modalProps.close;

    return (
        <Modal {...modalProps}>
            {field.type === CustomFieldType.ShortText && (
                <ShortTextModalForm
                    onCancel={closeModal}
                    field={field as ShortTextCustomField}
                    onSuccess={closeModal}
                    groupId={groupId}
                />
            )}

            {field.type === CustomFieldType.Number && (
                <NumberModalForm
                    onCancel={closeModal}
                    field={field as NumberCustomField}
                    onSuccess={closeModal}
                    groupId={groupId}
                />
            )}

            {field.type === CustomFieldType.Date && (
                <DateModalForm
                    onCancel={closeModal}
                    field={field as DateCustomField}
                    onSuccess={closeModal}
                    groupId={groupId}
                />
            )}

            {field.type === CustomFieldType.Attachment && (
                <AttachmentModalForm
                    field={field as AttachmentCustomField}
                    onCancel={closeModal}
                    onSuccess={closeModal}
                    groupId={groupId}
                />
            )}

            {field.type === CustomFieldType.MultipleChoice && (
                <MultipleChoiceModalForm
                    field={field as MultipleChoiceCustomField}
                    onCancel={closeModal}
                    onSuccess={closeModal}
                    groupId={groupId}
                />
            )}

            {field.type === CustomFieldType.Dropdown && (
                <DropdownModalForm
                    field={field as DropdownCustomField}
                    onCancel={closeModal}
                    onSuccess={closeModal}
                    groupId={groupId}
                />
            )}
        </Modal>
    );
};
