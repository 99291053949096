import { useEffect, useMemo, useState } from 'react';
import { faInfo } from '@fortawesome/pro-regular-svg-icons';
import { plural, Plural, t, Trans } from '@lingui/macro';
import { v4 as uuidv4 } from 'uuid';
import {
    Bubble,
    Button,
    getRandomWedoColor,
    ModalType,
    Switch,
    Textarea,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useAppDispatch } from 'App/store';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { EditMeetingAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingAccessModal';
import { MeetingUsersAvatarGroup } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUsersAvatarGroup';
import { MeetingViewSearchParams, useSelectedTopicId } from 'Pages/meeting/components/MeetingView/MeetingView';
import { ConfirmOutcomeModal } from 'Pages/meeting/components/Vote/ConfirmOutcomeModal';
import { EditVoteResultsModal } from 'Pages/meeting/components/Vote/EditVoteResultsModal';
import {
    generateVoteOptionAbstained,
    generateVoteOptionRequestedDiscussion,
    MAX_VOTE_CONTENT_LENGTH,
    MAX_VOTE_TITLE_LENGTH,
} from 'Pages/meeting/components/Vote/Vote';
import {
    useMeetingPresentShares,
    useMeetingPresentVoters,
    useMeetingQuorumAttained,
    useMeetingTotalShares,
    useMeetingVoters,
    usePendingVotes,
    useVoteHasAnswers,
} from 'Pages/meeting/components/Vote/VoteHooks';
import { VoteOptionComponent } from 'Pages/meeting/components/Vote/VoteOption';
import { VoteOptionRating } from 'Pages/meeting/components/Vote/VoteOptionRating';
import { VoteRatingSlider } from 'Pages/meeting/components/Vote/VoteRatingSlider';
import {
    getOptionCount,
    getValueRelativeShareFraction,
    getVoteOptionRelativeShareFraction,
    shouldDisplayShares,
} from 'Pages/meeting/components/Vote/VoteUtils';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    invalidateMeetingTopicBlocks,
    UpdateMeetingTopicBlocksFixedCacheKey,
    useUpdateMeetingTopicBlocksMutation,
} from 'Shared/services/meetingBlock';
import {
    buildGetVoteBlockParameters,
    useGetVoteQuery,
    useResetVoteResultsMutation,
    useUpdateVoteMutation,
    useUpdateVoteOptionsMutation,
} from 'Shared/services/meetingVote';
import { Vote, VoteOption } from 'Shared/types/vote';

export const VoteDetail = ({ voteId, meetingId }: { voteId: Id; meetingId: Id }) => {
    const dispatch = useAppDispatch();

    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();
    const { open: openModal } = useModal();
    const { show: showNotification } = useNotification();

    const { data: vote } = useGetVoteQuery(buildGetVoteBlockParameters(voteId, meetingId), {
        skip: voteId == null,
        refetchOnMountOrArgChange: true,
    });
    const [updateVote] = useUpdateVoteMutation();
    const [updateVoteOptions] = useUpdateVoteOptionsMutation();
    const [resetVoteResults] = useResetVoteResultsMutation();
    const [updateMeetingTopicBlocks] = useUpdateMeetingTopicBlocksMutation({
        fixedCacheKey: UpdateMeetingTopicBlocksFixedCacheKey,
    });

    const [{ viewMode }] = useSearchParams(MeetingViewSearchParams);

    const topicId = useSelectedTopicId();

    // Local state
    const [updatedVote, setUpdatedVote] = useState<Vote>(vote);
    const [isUpdatingAnswers, setIsUpdatingAnswers] = useState(false);

    // Computed values
    const voters = useMeetingVoters(meetingId);
    const presentVoters = useMeetingPresentVoters(meetingId);
    const presentShares = useMeetingPresentShares(meetingId);

    const totalShares = useMeetingTotalShares(meetingId);
    const displayShares = shouldDisplayShares(voters);
    const quorumAttained = useMeetingQuorumAttained(meetingId);

    const selectedOutcome = useMemo(() => {
        if (vote?.outcome_vote_option_id) {
            return vote.voteOptions.find((option) => option.id === vote.outcome_vote_option_id);
        }
        return null;
    }, [vote?.outcome_vote_option_id]);

    useEffect(() => {
        setUpdatedVote(vote);
    }, [vote]);

    const showConfirmChangeAnswerModal = async (confirmCallback: () => void) => {
        const applyOn = (await confirm(
            {
                title: t`You are about to change the answers to a vote that already has results`,
                size: 'sm',
                type: ModalType.Info,
                customApplyOn: [
                    { message: t`Keep results`, value: 'keep' },
                    { message: t`Reset results`, value: 'reset', type: 'danger', confirmText: t`Reset` },
                ],
                showAll: false,
                showFuture: false,
                showThis: false,
                defaultOption: 'keep',
                content: (
                    <div className={'text-sm'}>
                        <Trans>What do you want to do with the existing results?</Trans>
                    </div>
                ),
            },
            ConfirmSaveMeetingModal
        )) as unknown as string;

        if (applyOn == null) {
            return;
        }

        if (applyOn === 'reset') {
            await resetVoteResults({
                meetingId: meetingId,
                voteId: vote.id,
            });
        }
        confirmCallback();
    };

    const handleVoteChanges = async (changes: Partial<Vote>) => {
        setUpdatedVote({ ...updatedVote, ...changes });
        await updateVote({ meetingId: meetingId, voteId: vote.id, changes })
            .unwrap()
            .catch(() => showNotification(UnexpectedErrorNotification));
    };

    const handleVoteOptionChanges = async (voteOptionId: Id, changes: Partial<VoteOption>) => {
        await updateVoteOptions({
            meetingId: meetingId,
            voteId: vote.id,
            data: {
                updatedVoteOptions: [{ id: voteOptionId, changes: changes }],
            },
        })
            .unwrap()
            .catch(() => showNotification(UnexpectedErrorNotification));
    };

    const validateDecision = async () => {
        if (!vote?.outcome) {
            return;
        }
        const voteTopicId = viewMode === MeetingViewMode.TopicView ? topicId : vote?.meetingBlocks[0].meeting_topic_id;

        await updateMeetingTopicBlocks({
            meetingId,
            topicId: voteTopicId,
            addedBlocks: [
                {
                    id: uuidv4(),
                    type: 'decision',
                    children: [{ text: vote?.outcome }],
                },
            ],
        });
        dispatch(invalidateMeetingTopicBlocks(voteTopicId));
        await updateVote({
            meetingId: meetingId,
            voteId: vote.id,
            changes: { outcome: null },
        })
            .unwrap()
            .catch(() => showNotification(UnexpectedErrorNotification));
    };

    const addNewVoteOption = async () => {
        const addNewVoteOptionCallback = async () =>
            updateVoteOptions({
                meetingId: meetingId,
                voteId: vote.id,
                data: {
                    addedVoteOptions: [
                        {
                            value: '',
                            color: getRandomWedoColor(),
                        },
                    ],
                },
            })
                .unwrap()
                .catch(() => showNotification(UnexpectedErrorNotification));
        await addNewVoteOptionCallback();
    };

    const deleteVoteOption = async (voteOptionId: Id) => {
        const deleteOptionCallback = async () =>
            updateVoteOptions({
                meetingId: meetingId,
                voteId: vote.id,
                data: {
                    deletedVoteOptions: [voteOptionId],
                },
            })
                .unwrap()
                .catch(() => showNotification(UnexpectedErrorNotification));
        await deleteOptionCallback();
    };

    const confirmOutcome = async (voteOptionId: Id) => {
        if (vote?.status !== 'closed') {
            await handleVoteChanges({ status: 'closed' });
        }
        await handleVoteChanges({ outcome_vote_option_id: voteOptionId });
        await validateDecision();
    };

    const voteHasAnswers = useVoteHasAnswers(vote);
    const voteOptionMode = useMemo(() => {
        if (voteHasAnswers && !isUpdatingAnswers) {
            return 'results-detail';
        }
        return 'editable';
    }, [voteHasAnswers, isUpdatingAnswers, voters]);

    const abstainedOption = useMemo(() => generateVoteOptionAbstained(vote, voteOptionMode), [vote]);
    const requestedDiscussionOption = useMemo(
        () => generateVoteOptionRequestedDiscussion(vote, voteOptionMode),
        [vote]
    );
    const pendingVotes = usePendingVotes(vote, meetingId);

    const shouldDisplayAbstainedOption = useMemo(() => {
        if (isUpdatingAnswers) {
            return false;
        }
        if (vote?.voteAnswers == null || vote?.voteAnswers.length === 0) {
            return vote?.abstained_count > 0;
        }
        return vote?.voteAnswers?.find((a) => a.status === 'abstained') != null && vote?.voteAnswers.length > 0;
    }, [vote?.voteAnswers, voters, vote?.abstained_count, isUpdatingAnswers]);
    const shouldDisplayRequestDiscussionOption = useMemo(() => {
        if (isUpdatingAnswers) {
            return false;
        }
        return (
            voters?.length > 0 &&
            vote?.voteAnswers?.find((a) => a.status === 'requested_discussion') != null &&
            vote?.voteAnswers.length > 0
        );
    }, [vote?.voteAnswers, voters, isUpdatingAnswers]);

    return (
        <>
            <div className={'text-sm'}>
                <div className={'relative h-full overflow-auto'}>
                    <div className="pl-5 pr-2 pt-1">
                        <Textarea
                            className={''}
                            size={'lg'}
                            borderless
                            debounce
                            disabled={vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted}
                            placeholder={t`Title`}
                            id={'title'}
                            onChange={(e) => handleVoteChanges({ title: e.target.value })}
                            value={updatedVote?.title || ''}
                            maxLength={MAX_VOTE_TITLE_LENGTH}
                        />
                    </div>
                    <div className={'pl-6 pr-4 pt-4'}>
                        <Textarea
                            wrapperClassName={'mb-4 h-28'}
                            debounce
                            disabled={vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted}
                            rows={3}
                            placeholder={t`Description`}
                            id={'description'}
                            onChange={(e) => handleVoteChanges({ description: e.target.value })}
                            value={updatedVote?.description ?? ''}
                            maxLength={MAX_VOTE_CONTENT_LENGTH}
                        />
                        <div className={'flex items-center justify-between'}>
                            <div className={'mb-2 text-sm font-semibold text-gray-500'}>{t`Answers`}</div>
                            <Button
                                variant={'link'}
                                color={'primary'}
                                onClick={() => openModal(EditVoteResultsModal, { voteId, meetingId })}
                            >
                                {vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted
                                    ? t`View results`
                                    : t`Edit results`}
                            </Button>
                        </div>

                        {vote?.voteOptions && vote?.voteOptions.length > 0 ? (
                            <div className={'mb-2'}>
                                {vote?.voteOptions?.map((option) =>
                                    vote.type === 'rating' ? (
                                        <VoteOptionRating
                                            key={`starOption-${option.value}`}
                                            mode={
                                                voteHasAnswers ||
                                                vote?.status === 'closed' ||
                                                meeting?.status === 'locked' ||
                                                meeting?.deleted
                                                    ? 'results'
                                                    : 'editable'
                                            }
                                            disabled={
                                                voteHasAnswers ||
                                                vote?.status === 'closed' ||
                                                meeting?.status === 'locked' ||
                                                meeting?.deleted
                                            }
                                            label={option.label || ''}
                                            stars={parseInt(option.value, 10)}
                                            voteOption={option}
                                            fraction={getValueRelativeShareFraction(option, vote, meeting)}
                                            voteCount={getOptionCount({
                                                voteOption: option,
                                                vote,
                                                meetingUsers: meeting?.meetingUsers,
                                            })}
                                            meetingId={meetingId}
                                        />
                                    ) : (
                                        <VoteOptionComponent
                                            mode={voteOptionMode}
                                            handleUpdate={(changes) => handleVoteOptionChanges(option.id, changes)}
                                            handleDelete={() => deleteVoteOption(option.id)}
                                            key={`vote-option-edit-${option.id}`}
                                            voteOption={option}
                                            fraction={getVoteOptionRelativeShareFraction(option, vote, meeting)}
                                            voteCount={getOptionCount({
                                                voteOption: option,
                                                vote,
                                                meetingUsers: meeting?.meetingUsers,
                                            })}
                                            isDisabled={
                                                vote?.status === 'closed' ||
                                                meeting?.status === 'locked' ||
                                                meeting?.deleted
                                            }
                                            meetingId={meetingId}
                                            voteStatus={vote?.status}
                                        />
                                    )
                                )}
                                {shouldDisplayAbstainedOption && (
                                    <VoteOptionComponent
                                        key={'vote-option-abstained'}
                                        mode={voteOptionMode}
                                        voteOption={abstainedOption}
                                        fraction={getVoteOptionRelativeShareFraction(abstainedOption, vote, meeting)}
                                        isDisabled={true}
                                        voteCount={getOptionCount({
                                            voteOption: abstainedOption,
                                            vote,
                                            meetingUsers: meeting?.meetingUsers,
                                        })}
                                        meetingId={meetingId}
                                        voteStatus={vote?.status}
                                    />
                                )}
                                {shouldDisplayRequestDiscussionOption && (
                                    <VoteOptionComponent
                                        key={'vote-option-requested-discussion'}
                                        mode={voteOptionMode}
                                        isDisabled={true}
                                        voteOption={requestedDiscussionOption}
                                        fraction={getVoteOptionRelativeShareFraction(
                                            requestedDiscussionOption,
                                            vote,
                                            meeting
                                        )}
                                        voteCount={getOptionCount({
                                            voteOption: requestedDiscussionOption,
                                            vote,
                                            meetingUsers: meeting?.meetingUsers,
                                        })}
                                        meetingId={meetingId}
                                        voteStatus={vote?.status}
                                    />
                                )}
                            </div>
                        ) : (
                            <div
                                className={'mb-2'}
                            >{t`There are currently no answers to choose from, add one using the button below`}</div>
                        )}
                        <div className={'mb-4 flex justify-between gap-2'}>
                            {!['rating', 'linear_scale'].includes(vote?.type) && (
                                <>
                                    {!isUpdatingAnswers && voteHasAnswers && (
                                        <Button
                                            disabled={
                                                vote?.status === 'closed' ||
                                                meeting?.status === 'locked' ||
                                                meeting?.deleted
                                            }
                                            onClick={() =>
                                                showConfirmChangeAnswerModal(() => setIsUpdatingAnswers(true))
                                            }
                                        >{t`Edit answers`}</Button>
                                    )}
                                    {(isUpdatingAnswers || !voteHasAnswers) && (
                                        <Button
                                            disabled={
                                                vote?.status === 'closed' ||
                                                meeting?.status === 'locked' ||
                                                meeting?.deleted
                                            }
                                            onClick={() => addNewVoteOption()}
                                        >{t`Add answer`}</Button>
                                    )}
                                </>
                            )}
                            {isUpdatingAnswers && voteHasAnswers && (
                                <Button color={'primary'} onClick={() => setIsUpdatingAnswers(false)}>{t`Done`}</Button>
                            )}
                        </div>

                        <div className={'mb-4'}>
                            <div className="mb-2 text-sm font-semibold text-gray-500">{t`Options`}</div>
                            {vote?.type === 'rating' && (
                                <VoteRatingSlider
                                    onChange={(e) => handleVoteChanges({ scale_start: 1, scale_end: e.target.value })}
                                    vote={vote}
                                    isDisabled={
                                        voteHasAnswers ||
                                        vote?.status === 'closed' ||
                                        meeting?.status === 'locked' ||
                                        meeting?.deleted
                                    }
                                />
                            )}
                            {/* TODO: See what to do with request discussion / abstain options */}
                            {['single', 'multiple'].indexOf(vote?.type) > -1 && (
                                <>
                                    <div className={'mb-2'}>
                                        <Switch.Group>
                                            <div className={'flex items-center gap-2'}>
                                                <Switch
                                                    disabled={
                                                        vote?.status === 'closed' ||
                                                        meeting?.status === 'locked' ||
                                                        meeting?.deleted
                                                    }
                                                    checked={updatedVote?.can_abstain}
                                                    onChange={(checked: boolean) =>
                                                        handleVoteChanges({ can_abstain: checked })
                                                    }
                                                />
                                                <Switch.Label>{t`Can abstain`}</Switch.Label>
                                            </div>
                                        </Switch.Group>
                                    </div>
                                    {voters?.length > 0 && (
                                        <Switch.Group>
                                            <div className={'flex items-center gap-2'}>
                                                <Switch
                                                    disabled={
                                                        vote?.status === 'closed' ||
                                                        meeting?.status === 'locked' ||
                                                        meeting?.deleted
                                                    }
                                                    checked={updatedVote?.can_request_discussion}
                                                    onChange={(checked: boolean) =>
                                                        handleVoteChanges({ can_request_discussion: checked })
                                                    }
                                                />
                                                <Switch.Label>{t`Can request discussion`}</Switch.Label>
                                            </div>
                                        </Switch.Group>
                                    )}
                                </>
                            )}
                        </div>
                        <div className={'my-4'}>
                            <div className={'flex items-center justify-between'}>
                                <div className="relative mb-2 text-sm font-semibold text-gray-500">
                                    {t`Voters`}
                                    <Tooltip content={t`Voters are common to all votes in the meeting`}>
                                        <Bubble size="sm" className={'absolute -right-4 -top-1'} icon={faInfo} />
                                    </Tooltip>
                                </div>
                                <Button
                                    variant={'link'}
                                    color={'primary'}
                                    onClick={() =>
                                        openModal(EditMeetingAccessModal, {
                                            meetingId: meeting.id,
                                            mode: 'voter',
                                            size: 'md',
                                        })
                                    }
                                >
                                    {t`Manage voters`}
                                </Button>
                            </div>
                            <div className={'flex justify-between'}>
                                <div>
                                    <div className={'mb-2 flex items-center justify-between'}>
                                        {voters != null && voters.length > 0 ? (
                                            <Button
                                                onClick={() =>
                                                    openModal(EditMeetingAccessModal, {
                                                        meetingId: meeting?.id,
                                                        mode: 'voter',
                                                        size: 'md',
                                                    })
                                                }
                                                variant="ghost"
                                            >
                                                <MeetingUsersAvatarGroup
                                                    meetingUsers={voters}
                                                    maxDisplayed={10}
                                                    size="sm"
                                                />
                                            </Button>
                                        ) : (
                                            <div>{t`No voters exist in this meeting`}</div>
                                        )}
                                    </div>
                                    {voters != null &&
                                        voters.length > 0 &&
                                        pendingVotes > 0 &&
                                        (vote?.status === 'closed' ? (
                                            <div className={'mb-2 text-xs text-gray-800'}>
                                                <Plural
                                                    value={pendingVotes}
                                                    one={`${pendingVotes} voter didn't answer`}
                                                    other={`${pendingVotes} voters didn't answer`}
                                                />
                                            </div>
                                        ) : (
                                            <div className={'mb-2 text-xs text-gray-800'}>
                                                <Plural
                                                    value={pendingVotes}
                                                    one={`${pendingVotes} vote pending`}
                                                    other={`${pendingVotes} votes pending`}
                                                />
                                            </div>
                                        ))}
                                    {voters != null && voters.length > 0 && pendingVotes === 0 && (
                                        <div className={'mb-2 text-green-600'}>{t`All voters have voted`}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'pl-6 pr-4'}>
                        {meeting?.settings?.vote?.require_quorum &&
                            totalShares > 0 &&
                            (quorumAttained ? (
                                <div className={'mb-4 p-2 text-end text-green-600'}>
                                    {`${t`Quorum reached`} (${displayShares ? presentShares : presentVoters.length}/${
                                        displayShares
                                            ? plural(totalShares, {
                                                  one: '# share',
                                                  other: '# shares',
                                              })
                                            : voters.length
                                    })`}
                                </div>
                            ) : (
                                <div className={'p-2 text-end text-red-600'}>
                                    {`${t`Quorum not reached`} (${
                                        displayShares ? presentShares : presentVoters.length
                                    }/${
                                        displayShares
                                            ? plural(totalShares, {
                                                  one: '# share',
                                                  other: '# shares',
                                              })
                                            : voters.length
                                    })`}
                                </div>
                            ))}
                        {!selectedOutcome && vote?.outcome != null && (
                            <>
                                <div className="mb-2 text-sm font-semibold text-gray-500">{t`Decision`}</div>
                                <Textarea
                                    wrapperClassName={'mb-4'}
                                    className={'h-28'}
                                    value={vote?.outcome}
                                    rows={3}
                                    debounce
                                    disabled={meeting?.status === 'locked' || meeting?.deleted}
                                    onChange={(e) => handleVoteChanges({ outcome: e.target.value })}
                                />
                            </>
                        )}

                        {['single', 'multiple'].indexOf(vote?.type) > -1 && !selectedOutcome && (
                            <div className={'mb-2 flex justify-center'}>
                                <Button
                                    color={'primary'}
                                    className={'w-full'}
                                    onClick={() =>
                                        openModal(ConfirmOutcomeModal, {
                                            meetingId,
                                            vote,
                                            onDone: confirmOutcome,
                                        })
                                    }
                                    disabled={
                                        vote?.voteOptions == null ||
                                        vote?.voteOptions.length === 0 ||
                                        meeting?.status === 'locked' ||
                                        meeting?.deleted
                                    }
                                >{t`Confirm outcome`}</Button>
                            </div>
                        )}

                        {selectedOutcome && (
                            <div>
                                <div className={'flex items-center justify-between'}>
                                    <div className="mb-2 text-sm font-semibold text-gray-500">{t`Vote outcome`}</div>
                                    <Button
                                        color={'primary'}
                                        variant={'link'}
                                        onClick={() =>
                                            openModal(ConfirmOutcomeModal, {
                                                meetingId,
                                                vote,
                                                onDone: confirmOutcome,
                                            })
                                        }
                                    >
                                        {t`Change outcome`}
                                    </Button>
                                </div>
                                <VoteOptionComponent
                                    meetingId={meetingId}
                                    mode={'vote'}
                                    hideUserFaces={true}
                                    isSelected={true}
                                    key={`vote-option-edit-${selectedOutcome.id}`}
                                    voteOption={selectedOutcome}
                                    isDisabled={false}
                                    voteStatus={'open'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
