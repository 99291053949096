import React, { PropsWithChildren, useState } from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Alert,
    Button,
    CloseSource,
    CodeInput,
    ContextModalProps,
    Label,
    Modal,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { trpc, trpcUtils } from 'Shared/trpc';

type ConfirmPhoneNumberModalProps = {
    phoneNumber: string;
    onDone?: () => void;
} & ContextModalProps &
    PropsWithChildren;

export const ConfirmPhoneNumberModal = ({
    phoneNumber,
    children,
    onDone,
    ...modalProps
}: ConfirmPhoneNumberModalProps) => {
    const [error, setError] = useState('');
    const { show: showNotification } = useNotification();
    const { confirm: showConfirm } = useConfirm();
    const { mutate: verifyVerificationCode } = trpc.signature.verifyPhoneVerificationCode.useMutation({
        onSuccess: () => {
            showNotification({
                type: 'success',
                title: t`Phone number successfully verified`,
                message: t`Your changes have been saved`,
            });
            void trpcUtils().user.getSettingsByKey.invalidate('signature');
            void modalProps.close();
        },
        onError: () => {
            setError(t`Invalid verification code`);
        },
    });

    const handleCompleteCode = (code: string) => {
        verifyVerificationCode({
            phoneNumber,
            verificationCode: code,
        });
    };

    const handleBeforeClose = async (source: CloseSource) => {
        let close = true;
        if (source !== 'api') {
            close = await showConfirm(
                {
                    title: t`Are you sure you want to leave without confirming your phone number?`,
                    confirmText: t`Leave`,
                },
                ConfirmDiscardChangesModal
            );
        }
        if (close) {
            onDone?.();
        }
        return close;
    };

    return (
        <Modal {...modalProps} onBeforeClose={handleBeforeClose}>
            <Modal.Header title={t`Confirm your phone number`} />
            <Modal.Body className={'flex flex-col gap-2'}>
                <Alert type={'info'}> {t`An SMS code has been sent to your mobile phone.`}</Alert>
                <Label>{t`Confirmation code`}</Label>
                <CodeInput
                    autofocus
                    onComplete={handleCompleteCode}
                    onChange={() => {
                        setError('');
                    }}
                    status={error ? 'error' : 'default'}
                    length={4}
                />
                {!isEmpty(error) && <Alert type={'danger'}>{t`The confirmation code is incorrect`}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
