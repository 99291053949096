import React, { FC } from 'react';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Tag, UploadButton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { Attachment } from 'Shared/types/attachment';

type FeedEditorAttachmentProps = {
    oldAttachments: Partial<Attachment>[];
    newAttachments: File[];
    onDeleteOldAttachment: (id: Id) => void;
    onDeleteNewAttachment: (name: string) => void;
    onAddAttachment: (files: File[]) => void;
};

export const FeedEditorAttachment: FC<FeedEditorAttachmentProps> = ({
    oldAttachments,
    newAttachments,
    onDeleteOldAttachment,
    onDeleteNewAttachment,
    onAddAttachment,
}) => {
    return (
        <div className="bg-yellow-50 px-2 py-1 border-t flex flex-wrap items-center gap-2">
            <UploadButton
                aria-label={t`Add an attachment`}
                icon={faPaperclip}
                size="sm"
                multiple
                onFilesSelect={onAddAttachment}
            />
            {oldAttachments.map((attachment) => {
                return (
                    <Tag
                        key={attachment.download_url}
                        size="xs"
                        onRemove={() => onDeleteOldAttachment(attachment.attachment_version_id)}
                    >
                        {attachment.filename}
                    </Tag>
                );
            })}
            {[...newAttachments.values()].map((file: File) => {
                return (
                    <Tag size="xs" key={file.name} onRemove={() => onDeleteNewAttachment(file.name)}>
                        {file.name}
                    </Tag>
                );
            })}
        </div>
    );
};
