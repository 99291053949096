import React from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isString } from 'lodash-es';
import {
    Button,
    EditImageModal,
    type PixelCrop,
    Skeleton,
    UploadButton,
    useConfirm,
    useModal,
} from '@wedo/design-system';
import { fileToImage, getFormattedBytes, totalBytes } from '@wedo/utils';

type LogoSettingsProps = {
    logo?: string | object;
    isLogoLoading?: boolean;
    isUpdateLogoLoading?: boolean;
    isDeleteLogoLoading?: boolean;
    onLogoDelete?: () => void;
    logoMinWidth: number;
    logoMinHeight: number;
    onImageEdit?: (file: File, crop?: PixelCrop) => void;
    addText?: string;
    deleteText?: string;
    replaceText?: string;
    aspect?: number;
    placeholderImage?: 'logo' | 'icon';
};

const FIVE_MB = totalBytes(5, 'mb');

export const LogoSettings: React.FC<LogoSettingsProps> = ({
    isLogoLoading = false,
    logo,
    isUpdateLogoLoading,
    isDeleteLogoLoading,
    onLogoDelete,
    onImageEdit,
    logoMinWidth,
    logoMinHeight,
    addText = t`Choose logo`,
    deleteText = t`Delete logo`,
    replaceText = t`Replace logo`,
    aspect,
    placeholderImage = 'logo',
}) => {
    const hasLogo = isString(logo);

    const { confirm } = useConfirm();
    const { open } = useModal();

    const showFileTooLargeError = async (file: File): Promise<void> => {
        const fileTooLargeMessage = t`The image that you have selected (${getFormattedBytes(
            file.size
        )}) is larger than the allowed limit of 5 MB.`;

        await confirm({
            type: 'danger',
            title: t`Image too large`,
            content: fileTooLargeMessage,
            confirmText: t`Close`,
            isCancelButtonVisible: false,
        });
    };

    const showImageResolutionLowError = async (image: HTMLImageElement): Promise<void> => {
        const invalidResolutionMessage = t`Your image (${image.width} x ${image.height} pixels) is smaller than ${logoMinWidth} x ${logoMinHeight} pixels. Please select a larger image`;

        await confirm({
            type: 'danger',
            title: t`Image resolution too low `,
            content: invalidResolutionMessage,
            confirmText: t`Close`,
            isCancelButtonVisible: false,
        });
    };

    const imageResolutionTooLow = (image: HTMLImageElement): boolean =>
        image.width < logoMinHeight || image.height < logoMinHeight;

    const handleFileSelect = async (file: File): Promise<void> => {
        if (file.size > FIVE_MB) {
            await showFileTooLargeError(file);
            return;
        }
        let image: HTMLImageElement;
        try {
            image = await fileToImage(file);
        } catch {
            void confirm({
                type: 'danger',
                title: t`Invalid image format`,
                content: t`Please check the image extension and format, and try once again`,
                isCancelButtonVisible: false,
                confirmText: t`Close`,
            });
            return;
        }
        if (file.type === 'image/svg+xml') {
            onImageEdit(file);
            return;
        }
        if (imageResolutionTooLow(image)) {
            await showImageResolutionLowError(image);
            return;
        }
        open(EditImageModal, {
            title: t`Crop the logo to your preference`,
            image: file,
            showCroppedImageSize: true,
            minHeight: logoMinHeight,
            minWidth: logoMinWidth,
            onEdit: (crop: PixelCrop) => onImageEdit(file, crop),
            aspect,
        });
    };

    const WedoLogo =
        placeholderImage === 'logo' ? (
            <img src="/assets/logo/logo.svg" className={clsx('opacity-30')} alt={t`WEDO logo`} />
        ) : (
            <img src="/assets/logo/icon.svg" className={clsx('opacity-30')} alt={t`WEDO icon`} />
        );

    return (
        <div className="flex flex-col gap-4 sm:flex-row">
            <div className={clsx('flex h-28 w-28 items-center justify-center rounded-md border border-gray-200 p-3')}>
                {isLogoLoading ? (
                    <Skeleton shape="circle" className="h-20 w-20" />
                ) : hasLogo ? (
                    <img className="object-contain h-full" alt={t`Logo`} src={logo} />
                ) : (
                    WedoLogo
                )}
            </div>

            <div className="flex flex-col gap-2 lg:flex-col xl:justify-between xl:gap-0">
                <UploadButton
                    className="w-full max-w-[200px]"
                    loading={isUpdateLogoLoading}
                    color="primary"
                    variant="filled"
                    onFileSelect={handleFileSelect}
                    accept="image/*"
                >
                    {hasLogo ? replaceText : addText}
                </UploadButton>

                <Button
                    className="w-full max-w-[200px]"
                    color="danger"
                    variant="outlined"
                    disabled={!hasLogo || isUpdateLogoLoading}
                    onClick={onLogoDelete}
                    loading={isDeleteLogoLoading}
                >
                    {deleteText}
                </Button>
                <div className="text-xs text-gray-700">
                    <Trans>
                        Maximum file size allowed is 5MB, and minimum resolution is {logoMinWidth}x{logoMinHeight}{' '}
                        pixels.
                    </Trans>
                </div>
            </div>
        </div>
    );
};
