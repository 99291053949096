import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { useLoader } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { useAddTaskMutation } from 'Shared/services/task';
import { useGanttContextStore } from './GanttContext';
import { GanttPageParams } from './GanttPage';
import { type SectionWithTasks } from './types';

type ListNewTaskProps = {
    section: SectionWithTasks;
};

export const ListAddTask = ({ section }: ListNewTaskProps) => {
    const sessionUser = useSessionUser();

    const queryClient = useQueryClient();

    const store = useGanttContextStore()!;

    const { workspaceId } = useParams<GanttPageParams>();

    const [addTask] = useAddTaskMutation();
    const { isLoading: isAddingTask, wrap } = useLoader();

    const handleAddTask = () =>
        wrap(async () => {
            const result = await addTask({
                name: '',
                planned_date: new Date().toISOString(),
                tags: [
                    {
                        id: workspaceId,
                        tag_section_id: section.id === '' ? undefined : section.id,
                        order: section.tasks.length,
                    },
                ],
                watchers: [{ id: sessionUser.id }],
                keepCache: true,
            });
            if (result.data?.id != null) {
                store.setState((state) => {
                    state.view.focusedTaskId = result.data.id;
                });
            }
            await invalidateQueries(queryClient, [taskQueryTag.added()]);
        });

    return (
        <div className="grid grid-cols-subgrid col-span-4" data-add-task={section.id}>
            <div />
            <div className="col-span-3 flex gap-3 w-full items-center pl-6 pr-2">
                <Button
                    icon={faPlus}
                    variant="ghost"
                    onClick={handleAddTask}
                    loading={isAddingTask}
                    className={clsx(
                        'text-gray-400 flex items-center w-full !font-normal',
                        isAddingTask ? 'pl-1.5 gap-2' : 'pl-2.5 gap-[calc(0.625rem+2px)]'
                    )}
                >
                    <Trans>Add task</Trans>
                </Button>
            </div>
        </div>
    );
};
