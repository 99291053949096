import { FC, useEffect, useId, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form } from '~/components/Form/Form';
import { Input } from '~/components/Input/Input';
import { Label } from '~/components/Label/Label';

type VerificationInputProps = {
    verificationText: string;
    onMatchChange: (value: boolean) => void;
    onConfirm: () => void;
    triggerError: number;
};

export const VerificationInput: FC<VerificationInputProps> = ({
                                                                  verificationText,
                                                                  onMatchChange,
                                                                  onConfirm,
                                                                  triggerError,
                                                              }) => {
    const [verificationInput, setVerificationInput] = useState('');
    const [submitTried, setSubmitTried] = useState(false);
    const id = useId();

    useEffect(() => {
        if (verificationText === null) {
            onMatchChange(true);
        }
    }, [verificationText])

    if (verificationText === null) {
        return null;
    }

    const checkStringsMatch = (a: string, b: string) =>
        a.replace(/\s/g, '').toLowerCase() === b.replace(/\s/g, '').toLowerCase();

    const showError = !checkStringsMatch(verificationText, verificationInput) && submitTried;

    const handleVerificationUpdate = (newValue: string) => {
        setSubmitTried(false);
        setVerificationInput(newValue);
        onMatchChange(checkStringsMatch(verificationText, newValue));
    };

    const [oldTriggerError, setOldTriggerError] = useState(triggerError);
    if (triggerError !== oldTriggerError) {
        setOldTriggerError(triggerError);
        setSubmitTried(true);
    }

    return (
        <Form className="mt-4" layout="vertical" onSubmit={onConfirm}>
            <Form.Item>
                <Label htmlFor={id}>
                    <Trans>Confirm the action</Trans>
                </Label>
                <Input
                    id={id}
                    value={verificationInput}
                    onChange={(e) => handleVerificationUpdate(e.target.value)}
                    status={showError ? 'error' : 'default'}
                    statusText={showError && t`The value is incorrect.`}
                />
            </Form.Item>
        </Form>
    );
};
