import React from 'react';
import { ProgressBar, getColorId } from '@wedo/design-system';

export const VoteProgressBar = ({ fraction, optionColor }: { fraction: number; optionColor: string }) => {
    const colorId = getColorId(optionColor);
    return (
        <ProgressBar
            borderless
            className={'mt-2'}
            size={'thin'}
            percent={fraction * 100}
            color={'bg-gradient-' + colorId}
            showPercent={false}
        />
    );
};
