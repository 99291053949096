import { FC } from 'react';
import { Select } from '@wedo/design-system';
import { EmptyFunction } from '@wedo/utils';
import { CompanySizeOptions } from 'Pages/onboarding/utils/selectOptions';
import { Organization } from 'Shared/types/organization';

type OrganisationSizeSelectorProps = {
    id: string;
    value: Organization['cf_company_size'];
    onChange: (value: Organization['cf_company_size']) => void;
    popoverClassName?: string;
    onBlur?: () => void;
};

export const OrganisationSizeSelector: FC<OrganisationSizeSelectorProps> = ({
    id,
    value,
    onChange,
    popoverClassName,
    onBlur = EmptyFunction,
}) => {
    return (
        <Select id={id} value={value} onChange={onChange} popoverClassName={popoverClassName} onBlur={onBlur}>
            {CompanySizeOptions.map((size) => (
                <Select.Option key={size} value={size}>
                    {size}
                </Select.Option>
            ))}
        </Select>
    );
};
