import { LanguageCode } from '@wedo/utils';
import { PartialUser } from 'Pages/settings/users/utils/user';
import { AddUserArg } from 'Shared/services/admin';
import { UserEmail } from 'Shared/types/user-email';
import { calculateInitials } from 'Shared/utils/user';

export const SET_WHOLE_USER = Symbol('set-whole-user');

type AddUserReducerFn = (
    state: PartialUser,
    action: { type: keyof AddUserArg; value: string } | { type: typeof SET_WHOLE_USER; value: PartialUser }
) => PartialUser;

export const addEditUserReducer: AddUserReducerFn = (state, action) => {
    switch (action.type) {
        case SET_WHOLE_USER:
            return action.value;
        case 'firstName':
            return {
                ...state,
                first_name: action.value,
                initials: calculateInitials(action.value, state.last_name, state.initials),
            };
        case 'lastName':
            return {
                ...state,
                last_name: action.value,
                initials: calculateInitials(state.first_name, action.value, state.initials),
            };
        case 'displayName':
            return { ...state, display_name: action.value };
        case 'email':
            return { ...state, userEmail: { email_address: action.value } as UserEmail };
        case 'can_add_external':
            return { ...state, userNetwork: { ...state.userNetwork, can_add_external: action.value === 'true' } };
        case 'color':
            return { ...state, color: { background: action.value, text: state.color.text } };
        case 'language':
            return { ...state, language_code: action.value as LanguageCode };
        case 'saml':
            return { ...state, userAccount: { ...state.userAccount, saml: action.value === 'true' } };
        case 'baseLicense':
            return {
                ...state,
                role:
                    action.value === 'standard'
                        ? 'USER'
                        : action.value === 'light'
                          ? 'LIGHT'
                          : action.value === 'external' && 'EXTERNAL',
                baseLicense: action.value,
            };
        case 'governanceAddon': {
            return {
                ...state,
                userLicenses:
                    action.value === 'true'
                        ? (state.userLicenses ?? []).concat(['governance'])
                        : (state.userLicenses ?? []).filter((license) => license !== 'governance'),
            };
        }
        default:
            return { ...state, [action.type]: action.value };
    }
};
