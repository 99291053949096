import React from 'react';
import { Trans } from '@lingui/macro';
import { Card, FormatDate, FormatDateDistance, NavLink, Tooltip } from '@wedo/design-system';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type TopicSearchItemProps = {
    topic: MeetingTopic;
    areVotesAllowed?: boolean;
};

export const TopicSearchItem = ({ topic, areVotesAllowed }: TopicSearchItemProps) => {
    return (
        <Card>
            <Card.Header title={`${topic.display_id} ${topic.title}`} className="!py-2" />
            <Card.Body className="!px-2 !py-0">
                <TopicEditor
                    topic={topic}
                    isVoteOnly={areVotesAllowed}
                    isReadOnly
                    isStatic
                    viewMode={MeetingViewMode.TopicView}
                    initialBlocks={topic.meetingBlocks}
                />
            </Card.Body>
            <Card.Footer className="flex justify-between border-t border-gray-200 !py-2 text-sm text-gray-500">
                <NavLink
                    to={{
                        pathname: `/meetings/${topic.meeting.id}`,
                        searchParams: { meeting_topic_id: topic.id as string },
                    }}
                >
                    <Trans>Topic created during the</Trans>
                    <b>
                        {' '}
                        <FormatMeetingTitle title={topic.meeting.title} startAt={topic.meeting.start_at} />{' '}
                    </b>
                    {topic.meeting.workspace?.name && <span>&nbsp;&bull;&nbsp;{topic.meeting.workspace.name}</span>}
                </NavLink>
                <Tooltip content={<FormatDate date={topic.updated_at} format="short" />} placement="top">
                    <FormatDateDistance date={topic.updated_at} />
                </Tooltip>
            </Card.Footer>
        </Card>
    );
};
