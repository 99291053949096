import { ReactElement, useMemo } from 'react';
import { useCurrentUserContext } from 'App/contexts';
import { Can } from 'Shared/components/Can';
import { Task } from 'Shared/types/task';
import { Permission } from 'Shared/utils/rbac';

export const useCanEditTask = (task: Task): boolean => {
    const { currentUserId, can } = useCurrentUserContext();

    const canEditTask = useMemo(() => can(Permission.ManageTasks), [currentUserId]);
    const canEditMyTask = useMemo(() => task?.assignee_id === currentUserId, [task, currentUserId]);

    return canEditTask || canEditMyTask;
};

type CanTaskProps = {
    task: Task;
    permission: Permission;
    children: ReactElement;
};
export const CanTask = ({ task, permission, children }: CanTaskProps) => {
    const canEditMyTask = useCanEditTask(task);
    return canEditMyTask ? children : <Can permission={permission}>{children}</Can>;
};
