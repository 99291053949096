import { Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ManageMembersModalBody } from 'Shared/components/userGroup/ManageMembersModalBody';
import { useWorkspaceManageMembers } from 'Shared/components/workspace/WorkspaceManageMembers/useWorkspaceManageMembers';
import { useManageMembers } from 'Shared/hooks/useManageMembers';

export const WorkspaceManageMembersModalBody = ({ workspaceId }: { workspaceId: Id }) => {
    const { handleAddUser, handleTogglePrivacy, handleModeratorToggle, handleDeleteUser, workspace } =
        useWorkspaceManageMembers(workspaceId);
    const { isCurrentUserModerator } = useManageMembers(workspace);

    const isReadOnly = !isCurrentUserModerator;

    return (
        <Modal.Body>
            <ManageMembersModalBody
                isReadOnly={isReadOnly}
                entity={workspace}
                onPrivacyChange={handleTogglePrivacy}
                onDeleteUser={handleDeleteUser}
                onToggleModerator={handleModeratorToggle}
                onAddUser={handleAddUser}
                canRemoveMyself={false}
            />
        </Modal.Body>
    );
};
