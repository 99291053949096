import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { Id } from '@wedo/types';
import { intersection } from '@wedo/utils/set';
import { useFilesStore } from 'Shared/hooks/files/filesStore';
import { useAddLabelRelationMutation, useRemoveLabelRelationMutation } from 'Shared/services/label';
import { Attachment, TreeItem } from 'Shared/types/attachment';
import { LabelRelation } from 'Shared/types/label';

export const useFiles = () => {
    const { files, selectedFilesIds, setSelectedFilesIds, setFiles } = useFilesStore();

    const [addLabelRelation] = useAddLabelRelationMutation();
    const [removeLabelRelation] = useRemoveLabelRelationMutation();

    const selectedFiles = useMemo<TreeItem[]>(
        () => selectedFilesIds.map(({ id }) => files.find((file) => file.object.id === id)).filter(Boolean),
        [files, selectedFilesIds]
    );

    const selectedFilesCommonLabelIds = useMemo<Set<Id>>(() => {
        if (isEmpty(selectedFiles) || selectedFiles[0].object_type !== 'file') return new Set();

        let result = new Set<Id>((selectedFiles[0].object as Attachment).labels.map((label) => label.id));
        for (const file of selectedFiles) {
            if (file.object_type === 'file') {
                const attachment = file.object as Attachment;
                const currentLabels = new Set(attachment.labels.map((label) => label.id));
                result = intersection(result, currentLabels);
            }
        }
        return result;
    }, [selectedFiles]);

    const numberOfSelectedFiles = useMemo<number>(() => {
        return selectedFiles.reduce(
            (previousValue, currentValue) => previousValue + (currentValue.object_type === 'file' ? 1 : 0),
            0
        );
    }, [selectedFiles]);

    const numberOfSelectedFolders = selectedFiles.length - numberOfSelectedFiles;

    const areAllSelectedItemsFiles = numberOfSelectedFiles === selectedFiles.length;

    const removeLabelFromFile = async (fileId: Id, labelId: Id) =>
        removeLabelRelation({ labelId, relation: { attachment_id: fileId } as LabelRelation });

    const addLabelInFile = async (fileId: Id, labelId: Id) =>
        addLabelRelation({ labelId, relation: { attachment_id: fileId } as LabelRelation });

    return {
        files,
        selectedFilesIds,
        selectedFiles,
        selectedFilesCommonLabelIds,
        areAllSelectedItemsFiles,
        numberOfSelectedFiles,
        numberOfSelectedFolders,
        removeLabelFromFile,
        addLabelInFile,
        setFiles,
        setSelectedFilesIds,
    };
};
