import React from 'react';
import { Link } from 'react-router-dom';
import { FormatDate } from '@wedo/design-system';
import { ChargebeeInvoice } from '@wedo/types';
import { InvoiceAmount } from 'Pages/settings/billing/components/InvoicesCard/InvoiceAmount';
import { InvoiceStatusTag } from 'Pages/settings/billing/components/InvoicesCard/InvoiceStatusTag';
import { useGetInvoiceUrlQuery } from 'Shared/services/organization';

type InvoiceItemProps = {
    invoice: ChargebeeInvoice;
};

export const InvoiceItem: React.FC<InvoiceItemProps> = ({ invoice }) => {
    const { data: invoiceUrl } = useGetInvoiceUrlQuery(invoice.id);

    return (
        <Link to={invoiceUrl?.open_url} className="bg-red-500 hover:text-gray-700" target="_blank">
            <div className="flex w-full items-center justify-between rounded-md px-2 py-2 text-xs hover:bg-gray-100">
                <span>
                    <span className="font-medium">{invoice.id}</span> (
                    <FormatDate format="P" date={new Date(invoice.date)} className="text-gray-600" />)
                </span>
                <div className="flex items-center gap-2">
                    <InvoiceAmount invoice={invoice} />
                    <InvoiceStatusTag status={invoice.status} />
                </div>
            </div>
        </Link>
    );
};
