import { useEffect, useMemo, useState } from 'react';
import { Id } from '@wedo/types';
import { UserMeetingPermission } from 'Shared/types/meeting';
import { User } from 'Shared/types/user';
import { prepareMeetingAccessData } from './utils';

export const useUpdateMeetingAccess = (addedUsers: Map<Id, User>, deletedUsers: Map<Id, User>) => {
    const [meetingsAccessData, setMeetingsAccessData] = useState(() =>
        prepareMeetingAccessData(addedUsers, deletedUsers)
    );

    useEffect(
        () => setMeetingsAccessData(prepareMeetingAccessData(addedUsers, deletedUsers)),
        [addedUsers, deletedUsers]
    );

    const modifiedUsers = useMemo<Array<User>>(() => {
        const result: Array<User> = [];
        for (const { user, updateAccess } of meetingsAccessData.values()) {
            if (updateAccess) {
                result.push(user);
            }
        }
        return result;
    }, [meetingsAccessData]);

    const payload = useMemo<Array<{ userId: Id; meetingRole: UserMeetingPermission }>>(() => {
        const result: Array<{ userId: Id; meetingRole: UserMeetingPermission }> = [];
        for (const { user, updateAccess, role } of meetingsAccessData.values()) {
            if (updateAccess) {
                result.push({ userId: user.id, meetingRole: role });
            }
        }
        return result;
    }, [meetingsAccessData]);

    const accessRightsChangedFor = useMemo<number>(() => modifiedUsers.length, [modifiedUsers]);

    return { meetingsAccessData, setMeetingsAccessData, payload, accessRightsChangedFor };
};
