import React, { FC } from 'react';
import HighlighterWrapper, { HighlighterProps } from 'react-highlight-words';
import latinize from 'latinize';
import { colors } from '@wedo/design-system';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Highlighter: FC<HighlighterProps> = ({
    highlightStyle = { background: colors.yellow[300] },
    ...props
}) => <HighlighterWrapper sanitize={latinize} highlightStyle={highlightStyle} {...props} />;
