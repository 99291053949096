import React, { useContext, useMemo } from 'react';
import { camelToSnake } from 'caseparser';
import { AvatarSizes, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { getUser } from 'App/store/usersStore';
import { MeetingWrapperContainerContext } from 'Pages/meeting/components/MeetingView/MeetingViewBody';
import { TopicPresenters } from 'Shared/components/meeting/TopicPresenters';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { useAddTopicPresentersMutation, useRemoveTopicPresentersMutation } from 'Shared/services/meetingTopic';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Presenter } from 'Shared/types/presenter';
import { User } from 'Shared/types/user';

type Props = {
    meetingId: Id;
    topicId: Id;
    canEdit: boolean;
    size: AvatarSizes;
    hideName?: boolean;
};

export const TopicPresenter = ({ meetingId, topicId, canEdit, size = 'xs', hideName }: Props) => {
    const { data: topic } = trpc.meetingTopic.get.useQuery(topicId, { select: camelToSnake });
    const { show: showNotification } = useNotification();
    const containerRef = useContext(MeetingWrapperContainerContext);
    const containerSize = useElementSize(containerRef);
    const { contextTitle, contextUsers } = useContextUsers({ meetingId });

    const [addMeetingPresenters, { isLoading: isAddingPresenters }] = useAddTopicPresentersMutation();
    const [removeMeetingPresenters, { isLoading: isRemovingPresenters }] = useRemoveTopicPresentersMutation();

    const isLoading = isAddingPresenters || isRemovingPresenters;

    const sortedPresenters = useMemo(
        () =>
            topic?.presenters
                ?.map((presenter) => ({ ...presenter, user: getUser(presenter.user_id) }))
                .sort((a: Presenter, b: Presenter) =>
                    getUser(a.user_id)?.full_name.localeCompare(getUser(b.user_id)?.full_name)
                ),
        [topic?.presenters]
    );

    if (!topic) {
        return null;
    }

    const handleUserSelected = async (user: User) => {
        try {
            const found = topic.presenters?.find((p) => p.user_id === user.id);
            if (found) {
                await removeMeetingPresenters({ meetingId, topicId, presenters: [found.id] }).unwrap();
            } else {
                await addMeetingPresenters({
                    meetingId: meetingId,
                    topicId: topicId,
                    presenters: [{ user_id: user.id }],
                }).unwrap();
            }
            void trpcUtils().meetingTopic.get.invalidate();
        } catch (exception) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    if (!canEdit) {
        if (sortedPresenters?.length > 0) {
            return (
                <UsersAvatarGroup
                    size={size}
                    users={sortedPresenters?.map((presenter) => presenter?.user)}
                    maxDisplayed={2}
                    showTooltip={!hideName}
                />
            );
        }
        return null;
    }

    return (
        <TopicPresenters
            topicPresenters={topic.presenters.map(({ user_id }) => getUser(user_id))}
            onUserSelected={handleUserSelected}
            wrapperClassName="flex"
            isLoading={isLoading}
            contextUsers={contextUsers}
            contextTitle={contextTitle}
            userAvatarSize={size}
            maxDisplayed={containerSize?.width < getBreakpointValue('sm') ? 2 : 3}
        />
    );
};
