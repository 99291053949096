import React, { useEffect, useState } from 'react';
import { msg, t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, useModal } from '@wedo/design-system';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { SelectPreference } from 'Pages/settings/preferences/Preference';
import { MeetingStatus } from 'Shared/types/meeting';
import { meetingStatusToMeetingView } from 'Shared/utils/meeting';

const statuses = [
    { code: MeetingStatus.PREPARATION, title: msg`Preparation` },
    { code: MeetingStatus.AGENDA_READY, title: msg`Validated` },
    { code: MeetingStatus.ONGOING, title: msg`Ongoing` },
    { code: MeetingStatus.FINISHED, title: msg`Review` },
    { code: MeetingStatus.LOCKED, title: msg`Locked` },
];

const options = [
    { id: MeetingViewMode.TopicView.toString(), label: msg`Topic view` },
    { id: MeetingViewMode.DocumentView.toString(), label: msg`Document view` },
];

type ModalProps = {
    value: Record<MeetingStatus, MeetingViewMode>;
    onSave: (value: Record<MeetingStatus, MeetingViewMode>) => void;
} & ContextModalProps;
const CustomizeModal = ({ value, onSave, ...modalProps }: ModalProps) => {
    const [data, setData] = useState<Record<MeetingStatus, MeetingViewMode>>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    const handleSave = () => {
        void onSave(data);
        void modalProps.close();
    };

    return (
        <Modal size="sm" {...modalProps}>
            <Modal.Header title={t`Default meeting view`} />

            <Modal.Body>
                <div className="text-sm mb-2 text-gray-500">
                    <Trans>Choose the default view when opening a meeting</Trans>
                </div>
                {statuses?.map((status, index) => (
                    <div key={status.code}>
                        <SelectPreference
                            value={data[status.code] ?? meetingStatusToMeetingView[status.code]}
                            options={options}
                            onChange={(value: string) => setData({ ...data, [status.code]: value })}
                            title={status.title}
                        />
                        {index < statuses.length - 1 && <hr className="my-2" />}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={handleSave}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const CustomizeMeetingView = ({
    value,
    onSave,
}: {
    value: Record<MeetingStatus, MeetingViewMode>;
    onSave: (value: string) => void;
}) => {
    const { open } = useModal();

    return (
        <Button onClick={() => open(CustomizeModal, { value, onSave })}>
            <Trans>Customize</Trans>
        </Button>
    );
};
