import { useState } from 'react';
import { useCurrentUserContext } from 'App/contexts';
import { useNotificationServiceWorker } from 'Shared/hooks/useNotificationServiceWorker';

export enum NotificationPermissionStatus {
    DEFAULT = 'default',
    GRANTED = 'granted',
    DENIED = 'denied',
}

export const useDesktopNotifications = () => {
    const serviceWorker = useNotificationServiceWorker();
    const { currentUserId } = useCurrentUserContext();

    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const browserSupportsNotifications = 'Notification' in window;

    let notificationsEnabled = false;
    let hasDeniedPermission = true;

    if (browserSupportsNotifications) {
        notificationsEnabled = Notification.permission === NotificationPermissionStatus.GRANTED;
        hasDeniedPermission = Notification.permission === NotificationPermissionStatus.DENIED;
    }

    const askPermission = async () => {
        if (!browserSupportsNotifications) {
            return;
        }

        if (Notification.requestPermission instanceof Promise) {
            await Notification.requestPermission();
            setRefreshTrigger(!refreshTrigger);
        } else {
            void Notification.requestPermission(() => {
                setRefreshTrigger(!refreshTrigger);
            });
        }
    };

    const show = async (
        title: string,
        options: Parameters<ServiceWorkerRegistration['showNotification']>[1] & {
            notificationId?: string;
        }
    ) => {
        if (!notificationsEnabled) {
            return;
        }

        void serviceWorker.showNotification(title, {
            ...options,
            icon: '/assets/logo/icon.svg',
            vibrate: [200, 100, 200],
            data: {
                ...options?.data,
                notificationId: options?.notificationId,
                currentUserId,
            },
        });
    };

    return {
        hasDeniedPermission,
        browserSupportsNotifications,
        notificationsEnabled,
        askPermission,
        show,
    };
};
