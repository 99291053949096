import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';
import { useMeetings } from './useMeetings';

export const ListMeetings = () => {
    const meetings = useMeetings();

    return (
        meetings?.length > 0 && (
            <div className="grid grid-cols-subgrid col-span-4 group h-[calc(var(--row-height)+1px)] sticky top-[calc(var(--row-height)*2+2px)] border-b border-gray-200 bg-white z-10">
                <div></div>
                <div className="font-semibold flex items-center pl-2 gap-2">
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <Trans>Meetings</Trans>
                </div>
                <div className="flex items-center justify-center border-r border-gray-200">
                    <Tag size="xs" color="gray">
                        {meetings.length}
                    </Tag>
                </div>
            </div>
        )
    );
};
