export const decodeJWT = (token: string) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } catch (e) {
        return {};
    }
};

export const clearObject = <T>(object: T): T =>
    Object.entries(object).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {}) as T;
