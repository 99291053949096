import React, { FC } from 'react';
import { Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useTemplateManageMembers } from 'Shared/components/template/TemplateManageMembers/useTemplateManageMembers';
import { ManageMembersModalBody } from 'Shared/components/userGroup/ManageMembersModalBody';

type ManageTemplateMembersModalBodyProps = { templateId: Id };

export const ManageTemplateMembersModalBody: FC<ManageTemplateMembersModalBodyProps> = ({ templateId }) => {
    const { template, handleTogglePrivacy, handleDeleteUser, handleAddUser, handleModeratorToggle } =
        useTemplateManageMembers(templateId);

    return (
        <Modal.Body>
            <ManageMembersModalBody
                entity={template}
                onDeleteUser={handleDeleteUser}
                onToggleModerator={handleModeratorToggle}
                onAddUser={handleAddUser}
                onPrivacyChange={handleTogglePrivacy}
                canRemoveMyself={false}
            />
        </Modal.Body>
    );
};
