import { PropsWithChildren, useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { sortBy } from 'lodash-es';
import { Button, ContextModalProps, Label, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useImmer } from '@wedo/utils/hooks';
import { TaskDetailCustomFieldAttachmentsInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldAttachments';
import { TaskDetailCustomFieldDateInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldDate';
import { TaskDetailCustomFieldMultipleChoiceInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldMultipleChoice';
import { TaskDetailCustomFieldNumericInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldNumeric';
import { TaskDetailCustomFieldShortTextInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldShortText';
import { TaskDetailCustomFieldSingleChoiceInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldSingleChoice';
import { TaskDetailRowAddon } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { useGetCustomFieldGroupsQuery } from 'Shared/services/customFields';
import {
    useAddTaskCustomFieldGroupValueMutation,
    useGetCustomFieldGroupValuesQuery,
    useUpdateTaskCustomFieldGroupValueMutation,
} from 'Shared/services/taskCustomFieldGroupValue';
import { useRemoveTaskCustomFieldValueMutation } from 'Shared/services/taskCustomFieldValue';
import { CustomField, CustomFieldType, CustomFieldValue } from 'Shared/types/customField';

const CustomFieldGroupInput = ({
    taskId,
    customField,
    values,
    handleUpdateValue,
    handleRemoveValues,
}: {
    taskId: Id;
    customField: CustomField;
    values: CustomFieldValue[];
    handleUpdateValue: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    handleRemoveValues: (customFieldId: Id, attachment?: { valueId: Id; attachmentId: Id }) => Promise<void>;
}) => {
    const { isTaskReadonly } = useTask(taskId);
    const customFieldValues = values?.filter((v) => v.custom_field_id === customField.id);
    const inputId = customField.short_name + '-' + customField?.id;

    switch (customField.type) {
        case CustomFieldType.Attachment:
            return (
                <>
                    <Label key={'label-' + customField.id} inputType={'inline'}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldAttachmentsInput
                            key={customField.id}
                            taskId={taskId}
                            customField={customField}
                            values={customFieldValues}
                            onUpdate={handleUpdateValue}
                            onDelete={handleRemoveValues}
                        />
                        <TaskDetailRowAddon />
                    </div>
                </>
            );
        case CustomFieldType.Date:
            return (
                <>
                    <Label key={'label-' + customField.id} inputType={'inline'}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldDateInput
                            key={customField.id}
                            taskId={taskId}
                            value={customFieldValues?.[0]}
                            customField={customField}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={faXmark}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </>
            );
        case CustomFieldType.Dropdown:
            return (
                <>
                    <Label key={'label-' + customField.id} inputType={'inline'}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldSingleChoiceInput
                            key={customField.id}
                            taskId={taskId}
                            customField={customField}
                            value={customFieldValues?.[0]}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={faXmark}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </>
            );
        case CustomFieldType.Number:
            return (
                <>
                    <Label key={'label-' + customField.id} inputType={'inline'} htmlFor={inputId}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldNumericInput
                            id={inputId}
                            taskId={taskId}
                            key={customField.id}
                            customField={customField}
                            value={customFieldValues?.[0]}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={faXmark}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </>
            );
        case CustomFieldType.ShortText:
            return (
                <>
                    <Label key={'label-' + customField.id} inputType={'inline'} htmlFor={inputId}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldShortTextInput
                            id={inputId}
                            taskId={taskId}
                            key={customField.id}
                            customField={customField}
                            value={customFieldValues?.[0]}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={faXmark}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </>
            );
        case CustomFieldType.MultipleChoice:
            return (
                <>
                    <Label key={'label-' + customField.id} inputType={'inline'}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldMultipleChoiceInput
                            key={customField.id}
                            taskId={taskId}
                            customField={customField}
                            values={customFieldValues}
                            onUpdate={handleUpdateValue}
                            onDelete={(_, valueIds) => handleRemoveValues(valueIds)}
                        />
                        <TaskDetailRowAddon>
                            {(customFieldValues || []).length > 0 && !isTaskReadonly && (
                                <Button
                                    size={'sm'}
                                    onClick={() => {
                                        handleRemoveValues(customFieldValues.map((v) => v.id));
                                    }}
                                    variant={'text'}
                                    icon={faXmark}
                                    disabled={isTaskReadonly}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </>
            );
        default:
            return null;
    }
};

type TaskCustomFieldGroupModalProps = {
    taskId: Id;
    customFieldGroupId: Id;
    customFieldGroupValueId?: Id;
} & ContextModalProps &
    PropsWithChildren;

export const TaskCustomFieldGroupModal = ({
    taskId,
    customFieldGroupId,
    customFieldGroupValueId,
    children,
    ...modalProps
}: TaskCustomFieldGroupModalProps) => {
    const { isTaskReadonly, task } = useTask(taskId);

    const [isSaving, setIsSaving] = useState(false);

    const { data: customFieldGroups } = useGetCustomFieldGroupsQuery(null, {
        selectFromResult: ({ data = [] }) => ({
            data: sortBy(
                data.filter((customFieldGroup) => {
                    return (
                        customFieldGroup.is_global ||
                        task?.tags.some((taskWorkspace) =>
                            customFieldGroup.tags.some((fieldWorkspace) => fieldWorkspace.id === taskWorkspace?.id)
                        ) ||
                        customFieldGroup.checklists.some(({ id }) => id === task?.checklist?.id)
                    );
                }),
                ['order', 'id']
            ),
        }),
    });
    const { data: customFieldGroupValues } = useGetCustomFieldGroupValuesQuery(taskId);

    const customFieldGroup = customFieldGroups?.find((c) => c.id === customFieldGroupId);
    const value = customFieldGroupValues?.find((v) => v.id === customFieldGroupValueId);

    const [formValues, setFormValues] = useImmer<Partial<CustomFieldValue>[]>([]);
    const [originalValues, setOriginalValues] = useImmer<Partial<CustomFieldValue>[]>([]);

    const [addTaskCustomFieldGroupValue] = useAddTaskCustomFieldGroupValueMutation();
    const [updateTaskCustomFieldGroupValue] = useUpdateTaskCustomFieldGroupValueMutation();
    const [removeTaskCustomFieldValue] = useRemoveTaskCustomFieldValueMutation();

    const updateFormFieldValue = (customFieldId: Id, _valueId: Id, changes) => {
        const index = formValues.findIndex((i) => i.custom_field_id === customFieldId);
        const customField = customFieldGroup.customFields.find((cf) => cf.id === customFieldId);
        setFormValues((draftValues) => {
            // Updating attachment / multi-choice field values adds new values instead of updating them, that's just how it goes ¯\_(ツ)_/¯
            if (
                customField.type === CustomFieldType.MultipleChoice ||
                customField.type === CustomFieldType.Attachment
            ) {
                draftValues.push({ custom_field_id: customFieldId, ...changes });
            } else {
                if (index > -1) {
                    draftValues[index] = { ...draftValues[index], ...changes };
                } else {
                    draftValues.push({ custom_field_id: customFieldId, ...changes });
                }
            }
            return draftValues;
        });
    };

    const deleteFormFieldAttachment = (customFieldId: Id, attachment: { valueId: Id; attachmentId: Id }) => {
        // attachments that are only in the form (not yet saved) don't yet have a valueId
        if (!attachment.valueId) {
            setFormValues((draftValues) =>
                draftValues.map((draftValue) => {
                    if (draftValue.custom_field_id === customFieldId && draftValue.attachments?.length > 0) {
                        return {
                            ...draftValue,
                            attachments: draftValue.attachments.filter(({ id }) => id !== attachment.attachmentId),
                        };
                    }
                    return draftValue;
                })
            );
        } else {
            setFormValues((draftValues) => draftValues.filter(({ id }) => id !== attachment.valueId));
        }
    };

    const deleteFormFieldValues = (customFieldId: Id, valueIds: Id[]) => {
        const indexes: number[] = [];

        for (const valueId of valueIds) {
            indexes.push(
                formValues.findIndex(
                    (formValue) =>
                        formValue.custom_field_id === customFieldId &&
                        (!valueId || valueId === formValue.id || valueId === formValue.custom_field_option_id)
                )
            );
        }
        // we do this backwards to not mess up the indexes
        indexes.sort().reverse();

        setFormValues((draftValues) => {
            indexes.forEach((index) => {
                draftValues.splice(index, 1);
            });
            return draftValues;
        });
    };

    const handleSave = async () => {
        setIsSaving(true);
        const customFieldGroup = customFieldGroups.find((cfg) => cfg.id === customFieldGroupId);
        const valuesObject: Record<string, CustomField> = {};
        formValues.forEach((value) => {
            const customField = customFieldGroup.customFields.find(({ id }) => id === value.custom_field_id);
            if (customField.type === CustomFieldType.MultipleChoice) {
                if (value.custom_field_option_id) {
                    const list = valuesObject[customField.short_name]
                        ? valuesObject[customField.short_name].custom_field_option_ids
                        : [];
                    valuesObject[customField.short_name] = {
                        custom_field_option_ids: [...list, value.custom_field_option_id],
                    };
                }
            } else if (customField.type === CustomFieldType.Attachment && customField.short_name in valuesObject) {
                valuesObject[customField.short_name].attachments = [
                    ...valuesObject[customField.short_name].attachments,
                    ...value.attachments,
                ];
            } else {
                valuesObject[customField.short_name] = { ...value };
            }
        });

        if (customFieldGroupValueId) {
            await updateTaskCustomFieldGroupValue({
                taskId,
                customFieldGroupId: customFieldGroupId,
                customFieldGroupValueId: customFieldGroupValueId,
                changes: { values: valuesObject },
            });
        } else {
            await addTaskCustomFieldGroupValue({
                taskId,
                customFieldGroupId: customFieldGroupId,
                values: { values: valuesObject },
            });
        }
        const valuesToDelete = originalValues.filter(
            (originalValue) => !formValues.map((v) => v.id).includes(originalValue.id)
        );
        if (valuesToDelete.length > 0) {
            await Promise.all(
                valuesToDelete.map((value) =>
                    removeTaskCustomFieldValue({
                        taskId,
                        customFieldId: value.custom_field_id,
                        valueId: value.id,
                        isCustomFieldGroup: true,
                    })
                )
            );
        }
        await modalProps.close();
        setIsSaving(false);
    };

    useEffect(() => {
        if (!customFieldGroup || isSaving) {
            return;
        }
        if (!value) {
            setFormValues([]);
            setOriginalValues([]);
        } else {
            setFormValues(value?.values);
            setOriginalValues(value?.values);
        }
    }, [value, customFieldGroup, isSaving]);

    return (
        <Modal {...modalProps}>
            <Modal.Header title={customFieldGroup?.label} />
            <Modal.Body>
                <>
                    <div className={'@container'}>
                        <div className={'grid grid-cols-[1fr_3fr] items-center gap-2'}>
                            {(customFieldGroup?.customFields || []).map((customField) => {
                                const customFieldValues = formValues
                                    ? formValues.filter((v) => v.custom_field_id === customField.id)
                                    : null;
                                return (
                                    <CustomFieldGroupInput
                                        key={customField.id}
                                        taskId={taskId}
                                        customField={customField}
                                        values={customFieldValues}
                                        handleRemoveValues={
                                            customField.type === CustomFieldType.Attachment
                                                ? deleteFormFieldAttachment
                                                : (valueIds) => deleteFormFieldValues(customField.id, valueIds)
                                        }
                                        handleUpdateValue={updateFormFieldValue}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    {children} {/* Used for nesting the attachment modal */}
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{isTaskReadonly ? t`Close` : t`Cancel`}</Button>
                {!isTaskReadonly && (
                    <Button color={'primary'} loading={isSaving} onClick={handleSave}>{t`Save`}</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
