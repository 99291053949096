import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { NavLinkOptions, useNavLinkOptions } from '@wedo/design-system';
import { EmptyFunction } from '@wedo/utils';
import { TranslatedNavBarLink, TranslatedNavBarTab } from 'Shared/components/layout/NavBar/types';
import { isNavBarLinkType, isNavBarTabType } from 'Shared/components/layout/NavBar/utils';
import { TranslatedPageHeaderTab } from 'Shared/components/layout/TabsPageHeader';

type MatchedTabProps = {
    tab: TranslatedNavBarTab | TranslatedPageHeaderTab;
    onMatchedTabChange: (isMatch: boolean) => void;
};

const MatchedTab = ({ tab, onMatchedTabChange }: MatchedTabProps) => {
    const { isMatch } = useNavLinkOptions(tab as NavLinkOptions);

    useEffect(() => {
        if (isMatch) {
            onMatchedTabChange(isMatch);
        }
    }, [isMatch]);

    return <></>;
};

type MatchedTabsProps = {
    tabs: Array<TranslatedNavBarTab | TranslatedPageHeaderTab>;
    prefix: string;
    onMatchedTabChange: (matchedTab: TranslatedNavBarLink | TranslatedPageHeaderTab) => void;
};

export const MatchedTabs = ({ tabs, prefix, onMatchedTabChange = EmptyFunction }: MatchedTabsProps) => {
    useSearchParams();
    useLocation();

    if (!Array.isArray(tabs)) {
        return null;
    }

    const handleIsMatch = (tab: TranslatedNavBarTab | TranslatedPageHeaderTab, isMatch: boolean) => {
        if (isMatch && ((isNavBarTabType(tab) && isNavBarLinkType(tab)) || !isNavBarTabType(tab))) {
            onMatchedTabChange(tab);
        }
    };

    return (
        <>
            {tabs?.map((tab, index) => (
                <MatchedTab
                    tab={{ ...tab, prefix }}
                    key={index}
                    onMatchedTabChange={(isMatch) => handleIsMatch(tab, isMatch)}
                />
            ))}
        </>
    );
};
