import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faEllipsisV, faPen, faPlus, faTrash, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { AttendeeEditModal } from 'Pages/meeting/components/AttendeeEditModal/AttendeeEditModal';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingUserData } from 'Shared/types/meetingUserData';
import { User } from 'Shared/types/user';
import { formRowClasses, formRowIconClasses } from './FormStyles';

type AttendeeElementProps = {
    attendee: MeetingUser;
    onEditClick: () => void;
    onRemoveClick: () => void;
    isDisabled?: boolean;
};

const AttendeeElement = ({ attendee, onEditClick, onRemoveClick, isDisabled }: AttendeeElementProps) => {
    return (
        <div className="flex h-[30px] max-w-[250px] items-center overflow-hidden rounded-[15px] bg-gray-200">
            <MeetingUserAvatar user={attendee} size={'sm'} />
            <div className={'flex min-w-0 max-w-full items-center gap-2 px-2 text-sm text-gray-600'}>
                <span className={'shrink truncate'}>
                    {attendee.user?.full_name ?? attendee.user_data.external_full_name}
                </span>
                <Dropdown
                    className={'shrink-0'}
                    disabled={isDisabled}
                    size={'xs'}
                    shape={'circle'}
                    variant={'text'}
                    icon={faEllipsisV}
                    title={t`Options for ${
                        attendee.user_id != null ? attendee.user?.full_name : attendee.user_data.external_full_name
                    }`}
                >
                    <Dropdown.Item onClick={onEditClick} icon={faPen}>{t`Edit attendee`}</Dropdown.Item>
                    <Dropdown.Item onClick={onRemoveClick} icon={faTrash}>{t`Remove attendee`}</Dropdown.Item>
                </Dropdown>
            </div>
        </div>
    );
};

type Props = {
    meetingUsers: MeetingUser[];
    onAdd: (
        Attendee: Omit<Partial<MeetingUser>, 'user_data'> & { type?: string } & { user_data: Partial<MeetingUserData> }
    ) => void;
    onRemove: (Attendee: MeetingUser) => void;
    onUpdate: (changes: { id: Id; changes: Partial<MeetingUser> }[]) => void;
    isDisabled?: boolean;
};

export const MeetingFormAttendees = ({
    meetingUsers,
    onAdd,
    onRemove,
    onUpdate,
    isDisabled,
}: Props): React.ReactElement => {
    const { open: openModal } = useModal();

    const handleAddRow = (fullName: string) =>
        onAdd({
            id: uuidv4(),
            user_id: null,
            user_data: {
                external_full_name: fullName,
            },
            is_attendee: true,
            attendance: 'present',
            order: meetingUsers.length,
        });

    const handleUserSelected = (user: User) =>
        onAdd({
            id: uuidv4(),
            type: 'internal',
            user_id: user.id,
            user: user,
            order: meetingUsers.length,
            is_attendee: true,
            attendance: 'present',
            user_data: {
                title: user.title,
            },
        });

    const handleEdit = async (attendee: MeetingUser) => {
        openModal(AttendeeEditModal, {
            attendee,
            onDone: (data: { id: Id; changes: Partial<MeetingUser> }) => {
                if (data) {
                    onUpdate([{ id: attendee.id, changes: data.changes }]);
                }
            },
        });
    };

    return (
        <div className={formRowClasses}>
            <div className={formRowIconClasses}>
                <FontAwesomeIcon icon={faUsers} />
            </div>
            <div className={'-ml-1 flex-1 overflow-hidden p-1'}>
                <div className={'flex flex-wrap items-center gap-[5px]'}>
                    {meetingUsers?.map((meetingUser) => (
                        <AttendeeElement
                            isDisabled={isDisabled}
                            key={meetingUser.user_id + '-' + meetingUser.id}
                            attendee={meetingUser}
                            onEditClick={() => handleEdit(meetingUser)}
                            onRemoveClick={() => onRemove(meetingUser)}
                        />
                    ))}
                    <UserPicker
                        disabled={isDisabled}
                        onAddRow={handleAddRow}
                        size={'sm'}
                        title={t`Add attendee`}
                        icon={faPlus}
                        shape={'circle'}
                        onUserSelected={handleUserSelected}
                        usersToHide={meetingUsers}
                    />
                </div>
            </div>
        </div>
    );
};
