import React from 'react';
import { t } from '@lingui/macro';
import { difference, isEmpty } from 'lodash-es';
import { Select, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/TaskDetailCustomFields';
import { CustomFieldSelectColoredOptionLabel } from 'Shared/components/task/TaskDetail/shared/CustomFieldSelectColoredOptionLabel';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldMultipleChoiceInput = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
}: {
    taskId: Id;
    customField: CustomField;
    values: CustomFieldValue[];
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    onDelete?: (customFieldId: Id, valueIds: Id[]) => Promise<void>;
}) => {
    const { isTaskReadonly } = useTask(taskId);

    const handleUpdate = (newIds) => {
        const currentIds = values.map((value) => value.custom_field_option_id);
        const added = difference(newIds, currentIds);
        const deleted = difference(currentIds, newIds);
        if (added.length > 0) {
            added.map((newValue) => onUpdate(customField.id, null, { custom_field_option_id: newValue }));
        }
        if (deleted.length > 0) {
            deleted.forEach((id) => {
                const value = values.find((v) => v.custom_field_option_id === id);
                onDelete(customField.id, [value.id || value.custom_field_option_id]);
            });
        }
    };

    return (
        <Select
            multiple
            customRenderSelected={(value: string[]) => (
                <>
                    {value.map((v) => (
                        <CustomFieldSelectColoredOptionLabel
                            className={'mr-2 inline'}
                            key={v}
                            option={(customField.options || []).find((o) => o.id === v)}
                        />
                    ))}
                </>
            )}
            disabled={isTaskReadonly}
            placeholder={t`Select ${customField.label}`}
            value={(values || []).map((v) => v.custom_field_option_id)}
            onChange={handleUpdate}
        >
            {(customField.options || []).map((option) => (
                <Select.Option key={option.id} value={option.id}>
                    <CustomFieldSelectColoredOptionLabel option={option} />
                </Select.Option>
            ))}
        </Select>
    );
};

export const TaskDetailCustomFieldMultipleChoice = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    return (
        <TaskDetailCustomFieldWrapper
            taskId={taskId}
            hasValue={!isEmpty(values)}
            customField={customField}
            onDelete={() =>
                onDelete(
                    customField.id,
                    values.map((v) => v.id),
                )
            }
        >
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldMultipleChoiceInput
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
