import { I18n } from '@lingui/core';
import { i18n as i18nCore } from '@lingui/core';
import { plural, t } from '@lingui/macro';
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
import { getDateFnsLocale, formatDate, getLocale } from '@wedo/utils';
import { Recurrence } from 'Shared/types/formRecurrence';

export type Day = {
    label: string;
    selected: boolean;
    index: number;
};
export type MonthDay = {
    label: string;
    number: number;
};
export type Month = {
    label: string;
    selected: boolean;
    index: number;
};
export type Week = {
    selected: boolean;
    label: string;
    index: number;
};

export const getMonthDays = (i18n: typeof i18nCore): MonthDay[] => {
    const monthDays = [];
    monthDays.push({ number: 0, label: t`first business day` });

    for (let i = 1; i <= 30; i++) {
        monthDays.push({
            number: i,
            label: format(new Date(2015, 7, i), 'do', { locale: getDateFnsLocale(i18n?.locale) }),
        });
    }

    monthDays.push({ number: 31, label: t`last day` });
    monthDays.push({ number: 32, label: t`last business day` });

    return monthDays;
};

export const getWeekDayList = (i18n: typeof i18nCore, dateFormat = 'EEEEEE'): Day[] => {
    const days: Day[] = [];
    const locale = getLocale(i18n.locale);
    let i = (locale.DATETIME_FORMATS.FIRSTDAYOFWEEK + 1) % 7;
    const now = new Date();
    const arr = eachDayOfInterval({ start: startOfWeek(now), end: endOfWeek(now) });
    while (days.length < 7) {
        days.push({
            label: format(arr[i], dateFormat, { locale: getDateFnsLocale(i18n?.locale) }),
            index: i,
            selected: false,
        });
        i = (i + 1) % 7;
    }
    return days;
};

export const getWeekDays = (i18n: typeof i18nCore, current: number[], dateFormat = 'EEEEEE'): Day[] => {
    const weekDayListWithSelection = getWeekDayList(i18n, dateFormat);
    for (let i = 0; i < weekDayListWithSelection.length; i++) {
        const day = weekDayListWithSelection[i];
        day.selected = current?.length > 0 ? current.includes(day.index) : new Date().getDay() === day.index;
    }
    return weekDayListWithSelection;
};

export const getWeekDay = (day: number, dateFormat: string, i18n: typeof i18nCore): string => {
    const weekDays = getWeekDays(i18n, [], dateFormat);
    return weekDays.find((wd) => {
        return wd.index === day;
    })?.label;
};

export const getWeeksList = (): Omit<Week, 'selected'>[] => {
    return [
        {
            label: '1',
            index: 1,
        },
        {
            label: '2',
            index: 2,
        },
        {
            label: '3',
            index: 3,
        },
        {
            label: '4',
            index: 4,
        },
        {
            label: t`last`,
            index: 5,
        },
    ];
};

const getWeekName = (index: number): string => {
    switch (index) {
        case 1:
            return t`first`;
        case 2:
            return t`second`;
        case 3:
            return t`third`;
        case 4:
            return t`fourth`;
        case 5:
            return t`last`;
        default:
            return '';
    }
};
export const getMonthsList = (i18n: typeof i18nCore): Omit<Month, 'selected'>[] => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        months.push({
            index: i,
            label: format(new Date(2021, i, 1), 'MMM', { locale: getDateFnsLocale(i18n?.locale) }),
        });
    }
    return months;
};

export const getRecurrenceSummary = (recurrence: Recurrence, i18n: I18n): string => {
    let summary = '';
    if (recurrence == null) {
        return summary;
    }
    let nb = 0;
    let day, days;
    let week, weeks;

    if (recurrence.frequency === 'daily') {
        summary = plural(recurrence.repeat_every, {
            one: 'Daily',
            other: `Every ${recurrence.repeat_every} days`,
        });
    } else if (recurrence.frequency === 'weekly') {
        summary = plural(recurrence.repeat_every, {
            one: 'Weekly',
            other: `Every ${recurrence.repeat_every} weeks`,
        });

        nb = 1;
        days = recurrence.days || [];
        for (day in days) {
            if (nb === 1) {
                summary += t`, on`;
            } else if (nb === recurrence.days.length) {
                summary += ' ' + t`and`;
            } else {
                summary += ',';
            }
            summary += ' ' + getWeekDay(days[day], 'EEEE', i18n);
            nb++;
        }
    } else if (recurrence.frequency === 'monthly') {
        summary = plural(recurrence.repeat_every, {
            one: 'Monthly',
            other: `Every ${recurrence.repeat_every} months`,
        });

        if (recurrence.weeks == null) {
            day = recurrence.days?.[0];
            if (day == null) {
                return '';
            }
            if (day === 0) {
                summary += t`, on the first business day`;
            } else if (day === 32) {
                summary += t`, on the last business day`;
            } else if (day === 31) {
                summary += t`, on the last day`;
            } else {
                summary += t`, on the ${format(new Date(2015, 7, day), 'do', {
                    locale: getDateFnsLocale(i18n?.locale),
                })} day`;
            }
        } else {
            weeks = recurrence.weeks || [];
            nb = 1;
            for (week in weeks) {
                if (nb === 1) {
                    summary += ' ' + t`on the `;
                } else if (nb === recurrence.weeks.length) {
                    summary += ' ' + t`and `;
                } else {
                    summary += ', ';
                }

                summary += ' ' + getWeekName(weeks[week]);
                nb++;
            }

            nb = 1;
            days = recurrence.days || [];
            for (day in days) {
                if (nb === 1) {
                    summary += ' ';
                } else if (nb === recurrence.days.length) {
                    summary += ' ' + t`and `;
                } else {
                    summary += ', ';
                }
                summary += ' ' + getWeekDay(days[day], 'EEEE', i18n);
                nb++;
            }
        }
    } else if (recurrence.frequency === 'yearly') {
        summary = plural(recurrence.repeat_every, {
            one: 'Yearly',
            other: `Every ${recurrence.repeat_every} years`,
        });

        if (recurrence.weeks == null) {
            day = recurrence.days?.[0];
            if (day == null) {
                return '';
            }
            if (day === 0) {
                summary += t`, on the first business day`;
            } else if (day === 32) {
                summary += t`, on the last business day`;
            } else if (day === 31) {
                summary += t`, on the last day`;
            } else {
                summary += t`, on the ${format(new Date(2015, 7, day), 'do', {
                    locale: getDateFnsLocale(i18n?.locale),
                })} day`;
            }
        } else {
            weeks = recurrence.weeks || [];
            nb = 1;
            for (week in weeks) {
                if (nb === 1) {
                    summary += ' ' + t`on the `;
                } else if (nb === recurrence.weeks.length) {
                    summary += ' ' + t`and `;
                } else {
                    summary += ', ';
                }

                summary += ' ' + getWeekName(weeks[week]);
                nb++;
            }

            nb = 1;
            days = recurrence.days || [];
            for (day in days) {
                if (nb === 1) {
                    summary += ' ';
                } else if (nb === recurrence.days.length) {
                    summary += ' ' + t`and `;
                } else {
                    summary += ', ';
                }
                summary += ' ' + getWeekDay(days[day], 'EEEE', i18n);
                nb++;
            }
        }

        nb = 1;
        const months = recurrence.months || [];
        for (const month in months) {
            if (nb === 1) {
                summary += ' ' + t`of `;
            } else if (nb === recurrence.months.length) {
                summary += ' ' + t`and `;
            } else {
                summary += ', ';
            }
            summary +=
                ' ' + format(new Date(2015, months[month], 1), 'MMMM', { locale: getDateFnsLocale(i18n?.locale) });
            nb++;
        }
    } else {
        summary = t`Once`;
    }

    if (recurrence.count != null && recurrence.until !== null) {
        summary += ', ';

        if (recurrence.count) {
            summary = plural(recurrence.count, {
                one: 'once',
                other: `${recurrence.count} times`,
            });
        }
        if (recurrence.until) {
            summary += t`until ${formatDate(new Date(recurrence.until), 'shortDate', i18n)}`;
        }
    }

    return summary;
};
