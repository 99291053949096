import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

export const teamMembership = (user: User, team: Team) => {
    const teamMember = team.userGroup.members.find((member) => member.user.id === user.id) ?? undefined;
    const isTeamMember = teamMember !== undefined;
    const isTeamModerator = teamMember?.is_moderator === true;

    return { isTeamMember, isTeamModerator };
};

export const computeTeamWithWorkspacesAndTemplates = (
    teams: Team[],
    workspaces: Workspace[],
    templates: Template[],
    showAll: boolean
) =>
    [{ id: null, isFavorite: true } as Team]
        .concat(teams ?? [])
        ?.filter(({ isFavorite }) => showAll || isFavorite)
        .map((team) => ({
            ...team,
            workspaces: workspaces?.filter(({ teamId, isFavorite }) => teamId === team.id && (showAll || isFavorite)),
            checklistTemplates: templates?.filter(
                ({ teamId, isFavorite }) => teamId === team.id && (showAll || isFavorite)
            ),
        }));
