export const SUBSCRIPTION_ADDRESS_STEP_URL = '/onboarding/subscribe/address';

export const SUBSCRIPTION_PLAN_STEP_URL = '/onboarding/subscribe/plan';

export const SUBSCRIPTION_CONFIRMATION_STEP_URL = '/onboarding/subscribe/confirm';
export const SUBSCRIPTION_ADDON_STEP_URL = '/onboarding/subscribe/addons';

export const getValidLicenseOptions = (currentUsers: number, options?: { allowZeroLicenses: boolean }) => {
    let userPlans = [...(options?.allowZeroLicenses ? [0] : []), 1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45];

    for (let i = 50; i <= 200; i += 10) {
        userPlans.push(i);
    }

    let startingPoint = 1;

    for (const plans of userPlans) {
        if (plans >= currentUsers) {
            startingPoint = plans;
            break;
        }
    }
    if (startingPoint === 1 && currentUsers > 1) {
        startingPoint = currentUsers;
    }

    if (startingPoint > 99) {
        for (let i = 225; i <= startingPoint * 3; i += 25) {
            userPlans.push(i);
        }
    }

    userPlans = userPlans.filter((nbUser) => nbUser >= startingPoint);

    return userPlans;
};
