import React from 'react';
import { faCog, faEllipsisV, faLock, faUnlock, faUserLock, faVoteYea } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    Dropdown,
    ModalType,
    Tag,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { EditMeetingAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingAccessModal';
import { MeetingSettingsModal } from 'Pages/meeting/components/MeetingSettingsModal';
import { RequestVotesModal } from 'Pages/meeting/components/Vote/RequestVotesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    buildGetVoteBlockParameters,
    useGetVoteQuery,
    useUpdateMeetingVotesStatusesMutation,
    useUpdateVoteMutation,
} from 'Shared/services/meetingVote';
import { Vote } from 'Shared/types/vote';

interface VoteDetailFooterProps {
    voteId: Id;
    meetingId: Id;
}

export const VoteDetailHeader = ({ voteId, meetingId }: VoteDetailFooterProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);
    const { open } = useModal();
    const { show } = useNotification();
    const { confirm } = useConfirm();

    const { data: vote } = useGetVoteQuery(buildGetVoteBlockParameters(voteId, meetingId), {
        skip: voteId == null,
        refetchOnMountOrArgChange: true,
    });
    const [updateMeetingVotesStatuses] = useUpdateMeetingVotesStatusesMutation();
    const [updateVote] = useUpdateVoteMutation();

    const handleOptionsMenuClick = (key: string) => {
        switch (key) {
            case 'request-votes': {
                open(RequestVotesModal, { meetingId: meeting.id });
                break;
            }
            case 'manage-voters': {
                open(EditMeetingAccessModal, { meetingId: meeting.id, mode: 'voter', size: 'md' });
                break;
            }
            case 'votes-settings': {
                open(MeetingSettingsModal, { defaultOpenedPane: 'votes', meetingId: meeting.id });
                break;
            }
            default:
                break;
        }
    };

    const handleVoteChanges = async (changes: Partial<Vote>) => {
        await updateVote({ meetingId: meetingId, voteId: vote.id, changes })
            .unwrap()
            .catch(() => show(UnexpectedErrorNotification));
    };

    const handleLockButtonClick = async () => {
        const applyOn = (await confirm(
            {
                title:
                    vote?.status === 'closed'
                        ? t`Which votes do you want to open?`
                        : t`Which votes do you want to close?`,
                type: ModalType.Info,
                customApplyOn: [
                    { message: t`Only this vote`, value: 'this' },
                    { message: t`All votes in this meeting`, value: 'all' },
                ],
                showAll: false,
                showFuture: false,
                showThis: false,
                defaultOption: 'this',
            },
            ConfirmSaveMeetingModal
        )) as unknown as string;
        if (applyOn == null) {
            return;
        }

        if (applyOn === 'all') {
            await updateMeetingVotesStatuses({
                meetingId: meetingId,
                status: vote?.status === 'closed' ? 'open' : 'closed',
            })
                .unwrap()
                .catch(() => show(UnexpectedErrorNotification));
            return;
        }
        const newStatus = vote?.status === 'closed' ? 'open' : 'closed';
        await handleVoteChanges({
            status: vote?.status === 'closed' ? 'open' : 'closed',
            ...(newStatus === 'open' ? { outcome_vote_option_id: null } : {}),
        });
    };

    return (
        <div className="flex flex-col border-b border-gray-200 py-2">
            <div className="flex items-center justify-between px-2">
                <div className="flex items-center gap-2">
                    <Button
                        disabled={meeting?.status === 'locked' || meeting?.deleted}
                        icon={vote?.status === 'open' ? faLock : faUnlock}
                        onClick={handleLockButtonClick}
                        size="sm"
                    >
                        {vote?.status === 'open' ? t`Close vote` : t`Reopen vote`}
                    </Button>
                    {vote?.status === 'closed' && (
                        <div>
                            <Tag icon={faLock} color="green">
                                <Trans>Vote closed</Trans>
                            </Tag>
                        </div>
                    )}
                </div>
                <Dropdown icon={faEllipsisV} size="sm">
                    <Dropdown.Item
                        icon={faVoteYea}
                        onClick={() => handleOptionsMenuClick('request-votes')}
                    >{t`Request votes`}</Dropdown.Item>
                    <Dropdown.Item
                        icon={faUserLock}
                        onClick={() => handleOptionsMenuClick('manage-voters')}
                    >{t`Manage voters`}</Dropdown.Item>
                    <Dropdown.Item
                        icon={faCog}
                        onClick={() => handleOptionsMenuClick('votes-settings')}
                    >{t`Votes settings`}</Dropdown.Item>
                </Dropdown>
            </div>
        </div>
    );
};
