import { FC } from 'react';
import { Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useTemplateManageMembers } from 'Shared/components/template/TemplateManageMembers/useTemplateManageMembers';
import { ManageMembersModalBody } from 'Shared/components/userGroup/ManageMembersModalBody';
import { useManageMembers } from 'Shared/hooks/useManageMembers';

type MembersPanelProps = {
    templateId: Id;
    isReadOnly: boolean;
    onUserDeleted: (action: string) => void;
};

export const MembersPanel: FC<MembersPanelProps> = ({ templateId, isReadOnly: isReadOnlyInitial, onUserDeleted }) => {
    const {
        template,
        handleAddUser,
        handleDeleteUser: deleteUser,
        handleModeratorToggle,
        handleTogglePrivacy,
    } = useTemplateManageMembers(templateId);

    const { isCurrentUserModerator } = useManageMembers(template);

    const isReadOnly = isReadOnlyInitial || !isCurrentUserModerator;

    const handleDeleteUser = async (user) => {
        onUserDeleted(await deleteUser(user));
    };

    return (
        <Tabs.Panel>
            <ManageMembersModalBody
                className="!p-0"
                tableBodyClassName="overflow-hidden"
                isReadOnly={isReadOnly}
                entity={template}
                onToggleModerator={handleModeratorToggle}
                onAddUser={handleAddUser}
                onDeleteUser={handleDeleteUser}
                onPrivacyChange={handleTogglePrivacy}
            />
        </Tabs.Panel>
    );
};
