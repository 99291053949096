import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { camelToSnake } from 'caseparser';
import { create, createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Id } from '@wedo/types';
import { buildCacheKey, EmptyArray, EmptyObject } from '@wedo/utils';
import { usePendingTasksStore } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { trpcUtils } from 'Shared/trpc';
import { SearchType } from 'Shared/types/search';
import { Task, TaskFilter, TaskOrder, TaskStatus } from 'Shared/types/task';

const CleaningTimeout = 60_000;

type FetchTasksParams = {
    view?: TaskFilter;
    statuses?: TaskStatus[];
    order?: TaskOrder;
    grouping?: TaskOrder;
    search?: string;
    searchType?: SearchType;
    workspaceId?: string;
    userId?: string;
    checklistId?: string;
    templateId?: string;
    meetingId?: string;
    parentTaskId?: string;
    workspaces?: string[];
    related?: Array<'isBlocked'>;
};

type TasksStoreEntry = {
    cleaningTimeout: number;
    tasks: Task[];
    pageSize: number;
    canFetchMore: boolean;
    isFetching: boolean;
    error: Error;
};

type TasksStore = Record<string, TasksStoreEntry>;

const currentParamsStore = createStore<FetchTasksParams>()(() => null);

const useTasksStore = create<TasksStore>()(immer(() => EmptyObject));

type TasksContextType = {
    tasks: Array<Task>;
    isLoading: boolean;
    selectedTasks: Task[];
    recentlyCreatedTaskId: Id;
    params: FetchTasksParams;
    hiddenTaskIds: Set<Id>;
    setSelectedTasks: (selectedTasks: Task[]) => void;
    setRecentlyCreatedTaskId: (recentlyCreatedTaskId: Id) => void;
    resetCurrentParams: () => void;
};

const TasksContext = createContext<TasksContextType>({
    tasks: [],
    isLoading: false,
    selectedTasks: [],
    recentlyCreatedTaskId: null,
    params: null,
    hiddenTaskIds: new Set(),
    setSelectedTasks: () => null,
    setRecentlyCreatedTaskId: () => null,
    resetCurrentParams: () => null,
});

export const useTasksContext = (): TasksContextType => useContext(TasksContext);

type TasksContextProviderProps = {
    children: ReactNode;
    params: FetchTasksParams;
    hiddenTaskIds?: Set<Id>;
    selectedTasks?: Task & { groupedId: string }[];
    recentlyCreatedTaskId?: Id;
};

export const TasksContextProvider: React.FC<TasksContextProviderProps> = ({
    children,
    params,
    hiddenTaskIds = new Set(),
    selectedTasks: initialSelectedTasks = [],
    recentlyCreatedTaskId: initialCreatedTaskId = null,
}) => {
    const [selectedTasks, setSelectedTasks] = useState<Task & { groupedId: string }>(initialSelectedTasks);
    const [recentlyCreatedTaskId, setRecentlyCreatedTaskId] = useState<Id>(initialCreatedTaskId);

    currentParamsStore.setState(params, true);

    const currentTasksSelector = (state: TasksStore) => state[buildCacheKey(params)]?.tasks ?? EmptyArray;

    const currentTasks = currentTasksSelector(useTasksStore.getState());

    const resetCurrentParams = () => currentParamsStore.setState(params, true);

    return (
        <TasksContext.Provider
            value={{
                tasks: currentTasks,
                isLoading: false,
                selectedTasks,
                recentlyCreatedTaskId,
                params,
                hiddenTaskIds,
                setSelectedTasks,
                setRecentlyCreatedTaskId,
                resetCurrentParams,
            }}
        >
            {children}
        </TasksContext.Provider>
    );
};

const setState = (key: string, set: (entry: TasksStoreEntry) => void) => {
    useTasksStore.setState((state) => {
        if (state[key] == null) {
            state[key] = {
                cleaningTimeout: null,
                tasks: EmptyArray,
                pageSize: 30,
                canFetchMore: true,
                isFetching: false,
                error: null,
            };
        }
        set(state[key]);
    });
};

const useStore = <T,>(key: string, selector: (entry: TasksStoreEntry) => T) =>
    useTasksStore((state) => selector(state[key]));

const fetchTasks = (page: number, pageSize: number, params: FetchTasksParams) =>
    (params.searchType != null ? trpcUtils().task.search : trpcUtils().task.list)
        .fetch({
            ...params,
            workspaceIds:
                params.workspaces?.length > 0
                    ? params.workspaces
                    : params.workspaceId != null
                      ? [params.workspaceId]
                      : undefined,
            page,
            pageSize,
        })
        .then(camelToSnake);

const safeSetState = (key: string, set: (entry: TasksStoreEntry) => void) => {
    if (key === buildCacheKey(currentParamsStore.getState())) {
        setState(key, set);
    }
};

export const invalidateCachedTasks = () => {
    usePendingTasksStore.setState({
        assigneeTasks: new Map(),
        completedTasks: new Set(),
        openTasks: new Set(),
        deletedTasks: new Set(),
    });

    void trpcUtils().task.invalidate();

    const params = currentParamsStore.getState();
    const key = buildCacheKey(params);
    const entry = useTasksStore.getState()[key];

    if (entry != null) {
        const pages = Math.ceil((entry.tasks.length === 0 ? 1 : entry.tasks.length) / entry.pageSize);
        Promise.all(Array.from(Array(pages)).map((_, page) => fetchTasks(page + 1, entry.pageSize, params)))
            .then((responses) => {
                safeSetState(key, (state) => {
                    state.tasks = responses.flat();
                    state.error = null;
                });
            })
            .catch((e) => {
                safeSetState(key, (state) => {
                    state.canFetchMore = false;
                    state.error = e;
                });
            });
    }
};

const fetchTasksAndUpdateCache = async (page: number, pageSize: number, params: FetchTasksParams, replace = false) => {
    const key = buildCacheKey(params);
    safeSetState(key, (state) => {
        state.isFetching = true;
    });

    return fetchTasks(page, pageSize, params)
        .then((data) => {
            safeSetState(key, (state) => {
                state.pageSize = pageSize;
                state.canFetchMore = data.length >= pageSize;
                if (replace) {
                    state.tasks = data;
                } else {
                    state.tasks.push(...data);
                }

                state.error = null;
            });
        })
        .catch((e) => {
            safeSetState(key, (state) => {
                state.canFetchMore = false;
                state.error = e;
            });
        })
        .finally(() => {
            safeSetState(key, (state) => {
                state.isFetching = false;
            });
        });
};

type UseTasksList = (params: { pageSize: number; onPageLoad?: () => void }) => {
    error: Object;
    tasks: Task[];
    key: string;
    isLoading: boolean;
    isFetching: boolean;
    canFetchMore: boolean;
    fetchMore: () => void;
    changePage: (page: number) => void;
};

export const useTasksList: UseTasksList = ({ pageSize, onPageLoad }) => {
    const { params, hiddenTaskIds } = useTasksContext();
    const key = buildCacheKey(params);

    const initialTasks = useStore(key, (state) => state?.tasks);
    const error = useStore(key, (state) => state?.error);
    const [page, setPage] = useState(1);
    const forceReplace = useRef(page === 1);
    const [isLoading, setIsLoading] = useState(initialTasks == null);
    const isFetching = useStore(key, (state) => state?.isFetching ?? false);

    const tasks = useMemo(() => {
        if (hiddenTaskIds?.size > 0) {
            return initialTasks?.filter(({ id }) => !hiddenTaskIds.has(Number(id)));
        }
        return initialTasks;
    }, [initialTasks, hiddenTaskIds]);

    /** fetch the next page of tasks and return "true" if we can load more or "false" otherwise */
    const fetchMore = (): boolean => {
        if (useTasksStore.getState()[key]?.canFetchMore) {
            setPage(page + 1);
            return true;
        }

        return false;
    };

    const changePage = (page: number) => {
        forceReplace.current = true;
        setPage(page);
    };

    useEffect(() => {
        if (pageSize != null) {
            fetchTasksAndUpdateCache(page, pageSize, params, forceReplace.current).then(() => {
                setIsLoading(false);
                onPageLoad?.();
            });
            forceReplace.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize]);

    useEffect(() => {
        const cleaningTimeout = useTasksStore.getState()[key]?.cleaningTimeout;
        if (cleaningTimeout != null) {
            clearTimeout(cleaningTimeout);
        }
        return () => {
            setState(key, (state) => {
                // When unmounting the tasks, we slice the tasks to pageSize because when we go back to these tasks,
                // only the first page is shown
                state.tasks = state.tasks.slice(0, pageSize);
                state.cleaningTimeout = setTimeout(() => {
                    useTasksStore.setState((state) => {
                        delete state[key];
                    });
                }, CleaningTimeout) as unknown as number;
            });
        };
    }, []);

    return {
        error,
        tasks: tasks ?? EmptyArray,
        isLoading,
        isFetching,
        key,
        canFetchMore: useStore(key, (state) => state?.canFetchMore),
        fetchMore,
        changePage,
    };
};
