import React, { useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Form,
    Input,
    Modal,
    Select,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { allEventAction, allObjectType, displayObjectAction } from 'Pages/settings/api/utils/webhook';
import { CreateProps, createWebhookError, useCreateWebhookMutation } from 'Shared/services/webhook';
import { ApiError } from 'Shared/types/apiError';

const ObjectType = ['null', 'checklist', 'attachment', 'meeting', 'tag', 'task', 'team', 'user'] as const;

const EventAction = ['null', 'create', 'delete', 'update'] as const;

export const AddWebhookModal = ({ close, ...modalProps }: ContextModalProps): JSX.Element => {
    const { show } = useNotification();
    const urlInputRef = useRef<HTMLInputElement>();

    const [create, { isLoading, error }] = useCreateWebhookMutation();

    // properties of the object sent to the server
    const [objectType, setObjectType] = useState<(typeof ObjectType)[number]>('null');
    const [actionType, setActionType] = useState<(typeof EventAction)[number]>('null');
    const [endpoint, setEndpoint] = useState('');

    const inlineError = error instanceof ApiError && error.matchesSome(createWebhookError);

    const handleSave = async () => {
        const createObject: CreateProps = {
            eventAction: { code: '' },
            objectType: { code: '' },
            url: endpoint,
        };

        if (objectType !== 'null') {
            createObject.objectType = { code: objectType };
            createObject.object_type = objectType;
        } else {
            createObject.objectType = null;
        }

        if (actionType !== 'null') {
            createObject.eventAction = { code: actionType };
            createObject.event_action = actionType;
        } else {
            createObject.eventAction = null;
        }

        const response = await create(createObject);

        if ('error' in response && !(response.error as ApiError).matchesSome(createWebhookError)) {
            show(UnexpectedErrorNotification);
            return;
        }

        if ('data' in response) {
            await close();
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (endpoint === '') {
            return;
        }

        void handleSave();
    };

    return (
        <Modal {...modalProps} initialFocus={urlInputRef}>
            <Modal.Header title={t`Add webhook`} />
            <Modal.Body>
                <div className="mb-4 flex gap-4">
                    <div className="grow">
                        <Select
                            placeholder=""
                            label={t`Object type`}
                            value={objectType}
                            onChange={(val: (typeof ObjectType)[0]) => setObjectType(val)}
                            customRenderSelected={(value: (typeof ObjectType)[0]) => (
                                <span>{displayObjectAction(value, allObjectType)}</span>
                            )}
                        >
                            {ObjectType.map((type) => (
                                <Select.Option key={type} value={type} disabled={false}>
                                    {displayObjectAction(type, allObjectType)}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>

                    <div className="grow">
                        <Select
                            placeholder=""
                            label={t`Action type`}
                            value={actionType}
                            onChange={(val: (typeof EventAction)[0]) => setActionType(val)}
                            customRenderSelected={(value: (typeof EventAction)[0]) => (
                                <span>{displayObjectAction(value, allEventAction)}</span>
                            )}
                        >
                            {EventAction.map((type) => (
                                <Select.Option key={type} value={type} disabled={false}>
                                    {displayObjectAction(type, allEventAction)}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <Form.Item label={t`Endpoint URL`} htmlFor="webhook-add-url">
                        <Input
                            ref={urlInputRef}
                            id="webhook-add-url"
                            value={endpoint}
                            onChange={(e) => setEndpoint(e.target.value.trim())}
                            placeholder="https://api.mywebsite.com/notifications"
                            status={inlineError ? 'error' : 'default'}
                            statusText={inlineError && error.message}
                        />
                    </Form.Item>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button loading={isLoading} disabled={endpoint === ''} onClick={handleSave} color="primary">
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
