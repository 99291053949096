import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Modal } from '@wedo/design-system';

export const IntercomBlockedModal: FC<ContextModalProps> = ({ ...modalProps }) => {
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Chat support is disabled`} />
            <Modal.Body>
                <h3 className="text-base font-medium leading-6 text-gray-900">
                    <Trans>If you have an Ad blocker extension</Trans>
                </h3>
                <ul className="mt-2 list-disc pl-6">
                    <li>
                        <Trans>Click on the extension menu and disable ad blocking for this website.</Trans>
                    </li>

                    <li>
                        <Trans>Refresh your browser and retry to click on the support button.</Trans>
                    </li>
                </ul>

                <Alert
                    type="info"
                    title={
                        <Trans>
                            If you still have issues, please contact us at{' '}
                            <Link to="mailto:info@wedo.com" className="hover:underline">
                                <b>info@wedo.com</b>
                            </Link>
                        </Trans>
                    }
                    className="mt-4"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
