import React from 'react';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Button, Skeleton, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { usePendingTasks } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { TaskDetailIcon } from 'Shared/components/task/TaskDetail/shared/TaskDetailIcon';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useTask } from 'Shared/hooks/useTask';
import { TaskFilter } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { TaskDetailRow } from '../shared/TaskDetailRow';

type TaskDetailAssigneeProps = {
    taskId: Id;
    workspaceId: Id;
    checklistId?: Id;
    templateId?: Id;
    meetingId?: Id;
    defaultView?: TaskFilter;
};

export const TaskDetailAssignee = ({
    taskId,
    workspaceId,
    templateId,
    checklistId,
    meetingId,
    defaultView,
}: TaskDetailAssigneeProps) => {
    const [{ view = defaultView }] = useSearchParams(TasksPageSearchParams);

    const { task, handleTaskUpdate, isLoadingTask, isTaskReadonly, isUpdating } = useTask(taskId);
    const { currentUserId } = useCurrentUserContext();
    const { toggleAssigneeTask } = usePendingTasks();
    const { contextTitle, contextUsers } = useContextUsers({ workspaceId, templateId, checklistId, meetingId });

    const handleAssigneeChange = async (user: User) => {
        if (view === 'me') {
            toggleAssigneeTask(task, user);
        }

        const response = await handleTaskUpdate({
            assignee_id: user ? user.id : null,
            is_new: user?.id && user.id !== currentUserId,
            keepCache: view === 'me',
        });

        if ('error' in response) {
            invalidateCachedTasks();
        }
    };

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={task?.assignee == null ? t`Assignee` : null} delay={300}>
                    <>
                        {isLoadingTask ? (
                            <Skeleton className="!h-5 !w-5 !rounded-full" />
                        ) : task?.assignee ? (
                            <UserAvatar size="xs" user={task?.assignee as User} />
                        ) : (
                            <TaskDetailIcon type="assignee" isActive={false} />
                        )}
                    </>
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    <UserPicker
                        className={'max-w-full'}
                        showNobody={task?.assignee !== undefined}
                        contextTitle={contextTitle}
                        contextUsers={contextUsers}
                        aria-label={task?.assignee ? t`Assignee` + ': ' + task.assignee.full_name : t`Assign to`}
                        variant={'text'}
                        disabled={isTaskReadonly}
                        onUserSelected={handleAssigneeChange}
                        usersToHide={task?.assignee ? [task.assignee] : undefined}
                    >
                        <div className={'truncate'}>{task?.assignee ? task.assignee.full_name : t`Assign to`}</div>
                    </UserPicker>
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {task?.assignee && !isTaskReadonly && (
                    <Button
                        size={'sm'}
                        className={'enabled:hover:bg-red-100 enabled:hover:text-red-500'}
                        variant={'text'}
                        disabled={isTaskReadonly}
                        icon={faXmark}
                        onClick={() => handleAssigneeChange(null)}
                        title={t`Remove`}
                        loading={isUpdating}
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
