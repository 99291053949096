import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { useGetTaskCommentsQuery } from 'Shared/services/taskComment';
import { Comment } from './Comment';

const PageSize = 10;

export const CommentsList = ({ taskId }: { taskId: Id }) => {
    const { data = EmptyArray, isLoading } = useGetTaskCommentsQuery(taskId, {
        refetchOnMountOrArgChange: true,
        skip: !taskId,
    });
    const [offset, setOffset] = useState(PageSize);

    useEffect(() => {
        setOffset(PageSize);
    }, [taskId]);

    return (
        <div className={'flex flex-col gap-2 px-4 py-2'}>
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                data
                    .filter((item, index) => index <= offset)
                    .map((comment) => <Comment key={comment.id} comment={comment} taskId={taskId} />)
            )}
            {offset < data?.length && (
                <Button variant="link" onClick={() => setOffset((value) => value + PageSize)}>
                    <Trans>Show more...</Trans>
                </Button>
            )}
        </div>
    );
};
