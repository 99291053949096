import { FC, PropsWithChildren } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPalette } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { ButtonColor, ButtonProps } from '~/components/Button/Button';
import { Popover } from '~/components/Popover/Popover';
import { contrastingColor } from '~/utils/color';
import { ColorPicker, ColorPickerProps } from './ColorPicker';

type ColorPickerPopoverProps = {
    classNameButton?: string;
    classNamePicker?: string;
    buttonColor?: ButtonColor;
    icon?: IconDefinition;
    showSelectedColor?: boolean;
} & Omit<ColorPickerProps, 'className'> &
    Omit<ButtonProps, 'icon' | 'color' | 'onChange'> &
    PropsWithChildren;

export const ColorPickerPopover: FC<ColorPickerPopoverProps> = ({
    color,
    classNameButton = '',
    classNamePicker = '',
    onChange = () => null,
    iconSize,
    buttonColor,
    variant = 'outlined',
    icon = faPalette,
    showSelectedColor = false,
    canDeselectColor = true,
    children,
    isDisabled,
    ...props
}) => {
    const iconColor = color && contrastingColor(color);

    const popoverButtonStyles = {
        background: color,
        color: iconColor,
    };

    const handleColorChange = (closePopover: () => void, color: string): void => {
        onChange(color);
    };
    return (
        <Popover
            className={clsx('transition-colors', showSelectedColor && 'hover:enabled:opacity-80', classNameButton)}
            style={showSelectedColor ? popoverButtonStyles : null}
            icon={icon}
            iconSize={iconSize}
            color={buttonColor}
            variant={variant}
            text={children}
            {...props}
        >
            {({ close }) => (
                <div className="z-10 bg-white p-2">
                    <ColorPicker
                        className={classNamePicker}
                        color={color}
                        onChange={(color) => handleColorChange(close, color)}
                        canDeselectColor={canDeselectColor}
                        isDisabled={isDisabled}
                    />
                </div>
            )}
        </Popover>
    );
};
