import { Trans } from '@lingui/react';
import { FC } from 'react';
import { Select } from '@wedo/design-system';
import { EmptyFunction } from '@wedo/utils';
import { IndustryOptions } from 'Pages/onboarding/utils/selectOptions';
import { Organization } from 'Shared/types/organization';

type OrganisationIndustrySelectorProps = {
    id: string;
    value: Organization['cf_industry'];
    onChange: (value: Organization['cf_industry']) => void;
    className?: string;
    popoverClassName?: string;
    wrapperClassName?: string;
    onBlur?: () => void;
};

export const OrganisationIndustrySelector: FC<OrganisationIndustrySelectorProps> = ({
    id,
    value,
    onChange,
    className,
    popoverClassName,
    wrapperClassName,
    onBlur = EmptyFunction,
}) => {
    return (
        <Select
            id={id}
            value={value}
            onChange={onChange}
            customRenderSelected={(key: Organization['cf_industry']) => <Trans id={IndustryOptions.get(key)?.id} />}
            popoverClassName={popoverClassName}
            wrapperClassName={wrapperClassName}
            className={className}
            onBlur={onBlur}
        >
            {[...IndustryOptions.keys()].map((key) => (
                <Select.Option key={key} value={key}>
                    <Trans id={IndustryOptions.get(key).id} />
                </Select.Option>
            ))}
        </Select>
    );
};
