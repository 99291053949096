import { useCallback } from 'react';
import { useEvent } from './useEvent';
import { useRafState } from './useRafState';

export const useWindowSize = () => {
    const [state, setState] = useRafState<{ width: number; height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handler = useCallback(() => {
        setState({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, []);

    useEvent('resize', handler);

    return state;
};
