import { t } from '@lingui/macro';
import { Input, ItemGroup, Label } from '@wedo/design-system';
import { FormRecurrence } from 'Shared/types/formRecurrence';

type RecurrenceRepeatEveryInputProps = {
    state: FormRecurrence;
    handleValueChange?: (name: string, value: number | string) => void;
    repeatEverySuffix?: string;
};

export const RecurrenceRepeatEveryInput = ({
    state,
    handleValueChange,
    repeatEverySuffix,
}: RecurrenceRepeatEveryInputProps) => {
    return (
        <>
            <Label className="col-span-2 text-end" htmlFor={'repeatEvery'}>{t`Repeat every`}</Label>
            <div className={'col-span-4'}>
                <ItemGroup>
                    <Input
                        inputClassName={'w-full'}
                        type={'number'}
                        className={'w-16'}
                        id={'repeatEvery'}
                        min={1}
                        max={999}
                        onChange={(e) => handleValueChange('repeatEvery', e.target.value)}
                        value={state.repeatEvery}
                    ></Input>
                    <Input.Addon text={repeatEverySuffix} />
                </ItemGroup>
            </div>
        </>
    );
};
