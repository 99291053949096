import { Defs, LinearGradient, Path, Stop, Svg, View, Image } from '@react-pdf/renderer';

export const Logo = ({ url }: { url: string }) => {
    return url != null ? (
        <View style={{ alignItems: 'flex-start' }}>
            <Image src={url} style={{ height: 35 }} />
        </View>
    ) : (
        <View style={{ height: 35, width: '100%' }}>
            <Svg
                width="266"
                height="62"
                viewBox="0 0 266 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin meet"
            >
                <Path
                    d="M106.688 50.345L98.246 18.921L89.804 50.345C89.335 52.355 87.526 53.762 85.382 53.762C83.238 53.762 81.429 52.355 80.96 50.412L70.24 13.025C70.173 12.689 70.106 12.288 70.106 11.953C70.106 10.01 71.714 8.40201 73.657 8.40201C75.332 8.40201 76.672 9.541 77.074 11.082L85.784 44.582L94.963 10.881C95.365 9.47401 96.638 8.40201 98.313 8.40201C99.854 8.40201 101.194 9.47301 101.596 10.881L110.708 44.582L119.418 11.082C119.82 9.541 121.16 8.40201 122.835 8.40201C124.845 8.40201 126.386 10.009 126.386 11.953C126.386 12.288 126.319 12.689 126.252 13.025L115.599 50.412C115.063 52.354 113.321 53.762 111.177 53.762C109.033 53.762 107.224 52.355 106.688 50.345Z"
                    fill="#002D54"
                />
                <Path
                    d="M134.492 49.875V12.288C134.492 10.01 135.765 8.73602 138.11 8.73602H161.828C163.436 8.73602 164.843 10.076 164.843 11.752C164.843 13.359 163.436 14.633 161.828 14.633H141.192V27.698H161.359C162.967 27.698 164.374 28.97 164.374 30.579C164.374 32.254 162.967 33.594 161.359 33.594H141.192V47.532H161.828C163.436 47.532 164.843 48.804 164.843 50.413C164.843 52.088 163.436 53.428 161.828 53.428H138.11C135.765 53.427 134.492 52.154 134.492 49.875Z"
                    fill="#002D54"
                />
                <Path
                    d="M174.223 49.875V12.288C174.223 10.01 175.496 8.73602 177.841 8.73602H190.169C204.106 8.73602 213.352 18.251 213.352 31.115C213.352 44.047 204.106 53.427 190.169 53.427H177.841C175.496 53.427 174.223 52.154 174.223 49.875ZM190.169 47.531C200.487 47.531 206.45 40.16 206.45 31.115C206.45 21.935 200.688 14.633 190.169 14.633H180.923V47.531H190.169Z"
                    fill="#002D54"
                />
                <Path
                    d="M243.434 8C256.767 8 265.946 17.85 265.946 31.115C265.946 44.38 256.767 54.23 243.434 54.23C230.101 54.23 220.921 44.38 220.921 31.115C220.921 17.85 230.1 8 243.434 8ZM243.434 13.963C233.852 13.963 227.823 21.266 227.823 31.115C227.823 40.897 233.853 48.267 243.434 48.267C252.881 48.267 259.045 40.897 259.045 31.115C259.045 21.266 252.88 13.963 243.434 13.963Z"
                    fill="#002D54"
                />
                <Path
                    d="M2.14493 25.3675C1.44693 24.9645 0.876953 23.9766 0.876953 23.1706V2.03454C0.876953 0.222535 2.16093 -0.519475 3.73093 0.386525L36.3189 19.2015C37.0169 19.6045 37.0169 20.2635 36.3189 20.6655L16.4289 32.1485C15.7309 32.5515 14.5899 32.5515 13.8919 32.1485L2.14493 25.3675ZM16.4289 42.1745C15.7309 42.5775 14.5899 42.5775 13.8919 42.1745L2.14493 35.3925C1.44693 34.9895 0.876953 35.3195 0.876953 36.1245V59.9655C0.876953 61.7775 2.16093 62.5195 3.73093 61.6135L53.9009 32.6475C55.4699 31.7415 55.4699 30.2585 53.9009 29.3525L47.5369 25.6785C46.8389 25.2755 45.6979 25.2755 44.9999 25.6785L16.4289 42.1745Z"
                    fill="url(#paint0_linear)"
                />
                <Defs>
                    <LinearGradient
                        id="paint0_linear"
                        x1="-4.23845"
                        y1="57.9154"
                        x2="27.6856"
                        y2="16.6804"
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#0072CE" />
                        <Stop offset="1" stopColor="#2F94E7" />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
};
