import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorCard } from 'Shared/components/error/ErrorCard';

export const ErrorPage = () => {
    const error = useRouteError() as { message: string; stack: string };

    useEffect(() => {
        if (error != null) {
            datadogRum.addError(error);
        }
    }, []);

    return (
        <div className="flex h-screen justify-center bg-white overflow-auto">
            <ErrorCard message={error.message} stack={error.stack} />
        </div>
    );
};
