import { useModal } from '@wedo/design-system';
import { EmptyFunction } from '@wedo/utils';
import { useCmdKeyboardShortcut, useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { AddTemplateModal } from 'Pages/TemplatesPage/components/AddTemplateModal/AddTemplateModal';
import { AddMeetingModal } from 'Shared/components/meeting/addMeetingModal/AddMeetingModal';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { AddUserModal } from 'Shared/components/user/AddEditUserModal/AddUserModal';
import { AddWorkspaceModal } from 'Shared/components/workspace/AddWorkspaceModal/AddWorkspaceModal';
import { Meeting } from 'Shared/types/meeting';
import { Permission } from 'Shared/utils/rbac';

export const useCommandPaletteModalKeyboardShortcuts = (close: () => void) => {
    const navigate = useNavigate();
    const { open } = useModal();
    const { can } = useCurrentUserContext();

    // This keyboard shortcut prevents us from opening the modal again when it is already open
    useCmdKeyboardShortcut('k', EmptyFunction, { target: document.body });

    useCmdKeyboardShortcut('m', async () => {
        if (!can(Permission.AddMeeting)) {
            return;
        }

        await close();
        open(AddMeetingModal, {
            onDone: (meeting: Meeting) => {
                if ('id' in meeting) {
                    navigate(`/meetings/${meeting.id}`);
                }
            },
        });
    });

    useCmdKeyboardShortcut('e', async () => {
        if (!can(Permission.AddWorkspace)) {
            return;
        }

        await close();
        open(AddWorkspaceModal);
    });

    useCmdKeyboardShortcut('i', async () => {
        if (!can(Permission.AddTeam)) {
            return;
        }

        await close();
        open(AddTeamModal);
    });

    useCmdKeyboardShortcut('p', async () => {
        if (!can(Permission.AddTemplate)) {
            return;
        }

        await close();
        open(AddTemplateModal);
    });

    useCmdKeyboardShortcut('u', async () => {
        if (!can(Permission.ManageNetwork)) {
            return;
        }

        await close();
        open(AddUserModal);
    });

    useCmdKeyboardShortcut('s', async () => {
        if (!can(Permission.ManageTasks)) {
            return;
        }

        await close();
        open(TaskModal);
    });
};
