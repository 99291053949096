import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripVertical } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Id } from '@wedo/types';
import { useTasksContext } from 'App/contexts/TasksContext';
import { useDraggedTasksStore } from 'Pages/TasksPage/components/TasksList/TasksList';
import { Group, GroupedTask } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { Layout } from 'Pages/TasksPage/types';
import { Task } from 'Shared/components/task/Task';
import { TaskFilter } from 'Shared/types/task';

type DraggableTaskProps = {
    addTaskElement: (id: Id, element: HTMLDivElement) => void;
    task: GroupedTask;
    workspaceId: Id;
    checklistId: Id;
    isDraggable: boolean;
    isReadOnly: boolean;
    keepCollapsed: boolean;
    layout: Layout;
    view: TaskFilter;
    setNodeRef: (node: HTMLElement) => void;
    listeners: SyntheticListenerMap;
    style?: CSSProperties;
    onSelectTask: (e: React.MouseEvent<HTMLDivElement>, task: GroupedTask) => void;
};

const BaseDraggableTask = ({
    addTaskElement,
    task,
    workspaceId,
    checklistId,
    layout,
    view,
    isDraggable,
    isReadOnly,
    keepCollapsed,
    setNodeRef,
    listeners,
    style,
    onSelectTask,
}: DraggableTaskProps) => {
    const { selectedTasks, setSelectedTasks } = useTasksContext();
    const draggedTasks = useDraggedTasksStore((state) => state.draggedTasks);

    const isDragged = draggedTasks.some(({ groupedId }) => groupedId === task.groupedId);

    // If the task is selected but has no groupedId, set it here
    // It can happen after adding a task, as after the addition, we do not compute the groupedId because it would be to
    // complex to do
    if (selectedTasks.length === 1 && selectedTasks.some(({ id, groupedId }) => id === task.id && groupedId === null)) {
        setSelectedTasks([{ id: task.id, groupedId: task.groupedId }]);
    }

    return (
        <div
            ref={(el) => {
                setNodeRef(el);
                addTaskElement(task.id, el);
            }}
            data-selectable={true}
            data-intersected={false}
            data-id={task.id}
            data-grouped-id={task.groupedId}
            className={clsx('group relative -mt-px flex items-start', isDragged && 'text-black/25')}
            style={style}
        >
            <div className={clsx('flex items-center pt-2', !isReadOnly && 'w-5', layout === 'list' ? 'pt-2' : 'pt-3')}>
                {!isReadOnly && (
                    <FontAwesomeIcon
                        {...listeners}
                        icon={faGripVertical}
                        data-unselectable={true}
                        className={clsx(
                            'h-4 w-5 text-gray-400 opacity-0',
                            isDraggable ? 'cursor-grab group-hover:opacity-100' : 'pointer-events-none'
                        )}
                        aria-hidden="true"
                    />
                )}
            </div>
            <Task
                task={task}
                layout={layout}
                view={view}
                isEditable={!task.deleted && !isReadOnly}
                isListReadonly={isReadOnly}
                workspaceId={workspaceId}
                checklistId={checklistId}
                isDragged={isDragged}
                className="flex-1 gap-1"
                keepCollapsed={keepCollapsed}
                onSelectTask={onSelectTask}
            />
        </div>
    );
};

type DraggableOrSortableTaskProps = {
    addTaskElement: (id: Id, element: HTMLDivElement) => void;
    group: Group;
    task: GroupedTask;
    workspaceId: Id;
    checklistId: Id;
    layout: Layout;
    view: TaskFilter;
    isDraggable: boolean;
    isReadOnly: boolean;
    keepCollapsed: boolean;
    onSelectTask: (e: React.MouseEvent<HTMLDivElement>, task: GroupedTask) => void;
};

// a "DraggableTask" is a task that can be dragged to another group
export const DraggableTask = ({
    addTaskElement,
    group,
    task,
    workspaceId,
    checklistId,
    layout,
    view,
    isDraggable = false,
    isReadOnly,
    keepCollapsed,
    onSelectTask,
}: DraggableOrSortableTaskProps) => {
    const { tasks, ...groupProperties } = group;

    const { setNodeRef, listeners } = useDraggable({
        id: task.groupedId,
        data: { task, group: groupProperties },
    });

    return (
        <BaseDraggableTask
            addTaskElement={addTaskElement}
            task={task}
            workspaceId={workspaceId}
            checklistId={checklistId}
            layout={layout}
            view={view}
            isDraggable={isDraggable}
            isReadOnly={isReadOnly}
            keepCollapsed={keepCollapsed}
            setNodeRef={setNodeRef}
            listeners={listeners}
            onSelectTask={onSelectTask}
        />
    );
};

// a "SortableTask" is a task that can be sorted inside its own group
export const SortableTask = ({
    addTaskElement,
    group,
    task,
    workspaceId,
    checklistId,
    layout,
    view,
    isDraggable = false,
    isReadOnly,
    keepCollapsed,
    hideCheckbox,
    onSelectTask,
}: DraggableOrSortableTaskProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tasks, ...groupProperties } = group;

    const { setNodeRef, listeners, isDragging, transform, transition } = useSortable({
        id: task.groupedId,
        data: { task, group: groupProperties },
    });

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        zIndex: isDragging ? 1 : 'auto',
    };

    return (
        <BaseDraggableTask
            addTaskElement={addTaskElement}
            task={task}
            workspaceId={workspaceId}
            checklistId={checklistId}
            layout={layout}
            view={view}
            isDraggable={isDraggable}
            isReadOnly={isReadOnly}
            keepCollapsed={keepCollapsed}
            hideCheckbox={hideCheckbox}
            setNodeRef={setNodeRef}
            listeners={listeners}
            style={style}
            onSelectTask={onSelectTask}
        />
    );
};
