import React from 'react';
import { useDesktopNotifications } from 'Shared/hooks/useDesktopNotifications';
import { DesktopNotificationsNotSupportedBanner } from './DesktopNotificationsNotSupportedBanner';
import { DesktopNotificationsSupportedSection } from './DesktopNotificationsSupportedSection';

export const DesktopNotificationsSection = () => {
    const { browserSupportsNotifications } = useDesktopNotifications();

    return (
        <>
            {browserSupportsNotifications ? (
                <DesktopNotificationsSupportedSection />
            ) : (
                <DesktopNotificationsNotSupportedBanner />
            )}
        </>
    );
};
