import { useCallback, useEffect, useRef } from 'react';
import { t } from '@lingui/macro';
import WebViewer, { type Core, type WebViewerInstance } from '@pdftron/webviewer';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { colors } from '@wedo/design-system';
import { tryOrValue } from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import { once } from '@wedo/utils/promise';
import { useSessionUser } from 'App/store/usersStore';

const sharedPdfViewerId = 'shared-pdf-viewer';

type Theme = 'dark' | 'light';

export type Stamp = (
    instance: WebViewerInstance,
    document: Core.PDFNet.PDFDoc,
    pageRange: [number, number]
) => Promise<void>;

type SharedDocument = {
    pageRange: [number, number];
    stamp?: Stamp;
};

type SharedPdfViewerInstance = {
    instance: WebViewerInstance;
    loadDocument: (document: Core.Document | Blob, options?: LoadDocumentOptions) => Promise<void>;
    appendDocument: (document: Core.Document, options?: AppendDocumentOptions) => Promise<void>;
};

type SharedPdfViewerStore = {
    documents: Array<SharedDocument>;
    instance: Promise<SharedPdfViewerInstance>;
};

const sharedPdfViewerStore = create<SharedPdfViewerStore>()(
    immer(() => ({
        documents: null,
        instance: null,
    }))
);

const applyWatermarks = async (instance: WebViewerInstance) => {
    const { Core } = instance;
    const { documentViewer, PDFNet } = Core;

    const stampableDocuments = sharedPdfViewerStore.getState().documents.filter(({ stamp }) => stamp != null);

    if (stampableDocuments.length > 0) {
        await PDFNet.initialize();
        const document = await documentViewer.getDocument().getPDFDoc();
        await PDFNet.runWithCleanup(async () => {
            document.lock();
            for (const stampableDocument of stampableDocuments) {
                await stampableDocument.stamp(instance, document, stampableDocument.pageRange);
            }
        });
        documentViewer.refreshAll();
        documentViewer.updateView();
        documentViewer.getDocument().refreshTextData();
    }
};

type LoadDocumentOptions = {
    filename?: string;
    stamp?: Stamp;
};

const loadDocument = (instance: WebViewerInstance) => {
    return async (document: Core.Document | Blob, options?: LoadDocumentOptions) => {
        const { Core, UI } = instance;
        UI.loadDocument(document, { filename: options?.filename });
        await Promise.race([
            once(Core.documentViewer, 'documentLoaded'),
            once(UI, UI.Events.LOAD_ERROR).then((error) => Promise.reject(error)),
        ]);
        sharedPdfViewerStore.setState({
            documents: [{ pageRange: [1, Core.documentViewer.getPageCount()], stamp: options?.stamp }],
        });
        await applyWatermarks(instance);
    };
};

type AppendDocumentOptions = {
    stamp?: Stamp;
};

const appendDocument = (instance: WebViewerInstance) => {
    return async (document: Core.Document, options?: AppendDocumentOptions) => {
        const { Core } = instance;
        const pageCount = Core.documentViewer.getDocument().getPageCount();
        if (document.getFilename() != null) {
            sharedPdfViewerStore.setState(({ documents }) => {
                documents.push({
                    pageRange: [pageCount + 1, pageCount + document.getPageCount()],
                    stamp: options.stamp,
                });
            });
        }
        await Core.documentViewer.getDocument().insertPages(document);
        await applyWatermarks(instance);
    };
};

export const getSharedPdfViewerInstance = async () => {
    if (sharedPdfViewerStore.getState().instance != null) {
        return sharedPdfViewerStore.getState().instance;
    }

    const element = document.createElement('div');
    element.id = sharedPdfViewerId;
    Object.assign(element.style, { display: 'none', position: 'fixed' });
    document.body.appendChild(element);

    // eslint-disable-next-line new-cap
    const promise = WebViewer(
        {
            path: '/pdf-viewer',
            licenseKey: __PDFTRON_LICENCE_KEY__,
            loadAsPDF: true,
            fullAPI: true,
            ui: 'beta',
        },
        element
    ).then((instance) => {
        return {
            instance,
            loadDocument: loadDocument(instance),
            appendDocument: appendDocument(instance),
        };
    });

    sharedPdfViewerStore.setState({ instance: promise });

    const instance = await promise;
    const { UI, Core } = instance.instance;

    // instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous) doesn't work
    // See https://community.apryse.com/t/displaymodemanager-setdisplaymode-rendering-the-document-a-second-time/6730
    Core.documentViewer
        .getDisplayModeManager()
        .setDisplayMode(new Core.DisplayMode(Core.documentViewer, Core.DisplayModes.Continuous));

    UI.setPrintQuality(5);

    const style = UI.iframeWindow.document.documentElement.style;
    style.setProperty('--document-background-color', colors.gray['50']);

    const leftGroup = new UI.Components.GroupedItems({
        dataElement: 'leftGroup',
        items: [new UI.Components.Zoom()],
        alwaysVisible: true,
    });

    const centerGroup = new UI.Components.GroupedItems({
        dataElement: 'centerGroup',
        items: [{ type: 'pageControls' }],
        alwaysVisible: true,
    });

    const rightGroup = new UI.Components.GroupedItems({
        dataElement: 'rightGroup',
        items: [
            new UI.Components.PresetButton({ buttonType: 'downloadButton' }),
            new UI.Components.CustomButton({
                dataElement: 'printButton',
                title: t`Print`,
                onClick: () => UI.printInBackground(),
                img: 'icon-header-print-line',
            }),
        ],
        alwaysVisible: true,
    });

    const topHeader = new UI.Components.ModularHeader({
        dataElement: 'default-top-header',
        placement: 'top',
        grow: 0,
        gap: 12,
        justifyContent: 'space-between',
        stroke: true,
        dimension: { borderWidth: 1 },
        style: {
            padding: '8px 12px',
            borderColor: colors.gray['200'],
        },
        items: [leftGroup, centerGroup, rightGroup],
    });

    UI.setModularHeaders([topHeader]);

    UI.disableElements(['toolsOverlay']);

    return instance;
};

export type SharedPdfViewerProps = {
    theme?: Theme;
    isReadOnly?: boolean;
    zIndex?: number;
};

export const SharedPdfViewer = ({
    theme = 'light',
    isReadOnly = true,
    zIndex: defaultZIndex,
}: SharedPdfViewerProps) => {
    const sessionUser = useSessionUser();

    const elementRef = useRef<HTMLDivElement>();

    const setPositionAndSize = useCallback(() => {
        if (elementRef.current == null) {
            return;
        }

        const { top, right, bottom, left } = elementRef.current.getBoundingClientRect();
        const zIndex =
            defaultZIndex ??
            tryOrValue(() => Number(getComputedStyle(elementRef.current.closest('[class*="z-"]')).zIndex), 0);
        Object.assign(document.getElementById(sharedPdfViewerId).style, {
            top: `${top}px`,
            right: `${window.innerWidth - right}px`,
            bottom: `${window.innerHeight - bottom}px`,
            left: `${left}px`,
            zIndex: `${zIndex + 1}`,
            display: 'block',
        });
    }, []);

    useEvent('resize', setPositionAndSize);

    useEffect(() => {
        getSharedPdfViewerInstance().then(({ instance: { Core, UI } }) => {
            UI.openElements(['progressModal']);

            if (isReadOnly) {
                UI.disableElements(['ribbons', 'toggleNotesButton']);
                Core.annotationManager.enableReadOnlyMode();
            } else {
                UI.enableElements(['ribbons', 'toggleNotesButton']);
                Core.annotationManager.disableReadOnlyMode();
            }

            UI.setTheme(theme);

            void UI.setLanguage(sessionUser.language_code);
            Core.annotationManager.setCurrentUser(sessionUser.id.toString());
            Core.annotationManager.setAnnotationDisplayAuthorMap(() => sessionUser.full_name);

            UI.setToolMode(Core.Tools.ToolNames.PAN);

            setPositionAndSize();
        });
        return () => {
            getSharedPdfViewerInstance().then(({ instance }) => {
                void instance.UI.closeDocument();
                document.getElementById(sharedPdfViewerId).style.display = 'none';
            });
        };
    }, []);

    return <div ref={elementRef} className="w-full h-full"></div>;
};
