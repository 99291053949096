import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCircleCheck, faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { plural, t } from '@lingui/macro';
import { differenceInDays } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { FormatDate, NavLink, ProgressBar, Tag, Tooltip } from '@wedo/design-system';
import { ChecklistDescriptionPopover } from 'Pages/ChecklistPage/ChecklistDescriptionPopover';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { ChecklistDropdown } from 'Shared/components/checklist/ChecklistDropdown';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { Checklist } from 'Shared/types/checklist';

type ChecklistGridCardProps = {
    checklist: Checklist;
};

const MAX_VISIBLE_WORKSPACES = 3;

export const ChecklistGridCard = ({ checklist }: ChecklistGridCardProps) => {
    const daysLeft = differenceInDays(new Date(checklist.reference_date), new Date());
    const linkToDetail = `/checklists/${checklist.id}/tasks`;
    const nbOpenTasks = Number(checklist.nb_open_tasks);
    const nbCompletedTasks = Number(checklist.nb_completed_tasks);
    const percentage = Math.round((nbCompletedTasks / (nbOpenTasks + nbCompletedTasks)) * 100);
    const workspaces = [...(checklist?.tags ?? [])]?.slice(0, MAX_VISIBLE_WORKSPACES);

    return (
        <GridCard>
            <GridCard.Header
                isHoverable
                href={linkToDetail}
                icon={<FontAwesomeIcon icon={faCircleCheck} />}
                title={checklist.name}
                status={checklist.deleted ? 'deleted' : checklist.archived ? 'archived' : undefined}
            >
                <GridCard.Actions>
                    <div className="z-10 flex gap-2">
                        {!isEmpty(checklist?.description) && (
                            <ChecklistDescriptionPopover checklist={checklist} icon={faFileLines} />
                        )}
                        <ChecklistDropdown checklist={checklist} />
                    </div>
                </GridCard.Actions>
            </GridCard.Header>

            <GridCard.Body isHoverable members={[]}>
                <div className="flex flex-wrap items-center justify-center gap-2 flex-grow">
                    {workspaces?.map((workspace) => <WorkspaceTag key={workspace.id} workspace={workspace} />)}
                    {checklist?.tags?.length > MAX_VISIBLE_WORKSPACES && (
                        <Tag size="sm">+{checklist?.tags?.length - MAX_VISIBLE_WORKSPACES}</Tag>
                    )}
                </div>

                <GridCard.Metrics>
                    <GridCard.Metric value={nbOpenTasks} text={t`to do`} />
                    <GridCard.Metric value={nbCompletedTasks} text={t`done`} />
                    <div className="z-10">
                        <Tooltip content={<FormatDate date={checklist?.reference_date} format="PPP" />}>
                            <GridCard.Metric
                                value={daysLeft}
                                text={
                                    daysLeft >= 0
                                        ? plural(daysLeft, { one: 'day before', other: 'days before' })
                                        : plural(Math.abs(daysLeft), { one: 'day after', other: 'days after' })
                                }
                            />
                        </Tooltip>
                    </div>
                </GridCard.Metrics>
                <ProgressBar className="w-full" percent={percentage} color="green" />
            </GridCard.Body>

            <NavLink to={linkToDetail} className="absolute h-full w-full" />
        </GridCard>
    );
};
