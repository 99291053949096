import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import { Button, Label } from '@wedo/design-system';
import {
    getMonthsList,
    Month,
} from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/ReccurrenceUtils';
import { FormRecurrence, RecurrenceMonth } from 'Shared/types/formRecurrence';

type RecurrenceMonthsInputProps = {
    state: FormRecurrence;
    toggleMonth: (month: RecurrenceMonth) => void;
};

export const RecurrenceMonthsInput = ({ state, toggleMonth }: RecurrenceMonthsInputProps) => {
    const { i18n } = useLingui();
    const months = getMonthsList(i18n);
    const isMonthSelected = (month: Omit<Month, 'selected'>) => (state.months || []).some((d) => d === month.index);

    const handleClick = (month: Omit<Month, 'selected'>) => {
        toggleMonth(month.index as RecurrenceMonth);
    };
    return (
        <>
            <Label className={'col-span-2 text-end'} inputType={'inline'}>{t`Of months`}</Label>
            <div className={'col-span-10 flex flex-wrap gap-1'}>
                {months.map((month) => {
                    return (
                        <Button
                            key={month.index}
                            role="checkbox"
                            active={isMonthSelected(month)}
                            aria-checked={isMonthSelected(month)}
                            onClick={() => handleClick(month)}
                        >
                            {month.label}
                        </Button>
                    );
                })}
            </div>
        </>
    );
};
