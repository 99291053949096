import { forwardRef, PropsWithChildren, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CountryCode } from 'libphonenumber-js';
import { Input, ItemGroup } from '@wedo/design-system';
import { usePhoneNumber } from 'Shared/components/PhoneNumberPicker/usePhoneNumber';
import { CountrySelector } from 'Shared/components/address/CountrySelector';

type PhoneNumberPickerProps = {
    initialValue: string;
    onChange: (phoneNumber: string) => void;
    status?: 'default' | 'success' | 'error' | 'loading';
    statusText?: string;
    placeholder?: string;
    disabled?: boolean;
} & PropsWithChildren;
export const PhoneNumberPicker = forwardRef(
    (
        {
            initialValue,
            onChange,
            status = 'default',
            statusText,
            placeholder,
            children,
            disabled = false,
        }: PhoneNumberPickerProps,
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [countryCode, setCountryCode] = useState<CountryCode>('CH');
        const [phoneNumber, setPhoneNumber] = useState<string>(initialValue);
        const {
            formattedInput,
            internationalInput,
            isValid,
            country: detectedCountryCode,
        } = usePhoneNumber({
            input: phoneNumber,
            countryCode,
        });
        const focus = () => {
            inputRef.current?.focus();
        };

        useEffect(() => {
            if (detectedCountryCode != null) {
                setCountryCode(detectedCountryCode);
            }
        }, [detectedCountryCode]);

        useEffect(() => {
            onChange(internationalInput ?? '');
        }, [internationalInput]);

        useEffect(() => {
            setPhoneNumber(initialValue);
        }, [initialValue]);

        useImperativeHandle(
            ref,
            () => ({
                focus,
                formattedInput,
                internationalInput,
                isValid: isValid && internationalInput != null,
            }),
            [formattedInput, internationalInput, isValid]
        );

        return (
            <ItemGroup status={status} statusText={statusText}>
                <CountrySelector
                    value={countryCode}
                    onChange={(value) => setCountryCode(value)}
                    className={'!rounded-r-none !w-[5.125rem]'}
                    mode="simple"
                    isDisabled={disabled}
                />
                <Input
                    ref={inputRef}
                    placeholder={placeholder}
                    className="min-w-0 w-full"
                    type={'tel'}
                    value={formattedInput ?? ''}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={disabled}
                />
                {children}
            </ItemGroup>
        );
    }
);
