import { useAttachmentAction } from 'Shared/components/notification/hooks/useAttachmentAction';
import { useChecklistAction } from 'Shared/components/notification/hooks/useChecklistAction';
import { useMeetingAction } from 'Shared/components/notification/hooks/useMeetingAction';
import { useNetworkAction } from 'Shared/components/notification/hooks/useNetworkAction';
import { useTaskAction } from 'Shared/components/notification/hooks/useTaskAction';
import { useTeamAction } from 'Shared/components/notification/hooks/useTeamAction';
import { useTemplateAction } from 'Shared/components/notification/hooks/useTemplateAction';
import { useWorkspaceAction } from 'Shared/components/notification/hooks/useWorkspaceAction';
import { type Notification as NotificationType } from 'Shared/types/notification';

export const useNotificationHandler = () => {
    const networkAction = useNetworkAction();
    const workspaceAction = useWorkspaceAction();
    const taskAction = useTaskAction();
    const teamAction = useTeamAction();
    const checklistAction = useChecklistAction();
    const templateAction = useTemplateAction();
    const attachmentAction = useAttachmentAction();
    const meetingAction = useMeetingAction();

    return (notification: NotificationType) => {
        switch (notification.object_type) {
            case 'attachment':
                return attachmentAction(notification);
            case 'checklist':
                return checklistAction(notification);
            case 'checklist_template':
                return templateAction(notification);
            case 'network':
                return networkAction(notification);
            case 'tag':
                return workspaceAction(notification);
            case 'task':
                return taskAction(notification);
            case 'team':
                return teamAction(notification);
            case 'meeting':
            case 'meeting_topic':
                return meetingAction(notification);
            default:
                return null;
        }
    };
};
