import React, { useCallback, useMemo, useState } from 'react';
import { faEllipsisV, faPen, faStickyNote, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Dropdown, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useAppDispatch } from 'App/store';
import { ActivityFeedEditorView } from 'Pages/activityFeed/FeedEditor/ActivityFeedEditorView';
import { FeedItem } from 'Pages/activityFeed/FeedItem/FeedItem';
import { FeedItemAttachment } from 'Pages/activityFeed/FeedItem/FeedItemAttachment';
import { FeedEditor } from 'Shared/components/editor/FeedEditor';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { TemplateIcon, TemplateIconProps } from 'Shared/components/template/TemplateIcon';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { useMentions } from 'Shared/hooks/useMentions';
import { invalidateActivityFeeds } from 'Shared/services/activityFeed';
import { useDeleteCommentMutation } from 'Shared/services/comment';
import { Mention } from 'Shared/types/Mention';
import { ActivityLog } from 'Shared/types/activityLog';
import { Comment } from 'Shared/types/comment';

const NoteMention = ({ mention, size }: { mention: Mention; size: TemplateIconProps['size'] }): JSX.Element => {
    if (mention.tag) {
        return <WorkspaceIcon workspace={mention.tag} showTooltip size={size} />;
    }

    if (mention.checklistTemplate) {
        return <TemplateIcon template={mention.checklistTemplate} showTooltip size={size} />;
    }

    if (mention.team) {
        return <TeamIcon team={mention.team} size={size} />;
    }

    return <span>&nbsp;</span>;
};

export const FeedItemNote = ({ activityFeed }: { activityFeed: ActivityLog }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { currentUser } = useCurrentUserContext();
    const [deleteComment] = useDeleteCommentMutation();
    const { confirm } = useConfirm();

    const { show: showNotification } = useNotification();

    const [isEdit, setIsEdit] = useState(false);

    const { transformMentions } = useMentions();

    const comment = activityFeed.object as Comment;

    const mentions = useMemo(() => {
        return transformMentions(comment.mentions);
    }, [comment, transformMentions]);

    const mentionsUser = mentions
        .filter((mention) => 'user' in mention && mention.user)
        .map((mention) => 'user' in mention && mention.user);
    const mentionsOthers = mentions.filter((mention) => !('user' in mention));

    const handleDelete = useCallback(async () => {
        await confirm({
            type: 'danger',
            title: t`Are you sure you want to delete this note?`,
            confirmText: t`Delete note`,
            onConfirm: async () => {
                const res = await deleteComment({ commentId: comment.id });
                if ('error' in res) {
                    showNotification(UnexpectedErrorNotification);
                    return;
                }
                if ('data' in res) {
                    dispatch(invalidateActivityFeeds());
                }

                setIsEdit(false);
            },
        });
    }, [confirm, activityFeed]);

    return (
        <FeedItem icon={faStickyNote} color="yellow">
            {isEdit ? (
                <div className="">
                    <ActivityFeedEditorView
                        onDelete={handleDelete}
                        currentItem={comment}
                        onClose={() => setIsEdit(false)}
                        hasAutoFocus
                    />
                </div>
            ) : (
                <FeedItem.Wrapper color="yellow" isDark>
                    <div className="m-1">
                        <FeedEditor html={comment.comment} isReadOnly={true} />
                    </div>
                    <FeedItemAttachment attachments={comment.attachments} />
                </FeedItem.Wrapper>
            )}
            {activityFeed.created_by?.id === Number(currentUser?.id) && !isEdit && (
                <div className="absolute right-2 top-2">
                    <Dropdown icon={faEllipsisV} size="sm">
                        <Dropdown.Item icon={faPen} onClick={() => setIsEdit(true)}>
                            <Trans>Edit</Trans>
                        </Dropdown.Item>
                        <Dropdown.Item icon={faTrash} danger={true} onClick={handleDelete}>
                            <Trans>Delete</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            )}
            {!isEdit && (
                <FeedItem.Footer
                    createdBy={activityFeed.created_by}
                    createdAt={new Date(activityFeed.created_at)}
                    customFooter={
                        <>
                            {mentionsOthers.map((mention: Mention) => (
                                <NoteMention key={mention.id} mention={mention} size="sm" />
                            ))}
                            {mentionsUser.length > 0 && (
                                <UsersAvatarGroup users={mentionsUser} maxDisplayed={3} size="xs" />
                            )}
                        </>
                    }
                />
            )}
        </FeedItem>
    );
};
