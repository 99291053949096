import { Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const KDriveIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Path
                opacity="0.504"
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M232.09 43.1514L269.087 58.6138C274.749 60.9792 279.122 65.6589 281.108 71.465L295.813 114.452C298.179 121.375 303.913 126.607 311.02 128.348L464.927 165.99C469.592 167.132 473.739 169.806 476.704 173.584C479.669 177.362 481.28 182.026 481.28 186.829V366.408C481.281 369.765 480.494 373.076 478.982 376.074C477.47 379.072 475.276 381.673 472.575 383.668C469.875 385.663 466.744 386.997 463.434 387.561C460.124 388.126 456.728 387.906 453.519 386.918L217.498 314.296C213.111 312.949 209.272 310.231 206.544 306.542C203.817 302.852 202.344 298.384 202.342 293.796V62.9453C202.342 59.4101 203.214 55.9295 204.883 52.813C206.552 49.6965 208.965 47.0407 211.908 45.0818C214.851 43.1229 218.232 41.9217 221.751 41.5849C225.271 41.2481 228.818 41.7862 232.079 43.1514H232.09Z"
                fill="#A2BFFF"
            />
            <Path
                opacity="0.8"
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M178.452 86.057L215.45 101.519C221.102 103.885 225.485 108.565 227.471 114.371L242.176 157.368C244.541 164.28 250.266 169.523 257.382 171.264L411.29 208.896C415.956 210.038 420.105 212.714 423.07 216.494C426.035 220.274 427.645 224.94 427.643 229.745V409.324C427.643 412.681 426.854 415.992 425.341 418.99C423.828 421.987 421.633 424.588 418.931 426.582C416.23 428.576 413.098 429.909 409.787 430.472C406.477 431.035 403.081 430.813 399.872 429.824L163.871 357.212C159.483 355.865 155.642 353.145 152.915 349.453C150.187 345.761 148.715 341.292 148.716 336.701V105.851C148.715 102.316 149.587 98.8351 151.256 95.7186C152.925 92.6021 155.338 89.9463 158.281 87.9874C161.224 86.0286 164.605 84.8273 168.125 84.4905C171.644 84.1537 175.191 84.6918 178.452 86.057Z"
                fill="#A0BDFF"
            />
            <Path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M124.826 122.532L161.802 137.994C167.465 140.36 171.837 145.039 173.834 150.845L188.539 193.833C190.904 200.755 196.628 205.998 203.745 207.729L357.652 245.371C362.319 246.513 366.467 249.188 369.433 252.969C372.398 256.749 374.008 261.415 374.006 266.22V445.798C374.006 449.156 373.217 452.467 371.704 455.465C370.191 458.462 367.995 461.063 365.294 463.057C362.592 465.051 359.461 466.384 356.15 466.947C352.84 467.51 349.444 467.288 346.235 466.299L110.234 393.687C105.845 392.34 102.005 389.62 99.2774 385.928C96.5495 382.236 95.0777 377.767 95.0784 373.176V142.326C95.0776 138.791 95.9504 135.31 97.6192 132.193C99.2881 129.077 101.701 126.421 104.644 124.462C107.587 122.503 110.968 121.302 114.487 120.965C118.007 120.629 121.565 121.167 124.826 122.532Z"
                fill="#1A47FF"
            />
            <Path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M70.4 217.672L308.787 283.894C312.324 284.878 315.55 286.753 318.155 289.34C320.76 291.927 322.658 295.14 323.666 298.67L368.568 455.823C369.099 457.681 369.119 459.647 368.626 461.514C368.133 463.382 367.144 465.081 365.765 466.434C364.386 467.787 362.668 468.742 360.791 469.2C358.915 469.657 356.949 469.599 355.103 469.033L105.411 392.192C102.207 391.207 99.2781 389.486 96.8596 387.165C94.4412 384.844 92.6002 381.989 91.4841 378.829L44.4416 245.494C43.1331 241.794 42.8647 237.805 43.6657 233.963C44.4666 230.121 46.3062 226.572 48.9842 223.703C51.6621 220.834 55.0759 218.755 58.8537 217.691C62.6316 216.628 66.6185 216.621 70.4 217.672Z"
                fill="#5287FF"
            />
        </Svg>
    );
};
