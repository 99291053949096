import { ReactNode, useState } from 'react';
import { ConfirmModal, ContextModalProps, Label, Textarea } from '@wedo/design-system';

export const ConfirmWithTextareaModal = ({
    title,
    content,
    inputLabel,
    confirmText,
    ...modalProps
}: { title: ReactNode; content: ReactNode; confirmText: ReactNode } & ContextModalProps) => {
    const [text, setText] = useState<string>();
    return (
        <ConfirmModal
            {...modalProps}
            title={title}
            confirmText={confirmText}
            onConfirm={async () => text}
            content={
                <>
                    {content != null ? <div className={'mb-4'}>{content}</div> : null}
                    <Label>
                        {inputLabel}
                        <Textarea
                            value={text}
                            rows={5}
                            onChange={(e) => {
                                setText(e.target.value);
                            }}
                        />
                    </Label>
                </>
            }
        />
    );
};
