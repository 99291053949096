import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert } from '@wedo/design-system';

export const EmailToTaskAlert = () => {
    return (
        <Alert type={'info'} title={t`How the email will be converted`} className="mt-8">
            <ul className="mt-1 ml-4 list-disc">
                <li>
                    <Trans>The subject will be the task name</Trans>
                </li>
                <li>
                    <Trans>The body will be the task description</Trans>
                </li>
                <li>
                    <Trans>All email attachments will be attached to the task</Trans>
                </li>
            </ul>
        </Alert>
    );
};
