import { createRef, FC } from 'react';
import { create } from 'zustand';
import { CloseSource, ModalHandle, ModalProps } from '~/components/Modal/Modal';

export type ContextModalProps = ModalProps & ModalHandle;

type Modal = {
    modal: FC<ContextModalProps>;
    props: ContextModalProps;
};

export type ModalsStoreState = {
    modals: Modal[];
};

export type ModalsStoreActions = {
    actions: {
        open: <T extends ContextModalProps, P extends Omit<T, keyof ModalHandle>>(modal: FC<T>, props?: P) => void;
        close: <T extends ContextModalProps>(modal: FC<T>) => Promise<void>;
        closeAnyOpenModals: (exception?: string[]) => void;
    };
};

export const useModalStore = create<ModalsStoreState & ModalsStoreActions>()((set, get) => ({
    modals: [],
    actions: {
        open: (modal, props) => {
            const name = modal.name;
            const modalRef = createRef<ModalHandle>();

            // This close method is just a helper function passed to the modal to allow us close a modal and
            // correctly follow the close process
            const close = (source?: CloseSource) => modalRef.current?.close(source);

            // When the modal is closed, the onClose handler is called, and we then set the open property of the
            // modal to false, it's the step 2 of the close process
            const onClose = () =>
                set(({ modals }) => {
                    const modal = modals.find(({ modal }) => modal.name === name);
                    if (modal != null) {
                        modal.props.open = false;
                        return { modals: [...modals] };
                    }
                    return { modals };
                });

            // Once the modal is closed, we remove it from our stack, it's the step 3 and final step of the close
            // process
            const onAfterClose = () => {
                set(({ modals }) => ({
                    modals: modals.filter(({ modal }) => modal.name !== name),
                }));
            };

            set(({ modals }) => ({
                modals: [
                    {
                        modal,
                        props: {
                            ...props,
                            modalRef,
                            open: true,
                            close,
                            onClose,
                            onAfterClose,
                        },
                    },
                    ...modals,
                ],
            }));
        },

        close: ({ name }, source?: CloseSource) => {
            // To close a modal, we call its close function, it's the step 1 of the close process
            // The modal will then trigger the onClose handler
            return get()
                .modals.find(({ modal }) => modal.name === name)
                ?.props.modalRef.current?.close(source);
        },

        closeAnyOpenModals: (exception?: string[]) =>
            set((modals) => ({
                modals: modals.modals.filter(({ modal }) => exception?.includes(modal.name)),
            })),
    },
}));

export const useModal = () => useModalStore((state) => state.actions);

export const useModalsCount = () => useModalStore((state) => state.modals.length);

export const Modals = () => {
    const modals = useModalStore((state) => state.modals);

    return modals.reduce((root, { modal: Modal, props }) => {
        // We use the modal name as an id, thus, only ONE modal with the same name can be open at the same time
        return (
            <Modal {...props} key={Modal.name}>
                {root}
            </Modal>
        );
    }, null as JSX.Element);
};
