import React from 'react';
import { Label, Radio } from '@wedo/design-system';
import { AvailabilityOption } from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';

type AvailabilityItemRadioProps = {
    option: AvailabilityOption;
    isChecked: boolean;
    onClick: (option: AvailabilityOption) => void;
};

export const AvailabilityItemRadio: React.FC<AvailabilityItemRadioProps> = ({ option, isChecked, onClick }) => {
    return (
        <div className="flex items-center">
            <Radio
                id={`list-${option.id}`}
                name="availability"
                defaultChecked={isChecked}
                onChange={() => onClick(option)}
            />
            <Label htmlFor={`list-${option.id}`} className="ml-3 mt-1">
                {option.title}
            </Label>
        </div>
    );
};
