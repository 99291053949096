import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash-es';
import { EmptyArray, getIdMapping } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { TasksPageParams, TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import {
    allTasksFilter,
    assignedTasksFilter,
    createdByTasksFilter,
    defaultFilter,
    Fields,
    myTasksFilter,
    PreSetFilter,
    unassignedTasksFilter,
    watchedTasksFilter,
} from 'Pages/TasksPage/components/utils';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { useGetCustomFieldGroupsQuery, useGetCustomFieldsQuery } from 'Shared/services/customFields';
import { useGetFiltersQuery } from 'Shared/services/filter';
import { CustomField } from 'Shared/types/customField';
import { FieldConditionFilter, Filter } from 'Shared/types/filter';

export const useCurrentTasksFilter = () => {
    const { currentUserId } = useCurrentUserContext();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { workspaceId, userId } = useParams<TasksPageParams>();

    const [{ view = isMyTasksPage || userId ? 'me' : 'all' }] = useSearchParams(TasksPageSearchParams);

    const { customFields } = useGetCustomFieldsQuery(null, {
        selectFromResult: ({ data = [] }) => ({
            customFields: data.filter(
                (customField) => customField.is_global || customField.tags?.some(({ id }) => id === workspaceId)
            ),
        }),
    });

    const { data: customFieldGroups = [] } = useGetCustomFieldGroupsQuery();

    const { data: customFilters = EmptyArray as Filter[] } = useGetFiltersQuery({
        tag_id: workspaceId,
        object_type: 'task',
    });

    const allCustomFields = useMemo<CustomField[]>(() => {
        const result = [...customFields];
        customFieldGroups.forEach(({ customFields }) => {
            result.push(...customFields);
        });
        return result;
    }, [customFields, customFieldGroups]);

    const idToFilterMapping = getIdMapping(customFilters);

    const allCustomFilterNames = new Set(customFilters?.map((filter) => filter.name?.trim()));

    const currentFilter = useMemo<Filter>(() => {
        if (idToFilterMapping.has(view)) {
            return idToFilterMapping.get(view);
        }
        if (view === 'me') {
            return myTasksFilter(workspaceId, userId ?? currentUserId);
        }
        if (view === 'all') {
            return allTasksFilter(workspaceId);
        }
        if (view === 'unassigned') {
            return unassignedTasksFilter(workspaceId);
        }
        if (view === 'assigned') {
            return assignedTasksFilter(workspaceId, userId ?? currentUserId);
        }
        if (view === 'watched') {
            return watchedTasksFilter(workspaceId, userId ?? currentUserId);
        }
        if (view === 'created') {
            return createdByTasksFilter(workspaceId, userId ?? currentUserId);
        }
        return defaultFilter(workspaceId);
    }, [idToFilterMapping, view, workspaceId, currentUserId]);

    const currentFilterWithoutId = useMemo(
        () => ({
            ...cloneDeep(currentFilter),
            id: null,
        }),
        [currentFilter]
    );

    const emptyFilter = useMemo<Filter>(
        () => ({
            ...cloneDeep(currentFilter),
            id: null,
            name: '',
            conditions: {
                operator: 'and',
                conditions: [
                    {
                        operator: 'and',
                        conditions: [],
                    },
                    {
                        operator: 'or',
                        conditions: [],
                    },
                ],
            },
        }),
        [currentFilter]
    );

    const isCurrentFilterCustomFilter = useMemo<boolean>(() => idToFilterMapping.has(view), [view, idToFilterMapping]);

    const getFilterType = (filter: FieldConditionFilter) => {
        if (filter in Fields) {
            return Fields[filter as PreSetFilter].type;
        }
        return allCustomFields.find((customField) => customField.id === filter)?.type;
    };

    return {
        currentFilter,
        isCurrentFilterCustomFilter,
        currentFilterWithoutId,
        allCustomFilterNames,
        emptyFilter,
        allCustomFields,
        customFields,
        customFieldGroups,
        getFilterType,
    };
};
