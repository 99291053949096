import { EventHandler, useEffect } from 'react';

export type UseEventTarget = {
    addEventListener(name: string, handler: (event?: any) => void, ...args: any[]): void;
    removeEventListener(name: string, handler: (event?: any) => void, ...args: any[]): void;
};

export const useEvent = (event: string, handler: EventHandler<any>, target: UseEventTarget = window) => {
    useEffect(() => {
        target.addEventListener(event, handler);
        return () => {
            target.removeEventListener(event, handler);
        };
    }, [event, handler, target]);
};
