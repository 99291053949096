import { createContext, useContext } from 'react';
import { TabsLayout } from './Tabs';

type TabsContext = {
    layout: TabsLayout;
};

export const TabsContext = createContext<TabsContext | undefined>(undefined);

export function useTabsContext(): TabsContext {
    const context = useContext(TabsContext);

    if (!context) {
        throw new Error('useTabsContext should be used within the TabsContext provider!');
    }

    return context;
}
