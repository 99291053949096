import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import React, { createContext, ReactNode, useContext } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { Id } from '@wedo/types';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { Meeting } from 'Shared/types/meeting';

export type MeetingContextType = {
    meetingId: Id;
    meeting: Meeting | null | undefined;
    meetingError: FetchBaseQueryError | SerializedError;
    refetchMeeting: () => void;
};

const MeetingContext = createContext<MeetingContextType>({
    meetingError: undefined,
    meetingId: undefined,
    refetchMeeting: () => undefined,
    meeting: undefined,
});

export const useMeetingContext = () => useContext(MeetingContext);

type MeetingContextProviderProps = {
    meetingId: Id;
    initialMeeting?: Meeting;
    children: ReactNode;
};

export const MeetingContextProvider = ({ meetingId, initialMeeting, children }: MeetingContextProviderProps) => {
    const {
        data: meeting = initialMeeting,
        error: meetingError,
        refetch: refetchMeeting,
    } = useGetMeetingQuery(buildGetMeetingParameters(meetingId), {
        skip: initialMeeting !== undefined,
    });

    return (
        <MeetingContext.Provider value={{ meetingId, meeting, meetingError, refetchMeeting }}>
            {children}
        </MeetingContext.Provider>
    );
};
