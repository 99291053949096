import React, { FC } from 'react';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Tooltip } from '@wedo/design-system';
import { useMatch } from '@wedo/utils/hooks/useMatch';
import { useNavigate } from '@wedo/utils/hooks/useNavigate';
import { useTasksContext } from 'App/contexts/TasksContext';
import { useAppDispatch } from 'App/store';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { trpc } from 'Shared/trpc';
import { Task } from 'Shared/types/task';

export const TaskDetailBreadcrumb: FC<{ taskId: string }> = ({ taskId }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { setSelectedTasks } = useTasksContext();
    const { data: parentTask }: { data: Task & { hasAccess: boolean } } = trpc.task.getParentTask.useQuery(taskId);

    const isOnSingleTasksPage = useMatch('/tasks/:taskId');

    const handleClick = () => {
        if (!parentTask.hasAccess) {
            return;
        }
        if (isOnSingleTasksPage) {
            navigate(`/tasks/${parentTask.id}`);
        } else {
            setSelectedTasks([{ id: parentTask.id, groupedId: parentTask.id.toString() }]);
            dispatch(taskSelected({ taskId: parentTask.id }));
        }
    };

    return (
        parentTask != null && (
            <Tooltip content={!parentTask.hasAccess && <Trans>You do not have access to this task</Trans>}>
                <Button
                    variant="ghost"
                    onClick={handleClick}
                    icon={faAngleRight}
                    iconClassName="h-3 w-2 mt-0.5"
                    className={clsx(
                        'text-gray-400 pt-2 mx-4 text-xs flex gap-2 hover:text-gray-600 !whitespace-normal text-left',
                        !parentTask.hasAccess && 'cursor-not-allowed'
                    )}
                >
                    {parentTask.name}
                </Button>
            </Tooltip>
        )
    );
};
