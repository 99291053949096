import React from 'react';
import { useNavigate } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { ActivitiesTable } from 'Shared/components/activity/ActivitiesTable/ActivitiesTable';
import {
    getDescription,
    getIcon,
    getIconColor,
    getIconTextColor,
    getText,
    GetTextParam,
} from 'Shared/components/task/TaskActivityLogsModal/TaskActivityLogsValues';
import { useGetTaskActivityLogsQuery } from 'Shared/services/task';
import { ActivityLog } from 'Shared/types/activityLog';

type TaskHistoryModalProps = {
    taskId: Id;
    children?: React.ReactNode;
} & ContextModalProps;

export const TaskHistoryModal = ({ taskId, close, children, ...modalProps }: TaskHistoryModalProps): JSX.Element => {
    const navigate = useNavigate();

    const { data: activityLogs = EmptyArray as ActivityLog[], isLoading } = useGetTaskActivityLogsQuery(
        { taskId },
        { skip: !taskId }
    );

    const handleClick = (url: string) => {
        navigate(url);
        void close();
    };

    return (
        <Modal size="lg" {...modalProps}>
            <Modal.Header title={t`Task history`} />
            <Modal.Body>
                {isLoading ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-2" />
                    </div>
                ) : (
                    <ActivitiesTable
                        activities={activityLogs}
                        getIcon={getIcon}
                        getIconColor={getIconColor}
                        getIconTextColor={getIconTextColor}
                        getText={(param: GetTextParam) => getText({ ...param, onClickMeeting: handleClick })}
                        getDescription={getDescription}
                        isImportant={() => false}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
