import React, { MutableRefObject } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Editor } from 'slate';
import { Dropdown } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { stopPropagation } from '@wedo/utils';
import { useCurrentUserContext, useMeetingContext } from 'App/contexts';
import { commandItems } from 'Shared/components/editor/plugins/commandsPlugin';
import {
    MeetingPermission,
    useUserHasMeetingPermission,
    useUserHasMeetingSectionPermission,
} from 'Shared/types/meeting';
import { MeetingBlock } from 'Shared/types/meetingBlock';

export const AddBlockDropdownItems = ({
    editorRef,
    workspaceId,
    meetingId,
    blockId,
    topicId,
    sectionId,
    onCreateBlocks,
}: {
    editorRef: MutableRefObject<Editor>;
    workspaceId?: Id;
    meetingId?: Id;
    blockId?: string;
    topicId?: string;
    sectionId?: string;
    onCreateBlocks?: (blocks: Partial<MeetingBlock>[]) => void;
}) => {
    const { currentUser } = useCurrentUserContext();
    const { meeting } = useMeetingContext();

    const { hasPermission: canEditTopicContent } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        sectionId,
        MeetingPermission.EDIT_TOPIC_CONTENT
    );

    const { hasPermission: canEditVote } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const isDraftTopic = topicId != null && meetingId == null;

    return (
        <>
            {commandItems()
                .filter((command) =>
                    command.isEnabled(editorRef?.current, null, {
                        canEditVote,
                        isDraftTopic,
                        canEditTopicContent: meetingId == null || canEditTopicContent,
                    })
                )
                .map((command) => (
                    <Dropdown.Item
                        key={command.id}
                        icon={command.icon}
                        iconClassName={command.className}
                        onClick={stopPropagation(() => {
                            command.trigger(editorRef.current, {
                                workspaceId,
                                meetingId,
                                blockId,
                                topicId,
                                force: true,
                                onCreateBlocks,
                                canEditTopicContent,
                            });
                        })}
                    >
                        {command.label}
                    </Dropdown.Item>
                ))}
        </>
    );
};

export const ToolbarAddBlock = ({
    editorRef,
    workspaceId,
    meetingId,
    topicId,
    sectionId,
}: {
    editorRef: MutableRefObject<Editor>;
    workspaceId?: Id;
    meetingId?: Id;
    topicId?: Id;
    sectionId?: Id;
}) => {
    return (
        <Dropdown
            size="sm"
            color={'primary'}
            icon={faPlus}
            aria-label={t`Add a block in the editor`}
            data-testid="add-block"
            focusItemOnOpen={false}
        >
            <AddBlockDropdownItems
                editorRef={editorRef}
                workspaceId={workspaceId}
                meetingId={meetingId}
                topicId={topicId}
                sectionId={sectionId}
            />
        </Dropdown>
    );
};
