import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { faComment, faExclamationCircle, faLock, faStar } from '@fortawesome/pro-regular-svg-icons';
import { Plural, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Button, FormatDate, Tag, Tooltip, useConfirm } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import {
    useCurrentUserVote,
    useMeetingVoters,
    usePendingVotes,
    useShouldHideOtherUsersAnswers,
} from 'Pages/meeting/components/Vote/VoteHooks';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { MeetingStatus } from 'Shared/types/meeting';
import { Vote } from 'Shared/types/vote';

const classes = {
    base: 'flex justify-between gap-2 w-full flex-wrap',
    deleteAnswerButton: 'text-red-600 self-center hover:text-red-400',
    footerText: 'text-xs text-gray-600 break-all ignore-marker',
};

interface VoteFooterProps {
    vote: Vote;
    selectedOptionId?: Id;
    canVote: boolean;
    isChangingAnswer: boolean;
    prepareRequestDiscussion: () => void;
    isRequestingDiscussion: boolean;
    readOnly: boolean;
    setIsChangingAnswer: (value: boolean) => void;
    isAddingAnswer: boolean;
    abstain: () => void;
    addAnswer: () => void;
    deleteUserAnswer: () => void;
    meetingId: Id;
}

export const VoteFooter = ({
    vote,
    selectedOptionId,
    canVote,
    isChangingAnswer,
    prepareRequestDiscussion,
    isRequestingDiscussion,
    readOnly,
    setIsChangingAnswer,
    abstain,
    addAnswer,
    isAddingAnswer,
    deleteUserAnswer,
    meetingId,
}: VoteFooterProps) => {
    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();

    const currentUserVote = useCurrentUserVote(vote);
    const hideOtherUsersAnswers = useShouldHideOtherUsersAnswers(vote, meetingId);
    const pendingVotes = usePendingVotes(vote, meetingId);
    const voters = useMeetingVoters(meetingId);
    const currentUser = useSessionUser();
    const currentUserIsVoter = voters?.some((v) => v.user_id === currentUser?.id);

    const currentUserVoteOptionValue = useMemo(() => {
        if (currentUserVote == null) {
            return null;
        }
        return vote?.voteOptions?.find((o) => o.id === currentUserVote.vote_option_id)?.value;
    }, [currentUserVote, vote?.voteOptions]);

    const currentUserVoteLabel = useMemo(() => {
        if (currentUserVote == null) {
            return null;
        }
        const option = vote?.voteOptions?.find((o) => o.id === currentUserVote?.vote_option_id);
        return ['rating', 'linear_scale'].includes(vote?.type) ? option?.label : option?.value;
    }, [currentUserVote, vote?.type, vote?.voteOptions]);

    const selectedOutcome = useMemo(() => {
        if (vote?.outcome_vote_option_id) {
            return vote.voteOptions.find((option) => option.id === vote.outcome_vote_option_id);
        }
        return null;
    }, [vote?.outcome_vote_option_id]);

    const shouldDisplayVoteButton = useMemo(() => {
        return (
            !readOnly &&
            vote?.status === 'open' &&
            canVote &&
            (!currentUserVote || isChangingAnswer) &&
            vote?.voteOptions?.length > 0
        );
    }, [readOnly, vote?.status, canVote, currentUserVote, isChangingAnswer, vote?.voteOptions]);

    const shouldDisplayAbstainButton = useMemo(() => {
        if (!['rating', 'linear_scale'].includes(vote?.type)) {
            return false;
        }
        if (readOnly || (currentUserVote && !isChangingAnswer)) {
            return false;
        }
        return vote?.status === 'open' && canVote && vote?.can_abstain;
    }, [vote?.type, vote?.status, vote?.can_abstain, readOnly, currentUserVote, isChangingAnswer, canVote]);

    const shouldDisplayRequestDiscussionButton = useMemo(() => {
        if (readOnly || (currentUserVote && !isChangingAnswer)) {
            return false;
        }
        if (isRequestingDiscussion) {
            return false;
        }
        if (isChangingAnswer && currentUserVote?.status === 'requested_discussion') {
            return false;
        }
        if (
            isChangingAnswer &&
            vote?.voteAnswers?.find((a) => a.status === 'requested_discussion') != null &&
            hideOtherUsersAnswers === false
        ) {
            return false;
        }
        return vote?.status === 'open' && canVote && vote?.can_request_discussion;
    }, [
        readOnly,
        currentUserVote,
        isChangingAnswer,
        isRequestingDiscussion,
        vote?.voteAnswers,
        vote?.status,
        vote?.can_request_discussion,
        hideOtherUsersAnswers,
        canVote,
    ]);

    const voteButtonText = useMemo(() => {
        if (selectedOptionId === 'requested_discussion') {
            return t`Send request`;
        }
        if (!currentUserVote) {
            return <Trans>Confirm my vote</Trans>;
        }
        if (isChangingAnswer) {
            return t`Save`;
        }
    }, [selectedOptionId, currentUserVote, isChangingAnswer]);

    return (
        <div className={clsx(classes.base)}>
            <div className={'flex-basis-min-content min-h-8 flex flex-1 flex-wrap gap-2 w-full'}>
                {selectedOutcome ? (
                    <div className={clsx(classes.footerText)}>
                        <Trans>The outcome of this vote is:</Trans>
                        <b> {selectedOutcome.value}</b>
                    </div>
                ) : (
                    vote?.status === 'closed' && (
                        <div className={clsx(classes.footerText)}>
                            <FontAwesomeIcon icon={faLock} /> <Trans>This vote is closed</Trans>
                        </div>
                    )
                )}
                {!canVote &&
                    vote?.status === 'open' &&
                    meeting?.status === MeetingStatus.PREPARATION &&
                    currentUserIsVoter && (
                        <Tag
                            className="ignore-marker"
                            color={'yellow'}
                            icon={faExclamationCircle}
                            title={t`This can be changed by an editor`}
                        >
                            <Trans>You cannot vote because the agenda is in preparation.</Trans>
                        </Tag>
                    )}
                {!canVote && vote?.status === 'open' && !currentUserIsVoter && (
                    <Tag
                        className="ignore-marker"
                        color={'yellow'}
                        icon={faExclamationCircle}
                        title={t`This can be changed by an editor, in the meeting's "Access and signatures" menu`}
                        style={{ textWrap: 'wrap' }}
                    >
                        <Trans>You are not in the voters of this meeting.</Trans>
                    </Tag>
                )}
                {canVote && vote?.status === 'open' && (
                    <>
                        {currentUserVote != null && !isChangingAnswer && !vote?.outcome_vote_option_id && (
                            <div className={classes.footerText}>
                                {currentUserVote.status === 'voted' &&
                                    (vote?.type === 'rating' ? (
                                        <>
                                            <Trans>
                                                You gave a rating of {currentUserVoteOptionValue}
                                                <FontAwesomeIcon icon={faStar} />
                                            </Trans>
                                            {currentUserVoteLabel != null &&
                                                currentUserVoteLabel !== '' &&
                                                `(${currentUserVoteLabel})`}
                                        </>
                                    ) : vote?.type === 'linear_scale' ? (
                                        <Trans>
                                            You picked {currentUserVoteOptionValue}/{vote?.voteOptions?.length}
                                        </Trans>
                                    ) : (
                                        <div className="break-all ignore-marker">
                                            <Trans>
                                                You voted <b>{currentUserVoteLabel}</b>
                                            </Trans>
                                        </div>
                                    ))}
                                {currentUserVote.status === 'abstained' && <Trans>You abstained</Trans>}
                                {currentUserVote.status === 'requested_discussion' &&
                                    (!isEmpty(currentUserVote.discussion) ? (
                                        <Tooltip delay={300} content={currentUserVote.discussion}>
                                            <div className="flex gap-1">
                                                <FontAwesomeIcon icon={faComment} />
                                                <div
                                                    className={'cursor-help underline decoration-dotted ignore-marker'}
                                                >
                                                    <Trans>You requested a discussion</Trans>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Trans>You requested a discussion</Trans>
                                    ))}
                            </div>
                        )}
                        {currentUserVote && canVote && !isChangingAnswer && !readOnly && (
                            <Button
                                color={'primary'}
                                variant={'link'}
                                size={'sm'}
                                onClick={(e) => {
                                    setIsChangingAnswer(true);
                                    e.stopPropagation();
                                }}
                                disabled={meeting?.status === 'locked' || meeting?.deleted}
                            >
                                <Trans>Change answer</Trans>
                            </Button>
                        )}
                        {shouldDisplayVoteButton && (
                            <Button
                                color={'primary'}
                                loading={isAddingAnswer}
                                onClick={(e) => {
                                    addAnswer();
                                    e.stopPropagation();
                                }}
                                disabled={
                                    selectedOptionId == null ||
                                    meeting?.status === 'locked' ||
                                    meeting?.deleted ||
                                    readOnly
                                }
                            >
                                {voteButtonText}
                            </Button>
                        )}
                        {shouldDisplayAbstainButton && (
                            <Button
                                disabled={meeting?.status === 'locked' || meeting?.deleted}
                                loading={isAddingAnswer}
                                onClick={(e) => {
                                    abstain();
                                    e.stopPropagation();
                                }}
                            >
                                <Trans>Abstain</Trans>
                            </Button>
                        )}
                        {currentUserVote && isChangingAnswer && (
                            <Button
                                className={'self-center'}
                                variant={'link'}
                                color={'danger'}
                                size={'sm'}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    if (
                                        await confirm({
                                            type: 'danger',
                                            title: t`Are you sure you want to delete your answer?`,
                                            confirmText: t`Yes, delete it`,
                                            cancelText: t`No, cancel`,
                                        })
                                    ) {
                                        deleteUserAnswer();
                                    }
                                }}
                            >
                                <Trans>Delete answer</Trans>
                            </Button>
                        )}
                        {shouldDisplayRequestDiscussionButton && (
                            <Button
                                disabled={meeting?.status === 'locked' || meeting?.deleted}
                                onClick={(e) => {
                                    prepareRequestDiscussion();
                                    e.stopPropagation();
                                }}
                            >
                                <Trans>Request discussion</Trans>
                            </Button>
                        )}
                    </>
                )}
            </div>
            {!selectedOutcome && (
                <div className={'flex flex-wrap justify-end  text-end text-xs  text-gray-600 ignore-marker'}>
                    <>
                        {voters != null && voters.length > 0 && pendingVotes > 0 && (
                            <Plural
                                value={pendingVotes}
                                one={`${pendingVotes} vote pending`}
                                other={`${pendingVotes} votes pending`}
                            />
                        )}
                        {voters != null && voters.length > 0 && pendingVotes === 0 && (
                            <div className="ignore-marker">{t`All voters have voted`}</div>
                        )}

                        {((voters != null && voters.length > 0 && pendingVotes > 0) ||
                            (voters != null && voters.length > 0 && pendingVotes === 0)) &&
                            meeting?.settings?.vote?.due_date != null &&
                            vote?.status === 'open' && <div>{' · '}</div>}

                        {meeting?.settings?.vote?.due_date != null && vote?.status === 'open' && (
                            <Tooltip content={t`Due date`}>
                                <div>
                                    <FormatDate
                                        className="ignore-marker"
                                        date={new Date(meeting?.settings?.vote?.due_date)}
                                        format={'short'}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                </div>
            )}
        </div>
    );
};
