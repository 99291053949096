import React from 'react';
import {
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faFileLines,
    faRectangleHistory,
} from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import { Button, Label } from '@wedo/design-system';
import { useLocalStorage } from '@wedo/utils/hooks';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { DisplayPopover } from 'Shared/components/displayPopover/DisplayPopover';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { LocalStorage } from 'Shared/types/localStorage';
import { Meeting } from 'Shared/types/meeting';
import { meetingStatusToMeetingView } from 'Shared/utils/meeting';

export const MeetingViewLayouts = [
    { value: MeetingViewMode.TopicView, label: msg`Topic view`, icon: faRectangleHistory },
    { value: MeetingViewMode.DocumentView, label: msg`Document view`, icon: faFileLines },
];

type Props = {
    meeting: Meeting;
    onViewModeChange: (viewMode: MeetingViewMode) => void;
    viewMode: MeetingViewMode;
};
export const MeetingViewOptionsPopover = ({ meeting, onViewModeChange, viewMode }: Props) => {
    const getDefaultPreferences = usePreferences();

    const [isCollapsed, setIsCollapsed] = useLocalStorage<boolean>(LocalStorage.MeetingCommentsCollapsed, false);

    const collapseComments = (isCollapsed: boolean) =>
        document.dispatchEvent(new CustomEvent('collapseComments', { detail: isCollapsed }));

    const handleReset = () => {
        const viewMode =
            getDefaultPreferences('defaultMeetingView', null)?.[meeting?.status] ??
            meetingStatusToMeetingView[meeting?.status];
        onViewModeChange(viewMode);
        collapseComments(true);
        setIsCollapsed(false);
    };

    const handleCollapsedChanged = (checked: boolean) => {
        collapseComments(checked);
        setIsCollapsed(checked);
    };

    return (
        <DisplayPopover onReset={handleReset} variant={'outlined'} size={'sm'}>
            <Label className={'px-4 pt-4 pb-2 text-sm text-gray-600'}>{t`Layout`}</Label>

            <DisplayPopover.LayoutGrid
                layout={viewMode}
                layouts={MeetingViewLayouts}
                onLayoutChange={onViewModeChange}
                className="mx-4"
            />
            <DisplayPopover.Rows className="px-4">
                <DisplayPopover.Row title={t`Comments (private)`}>
                    <Button
                        icon={
                            isCollapsed
                                ? faArrowUpRightAndArrowDownLeftFromCenter
                                : faArrowDownLeftAndArrowUpRightToCenter
                        }
                        onClick={() => handleCollapsedChanged(!isCollapsed)}
                    >
                        {isCollapsed ? <Trans>Expand all</Trans> : <Trans>Collapse all</Trans>}
                    </Button>
                </DisplayPopover.Row>
            </DisplayPopover.Rows>
        </DisplayPopover>
    );
};
