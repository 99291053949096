import { plural } from '@lingui/macro';
import { RecurrenceEndsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceEndsOnInput';
import { RecurrenceRepeatEveryInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatEveryInput';
import { RecurrenceRepeatOnWeekDaysInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatOnWeekDaysInput';
import { RecurrenceStartsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceStartsOnInput';
import { FormRecurrence, RecurrenceWeekDay } from 'Shared/types/formRecurrence';

interface RecurrenceWeeklyFormProps {
    state: FormRecurrence;
    handleValueChange?: (name: string, value: number | string) => void;
    repeatEverySuffix?: string;
    toggleDay: (day: RecurrenceWeekDay) => void;
}

export const RecurrenceWeeklyForm = ({
    state,
    handleValueChange,
    toggleDay,
}: RecurrenceWeeklyFormProps): JSX.Element => {
    return (
        <div className={'grid grid-cols-12 items-center gap-x-3 gap-y-8'}>
            <RecurrenceStartsOnInput state={state} handleValueChange={handleValueChange} />
            <RecurrenceRepeatEveryInput
                state={state}
                repeatEverySuffix={plural(state.repeatEvery, { one: 'week', other: `weeks` })}
                handleValueChange={handleValueChange}
            />
            <RecurrenceRepeatOnWeekDaysInput state={state} toggleDay={toggleDay} />
            <RecurrenceEndsOnInput state={state} handleValueChange={handleValueChange} />
        </div>
    );
};
