import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { I18nProvider } from '@lingui/react';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { faBell } from '@fortawesome/pro-duotone-svg-icons';
import { faCircle as faCircleEmpty, faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as faCircleFull } from '@fortawesome/pro-solid-svg-icons';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    EmptyState,
    EmptyStateSizes,
    FormatDateDistance,
    Skeleton,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { NotificationsPageSearchParams } from 'Pages/NotificationsPage/NotificationsPage';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { useNotificationHandler } from 'Shared/components/notification/hooks/useNotificationHandler';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';
import { attachmentNotificationHandler } from 'Shared/components/notification/utils/attachmentNotificationHandler';
import { checklistNotificationHandler } from 'Shared/components/notification/utils/checklistNotificationHandler';
import { checklistTemplateNotificationHandler } from 'Shared/components/notification/utils/checklistTemplateNotificationHandler';
import { meetingNotificationHandler } from 'Shared/components/notification/utils/meetingNotificationHandler';
import { networkNotificationHandler } from 'Shared/components/notification/utils/networkNotificationHandler';
import { taskNotificationHandler } from 'Shared/components/notification/utils/taskNotificationHandler';
import { teamNotificationHandler } from 'Shared/components/notification/utils/teamNotificationHandler';
import { workspaceNotificationHandler } from 'Shared/components/notification/utils/workspaceNotificationHandler';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import {
    notificationsApi,
    tagNotifications,
    useListNotificationsQuery,
    useUpdateNotificationMutation,
} from 'Shared/services/notifications';
import { trpc } from 'Shared/trpc';
import { type Notification as NotificationType } from 'Shared/types/notification';

type HandleClickFn = (notification: NotificationType) => void;

export const convertTransToText = (trans: ReactNode) => {
    const div = document.createElement('div');
    const root = createRoot(div);
    flushSync(() => {
        root.render(<I18nProvider i18n={i18n}>{trans}</I18nProvider>);
    });
    return div.innerText;
};

export const transformNotification: NotificationHandlerFn = (notification, currentUser = { id: null }) => {
    switch (notification?.object_type) {
        case 'empty':
            return {
                icon: faEmptySet,
                text: (
                    <span className="text-red-700">
                        {t`You were notified but you don't have access to this resource anymore.`}
                    </span>
                ),
            };
        case 'attachment':
            return attachmentNotificationHandler(notification, currentUser);
        case 'checklist':
            return checklistNotificationHandler(notification, currentUser);
        case 'checklist_template':
            return checklistTemplateNotificationHandler(notification, currentUser);
        case 'network':
            return networkNotificationHandler(notification, currentUser);
        case 'task':
            return taskNotificationHandler(notification, currentUser);
        case 'tag':
            return workspaceNotificationHandler(notification, currentUser);
        case 'team':
            return teamNotificationHandler(notification, currentUser);
        case 'meeting':
        case 'meeting_topic':
            return meetingNotificationHandler(notification, currentUser);
        default:
            return { icon: faEmptySet, text: <></> };
    }
};

type NotificationProps = {
    notification: NotificationType;
};

const Notification: FC<NotificationProps> = ({ notification }) => {
    const { currentUser } = useCurrentUserContext();

    const { show } = useNotification();
    const dispatch = useAppDispatch();

    const [setNotificationRead] = useUpdateNotificationMutation();
    const notificationHandler = useNotificationHandler();
    const parsedNotification = transformNotification(notification, currentUser);

    const { mutate: readNotification } = trpc.notification.read.useMutation({
        onError: () => show(UnexpectedErrorNotification),
        onSuccess: () => {
            notificationHandler(notification);
            dispatch(notificationsApi.util.invalidateTags([tagNotifications]));
        },
    });

    const [isTogglingSeen, setIsTogglingSeen] = useState(false);

    const toggleSeen: (id: Id, read: boolean) => void = async (id, read) => {
        setIsTogglingSeen(true);
        const result = await setNotificationRead({ id, read: !read });
        if ('error' in result) {
            show(UnexpectedErrorNotification);
        }
        setIsTogglingSeen(false);
    };

    const handleClick: HandleClickFn = async (notification) => {
        readNotification({ notificationId: notification.id });
    };

    return (
        <div
            key={notification.id}
            className={clsx(
                'flex items-center justify-between',
                notification.read ? 'bg-white hover:bg-gray-100' : 'bg-blue-50 hover:bg-blue-200'
            )}
        >
            <button
                onClick={() => handleClick(notification)}
                className="w-full cursor-pointer rounded-tl-md p-3 focus-visible:outline-1 focus-visible:outline-blue-500"
            >
                <div className="flex items-center gap-3">
                    <UserAvatar user={notification.created_by} size={'sm'} />
                    <div className="flex flex-col gap-1">
                        <span className="text-left">{parsedNotification.text}</span>
                        <div className="flex items-center gap-2 text-gray-700">
                            <FontAwesomeIcon className="text-sm" icon={parsedNotification.icon} />
                            <FormatDateDistance date={new Date(notification.updated_at)} />
                        </div>
                    </div>
                </div>
            </button>

            <div className="flex items-center justify-center px-2">
                <Button
                    variant="ghost"
                    size={'xs'}
                    color="default"
                    loading={isTogglingSeen}
                    aria-live="polite"
                    aria-checked={notification.read}
                    role="checkbox"
                    aria-label={t`Mark notification ${notification.id} as read`}
                    onClick={() => toggleSeen(notification.id, notification.read)}
                    title={notification.read ? t`Mark the notification as unread` : t`Mark the notification as read`}
                >
                    {!isTogglingSeen && (
                        <FontAwesomeIcon
                            className={clsx(
                                'rounded-full text-xs',
                                notification.read ? 'text-gray-700' : 'text-blue-600'
                            )}
                            icon={notification.read ? faCircleEmpty : faCircleFull}
                        />
                    )}
                </Button>
            </div>
        </div>
    );
};

type NotificationsInfiniteScrollPageProps = InfiniteScrollPageProps & {
    isOnlyUnread?: boolean;
};

const NotificationsInfiniteScrollPage = ({
    offset,
    limit,
    updateInfiniteScroll,
    isOnlyUnread,
}: NotificationsInfiniteScrollPageProps) => {
    const [searchParams, setSearchParams] = useSearchParams(NotificationsPageSearchParams);
    const notificationId = searchParams.notificationId;
    const notificationHandler = useNotificationHandler();

    const { currentData, isLoading, error, refetch } = useListNotificationsQuery(
        {
            limit,
            offset,
            read: isOnlyUnread ? false : null,
        },
        { refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
        // Because of the infinite scroll, there might be cases where the notificationId is not in the current data
        // However since this will be triggered when clicking a notification, theoretically the notification should be at the top of the list
        if (notificationId != null) {
            const notification = currentData?.data.find((n) => n.id === notificationId);
            if (notification != null) {
                setTimeout(() => notificationHandler(notification), 0);
                setSearchParams({ ...searchParams, notificationId: undefined }, { replace: true });
            }
        }
    }, [notificationId, currentData]);

    updateInfiniteScroll(currentData?.data);

    return (
        <>
            {isLoading ? (
                <div className={'flex flex-col gap-2 w-full h-full'}>
                    <Skeleton count={5} className={'h-10'} />
                </div>
            ) : error != null ? (
                <RetryComponent className="p-4" retryFunction={refetch} />
            ) : (
                currentData?.data.map((notification) => (
                    <Notification key={notification.id} notification={notification} />
                ))
            )}
        </>
    );
};

type NotificationListProps = { isOnlyUnread?: boolean; size?: EmptyStateSizes };

export const NotificationList: FC<NotificationListProps> = ({ isOnlyUnread = false, size = 'md' }) => {
    return (
        <InfiniteScroll
            className="scrollbar-light flex flex-col text-xs px-6 pb-6"
            page={NotificationsInfiniteScrollPage}
            emptyPage={
                <EmptyState icon={faBell} size={size}>
                    <EmptyState.Text>
                        <Trans>No notifications</Trans>
                    </EmptyState.Text>
                </EmptyState>
            }
            size={20}
            props={{ isOnlyUnread }}
        />
    );
};
