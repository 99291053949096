import React, { useRef, useState } from 'react';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, Card, useModal } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { TeamsTable } from 'Pages/settings/teams/components/TeamsTable/TeamsTable';
import { Can } from 'Shared/components/Can';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { Permission } from 'Shared/utils/rbac';

export const TeamsSettingsPage = () => {
    const { open } = useModal();
    const [search, setSearch] = useState<string>(EmptyString);
    const [page, setPage] = useState<number>(1);
    const toolbarRef = useRef<HTMLDivElement>(null);

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: setSearch,
        search: search,
        size: 'md',
    });

    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <Card>
                <Card.Header title={t`Teams`} />
                <Card.Body>
                    <div ref={toolbarRef} className="mb-4 flex flex-col gap-2">
                        <div className="flex justify-end gap-2">
                            {toggleButton}
                            <Button
                                title={t`Add team`}
                                tooltipClassName="flex lg:hidden"
                                icon={faAdd}
                                color="primary"
                                onClick={() => open(AddTeamModal)}
                            >
                                <span className="hidden lg:flex">
                                    <Trans>Add team</Trans>
                                </span>
                            </Button>
                        </div>
                        {searchInput}
                    </div>

                    <TeamsTable search={search} page={page} onPageChange={setPage} pageSize={15} />
                </Card.Body>
            </Card>
        </Can>
    );
};
