import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Modal, ContextModalProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { VoteOptionComponent } from 'Pages/meeting/components/Vote/VoteOption';
import { VoteOptionRating } from 'Pages/meeting/components/Vote/VoteOptionRating';
import {
    getOptionCount,
    getValueRelativeShareFraction,
    getVoteOptionRelativeShareFraction,
} from 'Pages/meeting/components/Vote/VoteUtils';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { Vote, VoteOption } from 'Shared/types/vote';

type ValidateResultsModalProps = {
    vote: Vote;
    onDone: (outcomeVoteOptionId: string | null) => Promise<void>;
    meetingId: Id;
} & ContextModalProps;

export const ConfirmOutcomeModal = ({ meetingId, vote, onDone, ...modalProps }: ValidateResultsModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const [selectedOption, setSelectedOption] = useState({ id: vote?.outcome_vote_option_id });

    useEffect(() => {
        setSelectedOption({ id: vote?.outcome_vote_option_id });
    }, []);

    return (
        <>
            <Modal size="md" {...modalProps}>
                <Modal.Header title={t`Select vote outcome`} />
                <Modal.Body>
                    <div className={'mb-2'}>
                        {vote?.voteOptions?.map((option: VoteOption) =>
                            vote.type === 'rating' ? (
                                <VoteOptionRating
                                    meetingId={meetingId}
                                    key={`starOption-${option.value}`}
                                    mode={'results'}
                                    label={option.label || ''}
                                    stars={Number(option.value)}
                                    voteOption={option}
                                    fraction={getValueRelativeShareFraction(option, vote, meeting)}
                                    voteCount={getOptionCount({
                                        voteOption: option,
                                        vote,
                                        meetingUsers: meeting?.meetingUsers,
                                    })}
                                />
                            ) : (
                                <VoteOptionComponent
                                    mode={'results-voted'}
                                    hideUserFaces={false}
                                    meetingId={meetingId}
                                    handleClick={() => setSelectedOption(option)}
                                    isSelected={selectedOption?.id === option.id}
                                    key={`vote-option-edit-${option.id}`}
                                    voteOption={option}
                                    fraction={getVoteOptionRelativeShareFraction(option, vote, meeting)}
                                    voteCount={getOptionCount({
                                        voteOption: option,
                                        vote,
                                        meetingUsers: meeting?.meetingUsers,
                                    })}
                                    isDisabled={false}
                                    voteStatus={'open'}
                                />
                            )
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'w-full'}>
                        <div className={clsx('flex', vote?.outcome_vote_option_id ? 'justify-between' : 'justify-end')}>
                            {vote?.outcome_vote_option_id && (
                                <Button
                                    key={'reset'}
                                    color={'danger'}
                                    onClick={async () => {
                                        await onDone(null);
                                        void modalProps.close();
                                    }}
                                    style={{ float: 'left' }}
                                >{t`Reset outcome`}</Button>
                            )}
                            <div className={'flex gap-2 justify-self-end'}>
                                <Button key={'cancel'} onClick={modalProps.close}>{t`Cancel`}</Button>
                                <Button
                                    key={'save'}
                                    variant={'filled'}
                                    color={'primary'}
                                    disabled={!selectedOption?.id}
                                    onClick={async () => {
                                        await onDone(selectedOption.id);
                                        void modalProps.close();
                                    }}
                                >
                                    {t`Confirm outcome`}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};
