import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBusinessTime, faCogs, faFileContract, faUserLock } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, Card, FormatDate, Table } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { Can } from 'Shared/components/Can';
import { Permission } from 'Shared/utils/rbac';

type TableRowProps = {
    text: string;
    icon: IconProp;
    href: string;
};

const TableRow: React.FC<TableRowProps> = ({ text, icon, href }) => (
    <Table.Row>
        <Table.Cell className="text-center">
            <FontAwesomeIcon icon={icon} className="text-gray-700" size="2x" />
        </Table.Cell>
        <Table.Cell className="font-medium">{text}</Table.Cell>
        <Table.Cell>
            <FormatDate format={'PPPP'} date={new Date(2021, 6, 9)} />
        </Table.Cell>
        <Table.Cell>
            <Button href={href} target="_blank" rel="noreferrer">
                <Trans>View</Trans>
            </Button>
        </Table.Cell>
    </Table.Row>
);

export const TermsSettingsPage: FC = () => {
    const { userLanguage } = useCurrentUserContext();

    const privacyPolicyLink =
        userLanguage === 'en' ? 'https://wedo.com/privacy' : `https://wedo.com/${userLanguage}/privacy`;

    const termsLink = userLanguage === 'en' ? 'https://wedo.com/terms' : `https://wedo.com/${userLanguage}/terms`;
    const slaLink = userLanguage === 'en' ? 'https://wedo.com/sla' : `https://wedo.com/${userLanguage}/sla`;
    const dpaLink = userLanguage === 'en' ? 'https://wedo.com/dpa' : `https://wedo.com/${userLanguage}/dpa`;

    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <Card>
                <Card.Header title={t`Terms and policies`} />
                <Card.Body>
                    <Table>
                        <Table.Head>
                            <Table.HeadCell className={'w-20'} />
                            <Table.HeadCell>
                                <Trans>Policy</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Last update</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell className={'w-20'} />
                        </Table.Head>
                        <Table.Body>
                            <TableRow text={t`Terms of services`} icon={faFileContract} href={termsLink} />
                            <TableRow text={t`Privacy policy`} icon={faUserLock} href={privacyPolicyLink} />
                            <TableRow text={t`Service level agreement`} icon={faBusinessTime} href={slaLink} />
                            <TableRow text={t`Data processing agreement`} icon={faCogs} href={dpaLink} />
                        </Table.Body>
                    </Table>
                </Card.Body>
            </Card>
        </Can>
    );
};
