import { isEqual as lodashIsEqual } from 'lodash-es';
import { Editor, Element, Node, NodeEntry, NodeMatch, Path, Range, Text as SlateText, Transforms } from 'slate';
import { EmptyArray, generateUUID, not, tryOrFalse, tryOrNull, tryOrZero } from '@wedo/utils';
import { getSessionUser } from 'App/store/usersStore';
import { Line } from '../plugins/linePlugin';
import { Paragraph } from '../plugins/paragraphPlugin';
import { is } from './node';
import { isIn } from './slate';

export const isTextualBlock = is('paragraph', 'decision', 'note');

export const reorderBlocks = (editor: Editor) => {
    editor.children.forEach((child, index) => Transforms.setNodes(editor, { order: index }, { at: [index] }));
};

export const selectedBlock = (editor: Editor, at = editor.selection) =>
    Editor.above(editor, { at, mode: 'highest', match: not(Editor.isEditor) });

export const selectedBlockIndex = (editor: Editor, at: Range) => {
    return selectedBlock(editor, at)?.[1][0];
};

export const hasVoid = (editor: Editor, element: Element) => {
    for (const [node] of Node.nodes(element)) {
        if (Editor.isVoid(editor, node)) {
            return true;
        }
    }
    return false;
};

export const isTopLevelBlock = (node: Node, path: Path) => path.length === 1;

export const hasNodes = <T extends Editor>(editor: T, match: NodeMatch<Node>) =>
    tryOrZero(() => Array.from(Editor.nodes(editor, { match })).length) > 0;

export const getNodes = <T extends Editor>(editor: T, match: NodeMatch<Node>) =>
    tryOrNull(() => Array.from(Editor.nodes(editor, { match })));

export const isInVoid = (editor: Editor) =>
    tryOrFalse(
        () =>
            Editor.above(editor, {
                voids: true,
                match: (node) => !Editor.isEditor(node) && Editor.isVoid(editor, node),
            }) != null
    );

export const isBlockEmpty = (element: Element) =>
    element == null ||
    (element.children.length === 1 &&
        Element.isElement(element.children[0]) &&
        element.children[0].type === 'text' &&
        element.children[0].children.length === 1 &&
        SlateText.isText(element.children[0].children[0]) &&
        element.children[0].children[0].text === '');

export const resetToParagraphIfEmpty = (editor: Editor, [node]: NodeEntry<Element>, isReadOnly = false) => {
    // If the block is empty, we do not delete it, instead, we just change its type back to paragraph
    if (!isReadOnly && isBlockEmpty(node)) {
        Transforms.setNodes(editor, { type: Paragraph }, { mode: 'highest' });
        return true;
    }
    return false;
};

export const resetOrMergeBackwardIfPossible = (editor: Editor, type: string, isReadOnly?: boolean) =>
    isIn(editor, type, (above) => {
        const [, abovePath] = above;
        return (
            resetToParagraphIfEmpty(editor, above, isReadOnly) ||
            (Range.isCollapsed(editor.selection) &&
                Editor.isStart(editor, editor.selection.anchor, abovePath) &&
                Editor.previous(editor, { at: abovePath })?.[0].type !== type)
        );
    });

export const resetOrMergeForwardIfPossible = (editor: Editor, type: string, isReadOnly?: boolean) =>
    isIn(editor, type, (above) => {
        const [, abovePath] = above;
        const nextType = Editor.next(editor, { at: abovePath })?.[0].type;
        return (
            resetToParagraphIfEmpty(editor, above, isReadOnly) ||
            (Range.isCollapsed(editor.selection) &&
                Editor.isEnd(editor, editor.selection.anchor, abovePath) &&
                nextType !== type &&
                nextType !== Paragraph)
        );
    });

export const tryResetVoidElement = (editor: Editor, element?: NodeEntry<Element>) => {
    const voidElement = element ?? Editor.void(editor);
    if (voidElement != null) {
        const [{ type, children, ...props }] = voidElement;
        Transforms.setNodes(editor, {
            type: Line,
            ...Object.fromEntries(Object.entries(props).map(([key]) => [key, null])),
        });
    }
};

export const breakBlockInside = (editor: Editor) => {
    Transforms.splitNodes(editor, { always: true, voids: true });
    tryResetVoidElement(editor);
    return true;
};

export const breakBlockOutside = (editor: Editor) => {
    Transforms.splitNodes(editor, { always: true, voids: true, mode: 'highest' });
    Transforms.setNodes(editor, { type: Paragraph, id: generateUUID() }, { mode: 'highest' });
    tryResetVoidElement(editor);
    reorderBlocks(editor);
    return true;
};

export const isEqual = (firstBlock: Element, secondBlock: Element, ignoreOrder: boolean = false) =>
    firstBlock.type === secondBlock.type &&
    (ignoreOrder || firstBlock.order === secondBlock.order) &&
    firstBlock.task_id === secondBlock.task_id &&
    firstBlock.vote_id === secondBlock.vote_id &&
    lodashIsEqual(firstBlock.attachments ?? EmptyArray, secondBlock.attachments ?? EmptyArray) &&
    lodashIsEqual(firstBlock.children ?? EmptyArray, secondBlock.children ?? EmptyArray);

export const createBlock = (props: Record<string, unknown>, text = '') => {
    return {
        id: generateUUID(),
        updated_by: getSessionUser()?.id,
        updated_at: new Date(),
        children: [{ type: Line, children: [{ text }] }],
        ...props,
    };
};
