import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, useRef, useState } from 'react';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { camelToSnake, snakeToCamel } from 'caseparser';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { AutoTooltipText, Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { stopPropagationOnMouseEvents } from '@wedo/utils';
import { AttachmentMenu } from 'Shared/components/file/AttachmentItem/AttachmentMenu';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { useDownloadAttachment } from 'Shared/hooks/useDownloadAttachment';
import { trpcUtils } from 'Shared/trpc';
import { Attachment, AttachmentRelation } from 'Shared/types/attachment';
import { Workspace } from 'Shared/types/workspace';
import { getAttachmentUrl, isAttachmentPreviewableOrOffice, isUrlFile } from 'Shared/utils/attachment';
import { AttachmentIcon } from '../AttachmentIcon';

type Props = {
    attachment: Attachment;
    attachmentList: Partial<Attachment>[];
    relation: AttachmentRelation;
    workspaceId?: Id;
    workspaces?: Workspace[];
    onDelete?: () => void;
    isReadonly: boolean;
    isSelected?: boolean;
    showTooltip?: boolean;
    showMenu?: boolean;
    onReload?: (attachment: Attachment, refreshTopicEditor?: boolean) => void;
};

export const AttachmentItem = ({
    attachment: initialAttachment,
    attachmentList,
    relation,
    workspaceId,
    workspaces,
    onDelete,
    isReadonly,
    isSelected,
    showTooltip = true,
    showMenu = true,
    onReload,
}: Props) => {
    const previousInitialAttachment = useRef<Attachment>(initialAttachment);
    const previousLazyAttachment = useRef<Attachment>();

    const { setData } = usePdfViewerContext();

    const [attachment, setAttachment] = useState<Attachment>(initialAttachment);
    const [isPreviewDisabled, setIsPreviewDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { downloadAttachment } = useDownloadAttachment(initialAttachment);
    const { isAttachmentInfected } = useDownloadAttachment(initialAttachment);

    if (initialAttachment && !isEqual(previousInitialAttachment.current, initialAttachment)) {
        previousInitialAttachment.current = initialAttachment;
        setAttachment(initialAttachment);
    }

    const handleReload = async (
        attachmentId: Id,
        attachmentRelation: AttachmentRelation,
        refreshTopicEditor = false
    ) => {
        const fullAttachment = await trpcUtils()
            .attachment.getByRelation.fetch({
                attachmentId: attachmentId ?? attachment.id,
                ...snakeToCamel(relation),
            })
            .then(camelToSnake);
        if (fullAttachment) {
            previousLazyAttachment.current = fullAttachment;
            setAttachment(fullAttachment);
            onReload?.(fullAttachment, refreshTopicEditor);
        }
    };

    const handlePreview = async (event: MouseEvent) => {
        event.stopPropagation();

        setIsLoading(true);
        setIsPreviewDisabled(true);

        const fullAttachment =
            attachment?.download_url != null
                ? attachment
                : await trpcUtils()
                      .attachment.getByRelation.fetch({
                          attachmentId: initialAttachment.id,
                          ...snakeToCamel(relation),
                      })
                      .then(camelToSnake);
        setIsLoading(false);
        setIsPreviewDisabled(false);

        if (isUrlFile(fullAttachment)) {
            window.open(getAttachmentUrl(fullAttachment));
        } else if (isAttachmentPreviewableOrOffice(fullAttachment)) {
            setData({ pdf: fullAttachment, relation, list: attachmentList as Attachment[] });
        } else if (fullAttachment != null) {
            void downloadAttachment(fullAttachment);
        }
    };

    return (
        <div
            className={clsx(
                'fond-bold flex max-w-full items-center gap-1 rounded-md border border-gray-300 px-1 py-1 text-base',
                isSelected ? 'bg-blue-300' : 'bg-white'
            )}
        >
            <div className="w-5 flex justify-center">
                {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse flex h-4 w-4 items-center justify-center" />
                ) : (
                    <AttachmentIcon
                        attachment={
                            isUrlFile(attachment)
                                ? attachment
                                : attachment?.filename == null
                                  ? attachment?.currentVersion
                                  : attachment
                        }
                    />
                )}
            </div>

            {attachment?.tags?.[0] && (
                <WorkspaceIcon className="!h-5 !w-5" workspace={attachment.tags[0]} showTooltip />
            )}
            <Button
                variant="ghost"
                className={clsx(
                    'overflow-hidden whitespace-nowrap text-sm flex-1 text-left',
                    isAttachmentInfected && 'text-red-500',
                    isReadonly ? '!cursor-default' : '!cursor-pointer'
                )}
                onClick={showMenu ? handlePreview : null}
                disabled={isPreviewDisabled}
            >
                <AutoTooltipText disabled={!showTooltip} delay={300}>
                    {attachment?.filename ?? attachment?.currentVersion?.filename}
                </AutoTooltipText>
            </Button>
            {/* the following div is used to avoid d&d events inside the modals */}
            {showMenu && (
                <div className="contents" {...stopPropagationOnMouseEvents}>
                    <AttachmentMenu
                        attachment={attachment}
                        size="xs"
                        variant="ghost"
                        className="py-0.5 hover:bg-gray-300 hover:bg-opacity-50 hover:text-gray-800"
                        isDisabled={isLoading}
                        isReadonly={isReadonly}
                        workspaceId={workspaceId}
                        workspaces={workspaces}
                        onDelete={onDelete}
                        relation={relation}
                        onReload={handleReload}
                    />
                </div>
            )}
        </div>
    );
};
