import React from 'react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Divider } from '@wedo/design-system';
import { countryCodeMapping, getSwissCantonName } from '@wedo/utils';
import { useSubscriptionAddressStore } from 'Pages/subscription/hooks/useSubscriptionAddressStore';

export const BillingAddressPreview = () => {
    const {
        firstName,
        lastName,
        company,
        email,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        zipCode,
        country,
        canton,
    } = useSubscriptionAddressStore();

    return (
        <div className="rounded-md bg-white px-16 py-10 shadow-sm">
            <div className="flex flex-col justify-start gap-2">
                <img alt={t`WEDO logo`} src="/assets/logo/logo.svg" className="mb-8 w-36" />
                <div className="h-4 w-20 rounded-lg bg-gray-200" />
                <div className="h-4 w-32 rounded-lg bg-gray-200" />
                <div className="w-18 h-4 rounded-lg bg-gray-200" />
            </div>

            <Divider />

            <div className="mt-4 text-xs">
                <h1 className="mb-1 text-base font-semibold uppercase text-gray-700">
                    <Trans>Billed to</Trans>
                </h1>

                <div>
                    <span>{firstName}</span> <span>{lastName}</span>
                </div>
                <div>{company}</div>

                <div>{addressLine1}</div>
                <div>{addressLine2}</div>
                <div>{addressLine3}</div>

                <div>
                    {city}
                    {city && zipCode && ', '}
                    {zipCode}
                </div>

                <div>{getSwissCantonName(canton)}</div>
                <div>{country && i18n._(countryCodeMapping[country])}</div>

                <div>{email}</div>
            </div>

            <h2 className="mt-6 text-base font-semibold uppercase text-gray-500">
                <Trans>Invoice # INV-20XX-XXX</Trans>
            </h2>

            <div className="-mr-16 mt-2 flex flex-col border-b border-b-gray-300">
                <div className="flex justify-between border-y border-y-gray-300 py-2">
                    <div>
                        <div className="h-4 w-10 rounded-lg bg-gray-200" />
                    </div>
                    <div className="flex gap-3 pr-4">
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                    </div>
                </div>

                <div className="flex justify-between py-2">
                    <div>
                        <div className="h-4 w-14 rounded-lg bg-gray-200" />
                    </div>
                    <div className="flex gap-3 pr-4">
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                    </div>
                </div>

                <div className="flex justify-between py-2">
                    <div>
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                    </div>
                    <div className="flex gap-3 pr-4">
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                        <div className="h-4 w-6 rounded-lg bg-gray-200" />
                    </div>
                </div>
            </div>
        </div>
    );
};
