import { autoUpdate, FloatingPortal, offset, shift, useFloating } from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { useLingui } from '@lingui/react';
import React, { FC, Fragment, PropsWithChildren, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faBuilding,
    faCalendarDay,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faCircle,
    faCircleDashed,
    faPeopleGroup,
    faPlus,
    faRectangleHistory,
    faRss,
    faScreenUsers,
    faUsers,
    faVectorCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faInbox } from '@fortawesome/pro-solid-svg-icons';
import { plural, t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import {
    Bubble,
    Button,
    Dropdown,
    EmptyState,
    getColorId,
    NavLink,
    NavLinkOptions,
    Tooltip,
    useModal,
} from '@wedo/design-system';
import { EmptyFunction, EmptyString } from '@wedo/utils';
import { useLocalStorage } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useSessionUser } from 'App/store/usersStore';
import { useSidebarStore } from 'Pages/AppPage/Sidebar/sidebarStore';
import { useInbox } from 'Pages/InboxPage/InboxPage';
import { AddonTag } from 'Shared/components/AddonTag';
import { Can } from 'Shared/components/Can';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { AddWorkspaceModal } from 'Shared/components/workspace/AddWorkspaceModal/AddWorkspaceModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { trpc } from 'Shared/trpc';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { DevFeature } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { isGovernanceAddonId } from 'Shared/utils/chargebee';
import { Permission } from 'Shared/utils/rbac';
import { computeTeamWithWorkspacesAndTemplates } from 'Shared/utils/team';
import { getWorkspaceTaskParams, WorkspaceSettingsToTab } from 'Shared/utils/workspace';

type MyLinkProps = NavLinkOptions & {
    label: string;
    icon: IconProp;
    isCollapsed: boolean;
    tag?: ReactNode;
} & PropsWithChildren;

const MyLink = ({ label, icon, isCollapsed, children, tag, ...navLinkOptions }: MyLinkProps) => {
    return (
        <Tooltip content={isCollapsed && label} placement="right" delay={300}>
            <NavLink
                className={(isActive) =>
                    clsx(
                        'group/link flex items-center rounded-md px-2 py-1 text-sm relative',
                        isActive ? 'isActive bg-gray-600 text-gray-200' : 'hover:bg-gray-700 hover:text-gray-200'
                    )
                }
                {...navLinkOptions}
            >
                <FontAwesomeIcon
                    icon={icon}
                    className={clsx('h-3.5 w-6 min-w-[1.5rem] text-gray-500 group-[.isActive]/link:text-gray-200')}
                    aria-hidden="true"
                />
                <div
                    className={clsx(
                        'truncate pl-1 transition-opacity flex items-center justify-between w-full',
                        isCollapsed && 'opacity-0'
                    )}
                >
                    {label}
                    {children}
                </div>
                <div>{tag}</div>
            </NavLink>
        </Tooltip>
    );
};

const WorkspaceLink = ({ workspace }: { workspace: Workspace }) => {
    const { grouping, layout } = getWorkspaceTaskParams(camelToSnake(workspace) as Workspace);
    const colorId = getColorId(workspace.color.background);

    return (
        <NavLink
            to={{
                pathname: `/workspaces/${workspace.id}/${
                    WorkspaceSettingsToTab[workspace.settings?.default_tab] ?? 'tasks'
                }`,
                searchParams: { grouping, layout },
            }}
            matchPathname={`/workspaces/${workspace.id}/*`}
            className={(isActive) =>
                clsx(
                    'flex items-center rounded-md px-2 py-1 text-sm',
                    isActive ? 'bg-gray-600 text-gray-200' : 'hover:bg-gray-700 hover:text-gray-200'
                )
            }
        >
            <div className="flex w-6 items-center justify-center">
                <span
                    className={clsx('h-3 w-3 rounded-full', `border-2 border-${colorId as string}-500`)}
                    aria-hidden="true"
                />
            </div>
            <span className="ml-1 flex-1 truncate">{workspace.name}</span>
        </NavLink>
    );
};

type TemplateLinkProps = { template: Template };

const TemplateLink = ({ template }: TemplateLinkProps) => {
    return (
        <NavLink
            key={template.id}
            to={`/templates/${template.id}/checklists`}
            matchPathname={`/templates/${template.id}/*`}
            className={(isActive) =>
                clsx(
                    'flex items-center rounded-md px-2 py-1 text-sm',
                    isActive ? 'bg-gray-600 text-gray-200' : 'hover:bg-gray-700 hover:text-gray-200'
                )
            }
        >
            <div className="flex w-6 items-center justify-center">
                <span
                    className={clsx('h-3 w-3 rounded-full', `border-2 border-dotted border-gray-200`)}
                    aria-hidden="true"
                />
            </div>
            <span className="ml-1 flex-1 truncate">{template.name}</span>
        </NavLink>
    );
};

const TeamMenu = ({ team }: { team: Team }) => {
    const { open } = useModal();
    const { can } = useCurrentUserContext();

    const [isTeamCollapsed, setTeamCollapsed] = useLocalStorage(`isTeamCollapsed-${team.id}`, false);

    const isTeamEmpty = isEmpty(team?.workspaces) && isEmpty(team?.checklistTemplates);

    return (
        <>
            <div className="flex gap-2 pt-1 text-xs font-bold uppercase">
                <NavLink
                    to={`/teams/${team.id}/workspaces`}
                    matchPathname={`/teams/${team.id}/*`}
                    className={(isActive) =>
                        clsx(
                            'inline flex-1 truncate rounded-md py-1 pl-2',
                            isActive ? 'bg-gray-600 text-gray-200' : 'hover:bg-gray-700 hover:text-gray-200'
                        )
                    }
                >
                    {team.name}
                </NavLink>
                <Button
                    variant="ghost"
                    color="light"
                    className="hover:bg-gray-700"
                    icon={isTeamCollapsed ? faChevronLeft : faChevronDown}
                    onClick={() => setTeamCollapsed(!isTeamCollapsed)}
                    title={isTeamCollapsed ? t`Expand team menu` : t`Collapse team menu`}
                />
            </div>
            {isTeamEmpty && !isTeamCollapsed && can(Permission.AddWorkspace) && (
                <Button
                    size="sm"
                    variant="ghost"
                    color="light"
                    className="flex items-center gap-2 rounded-md px-3 py-1 text-sm text-gray-200 hover:bg-gray-700"
                    onClick={() => open(AddWorkspaceModal, { teamId: team.id })}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="whitespace-nowrap">
                        <Trans>Add workspace</Trans>
                    </span>
                </Button>
            )}
            {!isTeamCollapsed &&
                team.workspaces?.map((workspace) => <WorkspaceLink key={workspace.id} workspace={workspace} />)}
            {!isTeamCollapsed &&
                team.checklistTemplates?.map((template) => <TemplateLink key={template.id} template={template} />)}
        </>
    );
};

type SidebarBodyProps = {
    isCollapsed?: boolean;
    isMobile?: boolean;
};

const SidebarTeamContent: FC<{ onMouseLeave?: () => void; className?: string }> = ({
    onMouseLeave = EmptyFunction,
    className = EmptyString,
}) => {
    const { open } = useModal();
    const { can } = useCurrentUserContext();

    const { data: teams, isLoading } = trpc.team.list.useQuery({});
    const { data: workspaces } = trpc.workspace.list.useQuery({});
    const { data: templates } = trpc.template.list.useQuery({});

    const teamsWithWorkspacesAndTemplates = useMemo(
        () => computeTeamWithWorkspacesAndTemplates(teams, workspaces, templates, false),
        [teams, workspaces, templates]
    );

    const workspacesOutsideTeams = useMemo(
        () => workspaces?.filter(({ teamId, isFavorite }) => teamId == null && isFavorite),
        [workspaces]
    );
    const templatesOutsideTeams = useMemo(
        () => templates?.filter(({ teamId, isFavorite }) => teamId == null && isFavorite),
        [templates]
    );

    return (
        <div onMouseLeave={onMouseLeave} className={className}>
            {workspacesOutsideTeams?.map((workspace) => <WorkspaceLink key={workspace.id} workspace={workspace} />)}
            {templatesOutsideTeams?.map((template) => <TemplateLink key={template.id} template={template} />)}

            {isEmpty(teams) && isEmpty(workspaces) && isEmpty(templates) && !isLoading && can(Permission.AddTeam) && (
                <EmptyState isDark className="mt-6 rounded-md" icon={faScreenUsers} size="md">
                    <EmptyState.Text>
                        <Trans>You have no teams</Trans>
                    </EmptyState.Text>
                    <Button icon={faPlus} color="light" onClick={() => open(AddTeamModal)}>
                        <Trans>Add team</Trans>
                    </Button>
                </EmptyState>
            )}

            {teamsWithWorkspacesAndTemplates
                .filter(({ id }) => id != null)
                .map((team: Team) => (
                    <TeamMenu key={team.id} team={team} />
                ))}
        </div>
    );
};

const useSidebarDrawer = ({ isSidebarCollapsed = false }: { isSidebarCollapsed?: boolean }) => {
    const { setIsFloatingDrawerOpen, isFloatingDrawerOpen } = useSidebarStore();

    const closeFloatingDrawer = () => setIsFloatingDrawerOpen(false);

    const openFloatingDrawer = () => {
        if (isSidebarCollapsed) {
            setIsFloatingDrawerOpen(true);
        }
    };

    return { isFloatingDrawerOpen, closeFloatingDrawer, openFloatingDrawer };
};

const SidebarDrawer: FC<{ isCollapsed?: boolean }> = ({ isCollapsed = false }) => {
    const { isFloatingDrawerOpen, openFloatingDrawer, closeFloatingDrawer } = useSidebarDrawer({
        isSidebarCollapsed: isCollapsed,
    });

    const { strategy, refs } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: 'right',
        middleware: [offset(4), shift()],
    });

    const floatingStyles = {
        position: strategy,
        top: 0,
        left: '56px',
        bottom: 0,
    };

    return (
        <FloatingPortal>
            <Transition
                afterLeave={closeFloatingDrawer}
                show={isFloatingDrawerOpen}
                as={Fragment}
                enter="transition ease-in-out duration-200 delay-100 transform"
                enterFrom="-translate-x-full z-0"
                enterTo="translate-x-0 z-10"
                leave={clsx('transition ease-linear transform', !isCollapsed ? 'duration-0' : 'duration-200')}
                leaveFrom={clsx('z-10', !isCollapsed ? 'opacity-100 -translate-x-full' : 'translate-x-0 opacity-100')}
                leaveTo="-translate-x-full z-10 opacity-0"
            >
                <div
                    className={clsx('pr-6 w-64 hidden lg:block')}
                    onMouseEnter={openFloatingDrawer}
                    onMouseLeave={closeFloatingDrawer}
                    ref={refs.setFloating}
                    style={{
                        ...floatingStyles,
                    }}
                >
                    <div
                        className="border-l border-gray-600 drop-shadow-lg bg-gray-900 overflow-hidden text-white p-2 rounded-tr-sm rounded-br-sm"
                        style={{
                            height: `${window.innerHeight}px`,
                        }}
                    >
                        <div className="scrollbar-dark overflow-auto h-full">
                            <SidebarTeamContent className="pr-2 py-1 flex flex-col gap-1" />
                        </div>
                    </div>
                </div>
            </Transition>
        </FloatingPortal>
    );
};

export const SidebarBody = ({ isCollapsed = false, isMobile = false }: SidebarBodyProps) => {
    useLingui();
    const currentUser = useSessionUser();
    const sidebar = useRef<HTMLDivElement>();
    const { data: organization } = useGetOrganizationQuery();
    const { data: subscription } = trpc.subscription.get.useQuery();
    const isOrganizationInTrial = organization?.free_trial_end_date && organization?.status === 'in_trial';
    const { network } = useCurrentNetwork();
    const { pathname } = useLocation();
    const { defaultInboxTab, totalInboxCount, notificationCount, signatureCount, voteCount } = useInbox();
    const { openFloatingDrawer, closeFloatingDrawer } = useSidebarDrawer({ isSidebarCollapsed: isCollapsed });

    const isBrowseOrganizationHighlighted =
        ['/users', '/teams', '/workspaces', '/templates'].includes(pathname) || pathname.startsWith('/feed');

    const hasGovernanceAddon = subscription?.subscription_items.some((item) => isGovernanceAddonId(item.item_price_id));
    const hasTopicSubmission = useHasFeature(currentUser, network, DevFeature.TopicSubmission);

    useEffect(() => {
        if (!isCollapsed) {
            closeFloatingDrawer();
        }
    }, [isCollapsed]);

    return (
        <>
            <div
                className="flex flex-col gap-1 overflow-hidden py-2 pl-2 text-white"
                ref={sidebar}
                onMouseEnter={openFloatingDrawer}
                onMouseLeave={closeFloatingDrawer}
            >
                <div className="flex flex-col gap-1 pr-2">
                    <div className="relative">
                        <MyLink
                            to={`/inbox/${defaultInboxTab}`}
                            matchPathname={`/inbox/*`}
                            label={'Inbox'}
                            icon={faInbox}
                            isCollapsed={isCollapsed}
                        >
                            {!isCollapsed && (
                                <div className={'flex gap-0.5'}>
                                    {notificationCount > 0 && (
                                        <Tooltip
                                            content={plural(notificationCount, {
                                                one: '# notification pending',
                                                other: '# notifications pending',
                                            })}
                                        >
                                            <Bubble
                                                size={'sm'}
                                                text={notificationCount.toString()}
                                                color={'lollipop'}
                                            />
                                        </Tooltip>
                                    )}

                                    {voteCount > 0 && (
                                        <Tooltip
                                            content={plural(voteCount, {
                                                one: '# vote pending',
                                                other: '# votes pending',
                                            })}
                                        >
                                            <Bubble size={'sm'} text={voteCount.toString()} color={'uv'} />
                                        </Tooltip>
                                    )}
                                    {signatureCount > 0 && (
                                        <Tooltip
                                            content={plural(signatureCount, {
                                                one: '# signature pending',
                                                other: '# signatures pending',
                                            })}
                                        >
                                            <Bubble size={'sm'} text={signatureCount.toString()} color={'neon'} />
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </MyLink>
                        {isCollapsed && totalInboxCount > 0 && (
                            <div className={'absolute -right-1 -top-1'}>
                                <Tooltip
                                    content={plural(totalInboxCount, {
                                        one: '# item pending in the inbox',
                                        other: '# items pending in the inbox',
                                    })}
                                >
                                    <Bubble
                                        size={'sm'}
                                        text={totalInboxCount > 99 ? '99+' : totalInboxCount.toString()}
                                        color={'primary'}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    <MyLink to="/tasks" label={t`My tasks`} icon={faCheckSquare} isCollapsed={isCollapsed} />
                    <MyLink to="/meetings" label={t`My meetings`} icon={faCalendarDay} isCollapsed={isCollapsed} />

                    {hasTopicSubmission && (
                        <MyLink to="/topics" label={t`My topics`} icon={faRectangleHistory} isCollapsed={isCollapsed} />
                    )}

                    {(hasGovernanceAddon || isOrganizationInTrial) && (
                        <MyLink
                            to="/governance"
                            label={t`Governance`}
                            tag={isOrganizationInTrial && <AddonTag />}
                            icon={faVectorCircle}
                            isCollapsed={isCollapsed}
                        />
                    )}
                    <Can permission={Permission.ViewUsers}>
                        <Dropdown
                            label={
                                <>
                                    <FontAwesomeIcon
                                        icon={faBuilding}
                                        className={clsx(
                                            'h-3.5 w-6 min-w-[1.5rem] ',
                                            isCollapsed ? 'mr-0' : '',
                                            isBrowseOrganizationHighlighted
                                                ? 'group-[.isActive]/dropdown:text-gray-200'
                                                : 'text-gray-500'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <div
                                        className={clsx(
                                            'truncate pl-1 text-white transition-opacity',
                                            isCollapsed && 'opacity-0'
                                        )}
                                    >
                                        <Trans>Browse organization</Trans>
                                    </div>
                                </>
                            }
                            variant={'ghost'}
                            offset={[0, 8]}
                            className={clsx(
                                isBrowseOrganizationHighlighted &&
                                    'isActive bg-gray-600 text-gray-200 hover:!bg-blue-700 active:bg-blue-700',
                                'flex items-center rounded-md w-full py-1 px-2 text-sm hover:bg-gray-700 hover:text-gray-200 group/dropdown'
                            )}
                            dropdownClassName={!isMobile ? 'w-60 !min-w-0' : 'w-[19rem] !max-w-[calc(100%_-_4.5rem)]'}
                        >
                            <Dropdown.LinkItem to="/users" icon={faUsers} selected={pathname === '/users'}>
                                <Trans>Users</Trans>
                            </Dropdown.LinkItem>
                            <Dropdown.LinkItem to="/teams" icon={faPeopleGroup} selected={pathname === '/teams'}>
                                <Trans>Teams</Trans>
                            </Dropdown.LinkItem>
                            <Dropdown.LinkItem to={'/workspaces'} icon={faCircle} selected={pathname === '/workspaces'}>
                                <Trans>Workspaces</Trans>
                            </Dropdown.LinkItem>
                            <Dropdown.LinkItem
                                to={'/templates'}
                                icon={faCircleDashed}
                                selected={pathname === '/templates'}
                            >
                                <Trans>Templates</Trans>
                            </Dropdown.LinkItem>
                            <Dropdown.LinkItem to="/feed" icon={faRss} selected={pathname.startsWith('/feed')}>
                                <Trans>Feed</Trans>
                            </Dropdown.LinkItem>
                        </Dropdown>
                    </Can>
                </div>
                <div
                    className={clsx(
                        'transition-transform-opacity gap-1 scrollbar-dark overflow-y-auto pr-2',
                        isCollapsed && '-translate-x-64 overflow-hidden opacity-0'
                    )}
                >
                    <SidebarTeamContent />
                </div>
            </div>
            <div className="flex flex-col gap-1 overflow-x-hidden pr-2">
                <SidebarDrawer isCollapsed={isCollapsed} />
            </div>
        </>
    );
};
