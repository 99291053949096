import { Element } from 'slate';
import { Id } from '@wedo/types';
import {
    customFetch,
    withAuth,
    withHeader,
    withJsonBody,
    withJsonResponse,
    withMethod,
    withUnauthorizedHandler,
    withUrl,
} from '@wedo/utils';
import { clientVersion } from 'App/store/versionStore';
import { Changes } from 'Shared/components/editor/plugins/serverBlocksPlugin/serverBlocksPlugin';
import { useWebSocketStore } from 'Shared/services/webSocket';
import { trpcUtils } from 'Shared/trpc';

export const updateBlocks = async (topicId: Id, changes: Changes): Promise<Element[]> => {
    return customFetch(
        withAuth,
        withUnauthorizedHandler,
        withHeader('X-Socket-Id', useWebSocketStore.getState().socket?.id),
        withHeader('X-Client-Version', clientVersion()),
        withUrl(`/api/topics/${topicId}/blocks`),
        withMethod('PUT'),
        withJsonBody(changes),
        withJsonResponse
    ).then((response) => {
        if (changes.deletedBlocks?.length > 0) {
            trpcUtils().meetingComment.listByTopicId.invalidate(topicId);
        }
        return response;
    });
};
