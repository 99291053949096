import { ChangeEvent, FC, useEffect, useState } from 'react';
import { faCircle, faCog, faInputText, faPause } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    Modal,
    Spinner,
    Tabs,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { GeneralSettingsPanel } from 'Shared/components/checklist/ChecklistSettingsModal/components/GeneralSettingsPanel';
import { WorkspacesTabsPanel } from 'Shared/components/checklist/ChecklistSettingsModal/components/WorkspacesTabsPanel';
import { useChecklistSections } from 'Shared/components/checklist/useChecklistSections';
import { SectionsTabsPanel } from 'Shared/components/sections/SectionsTabsPanel';
import { CustomFieldPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/CustomFieldPanel';
import {
    checklistError,
    useCreateChecklistSectionMutation,
    useDeleteChecklistSectionMutation,
    useGetChecklistQuery,
    useUpdateChecklistMutation,
    useUpdateChecklistSectionMutation,
    useUpdateChecklistWorkspacesMutation,
} from 'Shared/services/checklist';
import { ApiError } from 'Shared/types/apiError';
import { Section } from 'Shared/types/section';
import { Workspace } from 'Shared/types/workspace';

type ChecklistSettingsModalProps = { checklistId: Id } & ContextModalProps;

export const ChecklistSettingsModal: FC<ChecklistSettingsModalProps> = ({ checklistId, ...modalProps }) => {
    const { data: checklist } = useGetChecklistQuery(checklistId);
    const { show } = useNotification();
    const { width: viewportWidth } = useWindowSize();
    const { sections, handleDragEnd, maxOrder } = useChecklistSections(checklistId);

    const [updateChecklist, { isLoading: isUpdateChecklistLoading, error }] = useUpdateChecklistMutation();
    const [updateChecklistWorkspaces, { isLoading: isUpdateChecklistWorkspacesLoading }] =
        useUpdateChecklistWorkspacesMutation();
    const [createChecklistSection] = useCreateChecklistSectionMutation();
    const [deleteChecklistSection] = useDeleteChecklistSectionMutation();
    const [updateChecklistSection] = useUpdateChecklistSectionMutation();

    const isLoading = isUpdateChecklistLoading || isUpdateChecklistWorkspacesLoading;

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>();
    const [workspaces, setWorkspaces] = useState<Workspace[]>();

    const handleUpdateName = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setName(target.value);
    };

    const handleAddWorkspace = (workspace: Workspace) => {
        setWorkspaces(workspaces.concat(workspace));
    };

    const handleDeleteWorkspace = ({ id }: Workspace) => {
        setWorkspaces(workspaces.filter((workspace) => workspace.id !== id));
    };

    const handleAddSection = async (name: string): Promise<boolean> => {
        const response = await createChecklistSection({ checklistId, name, order: maxOrder + 1 });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleDeleteSection = async (section: Section): Promise<boolean> => {
        const response = await deleteChecklistSection({ checklistId, sectionId: section?.id });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (error.matches({ path: 'Section should be empty' })) {
                show({
                    type: 'danger',
                    title: t`The section must be empty to be deleted`,
                });
                return false;
            }
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleUpdateSection = async ({ id, ...section }: Partial<Section>) => {
        const response = await updateChecklistSection({
            checklistId,
            section: section,
            sectionId: id,
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        const hasNameChanged = !isEqual(name?.trim(), checklist?.name?.trim());
        const hasDescriptionChanged = !isEqual(description?.trim(), checklist?.description?.trim());
        const haveWorkspacesChanged = !isEqual(
            workspaces.map((workspace) => workspace.id),
            checklist?.tags.map((tag) => tag.id)
        );

        const hasUserMadeChanges = hasNameChanged || hasDescriptionChanged || haveWorkspacesChanged;
        const hasUserMadeChangesInGeneralSettings = hasNameChanged || hasDescriptionChanged;

        if (!hasUserMadeChanges) {
            await modalProps.close();
            return;
        }

        if (haveWorkspacesChanged) {
            const response = await updateChecklistWorkspaces({
                checklistId,
                workspaces: workspaces.map((workspace) => ({ id: workspace.id })),
                previousWorkspaces: checklist?.tags,
            });
            if ('error' in response) {
                show(UnexpectedErrorNotification);
            } else if (!hasUserMadeChangesInGeneralSettings) {
                await modalProps.close();
                return;
            }
        }

        const response = await updateChecklist({
            checklistId,
            checklist: {
                name: hasNameChanged ? name : undefined,
                description: hasDescriptionChanged ? description : undefined,
            },
        });
        if ('error' in response && response.error instanceof ApiError) {
            if (response.error.matches(checklistError.Duplicate)) {
                return;
            }
            show(UnexpectedErrorNotification);
        } else {
            await modalProps.close();
        }
    };

    useEffect(() => {
        setName(checklist?.name ?? '');
        setDescription(checklist?.description);
        setWorkspaces(checklist?.tags);
    }, [checklist]);

    if (checklist == null) {
        return (
            <Modal {...modalProps} size="lg">
                <Modal.Header title={t`Checklist settings`} />

                <Modal.Body>
                    <div className="flex items-center justify-center">
                        <Spinner color="blue" size="lg" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={t`${checklist.name} settings`} />

            <Modal.Body className="!p-0">
                <div className="min-h-[400px]">
                    <Tabs layout={viewportWidth >= getBreakpointValue('md') ? 'vertical' : 'horizontal'}>
                        <Tabs.Header>
                            <Tabs.Tab icon={faCog}>
                                <Trans>General</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={faPause} iconClassName="fa-rotate-90">
                                <Trans>Sections</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={faCircle}>
                                <Trans>Workspaces</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={faInputText}>
                                <Trans>Custom fields</Trans>
                            </Tabs.Tab>
                        </Tabs.Header>

                        <div className="hidden min-h-[400px] w-px bg-gray-200 md:block" />

                        <Tabs.Panels className={clsx(viewportWidth < getBreakpointValue('md') ? 'p-5' : 'w-full')}>
                            <GeneralSettingsPanel
                                checklistId={checklistId}
                                name={name}
                                onNameChange={handleUpdateName}
                                description={checklist.description}
                                onDescriptionChange={setDescription}
                                error={error as ApiError}
                            />

                            <SectionsTabsPanel
                                canChangeColor
                                sections={sections}
                                onAddSection={handleAddSection}
                                onUpdateSection={handleUpdateSection}
                                onDeleteSection={handleDeleteSection}
                                onDragEnd={handleDragEnd}
                            />

                            <WorkspacesTabsPanel
                                onAddWorkspace={handleAddWorkspace}
                                onDeleteWorkspace={handleDeleteWorkspace}
                                workspaces={workspaces}
                            />

                            <CustomFieldPanel
                                closeModal={modalProps.close}
                                relation={{ checklist_id: checklistId }}
                                title={t`Custom fields available on selected checklists`}
                            />
                        </Tabs.Panels>
                    </Tabs>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={handleSave} loading={isLoading}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
