import { JSX } from 'react';
import { ComponentProps } from 'react';
import clsx from 'clsx';

export type ScrollableContainerProps<T extends keyof JSX.IntrinsicElements> = {
    isHorizontal?: boolean;
    isVertical?: boolean;
    as?: T;
} & ComponentProps<T>;

export const ScrollableContainer = ({
    isHorizontal = false,
    isVertical = false,
    as: As = 'div',
    className,
    children,
    ...props
}: ScrollableContainerProps<typeof As>) => {
    return (
        <As
            {...props}
            className={clsx(
                'h-full max-h-full w-full max-w-full',
                isHorizontal ? 'overflow-x-auto' : 'overflow-x-hidden',
                isVertical ? 'overflow-y-auto' : 'overflow-y-hidden',
                className
            )}
        >
            {children}
        </As>
    );
};
