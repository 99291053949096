import React, { FC } from 'react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, ItemGroup, useNotification } from '@wedo/design-system';

type EmailToTaskInputProps = { email: string; className?: string; disabled?: boolean };

export const EmailToTaskButton: FC<EmailToTaskInputProps> = ({ email, className, disabled }) => {
    const { show } = useNotification();

    const handleCopy = async (
        e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>
    ): Promise<void> => {
        e.stopPropagation();
        await navigator.clipboard.writeText(email);

        show({
            title: t`Email address copied to clipboard`,
            type: 'success',
        });
    };

    return (
        <div className={clsx('flex flex-row', className)}>
            <ItemGroup>
                <Button disabled={disabled} href={disabled ? undefined : 'mailto:' + email} className="font-mono">
                    {email}
                </Button>
                <Button disabled={disabled} color={'primary'} onClick={handleCopy} icon={faCopy}>
                    <Trans>Copy</Trans>
                </Button>
            </ItemGroup>
        </div>
    );
};
