import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { plural, t, Trans } from '@lingui/macro';
import { addDays, addMonths, addYears, endOfMonth, isBefore, isSameDay, parse, startOfMonth } from 'date-fns';
import { Card, Skeleton, Tooltip } from '@wedo/design-system';
import { EmptyArray, formatDate } from '@wedo/utils';
import { useDateTime } from '@wedo/utils/hooks';
import { useGetUserStatsTimestampsQuery } from 'Shared/services/dashboard';

const legends = [
    {
        color: 'bg-green-100',
        title: <Trans>Less than 2 items</Trans>,
    },
    {
        color: 'bg-green-200',
        title: <Trans>Between 2 and 4 items</Trans>,
    },
    {
        color: 'bg-green-500',
        title: <Trans>Between 4 and 6 items</Trans>,
    },
    {
        color: 'bg-green-600',
        title: <Trans>Between 6 and 8 items</Trans>,
    },
    {
        color: 'bg-green-700',
        title: <Trans>Between 8 and 10 items</Trans>,
    },
    {
        color: 'bg-green-800',
        title: <Trans>More than 10 items</Trans>,
    },
];

type Activity = {
    count: number;
    date: Date;
};

const ColorLevels = [
    'fill-green-100',
    'fill-green-200',
    'fill-green-500',
    'fill-green-600',
    'fill-green-700',
    'fill-green-800',
];

const classForValue = (item: Activity): string =>
    !item?.count ? 'fill-gray-50' : ColorLevels[Math.min(Math.floor(item.count / 2), 5)];

export const ColleagueActivityHeatmap = (): JSX.Element => {
    const { userId } = useParams();
    const { i18n } = useLingui();
    const { abbreviatedMonths } = useDateTime();

    const { data: timestamps = EmptyArray, isLoading } = useGetUserStatsTimestampsQuery(userId, {
        selectFromResult: ({ data, isLoading }) => {
            if (!data) {
                return { data: EmptyArray, isLoading };
            }
            const keys = Object.keys(data);
            return {
                data: keys.map((key) => ({
                    date: parse(key, 't', new Date()),
                    count: data[Number(key)],
                })),
                isLoading: isLoading,
            };
        },
    });

    const { startDate, endDate, daysList } = useMemo(() => {
        const startDate = startOfMonth(addMonths(addYears(new Date(), -1), 1));
        const endDate = endOfMonth(new Date());
        let current = startDate;
        const daysList = []; // will contains dates from startDate to endDate with potential timestamps data
        while (isBefore(current, endDate)) {
            // Sum of timestamps for current date
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,no-loop-func
            const total = timestamps.reduce((prev, curr) => prev + (isSameDay(curr.date, current) ? curr.count : 0), 0);
            daysList.push({
                date: current,
                count: total,
            });
            current = addDays(current, 1);
        }
        return { startDate, endDate, daysList };
    }, [timestamps]);

    return (
        <Card className="col-span-6">
            <Card.Header title={t`Activity heatmap`} />
            <Card.Body>
                {isLoading ? (
                    <Skeleton className="h-32" />
                ) : (
                    <div className="flex w-full flex-col gap-4 xl:flex-row">
                        <div className="ml-4 block xl:w-3/4 xl:max-w-4xl [&_text.react-calendar-heatmap-month-label]:text-xs">
                            <CalendarHeatmap
                                showOutOfRangeDays
                                startDate={startDate}
                                endDate={endDate}
                                values={daysList}
                                classForValue={classForValue}
                                tooltipDataAttrs={(value: Activity) => ({
                                    'data-tip': plural(value.count, {
                                        zero: `${formatDate(value.date, 'shortDate', i18n)}`,
                                        one: `${value.count} item on ${formatDate(value.date, 'shortDate', i18n)}`,
                                        other: `${value.count} items on ${formatDate(value.date, 'shortDate', i18n)}`,
                                    }),
                                })}
                                monthLabels={abbreviatedMonths}
                            />
                            <ReactTooltip />
                        </div>
                        <div className="align-center xs:w-1/4 ml-4 flex flex-row xl:mt-5 xl:flex-col">
                            {legends.map((item, index) => (
                                <div className="flex" key={index}>
                                    <Tooltip content={item.title}>
                                        <div className={`mr-2 inline-block h-3 w-3 ${item.color}`} />
                                    </Tooltip>
                                    <span className="ml-2 hidden text-sm xl:!block">{item.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};
