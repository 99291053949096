import { useEffect } from 'react';
import clsx from 'clsx';
import { useEvent } from '@wedo/utils/hooks';
import { useFocusedCommentIdStore } from 'Shared/components/editor/plugins/commentPlugin/commentPlugin';
import { trpc } from 'Shared/trpc';
import { LocalStorage } from 'Shared/types/localStorage';
import { ThreadPortal } from './ThreadPortal';
import { type Store } from './store';
import { type Comment as CommentType } from './types';

type CommentsProps = {
    store: Store;
    topicId: string;
    isStatic: boolean;
};

export const Comments = ({ store, topicId, isStatic }: CommentsProps) => {
    const areCommentsCollapsed = localStorage.getItem(LocalStorage.MeetingCommentsCollapsed) === 'true';

    const { data: threads }: { data: Record<string, CommentType[]> } = trpc.meetingComment.listByTopicId.useQuery(
        topicId,
        {
            refetchOnMount: 'always',
            cacheTime: 0,
            select: (data: CommentType[]) =>
                Object.groupBy(data, ({ meetingBlockId, meetingTopicId }) => meetingBlockId ?? meetingTopicId),
        }
    );
    useEffect(() => {
        const focusedCommentId = useFocusedCommentIdStore.getState();
        if (threads != null && (focusedCommentId != null || store.getState().collapsedIds == null)) {
            store.setState(() => ({
                collapsedIds: areCommentsCollapsed
                    ? Object.entries(threads)
                          .filter(([, comments]) => comments.every(({ id }) => id !== focusedCommentId))
                          .map(([meetingBlockId]) => meetingBlockId)
                    : [],
            }));
        }
    }, [threads]);

    useEvent(
        'collapseComments',
        ({ detail: isCollapsed }) => {
            store.setState(() => ({
                collapsedIds: isCollapsed ? Object.keys(threads) : [],
            }));
        },
        document
    );

    return (
        <div
            contentEditable={false}
            className={clsx('flex flex-col px-2', threads != null && Object.entries(threads).length > 0 && 'pb-2')}
        >
            {threads != null &&
                Object.entries(threads).map(([meetingBlockId, comments]) => (
                    <ThreadPortal
                        key={meetingBlockId}
                        meetingBlockId={meetingBlockId}
                        store={store}
                        comments={comments}
                        isStatic={isStatic}
                    />
                ))}
        </div>
    );
};
