import { t } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { ConfirmDeleteTasksWithSubtasks } from 'Shared/components/task/TaskDetail/ConfirmDeleteTasksWithSubtasks';
import { Task } from 'Shared/types/task';
import { hasOpenSubTasks } from 'Shared/utils/task';

export const useConfirmDeleteTask = (task: Task) => {
    const { confirm } = useConfirm();

    const confirmSimpleDeleteTask = async (): Promise<boolean> => {
        return confirm<boolean>({
            type: 'danger',
            title: t`Delete task`,
            content: t`Are you sure you want to delete this task?`,
            confirmText: t`Delete`,
        });
    };

    const confirmDeleteTask = async (): Promise<{ confirm: boolean; applyOn: ApplyOn }> => {
        if (!hasOpenSubTasks(task)) {
            return { confirm: await confirmSimpleDeleteTask(), applyOn: ApplyOn.OnlyCurrentTask };
        }

        const applyOn = await confirm<ApplyOn>({ task }, ConfirmDeleteTasksWithSubtasks);
        if (applyOn == null || !applyOn) {
            return { confirm: false, applyOn: null };
        }

        return { confirm: true, applyOn };
    };

    return { confirmDeleteTask };
};
