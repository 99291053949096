import { Id } from '@wedo/types';
import { AddUserArg } from 'Shared/services/admin';
import { User } from 'Shared/types/user';

export type PartialUser = Pick<
    User,
    | 'id'
    | 'photo_url'
    | 'color'
    | 'display_name'
    | 'first_name'
    | 'last_name'
    | 'initials'
    | 'language_code'
    | 'userNetwork'
    | 'role'
    | 'userEmail'
    | 'userAccount'
    | 'userLicenses'
    | 'title'
> & {
    teams?: { id: Id }[];
};

export const partialUserToAddUserArg = (user: PartialUser): AddUserArg => {
    return {
        id: user.id,
        displayName: user.display_name,
        firstName: user.first_name,
        lastName: user.last_name,
        color: user.color,
        email: user.userEmail.email_address,
        initials: user.initials,
        can_add_external: user.userNetwork.can_add_external,
        language: user.language_code,
        title: user.title,
        userType: user.role,
        photoUrl: user.photo_url,
        saml: user?.userAccount?.saml ?? false,
        teams: user?.teams,
    };
};
