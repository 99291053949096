import { FloatingPortal } from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faImage } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowToBottom, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import { camelToSnake, snakeToCamel } from 'caseparser';
import clsx from 'clsx';
import {
    Button,
    EmptyState,
    SavedSuccessNotification,
    SavingIndicator,
    Spinner,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { download } from '@wedo/utils';
import { useEvent, useLoader } from '@wedo/utils/hooks';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { configureWebViewer, refreshHeaderButtons, useWebViewer } from 'Shared/components/pdfViewer/useWebViewer';
import { useReplaceAttachmentMutation } from 'Shared/services/attachment';
import {
    useLazyGetAttachmentAnnotationsQuery,
    useUpdateAttachmentAnnotationMutation,
} from 'Shared/services/attachmentAnnotation';
import { trpc, trpcUtils } from 'Shared/trpc';

type NavigationButtonProps = {
    position: 'left' | 'right';
    icon: IconProp;
    onClick: () => void;
};
const NavigationButton = ({ position, icon, onClick }: NavigationButtonProps) => (
    <div className={clsx('fixed top-1/2 z-40', position === 'left' ? 'left-2' : 'right-2')}>
        <Button onClick={onClick}>
            <FontAwesomeIcon icon={icon} />
        </Button>
    </div>
);

export const PdfViewerContainer = () => {
    const { show } = useNotification();
    const viewer = useRef();
    const { data, setData, clearData } = usePdfViewerContext();
    const [index, setIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { wrap: wrapSaving, isLoading: isSaving } = useLoader();
    const [isSavingError, setIsSavingError] = useState(false);
    const { mutateAsync: logDownload } = trpc.attachment.logDownload.useMutation({
        onSuccess: () => trpcUtils().activity.listAttachmentDownloads.invalidate(),
    });

    const [getAnnotations] = useLazyGetAttachmentAnnotationsQuery();
    const [updateAttachmentAnnotation] = useUpdateAttachmentAnnotationMutation();

    const [replaceAttachment] = useReplaceAttachmentMutation();

    const showNav = !isLoading && data?.current?.list?.length > 1;

    const updateAnnotations = async () => {
        if (data.current.isAnnotationChanged) {
            const { annotationManager } = data.current.instance.Core;
            const annotation = await annotationManager.exportAnnotations();
            const attachmentVersionId =
                data.current?.pdf?.currentVersion?.id ?? data.current?.pdf?.attachment_version_id;

            return updateAttachmentAnnotation({
                attachmentVersionId,
                annotation_xfdf: annotation,
            });
        }

        return null;
    };

    const handleClose = async () => {
        await updateAnnotations();
        clearData();
    };

    const handleDownload = async () => {
        if (data?.current?.pdf?.id == null) {
            return;
        }
        download(data.current.pdf.download_url, data.current.pdf.filename);
        void logDownload({ attachmentIds: [data.current.pdf.id] });
    };

    const handleDownloadPdf = async () => {
        if (data?.current?.pdf?.id == null) {
            return;
        }
        const { documentViewer, annotationManager } = data.current.instance.Core;
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        const arrayBuffer = await doc.getFileData({ xfdfString });
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'application/pdf' });
        const filename = data.current.pdf.filename.replace(/(?:\.([^.]+))?$/, '.pdf');
        download(URL.createObjectURL(blob), filename);
        void logDownload({ attachmentIds: [data.current.pdf.id] });
    };

    const handleEditToggle = (isEditing: boolean) => {
        data?.current?.instance.UI.loadDocument(data?.current?.pdf?.download_url, {
            filename: data?.current?.pdf.filename,
            enableOfficeEditing: isEditing,
        });
        refreshHeaderButtons(data?.current?.instance, isEditing);
    };

    const handleSave = async () => {
        const { documentViewer } = data.current.instance.Core;
        const doc = documentViewer.getDocument();
        const fileData = await doc.getFileData();
        const blob = new Blob([fileData]);
        const pdf = data.current.pdf;

        const formData = new FormData();
        formData.append(
            'attachments',
            new File([blob], pdf.filename, {
                type: pdf.currentVersion?.mimetype ?? pdf.mimetype,
            })
        );
        const res = await replaceAttachment({ attachmentId: pdf.id.toString(), body: formData, keepAnnotations: true });
        if ('error' in res) {
            show(UnexpectedErrorNotification);
            return null;
        }

        setData({ pdf: res.data });
        refreshHeaderButtons(data?.current?.instance, false);
        data?.current?.instance.UI.loadDocument(res.data.download_url, {
            filename: res.data.filename,
            enableOfficeEditing: false,
        });

        show(SavedSuccessNotification);
        return res.data;
    };

    const handleDocumentLoaded = async () => {
        // annotations
        const attachmentVersionId = data.current?.pdf?.currentVersion?.id ?? data.current?.pdf?.attachment_version_id;
        const annotations = await getAnnotations(attachmentVersionId);
        const { annotationManager } = data.current.instance.Core;
        const currentAnnotations = annotationManager.getAnnotationsList();
        if (annotations?.data?.annotation_xfdf != null) {
            annotationManager.deleteAnnotations(currentAnnotations);
            await annotationManager.importAnnotations(annotations.data.annotation_xfdf);
        }
        setData({ isAnnotationChanged: false });
        setIsLoading(false);
    };

    const handleError = async () => {
        setIsLoading(false);
        setIsError(true);
    };

    const webViewer = useWebViewer({
        onClose: handleClose,
        onEdit: handleEditToggle,
        onDownload: handleDownload,
        onDownloadPdf: handleDownloadPdf,
        onSave: handleSave,
        onDocumentLoaded: handleDocumentLoaded,
        onError: handleError,
    });

    useEffect(() => {
        if (data?.current?.pdf == null) {
            return;
        }
        const load = async () => {
            setIsLoading(true);
            setIsError(false);

            // Load full attachment
            if (data.current.pdf.download_url == null) {
                const fullAttachment = await trpcUtils()
                    .attachment.getByRelation.fetch({
                        attachmentId: data.current.pdf.id,
                        workspaceId: data.current.relation.tag_id,
                        ...snakeToCamel(data.current.relation),
                    })
                    .then(camelToSnake);

                if (fullAttachment) {
                    // it will trigger this useEffect but this time with an attachment contains a download_url
                    setData({ pdf: fullAttachment });
                }
                return;
            }
            if (data?.current?.instance != null) {
                data?.current?.instance.UI.loadDocument(data?.current?.pdf.download_url, {
                    filename: data?.current?.pdf.filename,
                });
                configureWebViewer(data?.current?.instance, data?.current?.pdf, data?.current?.search);
            } else {
                const newInstance = await webViewer({
                    viewerElement: viewer.current,
                    doc: data?.current?.pdf.download_url,
                    filename: data?.current?.pdf.filename,
                });
                configureWebViewer(newInstance, data?.current?.pdf, data?.current?.search);
            }
        };
        void load();
    }, [data?.current?.pdf]);

    const handleNavigation = (acc: number): void => {
        const list = data.current.list;
        let newIndex = (index + acc) % list.length;
        if (newIndex < 0) {
            newIndex = list.length - 1;
        }
        setIndex(newIndex);
        setData({ pdf: list[newIndex] });
    };

    const handleAnnotationChange = async (
        _annotations: Array<unknown>,
        _operation: 'add' | 'delete' | 'modify',
        { imported }: { imported: boolean }
    ) => {
        // imported can also be false or undefined
        if (imported === true) {
            return;
        }
        const result = await wrapSaving(updateAnnotations);
        if ('error' in result) {
            setIsSavingError(true);
        } else {
            setIsSavingError(false);
        }
    };

    useEvent('annotationChanged', handleAnnotationChange, data.current.instance?.Core.annotationManager);

    return (
        <FloatingPortal>
            <div aria-live="assertive" className={clsx('fixed inset-0 z-50', data?.current?.pdf == null && 'hidden')}>
                {isLoading && (
                    <div className="absolute flex w-full h-full items-center justify-center bg-gray-900">
                        <div className="absolute top-5 right-5">
                            <Button
                                variant="ghost"
                                size="lg"
                                icon={faXmark}
                                className="text-white"
                                onClick={handleClose}
                            />
                        </div>
                        <Spinner className="h-40 w-40 animate-spin" />
                    </div>
                )}
                {isError && (
                    <div className="absolute flex w-full h-full items-center justify-center bg-gray-900">
                        <EmptyState icon={faImage} onClick={handleDownload}>
                            <EmptyState.Text>
                                <Trans>No preview available for {data.current.pdf?.filename}</Trans>
                            </EmptyState.Text>
                            <div className="flex gap-2">
                                <Button color="default" onClick={handleClose}>
                                    <Trans>Close</Trans>
                                </Button>
                                <Button icon={faArrowToBottom} color="primary" onClick={handleDownload}>
                                    <Trans>Download</Trans>
                                </Button>
                            </div>
                        </EmptyState>
                    </div>
                )}
                {showNav && (
                    <NavigationButton icon={faChevronLeft} position="left" onClick={() => handleNavigation(-1)} />
                )}
                <div className="webviewer w-full h-full" ref={viewer}></div>
                {showNav && (
                    <NavigationButton icon={faChevronRight} position="right" onClick={() => handleNavigation(1)} />
                )}
                <div className="absolute bottom-4 right-4">
                    <SavingIndicator
                        status={isSaving ? 'saving' : isSavingError ? 'error' : 'success'}
                        isIdle={!isSaving && !isSavingError}
                    />
                </div>
            </div>
        </FloatingPortal>
    );
};
