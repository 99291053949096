import { isBefore, isAfter, isEqual, max, min, differenceInHours, format, subWeeks, addWeeks } from 'date-fns';
import { Day } from '@wedo/utils';
import { ganttViewElement } from './GanttView';
import { ZoomViews, type ZoomView, createStore, ZoomColumnWidths } from './store';
import { type DateWindow, type Section, type SectionWithTasks, type Task } from './types';

export const GlobalDateWindow = 'global';

export const addTaskDependencies = (store: ReturnType<typeof createStore>, task: Task, element: HTMLElement) => {
    store.setState((state) => {
        state.view.taskElements.set(task.id, element);
        state.data.dependencies.set(task.id, task.blockedTaskIds?.map((toId) => ({ fromId: task.id, toId })) ?? []);
    });
};

export const removeTaskDependencies = (store: ReturnType<typeof createStore>, task: Task) => {
    store.setState((state) => {
        state.view.taskElements.delete(task.id);
        state.data.dependencies.delete(task.id);
    });
};

export const groupTasks = (tasks: Array<Task>, sectionsById: Map<string, Section>) => {
    const sections = new Map<string, SectionWithTasks>(
        Array.from(sectionsById.entries()).map(([id, section]) => [id, { ...section, tasks: [] }])
    );
    return Array.from(
        tasks
            .filter((task) => task.parentTaskId == null)
            .reduce((sections, task, index) => {
                // To make sure the section currently exists
                const section = sections.get(task.sectionId ?? '') ?? sections.get('')!;
                section.tasks.push(task);
                if (index === tasks.length - 1) {
                    section.isLastSection = true;
                }
                return sections;
            }, sections)
            .values()
    );
};

export const getZoomFromView = (toView: ZoomView) => {
    const index = ZoomViews.findIndex((view) => view === toView);
    return { level: index + 1, view: toView, columnWidth: ZoomColumnWidths[index] };
};

export const isBeforeOrEqual = (firstDate: Date, secondDate: Date) => {
    return isBefore(firstDate, secondDate) || isEqual(firstDate, secondDate);
};

export const isAfterOrEqual = (firstDate: Date, secondDate: Date) => {
    return isAfter(firstDate, secondDate) || isEqual(firstDate, secondDate);
};

export const durationInDays = (minDate: string | null | undefined, maxDate: string | null | undefined) => {
    // From date-fns documentation at https://date-fns.org/v4.1.0/docs/differenceInDays:
    // To ignore DST and only measure exact 24-hour periods, use this instead: Math.trunc(differenceInHours(dateLeft, dateRight)/24)|0.
    return minDate == null || maxDate == null
        ? 1
        : Math.trunc(differenceInHours(new Date(maxDate), new Date(minDate)) / 24) + 1;
};

export const daysSinceEpoch = (date: string | null | undefined) => {
    return date == null ? null : Math.trunc(new Date(date.endsWith('Z') ? date : date + 'Z').getTime() / Day);
};

export const toLocalDateString = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const adjustDateWindow = (dateWindow: DateWindow, minDate: string | null, maxDate: string | null) => {
    dateWindow.minDate =
        dateWindow.minDate == null
            ? minDate
            : minDate != null
              ? toLocalDateString(min([new Date(dateWindow.minDate), new Date(minDate)]))
              : dateWindow.minDate;

    dateWindow.maxDate =
        dateWindow.maxDate == null
            ? maxDate
            : maxDate != null
              ? toLocalDateString(max([new Date(dateWindow.maxDate), new Date(maxDate)]))
              : dateWindow.maxDate;
};

export const computeDateWindows = (tasks: Array<Task>, sectionsById: Map<string, Section>) => {
    const dateWindows = new Map<string, DateWindow>([[GlobalDateWindow, { minDate: null, maxDate: null }]]);
    const globalDateWindow = dateWindows.get(GlobalDateWindow)!;
    for (const task of tasks) {
        // To make sure the section currently exists
        const sectionId = sectionsById.get(task.sectionId ?? '')?.id ?? '';

        if (dateWindows.has(sectionId)) {
            adjustDateWindow(dateWindows.get(sectionId)!, task.plannedDate, task.dueDate);
        } else {
            dateWindows.set(sectionId, { minDate: task.plannedDate, maxDate: task.dueDate });
        }

        adjustDateWindow(globalDateWindow, task.plannedDate, task.dueDate);
    }
    adjustDateWindow(
        globalDateWindow,
        globalDateWindow.minDate != null ? subWeeks(new Date(globalDateWindow.minDate), 2) : null,
        globalDateWindow.maxDate != null ? addWeeks(new Date(globalDateWindow.maxDate), 2) : null
    );
    const ganttView = ganttViewElement();
    if (ganttView != null && globalDateWindow.minDate != null) {
        ganttView.style.setProperty('--start-day', daysSinceEpoch(globalDateWindow.minDate));
    }
    return dateWindows;
};
