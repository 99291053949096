import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { getFormattedNumber } from '@wedo/utils';

export const useFormattedNumber = (number: number) => {
    const {
        i18n: { locale },
    } = useLingui();

    return useMemo(() => getFormattedNumber(number, locale), [number, locale]);
};
