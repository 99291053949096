import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Button, Input, ItemGroup, Label, RadioGroup, Select } from '@wedo/design-system';
import {
    Day,
    getMonthDays,
    getWeekDayList,
    getWeeksList,
    Week,
} from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/ReccurrenceUtils';
import { FormRecurrence, RecurrenceMonthDay, RecurrenceWeek, RecurrenceWeekDay } from 'Shared/types/formRecurrence';

export type RecurrenceRepeatOnInputProps = {
    state: FormRecurrence;
    handleValueChange?: (name: string, value: number | string | boolean) => void;
    repeatEverySuffix?: string;
    toggleDay: (day: RecurrenceWeekDay, value: boolean) => void;
    toggleWeek: (week: RecurrenceWeek) => void;
};

export const RecurrenceRepeatOnInput = ({
    state,
    toggleDay,
    toggleWeek,
    handleValueChange,
}: RecurrenceRepeatOnInputProps) => {
    const { i18n } = useLingui();

    const weekDays = useMemo(() => getWeekDayList(i18n), [i18n]);
    const weeks = getWeeksList();
    const monthDays = getMonthDays(i18n);

    const handleDayClick = (day: Omit<Day, 'selected'>) => {
        toggleDay(day.index as RecurrenceWeekDay, true);
    };
    const handleWeekClick = (week: Omit<Week, 'selected'>) => {
        toggleWeek(week.index as RecurrenceWeek);
    };

    const isWeekSelected = (week: Omit<Week, 'selected'>) => (state.weeks || []).some((w) => w === week.index);
    const isWeekDaySelected = (weekDay: Omit<Day, 'selected'>) => (state.days || []).some((d) => d === weekDay.index);

    return (
        <>
            <Label className={'col-span-2 mt-[0.4rem] self-start text-end'} inputType={'inline'}>{t`Repeat`}</Label>
            <RadioGroup
                className={'col-span-10'}
                onChange={(value) => handleValueChange('repeatByChoice', value)}
                value={state.repeatByChoice}
                name="repeatByChoice"
            >
                <RadioGroup.Radio
                    value={'everyDays'}
                    id={'everyDays'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center gap-2'}
                >
                    <div className={'shrink-0'}>{t`every`}</div>
                    <div className={'flex flex-wrap items-center gap-1'}>
                        {weeks.map((week) => {
                            return (
                                <Button
                                    key={week.index}
                                    role="checkbox"
                                    active={isWeekSelected(week)}
                                    aria-checked={isWeekSelected(week)}
                                    disabled={state.repeatByChoice !== 'everyDays'}
                                    onClick={() => handleWeekClick(week)}
                                >
                                    {week.label}
                                </Button>
                            );
                        })}
                        |
                        {weekDays.map((day) => {
                            return (
                                <Button
                                    key={day.index}
                                    role="checkbox"
                                    active={isWeekDaySelected(day)}
                                    aria-checked={isWeekDaySelected(day)}
                                    disabled={state.repeatByChoice !== 'everyDays'}
                                    onClick={() => handleDayClick(day)}
                                >
                                    {day.label}
                                </Button>
                            );
                        })}
                    </div>
                </RadioGroup.Radio>
                <RadioGroup.Radio
                    value={'dayOfMonth'}
                    id={'dayOfMonth'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center gap-2'}
                >
                    <div className={'shrink-0'}>{t`on the`}</div>
                    <ItemGroup className={'w-56'}>
                        <Select
                            className={'flex-grow'}
                            value={state.monthDaySelected?.toString()}
                            onChange={(value: RecurrenceMonthDay) =>
                                handleValueChange('monthDaySelected', Number(value))
                            }
                            disabled={state.repeatByChoice !== 'dayOfMonth'}
                        >
                            {monthDays.map((monthDay) => (
                                <Select.Option key={monthDay.number} value={monthDay.number.toString()}>
                                    {monthDay.label}
                                </Select.Option>
                            ))}
                        </Select>
                        {![0, 31, 32].some((n) => n === state.monthDaySelected) ? (
                            <Input.Addon text={t`day`} />
                        ) : undefined}
                    </ItemGroup>
                </RadioGroup.Radio>
            </RadioGroup>
        </>
    );
};
