import { Svg, Rect } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const TrelloIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg fill="#fff" height="512" width="512" viewBox="0 0 512 512" style={style}>
            <Rect fill="#0079bf" height="512" rx="15%" width="512" />
            <Rect height="188" rx="23" width="132" x="284" y="95" />
            <Rect height="296" rx="23" width="132" x="97" y="95" />
        </Svg>
    );
};
