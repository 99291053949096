import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Card, useModal } from '@wedo/design-system';
import { EnableTotpModal } from 'Pages/settings/security/components/EnableTotpModal';

export const EnableTotpCard = (): JSX.Element => {
    const { open } = useModal();

    const handleOpen = (): void => {
        open(EnableTotpModal);
    };

    return (
        <Card>
            <Card.Header title={t`Two-factor authentication`} />
            <Card.Body>
                <Button onClick={handleOpen} color="primary">
                    <Trans>Enable two-factor authentication</Trans>
                </Button>
            </Card.Body>
        </Card>
    );
};
