import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { Button, Input, Label, NoInternetErrorNotification, Textarea, useNotification } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { useUpdateNetworkConfigMutation } from 'Shared/services/network';
import { ApiError } from 'Shared/types/apiError';
import { NetworkConfig } from 'Shared/types/network';
import { isFetchError } from 'Shared/utils/rtkQuery';
import { SAML_CARD_CACHE_KEY } from './SamlAuthCard';

type StandardIdentityProviderConfigSectionProps = {
    className?: string;
    config: NetworkConfig['saml'];
    hasMadeChanges: boolean;
};

export const StandardIdentityProviderConfigSection: React.FC<StandardIdentityProviderConfigSectionProps> = ({
    className,
    config,
    hasMadeChanges,
}) => {
    const { show } = useNotification();

    const [updateConfig, { isLoading }] = useUpdateNetworkConfigMutation({
        fixedCacheKey: SAML_CARD_CACHE_KEY,
    });

    const [issuer, setIssuer] = useState<string>(config?.issuer);
    const [entryPoint, setEntryPoint] = useState<string>(config?.entryPoint);
    const [certificate, setCertificate] = useState<string>(config?.certificate);

    const [isEntryPointInvalid, setIsEntryPointInvalid] = useState<boolean>(false);
    const [isIssuerInvalid, setIsIssuerInvalid] = useState<boolean>(false);
    const [isCertificateInvalid, setIsCertificateInvalid] = useState<boolean>(false);

    const [isUrlInputDirty, setIsUrlInputDirty] = useState<boolean>(false);

    const showInvalidUrlError = isEntryPointInvalid && isUrlInputDirty;

    const urlHasError = showInvalidUrlError || isEntryPointInvalid;

    const urlErrorMessage = showInvalidUrlError
        ? t`Url is invalid`
        : isEntryPointInvalid
        ? t`Entry point is empty`
        : EmptyString;

    const clearErrors = () => {
        setIsIssuerInvalid(false);
        setIsCertificateInvalid(false);
        setIsEntryPointInvalid(false);
    };

    const handleSave = () => {
        clearErrors();
        void updateConfig({
            ...config,
            enable: true,
            entryPoint,
            issuer,
            certificate,
            idp_metadata: '',
        }).then((response) => {
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else if (error.matches({ path: 'EntryPoint is empty' })) {
                    setIsEntryPointInvalid(true);
                } else if (error.matches({ path: 'Issuer is empty' })) {
                    setIsIssuerInvalid(true);
                } else if (error.matches({ path: 'Certificate is empty' })) {
                    setIsCertificateInvalid(true);
                }
            } else {
                clearErrors();
            }
        });
    };

    const hasUserMadeChanges =
        !isEqual(issuer?.trim(), config?.issuer?.trim()) ||
        !isEqual(entryPoint?.trim(), config?.entryPoint?.trim()) ||
        !isEqual(certificate?.trim(), config?.certificate) ||
        hasMadeChanges;

    const isSaveButtonDisabled = !hasUserMadeChanges;

    return (
        <div className={className}>
            <Label>
                <Trans>Identity provider single sign-on url</Trans>
                <Input
                    value={entryPoint}
                    onChange={(e) => setEntryPoint(e.target.value)}
                    status={urlHasError ? 'error' : 'default'}
                    statusText={urlErrorMessage}
                    onBlur={() => setIsUrlInputDirty(true)}
                />
            </Label>

            <Label className="mt-4">
                <Trans>X.509 Certificate</Trans>
                <Textarea
                    rows={10}
                    value={certificate}
                    onChange={(e) => setCertificate(e.target.value)}
                    status={isCertificateInvalid ? 'error' : 'default'}
                    statusText={isCertificateInvalid && t`Certificate is empty`}
                />
            </Label>

            <Label className="mt-4">
                <Trans>Identity Provider Issuer</Trans>
                <Input
                    value={issuer}
                    onChange={(e) => setIssuer(e.target.value)}
                    status={isIssuerInvalid ? 'error' : 'default'}
                    statusText={isIssuerInvalid && t`Issuer is empty`}
                />
            </Label>

            <Button
                color="primary"
                disabled={isSaveButtonDisabled || isLoading}
                loading={isLoading}
                onClick={handleSave}
                className="mt-6"
            >
                <Trans>Save</Trans>
            </Button>
        </div>
    );
};
