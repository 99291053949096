import React, { KeyboardEvent } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { IconPicker, Input, ItemGroup } from '@wedo/design-system';
import { FaIconName } from '@wedo/types';

type FieldNameInputProps = {
    value: string;
    onChange: (value: string) => void;
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    icon: FaIconName;
    onIconChange: (icon: IconDefinition) => void;
    isInGroup: boolean;
    shortName?: string;
};

export const FieldNameInput: React.FC<FieldNameInputProps> = ({
    value,
    onChange,
    onKeyDown,
    icon,
    onIconChange,
    isInGroup,
    shortName,
}) => {
    return (
        <ItemGroup helperText={shortName && t`Short name: ${shortName}`}>
            {!isInGroup && (
                <IconPicker icon={icon} onClick={onIconChange} color="gray" position="start" canDeselectIcon={false} />
            )}
            <Input
                className={'flex-grow'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={onKeyDown}
            />
        </ItemGroup>
    );
};
