import React, { ReactNode, useState } from 'react';
import ReactJson from 'react-json-view';
import { t, Trans } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { Button, ContextModalProps, HelperText, Label, Modal, Tabs, UploadButton, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { CircleSearchSelect } from 'Pages/governance/CircleSearchSelect';
import { useFetchImportSchemaQuery, useImportGovernanceMutation } from 'Shared/services/governance';
import { Circle, Root } from 'Shared/types/governance';

const ImportSchemaDisplay = ({ schemaType }: { schemaType: 'open-api' | 'json' }) => {
    const { currentData: schema } = useFetchImportSchemaQuery({ schemaType });

    return <ReactJson displayDataTypes={false} src={schema} />;
};

const JSONSchemaModal = (modalProps: ContextModalProps) => (
    <Modal {...modalProps} size="lg">
        <Modal.Header title={t`Required format`} />

        <Tabs>
            {/* TODO: add "Example" tab */}
            <Tabs.Header>
                <Tabs.Tab>{t`Open API Schema`}</Tabs.Tab>
                <Tabs.Tab>{t`JSON Schema (draft 07)`}</Tabs.Tab>
            </Tabs.Header>
            <Modal.Body>
                <Tabs.Panels>
                    <Tabs.Panel>
                        <div className="h-96 overflow-y-auto">
                            <ImportSchemaDisplay schemaType="open-api" />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel>
                        <div className="h-96 overflow-y-auto">
                            <ImportSchemaDisplay schemaType="json" />
                        </div>
                    </Tabs.Panel>
                </Tabs.Panels>
            </Modal.Body>
        </Tabs>
        <Modal.Footer>
            <Button onClick={modalProps.close}>{t`Close`}</Button>
        </Modal.Footer>
    </Modal>
);

type ImportGovernanceModalProps = {
    children: ReactNode;
    selectedCircleId: Id;
    circles: HierarchyCircularNode<Circle | Root>[];
} & ContextModalProps;

export const ImportGovernanceModal = ({
    children,
    selectedCircleId,
    circles,
    ...modalProps
}: ImportGovernanceModalProps) => {
    const { open: openModal } = useModal();

    const [file, setFile] = useState<File>(null);
    const [fileInputError, setFileInputError] = useState<string>(null);
    const [parentCircleId, setParentCircleId] = useState<Id>(selectedCircleId);
    const [isLoading, setIsLoading] = useState(false);

    const [importGovernance] = useImportGovernanceMutation();

    const handleFormFinish = async () => {
        setIsLoading(true);
        const reader = new FileReader();
        let organizationString;
        reader.onload = async (e) => {
            organizationString = e.target.result.toString();
            let organization;
            try {
                organization = JSON.parse(organizationString);
            } catch (e) {
                setFileInputError(t`Your file isn't a valid JSON`);
                setFile(null);
                setIsLoading(false);
                return;
            }
            importGovernance({
                organization,
                parent_circle_id: parentCircleId !== 'root' ? parentCircleId : undefined,
            })
                .unwrap()
                .then(() => modalProps.close())
                .catch(() => {
                    setFileInputError(t`Your file doesn't match the required format`);
                    setFile(null);
                })
                .finally(() => setIsLoading(false));
        };
        reader.readAsText(file);
    };

    return (
        <Modal title={t`Import JSON`} size="md" {...modalProps}>
            <Modal.Header title={t`Import JSON`} />
            <Modal.Body>
                <div className="mb-4">
                    <CircleSearchSelect
                        label={t`Destination`}
                        circles={circles}
                        value={parentCircleId as string}
                        setValue={setParentCircleId}
                    />
                </div>
                <div>
                    <div className={'mb-1 flex items-center gap-2'}>
                        <Label htmlFor="file" inputType={'inline'}>{t`Your JSON file`}</Label>
                        <Button
                            variant="link"
                            style={{ height: 'auto' }}
                            onClick={() => openModal(JSONSchemaModal, {})}
                        >
                            ({t`required format`})
                        </Button>
                    </div>

                    <UploadButton
                        displayFileName={true}
                        onFileSelect={setFile}
                        className="mb-1"
                        id="file"
                        name="file"
                        accept=".json"
                    >
                        <Trans>Select file</Trans>
                    </UploadButton>
                    {fileInputError && <HelperText status="error">{fileInputError}</HelperText>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    loading={isLoading}
                    disabled={parentCircleId == null || file == null}
                    color="primary"
                    onClick={handleFormFinish}
                >
                    <Trans>Confirm</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
