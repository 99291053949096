import { EnforceDkimCard } from 'Pages/settings/authentication/components/EnforceDkimCard';
import { Can } from 'Shared/components/Can';
import { Permission } from 'Shared/utils/rbac';
import { Force2faCard } from './components/Force2faCard';
import { SamlAuthCard } from './components/SamlAuthCard';
import { ScimAuthCard } from './components/ScimAuthCard';

export const AuthenticationSettingsPage = () => {
    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <>
                <SamlAuthCard />
                <ScimAuthCard />
                <Force2faCard />
                <EnforceDkimCard />
            </>
        </Can>
    );
};
