import { FC, useState } from 'react';
import { CloseSource, Modal, ModalProps } from '@wedo/design-system';
import { ImportModalSwitch } from 'Pages/settings/users/components/ImportModal/ImportModalSwitch';

export type ImportModalProps = ModalProps;

export const ImportModal: FC<ImportModalProps> = ({ close, ...modalProps }) => {
    const [tabIndex, setTabIndex] = useState(1);

    const handleTabChange = (newIndex: number) => {
        if (newIndex < 1) {
            void close();
        } else {
            setTabIndex(newIndex);
        }
    };

    const handleClose = async (source: CloseSource) => source !== 'backdrop-or-esc';

    return (
        <Modal {...modalProps} size="full" onBeforeClose={handleClose}>
            <ImportModalSwitch tabIndex={tabIndex} onClose={close} onStepChange={handleTabChange} />
        </Modal>
    );
};
