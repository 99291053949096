import { FC } from 'react';
import { faClock, faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { plural, t } from '@lingui/macro';
import { Button, NavLink, Skeleton, Tag } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { TeamDropdown } from 'Shared/components/team/TeamDropdown';
import { TeamFavoriteToggle } from 'Shared/components/team/TeamFavoriteToggle';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { currentNetworkTeamsTag, teamApi, teamTag, useRequestJoinTeamAccessMutation } from 'Shared/services/team';
import { trpc } from 'Shared/trpc';
import { Team } from 'Shared/types/team';

export const TeamGridCard: FC<{ team: Team }> = ({ team }) => {
    const dispatch = useAppDispatch();

    const { currentUser, isAdmin, isLoading: isCurrentUserLoading, currentUserId } = useCurrentUserContext();

    const { mutateAsync: addMember, isLoading: isAddMemberLoading } = trpc.team.addMember.useMutation({
        onSuccess: () => dispatch(teamApi.util.invalidateTags([currentNetworkTeamsTag, teamTag(team.id)])),
    });

    const [applyToTeam, { isLoading: isApplyToTeamLoading }] = useRequestJoinTeamAccessMutation();

    const isJoinLoading = isAddMemberLoading || isApplyToTeamLoading;
    const isRequestPending = team.join_requests?.some((request) => request.user_id === currentUserId);

    const isMember = team.userGroup.members.some((member) => member.user_id === currentUserId);

    const isOpen = !team.deleted;
    const hasAccess = team.has_access;
    const status = team.deleted ? 'deleted' : undefined;
    const linkToDetail = `/teams/${team.id}/workspaces`;

    const handleApplyToTeam = () => {
        void applyToTeam({ team_id: team.id, user_id: currentUser.id });
    };

    const handleJoinTeam = () => {
        if (team.is_team_moderator) {
            void addMember({ teamId: team.id, userId: currentUserId });
            return;
        }

        handleApplyToTeam();
    };

    const tagApprovalPending = <Tag icon={faClock}>{t`Waiting for approval`}</Tag>;

    // No access and join request pending
    if (!hasAccess && team.join_requests?.some((request) => request.user_id === currentUser.id)) {
        return (
            <GridCard>
                <GridCard.Header
                    classNameHeader="bg-gray-100"
                    icon={<TeamIcon />}
                    iconColor="#fefefe"
                    title={team.name}
                />
                <GridCard.Body status={status}>
                    <div className="flex justify-center">{tagApprovalPending}</div>
                </GridCard.Body>
            </GridCard>
        );
    }

    // No access
    if (!hasAccess) {
        return (
            <GridCard>
                <GridCard.Header
                    classNameHeader="bg-gray-100"
                    icon={<TeamIcon />}
                    iconColor="#fefefe"
                    title={team.name}
                >
                    {!isRequestPending && (
                        <GridCard.Actions>
                            {!isMember && isOpen && (
                                <Button loading={isJoinLoading} onClick={handleJoinTeam}>{t`Join`}</Button>
                            )}
                            {isAdmin && <TeamDropdown team={team} />}
                        </GridCard.Actions>
                    )}
                </GridCard.Header>
                <GridCard.Body members={[]} status={status}>
                    <div className="flex items-center justify-center">
                        {isRequestPending ? (
                            tagApprovalPending
                        ) : (
                            <Tag icon={faLockAlt}>{t`You don't have access to this team`}</Tag>
                        )}
                    </div>
                </GridCard.Body>
            </GridCard>
        );
    }

    if (isCurrentUserLoading) {
        return <Skeleton className="h-72" />;
    }

    return (
        <GridCard>
            <GridCard.Header
                isHoverable
                href={linkToDetail}
                classNameHeader="bg-gray-100"
                icon={<TeamIcon />}
                iconColor="#fefefe"
                title={team.name}
            >
                <GridCard.Actions>
                    <div className="z-10 flex gap-2">
                        <TeamFavoriteToggle.Button team={team} size="md" />
                        {!isMember && isOpen && !isRequestPending && (
                            <Button loading={isJoinLoading} onClick={handleJoinTeam}>{t`Join`}</Button>
                        )}
                        <TeamDropdown team={team} />
                    </div>
                </GridCard.Actions>
            </GridCard.Header>

            <GridCard.Body status={status} isHoverable members={team.userGroup.members.map((member) => member.user)}>
                {isRequestPending && tagApprovalPending}
                <GridCard.Metrics>
                    <GridCard.Metric
                        value={team.checklist_templates.length}
                        text={plural(team.checklist_templates.length, { one: 'template', other: 'templates' })}
                    />
                    <GridCard.Metric
                        value={team.tags.length}
                        text={plural(team.tags.length, { one: 'workspace', other: 'workspaces' })}
                    />
                    <GridCard.Metric
                        value={team.userGroup.members.length}
                        text={plural(team.userGroup.members.length, { one: 'user', other: 'users' })}
                    />
                </GridCard.Metrics>
            </GridCard.Body>

            <NavLink to={linkToDetail} className="absolute z-0 h-full w-full" />
        </GridCard>
    );
};
