import { Link, Text } from '@react-pdf/renderer';
import { useMeetingPdfContext } from './MeetingPdf';
import { type Meeting } from './types';

type MeetingLocationProps = {
    meeting: Meeting;
};

export const MeetingLocation = ({ meeting }: MeetingLocationProps) => {
    const { color } = useMeetingPdfContext();

    return meeting.location?.includes('zoom.us') ? (
        <Link src={meeting.location} style={{ color: color.linkText }}>
            Zoom
        </Link>
    ) : meeting.location?.includes('teams.microsoft.com') ? (
        <Link src={meeting.location} style={{ color: color.linkText }}>
            Microsoft Teams
        </Link>
    ) : (
        <Text>{meeting.location}</Text>
    );
};
