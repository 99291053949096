import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { msg, Trans } from '@lingui/macro';
import {
    addDays,
    differenceInDays,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    endOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subMonths,
    subQuarters,
    subWeeks,
    subYears,
} from 'date-fns';
import { useStore } from 'zustand';
import { Button, Dropdown, useModal } from '@wedo/design-system';
import { AddUpdateSectionModal } from './AddUpdateSectionModal';
import { useGanttContextStore } from './GanttContext';
import { type GanttPageParams } from './GanttPage';
import { ganttViewElement } from './GanttView';
import { useDateFnsLocale } from './useDateFnsLocale';
import { getZoomFromView, GlobalDateWindow, isAfterOrEqual, isBeforeOrEqual } from './utils';

const ZoomViewLabels = {
    week: msg`Week`,
    month: msg`Month`,
    quarter: msg`Quarter`,
    year: msg`Year`,
};

export const GanttViewControls = () => {
    const { workspaceId } = useParams<GanttPageParams>();

    const store = useGanttContextStore()!;
    const eventBus = store.getState().eventBus;

    const { open: openModal } = useModal();

    const { minDate: minDateString, maxDate: maxDateString } = useStore(
        store,
        ({ data }) => data.dateWindows.get(GlobalDateWindow)!
    );
    const zoom = useStore(store, ({ view }) => view.zoom);

    const { _ } = useLingui();
    const locale = useDateFnsLocale();

    const hasToday = useMemo(() => {
        const now = new Date();
        const minDate = new Date(minDateString);
        return isAfterOrEqual(now, minDate) && isBeforeOrEqual(now, new Date(maxDateString));
    }, [minDateString, maxDateString]);

    const handleAddSection = () => {
        openModal(AddUpdateSectionModal, { eventBus: store.getState().eventBus, workspaceId });
    };

    const handleToggleSections = (isOpen: boolean) => () => {
        store.setState((state) => {
            state.view.closedSections = isOpen ? new Set() : new Set(state.data.sectionsById.keys());
        });
    };

    const handleChangeZoom = (view: string) => () => {
        store.setState((state) => {
            state.view.zoom = getZoomFromView(view);
        });
        eventBus.dispatchRenderDependenciesEvent();
    };

    const handleScrollToToday = () => {
        eventBus.dispatchScrollToDayEvent(hasToday ? differenceInDays(new Date(), new Date(minDateString)) : 0);
    };

    const handleScrollToPrevious = () => {
        const minDate = new Date(minDateString);
        const days = Math.floor(ganttViewElement().lastElementChild!.scrollLeft / zoom.columnWidth);
        const date = addDays(minDate, days);
        const startDate =
            zoom.view === 'week'
                ? startOfWeek(subWeeks(date, 1), { locale })
                : zoom.view === 'month'
                  ? startOfMonth(subMonths(date, 1))
                  : zoom.view === 'quarter'
                    ? startOfQuarter(subQuarters(date, 1))
                    : startOfYear(subYears(date, 1));
        eventBus.dispatchScrollToDayEvent(differenceInDays(endOfDay(startDate), minDate));
    };

    const handleScrollToNext = () => {
        const minDate = new Date(minDateString);
        const days = Math.floor(ganttViewElement().lastElementChild!.scrollLeft / zoom.columnWidth);
        const date = addDays(minDate, days);
        const startDate = addDays(
            zoom.view === 'week'
                ? endOfWeek(date, { locale })
                : zoom.view === 'month'
                  ? endOfMonth(date)
                  : zoom.view === 'quarter'
                    ? endOfQuarter(date)
                    : endOfYear(date),
            1
        );
        eventBus.dispatchScrollToDayEvent(differenceInDays(startDate, minDate));
    };

    return (
        <div className="p-2 flex items-center justify-between rounded-t-md border-b border-gray-200">
            <div className="flex items-center gap-1">
                <Button size="sm" icon={faPlus} onClick={handleAddSection} color="primary">
                    <Trans>Add section</Trans>
                </Button>
                <Dropdown size="sm" icon={faEllipsisVertical}>
                    <Dropdown.Item icon={faArrowDownLeftAndArrowUpRightToCenter} onClick={handleToggleSections(false)}>
                        <Trans>Collapse all sections</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item icon={faArrowUpRightAndArrowDownLeftFromCenter} onClick={handleToggleSections(true)}>
                        <Trans>Expand all sections</Trans>
                    </Dropdown.Item>
                </Dropdown>
            </div>
            <div className="flex items-center gap-1">
                <Dropdown size="sm" icon={faChevronDown} iconPosition="end" label={_(ZoomViewLabels[zoom.view])}>
                    <Dropdown.Item selected={zoom.view === 'week'} onClick={handleChangeZoom('week')}>
                        <Trans>Week</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item selected={zoom.view === 'month'} onClick={handleChangeZoom('month')}>
                        <Trans>Month</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item selected={zoom.view === 'quarter'} onClick={handleChangeZoom('quarter')}>
                        <Trans>Quarter</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item selected={zoom.view === 'year'} onClick={handleChangeZoom('year')}>
                        <Trans>Year</Trans>
                    </Dropdown.Item>
                </Dropdown>
                <Button
                    size="sm"
                    icon={faChevronLeft}
                    className="hover:!bg-gray-100"
                    onClick={handleScrollToPrevious}
                />
                <Button size="sm" className="hover:!bg-gray-100 !text-sm !text-gray-500" onClick={handleScrollToToday}>
                    <Trans>Today</Trans>
                </Button>
                <Button size="sm" icon={faChevronRight} className="hover:!bg-gray-100" onClick={handleScrollToNext} />
            </div>
        </div>
    );
};
