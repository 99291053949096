import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { faCalendarAlt, faDownload, faLink, faLock, faTags, faWeightHanging } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { sortBy } from 'lodash-es';
import { Button, FormatDate, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getFormattedBytes, getIdMapping } from '@wedo/utils';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { PanelRow } from 'Shared/components/Panel/PanelRow';
import { AttachmentIcon } from 'Shared/components/file/AttachmentIcon';
import { AttachmentMenu } from 'Shared/components/file/AttachmentItem/AttachmentMenu';
import { Preview } from 'Shared/components/file/fileDetails/Preview';
import { LabelSelect } from 'Shared/components/file/fileList/LabelSelect';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { useFiles } from 'Shared/hooks/files/useFiles';
import { useDownloadAttachment } from 'Shared/hooks/useDownloadAttachment';
import {
    invalidateAttachment,
    invalidateGetAttachments,
    invalidateGetAttachmentVersions,
    useDeleteAttachmentsMutation,
    useGetAttachmentQuery,
} from 'Shared/services/attachment';
import { getAttachmentUrl, isAttachmentPreviewableOrOffice, isUrlFile } from 'Shared/utils/attachment';
import { Permission } from 'Shared/utils/rbac';
import { FileDetailLocation } from './FileDetailLocation';
import { FileDetailMeetings } from './FileDetailMeetings';
import { FileDetailTasks } from './FileDetailTasks';
import { FileDetailWorkspace } from './FileDetailWorkspace';

type FileDetailsHeaderProps = {
    fileId: Id;
    workspaceId?: Id;
    search?: string;
    onUnselect: () => void;
};

export const FileDetailsHeader: FC<FileDetailsHeaderProps> = ({ fileId, workspaceId, search, onUnselect }) => {
    const dispatch = useAppDispatch();

    const { setData } = usePdfViewerContext();
    const { data: attachment } = useGetAttachmentQuery({ id: fileId });

    const [deleteAttachments] = useDeleteAttachmentsMutation();

    const isUrlFileLink = isUrlFile(attachment);
    const { downloadAttachment } = useDownloadAttachment(attachment);

    const handlePreviewClick = () => {
        if (isAttachmentPreviewableOrOffice(attachment)) {
            setData({ pdf: attachment, search });
        }
    };

    const handleReload = () => {
        dispatch(invalidateGetAttachmentVersions());
        dispatch(invalidateGetAttachments());
        dispatch(invalidateAttachment(fileId));
    };

    const handleDelete = async () => {
        await deleteAttachments({ attachments: [{ id: attachment.id }] });
        onUnselect();
        dispatch(invalidateGetAttachments());
    };

    return (
        <div className="flex flex-col border-b border-gray-200 py-2">
            <div className="flex items-center justify-between px-2">
                <div className="flex gap-2">
                    {isAttachmentPreviewableOrOffice(attachment) && (
                        <Button onClick={handlePreviewClick}>
                            <Trans>Preview</Trans>
                        </Button>
                    )}
                    {isUrlFileLink && (
                        <Button onClick={() => window.open(getAttachmentUrl(attachment))} icon={faLink}>
                            <Trans>Open link</Trans>
                        </Button>
                    )}
                    {!isUrlFileLink && (
                        <Button onClick={downloadAttachment} icon={faDownload}>
                            <Trans>Download</Trans>
                        </Button>
                    )}
                </div>
                <AttachmentMenu
                    attachment={attachment}
                    relation={{}}
                    onReload={handleReload}
                    onDelete={handleDelete}
                    isAll
                    isReadonly={!attachment?.canEdit}
                    workspaceId={workspaceId}
                />
            </div>
        </div>
    );
};

type FileDetailsProps = {
    fileId: Id;
    workspaceId?: Id;
    search?: string;
    onUnselect: () => void;
};

export const FileDetails: FC<FileDetailsProps> = ({ fileId, workspaceId, search, onUnselect }) => {
    const { data: attachment, isLoading } = useGetAttachmentQuery({ id: fileId });
    const { addLabelInFile, removeLabelFromFile } = useFiles();
    const { can } = useCurrentUserContext();
    const { setData } = usePdfViewerContext();

    const [loadingLabels, { add: addLoadingLabel, remove: removeLoadingLabel }] = useSet<Id>(new Set());
    const { isAttachmentInfected } = useDownloadAttachment(attachment);

    const isUrlFileLink = isUrlFile(attachment);
    const fileLabels = useMemo(() => getIdMapping(attachment?.labels ?? []), [attachment]);
    const handlePreviewClick = () => {
        if (isUrlFile(attachment)) {
            window.open(getAttachmentUrl(attachment));
        } else if (isAttachmentPreviewableOrOffice(attachment)) {
            setData({ pdf: attachment, search });
        }
    };

    const handleLabelChange = async (labels: Id[]) => {
        const newLabels = new Set([...labels]);

        // removing labels from file that are not part of new labels
        for (const [id, label] of fileLabels) {
            if (!newLabels.has(id)) {
                addLoadingLabel(label.id);
                await removeLabelFromFile(attachment.id, label.id);
                removeLoadingLabel(label.id);
            }
        }

        // adding labels in file that were initially not there, but are now present in the new labels
        for (const id of labels) {
            if (!fileLabels.has(id)) {
                addLoadingLabel(id);
                await addLabelInFile(attachment.id, id);
                removeLoadingLabel(id);
            }
        }
    };

    return isLoading || !attachment ? (
        <div className="flex flex-col gap-2">
            <Skeleton count={5} className={'h-10'} />
        </div>
    ) : (
        <div>
            <div className={'flex flex-col overflow-y-auto bg-white'}>
                <div className="flex items-center gap-2 px-4 py-2">
                    <AttachmentIcon attachment={isUrlFileLink ? attachment : attachment.currentVersion} size="lg" />
                    <div
                        className={clsx(
                            'break-all text-base font-bold',
                            isAttachmentInfected ? 'text-red-500' : 'text-gray-800'
                        )}
                    >
                        {attachment.currentVersion.filename}
                    </div>
                </div>

                <Preview
                    size="lg"
                    attachment={attachment}
                    onClick={
                        isAttachmentPreviewableOrOffice(attachment) || isUrlFileLink ? handlePreviewClick : undefined
                    }
                />

                {attachment?.lock && (
                    <div className="mt-1 flex justify-center text-gray-800">
                        <FontAwesomeIcon icon={faLock} className="pr-1" />
                        <Trans>Locked meeting</Trans>
                    </div>
                )}

                {workspaceId != null && (
                    <FileDetailWorkspace attachment={attachment} workspaceId={workspaceId} onClose={onUnselect} />
                )}

                {attachment?.folder_id && <FileDetailLocation attachment={attachment} />}

                <PanelRow
                    customIcon={
                        <div className="flex items-center leading-8">
                            <UserAvatar user={attachment?.updated_by} size="xs" tooltipContent={t`Added by`} />
                        </div>
                    }
                >
                    <span>{attachment?.updated_by?.full_name}</span>
                </PanelRow>

                <PanelRow isActive icon={faCalendarAlt} toolTipContent={t`Last updated`}>
                    <FormatDate date={attachment?.updated_at} format={'short'} />
                </PanelRow>

                {!isUrlFileLink && (
                    <PanelRow isActive icon={faWeightHanging} toolTipContent={t`Size`}>
                        <span>{getFormattedBytes(Number(attachment?.currentVersion.file_size))}</span>
                    </PanelRow>
                )}

                <PanelRow isActive={attachment?.labels.length > 0} icon={faTags} toolTipContent={t`Labels`}>
                    <LabelSelect
                        canEdit={can(Permission.ManageFiles)}
                        canAdd={can(Permission.ManageFiles)}
                        canDelete={can(Permission.ManageFiles)}
                        value={attachment?.labels?.map(({ id }) => id)}
                        onChange={handleLabelChange}
                        loadingLabels={loadingLabels}
                    />
                </PanelRow>

                {attachment?.tasks.length > 0 && <FileDetailTasks tasks={attachment?.tasks} />}

                {attachment?.meetingBlocks.length > 0 && (
                    <FileDetailMeetings
                        meetingBlocks={sortBy(attachment?.meetingBlocks, ({ topic }) =>
                            topic.meeting == null ? topic.updated_at : topic.meeting.start_at
                        ).reverse()}
                    />
                )}
            </div>
        </div>
    );
};
