import { Query } from '@tanstack/react-query';
import { TRPCClientError } from '@trpc/client';

export const resetStateOnError = {
    throwOnError: (error: TRPCClientError<any>, query: Query) => {
        if (error.data?.code === 'NOT_FOUND') {
            query.setData(null);
            return false;
        }

        return true;
    },
};
