import { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { SafeParseError, z } from 'zod';
import {
    Button,
    ContextModalProps,
    Form,
    Input,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { adminFieldError, useInviteUserMutation } from 'Shared/services/admin';
import { ApiError } from 'Shared/types/apiError';
import { getErrorMessage } from 'Shared/utils/user';

type ResendInvitationModalProps = { userEmail: string; userId: Id } & ContextModalProps;

export const ResendInvitationModal: FC<ResendInvitationModalProps> = ({ userEmail, userId, close, ...modalProps }) => {
    const [sendInvitation, { isLoading: isInvitationLoading }] = useInviteUserMutation();
    const { show } = useNotification();

    const [inputEmail, setInputEmail] = useState(userEmail);
    const [formatError, setFormatError] = useState('');

    const handleEmailUpdate = (newEmail: string) => {
        const result = z
            .string()
            .email({ message: getErrorMessage('emailInvalid') })
            .safeParse(newEmail);

        if (!result.success) {
            setFormatError((result as SafeParseError<string>).error.issues[0].message);
        } else {
            setFormatError('');
        }

        setInputEmail(newEmail);
    };

    const handleInvitation = async () => {
        if (formatError !== '') {
            return;
        }

        const result = await sendInvitation({ user_id: userId, email: inputEmail });

        if ('error' in result && result.error instanceof ApiError) {
            if (result.error.matches(adminFieldError.email)) {
                setFormatError(result.error.message);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            await close();
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Re-send invitation`} />

            <Modal.Body>
                <Form layout="vertical">
                    <Form.Item label={t`Email`}>
                        <Input
                            value={inputEmail}
                            onChange={(e) => handleEmailUpdate(e.target.value)}
                            status={formatError ? 'error' : 'default'}
                            statusText={formatError}
                        />
                    </Form.Item>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button loading={isInvitationLoading} onClick={handleInvitation} color="success">
                    <Trans>Confirm</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
