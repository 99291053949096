import React from 'react';
import { Trans } from '@lingui/macro';
import { Table } from '@wedo/design-system';
import { formatLocale } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { WebhookTableRow } from 'Pages/settings/api/components/WebhookCard/WebhookTableRow';
import { WebhookType } from 'Shared/services/webhook';

type WebhookListProps = {
    webhookList: WebhookType[];
};

export const WebhookTable = ({ webhookList }: WebhookListProps): JSX.Element => {
    const { currentUser } = useCurrentUserContext();
    const formatDate = formatLocale({ precision: 'minute', defaultLocale: currentUser?.language_code ?? 'fr-ch' });

    return (
        <Table>
            <Table.Head>
                <Table.HeadCell>
                    <Trans>Object type</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Event action</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Endpoint URL</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Created</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Last attempt</Trans>
                </Table.HeadCell>
                <Table.HeadCell>&nbsp;</Table.HeadCell>
            </Table.Head>
            <Table.Body>
                {webhookList.map((webhook) => (
                    <WebhookTableRow key={webhook.id} webhook={webhook} formatDate={formatDate} />
                ))}
            </Table.Body>
        </Table>
    );
};
