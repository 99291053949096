import { create } from 'zustand';

export const createStore = () => {
    return create<{ activeBlockId: string; overBlockId: string }>()(() => ({
        activeBlockId: null,
        overBlockId: null,
    }));
};

export type Store = ReturnType<typeof createStore>;
