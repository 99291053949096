export const enum HttpMethod {
    Get = 'get',
    Head = 'head',
    Post = 'post',
    Put = 'put',
    Delete = 'delete',
    Connect = 'connect',
    Options = 'options',
    Trace = 'trace',
    Path = 'path',
}
