type Predicate<T> = (parameter: T) => boolean;

export const not =
    <T>(predicate: Predicate<T>) =>
    (parameter: T) =>
        !predicate(parameter);

export const and =
    <T>(...predicates: [Predicate<T>]) =>
    (parameter: T) =>
        predicates.every((predicate) => predicate(parameter));

export const or =
    <T>(...predicates: [Predicate<T>]) =>
    (parameter: T) =>
        predicates.some((predicate) => predicate(parameter));

export const accumulate = <T>(take: () => T): T[] => {
    const result = [];
    let item: T;
    while ((item = take()) != null) {
        result.push(item);
    }
    return result;
};
