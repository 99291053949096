import {
    NavBarButton,
    NavBarDivider,
    NavBarLink,
    NavBarTab,
    TranslatedNavBarTab,
} from 'Shared/components/layout/NavBar/types';
import { TranslatedPageHeaderTab } from 'Shared/components/layout/TabsPageHeader';

export const isNavBarTabType = (tab: TranslatedNavBarTab | TranslatedPageHeaderTab): tab is TranslatedNavBarTab =>
    !('icon' in tab);

export const isNavBarLinkType = (tab: NavBarTab): tab is NavBarLink => 'to' in tab;

export const isNavBarButtonType = (tab: NavBarTab): tab is NavBarButton => 'onClick' in tab;

export const isNavBarDividerType = (tab: NavBarTab): tab is NavBarDivider => 'type' in tab && tab.type === 'divider';
