import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useClick,
    useDismiss,
    useRole,
    useInteractions,
    Placement,
    FloatingPortal,
    FloatingFocusManager,
} from '@floating-ui/react';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { Button, ButtonProps } from '~/components/Button/Button';
import { preventOverflowMiddleware, restrictSizeMiddleware } from '@wedo/utils';

export type PopoverProps = {
    text?: ReactNode;
    placement?: Placement;
    icon?: IconDefinition;
    wrapperClassName?: string;
    isStatic?: boolean; // Popover visibility is managed by the component
    onOpenChange?: (isOpen: boolean) => void;
} & PropsWithChildren &
    Omit<ButtonProps, 'children'>;

export const Popover = ({
    text,
    children,
    placement = 'bottom-end',
    icon,
    wrapperClassName,
    isStatic,
    onOpenChange,
    ...buttonProps
}: PopoverProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: (open) => {
            setIsOpen(open);
            onOpenChange?.(open);
        },
        middleware: [offset(8), flip(), shift(), preventOverflowMiddleware, restrictSizeMiddleware],
        whileElementsMounted: autoUpdate,
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);
    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

    return (
        <>
            <Button icon={icon} ref={refs.setReference} {...getReferenceProps()} {...buttonProps}>
                {text}
            </Button>
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            className={clsx('border rounded-md shadow-md bg-white z-40', wrapperClassName)}
                            ref={refs.setFloating}
                            style={floatingStyles}
                            {...getFloatingProps()}
                        >
                            {children}
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );
};
