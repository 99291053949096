import { useLingui } from '@lingui/react';
import { Text, View } from '@react-pdf/renderer';
import { Trans } from '@lingui/macro';
import { intervalToDuration } from 'date-fns';
import { MeetingLocation } from './MeetingLocation';
import { useMeetingPdfContext } from './MeetingPdf';
import { formatDurationWithLocale, formatMeetingDateTime, formatMeetingTitle } from './utils';

export const NextMeeting = () => {
    const { i18n } = useLingui();
    const { settings, meeting, userTimeZone, fontSize, spacing, borderRadius, color } = useMeetingPdfContext();

    if (meeting.nextMeetings == null || meeting.nextMeetings.length === 0) {
        return <></>;
    }

    const [nextMeeting] = meeting.nextMeetings;

    const duration = intervalToDuration({
        start: new Date(nextMeeting.start_at),
        end: new Date(nextMeeting.end_at),
    });

    const useUserTimeZone = settings.elementsToDisplay.includes('userTimeZone');
    const timeZone = useUserTimeZone ? userTimeZone : null;

    return (
        <View style={{ marginTop: spacing.extraLarge * 3 }} wrap={false}>
            <Text
                style={{
                    fontSize: fontSize.large,
                    backgroundColor: color.background.normal,
                    paddingHorizontal: spacing.medium,
                    paddingVertical: spacing.small,
                    borderTopLeftRadius: borderRadius.normal,
                    borderTopRightRadius: borderRadius.normal,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Trans>Next meeting</Trans>
            </Text>
            <View
                style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: spacing.extraLarge,
                    borderBottomLeftRadius: borderRadius.normal,
                    borderBottomRightRadius: borderRadius.normal,
                    gap: spacing.large,
                    border: `1px solid ${color.borderColor}`,
                }}
            >
                <Text style={{ fontWeight: 'bold', fontSize: fontSize.medium }}>
                    {i18n._(formatMeetingTitle(nextMeeting, i18n.locale, timeZone))}
                </Text>
                <View>
                    <Text style={{ color: color.secondaryText }}>
                        {formatMeetingDateTime(nextMeeting, i18n.locale, timeZone)}
                        {!useUserTimeZone && ` (${nextMeeting.start_at_time_zone})`} |{' '}
                        {formatDurationWithLocale(duration, i18n.locale)}
                        {nextMeeting.location != null && (
                            <>
                                <Text> | </Text>
                                <MeetingLocation meeting={nextMeeting} />
                            </>
                        )}
                    </Text>
                </View>
            </View>
        </View>
    );
};
