import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { faCircle } from '@fortawesome/pro-duotone-svg-icons';
import {
    faBallotCheck,
    faCalendarDay,
    faChartGantt,
    faCheckSquare,
    faChevronDown,
    faCircleInfo,
    faFileLines,
    faRss,
} from '@fortawesome/pro-regular-svg-icons';
import { faInbox, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { msg, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Banner, Button, EmptyState, getPathNameFromTo, useModal } from '@wedo/design-system';
import { getLocalStorageSearchParams } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { WorkspaceEditTabDropdown } from 'Pages/workspace/WorkspaceEditTabDropdown';
import { Can } from 'Shared/components/Can';
import { PageHeaderTab, TabsPageHeader } from 'Shared/components/layout/TabsPageHeader';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { WorkspaceDescriptionPopover } from 'Shared/components/workspace/WorkspaceDescriptionPopover';
import { WorkspaceDropdown } from 'Shared/components/workspace/WorkspaceDropdown';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { WorkspaceSettingsModal } from 'Shared/components/workspace/WorkspaceSettingsModal/WorkspaceSettingsModal';
import { WorkspaceStatusSelector } from 'Shared/components/workspace/WorkspaceStatusSelector';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useWorkspace } from 'Shared/hooks/useWorkspace';
import { Permission } from 'Shared/utils/rbac';
import { getWorkspaceTaskParams, WorkspaceSettingsToTab } from 'Shared/utils/workspace';

const Tabs: PageHeaderTab[] = [
    {
        to: '/overview',
        icon: faCircleInfo,
        title: msg`Overview`,
    },
    {
        to: '/meetings',
        icon: faCalendarDay,
        title: msg`Meetings`,
    },
    {
        to: '/tasks',
        icon: faCheckSquare,
        title: msg`Tasks`,
    },
    {
        to: '/gantt',
        icon: faChartGantt,
        title: msg`Gantt`,
        isNew: true,
    },
    {
        to: '/files',
        icon: faFileLines,
        title: msg`Files`,
    },
    {
        to: '/checklists',
        icon: faBallotCheck,
        title: msg`Checklists`,
    },
    {
        to: '/feed',
        icon: faRss,
        title: msg`Feed`,
        matchPathname: '/feed/*',
    },
];

export const WorkspacePage = () => {
    const navigate = useNavigate();

    const { workspaceId } = useParams();
    const { open } = useModal();

    const match = useMatch('/workspaces/:workspaceId/:currentTab/*');
    const currentTab = match?.params?.currentTab;

    const { workspace, tabs: workspaceTabs } = useWorkspace(workspaceId);
    const { isCurrentUserModerator } = useManageMembers(workspace);
    const { grouping, layout } = getWorkspaceTaskParams(camelToSnake(workspace));

    const tabs = useMemo(
        () =>
            Tabs.filter(
                ({ to }) => workspaceTabs.find(({ type }) => type === getPathNameFromTo(to)?.substring(1))?.active
            ).map((tab) => {
                if (tab.to === '/tasks') {
                    return { ...tab, to: { pathname: tab.to, searchParams: { grouping, layout } } };
                }
                return tab;
            }),
        [workspaceTabs]
    );

    usePageTitle(workspace?.name);

    useEffect(() => {
        if (!tabs?.some(({ to }) => getPathNameFromTo(to).toString().includes(currentTab))) {
            const tasksPageStoredSearchParams = getLocalStorageSearchParams(`/workspaces/${workspaceId}/tasks`);
            if ((WorkspaceSettingsToTab[workspace.settings?.default_tab] ?? 'tasks') === 'tasks') {
                navigate({
                    pathname: `/workspaces/${workspace.id}/tasks`,
                    searchParams: tasksPageStoredSearchParams,
                });
            } else {
                navigate({
                    pathname: `/workspaces/${workspace.id}/${WorkspaceSettingsToTab[workspace.settings?.default_tab]}`,
                    searchParams: {},
                });
            }
        }
    }, [currentTab, tabs]);

    const actions = (
        <div className="flex items-center gap-1">
            {isCurrentUserModerator && (
                <WorkspaceStatusSelector workspace={workspace} isReadonly={!isCurrentUserModerator} />
            )}
            <div className="hidden xl:flex xl:pr-2">
                <Button
                    variant="ghost"
                    disabled={workspace?.deleted}
                    onClick={() => {
                        open(WorkspaceSettingsModal, { workspaceId, defaultIndex: isCurrentUserModerator ? 1 : 0 });
                    }}
                    shape="circle"
                >
                    <UsersAvatarGroup
                        users={workspace?.userGroup?.members?.map((member) => member.user)}
                        size="sm"
                        maxDisplayed={3}
                        tooltipPlacement="bottom"
                    />
                </Button>
            </div>

            <Can permission={Permission.ViewWorkspaces}>
                <WorkspaceDropdown workspace={workspace} size="sm" />
            </Can>
        </div>
    );

    const title = (
        <WorkspaceDescriptionPopover
            showEditButton
            showBreadcrumb
            wrapperClassName="max-w-full"
            className="max-w-full"
            text={
                <div className="flex max-w-full items-center gap-1 truncate">
                    <WorkspaceIcon workspace={workspace} />
                    <div className={'truncate pl-1'}>{workspace?.name}</div>
                    <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3 text-gray-400" />
                </div>
            }
            workspace={workspace}
        />
    );

    if (!workspace?.meta.hasAccess && workspace) {
        return (
            <EmptyState size="lg" icon={faCircle}>
                <EmptyState.Text>
                    <Trans>
                        You do not have access to this workspace. Please request access to a workspace moderator.
                    </Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <>
            {workspace?.deleted && (
                <Banner type="danger" icon={faTrash}>
                    <Trans>{workspace?.name} workspace has been deleted</Trans>
                </Banner>
            )}

            {workspace?.archived && !workspace?.deleted && (
                <Banner type="warning" icon={faInbox}>
                    <Trans>{workspace?.name} workspace has been archived</Trans>
                </Banner>
            )}

            <TabsPageHeader
                title={title}
                actions={actions}
                baseLink={`/workspaces/${workspaceId}`}
                tabs={tabs}
                extraTab={
                    isCurrentUserModerator ? (
                        <WorkspaceEditTabDropdown workspaceId={workspaceId as string} tabs={Tabs} />
                    ) : undefined
                }
            />
        </>
    );
};
