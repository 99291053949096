import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faAt,
    faBell,
    faCalendar,
    faCode,
    faCog,
    faCreditCard,
    faEnvelopeCircleCheck,
    faFileContract,
    faFlask,
    faInputText,
    faKey,
    faPeopleGroup,
    faShieldCheck,
    faSignature,
    faSliders,
    faUser,
    faUsers,
    faListTimeline,
    faIdCard,
} from '@fortawesome/pro-regular-svg-icons';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { NavLinkOptions } from '@wedo/design-system';
import { Permission } from 'Shared/utils/rbac';

export type SettingLinkProps = NavLinkOptions & {
    icon: IconProp;
    name: MessageDescriptor;
    permission?: Permission;
    enterpriseOnly?: boolean;
    searchableName?: MessageDescriptor;
};

export const accountSettings: SettingLinkProps[] = [
    { name: msg`Profile`, to: '/profile', icon: faUser },
    { name: msg`Preferences`, to: '/preferences', icon: faSliders, searchableName: msg`My preferences` },
    { name: msg`Emails`, to: '/emails', icon: faAt },
    { name: msg`Password & 2FA`, to: '/security', icon: faKey },
    { name: msg`Notifications`, to: '/notifications', icon: faBell },
    { name: msg`Calendar sync`, to: '/ics', icon: faCalendar },
    { name: msg`Signature`, to: '/signature', icon: faSignature },
    {
        name: msg`Email to task`,
        to: '/email-to-task',
        icon: faEnvelopeCircleCheck,
        permission: Permission.AccessAllSettings,
    },
    { name: msg`API`, to: '/api', icon: faCode, permission: Permission.AccessAllSettings },
    {
        name: msg`Labs`,
        to: '/labs',
        icon: faFlask,
        permission: Permission.AccessAllSettings,
        searchableName: msg`My Labs`,
    },
];

export const organizationSettings: SettingLinkProps[] = [
    { name: msg`General`, to: '/general', icon: faCog },
    {
        name: msg`Preferences`,
        to: '/organisation-preferences',
        icon: faSliders,
        searchableName: msg`Organization preferences`,
    },
    { name: msg`Custom fields`, to: '/custom-fields', icon: faInputText },
    { name: msg`Users`, to: '/users', icon: faUsers },
    { name: msg`Teams`, to: '/teams', icon: faPeopleGroup },
    { name: msg`Billing`, to: '/billing', icon: faCreditCard },
    { name: msg`Licenses`, to: '/licenses', icon: faIdCard },
    { name: msg`Authentication`, to: '/auth', icon: faShieldCheck },
    { name: msg`Authorized emails`, to: '/authorized-emails', icon: faEnvelopeCircleCheck },
    { name: msg`Audit trail`, to: '/audit-trail', icon: faListTimeline, enterpriseOnly: true },
    {
        name: msg`Labs`,
        to: '/organization-labs',
        icon: faFlask,
        permission: Permission.ManageNetwork,
        searchableName: msg`Organization Labs`,
    },
    { name: msg`Terms and policies`, to: '/terms', icon: faFileContract },
];
