import React, { FC, useContext } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Modal, ModalProps, Spinner, useNotification } from '@wedo/design-system';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';

const ModalTitle = ({ consentUrl, errorMessage }: { consentUrl: string; errorMessage: string | null }) => {
    if (errorMessage === 'StepUpCancel') {
        return <Trans>Signature canceled</Trans>;
    }
    if (errorMessage === 'StepUpTimeout' || errorMessage === 'timedOut') {
        return <Trans>Signature timed out</Trans>;
    }

    if (errorMessage != null) {
        return <Trans>An error occurred during the signature validation process</Trans>;
    }

    if (consentUrl === '') {
        return <Trans>Confirm your identity with MobileID</Trans>;
    }

    return (
        <div className={'flex gap-2 items-center'}>
            <img alt="" className="h-8" src={'/assets/swisscom-logo.svg'} />{' '}
            <Trans>Verify your identity with Swisscom</Trans>
        </div>
    );
};

const AisErrorMessage = ({ errorMessage }: { errorMessage: string }) => {
    switch (errorMessage) {
        case 'AuthenticationFailed':
            return <span>Authentication failed. Please check your credentials and try again.</span>;
        case 'CantServeTimely':
            return (
                <span>
                    The server is currently busy and could not process your request in time. Please try again later.
                </span>
            );
        case 'InsufficientData':
            return <span>The request was incomplete. Please provide all required information and try again.</span>;
        case 'ServiceInactive':
            return <span>The requested service is currently inactive. Please try again later or contact support.</span>;
        case 'SignatureError':
            return <span>An error occurred while generating the signature. Please try again.</span>;
        case 'SerialNumberMismatch':
            return <span>The serial number provided did not match the one associated with your mobile number.</span>;
        case 'ServiceError':
            return <span>A service error occurred during the step-up authentication. Please try again later.</span>;
        case 'StepUpStatusError':
            return (
                <span>An unknown status code was returned during the step-up authentication. Please try again.</span>
            );
        case 'StepUpTimeout':
            return (
                <span>The transaction expired before the step-up authorization was completed. Please try again.</span>
            );
        case 'timedOut':
            return <span>The signature request has timed out.</span>;
        case 'StepUpCancel':
            return (
                <>
                    <div>The signature request has been canceled.</div>
                    <div>Please try again.</div>
                </>
            );
        case 'TimestampError':
            return <span>An error occurred while generating the timestamp. Please try again.</span>;
        case 'UnexpectedData':
            return <span>The request contains unexpected data. Please review your input and try again.</span>;
        case 'UnknownCustomer':
            return <span>The specified customer is unknown. Please verify the customer details and try again.</span>;
        case 'UnknownServiceEntity':
            return <span>The service entity could not be found. Please verify your details and try again.</span>;
        case 'UnsupportedDigestAlgorithm':
            return (
                <span>
                    The request contains a document hashed with unsupported or weak digest algorithms. Please use a
                    supported algorithm.
                </span>
            );
        case 'UnsupportedProfile':
            return <span>The selected profile is not supported. Please choose a different profile and try again.</span>;
        case 'GeneralError':
            return <span>A general internal error occurred. Please try again later.</span>;
        case 'SubsystemTransportError':
            return <span>A subsystem transport error occurred during the step-up process. Please try again.</span>;
        default:
            return (
                <span>
                    <Trans>An unknown error occurred.</Trans>
                </span>
            );
    }
};

const ModalBody = ({ consentUrl, errorMessage }: { consentUrl: string; errorMessage: string | null }) => {
    const { show: showNotification } = useNotification();
    const handleCopyErrorToClipboard = async () => {
        await navigator.clipboard.writeText(errorMessage);
        showNotification({ type: 'success', title: t`Error copied to clipboard` });
    };
    if (errorMessage != null) {
        return (
            <div className="flex flex-col gap-4 items-center">
                <img alt="" className="h-56" src={'/assets/signature-error.svg'} />
                <div className="text-center text-sm max-w-md flex flex-col gap-1">
                    {errorMessage !== 'StepUpCancel' && (
                        <div>
                            <Trans>Error message:</Trans>
                        </div>
                    )}
                    <div className={clsx(errorMessage !== 'StepUpCancel' && 'font-mono bg-gray-100 p-2 py-4')}>
                        <AisErrorMessage errorMessage={errorMessage} />
                    </div>
                    {navigator.clipboard != null && errorMessage !== 'StepUpCancel' && (
                        <Button onClick={handleCopyErrorToClipboard}>
                            <Trans>Copy error to clipboard</Trans>
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    if (consentUrl === '') {
        return (
            <div className="flex flex-col gap-4 items-center">
                <div className="relative">
                    <img alt="" className="h-56" src={'/assets/signature-confirm-mobile.svg'} />
                    <Spinner className="absolute h-12 w-12 top-[105px] left-[26px]" />
                </div>
                <div className="text-center text-sm max-w-md flex flex-col gap-1">
                    <span>
                        <Trans>Please approve the request on your MobileID app.</Trans>
                    </span>
                    <span>
                        <Trans>
                            If you haven't got any notification on your smartphone please click on "Cancel" and try
                            again.
                        </Trans>
                    </span>
                </div>
            </div>
        );
    }

    return (
        <iframe
            className="w-full h-[36rem]"
            src={consentUrl + '&progressBarLineColor=DodgerBlue&backgroundColor=white'}
            title={'Swisscom consent modal'}
        />
    );
};

type SignModalProps = {
    consentUrl: string;
} & ModalProps;

export const SignModal: FC<SignModalProps> = ({ ...modalProps }) => {
    const { consentUrl, errorMessage } = useContext(SignatureModalContext);

    return (
        <Modal {...modalProps}>
            <Modal.Header title={<ModalTitle consentUrl={consentUrl} errorMessage={errorMessage} />}></Modal.Header>
            <Modal.Body>
                <ModalBody consentUrl={consentUrl} errorMessage={errorMessage} />
            </Modal.Body>
            {consentUrl === '' && (
                <Modal.Footer>
                    <Button onClick={() => modalProps.close()}>
                        {errorMessage != null ? <Trans>Close</Trans> : <Trans>Cancel</Trans>}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};
