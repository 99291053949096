import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import {
    Button,
    Card,
    EditImageModal,
    getCroppedImgBlob,
    Input,
    Label,
    PixelCrop,
    RadioGroup,
    Switch,
    UnexpectedErrorNotification,
    UploadButton,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { customFetch, fileTypeToImageExtension, withAuth, withFormDataBody, withMethod, withUrl } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { PageNotFoundErrorCard } from 'Pages/AppPage/RoutingError';
import { ConfirmPhoneNumberModal } from 'Pages/SignDocumentPage/ConfirmPhoneNumberModal';
import { UserSignatureCanvas } from 'Pages/settings/signature/UserSignatureCanvas';
import { AssuranceLevelCardContent } from 'Shared/components/signature/AssuranceLevelCardContent';
import {
    SignatoryInformationForm,
    SignatoryInformationFormHandle,
} from 'Shared/components/signature/SignatoryInformationForm';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { trpc } from 'Shared/trpc';
import { SignatureCertificate, SignatureType, SignatureVisual } from 'Shared/types/signature';
import { DevFeature } from 'Shared/types/user';
import { defaultSignatureVisual, defaultValuesToSignatureSettings } from 'Shared/utils/signature';

type AssuranceLevels = { esiga: SignatureType; eidas: SignatureType };

export const SignatureSettingsPage = () => {
    const utils = trpc.useContext();

    const { replace: replaceNotification, show: showNotification } = useNotification();
    const { currentUser } = useCurrentUserContext();
    const signatoryForm = useRef<SignatoryInformationFormHandle>();

    const [assuranceLevels, setAssuranceLevels] = useState<AssuranceLevels>();
    const [isSaving, setIsSaving] = useState(false);
    const [newVisual, setNewVisual] = useState<SignatureVisual>(defaultSignatureVisual);
    const [isUpdatingDate, setIsUpdatingDate] = useState(false);
    const [isUpdatingQrCode, setIsUpdatingQrCode] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [phoneError, setPhoneError] = useState<String>(null);

    const { data: signatureSettings } = trpc.user.getSettingsByKey.useQuery('signature');
    const { mutate: getAssuranceLevel } = trpc.user.getAssuranceLevel.useMutation({
        onSuccess: (data: AssuranceLevels) => {
            setAssuranceLevels(data);
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });
    const { mutate: updateSettings } = trpc.user.updateSettingsByKey.useMutation({
        onSuccess: () => {
            void utils.user.getSettingsByKey.invalidate('signature');
            replaceNotification('success-signature', {
                title: t`Your changes have been saved`,
                type: 'success',
                id: 'success-signature',
            });
            setIsUpdatingDate(false);
            setIsUpdatingQrCode(false);
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
            setIsUpdatingDate(false);
            setIsUpdatingQrCode(false);
        },
    });
    const { mutateAsync: sendVerificationCodeAsync } = trpc.signature.sendPhoneVerificationCode.useMutation({
        onError: (e: { shape: { message: string } }) => {
            if (e.shape?.message === 'landline') {
                // it needs to be a new string so the operator "===" understands it's a new error,
                // even though it contains the same message
                setPhoneError(new String(t`A landline number can't receive SMS`));
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        },
    });
    const { network: currentNetwork } = useCurrentNetwork();
    const { open: openModal } = useModal();
    const hasSignatureFeature = useHasFeature(currentUser, currentNetwork, DevFeature.Signature);

    const updateUserSignature = useMutation({
        mutationFn: async (formData: FormData) => {
            const res = await customFetch(
                withMethod('POST'),
                withUrl('/rpc/user.signature'),
                withFormDataBody(formData),
                withAuth
            );
            if (res.ok) {
                void utils.user.getSettingsByKey.invalidate('signature');
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        },
    });

    const setVisual = (key: keyof SignatureVisual, value: SignatureVisual[keyof SignatureVisual]) => {
        if (key === 'showDate') {
            setIsUpdatingDate(true);
        } else if (key === 'showQRCode') {
            setIsUpdatingQrCode(true);
        }
        setNewVisual((settings) => {
            const updatedVisual = structuredClone(settings);
            // @ts-ignore key should exist in updatedVisual
            updatedVisual[key] = value;
            return updatedVisual;
        });
    };

    const handleFileSelect = async (file: File) => {
        const formData = new FormData();

        const handleUploadImage = async (file: File, crop: PixelCrop) => {
            const croppedImage: Blob = await getCroppedImgBlob(file, crop);
            formData.append(
                'file',
                new File([croppedImage], `logo.${fileTypeToImageExtension(file.type)}`, { type: file.type })
            );
            updateUserSignature.mutate(formData);
        };

        if (!file.type.startsWith('image/')) {
            showNotification({
                title: t`Incorrect file format`,
                message: t`Please make sure to upload an image file.`,
                type: 'danger',
            });
            return;
        }

        if (file.type !== 'image/svg+xml') {
            openModal(EditImageModal, {
                title: t`Crop the signature to your preference`,
                image: file,
                showCroppedImageSize: true,
                minHeight: 45,
                minWidth: 100,
                onEdit: (crop: PixelCrop) => handleUploadImage(file, crop),
                aspect: 10 / 3,
            });
        } else {
            formData.append('file', file);
            updateUserSignature.mutate(formData);
        }
    };

    const onSignatoryFormDone = async (signatoryInformation: SignatureCertificate) => {
        if (signatoryInformation != null) {
            const res = await sendVerificationCodeAsync(signatoryInformation.phone);

            if (res === true) {
                openModal(ConfirmPhoneNumberModal, {
                    phoneNumber: signatoryInformation.phone,
                    onDone: () => {
                        setIsSaving(false);
                    },
                });
            } else {
                setIsSaving(false);
            }
        } else {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (signatureSettings != null && !isEqual(signatureSettings, newVisual)) {
            setNewVisual(signatureSettings);
        }

        if (signatureSettings?.phone != null) {
            void getAssuranceLevel();
        }
    }, [signatureSettings]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (newVisual != null && !isEqual(newVisual, signatureSettings ?? defaultSignatureVisual)) {
                updateSettings({ key: 'signature', value: newVisual });
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [newVisual]);

    if (!hasSignatureFeature) {
        return <PageNotFoundErrorCard />;
    }

    return (
        <div className="flex flex-col gap-8 pb-10">
            <Card>
                <Card.Header title={t`Electronic signature identity`} />
                <Card.Body>
                    <SignatoryInformationForm
                        ref={signatoryForm}
                        setHasChanged={setHasChanged}
                        onDone={onSignatoryFormDone}
                        phoneError={phoneError}
                    />

                    {signatureSettings?.phone != null && (
                        <>
                            <div className="text-sm text-gray-700 mt-6 font-medium mb-2">
                                <Trans>You can sign with the following standards:</Trans>
                            </div>
                            <AssuranceLevelCardContent assuranceLevels={assuranceLevels} />
                        </>
                    )}
                </Card.Body>
                <Card.Footer className="text-right">
                    <div className="text-sm text-blue-500 flex gap-2 justify-start items-center">
                        <Button
                            loading={isSaving}
                            disabled={!hasChanged}
                            onClick={() => {
                                setIsSaving(true);
                                signatoryForm.current?.save();
                            }}
                            color="primary"
                        >
                            {t`Save`}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Header title={t`Configure your visual signature`} />
                <Card.Body className={'flex flex-row flex-wrap gap-2 justify-between'}>
                    <div className={'mb-4 max-w-sm'}>
                        {newVisual?.handwrittenSignatureUrl == null && (
                            <Label className={'mb-4'}>
                                <div className={'mb-1'}>{t`Name`}</div>
                                <Input
                                    className={'max-w-sm'}
                                    disabled={newVisual?.handwrittenSignatureUrl != null}
                                    debounce
                                    type="text"
                                    placeholder={currentUser?.full_name}
                                    onChange={(e) => setVisual('name', e.target.value)}
                                    value={newVisual?.name !== '' ? newVisual?.name : currentUser?.full_name}
                                />
                            </Label>
                        )}
                        <Label className={'mb-4'}>
                            <div className={'mb-1'}>{t`Optional line`}</div>
                            <Input
                                maxLength={30}
                                debounce
                                className={'max-w-sm'}
                                type="text"
                                value={newVisual?.optionalLine}
                                onChange={(e) => setVisual('optionalLine', e.target.value)}
                            />
                        </Label>
                        <div className={'mb-4'}>
                            <Label className={'mb-1 text-sm'}>
                                <Trans>Upload your signature as an image</Trans>
                            </Label>
                            <div className="flex gap-4">
                                <div
                                    className={clsx(
                                        'flex shrink-0 h-28 w-28 items-center justify-center rounded-md border border-gray-200 p-3'
                                    )}
                                >
                                    {newVisual?.handwrittenSignatureUrl != null ? (
                                        <img
                                            className="object-contain h-full"
                                            alt=""
                                            src={newVisual?.handwrittenSignatureUrl}
                                        />
                                    ) : (
                                        <img
                                            className="object-contain h-full"
                                            alt=""
                                            src={'/assets/placeholder-signature.png'}
                                        />
                                    )}
                                </div>

                                <div className="flex flex-col gap-2 lg:flex-col xl:justify-between xl:gap-0">
                                    <UploadButton
                                        className="w-full max-w-[200px]"
                                        variant="filled"
                                        accept="image/*"
                                        color={'primary'}
                                        onFileSelect={handleFileSelect}
                                    >
                                        {newVisual?.handwrittenSignatureUrl != null
                                            ? t`Replace image`
                                            : t`Upload image`}
                                    </UploadButton>

                                    <Button
                                        variant={'outlined'}
                                        disabled={newVisual?.handwrittenSignatureUrl == null}
                                        className={'w-full max-w-[200px]'}
                                        color={'danger'}
                                        onClick={() => {
                                            setVisual('handwrittenSignatureUrl', null);
                                        }}
                                    >{t`Remove image`}</Button>
                                    <div className="text-xs text-gray-700">
                                        <Trans>
                                            Maximum file size allowed is 5MB, and minimum resolution is 300x90 pixels.
                                        </Trans>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <UserSignatureCanvas
                            settings={defaultValuesToSignatureSettings(signatureSettings, currentUser)}
                            type={assuranceLevels?.esiga ?? 'SES'}
                        />
                        <Label className={'mt-4 mb-2'}>
                            <div className={'flex gap-2 items-center justify-between'}>
                                <Trans>Show date</Trans>
                                <Switch
                                    loading={isUpdatingDate}
                                    checked={newVisual?.showDate ?? false}
                                    onChange={(value) => setVisual('showDate', value)}
                                />
                            </div>
                        </Label>
                        <Label>
                            <div className={'flex gap-2 mb-2 items-center justify-between'}>
                                <Trans>Show QR code</Trans>
                                <Switch
                                    loading={isUpdatingQrCode}
                                    checked={newVisual?.showQRCode ?? false}
                                    onChange={(value) => setVisual('showQRCode', value)}
                                />
                            </div>
                        </Label>
                        {newVisual?.handwrittenSignatureUrl == null && (
                            <div className={'flex gap-2 items-center justify-between mb-2'}>
                                <Label>{t`Font`}</Label>
                                <RadioGroup
                                    name={'font-radio-group'}
                                    radioType={'buttonGroup'}
                                    value={newVisual?.font}
                                    onChange={(selectedFont: string) => setVisual('font', selectedFont)}
                                >
                                    <RadioGroup.Radio
                                        id={'font-a'}
                                        value={'Anjhay'}
                                        disabled={newVisual?.handwrittenSignatureUrl != null}
                                    >
                                        A
                                    </RadioGroup.Radio>
                                    <RadioGroup.Radio
                                        id={'font-b'}
                                        value={'Astagina Signature'}
                                        disabled={newVisual?.handwrittenSignatureUrl != null}
                                    >
                                        B
                                    </RadioGroup.Radio>
                                    <RadioGroup.Radio
                                        id={'font-c'}
                                        value={'Loftygoals'}
                                        disabled={newVisual?.handwrittenSignatureUrl != null}
                                    >
                                        C
                                    </RadioGroup.Radio>
                                </RadioGroup>
                            </div>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};
