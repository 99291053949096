import { ChangeEvent, forwardRef, ReactNode, useId } from 'react';
import clsx from 'clsx';
import { classes as checkboxClasses } from '~/components/Checkbox/Checkbox';
import { classes, DropdownItem } from '~/components/Dropdown/Dropdown';
import { Spinner } from '~/components/Spinner/Spinner';

export type DropdownCheckboxProps = {
    children: ReactNode;
    checked: boolean;
    onChange: () => void;
    onCheckboxChange?: (e: ChangeEvent) => void; // Optional callback when you want to differentiate when clicking the checkbox vs the container
    loading?: boolean;
    disabled?: boolean;
    isCheckboxDisabled?: boolean; // Disable only the checkbox (as it can have a different click event)
    active?: boolean;
};

export const DropdownCheckbox = forwardRef<HTMLButtonElement, DropdownCheckboxProps>(
    ({ children, active, checked, onChange, onCheckboxChange, loading, disabled, isCheckboxDisabled }, ref) => {
        const id = useId();
        return (
            <DropdownItem
                keepOpen
                active={active}
                ref={ref}
                disabled={disabled || loading}
                onClick={onCheckboxChange ? onChange : null}
            >
                {!loading && (
                    <input
                        id={id}
                        type={'checkbox'}
                        onChange={onCheckboxChange ? onCheckboxChange : onChange}
                        checked={checked}
                        disabled={isCheckboxDisabled || disabled}
                        className={clsx(
                            'inset-0',
                            checkboxClasses.base,
                            (isCheckboxDisabled || disabled) && checkboxClasses.disabled
                        )}
                        readOnly
                    />
                )}
                {loading && <Spinner className={clsx('h-4 w-4 animate-spin', loading && classes.item.loading)} />}
                <label htmlFor={id} className={'flex w-full cursor-pointer items-center gap-3'}>
                    {children}
                </label>
            </DropdownItem>
        );
    }
);
