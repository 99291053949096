import React from 'react';
import { useSelected, useSlateStatic } from 'slate-react';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import { t } from '@lingui/macro';
import { Editor, Element, Range, Text as SlateText, Transforms } from 'slate';
import { Plugin, useEditorIsFocused } from '../Editor';
import { is } from '../utils/node';

const UnwrappableTextualElements = ['text', 'task', 'attachment', 'image', 'vote', 'link'];

// FIXME Use line instead of text when all customer will be migrated
export const Line = 'text';

export const isEmpty = (element: Element) => element.children.length === 1 && element.children[0].text === '';

export const isLine = is(Line);

export const selectedLine = (editor: Editor) => Editor.above(editor, { match: is(Line) });

type LineElementProps = RenderElementProps & {
    placeholder: string;
};

const LineElement = ({ placeholder, element, children, attributes }: LineElementProps) => {
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useEditorIsFocused();

    return (
        <div style={{ textAlign: element.align }} {...attributes}>
            {selected && focused && isEmpty(element) && Range.isCollapsed(editor.selection) && (
                <div contentEditable={false} className="pointer-events-none absolute select-none opacity-40">
                    {placeholder}
                </div>
            )}
            {children}
        </div>
    );
};

export const linePlugin = (placeholder: string = t`Type '/' for commands`): Plugin => ({
    normalizeNode: (editor, [node, path]) => {
        if (
            node?.type != null &&
            !UnwrappableTextualElements.includes(node.type) &&
            node.children.length === 1 &&
            SlateText.isText(node.children[0])
        ) {
            Transforms.wrapNodes(editor, { type: Line }, { at: [...path, 0] });
        } else if (Element.isElement(node) && node.children.length === 0) {
            Transforms.insertNodes(
                editor,
                { type: Line, children: [{ text: '' }] },
                {
                    at: path.concat(0),
                    voids: true,
                }
            );
            return true;
        } else if (node?.type === Line && node.children.length === 1 && node.children[0].type === Line) {
            Transforms.unwrapNodes(editor, { at: path, match: isLine });
            return true;
        } else if (SlateText.isText(node)) {
            const [parentNode, parentPath] = Editor.above(editor, { at: path, mode: 'lowest' });
            if (!isLine(parentNode) && parentNode.type !== 'link') {
                Transforms.wrapNodes(editor, { type: Line }, { at: parentPath, mode: 'all', match: SlateText.isText });
            }
        }

        return false;
    },
    renderElement: (editor, { element, children, ...props }) =>
        element.type === Line && (
            <LineElement placeholder={placeholder} element={element} {...props}>
                {children}
            </LineElement>
        ),
});
