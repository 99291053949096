import { ClientRect, CollisionDescriptor, CollisionDetection } from '@dnd-kit/core';
import { Coordinates } from '@dnd-kit/core/dist/types';

type Data = {
    data: {
        value: number;
    };
};

export const distanceBetween = (p1: Coordinates, p2: Coordinates) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

/**
 * Sort collisions from smallest to greatest value
 */
export const sortCollisionsAsc = ({ data: { value: a } }: Data, { data: { value: b } }: Data) => a - b;

/**
 * Returns the coordinates of the center of a given ClientRect
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const centerOfRectangle = (rect: ClientRect): Coordinates => ({
    x: 0,
    y: rect.top + rect.height * 0.5,
});

/**
 * Returns the closest rectangles from an array of rectangles to the center of a given
 * rectangle.
 */
export const closestY: CollisionDetection = ({ collisionRect, droppableContainers }) => {
    const centerRect = centerOfRectangle(collisionRect);
    const collisions: CollisionDescriptor[] = [];

    for (const droppableContainer of droppableContainers) {
        const {
            id,
            rect: { current: rect },
        } = droppableContainer;

        if (rect) {
            const distBetween = distanceBetween(centerOfRectangle(rect), centerRect);
            collisions.push({ id, data: { droppableContainer, value: distBetween } });
        }
    }

    return collisions.sort(sortCollisionsAsc);
};

export const closestTop: CollisionDetection = ({ collisionRect, droppableRects, droppableContainers }) => {
    const collisions = [];

    for (const droppableContainer of droppableContainers) {
        const { id } = droppableContainer;
        const rect = droppableRects.get(id);

        if (rect != null) {
            collisions.push({ id, data: { droppableContainer, value: Math.abs(rect.top - collisionRect.top) } });
        }
    }

    return collisions.sort(sortCollisionsAsc);
};
