import { createPortal } from 'react-dom';
import { Plural } from '@lingui/macro';
import clsx from 'clsx';
import { useStore } from 'zustand';
import { Comment } from './Comment';
import { type Store } from './store';
import { type Comment as CommentType } from './types';

type ExtractState<S> = S extends {
    getState: () => infer T;
}
    ? T
    : never;

const isThreadDragged = (state: ExtractState<Store>, meetingBlockId: string) => {
    return state.activeMeetingBlockId == null
        ? state.activeMeetingTopicId === meetingBlockId
        : state.activeMeetingBlockId === meetingBlockId;
};

type CommentPortalProps = {
    store: Store;
    meetingBlockId: string;
    comments: CommentType[];
    isStatic: boolean;
};

const Thread = ({ store, meetingBlockId, comments, isStatic }: CommentPortalProps) => {
    const isCollapsed = useStore(store, ({ collapsedIds }) => collapsedIds?.includes(meetingBlockId));
    const isDragging = useStore(store, (state) => isThreadDragged(state, meetingBlockId));

    const handleOpen = () => {
        store.setState(({ collapsedIds }) => ({
            collapsedIds: collapsedIds?.filter((id) => id !== meetingBlockId),
        }));
    };

    const handleMouseEnter = () => {
        store.setState({ highlightedMeetingBlockId: meetingBlockId });
    };

    const handleMouseLeave = () => {
        store.setState({ highlightedMeetingBlockId: null });
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={clsx(isDragging && 'opacity-50')}
        >
            {isCollapsed ? (
                <div className="border-b border-yellow-300 flex justify-center relative mt-4 mb-2">
                    <button
                        className="absolute -top-[0.625rem] text-xs flex items-center text-yellow-700 bg-yellow-100 px-2 py-0.5 rounded"
                        onClick={handleOpen}
                    >
                        <Plural
                            value={comments.length}
                            one="1 private comment"
                            other={`${comments.length} private comments`}
                        />
                    </button>
                </div>
            ) : (
                <div className="flex flex-col">
                    {comments?.map((comment, index) => (
                        <Comment
                            key={comment.id}
                            store={store}
                            comment={comment}
                            isStatic={isStatic}
                            isFirst={index === 0}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export const ThreadPortal = ({ store, meetingBlockId, comments, isStatic }: CommentPortalProps) => {
    const target = useStore(store, ({ blockPlaceholders }) => blockPlaceholders.get(meetingBlockId));
    const element = <Thread store={store} meetingBlockId={meetingBlockId} comments={comments} isStatic={isStatic} />;
    return target == null ? element : createPortal(element, target);
};
