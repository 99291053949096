import { CheckboxTree } from '@wedo/design-system';

export const flattenTopicsTree = (topicsTree: CheckboxTree) => {
    const flatten = (tree: CheckboxTree, result: Array<string>) => {
        return (
            tree.children?.reduce((result, item) => {
                return item.checked === null || item.checked
                    ? flatten(item, result.concat(item.id))
                    : flatten(item, result);
            }, result) ?? result
        );
    };
    return flatten(topicsTree, []);
};
