import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Id } from '@wedo/types';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { TableOfContentsSection } from './TocSection';
import { TableOfContentsTopic } from './TocTopic';
import { TreeNodeModel } from './utils';

interface TreeNodeProps {
    node: TreeNodeModel;
    childCount?: number;
    depth: number;
    isOpen: boolean;
    overrideDisplayId?: string;
    indentationWidth: number;
    isError: boolean;
    latestAddedSection: Id;
    latestAddedTopic: Id;
    showDuration: boolean;
    onTopicClick: (MeetingTopic: MeetingTopic) => void;
    onSectionChange: (sectionId: Id, changes: Partial<MeetingSection>) => void;
    onTopicChange: (topicId: Id, changes: Partial<MeetingTopic>) => void;
    onAddTopic: (section: MeetingSection) => void;
    onAddSectionInside: (section: MeetingSection) => void;
    onAddSectionAfter: (section: MeetingSection) => void;
    onDeleteSection: (section: MeetingSection) => void;

    onCollapse?(id: string): void;

    handleTopicRef: (topicId: Id) => (element: HTMLDivElement) => void;
    handleSectionRef: (sectionId: Id) => (element: HTMLDivElement) => void;
}

export const TreeNode = ({
    node,
    indentationWidth,
    overrideDisplayId,
    childCount,
    isOpen = false,
    isError = false,
    onCollapse,
    depth,
    latestAddedSection,
    latestAddedTopic,
    showDuration = false,
    onTopicClick,
    onSectionChange,
    onTopicChange,
    onAddTopic,
    onAddSectionInside,
    onAddSectionAfter,
    onDeleteSection,
    handleTopicRef,
    handleSectionRef,
}: TreeNodeProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: node.id,
        disabled: !node.canDrag,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        marginLeft: depth * indentationWidth,
    };

    return (
        <div key={node.id} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {node.isSection ? (
                <TableOfContentsSection
                    key={node.id}
                    section={node.item as MeetingSection}
                    displayId={overrideDisplayId || node.display_id}
                    onChange={onSectionChange}
                    onDelete={onDeleteSection}
                    isOpen={isOpen}
                    isError={isError}
                    isDragging={isDragging}
                    onClick={(id) => onCollapse?.(id.toString())}
                    onAddTopic={onAddTopic}
                    onAddSectionInside={onAddSectionInside}
                    onAddSectionAfter={onAddSectionAfter}
                    canAddAfter={node.canAddAfter}
                    lastAddedTocSection={latestAddedSection}
                    handleSectionRef={handleSectionRef}
                />
            ) : (
                <TableOfContentsTopic
                    key={node.id}
                    topic={node.item as MeetingTopic}
                    displayId={overrideDisplayId || node.display_id}
                    isError={isError}
                    isDragging={isDragging}
                    onTopicClick={onTopicClick}
                    onChange={onTopicChange}
                    lastAddedTopic={latestAddedTopic}
                    canRename={node.canRename}
                    handleTopicRef={handleTopicRef}
                    showDuration={showDuration}
                />
            )}
            {childCount > 0 && (
                <div
                    className={
                        'absolute -right-1 -top-0.5 flex h-6 w-6 items-center justify-center rounded-[50%] border bg-blue-500 text-center font-bold text-white'
                    }
                >
                    {childCount}
                </div>
            )}
        </div>
    );
};
