import { useGetCurrentNetworkQuery } from 'Shared/services/network';
import { Network } from 'Shared/types/network';

export const isGlobal = (network: Network) => network?.short_name === 'login';

export const useCurrentNetwork = () => {
    const { data: network, error } = useGetCurrentNetworkQuery();

    return {
        network,
        error,
        isScimStrictMode: network?.scim_strict_mode,
        isSamlEnabled: network?.isSaml,
        isGlobal: isGlobal(network),
    };
};
