// See: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
import { useRef } from 'react';

export const useFocus = (placeCursorAtEnd?: boolean): [any, () => void] => {
    const htmlElRef = useRef(null);

    const setFocus = () => {
        if (htmlElRef?.current && 'focus' in htmlElRef.current) {
            htmlElRef.current.focus();
        }
        if (placeCursorAtEnd) {
            const length = htmlElRef?.current?.value?.length;
            htmlElRef.current?.setSelectionRange?.(length, length);
        }
    };

    return [htmlElRef, setFocus];
};
