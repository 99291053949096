import { FC } from 'react';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { EmptyState, Skeleton, Tabs, Timeline } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { AddEntityToFavoriteTimeLineItem } from 'Shared/components/history/AddEntityToFavoriteTimeLineItem';
import { AddUserToEntityTimelineItem } from 'Shared/components/history/AddUserToEntityTimelineItem';
import { AddedCommentTimeLineItem } from 'Shared/components/history/AddedCommentTimeLineItem';
import { ArchivedEntityTimeLineItem } from 'Shared/components/history/ArchivedEntityTimeLineItem';
import { ChangedDescriptionTimeLineItem } from 'Shared/components/history/ChangedDescriptionTimeLineItem';
import { ChangedNameTimeLineItem } from 'Shared/components/history/ChangedNameTimeLineItem';
import { CreatedEntityTimeLineItem } from 'Shared/components/history/CreatedEntityTimeLineItem';
import { DeletedEntityTimeLineItem } from 'Shared/components/history/DeletedEntityTimeLineItem';
import { PromotedMemberToModeratorTimeLineItem } from 'Shared/components/history/PromotedMemberToModeratorTimeLineItem';
import { RefuseMembershipTimeLineItem } from 'Shared/components/history/RefuseMembershipTimeLineItem';
import { RemoveFromFavoriteTimeLineItem } from 'Shared/components/history/RemoveFromFavoriteTimeLineItem';
import { RemoveUserFromEntityTimelineItem } from 'Shared/components/history/RemoveUserFromEntityTimelineItem';
import { RequestedMembershipTimeLineItem } from 'Shared/components/history/RequestedMembershipTimeLineItem';
import { RestoreEntityTimeLineItem } from 'Shared/components/history/RestoreEntityTimeLineItem';
import { RevokedMemberModeratorTimeLineItem } from 'Shared/components/history/RevokedMemberModeratorTimeLineItem';
import { UnarchiveEntityTimeLineItem } from 'Shared/components/history/UnarchiveEntityTimeLineItem';
import { UpdateCommentTimeLineItem } from 'Shared/components/history/UpdateCommentTimeLineItem';
import { useGetWorkspaceActivitiesQuery, useGetWorkspaceQuery } from 'Shared/services/workspace';
import { WorkspaceHistory } from 'Shared/types/workspace';
import { AddedAttachmentTimeLineItem } from './components/AddedAttachmentTimeLineItem';
import { AddedTagStatusTimeLineItem } from './components/AddedTagStatusTimeLineItem';
import { AddedWorkspaceSectionTimeLineItem } from './components/AddedWorkspaceSectionTimeLineItem';
import { ChangeWorkspaceSettingsTimelineItem } from './components/ChangeWorkspaceSettingsTimelineItem';
import { ChangedColorTimeLineItem } from './components/ChangedColorTimeLineItem';
import { ChangedDefaultGroupByTimeLineItem } from './components/ChangedDefaultGroupByTimeLineItem';
import { ChangedDefaultViewTimeLineItem } from './components/ChangedDefaultViewTimeLineItem';
import { DeletedCommentTag } from './components/DeletedCommentTag';
import { DuplicatedWorkspaceTimeLineItem } from './components/DuplicatedWorkspaceTimeLineItem';
import { RemoveTagTimeLineItem } from './components/RemoveTagTimeLineItem';
import { RemovedStatusTimeLineItem } from './components/RemovedStatusTimeLineItem';
import { SubscribeToWorkspaceTimeLineItem } from './components/SubscribeToWorkspaceTimeLineItem';
import { UnsubscribeFromWorkspaceTimeLineItem } from './components/UnsubscribeFromWorkspaceTimeLineItem';
import { UpdatedSectionTimeLineItem } from './components/UpdatedSectionTimeLineItem';
import { UpdatedStatusTimeLineItem } from './components/UpdatedStatusTimeLineItem';

const ActivityItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    switch (activity.event_key) {
        case 'deleted_comment_tag':
            return <DeletedCommentTag activity={activity} />;
        case 'changed_tag_color':
            return <ChangedColorTimeLineItem activity={activity} />;
        case 'changed_tag_name':
        case 'renamed_tag':
            return <ChangedNameTimeLineItem activity={activity} entityType="workspace" />;
        case 'changed_tag_settings':
            return <ChangeWorkspaceSettingsTimelineItem activity={activity} />;
        case 'changed_tag_description':
            return <ChangedDescriptionTimeLineItem activity={activity} entityType="workspace" />;
        case 'changed_tag_default_view':
            return <ChangedDefaultViewTimeLineItem activity={activity} />;
        case 'changed_tag_default_group_by':
            return <ChangedDefaultGroupByTimeLineItem activity={activity} />;
        case 'added_member_to_tag':
            return <AddUserToEntityTimelineItem activity={activity} entityType="workspace" />;
        case 'removed_member_from_tag':
            return <RemoveUserFromEntityTimelineItem activity={activity} entityType="workspace" />;
        case 'promoted_tag_member':
            return <PromotedMemberToModeratorTimeLineItem activity={activity} entityType="workspace" />;
        case 'revoked_tag_member':
            return <RevokedMemberModeratorTimeLineItem activity={activity} entityType="workspace" />;
        case 'created_tag':
            return <CreatedEntityTimeLineItem activity={activity} entityType="workspace" />;
        case 'duplicated_tag':
            return <DuplicatedWorkspaceTimeLineItem activity={activity} />;
        case 'added_tag_status':
            return <AddedTagStatusTimeLineItem activity={activity} />;
        case 'updated_tag_status':
            return <UpdatedStatusTimeLineItem activity={activity} />;
        case 'removed_tag_status':
            return <RemovedStatusTimeLineItem activity={activity} />;
        case 'commented_tag':
            return <AddedCommentTimeLineItem activity={activity} entityType="workspace" />;
        case 'updated_comment_tag':
            return <UpdateCommentTimeLineItem activity={activity} entityType="workspace" />;
        case 'archived_tag':
            return <ArchivedEntityTimeLineItem activity={activity} entityType="workspace" />;
        case 'unarchived_tag':
            return <UnarchiveEntityTimeLineItem activity={activity} entityType="workspace" />;
        case 'deleted_tag':
            return <DeletedEntityTimeLineItem activity={activity} entityType="workspace" />;
        case 'restored_tag':
            return <RestoreEntityTimeLineItem activity={activity} entityType="workspace" />;
        case 'added_watcher_to_tag':
            return <SubscribeToWorkspaceTimeLineItem activity={activity} />;
        case 'removed_watcher_from_tag':
            return <UnsubscribeFromWorkspaceTimeLineItem activity={activity} />;
        case 'requested_tag_membership':
            return <RequestedMembershipTimeLineItem activity={activity} entityType="workspace" />;
        case 'refused_tag_membership':
            return <RefuseMembershipTimeLineItem activity={activity} entityType="workspace" />;
        case 'added_section_to_tag':
            return <AddedWorkspaceSectionTimeLineItem activity={activity} />;
        case 'removed_tag_section':
            return <RemoveTagTimeLineItem activity={activity} />;
        case 'updated_tag_section':
            return <UpdatedSectionTimeLineItem activity={activity} />;
        case 'added_tag_attachment':
            return <AddedAttachmentTimeLineItem activity={activity} />;
        case 'added_tag_to_favorites':
            return <AddEntityToFavoriteTimeLineItem activity={activity} entityType="workspace" />;
        case 'removed_tag_from_favorites':
            return <RemoveFromFavoriteTimeLineItem activity={activity} entityType="workspace" />;
        default:
            return null;
    }
};

export const HistoryPanel: FC<{ workspaceId: Id; tabKey: string }> = ({ workspaceId, tabKey }) => {
    const { data: history, isLoading } = useGetWorkspaceActivitiesQuery(workspaceId, {
        skip: workspaceId == null || tabKey !== 'history',
    });
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, {
        skip: workspaceId == null || tabKey !== 'history',
    });

    return (
        <Tabs.Panel>
            {isLoading && (
                <div className="flex flex-col gap-2">
                    <Skeleton count={9} className="h-6" />
                </div>
            )}

            {isEmpty(history) && !isLoading && (
                <EmptyState icon={faClockRotateLeft}>
                    <EmptyState.Text>
                        <Trans>No history for the {workspace?.name} workspace</Trans>
                    </EmptyState.Text>
                </EmptyState>
            )}

            {!isEmpty(history) && (
                <Timeline className="mb-6">
                    {history?.filter(Boolean).map((activity) => <ActivityItem key={activity.id} activity={activity} />)}
                </Timeline>
            )}
        </Tabs.Panel>
    );
};
