import { useId, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { Descendant, Editor as SlateEditor } from 'slate';
import { Editor, focusEditor } from 'Shared/components/editor/Editor';
import { useFocusedCommentIdStore } from 'Shared/components/editor/plugins/commentPlugin/commentPlugin';
import { Comment } from 'Shared/components/editor/plugins/commentPlugin/types';
import { copyPastePlugin } from 'Shared/components/editor/plugins/copyPastePlugin/copyPastePlugin';
import { deserializeHtml } from 'Shared/components/editor/plugins/copyPastePlugin/deserializeHtml';
import { serializeBlocks } from 'Shared/components/editor/plugins/copyPastePlugin/serializeBlocks';
import { keyboardPlugin } from 'Shared/components/editor/plugins/keyboardPlugin';
import { linePlugin } from 'Shared/components/editor/plugins/linePlugin';
import { linkPlugin } from 'Shared/components/editor/plugins/linkPlugin';
import { listPlugin } from 'Shared/components/editor/plugins/listPlugin';
import { onChangePlugin } from 'Shared/components/editor/plugins/onChangePlugin';
import { onInitializePlugin } from 'Shared/components/editor/plugins/onInitializePlugin';
import { createParagraphBlock, paragraphPlugin } from 'Shared/components/editor/plugins/paragraphPlugin';
import { DividerItem, toolbarPlugin } from 'Shared/components/editor/plugins/toolbarPlugin/toolbarPlugin';

type CommentEditorProps = {
    comment: Comment;
    onChange: (children: Descendant[]) => void;
    isReadOnly: boolean;
};

export const CommentEditor = ({ comment, onChange, isReadOnly }: CommentEditorProps) => {
    const toolbarIdentifier = useId();

    const editorRef = useRef<SlateEditor>();

    const focusedCommentId = useFocusedCommentIdStore();

    const handleInitialize = (editor: SlateEditor) => {
        const localValue = localStorage.getItem(`comment-${comment.id}`);
        const children = deserializeHtml(serializeBlocks(localValue != null ? JSON.parse(localValue) : comment.value));
        editor.children = children.length === 0 ? [createParagraphBlock()] : children;
        editor.onChange();
    };

    const handleChange = (editor: SlateEditor, children: Descendant[]) => {
        onChange(children);
    };

    useLayoutEffect(() => {
        if (focusedCommentId === comment.id) {
            setTimeout(() => {
                focusEditor(editorRef.current);
                useFocusedCommentIdStore.setState(null);
            });
        }
    }, [focusedCommentId]);

    return (
        <>
            {!isReadOnly && <div id={toolbarIdentifier} className="-mx-2"></div>}
            <Editor
                editorRef={editorRef}
                plugins={[
                    linkPlugin(),
                    listPlugin(),
                    paragraphPlugin(true),
                    linePlugin(''),
                    keyboardPlugin(),
                    copyPastePlugin(),
                    toolbarPlugin(
                        ['bold', 'italic', 'underlined', 'strikethrough', DividerItem, 'list', DividerItem, 'reset'],
                        toolbarIdentifier,
                        'h-10 border-b border-gray-300 !justify-start px-2'
                    ),
                    onInitializePlugin(handleInitialize),
                    onChangePlugin(handleChange),
                ]}
                isReadOnly={isReadOnly}
                className={clsx('!py-1', !isReadOnly && 'text-black')}
            />
        </>
    );
};
