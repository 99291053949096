import React, { PropsWithChildren, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { createDefaultMeetingData, useAddMeeting } from 'Shared/components/meeting/addMeetingModal/useAddMeeting';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { Meeting } from 'Shared/types/meeting';
import { MeetingForm } from './MeetingForm';

type AddNextMeetingModalProps = {
    onDone: (meeting?: Meeting) => void;
    workspaceId?: Id;
    templateId?: Id;
} & ContextModalProps &
    PropsWithChildren;

export const AddNextMeetingModal = ({
    onDone,
    workspaceId,
    templateId,
    children,
    ...modalProps
}: AddNextMeetingModalProps): JSX.Element => {
    const [meetingData, setMeetingData] = useState(createDefaultMeetingData(workspaceId));
    const { currentData: template } = useGetMeetingQuery(buildGetMeetingParameters(templateId), {
        skip: templateId === '',
    });
    const handleDone = (meeting: Meeting) => {
        void modalProps.close();
        onDone(meeting);
    };

    const { handleSave, updateMeetingData, errorField, errorMessage, isAdding } = useAddMeeting({
        onDone: handleDone,
        workspaceId,
        templateId,
        template,
        selectedTemplateId: templateId,
        meetingData,
        setMeetingData,
    });

    return (
        <Modal size={'lg'} {...modalProps}>
            <Modal.Header title={<Trans>Add next meeting</Trans>} />
            <Modal.Body>
                <MeetingForm
                    mode={'add'}
                    template={template}
                    templateSeriesMaster={template?.seriesMaster}
                    errorField={errorField}
                    meetingData={meetingData}
                    updateMeetingData={updateMeetingData}
                    onSave={handleSave}
                />
                {errorMessage && <Alert type={'danger'} title={errorMessage} />}
            </Modal.Body>
            <Modal.Footer>
                <div className="flex justify-end gap-1">
                    <Button
                        color={'primary'}
                        loading={isAdding}
                        disabled={meetingData?.meetingUsers == null || meetingData?.meetingUsers.length === 0}
                        onClick={() => handleSave()}
                    >
                        <Trans>Add meeting</Trans>
                    </Button>
                </div>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
