import React, { FC, useMemo, useState } from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Select, SelectProps } from '@wedo/design-system';
import { SwissCantonCode } from '@wedo/types';
import { getSwissCantonName, SwissCantons } from '@wedo/utils';

type SwitzerlandCantonSelectorProps = {
    canton: SwissCantonCode;
    onChange: (canton: SwissCantonCode) => void;
    onBlur?: () => void;
    status?: SelectProps['status'];
    statusText?: string;
};

export const SwitzerlandCantonSelector: FC<SwitzerlandCantonSelectorProps> = ({
    canton,
    onChange,
    onBlur,
    status,
    statusText,
}) => {
    const [cantons, setCantons] = useState<Array<{ name: string; code: SwissCantonCode }>>([]);

    const cantonsArray = useMemo(
        () =>
            [...SwissCantons.values()]
                .map(({ name, code }) => ({ code, name: i18n._(name) }))
                .sort((a, b) => a.name.localeCompare(b.name)),
        []
    );

    const handleSearch = (search: string) => {
        if (isEmpty(search)) {
            setCantons(cantonsArray);
        } else {
            setCantons(() =>
                cantonsArray.filter(
                    (canton) =>
                        canton.name.toLowerCase().includes(search.toLowerCase()) ||
                        canton.code.toLowerCase().includes(search.toLowerCase())
                )
            );
        }
    };

    return (
        <Select
            value={canton}
            onChange={onChange}
            placeholder={t`Select canton`}
            customRenderSelected={(canton: SwissCantonCode) => `${getSwissCantonName(canton)} (${canton})`}
            onSearch={handleSearch}
            onBlur={onBlur}
            status={status}
            statusText={statusText}
        >
            {cantons.map((canton) => {
                return (
                    <Select.Option key={canton.code} value={canton.code}>
                        {canton.name} ({canton.code})
                    </Select.Option>
                );
            })}
        </Select>
    );
};
