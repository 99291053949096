import { Trans } from '@lingui/macro';

export const ListHeader = () => {
    return (
        <div className="sticky font-semibold bg-white top-0 z-10 h-[calc(100%+1px)] grid col-span-4 row-span-2 grid-cols-subgrid border-b border-gray-200">
            <div className="flex items-end p-2 border-r">#</div>
            <div className="flex items-end p-2">
                <Trans>Name</Trans>
            </div>
            <div className="flex items-end p-2 border-r" />
            <div className="flex items-end p-2">
                <Trans>Duration</Trans>
            </div>
        </div>
    );
};
