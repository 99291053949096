import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { DefaultPreferencesCard } from 'Pages/settings/preferences/DefaultPreferencesCard';
import { useGetAdminNetworkQuery, useUpdateNetworkMutation } from 'Shared/services/network';
import { DefaultPreferences } from 'Shared/types/network';

export const OrganisationPreferencesPage: FC = () => {
    const { show } = useNotification();

    const { data: adminNetwork } = useGetAdminNetworkQuery();
    const [updateNetwork] = useUpdateNetworkMutation();

    const handleSave = async (preferences: DefaultPreferences) => {
        const response = await updateNetwork({
            config: { defaultPreferences: preferences },
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <DefaultPreferencesCard
            title={t`Organization preferences`}
            preferences={adminNetwork?.config?.defaultPreferences}
            onSave={handleSave}
        />
    );
};
