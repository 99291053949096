import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBarsProgress, faCalendar, faCheck, faText } from '@fortawesome/pro-regular-svg-icons';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { ChecklistAscendingSortOptions } from 'Shared/types/checklist';

export const ChecklistSortToString: Record<ChecklistAscendingSortOptions, MessageDescriptor> = {
    'checklist.name': msg`Name`,
    tasks: msg`Tasks`,
    progression: msg`Progress`,
    reference_date: msg`Reference date`,
};

export const ChecklistSortToIcon: Record<ChecklistAscendingSortOptions, IconDefinition> = {
    'checklist.name': faText,
    tasks: faCheck,
    progression: faBarsProgress,
    reference_date: faCalendar,
};

export const ChecklistSortToAscendingItem: Record<ChecklistAscendingSortOptions, MessageDescriptor> = {
    'checklist.name': msg`A-Z`,
    tasks: msg`Ascending`,
    progression: msg`Ascending`,
    reference_date: msg`Oldest to newest`,
};

export const ChecklistSortToDescendingItem: Record<ChecklistAscendingSortOptions, MessageDescriptor> = {
    'checklist.name': msg`Z-A`,
    tasks: msg`Descending`,
    progression: msg`Descending`,
    reference_date: msg`Newest to oldest`,
};
