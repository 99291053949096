import { ReactElement } from 'react';
import {
    faCalculator,
    faCalendarDays,
    faCaretDown,
    faInputText,
    faPaperclip,
    faSquareCheck,
    IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { CustomField, CustomFieldGroup, CustomFieldType } from 'Shared/types/customField';

export const isGroup = (item: CustomField | CustomFieldGroup): item is CustomFieldGroup => !('type' in item);

export const customFieldTypeToString: Record<CustomFieldType, ReactElement> = {
    [CustomFieldType.Attachment]: <Trans>Attachment</Trans>,
    [CustomFieldType.Number]: <Trans>Number</Trans>,
    [CustomFieldType.Date]: <Trans>Date</Trans>,
    [CustomFieldType.Dropdown]: <Trans>Dropdown</Trans>,
    [CustomFieldType.MultipleChoice]: <Trans>Multiple choice</Trans>,
    [CustomFieldType.ShortText]: <Trans>Short text</Trans>,
};

export const customFieldTypeToIcon: Record<CustomFieldType, IconDefinition> = {
    [CustomFieldType.Attachment]: faPaperclip,
    [CustomFieldType.Number]: faCalculator,
    [CustomFieldType.Date]: faCalendarDays,
    [CustomFieldType.Dropdown]: faCaretDown,
    [CustomFieldType.MultipleChoice]: faSquareCheck,
    [CustomFieldType.ShortText]: faInputText,
};
