import { Document, Page, pdf, Text, View } from '@react-pdf/renderer';
import React from 'react';

export const generateErrorPdf = (message: string) => {
    return pdf(
        <Document>
            <Page size="A4">
                <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <View
                        style={{
                            padding: 16,
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            color: 'red',
                            borderRadius: 10,
                            fontSize: 12,
                        }}
                    >
                        <Text>{message}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    ).toBlob();
};
