import { ReactNode, useEffect, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmModal, ContextModalProps, ModalSize, RadioGroup } from '@wedo/design-system';
import { ModalType } from '@wedo/design-system';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';

type CustomApply = { message: string; value: string; type?: string; disabled?: boolean; confirmText?: string };
type ConfirmSaveMeetingModalProps = {
    showThis?: boolean;
    showAll?: boolean;
    showFuture?: boolean;
    title?: ReactNode;
    content?: JSX.Element | string;
    okText?: string;
    cancelText?: string;
    type?: ModalType;
    defaultOption?: APPLY_ON | string;
    disabledThis?: boolean;
    disabledFuture?: boolean;
    customApplyOn?: CustomApply[];
    size?: ModalSize;
} & ContextModalProps;

export const ConfirmSaveMeetingModal = ({
    showAll = false,
    showFuture = true,
    showThis = true,
    title,
    okText,
    cancelText,
    type,
    defaultOption,
    content,
    disabledThis,
    disabledFuture,
    customApplyOn,
    size,
    ...modalProps
}: ConfirmSaveMeetingModalProps): JSX.Element => {
    const [applyOn, setApplyOn] = useState(defaultOption ? defaultOption : APPLY_ON.FUTURE_MEETINGS);

    const computedConfirmText = useMemo(() => {
        return (
            customApplyOn?.find((ca: CustomApply) => ca.value === applyOn && ca.confirmText != null)?.confirmText ||
            okText
        );
    }, [okText, applyOn]);

    useEffect(() => {
        if (applyOn === APPLY_ON.THIS_MEETING && disabledThis) {
            setApplyOn(APPLY_ON.FUTURE_MEETINGS);
        }
    }, [disabledThis]);

    useEffect(() => {
        if (applyOn === APPLY_ON.FUTURE_MEETINGS && disabledFuture) {
            setApplyOn(APPLY_ON.THIS_MEETING);
        }
    }, [disabledFuture]);

    const getNewConfirmationType = (type: ModalType) => {
        switch (type) {
            case ModalType.Danger:
                return 'danger';
            case ModalType.Warning:
                return 'warning';
            case ModalType.Info:
            case ModalType.Question:
                return 'primary';
            case ModalType.Success:
                return 'success';
            default:
                return '';
        }
    };

    return (
        <ConfirmModal
            {...modalProps}
            size={size}
            onConfirm={async () => applyOn}
            title={title ? title : t`Which meetings should be updated?`}
            confirmText={computedConfirmText}
            type={getNewConfirmationType(type)}
            defaultValue={null}
            content={
                <>
                    {content != null ? <div className={'mb-4'}>{content}</div> : null}
                    {([showAll, showFuture, showThis].filter(Boolean).length > 1 || customApplyOn?.length > 0) && (
                        <RadioGroup name="apply_on" onChange={(value) => setApplyOn(value)} value={applyOn}>
                            {showThis && (
                                <RadioGroup.Radio
                                    id={'apply-on-' + APPLY_ON.THIS_MEETING}
                                    key={APPLY_ON.THIS_MEETING}
                                    value={APPLY_ON.THIS_MEETING}
                                    disabled={disabledThis}
                                >
                                    <Trans>Only this meeting</Trans>
                                </RadioGroup.Radio>
                            )}
                            {showFuture && (
                                <RadioGroup.Radio
                                    id={'apply-on-' + APPLY_ON.FUTURE_MEETINGS}
                                    key={APPLY_ON.FUTURE_MEETINGS}
                                    value={APPLY_ON.FUTURE_MEETINGS}
                                    disabled={disabledFuture}
                                >
                                    <Trans>This and following meetings</Trans>
                                </RadioGroup.Radio>
                            )}
                            {showAll && (
                                <RadioGroup.Radio
                                    key={'apply-on-' + APPLY_ON.ALL_MEETINGS}
                                    value={APPLY_ON.ALL_MEETINGS}
                                    id={APPLY_ON.ALL_MEETINGS}
                                >
                                    <Trans>All meetings</Trans>
                                </RadioGroup.Radio>
                            )}
                            {customApplyOn &&
                                customApplyOn.map((applyOn: CustomApply) => (
                                    <RadioGroup.Radio
                                        id={'apply-on-custom-' + applyOn.value}
                                        type={applyOn.type === 'danger' ? 'danger' : null}
                                        disabled={Boolean(applyOn.disabled)}
                                        key={applyOn.value}
                                        value={applyOn.value}
                                    >
                                        {applyOn.message}
                                    </RadioGroup.Radio>
                                ))}
                        </RadioGroup>
                    )}
                </>
            }
        />
    );
};
