import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { enumeration, string } from '@wedo/utils';
import {
    SignatureCertificate,
    SignatureRequestStatus,
    SignatureSettings,
    SignatureStatus,
    SignatureVisual,
} from 'Shared/types/signature';
import { User } from 'Shared/types/user';

export const signatureStatuses = ['PENDING', 'CANCELED', 'SIGNED', 'REFUSED'] as const;
export const signatureRequestStatuses = ['PENDING', 'CANCELED', 'COMPLETED'] as const;

export enum LegalFrameworkEnum {
    CH = 'ESigA',
    EU = 'eIDAS',
}

export const legalFramework = [LegalFrameworkEnum.CH, LegalFrameworkEnum.EU] as const;
export const signatureType = ['SES', 'AES', 'QES'] as const;
export const signatureView = ['pending', 'all'] as const;

export const SignaturesPageSearchParams = {
    view: enumeration(...signatureView).default('pending'),
    documentToSign: string().default(null),
    search: string().default(''),
};

export const signatureStatusTranslation: Record<SignatureStatus | SignatureRequestStatus, MessageDescriptor> = {
    CANCELED: msg`Canceled`,
    PENDING: msg`Pending`,
    REFUSED: msg`Refused`,
    SIGNED: msg`Signed`,
    COMPLETED: msg`Completed`,
};

export const defaultSignatureVisual: SignatureVisual = {
    name: '',
    optionalLine: '',
    showDate: true,
    showQRCode: true,
    font: 'Anjhay',
    handwrittenSignatureUrl: null,
};

export const defaultSignatureCertificate: SignatureCertificate = {
    // @ts-ignore (country shouldn't be empty, that's on purpose to force the user to set it)
    country: '',
    phone: '',
};

export const defaultValuesToSignatureSettings = (signatureSettings: SignatureVisual, user: User): SignatureSettings => {
    return {
        ...(signatureSettings ?? defaultSignatureVisual),
        ...((signatureSettings == null || signatureSettings.name === '') && { name: user.full_name }),
    };
};
