import { DragEndEvent } from '@dnd-kit/core';
import { HierarchyCircularNode, interpolateHcl, pack, scaleLinear, stratify } from 'd3';
import { isEmpty } from 'lodash-es';
import { Id } from '@wedo/types';
import { generateUUID } from '@wedo/utils';
import { Circle, Root } from 'Shared/types/governance';
import { parseTextData } from 'Shared/utils/parseClipboard';

export const ViewportSize = 1024;
export const HalfSize = ViewportSize / 2;
const Margin = 20;
const LargePadding = 4000;
const Padding = 100;

export const StrokeWidth = 4;
export const ZoomTransitionDuration = 550;
export const IdPrefix = 'id';

export const CircleSizes = {
    Big: 3,
    Medium: 2,
    Small: 1,
    Tiny: 0,
};
export const fontSizes = {
    [CircleSizes.Big]: 26,
    [CircleSizes.Medium]: 20,
    [CircleSizes.Small]: 16,
    [CircleSizes.Tiny]: 12,
};

export const getMargin = (r: number): number => Margin * (r / HalfSize);
export const getPadding = (node: HierarchyCircularNode<Circle>): number =>
    node.children.length === 1 ? LargePadding * (node.r / HalfSize) : Padding * (node.r / HalfSize);

export const getReorderedObjectList = <
    T extends {
        id: Id;
    },
>(
    list: T[],
    active: DragEndEvent['active'],
    over: DragEndEvent['over']
): T[] => {
    const updatedList = [...list];
    updatedList.splice(
        list.findIndex((item) => item?.id === over?.id),
        0,
        updatedList.splice(
            list.findIndex((item) => item?.id === active?.id),
            1
        )[0]
    );
    return updatedList.map((item, index) => ({ ...item, order: index }));
};

export const parsePasteEvent = (event: ClipboardEvent): string[] => {
    return parseTextData(event.clipboardData.getData('text'), false).map(({ title }) => title);
};

export const mergePasteEventIntoList = (
    event: ClipboardEvent,
    oldList: {
        id: Id;
        value: string;
    }[]
): {
    id: Id;
    value: string;
}[] => {
    const lines = parsePasteEvent(event);
    return (oldList || []).concat(lines.map((line) => ({ id: generateUUID(), value: line })));
};

export const getCircleBreadcrumb = (
    circle: Circle | Root,
    nodes: HierarchyCircularNode<Circle | Root>[],
    options?: {
        skipSelf?: boolean;
    }
): string => {
    if (circle != null && !isEmpty(nodes)) {
        const circleNode = nodes.find((node) => node.data?.id === circle.id);
        const ancestors = circleNode?.ancestors();
        if (!ancestors) {
            return null;
        }
        if (options?.skipSelf) {
            ancestors.shift();
        }
        return ancestors
            .reverse()
            .splice(1)
            .map((node) => node.data?.name)
            .join(' > ');
    }
    return null;
};

export const colorScale = scaleLinear()
    .domain([0, 5])
    .range(['hsl(207, 100%, 88%)', 'hsl(207, 100%, 40%)'])
    .interpolate(interpolateHcl);

export const colorScaleDraft = scaleLinear()
    .domain([0, 5])
    .range(['hsl(30, 100%, 88%)', 'hsl(30, 100%, 40%)'])
    .interpolate(interpolateHcl);

export const getRoleIsOnlyChild = (role: Circle, nodes: Circle[]) => {
    return !nodes?.some((n) => n.parent_circle_id === role.parent_circle_id && n.id !== role.id);
};

export const getRoleHasCircleSiblings = (role: Circle, nodes: Circle[]) => {
    return nodes?.some(
        (n: Circle | Root) =>
            (n?.parent_circle_id === role.parent_circle_id && n.type === 'circle') || role.parent_circle_id === 'root'
    );
};

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
};

export const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number): string => {
    const start = polarToCartesian(x, y, radius, startAngle);
    const end = polarToCartesian(x, y, radius, endAngle);
    let arcLength = endAngle - startAngle;
    if (arcLength < 0) {
        arcLength += 360;
    }
    const longArc = arcLength >= 180 ? 1 : 0;

    const startX = start.x;
    const radiusY = radius;
    const endX = end.x;

    return ['M', startX, start.y, 'A', radius, radiusY, 0, longArc, 1, endX, end.y].join(' ');
};

// Converts our server data into data that d3 can understand
export const getStratifiedNodes = (nodes: (Circle | Root)[]) => {
    return pack()
        .size([ViewportSize, ViewportSize])
        .padding((node: HierarchyCircularNode<Circle>) => getPadding(node))(
        stratify()
            .id((node: HierarchyCircularNode<Circle>) => node.id)
            .parentId((node: Circle) => node.parent_circle_id)(nodes)
            .sum((d: Circle | Root) => {
                if (d.type === 'role') {
                    const roleHasCircleSiblings = getRoleHasCircleSiblings(d, nodes);
                    const roleIsOnlyChild = getRoleIsOnlyChild(d, nodes);

                    // In order to make the graph more balanced / visually appealing we make some roles bigger
                    if (roleHasCircleSiblings || roleIsOnlyChild) {
                        return 5;
                    }
                    return 1;
                }
                return 5;
            })
            .sort((a: HierarchyCircularNode<Circle>, b: HierarchyCircularNode<Circle>) => {
                if (a.value !== b.value) {
                    return b.value - a.value;
                }
                return new Date(a.data.created_at).getTime() - new Date(b.data.created_at).getTime();
            })
    );
};

export const getNodeFromCircleId = (root, circleId) => {
    return root.descendants().find((node) => node.data.id === circleId);
};

// Returns the circle we want to zoom on when we first load the graph
export const getMainCircle = (nodes: Circle[], root: HierarchyCircularNode<Root>) => {
    if (nodes.length === 1) {
        return root;
    }
    const firstLevelNodes = root.descendants().filter((n) => n.depth === 1);
    firstLevelNodes.sort((a, b) => (b.children?.length ?? 0) - (a.children?.length ?? 0));
    return firstLevelNodes[0];
};
