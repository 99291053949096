import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentProps } from 'react';
import { faPeopleGroup } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Tooltip } from '@wedo/design-system';
import { Team } from 'Shared/types/team';

const classes = {
    wrapper: {
        base: 'shrink-0 flex items-center justify-center rounded-sm bg-gray-200 text-gray-800',
        size: {
            xs: 'h-3 w-3',
            sm: 'h-5 w-5',
            md: 'h-6 w-6',
            lg: 'h-8 w-8',
        },
    },
    icon: {
        size: {
            xs: 'h-1 w-1',
            sm: 'h-2 w-2',
            md: 'h-3 w-3',
            lg: 'h-4 w-4',
        },
    },
};

type TeamIconProps = {
    team?: Team;
    size?: 'xs' | 'sm' | 'md' | 'lg';
} & ComponentProps<'div'>;
export const TeamIcon = ({ className, team, size = 'md', ...props }: TeamIconProps) => (
    <Tooltip content={team?.name}>
        <div className={clsx(classes.wrapper.base, classes.wrapper.size[size], className)} {...props}>
            <FontAwesomeIcon icon={faPeopleGroup} className={clsx(classes.icon.size[size])} />
        </div>
    </Tooltip>
);
