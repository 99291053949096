import { FC, useId, useMemo, useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { ZodIssue } from 'zod';
import { Button, Form, Input, Label } from '@wedo/design-system';
import { LanguageCode } from '@wedo/utils';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { NewOrganizationAction, UPDATE_USER } from 'Pages/onboarding/utils/onboardingStore';
import { LanguageSelector } from 'Shared/components/lang/LanguageSelector';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';

export const ProfileStep: FC<{ userOnboarding?: boolean }> = () => {
    const id = useId();

    const { updateKey, actions, zodErrors, ...store } = useOnboarding();
    const { setUserLanguage } = useUserLanguage();

    const [nextClicked, setNextClicked] = useState(false);

    const handleChange = (params: NewOrganizationAction) => {
        if (params.key === 'language') {
            setUserLanguage(params.value);
        }

        if (params.key === UPDATE_USER) {
            return;
        }

        updateKey(params);
    };

    const getErrorMessage = (key: string) => {
        if (!nextClicked) {
            return false;
        }

        return zodErrors.find((error) => error.path.includes(key))?.message ?? false;
    };

    const handleNext = async () => {
        if (!nextClicked) {
            setNextClicked(true);
        }
        let errors = structuredClone(zodErrors) as ZodIssue[];
        errors = errors.filter((error) =>
            ['firstName', 'lastName', 'jobTitle', 'language'].some((key) => error.path.includes(key))
        );
        if (errors?.length > 0) {
            return;
        }
        actions.setStep(store.step + 1);
    };

    const formHasErrors = useMemo<boolean>(() => {
        return (getErrorMessage('firstName') || getErrorMessage('lastName')) as boolean;
    }, [getErrorMessage('firstName'), getErrorMessage('lastName')]);

    return (
        <>
            <Form
                onKeyDown={(e) => e.key === 'Enter' && handleNext()}
                layout="vertical"
                title={t`Welcome ${store.firstName}!`}
            >
                <Form.Item>
                    <Trans>
                        You are about to set up a new account on WEDO. We will guide you in a few simple steps. Please
                        start by completing your profile. This will help your colleagues find you.
                    </Trans>
                </Form.Item>
                <Form.Item htmlFor={id + 'first-name'} label={t`First name`}>
                    <Input
                        id={id + 'first-name'}
                        value={store.firstName}
                        onChange={(e) => handleChange({ key: 'firstName', value: e.target.value })}
                        status={getErrorMessage('firstName') ? 'error' : 'default'}
                        statusText={getErrorMessage('firstName') || ''}
                    />
                </Form.Item>
                <Form.Item htmlFor={id + 'last-name'} label={t`Last name`}>
                    <Input
                        id={id + 'last-name'}
                        value={store.lastName}
                        onChange={(e) => handleChange({ key: 'lastName', value: e.target.value })}
                        status={getErrorMessage('lastName') ? 'error' : 'default'}
                        statusText={getErrorMessage('lastName') || ''}
                    />
                </Form.Item>
                <Form.Item htmlFor={id + 'job-title'} label={t`Job title`}>
                    <Input
                        id={id + 'job-title'}
                        value={store.jobTitle}
                        onChange={(e) => handleChange({ key: 'jobTitle', value: e.target.value })}
                    />
                </Form.Item>
                <Form.Item>
                    <Label>
                        <Trans>Language</Trans>
                    </Label>
                    <LanguageSelector
                        value={store.language as LanguageCode}
                        onChange={(value: string) => handleChange({ key: 'language', value })}
                    />
                </Form.Item>
            </Form>
            <OnboardingLayout.Buttons>
                <Button
                    disabled={formHasErrors}
                    color="primary"
                    onClick={handleNext}
                    icon={faChevronRight}
                    iconPosition="end"
                >
                    <Trans>Next</Trans>
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
