import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Skeleton, Tag, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { TASK_DETAIL_ICON_CLASS, TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { useGetTaskMeetingsQuery } from 'Shared/services/meeting';

type TaskDetailMeetingsProps = {
    taskId: Id;
};

export const TaskDetailMeetings = ({ taskId }: TaskDetailMeetingsProps) => {
    const { isLoadingTask } = useTask(taskId);
    const { i18n } = useLingui();
    const [maxMeetingsToShow, setMaxMeetingsToShow] = useState<number>(1);

    const { data: taskMeetings = EmptyArray } = useGetTaskMeetingsQuery(
        { taskId, limit: 5 },
        { refetchOnMountOrArgChange: true, skip: !taskId }
    );

    useEffect(() => {
        setMaxMeetingsToShow(1);
    }, [taskId]);

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={t`Most recent meeting`} delay={300}>
                    <FontAwesomeIcon className={clsx(TASK_DETAIL_ICON_CLASS, 'text-gray-500')} icon={faCalendarDay} />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    <div className={'flex flex-wrap gap-2'}>
                        {taskMeetings.map((meeting, index) => {
                            if (index >= maxMeetingsToShow) {
                                return null;
                            }
                            return (
                                <Tag
                                    key={meeting.id}
                                    size="sm"
                                    color="gray"
                                    href={{
                                        pathname: `/meetings/${meeting.id}`,
                                        searchParams: { topicId: meeting.itemId },
                                    }}
                                >
                                    <div className={'truncate'}>{formatMeetingTitle(meeting, i18n)}</div>
                                </Tag>
                            );
                        })}
                        {taskMeetings.length > maxMeetingsToShow && (
                            <Tag size="sm" color="gray" onClick={() => setMaxMeetingsToShow((prev) => prev + 1)}>
                                ...
                            </Tag>
                        )}
                    </div>
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon />
        </TaskDetailRow>
    );
};
