import { Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ManageMembersModalBody } from 'Shared/components/userGroup/ManageMembersModalBody';
import { useTeam } from 'Shared/hooks/useTeam';

type MembersPanelProps = {
    teamId: Id;
    isReadOnly: boolean;
    onUserDeleted: (action: string) => void;
};

export const MembersPanel = ({ teamId, isReadOnly: isReadOnlyInitial, onUserDeleted }: MembersPanelProps) => {
    const {
        team,
        isCurrentUserTeamModerator,
        handleAddMember,
        handleMemberDelete: deleteUser,
        handleRefuseJoinRequest,
        handleAcceptRequest,
        handleModeratorToggle,
    } = useTeam(teamId);

    const isReadOnly = isReadOnlyInitial || !isCurrentUserTeamModerator;

    const handleDeleteUser = async (user) => {
        onUserDeleted(await deleteUser(user));
    };

    return (
        <Tabs.Panel>
            <ManageMembersModalBody
                tableBodyClassName="overflow-hidden"
                isReadOnly={isReadOnly}
                onDeleteUser={handleDeleteUser}
                onToggleModerator={handleModeratorToggle}
                onAddUser={handleAddMember}
                entity={team}
                onAcceptRequest={handleAcceptRequest}
                onRefuseRequest={handleRefuseJoinRequest}
                joinRequests={team?.join_requests}
            />
        </Tabs.Panel>
    );
};
