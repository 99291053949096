export const chain = (...functions: Array<() => void>) => {
    for (const f of functions) {
        f();
    }
};

export const EmptyFunction = <T>(data?: T) => data;

export const tryOrValue = <T>(f: () => T, value: T): T => {
    try {
        return f();
    } catch (error) {
        return value;
    }
};

export const tryOrUndefined = <T>(f: () => T): T => tryOrValue(f, undefined);

export const tryOrNull = <T>(f: () => T): T => tryOrValue(f, null);

export const tryOrFalse = (f: () => boolean): boolean => tryOrValue(f, false);

export const tryOrZero = (f: () => number): number => tryOrValue(f, 0);

export const tryAndIgnore = (f: () => unknown): void => {
    try {
        f();
    } catch (error) {}
};

export const asynchronous = (f: () => unknown, asynchronous: boolean): void => {
    if (asynchronous) {
        setTimeout(f);
    } else {
        f();
    }
};
