import { arrayMove } from '@dnd-kit/sortable';
import { TreeNodeModel } from './utils';

const getDragDepth = (offset: number, indentationWidth: number) => Math.round(offset / indentationWidth);

const findItem = (items: TreeNodeModel[], itemId: string): TreeNodeModel => items.find(({ id }) => id === itemId);

const findItemIndex = (items: TreeNodeModel[], itemId: string): number => items.findIndex(({ id }) => id === itemId);

const getMaxDepth = ({ previousItem }: { previousItem: TreeNodeModel }) => {
    if (previousItem) {
        return previousItem.isSection ? previousItem.depth + 1 : previousItem.depth;
    }

    return 0;
};

const getMinDepth = ({ nextItem }: { nextItem: TreeNodeModel }) => {
    if (nextItem) {
        return nextItem.depth;
    }

    return 0;
};

const findPreviousItemOfSameDepth = (items: TreeNodeModel[], index: number, depth: number): TreeNodeModel => {
    let i = index;
    while (i >= 0 && items[i].depth > depth) {
        i--;
    }
    return i >= 0 ? items[i] : null;
};

const getParentId = (depth, previousItem, newItems, overItemIndex) => {
    if (depth === 0 || !previousItem) {
        return null;
    }

    if (depth === previousItem.depth) {
        return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
        return previousItem.id;
    }

    const newParent = newItems
        .slice(0, overItemIndex)
        .reverse()
        .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
};

export const getDropLocationData = (
    items: TreeNodeModel[],
    draggedId: string,
    overId: string,
    dragOffset: number,
    indentationWidth: number,
    canManageTopics: boolean,
    canManageSections: boolean
): { depth: number; parentId: string; displayId: string; canDrop: boolean } => {
    const overItemIndex = findItemIndex(items, overId);
    const activeItemIndex = findItemIndex(items, draggedId);
    const draggedItem = items[activeItemIndex];
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];
    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const targetDepth = draggedItem.depth + dragDepth;
    const maxDepth = getMaxDepth({
        previousItem,
    });
    const minDepth = getMinDepth({ nextItem });
    let depth = targetDepth;
    if (targetDepth >= maxDepth) {
        depth = maxDepth;
    } else if (targetDepth < minDepth) {
        depth = minDepth;
    }
    const parentId = getParentId(depth, previousItem, newItems, overItemIndex);
    const previousItemOfSameDepth = findPreviousItemOfSameDepth(
        items,
        overItemIndex > activeItemIndex ? overItemIndex : overItemIndex - 1,
        depth
    );

    let displayId = '1';
    if (previousItemOfSameDepth) {
        const splited = previousItemOfSameDepth.display_id.split('.').map((el) => Number(el));
        if (previousItemOfSameDepth.depth === depth) {
            splited[splited.length - 1] += 1;
            displayId = splited.join('.');
        } else {
            displayId = splited.join('.') + '.1';
        }
    }

    const draggedNode = findItem(items, draggedId);
    let canDrop = draggedNode.isSection ? canManageSections : canManageTopics;

    if (parentId) {
        const parentNode = findItem(items, parentId);
        canDrop = draggedNode.isSection ? parentNode.canDropSection : parentNode.canDropTopic;
    }

    return { depth, parentId, displayId, canDrop };
};
