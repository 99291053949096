import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox } from '@headlessui/react';
import { ChangeEventHandler, FC, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Icon } from '~/components/Icon/Icon';
import { IconName } from '~/components/Icon/type';
import { KeyboardShortcut } from '~/components/KeyboardShortcut/KeyboardShortcut';
import { FaIconSize } from '@wedo/types';
import { isOSX } from '@wedo/utils';

const CommandPaletteOptions = ({ children, className }: { children: ReactNode[]; className?: string }) => {
    return (
        children.filter(Boolean).length > 0 && (
            <Combobox.Options
                static
                className={clsx(
                    'scroll-py-2 divide-y divide-gray-300 overflow-y-auto border-t border-gray-300',
                    className
                )}
            >
                {children}
            </Combobox.Options>
        )
    );
};
const CommandPaletteGroup = ({ children, title }: { children: ReactNode; title: string }) => {
    return (
        <li className="p-2">
            <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-900">{title}</h2>
            <ul className="text-sm text-gray-700">{children}</ul>
        </li>
    );
};

type CommandPaletteOptionProps = {
    value: object | string;
    icon?: IconDefinition | IconName;
    iconComponent?: ReactNode;
    iconClassName?: string;
    iconSize?: FaIconSize;
    children: ReactNode;
    shortcut?: string;
    onClick?: () => void;
};

const CommandPaletteOption: FC<CommandPaletteOptionProps> = ({
    value,
    icon,
    iconComponent,
    children,
    shortcut,
    onClick,
    iconClassName,
    iconSize,
}) => {
    return (
        <Combobox.Option
            value={value}
            onClick={onClick}
            className={({ active }) =>
                clsx(
                    'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
                    active && 'bg-gray-900 bg-opacity-5 text-gray-900'
                )
            }
        >
            {({ active }) => (
                <>
                    {icon && (
                        <Icon
                            name={icon}
                            className={clsx(
                                'h-6 w-6 flex-none text-gray-900 text-opacity-40',
                                active && 'text-opacity-100',
                                iconClassName
                            )}
                            size={iconSize}
                        />
                    )}
                    {iconComponent && <div className={clsx('flex items-start', iconClassName)}>{iconComponent}</div>}
                    <span className="ml-3 flex-auto truncate">{children}</span>
                    {active && !shortcut && (
                        <span className="ml-3 flex-none text-gray-600">
                            <Trans>Jump to...</Trans>
                        </span>
                    )}
                    {shortcut && (
                        <span className="ml-3 flex-none text-xs font-semibold text-gray-600">
                            <KeyboardShortcut keyStrokes={[isOSX() ? '⌘' : 'Ctrl', shortcut]} />
                        </span>
                    )}
                </>
            )}
        </Combobox.Option>
    );
};

const CommandPaletteComponent = ({
    onChange,
    onSelect,
    children,
    query,
}: {
    onChange: ChangeEventHandler<HTMLInputElement>;
    onSelect: (value: unknown) => void;
    children: ReactNode;
    query: string;
}) => {
    return (
        <Combobox onChange={onSelect}>
            <div className="relative">
                <FontAwesomeIcon
                    icon={faSearch}
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-700 text-opacity-40"
                />
                <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder-gray-600 focus:ring-0"
                    placeholder={t`Search...`}
                    onChange={onChange}
                    value={query}
                />
            </div>
            {children}
        </Combobox>
    );
};

export const CommandPalette = Object.assign(CommandPaletteComponent, {
    Options: CommandPaletteOptions,
    Group: CommandPaletteGroup,
    Option: CommandPaletteOption,
});
