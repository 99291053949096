import { useCallback, useMemo } from 'react';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { UserEmail } from 'Shared/types/user-email';

export const useUserEmails = () => {
    const { currentUser } = useCurrentUserContext();

    const userEmails = useMemo(() => currentUser?.userEmails, [currentUser]);

    const primaryEmail = useMemo(() => currentUser?.userEmail, [currentUser]);

    const primaryEmailId = useMemo(() => primaryEmail?.id, [primaryEmail]);

    const isPrimaryEmail = useCallback((email: UserEmail) => email.id === primaryEmailId, [primaryEmailId]);

    return {
        userEmails,
        primaryEmail,
        primaryEmailId,
        isPrimaryEmail,
    };
};
