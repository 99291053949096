import { plural } from '@lingui/macro';
import { RecurrenceEndsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceEndsOnInput';
import { RecurrenceRepeatEveryInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatEveryInput';
import { RecurrenceStartsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceStartsOnInput';
import { FormRecurrence } from 'Shared/types/formRecurrence';

export type RecurrenceDailyFormProps = {
    state: FormRecurrence;
    handleValueChange?: (name: string, value: number | string) => void;
    repeatEverySuffix?: string;
};
export const RecurrenceDailyForm = ({ state, handleValueChange }: RecurrenceDailyFormProps): JSX.Element => {
    return (
        <div className={'grid grid-cols-12 items-center gap-x-3 gap-y-8'}>
            <RecurrenceStartsOnInput state={state} handleValueChange={handleValueChange} />
            <RecurrenceRepeatEveryInput
                state={state}
                repeatEverySuffix={plural(state.repeatEvery, { one: 'day', other: `days` })}
                handleValueChange={handleValueChange}
            />
            <RecurrenceEndsOnInput state={state} handleValueChange={handleValueChange} />
        </div>
    );
};
