import { FC } from 'react';
import { faShareSquare } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import {
    Dropdown,
    FormatDate,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts';
import {
    getSearchParamsWithoutTopicId,
    MeetingViewSearchParams,
} from 'Pages/meeting/components/MeetingView/MeetingView';
import {
    PostponeTopicType,
    PostponeTopicTypeConfirmModal,
} from 'Pages/meeting/components/TopicDropdown/PostponeTopicTypeConfirmModal';
import {
    useCanPostponeTopicMutation,
    usePreviousAndNextTopic,
    useUpdateTopicsMutation,
} from 'Shared/services/meetingTopic';
import { trpcUtils } from 'Shared/trpc';
import { Meeting } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { isRecurringMeeting, isRecurringTopic } from 'Shared/utils/meeting';

export const PostponeTopicDropdownSubMenu: FC<{ topic: MeetingTopic }> = ({ topic }) => {
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const { confirm } = useConfirm();
    const { show } = useNotification();
    const { meeting, meetingId } = useMeetingContext();
    const { previousTopic, nextTopic } = usePreviousAndNextTopic(meetingId, topic?.id);

    const [updateTopics] = useUpdateTopicsMutation();
    const [canPostponeTopic] = useCanPostponeTopicMutation();

    const isLastRecurringTopicInSeries = topic?.next_occurrences?.every(({ id }) => id == null);

    const onTopicChange = async (changes: { shiftType: PostponeTopicType; meeting_id: string }) => {
        try {
            const destinationMeetingId = changes.meeting_id;
            await updateTopics({ meetingId, topics: [{ id: topic.id, changes }] }).unwrap();
            await trpcUtils().meetingTopic.search.invalidate();
            await trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
            await trpcUtils().meetingTopic.listByMeetingId.invalidate(destinationMeetingId);
            await trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
            await trpcUtils().meetingSection.listByMeetingId.invalidate(destinationMeetingId);
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    const canPostponeRecurringTopic = async ({
        destinationMeetingId,
        shiftType,
        topic,
    }: {
        destinationMeetingId: Id;
        shiftType: PostponeTopicType;
        topic: MeetingTopic;
    }): Promise<boolean> => {
        const response = await canPostponeTopic({ meetingId, destinationMeetingId, shiftType, topicId: topic.id });
        if ('data' in response) {
            const { canPostpone } = response.data;
            return canPostpone;
        }
        return false;
    };

    const postponeTopic = (meetingId: Id, shiftType: PostponeTopicType = 'shift-series') => {
        void onTopicChange({ meeting_id: meetingId.toString(), shiftType });
        if (nextTopic) {
            setSearchParams({ ...searchParams, topicId: String(nextTopic.id) }, { replace: true });
        } else if (previousTopic) {
            setSearchParams({ ...searchParams, topicId: String(previousTopic.id) }, { replace: true });
        } else {
            setSearchParams(getSearchParamsWithoutTopicId(searchParams), { replace: true });
        }
    };

    const topicExistsInMeeting = (destinationMeeting: Partial<Meeting>): boolean => {
        return topic.next_occurrences.some(
            ({ meeting: { id: meetingId }, id: topicId }) =>
                Number(meetingId) === Number(destinationMeeting.id) && topicId !== null
        );
    };

    const showInsufficientMeetingsError = async () => {
        return confirm({
            type: 'warning',
            title: t`You can't postpone this topic`,
            content: (
                <div className="flex flex-col gap-2">
                    <p>
                        <Trans>There are not enough meetings in this series.</Trans>
                    </p>
                    <p>
                        <Trans>
                            There are some topics towards the end of the series that have some content and will be
                            pushed out of the meeting series.
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            To perform this action you either need to add more meetings or delete these topics which
                            have content.
                        </Trans>
                    </p>
                </div>
            ),
            confirmText: t`Close`,
            isCancelButtonVisible: false,
        });
    };

    const postponeRecurringTopicInNonRecurringMeeting = async (destinationMeeting: Partial<Meeting>) => {
        if (topicExistsInMeeting(destinationMeeting)) {
            if (
                await canPostponeRecurringTopic({
                    topic,
                    shiftType: 'shift-series',
                    destinationMeetingId: destinationMeeting.id,
                })
            ) {
                postponeTopic(destinationMeeting.id, 'shift-series');
            } else {
                await showInsufficientMeetingsError();
            }
            return;
        }

        if (isLastRecurringTopicInSeries) {
            postponeTopic(destinationMeeting.id, 'shift-instance');
            return;
        }

        // we have to ask to move only this topic or shift the entire series
        const shiftType = await confirm<PostponeTopicType>(
            { topic, destinationMeeting },
            PostponeTopicTypeConfirmModal
        );
        if (shiftType === null) {
            return;
        }
        if (
            !(await canPostponeRecurringTopic({
                topic,
                shiftType,
                destinationMeetingId: destinationMeeting.id,
            }))
        ) {
            await showInsufficientMeetingsError();
            return;
        }
        postponeTopic(destinationMeeting.id, shiftType);
    };

    const postponeRecurringTopicInRecurringMeeting = async (destinationMeeting: Partial<Meeting>) => {
        if (topicExistsInMeeting(destinationMeeting)) {
            postponeTopic(destinationMeeting.id, 'shift-series');
            return;
        }
        if (isLastRecurringTopicInSeries) {
            postponeTopic(destinationMeeting.id, 'shift-instance');
            return;
        }

        const shiftType = await confirm<PostponeTopicType>(
            { topic, destinationMeeting },
            PostponeTopicTypeConfirmModal
        );
        if (shiftType == null) {
            return;
        }
        postponeTopic(destinationMeeting.id, shiftType);
    };

    const handlePostponeTopic = async (destinationMeeting: Partial<Meeting>) => {
        if (!isRecurringTopic(topic)) {
            postponeTopic(destinationMeeting.id);
        } else if (isRecurringMeeting(meeting)) {
            await postponeRecurringTopicInRecurringMeeting(destinationMeeting);
        } else {
            await postponeRecurringTopicInNonRecurringMeeting(destinationMeeting);
        }
    };

    return (
        <Dropdown.SubMenu icon={faShareSquare} label={t`Postpone to...`}>
            {meeting.nextMeetings.map((nextMeeting) => (
                <Dropdown.Item
                    onClick={() => handlePostponeTopic(nextMeeting)}
                    key={`'postpone-'${nextMeeting.id}`}
                    disabled={nextMeeting.status === 'locked'}
                >
                    <Tooltip
                        content={
                            nextMeeting.status === 'locked' &&
                            t`You can't postpone the topic to this meeting as it is locked`
                        }
                    >
                        <div className="flex items-center gap-2">
                            <FormatDate format="PPP" date={new Date(nextMeeting.start_at)} />
                        </div>
                    </Tooltip>
                </Dropdown.Item>
            ))}
        </Dropdown.SubMenu>
    );
};
