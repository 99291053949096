import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MeetingContextProvider } from 'App/contexts/MeetingContext';
import { MeetingView } from 'Pages/meeting/components/MeetingView/MeetingView';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';

export const MeetingPage = () => {
    const { meetingId } = useParams();

    const { data: meeting, refetch } = useGetMeetingQuery(buildGetMeetingParameters(meetingId));

    usePageTitle(meeting?.title);

    useEffect(() => {
        // Refetch meeting on page load to have latest meeting changes
        if (meeting) {
            refetch();
        }
    }, [meeting?.id]);

    return (
        <MeetingContextProvider meetingId={meetingId} initialMeeting={meeting}>
            <MeetingView />
        </MeetingContextProvider>
    );
};
