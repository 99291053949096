import React, { useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { addDays, endOfDay, isBefore } from 'date-fns';
import {
    Button,
    ContextModalProps,
    DatePicker,
    Form,
    FormatDate,
    Input,
    Modal,
    Popover,
    Select,
    Textarea,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { onEnter } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { invalidateActivityFeeds } from 'Shared/services/activityFeed';
import { invalidateWorkspaceById } from 'Shared/services/workspace';
import { useAddWorkspaceStatusMutation, useUpdateWorkspaceStatusMutation } from 'Shared/services/workspaceStatus';
import { Status, Statuses, WorkspaceStatus } from 'Shared/types/workspaceStatus';

const DatePickerInput = ({
    date,
    minDate,
    onChange,
}: {
    date: Date;
    minDate?: Date;
    onChange: (date: Date) => void;
}) => (
    <Popover
        text={
            <div className="w-40">
                <FormatDate date={date} format={'P'} />
            </div>
        }
        placement="bottom"
    >
        <div className="w-72 bg-white p-4">
            <DatePicker date={date} minDate={minDate} onChange={onChange} />
        </div>
    </Popover>
);

const createEmptyStatus = (workspaceId: Id): Partial<WorkspaceStatus> => ({
    status: Status.OnTrack,
    tag_id: workspaceId,
    title: '',
    description: '',
    start_date: new Date(),
    due_date: new Date(),
});

type UpdateWorkspaceStatusModalProps = {
    workspaceId: Id;
    status?: Partial<WorkspaceStatus>;
} & ContextModalProps;
export const UpdateWorkspaceStatusModal: React.FC<UpdateWorkspaceStatusModalProps> = ({
    workspaceId,
    status,
    ...modalProps
}) => {
    const dispatch = useAppDispatch();
    const [addWorkspaceStatus] = useAddWorkspaceStatusMutation();
    const [updateWorkspaceStatus] = useUpdateWorkspaceStatusMutation();

    const [currentStatus, setCurrentStatus] = useState<Partial<WorkspaceStatus>>({
        ...createEmptyStatus(workspaceId),
        ...status,
    });
    const [prevStatus, setPrevStatus] = useState(status);
    const [loading, setLoading] = useState(false);

    const titleInput = useRef<HTMLInputElement>();

    if (prevStatus !== status) {
        setPrevStatus(status);
        setCurrentStatus({ ...createEmptyStatus(workspaceId), ...status });
    }

    const handleConfirm = async (): Promise<void> => {
        setLoading(true);
        if (status?.id) {
            await updateWorkspaceStatus({ status: currentStatus });
        } else {
            await addWorkspaceStatus({ status: currentStatus });
        }
        dispatch(invalidateWorkspaceById(workspaceId));
        dispatch(invalidateActivityFeeds());
        setLoading(false);
        await modalProps.close();
    };

    const handleChange = (attribute: string, value: string | Date): void => {
        setCurrentStatus((current) => ({ ...current, [attribute]: value }));
    };

    const handleChangeStartDate = (value: Date): void => {
        handleChange('start_date', value);
        if (currentStatus.due_date && isBefore(new Date(currentStatus.due_date), endOfDay(value))) {
            handleChange('due_date', addDays(value, 1));
        }
    };

    return (
        <Modal {...modalProps} initialFocus={titleInput}>
            <Modal.Header title={status?.id ? t`Edit status update` : t`Add status update`} />

            <Modal.Body>
                <Form layout={'vertical'}>
                    <div className="flex w-full gap-4">
                        <Form.Item htmlFor="status" label={t`Status`} cols={3}>
                            <Select
                                value={currentStatus.status}
                                onChange={(value: string) => handleChange('status', value)}
                                placeholder={t`Status`}
                                customRenderSelected={(value: string) => (
                                    <WorkspaceStatusTag
                                        status={Statuses.find((s) => s === value)}
                                        className="pointer-events-none"
                                    />
                                )}
                                wrapperClassName="min-w-[10rem]"
                            >
                                {Statuses.map((status) => (
                                    <Select.Option key={status} value={status}>
                                        <WorkspaceStatusTag status={status} className="pointer-events-none" />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label={t`Start date`} htmlFor="startDate" cols={3}>
                            <DatePickerInput
                                date={new Date(currentStatus.start_date)}
                                onChange={handleChangeStartDate}
                            />
                        </Form.Item>

                        <Form.Item label={t`Due date`} htmlFor="dueDate" cols={3}>
                            <DatePickerInput
                                date={new Date(currentStatus.due_date)}
                                minDate={new Date(currentStatus.start_date)}
                                onChange={(value) => handleChange('due_date', value)}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item label={t`Status update title`} htmlFor="title">
                        <Input
                            ref={titleInput}
                            id={'title'}
                            name={'title'}
                            value={currentStatus.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                            onKeyDown={onEnter(handleConfirm)}
                        />
                    </Form.Item>

                    <Form.Item label={t`Description`} htmlFor="description">
                        <Textarea
                            rows={3}
                            value={currentStatus.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    color="primary"
                    onClick={handleConfirm}
                    loading={loading}
                    disabled={currentStatus.status === undefined}
                >
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
