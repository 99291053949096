import { t } from '@lingui/macro';
import { ConfirmModal, ContextModalProps } from '@wedo/design-system';

export const ConfirmDiscardChangesModal = ({
    title,
    confirmText,
    ...modalProps
}: { title?: string; confirmText?: string } & ContextModalProps) => {
    return (
        <ConfirmModal
            {...modalProps}
            title={title ?? t`Are you sure you want to discard your changes?`}
            type={'warning'}
            confirmText={confirmText ?? t`Discard`}
        />
    );
};
