import { useId } from 'react';
import { colors } from '@wedo/design-system';
import { convertPointsToSvg, createPolygon } from '@wedo/utils';

export const badgeLevelPoints = [0, 50, 100, 250, 500, 1000, 2500, 5000];

// SUMMER, LOLLIPOP, RED, PINK, PURPLE, INDIGO, ROYAL
export const badgeLevelGradients = [
    [colors.green[400], colors.teal[400]],
    [colors.yellow[300], colors.orange[300]],
    [colors.orange[500], colors.pink[500]],
    [colors.red[400], colors.red[500]],
    [colors.pink[400], colors.pink[500]],
    [colors.purple[400], colors.purple[500]],
    [colors.indigo[400], colors.indigo[500]],
    [colors.blue[600], '#2f94e7'],
];

export const getBadgeLevel = (score: number) => {
    let level = badgeLevelPoints.findIndex((points) => score < points);
    if (level === -1) {
        level = badgeLevelPoints.length;
    }
    return level;
};

export const getBadgeGradient = (score: number, id?: string) => {
    const level = getBadgeLevel(score);
    const gradientValues = badgeLevelGradients[level - 1];
    return (
        <linearGradient id={id ?? `badgeLevelGradient-${level}`}>
            <stop offset="0%" stopColor={gradientValues[0]} />
            <stop offset="100%" stopColor={gradientValues[1]} />
        </linearGradient>
    );
};

export const BadgeLevel = ({ score, size = 80 }: { score: number; size?: number }) => {
    const randomId = useId();
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" className="shrink-0">
            <defs>{getBadgeGradient(score, randomId)}</defs>
            <polygon
                points={convertPointsToSvg(createPolygon([size / 2, size / 2], size / 2, 6))}
                fill={`url(#${randomId})`}
            />
            <text
                fontSize={0.6 * size}
                x={size / 2}
                y={size / 2 + 0.225 * size}
                fontWeight="bold"
                textAnchor="middle"
                fill="white"
            >
                {getBadgeLevel(score)}
            </text>
        </svg>
    );
};
