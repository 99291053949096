import { cloneDeep, isEmpty } from 'lodash-es';
import { create } from 'zustand';
import { CountryCode, SwissCantonCode } from '@wedo/types';
import { EmptyString } from '@wedo/utils';

export type Address = {
    firstName: string;
    lastName: string;
    company: string;
    phone: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    country: CountryCode;
    canton: SwissCantonCode;
    email: string;
    city: string;
    zipCode: string;
    vatNumber: string;
};

type Actions = {
    setAddress: (address: Partial<Address>) => void;
    setAddressIfEmpty: (address: Partial<Address>) => void;
    reset: () => void;
};

const SubscriptionPageAddressInitialState: Address = {
    firstName: EmptyString,
    lastName: EmptyString,
    company: EmptyString,
    phone: EmptyString,
    addressLine1: EmptyString,
    addressLine2: EmptyString,
    addressLine3: EmptyString,
    country: undefined,
    canton: undefined,
    email: EmptyString,
    city: EmptyString,
    zipCode: EmptyString,
    vatNumber: EmptyString,
};

export const useSubscriptionAddressStore = create<Address & { actions: Actions }>()((set, getState) => ({
    ...SubscriptionPageAddressInitialState,

    actions: {
        setAddress: (address: Partial<Address>) => set(() => address),
        setAddressIfEmpty: (address: Partial<Address>) => {
            const current = cloneDeep(getState());
            const { actions, ...currentAddress } = current;
            Object.keys(address).forEach((field: keyof Address) => {
                if (field in currentAddress && isEmpty(currentAddress[field])) {
                    currentAddress[field] = address[field];
                }
            });
            set(currentAddress);
        },
        reset: () => set(SubscriptionPageAddressInitialState),
    },
}));
