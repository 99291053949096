import { QueryClient } from '@tanstack/react-query';
import type { TRPCLink } from '@trpc/react-query';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { AppRouter, observable } from '@wedo/backend';

export const invalidationLink =
    (queryClient: QueryClient): TRPCLink<AppRouter> =>
    () => {
        return ({ op, next }) => {
            return observable((observer) => {
                let skipComplete = false;
                let canComplete = false;
                return next(op).subscribe({
                    next: (value) => {
                        // If we have tags to invalidates we did not complete the link yet, we first wait for the
                        // invalidation to finish. Thus the isLoading property of a mutation will be true until the
                        // invalidation finishes.
                        if (op.type === 'mutation' && value.result.type === 'data' && 'tags' in value.result) {
                            skipComplete = true;
                            void invalidateQueries(queryClient, value.result.tags)
                                .then(() => {
                                    observer.next(value);
                                })
                                .finally(() => {
                                    if (canComplete) {
                                        observer.complete();
                                    }
                                });
                        } else {
                            observer.next(value);
                        }
                    },
                    error: (error) => {
                        observer.error(error);
                    },
                    complete: () => {
                        if (!skipComplete) {
                            observer.complete();
                        } else {
                            canComplete = true;
                        }
                    },
                });
            });
        };
    };
