import { Id } from '@wedo/types';
import { TaskDetailCustomFieldAttachments } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldAttachments';
import { TaskDetailCustomFieldDate } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldDate';
import { TaskDetailCustomFieldMultipleChoice } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldMultipleChoice';
import { TaskDetailCustomFieldNumeric } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldNumeric';
import { TaskDetailCustomFieldShortText } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldShortText';
import { TaskDetailCustomFieldSingleChoice } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldSingleChoice';
import { useTask } from 'Shared/hooks/useTask';
import { useGetCustomFieldsQuery } from 'Shared/services/customFields';
import {
    useAddTaskCustomFieldValueMutation,
    useGetTaskCustomFieldValuesQuery,
    useRemoveTaskCustomFieldValueMutation,
    useUpdateTaskCustomFieldValueMutation,
} from 'Shared/services/taskCustomFieldValue';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export type TaskDetailCustomFieldProps = {
    taskId: Id;
    customField: CustomField;
    values: CustomFieldValue[];
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    onDelete: (customFieldId: Id, valueIds: Id[]) => Promise<void>;
    isLoading: boolean;
};

type TaskDetailCustomFieldsProps = {
    taskId: Id;
};

export const TaskDetailCustomFields = ({ taskId }: TaskDetailCustomFieldsProps) => {
    const { task } = useTask(taskId);

    const { customFields } = useGetCustomFieldsQuery(null, {
        selectFromResult: ({ data = [] }) => ({
            customFields: data.filter(
                (customField) =>
                    customField.is_global ||
                    task?.tags?.some((taskWorkspace) =>
                        customField.tags.some((workspace) => workspace.id === taskWorkspace?.id)
                    ) ||
                    customField.checklists?.some((checklist) => checklist.id === task?.checklist_id)
            ),
        }),
    });
    const { data: customFieldValues, currentData = null } = useGetTaskCustomFieldValuesQuery(taskId, {
        skip: !taskId,
        refetchOnMountOrArgChange: true,
    });
    const isCustomFieldLoading = currentData === null;

    const [removeTaskCustomFieldValue] = useRemoveTaskCustomFieldValueMutation();
    const [updateTaskCustomFieldValue] = useUpdateTaskCustomFieldValueMutation();
    const [addTaskCustomFieldValue] = useAddTaskCustomFieldValueMutation();

    const handleUpdate = async (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => {
        if (!valueId) {
            await addTaskCustomFieldValue({ taskId, customFieldId, value: changes });
        } else {
            await updateTaskCustomFieldValue({
                taskId,
                valueId,
                customFieldId,
                changes,
            });
        }
    };

    const handleDeleteAttachment = async (customFieldId: Id, attachment: { valueId: Id; attachmentId: Id }) => {
        await removeTaskCustomFieldValue({ taskId, customFieldId, valueId: attachment.valueId });
    };

    const handleDelete = async (customFieldId: Id, values: Id[]) => {
        await Promise.all(values.map((valueId) => removeTaskCustomFieldValue({ taskId, customFieldId, valueId })));
    };
    return (
        <>
            {customFields.map((customField) => {
                const values = (customFieldValues || []).filter((value) => value.custom_field_id === customField.id);

                if (customField.archived && values.length === 0) {
                    return null;
                }

                switch (customField.type) {
                    case 'short_text':
                        return (
                            <TaskDetailCustomFieldShortText
                                taskId={taskId}
                                customField={customField}
                                key={customField.id}
                                values={values}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                isLoading={isCustomFieldLoading}
                            />
                        );
                    case 'numeric':
                        return (
                            <TaskDetailCustomFieldNumeric
                                taskId={taskId}
                                customField={customField}
                                key={customField.id}
                                values={values}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                isLoading={isCustomFieldLoading}
                            />
                        );
                    case 'date':
                        return (
                            <TaskDetailCustomFieldDate
                                taskId={taskId}
                                customField={customField}
                                key={customField.id}
                                values={values}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                isLoading={isCustomFieldLoading}
                            />
                        );
                    case 'set':
                        return (
                            <TaskDetailCustomFieldMultipleChoice
                                taskId={taskId}
                                customField={customField}
                                key={customField.id}
                                values={values}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                isLoading={isCustomFieldLoading}
                            />
                        );
                    case 'enum':
                        return (
                            <TaskDetailCustomFieldSingleChoice
                                taskId={taskId}
                                customField={customField}
                                key={customField.id}
                                values={values}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                isLoading={isCustomFieldLoading}
                            />
                        );
                    case 'attachment':
                        return (
                            <TaskDetailCustomFieldAttachments
                                taskId={taskId}
                                customField={customField}
                                key={customField.id}
                                values={values}
                                onUpdate={handleUpdate}
                                onDelete={handleDeleteAttachment}
                                isLoading={isCustomFieldLoading}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};
