import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Button, FloatingPopover, Skeleton, Timeline, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { SignatureModal } from 'Pages/SignaturesPage/SignatureModal';
import { getIcon, getIconColor, getText } from 'Pages/meeting/components/MeetingHistoryModal/MeetingActivityLogsValues';
import { MeetingStatusTag } from 'Shared/components/meeting/MeetingStatusTag';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useLazyGetMeetingActivityLogsQuery } from 'Shared/services/meeting';
import { MeetingPermission, MeetingStatusExtension, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { DevFeature } from 'Shared/types/user';

export const MeetingHeaderStatusPopover = ({ meetingId }: { meetingId: Id }) => {
    const [fetchActivityLogs, { isLoading: isLoadingActivityLogs, isUninitialized }] =
        useLazyGetMeetingActivityLogsQuery();

    const [activityLogs, setActivityLogs] = useState([]);

    const { open: openModal } = useModal();
    const { i18n } = useLingui();
    const { meeting } = useMeeting(meetingId);
    const currentUser = useSessionUser();
    const { network: currentNetwork } = useCurrentNetwork();
    const hasSignatureFeature = useHasFeature(currentUser, currentNetwork, DevFeature.Signature);
    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const relevantActivityLogs = activityLogs.filter((activity) =>
        [
            'completed_signature_request',
            'created_signature_request',
            'canceled_signature_request',
            'meeting_locked',
            'meeting_unlocked',
        ].includes(activity.event_key)
    );

    const openSignatureModal = () => openModal(SignatureModal, { meetingId: meeting.id });

    return (
        <FloatingPopover
            wrapperClassName="flex items-center"
            variant="ghost"
            className="!rounded-xl flex items-center min-w-0 truncate"
            color="default"
            placement="bottom"
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    fetchActivityLogs({ meetingId })
                        .unwrap()
                        .then((data) => {
                            setActivityLogs(data);
                        });
                }
            }}
            text={
                <MeetingStatusTag size={'sm'} meetingStatus={meeting.extendedStatus}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </MeetingStatusTag>
            }
        >
            <div className={'flex flex-col gap-1 md:w-[30rem] w-screen text-sm'}>
                <div className={'max-h-60 overflow-y-auto'}>
                    {isLoadingActivityLogs || isUninitialized ? (
                        <div className={'flex gap-2 py-2 px-4 flex-col'}>
                            <Skeleton count={3} className={'h-6'} />
                        </div>
                    ) : (
                        <div className={'pl-2 pr-4 py-3'}>
                            <Timeline>
                                {[...relevantActivityLogs].reverse().map((activity) => (
                                    <Timeline.Item
                                        key={activity.id}
                                        isImportant={false}
                                        icon={getIcon(activity)}
                                        dateTime={activity.created_at}
                                        iconColor={getIconColor?.(activity, relevantActivityLogs)}
                                    >
                                        <div className="flex flex-col">
                                            <div>{getText({ activity, i18n }, relevantActivityLogs)}</div>
                                        </div>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </div>
                    )}
                </div>

                {meeting.extendedStatus !== MeetingStatusExtension.UNLOCKED &&
                    hasSignatureFeature &&
                    canManageMeeting && (
                        <div className={'border-t border-gray-200 p-2 flex items-center justify-center'}>
                            <Button color={'primary'} size="sm" onClick={openSignatureModal}>
                                {meeting.extendedStatus === MeetingStatusExtension.WAITING_SIGNATURE ? (
                                    <Trans>View signature request</Trans>
                                ) : meeting.extendedStatus === MeetingStatusExtension.SIGNED ? (
                                    <Trans>View signed document</Trans>
                                ) : (
                                    <Trans>Request signatures</Trans>
                                )}
                            </Button>
                        </div>
                    )}
            </div>
        </FloatingPopover>
    );
};
