import { useNavigate } from '@wedo/utils/hooks';
import { Notification } from 'Shared/types/notification';

export const useMeetingAction = () => {
    const navigate = useNavigate();
    return (notification: Notification) => {
        switch (notification.event_key) {
            case 'accepted_meeting_topic':
                navigate(`/topics`);
                break;
            case 'rejected_meeting_topic':
                navigate(`/topics?view=rejected`);
                break;
            default:
                break;
        }
    };
};
