import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans as TransReact } from '@lingui/react';
import React, { FC } from 'react';
import { faCreditCard, faFileInvoice } from '@fortawesome/pro-regular-svg-icons';
import { msg } from '@lingui/macro';
import { RadioGroup } from '@wedo/design-system';

type PaymentOption = 'invoice' | 'card';

type PaymentOptionRadioGroupProps = {
    value: PaymentOption;
    onChange: (value: PaymentOption) => void;
};

const PaymentOptions = [
    { id: 'invoice', label: msg`By invoice`, icon: faFileInvoice },
    { id: 'card', label: msg`By credit card`, icon: faCreditCard },
];

export const PaymentOptionRadioGroup: FC<PaymentOptionRadioGroupProps> = ({ value, onChange }) => {
    return (
        <RadioGroup
            value={value}
            name="payment-method"
            onChange={onChange}
            radioType="button"
            className="mt-2 flex gap-4"
        >
            {PaymentOptions.map((method) => (
                <RadioGroup.Radio key={method.id} value={method.id}>
                    <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon={method.icon} />
                        <TransReact id={method.label.id} />
                    </div>
                </RadioGroup.Radio>
            ))}
        </RadioGroup>
    );
};
