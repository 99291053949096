import { type Element } from 'slate';
import { getSessionUser } from 'App/store/usersStore';

export type StackNode<T> = T & { isOpen: boolean };

export type StackEntry<T> = {
    type:
        | 'attachment'
        | 'block'
        | 'heading'
        | 'lineBreak'
        | 'link'
        | 'list'
        | 'listItem'
        | 'image'
        | 'mention'
        | 'paragraph'
        | 'task'
        | 'text'
        | 'vote';
    level: number;
    node?: StackNode<T>;
    parentNode?: StackNode<T>;
    isAcceptingInline?: boolean;
    value?: string;
    attributes?: Record<string, unknown>;
};

export type AttributeStackEntry = {
    node: StackNode<unknown>;
    applyOn: 'block' | 'paragraph' | 'text';
    value: Record<string, unknown>;
};

export const evaluateStack = (
    level: number,
    stack: Array<StackEntry<unknown>>,
    keepEmptyBlocks = false
): Array<Element> => {
    const blocks: Array<Element> = [];
    const sessionUserId = __ENVIRONMENT__ === 'test' ? null : getSessionUser()?.id;
    const pushIfNotEmpty = (block: Element) => {
        if (block.children.length > 0) {
            blocks.push(block);
        } else if (keepEmptyBlocks && block.type === 'text') {
            blocks.push({ ...block, children: [{ text: '' }] });
        }
    };
    while (stack[0]?.level > level) {
        const entry = stack.shift();
        const updatedBlock = (block: Element) => ({
            ...block,
            id: entry.attributes?.id ?? crypto.randomUUID(),
            updated_by: entry.attributes?.updatedBy ?? sessionUserId,
            updated_at: entry.attributes?.updatedAt ?? new Date(),
            children:
                entry.attributes?.id != null && block.children.length === 0
                    ? [{ type: 'text', children: [{ text: '' }] }]
                    : block.children,
        });
        switch (entry.type) {
            case 'block': {
                const { variant } = entry.attributes ?? {};
                if (variant === 'attachment') {
                    blocks.push(
                        updatedBlock({
                            type: 'attachment',
                            children: [{ text: '' }],
                            attachments: entry.attributes.attachments.map(([id, filename, host, url, tags], order) => ({
                                id,
                                host,
                                order,
                                currentVersion: { filename },
                                location: { url },
                                tags,
                            })),
                        })
                    );
                } else if (variant === 'decision') {
                    pushIfNotEmpty(
                        updatedBlock({
                            type: 'decision',
                            children: evaluateStack(level + 1, stack, entry.attributes?.id != null),
                        })
                    );
                } else if (variant === 'task') {
                    blocks.push(
                        updatedBlock({
                            type: 'task',
                            children: [{ text: '' }],
                            task: entry.attributes.task,
                            task_id: entry.attributes.taskId,
                        })
                    );
                } else if (variant === 'vote') {
                    blocks.push(
                        updatedBlock({
                            type: 'vote',
                            children: [{ text: '' }],
                            vote: entry.attributes.vote,
                            vote_id: entry.attributes.voteId,
                        })
                    );
                } else {
                    pushIfNotEmpty(
                        updatedBlock({
                            type: 'paragraph',
                            children: evaluateStack(level + 1, stack, entry.attributes?.id != null),
                        })
                    );
                }
                break;
            }
            case 'lineBreak':
                blocks.push({ text: '\n' });
                break;
            case 'link':
                pushIfNotEmpty({
                    type: entry.type,
                    children: evaluateStack(level + 1, stack),
                    url: entry.attributes.url,
                });
                break;
            case 'list':
                pushIfNotEmpty({
                    type: entry.attributes?.variant === 'numberedList' ? 'numbered-list' : 'list',
                    children: evaluateStack(level + 1, stack),
                });
                break;
            case 'listItem':
                pushIfNotEmpty({
                    type: 'list-item',
                    children: evaluateStack(level + 1, stack),
                });
                break;
            case 'image': {
                const { width, height, maxWidth, align } = entry.attributes ?? {};
                blocks.push(
                    updatedBlock({
                        type: 'image',
                        children: [{ url: entry.attributes?.source, text: '' }],
                        decoration:
                            width != null && height != null && maxWidth != null && align != null
                                ? JSON.stringify({ size: { width, height, maxWidth }, textAlign: align })
                                : undefined,
                    })
                );
                break;
            }
            case 'paragraph': {
                pushIfNotEmpty({
                    type: entry.attributes?.variant === 'heading' ? 'heading' : 'text',
                    children: evaluateStack(level + 1, stack),
                    align: entry.attributes?.align,
                    level: entry.attributes?.level,
                });
                break;
            }
            case 'text': {
                const { variant, ...attributes } = entry.attributes ?? {};
                if (variant === 'mention') {
                    blocks.push({
                        type: 'mention',
                        userId: attributes.userId,
                        userFullName: attributes.userFullName,
                        children: [{ text: entry.value }],
                    });
                } else {
                    blocks.push({ text: entry.value, ...attributes });
                }
                break;
            }
            default:
        }
    }
    return blocks;
};
