import React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { Signature } from '@wedo/db';
import { FullSignatureRequest, LegalFramework, SignatureType } from 'Shared/types/signature';
import { User } from 'Shared/types/user';

export const SignatureModalContext = React.createContext<{
    signature: Signature;
    signatureRequest?: FullSignatureRequest;
    isFetchingSignatureRequest: boolean;
    meetingId: string;
    pdfInstance?: WebViewerInstance;
    signatureType: SignatureType;
    legalFramework: LegalFramework;
    lockedByUser: User;
    signatureLock: {
        isLockValid: boolean;
        userId: number;
    };
    exportedDocument: ArrayBuffer;
    setExportedDocument: (exportedDocument: ArrayBuffer) => void;
    consentUrl: string;
    setConsentUrl: (consentUrl: string) => void;
    attachmentUrl: string;
    isLoadingAttachmentUrl: boolean;
    changeSignatureStatus: (status: string) => void;
    isSigning: boolean;
    setIsSigning: (isSigning: boolean) => void;
    errorMessage: string | null;
    setErrorMessage: (errorMessage: string | null) => void;
    closeModal: () => void;
}>({});
