import React from 'react';
import { CustomFieldDropdown } from 'Pages/settings/customFields/components/CustomFieldItem/CustomFieldDropdown';
import { LastUpdatedByAvatar } from 'Pages/settings/customFields/components/LastUpdatedByAvatar';
import { ArchivedTag } from 'Pages/settings/customFields/components/tags/ArchivedTag';
import { CustomFieldTypeTag } from 'Shared/components/customField/CustomFieldTypeTag';
import { useGetUserQuery } from 'Shared/services/user';
import { CustomField } from 'Shared/types/customField';

export type GroupCardFieldsTableRowProps = {
    field: CustomField;
};

export const GroupCardFieldsTableRow: React.FC<GroupCardFieldsTableRowProps> = ({ field }) => {
    const { data: createdBy } = useGetUserQuery(field.created_by);

    return (
        <div className="flex flex-1 justify-between bg-white py-2">
            <div className="@2xl:text-base flex flex-wrap items-center text-sm">
                <span>{field.label}</span>
            </div>

            <div className="flex flex-shrink-0 items-center gap-2">
                {field.archived && <ArchivedTag />}
                <CustomFieldTypeTag fieldType={field.type} />
                <div className="flex-shrink-0">
                    <LastUpdatedByAvatar createdBy={createdBy} createdAt={field.created_at} />
                </div>
                <div className="flex-shrink-0">
                    <CustomFieldDropdown customField={field} />
                </div>
            </div>
        </div>
    );
};
