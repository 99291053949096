export const clearMarks = () => {
    performance.clearMarks();
};

export const mark = () => {
    return performance.mark(crypto.randomUUID());
};

export const measure = (firstMark: PerformanceMark, secondMark: PerformanceMark) => {
    return performance.measure(crypto.randomUUID(), firstMark.name, secondMark.name);
};

export const present = (measures: Record<string, PerformanceMeasure>) => {
    console.table(Object.fromEntries(Object.entries(measures).map(([name, { duration }]) => [name, duration])));
};
