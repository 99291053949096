import { useLingui } from '@lingui/react';
import React, { FC, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Dropdown } from '@wedo/design-system';
import { LanguageList, LanguageMap, storage } from '@wedo/utils';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';
import { LocalStorage } from 'Shared/types/localStorage';

const getLongLocale = (locale: string): string => {
    switch (locale) {
        case 'de':
            return 'de-de';
        case 'fr':
            return 'fr-fr';
        case 'it':
            return 'it-it';
        case 'en':
            return 'en-us';
        default:
            return locale.toLowerCase();
    }
};

export const SignInFooter: FC = () => {
    const { show } = useIntercom();
    const [language, setLanguage] = useState<string>();
    const { setUserLanguage } = useUserLanguage();
    const { i18n } = useLingui();

    useEffect(() => {
        const lang = storage.getItem(LocalStorage.Language) ?? getLongLocale(i18n.locale);
        setLanguage(lang);
    }, [i18n]);

    const handleChange = (lang: string) => {
        setLanguage(lang);
        setUserLanguage(lang);
    };

    return (
        <div className="bottom-0 left-0 mx-auto flex w-full flex-row items-center justify-center gap-4 py-3 sm:absolute">
            <div>
                <Dropdown
                    icon={faGlobe}
                    variant="ghost"
                    size="sm"
                    label={<span className="ml-1 uppercase">{language}</span>}
                    className="px-2 leading-8 hover:bg-gray-200"
                >
                    {LanguageList.map((lang) => (
                        <Dropdown.Item
                            key={lang}
                            className={clsx('hover:bg-highlight', lang === language && 'bg-highlight')}
                            onClick={() => handleChange(lang)}
                        >
                            <span>{LanguageMap.get(lang)}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </div>
            <Button variant="text" color="default" onClick={show}>
                <Trans>Need help?</Trans>
            </Button>
            <Button variant="text" color="default" href="https://status.wedo.swiss" target="_blank">
                <Trans>Status page</Trans>
            </Button>
        </div>
    );
};
