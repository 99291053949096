import clsx from 'clsx';
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { Tooltip } from '~/components/Tooltip/Tooltip';

type AutoTooltipTextProps = {
    tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    tooltipText?: string;
    disabled?: boolean;
    delay?: number;
} & PropsWithChildren;

export const AutoTooltipText = ({
    tooltipPlacement = 'top',
    tooltipText,
    disabled,
    children,
    delay,
}: AutoTooltipTextProps) => {
    const ref = useRef<HTMLSpanElement>();

    const [content, setContent] = useState<ReactNode>('');

    useEffect(() => {
        if (!disabled) {
            if (ref.current?.offsetWidth > ref.current?.parentElement.offsetWidth) {
                setContent(tooltipText ?? children);
            } else {
                setContent('');
            }
        } else {
            setContent('');
        }
    }, [tooltipText, children, disabled]);

    return (
        <Tooltip placement={tooltipPlacement} wrapperClassName="max-w-full grid" content={content} delay={delay}>
            <div className={clsx('max-w-full', content !== '' && 'truncate')}>
                <span ref={ref}>{children}</span>
            </div>
        </Tooltip>
    );
};
