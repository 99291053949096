import { Element } from 'slate';
import { Id } from '@wedo/types';
import { dndPlugin } from 'Shared/components/editor/plugins/dndPlugin/dndPlugin';
import { Editor } from './Editor';
import { attachmentPlugin } from './plugins/attachmentPlugin';
import { commentPlugin } from './plugins/commentPlugin/commentPlugin';
import { decisionPlugin } from './plugins/decisionPlugin';
import { imagePlugin } from './plugins/imagePlugin';
import { linePlugin } from './plugins/linePlugin';
import { linkPlugin } from './plugins/linkPlugin';
import { listPlugin } from './plugins/listPlugin';
import { paragraphPlugin } from './plugins/paragraphPlugin';
import { staticBlocksPlugin } from './plugins/staticBlocksPlugin';
import { taskPlugin } from './plugins/taskPlugin';
import { voidPlugin } from './plugins/voidPlugin';
import { votePlugin } from './plugins/votePlugin';

type ReadOnlyEditorProps = {
    blocks: Element[];
    meetingId: Id;
    topicId?: Id;
    isInert?: boolean; // all interactions are not available
};

export const ReadOnlyEditor = ({ blocks, meetingId, topicId, isInert = false }: ReadOnlyEditorProps) => {
    return (
        <Editor
            isReadOnly={true}
            plugins={[
                listPlugin(),
                votePlugin({ isReadOnly: true, isVoteOnly: false, meetingId, canDeleteVote: false }),
                taskPlugin({ isInert: isInert, meetingId }),
                imagePlugin(),
                linkPlugin(),
                decisionPlugin(),
                attachmentPlugin({ workspaceId: null }),
                paragraphPlugin(true),
                linePlugin(''),
                voidPlugin(),
                staticBlocksPlugin(blocks),
                topicId != null && commentPlugin({ topicId }),
            ].filter(Boolean)}
        />
    );
};
