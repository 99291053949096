import { FC, FormEvent, useEffect, useId, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Card, Form, Input, SavedSuccessNotification, useNotification } from '@wedo/design-system';
import { checkPasswordCompliance, PasswordRule } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { usePasswordChange } from 'Pages/settings/security/hooks/usePasswordChange';
import { CheckCompliance } from 'Shared/components/CheckCompliance';

export const ChangePasswordCard: FC = () => {
    const { show } = useNotification();
    const [oldPassword, setPasswordOld, handleOldPassword] = useInputState('');
    const [newPassword, setPasswordNew, handleNewPassword] = useInputState('');
    const [newPasswordBis, setPasswordNewBis, handleNewPasswordBis] = useInputState('');
    const { isLoading, error, isSuccess, change } = usePasswordChange();
    const id = useId();

    const isNewPasswordEnabled = oldPassword.length > 0;

    const passwordComplianceList = useMemo(() => checkPasswordCompliance(newPassword), [newPassword]);

    const isPasswordCompliant = passwordComplianceList.size === 4;
    const isPasswordMatching = newPassword === newPasswordBis;
    const showPasswordMatching = newPasswordBis.length > 0 && !isPasswordMatching;
    const disableSaveButton = !isPasswordMatching || !isPasswordCompliant || !isNewPasswordEnabled || isLoading;

    const handleSave = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        await change(oldPassword, newPassword);
    };

    const [oldSuccess, setOldSuccess] = useState(isSuccess);
    if (oldSuccess !== isSuccess) {
        setOldSuccess(isSuccess);

        if (isSuccess) {
            setPasswordOld('');
            setPasswordNew('');
            setPasswordNewBis('');
        }
    }

    useEffect(() => {
        if (isSuccess) {
            show(SavedSuccessNotification);
        }
    }, [isSuccess]);

    return (
        <Card>
            <Card.Header title={t`Password`} />
            <Card.Body>
                <Form layout="vertical" onSubmit={handleSave}>
                    <Form.Item label={t`Old password`} htmlFor={`${id}old-password`} cols={6}>
                        <Input
                            id={`${id}old-password`}
                            type="password"
                            value={oldPassword}
                            onChange={handleOldPassword}
                            status={error !== '' ? 'error' : 'default'}
                            statusText={error}
                        />
                    </Form.Item>
                    <Form.Item label={t`New password`} htmlFor={`${id}new-password`} cols={6}>
                        <Input
                            id={`${id}new-password`}
                            type="password"
                            value={newPassword}
                            onChange={handleNewPassword}
                            disabled={!isNewPasswordEnabled}
                            status={isPasswordCompliant ? 'success' : 'default'}
                            statusText={isPasswordCompliant ? t`Great! Your password is secure.` : ''}
                        />
                        {!isPasswordCompliant && (
                            <div className="mt-4 grid grid-cols-2 gap-2">
                                <CheckCompliance
                                    text={t`One lowercase character`}
                                    isPassing={passwordComplianceList.has(PasswordRule.Lowercase)}
                                />
                                <CheckCompliance
                                    text={t`One number`}
                                    isPassing={passwordComplianceList.has(PasswordRule.Number)}
                                />
                                <CheckCompliance
                                    text={t`One special character`}
                                    isPassing={passwordComplianceList.has(PasswordRule.Special)}
                                />
                                <CheckCompliance
                                    text={t`8 characters minimum`}
                                    isPassing={passwordComplianceList.has(PasswordRule.Size)}
                                />
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label={t`Confirm password`} htmlFor={`${id}confirm-password`} cols={6}>
                        <Input
                            id={`${id}confirm-password`}
                            type="password"
                            value={newPasswordBis}
                            onChange={handleNewPasswordBis}
                            disabled={!isNewPasswordEnabled}
                            status={showPasswordMatching ? 'error' : 'default'}
                            statusText={showPasswordMatching ? t`New passwords don't match` : ''}
                        />
                    </Form.Item>

                    <Form.Item cols={6} htmlFor="" label="">
                        <Button type="submit" loading={isLoading} disabled={disableSaveButton} color="primary">
                            <Trans>Change password</Trans>
                        </Button>
                    </Form.Item>
                </Form>
            </Card.Body>
        </Card>
    );
};
