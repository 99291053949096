import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { taskActivitiesTag, taskTagType } from 'Shared/services/task';
import { Task } from 'Shared/types/task';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType: taskWorkspaceTagType, tag: taskWorkspaceTag, tags } = configureTag('TaskWorkspace');

export const taskWorkspaceApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [taskWorkspaceTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addTaskTag: build.mutation<Task, { taskId: Id; workspaceId: Id }>({
                query: ({ taskId, workspaceId }) => ({
                    url: `tasks/${taskId}/tags`,
                    body: { id: workspaceId },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [taskWorkspaceTag(resourceId(taskTagType, taskId)), taskActivitiesTag(taskId)];
                },
            }),

            removeTaskTag: build.mutation<Task, { taskId: Id; workspaceId: Id }>({
                query: ({ taskId, workspaceId }) => ({
                    url: `tasks/${taskId}/tags/${workspaceId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { taskId }) => {
                    invalidateCachedTasks();
                    return [taskWorkspaceTag(resourceId(taskTagType, taskId)), taskActivitiesTag(taskId)];
                },
            }),
        }),
    });

export const { useAddTaskTagMutation, useRemoveTaskTagMutation } = taskWorkspaceApi;
