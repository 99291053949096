import { FC, useMemo } from 'react';
import { t } from '@lingui/macro';
import { ContextModalProps, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { Config, DuplicateEntityModal } from 'Shared/components/duplicate/DuplicateEntityModal/DuplicateEntityModal';
import {
    useDuplicateWorkspaceMutation,
    useGetDuplicateWorkspaceInfoQuery,
    useGetWorkspaceQuery,
} from 'Shared/services/workspace';

type DuplicateWorkspaceModalProps = { workspaceId: Id } & ContextModalProps;

export const DuplicateWorkspaceModal: FC<DuplicateWorkspaceModalProps> = ({ workspaceId, ...modalProps }) => {
    const navigate = useNavigate();
    const { show } = useNotification();

    const { data: workspace } = useGetWorkspaceQuery(workspaceId);
    const { data: info } = useGetDuplicateWorkspaceInfoQuery(workspaceId);

    const [duplicateWorkspace] = useDuplicateWorkspaceMutation();

    const startDate = useMemo(() => new Date(info?.startDate), [info]);
    const endDate = useMemo(() => new Date(info?.endDate), [info]);

    const handleDuplicate = async (config: Config, payload): Promise<boolean> => {
        const response = await duplicateWorkspace({
            workspaceId,
            config,
            workspace: {
                name: payload?.name,
                color: {
                    text: '#000',
                    background: payload?.color,
                },
                team_id: payload?.teamId,
                description: workspace?.description,
                settings: workspace?.settings,
            },
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return Promise.resolve(false);
        }
        await modalProps.close();
        void navigate(`workspaces/${response?.data?.id}/tasks`);
        return Promise.resolve(false);
    };

    return (
        <DuplicateEntityModal
            {...modalProps}
            entity={workspace}
            entityName="workspace"
            onDuplicate={handleDuplicate}
            modalTitle={t`Duplicate workspace ${workspace?.name}`}
            startDate={startDate}
            endDate={endDate}
        />
    );
};
