import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button } from '@wedo/design-system';

interface CustomFieldButtonProps {
    icon: IconDefinition;
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>) => void;
}

export const CustomFieldButton: React.FC<CustomFieldButtonProps> = ({ icon, label, onClick }) => {
    return (
        <Button onClick={onClick} icon={icon} iconSize="xl" className="!justify-start">
            {label}
        </Button>
    );
};
