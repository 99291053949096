import React from 'react';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { Button, FormatDate, Table } from '@wedo/design-system';
import { ChargebeeInvoice } from '@wedo/types';
import { InvoiceAmount } from 'Pages/settings/billing/components/InvoicesCard/InvoiceAmount';
import { InvoiceStatusTag } from 'Pages/settings/billing/components/InvoicesCard/InvoiceStatusTag';
import { useGetInvoiceUrlQuery } from 'Shared/services/organization';

type InvoiceTableRowProps = {
    invoice: ChargebeeInvoice;
};

export const InvoiceTableRow: React.FC<InvoiceTableRowProps> = ({ invoice }) => {
    const { data: invoiceUrl } = useGetInvoiceUrlQuery(invoice.id);

    return (
        <Table.Row>
            <Table.Cell>
                <span className="font-medium">{invoice.id}</span>
            </Table.Cell>
            <Table.Cell>
                <FormatDate format="PPP" date={new Date(invoice.date)} />
            </Table.Cell>
            <Table.Cell>
                <InvoiceAmount invoice={invoice} />
            </Table.Cell>

            <Table.Cell className="text-center">
                <InvoiceStatusTag status={invoice.status} />
            </Table.Cell>

            <Table.Cell>
                <Button title={t`Download invoice`} icon={faDownload} onClick={() => open(invoiceUrl.download_url)} />
            </Table.Cell>
        </Table.Row>
    );
};
