import React, { ChangeEventHandler, forwardRef } from 'react';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import { HTMLTextAreaElement } from 'happy-dom';
import { Button, Textarea, UploadButton, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { useSessionUser } from 'App/store/usersStore';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { DevFeature } from 'Shared/types/user';
import { ImportAgendaAIModal } from './ImportAgendaAIModal';

type ShowPasteTextContentProps = {
    textAreaContent: string;
    handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement>;
    handleImportXlsx: (file: File) => void;
    meetingId: Id;
};

const ImportUsersFiles = {
    de: '/wedo-toc-template-de.xlsx',
    en: '/wedo-toc-template-en.xlsx',
    fr: '/wedo-toc-template-fr.xlsx',
    it: '/wedo-toc-template-it.xlsx',
};

export const PasteTextStep = forwardRef<HTMLTextAreaElement, ShowPasteTextContentProps>(
    ({ textAreaContent, handleTextareaChange, handleImportXlsx, meetingId }, ref) => {
        const { open } = useModal();
        const { userLanguage } = useCurrentUserContext();
        const currentUser = useSessionUser();
        const { network } = useCurrentNetwork();
        const hasMeetingAI = useHasFeature(currentUser, network, DevFeature.MeetingAI);

        const url = ImportUsersFiles[userLanguage] ?? ImportUsersFiles.en;

        const handleGenerateAgendaAI = () => {
            open(ImportAgendaAIModal, {
                meetingId: meetingId,
                onDone: (text: string) => handleTextareaChange({ target: { value: text } }, true),
            });
        };

        return (
            <div className={'flex flex-col gap-2'}>
                {hasMeetingAI && (
                    <div className="mb-2">
                        <Button
                            className="mt-4"
                            icon={faSparkles}
                            variant="outlined"
                            color="primary"
                            onClick={handleGenerateAgendaAI}
                        >
                            <Trans>Generate an agenda using AI</Trans>
                        </Button>
                    </div>
                )}
                <Textarea
                    ref={ref}
                    rows={3}
                    placeholder={t`Paste your text here`}
                    value={textAreaContent}
                    autoSize={{ minRows: 5, maxRows: 30 }}
                    onChange={handleTextareaChange}
                />
                <div className="bg-gray-100 p-4 rounded-md">
                    <p className={'text-sm'}>
                        {t`You can paste a bullet list, numbered list or any text with line breaks (from Word, Excel or anywhere else).`}
                    </p>
                    <ul className={'list-inside list-disc text-sm pl-2 mt-1'}>
                        <li>{t`Each line will be a new item (section or topic).`}</li>
                        <li>{t`The numbering or text indent will be used to detect the multi-levels.`}</li>
                        <li>{t`The last levels are topics, all other levels are sections.`}</li>
                    </ul>
                </div>
                <div className="mt-2">
                    <UploadButton color="primary" icon={faFileExcel} onFileSelect={handleImportXlsx} accept=".xlsx">
                        <Trans>Import from file</Trans>
                    </UploadButton>
                    <span className="text-sm ml-2">
                        <a href={url} className="font-bold text-blue-600 hover:underline">
                            <Trans>Click here</Trans>
                        </a>{' '}
                        <Trans>to download our Excel template</Trans>
                    </span>
                </div>
            </div>
        );
    }
);
