import { FC, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { t, Trans } from '@lingui/macro';
import { Button } from '~/components/Button/Button';
import { Modal } from '~/components/Modal/Modal';
import { ContextModalProps } from '~/components/Modal/Modals';
import { EmptyFunction } from '@wedo/utils';
import { canvasPreview } from './utils';

export type EditImageModalProps = {
    image: File;
    title?: string;
    header?: ReactNode;
    content?: ReactNode;
    aspect?: number | undefined;
    showPreview?: boolean;
    showCroppedImageSize?: boolean;
    onEdit?: (image: PixelCrop) => void;
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    hasCircularCrop?: boolean;
} & ContextModalProps;

export const EditImageModal: FC<EditImageModalProps> = ({
    title,
    header,
    content,
    image,
    aspect,
    showPreview = false,
    showCroppedImageSize = false,
    onEdit = EmptyFunction,
    minHeight = 0,
    minWidth = 0,
    maxHeight = 2560,
    maxWidth = 2560,
    hasCircularCrop = false,
    ...modalProps
}) => {
    const [percentageCrop, setPercentageCrop] = useState<Crop>();
    const [pixelCrop, setPixelCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [imgSrc, setImgSrc] = useState<string>();
    const previewCanvasRef = useRef<HTMLCanvasElement>();
    const imageRef = useRef<HTMLImageElement>(null);

    const scaleX = imageRef?.current?.width ? imageRef?.current?.naturalWidth / imageRef?.current?.width : 1;
    const scaleY = imageRef?.current?.width ? imageRef?.current?.naturalHeight / imageRef?.current?.height : 1;

    useEffect(() => {
        const reader = new FileReader();
        reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
        reader.readAsDataURL(image);
    }, [image]);

    useEffect(() => {
        if (
            showPreview &&
            completedCrop?.width &&
            completedCrop?.height &&
            imageRef.current &&
            previewCanvasRef.current
        ) {
            void canvasPreview(imageRef.current, previewCanvasRef.current, completedCrop);
        }
    }, [completedCrop, showPreview]);

    const handleEdit = (): void => {
        void modalProps.close();
        onEdit({
            ...completedCrop,
            x: completedCrop.x * scaleX,
            y: completedCrop.y * scaleY,
            width: completedCrop.width * scaleX,
            height: completedCrop.height * scaleY,
        });
    };

    const onImageLoad = (e: SyntheticEvent<HTMLImageElement>): void => {
        const { width, height } = e.currentTarget;
        if (aspect) {
            setPercentageCrop({
                unit: '%',
                height: width / aspect <= height ? (width / aspect / height) * 100 : 100,
                width: width / aspect <= height ? 100 : ((height * aspect) / width) * 100,
                x: 0,
                y: 0,
            });
        } else {
            setPixelCrop({
                unit: 'px',
                height,
                width,
                x: 0,
                y: 0,
            });
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={title ?? 'Crop image'}>{header}</Modal.Header>

            <Modal.Body>
                {content}

                {imgSrc != null && (
                    <div className="bg-gray-200 p-5">
                        <ReactCrop
                            crop={aspect ? percentageCrop : pixelCrop}
                            onChange={(pixelCrop, percentCrop) => {
                                setPercentageCrop(percentCrop);
                                setPixelCrop(pixelCrop);
                            }}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            minHeight={minHeight / scaleY}
                            minWidth={minWidth / scaleX}
                            maxHeight={maxHeight}
                            maxWidth={maxWidth}
                            circularCrop={hasCircularCrop}
                        >
                            <img ref={imageRef} alt={t`Cropped`} src={imgSrc} onLoad={onImageLoad} />
                        </ReactCrop>
                    </div>
                )}

                {!!completedCrop && showPreview && (
                    <div className="mt-6">
                        <h2 className="mb-2">Preview</h2>
                        <canvas
                            ref={previewCanvasRef}
                            className="border border-gray-400"
                            style={{
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                )}

                {showCroppedImageSize && pixelCrop && (
                    <div className={'mt-4 text-sm text-gray-600'}>
                        {(pixelCrop?.width * scaleX).toFixed(0)} x {(pixelCrop?.height * scaleY).toFixed(0)} pixels
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" onClick={handleEdit}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
