import { Id } from '@wedo/types';
import { User } from 'Shared/types/user';

export enum ActivityLogObject {
    ATTACHMENT = 'attachment',
    CHECKLIST = 'checklist',
    MAILING = 'mailing',
    MEETING = 'meeting',
    NETWORK = 'network',
    ORGANIZATION = 'organisation',
    TASK = 'task',
    TEAM = 'team',
    TEMPLATE = 'checklist_template',
    WORKSPACE = 'tag',
    USER = 'user',
}

export interface ActivityLog<T = Record<string, any>> {
    id: Id;
    object_id?: Id;
    object_type: string;
    event_key: string;
    event_action?: string;
    new_value: T;
    old_value: T;
    created_at: string;
    // TODO Replace User by Id
    created_by?: User;

    // Related
    object?: unknown;
}
