import React from 'react';
import { AvatarSizes } from '@wedo/design-system';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { User } from 'Shared/types/user';
import { getFormattedUserRole } from 'Shared/utils/user';

type UserDataProps = {
    user: User;
    avatarSize?: AvatarSizes;
    hideUserRole?: boolean;
};

export const UserData: React.FC<UserDataProps> = ({ user, avatarSize = 'sm', hideUserRole = false }) => {
    return (
        <div className="flex items-center gap-2">
            <UserAvatar user={user} size={avatarSize} />
            <div className="flex flex-col justify-center">
                <span>{user?.full_name}</span>
                {!hideUserRole && <span className="text-sm text-gray-500">{getFormattedUserRole(user?.role)}</span>}
            </div>
        </div>
    );
};
