import { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { getColorId } from '@wedo/design-system';
import { trpc } from 'Shared/trpc';
import { useGanttContextStore } from './GanttContext';
import { type Task, type Section } from './types';

type TimelineTaskConnectorProps = {
    task: Task;
    section: Section;
    direction: 'to' | 'from';
};

export const TimelineTaskConnector = ({ task, section, direction }: TimelineTaskConnectorProps) => {
    const store = useGanttContextStore()!;

    const ref = useRef<HTMLDivElement>(null);

    const { mutateAsync: addDependencies } = trpc.task.addDependencies.useMutation();

    const handlePointerMove = useCallback(({ target, clientX, clientY }: PointerEvent) => {
        store.setState((state) => {
            const floatingDependency = state.view.floatingDependency!;

            if (floatingDependency.hoverElement != null) {
                floatingDependency.hoverElement.classList.remove('connected', 'can-connect', 'cannot-connect');
            }

            const hoverElement = (target as HTMLElement).closest('[data-task-id]') as HTMLElement;

            if (hoverElement != null) {
                const hoverId = hoverElement.dataset.taskId!;
                const canConnect =
                    hoverId !== floatingDependency.id &&
                    !state.data.dependencies.get(floatingDependency.id)?.some(({ toId }) => toId === hoverId) &&
                    !state.data.dependencies.get(hoverId)?.some(({ toId }) => toId === floatingDependency.id);
                hoverElement.classList.add('connected', canConnect ? 'can-connect' : 'cannot-connect');
                floatingDependency.canConnect = canConnect;
                floatingDependency.hoverId = hoverId;
                floatingDependency.hoverElement = hoverElement;
            } else {
                floatingDependency.canConnect = false;
                floatingDependency.hoverId = null;
                floatingDependency.hoverElement = null;
            }

            floatingDependency.pointerPosition = new DOMPoint(clientX, clientY - 7);
        });
    }, []);

    const handlePointerUp = useCallback(() => {
        const floatingDependency = store.getState().view.floatingDependency!;
        Object.assign(document.body.style, { userSelect: 'auto' });
        document.documentElement.style.cursor = 'auto';
        ref.current!.parentElement!.parentElement!.classList.remove('dragging');
        document.removeEventListener('pointermove', handlePointerMove);
        document.removeEventListener('pointerup', handlePointerUp);
        floatingDependency.hoverElement?.classList.remove('connected', 'can-connect', 'cannot-connect');
        if (floatingDependency.hoverId != null && floatingDependency.canConnect) {
            const fromId =
                floatingDependency.direction === 'from' ? floatingDependency.id! : floatingDependency.hoverId!;
            const toId = floatingDependency.direction === 'to' ? floatingDependency.id! : floatingDependency.hoverId!;
            // We optimistically update the store
            const revert = () => {
                store.setState((state) => {
                    const dependencies = state.data.dependencies.get(fromId);
                    if (dependencies != null) {
                        state.data.dependencies.set(
                            fromId,
                            dependencies.filter(
                                (dependency) => dependency.fromId !== fromId || dependency.toId !== toId
                            )
                        );
                    }
                });
            };
            store.setState((state) => {
                const dependencies = (state.data.dependencies.get(fromId) ?? []).concat({ fromId, toId });
                state.data.dependencies.set(fromId, dependencies);
            });
            addDependencies([{ blockedTaskId: Number(toId), blockingTaskId: Number(fromId) }])
                .then((dependencies) => {
                    if (dependencies.length === 0) {
                        revert();
                    }
                })
                .catch(() => revert);
        }
        store.setState((state) => {
            state.view.floatingDependency = null;
        });
    }, []);

    const handlePointerDown = () => {
        Object.assign(document.body.style, { userSelect: 'none' });
        document.documentElement.style.cursor = 'default';
        ref.current!.parentElement!.parentElement!.classList.add('dragging');
        document.addEventListener('pointermove', handlePointerMove);
        document.addEventListener('pointerup', handlePointerUp);
        store.setState((state) => {
            state.view.floatingDependency = {
                canConnect: false,
                id: task.id,
                element: ref.current!.parentElement!.parentElement!,
                direction,
            };
        });
    };

    return (
        <div
            className={clsx(
                'absolute transition-transform-opacity opacity-0 self-center flex items-center group-[.dragging]:opacity-0 group-[:hover:not(.connected)]:opacity-100',
                direction === 'to'
                    ? 'group-[:hover:not(.connected)]:-translate-x-full flex-row-reverse'
                    : 'group-[:hover:not(.connected)]:translate-x-full',
                direction === 'to' ? 'left-0' : 'right-0'
            )}
        >
            <div
                className={clsx(
                    `h-px bg-${getColorId(section.color)}-300`,
                    task.type === 'milestone' ? 'w-3' : 'w-5',
                    direction === 'to' ? '-ml-2' : '-mr-2'
                )}
            />
            <div
                ref={ref}
                className={`w-4 h-4 flex items-center justify-center rounded-full hover:bg-white hover:border hover:border-${getColorId(section.color)}-300 group-[:not(.connected)]:cursor-e-resize`}
                onPointerDown={handlePointerDown}
            >
                <div className={`w-2 h-2 bg-${getColorId(section.color)}-300 rounded-full`} />
            </div>
        </div>
    );
};
