import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Switch } from '@wedo/design-system';
import { CalendarSyncItem } from 'Shared/components/calendar/CalendarSync/CalendarSyncItem';

export type SectionItemChoiceProps = {
    itemIncluded: Set<CalendarSyncItem>;
    onChange: (itemList: Set<CalendarSyncItem>) => void;
};

const itemText = (item: CalendarSyncItem): string => {
    switch (item) {
        case CalendarSyncItem.TaskPlanned:
            return t`Tasks with a start date`;
        case CalendarSyncItem.TaskDue:
            return t`Tasks with a due date`;
        case CalendarSyncItem.Meeting:
            return t`Meeting`;
        default:
            return item;
    }
};

export const SyncItemSection = ({ itemIncluded, onChange }: SectionItemChoiceProps): JSX.Element => {
    const handleChange = (item: CalendarSyncItem, checked: boolean) => {
        const newList = new Set(itemIncluded);

        if (checked) {
            newList.add(item);
        } else {
            newList.delete(item);
        }

        onChange(newList);
    };

    return (
        <div className="pb-4">
            <h4 className="mb-4 text-base font-semibold">
                <Trans>Items to synchronize</Trans>
            </h4>

            <div className="flex flex-col gap-4 text-sm">
                {[CalendarSyncItem.TaskPlanned, CalendarSyncItem.TaskDue, CalendarSyncItem.Meeting].map((item) => (
                    <Switch.Group key={item}>
                        <div className="flex items-center gap-4">
                            <Switch checked={itemIncluded.has(item)} onChange={(e) => handleChange(item, e)} />
                            <Switch.Label>{itemText(item)}</Switch.Label>
                        </div>
                    </Switch.Group>
                ))}
            </div>
        </div>
    );
};
