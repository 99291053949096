import React, { FC } from 'react';
import { Tag, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { Task } from 'Shared/types/task';

export const TaskChecklistOrderIcon: FC<{ task: Task; checklistId: Id }> = ({ task, checklistId }) => {
    // Task in template
    if (task.checklist?.checklist_template_id === null) {
        return (
            <Tag size="xs" className="block min-w-[30px] bg-gray-300 text-gray-900" color="none">
                {task.order}
            </Tag>
        );
    }

    // Task in checklist
    if (task.checklist?.id === checklistId) {
        return (
            <Tooltip content={`${task.checklist.name} (${task.checklist.checklist_template.name})`}>
                <Tag size="xs" className="block min-w-[30px] bg-gray-300 text-gray-900" color="none">
                    <span>{task.order}</span>
                </Tag>
            </Tooltip>
        );
    }

    // Task everywhere else
    return (
        <Tooltip content={`${task.checklist.name} (${task.checklist.checklist_template.name})`}>
            <Tag size="xs" className="block flex-1 truncate bg-gray-200 text-gray-800" color="none">
                <span className="-mx-1 inline-block h-full min-w-[20px] rounded-full bg-gray-300 text-gray-900">
                    {task.order}
                </span>
                <span className="@xl:block hidden"> {task.checklist.name}</span>
            </Tag>
        </Tooltip>
    );
};
