import { t, Trans } from '@lingui/macro';
import { Accordion, Button } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';

export const CancelSubscriptionBanner = () => {
    const { userLanguage } = useCurrentUserContext();

    return (
        <Accordion.Item title={t`Want to cancel your subscription?`} className="overflow-hidden rounded-md bg-gray-50">
            <div className="text-sm">
                <p className="mt-4">
                    <Trans>We are sorry to see you leave. But before you go, have you tried:</Trans>
                </p>

                <ol>
                    <li>
                        <Button
                            variant="link"
                            color="primary"
                            href={`https://help.wedo.com/${userLanguage}`}
                            target="_blank"
                        >
                            <Trans>Our Help Center</Trans>
                        </Button>
                    </li>

                    <li>
                        <Button variant="link" href="mailto:info@wedo.com" color="primary">
                            <Trans>Contacting us to request more training</Trans>
                        </Button>
                    </li>
                </ol>

                <p className="mt-2">
                    <Trans>
                        If you still want to cancel your subscription write us an email at{' '}
                        <Button variant="link" href="mailto:info@wedo.com" color="primary">
                            info@wedo.com
                        </Button>
                        .
                    </Trans>
                </p>
            </div>
        </Accordion.Item>
    );
};
