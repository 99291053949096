import { ReactNode } from 'react';
import {
    faBriefcase,
    faCalendarCheck,
    faCalendarExclamation,
    faCalendarXmark,
    faClipboardListCheck,
    faComment,
    faExclamationCircle,
    faEye,
    faInputText,
    faPaperclip,
    faSquare,
    faText,
    faUser,
    faUserCheck,
    faUserPen,
    faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Id } from '@wedo/types';
import { CustomField, CustomFieldType } from 'Shared/types/customField';
import { FieldConditionFilter, Filter, Operator } from 'Shared/types/filter';

export const myTasksFilter = (workspaceId: Id, currentUserId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: currentUserId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const watchedTasksFilter = (workspaceId: Id, currentUserId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'watched_by',
                            value: currentUserId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const createdByTasksFilter = (workspaceId: Id, currentUserId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'created_by',
                            value: currentUserId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const allTasksFilter = (workspaceId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const unassignedTasksFilter = (workspaceId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: null,
                            operator: 'IS NULL',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const assignedTasksFilter = (workspaceId: Id, userId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assigned_by',
                            value: userId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const defaultFilter = (workspaceId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',
        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: undefined,
                            operator: '=',
                        },
                        {
                            field: 'watched_by',
                            value: undefined,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: undefined,
                            operator: '=',
                        },
                    ],
                },
            ],
        },
        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const Operators: { [operator in Operator]: ReactNode } = {
    '=': <Trans>is</Trans>,
    '<>': <Trans>is not</Trans>,
    '>': <Trans>is more than</Trans>,
    '<': <Trans>is less than</Trans>,
    '>=': <Trans>is more or equal to</Trans>,
    '<=': <Trans>is less or equal to</Trans>,
    'IS NULL': <Trans>is empty</Trans>,
    'IS NOT NULL': <Trans>is not empty</Trans>,
    on: <Trans>on</Trans>,
    within_next: <Trans>within the next</Trans>,
    within_last: <Trans>within the last</Trans>,
    BETWEEN: <Trans>between</Trans>,
    contains: <Trans>contains</Trans>,
    not_contains: <Trans>does not contain</Trans>,
    starts_with: <Trans>starts with</Trans>,
    not_starts_with: <Trans>does not start with</Trans>,
    ends_with: <Trans>ends with</Trans>,
    not_ends_with: <Trans>does not end with</Trans>,
};

export const FieldTypes = {
    numeric: ['=', '<>', '>', '<', '>=', '<=', 'IS NULL', 'IS NOT NULL'],
    date: ['on', 'within_next', 'within_last', 'BETWEEN', 'IS NULL', 'IS NOT NULL'],
    short_text: [
        '=',
        '<>',
        'IS NULL',
        'IS NOT NULL',
        'contains',
        'not_contains',
        'starts_with',
        'not_starts_with',
        'ends_with',
        'not_ends_with',
    ],
    set: ['=', '<>', 'contains', 'not_contains', 'IS NULL', 'IS NOT NULL'],
    enum: ['=', '<>', 'IS NULL', 'IS NOT NULL'],
    user: ['=', '<>', 'IS NULL', 'IS NOT NULL'],
    priority: ['=', '<>'],
    tag: ['=', '<>', 'IS NULL', 'IS NOT NULL'],
    checklist: ['IS NULL', 'IS NOT NULL'],
    comment: ['IS NULL', 'IS NOT NULL'],
    attachment: ['IS NULL', 'IS NOT NULL'],
} as const;

export const Fields = {
    assignee_id: { type: 'user', name: <Trans>Assignee</Trans>, icon: faUser },
    tag: { type: 'tag', name: <Trans>Workspace</Trans>, icon: faBriefcase },
    priority: { type: 'priority', name: <Trans>Priority</Trans>, icon: faSquare },
    planned_date: { type: 'date', name: <Trans>Start date</Trans>, icon: faCalendarExclamation },
    due_date: { type: 'date', name: <Trans>Due date</Trans>, icon: faExclamationCircle },
    completed_at: { type: 'date', name: <Trans>Completion date</Trans>, icon: faCalendarCheck },
    deleted_at: { type: 'date', name: <Trans>Deleted date</Trans>, icon: faCalendarXmark },
    attachment: { type: 'attachment', name: <Trans>Attachment</Trans>, icon: faPaperclip },
    comment: { type: 'comment', name: <Trans>Comment</Trans>, icon: faComment },
    checklist_id: { type: 'checklist', name: <Trans>Checklist</Trans>, icon: faClipboardListCheck },
    watched_by: { type: 'user', name: <Trans>Watcher</Trans>, icon: faEye },
    assigned_by: { type: 'user', name: <Trans>Assigned by</Trans>, icon: faUserPen },
    created_by: { type: 'user', name: <Trans>Created by</Trans>, icon: faUserPlus },
    completed_by: { type: 'user', name: <Trans>Completed by</Trans>, icon: faUserCheck },
    name: { type: 'short_text', name: <Trans>Task name</Trans>, icon: faInputText },
    description: { type: 'short_text', name: <Trans>Description</Trans>, icon: faText },
} as const;

export type PreSetFilter = keyof typeof Fields;

export const fields = Object.entries(Fields).map(([id, { name, icon }]) => ({
    value: id as PreSetFilter,
    label: name,
    icon: icon,
}));

export const isAttachmentFilterType = (filter: FieldConditionFilter, customFields: CustomField[]) =>
    filter === 'attachment' ||
    filter === 'comment' ||
    filter === 'checklist_id' ||
    customFields?.find((field) => field.id === filter)?.type === CustomFieldType.Attachment;

export const isDateFilterType = (filter: FieldConditionFilter, customFields: CustomField[]) =>
    filter === 'due_date' ||
    filter === 'completed_at' ||
    filter === 'deleted_at' ||
    filter === 'planned_date' ||
    customFields?.find((field) => field.id === filter)?.type === CustomFieldType.Date;
