import { msg } from '@lingui/macro';
import { HttpMethod } from '@wedo/types';
import { TransformParameter } from 'Shared/types/apiError';
import { baseApi, configureTag } from './base';

export const createWebhookError = {
    INVALID_URL: {
        code: 'ValidationError',
        path: ['url', 'string.uri'],
        message: msg`Invalid URL`,
    },
    UNSAFE_URL: {
        code: 'BadRequestError',
        path: ['Unsafe URL'],
        message: msg`URL unsafe`,
    },
    INVALID_REQUEST: {
        code: 'Request data is invalid',
        message: msg`The webhook's data is invalid, please verify the URL.`,
    },
} satisfies TransformParameter;

export type CreateProps = {
    eventAction: { code: string };
    event_action?: string;
    objectType: { code: string };
    object_type?: string;
    url: string;
};

export type WebhookType = {
    created_at: string;
    created_by: string;
    event_action: string;
    id: string;
    last_attempt_at: string | null;
    last_attempt_status: string | null;
    network_id: string;
    object_type: string;
    updated_at: string;
    url: string;
};

const { tagType, tag } = configureTag('Webhook');

export const webhookApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getWebhookList: build.query<WebhookType[], void>({
                query: () => ({ url: 'webhooks' }),
                providesTags: () => [tag(tagType)],
            }),

            deleteWebhook: build.mutation<{ result: string }, string>({
                query: (id: string) => ({ url: `webhooks/${id}`, method: HttpMethod.Delete }),
                invalidatesTags: (_, error) => {
                    if (error === undefined) {
                        return [tag(tagType)];
                    }
                    return [];
                },
            }),

            createWebhook: build.mutation<WebhookType, CreateProps>({
                query: (body) => ({ url: 'webhooks', method: HttpMethod.Post, body }),
                invalidatesTags: (_, error) => {
                    if (error === undefined) {
                        return [tag(tagType)];
                    }

                    return [];
                },
                transformErrorResponse: (error) => error.transform(createWebhookError),
            }),
        }),
    });

export const { useCreateWebhookMutation, useGetWebhookListQuery, useDeleteWebhookMutation } = webhookApi;
