import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Alert,
    Button,
    CloseSource,
    CodeInput,
    ContextModalProps,
    Label,
    Modal,
    Steps,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { EmptyObject } from '@wedo/utils';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import {
    SignatoryInformationForm,
    SignatoryInformationFormHandle,
} from 'Shared/components/signature/SignatoryInformationForm';
import { trpc, trpcUtils } from 'Shared/trpc';
import { SignatureCertificate } from 'Shared/types/signature';

type SignatoryInformationModalProps = ContextModalProps &
    PropsWithChildren & { onDone: (signatureSettings: SignatureCertificate) => void };
export const SignatoryInformationModal: FC<SignatoryInformationModalProps> = ({ children, onDone, ...modalProps }) => {
    const { show: showNotification } = useNotification();
    const { confirm } = useConfirm();
    const form = useRef<SignatoryInformationFormHandle>(null);
    const [step, setStep] = useState(1);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [isValidatingForm, setIsValidatingForm] = useState(false);
    const [codeIsVerified, setCodeIsVerified] = useState(false);
    const [error, setError] = useState('');
    const [signatoryInformation, setSignatoryInformation] = useState<SignatureCertificate>(EmptyObject);
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
    const [phoneError, setPhoneError] = useState<String>(null);
    const { mutateAsync: verifyVerificationCode, isLoading: isVerifyingCode } =
        trpc.signature.verifyPhoneVerificationCode.useMutation({
            onError: () => {
                setError(t`Invalid verification code`);
            },
        });
    const { mutateAsync: sendVerificationCodeAsync, isLoading: isSendingVerificationCode } =
        trpc.signature.sendPhoneVerificationCode.useMutation({
            onError: (e: unknown) => {
                if (e.shape?.message === 'landline') {
                    // it needs to be a new string so the operator "===" understands it's a new error,
                    // even though it contains the same message
                    setPhoneError(new String(t`A landline number can't receive SMS`));
                } else {
                    showNotification(UnexpectedErrorNotification);
                }
            },
        });

    const onSignatoriesFormDone = async (signatureSettings: SignatureCertificate) => {
        if (signatureSettings != null) {
            setSignatoryInformation(signatureSettings);
            await sendVerificationCodeAsync(signatureSettings.phone);
            setPhoneNumberIsValid(true);
            setStep(2);
        }
        setIsValidatingForm(false);
    };

    const handleNext = () => {
        setIsValidatingForm(true);
        form.current.save();
    };

    const handleSave = async (confirmationCode: string) => {
        const res = await verifyVerificationCode({
            phoneNumber: signatoryInformation?.phone,
            verificationCode: confirmationCode,
        });
        if (res != null) {
            setCodeIsVerified(true);
            await trpcUtils().user.getSettingsByKey.invalidate('signature');
            void modalProps.close();
        }
    };

    const handleBeforeClose = async (source: CloseSource) => {
        let close = true;
        if (!phoneNumberIsValid) {
            return close;
        }
        if (source !== 'api') {
            close = confirm(
                {
                    title: t`Are you sure you want to leave without confirming your phone number?`,
                    confirmText: t`Leave`,
                },
                ConfirmDiscardChangesModal
            );
        }
        return close;
    };

    useEffect(() => {
        return () => {
            if (codeIsVerified) {
                onDone?.(signatoryInformation);
            }
        };
    }, [codeIsVerified]);

    return (
        <Modal {...modalProps} onBeforeClose={handleBeforeClose}>
            <Modal.Header title={t`Signatory information`} />
            <Steps currentIndex={step}>
                <Steps.Step index={1}>
                    <Trans>Phone number</Trans>
                </Steps.Step>
                <Steps.Step index={2}>
                    <Trans>Confirm code</Trans>
                </Steps.Step>
            </Steps>

            <Modal.Body className={'flex flex-col gap-4'}>
                {step === 1 && (
                    <SignatoryInformationForm
                        ref={form}
                        disabled={phoneNumberIsValid}
                        hideAlert={phoneNumberIsValid}
                        onDone={onSignatoriesFormDone}
                        phoneError={phoneError}
                    />
                )}
                {step === 2 && (
                    <div className={'flex flex-col gap-2'}>
                        <Alert type={'info'}> {t`An SMS code has been sent to your mobile phone.`}</Alert>
                        <Label>{t`Confirmation code`}</Label>
                        <CodeInput
                            autofocus
                            onComplete={handleSave}
                            onChange={(value) => {
                                setConfirmationCode(value);
                                setError('');
                            }}
                            status={error ? 'error' : 'default'}
                            length={4}
                        />
                        {!isEmpty(error) && <Alert type={'danger'}>{t`The confirmation code is incorrect`}</Alert>}
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer className="items-center">
                <Button onClick={() => modalProps.close()}>
                    <Trans>Cancel</Trans>
                </Button>

                {step === 1 ? (
                    <Button
                        loading={isValidatingForm || isSendingVerificationCode}
                        onClick={handleNext}
                        color="primary"
                    >
                        <Trans>Next</Trans>
                    </Button>
                ) : (
                    step === 2 && (
                        <Button loading={isVerifyingCode} onClick={() => handleSave(confirmationCode)} color="primary">
                            <Trans>Save</Trans>
                        </Button>
                    )
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
