import React from 'react';
import { ContextModalProps } from '@wedo/design-system';
import { GroupModalBody } from 'Pages/settings/customFields/components/GroupModal/GroupModalBody';
import { CustomFieldGroup } from 'Shared/types/customField';

type EditGroupModalProps = {
    group: CustomFieldGroup;
} & ContextModalProps;

export const EditGroupModal: React.FC<EditGroupModalProps> = ({ group, ...modalProps }) => {
    return <GroupModalBody {...modalProps} group={group} />;
};
