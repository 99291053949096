import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { WorkspaceDescriptionCard } from 'Pages/workspace/info/WorkspaceDescriptionCard';
import { WorkspaceMembersCard } from 'Pages/workspace/info/WorkspaceMembersCard';
import { WorkspaceProgressCard } from 'Pages/workspace/info/WorkspaceProgressCard';
import { WorkspaceStatusCard } from 'Pages/workspace/info/WorkspaceStatusCard';
import { WorkspaceBurnUpChart } from 'Pages/workspace/info/charts/WorkspaceBurnUpChart';
import { WorkspaceBurndownChart } from 'Pages/workspace/info/charts/WorkspaceBurndownChart';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';

export const WorkspaceOverviewPage = () => {
    const { workspaceId } = useParams();
    const { data: workspace, refetch } = useGetWorkspaceQuery(workspaceId);

    usePageTitle(`${workspace?.name} | ${t`Overview`}`);

    return (
        <div className="scrollbar-light overflow-y-auto">
            <div className="mx-6 mb-10 mt-6 flex flex-col gap-6 sm:mx-10">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <WorkspaceDescriptionCard workspace={workspace} />
                    <WorkspaceMembersCard workspace={workspace} />
                </div>

                <WorkspaceStatusCard workspaceId={workspaceId} onReload={refetch} />

                <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                    <WorkspaceBurndownChart workspaceId={workspaceId} />
                    <WorkspaceBurnUpChart workspaceId={workspaceId} />
                </div>

                <WorkspaceProgressCard workspaceId={workspaceId} />
            </div>
        </div>
    );
};
