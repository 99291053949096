import React from 'react';
import { i18n } from '@lingui/core';
import { Tag, Tooltip } from '@wedo/design-system';
import { ChargebeeInvoice } from '@wedo/types';
import {
    ChargebeeInvoiceStatusToColor,
    ChargebeeInvoiceStatusToIcon,
    ChargebeeInvoiceStatusToString,
} from 'Shared/utils/chargebee';

type InvoiceStatusTagProps = { status: ChargebeeInvoice['status'] };

export const InvoiceStatusTag: React.FC<InvoiceStatusTagProps> = ({ status }) => {
    return (
        <Tooltip content={ChargebeeInvoiceStatusToString[status] && i18n._(ChargebeeInvoiceStatusToString[status])}>
            <Tag
                icon={ChargebeeInvoiceStatusToIcon[status]}
                color={ChargebeeInvoiceStatusToColor[status]}
                className="w-8"
            />
        </Tooltip>
    );
};
