import React, { useEffect } from 'react';
import { Textarea } from '@wedo/design-system';
import { onEnter, stopPropagationOnMouseEvents } from '@wedo/utils';
import { useFocus } from 'Shared/hooks/useFocus';

export const TocElementInput = ({
    initialValue,
    onComplete,
    currentValue,
    setCurrentValue,
    label,
}: {
    initialValue: string;
    onComplete: () => void;
    currentValue: string;
    label?: string;
    setCurrentValue: (e: string) => void;
}): JSX.Element => {
    const [inputRef, setInputFocus] = useFocus(true);

    useEffect(() => {
        setCurrentValue(initialValue);
        setTimeout(() => {
            setInputFocus();
        }, 100);
    }, [initialValue]);

    const handleBlur = () => {
        if (currentValue !== '') {
            onComplete();
        } else {
            setInputFocus();
        }
    };

    return (
        <Textarea
            wrapperClassName={
                'p-0 pt-1 -mt-px -mr-1 -mb-px pb-1 -ml-1 pl-[3px] bg-white !ring-0 !ring-offset-0 !rounded-none break-all'
            }
            className={'!p-0 !text-sm !leading-4'}
            size="sm"
            ref={inputRef}
            aria-label={label}
            placeholder={label}
            onChange={(e) => setCurrentValue(e.target.value)}
            onBlur={handleBlur}
            value={currentValue}
            maxLength={200}
            onKeyDown={onEnter(onComplete)}
            {...stopPropagationOnMouseEvents}
        />
    );
};
