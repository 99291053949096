import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Spinner } from '@wedo/design-system';

export type CheckComplianceProps = {
    text: string;
    isPassing: boolean;
    isLoading?: boolean;
};

export const CheckCompliance: FC<CheckComplianceProps> = ({ text, isPassing, isLoading = false }) => (
    <div
        className={clsx(
            'flex items-center gap-1 text-sm',
            !isLoading && isPassing && 'text-green-600',
            !isLoading && !isPassing && 'text-gray-600',
            isLoading && 'text-gray-600'
        )}
    >
        <span className="flex w-4 items-center justify-start">
            {isLoading && <Spinner className="h-3 w-3" color="blue" />}
            {!isLoading && (
                <FontAwesomeIcon icon={isPassing ? faCheck : faTimes} className={clsx(!isPassing && 'text-red-500')} />
            )}
        </span>
        {text}
    </div>
);
