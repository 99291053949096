import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { msg, t, Trans } from '@lingui/macro';
import { getYear } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { Button, EmptyState, ListTitleSeparator, Skeleton, useModal } from '@wedo/design-system';
import { enumeration } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { MeetingStatusDropdown } from 'Pages/MeetingListPage/components/MeetingStatusDropdown';
import { Can } from 'Shared/components/Can';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { PageHeader } from 'Shared/components/PageHeader';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { AddMeetingModal } from 'Shared/components/meeting/addMeetingModal/AddMeetingModal';
import { MeetingListItem } from 'Shared/components/meeting/meetingList/MeetingListItem';
import { isUserGroupMember } from 'Shared/hooks/useIsUserGroupMember';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useGetMeetingsQuery } from 'Shared/services/meeting';
import { useGetTeamQuery } from 'Shared/services/team';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Meeting } from 'Shared/types/meeting';
import { Permission } from 'Shared/utils/rbac';

const MeetingListPageSearchParams = {
    view: enumeration('upcoming', 'week', 'past').default('upcoming'),
    status: enumeration('all', 'deleted').options({ allowUnknown: true }),
};

type MeetingsInfiniteScrollPageProps = InfiniteScrollPageProps & {
    search: string;
};

const MeetingsInfiniteScrollPage = ({
    offset,
    limit,
    updateInfiniteScroll,
    search,
}: MeetingsInfiniteScrollPageProps) => {
    const { workspaceId, teamId } = useParams();
    const [{ view, status }] = useSearchParams(MeetingListPageSearchParams);

    const { data, isLoading, error, refetch } = useGetMeetingsQuery(
        {
            related: ['tag', 'meetingUsers', 'meetingUsers.user', 'extendedStatus'],
            deleted: status === 'deleted' ? true : status === 'all' ? undefined : false,
            filter: view,
            tagId: workspaceId,
            teamId: teamId,
            orderBy: view === 'past' ? 'start_at_desc' : 'start_at_asc',
            search: search,
            offset,
            limit,
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data);

    return (
        <>
            {error != null ? (
                <RetryComponent retryFunction={refetch} className="!h-auto" />
            ) : isLoading ? (
                <div className="flex flex-col gap-4">
                    <Skeleton count={5} className="h-14" />
                </div>
            ) : (
                (data || []).map((meeting, index) => {
                    const meetingYear = getYear(new Date(meeting.start_at));
                    const previousMeetingYear =
                        index - 1 >= 0 ? getYear(new Date(data[index - 1].start_at)) : meetingYear;
                    return (
                        <div key={`${meeting.id}-${meeting.tag_id}-${meeting.start_at}`}>
                            {meetingYear !== previousMeetingYear ||
                            (view === 'past' && index === 0 && offset === 0 && meetingYear !== getYear(new Date())) ? (
                                <ListTitleSeparator key={meetingYear}>{meetingYear}</ListTitleSeparator>
                            ) : null}
                            <MeetingListItem key={meeting.id} meeting={meeting} />
                        </div>
                    );
                })
            )}
        </>
    );
};

const Tabs = [
    {
        isDefault: true,
        to: { searchParams: { view: 'upcoming' } },
        matchSearchParams: ['view'],
        keepSearchParams: ['status'],
        title: msg`Upcoming`,
    },
    {
        to: { searchParams: { view: 'week' } },
        matchSearchParams: ['view'],
        keepSearchParams: ['status'],
        title: msg`This week`,
    },
    {
        to: { searchParams: { view: 'past' } },
        matchSearchParams: ['view'],
        keepSearchParams: ['status'],
        title: msg({ id: 'Past meetings', message: 'Past' }),
    },
];

export const MeetingListPage = () => {
    const sessionUser = useSessionUser();

    const navigate = useNavigate();
    const { workspaceId, teamId } = useParams();
    const [{ view, status }, setSearchParams] = useSearchParams(MeetingListPageSearchParams);
    const toolbarRef = useRef(null);

    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
    const { data: team } = useGetTeamQuery({ teamId }, { skip: !teamId });
    const { open: openModal } = useModal();

    const [search, setSearch] = useState<string>('');
    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: setSearch,
        search: search,
        title: t`Search meetings`,
    });
    const isMyMeetingsPage = !workspace && !team;

    usePageTitle(() => {
        if (workspace) {
            return `${workspace?.name} | ${t`Meetings`}`;
        }
        if (team) {
            return `${team?.name} | ${t`Meetings`}`;
        }
        return t`My meetings`;
    });

    const handleAddDone = (meeting: Meeting): void => {
        if (meeting) {
            navigate('/meetings/' + meeting.id);
        }
    };

    return (
        <>
            {isMyMeetingsPage && <PageHeader title={t`My meetings`} />}
            <div className="grid-rows-header-content grid h-full max-h-full gap-6 overflow-hidden">
                <div ref={toolbarRef} className="flex flex-col gap-2 px-6">
                    <NavBar
                        basePath={
                            workspaceId != null
                                ? `/workspaces/${workspaceId}/meetings`
                                : teamId != null
                                  ? `/teams/${teamId}/meetings`
                                  : '/meetings'
                        }
                        tabs={Tabs}
                    >
                        <div className="flex gap-2">
                            {toggleButton}
                            <MeetingStatusDropdown
                                value={status ?? 'active'}
                                onChange={(status) => {
                                    setSearchParams({
                                        view,
                                        status: status === 'all' ? 'all' : status === 'deleted' ? 'deleted' : undefined,
                                    });
                                }}
                            />
                            {(team == null || isUserGroupMember(sessionUser, team)) && (
                                <Can permission={Permission.AddMeeting}>
                                    <Button
                                        color="primary"
                                        title={t`Add meeting`}
                                        tooltipClassName="flex lg:hidden"
                                        icon={faPlus}
                                        onClick={() =>
                                            openModal(AddMeetingModal, {
                                                onDone: handleAddDone,
                                                workspaceId: workspaceId,
                                            })
                                        }
                                    >
                                        <span className="hidden lg:flex">
                                            <Trans>Add meeting</Trans>
                                        </span>
                                    </Button>
                                </Can>
                            )}
                        </div>
                    </NavBar>
                    {searchInput}
                </div>

                <InfiniteScroll
                    key={view}
                    className="scrollbar-light flex flex-col gap-4 px-6 pb-6"
                    page={MeetingsInfiniteScrollPage}
                    emptyPage={
                        <EmptyState icon={faCalendarDay} size="lg">
                            <EmptyState.Text>
                                {isEmpty(search) ? <Trans>No meetings</Trans> : <Trans>No meetings found</Trans>}
                            </EmptyState.Text>
                        </EmptyState>
                    }
                    size={20}
                    props={{ search }}
                />
            </div>
        </>
    );
};
