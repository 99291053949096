import React, { forwardRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Input, Label } from '@wedo/design-system';
import { RtkQueryTransformedError } from 'Shared/types/rtkQuery';

interface VerificationCodeInputProps {
    emailAddress: string;
    verificationCode: string;
    onVerificationCodeChange: (code: string) => void;
    errorTitle?: string;
    errorMessage?: string;
    className?: string;
    error: RtkQueryTransformedError;
    onPressEnter: () => void;
}

export const VerificationCodeInput = forwardRef<HTMLInputElement, VerificationCodeInputProps>(
    ({ emailAddress, verificationCode, onVerificationCodeChange, className, error, onPressEnter }, ref) => {
        return (
            <div className={className}>
                <p>
                    <Trans>
                        We have sent you a verification code at <span className="font-semibold">{emailAddress}</span>.
                    </Trans>
                </p>

                <Label className="mt-4">
                    <Trans>Enter verification code</Trans>

                    <Input
                        ref={ref}
                        placeholder={t`Verification code`}
                        value={verificationCode}
                        onChange={(e) => onVerificationCodeChange(e.target.value)}
                        onPressEnter={onPressEnter}
                        status={error ? 'error' : 'default'}
                        statusText={error && (error as RtkQueryTransformedError).message}
                    />
                </Label>
            </div>
        );
    }
);
