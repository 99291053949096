import React, { FC, useId, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import validator from 'validator';
import {
    Button,
    ContextModalProps,
    Form,
    Input,
    Modal,
    NoInternetErrorNotification,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { SwissCantonCode } from '@wedo/types';
import { EmptyString, getCountryName, isValidEmail, valueOrEmptyString } from '@wedo/utils';
import { SwitzerlandCantonSelector } from 'Shared/components/address/SwitzerlandCantonSelector';
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from 'Shared/services/organization';
import { ApiError } from 'Shared/types/apiError';
import { Organization } from 'Shared/types/organization';
import { isFetchError } from 'Shared/utils/rtkQuery';

export const EditBillingAddressModal: FC<ContextModalProps> = ({ ...modalProps }) => {
    const id = useId();
    const { data: organisation } = useGetOrganizationQuery();
    const { show } = useNotification();

    const address = organisation?.billing_address;
    const swissCanton = organisation?.cf_canton;

    const [updateBillingAddress, { isLoading }] = useUpdateOrganizationMutation();

    const [firstName, setFirstName] = useState<string>(address?.first_name ?? EmptyString);
    const [lastName, setLastName] = useState<string>(address?.last_name ?? EmptyString);
    const [company, setCompany] = useState<string>(address?.company ?? EmptyString);
    const [phone, setPhone] = useState<string>(address?.phone ?? EmptyString);
    const [isPhoneDirty, setIsPhoneDirty] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(address?.email ?? EmptyString);
    const [isEmailDirty, setIsEmailDirty] = useState<boolean>(false);

    const [addressLine1, setAddressLine1] = useState<string>(address?.line1 ?? EmptyString);
    const [addressLine2, setAddressLine2] = useState<string>(address?.line2 ?? EmptyString);
    const [addressLine3, setAddressLine3] = useState<string>(address?.line3 ?? EmptyString);
    const [isAddressLine1Dirty, setIsAddressLine1Dirty] = useState<boolean>(false);

    const [zipCode, setZipCode] = useState<string>(address?.zip ?? EmptyString);
    const [isZipDirty, setIsZipDirty] = useState<boolean>(false);
    const [isZipCodeInvalid, setIsZipCodeInvalid] = useState<boolean>(false);
    const [city, setCity] = useState<string>(address?.city ?? EmptyString);
    const [canton, setCanton] = useState<SwissCantonCode>(swissCanton);

    const setValue = (setter: React.Dispatch<string>, e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.value);

    const hasUserMadeChanges =
        !isEqual(firstName?.trim(), address?.first_name?.trim()) ||
        !isEqual(lastName?.trim(), address?.last_name?.trim()) ||
        !isEqual(company?.trim(), address?.company?.trim()) ||
        !isEqual(email?.trim(), address?.email?.trim()) ||
        !isEqual(phone?.trim(), address?.phone?.trim()) ||
        !isEqual(addressLine1?.trim(), valueOrEmptyString(address?.line1?.trim())) ||
        !isEqual(addressLine2?.trim(), valueOrEmptyString(address?.line2?.trim())) ||
        !isEqual(addressLine3?.trim(), valueOrEmptyString(address?.line3?.trim())) ||
        !isEqual(zipCode?.trim(), address?.zip?.trim()) ||
        !isEqual(city?.trim(), address?.city?.trim()) ||
        !isEqual(canton, swissCanton);

    const isSaveButtonDisabled =
        !hasUserMadeChanges ||
        isEmpty(company?.trim()) ||
        isEmpty(email?.trim()) ||
        isEmpty(addressLine1?.trim()) ||
        isEmpty(zipCode?.trim()) ||
        isEmpty(city?.trim()) ||
        isEmpty(phone) ||
        (address?.country === 'CH' && isEmpty(canton?.trim()));

    const showEmailError = !isValidEmail(email) && isEmailDirty;
    const showZipCodeError = (isEmpty(zipCode.trim()) && isZipDirty) || isZipCodeInvalid;
    const isPhoneInvalid = !validator.isMobilePhone(phone?.replace(/\s/g, '') ?? EmptyString) || isEmpty(phone);

    const zipCodeErrorMessage = isEmpty(zipCode)
        ? t`Zip code can't be empty`
        : isZipCodeInvalid
          ? t`Invalid zip code`
          : EmptyString;

    const phoneNumberErrorMessage = isEmpty(phone)
        ? t`Phone number can't be empty`
        : isPhoneInvalid
          ? t`Invalid phone number`
          : EmptyString;

    const markAllFieldsAsDirty = () => {
        setIsPhoneDirty(true);
        setIsZipDirty(true);
        setIsEmailDirty(true);
        setIsAddressLine1Dirty(true);
    };

    const clearAllValidationErrors = () => setIsZipCodeInvalid(false);

    const handleSave = async () => {
        clearAllValidationErrors();
        markAllFieldsAsDirty();

        if (isSaveButtonDisabled) {
            void modalProps.close();
            return;
        }

        const response = await updateBillingAddress({
            billing_address: {
                ...address,
                city,
                company,
                country: address.country,
                email,
                phone,
                first_name: firstName,
                last_name: lastName,
                line1: addressLine1,
                line2: addressLine2,
                line3: addressLine3,
                zip: zipCode,
            },
            cf_canton: canton,
        } as Organization);
        if ('error' in response) {
            const error = response.error as ApiError;
            if (isFetchError(error)) {
                show(NoInternetErrorNotification);
            } else if (error.matches({ path: 'billing_address[zip]' })) {
                setIsZipCodeInvalid(true);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={t`Edit billing address`} />

            <Modal.Body>
                <Form>
                    <Form.Item label={t`First name`} cols={3} htmlFor={id + 'firstname'}>
                        <Input
                            id={id + 'firstname'}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder={t`First name`}
                        />
                    </Form.Item>
                    <Form.Item label={t`Last name`} cols={3} htmlFor={id + 'lastname'}>
                        <Input
                            id={id + 'lastname'}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder={t`Last name`}
                        />
                    </Form.Item>

                    <Form.Item isMandatory label={t`Company`} cols={3} htmlFor={id + 'company'}>
                        <Input
                            id={id + 'company'}
                            placeholder={t`Company`}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label={t`Phone`} cols={3} htmlFor={id + 'phone'}>
                        <Input
                            id={id + 'phone'}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder={t`Phone`}
                            onBlur={() => setIsPhoneDirty(true)}
                            status={isPhoneDirty && isPhoneInvalid ? 'error' : 'default'}
                            statusText={isPhoneDirty && phoneNumberErrorMessage}
                        />
                    </Form.Item>

                    <Form.Item
                        isMandatory
                        label={t`Email address (invoices will be sent to this email)`}
                        htmlFor={id + 'email'}
                    >
                        <Input
                            id={id + 'email'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setIsEmailDirty(true)}
                            placeholder={t`Email`}
                            type="email"
                            status={showEmailError ? 'error' : 'default'}
                            statusText={showEmailError && t`Invalid email address`}
                        />
                    </Form.Item>

                    <Form.Item isMandatory label={t`Address`} htmlFor={id + 'address'}>
                        <div className="flex flex-col gap-2">
                            <Input
                                id={id + 'address'}
                                placeholder={t`Address line 1`}
                                value={addressLine1}
                                onChange={(e) => setValue(setAddressLine1, e)}
                                status={isAddressLine1Dirty && isEmpty(addressLine1.trim()) ? 'error' : 'default'}
                                statusText={
                                    isAddressLine1Dirty && isEmpty(addressLine1.trim()) && t`Address can't be empty`
                                }
                            />
                            <Input
                                placeholder={t`Address line 2`}
                                value={addressLine2}
                                onChange={(e) => setValue(setAddressLine2, e)}
                            />
                            <Input
                                placeholder={t`Address line 3`}
                                value={addressLine3}
                                onChange={(e) => setValue(setAddressLine3, e)}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item isMandatory label={t`Zip code`} cols={3} htmlFor={id + 'zip'}>
                        <Input
                            id={id + 'zip'}
                            placeholder={t`Zip code`}
                            value={zipCode}
                            onChange={(e) => setValue(setZipCode, e)}
                            onBlur={() => setIsZipDirty(true)}
                            status={showZipCodeError ? 'error' : 'default'}
                            statusText={zipCodeErrorMessage}
                        />
                    </Form.Item>
                    <Form.Item isMandatory label={t`City`} cols={3} htmlFor={id + 'city'}>
                        <Input
                            id={id + 'city'}
                            placeholder={t`City`}
                            value={city}
                            onChange={(e) => setValue(setCity, e)}
                        />
                    </Form.Item>

                    <Form.Item label={t`Country`} cols={4} htmlFor={id + 'country'}>
                        <Input
                            id={id + 'country'}
                            disabled
                            placeholder={t`Country`}
                            value={getCountryName(address.country)}
                        />
                    </Form.Item>

                    {address?.country === 'CH' && (
                        <Form.Item isMandatory label={t`Canton`} cols={2}>
                            <SwitzerlandCantonSelector canton={canton} onChange={setCanton} />
                        </Form.Item>
                    )}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" loading={isLoading} onClick={handleSave}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
