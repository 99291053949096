import React from 'react';
import { ChargebeeInvoice } from '@wedo/types';
import { useFormattedCurrency } from 'Shared/hooks/useFormattedCurrency';

type InvoiceAmountProps = {
    invoice: ChargebeeInvoice;
};

export const InvoiceAmount: React.FC<InvoiceAmountProps> = ({ invoice }) => {
    const { getFormattedCurrency } = useFormattedCurrency();

    const amount = invoice.status === 'paid' ? invoice.amount_paid : invoice.amount_due;

    return <span className="font-medium">{getFormattedCurrency(amount, invoice.currency_code)}</span>;
};
