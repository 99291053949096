import { FC } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Select } from '@wedo/design-system';

export type ChangeOption = 'keep' | 'edit' | 'clear';

type EditOptionsSelectProps = {
    value: ChangeOption;
    onChange: (value: ChangeOption) => void;
    changeLabel?: string;
    resetLabel?: string;
    className?: string;
    isResetDisabled?: boolean;
    isResetHidden?: boolean;
};

export const EditOptionsSelect: FC<EditOptionsSelectProps> = ({
    value,
    onChange,
    changeLabel = t`Edit current values...`,
    resetLabel = t`Clear field`,
    className,
    isResetDisabled = false,
    isResetHidden = false,
}) => {
    const retainLabel = t`Keep current values`;

    return (
        <Select value={value} onChange={onChange} className={clsx('w-full', className)}>
            <Select.Option value={'keep'}>{retainLabel}</Select.Option>
            <Select.Option value={'edit'}>{changeLabel}</Select.Option>
            {!isResetHidden && (
                <Select.Option value={'clear'} disabled={isResetDisabled}>
                    {resetLabel}
                </Select.Option>
            )}
        </Select>
    );
};
