import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import {
    Button,
    DatePicker,
    DatePickerShortcuts,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { OrganizePanelProps } from 'Pages/TasksPage/types';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { Panel } from './Panel';

export const TaskDueDatePanel = ({ task, onNext, isInGanttContext }: OrganizePanelProps) => {
    const queryClient = useQueryClient();

    const [updateTask, { isLoading }] = useUpdateTaskMutation();
    const { show } = useNotification();

    const handleUpdate = async (date: Date) => {
        const result = await updateTask({
            id: task.id,
            due_date: date?.toISOString() ?? null,
            keepCache: isInGanttContext,
        });

        if ('error' in result) {
            show(UnexpectedErrorNotification);
            return;
        }

        if (isInGanttContext) {
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'dueDate')]);
        }

        onNext();
    };

    return (
        <Panel className="flex flex-col gap-2">
            <div className="flex flex-col gap-4 rounded-md bg-gray-50  p-5">
                <DatePickerShortcuts onChange={handleUpdate} />
                <DatePicker onChange={handleUpdate} date={new Date()} className="" />
                <Button
                    variant="outlined"
                    className="justify-center"
                    icon={faTrash}
                    loading={isLoading}
                    onClick={() => handleUpdate(null)}
                >
                    <Trans>No due date</Trans>
                </Button>
            </div>
        </Panel>
    );
};
