import { Svg, Rect, Path, Defs, ClipPath, G } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const ExternalLinkIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <G clip-path="url(#clip0_1800_26562)">
                <Rect width="500" height="500" transform="translate(6.00012 6)" fill="white" />
                <Path
                    opacity="0.4"
                    d="M470.942 96.0005C517.686 142.745 517.686 218.446 470.942 265.19L378.032 358.1C331.288 404.844 255.587 404.844 208.842 358.1C167.476 316.733 162.098 251.539 196.101 203.968L197.011 202.644C205.533 190.73 222.08 188 233.91 196.522C245.741 205.043 248.554 221.59 240.033 233.421L239.123 234.744C220.177 261.302 223.155 297.622 246.238 320.622C272.299 346.683 314.493 346.683 340.554 320.622L433.464 227.795C459.525 201.734 459.525 159.54 433.464 133.479C410.464 110.396 374.061 107.418 347.586 126.364L346.263 127.274C334.349 135.795 317.802 132.982 309.363 121.151C300.925 109.321 303.655 92.6911 315.486 84.2523L316.809 83.3422C364.381 49.2561 429.575 54.7165 470.942 96.0005Z"
                    fill="#0158A3"
                />
                <Path
                    d="M41.0584 415.021C-5.68598 368.277 -5.68598 292.575 41.0584 245.831L133.968 152.921C180.712 106.177 256.414 106.177 303.158 152.921C344.525 194.288 349.902 259.482 315.899 307.054L314.989 308.378C306.467 320.291 289.921 323.021 278.09 314.5C266.259 305.978 263.446 289.432 271.967 277.601L272.878 276.277C291.823 249.72 288.845 213.4 265.762 190.317C239.701 164.256 197.507 164.256 171.446 190.317L78.5367 283.227C52.4756 309.288 52.4756 351.482 78.5367 377.543C101.619 400.625 137.939 403.604 164.414 384.658L165.738 383.748C177.651 375.226 194.198 378.039 202.637 389.87C211.076 401.701 208.345 418.33 196.515 426.769L195.191 427.679C147.619 461.765 82.4251 456.305 41.0584 415.021Z"
                    fill="#0158A3"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1800_26562">
                    <Rect width="500" height="500" fill="white" transform="translate(6.00012 6)" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};
