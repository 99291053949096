import { FloatingPortal } from '@floating-ui/react';
import { Combobox, Transition } from '@headlessui/react';
import { CSSProperties, Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Alert } from '~/components/Alert';
import { TimeItem } from '~/components/TimePicker/components/TimeItem';
import { Time } from '~/hooks/useCalendar';

type TimeOptionsProps = {
    timeList: Time[];
    showPanel: boolean;
    setPopperElement: Dispatch<SetStateAction<HTMLElement>>;
    styles: CSSProperties;
    className?: string;
};

export const TimeOptions: FC<TimeOptionsProps> = ({ timeList, showPanel, setPopperElement, className, styles }) => {
    const [showFloating, setShowFloating] = useState(false);
    const [selectedElement, setSelectedElement] = useState<HTMLElement>(null);

    const handleSelectedElement = (el: HTMLElement, selected: boolean) => {
        if (selected) {
            setSelectedElement(el);
        }
    };

    useEffect(() => {
        if (showPanel && selectedElement && selectedElement.scrollIntoView) {
            selectedElement.scrollIntoView({ block: 'center' });
        }

        if (showPanel) {
            setShowFloating(true);
        }
    }, [showPanel, selectedElement]);

    if (!showFloating) {
        return null;
    }

    return (
        <FloatingPortal>
            <Transition
                afterLeave={() => setShowFloating(false)}
                as={Fragment}
                show={showPanel}
                leave="transition-opacity ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Combobox.Options
                    ref={setPopperElement}
                    as="div"
                    className={clsx(
                        'z-40 w-40 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                        className
                    )}
                    style={styles}
                >
                    <ul className="max-h-60 overflow-auto p-1">
                        {timeList.length === 0 && <Alert title={t`Invalid time`} type="danger" />}

                        {timeList.map((time) => (
                            <Combobox.Option key={time.timestamp} value={time}>
                                {({ active, selected }) => (
                                    <TimeItem
                                        ref={(el: HTMLElement) => handleSelectedElement(el, selected)}
                                        active={active}
                                        value={time}
                                        selected={selected}
                                    />
                                )}
                            </Combobox.Option>
                        ))}
                    </ul>
                </Combobox.Options>
            </Transition>
        </FloatingPortal>
    );
};
