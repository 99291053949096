import uaParser from 'ua-parser-js';

export const parseUserAgent = (userAgent = window.navigator.userAgent) => {
    return uaParser(userAgent);
};

export const getPlatform = () => {
    // This userAgentData feature is available only in secure contexts (HTTPS)
    if (
        typeof navigator.userAgentData !== 'undefined' &&
        navigator.userAgentData != null &&
        navigator.userAgentData.platform != null
    ) {
        return navigator.userAgentData.platform;
    }
    // Deprecated but still works for most of the browsers
    if (typeof navigator.platform !== 'undefined' && navigator.platform != null) {
        if (typeof navigator.userAgent !== 'undefined' && /android/.test(navigator.userAgent.toLowerCase())) {
            // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
            return 'android';
        }
        return navigator.platform;
    }
    return 'unknown';
};

export const isOSX = () => /mac/.test(getPlatform().toLowerCase()); // Mac desktop
export const isIOS = () => ['iphone', 'ipad', 'ipod'].indexOf(getPlatform().toLowerCase()); // Mac iOs
export const isApple = () => isOSX() || isIOS(); // Apple device (desktop or iOS)
export const isWindows = () => /win/.test(getPlatform().toLowerCase()); // Windows
export const isAndroid = () => /android/.test(getPlatform().toLowerCase()); // Android
export const isLinux = () => /linux/.test(getPlatform().toLowerCase()); // Linux
