import React, { Dispatch, FC, SetStateAction } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { AddConditionDropdown } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/AddConditionDropdown';
import { AndOrLabel } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/AndOrLabel';
import { FieldTypes, isAttachmentFilterType, isDateFilterType } from 'Pages/TasksPage/components/utils';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';
import { FieldCondition, FieldConditionFilter, Filter, GroupCondition } from 'Shared/types/filter';
import { isFieldCondition } from 'Shared/utils/filter';
import { CustomFilter } from './CustomFilter';

const defaultFieldCondition = (field: FieldConditionFilter = 'assignee_id'): FieldCondition => ({
    operator: '=',
    field,
    value: null,
});

const attachmentFilterCondition = (field: FieldConditionFilter): FieldCondition =>
    ({
        operator: FieldTypes.attachment[0],
        field,
        value: null,
    }) as FieldCondition;

const dateFilterCondition = (field: FieldConditionFilter): FieldCondition =>
    ({
        operator: FieldTypes.date[0],
        field,
        value: null,
    }) as FieldCondition;

type CustomFilterGroupProps = {
    condition: GroupCondition;
    setFilter: Dispatch<SetStateAction<Filter>>;
    depth?: number;
    onDelete?: () => void;
    isDeletable?: boolean;
};

export const CustomFilterGroup: FC<CustomFilterGroupProps> = ({
    condition,
    setFilter,
    depth = 0,
    onDelete,
    isDeletable = true,
}) => {
    const { allCustomFields } = useCurrentTasksFilter();

    const handleAddField = (fieldType: FieldConditionFilter = 'assignee_id') => {
        if (isAttachmentFilterType(fieldType, allCustomFields)) {
            condition.conditions.push(attachmentFilterCondition(fieldType));
        } else if (isDateFilterType(fieldType, allCustomFields)) {
            condition.conditions.push(dateFilterCondition(fieldType));
        } else {
            condition.conditions.push(defaultFieldCondition(fieldType));
        }
        setFilter((filter) => ({ ...filter }));
    };

    const handleDelete = (index: number) => () => {
        condition.conditions.splice(index, 1);
        if (condition.conditions.length === 0 && depth > 0) {
            onDelete();
        } else {
            setFilter((filter) => ({ ...filter }));
        }
    };

    return (
        <div className="flex flex-wrap items-center gap-2 overflow-hidden p-1">
            {condition.conditions.map((conditionTree, index) => (
                <div key={`operator-${index}-${condition.operator}`} className="flex max-w-full items-center gap-2">
                    {index > 0 && <AndOrLabel value={condition.operator} />}
                    {isFieldCondition(conditionTree) ? (
                        <CustomFilter
                            key={index}
                            condition={conditionTree}
                            setFilter={setFilter}
                            onDelete={handleDelete(index)}
                            isDeletable={isDeletable}
                        />
                    ) : (
                        <CustomFilterGroup
                            key={index}
                            condition={conditionTree}
                            setFilter={setFilter}
                            depth={depth + 1}
                            onDelete={handleDelete(index)}
                            isDeletable={isDeletable}
                        />
                    )}
                </div>
            ))}
            <AddConditionDropdown
                hideLabel
                value="status"
                onChange={(field) => handleAddField(field)}
                icon={faPlus}
                position="none"
            />
        </div>
    );
};
