import { useLingui } from '@lingui/react';
import React, { ReactNode } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import { Select, Switch } from '@wedo/design-system';
import { EmptyFunction } from '@wedo/utils';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';

type PreferenceProps = {
    title: MessageDescriptor;
    description?: MessageDescriptor;
    onClick?: () => void;
    children: ReactNode;
};
export const Preference = ({ title, description, onClick, children }: PreferenceProps) => {
    const { i18n } = useLingui();

    return (
        <div className="relative flex items-center gap-2 p-2 rounded">
            {onClick && <button className="absolute inset-0" onClick={onClick}></button>}
            <div className="flex-grow">
                <div>{i18n._(title)}</div>
                {description && <div className="text-sm text-gray-500">{i18n._(description)}</div>}
            </div>
            {children}
        </div>
    );
};

type SwitchPreferenceProps = {
    value: boolean;
    isLoading: boolean;
    onToggle: (value: boolean) => void;
    title: MessageDescriptor;
    description: MessageDescriptor;
};
export const SwitchPreference = ({ value, isLoading, onToggle, title, description }: SwitchPreferenceProps) => {
    return (
        <Preference title={title} description={description} onClick={() => onToggle(!value)}>
            <div className="shrink-0">
                <Switch checked={value as boolean} onChange={onToggle} loading={isLoading} />
            </div>
        </Preference>
    );
};

type SelectPreferenceProps = {
    value: string;
    options: { id: string; label: MessageDescriptor }[];
    onChange: (value: string) => void;
    title: MessageDescriptor;
    description?: MessageDescriptor;
};
export const SelectPreference = ({ value, options, onChange, title, description }: SelectPreferenceProps) => {
    const { i18n } = useLingui();

    return (
        <Preference title={title} description={description} onClick={EmptyFunction}>
            <div className="w-50">
                <Select
                    size="md"
                    value={value}
                    customRenderSelected={(value: MeetingViewMode) =>
                        value === MeetingViewMode.TopicView ? t`Topic view` : t`Document view`
                    }
                    onChange={onChange}
                >
                    {options.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                            {i18n._(option.label)}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </Preference>
    );
};
