import { autoUpdate, flip, FloatingPortal, offset, Placement, shift, useFloating } from '@floating-ui/react';
import { Popover as PopoverUi, Transition } from '@headlessui/react';
import { type CSSProperties, FC, Fragment, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { size } from '@floating-ui/dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { IconName } from '~/components/Icon/type';
import { preventOverflowMiddleware } from '@wedo/utils';
import { Button, ButtonProps } from '../Button/Button';

type PanelClose = (focusableElement?: HTMLElement | MutableRefObject<HTMLElement>) => void;

type ClosableChildren = ({ close, maxHeight }: { close: PanelClose; maxHeight?: number }) => ReactNode;

export type PopoverProps = {
    text?: ReactNode;
    children: ReactNode | ClosableChildren;
    placement?: Placement;
    icon?: IconDefinition | IconName;
    wrapperClassName?: string;
    wrapperStyle?: CSSProperties;
    isStatic?: boolean; // Popover visibility is managed by the component
    afterLeave?: () => void;
} & Omit<ButtonProps, 'children'>;

export const Popover: FC<PopoverProps> = ({
    text,
    children,
    placement = 'bottom-end',
    className,
    wrapperClassName = '',
    wrapperStyle,
    icon,
    isStatic,
    afterLeave,
    ...props
}) => {
    const [showPortal, setShowPortal] = useState(false);
    const [oldIsStatic, setOldIsStatic] = useState(isStatic);
    const [availableHeight, setAvailableHeight] = useState<number>(0);

    const { x, y, strategy, refs } = useFloating({
        placement,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            flip(),
            shift(),
            size({
                apply: ({ availableHeight }) => setAvailableHeight(availableHeight),
            }),
            preventOverflowMiddleware,
        ],
    });

    const floatingStyle = {
        position: strategy,
        left: x ?? 0,
        top: y ?? 0,
        zIndex: 40,
    };

    if (oldIsStatic !== isStatic) {
        setOldIsStatic(isStatic);
        if (isStatic) {
            setShowPortal(true);
        }
    }

    return (
        <PopoverUi className={clsx('relative', wrapperClassName)} style={wrapperStyle}>
            {({ open }) => {
                const contentRef = useRef(null);
                const previouslyFocusedElement = useRef(null);

                // use effect, if changed from closed to open, focus the first focusable thing, if not refocus previous element
                useEffect(() => {
                    if (open && contentRef.current != null) {
                        previouslyFocusedElement.current = document.activeElement;
                        contentRef.current
                            .querySelectorAll('button, [href], input, [tabindex="0"], [tabindex="-1"]')[0]
                            ?.focus();
                        setShowPortal(true);
                    }
                    if (open === false && previouslyFocusedElement.current != null && document.activeElement == null) {
                        previouslyFocusedElement.current.focus();
                    }
                }, [open, contentRef.current]);
                return (
                    <>
                        <PopoverUi.Button
                            as={Button}
                            className={className}
                            {...props}
                            icon={icon}
                            ref={refs.setReference}
                        >
                            {text}
                        </PopoverUi.Button>
                        {(isStatic || open || showPortal) && (
                            <FloatingPortal>
                                <Transition
                                    afterLeave={() => {
                                        setShowPortal(false);
                                        afterLeave?.();
                                    }}
                                    as={Fragment}
                                    enter="transition-opacity ease-out duration-200"
                                    enterFrom="transform opacity-0 "
                                    enterTo="transform opacity-100 "
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 "
                                    leaveTo="transform opacity-0 "
                                    show={isStatic}
                                >
                                    <PopoverUi.Panel ref={refs.setFloating} style={floatingStyle} static={isStatic}>
                                        {({ close }) => (
                                            <div
                                                ref={contentRef}
                                                className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                            >
                                                {children instanceof Function
                                                    ? children({ close, maxHeight: availableHeight })
                                                    : children}
                                            </div>
                                        )}
                                    </PopoverUi.Panel>
                                </Transition>
                            </FloatingPortal>
                        )}
                    </>
                );
            }}
        </PopoverUi>
    );
};
