import React, { useEffect, useState } from 'react';
import { Id } from '@wedo/types';
import { TemplateTaskRelativeDateSelect } from 'Shared/components/task/TaskDetail/shared/TemplateTaskRelativeDateSelect';
import { useTask } from 'Shared/hooks/useTask';

export const TaskDetailRelativeDateContent = ({
    taskId,
    fieldName,
}: {
    taskId: Id;
    fieldName: 'relative_planned_date' | 'relative_due_date';
}) => {
    const { task, handleTaskUpdate } = useTask(taskId);

    const [offset, setOffset] = useState<number>(task?.[fieldName]);

    useEffect(() => {
        if (fieldName in task) setOffset(task[fieldName]);
    }, [fieldName, task]);

    const handleUpdate = (offset: number) => {
        void handleTaskUpdate({ [fieldName]: offset });
    };

    const handleNumericInputChange = (offset: number) => {
        setOffset(offset);
        handleUpdate(offset);
    };

    return <TemplateTaskRelativeDateSelect offset={offset} onOffsetChange={handleNumericInputChange} />;
};
