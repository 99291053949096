import { ComponentProps, MouseEvent, TouchEvent } from 'react';

export const getStopPropagationOnMouseEvents = (
    props: Pick<ComponentProps<'div'>, 'onMouseDown' | 'onMouseMove' | 'onTouchStart' | 'onTouchMove' | 'onClick'>
) => {
    return {
        onMouseDown: (e: MouseEvent) => {
            if (props.onMouseDown) {
                props.onMouseDown(e);
            }
            e.stopPropagation();
        },
        onMouseMove: (e: MouseEvent) => {
            if (props.onMouseMove) {
                props.onMouseMove(e);
            }
            e.stopPropagation();
        },
        onTouchStart: (e: TouchEvent) => {
            if (props.onTouchStart) {
                props.onTouchStart(e);
            }
            e.stopPropagation();
        },
        onTouchMove: (e: TouchEvent) => {
            if (props.onTouchMove) {
                props.onTouchMove(e);
            }
            e.stopPropagation();
        },
        onClick: (e: MouseEvent) => {
            if (props.onClick) {
                props.onClick(e);
            }
            e.stopPropagation();
            e.preventDefault();
        },
    };
};

export const stopPropagationOnMouseEvents = getStopPropagationOnMouseEvents({});
