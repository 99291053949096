import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { useGetCustomFieldGroupsQuery } from 'Shared/services/customFields';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { useWorkspaceCustomFields } from './useWorkspaceCustomFields';

export const useAllCustomFields = (workspaceId: Id, checklistId: Id) => {
    const { customFields: workspaceCustomFields } = useWorkspaceCustomFields(workspaceId, checklistId);

    const { data: allCustomFieldGroups } = useGetCustomFieldGroupsQuery();

    const workspaceCustomFieldGroups = useMemo(() => {
        return allCustomFieldGroups == null || allCustomFieldGroups.length === 0
            ? (EmptyArray as CustomFieldGroup[])
            : allCustomFieldGroups.filter(
                  (customFieldGroup) =>
                      customFieldGroup.is_global || customFieldGroup.tags.some((tag) => tag.id === workspaceId)
              );
    }, [allCustomFieldGroups]);

    const selectableCustomFields = useMemo(() => {
        return [
            ...(workspaceCustomFields.map(({ id, label, type }) => ({
                id,
                label,
                type,
            })) as CustomField[]),
            ...(workspaceCustomFieldGroups.map(({ id, label, multiple_values, customFields }) => ({
                id: 'group-' + id,
                groupId: id,
                label,
                multiple_values,
                customFields: customFields.map(({ id, label, type }) => ({ id, label, type })),
            })) as CustomFieldGroup[]),
        ];
    }, [workspaceCustomFields, workspaceCustomFieldGroups]);

    const customFields = useMemo(() => {
        return [
            ...workspaceCustomFields,
            ...workspaceCustomFieldGroups.map((group) => group.customFields.map((customField) => customField)).flat(),
        ];
    }, [workspaceCustomFields, workspaceCustomFieldGroups]);

    return { selectableCustomFields, customFields };
};
