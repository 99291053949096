import React from 'react';
import { i18n } from '@lingui/core';
import { msg, Trans } from '@lingui/macro';
import { Card, FormatDateDistance, NavLink } from '@wedo/design-system';
import { ReadOnlyEditor } from 'Shared/components/editor/ReadOnlyEditor';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { LastUpdateInfo } from 'Shared/components/meeting/MeetingBlocks/LastUpdateInfo';
import { MeetingBlock } from 'Shared/types/meetingBlock';

const filters = [
    {
        value: 'paragraph',
        label: msg`Paragraph`,
    },
    {
        value: 'decision',
        label: msg`Decision`,
    },
    {
        value: 'note',
        label: msg`Note`,
    },
    {
        value: 'task',
        label: msg`Task`,
    },
    {
        value: 'attachment',
        label: msg`File`,
    },
    {
        value: 'vote',
        label: msg`Vote`,
    },
];

const getMeetingType = (type: string) => {
    return filters.find((i) => i.value === type)?.label;
};

type BlockSearchItemProps = {
    meetingBlock: MeetingBlock;
};

export const BlockSearchItem = ({ meetingBlock }: BlockSearchItemProps) => {
    return (
        <Card>
            <Card.Body className="!px-2 !py-0">
                <ReadOnlyEditor
                    blocks={[meetingBlock]}
                    meetingId={meetingBlock.topic.meeting_id}
                    topicId={meetingBlock.id}
                />
            </Card.Body>
            <Card.Footer className="flex justify-between border-t border-gray-200 !py-2 text-sm text-gray-500">
                <NavLink
                    className="ignore-marker"
                    to={{
                        pathname: `/meetings/${meetingBlock.topic.meeting_id}`,
                        searchParams: { meeting_topic_id: meetingBlock.topic.id as string },
                    }}
                >
                    <Trans>{i18n._(getMeetingType(meetingBlock.type))} created during the</Trans>
                    <b className="ignore-marker">
                        &nbsp;
                        <FormatMeetingTitle
                            title={meetingBlock.topic.meeting.title}
                            startAt={meetingBlock.topic.meeting.start_at}
                        />
                        &nbsp;
                    </b>
                    {meetingBlock.topic.meeting.tag && (
                        <span>&nbsp;&bull;&nbsp;{meetingBlock.topic.meeting.tag.name}</span>
                    )}
                </NavLink>
                <div className="flex">
                    <LastUpdateInfo meetingBlock={meetingBlock} />
                    &nbsp;&bull;&nbsp;
                    <FormatDateDistance className="ignore-marker" date={meetingBlock.updated_at} />
                </div>
            </Card.Footer>
        </Card>
    );
};
