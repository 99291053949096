import React from 'react';
import { useParams } from 'react-router-dom';
import { faCircleDashed } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Alert, EmptyState, Skeleton } from '@wedo/design-system';
import { parseSort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { WorkspacesPageSearchParams } from 'Pages/WorkspacesPage/WorkspacesPage';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { WorkspaceGridCard } from 'Shared/components/workspace/WorkspaceGridCard';
import { useGetWorkspacesQuery } from 'Shared/services/workspace';

const WorkspacesInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { teamId } = useParams();
    const [{ view, status, sort, search }] = useSearchParams(WorkspacesPageSearchParams);

    const { data, isLoading, isError, error, refetch } = useGetWorkspacesQuery(
        {
            filter: view,
            status,
            ...parseSort(sort),
            teamId,
            offset,
            limit,
            name: search,
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data);

    return (
        <>
            {error != null ? (
                <RetryComponent className="col-span-full" retryFunction={refetch} />
            ) : isLoading ? (
                <Skeleton count={5} className="h-[22rem]" />
            ) : isError ? (
                <Alert type="danger" title={error?.message ?? ''} />
            ) : (
                data.map((workspace) => <WorkspaceGridCard key={workspace.id} workspace={workspace} />)
            )}
        </>
    );
};

export const WorkspaceGrid = () => {
    const [{ view }] = useSearchParams(WorkspacesPageSearchParams);

    return (
        <InfiniteScroll
            key={view}
            className="scrollbar-light grid auto-rows-min grid-cols-1 gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(22rem,1fr))]"
            page={WorkspacesInfiniteScrollPage}
            emptyPage={
                <EmptyState size="lg" icon={faCircleDashed} className="col-span-full">
                    <EmptyState.Text>
                        <Trans>No workspaces</Trans>
                    </EmptyState.Text>
                </EmptyState>
            }
            size={20}
        />
    );
};
