import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { ActivityLog } from '@wedo/db';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { ActivityLogObject } from 'Shared/types/activityLog';
import { MeetingRole } from 'Shared/types/meetingRole';
import { User } from 'Shared/types/user';

const MeetingRoleTitle: FC<{ role: MeetingRole }> = ({ role }) => {
    return (
        <div style={{ color: role.color }} className="font-semibold">
            {role.name}
        </div>
    );
};

const MeetingTitle: FC<{ meetingId: Id }> = ({ meetingId }) => {
    const { data: meeting } = useGetMeetingQuery(buildGetMeetingParameters(meetingId));

    return (
        <Button size="xs" variant="link" href={`/meetings/${meetingId}`} className="w-full">
            <div className="text-xs group/meeting flex items-center gap-1 w-full font-semibold">
                <span className="truncate">{meeting?.title}</span>
            </div>
        </Button>
    );
};

const MeetingUserTitle: FC<{
    userName: string;
    meetingId: Id;
}> = ({ meetingId, userName }) => {
    return (
        <div className="text-xs flex gap-1 items-center font-semibold">
            {userName} <Trans>in</Trans> <MeetingTitle meetingId={meetingId} />
        </div>
    );
};

const MeetingRoleInMeetingTitle: FC<{
    meetingRole: MeetingRole;
    meetingId: Id;
}> = ({ meetingRole, meetingId }) => {
    return (
        <div className="text-xs flex gap-1 items-center truncate font-semibold">
            <MeetingRoleTitle role={meetingRole} /> <Trans>in</Trans> <MeetingTitle meetingId={meetingId} />
        </div>
    );
};

const MeetingUserEventKeys = new Set(['added_meeting_user']);
const MeetingTitleEventKeys = new Set([
    'added_meeting',
    'deleted_meeting',
    'restored_meeting',
    'meeting_locked',
    'meeting_unlocked',
    'changed_meeting_settings',
]);
const MeetingRoleEventKeys = new Set(['added_meeting_role', 'deleted_meeting_role']);

export const MeetingModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    if (auditLog.objectType !== ActivityLogObject.MEETING) {
        return null;
    }

    if (MeetingTitleEventKeys.has(auditLog.eventKey)) {
        return <MeetingTitle meetingId={auditLog.objectId} />;
    }

    if (MeetingUserEventKeys.has(auditLog.eventKey)) {
        return (
            <MeetingUserTitle
                userName={(auditLog.newValue as { user: User }).user.full_name}
                meetingId={auditLog.objectId}
            />
        );
    }

    if (MeetingRoleEventKeys.has(auditLog.eventKey)) {
        return (
            <MeetingRoleInMeetingTitle meetingId={auditLog.objectId} meetingRole={auditLog.newValue as MeetingRole} />
        );
    }

    return (
        <>
            {auditLog.eventKey === 'updated_meeting_role' && (
                <div className="flex gap-1 text-xs items-center">
                    <MeetingRoleTitle role={auditLog.oldValue as MeetingRole} /> {'-->'}{' '}
                    <MeetingRoleTitle role={auditLog.newValue as MeetingRole} /> <Trans>in</Trans>{' '}
                    <MeetingTitle meetingId={auditLog.objectId} />
                </div>
            )}
        </>
    );
};
