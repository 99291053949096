import { FC, MouseEvent } from 'react';
import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Button, ButtonProps } from '@wedo/design-system';

type FavoriteToggleButtonProps = {
    isFavorite: boolean;
    onClick?: (event: MouseEvent) => void;
    isLoading?: boolean;
    size?: ButtonProps['size'];
    className?: string;
};

export const FavoriteToggleButton: FC<FavoriteToggleButtonProps> = ({
    onClick,
    isFavorite,
    isLoading,
    size = 'md',
    className,
}) => {
    return (
        <Button
            size={size}
            icon={isFavorite ? faHeartSolid : faHeartRegular}
            className={clsx('!text-red-500', className)}
            variant="filled"
            onClick={(event) => onClick(event as MouseEvent)}
            loading={isLoading}
        />
    );
};
