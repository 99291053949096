import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';
import { faCalendar, faDownLong, faHexagon, faHundredPoints, faUpLong } from '@fortawesome/pro-regular-svg-icons';
import { faRankingStar } from '@fortawesome/pro-solid-svg-icons';
import { Trans, msg, t } from '@lingui/macro';
import { Dropdown, ContextModalProps, Modal, Button, useModal, Skeleton, Card } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useActiveUsers, useSessionUser } from 'App/store/usersStore';
import { BadgeLevelCard } from 'Pages/BadgesPage/BadgeLevelCard';
import { BadgesList } from 'Pages/BadgesPage/BadgesList';
import { Leaderboard } from 'Pages/BadgesPage/Leaderboard';
import { PageHeader } from 'Shared/components/PageHeader';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { trpc } from 'Shared/trpc';
import { User } from 'Shared/types/user';

export type AbsoluteBadgeOrder = 'default' | 'obtainedAt' | 'points';

export type BadgeOrder = AbsoluteBadgeOrder | `-${AbsoluteBadgeOrder}`;

export const validSortOrdersAsc: BadgeOrder[] = ['default', 'obtainedAt', 'points'];
export const validSortOrdersDesc: BadgeOrder[] = validSortOrdersAsc.map((order) => `-${order}` as BadgeOrder);
export const validSortOrders: BadgeOrder[] = [...validSortOrdersAsc, ...validSortOrdersDesc];

export const BadgesPageSearchParams = {
    search: string(),
    status: enumeration('all', 'obtained', 'missing').options({ allowUnknown: true }),
    order: enumeration(...validSortOrders).options({ allowUnknown: true }),
};

export const getDirectionLabelFromValue = (value: string, direction?: 'asc' | 'desc') => {
    const isAsc = direction != null ? direction === 'asc' : !value.startsWith('-');
    const absoluteValue = !value.startsWith('-') ? value : value.slice(1);

    if (isAsc) {
        return absoluteValue === 'obtainedAt' ? t`Oldest to newest` : t`Ascending`;
    }
    return absoluteValue === 'obtainedAt' ? t`Newest to oldest` : t`Descending`;
};

const BadgeLevelCardWithSkeleton = ({
    score,
    isLoading,
    isCurrentUser,
}: {
    score: number;
    isLoading: boolean;
    isCurrentUser?: boolean;
}) => {
    return isLoading ? (
        <Card>
            <Skeleton className="h-[112px] w-full" />
        </Card>
    ) : (
        <BadgeLevelCard score={score} isCurrentUser={isCurrentUser} />
    );
};

const LeaderboardModal = ({ users, ...modalProps }: { users: User[] } & ContextModalProps) => {
    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Leaderboard`} />
            <Modal.Body>
                <Leaderboard users={users} />
            </Modal.Body>
        </Modal>
    );
};

export const BadgesPage = () => {
    usePageTitle(t`Badges`);

    const { userId } = useParams();
    const location = useLocation();
    const currentUser = useSessionUser();
    const users = useActiveUsers();
    const { open: openModal } = useModal();
    const { data: userScores, isLoading: isLoadingUserScores } = trpc.badge.listUserScores.useQuery(
        {},
        { refetchOnMount: true }
    );

    const filteredUsers = users
        ?.filter(
            (user) => user.id === currentUser.id || user.userNetwork?.config?.defaultPreferences?.gamification !== false
        )
        .map((user) => ({
            ...user,
            badgeScore: userScores?.find(({ userId }) => userId === Number(user.id))?.score ?? 0,
        }));
    const userScore =
        (filteredUsers || []).find((user) => (userId != null ? user.id === userId : user.id === currentUser.id))
            ?.badgeScore || 0;

    const usersSortedByScore = useMemo(() => {
        return filteredUsers?.sort((a, b) => b.badgeScore - a.badgeScore);
    }, [filteredUsers]);
    const isMyBadgesPage = useMatch('/badges');
    const toolbarRef = useRef<HTMLDivElement>(null);

    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(BadgesPageSearchParams);
    const order = searchParams.order ?? '-obtainedAt';

    const tabs: NavBarTab[] = [
        {
            title: msg({ id: 'All badges', message: 'All' }),
            matchSearchParams: ['status'],
            isDefault: true,
            to: { searchParams: { status: 'all' } },
            keepSearchParams: ['search', 'sort'],
        },
        {
            title: msg({ id: 'Obtained badges', message: 'Obtained' }),
            matchSearchParams: ['status'],
            to: { searchParams: { status: 'obtained' } },
            keepSearchParams: ['search', 'sort'],
        },
        {
            title: msg({ id: 'Missing badges', message: 'Missing' }),
            matchSearchParams: ['status'],
            to: { searchParams: { status: 'missing' } },
            keepSearchParams: ['search', 'sort'],
        },
    ];

    const { data: badges, isLoading: isLoadingBadges } = trpc.badge.list.useQuery({
        ...(userId != null ? { userId: Number(userId) } : {}),
        sort: order,
        filter: searchParams.status,
    });

    const handleSortClick = (newOrder: AbsoluteBadgeOrder) => {
        if (order.startsWith('-')) {
            setSearchParams({ ...searchParams, order: `-${newOrder}` as BadgeOrder });
        } else {
            setSearchParams({ ...searchParams, order: newOrder });
        }
    };

    useEffect(() => {
        if (location.state?.badgeId) {
            const badgeId = location.state.badgeId;
            const badge = document.getElementById(badgeId);
            if (badge != null) {
                badge.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, []);

    const isCurrentUser = userId == null || userId === currentUser.id;

    return (
        <div className="flex flex-col overflow-hidden @container">
            {isMyBadgesPage && <PageHeader title={t`My Badges`} />}
            <div className="block @3xl:hidden p-4">
                <BadgeLevelCardWithSkeleton
                    score={userScore}
                    isLoading={isLoadingUserScores}
                    isCurrentUser={isCurrentUser}
                />
            </div>
            <div className="flex gap-2 justify-between overflow-hidden">
                <div ref={toolbarRef} className="w-full flex flex-col overflow-y-auto">
                    <div className="px-6">
                        <NavBar basePath={pathname} tabs={tabs}>
                            <Dropdown showChevron variant="text" label={t`Sort by`}>
                                <Dropdown.Item
                                    icon={faHexagon}
                                    selected={order.endsWith('default')}
                                    onClick={() => handleSortClick('default')}
                                >{t`Default`}</Dropdown.Item>
                                <Dropdown.Item
                                    icon={faCalendar}
                                    selected={order.endsWith('obtainedAt')}
                                    onClick={() => handleSortClick('obtainedAt')}
                                >{t`Obtained at`}</Dropdown.Item>
                                <Dropdown.Item
                                    icon={faHundredPoints}
                                    selected={order.endsWith('points')}
                                    onClick={() => handleSortClick('points')}
                                >{t`Points`}</Dropdown.Item>
                                <Dropdown.DividerItem />
                                <Dropdown.Item
                                    selected={order && !order.startsWith('-')}
                                    keepOpen
                                    icon={faUpLong}
                                    onClick={() => {
                                        if (order && order.startsWith('-')) {
                                            setSearchParams({ ...searchParams, order: order.slice(1) as BadgeOrder });
                                        }
                                    }}
                                >
                                    {getDirectionLabelFromValue(order, 'asc')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    selected={order && order.startsWith('-')}
                                    keepOpen
                                    icon={faDownLong}
                                    onClick={() => {
                                        if (order && !order.startsWith('-')) {
                                            setSearchParams({ ...searchParams, order: `-${order}` as BadgeOrder });
                                        }
                                    }}
                                >
                                    {getDirectionLabelFromValue(order, 'desc')}
                                </Dropdown.Item>
                            </Dropdown>
                        </NavBar>
                    </div>

                    <div className={'overflow-y-auto flex flex-col'}>
                        {isLoadingBadges ? (
                            <div className={'flex flex-col gap-4 p-6'}>
                                <Skeleton className={'h-96'} count={5} />
                            </div>
                        ) : (
                            <BadgesList badges={badges} isLoading={isLoadingBadges} />
                        )}
                    </div>
                </div>
                <div className="w-96 shrink-0 @3xl:flex flex-col gap-2 mt-2 hidden">
                    <BadgeLevelCardWithSkeleton
                        score={userScore}
                        isLoading={isLoadingUserScores}
                        isCurrentUser={isCurrentUser}
                    />
                    <div className="text-lg font-bold">
                        <Trans>Leaderboard</Trans>
                    </div>
                    <Leaderboard users={usersSortedByScore} />
                </div>
            </div>
            <div className="absolute @3xl:hidden bottom-0 flex items-center justify-center w-full p-2">
                <Button
                    onClick={() => openModal(LeaderboardModal, { users: usersSortedByScore })}
                    icon={faRankingStar}
                >{t`Leaderboard`}</Button>
            </div>
        </div>
    );
};
