import { Id, NumberRange } from '@wedo/types';
import { ActivityLog } from 'Shared/types/activityLog';
import { Checklist } from 'Shared/types/checklist';
import { ColorTuple } from 'Shared/types/colorTuple';
import { CustomFieldGroupValue, CustomFieldValue } from 'Shared/types/customField';
import { Recurrence } from 'Shared/types/formRecurrence';
import { User, UserBadgeLevel } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

export type TaskFilter = 'all' | 'assigned' | 'created' | 'me' | 'unassigned' | 'watched' | `custom-${string}` | number;

export type TaskStatus = 'completed' | 'deleted' | 'todo' | 'linked';

export type TaskLayout = 'kanban' | 'list';

export type RawTaskOrder =
    | 'alphanumeric'
    | 'completed_at'
    | 'default'
    | 'deleted_at'
    | 'due_date'
    | 'planned_date'
    | 'priority'
    | 'rank'
    | 'section'
    | 'status'
    | 'tag'
    | 'user'
    | 'none'
    | `custom-${string}`;

export type TaskOrder = RawTaskOrder | `-${RawTaskOrder}`;

export enum TaskType {
    Task = 'task',
    Milestone = 'milestone',
}

export interface Task {
    id: Id;
    name: string;
    is_new: boolean;
    description?: string;
    language: string;
    private: boolean;
    priority: NumberRange<0, 5>;
    nb_attachments: number;
    nb_comments: number;
    has_subtasks: boolean;
    created_by: Id;
    deleted: boolean;
    completed: boolean;
    completed_at?: string;
    due_date?: string;
    deleted_at?: string;
    order?: number;
    tag_order?: number;
    checklist_id?: Id;
    assignee_id?: Id;
    network_id: Id;
    planned_date?: string;
    checklist_section_id?: Id;
    parent_task_id?: Id;
    created_at?: string;
    type?: TaskType;

    // Computed
    is_forbidden?: boolean;
    is_blocked?: boolean;

    // Related
    assignee?: User;
    completed_by?: User;
    updated_by?: Id;
    deleted_by?: User;
    checklist?: Checklist;
    recurrence?: Recurrence[];
    meeting_activities?: ActivityLog[];
    meeting_comments?: ActivityLog[];
    custom_fields?: CustomFieldValue[];
    customFieldGroups?: CustomFieldGroupValue[];
    tags?: Partial<Workspace>[];
    parentTask?: Task;
    subtasks?: Task[];

    // Update
    meeting_id?: Id;
    workspaces?: Partial<Workspace>[];
    workspace_section_id?: Id;
    enum_custom_field?: {
        id: Id;
        option_id: Id;
    };
    watchers?: string[];

    // Create
    checklist_template_id?: Id;

    // Checklist_template
    relative_due_date: number;
    relative_planned_date: number;
}

export interface SubTask {
    id: Id;
    name: string;
    completed: boolean;
    order?: number;
    created_at: string;
}

export interface TaskWatcher {
    id: Id;
    paid: boolean;
    first_name: string;
    last_name: string;
    photo: string;
    created_at: null | string;
    updated_at: string;
    language_code: string;
    badge_level: UserBadgeLevel;
    badge_completion: number;
    initials: string;
    color: ColorTuple;
    timezone: string;
    display_name: null | string;
    _pivot_id: Id;
    _pivot_task_id: Id;
    _pivot_user_id: Id;
    _pivot_created_by: Id;
    full_name: string;
    photo_url: string;
}

export interface TaskComment {
    id: Id;
    comment: string;
    created_at: string;
    updated_at: string;
    // Related
    created_by: User;
}

export type TasksExport = {
    open_url: string;
    download_url: string;
};
