export const stripSearchParams = (searchParams?: Record<string, unknown>) => {
    return Object.fromEntries(Object.entries(searchParams).filter(([, value]) => value != null && value !== ''));
};

export const stringifySearchParams = (searchParams?: Record<string, unknown>, suffixArrays = false) => {
    return searchParams != null
        ? new URLSearchParams(
              Object.entries(stripSearchParams(searchParams)).reduce(
                  (result, [key, value]) =>
                      result.concat(
                          Array.isArray(value)
                              ? value.map((value) => [suffixArrays ? `${key}[]` : key, value])
                              : [[key, value]]
                      ),
                  []
              )
          ).toString()
        : undefined;
};
