import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';

export const IncludeAllItemsFilterLabel: FC<{ className?: string }> = ({ className }) => (
    <span
        className={clsx('mt-1 shrink-0 rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700', className)}
    >
        <Trans>
            Include items that match{' '}
            <span className="text-gray-900">
                <Trans>all filters</Trans>
            </span>
        </Trans>
    </span>
);
