import { HttpMethod, Id } from '@wedo/types';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { tag as meetingTag, tagType as MeetingTagType } from 'Shared/services/meeting';
import { MeetingUserItem } from 'Shared/types/meetingUserItem';
import { MeetingUser } from '../types/meetingUser';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag, tags } = configureTag('MeetingUser');

export interface UpdateMeetingUsersParameters {
    meetingId: Id;
    changes: {
        deletedMeetingUsers?: Id[];
        updatedMeetingUsers?: { id: Id; changes: Partial<MeetingUser> }[];
        addedMeetingUsers?: Partial<MeetingUser>[];
    };
    applyOn?: string;
}

export const meetingAttendeeApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMeetingAttendees: build.query<MeetingUser[], Id>({
                query: (meetingId) => ({
                    url: `meetings/${meetingId}/users`,
                    params: { related: ['user'] },
                }),
                transformResponse: (res: MeetingUser[]) => res.filter((mu) => mu.is_attendee),
                providesTags: (result, error, meetingId) => [
                    ...tags(result),
                    tag(resourceId(MeetingTagType, meetingId)),
                ],
            }),
            updateMeetingUsers: build.mutation<MeetingUser[], UpdateMeetingUsersParameters>({
                query: ({ meetingId, changes, applyOn }) => ({
                    url: `meetings/${meetingId}/meeting-users`,
                    body: { ...changes, applyOn },
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(resourceId(MeetingTagType, meetingId))],
            }),
            addMeetingUserItems: build.mutation<
                unknown,
                { meetingId: Id; meetingUserItems: Partial<MeetingUserItem>[]; applyOn: APPLY_ON }
            >({
                query: ({ meetingId, applyOn, ...body }) => ({
                    url: `meetings/${meetingId}/user-items`,
                    params: { applyOn },
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => [meetingTag(meetingId)],
            }),
            updateMeetingUserItems: build.mutation<
                unknown,
                { meetingId: Id; meetingUserItems: { id: Id; changes: Partial<MeetingUserItem> }[]; applyOn: APPLY_ON }
            >({
                query: ({ meetingId, applyOn, ...body }) => ({
                    url: `meetings/${meetingId}/user-items`,
                    params: { applyOn },
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => [meetingTag(meetingId)],
            }),
            deleteMeetingUserItems: build.mutation<
                unknown,
                { meetingId: Id; meetingUserItems: Id[]; applyOn: APPLY_ON }
            >({
                query: ({ meetingId, applyOn, ...body }) => ({
                    url: `meetings/${meetingId}/user-items/delete`,
                    params: { applyOn },
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => [meetingTag(meetingId)],
            }),
        }),
    });

export const {
    useGetMeetingAttendeesQuery,
    useUpdateMeetingUsersMutation,
    useAddMeetingUserItemsMutation,
    useUpdateMeetingUserItemsMutation,
    useDeleteMeetingUserItemsMutation,
} = meetingAttendeeApi;
