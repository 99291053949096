import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { MessageDescriptor } from '@lingui/core';
import { msg, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Popover, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';

type AttendanceType = {
    code: string;
    initials: React.ReactNode;
    title: MessageDescriptor;
};
const Attendances: AttendanceType[] = [
    {
        code: 'present',
        initials: <Trans id={'Ids.presentFirstLetter'}>P</Trans>,
        title: msg`Present`,
    },
    {
        code: 'absent',
        initials: <Trans id={'Ids.absentFirstLetter'}>A</Trans>,
        title: msg`Absent`,
    },
    {
        code: 'excused',
        initials: <Trans id={'Ids.excusedFirstLetter'}>E</Trans>,
        title: msg`Excused`,
    },
    {
        code: 'online',
        initials: <Trans id={'Ids.onlineFirstLetter'}>O</Trans>,
        title: msg`Online`,
    },
];

const classes = {
    background: {
        present: 'bg-green-100',
        absent: 'bg-red-100',
        excused: 'bg-yellow-100',
        online: 'bg-purple-100',
    },
    color: {
        present: 'text-green-500 hover:text-green-800',
        absent: 'text-red-500 hover:text-red-800',
        excused: 'text-yellow-500 hover:text-yellow-800',
        online: 'text-purple-500 hover:text-purple-800',
    },
    border: {
        present: 'border border-green-100 hover:border-green-500',
        absent: 'border border-red-100 hover:border-red-500',
        excused: 'border border-yellow-100 hover:border-yellow-500',
        online: 'border border-purple-100 hover:border-purple-500',
    },
};

const AttendanceBadge = ({
    attendance,
    showFullText = false,
    loading = false,
}: {
    attendance: AttendanceType;
    showFullText?: boolean;
    loading?: boolean;
}): JSX.Element => (
    <span
        className={clsx(
            'flex items-center justify-center rounded-full text-sm',
            showFullText ? 'w-fit px-2 py-1' : 'h-6 w-6',
            classes.color[attendance.code],
            classes.background[attendance.code],
            classes.border[attendance.code]
        )}
    >
        {loading ? (
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : showFullText ? (
            <Trans id={attendance.title.id} />
        ) : (
            attendance.initials
        )}
    </span>
);

export const AttendanceReadonly = ({ attendance }: { attendance: string }): JSX.Element => {
    const item = Attendances.find(({ code }) => code === attendance);

    return (
        <Tooltip content={<Trans id={item.title.id} />} wrapperClassName={'w-fit'}>
            <AttendanceBadge attendance={item} />
        </Tooltip>
    );
};

type AttendanceEditableProps = {
    attendeeId: Id;
    attendance: string;
    onUpdate: (applyOn, changes) => void;
};
export const AttendanceEditable = ({ attendeeId, attendance, onUpdate }: AttendanceEditableProps): JSX.Element => {
    const [loading, setLoading] = useState<string>(null);

    useEffect(() => {
        setLoading(null);
    }, [attendance]);

    const handleAttendanceChange = (newAttendance: string) => {
        setLoading(newAttendance);
        onUpdate(APPLY_ON.THIS_MEETING, [
            {
                id: attendeeId,
                changes: {
                    attendance: newAttendance,
                },
            },
        ]);
    };

    return (
        <Popover variant="ghost" text={<AttendanceReadonly attendance={attendance} />}>
            {({ close }) => (
                <div className="flex flex-col justify-between gap-2 bg-white p-2">
                    {Attendances.map((item) => (
                        <Button
                            key={item.code}
                            variant="ghost"
                            onClick={() => {
                                handleAttendanceChange(item.code);
                                close();
                            }}
                            className="mx-1"
                        >
                            <AttendanceBadge attendance={item} loading={loading === item.code} showFullText />
                        </Button>
                    ))}
                </div>
            )}
        </Popover>
    );
};
