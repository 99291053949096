import { Id } from '@wedo/types';
import { User } from 'Shared/types/user';

export enum Status {
    OnTrack = 'on_track',
    OffTrack = 'off_track',
    AtRisk = 'at_risk',
    OnHold = 'on_hold',
    Completed = 'completed',
}

export const Statuses = [Status.OnTrack, Status.AtRisk, Status.OffTrack, Status.OnHold, Status.Completed];

export interface WorkspaceStatus {
    id: Id;
    tag_id: Id;
    status: Status;
    title: string;
    description: string;
    start_date: string | Date;
    due_date: string | Date;

    // Related
    updated_by?: User;
    updated_at?: string;
}
