import React, { FC, useState } from 'react';
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleHalfStroke as fasCircleHalfStroke, faCircle } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Button, Icon } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { CircleDraggableListItem } from 'Pages/governance/CircleDraggableListItem';
import { Circle } from 'Shared/types/governance';

type ItemList = {
    value: string;
    id: Id;
}[];

type CircleListAttributeProps = {
    circle: Circle;
    list: ItemList;
    linkedList: ItemList;
    isEditing: boolean;
    attributeName: 'accountabilities' | 'domains';
    addInputPlaceholder: string;
    updateInputPlaceholder: string;
    deleteTooltipTitle: string;
    handlePaste: (circleId: Id, list: ItemList) => (event: ClipboardEvent) => Promise<void>;
    handleAdd: (
        circleId: Id,
        list: ItemList
    ) => (value: string) => Promise<{
        id: string;
        value: string;
    }>;
    handleUpdate: (circleId: Id, list: ItemList, itemId: Id) => (newValue: string) => Promise<void>;
    handleDelete: (circleId: Id, list: ItemList, itemId: Id) => () => Promise<void>;
    handleReorder: (circleId: Id, list: ItemList) => ({ active, over }: DragEndEvent) => Promise<void>;
    isMirrorChild?: boolean;
};

export const CircleListAttribute: FC<CircleListAttributeProps> = ({
    circle,
    list,
    linkedList,
    isEditing,
    attributeName,
    addInputPlaceholder,
    updateInputPlaceholder,
    deleteTooltipTitle,
    handlePaste,
    handleAdd,
    handleUpdate,
    handleDelete,
    handleReorder,
    isMirrorChild,
}) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [focusId, setFocusId] = useState(null);

    const addItem = async (value: string, shouldFocusOnAdded = false) => {
        const result = await handleAdd(circle.id, circle[attributeName])(value);
        if (shouldFocusOnAdded) {
            setFocusId(result.id);
        }
    };

    return (
        <>
            {list.length > 0 || linkedList.length > 0 ? (
                <>
                    <ul className={clsx('flex flex-col gap-2 list-none')}>
                        {linkedList.map((item) => (
                            <div key={item.id} className="flex gap-1">
                                <div className="flex w-4 h-5 justify-center items-center flex-none">
                                    <Icon
                                        name={fasCircleHalfStroke}
                                        color={'gray-900'}
                                        className="text-[10px] !text-gray-900"
                                    />
                                </div>
                                <CircleDraggableListItem
                                    key={item.id}
                                    id={circle.id + '_' + item.id}
                                    value={item.value}
                                    isEditing={false}
                                    inputPlaceholder={addInputPlaceholder}
                                />
                            </div>
                        ))}
                    </ul>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleReorder(circle.id, circle[attributeName])}
                        modifiers={[restrictToVerticalAxis]}
                    >
                        <SortableContext
                            items={list.map((i) => String(circle.id + '_' + i.id))}
                            strategy={verticalListSortingStrategy}
                        >
                            <ul
                                className={clsx(
                                    isMirrorChild && circle.type === 'role'
                                        ? 'list-none'
                                        : !isEditing && 'pl-4 list-disc'
                                )}
                            >
                                {list.map((item) => (
                                    <div
                                        key={item.id}
                                        className={clsx('flex gap-1 w-full', !isEditing ? 'mb-2 ' : 'mb-3')}
                                    >
                                        {isMirrorChild && circle.type === 'role' && !isEditing && (
                                            <div className="flex w-4 h-5 justify-center items-center flex-none">
                                                <Icon
                                                    name={faCircle}
                                                    color={'gray-900'}
                                                    className="text-[5px] !text-gray-900"
                                                />
                                            </div>
                                        )}
                                        <CircleDraggableListItem
                                            key={item.id}
                                            id={circle.id + '_' + item.id}
                                            value={item.value}
                                            isEditing={isEditing}
                                            shouldAutoFocus={focusId === item.id}
                                            onChange={handleUpdate(circle.id, circle[attributeName], item.id)}
                                            handleAdd={addItem}
                                            deleteTooltipTitle={deleteTooltipTitle}
                                            onDelete={handleDelete(circle.id, circle[attributeName], item.id)}
                                            inputPlaceholder={updateInputPlaceholder}
                                            onPaste={handlePaste(circle.id, circle[attributeName])}
                                        />
                                    </div>
                                ))}
                            </ul>
                        </SortableContext>
                    </DndContext>
                </>
            ) : (
                !isEditing && list.length === 0 && <div className={'pl-2'}>-</div>
            )}
            {isEditing && (
                <Button onClick={() => addItem('', true)} className="w-min shrink-0" icon={faPlus} size={'sm'}>
                    {addInputPlaceholder}
                </Button>
            )}
        </>
    );
};
