import React from 'react';
import { useParams } from 'react-router-dom';
import { faArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Dropdown, Icon, Spinner, Tooltip } from '@wedo/design-system';
import { stopPropagation } from '@wedo/utils';
import { useCanEditTask } from 'Shared/components/task/CanTask';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { Task, TaskType } from 'Shared/types/task';

type TaskStatusProps = {
    isSelected: boolean;
    isEditable: boolean;
    isListReadonly: boolean;
    isUpdating: boolean;
    onToggleComplete: () => void;
    task: Task;
};

export const TaskStatus = ({
    isSelected,
    isEditable,
    isListReadonly,
    isUpdating,
    onToggleComplete,
    task,
}: TaskStatusProps) => {
    const { templateId } = useParams();
    const { isCompleted, isDeleted } = useTaskStatus(task);
    const canEditTask = useCanEditTask(task);

    const isMilestone = task?.type === TaskType.Milestone;
    const isTemplate = task?.checklist != null && task.checklist.checklist_template_id == null;
    const isSubTask = task.parent_task_id != null;
    const isDisabled = !canEditTask || templateId != null;

    if (isListReadonly) {
        return (
            <Icon
                name={
                    isCompleted
                        ? 'completedTask'
                        : isSubTask
                          ? 'openSubtask'
                          : isMilestone
                            ? 'openMilestone'
                            : 'openTask'
                }
                className={clsx(
                    'h-5 w-5',
                    isCompleted ? 'text-green-500' : isSelected ? 'text-gray-400' : 'text-gray-300'
                )}
            />
        );
    }

    if (isDeleted) {
        return <Icon name="deletedTask" className="text-red-500 h-5 w-5" />;
    }

    if (isCompleted) {
        return isEditable ? (
            <Dropdown
                onClick={(e: MouseEvent) => e.stopPropagation()}
                icon={'completedTask'}
                iconClassName={'h-5 w-5'}
                placement={'right'}
                className="!h-5 !w-5 border-0 !bg-transparent pb-0 pl-0 pr-0 pt-0 text-green-500 hover:text-green-700"
            >
                <Dropdown.Item onClick={stopPropagation(onToggleComplete)} icon={faArrowRotateLeft}>
                    {isMilestone ? (
                        <Trans>Restore milestone</Trans>
                    ) : isSubTask ? (
                        <Trans>Restore subtask</Trans>
                    ) : (
                        <Trans>Restore task</Trans>
                    )}
                </Dropdown.Item>
            </Dropdown>
        ) : (
            <Icon
                name={isMilestone ? 'openMilestone' : 'completedTask'}
                className="h-5 w-5 text-green-500 hover:text-green-700"
            />
        );
    }

    if (isMilestone && isTemplate) {
        return <Icon name="openMilestone" className="h-5 w-5" />;
    }

    if (task.is_blocked && !isMilestone) {
        return (
            <Tooltip content={t`This task is blocked by other tasks`}>
                <div className="flex">
                    <Icon
                        name="blockedTask"
                        className={clsx('h-5 w-5', isSelected ? 'text-gray-400' : 'text-gray-300')}
                    />
                </div>
            </Tooltip>
        );
    }

    if (isUpdating) {
        return <Spinner color="blue" className="h-5 w-5 hover:cursor-wait" />;
    }

    return (
        <Button
            variant="ghost"
            className={clsx('flex', (isDisabled || !isEditable) && 'cursor-not-allowed')}
            onClick={onToggleComplete}
            disabled={isDisabled || !isEditable}
        >
            <Icon
                name={isMilestone ? 'openMilestone' : isSubTask ? 'openSubtask' : 'openTask'}
                className={clsx(
                    'h-5 w-5 cursor-pointer',
                    isDisabled || !isEditable ? '!cursor-not-allowed' : 'hover:text-gray-500',
                    isSelected ? 'text-gray-400' : 'text-gray-300'
                )}
            />
        </Button>
    );
};
