import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { SerializedError } from '@reduxjs/toolkit';
import { attachmentError } from 'Shared/services/attachment';
import { ApiError } from 'Shared/types/apiError';

export const ATTACHMENT_SIZE_MAX = 50_000_000;
export const FILE_UPLOAD_COMPLETE = Symbol();
type FileUploadErrorType = 'size' | 'virus' | 'duplicate' | 'unknown';
export type FileUploadError = { success: false; type: FileUploadErrorType; message: string; file: File; data: unknown };
type FileUploadSuccess = { success: true; data: unknown; file: File };
export type FileUploadApiFunction = (
    formData: FormData
) => Promise<{ error: ApiError | SerializedError } | { data: unknown }>;
export type FilesUploadResult = FileUploadError | FileUploadSuccess | typeof FILE_UPLOAD_COMPLETE;
export type FilesUpload = (
    fileList: File[],
    apiFunctionParam: FileUploadApiFunction,
    callbacks: { beforeUpload: BeforeUploadCallback; afterUpload: AfterUploadCallback }
) => Promise<void>;

/** called for each file after trying to upload it.
 * @param result result of the file upload
 * @param index index of the file in the files' list
 * @return Promise Tuple[should we retry again, function to call for the next upload try]
 */
export type AfterUploadCallback = (
    result: FilesUploadResult,
    index: number
) => Promise<[boolean, FileUploadApiFunction]>;

/** called for each file before trying to upload it.
 * @param file file to be uploaded
 */
export type BeforeUploadCallback = (file: File) => Promise<void>;

export const ATTACHMENT_ERROR_MESSAGE = {
    Duplicate: msg`An attachment already exists with this name`,
    DeleteHaveOtherRelations: msg`You can't delete this version because it's used somewhere`,
    TooLarge: msg`The file cannot be uploaded because it is too large`,
    Infected: msg`The file cannot be uploaded because it may contain a virus`,
    Unknown: msg`The file cannot be uploaded`,
};

const handleError = (error: ApiError | SerializedError, file: File): FileUploadError => {
    if (error instanceof ApiError) {
        if (error.matchesSome({ a: attachmentError.Duplicate, b: attachmentError.Duplicate2 })) {
            return {
                success: false,
                type: 'duplicate',
                file: file,
                data: error.data,
                message: i18n._(ATTACHMENT_ERROR_MESSAGE.Duplicate),
            };
        }
        if (error.matches(attachmentError.TooLarge)) {
            return {
                success: false,
                type: 'size',
                file: file,
                data: error.data,
                message: i18n._(ATTACHMENT_ERROR_MESSAGE.TooLarge),
            };
        }
        if (error.matches(attachmentError.Infected)) {
            return {
                success: false,
                type: 'virus',
                file: file,
                data: error.data,
                message: i18n._(ATTACHMENT_ERROR_MESSAGE.Infected),
            };
        }
    }

    return {
        success: false,
        type: 'unknown',
        file: file,
        data: error,
        message: i18n._(ATTACHMENT_ERROR_MESSAGE.Unknown),
    };
};

const handleFile = async (
    file: File,
    apiFunction: FileUploadApiFunction
): Promise<FileUploadError | FileUploadSuccess> => {
    if (file.size > ATTACHMENT_SIZE_MAX) {
        return {
            success: false,
            type: 'size',
            file: file,
            data: null,
            message: i18n._(ATTACHMENT_ERROR_MESSAGE.TooLarge),
        };
    }

    const formData = new FormData();
    formData.append('attachments', file);

    const result = await apiFunction(formData);

    if ('error' in result) {
        return handleError(result.error, file);
    }

    return { success: true, data: result.data, file };
};

export const uploadFiles: FilesUpload = async (fileList, apiFunctionParam, { beforeUpload, afterUpload }) => {
    for (const [index, file] of fileList.entries()) {
        let apiFunction = apiFunctionParam;

        let callbackRetry = true;
        let newApiFunction: FileUploadApiFunction;
        while (callbackRetry) {
            await beforeUpload(file);
            const result = await handleFile(file, apiFunction);
            [callbackRetry, newApiFunction] = await afterUpload(result, index);
            apiFunction = newApiFunction;
        }
    }

    void afterUpload(FILE_UPLOAD_COMPLETE, fileList.length);
};
