import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert } from '@wedo/design-system';

export const DesktopNotificationsNotSupportedBanner = () => {
    return (
        <Alert type="danger" title={t`Your browser does not support desktop notifications`}>
            <Trans>
                You can migrate to a browser that supports desktop notifications such as the latest version of Google
                Chrome, Mozilla Firefox, Safari or Microsoft Edge.
            </Trans>
        </Alert>
    );
};
