import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Checkbox, ContextModalProps, Input, Label, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import { useGenerateRandomDataMutation } from 'Shared/services/meeting';
import { invalidateMeetingSections } from 'Shared/services/meetingSection';
import { invalidateMeetingTopics } from 'Shared/services/meetingTopic';
import { trpcUtils } from 'Shared/trpc';

export const MeetingGenerateDataModal = ({
    meetingId,
    ...modalProps
}: { meetingId: Id } & ContextModalProps): JSX.Element => {
    const [isBigSections, setIsBigSections] = useState(false);
    const [isBigSubSections, setIsBigSubSections] = useState(false);
    const [isBigTopics, setIsBigTopics] = useState(false);
    const [isBigTopicContent, setIsBigTopicContent] = useState(false);
    const [nbSections, setNbSections] = useState(3);
    const [nbSubSections, setNbSubSections] = useState(1);
    const [nbTopics, setNbTopics] = useState(3);
    const [nbMeetingBlocks, setNbMeetingBlocks] = useState(3);
    const [generateData, { isLoading }] = useGenerateRandomDataMutation();
    const dispatch = useAppDispatch();

    const handleConfirm = async () => {
        const parameters = {
            meetingId: meetingId,
            nbTopics: nbTopics,
            nbSections: nbSections,
            nbSubSections: nbSubSections,
            nbMeetingBlocks: nbMeetingBlocks,
        };
        const result = await generateData(parameters);
        if (!('error' in result)) {
            void dispatch(invalidateMeetingTopics(meetingId));
            void dispatch(invalidateMeetingSections(meetingId));
            await trpcUtils().meeting.listBlocks.invalidate({ meetingId });
            await modalProps.close();
        }
    };

    return (
        <Modal size={'md'} {...modalProps}>
            <Modal.Header title={t`Generate random data`} />
            <Modal.Body>
                <div>Define the amount of each type below (random +-1)</div>
                <div className={'mb-2 flex items-center gap-2'}>
                    <Label className={'flex items-center gap-2'}>
                        <Checkbox
                            name="bigSections"
                            onChange={(value) => setIsBigSections(value.target.checked)}
                            checked={isBigSections}
                        />
                        <Trans>How many sections ?</Trans>
                    </Label>
                    <Input
                        type={'number'}
                        defaultValue={nbSections}
                        disabled={!isBigSections}
                        className={'w-20'}
                        min={1}
                        max={10}
                        onChange={(e) => setNbSections(Number(e.target.value))}
                    />
                </div>
                <div className={'mb-2 flex items-center gap-2'}>
                    <Label className={'flex items-center gap-2'}>
                        <Checkbox
                            name="bigSubSections"
                            onChange={(value) => setIsBigSubSections(value.target.checked)}
                            checked={isBigSubSections}
                        />
                        <Trans>How many sub-sections ?</Trans>
                    </Label>

                    <Input
                        className={'w-20'}
                        type={'number'}
                        defaultValue={nbSubSections}
                        disabled={!isBigSubSections}
                        min={1}
                        max={10}
                        onChange={(e) => setNbSubSections(Number(e.target.value))}
                    />
                </div>
                <div className={'mb-2 flex items-center gap-2'}>
                    <Label className={'flex items-center gap-2'}>
                        <Checkbox
                            name="bigTopics"
                            onChange={(value) => setIsBigTopics(value.target.checked)}
                            checked={isBigTopics}
                        />
                        <Trans>How many topics per section?</Trans>
                    </Label>

                    <Input
                        type={'number'}
                        className={'w-20'}
                        defaultValue={nbTopics}
                        disabled={!isBigTopics}
                        min={1}
                        max={10}
                        onChange={(e) => setNbTopics(Number(e.target.value))}
                    />
                </div>
                <div className={'mb-2 flex items-center gap-2'}>
                    <Label className={'flex items-center  gap-2'}>
                        <Checkbox
                            name="bigTopicContents"
                            onChange={(value) => setIsBigTopicContent(value.target.checked)}
                            checked={isBigTopicContent}
                        />
                        <Trans>How many meeting blocks per topic ?</Trans>
                    </Label>
                    <Input
                        type={'number'}
                        className={'w-20'}
                        defaultValue={nbMeetingBlocks}
                        disabled={!isBigTopicContent}
                        min={1}
                        max={10}
                        onChange={(e) => setNbMeetingBlocks(Number(e.target.value))}
                    />
                </div>

                <Alert title={t`What will be generated`} type={'info'} className={'mt-4'}>
                    <ul>
                        <li>
                            Number of sections: {nbSections - 1} - {nbSections + 1}
                        </li>
                        <li>
                            Number of total sub-sections: {(nbSections - 1) * (nbSubSections - 1)} -{' '}
                            {(nbSections + 1) * (nbSubSections + 1)}
                        </li>
                        <li>
                            Number of topics: {(nbSections - 1 + (nbSubSections - 1)) * (nbTopics - 1)} -{' '}
                            {(nbSections + 1 + (nbSubSections + 1)) * (nbTopics + 1)}
                        </li>
                        <li>
                            Number of meeting blocks:{' '}
                            {(nbSections - 1 + (nbSubSections - 1)) * (nbTopics - 1) * (nbMeetingBlocks - 1)} -{' '}
                            {(nbSections + 1 + (nbSubSections + 1)) * (nbTopics + 1) * (nbMeetingBlocks + 1)}
                        </li>
                    </ul>
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button color="primary" loading={isLoading} onClick={handleConfirm}>
                    {t`Generate`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
