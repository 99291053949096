import { useLingui } from '@lingui/react';
import React from 'react';
import { Trans } from '@lingui/macro';
import { MeetingTopic } from '@wedo/db';
import { Button, ContextModalProps, Modal, useModal } from '@wedo/design-system';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { TopicStatusIcon } from 'Pages/meeting/components/TopicStatusIcon';
import { TopicEditor } from 'Shared/components/editor/TopicEditor';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { trpc } from 'Shared/trpc';
import { Meeting } from 'Shared/types/meeting';
import { MeetingTopicSubmission, TopicSubmissionStatus } from 'Shared/types/meetingTopic';

export const PreviewTopicModal = ({
    topic,
    topicSubmission,
    ...modalProps
}: { topic: MeetingTopic; topicSubmission?: MeetingTopicSubmission } & ContextModalProps) => {
    return (
        <Modal {...modalProps}>
            {topicSubmission != null &&
                topicSubmission.status === TopicSubmissionStatus.REJECTED &&
                topicSubmission.reason != null && (
                    <div className="border-b px-4 py-2 w-full outline-none text-sm bg-yellow-50 rounded-t-md">
                        <div>
                            <b>
                                <Trans>Reason for rejection:</Trans>
                            </b>{' '}
                            {topicSubmission.reason}
                        </div>
                    </div>
                )}
            <Modal.Header title={topic.title} />
            <Modal.Body className="!px-2">
                <TopicEditor
                    isReadOnly
                    isStatic
                    topic={topic}
                    viewMode={MeetingViewMode.TopicView}
                    className="min-h-[300px] rounded-b-lg z-20"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const TopicSubmissionHistory = ({
    topicId,
    topicSubmission,
}: {
    topicId: string;
    topicSubmission?: MeetingTopicSubmission;
}) => {
    const { data: { previousSubmissions, submittedTopic, generatedTopic, mostRecentTopic } = {} } =
        trpc.meeting.getSubmissionHistory.useQuery({ topicId }, { enabled: topicId != null });

    const { i18n } = useLingui();

    const { open: openModal } = useModal();

    const getTitleForTopic = (topic: MeetingTopic, meeting: Meeting) => {
        const formattedMeetingTitle = formatMeetingTitle({ ...meeting, start_at: meeting?.startAt }, i18n);
        if (topic.addressed) {
            return <Trans>Completed in {formattedMeetingTitle}</Trans>;
        }
        if (topic.revisited) {
            return <Trans>Revisited in {formattedMeetingTitle}</Trans>;
        }
        return <Trans>Ongoing in {formattedMeetingTitle}</Trans>;
    };

    const getTitleForSubmission = (topicSubmission: MeetingTopicSubmission, meeting: Meeting) => {
        const formattedMeetingTitle = formatMeetingTitle({ ...meeting, start_at: meeting?.startAt }, i18n);
        if (topicSubmission?.status === 'accepted') {
            return <Trans>Accepted in {formattedMeetingTitle}</Trans>;
        }
        if (topicSubmission?.status === 'rejected') {
            return <Trans>Rejected in {formattedMeetingTitle}</Trans>;
        }
        return <Trans>Submitted to {formattedMeetingTitle}</Trans>;
    };

    const handleView = (topic: MeetingTopic, topicSubmission?: MeetingTopicSubmission) => {
        openModal(PreviewTopicModal, { topic, topicSubmission });
    };

    return (
        <>
            {(previousSubmissions?.length > 0 || generatedTopic != null || mostRecentTopic != null) && (
                <div>
                    <hr />
                    <div className="text-sm font-medium p-4">
                        <Trans>Submission history</Trans>
                    </div>
                </div>
            )}

            <div className="flex-col flex gap-2 px-4">
                {previousSubmissions != null &&
                    previousSubmissions.map(({ meetingTopicSubmission, meetingTopic }) => (
                        <div
                            key={meetingTopicSubmission.id}
                            className={'p-2 bg-gray-100 flex gap-2 justify-between rounded-md items-center'}
                        >
                            <div className={'flex gap-2 items-center'}>
                                <TopicStatusIcon topic={meetingTopic} />
                                <div className={'text-sm font-medium'}>
                                    {getTitleForSubmission(meetingTopicSubmission, meetingTopic.meeting)}
                                </div>
                            </div>
                            <Button size="sm" onClick={() => handleView(meetingTopic, meetingTopicSubmission)}>
                                <Trans>View</Trans>
                            </Button>
                        </div>
                    ))}
                {submittedTopic != null && (
                    <div className={'p-2 bg-gray-100 flex gap-2 justify-between rounded-md items-center'}>
                        <div className={'flex gap-2 items-center'}>
                            <TopicStatusIcon topic={null} />
                            <div className={'text-sm font-medium'}>
                                {getTitleForSubmission(topicSubmission, submittedTopic.meeting)}
                            </div>
                        </div>
                        <Button size="sm" onClick={() => handleView(submittedTopic, topicSubmission)}>
                            <Trans>View</Trans>
                        </Button>
                    </div>
                )}
                {generatedTopic != null && (
                    <>
                        <div className={'p-2 bg-gray-100 flex gap-2 justify-between rounded-md items-center'}>
                            <div className={'flex gap-2 items-center'}>
                                <TopicStatusIcon topic={generatedTopic} />
                                <div className={'text-sm font-medium'}>
                                    {getTitleForTopic(generatedTopic, generatedTopic.meeting)}
                                </div>
                            </div>
                            {(generatedTopic.meeting.canAccessMeeting || generatedTopic.addressed) && (
                                <Button size="sm" onClick={() => handleView(generatedTopic)}>
                                    <Trans>View</Trans>
                                </Button>
                            )}
                        </div>
                    </>
                )}
                {mostRecentTopic != null && (
                    <div className={'p-2 bg-gray-100 flex gap-2 justify-between rounded-md items-center'}>
                        <div className={'flex gap-2 items-center'}>
                            <TopicStatusIcon topic={mostRecentTopic} />
                            <div className={'text-sm font-medium'}>
                                {getTitleForTopic(mostRecentTopic, mostRecentTopic.meeting)}
                            </div>
                        </div>
                        {(mostRecentTopic.meeting.canAccessMeeting || mostRecentTopic.addressed) && (
                            <Button size="sm" onClick={() => handleView(mostRecentTopic)}>
                                <Trans>View</Trans>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
