import { useCallback, useMemo } from 'react';
import { MeasuringStrategy, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS, Transform } from '@dnd-kit/utilities';

export const useDndSortableVerticalStrategy = () => {
    const measuring = useMemo(
        () => ({
            droppable: {
                strategy: MeasuringStrategy.WhileDragging,
            },
        }),
        []
    );

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 15,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    const draggableStyle = useCallback(
        (transform: Transform, transition: string, isDragging = false) => ({
            transform: CSS.Transform.toString({ scaleX: 1, scaleY: 1, x: transform?.x, y: transform?.y }),
            transition,
            zIndex: isDragging && 40,
        }),
        []
    );

    return { measuring, sensors, draggableStyle };
};
