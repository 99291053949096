import { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmModal, ContextModalProps } from '@wedo/design-system';

export const ConfirmDeleteFutureTopicsModal: FC<ContextModalProps> = ({ ...modalProps }) => {
    return (
        <ConfirmModal
            {...modalProps}
            type="danger"
            title={t`Delete future topics?`}
            confirmText={t`Yes, delete them`}
            cancelText={t`No, cancel`}
            content={
                <div className="flex gap-4 flex-col">
                    <p>
                        <Trans>Some future topics have content, are you sure you want to delete them?</Trans>
                    </p>
                </div>
            }
        />
    );
};
