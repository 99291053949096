import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { AndOr } from 'Shared/types/filter';

const valueToLabel = {
    and: <Trans>and</Trans>,
    or: <Trans>or</Trans>,
} as const;

type AndOrLabelProps = { value: AndOr };

export const AndOrLabel: FC<AndOrLabelProps> = ({ value }) => {
    return (
        <span className="select-none rounded-md bg-gray-200 px-2 py-1 text-xs font-medium uppercase text-gray-700">
            {valueToLabel[value]}
        </span>
    );
};
