import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Modal,
    RadioGroup,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { BlockTypesPicker } from 'Pages/meeting/components/BlockTypesPicker';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import {
    ApplyOn,
    invalidateMeetingTopicBlocks,
    useImportMeetingTopicBlocksMutation,
} from 'Shared/services/meetingBlock';
import { invalidateTagTaskNotInMeeting } from 'Shared/services/task';
import { trpcUtils } from 'Shared/trpc';
import { Meeting, MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';

const DEFAULT_IMPORT_BLOCKS = ['open_task', 'paragraph', 'decision', 'attachment', 'image', 'vote'];
export const useDefaultImportBlocks = (meeting: Meeting) => {
    const { currentUser } = useCurrentUserContext();
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    return isEditor ? DEFAULT_IMPORT_BLOCKS : DEFAULT_IMPORT_BLOCKS.filter((item) => item !== 'vote');
};

type TopicImportBlocksModalProps = {
    topicId: Id;
    meetingId: Id;
} & ContextModalProps;

export const TopicImportBlocksModal = ({
    topicId,
    meetingId,
    ...modalProps
}: TopicImportBlocksModalProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);
    const { show } = useNotification();
    const defaultImportBlocks = useDefaultImportBlocks(meeting);

    const dispatch = useAppDispatch();
    const [updateMeetingTopicBlocks] = useImportMeetingTopicBlocksMutation();
    const [applyOn, setApplyOn] = useState<ApplyOn>(ApplyOn.ThisTopic);
    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [selectedBlocks, setSelectedBlocks] = useState<string[]>(defaultImportBlocks);

    const handleImportTopic = async () => {
        setIsImporting(true);
        try {
            await updateMeetingTopicBlocks({ meetingId, topicId, blockTypes: selectedBlocks, applyOn });
            dispatch(invalidateTagTaskNotInMeeting(meeting?.tag_id, meetingId));
            dispatch(invalidateMeetingTopicBlocks(topicId));

            void trpcUtils().meeting.listBlocks.invalidate({ meetingId });
            void trpcUtils().meetingTopic.listBlocks.invalidate({ topicId });
            await modalProps.close();
        } catch (e) {
            show(UnexpectedErrorNotification);
            setIsImporting(false);
        }
    };

    return (
        <Modal size={'lg'} {...modalProps}>
            <Modal.Header title={t`Import topic content from past`} />
            <Modal.Body>
                <div className={'mb-1 mt-3 font-bold'}>
                    <Trans>Import content in</Trans>
                </div>
                <RadioGroup name={'import-blocks'} onChange={(value) => setApplyOn(value as ApplyOn)} value={applyOn}>
                    <RadioGroup.Radio key={ApplyOn.ThisTopic} value={ApplyOn.ThisTopic}>
                        <Trans>Only this topic</Trans>
                    </RadioGroup.Radio>
                    <RadioGroup.Radio key={ApplyOn.AllTopics} value={ApplyOn.AllTopics}>
                        <Trans>All empty topics in this meeting</Trans>
                    </RadioGroup.Radio>
                </RadioGroup>
                <BlockTypesPicker meeting={meeting} selectedBlocks={selectedBlocks} onChange={setSelectedBlocks} />
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isImporting} onClick={modalProps.close}>{t`Close`}</Button>
                <Button color="primary" loading={isImporting} onClick={handleImportTopic}>{t`Import content`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
