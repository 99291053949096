import { forwardRef, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { Select } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { trpc } from 'Shared/trpc';
import { Team } from 'Shared/types/team';

type TeamsSelectProps = {
    teamId: string | null;
    onChange: (team: Partial<Team>) => void;
    disabledTeams?: Set<Id>;
};

export const TeamsSelect = forwardRef<HTMLInputElement, TeamsSelectProps>(
    ({ teamId, onChange, disabledTeams = new Set() }, ref) => {
        const sessionUser = useSessionUser();

        const { data: teams = [] } = trpc.team.list.useQuery(
            {},
            {
                select: (teams) => {
                    return sessionUser.role === 'ADMIN'
                        ? teams
                        : teams?.filter(({ members }) => members.some(({ user_id }) => user_id === sessionUser.id));
                },
            }
        );

        const [search, setSearch] = useState<string>();

        const filteredTeams = useMemo(() => {
            return [{ id: null, name: t`No team` }]
                .concat(teams)
                .filter(({ name }) => search == null || search === '' || name.toLowerCase().includes(search));
        }, [teams, search]);

        const handleOnChange = (teamId: string) => {
            onChange(filteredTeams.find(({ id }) => id === teamId));
        };

        return (
            <Select
                ref={ref}
                value={teamId}
                onChange={handleOnChange}
                customRenderSelected={(id) => filteredTeams.find(({ id: teamId }) => id === teamId)?.name ?? t`No team`}
                placeholder={t`No team`}
                onSearch={(value) => setSearch(value?.trim().toLowerCase())}
            >
                {filteredTeams.map(({ id, name }) => (
                    <Select.Option value={id as string} key={id} disabled={disabledTeams.has(id)}>
                        {name}
                    </Select.Option>
                ))}
            </Select>
        );
    }
);
