import { createSlice } from '@reduxjs/toolkit';
import { Id } from '@wedo/types';
import { RootState } from 'App/store';

type MeetingViewSliceState = {
    currentWorkspaceId: Id;
    selectedTaskId: Id;
    selectedVoteId: Id;
    selectedVoteMeetingId: Id;
};

export const initialState: MeetingViewSliceState = {
    currentWorkspaceId: null,
    selectedTaskId: null,
    selectedVoteId: null,
    selectedVoteMeetingId: null,
};

const slice = createSlice({
    name: 'meetingView',
    initialState,
    reducers: {
        currentWorkspaceSelected: (state, action) => {
            state.currentWorkspaceId = action.payload.workspaceId;
        },
        taskSelected: (state, action) => {
            if (action.payload.taskId != null) {
                state.selectedVoteId = null;
            }
            state.selectedTaskId = action.payload.taskId;
        },
        voteSelected: (state, action) => {
            if (action.payload.voteId != null) {
                state.selectedTaskId = null;
            }
            state.selectedVoteId = action.payload.voteId;
            state.selectedVoteMeetingId = action.payload.meetingId ?? null;
        },
    },
});

export const { currentWorkspaceSelected, taskSelected, voteSelected } = slice.actions;

export const selectCurrentWorkspaceId = (state: RootState): Id => state.meetingViewState.currentWorkspaceId;
export const selectSelectedTaskId = (state: RootState): Id => state.meetingViewState.selectedTaskId;
export const selectSelectedVoteId = (state: RootState): Id => state.meetingViewState.selectedVoteId;
export const selectSelectedVoteMeetingId = (state: RootState): Id => state.meetingViewState.selectedVoteMeetingId;

export default slice.reducer;
