import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { faArrowLeft, faComments, faRotateRight, faWifiSlash } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { ErrorPage } from 'Pages/ErrorPage/ErrorPage';
import { ErrorCard } from 'Shared/components/error/ErrorCard';
import { ApiError } from 'Shared/types/apiError';

const ReloadButton = () => {
    return (
        <Button onClick={() => window.location.reload()} size="lg" icon={faRotateRight}>
            <Trans>Reload the page</Trans>
        </Button>
    );
};

const PreviousPage = () => {
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(-1)} size="lg" icon={faArrowLeft}>
            <Trans>Previous page</Trans>
        </Button>
    );
};

export const PageNotFoundErrorCard = () => {
    return (
        <ErrorCard
            title={t`Sorry, we couldn't find this page`}
            subTitle={t`The link you followed is probably broken or the page has been removed.`}
            errorSymbol={<img src="/assets/not-found.svg" alt="" />}
            actions={
                <div className="flex gap-2">
                    <PreviousPage />
                    <Button href="/tasks" color="primary" size="lg">
                        <Trans>My tasks</Trans>
                    </Button>
                </div>
            }
        />
    );
};

export const MissingOrUnauthorizedErrorCard = () => {
    return (
        <ErrorCard
            errorSymbol={<img src="/assets/denied.svg" alt="" />}
            title={t`This resource is either missing or you don't have access to it`}
            subTitle={t`If you think you should have access, please contact your administrator.`}
            actions={
                <div className="flex gap-2">
                    <PreviousPage />
                    <ReloadButton />
                </div>
            }
        />
    );
};

export const RoutingError = () => {
    const error = useRouteError();

    const { show: showIntercom } = useIntercom();

    if (!(error instanceof ApiError)) {
        return <ErrorPage />;
    }

    if (['NotFoundError', 'ForbiddenError'].includes(error.code)) {
        return <MissingOrUnauthorizedErrorCard />;
    }

    if (error.matches({ code: 'FETCH_ERROR' }) || error.matches({ code: 'TIMEOUT_ERROR' })) {
        return (
            <ErrorCard
                errorSymbol={<FontAwesomeIcon icon={faWifiSlash} size="8x" className="text-gray-500" />}
                title={t`Cannot contact the server`}
                subTitle={t`You are offline, please check your network connection.`}
                actions={<ReloadButton />}
            />
        );
    }

    if (error.code.startsWith('5')) {
        return (
            <ErrorCard
                errorSymbol={<img src="/assets/error.svg" alt="" className="max-h-64" />}
                title={t`There was a problem on our side`}
                subTitle={t`If the error persists, please contact support to report the issue.`}
                actions={
                    <div className="flex gap-2">
                        <ReloadButton />
                        <Button onClick={showIntercom} size="lg" icon={faComments}>
                            <Trans>Chat with us</Trans>
                        </Button>
                    </div>
                }
            />
        );
    }

    return <ErrorPage />;
};
