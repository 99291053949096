import React, { FC } from 'react';
import { Table, useModal } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { TemplateDropdown } from 'Shared/components/template/TemplateDropdown';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { Template } from 'Shared/types/template';

export const TemplateTableRow: FC<{ template: Template }> = ({ template }) => {
    const navigate = useNavigate();
    const { open } = useModal();

    const handleLineClick = () => navigate(`/templates/${template.id}/checklists`);

    const handleTeamClick = () => {
        if (template.deleted) {
            return;
        }
        open(TemplateSettingsModal, { templateId: template.id, defaultIndex: 1 });
    };

    return (
        <Table.Row className="cursor-pointer flex">
            <Table.Cell className={'w-[60%] @md:w-[45%] @lg:w-[50%]'} onClick={handleLineClick}>
                {template.name}
            </Table.Cell>
            <Table.Cell onClick={handleLineClick} className="@3xl:w-[15%] w-[20%] text-center">
                {template.meta.nbOpenChecklist}
            </Table.Cell>
            <Table.Cell onClick={handleLineClick} className="@3xl:w-[15%] w-[20%] hidden @md:table-cell text-center">
                {template.meta.nbTaskInChecklist}
            </Table.Cell>
            <Table.Cell onClick={handleTeamClick} className="@3xl:w-[15%] hidden @3xl:table-cell">
                <div className={'flex items-center w-full'}>
                    <UsersAvatarGroup
                        maxDisplayed={3}
                        users={template.userGroup.members.filter((member) => member.is_moderator).map((m) => m.user)}
                    />
                </div>
            </Table.Cell>
            <Table.Cell className={'w-[20%] @md:w-[15%] @lg:w-[10%] @3xl:w-[5%]'}>
                <div className="flex justify-end">
                    <TemplateDropdown template={template} />
                </div>
            </Table.Cell>
        </Table.Row>
    );
};
