import { z } from 'zod';
import { meetingTopicSubmissionOutputSchema } from '@wedo/backend/src/modules/meetingTopic/schema';
import { Id } from '@wedo/types';
import { Meeting } from 'Shared/types/meeting';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { Presenter } from 'Shared/types/presenter';

export interface MeetingTopic {
    id: string;
    meeting_id: Id;
    topic_series_id?: string;
    meeting_section_id?: string;
    revisited: boolean;
    addressed: boolean;
    repeat_every: number;
    updated_at?: string;
    display_id: string;
    title: string;
    order: number;
    duration: number;
    start_at: Date;
    // Related
    meeting?: Partial<Meeting>;
    meetingBlocks?: MeetingBlock[];
    meeting_blocks?: MeetingBlock[];
    presenters: Presenter[];
    next_occurrences: Partial<MeetingTopic>[];
    revisit_meeting_id?: Id;
    displayId?: string;
    updatedAt?: string;
    // Rights
    can_manage_topic: boolean;
    submitted_by: Id;
    submitted_from: Id;
    submitted_by_user: { id: string; full_name: string };
    submitted_from_meeting: { id: string; title: string; start_at: string };
}

export const TopicSubmissionStatus = {
    DRAFT: 'draft',
    SUBMITTED: 'submitted',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    COMPLETED: 'completed',
} as const;

export type MeetingTopicSubmission = z.infer<typeof meetingTopicSubmissionOutputSchema>;
