import React from 'react';
import { faPeopleGroup } from '@fortawesome/pro-duotone-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { EmptyState, Pagination, Skeleton, Table } from '@wedo/design-system';
import { TeamRow } from 'Pages/settings/teams/components/TeamsTable/TeamRow';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { useGetTeamsQuery } from 'Shared/services/team';
import { ApiError } from 'Shared/types/apiError';
import { isFetchError } from 'Shared/utils/rtkQuery';

type TeamsTableProps = {
    search?: string;
    pageSize?: number;
    page?: number;
    className?: string;
    onPageChange?: (page: number) => void;
};

export const TeamsTable: React.FC<TeamsTableProps> = ({ search, page = 1, pageSize = 15, className, onPageChange }) => {
    const { data: teams, isLoading, isError, error, refetch } = useGetTeamsQuery({ search, page, pageSize });

    const pagination = teams?.pagination;

    if (isError && isFetchError(error as ApiError)) {
        return (
            <div className="flex justify-center">
                <RetryComponent retryFunction={refetch} />
            </div>
        );
    }

    if (isEmpty(teams?.data) && !isLoading) {
        return (
            <EmptyState icon={faPeopleGroup} className="py-6">
                <EmptyState.Text>
                    {isEmpty(search) ? <Trans>No teams</Trans> : <Trans>No teams found</Trans>}
                </EmptyState.Text>
            </EmptyState>
        );
    }

    if (isLoading) {
        return (
            <div className="flex flex-col justify-center gap-4">
                <Skeleton count={5} className="h-10" />
            </div>
        );
    }

    return (
        <>
            <Table hoverable size="condensed" striped={true} className={clsx(className)}>
                <Table.Head>
                    <Table.HeadCell>
                        <Trans>Name</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className="hidden xl:table-cell">
                        <Trans>Members</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className="hidden lg:table-cell xl:hidden">
                        <Trans>Members</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className="hidden text-center md:table-cell">
                        <Trans>Workspaces</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className="hidden text-center md:table-cell">
                        <Trans>Templates</Trans>
                    </Table.HeadCell>

                    <Table.HeadCell className="w-14" />
                </Table.Head>

                <Table.Body>{teams && teams?.data.map((team) => <TeamRow key={team.id} team={team} />)}</Table.Body>
            </Table>

            <div className="mt-4 flex justify-center">
                <Pagination
                    currentPage={page}
                    onPageChange={onPageChange}
                    totalCount={Number(pagination.rowCount)}
                    pageSize={pageSize}
                />
            </div>
        </>
    );
};
