import React, { FC, ReactElement } from 'react';
import { faCircleDashed } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Dropdown, Popover } from '@wedo/design-system';

export type MeetingStatus = 'active' | 'deleted' | 'all';

type MeetingStatusDropdownProps = {
    value: MeetingStatus;
    onChange: (value: MeetingStatus) => void;
};

const options: Array<{ id: MeetingStatus; label: ReactElement; shortLabel: ReactElement }> = [
    {
        id: 'active',
        label: <Trans>Active meetings</Trans>,
        shortLabel: <Trans id="Active Meetings">Active</Trans>,
    },
    {
        id: 'deleted',
        label: <Trans>Deleted meetings</Trans>,
        shortLabel: <Trans id="Deleted Meetings">Deleted</Trans>,
    },
    { id: 'all', label: <Trans>All meetings</Trans>, shortLabel: <Trans id="All meetings">All</Trans> },
];

export const MeetingStatusDropdown: FC<MeetingStatusDropdownProps> = ({ value, onChange }) => (
    <Popover text={t`Status`} placement="bottom" variant="text" icon={faCircleDashed} active={value !== 'active'}>
        <div className="flex flex-col bg-white p-1 gap-1">
            {options.map((option) => (
                <Dropdown.Item key={option.id} selected={value === option.id} onClick={() => onChange(option.id)}>
                    {option.label}
                </Dropdown.Item>
            ))}
        </div>
    </Popover>
);
