import React, { FC, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { Modal, Steps } from '@wedo/design-system';
import { ContextModalProps } from '@wedo/design-system';
import { VerifyEmailModalBody } from 'Pages/settings/emails/AddNewEmailModal/AddNewEmailVerifyBody';
import { UserEmail } from 'Shared/types/user-email';
import { AddNewEmailModalBody } from './AddNewEmailModalBody';
import { PasswordVerificationModalBody } from './PasswordVerificationModalBody';

type AddNewEmailModalProps = { userEmail?: UserEmail } & ContextModalProps;

export const AddNewEmailModal: FC<AddNewEmailModalProps> = ({ userEmail, ...modalProps }) => {
    const [password, setPassword] = useState<string>();
    const [step, setStep] = useState(userEmail ? 3 : 1);
    const [email, setEmail] = useState<UserEmail>(userEmail);

    const passwordInputRef = useRef<HTMLInputElement>();
    const emailInputRef = useRef<HTMLInputElement>();
    const verificationCodeInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if ('current' in emailInputRef && step === 2) {
            setTimeout(() => emailInputRef.current?.focus(), 100);
        }
    }, [emailInputRef, step]);

    useEffect(() => {
        if ('current' in verificationCodeInputRef && step === 3) {
            setTimeout(() => verificationCodeInputRef.current?.focus(), 100);
        }
    }, [verificationCodeInputRef, step]);

    const handlePasswordSuccess = (password: string) => {
        setPassword(password);
        setStep(2);
    };

    const handleAddEmailSuccess = (userEmail: UserEmail) => {
        setEmail(userEmail);
        setStep(3);
    };

    return (
        <Modal {...modalProps} initialFocus={passwordInputRef}>
            <Modal.Header title={t`Add email address`} />
            <Steps className={'!rounded-none'}>
                <Steps.Step index={1} currentIndex={step}>
                    {t`Verify password`}
                </Steps.Step>
                <Steps.Step index={2} currentIndex={step}>
                    {t`Enter email`}
                </Steps.Step>
                <Steps.Step index={3} currentIndex={step}>
                    {t`Verify email`}
                </Steps.Step>
            </Steps>
            {step === 1 && (
                <PasswordVerificationModalBody
                    ref={passwordInputRef}
                    onSuccess={handlePasswordSuccess}
                    onCancel={modalProps.close}
                />
            )}
            {step === 2 && (
                <AddNewEmailModalBody
                    ref={emailInputRef}
                    password={password}
                    onSuccess={handleAddEmailSuccess}
                    close={modalProps.close}
                />
            )}
            {step === 3 && (
                <VerifyEmailModalBody ref={verificationCodeInputRef} userEmail={email} close={modalProps.close} />
            )}
        </Modal>
    );
};
