import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentProps, FC } from 'react';
import { faCircleDashed } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { Tooltip } from '@wedo/design-system';
import { Template } from 'Shared/types/template';

export type TemplateIconProps = {
    template: Template;
    showTooltip?: boolean;
    variant?: 'light' | 'dark';
    size?: 'xs' | 'sm' | 'md' | 'lg';
} & ComponentProps<'div'>;

const classes = {
    wrapper: {
        dark: 'bg-gray-900',
        light: 'bg-gray-200',
        size: {
            xs: 'h-3 w-3',
            sm: 'h-5 w-5',
            md: 'h-6 w-6',
            lg: 'h-8 w-8',
        },
    },
    icon: {
        dark: 'text-white',
        light: 'text-gray-700',
        size: {
            xs: 'h-1 w-1',
            sm: 'h-2 w-2',
            md: 'h-3 w-3',
            lg: 'h-4 w-4',
        },
    },
};

export const TemplateIcon: FC<TemplateIconProps> = ({
    template,
    className,
    showTooltip = false,
    variant = 'dark',
    size = 'md',
    ...props
}) => (
    <Tooltip content={showTooltip && template?.name}>
        <div
            className={clsx(
                'flex shrink-0 items-center justify-center rounded',
                classes.wrapper[variant],
                classes.wrapper.size[size],
                className
            )}
            {...props}
        >
            <FontAwesomeIcon icon={faCircleDashed} className={clsx(classes.icon[variant], classes.icon.size[size])} />
        </div>
    </Tooltip>
);
