import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Children, cloneElement, FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { contrastingColor } from '~/utils/color';
import { Button } from '../Button/Button';

export const classes = {
    base: 'flex-shrink-0 flex justify-center items-center w-max space-x-4 group',
    bordered: 'p-1 ring-2 ring-gray-300',
    img: {
        off: 'rounded relative overflow-hidden bg-gray-100',
        on: 'rounded',
    },
    rounded: '!rounded-full',
    size: {
        xs: 'w-5 h-5',
        sm: 'w-7 h-7',
        md: 'w-9 h-9',
        lg: 'w-12 h-12',
        xl: 'w-20 h-20',
        '2xl': 'w-36 h-36',
        '3xl': 'w-72 h-72',
    },
    group: {
        xs: '-space-x-1',
        sm: '-space-x-1',
        md: '-space-x-1',
        lg: '-space-x-2',
        xl: '-space-x-3',
        '2xl': '-space-x-5',
        '3xl': '-space-x-8',
    },
    stacked: 'ring-2 ring-white',
    status: {
        away: 'bg-yellow-400',
        base: 'absolute rounded-full border-2 border-white',
        busy: 'bg-red-400',
        offline: 'bg-gray-400',
        online: 'bg-green-400',
    },
    statusPosition: {
        xs: '-top-1 -right-1 h-3 w-3',
        sm: '-top-1 -right-1 h-3.5 w-3.5',
        md: '-top-1 -right-1 h-3.5 w-3.5',
        lg: '-top-1 -right-1 h-5 w-5',
        xl: '-top-1 -right-1 h-6 w-6',
        '2xl': '-top-1 -right-1 h-10 w-10',
        '3xl': '-top-1 -right-1 h-20 w-20',
    },
    counter: {
        base: 'flex-shrink-0 inline-flex overflow-hidden relative justify-center items-center font-medium text-white bg-gray-900 rounded-full ring-2 ring-gray-200 select-none',
    },
    initials: {
        base: 'flex-shrink-0 inline-flex overflow-hidden relative justify-center items-center font-medium text-gray-700 bg-gray-100',
        xs: 'text-[10px]',
        sm: 'text-sm',
        md: 'text-lg',
        lg: 'text-xl',
        xl: 'text-4xl',
        '2xl': 'text-7xl',
        '3xl': 'text-9xl',
    },
};

const getScale = (nodeRef, childRef): number => {
    if (!childRef.current || !nodeRef.current) {
        return;
    }
    const childrenWidth = childRef.current.offsetWidth; // offsetWidth avoid affecting be transform scale
    const nodeWidth = nodeRef.current.offsetWidth;
    // denominator is 0 is no meaning
    if (childrenWidth !== 0 && nodeWidth !== 0) {
        const gap = Math.max(4, nodeWidth * 0.15);
        if (gap * 2 < nodeWidth) {
            return nodeWidth - gap * 2 < childrenWidth ? (nodeWidth - gap * 2) / childrenWidth : 1;
        }
    }
    return 1;
};

export type AvatarSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

type AvatarProps = {
    backgroundColor?: string;
    style?: object;
    className?: string;
    wrapperClassName?: string;
    alt?: string;
    img?: string;
    initials?: string;
    icon?: IconProp;
    isRounded?: boolean;
    size?: AvatarSizes;
    isStacked?: boolean;
    userFullName?: string;
    status?: 'away' | 'busy' | 'offline' | 'online';
    onClick?: () => unknown;
    onClear?: () => unknown;
    clearButtonLabel?: string;
};
const AvatarBody = ({
    alt,
    img,
    initials,
    icon,
    isRounded = true,
    size = 'md',
    isStacked,
    className,
    style: initialStyle,
}: Partial<AvatarProps>) => {
    const avatarNodeRef = useRef<HTMLDivElement>(null);
    const avatarChildrenRef = useRef<HTMLSpanElement>(null);

    const style = {
        ...initialStyle,
        // TODO We may get rid of the white ring and use a mask in the future
        // mask: isStacked && 'radial-gradient(circle at 150% 50%, transparent 46%, white 47%)',
        // '-webkit-mask': isStacked && 'radial-gradient(circle at 150% 50%, transparent 46%, white 47%)',
    };

    useEffect(() => {
        if (avatarChildrenRef.current != null) {
            avatarChildrenRef.current.style.transform = `scale(${getScale(avatarNodeRef, avatarChildrenRef)})`;
        }
    }, []);

    if (img) {
        return (
            <img
                alt={alt}
                className={clsx(
                    isRounded && classes.rounded,
                    isStacked && classes.stacked,
                    classes.size[size],
                    classes.img.on,
                    className
                )}
                style={style}
                src={img}
            />
        );
    }
    if (initials) {
        return (
            <div
                ref={avatarNodeRef}
                className={clsx(
                    classes.initials.base,
                    isRounded && classes.rounded,
                    isStacked && classes.stacked,
                    classes.size[size],
                    classes.initials[size],
                    classes.img.off,
                    className
                )}
                style={style}
            >
                <span ref={avatarChildrenRef}>{initials}</span>
            </div>
        );
    }
    return (
        <div
            className={clsx(
                'text-gray-800',
                isRounded && classes.rounded,
                isStacked && classes.stacked,
                classes.size[size],
                classes.img.off,
                className
            )}
            style={style}
        >
            <FontAwesomeIcon icon={icon || faUser} className={'h-full w-full'} transform={'shrink-6'} />
        </div>
    );
};

const AvatarGroupCounter: FC<AvatarGroupCounterProps> = ({ total, size = 'md', className, style }) => {
    const [scale, setScale] = useState(1);
    const avatarNodeRef = useRef<HTMLDivElement>(null);
    const avatarChildrenRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        setScale(getScale(avatarNodeRef, avatarChildrenRef));
    }, [avatarChildrenRef.current, avatarNodeRef.current]);

    return (
        <div
            ref={avatarNodeRef}
            className={clsx(classes.counter.base, classes.size[size], classes.initials[size], className)}
            style={style}
        >
            <span ref={avatarChildrenRef} style={{ transform: `scale(${scale})` }}>
                +{total}
            </span>
        </div>
    );
};

const AvatarComponent = ({
    backgroundColor = null,
    className,
    wrapperClassName,
    style: paramStyle,
    alt,
    img,
    initials,
    icon,
    isRounded = true,
    size,
    isStacked,
    status,
    onClick,
    onClear,
    clearButtonLabel,
}: AvatarProps) => {
    const Tag = onClick ? Button : 'div';

    let style = paramStyle;

    if (backgroundColor !== null) {
        const fgColor = contrastingColor(backgroundColor);

        style = { ...paramStyle, backgroundColor, color: fgColor };
    }

    return (
        <Tag
            className={clsx(classes.base, (onClear != null || status != null) && 'relative', wrapperClassName)}
            onClick={onClick}
            variant={onClick != null ? 'ghost' : undefined}
        >
            <AvatarBody
                alt={alt}
                img={img}
                initials={initials}
                icon={icon}
                isRounded={isRounded}
                size={size}
                isStacked={isStacked}
                className={className}
                style={style}
            />
            {onClear && (
                <Button
                    shape={'circle'}
                    icon={faXmark}
                    aria-label={clearButtonLabel ?? t`Remove`}
                    className={
                        'absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 opacity-0 focus:opacity-100 group-hover:opacity-100'
                    }
                    onClick={onClear}
                    size={'xs'}
                    variant={'filled'}
                    color={'danger'}
                />
            )}
            {status && (
                <span className={clsx(classes.status.base, classes.status[status], classes.statusPosition[size])} />
            )}
        </Tag>
    );
};

type AvatarGroupProps = {
    children: JSX.Element | JSX.Element[];
    size?: AvatarSizes;
    maxAvatars?: number;
    className?: string;
};
const AvatarGroup = ({ children, className, size = 'md', maxAvatars }: AvatarGroupProps) => {
    const avatars = useMemo(() => {
        const stackedChildren = Children.map(children as ReactElement<AvatarProps>[], (child) =>
            child
                ? cloneElement(child, {
                      isStacked: true,
                      size,
                  })
                : null
        );
        if (maxAvatars && Children.count(children) > maxAvatars) {
            return stackedChildren.slice(0, maxAvatars - 1);
        }
        return stackedChildren;
    }, [children, size, maxAvatars]);

    return (
        <span className={clsx('isolate flex', classes.group[size], className)}>
            <>
                {avatars}
                {maxAvatars && Children.count(children) > maxAvatars && (
                    <AvatarGroupCounter total={Children.count(children) - maxAvatars + 1} size={size} />
                )}
            </>
        </span>
    );
};

type AvatarGroupCounterProps = {
    total: number;
    size?: AvatarSizes;
    className?: string;
    style?: object;
};

AvatarGroupCounter.displayName = 'Avatar.GroupCounter';

AvatarComponent.displayName = 'Avatar';

export const Avatar = Object.assign(AvatarComponent, {
    Group: AvatarGroup,
    Counter: AvatarGroupCounter,
});
