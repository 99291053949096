import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Modal,
    NoInternetErrorNotification,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { UpdateMeetingsAccessTable } from 'Shared/components/workspace/UpdateMeetingAccessTable/UpdateMeetingsAccessTable';
import { useUpdateMeetingAccess } from 'Shared/components/workspace/UpdateMeetingAccessTable/useUpdateMeetingAccess';
import { useUpdateMeetingAccessMutation } from 'Shared/services/team';
import { useUpdateWorkspaceMeetingAccessMutation } from 'Shared/services/workspace';
import { ApiError } from 'Shared/types/apiError';
import { User } from 'Shared/types/user';
import { isFetchError } from 'Shared/utils/rtkQuery';

type UpdateMeetingsAccessModalProps = {
    deletedMembers: Map<Id, User>;
    addedMembers: Map<Id, User>;
    title: string;
    description?: string;
    teamId?: Id;
    workspaceId?: Id;
} & ContextModalProps;

export const UpdateMeetingsAccessModal: React.FC<UpdateMeetingsAccessModalProps> = ({
    addedMembers,
    deletedMembers,
    title,
    description,
    teamId,
    workspaceId,
    ...modalProps
}) => {
    const { show } = useNotification();
    const { meetingsAccessData, setMeetingsAccessData, payload, accessRightsChangedFor } = useUpdateMeetingAccess(
        addedMembers,
        deletedMembers
    );

    const [updateMeetingAccess, { isLoading }] = useUpdateMeetingAccessMutation();
    const [updateWorkspaceMeetingAccess, { isLoading: isLoadingUpdateWorkspaceMeetingAccess }] =
        useUpdateWorkspaceMeetingAccessMutation();

    const changesMade = useMemo<boolean>(() => accessRightsChangedFor > 0, [accessRightsChangedFor]);

    const handleFinalize = async () => {
        let response;
        if (workspaceId) {
            response = await updateWorkspaceMeetingAccess({ workspaceId, payload });
        } else {
            response = await updateMeetingAccess({ teamId, payload });
        }
        if ('error' in response) {
            const error = response.error as ApiError;
            if (isFetchError(error)) {
                show(NoInternetErrorNotification);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            await modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={title} />

            <Modal.Body>
                <p className="mb-6 text-gray-700">{description}</p>
                <UpdateMeetingsAccessTable value={meetingsAccessData} onChange={setMeetingsAccessData} />
            </Modal.Body>

            {changesMade && (
                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Skip</Trans>
                    </Button>

                    <Button
                        color="primary"
                        onClick={handleFinalize}
                        loading={isLoading || isLoadingUpdateWorkspaceMeetingAccess}
                    >
                        <Trans>Update meeting access</Trans>
                    </Button>
                </Modal.Footer>
            )}

            {!changesMade && (
                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};
