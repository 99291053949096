import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { FeedItem } from 'Pages/activityFeed/FeedItem/FeedItem';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { ActivityLog } from 'Shared/types/activityLog';
import { Task } from 'Shared/types/task';

export const FeedItemCompletedTask = ({ activityFeed }: { activityFeed: ActivityLog }): JSX.Element => {
    const { open } = useModal();

    const createdBy = activityFeed.created_by?.full_name;
    const task = activityFeed.object as Task;

    const handleClick = (): void => {
        open(TaskModal, { taskId: task.id });
    };

    return (
        <>
            <FeedItem icon="completedTask" color="green">
                <FeedItem.Wrapper color="green" isDark>
                    <Button
                        variant="ghost"
                        className="cursor-pointer px-5 py-3 text-left !whitespace-normal"
                        onClick={handleClick}
                    >
                        <Trans>
                            <b>{createdBy}</b> completed the task <b>{task.name}</b>
                        </Trans>
                    </Button>
                </FeedItem.Wrapper>
                <FeedItem.Footer createdAt={new Date(activityFeed.created_at)} createdBy={activityFeed.created_by} />
            </FeedItem>
        </>
    );
};
