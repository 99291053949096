import { useMemo } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Id } from '@wedo/types';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';

export type PendingTasksStore = {
    completedTasks: Set<Id>;
    deletedTasks: Set<Id>;
    openTasks: Set<Id>;
    assigneeTasks: Map<Id, { previous: User; next: User }>;
};

export const PendingTasksState: PendingTasksStore = {
    completedTasks: new Set(),
    deletedTasks: new Set(),
    openTasks: new Set(),
    assigneeTasks: new Map(),
};

export const usePendingTasksStore = create<PendingTasksStore>()(immer(() => PendingTasksState));

export const usePendingTasks = () => {
    const completedTasks = usePendingTasksStore((state) => state.completedTasks);
    const deletedTasks = usePendingTasksStore((state) => state.deletedTasks);
    const assigneeTasks = usePendingTasksStore((state) => state.assigneeTasks);
    const openTasks = usePendingTasksStore((state) => state.openTasks);

    const invalidatedTasks = useMemo<Set<Id>>(
        () => new Set([...completedTasks, ...deletedTasks, ...openTasks, ...assigneeTasks.keys()]),
        [completedTasks, deletedTasks, assigneeTasks, openTasks]
    );

    const addToCompletedTasks = (task: { id: Id }) => {
        usePendingTasksStore.setState(({ completedTasks, openTasks }) => {
            completedTasks.add(task.id);
            openTasks.delete(task.id);
        });
    };

    const removeFromCompletedTasks = (task: Task) => {
        usePendingTasksStore.setState(({ completedTasks, openTasks }) => {
            completedTasks.delete(task.id);
            openTasks.add(task.id);
        });
    };

    const addToDeletedTasks = (taskId: Id) => {
        usePendingTasksStore.setState(({ deletedTasks }) => {
            deletedTasks.add(taskId);
        });
    };

    const removeFromDeletedTasks = (taskId: Id) => {
        usePendingTasksStore.setState(({ deletedTasks }) => {
            deletedTasks.delete(taskId);
        });
    };

    const toggleAssigneeTask = (task: Task, user: User) => {
        usePendingTasksStore.setState(({ assigneeTasks }) => {
            if (assigneeTasks.has(task.id) && user?.id === assigneeTasks.get(task.id).previous.id) {
                assigneeTasks.delete(task.id);
            } else if (assigneeTasks.has(task.id)) {
                assigneeTasks.set(task.id, {
                    previous: assigneeTasks.get(task.id).previous,
                    next: user,
                });
            } else {
                assigneeTasks.set(task.id, {
                    previous: task?.assignee as User,
                    next: user,
                });
            }
        });
    };

    return {
        invalidatedTasks,
        addToCompletedTasks,
        removeFromCompletedTasks,
        addToDeletedTasks,
        removeFromDeletedTasks,
        toggleAssigneeTask,
    };
};
