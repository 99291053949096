import { FC, PropsWithChildren } from 'react';
import { Card } from '@wedo/design-system';
import { GridCardActions } from 'Shared/components/GridCard/components/GridCardActions';
import { GridCardBody } from 'Shared/components/GridCard/components/GridCardBody';
import { GridCardHeader } from 'Shared/components/GridCard/components/GridCardHeader';
import { GridCardMetric } from 'Shared/components/GridCard/components/GridCardMetric';
import { GridCardMetrics } from 'Shared/components/GridCard/components/GridCardMetrics';
import { GridCardTags } from 'Shared/components/GridCard/components/GridCardTags';
import { GridCardTitle } from 'Shared/components/GridCard/components/GridCardTitle';

const GridCardComponent: FC<PropsWithChildren> = ({ children }) => {
    return <Card className="group/grid-card relative">{children}</Card>;
};

export const GridCard = Object.assign(GridCardComponent, {
    Actions: GridCardActions,
    Body: GridCardBody,
    Header: GridCardHeader,
    Metric: GridCardMetric,
    Metrics: GridCardMetrics,
    Tags: GridCardTags,
    Title: GridCardTitle,
});
