import clsx from 'clsx';
import { getHighestVoteAndCount, getOptionCount } from 'Pages/meeting/components/Vote/VoteUtils';
import { Meeting } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { Vote, VoteOption } from 'Shared/types/vote';

const getBarHeight = (option: VoteOption, vote: Vote, meetingUsers: MeetingUser[]) => {
    const highestOption = getHighestVoteAndCount(vote, meetingUsers, true);
    const valueCount = getOptionCount({ voteOption: option, vote, meetingUsers });
    if (highestOption.count == null || highestOption.count === 0) {
        return 0;
    }
    const percent = (valueCount / highestOption.count) * 100;
    return `${percent}%`;
};

interface RatingChartProps {
    vote: Vote;
    meeting: Meeting;
    size: 'sm' | 'md' | null;
    hidden: boolean;
}

export const RatingChart = ({ vote, meeting, size, hidden = false }: RatingChartProps): JSX.Element => {
    return (
        <div
            className={clsx(
                'flex w-full items-end justify-center transition-[height] ',
                size === 'sm' ? 'gap-4' : size === 'md' ? 'gap-6' : 'gap-2',
                hidden ? 'w-0' : 'w-[100px]'
            )}
        >
            {vote?.voteOptions?.map((option) => {
                return (
                    <div
                        className={clsx(
                            'bg-blue-500 transition-colors',
                            size === 'sm' || size === 'md' ? 'w-7' : 'w-5'
                        )}
                        key={option.value}
                        style={{
                            height: hidden ? '0px' : getBarHeight(option, vote, meeting?.meetingUsers),
                        }}
                    />
                );
            })}
        </div>
    );
};
