import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { isString } from 'lodash-es';
import { ButtonProps } from '~/components/Button/Button';
import { classes as buttonClasses } from '~/components/Button/Button';
import { IconPickerInline, IconPickerInlineProps } from '~/components/IconPicker/IconPickerInline';
import { Popover } from '~/components/Popover/Popover';
import { FaIconName } from '@wedo/types';
import { getIconFromName } from './utils';

type IconPickerProps = {
    icon: FaIconName | IconDefinition;
    noIconText?: string;
    noIconHoverText?: string;
    showSelectedIcon?: boolean;
} & Omit<ButtonProps, 'icon' | 'onClick'> &
    Omit<IconPickerInlineProps, 'icon'>;

export const IconPicker: FC<IconPickerProps> = ({
    icon,
    onClick,
    noIconText = 'Icon',
    noIconHoverText = 'Icon',
    size = 'md',
    showSelectedIcon = true,
    canDeselectIcon = true,
    ...buttonProps
}) => {
    const [isHover, setIsHover] = useState<boolean>();

    const handleClick = (closePopover: () => void, icon: IconDefinition): void => {
        closePopover();
        onClick(icon);
    };

    const renderedIcon: IconDefinition = isString(icon) ? getIconFromName(icon) : icon;

    return (
        <Popover
            className={clsx(buttonClasses.size.noText[size], 'w-12')}
            text={
                renderedIcon && showSelectedIcon ? (
                    <FontAwesomeIcon icon={renderedIcon} />
                ) : isHover ? (
                    noIconHoverText
                ) : (
                    noIconText
                )
            }
            size={size}
            {...buttonProps}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {({ close }) => (
                <IconPickerInline
                    icon={renderedIcon}
                    onClick={(icon) => handleClick(close, icon)}
                    canDeselectIcon={canDeselectIcon}
                    isBorderVisible={false}
                />
            )}
        </Popover>
    );
};
