import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { faMinus, faPlus, faSpinner, faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { t } from '@lingui/macro';
import { isNumber } from 'lodash-es';
import { Button, Input, ItemGroup, Popover } from '@wedo/design-system';
import { onEnter } from '@wedo/utils';

type TopicDurationProps = {
    duration: number;
    onUpdate: ((value: number) => void) | ((value: number) => Promise<void>);
    onSave?: () => void;
};

export const TopicDurationCacheKey = 'topic-duration-cache-key';

const isDurationValid = (duration: number) => isNumber(duration) && !isNaN(duration) && duration >= 0;

export const TopicDuration: FC<TopicDurationProps> = ({ duration: initialDuration, onUpdate, onSave }) => {
    const inputRef = useRef<HTMLInputElement>();

    const [durationString, setDurationString] = useState<string>(initialDuration.toString());

    const duration = Number(durationString);

    const increment = useMemo(() => (duration >= 30 ? 10 : duration >= 10 ? 5 : 1), [duration]);
    const decrement = useMemo(() => (duration >= 40 ? 10 : duration >= 15 ? 5 : 1), [duration]);

    useEffect(() => {
        setDurationString(initialDuration.toString());
        inputRef?.current?.focus();
    }, [initialDuration]);

    useEffect(() => {
        setTimeout(() => inputRef?.current?.focus(), 100);
    }, []);

    const handleUpdateDuration = async (duration: number) => {
        let value = duration;
        if (!isDurationValid(duration)) {
            value = 0;
        }
        await onUpdate(value);
    };

    const handlePlus = () => handleUpdateDuration(duration + increment);
    const handleMinus = () => handleUpdateDuration(duration - decrement);

    return (
        <div className={'flex flex-col items-center justify-center gap-2'}>
            <ItemGroup>
                <Button
                    disabled={duration === 0}
                    color={'gray'}
                    title={t`Decrease`}
                    icon={faMinus}
                    onClick={handleMinus}
                />
                <Input
                    ref={inputRef}
                    inputClassName={
                        'text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                    }
                    className={'w-14'}
                    type={'number'}
                    value={durationString}
                    min={0}
                    max={999}
                    onChange={(e) => {
                        if (e.target.value.trim().length > 0) {
                            void handleUpdateDuration(Number(e.target.value));
                        }
                        setDurationString(e.target.value);
                    }}
                    onKeyDown={onSave ? onEnter(onSave) : undefined}
                />
                <Input.Addon text={t({ message: 'min', comment: '"min" as in "minutes"' })} />
                <Button
                    disabled={duration === 999}
                    title={t`Increase`}
                    color={'gray'}
                    onClick={handlePlus}
                    icon={faPlus}
                />
            </ItemGroup>
            <div className={'flex gap-1'}>
                <Button size={'sm'} onClick={() => handleUpdateDuration(0)}>
                    0'
                </Button>
                <Button size={'sm'} onClick={() => handleUpdateDuration(5)}>
                    5'
                </Button>
                <Button size={'sm'} onClick={() => handleUpdateDuration(10)}>
                    10'
                </Button>
                <Button size={'sm'} onClick={() => handleUpdateDuration(15)}>
                    15'
                </Button>
            </div>
        </div>
    );
};

export const TopicDurationPopover = ({
    duration: initialDuration,
    startAt = new Date(),
    onUpdate,
    disabled = false,
}: {
    duration: number;
    startAt?: Date;
    onUpdate?: (value: number) => Promise<any>;
    disabled?: boolean;
}) => {
    const [duration, setDuration] = useState<number>(initialDuration);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => setDuration(initialDuration), [initialDuration]);

    const handleUpdate = async () => {
        setIsLoading(true);
        const res = await onUpdate(duration);
        if ('error' in res) {
            setDuration(initialDuration);
        }
        setIsLoading(false);
    };

    if (disabled) {
        if (duration > 0 && startAt) {
            return (
                <div
                    className={
                        'h-[1.875rem] justify-center select-none items-center border border-gray-300 bg-white flex px-2 rounded text-xs text-gray-700'
                    }
                >
                    {duration}'
                </div>
            );
        }
        return null;
    }

    return (
        <Popover
            wrapperClassName={'flex items-center'}
            size={'sm'}
            title={t`Topic duration`}
            disabled={disabled}
            text={
                isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                ) : duration > 0 && startAt ? (
                    `${duration}'`
                ) : (
                    <FontAwesomeIcon icon={faStopwatch} />
                )
            }
            placement={'bottom'}
            afterLeave={handleUpdate}
        >
            <div className={'bg-white p-4'}>
                <TopicDuration duration={duration} onUpdate={setDuration} />
            </div>
        </Popover>
    );
};
