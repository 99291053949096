import { BaseTree, CheckboxTree, parseTreeToCheckboxTree } from '@wedo/design-system';
import { numericCompare } from '@wedo/utils';
import { Meeting } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const sectionsToCheckboxTree = (
    sections: MeetingSection[],
    topics: MeetingTopic[],
    meeting: Meeting
): CheckboxTree => {
    const tree: BaseTree = { id: null, label: '', children: [] };
    let nodesToVisit = [tree];
    const showNumbering = !meeting?.settings?.hide_section_numbering ?? true;

    while (nodesToVisit.length > 0) {
        const node = nodesToVisit[0];

        const childrenSections = sections?.filter((section) => section.parent_section_id === node.id) ?? [];
        const childrenTopics = topics?.filter((topic) => topic.meeting_section_id === node.id) ?? [];

        const children = (childrenSections as (MeetingTopic | MeetingSection)[])
            .concat(childrenTopics)
            .sort((a, b) => numericCompare(a.display_id, b.display_id));

        if (children.length > 0) {
            node.children = children.map((child) => ({
                id: String(child.id),
                label: (showNumbering ? child.display_id + ' ' : '') + child.title,
                children: null,
                payload: child,
            }));

            nodesToVisit = nodesToVisit.concat(node.children);
        }

        nodesToVisit = nodesToVisit.slice(1);
    }

    return parseTreeToCheckboxTree(tree);
};
