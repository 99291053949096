import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    DroppableContainer,
    getFirstCollision,
    pointerWithin,
} from '@dnd-kit/core';
import { CollisionDetection } from '@dnd-kit/core/dist/utilities';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { faArrowsRotate, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';
import { create } from 'zustand';
import { Button, EmptyState, Skeleton } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { EmptyArray, move, not } from '@wedo/utils';
import { useImmer } from '@wedo/utils/hooks';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { invalidateCachedTasks, useTasksContext, useTasksList } from 'App/contexts/TasksContext';
import { Layout } from 'Pages/TasksPage/types';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { useWebSocketEvent } from 'Shared/hooks/useWebSocketEvent';
import { invalidateTasks, useUpdateTasksMutation } from 'Shared/services/task';
import { trpcUtils } from 'Shared/trpc';
import { Notification } from 'Shared/types/notification';
import { Task } from 'Shared/types/task';
import { Group, GroupedTask, useGroupedTasks } from '../../hooks/useGroupedTasks';
import { TaskDragOverlay } from './TaskDragOverlay';
import { getDateFromKey, TasksGroup } from './TasksGroup';

const DefaultPageSize = 50;
const InfiniteScrollThreshold = 100;
const MaxAutoScrolls = 3;

const isSortable = (droppable: DroppableContainer) => 'sortable' in droppable.data.current;

type DraggedTasksStore = {
    draggedTasks: GroupedTask[];
};

export const useDraggedTasksStore = create<DraggedTasksStore>()(() => ({
    draggedTasks: [] as GroupedTask[],
}));

export type TasksListProps = {
    layout: Layout;
    isReadOnly?: boolean;
    keepTasksCollapsed?: boolean;
    hideEmptyGroups?: boolean;
    pageSize?: number;
    onTaskSelected?: () => void;
    onGroupSelected?: () => void;
    className?: string;
    wrapperClassName?: string;
};

export const TasksList = ({
    layout,
    isReadOnly = false,
    keepTasksCollapsed = false,
    hideEmptyGroups = false,
    pageSize = DefaultPageSize,
    className,
    wrapperClassName,
}: TasksListProps) => {
    const dispatch = useDispatch();
    const tasksListRef = useRef<HTMLDivElement>();
    const previousGroups = useRef<Group[]>();
    const lastOverId = useRef<Id>();

    const [updateTasks] = useUpdateTasksMutation();

    const {
        selectedTasks,
        recentlyCreatedTaskId,
        params: { view, statuses, grouping, order, searchType, workspaceId, userId, checklistId, templateId },
        setSelectedTasks,
        setRecentlyCreatedTaskId,
    } = useTasksContext();

    const {
        tasks,
        isLoading,
        isFetching,
        fetchMore,
        error: fetchTaskError,
        canFetchMore,
    } = useTasksList({
        pageSize,
        onPageLoad: () => {
            if (tasksListRef.current?.scrollHeight <= tasksListRef.current?.clientHeight) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                handleScroll({ target: tasksListRef.current });
            }
        },
    });

    const originalGroups = useGroupedTasks(tasks, workspaceId, checklistId, templateId, grouping, hideEmptyGroups);
    const previousWorkspaceId = usePrevious(workspaceId);

    const [oldTasks, setOldTasks] = useState(tasks || []);
    const [groups, setGroups] = useImmer<Group[]>(originalGroups);
    const [remainingAutoScrolls, setRemainingAutoScrolls] = useState(MaxAutoScrolls);
    const [taskElements, setTaskElements] = useState<Map<Id, HTMLDivElement>>(new Map());
    const [draggedTaskPosition, setDraggedTaskPosition] = useState<{ left: number; right: number }>({
        left: Number.POSITIVE_INFINITY,
        right: Number.NEGATIVE_INFINITY,
    });

    const orderedGroupedTasks = useMemo(() => groups.flatMap(({ tasks }) => tasks), [groups]);

    useInvalidationEvent(
        invalidateCachedTasks,
        taskQueryTag.updated('*', 'completed'),
        taskQueryTag.updated('*', 'deleted')
    );

    useWebSocketEvent('notification', (notification: Notification) => {
        if (notification.event_key.includes('task')) {
            invalidateCachedTasks();
            dispatch(invalidateTasks([notification.task]));
        }
    });

    useWebSocketEvent('notification_update', (notification: Notification) => {
        if (notification.event_key.includes('task')) {
            invalidateCachedTasks();
            dispatch(invalidateTasks([notification.task]));
        }
    });

    useWebSocketEvent('notification_cancel', (notification: Notification) => {
        if (notification.event_key.includes('task')) {
            invalidateCachedTasks();
            dispatch(invalidateTasks([notification.task]));
        }
    });

    useEffect(() => {
        if (layout === 'list' || ['section', '-section'].includes(grouping)) {
            return;
        }
        if (draggedTaskPosition.left / window.innerWidth < 0.2) {
            tasksListRef?.current?.scrollBy({ left: -350, behavior: 'smooth' });
        } else if (draggedTaskPosition.right / window.innerWidth > 0.8) {
            tasksListRef.current.scrollBy({ left: 350, behavior: 'smooth' });
        }
    }, [draggedTaskPosition]);

    // Close the task details panel when switching workspace
    if (previousWorkspaceId !== workspaceId) {
        setSelectedTasks([]);
    }

    if (originalGroups !== previousGroups.current) {
        previousGroups.current = originalGroups;
        setGroups(originalGroups);
    }

    const addTaskElement = (id: Id, element: HTMLDivElement) => {
        setTaskElements(taskElements.set(id, element));
    };

    const findGroupedTask = (id: Id) => {
        for (const group of groups) {
            for (const task of group.tasks) {
                if (task.groupedId === id) {
                    return task;
                }
            }
        }
        return null;
    };

    const collisionDetection: CollisionDetection = ({
        active,
        collisionRect,
        droppableRects,
        droppableContainers,
        pointerCoordinates,
    }) => {
        // When dragging multiple tasks, we always restrict DnD to groups other than the one we originated from
        if (['section', '-section'].includes(grouping)) {
            const state = useDraggedTasksStore.getState();
            // When dragging multiple tasks between sections, we restrict DnD to tasks groups only, otherwise, we
            // restrict DnD to tasks only
            const collisions = pointerWithin({
                active,
                collisionRect,
                droppableRects,
                droppableContainers:
                    state.draggedTasks.length > 1
                        ? droppableContainers.filter(not(isSortable))
                        : droppableContainers.filter(isSortable),
                pointerCoordinates,
            });
            if (collisions.length > 0) {
                lastOverId.current = getFirstCollision(collisions, 'id');
                return collisions;
            }
            return lastOverId.current != null && state.draggedTasks.length <= 1 ? [{ id: lastOverId.current }] : [];
        }
        return pointerWithin({
            active,
            collisionRect,
            droppableRects,
            droppableContainers: droppableContainers.filter(not(isSortable)),
            pointerCoordinates,
        });
    };

    const handleDragStart = ({ active }: DragStartEvent) => {
        // If the dragged task is not a selected one, unselect the selected tasks
        if (!selectedTasks.some(({ groupedId }) => groupedId === active.id)) {
            setSelectedTasks([]);
            useDraggedTasksStore.setState({ draggedTasks: [findGroupedTask(active.id)] });
        } else {
            // We directly read from the store as it may have been modified just above
            const draggedTasks = selectedTasks.map((selectedTask) => findGroupedTask(selectedTask.groupedId));
            // We always put the active task at index 0 so when dragging multiple tasks, it will show first
            const index = draggedTasks.findIndex(({ groupedId }) => groupedId === active.id);
            if (index > 0) {
                move(draggedTasks, index, 0);
            }
            useDraggedTasksStore.setState({ draggedTasks });
        }
    };

    const handleDragOver = ({ active, over }: DragOverEvent) => {
        const state = useDraggedTasksStore.getState();
        // We get the active task from draggedTasks instead of from active because active may not be up-to-date
        const activeTask = state.draggedTasks[0];
        const activeGroupKey = activeTask.groupKey;
        const overGroupKey = (over?.data.current.group as Group)?.key;

        setDraggedTaskPosition({ left: over?.rect?.left, right: over?.rect?.right });

        // If we are dragging only one task from a group to a different group and if we are grouping by sections, then
        // we have to move the task and update its group key
        if (
            ['section', '-section'].includes(grouping) &&
            state.draggedTasks.length === 1 &&
            over != null &&
            activeGroupKey !== overGroupKey
        ) {
            useDraggedTasksStore.setState({
                draggedTasks: state.draggedTasks.map((task) => ({ ...task, groupKey: overGroupKey })),
            });

            setGroups((groups) => {
                // We remove the active task from its group...
                const activeGroup = groups.find(({ key }) => key === activeGroupKey);
                const activeTaskIndex = activeGroup.tasks.findIndex(({ id }) => id === activeTask.id);

                // ...update its group key...
                const [movedTask] = activeGroup.tasks.splice(activeTaskIndex, 1);
                movedTask.groupKey = overGroupKey;

                // ...and add it to the over group
                const overGroup = groups.find(({ key }) => key === overGroupKey);
                const overTaskIndex =
                    overGroup.tasks.findIndex(({ groupedId }) => groupedId === over.id) +
                    (active.rect.current.translated.top > over.rect.top + over.rect.height / 2 ? 1 : 0);
                overGroup.tasks.splice(overTaskIndex, 0, movedTask);
            });
        }
    };

    const handleDragCancel = () => {
        useDraggedTasksStore.setState({ draggedTasks: EmptyArray as GroupedTask[] });
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        const draggedTasks = useDraggedTasksStore.getState().draggedTasks;

        useDraggedTasksStore.setState({ draggedTasks: EmptyArray as GroupedTask[] });
        setSelectedTasks([]);

        if (active == null || over == null) {
            return;
        }

        const { key: activeGroupKey } = active.data.current.group as Group;
        const { key: overGroupKey, type: overGroupType } = over.data.current.group as Group;

        const changes: {
            [id: string]: Partial<Task>;
        } = {};

        // If we group by sections, optimistically update the tasks to prevent jumping, and update the tasks order
        if (['section', '-section'].includes(grouping)) {
            let updatedGroups: Group[];

            if (draggedTasks.length > 1) {
                // When dropping multiple tasks, push the dropped tasks to the end of the over group
                updatedGroups = setGroups((groups) => {
                    const overGroup = groups.find(({ key }) => key === overGroupKey);
                    draggedTasks.forEach((task) => {
                        if (task.originalGroupKey !== overGroupKey) {
                            const group = groups.find(({ key }) => key === task.groupKey);
                            const [movedTask] = group.tasks.splice(
                                group.tasks.findIndex(({ id }) => id === task.id),
                                1
                            );
                            movedTask.groupKey = overGroupKey;
                            overGroup.tasks.push(movedTask);
                        }
                    });
                });
            } else {
                // When dropping one task, insert the dropped task above or below the over task depending on the dragged
                // task position
                updatedGroups = setGroups((groups) => {
                    const activeGroup = groups.find(({ key }) => key === activeGroupKey);
                    const overGroup = groups.find(({ key }) => key === overGroupKey);
                    const overTaskIndex = over.data.current.sortable.index;
                    const [movedTask] = activeGroup.tasks.splice(
                        activeGroup.tasks.findIndex(({ id }) => id === draggedTasks[0].id),
                        1
                    );
                    movedTask.groupKey = overGroupKey;
                    overGroup.tasks.splice(overTaskIndex, 0, movedTask);
                });
            }

            // When grouping by sections inside a workspace, we need to update the task_tag.order, not the task.order
            if (workspaceId != null) {
                updatedGroups.forEach(({ tasks }) =>
                    tasks.forEach(({ id }, index) => {
                        changes[id] = { tag_order: index };
                    })
                );
            } else if (checklistId != null || templateId != null) {
                let order = 1;
                updatedGroups.forEach(({ tasks }) =>
                    tasks.forEach(({ id }) => {
                        changes[id] = { order: order++ };
                    })
                );
            }

            // If the order didn't change, don't update the task
            Object.entries(changes).forEach(([id, { order, tag_order }]) => {
                const task = tasks.find((task) => task.id === id);
                if ((order != null && task?.order === order) || (tag_order != null && task?.tag_order === tag_order)) {
                    delete changes[id];
                }
            });
        } else if (!draggedTasks.some((task) => task.groupKey !== overGroupKey)) {
            return;
        }

        // We compute the change to apply to all moved tasks, if the destination group is a workspace, we need to
        // compute the change differently for all moved tasks...
        const commonChange = (() => {
            switch (overGroupType) {
                case 'custom_field': {
                    const [id, optionId] = overGroupKey.split('-', 2);
                    return { enum_custom_field: { id, option_id: optionId ?? null } };
                }
                case 'planned_date':
                case 'default_date': {
                    return {
                        planned_date: getDateFromKey(overGroupKey),
                    };
                }
                case 'due_date':
                    return { due_date: getDateFromKey(overGroupKey) };
                case 'priority':
                    return { priority: parseInt(overGroupKey, 10) };
                case 'checklist_section':
                    return { checklist_section_id: overGroupKey || null };
                case 'workspace_section':
                    return { workspace_section_id: overGroupKey || null };
                case 'user':
                    return { assignee_id: overGroupKey || null };
                default:
                    return null;
            }
        })();

        draggedTasks.forEach((task) => {
            if (task.originalGroupKey === overGroupKey) {
                return;
            }
            if (changes[task.id] == null) {
                changes[task.id] = {};
            }
            if (commonChange != null) {
                // ...it's a common change, so we just add the task id to the change...
                Object.assign(changes[task.id], commonChange);
            } else if (overGroupType === 'workspace') {
                // ...the destination group is a workspace, so the change is computed individually for the moved
                // tasks
                let workspaces = [] as Id[];
                if (overGroupKey !== '') {
                    const workspacesSet = new Set<Id>(task.workspaces.map(({ id }) => id));
                    workspacesSet.add(overGroupKey);
                    workspacesSet.delete(task.groupKey);
                    workspaces = Array.from(workspacesSet);
                }
                Object.assign(changes[task.id], { workspaces });
            }
        });

        void updateTasks({ tasks: Object.entries(changes).map(([id, change]) => ({ ...change, id })), workspaceId });
    };

    const handleScroll = useDebouncedCallback(
        ({ target }: React.UIEvent<HTMLDivElement, UIEvent> | { target: HTMLDivElement }) => {
            if (
                target instanceof HTMLDivElement &&
                target.scrollHeight - target.offsetHeight - target.scrollTop < InfiniteScrollThreshold &&
                !isLoading &&
                layout === 'list'
            ) {
                fetchMore();
            }
        },
        150
    );

    const handleNavigation = (event: KeyboardEvent) => {
        if (event?.code !== 'ArrowUp' && event?.code !== 'ArrowDown') {
            return;
        }
        if (selectedTasks.length === 0) {
            return;
        }

        const firstSelectedTask = selectedTasks[0];
        const lastSelectedTask = selectedTasks[selectedTasks.length - 1];
        // If we're navigating between tasks of a subtask
        if (
            firstSelectedTask?.parent_task_id != null &&
            selectedTasks.every(({ parent_task_id }) => selectedTasks[0]?.parent_task_id === parent_task_id)
        ) {
            const parentTaskSubtasks =
                tasks.find(({ id }) => id === firstSelectedTask.parent_task_id)?.subtasks ??
                trpcUtils().task.listSubTasks.getData(firstSelectedTask.parent_task_id);
            if (event?.code === 'ArrowUp') {
                const firstSelectedTaskIndex = parentTaskSubtasks.findIndex(
                    (subtask) => subtask.id === firstSelectedTask.id
                );
                if (firstSelectedTaskIndex <= 0) {
                    setSelectedTasks([parentTaskSubtasks[parentTaskSubtasks.length - 1]]);
                } else {
                    setSelectedTasks([parentTaskSubtasks[firstSelectedTaskIndex - 1]]);
                }
            } else if (event?.code === 'ArrowDown') {
                const lastSelectedTaskIndex = parentTaskSubtasks.findIndex(
                    (subtask) => subtask.id === lastSelectedTask.id
                );
                if (lastSelectedTaskIndex >= parentTaskSubtasks.length - 1) {
                    setSelectedTasks([parentTaskSubtasks[0]]);
                } else {
                    setSelectedTasks([parentTaskSubtasks[lastSelectedTaskIndex + 1]]);
                }
            }
        } else {
            const taskIndex = orderedGroupedTasks.findIndex(
                ({ groupedId }) => groupedId === selectedTasks[selectedTasks.length - 1].groupedId
            );
            const acc = event?.code === 'ArrowUp' ? orderedGroupedTasks.length - 1 : 1;
            const newIndex = (taskIndex + acc) % orderedGroupedTasks.length;
            const nextTask = orderedGroupedTasks[newIndex];

            setSelectedTasks([nextTask]);
        }
    };

    const handleSelectGroup = (group: Group) => () => {
        const isInSelectedTasks = ({ groupedId }: GroupedTask) =>
            selectedTasks.some(({ groupedId: selectedGroupId }) => groupedId === selectedGroupId);

        const remove = group.tasks.every(isInSelectedTasks);
        setSelectedTasks(
            remove
                ? selectedTasks.filter(
                      ({ groupedId }) =>
                          !group.tasks.some(({ groupedId: groupGroupedId }) => groupedId === groupGroupedId)
                  )
                : [...selectedTasks, ...group.tasks.filter((task) => !isInSelectedTasks(task))]
        );
    };

    const handleSelectTask = (event: React.MouseEvent<HTMLDivElement>, task: GroupedTask) => {
        event?.stopPropagation();

        if (event?.shiftKey && selectedTasks.length > 0) {
            if (task.parent_task_id != null) {
                if (!selectedTasks.some(({ parent_task_id }) => parent_task_id !== task.parent_task_id)) {
                    // Sometimes the subtask will be present in the task list, but sometimes it will be present in the subtasks query only and both of these have different structures
                    const parentTaskSubtasks =
                        tasks.find(({ id }) => id === task.parent_task_id)?.subtasks ??
                        trpcUtils().task.listSubTasks.getData(task.parent_task_id);

                    const lastIndex = parentTaskSubtasks.findIndex(
                        ({ id }) => id === selectedTasks[selectedTasks.length - 1].id
                    );
                    const selectedIndex = parentTaskSubtasks.findIndex(({ id }) => id === task.id);
                    if (selectedIndex > lastIndex) {
                        setSelectedTasks([
                            ...selectedTasks,
                            ...parentTaskSubtasks
                                .slice(lastIndex + 1, selectedIndex + 1)
                                .filter(
                                    (subtask) => !selectedTasks.some((selectedTask) => selectedTask.id === subtask.id)
                                ),
                        ]);
                    } else {
                        setSelectedTasks([
                            ...parentTaskSubtasks
                                .slice(selectedIndex, lastIndex + 1)
                                .filter(
                                    (subtask) => !selectedTasks.some((selectedTask) => selectedTask.id === subtask.id)
                                ),
                            ...selectedTasks,
                        ]);
                    }
                } else {
                    setSelectedTasks([task]);
                }
            } else {
                if (selectedTasks.some(({ parent_task_id }) => parent_task_id != null)) {
                    setSelectedTasks([task]);
                } else {
                    // Select all tasks between the previous selected task and the one clicked
                    const startTask = findGroupedTask(selectedTasks[selectedTasks.length - 1].groupedId);

                    const startGroupIndex = groups.findIndex(({ key }) => key === startTask.groupKey);
                    const endGroupIndex = groups.findIndex(({ key }) => key === task.groupKey);

                    const startTaskIndex = groups[startGroupIndex].tasks.findIndex(({ id }) => id === startTask.id);
                    const endTaskIndex = groups[endGroupIndex].tasks.findIndex(({ id }) => id === task.id);

                    // Assure the start task is before end task in the current task order
                    let groupIndex: {
                            start: number;
                            end: number;
                        },
                        taskIndex: {
                            start: number;
                            end: number;
                        };
                    if (
                        startGroupIndex < endGroupIndex ||
                        (startGroupIndex === endGroupIndex && startTaskIndex < endTaskIndex)
                    ) {
                        groupIndex = { start: startGroupIndex, end: endGroupIndex };
                        taskIndex = { start: startTaskIndex, end: endTaskIndex };
                    } else {
                        groupIndex = { start: endGroupIndex, end: startGroupIndex };
                        taskIndex = { start: endTaskIndex, end: startTaskIndex };
                    }

                    const newSelectedTasks = [
                        ...selectedTasks,
                        ...groups.flatMap((group, gIndex) => {
                            return group.tasks.flatMap((task, tIndex) =>
                                gIndex < groupIndex.start ||
                                gIndex > groupIndex.end ||
                                (gIndex === groupIndex.start && tIndex < taskIndex.start) ||
                                (gIndex === groupIndex.end && tIndex > taskIndex.end) ||
                                selectedTasks.some(({ id }) => id === task.id)
                                    ? []
                                    : [task]
                            );
                        }),
                    ];
                    setSelectedTasks(newSelectedTasks);
                }
            }
        } else if (event?.ctrlKey || event?.metaKey || isReadOnly) {
            // Add current task in the selection
            setSelectedTasks(
                selectedTasks.some(({ id }) => id === task.id)
                    ? selectedTasks.filter(({ id }) => id !== task.id)
                    : [...selectedTasks, task]
            );
        } else {
            setSelectedTasks([task]);
        }
    };

    useEffect(() => {
        const deletedTasks = oldTasks.filter((oldTask) => tasks.every((task) => task.id !== oldTask.id));

        deletedTasks.forEach((deletedTask) => {
            taskElements.delete(deletedTask.id);
        });

        setOldTasks(tasks);

        if (recentlyCreatedTaskId === null) {
            return;
        }

        if (taskElements.has(recentlyCreatedTaskId)) {
            taskElements
                .get(recentlyCreatedTaskId)
                ?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            setRecentlyCreatedTaskId(null);
        } else if (remainingAutoScrolls > 0) {
            tasksListRef.current.scrollTo(0, tasksListRef.current.scrollHeight);
            setRemainingAutoScrolls(remainingAutoScrolls - 1);
            return;
        }

        setRemainingAutoScrolls(MaxAutoScrolls);
    }, [tasks]);

    return (
        <div className={clsx('flex flex-col gap-1 overflow-y-auto grow', wrapperClassName)}>
            <div
                ref={tasksListRef}
                data-selectable-container={true}
                data-selectable-container-horizontal={true}
                className={clsx(
                    'py-0.5 scrollbar-light mx-auto flex transform outline-0 shrink h-full',
                    layout === 'list' || tasks.length === 0 ? 'flex-col gap-1 overflow-y-auto' : 'overflow-y-hidden',
                    !isReadOnly ? '-ml-6 w-[calc(100%_+_1.5rem)] px-6' : 'w-full',
                    className
                )}
                onScroll={handleScroll}
                onKeyDown={handleNavigation}
            >
                {fetchTaskError != null ? (
                    <RetryComponent retryFunction={invalidateCachedTasks} className="!h-auto" />
                ) : isLoading ? (
                    <Skeleton count={5} className="ml-6 mr-1 h-8 !w-[calc(100%-28px)] !rounded-md" />
                ) : tasks.length === 0 && templateId == null ? (
                    <EmptyState icon={faSquareCheck} size="lg">
                        <EmptyState.Text className="ignore-marker">
                            <Trans>No tasks</Trans>
                        </EmptyState.Text>
                    </EmptyState>
                ) : (
                    <DndContext
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDragCancel={handleDragCancel}
                        onDragEnd={handleDragEnd}
                        collisionDetection={collisionDetection}
                        autoScroll={['section', '-section'].includes(grouping) || layout === 'list'}
                    >
                        <SortableContext items={groups.map(({ key }) => key)} strategy={verticalListSortingStrategy}>
                            {groups.map((group) => (
                                <SortableContext
                                    key={group.key}
                                    items={group.tasks.map(({ groupedId }) => groupedId)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    <TasksGroup
                                        addTaskElement={addTaskElement}
                                        group={group}
                                        scope={searchType}
                                        workspaceId={workspaceId}
                                        userId={userId}
                                        checklistId={checklistId}
                                        templateId={templateId}
                                        statuses={statuses}
                                        view={view}
                                        layout={layout}
                                        order={order}
                                        grouping={grouping}
                                        isFetching={isFetching}
                                        isReadOnly={isReadOnly}
                                        canLoadMore={canFetchMore}
                                        onSelectTask={handleSelectTask}
                                        onSelectGroup={isReadOnly ? handleSelectGroup(group) : undefined}
                                        keepTasksCollapsed={keepTasksCollapsed}
                                    />
                                </SortableContext>
                            ))}
                        </SortableContext>
                        <TaskDragOverlay
                            workspaceId={workspaceId}
                            checklistId={checklistId}
                            layout={layout}
                            view={view}
                        />
                    </DndContext>
                )}
            </div>
            {canFetchMore && layout === 'kanban' && (
                <div className="mb-2 flex justify-center shrink-0">
                    <Button onClick={fetchMore} icon={faArrowsRotate} disabled={isLoading}>
                        <Trans>Load more...</Trans>
                    </Button>
                </div>
            )}
        </div>
    );
};
