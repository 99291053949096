import React from 'react';
import { t } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Skeleton } from '@wedo/design-system';
import {
    getGovernanceActivityIcon,
    getGovernanceActivityIconColor,
    getGovernanceActivityText,
    isGovernanceActivityImportant,
} from 'Pages/governance/GovernanceActivityLogsValues';
import { ActivitiesTable } from 'Shared/components/activity/ActivitiesTable/ActivitiesTable';
import { useFetchGovernanceActivitiesQuery } from 'Shared/services/governance';

type GovernanceHistoryModalProps = ContextModalProps;

export const GovernanceHistoryModal = (modalProps: GovernanceHistoryModalProps) => {
    const { data: activities, isLoading: isLoadingActivities } = useFetchGovernanceActivitiesQuery();
    return (
        <Modal size={'lg'} {...modalProps}>
            <Modal.Header title={t`Governance history`} />
            <Modal.Body>
                {isLoadingActivities ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-2" />
                    </div>
                ) : (
                    <ActivitiesTable
                        activities={activities}
                        getIcon={getGovernanceActivityIcon}
                        getIconColor={getGovernanceActivityIconColor}
                        getText={(param) => getGovernanceActivityText(param.activity)}
                        isImportant={isGovernanceActivityImportant}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
