import { Text, View } from '@react-pdf/renderer';
import { JSX } from 'react';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faFile,
    faFileAlt,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faSquareEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import { colors } from '../colors';
import { IconName, Icon } from '../icon/Icon';
import { useMeetingPdfContext } from './MeetingPdf';
import { type MeetingBlock } from './types';
import { type Attachment as AttachmentType } from './types';

const isUrlFile = (attachment: Partial<AttachmentType>) => {
    return attachment?.currentVersion?.host === 'URL' || attachment?.host === 'URL';
};

const getAttachmentUrl = (attachment: Partial<AttachmentType>) => {
    return attachment?.currentVersion?.location?.url ?? attachment?.location?.url;
};

export const getUrlIcon = (link: string): IconName => {
    try {
        const url = new URL(link);
        switch (url.hostname) {
            case 'app.box.com':
                return 'boxCom';
            case 'www.cmicloud.ch':
                return 'cmiCloud';
            case 'www.dropbox.com':
                return 'dropbox';
            case 'www.evernote.com':
                return 'evernote';
            case 'www.figma.com':
                return 'figma';
            case 'drive.google.com':
            case 'docs.google.com':
            case 'forms.gle':
            case 'forms.google.com':
                return 'googleDrive';
            case 'kdrive.infomaniak.com':
                return 'kDrive';
            case 'www.sharepoint.com':
                return 'sharePoint';
            case '1drv.ms':
            case 'onedrive.com':
                return 'onedrive';
            case 'www.linkedin.com':
                return 'linkedIn';
            case 'm-files.com':
                return 'mFiles';
            case 'www.netflix.com':
                return 'netflix';
            case 'www.notion.so':
                return 'notion';
            case 'trello.com':
                return 'trello';
            case 'youtu.be':
            case 'www.youtube.com':
                return 'youtube';
            default: {
                if (url.hostname.includes('atlassian.net') && url.pathname.includes('jira')) {
                    return 'jira';
                }
                if (url.hostname.includes('atlassian.net')) {
                    return 'confluence';
                }
                if (url.hostname.includes('slack.com')) {
                    return 'slack';
                }
                return 'externalLink';
            }
        }
    } catch (ignored) {
        return 'externalLink';
    }
};

export const getIcon = (
    attachment: AttachmentType,
    render: (icon: IconDefinition | IconName, color?: string) => JSX.Element
) => {
    if (isUrlFile(attachment)) {
        const iconName = getUrlIcon(getAttachmentUrl(attachment));
        return render(iconName);
    }

    switch (
        attachment.currentVersion?.filename
            ?.substring(attachment.currentVersion?.filename?.lastIndexOf('.') + 1)
            .toLowerCase()
    ) {
        case 'pdf':
            return render(faFilePdf, colors.red['500']);
        case 'txt':
            return render(faFileAlt);
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'svg':
        case 'bmp':
        case 'gif':
            return render(faFileImage, colors.blue['800']);
        case 'avi':
        case 'mpeg':
        case 'mp4':
        case 'wmv':
            return render(faFileVideo);
        case 'mp3':
        case 'wav':
        case 'flac':
            return render(faFileAudio);
        case 'zip':
        case '7z':
        case 'rar':
        case 'tar':
        case 'gz':
        case 'gzip':
            return render(faFileArchive);
        case 'doc':
        case 'docx':
            return render(faFileWord, colors.blue['600']);
        case 'xls':
        case 'xlsx':
            return render(faFileExcel, colors.green['500']);
        case 'ppt':
        case 'pptx':
            return render(faFilePowerpoint, colors.orange['600']);
        case 'html':
        case 'cs':
        case 'js':
        case 'php':
            return render(faFileCode);
        case 'eml':
            return render(faSquareEnvelope);
        default:
            return render(faFile, colors.gray['400']);
    }
};

export const Attachment = ({ element }: { element: Extract<MeetingBlock, { type: 'attachment' }> }) => {
    const { fontSize, spacing, borderRadius, color } = useMeetingPdfContext();

    return (
        <View style={{ flexDirection: 'row', gap: spacing.small, flexWrap: 'wrap' }}>
            {element.attachments.map((attachment) => (
                <View
                    key={attachment.id}
                    wrap={false}
                    style={{
                        border: `1px solid ${color.borderColor}`,
                        borderRadius: borderRadius.normal,
                        paddingVertical: spacing.small,
                        paddingHorizontal: spacing.normal,
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: spacing.small,
                    }}
                >
                    {getIcon(attachment, (icon, color) => (
                        <Icon icon={icon} style={{ color, width: 10, height: 10 }} />
                    ))}
                    <Text style={{ fontSize: fontSize.small }}>{attachment.currentVersion.filename}</Text>
                </View>
            ))}
        </View>
    );
};
