import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Input, Label, Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { ApiError } from 'Shared/types/apiError';

type GeneralSettingsPanelProps = {
    checklistId: Id;
    name: string;
    onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    description: string;
    onDescriptionChange: (value: string) => void;
    error: ApiError;
};

export const GeneralSettingsPanel: FC<GeneralSettingsPanelProps> = ({
    name,
    onNameChange,
    description,
    onDescriptionChange,
    error,
}) => {
    return (
        <Tabs.Panel>
            <Label>
                <Trans>Checklist name</Trans>
            </Label>

            <Input
                value={name}
                onChange={onNameChange}
                status={error ? 'error' : undefined}
                statusText={error?.message}
            />

            <Label className="mt-6">
                <Trans>Description</Trans>
            </Label>

            <DescriptionEditor html={description} onChange={onDescriptionChange} />
        </Tabs.Panel>
    );
};
