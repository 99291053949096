import { FC, PropsWithChildren, useEffect, useReducer, useState } from 'react';
import { t } from '@lingui/macro';
import { ContextModalProps, getRandomWedoColor, Modal } from '@wedo/design-system';
import { addEditUserReducer, SET_WHOLE_USER } from 'Pages/settings/users/utils/addEditUserReducer';
import { PartialUser } from 'Pages/settings/users/utils/user';
import { AddUserStep } from 'Shared/components/user/AddEditUserModal/AddUserStep';
import { useGetCurrentUserQuery } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';
import { UserNetwork, UserRole, UserStatus } from 'Shared/types/user';
import { UserAccount } from 'Shared/types/user-account';
import { UserEmail } from 'Shared/types/user-email';
import { useRemainingGovernanceLicenses } from 'Shared/utils/chargebee';

const enum Step {
    User = 1,
    Team,
    Invite,
}

export const AddUserModal: FC<ContextModalProps> = ({
    defaultRole,
    defaultUserLicenses,
    children,
    ...modalProps
}: { defaultRole: UserRole; defaultUserLicenses: string[] } & ContextModalProps & PropsWithChildren) => {
    const { data: currentUser } = useGetCurrentUserQuery();
    const remainingGovernanceLicenses = useRemainingGovernanceLicenses();

    const defaultUser: PartialUser = {
        id: null,
        first_name: '',
        initials: '',
        language_code: currentUser?.language_code ?? 'fr-ch',
        last_name: '',
        role: defaultRole ?? UserRole.USER,
        userNetwork: {
            can_add_external: false,
            title: '',
            status: UserStatus.PENDING,
        } as UserNetwork,
        userEmail: {
            email_address: '',
        } as UserEmail,
        color: {
            background: getRandomWedoColor(),
            text: '#000',
        },
        userLicenses: defaultUserLicenses ?? [],
        userAccount: {
            saml: true,
        } as UserAccount,
    };

    const [step, setStep] = useState(Step.User);
    const [user, dispatch] = useReducer(addEditUserReducer, defaultUser);

    useEffect(() => {
        if (remainingGovernanceLicenses > 0) {
            dispatch({ type: 'governanceAddon', value: 'true' });
        }
    }, [remainingGovernanceLicenses]);

    const handleUserSuccess = async (user: PartialUser) => {
        await trpcUtils().user.list.invalidate();
        await trpcUtils().subscription.get.invalidate();
        dispatch({ type: SET_WHOLE_USER, value: user });
        setStep(Step.Team);
    };

    const handleTeamSuccess = async () => setStep(Step.Invite);

    const handleInviteSuccess = async () => {
        await trpcUtils().user.list.invalidate();
        void modalProps.close();
    };

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={t`Add user`} />
            <AddUserStep
                step={step}
                user={user}
                onUserSuccess={handleUserSuccess}
                onTeamSuccess={handleTeamSuccess}
                onInviteSuccess={handleInviteSuccess}
                onClose={() => modalProps.close()}
                dispatch={dispatch}
            />
            {children}
        </Modal>
    );
};
