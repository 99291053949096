import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { isNumber } from 'lodash-es';
import { Task } from 'Shared/types/task';
import { taskPriority } from 'Shared/utils/task';

type TaskPriorityIconProps = { priority: Task['priority']; className?: string; size?: 'xs' | 'sm' | 'md' | 'lg' };

const classes = {
    size: {
        xs: 'w-3 h-3',
        sm: 'w-4 h-4',
        md: 'w-5 h-5',
        lg: 'w-6 h-6',
    },
};

export const TaskPriorityIcon: FC<TaskPriorityIconProps> = ({ priority, className, size = 'md' }) => {
    return (
        <FontAwesomeIcon
            icon={isNumber(priority) ? taskPriority[priority ?? 0].icon : faSquare}
            className={clsx(classes.size[size], taskPriority[priority ?? 0].className, className)}
        />
    );
};
