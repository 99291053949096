import { ChangeEvent, FC, Ref, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Input, Label, Tabs } from '@wedo/design-system';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { TEMPLATE_NAME_MIN_LENGTH } from 'Shared/components/template/TemplateSettingsModal/utils';

type GeneralSettingsPanelProps = {
    name: string;
    onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
    nameRef: Ref<HTMLInputElement>;
    description: string;
    onDescriptionChange: (value: string) => void;
    onSave: () => void;
};

export const GeneralSettingsPanel: FC<GeneralSettingsPanelProps> = ({
    name,
    onNameChange,
    nameRef,
    description,
    onDescriptionChange,
    onSave,
}) => {
    const [isNameDirty, setIsNameDirty] = useState<boolean>(false);

    const isNameValid = !isEmpty(name?.trim()) && name?.trim()?.length >= TEMPLATE_NAME_MIN_LENGTH;
    const showNameError = !isNameValid && isNameDirty;

    return (
        <Tabs.Panel>
            <Label>
                <Trans>Name</Trans>
                <Input
                    ref={nameRef}
                    value={name}
                    onChange={onNameChange}
                    onBlur={() => setIsNameDirty(true)}
                    statusText={showNameError && t`Template name must be minimum 3 characters`}
                    status={showNameError ? 'error' : 'default'}
                    onPressEnter={isNameValid && onSave}
                />
            </Label>

            <Label className="mt-6">
                <Trans>Description</Trans>
            </Label>
            <DescriptionEditor html={description} onChange={onDescriptionChange} />
        </Tabs.Panel>
    );
};
