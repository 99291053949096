import { type Element } from 'slate';
import { collapseHtmlDocument } from './collapseHtmlDocument';
import { AttributeStackEntry, evaluateStack, StackEntry, StackNode } from './evaluateStack';
import { convertWordImages, convertWordLists } from './word';

const VoidElementTypes = ['attachment', 'task', 'vote'];

const ContainerTypes = ['block', 'link', 'list', 'listItem', 'paragraph'];

type FindContainerOptions = {
    isInline?: boolean;
};

const getAttributes = (type: string, attributesStack: Array<AttributeStackEntry>) => {
    return attributesStack
        .filter(({ node, applyOn }) => node.isOpen && applyOn === type)
        .reduce((attributes, { value }) => Object.assign(attributes, value), {});
};

const findContainer = (stack: Array<StackEntry<ChildNode>>, options?: FindContainerOptions) => {
    for (let index = stack.length - 1; index >= 0; index--) {
        const entry = stack[index];
        if (entry.isAcceptingInline) {
            if (options?.isInline && (entry.parentNode == null || entry.parentNode?.isOpen)) {
                return entry;
            }
            entry.isAcceptingInline = false;
        }
        if (entry.node?.isOpen && ContainerTypes.includes(entry.type)) {
            return entry;
        }
    }
    return null;
};

const processBlock = (
    node: StackNode<ChildNode>,
    stack: Array<StackEntry<ChildNode>>,
    attributesStack: Array<AttributeStackEntry>
) => {
    const container = findContainer(stack);
    const attributes = getAttributes('block', attributesStack);
    switch (container?.type) {
        case 'block':
            stack.push({ type: 'paragraph', level: container.level + 1, node });
            break;
        case 'list':
            break;
        case 'listItem':
            break;
        case 'paragraph':
            stack.push({ type: 'paragraph', level: container.level, node });
            break;
        default:
            stack.push({ type: 'block', level: 0, node, attributes });
    }
};

const processBreak = (node: StackNode<ChildNode>, stack: Array<StackEntry<ChildNode>>) => {
    const container = findContainer(stack, { isInline: true });
    switch (container?.type) {
        case 'block':
            stack.push({ type: 'paragraph', level: container.level + 1 });
            stack.push({ type: 'lineBreak', level: container.level + 2 });
            break;
        case 'list':
            break;
        case 'listItem':
            stack.push({ type: 'paragraph', level: container.level + 1 });
            stack.push({ type: 'lineBreak', level: container.level + 2 });
            break;
        case 'paragraph':
            stack.push({ type: 'lineBreak', level: container.level + 1 });
            break;
        default:
    }
};

const processImage = (node: StackNode<ChildNode>, stack: Array<StackEntry<ChildNode>>) => {
    const element = node as StackNode<HTMLElement>;
    const source = element.getAttribute('src') ?? '';
    if (source !== '') {
        const width = parseFloat(element.getAttribute('width'));
        const height = parseFloat(element.getAttribute('height'));
        const maxWidth = parseFloat(element.dataset.maxWidth);
        stack.push({
            type: 'image',
            level: 0,
            attributes: {
                source,
                id: element.dataset.wedoId,
                updatedAt: element.dataset.updatedAt,
                updatedBy: element.dataset.updatedBy,
                width: isNaN(width) ? undefined : width,
                height: isNaN(height) ? undefined : height,
                maxWidth: isNaN(maxWidth) ? undefined : maxWidth,
                align: element.dataset.align,
            },
        });
        const baseIndex = stack.length;
        for (let index = stack.length - 2; index >= 0; index--) {
            const entry = stack[index];
            if (entry.node?.isOpen && ContainerTypes.includes(entry.type)) {
                const entries = [entry];
                if (entry.level > 0) {
                    let referenceLevel = entry.level - 1;
                    for (let parentIndex = index - 1; parentIndex >= 0; parentIndex--) {
                        const parentEntry = stack[parentIndex];
                        if (parentEntry.level === referenceLevel) {
                            entries.unshift(parentEntry);
                            referenceLevel--;
                        }
                    }
                }
                stack.splice(baseIndex, 0, ...entries);
                break;
            }
        }
    }
};

const processLink = (node: StackNode<ChildNode>, stack: Array<StackEntry<ChildNode>>) => {
    // Link are considered text as they are inline elements
    const container = findContainer(stack, { isInline: true });
    const href = (node as StackNode<HTMLElement>).getAttribute('href')?.trim();
    switch (container?.type) {
        case 'block':
            stack.push({ type: 'paragraph', level: container.level + 1 });
            stack.push({ type: 'link', level: container.level + 2, node, attributes: { url: href } });
            break;
        case 'list':
            stack.push({ type: 'listItem', level: container.level + 1 });
            stack.push({ type: 'paragraph', level: container.level + 2 });
            stack.push({ type: 'link', level: container.level + 3, node, attributes: { url: href } });
            break;
        case 'listItem':
            stack.push({ type: 'paragraph', level: container.level + 1 });
            stack.push({ type: 'link', level: container.level + 2, node, attributes: { url: href } });
            break;
        case 'paragraph':
            stack.push({ type: 'link', level: container.level + 1, node, attributes: { url: href } });
            break;
        default:
            stack.push({ type: 'block', level: 0 });
            stack.push({ type: 'paragraph', level: 1 });
            stack.push({ type: 'link', level: 2, node, attributes: { url: href } });
    }
};

const processList = (node: StackNode<ChildNode>, stack: Array<StackEntry<ChildNode>>) => {
    const container = findContainer(stack);
    const attributes = { variant: node.nodeName === 'OL' ? 'numberedList' : 'bulletedList' };
    switch (container?.type) {
        case 'block':
            stack.push({ type: 'list', level: container.level + 1, node, attributes });
            break;
        case 'list':
            stack.push({ type: 'listItem', level: container.level + 1 });
            stack.push({ type: 'list', level: container.level + 2, node, attributes });
            break;
        case 'listItem': {
            const last = stack[stack.length - 1];
            if (last.node !== container.node) {
                stack.push({ type: 'listItem', level: container.level });
            }
            stack.push({ type: 'list', level: container.level + 1, node, attributes });
            break;
        }
        case 'paragraph':
            stack.push({ type: 'list', level: container.level, node });
            break;
        default:
            stack.push({ type: 'block', level: 0, node });
            stack.push({ type: 'list', level: 1, node, attributes });
    }
};

const processListItem = (node: StackNode<ChildNode>, stack: Array<StackEntry<ChildNode>>) => {
    const container = findContainer(stack);
    switch (container?.type) {
        case 'block':
            stack.push({ type: 'list', level: container.level + 1 });
            stack.push({ type: 'listItem', level: container.level + 2, node });
            break;
        case 'list':
            stack.push({ type: 'listItem', level: container.level + 1, node });
            break;
        case 'listItem':
            stack.push({ type: 'listItem', level: container.level });
            stack.push({ type: 'list', level: container.level + 1 });
            stack.push({ type: 'listItem', level: container.level + 2, node });
            break;
        case 'paragraph':
            stack.push({ type: 'list', level: container.level });
            stack.push({ type: 'listItem', level: container.level + 1, node });
            break;
        default:
            stack.push({ type: 'block', level: 0 });
            stack.push({ type: 'list', level: 1 });
            stack.push({ type: 'listItem', level: 2, node });
    }
};

const processParagraph = (
    node: StackNode<ChildNode>,
    stack: Array<StackEntry<ChildNode>>,
    attributesStack: Array<AttributeStackEntry>
) => {
    const container = findContainer(stack);
    const attributes = getAttributes('paragraph', attributesStack);
    switch (container?.type) {
        case 'block':
            stack.push({ type: 'paragraph', level: container.level + 1, node, attributes });
            break;
        case 'list':
            stack.push({ type: 'listItem', level: container.level + 1 });
            stack.push({ type: 'paragraph', level: container.level + 2, node, attributes });
            break;
        case 'listItem':
            stack.push({ type: 'paragraph', level: container.level + 1, node, attributes });
            break;
        case 'paragraph':
            stack.push({ type: 'paragraph', level: container.level, node, attributes });
            break;
        default:
            stack.push({ type: 'block', level: 0 });
            stack.push({ type: 'paragraph', level: 1, node, attributes });
    }
};

const processText = (
    node: StackNode<ChildNode>,
    stack: Array<StackEntry<ChildNode>>,
    attributesStack: Array<AttributeStackEntry>
) => {
    const container = findContainer(stack, { isInline: true });
    if (!VoidElementTypes.includes(container?.attributes?.variant)) {
        const value = node.textContent;
        const attributes = getAttributes('text', attributesStack);
        switch (container?.type) {
            case 'block':
                stack.push({
                    type: 'paragraph',
                    level: container.level + 1,
                    parentNode: container.node,
                    isAcceptingInline: true,
                });
                stack.push({ type: 'text', level: container.level + 2, value, attributes });
                break;
            case 'link':
                stack.push({ type: 'text', level: container.level + 1, value, attributes });
                break;
            case 'list':
                stack.push({ type: 'listItem', level: container.level + 1 });
                stack.push({
                    type: 'paragraph',
                    level: container.level + 2,
                    parentNode: container.node,
                    isAcceptingInline: true,
                });
                stack.push({ type: 'text', level: container.level + 3, value, attributes });
                break;
            case 'listItem':
                stack.push({
                    type: 'paragraph',
                    level: container.level + 1,
                    parentNode: container.node,
                    isAcceptingInline: true,
                });
                stack.push({ type: 'text', level: container.level + 2, value, attributes });
                break;
            case 'paragraph':
                stack.push({ type: 'text', level: container.level + 1, value, attributes });
                break;
            default:
                stack.push({ type: 'block', level: 0 });
                stack.push({ type: 'paragraph', level: 1, isAcceptingInline: true });
                stack.push({ type: 'text', level: 2, value, attributes });
        }
    }
};

const computeColor = (color: string) => {
    let colorPicker = document.getElementById('color-picker');
    if (colorPicker == null) {
        colorPicker = document.createElement('div');
        colorPicker.id = 'color-picker';
        Object.assign(colorPicker.style, { position: 'fixed', top: '0', left: '0' });
        document.body.appendChild(colorPicker);
        const shadow = colorPicker.attachShadow({ mode: 'open' });
        shadow.appendChild(document.createElement('div'));
    }
    (colorPicker.shadowRoot.firstElementChild as HTMLElement).style.color = color;
    return window.getComputedStyle(colorPicker.shadowRoot.firstElementChild).getPropertyValue('color');
};

const processStyle = (node: StackNode<ChildNode>, attributesStack: Array<AttributeStackEntry>) => {
    const element = node as StackNode<HTMLElement>;
    if (element.hasAttribute('style')) {
        if (element.style.color !== '' && !element.style.color.includes('var(')) {
            const color = computeColor(element.style.color);
            if (color !== 'rgb(0, 0, 0)' && color !== 'rgb(15, 23, 42)') {
                attributesStack.push({ node, applyOn: 'text', value: { color } });
            }
        }
        if (element.style.backgroundColor !== '' && !element.style.backgroundColor.includes('var(')) {
            const backgroundColor = computeColor(element.style.backgroundColor);
            if (backgroundColor !== 'rgb(255, 255, 255)') {
                attributesStack.push({ node, applyOn: 'text', value: { backgroundColor } });
            }
        }
        if (element.style.textAlign !== '') {
            attributesStack.push({ node, applyOn: 'paragraph', value: { align: element.style.textAlign } });
        }
    }
};

const processAttributes = (node: StackNode<ChildNode>, attributesStack: Array<AttributeStackEntry>) => {
    const element = node as StackNode<HTMLElement>;
    const id = element.dataset.wedoId;
    const updatedAt = element.dataset.updatedAt;
    const updatedBy = element.dataset.updatedBy;
    const type = element.dataset.type;
    if (type === 'decision') {
        element.removeAttribute('style');
        attributesStack.push({ node, applyOn: 'block', value: { variant: 'decision', id, updatedAt, updatedBy } });
    } else if (type === 'task') {
        const taskId = element.dataset.taskId;
        attributesStack.push({ node, applyOn: 'block', value: { variant: 'task', taskId, id, updatedAt, updatedBy } });
    } else if (type === 'vote') {
        const voteId = element.dataset.voteId;
        attributesStack.push({ node, applyOn: 'block', value: { variant: 'vote', voteId, id, updatedAt, updatedBy } });
    } else if (type === 'attachment') {
        const attachments = JSON.parse(element.dataset.attachments.replaceAll('&quot;', '"'));
        attributesStack.push({
            node,
            applyOn: 'block',
            value: { variant: 'attachment', attachments, id, updatedAt, updatedBy },
        });
    } else if (type === 'mention') {
        const userId = element.dataset.userId;
        const userFullName = element.textContent;
        attributesStack.push({ node, applyOn: 'text', value: { variant: 'mention', userId, userFullName } });
    } else if (id != null) {
        attributesStack.push({ node, applyOn: 'block', value: { id, updatedAt, updatedBy } });
    }
};

const processNode = (
    node: StackNode<ChildNode>,
    stack: Array<StackEntry<ChildNode>>,
    attributesStack: Array<AttributeStackEntry>
) => {
    if (node.nodeType === Node.TEXT_NODE) {
        processText(node, stack, attributesStack);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        processAttributes(node, attributesStack);
        processStyle(node, attributesStack);
        if (node.nodeName === 'IMG') {
            processImage(node, stack);
        } else if (node.nodeName === 'BR') {
            processBreak(node, stack);
        } else if (node.nodeName === 'LI') {
            processListItem(node, stack);
        } else if (['UL', 'OL'].includes(node.nodeName)) {
            processList(node, stack);
        } else if (['DIV', 'TABLE'].includes(node.nodeName)) {
            processBlock(node, stack, attributesStack);
        } else if (['P', 'TR', 'TH'].includes(node.nodeName)) {
            processParagraph(node, stack, attributesStack);
        } else if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(node.nodeName)) {
            const level = Number(node.nodeName[1]);
            attributesStack.push({ node, applyOn: 'paragraph', value: { variant: 'heading', level } });
            processParagraph(node, stack, attributesStack);
        } else if (node.nodeName === 'A') {
            const href = (node as StackNode<HTMLElement>).getAttribute('href')?.trim();
            if (href != null && href !== '') {
                processLink(node, stack);
            }
        } else if (['B', 'STRONG'].includes(node.nodeName)) {
            attributesStack.push({ node, applyOn: 'text', value: { bold: true } });
        } else if (['EM', 'I'].includes(node.nodeName)) {
            attributesStack.push({ node, applyOn: 'text', value: { italic: true } });
        } else if (node.nodeName === 'U') {
            attributesStack.push({ node, applyOn: 'text', value: { underlined: true } });
        } else if (node.nodeName === 'S') {
            attributesStack.push({ node, applyOn: 'text', value: { strikethrough: true } });
        }
    }
};

const walk = (
    node: StackNode<ChildNode>,
    stack: Array<StackEntry<ChildNode>>,
    attributesStack: Array<AttributeStackEntry>
) => {
    node.isOpen = true;
    processNode(node, stack, attributesStack);
    if (node.hasChildNodes()) {
        node.childNodes.forEach((childNode) => walk(childNode as StackNode<ChildNode>, stack, attributesStack));
    }
    node.isOpen = false;
};

export const deserializeHtml = (html: string, rtf?: string, collapseHtml?: boolean = true): Array<Element> => {
    const stack: Array<StackEntry<ChildNode>> = [];
    const attributesStack: Array<AttributeStackEntry> = [];
    const parsedDocument = new DOMParser().parseFromString(html, 'text/html');
    const document = collapseHtml ? collapseHtmlDocument(parsedDocument) : parsedDocument;
    if (rtf != null) {
        convertWordImages(document, html, rtf);
        convertWordLists(document, html);
    }
    walk(document.body as StackNode<HTMLElement>, stack, attributesStack);
    return evaluateStack(-1, stack);
};
