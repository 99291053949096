import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { DragOverlay } from '@dnd-kit/core';

type TaskDragOverlayProps = {
    items: unknown[];
    children: ReactNode;
};

export const CustomDragOverlay = ({ items, children }: TaskDragOverlayProps) =>
    createPortal(
        <DragOverlay dropAnimation={null}>
            {items.length > 0 ? (
                <div className="relative ml-5 before:absolute before:-left-6 before:h-full before:w-6 before:cursor-grabbing">
                    {items.length > 1 && (
                        <>
                            {items.length > 2 && (
                                <div className="absolute left-5 top-5 h-full w-[calc(100%-2.5rem)] border border-gray-300 bg-gray-100 shadow-md"></div>
                            )}
                            <div className="absolute left-2.5 top-2.5 h-full w-[calc(100%-1.25rem)] border border-gray-300 bg-gray-100 shadow-md"></div>
                            <div className="absolute -right-4 -top-4 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white shadow-md">
                                {items.length}
                            </div>
                        </>
                    )}
                    {children}
                </div>
            ) : null}
        </DragOverlay>,
        document.body
    );
