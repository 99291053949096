import { Editor, Path, Range, Transforms } from 'slate';
import { generateUUID, getImageDimension, not, tryOrValue } from '@wedo/utils';
import { resizeFile } from 'Shared/components/meeting/topicView/UploadImageModal/UploadImageModal';
import { isBlockEmpty, reorderBlocks } from '../../utils/block';
import { forceSave } from '../../utils/operation';
import { Decision } from '../decisionPlugin';
import { computeAdjustedDimension, createImageBlock } from '../imagePlugin';
import { isList } from '../listPlugin';
import { Paragraph } from '../paragraphPlugin';
import { HtmlContentType, RtfContentType } from './copyPastePlugin';
import { extractBlocks } from './extractBlocks';

const isExcelData = (data: DataTransfer) => {
    return (
        data.types?.some((type) => type === RtfContentType) &&
        data.types?.some(
            (type) =>
                type === HtmlContentType &&
                data.getData(HtmlContentType).includes('urn:schemas-microsoft-com:office:excel')
        )
    );
};

const isHtmlImage = (data: DataTransfer) => {
    return tryOrValue(() => {
        const body = new DOMParser().parseFromString(data.getData(HtmlContentType), 'text/html').body;
        return body.children.length === 1 && body.firstElementChild.nodeName === 'IMG';
    }, false);
};

const pasteImage = async (file: File, editor: Editor) => {
    const image = await resizeFile(file);
    if (image != null) {
        const { width, height, naturalWidth } = await getImageDimension(image);
        const { adjustedWidth, adjustedHeight } = computeAdjustedDimension(editor, width, height);
        const [selectedBlock, selectedPath] = Editor.above(editor, { mode: 'highest', match: not(Editor.isEditor) });
        Transforms.insertNodes(
            editor,
            [createImageBlock(image, 'center', adjustedWidth, adjustedHeight, naturalWidth)],
            { at: Path.next(editor.selection.anchor.path.slice(0, 1)), select: true }
        );
        if (selectedBlock.type === Paragraph && isBlockEmpty(selectedBlock)) {
            Transforms.removeNodes(editor, { mode: 'highest', at: selectedPath });
        }
        reorderBlocks(editor);
        forceSave(editor);
    }
};

export const pasteBlocks = async (editor: Editor, data: DataTransfer) => {
    const shouldSplit = !Editor.isEnd(editor, editor.selection.anchor, editor.selection.anchor.path.slice(0, 1));
    const [pastableBlocks, type] = extractBlocks(data);
    const currentIndex = editor.selection.anchor.path[0];
    const isSimplePaste = type === 'text' && pastableBlocks.length === 1;

    if (isSimplePaste) {
        Transforms.insertText(editor, pastableBlocks[0].children[0].children[0].text);
    } else if (shouldSplit) {
        Transforms.splitNodes(editor, { mode: 'highest', always: true });
        Transforms.setNodes(editor, { id: generateUUID() }, { mode: 'highest' });
        Transforms.insertNodes(editor, pastableBlocks, { mode: 'highest', select: true });
    } else {
        Transforms.insertNodes(editor, pastableBlocks, {
            mode: 'highest',
            at: Path.next(editor.selection.anchor.path.slice(0, 1)),
            select: true,
        });
    }

    // Merge the last pasted block if possible
    if (
        !isSimplePaste &&
        pastableBlocks?.length > 0 &&
        pastableBlocks[pastableBlocks.length - 1].type === Paragraph &&
        editor.children[currentIndex + pastableBlocks.length + 1]?.type === Paragraph &&
        shouldSplit
    ) {
        Transforms.mergeNodes(editor, {
            at: Editor.start(editor, [currentIndex + pastableBlocks.length + 1]),
            mode: 'lowest',
        });
    }

    // Merge the first pasted block if possible
    if (
        !isSimplePaste &&
        pastableBlocks?.length > 0 &&
        pastableBlocks[0].type === Paragraph &&
        editor.children[currentIndex].type === Paragraph
    ) {
        const isFirstPastableBlockAList = isList(pastableBlocks[0].children[0]);
        // TODO Remove text or list-item if empty and if pasting a list
        Transforms.mergeNodes(editor, {
            at: Editor.start(editor, [currentIndex + 1]),
            mode: isFirstPastableBlockAList ? 'highest' : 'lowest',
        });
    }

    reorderBlocks(editor);
};

const pasteBlocksContent = (editor: Editor, data: DataTransfer) => {
    let [pastableBlocks] = extractBlocks(data);
    pastableBlocks = pastableBlocks
        .filter((block) => [Decision, Paragraph].includes(block.type))
        .map((block) => block.children)
        .flat();
    Transforms.insertFragment(editor, pastableBlocks);
};

export const paste = async (editor: Editor, data: DataTransfer) => {
    const isImageData =
        data.files?.length === 1 &&
        data.files[0].type.startsWith('image/') &&
        (isExcelData(data) || !data.types?.some((type) => type.startsWith('text/')) || isHtmlImage(data));

    if (editor.selection == null) {
        Transforms.select(editor, Editor.end(editor, []));
    } else if (Range.isExpanded(editor.selection)) {
        Transforms.delete(editor);
    }

    const [selectedBlock] = Editor.above(editor, { mode: 'highest', match: not(Editor.isEditor) });

    if (isImageData) {
        await pasteImage(data.files[0], editor);
    } else if ([Decision].includes(selectedBlock.type)) {
        pasteBlocksContent(editor, data);
    } else {
        pasteBlocks(editor, data);
    }
};
