import { useEffect, useRef } from 'react';
import { appendScript } from '@wedo/utils';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';

export const useChargebee = () => {
    const chargebeeInstance = useRef<ReturnType<typeof Chargebee.getInstance>>();
    const { network } = useCurrentNetwork();

    const instantiateChargebee = () => {
        // Env vars MUST be prefixed with VITE_ otherwise they are not picked up by vite.
        // https://vite.dev/guide/env-and-mode
        Chargebee.init({
            site:
                network?.organisation?.type === 'demo'
                    ? import.meta.env.VITE_CHARGEBEE_DEMO_SITE
                    : import.meta.env.VITE_CHARGEBEE_SITE,
            publishableKey:
                network?.organisation?.type === 'demo'
                    ? import.meta.env.VITE_CHARGEBEE_DEMO_KEY
                    : import.meta.env.VITE_CHARGEBEE_KEY,
        });

        chargebeeInstance.current = Chargebee.getInstance();
    };

    useEffect(() => {
        if (network == null) {
            return;
        }
        try {
            instantiateChargebee();
        } catch (error) {
            if (error.message === 'Chargebee is not defined') {
                appendScript({
                    source: 'https://js.chargebee.com/v2/chargebee.js',
                    onLoad: instantiateChargebee,
                });
            }
        }
    }, [network]);

    return { chargebee: chargebeeInstance.current };
};
