import React, { useMemo, useState } from 'react';
import { faUser, faUserMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { HierarchyCircularNode } from 'd3';
import { Button, ContextModalProps, EmptyState, Input, Modal } from '@wedo/design-system';
import { useUsers } from 'App/store/usersStore';
import { CircleMemberProfileModalBody } from 'Pages/governance/CircleMember/CircleMemberProfileModal';
import { getUserAdministratedCircles, getUserGovernanceRoles } from 'Pages/governance/GovernanceHooks';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { Circle, Root } from 'Shared/types/governance';
import { User } from 'Shared/types/user';

type GovernanceDirectoryModalProps = {
    root: HierarchyCircularNode<Circle | Root>;
} & ContextModalProps;

export const GovernanceDirectoryModal = ({ root, ...modalProps }: GovernanceDirectoryModalProps) => {
    const users = useUsers();
    const [search, setSearch] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const filteredUsers = useMemo(() => {
        return users.filter((u) => u.full_name.toLowerCase().includes(search.toLowerCase()));
    }, [users, search]);

    const handleArrowKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let currentUserIndex;
        let userToSelect;
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            currentUserIndex = filteredUsers.findIndex((u) => u.id === selectedUser?.id);
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (currentUserIndex === 0) {
                userToSelect = filteredUsers[filteredUsers.length - 1];
            } else {
                userToSelect = filteredUsers[currentUserIndex - 1];
            }
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (currentUserIndex === filteredUsers.length - 1) {
                userToSelect = filteredUsers[0];
            } else {
                userToSelect = filteredUsers[currentUserIndex + 1];
            }
        }
        if (userToSelect) {
            setSelectedUser(userToSelect);
            document.querySelector(`#user-${userToSelect?.id}`)?.scrollIntoView({
                block: 'nearest',
                inline: 'start',
            });
        }
    };

    return (
        <Modal {...modalProps} size={'lg'} position="">
            <Modal.Header title={t`Governance user directory`} />
            <Modal.Body>
                <div className={'flex h-auto flex-wrap gap-3 md:h-96 md:flex-nowrap'}>
                    <div className={'flex h-56 flex-col md:h-full w-full md:w-1/2'}>
                        <Input
                            onKeyDown={handleArrowKeys}
                            aria-label={t`Search`}
                            placeholder={t`Search...`}
                            leadingIcon={faSearch}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className={'mb-2 flex-shrink-0'}
                        />
                        {filteredUsers.length > 0 ? (
                            <div className={'scrollbar-light flex h-full flex-col gap-2 overflow-y-auto'}>
                                {filteredUsers.map((user) => (
                                    <div
                                        role="option"
                                        tabIndex={-1}
                                        aria-selected={selectedUser?.id === user.id}
                                        id={`user-${user.id}`}
                                        onKeyDown={handleArrowKeys}
                                        onClick={() => {
                                            if (selectedUser?.id !== user.id) {
                                                setSelectedUser(user);
                                            }
                                        }}
                                        key={user.id}
                                        className={clsx(
                                            'flex h-min cursor-pointer items-center gap-2 rounded-md px-2',
                                            selectedUser?.id === user.id && 'bg-gray-200',
                                            getUserAdministratedCircles(root, user).length === 0 &&
                                                getUserGovernanceRoles(root, user).length === 0 &&
                                                'text-gray-400'
                                        )}
                                    >
                                        <UserAvatar
                                            user={user}
                                            className={clsx(
                                                getUserAdministratedCircles(root, user).length === 0 &&
                                                    getUserGovernanceRoles(root, user).length === 0 &&
                                                    'opacity-80'
                                            )}
                                        />
                                        <div className={'text-left'}>
                                            <div className={'text-base'}>{user.full_name}</div>
                                            <div
                                                className={clsx(
                                                    'text-sm',
                                                    getUserAdministratedCircles(root, user).length === 0 &&
                                                        getUserGovernanceRoles(root, user).length === 0
                                                        ? 'text-gray-400'
                                                        : 'text-gray-600'
                                                )}
                                            >
                                                {user.title}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <EmptyState icon={faUserMagnifyingGlass} size="md" className={'overflow-y-auto'}>
                                <EmptyState.Text>
                                    <Trans>No users found</Trans>
                                </EmptyState.Text>
                            </EmptyState>
                        )}
                    </div>
                    <div className={'flex h-48 w-full flex-grow flex-col md:h-full md:w-1/2'}>
                        {selectedUser ? (
                            <>
                                <div className={'shrink-0 mb-3 pl-2'}>
                                    <div className={'text-lg font-medium'}>{selectedUser.full_name}</div>
                                    <div className={'text-sm'}>{selectedUser.title}</div>
                                </div>
                                <div className={'scrollbar-light overflow-y-auto p-2 pr-4'}>
                                    <CircleMemberProfileModalBody
                                        user={selectedUser}
                                        root={root}
                                        onSelectNode={() => modalProps.close()}
                                    />
                                </div>
                            </>
                        ) : (
                            <EmptyState icon={faUser} size="md">
                                <EmptyState.Text>
                                    <Trans>No user selected</Trans>
                                </EmptyState.Text>
                            </EmptyState>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
