import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Skeleton, Table } from '@wedo/design-system';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { InvoiceTableRow } from './InvoiceTableRow';

type AllInvoicesModalProps = ContextModalProps;

export const AllInvoicesModal: React.FC<AllInvoicesModalProps> = ({ ...modalProps }) => {
    const { data: organisation } = useGetOrganizationQuery();

    const invoices = organisation?.invoices_list;

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`All invoices`} />

            <Modal.Body>
                {invoices === undefined && (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className={'h-10'} />
                    </div>
                )}
                {invoices !== undefined && invoices.length > 0 && (
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                                <Trans>ID</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Date</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Amount</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell className="flex justify-center">
                                <Trans>Status</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell className="w-10" />
                        </Table.Head>

                        <Table.Body>
                            {invoices.map((invoice) => (
                                <InvoiceTableRow key={invoice.id} invoice={invoice} />
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
