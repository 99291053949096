import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useState } from 'react';
import { faInputText } from '@fortawesome/pro-duotone-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Alert,
    Button,
    EmptyState,
    getIconFromName,
    Label,
    NoInternetErrorNotification,
    Switch,
    Table,
    Tabs,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { CustomFieldGroupTag } from 'Shared/components/customField/CustomFieldGroupTag';
import { CustomFieldTypeTag } from 'Shared/components/customField/CustomFieldTypeTag';
import {
    CustomFieldRelation,
    useActivateCustomFieldGroupMutation,
    useActivateCustomFieldMutation,
    useDeActivateCustomFieldGroupMutation,
    useDeActivateCustomFieldMutation,
    useGetCustomFieldGroupsQuery,
    useGetCustomFieldsQuery,
} from 'Shared/services/customFields';
import { ApiError } from 'Shared/types/apiError';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { isGroup } from 'Shared/utils/customFields';
import { isFetchError } from 'Shared/utils/rtkQuery';

type CustomFieldSectionProps = {
    closeModal: () => void;
    relation: CustomFieldRelation;
    title: string;
};

export const CustomFieldPanel: FC<CustomFieldSectionProps> = ({ closeModal, relation, title }) => {
    const navigate = useNavigate();
    const { show } = useNotification();

    const { data: customFields } = useGetCustomFieldsQuery({ archived: false });
    const { data: customFieldGroups } = useGetCustomFieldGroupsQuery({
        archived: false,
    });

    const [activateCustomField] = useActivateCustomFieldMutation();
    const [deActivateCustomField] = useDeActivateCustomFieldMutation();
    const [activateCustomFieldGroup] = useActivateCustomFieldGroupMutation();
    const [deActivateCustomFieldGroup] = useDeActivateCustomFieldGroupMutation();

    const [loading, setLoading] = useState<Id>(null);

    const resetLoading = () => {
        setTimeout(() => {
            setLoading(null);
        }, 500);
    };

    const totalFields = useMemo<number>(
        () => customFieldGroups?.length + customFields?.length,
        [customFields, customFieldGroups]
    );

    const globallyAvailableFields = useMemo<Array<CustomField | CustomFieldGroup>>(() => {
        const result: Array<CustomField | CustomFieldGroup> = [];
        if (!Array.isArray(customFields) || !Array.isArray(customFieldGroups)) {
            return result;
        }
        for (const field of customFields) {
            if (field.is_global) {
                result.push(field);
            }
        }
        for (const group of customFieldGroups) {
            if (group.is_global) {
                result.push(group);
            }
        }
        return result;
    }, [customFields, customFieldGroups]);

    const nonGlobalFields = useMemo<Array<CustomField | CustomFieldGroup>>(() => {
        const result: Array<CustomField | CustomFieldGroup> = [];
        if (!Array.isArray(customFields) || !Array.isArray(customFieldGroups)) {
            return result;
        }
        for (const field of customFields) {
            if (!field.is_global) {
                result.push(field);
            }
        }
        for (const group of customFieldGroups) {
            if (!group.is_global) {
                result.push(group);
            }
        }
        return result;
    }, [customFields, customFieldGroups]);

    const isFieldEnabled = (item: CustomField | CustomFieldGroup) => {
        return relation?.tag_id
            ? item?.tags.some((tag) => tag.id === relation.tag_id)
            : item?.checklists.some((checklist) => checklist.id === relation.checklist_id);
    };

    const toggleCustomFieldGroup = async (group: CustomFieldGroup) => {
        setLoading(group.id);
        if (isFieldEnabled(group)) {
            const response = await deActivateCustomFieldGroup({ customFieldGroupId: group.id, relation });
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else {
                    show(UnexpectedErrorNotification);
                }
            }
        } else {
            const response = await activateCustomFieldGroup({ customFieldGroupId: group.id, relation });
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else {
                    show(UnexpectedErrorNotification);
                }
            }
        }
        resetLoading();
    };

    const toggleCustomField = async (field: CustomField) => {
        setLoading(field.id);
        if (isFieldEnabled(field)) {
            const response = await deActivateCustomField({ customFieldId: field.id, relation: relation });
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else {
                    show(UnexpectedErrorNotification);
                }
            }
        } else {
            const response = await activateCustomField({ customFieldId: field.id, relation: relation });
            if ('error' in response) {
                const error = response.error as ApiError;
                if (isFetchError(error)) {
                    show(NoInternetErrorNotification);
                } else {
                    show(UnexpectedErrorNotification);
                }
            }
        }
        resetLoading();
    };

    const toggleCustomFieldItem = (item: CustomField | CustomFieldGroup): void => {
        if (isGroup(item)) {
            void toggleCustomFieldGroup(item);
        } else {
            void toggleCustomField(item);
        }
    };

    return (
        <Tabs.Panel>
            {totalFields === 0 && (
                <EmptyState
                    icon={faInputText}
                    onClick={() => {
                        void closeModal();
                        void navigate('/settings/custom-fields');
                    }}
                >
                    <EmptyState.Text>
                        <Trans>No custom fields</Trans>
                    </EmptyState.Text>
                </EmptyState>
            )}
            {totalFields > 0 && (
                <div>
                    <Label>{title}</Label>

                    {nonGlobalFields?.length === 0 && (
                        <p className="text-sm text-gray-600">
                            <Trans>No custom fields enabled on this workspace.</Trans>
                        </p>
                    )}

                    {nonGlobalFields?.length > 0 && (
                        <div className="mt-2">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell />
                                    <Table.HeadCell>
                                        <Trans>Name</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-52 text-left">
                                        <Trans>Type</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-10 text-center">
                                        <Trans>Enabled</Trans>
                                    </Table.HeadCell>
                                </Table.Head>

                                <Table.Body>
                                    {nonGlobalFields?.map((item) => {
                                        return (
                                            <Table.Row key={`${isGroup(item) ? 'group' : 'field'}-${item.id}`}>
                                                <Table.Cell>
                                                    <FontAwesomeIcon icon={getIconFromName(item.icon)} />
                                                </Table.Cell>
                                                <Table.Cell className={clsx(!isFieldEnabled(item) && 'text-gray-500')}>
                                                    {item.label}
                                                </Table.Cell>
                                                <Table.Cell
                                                    className={clsx('text-left', !isFieldEnabled(item) && 'opacity-60')}
                                                >
                                                    {isGroup(item) ? (
                                                        <CustomFieldGroupTag />
                                                    ) : (
                                                        <CustomFieldTypeTag fieldType={item.type} />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    <Switch
                                                        checked={isFieldEnabled(item)}
                                                        onChange={() => toggleCustomFieldItem(item)}
                                                        loading={loading === item.id}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    )}

                    <Label className="mt-6">
                        <Trans>Custom fields available globally</Trans>
                    </Label>

                    {globallyAvailableFields?.length > 0 && (
                        <div className="mt-2">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell />
                                    <Table.HeadCell>
                                        <Trans>Name</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-52 text-left">
                                        <Trans>Type</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-10 text-center">
                                        <Trans>Enabled</Trans>
                                    </Table.HeadCell>
                                </Table.Head>

                                <Table.Body>
                                    {globallyAvailableFields.map((item) => {
                                        return (
                                            <Table.Row key={`${isGroup(item) ? 'group' : 'field'}-${item.id}`}>
                                                <Table.Cell>
                                                    <FontAwesomeIcon icon={getIconFromName(item.icon)} />
                                                </Table.Cell>
                                                <Table.Cell>{item.label}</Table.Cell>
                                                <Table.Cell className="text-left">
                                                    {isGroup(item) ? (
                                                        <CustomFieldGroupTag />
                                                    ) : (
                                                        <CustomFieldTypeTag fieldType={item.type} />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    <Tooltip
                                                        content={t`Globally available fields are available on all workspaces and cannot be disabled`}
                                                    >
                                                        <Switch checked={true} disabled={true} />
                                                    </Tooltip>
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    )}

                    {globallyAvailableFields?.length === 0 && (
                        <p className="text-sm text-gray-600">
                            <Trans>There are no globally available fields in this network.</Trans>
                        </p>
                    )}
                </div>
            )}
            <Alert
                type={'info'}
                title={
                    <Trans>
                        Custom fields can be{' '}
                        <Button
                            variant="link"
                            color="primary"
                            href="/settings/custom-fields"
                            className="!text-base"
                            onClick={closeModal}
                        >
                            configured in the settings
                        </Button>
                        .
                    </Trans>
                }
                className="mt-4"
            />
        </Tabs.Panel>
    );
};
