import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Skeleton, getRandomWedoColor, getColorId } from '@wedo/design-system';

export const WorkspaceLine: FC<{ text: string }> = ({ text = '' }) => {
    const [color] = useState(getRandomWedoColor());

    return (
        <div className="mb-2 ml-4 flex items-center gap-2 pr-4 text-gray-200">
            <span
                className={clsx('h-3 w-3 rounded-full', `border-2 border-${String(getColorId(color))}-500`)}
                aria-hidden="true"
            />

            {text !== null && <div className="w-52 truncate">{text || '\u00A0'}</div>}
            {text === null && (
                <div className="grow">
                    <Skeleton className="mb-1 h-3 w-auto" />
                </div>
            )}
        </div>
    );
};
