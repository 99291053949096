import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { useStore } from 'zustand';
import { Id } from '@wedo/types';
import { tryOrNull } from '@wedo/utils';
import { ContextMenu } from 'Shared/components/editor/plugins/dndPlugin/ContextMenu';
import { DropIndicator } from 'Shared/components/editor/plugins/dndPlugin/DropIndicator';
import { Plugin } from '../../Editor';
import { createStore, type Store } from './store';

type DndWrapperProps = {
    store: Store;
    children: ReactNode;
};

const DndWrapper = ({ store, children }: DndWrapperProps) => {
    const editor = useSlateStatic();

    const activeBlockId = useStore(store, ({ activeBlockId }) => activeBlockId);

    const [target, setTarget] = useState();

    useEffect(() => {
        setTarget(
            tryOrNull(() =>
                ReactEditor.toDOMNode(
                    editor,
                    editor.children.find(({ id }) => id === activeBlockId)
                )
            )
        );
    }, [activeBlockId]);

    return (
        <>
            <DropIndicator store={store} />
            {children}
            {target != null &&
                createPortal(
                    <div
                        contentEditable={false}
                        className="absolute ring-2 ring-offset-1 ring-blue-500 inset-0 rounded-sm bg-white/50"
                    />,
                    target
                )}
        </>
    );
};

export const dndPlugin = ({ topicId, sectionId }: { topicId: Id; sectionId?: string }): Plugin => {
    const store = createStore();
    return {
        renderWrapper: (editor, children) => {
            return <DndWrapper store={store}>{children}</DndWrapper>;
        },
        renderElement: (editor, { element }, previousElement) => {
            return (
                element.id != null && (
                    <>
                        <ContextMenu store={store} element={element} topicId={topicId} sectionId={sectionId} />
                        {previousElement}
                        <DropIndicator store={store} blockId={element.id} />
                    </>
                )
            );
        },
    };
};
