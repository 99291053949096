import React, { FC, useState } from 'react';
import { t } from '@lingui/macro';
import { useTasksContext } from 'App/contexts/TasksContext';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { TemplateTaskRelativeDateSelect } from 'Shared/components/task/TaskDetail/shared/TemplateTaskRelativeDateSelect';

export const TemplateStartDateRow: FC<BulkEditTaskRowProps<number>> = ({ value, onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonRelativePlannedDate } = useBulkTasksEditPane(selectedTasks);

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(undefined);
        } else if (change === 'edit') {
            onChange(commonRelativePlannedDate ?? 1);
        } else if (change === 'clear') {
            onChange(null);
        }
    };

    return (
        <BulkEditRow
            label={t`Relative start date`}
            changeOption={changeValue}
            onChangeOptionChange={handleChange}
            isResetHidden
        >
            <TemplateTaskRelativeDateSelect offset={value} onOffsetChange={onChange} className="mt-2" />
        </BulkEditRow>
    );
};
