import { i18n, MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { CountryCode, CurrencyCode, SwissCantonCode } from '@wedo/types';

export const countryCodeMapping: Record<CountryCode, MessageDescriptor> = {
    AD: msg`Andorra`,
    AE: msg`United Arab Emirates`,
    AF: msg`Afghanistan`,
    AG: msg`Antigua and Barbuda`,
    AI: msg`Anguilla`,
    AL: msg`Albania`,
    AM: msg`Armenia`,
    AO: msg`Angola`,
    AQ: msg`Antarctica`,
    AR: msg`Argentina`,
    AS: msg`American Samoa`,
    AT: msg`Austria`,
    AU: msg`Australia`,
    AW: msg`Aruba`,
    AX: msg`Åland Islands`,
    AZ: msg`Azerbaijan`,
    BA: msg`Bosnia and Herzegovina`,
    BB: msg`Barbados`,
    BD: msg`Bangladesh`,
    BE: msg`Belgium`,
    BF: msg`Burkina Faso`,
    BG: msg`Bulgaria`,
    BH: msg`Bahrain`,
    BI: msg`Burundi`,
    BJ: msg`Benin`,
    BL: msg`Saint Barthélemy`,
    BM: msg`Bermuda`,
    BN: msg`Brunei`,
    BO: msg`Bolivia`,
    BQ: msg`Caribbean Netherlands`,
    BR: msg`Brazil`,
    BS: msg`Bahamas`,
    BT: msg`Bhutan`,
    BV: msg`Bouvet Island`,
    BW: msg`Botswana`,
    BY: msg`Belarus`,
    BZ: msg`Belize`,
    CA: msg`Canada`,
    CC: msg`Cocos Islands`,
    CD: msg`Democratic Republic of the Congo`,
    CF: msg`Central African Republic`,
    CG: msg`Republic of the Congo`,
    CH: msg`Switzerland`,
    CI: msg`Ivory Coast`,
    CK: msg`Cook Islands`,
    CL: msg`Chile`,
    CM: msg`Cameroon`,
    CN: msg`China`,
    CO: msg`Colombia`,
    CR: msg`Costa Rica`,
    CU: msg`Cuba`,
    CV: msg`Cabo Verde`,
    CW: msg`Curaçao`,
    CX: msg`Christmas Island`,
    CY: msg`Cyprus`,
    CZ: msg`Czechia`,
    DE: msg`Germany`,
    DJ: msg`Djibouti`,
    DK: msg`Denmark`,
    DM: msg`Dominica`,
    DO: msg`Dominican Republic`,
    DZ: msg`Algeria`,
    EC: msg`Ecuador`,
    EE: msg`Estonia`,
    EG: msg`Egypt`,
    EH: msg`Western Sahara`,
    ER: msg`Eritrea`,
    ES: msg`Spain`,
    ET: msg`Ethiopia`,
    FI: msg`Finland`,
    FJ: msg`Fiji`,
    FK: msg`Falkland Islands`,
    FM: msg`Micronesia`,
    FO: msg`Faroe Islands`,
    FR: msg`France`,
    GA: msg`Gabon`,
    GB: msg`United Kingdom`,
    GD: msg`Grenada`,
    GE: msg`Georgia`,
    GF: msg`French Guiana`,
    GG: msg`Guernsey`,
    GH: msg`Ghana`,
    GI: msg`Gibraltar`,
    GL: msg`Greenland`,
    GM: msg`Gambia`,
    GN: msg`Guinea`,
    GP: msg`Guadeloupe`,
    GQ: msg`Equatorial Guinea`,
    GR: msg`Greece`,
    GS: msg`South Georgia and the South Sandwich Islands`,
    GT: msg`Guatemala`,
    GU: msg`Guam`,
    GW: msg`Guinea-Bissau`,
    GY: msg`Guyana`,
    HK: msg`Hong Kong`,
    HM: msg`Heard Island and McDonald Islands`,
    HN: msg`Honduras`,
    HR: msg`Croatia`,
    HT: msg`Haiti`,
    HU: msg`Hungary`,
    ID: msg`Indonesia`,
    IE: msg`Ireland`,
    IL: msg`Israel`,
    IM: msg`Isle of Man`,
    IN: msg`India`,
    IO: msg`British Indian Ocean Territory`,
    IQ: msg`Iraq`,
    IR: msg`Iran`,
    IS: msg`Iceland`,
    IT: msg`Italy`,
    JE: msg`Jersey`,
    JM: msg`Jamaica`,
    JO: msg`Jordan`,
    JP: msg`Japan`,
    KE: msg`Kenya`,
    KG: msg`Kyrgyzstan`,
    KH: msg`Cambodia`,
    KI: msg`Kiribati`,
    KM: msg`Comoros`,
    KN: msg`Saint Kitts and Nevis`,
    KP: msg`North Korea`,
    KR: msg`South Korea`,
    KW: msg`Kuwait`,
    KY: msg`Cayman Islands`,
    KZ: msg`Kazakhstan`,
    LA: msg`Laos`,
    LB: msg`Lebanon`,
    LC: msg`Saint Lucia`,
    LI: msg`Liechtenstein`,
    LK: msg`Sri Lanka`,
    LR: msg`Liberia`,
    LS: msg`Lesotho`,
    LT: msg`Lithuania`,
    LU: msg`Luxembourg`,
    LV: msg`Latvia`,
    LY: msg`Libya`,
    MA: msg`Morocco`,
    MC: msg`Monaco`,
    MD: msg`Moldova`,
    ME: msg`Montenegro`,
    MF: msg`Saint Martin`,
    MG: msg`Madagascar`,
    MH: msg`Marshall Islands`,
    MK: msg`North Macedonia`,
    ML: msg`Mali`,
    MM: msg`Myanmar`,
    MN: msg`Mongolia`,
    MO: msg`Macao`,
    MP: msg`Northern Mariana Islands`,
    MQ: msg`Martinique`,
    MR: msg`Mauritania`,
    MS: msg`Montserrat`,
    MT: msg`Malta`,
    MU: msg`Mauritius`,
    MV: msg`Maldives`,
    MW: msg`Malawi`,
    MX: msg`Mexico`,
    MY: msg`Malaysia`,
    MZ: msg`Mozambique`,
    NA: msg`Namibia`,
    NC: msg`New Caledonia`,
    NE: msg`Niger`,
    NF: msg`Norfolk Island`,
    NG: msg`Nigeria`,
    NI: msg`Nicaragua`,
    NL: msg`Netherlands`,
    NO: msg`Norway`,
    NP: msg`Nepal`,
    NR: msg`Nauru`,
    NU: msg`Niue`,
    NZ: msg`New Zealand`,
    OM: msg`Oman`,
    PA: msg`Panama`,
    PE: msg`Peru`,
    PF: msg`French Polynesia`,
    PG: msg`Papua New Guinea`,
    PH: msg`Philippines`,
    PK: msg`Pakistan`,
    PL: msg`Poland`,
    PM: msg`Saint Pierre and Miquelon`,
    PN: msg`Pitcairn`,
    PR: msg`Puerto Rico`,
    PS: msg`Palestine`,
    PT: msg`Portugal`,
    PW: msg`Palau`,
    PY: msg`Paraguay`,
    QA: msg`Qatar`,
    RE: msg`Réunion`,
    RO: msg`Romania`,
    RS: msg`Serbia`,
    RU: msg`Russia`,
    RW: msg`Rwanda`,
    SA: msg`Saudi Arabia`,
    SB: msg`Solomon Islands`,
    SC: msg`Seychelles`,
    SD: msg`Sudan`,
    SE: msg`Sweden`,
    SG: msg`Singapore`,
    SH: msg`Saint Helena, Ascension and Tristan da Cunha`,
    SI: msg`Slovenia`,
    SJ: msg`Svalbard and Jan Mayen`,
    SK: msg`Slovakia`,
    SL: msg`Sierra Leone`,
    SM: msg`San Marino`,
    SN: msg`Senegal`,
    SO: msg`Somalia`,
    SR: msg`Suriname`,
    SS: msg`South Sudan`,
    ST: msg`Sao Tome and Principe`,
    SV: msg`El Salvador`,
    SX: msg`Sint Maarten`,
    SY: msg`Syria`,
    SZ: msg`Eswatini`,
    TC: msg`Turks and Caicos Islands`,
    TD: msg`Chad`,
    TF: msg`French Southern Territories`,
    TG: msg`Togo`,
    TH: msg`Thailand`,
    TJ: msg`Tajikistan`,
    TK: msg`Tokelau`,
    TL: msg`Timor-Leste`,
    TM: msg`Turkmenistan`,
    TN: msg`Tunisia`,
    TO: msg`Tonga`,
    TR: msg`Turkey`,
    TT: msg`Trinidad and Tobago`,
    TV: msg`Tuvalu`,
    TW: msg`Taiwan`,
    TZ: msg`Tanzania`,
    UA: msg`Ukraine`,
    UG: msg`Uganda`,
    UM: msg`United States Minor Outlying Islands`,
    US: msg`United States of America`,
    UY: msg`Uruguay`,
    UZ: msg`Uzbekistan`,
    VA: msg`Holy See`,
    VC: msg`Saint Vincent and the Grenadines`,
    VE: msg`Venezuela`,
    VG: msg`Virgin Islands (UK)`,
    VI: msg`Virgin Islands (US)`,
    VN: msg`Vietnam`,
    VU: msg`Vanuatu`,
    WF: msg`Wallis and Futuna`,
    WS: msg`Samoa`,
    YE: msg`Yemen`,
    YT: msg`Mayotte`,
    ZA: msg`South Africa`,
    ZM: msg`Zambia`,
    ZW: msg`Zimbabwe`,
};

export const getCountryName = (countryCode: CountryCode): string => {
    return i18n._(countryCodeMapping[countryCode]);
};

export const SwissCantons = new Map<SwissCantonCode, { name: MessageDescriptor; code: SwissCantonCode }>([
    ['AG', { name: msg`Aargau`, code: 'AG' }],
    ['AI', { name: msg`Appenzell Inner-Rhodes`, code: 'AI' }],
    ['AR', { name: msg`Appenzell Outer-Rhodes`, code: 'AR' }],
    ['BE', { name: msg`Bern`, code: 'BE' }],
    ['BL', { name: msg`Basel-Country`, code: 'BL' }],
    ['BS', { name: msg`Basel-City`, code: 'BS' }],
    ['FR', { name: msg`Fribourg`, code: 'FR' }],
    ['GE', { name: msg`Geneva`, code: 'GE' }],
    ['GL', { name: msg`Glarus`, code: 'GL' }],
    ['GR', { name: msg`Grisons`, code: 'GR' }],
    ['JU', { name: msg`Jura`, code: 'JU' }],
    ['LU', { name: msg`Lucerne`, code: 'LU' }],
    ['NE', { name: msg`Neuchâtel`, code: 'NE' }],
    ['NW', { name: msg`Nidwalden`, code: 'NW' }],
    ['OW', { name: msg`Obwalden`, code: 'OW' }],
    ['SG', { name: msg`St. Gallen`, code: 'SG' }],
    ['SH', { name: msg`Schaffhausen`, code: 'SH' }],
    ['SO', { name: msg`Solothurn`, code: 'SO' }],
    ['SZ', { name: msg`Schwyz`, code: 'SZ' }],
    ['TG', { name: msg`Thurgau`, code: 'TG' }],
    ['TI', { name: msg`Ticino`, code: 'TI' }],
    ['UR', { name: msg`Uri`, code: 'UR' }],
    ['VD', { name: msg`Vaud`, code: 'VD' }],
    ['VS', { name: msg`Valais`, code: 'VS' }],
    ['ZG', { name: msg`Zug`, code: 'ZG' }],
    ['ZH', { name: msg`Zurich`, code: 'ZH' }],
]);

export const getSwissCantonName = (swissCantonCode: SwissCantonCode): string => {
    const cantonName = SwissCantons.get(swissCantonCode)?.name;
    if (cantonName) {
        return i18n._(cantonName);
    }
    return null;
};
export const CountriesWithSwissFranc = new Set([CountryCode.Switzerland, CountryCode.Liechtenstein]);

export const CountriesWithPreferredEuro = new Set([
    CountryCode.Aruba,
    CountryCode.BonaireSintEustatiusSaba,
    CountryCode.Curaçao,
    CountryCode.Cyprus,
    CountryCode.FrenchGuiana,
    CountryCode.FrenchPolynesia,
    CountryCode.FrenchSouthernTerritories,
    CountryCode.Greenland,
    CountryCode.Martinique,
    CountryCode.Mayotte,
    CountryCode.NewCaledonia,
    CountryCode.Reunion,
    CountryCode.SaintBarthelemy,
    CountryCode.SaintMartin,
    CountryCode.SaintPierreAndMiquelon,
    CountryCode.SintMaarten,
    CountryCode.WallisAndFutuna,
    CountryCode.Andorra,
    CountryCode.Austria,
    CountryCode.Belarus,
    CountryCode.Belgium,
    CountryCode.BosniaAndHerzegovina,
    CountryCode.Bulgaria,
    CountryCode.Croatia,
    CountryCode.CzechRepublic,
    CountryCode.Denmark,
    CountryCode.Estonia,
    CountryCode.Finland,
    CountryCode.France,
    CountryCode.Germany,
    CountryCode.Greece,
    CountryCode.Guadeloupe,
    CountryCode.HolySeeVaticanCityState,
    CountryCode.Hungary,
    CountryCode.Iceland,
    CountryCode.Ireland,
    CountryCode.Italy,
    CountryCode.Latvia,
    CountryCode.Lithuania,
    CountryCode.Luxembourg,
    CountryCode.Macedonia,
    CountryCode.Malta,
    CountryCode.Moldova,
    CountryCode.Monaco,
    CountryCode.Montenegro,
    CountryCode.Netherlands,
    CountryCode.Norway,
    CountryCode.Poland,
    CountryCode.Portugal,
    CountryCode.Romania,
    CountryCode.SanMarino,
    CountryCode.Serbia,
    CountryCode.Slovakia,
    CountryCode.Slovenia,
    CountryCode.Spain,
    CountryCode.Sweden,
    CountryCode.Ukraine,
    CountryCode.UnitedKingdom,
]);

export const CountriesThatRequireVatNumber = new Set([
    CountryCode.Austria,
    CountryCode.Belgium,
    CountryCode.Bulgaria,
    CountryCode.Croatia,
    CountryCode.Cyprus,
    CountryCode.CzechRepublic,
    CountryCode.Denmark,
    CountryCode.Estonia,
    CountryCode.Finland,
    CountryCode.France,
    CountryCode.Germany,
    CountryCode.Greece,
    CountryCode.Hungary,
    CountryCode.Ireland,
    CountryCode.Italy,
    CountryCode.Latvia,
    CountryCode.Lithuania,
    CountryCode.Luxembourg,
    CountryCode.Malta,
    CountryCode.Netherlands,
    CountryCode.Poland,
    CountryCode.Portugal,
    CountryCode.Romania,
    CountryCode.Slovakia,
    CountryCode.Slovenia,
    CountryCode.Spain,
    CountryCode.Sweden,
]);

export const canSwitchCurrency = (country: CountryCode): boolean => {
    return country != null && !CountriesWithSwissFranc.has(country) && !CountriesThatRequireVatNumber.has(country);
};

export const getPreferredCurrency = (country: CountryCode): CurrencyCode => {
    if (CountriesWithSwissFranc.has(country)) {
        return 'CHF';
    }
    if (CountriesWithPreferredEuro.has(country)) {
        return 'EUR';
    }
    return 'USD';
};
