import { useEffect } from 'react';
import { emit, useWebSocketStore } from 'Shared/services/webSocket';

export const useWebSocketRoom = (room: string, payload: unknown) => {
    useEffect(() => {
        useWebSocketStore.setState(({ rooms }) => {
            rooms.set(room, payload);
            return { rooms: new Map(rooms) };
        });
        void emit(`${room}/join`, payload);
        return () => {
            useWebSocketStore.setState(({ rooms }) => {
                rooms.delete(room);
                return { rooms: new Map(rooms) };
            });
            void emit(`${room}/leave`, payload);
        };
    }, [payload]);
};
