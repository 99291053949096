import { FC, ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { Label, Select, Tabs } from '@wedo/design-system';
import { Workspace } from 'Shared/types/workspace';

type TaskSettingsPanelProps = {
    groupBy: Workspace['default_group_by'];
    onGroupByChange: (value: Workspace['default_group_by']) => void;
    view: Workspace['default_view'];
    onViewChange: (value: Workspace['default_view']) => void;
};

// TODO: make it possible to have inverse sort order
const groupByValues: Array<{ id: Workspace['default_group_by']; label: ReactNode }> = [
    { id: 'default', label: <Trans>Default</Trans> },
    { id: 'none', label: <Trans>None</Trans> },
    { id: 'planned_date', label: <Trans>By start date</Trans> },
    { id: 'due_date', label: <Trans>By due date</Trans> },
    { id: 'tag', label: <Trans>By workspace</Trans> },
    { id: 'priority', label: <Trans>By priority</Trans> },
    { id: 'section', label: <Trans>By section</Trans> },
    { id: 'user', label: <Trans>By user</Trans> },
    { id: 'alphanumeric', label: <Trans>By name</Trans> },
];

const views: Array<{ id: Workspace['default_view']; label: ReactNode }> = [
    { id: 'list', label: <Trans>List</Trans> },
    { id: 'kanban', label: <Trans>Kanban</Trans> },
];

export const TaskSettingsPanel: FC<TaskSettingsPanelProps> = ({ groupBy, onGroupByChange, view, onViewChange }) => {
    return (
        <Tabs.Panel>
            <span className="text-base font-semibold">
                <Trans>Default view</Trans>
            </span>

            <div className="grid-cols-1 grid gap-6 md:grid-cols-2">
                <div>
                    <Label>
                        <Trans>Group by</Trans>
                    </Label>

                    <Select
                        value={groupBy}
                        onChange={onGroupByChange}
                        customRenderSelected={(value: string) => {
                            const groupBy = groupByValues.find((group) => group.id === value);
                            return groupBy?.label;
                        }}
                    >
                        {groupByValues.map((groupBy) => (
                            <Select.Option key={groupBy.id} value={groupBy.id}>
                                {groupBy.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div>
                    <Label>
                        <Trans>Layout</Trans>
                    </Label>

                    <Select
                        value={view}
                        onChange={onViewChange}
                        customRenderSelected={(value: string) => {
                            const view = views.find((view) => view.id === value);
                            return view.label;
                        }}
                    >
                        {views.map((view) => (
                            <Select.Option key={view.id} value={view.id}>
                                {view.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        </Tabs.Panel>
    );
};
