import React, { FC } from 'react';
import clsx from 'clsx';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { CustomFilterGroup } from 'Pages/TasksPage/components/CustomTaskFilterModal/CustomFilterGroup';
import { IncludeAllItemsFilterLabel } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/IncludeAllItemsFilterLabel';
import { IncludeSomeItemsFilterLabel } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/IncludeSomeItemsFilterLabel';
import { Filter, GroupCondition } from 'Shared/types/filter';

type TasksFilterBarProps = {
    filter?: Filter;
    workspaceId?: Id;
    setFilter?: React.Dispatch<React.SetStateAction<Filter>>;
    containerRef: React.RefObject<HTMLDivElement>;
};

export const TasksFilterBar: FC<TasksFilterBarProps> = ({ filter, setFilter, containerRef }) => {
    const { width: containerWidth } = useElementSize(containerRef);

    const andConditions = filter.conditions?.conditions[0] as GroupCondition;
    const orConditions = filter.conditions?.conditions[1] as GroupCondition;

    return (
        <div className="flex flex-col gap-4">
            <div
                className={clsx(
                    'flex gap-1',
                    containerWidth >= getBreakpointValue('md')
                        ? 'items-start justify-between'
                        : 'flex-col-reverse gap-2'
                )}
            >
                <CustomFilterGroup condition={andConditions} setFilter={setFilter} />
                <IncludeAllItemsFilterLabel />
            </div>

            <div
                className={clsx(
                    'flex gap-1',
                    containerWidth >= getBreakpointValue('md')
                        ? 'items-start justify-between'
                        : 'flex-col-reverse gap-2'
                )}
            >
                <CustomFilterGroup condition={orConditions} setFilter={setFilter} />
                <IncludeSomeItemsFilterLabel />
            </div>
        </div>
    );
};
