import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export const usePhoneNumber = ({ input, countryCode }: { input: string; countryCode?: CountryCode }) => {
    if (input == null || input === '') return {};
    const asYouType = new AsYouType(countryCode);
    const formattedInput = asYouType.input(input);
    // If no countryCode is provided as an input the library can find it from the +XX prefix
    const country = asYouType.getCountry();
    try {
        const phoneNumber = parsePhoneNumber(input, countryCode);
        const isValid = phoneNumber.isValid();
        const internationalInput = phoneNumber.number;
        return { formattedInput, internationalInput, isValid, country };
    } catch (e) {
        return { formattedInput: input, internationalInput: input, isValid: false, country };
    }
};
