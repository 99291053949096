import React, { useCallback, useMemo } from 'react';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { v4 as uuidv4 } from 'uuid';
import { Button, Divider, ManagedTable } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { numericCompare } from '@wedo/utils';
import { useActiveUsers } from 'App/store/usersStore';
import { useMeetingAccessStore } from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessStore';
import { RoleSelector } from 'Pages/meeting/components/EditMeetingAccessModal/RoleSelector';
import { DEFAULT_MEETING_ROLES } from 'Pages/meeting/components/RBAC/DefaultMeetingRoles';
import { SectionNumber } from 'Pages/meeting/components/TableOfContents/TocSection';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { trpc } from 'Shared/trpc';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingUserItem } from 'Shared/types/meetingUserItem';
import { User } from 'Shared/types/user';
import { can, Permission } from 'Shared/utils/rbac';

export const ManageMeetingUserItems = ({
    userId,
    onChanged,
}: {
    userId: Id;
    onChanged?: (hasChanged: boolean) => void;
}) => {
    const users = useActiveUsers();

    const {
        meetingUsers,
        meetingUserItems,
        meetingRoles,
        updateMeetingUser,
        updateMeetingUserItem,
        setMeetingUserItems,
        setHasChanged: setHasChangedStore,
    } = useMeetingAccessStore();
    const meetingUser = useMemo(() => meetingUsers.find(({ user_id }) => user_id === userId), [userId, meetingUsers]);
    const { meeting } = useMeeting(meetingUser?.meeting_id);

    const setHasChanged = (hasChanged: boolean) => {
        setHasChangedStore(hasChanged);
        onChanged?.(hasChanged);
    };

    const { data: meetingSections } = trpc.meetingSection.listByMeetingId.useQuery(meeting?.id, {
        enabled: meeting?.id != null,
        select: (data: MeetingSection[]) =>
            [...camelToSnake(data)].sort((a, b) => numericCompare(a.display_id, b.display_id)),
    });

    /* get all active users except
        - current meeting user
        - if external, avoid meeting user with Editor role
        This list will be used by the UserPicker to restrict the list to the meeting users
     */
    const otherUsers = useMemo(
        () =>
            users.filter(
                ({ id }) => !meetingUsers.some(({ user_id }) => id === user_id && meetingUser?.user_id !== user_id)
            ),
        [users, meetingUser]
    );
    const roleEditor = useMemo(
        () => meetingRoles?.find(({ code }) => code === DEFAULT_MEETING_ROLES.EDITOR),
        [meetingRoles]
    );
    const realRoles = useMemo(
        () => meetingRoles.filter(({ code }) => DEFAULT_MEETING_ROLES.CUSTOM !== code),
        [meetingRoles]
    );

    const savedMeetingUserItems = useMemo(() => meeting?.meetingUsers?.map((user) => user.items).flat(), [meeting]);

    const handleAddMeetingUserItem = (meetingUserItem: Partial<MeetingUserItem>) => {
        setHasChanged(true);

        const existingItem = savedMeetingUserItems.find(
            (item) =>
                item.meeting_user_id === meetingUserItem.meeting_user_id &&
                item.meeting_section_id === meetingUserItem.meeting_section_id
        );
        setMeetingUserItems([
            ...meetingUserItems,
            existingItem
                ? { ...existingItem, meeting_role_id: meetingUserItem.meeting_role_id, changed: true }
                : { ...meetingUserItem, id: uuidv4(), added: true },
        ]);
    };
    const handleClearMeetingUserItems = () => {
        setHasChanged(true);
        setMeetingUserItems(meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id !== meetingUser?.id));
    };
    const handleUpdateMeetingUserRole = (meetingRoleId: Id) =>
        updateMeetingUser(meetingUser, {
            attribute: 'meeting_role_id',
            value: meetingRoleId,
        });
    const handleUpdateRole = useCallback(
        (section: Partial<MeetingSection>, roleId: Id) => {
            setHasChanged(true);
            const item = meetingUserItems.find(
                (i) =>
                    i.meeting_section_id != null &&
                    i.meeting_section_id === section.id &&
                    i.meeting_user_id === meetingUser?.id
            );

            if (item) {
                updateMeetingUserItem(item, {
                    attribute: 'meeting_role_id',
                    value: roleId,
                });
            } else {
                handleAddMeetingUserItem({
                    meeting_user_id: meetingUser?.id,
                    meeting_section_id: section.id,
                    meeting_role_id: roleId,
                });
            }
        },
        [meetingUserItems, meetingUser?.id]
    );

    const handleDefaultRole = (meetingRoleId: Id) => {
        setHasChanged(true);
        handleUpdateMeetingUserRole(meetingRoleId);

        if (meetingRoleId === roleEditor.id) {
            handleClearMeetingUserItems();
            return;
        }

        setMeetingUserItems([
            ...meetingUserItems,
            ...meetingSections
                .filter(
                    (meetingSection: MeetingSection) =>
                        !meetingUserItems.some(
                            (item) =>
                                item.meeting_user_id === meetingUser.id && item.meeting_section_id === meetingSection.id
                        )
                )
                .map((meetingSection: MeetingSection) => ({
                    meeting_user_id: meetingUser?.id,
                    meeting_section_id: meetingSection.id,
                    meeting_role_id: meetingUser?.meeting_role_id,
                    id: uuidv4(),
                    added: true,
                })),
        ]);
    };

    const handleImportFrom = (user: User) => {
        if (!user) {
            return;
        }
        const currentUser = users.find(({ id }) => id === meetingUser?.user_id);

        setHasChanged(true);

        // Copy default role
        const importedMeetingUser = meetingUsers.find(({ user_id }) => user_id === user.id);

        // do not copy editor default role if the user is external or light
        if (roleEditor.id !== importedMeetingUser.meeting_role_id || can(currentUser?.role, Permission.ManageMeeting)) {
            handleUpdateMeetingUserRole(importedMeetingUser.meeting_role_id);
        }

        // Copy meeting user items
        const importedMeetingUserItems = meetingUserItems.filter((meetingUserItem) => {
            if (meetingUserItem.meeting_user_id !== importedMeetingUser?.id) {
                return false;
            }
            // do not copy editor role if the user is external or light
            if (
                roleEditor.id === meetingUserItem.meeting_role_id &&
                !can(currentUser?.role, Permission.ManageMeeting)
            ) {
                return false;
            }
            // do not copy role if the user is light except Reader or NoAccess role
            if (!can(currentUser?.role, Permission.HaveNonEditorRoles)) {
                const role = meetingRoles?.find(({ id }) => id === meetingUserItem.meeting_role_id);
                if (
                    ![DEFAULT_MEETING_ROLES.READER, DEFAULT_MEETING_ROLES.NO_ACCESS].includes(
                        role.code as DEFAULT_MEETING_ROLES
                    )
                ) {
                    return false;
                }
            }

            return true;
        });
        setMeetingUserItems([
            ...meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id !== meetingUser?.id),
            ...importedMeetingUserItems.map((meetingUserItem) => ({
                ...meetingUserItem,
                id: uuidv4(),
                added: true,
                meeting_user_id: meetingUser?.id,
            })),
        ]);
    };

    const columns = [
        {
            title: t`Section`,
            dataIndex: 'section',
            render: (section: MeetingSection) => (
                <div className={'inline-flex gap-2 text-sm'}>
                    <SectionNumber
                        displayId={section.display_id}
                        hideSectionNumbering={meeting?.settings?.hide_section_numbering}
                    />
                    <span>{section.title}</span>
                </div>
            ),
        },
        {
            title: t`Role`,
            dataIndex: 'role',
            render: (section: MeetingSection) => {
                const item = meetingUserItems.find(
                    (meetingUserItem) =>
                        meetingUserItem.meeting_section_id != null &&
                        meetingUserItem.meeting_section_id === section.id &&
                        meetingUserItem.meeting_user_id === meetingUser?.id
                );
                const meetingRoleId = item ? item.meeting_role_id : meetingUser?.meeting_role_id;

                return (
                    <RoleSelector
                        roles={realRoles}
                        meetingRoleSelected={meetingRoleId}
                        userId={meetingUser?.user_id}
                        onUpdateRole={(role) => handleUpdateRole(section, role)}
                        isDisabled={meetingUser?.meeting_role_id === roleEditor.id}
                    />
                );
            },
        },
    ];

    return (
        <div>
            <div className="flex gap-2 pb-4 items-center">
                <div className="flex-1 font-medium">
                    <Trans>Default role</Trans>
                </div>
                <div className="flex-1 min-w-[300px]">
                    <RoleSelector
                        roles={realRoles}
                        meetingRoleSelected={meetingUser?.meeting_role_id}
                        userId={meetingUser?.user_id}
                        onUpdateRole={handleDefaultRole}
                        customFooter={
                            <>
                                <Divider />
                                <div className="p-1">
                                    <UserPicker
                                        className="w-full"
                                        onUserSelected={handleImportFrom}
                                        showRecent={false}
                                        usersToHide={otherUsers}
                                        icon={faUser}
                                        variant="outlined"
                                    >
                                        <Trans>Copy access from</Trans>
                                    </UserPicker>
                                </div>
                            </>
                        }
                    />
                </div>

                <Button
                    key="reset"
                    disabled={
                        meetingUserItems.filter(({ meeting_user_id }) => meeting_user_id === meetingUser?.id).length ===
                        0
                    }
                    onClick={handleClearMeetingUserItems}
                    className="flex-1"
                >
                    <Trans>Set default role to all sections</Trans>
                </Button>
            </div>
            <ManagedTable data={meetingSections} columns={columns} rowKey={(i) => i.id} />
        </div>
    );
};
