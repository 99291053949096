import { FC, ReactElement } from 'react';
import { useCurrentUserContext } from 'App/contexts';
import { MissingOrUnauthorizedErrorCard } from 'Pages/AppPage/RoutingError';
import { User } from 'Shared/types/user';
import { can, Permission } from 'Shared/utils/rbac';

export const Can: FC<{ permission: Permission; user?: User; showNoAccess?: boolean; children: ReactElement }> = ({
    permission,
    user,
    showNoAccess = false,
    children,
}) => {
    const { can: canCurrentUserAccess } = useCurrentUserContext();

    return !permission || (user && can(user?.role, permission)) || canCurrentUserAccess(permission) ? (
        children
    ) : showNoAccess ? (
        <MissingOrUnauthorizedErrorCard />
    ) : null;
};
