class Memory {
    cache: Record<string, any>;

    constructor() {
        this.cache = {};
    }

    setItem = (cacheKey: string, data: string) => {
        this.cache[cacheKey] = data;
    };

    getItem = (cacheKey: string) => this.cache[cacheKey];

    removeItem = (cacheKey: string) => {
        this.cache[cacheKey] = undefined;
    };
}

const isLocalStorageSupported = () => {
    try {
        localStorage.setItem('_ranger-test-key', 'hi');
        localStorage.getItem('_ranger-test-key');
        localStorage.removeItem('_ranger-test-key');
        return true;
    } catch (e) {
        return false;
    }
};

export const storage = isLocalStorageSupported() ? window.localStorage : new Memory();

export const getAuthToken = (key?: string) => localStorage.getItem(key ?? 'authToken');

export const removeAuthToken = (key?: string) => {
    if (getAuthToken(key)) {
        storage.removeItem(key ?? 'authToken');
        window.dispatchEvent(new Event('storage'));
    }
};

export const getLocalStorageSearchParams = (localStorageKey: string) => JSON.parse(storage.getItem(localStorageKey));
