import React, { FC, useMemo } from 'react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, Input, ItemGroup, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { CalendarSyncItem } from 'Shared/components/calendar/CalendarSync/CalendarSyncItem';

type CopyLinkSectionProps = {
    hash: string;
    userId: Id;
    itemIncluded: Set<CalendarSyncItem>;
    additionalParams?: Map<string, string>;
};

export const CopyLinkSection: FC<CopyLinkSectionProps> = ({ hash, userId, itemIncluded, additionalParams }) => {
    const { show } = useNotification();

    const disabled = userId === '' || hash === '' || itemIncluded.size === 0;

    const link = useMemo(() => {
        if (disabled) {
            return '';
        }

        const include = [...itemIncluded.keys()].join('+');
        let url = `${window.location.protocol}//${window.location.host}`;
        url += `/ical/${userId}`;
        url += `?hash=${encodeURIComponent(hash)}`;
        url += `${include !== '' ? '&items=' : ''}${include}`;

        if (additionalParams) {
            for (const [key, value] of additionalParams) {
                url += `&${key}=${value}`;
            }
        }

        return url;
    }, [itemIncluded, userId, hash, disabled, additionalParams]);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(link);

        show({
            title: t`Link copied to clipboard`,
            type: 'success',
        });
    };

    return (
        <div className="py-4">
            <h4 className="mb-2 text-base font-semibold">
                <Trans>Calendar link</Trans>
            </h4>

            <p className="mb-1 block text-sm font-medium text-gray-700">
                <Trans>Copy the link below and import it in your calendar application</Trans>
            </p>
            <ItemGroup>
                <Input className="flex-1" readOnly={true} value={link} disabled={disabled} />
                <Button
                    className="h-auto whitespace-nowrap "
                    color="primary"
                    icon={faCopy}
                    onClick={handleCopy}
                    disabled={disabled}
                >
                    <Trans>Copy link</Trans>
                </Button>
            </ItemGroup>
        </div>
    );
};
