import { FC } from 'react';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { TimeLineItem } from 'Shared/components/history/TimeLineItem';
import { UserFullName } from 'Shared/components/history/UserFullName';

export const RemoveFromFavoriteTimeLineItem: FC<TimeLineItem> = ({ activity, entityType }) => {
    if (entityType === 'workspace') {
        return (
            <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faHeart}>
                <p>
                    <Trans>
                        <UserFullName fullName={activity?.created_by?.full_name} /> removed the workspace from
                        favorites.
                    </Trans>
                </p>
            </Timeline.Item>
        );
    }

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={faHeart}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> removed the template from favorites.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
