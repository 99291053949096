import { useLingui } from '@lingui/react';
import React, { FC, useMemo } from 'react';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { plural, t } from '@lingui/macro';
import clsx from 'clsx';
import { differenceInDays, startOfDay } from 'date-fns';
import { Tag, Tooltip } from '@wedo/design-system';
import { formatDate, preventDefault, stopPropagation } from '@wedo/utils';
import { useLocalStorage } from '@wedo/utils/hooks';
import { Layout } from 'Pages/TasksPage/types';
import { LocalStorage } from 'Shared/types/localStorage';
import { Task } from 'Shared/types/task';

export const TaskDueDate: FC<{
    task: Task;
    isTemplate: boolean;
    isCompleted: boolean;
    isDeleted: boolean;
    layout: Layout;
}> = ({ task, isTemplate, isCompleted, isDeleted, layout }) => {
    const { i18n } = useLingui();
    const [showAbsoluteDueDate, setShowAbsoluteDueDate] = useLocalStorage<boolean>(
        LocalStorage.ShowTasksAbsoluteDueDate,
        false
    );
    const dateDifference = useMemo(() => {
        return differenceInDays(
            task?.deleted
                ? startOfDay(new Date(task?.deleted_at))
                : task?.completed
                  ? startOfDay(new Date(task?.completed_at))
                  : startOfDay(new Date()),
            startOfDay(new Date(task?.due_date ?? null))
        );
    }, [task?.completed, task?.completed_at, task?.deleted, task?.deleted_at, task?.due_date]);

    const dueDateTooltipContent = useMemo(() => {
        if (showAbsoluteDueDate) {
            return dateDifference === 0
                ? t`Today`
                : dateDifference > 0
                  ? plural(Math.abs(dateDifference), {
                        one: `Overdue since 1 day`,
                        other: `Overdue since ${Math.abs(dateDifference)} days`,
                    })
                  : plural(Math.abs(dateDifference), {
                        one: `Due in 1 day`,
                        other: `Due in ${Math.abs(dateDifference)} days`,
                    });
        }

        return formatDate(new Date(task?.due_date), 'PPP', i18n);
    }, [showAbsoluteDueDate, dateDifference]);

    return (
        !isTemplate &&
        (task.recurrence?.length > 0 || task.due_date != null) && (
            <Tooltip content={dueDateTooltipContent}>
                <Tag
                    onClick={
                        !isCompleted && !isDeleted
                            ? stopPropagation(preventDefault(() => setShowAbsoluteDueDate(!showAbsoluteDueDate)))
                            : undefined
                    }
                    size="xs"
                    iconClassName="mr-1"
                    icon={task.recurrence?.length > 0 && faSync}
                    className={clsx(
                        layout === 'list' && '@md:block hidden',
                        'border-none font-medium',
                        isCompleted || isDeleted || dateDifference < 0
                            ? 'text-gray-700 !bg-gray-100'
                            : dateDifference === 0
                              ? 'text-blue-700 !bg-blue-100'
                              : dateDifference > 0 && 'text-red-700 !bg-red-100'
                    )}
                >
                    {showAbsoluteDueDate || isCompleted || isDeleted ? (
                        formatDate(new Date(task?.due_date), 'shortDate', i18n).replace(/[.\/]\d+$/, '')
                    ) : (
                        <>
                            {dateDifference === 0
                                ? t`Today`
                                : dateDifference > 0
                                  ? '+' + t`${dateDifference}d`
                                  : t`${dateDifference}d`}
                        </>
                    )}
                </Tag>
            </Tooltip>
        )
    );
};
