import React, { FC } from 'react';
import { Avatar } from '@wedo/design-system';
import { User } from 'Shared/types/user';

export const PictureCell: FC<User> = ({ photo, color, initials }) => {
    return (
        <div className="flex items-center justify-center">
            <Avatar
                size="sm"
                img={photo != null && `/files/${photo}`}
                initials={initials}
                style={{ backgroundColor: color?.background ?? 'black' }}
            />
        </div>
    );
};
