import React from 'react';
import { plural } from '@lingui/macro';
import { RecurrenceEndsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceEndsOnInput';
import { RecurrenceMonthsInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceMonthsInput';
import { RecurrenceRepeatEveryInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatEveryInput';
import { RecurrenceRepeatOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceRepeatOnInput';
import { RecurrenceStartsOnInput } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/RecurrenceStartsOnInput';
import {
    FormRecurrence,
    RecurrenceMonth,
    RecurrenceMonthDay,
    RecurrenceWeek,
    RecurrenceWeekDay,
} from 'Shared/types/formRecurrence';

type RecurrenceYearlyFormProps = {
    state: FormRecurrence;
    handleDateChange?: (value: string, date: Date) => void;
    handleValueChange?: (name: string, value: number | string) => void;
    repeatEverySuffix?: string;
    toggleDay: (day: RecurrenceWeekDay | RecurrenceMonthDay, value: boolean) => void;
    toggleWeek: (week: RecurrenceWeek) => void;
    toggleMonth: (month: RecurrenceMonth) => void;
};
export const RecurrenceYearlyForm = ({
    state,
    handleValueChange,
    toggleDay,
    toggleWeek,
    toggleMonth,
}: RecurrenceYearlyFormProps): JSX.Element => {
    return (
        <div className={'grid grid-cols-12 items-center gap-x-3 gap-y-8'}>
            <RecurrenceStartsOnInput state={state} handleValueChange={handleValueChange} />
            <RecurrenceRepeatEveryInput
                state={state}
                repeatEverySuffix={plural(state.repeatEvery, { one: 'year', other: `years` })}
                handleValueChange={handleValueChange}
            />
            <RecurrenceRepeatOnInput
                state={state}
                handleValueChange={handleValueChange}
                toggleDay={toggleDay}
                toggleWeek={toggleWeek}
            />
            <RecurrenceMonthsInput state={state} toggleMonth={toggleMonth} />
            <RecurrenceEndsOnInput state={state} handleValueChange={handleValueChange} />
        </div>
    );
};
