export type BaseTree = {
    children: BaseTree[];
    id: string;
    label: string;
    payload?: unknown;
};

export type CheckboxTree = Omit<BaseTree, 'children'> & {
    checked: boolean;
    children: CheckboxTree[];
    open: boolean;
    parent: CheckboxTree;
};

export const parseTreeToCheckboxTree = (nodeParam: BaseTree): CheckboxTree => {
    const node = structuredClone(nodeParam);
    let nodesToVisit: any = [node];

    while (nodesToVisit.length > 0) {
        const currentNode = nodesToVisit[0];

        currentNode.checked = true;
        currentNode.open = false;

        currentNode.children?.forEach((child) => {
            child.parent = currentNode;
        });

        nodesToVisit = nodesToVisit.concat(currentNode.children ?? []).slice(1);
    }

    return node as CheckboxTree;
};

export const checkChildren = (node: CheckboxTree, checked: CheckboxTree['checked']) => {
    let nodesToVisit = [node];

    while (nodesToVisit.length > 0) {
        nodesToVisit[0].checked = checked;
        nodesToVisit = nodesToVisit.concat(nodesToVisit[0].children ?? []).slice(1);
    }
};

export const checkParents = (node: CheckboxTree) => {
    let checked = node.checked;
    let currentNode = node.parent;

    while (currentNode) {
        if (checked !== null && currentNode.children?.some((child) => child.checked !== checked)) {
            checked = null;
        }

        currentNode.checked = checked || checked === null ? checked : currentNode.checked;

        currentNode = currentNode.parent;
    }
};

export const setCheckedValue = (node: CheckboxTree, checked: boolean) => {
    node.checked = checked;
    checkChildren(node, checked);
    checkParents(node);
};

export const openNodeAndParents = (node: CheckboxTree) => {
    node.open = true;

    if (node.parent != null) {
        openNodeAndParents(node.parent);
    }
};

export const findRoot = (node: CheckboxTree) => {
    let currentNode = node;

    while (currentNode.parent) {
        currentNode = currentNode.parent;
    }

    return currentNode;
};

export const findNodeById = <T extends BaseTree>(node: T, id: string): T | null => {
    return node.id === id
        ? node
        : (node.children as T[])?.reduce((result, child) => result ?? findNodeById(child, id), null);
};

export const flattenTree = <T extends BaseTree>(tree: T): T[] => {
    let nodesToVisit = [tree];
    const flattenedTree = [];

    while (nodesToVisit.length > 0) {
        const currentNode = nodesToVisit[0];

        flattenedTree.push(currentNode);

        if (currentNode.children?.length > 0) {
            nodesToVisit = nodesToVisit.concat(currentNode.children as T[]);
        }

        nodesToVisit = nodesToVisit.slice(1);
    }

    return flattenedTree;
};
