import { t } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { HttpMethod } from '@wedo/types';
import { AddAuthorizedEmailPayload } from 'Pages/settings/authorizedEmails/types/addAuthorizedEmailPayload';
import { AuthorizedEmail } from 'Pages/settings/authorizedEmails/types/authorizedEmail';
import { baseApi, configureTag } from 'Shared/services/base';
import { CacheTag } from 'Shared/services/cacheTag';
import { RtkQueryResponseObject } from 'Shared/types/rtkQuery';
import { FETCH_ERROR_RESPONSE_MESSAGE, fetchErrorOccurred } from 'Shared/utils/rtkQuery';

const { tagType: authorizedEmailTagType, tag: authorizedEmailTag } = configureTag(CacheTag.AUTHORIZED_EMAILS);

const CURRENT_USER_AUTHORIZED_EMAILS = authorizedEmailTag('current-user-authorized-emails');

const addUserEmailError = () => ({
    DUPLICATE_EMAIL: {
        error: { message: 'Email already added', type: 'DuplicateError' },
        alert: { title: t`Duplicate email found`, message: t`This email address is already in use.` },
    },
});

export const authorizedEmailsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [authorizedEmailTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAuthorizedEmails: build.query<Array<AuthorizedEmail>, void>({
                query: () => 'admin/network/authorized_emails',
                providesTags: [CURRENT_USER_AUTHORIZED_EMAILS],
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    return response;
                },
            }),

            addAuthorizedEmail: build.mutation<AuthorizedEmail, AddAuthorizedEmailPayload>({
                query: (data) => ({
                    url: 'admin/network/authorized_emails',
                    method: HttpMethod.Post,
                    body: data,
                }),
                invalidatesTags: () => [CURRENT_USER_AUTHORIZED_EMAILS],
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    if (
                        isEqual(
                            (response as RtkQueryResponseObject).data.errors[0],
                            addUserEmailError().DUPLICATE_EMAIL.error
                        )
                    ) {
                        return addUserEmailError().DUPLICATE_EMAIL.alert;
                    }
                    return response;
                },
            }),

            deleteAuthorizedEmail: build.mutation<void, AuthorizedEmail>({
                query: (email) => ({
                    url: `admin/network/authorized_emails/${email.id}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: () => [CURRENT_USER_AUTHORIZED_EMAILS],
                transformErrorResponse: (response) => {
                    if (fetchErrorOccurred(response as RtkQueryResponseObject)) {
                        return FETCH_ERROR_RESPONSE_MESSAGE;
                    }
                    return response;
                },
            }),
        }),
    });

export const { useGetAuthorizedEmailsQuery, useAddAuthorizedEmailMutation, useDeleteAuthorizedEmailMutation } =
    authorizedEmailsApi;
