import React, { FC } from 'react';
import clsx from 'clsx';
import { Size, Tag, TagColor } from '@wedo/design-system';
import { getWorkspaceStatus } from 'Shared/components/workspace/WorkspaceStatusUtils';
import { Status } from 'Shared/types/workspaceStatus';

type WorkspaceStatusTagProps = {
    status: Status;
    className?: string;
    size?: Size | 'xs';
    hideTextSM?: boolean;
};

export const WorkspaceStatusTag: FC<WorkspaceStatusTagProps> = ({ status, className, size = 'sm', hideTextSM }) => {
    const workspaceStatus = getWorkspaceStatus(status);

    return (
        <Tag color={workspaceStatus?.color as TagColor} size={size} icon={workspaceStatus?.icon} className={className}>
            <span className={clsx(hideTextSM && 'hidden lg:block')}>{workspaceStatus?.title}</span>
        </Tag>
    );
};
