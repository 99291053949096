import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { msg, t, Trans } from '@lingui/macro';
import { Alert, Card, Switch, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useAppDispatch } from 'App/store';
import { type Feature } from 'Pages/settings/labs/types';
import { Can } from 'Shared/components/Can';
import { useGetCurrentNetworkQuery } from 'Shared/services/network';
import { invalidateCurrentUser, useGetCurrentUserQuery, useToggleUserFeatureMutation } from 'Shared/services/user';
import { DevFeature, UserFeature } from 'Shared/types/user';
import { Permission } from 'Shared/utils/rbac';
import { isSuccess } from 'Shared/utils/rtkQuery';

const userFeatures = [
    { id: UserFeature.Gantt, label: msg`Gantt chart` },
    { id: DevFeature.MeetingAI, label: msg`Meeting AI`, isDevFeature: true },
];

export const LabsSettingsPage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { i18n } = useLingui();

    const { data: network } = useGetCurrentNetworkQuery();
    const { show } = useNotification();

    const { data: user } = useGetCurrentUserQuery();

    const [toggleFeature, { isLoading: isTogglingFeature }] = useToggleUserFeatureMutation();

    const features = useMemo(() => {
        return userFeatures
            .filter(
                ({ isDevFeature }) =>
                    !isDevFeature || (network?.organisation?.type === 'demo' && __ENVIRONMENT__ !== 'production')
            )
            .map((feature) => {
                const value = user?.userNetwork?.config?.features?.[feature.id];
                return { ...feature, isEnabled: value != null && value !== false };
            });
    }, [user]);

    const handleToggleUserFeature = (feature: Feature) => async (value: boolean) => {
        if (await isSuccess(toggleFeature({ userId: user?.id, feature: feature.id }))) {
            show({
                type: 'success',
                title: value
                    ? t`The ${i18n._(feature.label)} feature has been enabled`
                    : t`The ${i18n._(feature.label)} feature has been disabled`,
                delay: 2000,
            });
            dispatch(invalidateCurrentUser());
        } else {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Can permission={Permission.AccessAllSettings} showNoAccess>
            <Card>
                <Card.Header
                    title={t`My labs`}
                    description={t`Labs are beta features you can test before they are officially launched. Because of that, they may be unstable.`}
                />
                <Card.Body className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        {features.map((feature) => (
                            <Switch.Group key={feature.id}>
                                <div className="flex items-center gap-4">
                                    <Switch
                                        disabled={isTogglingFeature}
                                        checked={feature.isEnabled}
                                        onChange={handleToggleUserFeature(feature)}
                                        label={i18n._(feature.label)}
                                    />
                                    <Switch.Label>
                                        {i18n._(feature.label)}
                                        {feature.description != null && (
                                            <div className="text-sm">{i18n._(feature.description)}</div>
                                        )}
                                    </Switch.Label>
                                </div>
                            </Switch.Group>
                        ))}
                    </div>
                    <Alert
                        type="info"
                        title={
                            features.length === 0 ? (
                                <Trans>No beta features to test at this time.</Trans>
                            ) : (
                                <Trans>
                                    If you have enabled or disabled a feature then you might need to refresh the page to
                                    see the changes.
                                </Trans>
                            )
                        }
                    />
                </Card.Body>
            </Card>
        </Can>
    );
};
