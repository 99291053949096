import { HttpMethod, Id } from '@wedo/types';
import { SurrogateKey } from 'Shared/types/surrogateKey';
import { baseApi, configureTag } from './base';

export const { tagType, tag, tags } = configureTag('surrogateKey');

export const surrogateKeyApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getSurrogateKey: build.query<
                SurrogateKey,
                {
                    property: string;
                    id: Id;
                }
            >({
                query: ({ id, property }) => ({
                    url: `surrogate_key`,
                    method: HttpMethod.Post,
                    body: { [property]: id },
                }),
                providesTags: (res, err, req) => [tag(req.property + '-' + req.id)],
            }),
        }),
    });

export const { useGetSurrogateKeyQuery, useLazyGetSurrogateKeyQuery } = surrogateKeyApi;
