import { FC } from 'react';
import clsx from 'clsx';
import { Table } from '@wedo/design-system';
import { TABLE_DEFINITION } from 'Pages/settings/users/utils/tableDefinition';
import { GetUsersArg } from 'Shared/services/admin';
import { User } from 'Shared/types/user';

export type UserTableProps = {
    status: GetUsersArg['status'];
    onSort?: (column: string) => void;
    sort?: string;
    userList: User[];
    isLoading?: boolean;
};

export const UserTable: FC<UserTableProps> = ({ status, onSort = () => null, sort, userList, isLoading }) => {
    const tableDefinition = TABLE_DEFINITION.get(status);

    if (userList.length === 0 || tableDefinition === undefined) {
        return null;
    }

    return (
        <div>
            <Table size="condensed" className={clsx(isLoading && 'opacity-50')} hoverable={true} striped={true}>
                <Table.Head>
                    {tableDefinition.map((column, index) => (
                        <Table.HeadCell
                            key={index}
                            onSort={column.sortColumn === '' ? null : () => onSort(column.sortColumn)}
                            sortDirection={
                                !column.sortColumn.endsWith(sort) || column.sortColumn === ''
                                    ? null
                                    : sort?.startsWith('-')
                                      ? 'descending'
                                      : 'ascending'
                            }
                            className={column.className}
                        >
                            {column.name}
                        </Table.HeadCell>
                    ))}
                </Table.Head>
                <Table.Body>
                    {userList.map((user) => (
                        <Table.Row key={user.id}>
                            {tableDefinition.map((column, index) => (
                                <Table.Cell className={column.className} key={index}>
                                    <column.component {...user} status={status} />
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};
