import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty, pick } from 'lodash-es';
import { NavLinkOptions, useNavLinkOptions } from '@wedo/design-system';
import { not } from '../functional';
import { storage } from '../localStorage';
import { useSearchParams } from './useSearchParams';

export const useLocalStorageSearchParams = (to: NavLinkOptions['to'], searchParamsToSave: string[]) => {
    const [searchParams] = useSearchParams();

    const { pathname } = useLocation();
    const {
        to: { pathname: localStorageKey },
        isMatch,
    } = useNavLinkOptions({ to });

    // as search params change, we update local storage
    useEffect(() => {
        const persistentSearchParams = pick(searchParams, searchParamsToSave);
        const userHasSelectedSomeParams = Object.values(persistentSearchParams).some(not(isEmpty));

        if (userHasSelectedSomeParams && persistentSearchParams && isMatch) {
            storage.setItem(localStorageKey, JSON.stringify(persistentSearchParams));
        }
    }, [searchParams, pathname, isMatch]);
};
