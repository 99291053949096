import { FC } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import { Button, Form, Input, ItemGroup } from '@wedo/design-system';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';

export const InviteStep: FC = () => {
    const { updateKey, actions, zodErrors, isRestrictedDomain, getErrorAtIndex, getError, ...store } = useOnboarding();

    const inviteeErrors = zodErrors?.filter((error) => ['invites'].some((key) => error.path.includes(key)));
    const canSendInvitation = store.invites.join('').trim() !== '';
    const hasInviteeErrors = inviteeErrors?.length > 0;
    const creatorEmail = isRestrictedDomain ? store.email : store.email.split('@')[0];

    if (store.invites[0] !== creatorEmail) {
        const newInvitations = structuredClone(store.invites);
        newInvitations.unshift(creatorEmail);
        updateKey({ key: 'invites', value: newInvitations });
    }

    const updateInvitations = (value: string, index: number) => {
        const newInvitations = structuredClone(store.invites) as [string, string, string];
        newInvitations[index] = value.toLowerCase();

        if (newInvitations.every((invitation) => invitation.trim() !== '')) {
            newInvitations.push('');
        }

        updateKey({ key: 'invites', value: newInvitations });
    };

    const handleNext = () => {
        updateKey({ key: 'invites', value: store.invites.filter((email) => email !== creatorEmail) });

        let errors = structuredClone(zodErrors);
        errors = errors?.filter((error) => ['invites'].some((key) => error.path.includes(key)));
        if (errors.length > 0) {
            return;
        }
        actions.setStep(store.step + 1);
    };

    return (
        <>
            <Form
                layout="vertical"
                title={t`Invite your teammates`}
                onKeyDown={(e) => e.key === 'Enter' && handleNext()}
            >
                <Form.Item>
                    <Trans>
                        Add all teammates that will be working with you in the team <strong>{store.teamName}</strong>.
                        If you skip this step you can invite them later.
                    </Trans>
                </Form.Item>

                {store.invites.map((invitation, index) => (
                    <Form.Item key={index}>
                        <ItemGroup
                            status={getErrorAtIndex('invites', index)?.message ? 'error' : 'default'}
                            statusText={getErrorAtIndex('invites', index)?.message ?? ''}
                        >
                            <Input
                                disabled={index === 0}
                                className="grow"
                                value={invitation}
                                onChange={(e) => updateInvitations(e.target.value, index)}
                            />
                            {!isRestrictedDomain && (
                                <Button disabled position="end" className="!cursor-default">
                                    @{store.domainFromEmail}
                                </Button>
                            )}
                        </ItemGroup>
                    </Form.Item>
                ))}
            </Form>

            <OnboardingLayout.Buttons>
                <Button onClick={() => actions.setStep(store.step - 1)} icon={faChevronLeft}>{t`Previous`}</Button>
                <Button
                    disabled={hasInviteeErrors}
                    color={canSendInvitation ? 'primary' : 'default'}
                    onClick={handleNext}
                    icon={faChevronRight}
                    iconPosition="end"
                >
                    {canSendInvitation ? <Trans>Next</Trans> : <Trans>Skip</Trans>}
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
