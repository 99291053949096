import { useLingui } from '@lingui/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { faCalendar, faCheckToSlot, faCog } from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    Checkbox,
    ContextModalProps,
    DatePicker,
    Form,
    ItemGroup,
    Label,
    Modal,
    ModalType,
    Popover,
    RadioGroup,
    Switch,
    Tabs,
    TimePickerInput,
    useConfirm,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { formatDate, getBreakpointValue } from '@wedo/utils';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { useSessionUser } from 'App/store/usersStore';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useUpdateMeetingSettingsMutation } from 'Shared/services/meeting';
import { DevFeature } from 'Shared/types/user';

type MeetingSettingsModalProps = {
    meetingId: Id;
    defaultOpenedPane?: string;
} & ContextModalProps &
    PropsWithChildren;

interface SettingsFormState {
    allow_topic_submission: boolean;
    hide_numbering: boolean;
}

interface VoteSettingsFormState {
    require_quorum: boolean;
    anonymous_vote: boolean;
    hide_results_until_closed: boolean;

    due_date_active: boolean;
    due_date: Date;
}

export const MeetingSettingsModal = ({
    defaultOpenedPane = 'general',
    children,
    meetingId,
    ...modalProps
}: MeetingSettingsModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();
    const { i18n } = useLingui();
    const { width: viewportWidth } = useWindowSize();
    const currentUser = useSessionUser();
    const { network } = useCurrentNetwork();
    const hasTopicSubmissionFeature = useHasFeature(currentUser, network, DevFeature.TopicSubmission);

    const [updateMeetingSettings, { isLoading }] = useUpdateMeetingSettingsMutation();

    const [formState, setFormState] = useState<SettingsFormState>();
    const [voteFormState, setVoteFormState] = useState<VoteSettingsFormState>();
    const [hasChanged, setHasChanged] = useState(false);
    const updateVoteFormState = (changeObject: Partial<VoteSettingsFormState>) => {
        setHasChanged(true);
        setVoteFormState({
            ...voteFormState,
            ...changeObject,
        });
    };

    const updateFormState = (changeObject: Partial<VoteSettingsFormState> | Partial<SettingsFormState>) => {
        setHasChanged(true);
        setFormState({
            ...formState,
            ...changeObject,
        });
    };

    const onSaveModalDone = async (applyOn: APPLY_ON) => {
        const settings = {
            hide_topic_numbering: formState.hide_numbering,
            hide_section_numbering: formState.hide_numbering,
            allow_topic_submission: formState.allow_topic_submission,
            vote: {
                require_quorum: voteFormState.require_quorum,
                anonymous_vote: voteFormState.anonymous_vote,
                hide_results_until_closed: voteFormState.hide_results_until_closed,
                due_date: voteFormState.due_date_active ? voteFormState.due_date : null,
            },
        };
        if (applyOn != null) {
            await updateMeetingSettings({ meetingId, applyOn, settings })
                .unwrap()
                .then(() => modalProps.close());
        }
    };

    const resetFormState = () => {
        setFormState({
            hide_numbering: meeting?.settings?.hide_topic_numbering ?? false,
            allow_topic_submission: meeting?.settings?.allow_topic_submission ?? false,
        });
        setVoteFormState({
            require_quorum: meeting?.settings?.vote?.require_quorum,
            anonymous_vote: meeting?.settings?.vote?.anonymous_vote,
            hide_results_until_closed: meeting?.settings?.vote?.hide_results_until_closed,
            due_date_active: meeting?.settings?.vote?.due_date != null,
            due_date: meeting?.settings?.vote?.due_date
                ? new Date(meeting?.settings?.vote?.due_date)
                : new Date(meeting?.start_at),
        });
    };

    useEffect(() => {
        resetFormState();
    }, [meeting?.settings]);

    return (
        <>
            <Modal
                size={'md'}
                onBeforeClose={async (source) => {
                    if (['cancel', 'backdrop-or-esc', 'cross'].includes(source)) {
                        if (!hasChanged) {
                            return true;
                        }
                        const confirmDiscard = await confirm({}, ConfirmDiscardChangesModal);
                        if (confirmDiscard) {
                            setHasChanged(false);
                            resetFormState();
                        }
                        return confirmDiscard;
                    }
                    return true;
                }}
                {...modalProps}
            >
                <Modal.Header title={t`Meeting settings`} />
                <Modal.Body className="!p-0">
                    <div className={'min-h-[410px]'}>
                        <Tabs
                            layout={viewportWidth >= getBreakpointValue('md') ? 'vertical' : 'horizontal'}
                            defaultIndex={defaultOpenedPane === 'votes' ? 1 : undefined}
                        >
                            <Tabs.Header>
                                <Tabs.Tab key="general" icon={faCog}>{t`General`}</Tabs.Tab>
                                <Tabs.Tab key="votes" icon={faCheckToSlot}>{t`Votes`}</Tabs.Tab>
                            </Tabs.Header>
                            <div className="hidden min-h-[410px] w-px bg-gray-200 md:block" />
                            <Tabs.Panels className={clsx(viewportWidth < getBreakpointValue('md') && 'p-5')}>
                                <Tabs.Panel>
                                    <Form layout="vertical">
                                        {hasTopicSubmissionFeature && (
                                            <Form.Item label={t`Allow topic submission for users with no access`}>
                                                <Switch
                                                    checked={formState?.allow_topic_submission}
                                                    onChange={(value: boolean) => {
                                                        updateFormState({ allow_topic_submission: value });
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                        <Form.Item label={t`Section and Topic numbering`}>
                                            <RadioGroup
                                                name={'numbering'}
                                                radioType={'buttonGroup'}
                                                onChange={(value: boolean) => {
                                                    updateFormState({ hide_numbering: value });
                                                }}
                                                value={formState?.hide_numbering}
                                                size="md"
                                            >
                                                <RadioGroup.Radio id={'radio-hide-numbering'} value={true}>
                                                    <Trans>Hide</Trans>
                                                </RadioGroup.Radio>
                                                <RadioGroup.Radio id={'radio-show-numbering'} value={false}>
                                                    <Trans>Show</Trans>
                                                </RadioGroup.Radio>
                                            </RadioGroup>
                                        </Form.Item>
                                    </Form>
                                </Tabs.Panel>
                                <Tabs.Panel>
                                    <Form layout="vertical">
                                        <Form.Item>
                                            <Label>
                                                <div className={'flex items-center gap-2'}>
                                                    <Checkbox
                                                        checked={voteFormState?.require_quorum}
                                                        onChange={(e) => {
                                                            updateVoteFormState({ require_quorum: e.target.checked });
                                                        }}
                                                    />
                                                    <div>
                                                        <Trans>Require quorum</Trans>
                                                        <div className={'text-gray-600'}>
                                                            <Trans>
                                                                Minimum number of shares based on present attendees
                                                            </Trans>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Label>
                                        </Form.Item>
                                        <Form.Item>
                                            <Label>
                                                <div className={'flex items-center gap-2'}>
                                                    <Checkbox
                                                        checked={voteFormState?.anonymous_vote}
                                                        onChange={(e) => {
                                                            updateVoteFormState({ anonymous_vote: e.target.checked });
                                                        }}
                                                    />
                                                    <div>
                                                        <Trans>Anonymous vote</Trans>
                                                        <div className={'text-gray-600'}>
                                                            <Trans>Voters can't see others' answers</Trans>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Label>
                                        </Form.Item>
                                        <Form.Item>
                                            <Label>
                                                <div className={'flex items-center gap-2'}>
                                                    <Checkbox
                                                        checked={voteFormState?.hide_results_until_closed}
                                                        onChange={(e) => {
                                                            updateVoteFormState({
                                                                hide_results_until_closed: e.target.checked,
                                                            });
                                                        }}
                                                    />
                                                    <Trans>Hide results until voting is closed</Trans>
                                                </div>
                                            </Label>
                                        </Form.Item>
                                        <Form.Item>
                                            <div className={'mb-2 flex flex-wrap items-center gap-2'}>
                                                <Label className={'flex items-center gap-2'}>
                                                    <Checkbox
                                                        checked={voteFormState?.due_date_active}
                                                        onChange={(e) => {
                                                            updateVoteFormState({ due_date_active: e.target.checked });
                                                        }}
                                                    />
                                                    <Trans>Due date</Trans>
                                                </Label>
                                                <ItemGroup>
                                                    <Popover
                                                        iconPosition={'end'}
                                                        disabled={!voteFormState?.due_date_active}
                                                        icon={faCalendar}
                                                        className={'w-44 justify-between'}
                                                        text={formatDate(voteFormState?.due_date, 'shortDate', i18n)}
                                                    >
                                                        {({ close }) => (
                                                            <div
                                                                tabIndex={-1}
                                                                className={
                                                                    'flex flex-col items-center justify-center gap-2 bg-white p-3 outline-0'
                                                                }
                                                            >
                                                                <DatePicker
                                                                    onChange={(value) => {
                                                                        updateVoteFormState({ due_date: value });
                                                                        close();
                                                                    }}
                                                                    date={new Date(voteFormState?.due_date)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Popover>
                                                    <TimePickerInput
                                                        className={'!w-full'}
                                                        inputClassName={'!w-full'}
                                                        wrapperClassName={'w-24'}
                                                        disabled={!voteFormState?.due_date_active}
                                                        date={
                                                            voteFormState?.due_date
                                                                ? new Date(voteFormState.due_date)
                                                                : new Date()
                                                        }
                                                        onChange={(date: Date) => {
                                                            updateVoteFormState({ due_date: date });
                                                        }}
                                                    />
                                                </ItemGroup>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </Tabs.Panel>
                            </Tabs.Panels>
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button loading={isLoading} onClick={() => modalProps.close('cancel')}>{t`Cancel`}</Button>
                    <Button
                        variant={'filled'}
                        color={'primary'}
                        loading={isLoading}
                        onClick={async () => {
                            if (hasChanged) {
                                const applyOn = await confirm(
                                    {
                                        disabledFuture: !meeting.nextMeetings || meeting.nextMeetings.length === 0,
                                        showAll: false,
                                        defaultOption:
                                            !meeting.nextMeetings || meeting.nextMeetings.length === 0
                                                ? APPLY_ON.THIS_MEETING
                                                : APPLY_ON.FUTURE_MEETINGS,
                                        type: ModalType.Question,
                                    },
                                    ConfirmSaveMeetingModal
                                );
                                await onSaveModalDone(applyOn);
                                setHasChanged(false);
                            } else {
                                setHasChanged(false);
                                await modalProps.close();
                            }
                        }}
                    >
                        {hasChanged ? t`Save` : t`Close`}
                    </Button>
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
