// Retrieve the QRCode from the server
import { useContext, useEffect } from 'react';
import { t } from '@lingui/macro';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';
import { QrCodeType, ResetError } from 'Pages/settings/security/utils/type';
import { useGetQrCodeQuery } from 'Shared/services/settingsSecurity';
import { errorMessage } from 'Shared/utils/rtkQuery';

type QrCodeFn = () => { data: QrCodeType; isLoading: boolean };

export const useQrCode: QrCodeFn = () => {
    const { state, dispatch } = useContext(TotpContext);
    const { data, isLoading, isSuccess, isError } = useGetQrCodeQuery();

    useEffect(() => {
        if (isSuccess) {
            dispatch({ type: ResetError });

            dispatch({
                type: 'qrCode',
                value: {
                    image: data.image,
                    secret: data.secret,
                },
            });
        } else if (isError) {
            let error = t`Unexpected error`;

            if (errorMessage(error) === 'TypeError: Failed to fetch') {
                error = t`Network error`;
            }

            dispatch({ type: 'error', value: error });
        }
    }, [isSuccess, isError, isLoading]);

    return { data: state.qrCode, isLoading };
};
