import { msg, t } from '@lingui/macro';
import { HttpMethod, Id } from '@wedo/types';
import { CacheTag } from 'Shared/services/cacheTag';
import { ApiError, TransformParameter, UnknownError } from 'Shared/types/apiError';
import { User, UserFeature } from 'Shared/types/user';
import { baseApi, configureTag } from './base';

type ToggleFeatureResponse = {
    command: 'UPDATE';
    rowCount: 1;
    oid: null;
    rows: [];
    fields: [];
    _types: object;
    RowCtor: null;
    rowAsArray: boolean;
};

export const { tagType: userTagType, tag: userTag, tags: userTags } = configureTag(CacheTag.USER);

export const CURRENT_USER_TAG = userTag('current-user');
export const LIST_TAG = userTag('list-user');

export const updateCurrentUserError: TransformParameter = {
    DisplayName: {
        code: 'ValidationError',
        path: 'display_name',
        message: msg`The display name cannot contain special characters.`,
    },
    FirstName: {
        code: 'ValidationError',
        path: 'first_name',
        message: msg`The first name cannot be empty or contain special characters.`,
    },
    LastName: {
        code: 'ValidationError',
        path: 'last_name',
        message: msg`The last name cannot be empty or contain special characters.`,
    },
    Initials: {
        path: 'Data is not valid',
        message: msg`Initials must be 2 or 3 characters`,
    },
    NotFound: {
        code: 'NotFoundError',
        path: 'User not found',
        message: msg`User not found`,
    },
    [UnknownError]: {
        code: 'UnknownError',
    },
};
export const getUserError = (): TransformParameter => ({
    NotFound: {
        code: 'NotFoundError',
        path: 'User not found',
        message: t`User not found`,
    },
});

export const inviteUserError = {
    MaxUser: {
        code: 'NotAcceptableError',
        path: 'max users',
        message: t`Max users reached. Please upgrade your plan.`,
    },
    MaxLight: {
        code: 'NotAcceptableError',
        path: 'max lights',
        message: t`Max light users reached. Please upgrade your plan.`,
    },
} satisfies TransformParameter;

export const userApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [userTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getCurrentUser: build.query<User, void>({
                query: () => 'users/me',
                providesTags: (result) => [userTag(result?.id), CURRENT_USER_TAG],
            }),

            getUser: build.query<User, Id>({
                query: (id) => ({ url: `users/${id}` }),
                providesTags: (result) => [userTag(result?.id)],
                transformErrorResponse: (error) => error.transform(getUserError()),
            }),

            getUserSamlInfo: build.query<{ is_saml: boolean }, { email: string }>({
                query: ({ email }) => {
                    if (email === '') {
                        throw new Error(t`Email cannot be empty for the rtkQuery endpoint "getUserSamlInfo"`);
                    }

                    return {
                        url: 'users/saml-info',
                        // the endpoint doesn't support an empty email
                        params: { email },
                    };
                },
            }),

            getUsersWithFilter: build.query<
                User[],
                {
                    filter?: 'all' | 'users' | 'externals' | 'admins' | 'lights';
                    limit?: number;
                    offset?: number;
                    search: string;
                }
            >({
                query: ({ filter, limit = 24, offset = 0, search }) => ({
                    url: 'users',
                    params: {
                        filter,
                        limit,
                        offset,
                        query: search,
                    },
                }),
                providesTags: (result) => {
                    return [...userTags(result), LIST_TAG];
                },
            }),

            updateCurrentUser: build.mutation<User, Partial<User>>({
                query: (data: User) => ({
                    url: 'users/me',
                    method: HttpMethod.Put,
                    body: data,
                }),
                invalidatesTags: (result) => [userTag(result?.id), CURRENT_USER_TAG],
                transformErrorResponse: (error: ApiError) => error.transform(updateCurrentUserError),
            }),

            updateUserPicture: build.mutation<User, { file: FormData; userId: Id }>({
                query: ({ file, userId = null }) => ({
                    url: userId === null ? 'users/me/photo' : `/api/admin/users/${userId}/photo`,
                    method: HttpMethod.Put,
                    body: file,
                }),
                invalidatesTags: (result) => {
                    return [userTag(result?.id), CURRENT_USER_TAG];
                },
            }),

            deleteUserPicture: build.mutation<User, Id>({
                query: (userId = null) => ({
                    url: userId === null ? 'users/me/photo' : `/api/admin/users/${userId}/photo`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result) => {
                    return [userTag(result?.id), CURRENT_USER_TAG];
                },
            }),

            toggleUserFeature: build.mutation<ToggleFeatureResponse, { userId: Id; feature: UserFeature }>({
                query: ({ feature }) => ({
                    url: `users/me/features/${feature}`,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { userId }) => [userTag(userId)],
            }),
        }),
    });

export const {
    useGetCurrentUserQuery,
    useLazyGetCurrentUserQuery,
    useGetUserQuery,
    useLazyGetUserSamlInfoQuery,
    useGetUsersWithFilterQuery,
    useUpdateCurrentUserMutation,
    useUpdateUserPictureMutation,
    useDeleteUserPictureMutation,
    useToggleUserFeatureMutation,
} = userApi;

export const invalidateCurrentUser = () => userApi.util.invalidateTags([CURRENT_USER_TAG]);
export const invalidateUsers = () => userApi.util.invalidateTags([LIST_TAG]);
