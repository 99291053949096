import React, { FC, useMemo, useState } from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Select, SelectProps } from '@wedo/design-system';
import { CountryCode } from '@wedo/types';
import { countryCodeMapping } from '@wedo/utils';

type CountrySelectorProps = {
    value: CountryCode;
    onChange: (country: CountryCode) => void;
    onBlur?: () => void;
    status?: SelectProps['status'];
    statusText?: string;
    isDisabled?: boolean;
    mode?: 'full' | 'simple';
    className?: string;
};

export const CountrySelector: FC<CountrySelectorProps> = ({
    value,
    onChange,
    onBlur,
    status,
    statusText,
    isDisabled = false,
    mode = 'full',
    className,
}) => {
    const allCountries = useMemo(
        () =>
            Object.keys(countryCodeMapping)
                .map((code: CountryCode) => ({ code, label: i18n._(countryCodeMapping[code]) }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        []
    );
    const [countries, setCountries] = useState<
        Array<{
            code: CountryCode;
            label: string;
        }>
    >(allCountries);

    const handleSearch = (search: string) => {
        if (isEmpty(search)) {
            setCountries(allCountries);
        } else {
            setCountries(() =>
                allCountries.filter(
                    ({ label, code }) =>
                        (mode === 'full' && label.toLowerCase().includes(search.toLowerCase())) ||
                        code.toLowerCase().includes(search.toLowerCase())
                )
            );
        }
    };
    return (
        <Select
            wrapperClassName={clsx(mode === 'simple' && '!shrink !grow-0')}
            className={className}
            value={value}
            onChange={onChange}
            placeholder={mode === 'simple' ? 'XX' : t`Select country`}
            customRenderSelected={(countryCode: CountryCode) =>
                mode === 'simple' ? countryCode : `${i18n._(countryCodeMapping[countryCode])} (${countryCode})`
            }
            onSearch={handleSearch}
            onBlur={onBlur}
            status={status}
            statusText={statusText}
            disabled={isDisabled}
        >
            {countries.map(({ code, label }) => {
                return (
                    <Select.Option key={code} value={code}>
                        {mode === 'simple' ? code : `${label} (${code})`}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
