import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Card, getCroppedImgBlob, PixelCrop, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { fileTypeToImageExtension } from '@wedo/utils';
import { LogoSettings } from 'Pages/settings/shared/logo/LogoSettings';
import {
    useDeleteNetworkIconMutation,
    useDeleteNetworkLogoMutation,
    useGetCurrentNetworkQuery,
    useUpdateNetworkIconMutation,
    useUpdateNetworkLogoMutation,
} from 'Shared/services/network';

export const ImageSettingsCard = () => {
    const { data: { logo_url, icon_url } = {}, isLoading: isLogoLoading } = useGetCurrentNetworkQuery();
    const { show } = useNotification();

    const [deleteNetworkLogo, { isLoading: isDeleteLogoLoading }] = useDeleteNetworkLogoMutation();
    const [updateNetworkLogo, { isLoading: isUpdateLogoLoading }] = useUpdateNetworkLogoMutation();
    const [deleteNetworkIcon, { isLoading: isDeleteIconLoading }] = useDeleteNetworkIconMutation();
    const [updateNetworkIcon, { isLoading: isUpdateIconLoading }] = useUpdateNetworkIconMutation();

    const getImageFormData = async (file: File, crop?: PixelCrop): Promise<FormData> => {
        const formData = new FormData();
        if (file.type === 'image/svg+xml') {
            formData.append('attachments', file);
        } else {
            const croppedImage: Blob = await getCroppedImgBlob(file, crop);
            formData.append(
                'attachments',
                new File([croppedImage], `logo.${fileTypeToImageExtension(file.type)}`, { type: file.type })
            );
        }
        return formData;
    };

    const handleLogoDelete = async () => {
        const response = await deleteNetworkLogo();
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleIconDelete = async () => {
        const response = await deleteNetworkIcon();
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleLogoEdit = async (file: File, crop: PixelCrop) => {
        const formData = await getImageFormData(file, crop);
        const response = await updateNetworkLogo(formData);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleIconEdit = async (file: File, crop: PixelCrop) => {
        const formData = await getImageFormData(file, crop);
        const response = await updateNetworkIcon(formData);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Card>
            <Card.Header title={t`Logos`} />
            <Card.Body className="flex flex-col gap-5">
                <div>
                    <h4 className="text-base font-semibold">
                        <Trans>Icon</Trans>
                    </h4>

                    <p className="mb-3 mt-1 text-gray-700">
                        <Trans>This icon will be used on the header of the application.</Trans>
                    </p>

                    <LogoSettings
                        logo={icon_url}
                        isLogoLoading={isLogoLoading}
                        isDeleteLogoLoading={isDeleteIconLoading}
                        isUpdateLogoLoading={isUpdateIconLoading}
                        onImageEdit={handleIconEdit}
                        onLogoDelete={handleIconDelete}
                        logoMinHeight={100}
                        logoMinWidth={100}
                        addText={t`Choose icon`}
                        deleteText={t`Delete icon`}
                        replaceText={t`Replace icon`}
                        aspect={1}
                        placeholderImage="icon"
                    />
                </div>

                <div>
                    <h4 className="text-base font-semibold">
                        <Trans>Logo</Trans>
                    </h4>

                    <p className="mb-3 mt-1 text-gray-700">
                        <Trans>This logo will be used on the login page and on PDF exports.</Trans>
                    </p>

                    <LogoSettings
                        logo={logo_url}
                        isLogoLoading={isLogoLoading}
                        isDeleteLogoLoading={isDeleteLogoLoading}
                        isUpdateLogoLoading={isUpdateLogoLoading}
                        onImageEdit={handleLogoEdit}
                        onLogoDelete={handleLogoDelete}
                        logoMinHeight={80}
                        logoMinWidth={200}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};
