import React from 'react';
import { ContextModalProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useAppDispatch } from 'App/store';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { invalidateTaskAttachments } from 'Shared/services/attachment';
import { useAddTaskCustomFieldValueMutation } from 'Shared/services/taskCustomFieldValue';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Attachment } from 'Shared/types/attachment';

type Props = {
    workspaceId?: Id;
    taskId?: Id;
    customFieldId?: Id;
} & ContextModalProps;

export const TaskAddAttachmentModal = ({ workspaceId, taskId, customFieldId, ...modalProps }: Props) => {
    const dispatch = useAppDispatch();
    const [addTaskCustomFieldValue] = useAddTaskCustomFieldValueMutation();
    const { mutateAsync: addAttachmentRelations } = trpc.attachment.addRelations.useMutation({
        onSuccess: () => {
            invalidateCachedTasks();
            dispatch(invalidateTaskAttachments(taskId));
            void trpcUtils().attachment.list.invalidate({ taskId });
            void modalProps.close();
        },
    });

    const handleDone = async (attachments: Attachment[]) => {
        if (!attachments) {
            await modalProps.close();
            return;
        }
        if (customFieldId) {
            await addTaskCustomFieldValue({
                taskId: taskId,
                customFieldId: customFieldId,
                value: { attachments: attachments },
            });
        } else if (taskId) {
            await addAttachmentRelations(attachments.map(({ id }) => ({ attachmentId: id, taskId })));
        }
        await modalProps.close();
    };

    return <AddAttachmentModal {...modalProps} onDone={handleDone} taskId={taskId} workspaceId={workspaceId} />;
};
