import { useLingui } from '@lingui/react';
import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    faChevronLeft,
    faChevronRight,
    faFileLines,
    faPlus,
    faRectangleHistory,
} from '@fortawesome/pro-regular-svg-icons';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Button, ButtonProps, Dropdown, Skeleton, Tag, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue, getDateFnsLocale } from '@wedo/utils';
import { useElementSize, useNavigate } from '@wedo/utils/hooks';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { MeetingHeaderActions } from 'Pages/meeting/components/MeetingHeader/MeetingHeaderActions';
import { MeetingHeaderStatusButton } from 'Pages/meeting/components/MeetingHeader/MeetingHeaderStatusButton';
import { MeetingPopover } from 'Pages/meeting/components/MeetingHeader/MeetingPopover';
import { AddNextMeetingModal } from 'Shared/components/meeting/addMeetingModal/AddNextMeetingModal';
import { invalidateMeetingSeries, useAddMeetingMutation, usePreviousMeeting } from 'Shared/services/meeting';
import { Meeting, MeetingPermission, MeetingStateValue, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingViewMode } from '../../MeetingViewMode';
import { useGenerateNextMeetingInSeries } from './useGenerateNextMeetingInSeries';

const getActionsSkeletonCount = (width: number) => {
    if (getBreakpointValue('sm') > width) {
        return 1;
    }
    if (getBreakpointValue('md') > width) {
        return 2;
    }
    if (getBreakpointValue('lg') > width) {
        return 3;
    }
    return 4;
};

export const TransparencySkeleton = ({
    count,
    circle = false,
    className,
}: {
    count: number;
    circle?: boolean;
    className?: string;
}) => <Skeleton count={count} shape={circle ? 'circle' : 'square'} className={clsx('h-full w-full', className)} />;

export const MeetingViewDropdownItems = ({
    viewMode,
    onViewModeChange,
}: {
    viewMode: MeetingViewMode;
    onViewModeChange: (viewMode: MeetingViewMode) => void;
}) => (
    <>
        <Dropdown.Item
            icon={faRectangleHistory}
            selected={viewMode === MeetingViewMode.TopicView}
            onClick={() => onViewModeChange(MeetingViewMode.TopicView)}
        >
            <Trans>Topic view</Trans>
        </Dropdown.Item>
        <Dropdown.Item
            icon={faFileLines}
            selected={viewMode === MeetingViewMode.DocumentView}
            onClick={() => onViewModeChange(MeetingViewMode.DocumentView)}
        >
            <Trans>Document view</Trans>
        </Dropdown.Item>
    </>
);
export const MeetingHeader = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const { currentUser } = useCurrentUserContext();
    const { meetingId, meeting } = useMeetingContext();
    const { open: openModal } = useModal();
    const headerContainerRef = useRef(null);
    const { width: headerWidth } = useElementSize(headerContainerRef);

    const {
        data: previousMeeting,
        isUninitialized: isPreviousMeetingUninitialized,
        isLoading: isLoadingPreviousMeeting,
    } = usePreviousMeeting(meeting?.id, meeting?.series_master_id);
    const { hasPermission: canManageMeeting, isLoading: isLoadingPermission } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );
    const nextMeetingTemplate = useGenerateNextMeetingInSeries(meetingId, meeting?.nextMeetings?.length > 0);
    const [addMeeting, { isLoading: isAddingMeeting }] = useAddMeetingMutation();

    const { width } = useWindowSize();
    const { i18n } = useLingui();
    const navigate = useNavigate();

    const handleAddNextMeetingDone = (nextMeeting: Meeting) => {
        if (nextMeeting) {
            if (meeting?.series_master_id != null) {
                dispatch(invalidateMeetingSeries(meeting.series_master_id));
            }
            navigate('/meetings/' + nextMeeting.id);
        }
    };

    const handleAddNextMeeting = async () => {
        if (isAddingMeeting || !meeting) {
            return;
        }
        if (meeting.type === 'singleInstance' || meeting.seriesMaster?.recurrence_pattern === 'once') {
            openModal(AddNextMeetingModal, { onDone: handleAddNextMeetingDone, templateId: meetingId });
        } else {
            const response = await addMeeting({
                ...nextMeetingTemplate,
                type: nextMeetingTemplate.type === 'exception' ? 'occurrence' : nextMeetingTemplate.type,
            });
            if ('data' in response) {
                handleAddNextMeetingDone(response.data as Meeting);
            }
        }
    };

    const HeaderNavSkeleton = () => (
        <div style={{ marginTop: '-2px' }}>
            <TransparencySkeleton count={1} className={clsx('h-10 w-full')} />
        </div>
    );

    const HeaderButtonLinkWrapper = useCallback(
        ({
            meetingId,
            children,
            ...props
        }: {
            meetingId: Id;
            children: React.ReactNode;
        } & ButtonProps) => (
            <Link to={`/meetings/${meetingId}`}>
                <Button color={'light'} {...props}>
                    {children}
                </Button>
            </Link>
        ),
        []
    );

    return (
        <>
            <div
                ref={headerContainerRef}
                className={clsx(
                    '@container flex flex-row items-center justify-center gap-1 px-2 py-2.5 z-10',
                    meeting?.state === MeetingStateValue.CURRENT
                        ? 'bg-gradient-blue text-white'
                        : 'bg-gray-500 text-white'
                )}
            >
                <div className={'flex items-center justify-start'}>
                    {meeting && !meeting.deleted && previousMeeting ? (
                        <HeaderButtonLinkWrapper
                            title={t`Previous meeting`}
                            icon={faChevronLeft}
                            meetingId={previousMeeting.id}
                        >
                            {headerWidth >= getBreakpointValue('lg')
                                ? format(new Date(previousMeeting.start_at), 'd MMMM', {
                                      locale: getDateFnsLocale(i18n?.locale),
                                  })
                                : undefined}
                        </HeaderButtonLinkWrapper>
                    ) : (
                        (meeting === undefined || isPreviousMeetingUninitialized || isLoadingPreviousMeeting) && (
                            <HeaderNavSkeleton />
                        )
                    )}
                </div>

                <div className={'flex-1 overflow-hidden min-w-[8rem]'}>
                    <div className={'m-auto flex-1'}>
                        <div className={'flex items-center'}>
                            {meeting != null ? (
                                <MeetingPopover />
                            ) : (
                                meeting === undefined && (
                                    <div className={'w-full pt-1'}>
                                        <TransparencySkeleton
                                            count={1}
                                            className={clsx(
                                                'h-9',
                                                width >= getBreakpointValue('lg') ? 'w-[400px]' : 'w-52'
                                            )}
                                        />
                                    </div>
                                )
                            )}
                            {[MeetingStateValue.PAST, MeetingStateValue.PREVIOUS, MeetingStateValue.FUTURE].includes(
                                meeting?.state
                            ) && (
                                <Tag
                                    color="blue"
                                    size="sm"
                                    href={meeting?.currentMeeting && `/meetings/${meeting.currentMeeting.id}`}
                                    title={t`Click to view current meeting`}
                                    className="hidden @4xl:block ml-1 shrink-0"
                                >
                                    {meeting?.state === MeetingStateValue.FUTURE ? (
                                        <Trans>Future meeting</Trans>
                                    ) : (
                                        <Trans>Past meeting</Trans>
                                    )}
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
                {meeting && !meeting.deleted && <MeetingHeaderStatusButton meeting={meeting} />}
                {meeting === undefined || isLoadingPermission ? (
                    <div className={'-mr-0.5 mt-1 flex gap-0.5 pl-1'}>
                        <TransparencySkeleton
                            count={getActionsSkeletonCount(width)}
                            className={clsx('h-8 w-9 rounded-[3px]')}
                        />
                    </div>
                ) : (
                    <MeetingHeaderActions width={width} canManageMeeting={canManageMeeting} />
                )}
                <div className={'flex items-center justify-end'}>
                    {meeting && !meeting.deleted && meeting.nextMeetings?.length > 0 ? (
                        <HeaderButtonLinkWrapper
                            title={t`Next meeting`}
                            iconPosition={'end'}
                            icon={faChevronRight}
                            meetingId={meeting.nextMeetings[0].id}
                        >
                            {headerWidth >= getBreakpointValue('lg')
                                ? format(new Date(meeting.nextMeetings[0].start_at), 'd MMMM', {
                                      locale: getDateFnsLocale(i18n?.locale),
                                  })
                                : undefined}
                        </HeaderButtonLinkWrapper>
                    ) : meeting !== undefined && !isLoadingPermission ? (
                        canManageMeeting &&
                        !meeting?.deleted && (
                            <Button
                                title={headerWidth < getBreakpointValue('lg') ? t`Add next` : undefined}
                                variant={'outlined'}
                                color={'light'}
                                className={'border-dashed'}
                                disabled={nextMeetingTemplate == null}
                                onClick={handleAddNextMeeting}
                                loading={isAddingMeeting}
                                icon={faPlus}
                            >
                                {headerWidth >= getBreakpointValue('lg') ? <Trans>Add next</Trans> : undefined}
                            </Button>
                        )
                    ) : (
                        <HeaderNavSkeleton />
                    )}
                </div>
            </div>
        </>
    );
};
