import { FC } from 'react';
import { ChooseOrganizationStep } from 'Pages/onboarding/components/ChooseOrganizationStep';
import { InviteStep } from 'Pages/onboarding/components/InviteStep';
import { OrganizationStep } from 'Pages/onboarding/components/OrganizationStep';
import { PasswordStep } from 'Pages/onboarding/components/PasswordStep';
import { ProfileStep } from 'Pages/onboarding/components/ProfileStep';
import { RecapStep } from 'Pages/onboarding/components/RecapStep';
import { TeamStep } from 'Pages/onboarding/components/TeamStep';
import { UrlStep } from 'Pages/onboarding/components/UrlStep';
import { WorkspaceStep } from 'Pages/onboarding/components/WorkspaceStep';
import { useOnboardingStore } from 'Pages/onboarding/utils/onboardingStore';

export const OnboardingForm: FC = () => {
    const step = useOnboardingStore((state) => state.step);
    switch (step) {
        case 0:
            return <ProfileStep />;
        case 1:
            return <PasswordStep />;
        case 2:
            return <ChooseOrganizationStep />;
        case 3:
            return <OrganizationStep />;
        case 4:
            return <UrlStep />;
        case 5:
            return <TeamStep />;
        case 6:
            return <InviteStep />;
        case 7:
            return <WorkspaceStep />;
        case 8:
            return <RecapStep />;
        default:
            return null;
    }
};
