import { Svg, Rect, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { FC } from 'react';

export const DropboxIcon: FC<{ style?: Style }> = ({ style }) => {
    return (
        <Svg width="512" height="512" viewBox="0 0 512 512" fill="none" style={style}>
            <Rect width="500" height="500" transform="translate(6 6)" fill="#0061FE" />
            <Path
                d="M176.711 123.188L97.4226 172.992L176.711 222.797L256.012 172.992L335.299 222.797L414.587 172.992L335.299 123.188L256.012 172.992L176.711 123.188Z"
                fill="white"
            />
            <Path d="M176.711 322.407L97.4226 272.602L176.711 222.797L256.012 272.602L176.711 322.407Z" fill="white" />
            <Path d="M256.012 272.602L335.299 222.797L414.587 272.602L335.299 322.407L256.012 272.602Z" fill="white" />
            <Path d="M256.012 388.812L176.711 339.008L256.012 289.203L335.299 339.008L256.012 388.812Z" fill="white" />
        </Svg>
    );
};
